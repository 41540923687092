import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { GameQuickInfoComponent } from '../game-quick-info/game-quick-info.component';
import { QuickInfoModalComponent } from '../quick-info-modal/quick-info-modal.component';

@Injectable({
    providedIn: 'root',
})
export class QuickInfoService {
    dialogRef?: MatDialogRef<QuickInfoModalComponent>;
    gamedialogRef?: MatDialogRef<GameQuickInfoComponent>;
    constructor(private dialog: MatDialog) {}

    openQuickInfoDialog(requiredModalData: any) {
        const dialogRef = this.dialog.open(QuickInfoModalComponent, {
            width: '790px',
            data: requiredModalData,
        });
        this.dialogRef = dialogRef;
        dialogRef.afterClosed().subscribe(() => {
            this.dialogRef = undefined;
        });
        return dialogRef;
    }

    closeQuickInfoDialog(onCloseDialogData: any) {
        if (this.dialogRef) {
            this.dialogRef.close(onCloseDialogData);
        }
    }

    openGameQuickInfoDialog(requiredModalData: any) {
        const dialogRef = this.dialog.open(GameQuickInfoComponent, {
            data: requiredModalData,
        });
        this.gamedialogRef = dialogRef;
        dialogRef.afterClosed().subscribe(() => {
            this.gamedialogRef = undefined;
        });
        return dialogRef;
    }

    closeGameQuickInfoDialog() {
        if (this.gamedialogRef) {
            this.gamedialogRef.close();
        }
    }
    getQuickInfoGameTitle() {
        let gameTitle: string = '';
        if (this.dialogRef) {
            gameTitle = this.dialogRef.componentInstance.gameTitle;
        }
        return gameTitle;
    }
}
