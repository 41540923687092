import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csNewsFeedConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: newsFeedConfigClientConfigFactory,
})
export class NewsFeedConfigClientConfig {
    isEnabled: boolean;
    isEnabledOnHomeCategory: boolean;
    isEnabledOnNonHomeCategory: boolean;
    newsFeedPosition: any;
    isEnableNewsFeedV2: boolean;
    newsFeedConfiguration: any;
}

export function newsFeedConfigClientConfigFactory(service: LazyClientConfigService) {
    return service.get(NewsFeedConfigClientConfig);
}
