import { IContentImage, IContentLink, ImmersiveLobbyConfig } from './common.model';
import { GameLobbyModel } from './game-lobby.model';

export const LIVELOUNGE_ONBOARDING_COOKIE_NAME = 'hideLCLoungeOnboarding';
export const LIVELOUNGE_STATS_CTA_TYPE_GAME = 'Game';
export const LIVELOUNGE_STATS_CTA_TYPE_STATIC_GAME = 'StaticGame';
export const LIVELOUNGE_STATS_CTA_TYPE_SECTOR = 'Sector';

export interface LiveLoungeIContentParameters {
    CustomLinkDrawerBgClr: string;
    CustomLinkDrawerHeaderClr: string;
}

export interface ILiveCasinoLoungeCustomLink {
    customLinkType?: string;
    customLinkIcon?: IContentImage;
    iconType: string;
    customLink?: IContentLink;
    customLinkDrawerTitle?: string;
    customLinkDrawerBackgroundImage: IContentImage;
    customLinkDrawerContent?: Array<string>;
    messages: any;
    configurationsContent: any;
    constantMessages: any;
    condition: string;

    // Custom Props
    isLoaded: boolean;
}

export class LiveCasinoLoungeSectorItem {
    isGame: boolean;
    rowSpan: number;
    colSpan: number;
    iconType: string;
    game: GameLobbyModel;
    customLink: ILiveCasinoLoungeCustomLink;
}

export interface ILiveCasinoLoungeSector {
    isDisabled: boolean;
    categoryId: string;
    categoryName: string;
    width: number;
    height: number;
    top: number;
    left: number;
    imageType: string;
    sectorImage: string;
    sectorIcon: IContentImage;
    isDirectGameLaunch: boolean;
    gamesGridColumns: number;
    customLinksGridWidth: number;
    customLinkItems: Array<ILiveCasinoLoungeCustomLink>;
    customLinksGridColumns: number;
    customLinksPosition: string;
    condition: string;
    gamesDisplayType: string;

    // Custom Props
    categoryIndex: number;
    sectorContainerId: string;
    isLoaded: boolean;
    lobbyGames: Array<GameLobbyModel>;
    games: Array<LiveCasinoLoungeSectorItem>;
    customLinks: Array<LiveCasinoLoungeSectorItem>;
}

export interface ILiveCasinoLoungeMap {
    width: number;
    height: number;
    backgroundImage: IContentImage;
    layeredImageType: string;
    layeredImage: string;
    sectors: Array<ILiveCasinoLoungeSector>;
    condition: string;
    audioFile?: string;
}

export interface ILiveCasinoLoungeStatCategory {
    categoryId: string;
    categoryName: string;
    categoryIcon: IContentImage;
    showHighlights: boolean;
}

export interface ILiveCasinoLoungeStatItem {
    itemId: string;
    itemName: string;
    itemIcon: IContentImage;
    showCTA: boolean;
    ctaText: string;
    ctaType: string;
    sectorId: string;
    staticGameId: string;
    showTimeFrame: boolean;
    timeFrame: string;
    dataType: string;

    // Custom Props
    value: string;
    gameId: string;
    gameName: string;
    betTS: string;
    isStaticGame: boolean;
    isDynamicGame: boolean;
}

export interface IGameTableName {
    physicalName: string;
    gameTableName: string;
    commercialGameTableName: string;
}

export interface ILiveCasinoLoungeStats {
    welcomeTitle: string;
    welcomeDescription: string;
    welcomeBackgroundImage: IContentImage;
    statsDrawerTitle: string;
    statsDrawerBackgroundColor: string;
    statsDrawerHeaderColor: string;
    statsDrawerBackgroundImage: IContentImage;
    statsEmptyMessage: string;
    statsLoginMessage: string;
    statsLoginCategoryName: string;
    statsFooterContent: string;
    statsErrorMessage: string;
    statsUpdateErrorMessage: string;
    categories: Array<ILiveCasinoLoungeStatCategory>;
    stats: Array<ILiveCasinoLoungeStatItem>;
    gameTableNames: Array<IGameTableName>;
    condition: string;
}

export class LiveCasinoLoungeConfiguration extends ImmersiveLobbyConfig {
    enabled: boolean;
    defaultCategoryId: string;
    statCategoriesDataPath: string;
    statItemsDataPath: string;
    statsDataPath: string;
    enableAudioButton: boolean;
    enableGameNameMapping: boolean;
    enableStats: boolean;
}

export class LiveCasinoLoungeStat {
    category: ILiveCasinoLoungeStatCategory;
    stats: Array<ILiveCasinoLoungeStatItem>;
    impStats: Array<ILiveCasinoLoungeStatItem>;
    isAvailable: boolean;

    constructor() {
        this.stats = [];
        this.impStats = [];
        this.isAvailable = false;
    }
}

export interface ILiveCasinoLoungeGlobalConfig {
    StatsPreloginBtnText: string;
}

export interface ILiveCasinoLoungeGlobalClrConfig {
    mapBgClr: string;
    statsIconBgColor: string;
    statsThemeIcon: string;
}
