<!--component html goes here -->
<div
    class="small-icon"
    *ngIf="showJackpotWidget"
    style.background-image="{{ jackpotWidgetBackgroundImage?.src ? 'url(' + jackpotWidgetBackgroundImage.src + ')' : '' }}"
    [ngStyle]="{ 'background-color': singleJackpotBgClr }"
    [ngClass]="{
        'casino-ios-bottom-nav-bar': isCrossSaleIOS,
        'casino-jackpot-widget-container': mode === 'v1',
        'casino-mode2': mode === 'v2',
        'casino-mode3': mode === 'v3'
    }">
    <!--Jackpot widget section -->
    <cc-casino-core-lazy-placeholder
        *ngIf="mode === 'v1'"
        [inputData]="{
            jackpotName: jackpotName,
            jackpotValue: jackpotValue,
            jackpotTikking: games?.length > 0 ? games[0] : [],
            showBackgroundImage: showBackgroundImage,
            jackpotWidgetBackgroundImage: jackpotWidgetBackgroundImage,
            jackpotWidgetImage: jackpotWidgetImage,
            className: className,
            jackpotType: jackpotType
        }"
        [componentName]="'JackpotwidgetComponent'"></cc-casino-core-lazy-placeholder>

    <cc-casino-core-lazy-placeholder
        *ngIf="mode === 'v2' || mode === 'v3'"
        [inputData]="{
            jackpotName: jackpotName,
            jackpotTikking: games?.length > 0 ? games[0] : [],
            jackpotValue: jackpotValue,
            showBackgroundImage: showBackgroundImage,
            jackpotWidgetBackgroundImage: jackpotWidgetBackgroundImage,
            jackpotWidgetImage: jackpotWidgetImage,
            className: className,
            jackpotType: jackpotType,
            jackpotNameFromSitecore: jackpotNameFromSitecore
        }"
        [componentName]="'JackpotWidgetRedesignComponent'">
    </cc-casino-core-lazy-placeholder>

    <div class="multi-jackpot-seeall" *ngIf="changeCarouselView" [ngStyle]="{ 'color': subCategoryTextColor, 'font-family': lobbyTileFontFamily }">
        <span *ngIf="!isTouch && carouselData?.showArrows" class="seeallarrows">
            <span class="arrows-left" [ngClass]="themeArrowLeft" [ngStyle]="{ 'pointer-events': carouselData?.disablePrev ? 'none' : 'auto' }"></span>
            <span
                class="arrows-right"
                [ngClass]="themeArrowRight"
                [ngStyle]="{ 'pointer-events': carouselData?.disableNext ? 'none' : 'auto' }"></span>
        </span>
    </div>
    <!--Jackpot widget content section-->
    <div class="jackpot-widget-content scrollable-games casino-lobby-width-gametile" [class.non-touch-scrollable-games]="!isTouch">
        <div [ngClass]="{ 'jackpot-margin': isTouch, 'casino-jackpot-justifycenter': games.length <= 3 }">
            <cc-feature-grid
                #featuredGrid
                *ngIf="games && games.length > 0"
                [changeCarouselView]="changeCarouselView"
                [games]="games"
                [EnableFutureGrid]="false"
                [subcategory]="categoryObj"
                [isTouch]="isTouch"
                [Type]="'scrollable-games'"
                (carouselDataEvent)="getCarouselData($event)"
                [gameLaunchedFrom]="'JackpotWidget_' + category.engCategoryName"
                [newFeatureGridArr]="newFeatureGridArr"></cc-feature-grid>
        </div>
    </div>
    <div class="jackpot-widget-content">
        <div class="see-all-button" *ngIf="cta && cta.linkText">
            <!-- <div class="btn btn-cta btn-primary" [innerHTML]="cta.linkText || moreInfoMessage" (click)="GotoJackpots(cta.url)"></div> -->
            <cs-gametile-cta
                [isLegacy]="isLegacy"
                [kind]="'primary'"
                [size]="'medium'"
                (click)="GotoJackpots(cta.url)"
                [csDecoratorClass]="'btn-cta'"
                [cta_text]="cta.linkText || moreInfoMessage">
            </cs-gametile-cta>
        </div>
    </div>
</div>
