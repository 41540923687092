import { Injectable } from '@angular/core';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { CasinoCoreNpmParamsService } from './casino-core-npm-params.service';
import { CasinoManager } from './casino-manager.service';
import { InvokerProductService } from './invoker-product.service';

@Injectable({
    providedIn: 'root',
})
export class CasinoIFrameOverlayService {
    isOverlay: boolean;
    constructor(
        private casinoManager: CasinoManager,
        private configProviderService: ConfigProviderService,
        private casinoCoreNpmParamsService: CasinoCoreNpmParamsService,
        private invokerProductService: InvokerProductService,
    ) {}

    setOverlayStatus(isOverlay: boolean) {
        this.isOverlay = isOverlay;
    }

    getOverlayStatus() {
        return this.isOverlay;
    }

    isOverLayOnAProduct(lobbyType: string = ''): boolean {
        const casinoAsIFrameOverlayConfig = this.configProviderService.provideCasinoAsIFrameConfig();
        if (casinoAsIFrameOverlayConfig?.overlayLobbyTypeMap && casinoAsIFrameOverlayConfig.overlayLobbyTypeMap.length > 0) {
            const overlayLobbyTypeMap = casinoAsIFrameOverlayConfig?.overlayLobbyTypeMap.filter(
                (l: any) => l?.invokerProduct?.toLowerCase() === this.invokerProductService?.getInvokerProduct()?.toLowerCase(),
            );
            return lobbyType ? this.casinoManager.IsFeatureEnabledforLobbytype(lobbyType, overlayLobbyTypeMap) : false;
        }
        return false;
    }

    lobbyTypeForNavCategories(): string {
        const casinoAsIFrameOverlayConfig = this.configProviderService.provideCasinoAsIFrameConfig();
        if (
            casinoAsIFrameOverlayConfig &&
            casinoAsIFrameOverlayConfig.overlayBottomNavLobbyTypeMap &&
            casinoAsIFrameOverlayConfig.overlayBottomNavLobbyTypeMap.length > 0
        ) {
            const overlayBottomNavLobbyTypeMap = casinoAsIFrameOverlayConfig.overlayBottomNavLobbyTypeMap.filter(
                (l: any) => l?.invokerProduct?.toLowerCase() === this.invokerProductService?.getInvokerProduct()?.toLowerCase(),
            );
            if (overlayBottomNavLobbyTypeMap && overlayBottomNavLobbyTypeMap.length > 0) {
                for (let overlayBottomNavLobbyTypeMapItem of overlayBottomNavLobbyTypeMap.length) {
                    return overlayBottomNavLobbyTypeMapItem.lobbyType;
                }
            }
        }
        return '';
    }

    fetchOverlayLobby(): string {
        const lmtConfig: any = this.configProviderService.provideLmtConfig();
        const invokerProduct: string = this.invokerProductService.getInvokerProduct();
        const overlayConfig: any = this.configProviderService.provideCasinoAsIFrameConfig();
        const casinoNpmParams = this.casinoCoreNpmParamsService.getcasinoCoreNpmParams();
        let overlayLobbyType: string = '';

        if (lmtConfig.lobbyTypeMap && lmtConfig.lobbyTypeMap.length > 0) {
            const applicableLobbyTypeMaps: any = lmtConfig.lobbyTypeMap.filter(
                (l: any) => l?.invokerProduct?.toLowerCase() === invokerProduct?.toLowerCase(),
            );
            if (applicableLobbyTypeMaps && applicableLobbyTypeMaps.length > 0) {
                if (casinoNpmParams.customLobbyTypeMaps && casinoNpmParams.customLobbyTypeMaps.length > 0) {
                    overlayLobbyType = applicableLobbyTypeMaps[0].lobbyType ? applicableLobbyTypeMaps[0].lobbyType : '';
                } else if (overlayConfig && overlayConfig.overlayLobbyTypeMap && overlayConfig.overlayLobbyTypeMap.length > 0) {
                    const lobbyTypeObj = overlayConfig.overlayLobbyTypeMap.filter(
                        (l: any) => l?.invokerProduct?.toLowerCase() === invokerProduct?.toLowerCase(),
                    );
                    if (lobbyTypeObj?.length > 0) overlayLobbyType = lobbyTypeObj[0].fetchMeta ? lobbyTypeObj[0].lobbyType : '';
                }
            }
        }
        return overlayLobbyType;
    }
}
