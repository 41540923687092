import { CurrencyPipe, NgFor } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { ConfigProviderService, FreespinSummaryService, TimeZoneFormatPipe } from '@casinocore/platform/core';
import { ClaimsService, NavigationService, TrackingService } from '@frontend/vanilla/core';
import { NgCircleProgressModule } from 'ng-circle-progress';

@Component({
    selector: 'cc-quick-info-free-spins',
    templateUrl: 'quick-info-free-spins.component.html',
    standalone: true,
    imports: [NgFor, NgCircleProgressModule, CurrencyPipe, TimeZoneFormatPipe],
})
export class QuickInfoFreeSpinsComponent implements OnInit {
    @Input() rewardDetails: any;
    @Input() gameTitle: any;
    enableFreeSpinSummary: boolean;
    isSpinShow: boolean;
    isSpinDefaultShow: boolean;
    freespinscountbg: string;
    freeSpinsRemainingText: string;
    totalAmountText: string;
    ofText: string;
    expiryDateText: string;
    quickFreeSpinsBoxColor: string;
    quickExpiryDetailsBoxColor: string;
    freeSpinsCountCircleColor: string;
    freeSpinsFont: string;
    moreInfoText: string;
    moreInfoUrl: any;
    fontColor: any;
    currencySymbol: any;
    timeZoneData: any;
    rewardPercent: number;
    quickInfoService: any;

    constructor(
        private configProviderService: ConfigProviderService,
        private tracking: TrackingService,
        private claims: ClaimsService,
        private freespinSummaryService: FreespinSummaryService,
        private navigation: NavigationService,
        private dialogRef: MatDialogRef<QuickInfoFreeSpinsComponent>,
    ) {}
    ngOnInit() {
        if (this.rewardDetails) {
            this.timeZoneData = this.freespinSummaryService.getTimeZoneInfo();
        }
        this.freespinscountbg = this.configProviderService.provideGlobalConfig().freespinsCountBackgroundColor;
        this.enableFreeSpinSummary = this.configProviderService.providefreeSpinsClientConfig().isFreeSpinSummaryEnabled;
        this.freespinscountbg = this.configProviderService.provideGlobalConfig().lobbyConfigurations.freespinsCountBackgroundColor;
        this.freeSpinsRemainingText = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.freeSpinsRemainingText;
        this.totalAmountText = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.totalAmountText;
        this.ofText = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.ofText;
        this.expiryDateText = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.expiryDateText;
        this.quickFreeSpinsBoxColor = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.quickFreeSpinsBoxColor;
        this.quickExpiryDetailsBoxColor =
            this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.quickExpiryDetailsBoxColor;
        this.freeSpinsCountCircleColor = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.freeSpinsCountCircleColor;
        this.freeSpinsFont = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.freeSpinsFont;
        this.moreInfoText = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.moreInfoText;
        this.fontColor = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.fontColor;
    }
    moreBoxInfoClick(event: any, rewardDataIndex: number) {
        this.onMoreInfoclick(event, rewardDataIndex, 'promotion area');
    }
    onMoreInfoclick(event: any, rewardDataIndex: number, clickArea: string) {
        const rewardData = this.rewardDetails[rewardDataIndex - 1];
        event.stopPropagation();
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'games',
            'component.LabelEvent': 'game interactions', //this.configProviderService.providefreeSpinsClientConfig().freeSpinsMoreInfo.metadata.id.itemName,//(this.casinoManager.product ? this.casinoManager.product.toUpperCase() : '') + '_' + unescape(this.teaser.metadata.id.itemName)
            'component.ActionEvent': 'click',
            'component.PositionEvent': 'FreeSpins' + '-' + rewardDataIndex,
            'component.LocationEvent': 'more info overlay',
            'component.EventDetails': clickArea,
            'component.URLClicked': rewardData.moreinfoUrl,
        });
        this.navigationAndDialogClose();
        this.navigation.goTo(rewardData?.moreinfoUrl);
    }
    public moreInfoClick(event: any, rewardDataIndex: number) {
        this.onMoreInfoclick(event, rewardDataIndex, 'moreInfoCta');
    }
    navigationAndDialogClose() {
        const onCloseDialogData: any = {
            noNavigation: true,
        };
        this.dialogRef.close(onCloseDialogData);
        this.freespinSummaryService.freespinMoreinfoClick();
    }
    public calculateRewardPercent(rewardData: any) {
        this.rewardPercent = (rewardData.availableCount / rewardData.totalCount) * 100;
        return this.rewardPercent;
    }
}
