import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { CasinoLobbyService, CasinoManager, ConfigProviderService } from '@casinocore/platform/core';

import { CasinoCoreLazyPlaceholderComponent } from '../casino-core-lazy-placeholder/casino-core-lazy-placeholder.component';
import { LMTCategoryLobbyComponent } from '../lmt-category-lobby/lmt-category-lobby.component';

@Component({
    selector: 'cc-lmt-category-wrapper',
    templateUrl: 'lmt-category-wrapper.component.html',
    standalone: true,
    imports: [NgSwitch, NgIf, NgSwitchCase, CasinoCoreLazyPlaceholderComponent, NgSwitchDefault, LMTCategoryLobbyComponent],
})
export class LMTCategoryWrapperComponent implements OnChanges {
    @Input() categoryId: string;
    @Input() isTouch: boolean;
    @Input() category: any;
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    liveGameIframeCategory: string;
    liveGameInfo: any;
    liveGamesCategory: any;
    discoverFeedLmtkey: boolean;
    isSlotRacesEnabled: boolean;
    slotRacesCategoryId: string;
    isSlotRacesPhase2Enabled: boolean;
    constructor(
        private casinoManager: CasinoManager,
        private configProviderService: ConfigProviderService,
        private casinoLobbyService: CasinoLobbyService,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.lobbyType || changes.categoryId) {
            this.getInputParameters();
        }
    }
    getInputParameters() {
        const features = this.configProviderService.provideFeaturesConfig();
        let slotRacesConfig = this.configProviderService.provideSlotRacesConfig();
        this.isSlotRacesPhase2Enabled = slotRacesConfig?.enableSlotRacesPhase2;
        this.isSlotRacesEnabled = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.enableSlotRacesForLobbyType);
        this.slotRacesCategoryId = this.casinoManager.getSlotRaceCategoryId(this.lobbyType);
        const discoverFeedConfig = this.configProviderService.provideDiscoverFeedConfig();
        if (discoverFeedConfig.enableDiscoverFeed) {
            this.discoverFeedLmtkey = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, discoverFeedConfig.discoverFeedLmtkey);
        }
        const casinoConfig = this.configProviderService.provideCasinoConfig();
        if (casinoConfig.enableLiveGames) this.checkLiveGameIframeCategory();
    }
    checkLiveGameIframeCategory() {
        const casinoConfig = this.configProviderService.provideCasinoConfig();
        if (casinoConfig.liveGamesCategories && casinoConfig.liveGamesCategories.length > 0) {
            for (let i = 0; i < casinoConfig.liveGamesCategories.length; i++) {
                if (casinoConfig?.liveGamesCategories[i]?.toLowerCase() === this.categoryId?.toLowerCase()) {
                    const liveCategoryInfo = this.casinoLobbyService.getCategoryInfo(this.lobbyType, this.categoryId);
                    this.liveGameInfo = this.casinoLobbyService.getGameInfo(liveCategoryInfo.categoryInfo.gamelist[0].game);
                    this.liveGamesCategory = liveCategoryInfo.categoryInfo;
                    if (this.liveGameInfo) {
                        this.liveGameIframeCategory = this.categoryId;
                    }
                }
            }
        }
    }
}
