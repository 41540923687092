<div
    *ngIf="displayStaticThumbnail"
    [ngClass]="{
        'casino-with-out-subnavigation thumbnail-newgame': !isTouch || desktopbreakpointforMobile,
        'casino-mobile-thumbnail-wrapper with casino-thumbnail-mobile thumbnailAnimate': isTouch && isMobileWidth()
    }">
    <div [ngStyle]="{ top: isTouch ? thumnailHeaderHeight + 'px' : '0px' }">
        <cc-thumbnail-newgame
            [lobbyType]="lobbyType"
            [place]="'Product Switcher'"
            [category]="category"
            [isTouch]="isTouch"
            (closeThumbnail)="closeThumbnailBox($event)"></cc-thumbnail-newgame>
    </div>
</div>
