import { Injectable } from '@angular/core';

import { ApiBase, ApiServiceFactory } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    useFactory: apiServiceFactory,
    deps: [ApiServiceFactory],
})
export class PlatformApiService extends ApiBase {}

export function apiServiceFactory(apiServiceFactory: ApiServiceFactory) {
    return apiServiceFactory.createForProduct(PlatformApiService, { product: 'casino', area: 'games', forwardProductApiRequestHeader: true });
}
