import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

import { GeolocationConfigurationModel, InstallerSettingsModel, OobeeIntimationModel } from '../shared/models/geolocation.configuration.model';

@LazyClientConfig({ key: 'csGeolocationConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: geolocationClientConfigFactory,
})
export class GeolocationClientConfig {
    isEnabled: boolean;
    coordinatorSettings: GeolocationConfigurationModel;
    installerSettings: InstallerSettingsModel;
    oobeeIntimation: OobeeIntimationModel;
    synchronizationEvent: string;
    synchronizeCoordination: boolean;
    assetsPath: any;
    gdsUrl: any;
    initialRetryInterval: number;
    isRetryButtonEnabled: boolean;
    retryInterval: number;
    shouldCheckNativeGeoLocation: boolean;
    loadAssetsOnServer: boolean;
}

export function geolocationClientConfigFactory(service: LazyClientConfigService) {
    return service.get(GeolocationClientConfig);
}
