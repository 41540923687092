import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { CasinoLobbyService } from '../casino-lobby-manager/casino-lobby.service';
import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';
import { CacheManagerService } from './cache-manager.service';
import { CasinoManager } from './casino-manager.service';
import { GameLobbyModel } from './models/game-lobby.model';
import { GameDataFromCacheResponseModel } from './models/gameDataFromCacheResponse.model';

@Injectable({
    providedIn: 'root',
})
export class GameDataManager {
    gameDataLookup: Map<string, any> = new Map<string, any>();
    gamesLookUp: string[] = [];
    gameDataFromLMT: any = [];
    liveCasinoLookup: any = [];
    gameConfig: any;

    private gameLookUpFilledPublisher = new BehaviorSubject<any>(null);
    gameLookUpObservable: Observable<any> = this.gameLookUpFilledPublisher.asObservable();

    constructor(
        private configProviderService: ConfigProviderService,
        private api: PlatformApiService,
        private cacheManager: CacheManagerService,
        private casinoLobbyService: CasinoLobbyService,
        private casinoManager: CasinoManager,
    ) {}

    setGameLobbyModel(gameModelItem: any, isTouch: boolean, arcadeLobbyType: string = '', currentLobbyType = ''): GameLobbyModel {
        const gameConfig: any = this.configProviderService.provideGameConfig();
        const isArcade: boolean =
            arcadeLobbyType && currentLobbyType && currentLobbyType?.toLowerCase() == arcadeLobbyType?.toLowerCase() ? true : false;
        const gamelobbyModel: GameLobbyModel = new GameLobbyModel();
        gamelobbyModel.gameId = gameModelItem.game;
        gamelobbyModel.jackpotValue = gameModelItem.jackpotValue ? gameModelItem.jackpotValue : '';
        gamelobbyModel.name = gameModelItem.name;
        gamelobbyModel.provider = gameModelItem.provider;
        gamelobbyModel.ownerId = gameModelItem.ownerId;
        if (gameModelItem.sticker) {
            gamelobbyModel.sticker = gameModelItem.sticker;
        }
        gamelobbyModel.internalGameName = gameModelItem.gameId;
        gamelobbyModel.lobbyType = gameModelItem.lobbyType;
        gamelobbyModel.gameIndex = gameModelItem.gameIndex;
        gamelobbyModel.icon = {
            src:
                gameConfig.host +
                gameConfig.gameIconTemplate.replace('{0}', this.getGameIcon(gameModelItem, isArcade)).replace('{1}', gamelobbyModel.gameId) +
                '.' +
                this.getGameFormat(gameModelItem, isArcade),
        };

        gamelobbyModel.format = this.getGameFormat(gameModelItem, isArcade);
        gamelobbyModel.rowSpan = this.getRowSpan(String(gameModelItem.icon), isArcade);
        gamelobbyModel.colSpan = this.getColSpan(String(gameModelItem.icon), isTouch);
        gamelobbyModel.iconType = gameModelItem.icon ? gameModelItem.icon : '1';
        gamelobbyModel.iconTypeForTracking = gamelobbyModel.iconType;
        if (gamelobbyModel.iconType && typeof gamelobbyModel.iconType === 'number') {
            gamelobbyModel.iconType = String(gamelobbyModel.iconType);
        }
        if (gamelobbyModel.iconType && gamelobbyModel.iconType.indexOf('-') > -1) {
            gamelobbyModel.iconType = gamelobbyModel.iconType.replace('-', '');
        }

        return gamelobbyModel;
    }

    getGameIcon(gameModelItem: any, isArcade: boolean = false): string {
        const gameConfig: any = this.configProviderService.provideGameConfig();
        const arcadeConfig: any = this.configProviderService.provideAracadeLobbyConfig();
        let featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        const lobbyType = this.casinoManager.getLobbyType() || '';
        const lobbyInfo = this.casinoLobbyService.getLobbyInfo(lobbyType);
        const gameConfigMap = isArcade ? arcadeConfig.arcadeIconConfigMap : gameConfig.iconConfigMap;
        if (gameModelItem) {
            const gameIcon = gameConfigMap.filter((s: any) => s.id == gameModelItem.icon);
            if (gameIcon && gameIcon[0]) {
                // iskyc check commented
                if (lobbyInfo?.lobbyGameIconImageSuffix && featuresConfig.enableGameIconImageSuffix) {
                    return gameIcon[0].icon + lobbyInfo?.lobbyGameIconImageSuffix;
                }
                return gameIcon[0].icon;
            }
        }
        if (lobbyInfo?.lobbyGameIconImageSuffix && featuresConfig.enableGameIconImageSuffix) {
            return gameConfigMap[5].icon + lobbyInfo?.lobbyGameIconImageSuffix;
        }
        return gameConfigMap[5].icon;
    }

    getGameFormat(gameModelItem: any, isArcade: boolean = false): string {
        let gameConfig: any = this.configProviderService.provideGameConfig();
        let arcadeConfig: any = this.configProviderService.provideAracadeLobbyConfig();
        let gameConfigMap = isArcade ? arcadeConfig.arcadeIconConfigMap : gameConfig.iconConfigMap;
        if (gameModelItem) {
            let gameIcon = gameConfigMap.filter((s: any) => s.id == gameModelItem.icon);
            if (gameIcon && gameIcon[0]) {
                return gameIcon[0].format;
            }
        }
        return 'jpg';
    }

    getRowSpan(icon: string, isArcade: boolean): number {
        switch (icon) {
            case '2':
            case '-2':
                return 1;
            case '3':
            case '-3':
                return 2;
            case '4':
            case '-4':
                return 2;
            case '5':
            case '-5':
                return 2;
            case '6':
            case '-6':
                return isArcade ? 2 : 1;
            case '7':
            case '-7':
                return 1;
            case '10':
            case '-10':
                return 2;
            default:
                return isArcade ? 2 : 1;
        }
    }

    getColSpan(icon: string, isTouch: boolean): number {
        switch (icon) {
            case '2':
            case '-2':
                return 2;
            case '3':
            case '-3':
                return 1;
            case '4':
            case '-4':
                return 2;
            case '5':
            case '-5':
                if (isTouch) {
                    return 2;
                } else {
                    return 3;
                }
            case '6':
            case '-6':
                return 1;
            case '7':
            case '-7':
                return 2;
            case '10':
            case '-10':
                return 2;
            default:
                return 1;
        }
    }
    getAllGamesFromLMT() {
        return this.gameDataFromLMT;
    }
    // NFR - Services optimization - Remove
    getGameData(gameId: string) {
        const promise = new Promise((resolve, reject) => {
            const gameDataFromCache = this.tryAndGetGameDataFromCache([gameId]);
            if (gameDataFromCache && gameDataFromCache.gameMetaData.size > 0 && gameDataFromCache.gameMetaData.get(gameId)) {
                resolve(gameDataFromCache.gameMetaData.get(gameId));
            } else {
                const apiPathsConfig = this.configProviderService.provideApiPathsConfig();

                this.api.get(apiPathsConfig.gameData['gameData'], { n: [gameId], gameNames: true }).subscribe(
                    (response) => {
                        if (response && response[0]) {
                            resolve(response[0]);
                            this.updateLookUp(response);
                        } else {
                            const result = this.createEmptyGameModel(gameId);
                            resolve(result);
                            this.updateLookUp([result]);
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                );
            }
        });
        return promise;
    }
    tryAndGetGameDataFromCache(gamesList: string[]): GameDataFromCacheResponseModel {
        let response: GameDataFromCacheResponseModel = new GameDataFromCacheResponseModel();
        const gamesData = this.getGamesDataFromLookUp(gamesList);
        if (gamesData !== null) {
            response = gamesData;
            gamesList = gamesData.gamesNotInCache;
        }
        if (gamesData == null || gamesData.gamesNotInCache !== null) {
            const gameDataFromLocalStorage = this.cacheManager.getGamesDataFromCache(gamesList);
            if (gameDataFromLocalStorage !== null && gameDataFromLocalStorage.gameMetaData.size > 0) {
                if (response == null) response = new GameDataFromCacheResponseModel();
                Array.from(gameDataFromLocalStorage.gameMetaData.entries()).forEach((entry: any) => {
                    response.gameMetaData.set(entry[0], entry[1]);
                });
                response.gamesNotInCache = gameDataFromLocalStorage.gamesNotInCache;
            }
        }
        return response;
    }
    updateLookUp(gameData: any) {
        gameData.forEach((game: any) => {
            this.gameDataLookup.set(game.gameId, game);
        });
        this.cacheManager.setGameDataCache(this.gameDataLookup);
    }
    createEmptyGameModel(gameId: string) {
        const emptyGameModel: GameLobbyModel = new GameLobbyModel();
        emptyGameModel.isDataNotAvailable = true;
        emptyGameModel.gameId = gameId;
        return emptyGameModel;
    }
    getGamesDataFromLookUp(gamesList: string[]): GameDataFromCacheResponseModel {
        const gameDataSet: Map<string, any> = new Map<string, any>(),
            gamesNotInCache: string[] = [];
        if (this.gameDataLookup.size > 0) {
            const gameDataMap = this.gameDataLookup;
            if (gamesList && gamesList.length > 0) {
                gamesList.forEach((game) => {
                    if (gameDataMap.get(game)) {
                        gameDataSet.set(game, gameDataMap.get(game));
                    } else {
                        gamesNotInCache.push(game);
                    }
                });
            }
            const data: GameDataFromCacheResponseModel = {
                gameMetaData: gameDataSet,
                gamesNotInCache: gamesNotInCache.length > 0 ? gamesNotInCache : [],
            };
            return data;
        }
        return new GameDataFromCacheResponseModel();
    }
    setDefaultGameIcons(gameIds: string[]) {
        const gamesModel: any[] = [];
        if (gameIds && gameIds.length > 0) {
            gameIds.forEach((gameId: string) => {
                const GameModelItem: any = {
                    game: gameId,
                    icon: '1',
                };
                gamesModel.push(GameModelItem);
            });
        }
        return gamesModel;
    }
    getGameDisplayName(gameId: string): string {
        const gameMetaData = this.getLMTGameData(gameId);
        return gameMetaData && gameMetaData.name;
    }
    getLMTGameData(gameId: string): any {
        if (this.gameDataFromLMT && this.gameDataFromLMT.length > 0) {
            for (let i = 0; i < this.gameDataFromLMT.length; i++) {
                if (this.gameDataFromLMT[i].game === gameId) {
                    return this.gameDataFromLMT[i];
                }
            }
        }
        return null;
    }
    getGameDescription(gameId: string, providerId: string): Observable<any> {
        if (gameId) {
            const apiPathsConfig = this.configProviderService.provideApiPathsConfig();

            return this.api.get(apiPathsConfig.gameData['gameDataDescription'], { gameId: gameId, providerId: providerId });
        }
        return new Observable<any>();
    }
}
