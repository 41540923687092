<div class="casino-teasers-crousale teasers" *ngIf="teasers.length > 0">
    <div [ngClass]="Modeset" class="casino-lobby-width">
        <vn-swiper #teaserCarousel *ngIf="teasersconfigloaded" [swiperOptions]="swiperOptionsTeasersNpm">
            <div class="swiper-slide" *ngFor="let teaser of teasers; let i = index">
                <div class="bottom-terms swiper-item">
                    <div [ngClass]="assignBrowserSpecificClass()" (click)="teaserClick($event, teaser, i + 1)">
                        <div class="teasers">
                            <div class="item-slide" class="{{ teaser.attributes.teaserAlignments }}" [ngClass]="{ 'show-terms': teaser.termsText }">
                                <div
                                    *ngIf="!(teaser.jackpot && teaser.jackpot.trim().length > 0)"
                                    [ngClass]="{ 'bottom-title': teaser.attributes.bottomTitle === true }">
                                    <div class="md-teaser-body">
                                        <div
                                            class="md-teaser-image"
                                            *ngIf="teaser.backgroundImage"
                                            [ngStyle]="{
                                                'background-image': 'url(' + (teaser.backgroundImage.src | sitecore_image_resize) + ')'
                                            }"></div>
                                        <div class="md-teaser-text">
                                            <img *ngIf="teaser.image" [src]="teaser.image.src" alt="" />
                                            <div class="md-teaser-inner-text">
                                                <div
                                                    class="title"
                                                    [ngStyle]="{ color: teaser.attributes.titleColor }"
                                                    [innerHtml]="teaser.title | trustAsHtml"></div>
                                                <div
                                                    class="subtitle"
                                                    [ngStyle]="{ color: teaser.attributes.subtitleColor }"
                                                    [innerHtml]="teaser.subtitle | trustAsHtml"></div>
                                                <div class="md-teaser-cta" *ngIf="teaser.ctaTitle" (click)="ctaClick($event, teaser, i + 1)">
                                                    <!-- <a
                                                        class="btn btn-primary"
                                                        [ngStyle]="{ color: teaser.attributes.ctaBgColor }"
                                                        [innerHtml]="teaser.ctaTitle | trustAsHtml"
                                                        [ngClass]="{ 'epcot-btn': enableEpcotSubNavigation }">
                                                    </a> -->
                                                    <cs-gametile-cta
                                                        [isLegacy]="isLegacy"
                                                        [kind]="'primary'"
                                                        [csDecoratorClass]="{ 'epcot-btn': enableEpcotSubNavigation }"
                                                        [customTextColor]="teaser.attributes.ctaBgColor"
                                                        [cta_text]="teaser.ctaTitle"></cs-gametile-cta>
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="teaser.termsText && !enablebingoplay"
                                                class="small-terms"
                                                [innerHtml]="teaser.termsText | trustAsHtml"
                                                (click)="termsClick($event, teaser)"
                                                [ngStyle]="{
                                                    'color': teaser.attributes.termsColor,
                                                    'background-color': teaser.attributes.termsBackgroundColor
                                                }"></div>
                                            <div
                                                *ngIf="teaser.termsText && enablebingoplay"
                                                class="small-terms"
                                                (click)="termsClick($event, teaser)"
                                                [ngStyle]="{
                                                    'color': teaser.attributes.termsColor,
                                                    'background-color': teaser.attributes.termsBackgroundColor
                                                }">
                                                <div class="text-terms" [innerHtml]="teaser.termsText | trustAsHtml"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="teaser.jackpot && teaser.jackpot.trim().length > 0"
                                    class="jackpot-teaser"
                                    [ngClass]="{ 'bottom-title': teaser.attributes.bottomTitle === true }">
                                    <div class="md-teaser-body">
                                        <div
                                            class="md-teaser-image"
                                            *ngIf="teaser.backgroundImage"
                                            [ngStyle]="{
                                                'background-image': 'url(' + (teaser.backgroundImage.src | sitecore_image_resize) + ')'
                                            }"></div>
                                        <div class="md-teaser-text">
                                            <img *ngIf="teaser.image" [src]="teaser.image.src" alt="" />
                                            <div class="md-teaser-inner-text">
                                                <div
                                                    class="title"
                                                    [ngStyle]="{ color: teaser.attributes.titleColor }"
                                                    [innerHtml]="teaser.title | trustAsHtml"></div>
                                                <div
                                                    class="subtitle"
                                                    [ngStyle]="{ color: teaser.attributes.subtitleColor }"
                                                    [innerHtml]="teaser.subtitle | trustAsHtml"></div>
                                                <ng-container *ngIf="EnableJackpotTickerTeasers">
                                                    <!-- <div class="jackpot-value">
                                                    <cc-flipclock [amount]="teaser.jackpotTickValue"
                                                        [currency]="teaser.jpCurSymbol">
                                                    </cc-flipclock>
                                                </div> -->
                                                </ng-container>
                                                <ng-container *ngIf="!EnableJackpotTickerTeasers">
                                                    <div class="title-jackpot-name" [ngStyle]="{ color: teaser.attributes.jpTextColor }">
                                                        {{ teaser.jackpot }}
                                                    </div>
                                                    <div
                                                        *ngIf="teaser.jackpotValue"
                                                        class="title"
                                                        [ngStyle]="{ color: teaser.attributes.jpValueColor }">
                                                        {{ isNPM ? teaser.jackpotValue : teaser.jpCurSymbol + ' ' }}
                                                        <div *ngIf="!isNPM" class="jackpot-tick-value">
                                                            <!-- <cs-jp-counter [amt]="teaser.jackpotTickValue" [time]="1" [decimal]="2">
                                                        </cs-jp-counter> -->
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <div class="md-teaser-cta" (click)="ctaClick($event, teaser, i + 1)">
                                                    <!-- <a [ngStyle]="{ color: teaser.attributes.ctaBgColor }"
                                                        class="btn btn-primary"
                                                        [innerHtml]="teaser.ctaTitle | trustAsHtml">
                                                    </a> -->
                                                    <cs-gametile-cta
                                                        [isLegacy]="isLegacy"
                                                        [kind]="'primary'"
                                                        [customTextColor]="teaser.attributes.ctaBgColor"
                                                        [cta_text]="teaser.ctaTitle"></cs-gametile-cta>
                                                </div>
                                            </div>
                                            <div
                                                class="small-terms"
                                                *ngIf="teaser.termsText"
                                                [innerHtml]="teaser.termsText | trustAsHtml"
                                                (click)="termsClick($event, teaser)"
                                                [ngStyle]="{ color: teaser.attributes.termsColor }"></div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="EnableJackpotTickerTeasers">
                                    <div class="md-teaser-body">
                                        <div
                                            class="md-teaser-image"
                                            [ngStyle]="{
                                                'background-image': 'url(' + (teaser.backgroundImage.src | sitecore_image_resize) + ')'
                                            }"></div>
                                        <div class="md-teaser-text">
                                            <img *ngIf="teaser.image" [src]="teaser.image.src" alt="" />
                                            <div class="md-teaser-inner-text">
                                                <div
                                                    class="title"
                                                    [ngStyle]="{ color: teaser.attributes.titleColor }"
                                                    [innerHtml]="teaser.title | trustAsHtml"></div>
                                                <div
                                                    class="subtitle"
                                                    [ngStyle]="{ color: teaser.attributes.subtitleColor }"
                                                    [innerHtml]="teaser.subtitle | trustAsHtml"></div>
                                                <!-- <div class="jackpot-value">
                                                <cc-flip-clock [amount]="teaser.jackpotTickValue"
                                                    [currency]="teaser.jpCurSymbol"></cc-flip-clock>
                                            </div> -->
                                                <!-- *ngIf="teaser.ctaHref" -->
                                                <div class="md-teaser-cta" (click)="ctaClick($event, teaser, i + 1)">
                                                    <!-- <a class="btn btn-primary"
                                                        [ngStyle]="{ color: teaser.attributes.ctaBgColor }"
                                                        [innerHtml]="teaser.ctaTitle | trustAsHtml">
                                                    </a> -->
                                                    <cs-gametile-cta
                                                        [isLegacy]="isLegacy"
                                                        [kind]="'primary'"
                                                        [customTextColor]="teaser.attributes.ctaBgColor"
                                                        [cta_text]="teaser.ctaTitle"></cs-gametile-cta>
                                                </div>
                                            </div>
                                            <div
                                                class="small-terms"
                                                *ngIf="teaser.termsText"
                                                [innerHtml]="teaser.termsText | trustAsHtml"
                                                (click)="termsClick($event, teaser)"
                                                [ngStyle]="{ color: teaser.attributes.termsColor }"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vn-swiper>
        <div class="playbingo displayplaybingo" *ngIf="enablebingoplay && bingocta">
            <!-- <div class="btn btn-primary playbingo-cta" [innerHtml]="bingocta.name | trustAsHtml"
                (click)="playbingoclick(bingocta)"></div> -->
            <cs-gametile-cta
                [isLegacy]="isLegacy"
                [kind]="'primary'"
                [csDecoratorClass]="'playbingo-cta'"
                (click)="playbingoclick(bingocta)"
                [cta_text]="bingocta.name"></cs-gametile-cta>
        </div>
    </div>
</div>
