import { LowerCasePipe, NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { ConfigProviderService, JackpotDataService } from '@casinocore/platform/core';
import { DynamicHtmlDirective, TimerService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cc-opt-teaser-text-position-npm',
    templateUrl: 'opt-teaser-text-position-npm.component.html',
    standalone: true,
    imports: [NgIf, DynamicHtmlDirective, LowerCasePipe],
})
export class OptTeaserTextPositionNpmComponent implements OnInit, OnChanges, OnDestroy {
    @Input() teaser: any;
    @Input() teaserTitle: string;
    @Input() teaserSubTitle: string;
    @Input() verticalPosition: string;
    @Input() teaserVerticalPosition: string;
    //added for Teaser NPM
    @Input() globalTeaserSettings: any;
    @Input() isNPM: boolean;
    @Input() teaserDynaconConfigs: any;
    @Input() showJackpotCurrencyOnRight: any;
    @Input() jackpotGroupNameMapTeaserNPM: any;
    optimizedTeaserClientConfig: any;
    //added for Teaser NPM
    jackpotHandle: Subscription;
    jackpotValue: any = {};
    incrementTickingForJackpotOnGameTiles: any = 1;
    JackpotData: any;
    tickingInterval: any;
    isJackpot: boolean;

    constructor(
        private configProviderService: ConfigProviderService,
        private jackpot: JackpotDataService,
        private timerService: TimerService,
    ) {}

    ngOnInit() {
        if (this.teaser && this.teaser.displayJackpotOnTeaser && this.teaser.jackpotValue && this.teaser.jackpotValue.data.metadata.id.itemName) {
            this.isJackpot = this.teaser.displayJackpotOnTeaser;
            this.setJackPotValue();
            if (!this.isNPM) this.subscribtToJackpotFeed();
        }
    }

    ngOnChanges() {
        if (!this.isNPM) this.JackpotData = this.configProviderService.provideJackpotConfig();
    }

    subscribtToJackpotFeed() {
        this.jackpotHandle = this.jackpot.jackpotFeedObservable.subscribe(() => {
            this.setJackPotValue();
        });
    }
    setJackpotTickers() {
        const config: any = this.isNPM ? this.teaserDynaconConfigs : this.configProviderService.provideOptimizedTeaserConfig();
        if (this.jackpotValue && config?.enableTickingForJackpotOnTeasers) {
            clearInterval(this.tickingInterval);
            this.tickingInterval = this.timerService.setIntervalOutsideAngularZone(() => {
                this.jackpotTicking();
            }, 1500);
        }
    }

    setJackPotValue() {
        if (this.teaser && this.teaser.jackpotValue && this.teaser.jackpotValue.data.metadata.id.itemName) {
            if (this.isNPM) {
                this.jackpotValue = this.jackpotGroupNameMapTeaserNPM.get(this.teaser.data.metadata.id.itemName.toLowerCase());
            } else {
                const jackpotGroupName: string = decodeURI(this.teaser.jackpotValue.data.metadata.id.itemName);
                this.jackpotValue = this.jackpot.getJackpotByGroupName(jackpotGroupName.toLowerCase());
            }
            if (this.jackpotValue) {
                this.setJackpotTickers();
            }
        }
    }

    jackpotTicking() {
        let currency: any = '';
        let valueStr = this.jackpotValue.amount;
        if (valueStr) {
            valueStr = valueStr.replace(/\&nbsp;/g, '');
            const initialcurrency = valueStr.match(/[^\d\.\,\s]/g);
            if (initialcurrency && initialcurrency.length > 0) {
                for (let i = 0; i < initialcurrency.length; i++) {
                    currency = currency + initialcurrency[i];
                }
                currency = currency.replace(/\,/g, '');
                currency = currency.replace(/\./g, '');
            }
            valueStr = valueStr.replace(/\ /g, '');
            valueStr = valueStr.replace(/\,/g, '');
            valueStr = valueStr.replace(/\./g, '');
            valueStr = valueStr.replace(currency, '');
            valueStr = valueStr.replace(/\s/g, '');
            valueStr = valueStr.match(/\b[\d, ]+\b/g)[0];
            if (valueStr.length > 2) {
                const realpart = valueStr.slice(0, valueStr.length - 2);
                const decimalpart = valueStr.slice(-2);
                valueStr = realpart + '.' + decimalpart;
            }

            let jackpotTickValue: any = parseFloat(valueStr.replace(/,/g, ''));
            if (this.isNPM) {
                this.incrementTickingForJackpotOnGameTiles = +this.globalTeaserSettings.sharedList.IncrementTickingForJackpotOnGameTiles;
            } else {
                const globalClientConfig = this.configProviderService.provideGlobalConfig();
                if (globalClientConfig.lobbyConfigurations.IncrementTickingForJackpotOnGameTiles) {
                    this.incrementTickingForJackpotOnGameTiles = +globalClientConfig.lobbyConfigurations.IncrementTickingForJackpotOnGameTiles;
                }
            }
            jackpotTickValue = jackpotTickValue + this.incrementTickingForJackpotOnGameTiles * 0.01;
            jackpotTickValue = this.formatcurrency(jackpotTickValue.toFixed(2));
            if ((!this.isNPM && this.JackpotData && this.JackpotData.showJackpotCurrencyOnRight) || (this.isNPM && this.showJackpotCurrencyOnRight)) {
                jackpotTickValue = jackpotTickValue.replace(/[,.]/g, (m: any) => (m === ',' ? '.' : ','));
                this.jackpotValue.amount = jackpotTickValue + ' ' + currency + '.';
            } else {
                this.jackpotValue.amount = currency + ' ' + jackpotTickValue;
            }
        }
    }

    formatcurrency(value: any) {
        const inputarr = value.toString().split('.');
        const realpart = inputarr[0];
        const decimalpart = inputarr[1];
        let amt = '';
        for (let i = realpart.length; i > 0; i = i - 3) {
            if (i - 3 > 0) {
                amt = ',' + realpart.substring(i - 3, i) + amt;
            } else {
                amt = realpart.substring(0, i) + amt;
            }
        }
        return amt + '.' + decimalpart;
    }

    ngOnDestroy() {
        if (!this.isNPM && this.jackpotHandle) this.jackpotHandle.unsubscribe();
    }
}
