import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, signal } from '@angular/core';

import { ConfigProviderService, RecentGamesService, SignPostingService } from '@casinocore/platform/core';
import { DeviceService, UserService } from '@frontend/vanilla/core';

@Component({
    selector: 'cc-sign-posting',
    templateUrl: 'sign-posting.component.html',
    standalone: true,
    imports: [NgIf, NgClass],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignPostingComponent implements OnInit, OnChanges, OnDestroy {
    @Input() category: any;
    @Input() isDisplayBadge: boolean = true;
    @Output() signPostingCountValue: EventEmitter<any> = new EventEmitter<any>();
    signPostingGamesCount = signal(0);

    enableEpcotSubNavigation: boolean;

    recentObs: any;
    isMobileWidth = this.deviceService.isMobileWidth;

    casinoConfig: any;
    isTouch = this.deviceService.isTouch;
    constructor(
        private recentGameService: RecentGamesService,
        private signPostingService: SignPostingService,
        private configProviderService: ConfigProviderService,
        private deviceService: DeviceService,
        private user: UserService,
    ) {}
    ngOnInit() {
        this.casinoConfig = this.configProviderService.provideCasinoConfig();
        const subnavConfig: any = this.configProviderService.provideSubNavigationConfig();

        this.recentObs = this.recentGameService.recentGamesFeedObservable.subscribe((response: any) => {
            if (response) {
                if (this.category && this.category.gamelist && this.category.gamelist.length > 0) {
                    let categoryGames = this.category.gamelist;
                    categoryGames = this.gymlAndrpCount(categoryGames, response);
                    this.signPostingGamesCount.set(this.signPostingService.getSignPostingCount(categoryGames, response));
                    this.signPostingCountValue.emit(this.signPostingGamesCount());
                }
            }
        });
        this.enableEpcotSubNavigation = subnavConfig.enableEpcotSubNavigation;
    }
    ngOnChanges(changes: SimpleChanges) {
        //loop through category.gamelist fetch if any game has 'LMS_LATEST'
        if (changes.category && this.category && this.category.gamelist && this.category.gamelist.length > 0) {
            const recentGames = this.recentGameService.getrecentGames();
            let categoryGames = this.category.gamelist;
            categoryGames = this.gymlAndrpCount(categoryGames, recentGames);
            this.signPostingGamesCount.set(this.signPostingService.getSignPostingCount(categoryGames, recentGames));
            this.signPostingCountValue.emit(this.signPostingGamesCount());
        }
    }
    gymlAndrpCount(categoryGames: any, response: any) {
        this.casinoConfig = this.configProviderService.provideCasinoConfig();
        if (this.category.categoryid === 'LMC_GYML') {
            let totalRecentGamesCount: number = 0;
            if (response && response.length > 0) {
                totalRecentGamesCount += response.length;
            }
            if (totalRecentGamesCount >= this.casinoConfig.recentGamesCountLimit) {
                categoryGames = [];
            }
            if (categoryGames.length > 0) {
                let filteredLobbyTypeArray: any = [];

                categoryGames.forEach((item: any) => {
                    if (response && response.length > 0 && response.indexOf(item.game) == -1) {
                        filteredLobbyTypeArray.push(item);
                    }
                });
                if (filteredLobbyTypeArray && filteredLobbyTypeArray.length == 0) {
                    filteredLobbyTypeArray = categoryGames;
                }
                if (this.category.gamelist.length > this.casinoConfig.gymlLimit && this.user.isAuthenticated) {
                    categoryGames = filteredLobbyTypeArray.slice(0, this.casinoConfig.gymlLimit);
                } else if (this.category.gamelist.length + totalRecentGamesCount > this.casinoConfig.gymlLimit && !this.user.isAuthenticated) {
                    if (totalRecentGamesCount < this.casinoConfig.gymlLimit) {
                        categoryGames = filteredLobbyTypeArray.slice(0, this.casinoConfig.gymlLimit - totalRecentGamesCount);
                    }
                }
            }
        }
        return categoryGames;
    }

    ngOnDestroy() {
        if (this.recentObs) {
            this.recentObs.unsubscribe();
        }
    }
}
