import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { ConfigProviderService, CookieService, SlotRacesService } from '@casinocore/platform/core';
import { UserService } from '@frontend/vanilla/core';
import { rxEffects } from '@rx-angular/state/effects';
import { parseInt } from 'lodash-es';

@Component({
    selector: 'cc-coineconomy-component',
    templateUrl: 'coin-economy-animation.component.html',
    standalone: true,
    imports: [NgIf],
})
export class CoinEconomyAnimationComponent implements OnInit {
    private readonly rxEffects = rxEffects();

    showCoinShower: boolean = false;
    coinShowerImage: string;

    constructor(
        private configProviderService: ConfigProviderService,
        private cookieService: CookieService,
        private user: UserService,
        private slotracesService: SlotRacesService,
    ) {}

    ngOnInit() {
        this.coinShower();
        this.rxEffects.register(this.slotracesService.coinDetails, (response: boolean) => {
            if (response) {
                this.showCoinShower = false;
                this.coinShower();
                this.cookieService.setCookie('COIN_SHOWER', 'true', 30);
            }
        });
    }
    coinShower() {
        if (this.cookieService.getCookie('COIN_SHOWER')) return;
        if (this.user.isAuthenticated) this.cookieService.setCookie('COIN_SHOWER', 'true', 30);
        this.showCoinShower = true;
        const globalConfig: any = this.configProviderService.provideGlobalConfig();
        const CoinShowerTimeFrame = parseInt(globalConfig?.messages['CoinShowerTimeFrame'] || '0');
        this.coinShowerImage = globalConfig?.messages['CoinShowerImage'];
        setTimeout(() => {
            this.showCoinShower = false;
        }, CoinShowerTimeFrame);
    }
}
