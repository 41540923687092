import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csGlobal', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: globalClientConfigFactory,
})
export class GlobalClientConfig {
    messages: any;
    unfinishedTooltip: any;
    unfinishedStyle: any;
    lobbyConfigurations: any;
    arcadeLobbyConfigurations: any;
    gameDescriptionMapping: any;
    globalJackpotEnabled: boolean;
    takeoverStaticImage: any;
    stickerStyles: any;
    freespinsCountBackgroundColor: string;
    jackpotDisplayNameConfigurations: any;
    moreInfoConfiguration: {
        HighVolatilityText: string;
        High: string;
        HighVolatilityImagelink: string;
        MildVolatilityText: string;
        Low: string;
        MildVolatilityImagelink: string;
        RegVolatilityHeaderText: string;
        Medium: string;
        RegVolatilityImagelink: string;
        MaxPayout: string;
        GameProviderHeader: string;
        RelatedGamesText: string;
        MaxPayoutText: string;
        ReelsText: string;
        LinesText: string;
        HowItWorksLink: string;
        GotItCta: string;
        HighVolatilityDescription: string;
        MildVolatilityDescription: string;
        RegVolatilityText: string;
        RegVolatilityDescription: string;
        stickerStyles: any;
    };
}

export function globalClientConfigFactory(service: LazyClientConfigService) {
    return service.get(GlobalClientConfig);
}
