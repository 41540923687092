import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

import { IPathBasedEnableORDisable } from '../shared/models/common.model';

@LazyClientConfig({ key: 'csCasinoGlobalSearch', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: globalSearchClientConfigFactory,
})
export class GlobalSearchClientConfig {
    isEnabled: boolean;
    bootstrapOrigin: string;
    bootstraptimeout: number;
    pathBasedEnabler: IPathBasedEnableORDisable;
}

export function globalSearchClientConfigFactory(service: LazyClientConfigService) {
    return service.get(GlobalSearchClientConfig);
}
