import { Injectable } from '@angular/core';

import { AppInfoConfig, Page, ProductHomepagesConfig, ProductService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
})
export class UrlUtilityService {
    isDev: boolean;
    devUrl: string;

    constructor(
        private products: ProductHomepagesConfig,
        private page: Page,
        private appInfoConfig: AppInfoConfig,
        private productService: ProductService,
    ) {}

    getCasinoBaseUrl = (): string => this.getUrl('casino') || '';

    getProductBaseUrl = (productOverride?: string) => this.getUrl('', true, productOverride);

    getProductApiBaseUrl = (productOverride?: string): string => this.getProductBaseUrl(productOverride) + '/' + this.page.lang;

    getApiBaseUrl = (): string => this.getCasinoBaseUrl() + '/' + this.page.lang;

    getCasinoApiBaseUrl = (): string => this.productService.getMetadata('casino').apiBaseUrl;

    getClientConfigUrl = () => `${this.getCasinoApiBaseUrl()}/${this.page.lang}/api/clientconfig`;

    getCategoryNavigationBaseUrl = (): string => this.getUrl('casino') || '';

    getBingoApiBaseUrl = (): string => this.getUrl('bingo') + '/' + this.page.lang;

    getPromoSmartUrl = (): string => `${this.getUrl('promo')}/${this.page.lang}`;

    getDiceSmartUrl = (): string => `${this.getUrl('dicegames')}/${this.page.lang}`;

    getCasinoSmartUrl = (): string => `${this.getUrl('casino')}/${this.page.lang}`;

    getGrayImageUrl = (): string => `${this.getCasinoBaseUrl()}/pictures/grey.jpg`;

    private getUrl = (type: string, isProduct: boolean = false, productOverride?: string) => {
        const product: any = isProduct && productOverride ? productOverride : isProduct ? this.appInfoConfig?.product?.toLowerCase() : type;
        const productUrl = new URL(this.products[product as keyof ProductHomepagesConfig]);
        return productUrl.origin;
    };
}
