<div class="casino-overlay-search" (window:resize)="onResize()">
    <div class="casino-overlay-search-content">
        <div class="search-query">
            <span class="theme-search"></span>
            <input
                #searchInput
                id="serachTxt"
                name="searchText"
                type="text"
                (change)="queryChanged()"
                [(ngModel)]="model.query"
                (keyup)="queryChanged()"
                (focusout)="trackInput()"
                (keydown)="onEvent($event)"
                placeholder="{{ SearchProductForGamingOverlay }}"
                autocomplete="off" />

            <span class="close-button theme-close-i" *ngIf="SearchCloseIcon" (click)="closeSearchIcon()"></span>
        </div>
    </div>

    <div class="casino-overlay-search-results">
        <ul [hidden]="!(model.results.length > 0)">
            <li *ngFor="let game of model.results | dsl | async; let i = index" (click)="launchGame(game, i)">
                <img src="{{ game.icon.src }}" />
                <span class="search-result-title" [innerHTML]="game.title | highlightsearch: model.query"></span>
            </li>
        </ul>

        <p [hidden]="!isMsgVisible">
            <cc-global-translate [key]="'SearchNoResults'"></cc-global-translate>
        </p>
    </div>
    <div *ngIf="displaySearchOverlay" class="overlay"></div>
</div>
