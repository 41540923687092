import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle, SlicePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';

import {
    CasinoCoreGamelaunchService,
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    CurrencyFormatPipe,
    CurrencySymbolPipe,
    FavouriteService,
    PlayerStatsService,
    PlayerStatsWidgetClientConfig,
    UnfinishedGamesService,
} from '@casinocore/platform/core';
import { elementScroll } from '@casinocore/platform/utils';
import { DeviceService } from '@frontend/vanilla/core';
import { DslPipe } from '@frontend/vanilla/shared/browser';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Subject, takeUntil } from 'rxjs';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { CasinoCoreLazyPlaceholderComponent } from '../casino-core-lazy-placeholder/casino-core-lazy-placeholder.component';
import { GameLobbyComponent } from '../gamelobby/gamelobby.component';

@Component({
    selector: 'cc-player-stat-filters',
    templateUrl: 'player-stat-filters.component.html',
    standalone: true,
    imports: [
        NgClass,
        CasinoCoreLazyPlaceholderComponent,
        NgIf,
        NgFor,
        NgStyle,
        GameLobbyComponent,
        AsyncPipe,
        SlicePipe,
        DslPipe,
        CurrencySymbolPipe,
        SwiperComponent,
        CurrencyFormatPipe,
    ],
})
export class PlayerStatFiltersComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() category: any;
    @ViewChild('swiperRef', { static: false }) swiperRef?: SwiperComponent;
    @ViewChild('StatsNavContainer', { static: false })
    StatsNavContainer: ElementRef<HTMLDivElement>;
    @ViewChild('statsWidgetMobile', { static: false })
    statsWidgetMobile: ElementRef<HTMLDivElement>;
    parallaxInput: any;
    ParallaXInputsecond: any;
    displayFilterName: string = '';
    filters: any;
    isTouch: boolean;
    isMobile: boolean;
    isMobileView: boolean;
    isTabView: boolean;
    isIpadPro: boolean;
    leftFilterScrollButton: boolean;
    rightFilterScrollButton: boolean;
    playerstatsSitecoreFilterNameData: any;
    subtitleData: any;
    titleData: any;
    selectedTab: any;
    titleDisplay: any;
    subtitleDisplay: any;
    statsSitecoreData: any;
    categoryInfo: any;
    playerStatsData: any = [];
    displayPlayerStatsWidget: boolean;
    swiperOptionsPlayerStats: SwiperOptions = {
        modules: [Navigation, Pagination, Autoplay],
        loop: false,
        autoHeight: true,
        allowTouchMove: false,
        spaceBetween: 15,
        direction: 'horizontal',
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        centeredSlides: false,
        autoplay: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            enabled: false,
        },
        keyboard: true,
        watchOverflow: true,
        mousewheel: false,
        scrollbar: false,
        preventClicks: false,
        preventClicksPropagation: false,
    };
    statsCarousaulBgcolor: string;
    multiplierElement: string;
    multiplierBgColor: string;
    multiplierColor: string;
    multiplierBorderColor: string;
    displayFilterNameFontsize: string;
    displayFilterNameFontweight: string;
    filterTextFontsize: string;
    filterTextFontweight: string;
    subtitleFontsize: string;
    subtitleFontweight: string;
    titleFontsize: string;
    titleFontweight: string;
    filterTextcolor: string;
    filterCarousalIconColor: string;
    statsWigetCarousalIconColor: string;
    mobileDisplayfilterNamefontsize: string;
    mobileDisplayfilterNamefontweight: string;
    mobileSubtitlefontsize: string;
    mobileSubtitlefontweight: string;
    mobileTitlefontSize: string;
    mobileTitlefontweight: string;
    slidesCenterAlign: boolean;
    private destroy$ = new Subject<void>();
    feedRequests: any = [];
    prevActiveIndex: number = 0;
    minimumMultiplierBet: number;
    filterSwitched: boolean;
    isPlayerStatsLoaded: boolean;
    PrevioustrackingArray: any = [];
    isTrackingUpadateRequired: boolean;
    constructor(
        private deviceService: DeviceService,
        private configProviderService: ConfigProviderService,
        private casinoManager: CasinoManager,
        private casinoLobbyService: CasinoLobbyService,
        private playerStatsService: PlayerStatsService,
        private changeDetectorRef: ChangeDetectorRef,
        private casinoCoreGamelaunchService: CasinoCoreGamelaunchService,
        private unfinishedGamesService: UnfinishedGamesService,
        private favouriteService: FavouriteService,
    ) {}

    ngOnInit(): void {
        const playerstatsconfig: PlayerStatsWidgetClientConfig = this.configProviderService.providePlayerStatsWidgetConfig();
        this.statsSitecoreData = playerstatsconfig?.playerStatsSitecoreData;
        this.playerstatsSitecoreFilterNameData = playerstatsconfig?.playerStatsSitecoreData?.playerStatsWidgetFilterNameConfigs;
        this.subtitleData = playerstatsconfig?.playerStatsSitecoreData?.playerStatsWidgetSubtitleConfigs;
        this.titleData = playerstatsconfig?.playerStatsSitecoreData?.playerStatsWidgetTitleConfigs;
        this.statsCarousaulBgcolor = playerstatsconfig?.playerStatsSitecoreData?.statsWigetCarousalIconBgColor;
        this.multiplierElement = playerstatsconfig?.playerStatsSitecoreData?.multiplierComponent;
        this.multiplierBgColor = playerstatsconfig?.playerStatsSitecoreData?.multiplierBgColor;
        this.multiplierColor = playerstatsconfig?.playerStatsSitecoreData?.multiplierColor;
        this.multiplierBorderColor = playerstatsconfig?.playerStatsSitecoreData?.multiplierBorderColor;
        this.displayFilterNameFontsize = playerstatsconfig?.playerStatsSitecoreData?.displayfilterNamefontsize;
        this.displayFilterNameFontweight = playerstatsconfig?.playerStatsSitecoreData?.displayfilterNamefontweight;
        this.filterTextFontsize = playerstatsconfig?.playerStatsSitecoreData?.filterTextfontSize;
        this.filterTextFontweight = playerstatsconfig?.playerStatsSitecoreData?.filterTextfontweight;
        this.subtitleFontsize = playerstatsconfig?.playerStatsSitecoreData?.subtitlefontsize;
        this.subtitleFontweight = playerstatsconfig?.playerStatsSitecoreData?.subtitlefontweight;
        this.titleFontsize = playerstatsconfig?.playerStatsSitecoreData?.titlefontSize;
        this.filterTextcolor = playerstatsconfig?.playerStatsSitecoreData?.filterTextColor;
        this.titleFontweight = playerstatsconfig?.playerStatsSitecoreData?.titlefontweight;
        this.statsWigetCarousalIconColor = playerstatsconfig?.playerStatsSitecoreData?.statsWigetCarousalIconColor;
        this.filterCarousalIconColor = playerstatsconfig?.playerStatsSitecoreData?.filterCarousalIconColor;
        this.mobileDisplayfilterNamefontsize = playerstatsconfig?.playerStatsSitecoreData?.mobileDisplayfilterNamefontsize;
        this.mobileDisplayfilterNamefontweight = playerstatsconfig?.playerStatsSitecoreData?.mobileDisplayfilterNamefontweight;
        this.mobileSubtitlefontsize = playerstatsconfig?.playerStatsSitecoreData?.mobileSubtitlefontsize;
        this.mobileSubtitlefontweight = playerstatsconfig?.playerStatsSitecoreData?.mobileSubtitlefontweight;
        this.mobileTitlefontSize = playerstatsconfig?.playerStatsSitecoreData?.mobileTitlefontSize;
        this.mobileTitlefontweight = playerstatsconfig?.playerStatsSitecoreData?.mobileTitlefontweight;
        this.minimumMultiplierBet = playerstatsconfig?.minimumMuliplierBet;
        this.checkDeviceType();
        this.ParallaXInputsecond = {
            sitecoreData: {
                playerStatsWidgetMultiplier: playerstatsconfig?.playerStatsWidgetImagesContent?.playerStatsWidgetMultiplier,
            },
        };
        this.parallaxInput = {
            sitecoreData: {
                backgroundImageLayer1: playerstatsconfig?.playerStatsWidgetImagesContent?.backgroundImageLayer1,
                backgroundImageLayer2: playerstatsconfig?.playerStatsWidgetImagesContent?.backgroundImageLayer2,
                backgroundImageLayer3: playerstatsconfig?.playerStatsWidgetImagesContent?.backgroundImageLayer3,
            },
        };
        this.filters = playerstatsconfig?.playerStatFilters?.map((item: any) => {
            this.feedRequests.push(item.value);
            return {
                ...item,
                firstValue: this.playerstatsSitecoreFilterNameData[item.value]?.split(',')[0]
                    ? this.playerstatsSitecoreFilterNameData[item.value]?.split(',')[0]
                    : item.name,
                displayName:
                    this.playerstatsSitecoreFilterNameData[item.value]?.split(',')?.length > 1
                        ? this.playerstatsSitecoreFilterNameData[item.value]?.split(',')[1]
                        : item.name,
                subtitleName: this?.subtitleData[item.value] ? this.subtitleData[item.value] : undefined,
                title: this?.titleData[item.value] ? this.titleData[item.value] : undefined,
                playerStats: [],
                displayFilter: false,
            };
        });
        if (!this.playerStatsService.isFeedRequestRecieved) {
            this.playerStatsService.fetchPlayerStatsFeed(this.feedRequests);
        }
        this.getPlayerStats();
    }
    @HostListener('window:resize') onResize() {
        this.checkDeviceType();
        this.adjustfiltersContainerScroll();
        if (!this.isMobile) {
            this.assignswiperComponent();
        } else {
            this.adjustmultipliercontent();
        }
    }
    checkDeviceType(): void {
        this.isTouch = this.casinoManager.isTouch();
        this.isMobileView = window.innerWidth <= 767 || document.body.offsetWidth <= 767;
        this.isTabView =
            (window.innerWidth >= 768 || document.body.offsetWidth >= 768) && (window.innerWidth <= 1024 || document.body.offsetWidth <= 1024)
                ? true
                : false;
        this.isIpadPro =
            (window.innerWidth >= 1024 || document.body.offsetWidth >= 1024) && (window.innerWidth <= 1366 || document.body.offsetWidth <= 1366)
                ? true
                : false;
        if (this.isTouch && (this.isMobileView || this.isTabView || this.isIpadPro)) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }
    }
    onResized(): void {}
    getPlayerStats() {
        this.playerStatsService.playerstatsObservable.pipe(takeUntil(this.destroy$)).subscribe({
            next: (responses) => {
                if (responses) {
                    this.getLatestGameMetaDataLMT(responses);
                }
            },
        });
    }
    getLatestGameMetaDataLMT(playerStatsFeed: any) {
        this.casinoLobbyService.gameMetaDataPlayerStatsObservable.pipe(takeUntil(this.destroy$)).subscribe({
            next: (gameMataData) => {
                if (gameMataData) {
                    this.mapStatsData(playerStatsFeed);
                }
            },
        });
    }
    mapStatsData(responses: any) {
        this.filters.forEach((item: any, i: any) => {
            this.playerStatsData = [];
            if (responses[i]?.response?.winnerMultiplierDetails) {
                this.filterGamesArray(responses[i]?.response?.winnerMultiplierDetails, item.displayName);
            }
            item.playerStats = this.playerStatsData;
            if (!item.displayFilter) item.displayFilter = this.displayPlayerStatsFeed();
        });
        if (this.displayPlayerStatsWidget) {
            this.initialDisplayStats();
            if (this.isTrackingUpadateRequired) {
                this.addTrackingGameImpression();
                this.isTrackingUpadateRequired = false;
            }
        }
    }
    initialDisplayStats() {
        const initialState = this.selectedTab
            ? this.filters.find((item: any) => item.value === this.selectedTab)
            : this.filters.find((item: any) => item.displayFilter === true);
        if (initialState) {
            this.playerStatsData = initialState.playerStats;
            this.filterSwitched = true;
            this.changeDetectorRef.detectChanges();
            this.filterSwitched = false;
            this.selectedTab = initialState.value;
            this.displayFilterName = initialState.displayName;
            this.titleDisplay = initialState.title;
            this.subtitleDisplay = initialState.subtitleName;
            this.changeDetectorRef.detectChanges();
            this.adjustfiltersContainerScroll();
            this.slidesCenterAlign = false;
            if (!this.isMobile) {
                this.assignswiperComponent();
            } else {
                this.adjustmultipliercontent();
                if ((this.isIpadPro || this.isTabView) && this.playerStatsData.length == 2) {
                    this.slidesCenterAlign = true;
                }
            }
            if (!this.isPlayerStatsLoaded) {
                this.setSwiperNavigation();
                this.isPlayerStatsLoaded = true;
            }
        }
    }
    setSwiperNavigation() {
        if (
            this.swiperRef?.swiperRef?.nativeElement?.querySelector('.swiper-button-prev') &&
            this.swiperRef?.swiperRef?.nativeElement?.querySelector('.swiper-button-next')
        ) {
            (this.swiperRef?.swiperRef.nativeElement?.querySelector('.swiper-button-prev') as HTMLElement).style.background =
                this.statsCarousaulBgcolor;
            (this.swiperRef?.swiperRef.nativeElement?.querySelector('.swiper-button-prev') as HTMLElement).style.color =
                this.statsWigetCarousalIconColor;
            (this.swiperRef?.swiperRef.nativeElement?.querySelector('.swiper-button-next') as HTMLElement).style.background =
                this.statsCarousaulBgcolor;
            (this.swiperRef?.swiperRef.nativeElement?.querySelector('.swiper-button-next') as HTMLElement).style.color =
                this.statsWigetCarousalIconColor;
            this.swiperRef?.swiper?.on('navigationNext', () => {
                if (this.playerStatsData.length > 4) {
                    const previousIndex =
                        this.swiperRef?.swiper?.previousIndex && this.swiperRef?.swiper?.previousIndex > 0
                            ? this.swiperRef?.swiper?.previousIndex
                            : 0;
                    this.swiperRef?.swiper?.slideTo(previousIndex, 0);
                    this.handleSwiperNavigation('next');
                }
                this.trackSwiperNavigation('Next');
            });
            this.swiperRef?.swiper?.on('navigationPrev', () => {
                if (this.playerStatsData.length > 4) {
                    const previousIndex =
                        this.swiperRef?.swiper?.previousIndex && this.swiperRef?.swiper?.previousIndex > 0
                            ? this.swiperRef?.swiper?.previousIndex
                            : 0;
                    this.swiperRef?.swiper?.slideTo(previousIndex, 0);
                    this.handleSwiperNavigation('prev');
                }
                this.trackSwiperNavigation('Prev');
            });
        }
    }
    FilterType(item: any, index: any) {
        this.filterSwitched = true;
        this.changeDetectorRef.detectChanges();
        this.filterSwitched = false;
        this.selectedTab = item.value;
        this.displayFilterName = item.displayName;
        this.titleDisplay = item.title;
        this.subtitleDisplay = item.subtitleName;
        this.playerStatsData = item.playerStats;
        this.changeDetectorRef.detectChanges();
        this.slidesCenterAlign = false;
        if (!this.isMobile) {
            this.assignswiperComponent();
            this.swiperRef?.swiper?.slideTo(0, 0);
        } else {
            this.adjustmultipliercontent();
            if ((this.isIpadPro || this.isTabView) && this.playerStatsData.length == 2) {
                this.slidesCenterAlign = true;
            }
        }
        this.playerStatsService.addTrackingEvent(
            'casino widgets',
            'multiplier widget',
            'click',
            index,
            this.category?.categoryname,
            this.selectedTab,
            'not applicable',
        );
    }
    displayPlayerStatsFeed() {
        if (this.playerStatsData.length >= 2) {
            if (!this.displayPlayerStatsWidget) {
                this.displayPlayerStatsWidget = true;
            }
            return true;
        } else {
            return false;
        }
    }
    ngOnDestroy(): void {
        this.endSubscription();
    }
    endSubscription(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngAfterViewInit() {
        this.adjustfiltersContainerScroll();
        if (!this.isMobile) {
            this.assignswiperComponent();
        } else {
            this.adjustmultipliercontent();
        }
    }
    assignswiperComponent() {
        let allSwiperSlides = this.swiperRef?.swiperRef?.nativeElement?.querySelectorAll('.swiper-slide') as HTMLElement[];
        if (allSwiperSlides) {
            allSwiperSlides.forEach((swiperSlide: HTMLElement) => {
                if (this.playerStatsData.length > 2) {
                    if (swiperSlide.querySelector('.tile-0') && !swiperSlide.classList.contains('swiper-large')) {
                        swiperSlide.classList.add('swiper-large');
                    } else {
                        swiperSlide.style.width = '190px';
                    }
                    this.swiperRef?.swiper?.update();
                } else if (this.playerStatsData.length == 2 && !swiperSlide.classList.contains('swiper-large')) {
                    swiperSlide.classList.add('swiper-large');
                    this.swiperRef?.swiper?.update();
                }
            });
        }
        this.adjustmultipliercontent();
    }
    private adjustfiltersContainerScroll(): void {
        let offsetLeft = 0;
        const itemWidth = this.StatsNavContainer?.nativeElement?.querySelector('.filter-stats') as HTMLElement;
        if (itemWidth) {
            if (!this.StatsNavContainer?.nativeElement?.scrollLeft) {
                const { showLeft, showRight } = elementScroll(this.StatsNavContainer?.nativeElement, offsetLeft, false, 0, event);
                this.leftFilterScrollButton = showLeft;
                this.rightFilterScrollButton = showRight;
            }
        }
    }
    filterGamesArray(gamedata: any, currentTab: any) {
        const filterList: any = [];
        if (gamedata) {
            gamedata.forEach((item: any, index: any) => {
                const gameInfo = this.casinoLobbyService.getGameInfo(item?.gameVariant);
                let multiplier = Math.floor(item?.multiplier);
                if (item.setData) {
                    item.multiplierDisplay = '';
                    filterList.push(item);
                } else if (gameInfo && multiplier && multiplier >= this.minimumMultiplierBet) {
                    item.multiplier = multiplier;
                    item.index = index;
                    item = Object.assign(item, gameInfo);
                    item.gameId = item.gameVariant;
                    item.name = gameInfo?.name;
                    item.widgetName = 'PlayerStats';
                    item.currentTab = currentTab;
                    item.category = this.categoryInfo?.categoryid;
                    item.lobbyType = this.casinoManager.getLobbyType();
                    item.multiplierDisplay = '';
                    item.setData = true;
                    filterList.push(item);
                    if (!this.isTrackingUpadateRequired) {
                        this.isTrackingUpadateRequired = true;
                    }
                }
            });
            this.playerStatsData = this.sortByMuliplier(filterList);
            this.assaigngametileclassname();
        }
    }
    assaigngametileclassname() {
        const statsDataLength = this.playerStatsData.length;
        if (statsDataLength >= 2) {
            this.playerStatsData.forEach((item: any, index: any) => {
                if (statsDataLength == 2) {
                    item.icon = '2';
                    const currentWidth = this.casinoManager.getCurrentWidth();
                    item.infoIconClass = this.casinoManager.assignGameClass(currentWidth, item.icon);
                } else {
                    if (index == 0) {
                        item.icon = '2';
                        const currentWidth = this.casinoManager.getCurrentWidth();
                        item.infoIconClass = this.casinoManager.assignGameClass(currentWidth, item.icon);
                    } else {
                        item.icon = '1';
                        const currentWidth = this.casinoManager.getCurrentWidth();
                        item.infoIconClass = this.casinoManager.assignGameClass(currentWidth, item.icon);
                    }
                }
            });
        }
    }
    sortByMuliplier(data: any) {
        data.sort((a: any, b: any) => {
            const valueA = a['multiplier'] || 0;
            const valueB = b['multiplier'] || 0;
            return (valueA - valueB) * -1;
        });
        return data;
    }
    ngOnChanges(): void {
        const categoryInfoData = this.casinoLobbyService.getCategoryInfo(this.casinoManager.lobbyType, this.category?.categoryid);
        this.categoryInfo = categoryInfoData?.categoryInfo || {};
    }
    adjustmultipliercontent() {
        let swiperDom: any;
        let largeGameTileWidth: any;
        let smallGameTileWidth: any;
        const multiplierContentWidth = 17;
        if (this.isMobile) {
            swiperDom = this.statsWidgetMobile;
            largeGameTileWidth = 290;
            smallGameTileWidth = 145;
        } else {
            swiperDom = this.swiperRef?.swiperRef;
            largeGameTileWidth = 395;
            smallGameTileWidth = 190;
        }
        let gameTileWidth: any = smallGameTileWidth;
        this.playerStatsData.forEach((item: any, index: any) => {
            if (item.icon == '2') {
                gameTileWidth = largeGameTileWidth;
            } else {
                gameTileWidth = smallGameTileWidth;
            }
            const fittingDigits = Math.floor(gameTileWidth / multiplierContentWidth);
            let multiplier = item.multiplier + '';
            item.multiplierDisplay = multiplier?.slice(0, fittingDigits - 1)?.split('');
            if (this.multiplierElement) item?.multiplierDisplay?.push(this.multiplierElement);
            let eachMultiplierClass = '.digits-' + index;
            if (swiperDom?.nativeElement?.querySelector(eachMultiplierClass))
                (swiperDom?.nativeElement?.querySelector(eachMultiplierClass) as HTMLElement).style.width =
                    17 * item?.multiplierDisplay?.length - 1 + 'px';
        });
    }
    handleSwiperNavigation(button: any) {
        if (this.swiperRef) {
            const activeIndex =
                this.swiperRef?.swiper?.activeIndex && this.swiperRef?.swiper?.activeIndex > 0 ? this.swiperRef?.swiper?.activeIndex : 0;
            let slidePerPage = activeIndex == 0 ? 3 : 4;
            this.prevActiveIndex = button == 'next' ? activeIndex : this.prevActiveIndex;
            let startIndex =
                button == 'next'
                    ? activeIndex + slidePerPage
                    : this.swiperRef?.swiper?.isEnd
                      ? this.prevActiveIndex
                      : Math.max(activeIndex - slidePerPage, 0);
            this.swiperRef?.swiper?.slideTo(startIndex);
        }
    }
    onMoveFilterContainer(isLeft: boolean, event: Event) {
        const { showLeft, showRight } = elementScroll(this.StatsNavContainer?.nativeElement, 148, isLeft, 0, event);
        this.leftFilterScrollButton = showLeft;
        this.rightFilterScrollButton = showRight;
    }
    private trackSwiperNavigation(eventDetails: 'Next' | 'Prev'): void {
        this.playerStatsService.addTrackingEvent(
            'casino widgets',
            'multiplier widget',
            'click',
            'not applicable',
            this.category?.categoryname,
            eventDetails,
            'not applicable',
        );
    }
    private addTrackingGameImpression(): void {
        let trackingArray: any = [];
        this.filters.forEach((item: any) => {
            if (item.displayFilter === true) {
                item.playerStats.forEach((element: any, index: any) => {
                    if (element) {
                        let catType: string = '';
                        let catInfo: any;
                        let catEngName: any;
                        if (element?.lobbyType) {
                            catType = this.casinoCoreGamelaunchService.getProductForLobbyType(element?.lobbyType);
                        }
                        if (catType && element?.lobbyType) {
                            catInfo = this.casinoLobbyService.getCategoryInfo(element?.lobbyType, this.category?.categoryid);
                        }
                        let parentCategoryId = catInfo?.categoryInfo?.parentCategoryId
                            ? catInfo?.categoryInfo?.parentCategoryId
                            : catInfo?.categoryInfo?.categoryid;
                        if (parentCategoryId && element?.lobbyType) {
                            catEngName = this.casinoLobbyService.getCategoryName(element?.lobbyType, parentCategoryId);
                        }
                        element.categoryType = this.category?.categoryid;
                        element.gridPosition = this.category?.categoryIndex;
                        let trackingGridPosition: any = this.casinoCoreGamelaunchService.calculateGridPosition(element);
                        let trackingObj = {
                            'component.gameName': element?.gameId,
                            'component.gameDisplayName': element?.name
                                ? element?.name
                                : this.casinoLobbyService.getGameDisplayName(element?.gameId) || element?.gameVariantName,
                            'component.gameType': element?.provider,
                            'component.Game.section': 'multiplier widget',
                            'component.categoryType': catType,
                            'component.categorySubType': catEngName || 'multiplier widget',
                            'component.Game.GridPosition': trackingGridPosition
                                ? index
                                    ? `${trackingGridPosition}:${index + 1}`
                                    : `${trackingGridPosition}:1`
                                : index
                                  ? `1:${index + 1}`
                                  : '1:1',
                            'component.gameContainerLocation': 'multiplier widget- ' + element?.currentTab,
                            'component.gameFavourite': this.favouriteService.checkForFavouriteGame(element) ? 'Favourite' : 'Non_favourite',
                            'component.gamePlayingStatus': this.unfinishedGamesService.checkForUnfinishedGame(element) ? 'Unfinished' : 'Start',
                            'component.Game.position': index + 1,
                            'component.recommendationtype': catInfo?.categoryInfo?.havingCasiaGames ? 'Casia' : 'Default',
                            'list': 'Casino Games Multiplier',
                        };
                        trackingArray.push(trackingObj);
                    }
                });
            }
        });
        if (this.PrevioustrackingArray.length != trackingArray.length) {
            this.playerStatsService.addMultiplierImpressionTracking(trackingArray);
        }
        this.PrevioustrackingArray = trackingArray;
    }
}
