@if (rewardDetails && rewardDetails?.length > 0 && widgetConfigChanged()) {
    <div
        class="pro-background-color probg"
        [ngClass]="{
            'casino-backgroungImage-layer': parallaxInput && parallaxInput.sitecoreData.backgroundImageLayer1,
            'casino-backgroungImage-parallax':
                (parallaxInput && parallaxInput.sitecoreData.backgroundImageLayer2) ||
                (parallaxInput && parallaxInput.sitecoreData.backgroundImageLayer3),
            'widget-height': rewardDetails?.length <= 2
        }"
        style.overflow="{{ !isTouch ? 'unset' : 'unset' }}">
        <cc-casino-core-lazy-placeholder [inputData]="{ subCategory: parallaxInput }" [componentName]="'ParallaxAnimationComponent'">
        </cc-casino-core-lazy-placeholder>
        <div
            class="prominent-freespins disable-icons"
            [ngClass]="{
                'single-spin-d': rewardDetails?.length == 1,
                'two-spin-d': rewardDetails?.length == 2,
                'touch-device': isTouch,
                'disablePagination': freeSpinsConfigs?.disablePagination == 'true'
            }">
            <vn-swiper [swiperOptions]="swiperOptions">
                @for (rewardData of rewardDetails; track rewardData; let i = $index) {
                    <div class="casino-p-freespins-sec pfBoth swiper-slide">
                        <div
                            class="p-freespins-info"
                            (click)="widgetClick(rewardData, i)"
                            [ngStyle]="{
                                'backgroundImage': 'url(' + widgetBackgroundImage?.src + ')',
                                'background-color': widgetBackgroundImage?.src ? 'unset' : freeSpinsConfigs?.freeSpinBgColor,
                                'border': freeSpinsConfigs?.freeSpinBorderColor
                            }">
                            <div class="pfLeft">
                                <div class="spins-title" [ngStyle]="{ color: freeSpinsConfigs?.spinsColor }">
                                    {{ freeSpinsConfigs?.textPreffix }}<br />
                                    {{ replaceText(rewardData.availableCount) }}
                                </div>
                                <div class="spins-exp" [ngStyle]="{ color: freeSpinsConfigs?.spinsExpireColor }">
                                    {{ freeSpinsConfigs?.offerExpiringIn }}
                                    <cc-prominent-freespins-widget-timmer [timmer]="rewardData.expiryDate"></cc-prominent-freespins-widget-timmer>
                                </div>
                            </div>
                            <div class="btn btn-primary spin-btn" [ngStyle]="{ 'background-color': freeSpinsConfigs?.spinBackgroundColor }">
                                {{ freeSpinsConfigs?.ctaText }}
                            </div>
                        </div>
                    </div>
                }
            </vn-swiper>
        </div>
    </div>
}
