import { Injectable } from '@angular/core';

import { TimerService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { CasinoApiService } from './casino-api.service';
import { TimeZoneService } from './time-zone.service';

@Injectable({
    providedIn: 'root',
})
export class LiveCasinoService {
    private liveGamesLookUp: Map<string, any> = new Map<string, any>();
    private liveGamesSubject = new BehaviorSubject<any>(null);
    liveGamesFeedObservable: Observable<any> = this.liveGamesSubject.asObservable();
    private liveGameswithSitecoreSubject = new BehaviorSubject<any>(null);
    liveGameswithSitecoreObservable: Observable<any> = this.liveGameswithSitecoreSubject.asObservable();

    liveCasinoApiSitecoreInfo: any;
    liveCasinoApiFeedStarted: boolean = false;
    liveCasinoApiFeedIntervalRefresh: any;
    isSitecoreInfoPublished: boolean;
    listenersCnt = 0;

    constructor(
        private api: CasinoApiService,
        private timeZoneService: TimeZoneService,
        private configProvider: ConfigProviderService,
        private timerService: TimerService,
    ) {}

    onAppInit() {
        this.liveGameswithSitecoreObservable.subscribe((liveCasinoData) => {
            if (liveCasinoData) {
                this.startLiveCasinoApiTntervalFeed();
            }
        });
    }

    startLiveCasinoApiTntervalFeed() {
        this.listenersCnt++;
        const liveCasinoConfig = this.configProvider.provideLiveCasinoConfig();
        this.liveCasinoApiFeedIntervalRefresh = this.timerService.setIntervalOutsideAngularZone(() => {
            this.getLiveCasinoData(false);
        }, liveCasinoConfig.liveCasinoApiFeedInterval);
    }
    getLiveCasinoData(isSitecoreInfoRequired: boolean): void {
        const options: any = { headers: {} };
        options.headers['Cache-Control'] = 'no-cache';
        options.headers['Pragma'] = 'no-cache';
        options.headers['Expires'] = '0';
        const apiPathsConfig = this.configProvider.provideApiPathsConfig();
        this.api.get(apiPathsConfig.liveCasino['liveCasinoData'], { isSitecoreInfoRequired: isSitecoreInfoRequired }, options).subscribe(
            (response: any) => {
                this.getLiveCasinoDataSuccess(response, isSitecoreInfoRequired);
            },
            () => {},
        );
    }

    getLiveCasinoDataSuccess(response: any, isSitecoreInfoRequired: boolean) {
        if (response && response.liveCasinoApiDataItems && response.liveCasinoApiDataItems.length > 0) {
            response.liveCasinoApiDataItems.forEach((item: any) => {
                if (item && item.gamevariantname) {
                    if (!(item.gameType && item?.gameType?.toLowerCase() === 'blackjack') && !item.seatsTaken) {
                        item.seatsTaken = [];
                    }
                    this.liveGamesLookUp.set(item?.gamevariantname?.toLowerCase(), item);
                }
            });
            if (response.liveCasinoApiItemSitecoreData) {
                this.liveCasinoApiSitecoreInfo = response.liveCasinoApiItemSitecoreData;
            }

            this.liveGamesSubject.next(this.liveGamesLookUp);
            if (isSitecoreInfoRequired) {
                this.liveGameswithSitecoreSubject.next(this.liveGamesLookUp);
                this.isSitecoreInfoPublished = true;
            }
        }
    }
    getSitecoreInfoPublishedStatus(): boolean {
        return this.isSitecoreInfoPublished;
    }
    // NFR - Services optimization - Remove
    getLiveGamesLookUp() {
        return this.liveGamesLookUp;
    }

    getLiveGameLookUpInfo(gameId: string) {
        if (this.liveGamesLookUp.size > 0) {
            const liveGameInfo: any = this.liveGamesLookUp.get(gameId);
            if (liveGameInfo) {
                liveGameInfo.vendorName = liveGameInfo.vendorname;
                return liveGameInfo;
            }
        }
    }

    getLiveCasinoApiSitecoreInfo() {
        return this.liveCasinoApiSitecoreInfo;
    }

    getFlagIconURL(liveGameInfo: any): string {
        const gameConfig = this.configProvider.provideGameConfig();
        const liveCasinoConfig = this.configProvider.provideLiveCasinoConfig();
        if (liveGameInfo && liveGameInfo.language && liveGameInfo?.language?.toLowerCase() != 'en') {
            return gameConfig.host + liveCasinoConfig.flagUrlPattern.replace('{language}', liveGameInfo.language);
        } else {
            return '';
        }
    }

    isTableOnline(gameId: string): boolean {
        const liveGameInfo: any = this.getLiveGameLookUpInfo(gameId);
        if (liveGameInfo && liveGameInfo?.gameType) {
            if (!liveGameInfo.tableStatus) {
                return false;
            } else {
                if (!liveGameInfo.countdown) {
                    return true;
                }
                if (liveGameInfo?.countdown?.type?.toLowerCase() === 'full time') {
                    return true;
                } else if (liveGameInfo?.countdown?.type?.toLowerCase() === 'bounded') {
                    const timerStartInstance: string = this.timeZoneService.getTimerStartInstance(liveGameInfo.countdown.time + ':00', 'UTC') || '';
                    const seconds: number = this.timeZoneService.getSecondsFromTimeString(timerStartInstance);
                    if (seconds > 0) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }
        } else {
            //it is not a live Game. Hope it does not come here!
            return true;
        }
        return false;
    }
    // NFR - Services optimization - Remove
    setLiveCasinoStartVariable(value: boolean) {
        this.liveCasinoApiFeedStarted = value;
    }
    getLiveCasinoStartVariable(): boolean {
        return this.liveCasinoApiFeedStarted;
    }
    // NFR - Services optimization - Remove
    clearLiveCasinoDataPoints() {
        this.liveGamesLookUp = new Map<string, any>();
        this.liveGamesSubject.next(this.liveGamesLookUp);
    }
    secondsToStartTable(timeString: string): number {
        const timerStartInstance: string = this.timeZoneService.getTimerStartInstance(timeString + ':00', 'UTC') || '';
        const seconds: number = this.timeZoneService.getSecondsFromTimeString(timerStartInstance);
        return seconds;
    }

    stopFeed() {
        this.listenersCnt--;
        if (this.listenersCnt === 0 && this.liveCasinoApiFeedIntervalRefresh) {
            this.timerService.clearInterval(this.liveCasinoApiFeedIntervalRefresh);
            this.liveCasinoApiFeedIntervalRefresh = undefined;
            if (this.liveGamesSubject) {
                this.liveGamesSubject.unsubscribe();
            }
            if (this.liveGameswithSitecoreSubject) {
                this.liveGameswithSitecoreSubject.unsubscribe();
            }
            if (this.liveCasinoApiSitecoreInfo) {
                this.liveCasinoApiSitecoreInfo = undefined;
            }
            if (this.isSitecoreInfoPublished) {
                this.isSitecoreInfoPublished = false;
            }
        }
    }
}
