import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csLiveCasinoApi', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: liveCasinoClientConfigFactory,
})
export class LiveCasinoClientConfig {
    enabled: any;
    vendorName: any;
    liveCasinoApiFeedInterval: number;
    flagUrlPattern: string;
    enableQuickInfo: boolean;
    enableFavouriteGames: boolean;
    hideMoreInfoLink: boolean;
    avatarIcon: string;
    enableLobbyType: any;
    enableIconsForAll: boolean;
    enableFlagIconForEvolution: boolean;
    displayPlaytechBackgroundImage: boolean;
    displayPlaytechDealerImage: boolean;
}

export function liveCasinoClientConfigFactory(service: LazyClientConfigService) {
    return service.get(LiveCasinoClientConfig);
}
