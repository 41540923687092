import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csCasinoConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: casinoCoreConfigClientConfigFactory,
})
export class CasinoCoreConfigClientConfig {
    gameLogUrl: string;
    frontendId: string;
    brandId: string;
    productId: string;
    defaultCategory: string;
    teasers: any;
    gameIconsQuality: string;
    desktopGameIconsQuality: string;
    liveGamesCategories: string[];
    maxNavigationLevel: number;
    gamesLoadCount: any;
    gymlLimit: number;
    gymlLimitForSearch: number;
    recentGamesCountLimit: number;
    defaultyLobbyTypeCategory: any;
    cacheKeys: any;
    casinoContextValues: any;
    detailPageModeLobbyType: any;
    enableLiveGames: boolean;
}

export function casinoCoreConfigClientConfigFactory(service: LazyClientConfigService) {
    return service.get(CasinoCoreConfigClientConfig);
}
