import { NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { CasinoLobbyService, CasinoManager, ConfigProviderService } from '@casinocore/platform/core';
import { DeviceService } from '@frontend/vanilla/core';

import { ThumbnailNewgameComponent } from '../../thumbnail-newgame/thumbnail-newgame.component';

@Component({
    selector: 'cc-thumbnail-home-desktop',
    templateUrl: 'thumbnail-home-desktop.component.html',
    standalone: true,
    imports: [NgIf, ThumbnailNewgameComponent, NgStyle],
})
export class ThumbnailHomeDesktopComponent implements OnInit {
    isTouch: boolean;
    desktopbreakpointforMobile: boolean;
    enableSubNav: boolean;
    displayStaticThumbnail: boolean;
    readonly isMobileWidth = this.deviceService.isMobileWidth;
    isMobileDevice: boolean;
    thumnailHeaderHeight: any;
    currentCategory: any;
    latestCategory: any;

    @Input() lobbyType: string;
    @Input() categoryRoute: string;

    constructor(
        private casinoLobbyService: CasinoLobbyService,
        private casinoManager: CasinoManager,
        private configProviderService: ConfigProviderService,
        private deviceService: DeviceService,
    ) {}
    ngOnInit() {
        this.isTouch = this.casinoManager.isTouch();
        this.desktopbreakpointforMobile = this.isTouch && !this.isMobileWidth();
        const subNavConfig = this.configProviderService.provideSubNavigationConfig();
        this.enableSubNav = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, subNavConfig.enableSubNavOnLobbyType);
        this.isMobileDevice = this.deviceService.isMobilePhone;
        this.latestCategory = this.casinoLobbyService.getCategoryInfo(this.lobbyType, this.categoryRoute);
        this.displayThumbnail();
    }
    closeThumbnailBox(thumdnailData: any) {
        this.displayStaticThumbnail = thumdnailData;
    }
    displayThumbnail() {
        const subNavConfig = this.configProviderService.provideSubNavigationConfig();
        this.enableSubNav = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, subNavConfig.enableSubNavOnLobbyType);
        const thumbnailConfig = this.configProviderService.provideThumbnailConfig();
        if (this.latestCategory && this.latestCategory.categoryInfo) {
            this.currentCategory = this.latestCategory.categoryInfo;
            if (
                this.currentCategory &&
                this.currentCategory.sitecoreData &&
                this.currentCategory.sitecoreData.gameThumbnail &&
                this.currentCategory.sitecoreData.gameThumbnail.length > 0 &&
                this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, thumbnailConfig.displayStaticThumbnail)
            ) {
                this.displayStaticThumbnail = true;
                if (this.isMobileWidth() || this.isMobileDevice) {
                    this.getHeaderHeight();
                }
            } else {
                this.displayStaticThumbnail = false;
            }
        }
    }
    getHeaderHeight() {
        const hdrEle = document.querySelector('header');
        if (hdrEle && hdrEle.offsetHeight) {
            this.thumnailHeaderHeight = hdrEle.getBoundingClientRect().height;
        }
    }
}
