import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetModule, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import {
    CasinoCoreGamelaunchService,
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    FreespinSummaryService,
    GameLaunch,
    IContentImage,
} from '@casinocore/platform/core';
import { CurrencyPipe, DeviceService, NavigationService } from '@frontend/vanilla/core';

import { ProminentFreespinsTimmerComponent } from '../prominent-freespins-timmer/prominent-freespins-timer.component';

@Component({
    selector: 'cc-prominent-freespins-link-modal-info',
    templateUrl: 'prominent-freespins-link-modal-info.component.html',
    standalone: true,
    imports: [MatBottomSheetModule, MatProgressBarModule, NgIf, NgClass, NgFor, NgStyle, CurrencyPipe, ProminentFreespinsTimmerComponent],
})
export class ProminentFreespinsLinkModalInfoComponent implements OnInit {
    progressValue: number = 0;
    totalValue: number = 0;
    percentagevalue: number;
    initialCount: number;
    totalSPinCount: number;
    complitedSpins: number;
    gamesList: any;
    offerExpiryDate: number;
    winAmountSoFar: any;
    currencyAmount: string;
    spins: any;
    mainTitle: string;
    offerExpiringIn: string;
    noOfFreespinsLeft: string;
    currentWinningImage: IContentImage;
    @ViewChild('scrollerContainer', { static: false }) scrollerContainer: ElementRef<HTMLDivElement>;
    isTouch: boolean;
    spinIconImage: any;
    freeSpinsConfig: any;
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
        @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomData: any,
        @Optional() public dialogRef: MatDialogRef<ProminentFreespinsLinkModalInfoComponent>,
        @Optional() public bottomSheetRef: MatBottomSheetRef<ProminentFreespinsLinkModalInfoComponent>,
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private deviceService: DeviceService,
        private navigation: NavigationService,
        private freespinSummaryService: FreespinSummaryService,
        private casinoManager: CasinoManager,
    ) {}

    ngOnInit(): void {
        this.spins = this.data.rewardData || this.bottomData.rewardData;
        this.initialCount = this.spins.availableCount;
        this.totalSPinCount = this.spins.totalCount;
        this.complitedSpins = this.totalSPinCount - this.initialCount == 0 ? 0 : this.totalSPinCount - this.initialCount;
        this.percentagevalue = (this.complitedSpins * 100) / this.spins.totalCount;
        this.offerExpiryDate = this.spins.expiryDate;
        this.currencyAmount = this.spins.currency;
        this.winAmountSoFar = this.spins.winAmountSoFar;
        if (!this.deviceService.isTouch || this.deviceService.isTablet) {
            this.getGamesArray(this.data);
        } else {
            this.getGamesArray(this.bottomData);
        }
        this.assignFeatures();
        this.isTouch = this.deviceService.isTouch;
        //Freespins spins widgets tracking events
        this.freespinSummaryService.addTrackingEvent('casino games', 'free spins', 'load', 'not applicable', 'free spins sheet', 'free spins sheet');
    }

    assignFeatures() {
        const prominentFreespinsConfig = this.configProviderService.providefreeSpinsClientConfig();
        this.freeSpinsConfig = prominentFreespinsConfig?.prominentFreeSpinsConfigurations;
        this.mainTitle = prominentFreespinsConfig?.prominentFreeSpinsConfigurations?.mainTitle;
        this.offerExpiringIn = prominentFreespinsConfig?.prominentFreeSpinsConfigurations?.offerExpiringIn;
        this.currentWinningImage = prominentFreespinsConfig?.currentWinningsImage;
        this.spinIconImage = prominentFreespinsConfig?.ctaSpinBackgroundImage?.src;
    }

    replaceSpinsText(usedSpins: number): string {
        if (usedSpins > 1) {
            return this.freeSpinsConfig?.spinsUsed;
        } else {
            return this.freeSpinsConfig?.spinsUsed.replace(/s$/, '');
        }
    }

    replaceSpinsCountText(remainingSpins: number): string {
        if (remainingSpins > 1) {
            return this.freeSpinsConfig?.spinsLeft;
        }
        return this.freeSpinsConfig?.spinsLeft.replace(/s$/, '');
    }

    replaceGamesText(length: number): string {
        if (length == 1) {
            return (this.freeSpinsConfig.eligibleGames = this.freeSpinsConfig?.eligibleGames.replace(/\(s\)/, ''));
        }
        return this.freeSpinsConfig?.eligibleGames;
    }

    getGamesArray(item: any) {
        const filterList: any = [];
        const gamesIds: any = Object.values(item.freespinsRwardIdBasedGamesList)[0];
        gamesIds.forEach((item: any) => {
            const gameInfo = this.casinoLobbyService.getGameInfo(item);
            if (gameInfo) {
                // item = Object.assign(item, gameInfo);
                gameInfo.widgetName = 'FreeSpins';
                gameInfo.imageUrl = this.casinoLobbyService.getImagePath(item, 'square');
                filterList.push(gameInfo);
            }
        });
        this.gamesList = filterList;
    }

    onCloseModal(event?: Event): void {
        if (!this.deviceService.isTouch || this.deviceService.isTablet) {
            this.dialogRef.close();
        }
        if (this.deviceService.isTouch) {
            this.bottomSheetRef.dismiss();
        }
        if (event) {
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        //Freespins spins widgets tracking events
        this.freespinSummaryService.addTrackingEvent('casino games', 'free spins', 'close', 'not applicable', 'free spins sheet', 'free spins sheet');
    }

    onMoreInfoclick(event: any, spins: any) {
        const moreinfoUrl = this.configProviderService.providefreeSpinsClientConfig()?.freeSpinsMoreInfo?.url;
        const redirectUrl = moreinfoUrl.replace('{freespinId}', spins.userRewardId);
        event.stopPropagation();
        // this.tracking.triggerEvent('Event.Clicks', {
        //     'component.LabelEvent': 'casino game promotions',
        //     'component.ActionEvent': 'click',
        //     'component.URLClicked': redirectUrl,
        // });
        //Freespins spins widgets tracking events
        this.freespinSummaryService.addTrackingEvent(
            'casino games',
            'free spins',
            'click',
            'not applicable',
            'free spins sheet',
            'view full details',
        );
        // this.navigationAndDialogClose();
        this.navigation.goTo(redirectUrl);
    }

    public moreInfoClick(event: any, rewardDataIndex: any) {
        this.onMoreInfoclick(event, rewardDataIndex);
    }

    launchGame(data: any, i: number) {
        const gameModel = new GameLaunch();
        gameModel.game = data;
        gameModel.gamePosition = i;
        gameModel.gameSection = 'free spins sheet';
        gameModel.game.gameId = data.game;
        gameModel.game.lobbyType = this.casinoManager.getLobbyType();
        this.gameLaunchService.launchGame(gameModel);
        if (!this.deviceService.isTouch || this.deviceService.isTablet) {
            this.dialogRef.close();
        }
        if (this.deviceService.isTouch) {
            this.bottomSheetRef.dismiss();
        }
    }

    getZeroTimmer(value: any) {
        if (value?.IsTimmerZero) {
            this.onCloseModal();
        }
    }
}
