import { Injectable } from '@angular/core';

import { AppInfoConfig, NativeAppService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
})
export class InvokerProductService {
    invokerProduct: string;
    constructor(
        private appInfoConfig: AppInfoConfig,
        private nativeAppService: NativeAppService,
    ) {}

    getInvokerProduct(): string {
        let appProduct: string = this.appInfoConfig?.product?.toLowerCase();
        if (this.nativeAppService.isNative) {
            appProduct = this.nativeAppService?.product?.toLowerCase();
        }
        return appProduct;
    }
}
