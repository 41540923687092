import { NgClass, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DeviceService, NavigationService, UserService } from '@frontend/vanilla/core';
import { MessagePanelComponent } from '@frontend/vanilla/features/message-panel';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { GlobalTranslateComponent } from '../directives/globaltranslate/globaltranslate.component';
import { HeaderBoxComponent } from '../header-box/header-box.component';
import { FavouriteService } from '../shared/favourite.service';
import { GameDataManager } from '../shared/game-data-manager.service';
import { CasinoCoreGamelaunchService } from '../shared/gamelaunch.service';
import { CasinoCoreGameLaunchFactoryService } from '../shared/gamelaunchfactory.service';
import { GameDetailButtonsComponent } from './game-detail-buttons/game-detail-buttons.component';

@Component({
    selector: 'cc-game-detail',
    templateUrl: 'game-detail.component.html',
    standalone: true,
    imports: [HeaderBoxComponent, MessagePanelComponent, NgIf, NgClass, GameDetailButtonsComponent, GlobalTranslateComponent, TrustAsHtmlPipe],
})
export class GameDetailComponent implements OnInit {
    data: any;
    twoButtons: any;
    model: any;
    gameData: any;
    useFavouriteGames: boolean;
    favouriteStyle: string = 'theme-favorite-i';
    unFavouriteStyle: string = 'theme-favorite';
    constructor(
        private user: UserService,
        private gameDataManager: GameDataManager,
        private deviceService: DeviceService,
        private navigation: NavigationService,
        private favouritesService: FavouriteService,
        private configProviderService: ConfigProviderService,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private gameLaunchFactoryService: CasinoCoreGameLaunchFactoryService,
        @Inject(MAT_DIALOG_DATA) public gameInfo: any,
        public dialogRef: MatDialogRef<GameDetailComponent>,
    ) {
        this.gameLaunchFactoryService.setGameLaunchService(this.gameLaunchService);
    }
    ngOnInit() {
        if (this.gameInfo) {
            this.assignGameModel();
        }
        const global = this.configProviderService.provideGlobalConfig();
        if (
            global.lobbyConfigurations.FavouriteHeart === 'true' ||
            global.lobbyConfigurations.FavouriteHeart === 'True' ||
            global.lobbyConfigurations.FavouriteHeart === 'TRUE'
        ) {
            this.favouriteStyle = 'theme-heart-solid';
            this.unFavouriteStyle = 'theme-heart-outline';
        }
    }

    assignGameModel() {
        const mcPage = this.configProviderService.provideMcPageConfig();
        const global = this.configProviderService.provideGlobalConfig();
        const clientSideConfig = this.configProviderService.provideClientSideConfig();
        const features = this.configProviderService.provideFeaturesConfig();
        const gamedetailObj: any = {
            game: this.gameInfo.game.game.gameId,
            gameId: this.gameInfo.game.game.gameId,
            name: this.gameInfo.game.game.name,
            ownerId: this.gameInfo.game.game.ownerId,
            provider: this.gameInfo.game.game.provider,
            icon: this.gameInfo.game.game.iconType,
        };
        this.data = this.gameDataManager.setGameLobbyModel(gamedetailObj, this.deviceService.isTouch);
        this.twoButtons = clientSideConfig.playForFunAndRealButtons;
        this.useFavouriteGames = features.useFavouriteGames;
        if (!this.data) {
            this.navigation.goTo(mcPage?.prefix);
            return;
        }
        this.model = this.data;
        this.model.categoryType = this.gameInfo.game.categoryType;
        this.model.lobbyType = this.gameInfo.game.game.lobbyType;
        this.model.gameSection = this.gameInfo.game.gameSection;
        this.model = this.model && this.model[0] ? this.model[0] : this.model;
        this.model.disclaimer = global.messages['Disclaimer'];
        this.model.isFavourite = this.favouritesService.checkForFavouriteGame(this.model);
    }

    playButtonMode(game: any) {
        switch (game.launchType) {
            case 'inhouse':
                return 1;
            default:
                return 2;
        }
    }

    toggleFavourite(game: any, $event: any) {
        if (this.user.isAuthenticated) {
            $event.stopImmediatePropagation();
            this.favouritesService.toggleGameFavourite(game);
        }
    }
    isFavouriteGame(model: any) {
        return this.favouritesService.checkForFavouriteGame(model);
    }
    closeGameDetailDialog() {
        this.dialogRef.close();
    }
}
