import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'cs-class-icon',
    template: `
        @if (startIconClass) {
            <div [class]="startIconClass" data-decoratorclass-host></div>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class CsClassIconComponent implements CsClassIconViewModel {
    @Input() startIconClass = '';
}

export interface CsClassIconViewModel {
    startIconClass: string;
}
