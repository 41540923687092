<span>
    @switch (subcategoryid()) {
        @case (rpPositionLmtKey()) {
            <div>
                <cc-rp-gyml
                    id="rpGYMLSection"
                    *ngIf="!hideRpGyml() && arcadeLobbyGameConfig != currentLobbyType()"
                    [lobbyType]="lobbyType()"
                    [gameContainer]="gameContainer()"
                    [categoryId]="subcategoryid()"
                    class="casino-rpPositionConfiguration"></cc-rp-gyml>
                <cc-arcade-rp-gyml
                    id="rpGYMLSection"
                    *ngIf="!hideRpGyml() && arcadeLobbyGameConfig == currentLobbyType()"
                    [lobbyType]="lobbyType()"
                    [gameContainer]="gameContainer()"
                    [categoryId]="subcategoryid()"
                    class="casino-rpPositionConfiguration"></cc-arcade-rp-gyml>
            </div>
        }
        @case (cozyBingoWidgetKey()) {
            <cc-casino-core-lazy-placeholder
                [inputData]="{ categoryId: subcategoryid() }"
                [componentName]="'CozyBingoWidgetComponent'"></cc-casino-core-lazy-placeholder>
        }
        @case (promoOfferWidgetKey()) {
            @if (promoWidgetEnabled) {
                <cc-casino-core-lazy-placeholder [inputData]="{ products: promoProducts }" [componentName]="'PromoOfferWidgetComponent'">
                </cc-casino-core-lazy-placeholder>
            }
        }
        @case (gamificationWidgetKey()) {
            @if (gamificationWidgetEnabled) {
                <cc-casino-core-lazy-placeholder
                    *ngSwitchCase="gamificationWidgetKey()"
                    [inputData]="{ lobbyType: lobbyType() }"
                    [componentName]="'GamificationLoaderComponent'">
                </cc-casino-core-lazy-placeholder>
            }
        }
        @default {
            <span [ngClass]="{ 'hidden-cls': !loadLMTCategory() }">
                <cc-lmt-sub-category-lobby
                    [trackingIndex]="trackingIndex()"
                    [coinExpiryIndex]="coinExpiryIndex()"
                    [subcategoryId]="subcategoryid()"
                    [isTouch]="isTouch()"
                    [subcategory]="subcategory()"
                    [lobbyType]="lobbyType()"
                    [gameContainer]="gameContainer()"
                    [seeAllProductLinkOverride]="seeAllProductLinkOverride()">
                </cc-lmt-sub-category-lobby>
            </span>
        }
    }
</span>
