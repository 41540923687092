import { Injectable } from '@angular/core';

import { ClaimsService, MessageLifetime, MessageQueueService, TimerService, UserService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable, Subject, combineLatest, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { LobbyItem } from '../../casino-lobby-manager/casino-lobby.models';
import { ConfigProviderService } from '../../config-provider/config-provider.service';
import { CasinoApiService } from '../../shared/casino-api.service';
import { CasinoManager } from '../../shared/casino-manager.service';
import { Race, ResultsHistory, SitecoreContent, SlotApiParams, SlotRacesLive, SlotRacesResponseError, SlotRacesUpcoming } from './slot-races.models';

@Injectable({
    providedIn: 'root',
})
export class SlotRacesService {
    categoryRoutePrefix = '/c/';
    isSlotRacesEnabled: boolean;
    isNotificationEnabled: boolean;
    slotRacesCategoryId: string;
    isCoin: boolean;
    selectedFilteredtOption: string;
    stickerMap: Map<string, any> = new Map<string, any>();
    raceGamesMap: Map<string, any> = new Map<string, any>();
    messages: { [item: string]: string };
    private contentPublisher = new BehaviorSubject<any>({});
    content: Observable<any> = this.contentPublisher.asObservable();
    contentData: SitecoreContent = {
        raceRuleConfigs: [],
        rules: [],
        rulesBanner: {},
        placeholderCardImage: {},
        coinEconomy: {},
        textTranslations: { sharedList: {}, versionedList: {} },
        tips: [],
        errorMessages: { sharedList: {}, versionedList: {} },
        entryDetails: { sharedList: {}, versionedList: {} },
    };

    private notifyPublisher = new Subject<{ promoId: number; slotUniqueId: number; status: boolean }>();
    notifyObservable: Observable<{ promoId: number; slotUniqueId: number; status: boolean }> = this.notifyPublisher.asObservable();
    private liveRacesPublisher = new BehaviorSubject<SlotRacesLive>({ liveNow: [] });
    liveRacesData: Observable<SlotRacesLive> = this.liveRacesPublisher.asObservable();

    forcRacepublisher = new BehaviorSubject<any>({});
    forceRace: Observable<any> = this.forcRacepublisher.asObservable();

    forceCoinBalancepublisher = new BehaviorSubject<any>({});
    forceCoinBalanceObs: Observable<any> = this.forceCoinBalancepublisher.asObservable();

    private upcomingRacesPublisher = new BehaviorSubject<SlotRacesUpcoming>({ upcomingToday: [], upcomingTomorrow: [] });
    upcomingRacesData: Observable<SlotRacesUpcoming> = this.upcomingRacesPublisher.asObservable();

    private globalTimerPublisher = new BehaviorSubject<any>({});
    globalTimerObservable: Observable<any> = this.globalTimerPublisher.asObservable();

    private updateNavigationCategories = new BehaviorSubject<boolean>(true);
    updateNavigation: Observable<boolean> = this.updateNavigationCategories.asObservable();

    private stickerRacesPublisher = new BehaviorSubject<any>({});
    stickerRacesData: Observable<any> = this.stickerRacesPublisher.asObservable();

    private rtmsDatPublisher = new BehaviorSubject<any>({});
    rtmsData: Observable<any> = this.rtmsDatPublisher.asObservable();

    coinDetailsPublisher = new BehaviorSubject<any>(false);
    coinDetails: Observable<any> = this.coinDetailsPublisher.asObservable();

    raceCompletedPublisher = new BehaviorSubject<any>(false);
    raceCompletedObs: Observable<any> = this.raceCompletedPublisher.asObservable();

    selectedEntryOption = new Subject<string>();
    liveSlotPollingStarted: boolean;
    pollForUser: boolean = true;
    private pollingInterval: any;
    private globalTimerInterval: number = 1000; //take this value from dynacon if possible
    private gameMetadata: { [game: string]: any };
    private isGameMetadataAvailable: boolean;
    private slotRaces: Race[];
    private disabledGameNames: string[] = [];
    private isSlotRacesInitiated: boolean;
    private isNavCategoryVisible: boolean;
    private promoPrizesMap: any = {};
    private timer: any;
    optInButtonText: string;
    categoryMap: any = [];
    slotInputArr: any[] = [];

    constructor(
        private api: CasinoApiService,
        private timerService: TimerService,
        private messageQueue: MessageQueueService,
        private claims: ClaimsService,
        private casinoManager: CasinoManager,
        private user: UserService,
        private configProviderService: ConfigProviderService,
    ) {}

    setNotifyStatus(promoId: number, slotUniqueId: number, status: boolean): void {
        this.notifyPublisher.next({ promoId: promoId, slotUniqueId: slotUniqueId, status: status });
    }

    initSlotRaces(gameDataMap: any, disabledGames: any, lmtData: LobbyItem, isDLL: boolean, isInItSlotRaces: boolean): void {
        if (isInItSlotRaces) {
            this.emptySlotRacesData();
        }
        if (!this.isSlotRacesInitiated) {
            this.isSlotRacesInitiated = true;
            this.updateLobbyNavigation();
            this.getSitecoreData(!isDLL);
        }
        const configs = this.configProviderService.provideSlotRacesConfig();
        if (lmtData) this.getlmtData(lmtData);
        if (this.user.isAuthenticated || configs.enablePreLogin) {
            this.getLiveRaces();
            this.getUpcomingRaces();
        }

        if (gameDataMap && gameDataMap.size > 0) {
            this.updateGameMetaData(Array.from(gameDataMap.values()));
        }
        this.disabledGameNames = disabledGames && disabledGames.length > 0 ? disabledGames : [];
        if (configs.enableSlotRacesStickers) {
            this.slotRaceStickersData();
        }
    }

    private emptySlotRacesData(): void {
        this.slotRaces = [];
        this.raceGamesMap.clear();
        this.liveRacesPublisher.next({ liveNow: [] });
        this.upcomingRacesPublisher.next({ upcomingToday: [], upcomingTomorrow: [] });
    }

    setSlotRaces(lobbyType: string): void {
        this.setSlotRacesParams(lobbyType);
        const configs = this.configProviderService.provideSlotRacesConfig();
        if (this.isPrizesMapEmpty()) {
            if (!configs.disablePrizesApiCall || (configs.disablePrizesApiCall && this.user.isAuthenticated)) {
                this.getPrizes().subscribe();
            }
        }
        if (!this.timer) {
            this.globalTimerCountDown();
        }
    }

    getRaces(): void {
        if (this.isSlotRacesEnabled) {
            this.getLiveRaces();
            this.getUpcomingRaces();
        }
    }

    startLiveSlotPolling() {
        if (!this.liveSlotPollingStarted && this.pollForUser) {
            const configs = this.configProviderService.provideSlotRacesConfig();

            this.liveSlotPollingStarted = true;
            this.pollingInterval = this.timerService.setIntervalOutsideAngularZone(() => this.getLiveRaces(), configs.racesListPollingInterval);
        }
    }

    stopLiveSlotPolling() {
        this.liveSlotPollingStarted = false;
        this.timerService.clearInterval(this.pollingInterval);
    }

    private getSitecoreData(fetchNPMData: boolean): void {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        this.api.get(apiPathsConfig.slotRaces['racesContent'], { fetchNPMData: fetchNPMData }).subscribe((content: SitecoreContent) => {
            this.contentData = content;
            this.contentPublisher.next(content);
            if (
                content &&
                content.textTranslations &&
                content.textTranslations.sharedList &&
                content.textTranslations.sharedList.progressBarColor &&
                content.textTranslations.sharedList.progressBarColor != ''
            ) {
                const style = document.createElement('style');
                style.innerHTML =
                    '.sr-progress-bar-color.mat-accent .mat-slider-thumb,.mat-slider-thumb-label,.mat-slider-track-fill { background-color:' +
                    content.textTranslations.sharedList.progressBarColor +
                    ' !important } ';
                document.getElementsByTagName('head')[0]!.appendChild(style);
            }
        });
    }

    getPrizes(): Observable<any> {
        return this.getPrizesFromAPI().pipe(
            map((data: any) => {
                let prizesMap;
                if (data?.offers && data.offers?.length) {
                    prizesMap = this.createPrizesMap(data.offers);
                }
                if (prizesMap) {
                    this.promoPrizesMap = prizesMap;
                }
                return prizesMap;
            }),
            catchError((error: any) => {
                return error;
            }),
        );
    }

    private createPrizesMap(prizes: any): {} {
        const promoPrizes: { [promoId: number]: any } = [];
        prizes = prizes.filter((prize: any) => prize && prize.promoId && prize.rankDetails && prize.rankDetails.length);
        for (let n = 0; n < prizes.length; n++) {
            if (!promoPrizes[prizes[n].promoId]) {
                const prizesArray: any[] = [];
                for (let i = 0; i < prizes[n].rankDetails.length; i++) {
                    prizesArray.push(prizes[n].rankDetails[i].configMaps);
                }

                if (prizes[n].beatBanker) {
                    prizes[n].beatBanker.isBeatBanker = true;
                    prizesArray.push([prizes[n].beatBanker]);
                }
                promoPrizes[prizes[n].promoId] = prizesArray;
            }
        }
        return promoPrizes;
    }

    getPrizesForPromo(promoId: number): any {
        if (this.promoPrizesMap) {
            const prize = this.promoPrizesMap[promoId];
            if (prize && prize.length) {
                const currencyPrize = prize.find((p: any) => p?.currency && p?.currency?.length);
                if (currencyPrize) {
                    if (currencyPrize.currency === this.claims.get('currency')) {
                        return prize;
                    } else {
                        return []; // so that a downstream call is made to fetch latest data for prizes with new currency
                    }
                } else {
                    return prize; //return prize if it does not involve any prize with currency
                }
            }
        }
        return [];
    }

    private isPrizesMapEmpty(): boolean {
        for (const promo in this.promoPrizesMap) {
            if (this.promoPrizesMap.hasOwnProperty(promo)) {
                return false;
            }
        }

        return JSON.stringify(this.promoPrizesMap) === JSON.stringify({});
    }

    getLiveRaces(): void {
        const params: SlotApiParams = {
            slotInput: 'LIVE',
        };
        this.getRacesList(params);
    }

    getUpcomingRaces(): void {
        const configs = this.configProviderService.provideSlotRacesConfig();

        const params: SlotApiParams = {
            slotInput: 'UPCOMING',
            slotDuration: configs.upcomingSlotsDuration,
        };
        this.getRacesList(params);
    }

    private getRacesList(params: any): void {
        const configs = this.configProviderService.provideSlotRacesConfig();
        if (!configs.disablePreloginApiCalls || (configs.disablePreloginApiCalls && this.user.isAuthenticated)) {
            this.getRacesFromAPI(params).subscribe(
                (races: any) => {
                    if (races) {
                        if (races.statusDtls && races.statusDtls.statusCode == 1016 && this.liveSlotPollingStarted) {
                            this.pollForUser = false;
                            this.stopLiveSlotPolling();
                        } else {
                            if (!this.pollForUser) {
                                this.pollForUser = true;
                            }
                        }
                        this.getRaceCompletedStatus(params, configs);
                        if (races.offerResponses && races.offerResponses.length > 0) {
                            this.makeDateConversions(races.offerResponses, params.slotInput);
                        }
                    }
                },
                (error: any) => {
                    if (error) {
                        this.getRaceCompletedStatus(params, configs);
                    }
                },
            );
        }
    }

    private getRaceCompletedStatus(params: any, configs: any) {
        if (configs?.enableSlotRacesPhase2 && !this.liveSlotPollingStarted) {
            this.slotInputArr.push(params.slotInput);
            const slotInputUniqueArr = this.slotInputArr.filter(function (item, pos, self) {
                return self.indexOf(item) == pos;
            });
            if (slotInputUniqueArr?.length == 2) {
                setTimeout(() => {
                    this.raceCompletedPublisher.next(true);
                }, 100);
            }
        }
    }

    private makeDateConversions(races: Race[], slotInput: string) {
        const finalRaces: Race[] = [];
        const now = Date.now();
        races.forEach((race: Race) => {
            const startDate = new Date(race.startTime); // as date coming from API is epoch UTC
            const endDate = new Date(race.endTime);
            const optinExpiryDate = new Date(race.optinExpiryTime);
            race.startDate = startDate;
            race.endDate = endDate;
            race.optinExpiryDate = optinExpiryDate;
            //adding following if-else block to eliminate older data showing up in response from pre-login cache
            if (slotInput === 'LIVE' && race.startDate.getTime() < now && race.endDate.getTime() > now) {
                race.startTimeAMPM = this.getAMPMTime(race.startDate);
                finalRaces.push(race);
            } else if (slotInput === 'UPCOMING' && race.startDate.getTime() > now) {
                race.startTimeAMPM = this.getAMPMTime(race.startDate);
                finalRaces.push(race);
            }
        });
        this.slotRaces = finalRaces;
        this.populateRacesObject();
    }

    getAMPMTime(date: Date): string {
        const features = this.configProviderService.provideSlotRacesConfig();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        if (features.enable24HrTimeFormat) {
            return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);
        }
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const mnts = minutes < 10 ? '0' + minutes : minutes;
        const startTime = hours + ':' + mnts + ampm;
        return startTime;
    }

    private populateRacesObject(): void {
        if (this.slotRaces && this.slotRaces.length && this.isGameMetadataAvailable) {
            const tomorrow = new Date();
            tomorrow.setHours(24, 0, 0, 0);
            const dayAfter = new Date();
            dayAfter.setHours(48, 0, 0, 0);
            const now = Date.now();
            const liveSlots: SlotRacesLive = { liveNow: [] };
            const upcomingSlots: SlotRacesUpcoming = { upcomingToday: [], upcomingTomorrow: [] };
            this.slotRaces.every((race: Race) => {
                const ordandunordGames: any = {};
                let mappedObject: any;
                this.filterPromoGames(race);
                const raceMetaData = this.gameMetadata[race.gameVariantList[0]!];
                if (raceMetaData && race.gameVariantList && race.gameVariantList.length && race.endDate.getTime() > now) {
                    if (race.gameVariantList.length > 1) {
                        mappedObject = this.raceGamesMap && this.raceGamesMap.get(race.promoId?.toString());
                        if (!mappedObject || (mappedObject && mappedObject?.unorderedGames !== race.gameVariantList)) {
                            ordandunordGames.unorderedGames = race.gameVariantList;
                            ordandunordGames.orderedGames = race.gameVariantList = this.getOrderedGames(race);
                            this.raceGamesMap.set(race.promoId.toString(), ordandunordGames);
                        } else {
                            race.gameVariantList = mappedObject.orderedGames;
                        }
                    }
                    race.gameId = raceMetaData.name;
                    if (race.startDate.getTime() <= now) {
                        race.isLive = true;
                        liveSlots.liveNow.push(race);
                        return true;
                    } else if (race.startDate < tomorrow) {
                        upcomingSlots.upcomingToday.push(race);
                        return true;
                    } else if (race.startDate < dayAfter) {
                        upcomingSlots.upcomingTomorrow.push(race);
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            });
            if (liveSlots && liveSlots.liveNow && liveSlots.liveNow.length) {
                if (
                    upcomingSlots &&
                    ((upcomingSlots.upcomingToday && upcomingSlots.upcomingToday.length) ||
                        (upcomingSlots.upcomingTomorrow && upcomingSlots.upcomingTomorrow.length))
                ) {
                    this.publishRaces(liveSlots, upcomingSlots);
                } else {
                    this.publishRaces(liveSlots, '');
                }
            } else {
                this.publishRaces('', upcomingSlots);
            }
        }
    }
    private filterPromoGames(promo: any) {
        if (promo) {
            if (!promo.gameVariantList || !promo.gameVariantList.length) {
                promo.gameVariantList = [promo.gameVariantName];
            }

            const tempGameList: any = [];
            for (let i = 0; i < promo.gameVariantList.length; i++) {
                if (this.gameMetadata[promo.gameVariantList[i]] && this.disabledGameNames.indexOf(promo.gameVariantList[i]) < 0) {
                    tempGameList.push(promo.gameVariantList[i]);
                }
            }
            promo.gameVariantList = tempGameList;
        }
    }

    getLeaderboard(promoId: any, slotId: any, maxRank: number = 0, fromResultsHistory: boolean): Observable<any> {
        return this.getLeaderboardFromAPI(promoId, slotId, maxRank, fromResultsHistory);
    }

    updateRacesArrays(): void {
        const upcomingRaces = this.upcomingRacesPublisher.value;
        const liveRaces = this.liveRacesPublisher.value;
        const now = Date.now();
        const newLiveRaces: Race[] = upcomingRaces.upcomingToday.filter((r) => r.startDate.getTime() <= now);
        newLiveRaces.forEach((race: Race) => {
            race.isLive = true;
            upcomingRaces.upcomingToday.splice(upcomingRaces.upcomingToday.indexOf(race), 1);
        });
        const endedRaces: Race[] = liveRaces.liveNow.filter((r) => r.endDate.getTime() <= now);
        endedRaces.forEach((race: Race) => {
            liveRaces.liveNow.splice(liveRaces.liveNow.indexOf(race), 1);
        });
        liveRaces.liveNow = liveRaces.liveNow.concat(newLiveRaces);

        this.publishRaces(liveRaces, upcomingRaces);
    }

    private publishRaces(liveRaces: SlotRacesLive | '', upcomingRaces: SlotRacesUpcoming | ''): void {
        if (upcomingRaces) {
            if (upcomingRaces.upcomingToday && upcomingRaces.upcomingToday.length) {
                const timeoutInterval = upcomingRaces.upcomingToday[0]!.startDate.getTime() - Date.now();
                setTimeout(() => {
                    this.updateRacesArrays();
                }, timeoutInterval);
            } else if (upcomingRaces.upcomingTomorrow && upcomingRaces.upcomingTomorrow.length) {
                const timeoutInterval = upcomingRaces.upcomingTomorrow[0]!.startDate.getTime() - Date.now();
                setTimeout(() => {
                    this.updateRacesArrays();
                }, timeoutInterval);
            }
        }

        if (liveRaces && liveRaces.liveNow) {
            this.liveRacesPublisher.next(liveRaces);
        }
        if (upcomingRaces && (upcomingRaces.upcomingToday || upcomingRaces.upcomingTomorrow)) {
            this.upcomingRacesPublisher.next(upcomingRaces);
        }
    }
    //To Show Stickers on Game Tile
    slotRaceStickersData() {
        this.content.subscribe((content: SitecoreContent) => {
            this.contentData = content;
            this.messages = content && content.textTranslations ? content.textTranslations.versionedList : {};
            combineLatest(this.liveRacesData, this.upcomingRacesData).subscribe((races: any) => {
                const liveRaces = races[0] && races[0].liveNow && races[0].liveNow.slice(0, races[0].liveNow.length);
                const upcomingToday = races[1] && races[1].upcomingToday && races[1].upcomingToday.slice(0, races[1].upcomingToday.length);
                this.raceData(liveRaces, upcomingToday, this.contentData);
            });
        });
    }

    raceData(liveRaces: Race[], upcomingToday: Race[], contentData: any) {
        if (liveRaces.length > 0 || upcomingToday.length > 0) {
            for (let i = 0; i < upcomingToday.length; i++) {
                const corrLive = liveRaces.find((r) => r.promoId === upcomingToday[i]!.promoId);
                if (corrLive) {
                    if (corrLive.optinExpiryDate.getTime() < Date.now()) {
                        continue;
                    } else {
                        this.stickersDataMap(upcomingToday[i]!, contentData, 'upcomingRaces');
                    }
                } else {
                    this.stickersDataMap(upcomingToday[i]!, contentData, 'upcomingRaces');
                }
            }
            for (let i = 0; i < liveRaces.length; i++) {
                if (liveRaces[i]!.optinExpiryDate.getTime() < Date.now()) {
                    continue;
                } else {
                    this.stickersDataMap(liveRaces[i]!, contentData, 'liveRaces');
                }
            }
            if (this.stickerMap.size > 0) {
                this.stickerRacesPublisher.next(this.stickerMap);
            }
        }
    }
    stickersDataMap(raceItem: Race, contentData: any, races: string) {
        const configs = this.configProviderService.provideSlotRacesConfig();
        const milliseconds = raceItem.startDate.getTime() - Date.now();
        const seconds: number = Math.floor(milliseconds / 1000);
        const minutes: number = Math.floor(seconds / 60);
        if (races === 'upcomingRaces') {
            if (minutes > configs.upcomingSlotStickersDuration) {
                return;
            }
            if (minutes && minutes <= configs.upcomingSlotStickersDuration) {
                this.getUpdatedSlotStickerDuration(raceItem, contentData);
            }
        } else if (races === 'liveRaces') {
            if (minutes > 30) {
                return;
            }
            if (minutes && minutes <= 30) {
                this.getUpdatedSlotStickerDuration(raceItem, contentData);
            }
        }
    }
    getUpdatedSlotStickerDuration(raceItem: Race, contentData: any) {
        this.messages = contentData && contentData.textTranslations ? contentData.textTranslations.versionedList : {};
        const configs = this.configProviderService.provideSlotRacesConfig();
        let gameName: string;
        let stickerText: string = '';
        const awardType: string = raceItem.additionalParams.awardType!;
        const awardTypeSticker: string = 'sticker_' + (awardType == null ? '' : awardType?.toLowerCase());
        const subType: string = raceItem.subType;
        const milliseconds = raceItem.startDate.getTime() - Date.now();
        const seconds: number = Math.floor(milliseconds / 1000);
        const minutes: number = Math.floor(seconds / 60);
        for (let i = 0; i < raceItem.gameVariantList.length; i++) {
            gameName = raceItem.gameVariantList[i]!;

            if (subType == 'CASINO_LEADERBOARD' && awardType) {
                stickerText = this.messages[awardTypeSticker]!;
            } else if (configs.enableStickersFreeToPlay && subType == 'FREE_TO_PLAY') {
                stickerText = this.messages.sticker_free_to_play!;
            } else if (configs.enableStickersFreePlayTech && subType?.toLowerCase() == 'free_play_tech') {
                stickerText = this.messages.sticker_free_play_tech!;
            }
            const stickerItem = {
                minutes: minutes,
                optinExpiryDate: raceItem.optinExpiryDate,
                optinExpiryTime: raceItem.optinExpiryTime,
                endDate: raceItem.endDate,
                stickerText: [stickerText],
            };
            if (stickerText !== undefined) {
                this.stickerMap.set(gameName, { data: stickerItem });
            }
        }
    }

    getResultsHistory(to: string, from?: string): Observable<ResultsHistory[] | null> {
        return this.getResultsHistoryFromAPI(to, from).pipe(
            map((data: any) => {
                if (data && data.rankDetails && data.rankDetails.length > 0) {
                    const resultHistory = this.formatResults(data.rankDetails);
                    return resultHistory;
                }
                return null;
            }),
            catchError((error: any) => {
                return throwError(error);
            }),
        );
    }

    private formatResults(results: ResultsHistory[]) {
        let prizes: any;
        let beatBanker: any;
        results.forEach((result: ResultsHistory) => {
            this.filterPromoGames(result);
            result.startDate = new Date(result.slotStartDate);
            result.endDate = new Date(result.slotEndDate);
            result.dateStr = result.startDate.getDate() + '-' + (result.startDate.getMonth() + 1) + '-' + result.startDate.getFullYear();
            if ((result.content && result.content.promotion && result.content.promotion.prizes) || result?.content?.promotion?.beatBanker) {
                if (result.configMaps && result.configMaps[0]?.awardType) {
                    result.isWin = true;
                } else {
                    result.isWin = false;
                }
                prizes = this.getParsedData(result.content.promotion.prizes);
                if (result?.content?.promotion?.beatBanker) {
                    result.isBeatBanker = true;
                    beatBanker = this.getParsedData(result.content.promotion.beatBanker);
                }
                prizes?.forEach((prize: any) => {
                    if (result.rank >= Number(prize.FromRank) && result.rank < Number(prize.ToRank)) {
                        result.prizeIcon = prize.Icon;
                    }
                });
                beatBanker?.forEach((banker: any) => {
                    if (result.configMaps && result?.configMaps[0]?.awardType && result?.configMaps[0]?.value) {
                        result.prizeIcon = banker.Icon;
                    }
                });
            }
        });
        return results;
    }

    getParsedData(data: any) {
        try {
            return JSON.parse(data);
        } catch {
            return null;
        }
    }

    updateGameMetaData(data: any[]): void {
        this.gameMetadata = this.buildGameIdBasedMetadataIndex(data);
        this.populateRacesObject();
    }

    private buildGameIdBasedMetadataIndex(metadata: Array<any>): { [game: string]: object } {
        const indexedMetadata: { [game: string]: object } = {};
        if (metadata && metadata.length) {
            this.isGameMetadataAvailable = true;
            metadata
                .filter((item) => item)
                .forEach((metadataItem) => {
                    if (!indexedMetadata[metadataItem.game.trim()]) {
                        indexedMetadata[metadataItem.game.trim()] = metadataItem;
                    }
                });
            return indexedMetadata;
        }
        return {};
    }

    private getRacesFromAPI(params: any): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(apiPathsConfig.slotRaces['racesList'], params, { withCredentials: true });
    }

    private getLeaderboardFromAPI(promoId: number, slotId: number, maxRank: number, fromResultsHistory: boolean): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(
            apiPathsConfig.slotRaces['racesLeaderboard'],
            { prmId: promoId, slotId: slotId, maxRank: maxRank, fromResultsHistory: fromResultsHistory },
            { withCredentials: true },
        );
    }

    private getResultsHistoryFromAPI(to: string, from?: string): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(apiPathsConfig.slotRaces['racesResults'], { fromDate: from, endDate: to }, { withCredentials: true });
    }

    private getPrizesFromAPI(): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(apiPathsConfig.slotRaces['racesPrizes'], {}, { withCredentials: true });
    }

    updateOptinStatus(promoId: number, slotId: number, coinsForOptin: number): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(apiPathsConfig.slotRaces['racesOptin'], { prmId: promoId, slotId, coinsForOptin }, { withCredentials: true });
    }

    GetCoinBalnce(): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(apiPathsConfig.slotRaces['racesCoinBalance'], {}, { withCredentials: true });
    }

    updateNotificationStatus(promoId: number, slotId: number, notificationStatus: boolean): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(apiPathsConfig.slotRaces['raceUpdateNotification'], { promoId, slotId, notificationStatus }, { withCredentials: true });
    }

    notificationPopupTimeout(): number {
        const config = this.configProviderService.provideSlotRacesConfig();
        return config.notificationPopupTimeout;
    }

    addErrorMessage(errCode: number): void {
        this.messageQueue.clear();
        const msg = this.getErrorMessage(errCode);
        if (msg) {
            this.messageQueue.addError(msg, MessageLifetime.Single, 'casinocorelobby');
            window.scrollTo(0, 0); //added so that the error message is visible to the user as vanilla scroll is not wokring properly here. Assumption agreed upon after discussion is that the error message panel would always be at the top of the lobby
        }
    }

    getErrorMessage(errCode: number): string {
        if (this.contentData && this.contentData.errorMessages && this.contentData.errorMessages.versionedList) {
            if (this.contentData.errorMessages.versionedList[errCode]) return this.contentData.errorMessages.versionedList[errCode]!;
            else return this.contentData.errorMessages.versionedList['defaultErrorCode']!;
            //add this to sitecore
        }
        return '';
    }

    globalTimerCountDown(): void {
        this.timer = setInterval(() => {
            this.globalTimerPublisher.next({ timerInterval: this.globalTimerInterval });
        }, this.globalTimerInterval);
    }

    forceOptin() {
        const forceOptinRace = sessionStorage.getItem('ForceOptin');
        if (forceOptinRace) {
            const parsedForceOptinRace = (forceOptinRace && JSON.parse(forceOptinRace)) || {};
            sessionStorage.removeItem('ForceOptin');
            const promoId = parsedForceOptinRace.promoId;
            const slotId = parsedForceOptinRace.slotUniqueId;
            const coinsForOptin = parsedForceOptinRace.coinsForOptin;
            this.updateOptinStatus(promoId, slotId, coinsForOptin).subscribe(
                () => {
                    this.forcRacepublisher.next({ race: parsedForceOptinRace });
                },
                (error: SlotRacesResponseError) => {
                    this.addErrorMessage(error.errorCode);
                },
            );
        }
    }
    forceCoinBalance() {
        const forceCoinBalance = sessionStorage.getItem('forceCoinBalance');
        if (forceCoinBalance) {
            const parsedForceCoinBalance = (forceCoinBalance && JSON.parse(forceCoinBalance)) || {};
            sessionStorage.removeItem('forceCoinBalance');
            this.GetCoinBalnce().subscribe(
                (coinBal: any) => {
                    this.forceCoinBalancepublisher.next({ coinBal: coinBal, race: parsedForceCoinBalance });
                },
                (error: SlotRacesResponseError) => {
                    this.addErrorMessage(error.errorCode);
                },
            );
        }
    }

    getCurrentRaces(): any {
        const liveRaces = this.liveRacesPublisher.value;
        const upcomingRaces = this.upcomingRacesPublisher.value;
        const data = {
            liveNow: liveRaces.liveNow || [],
            upcomingToday: upcomingRaces.upcomingToday || [],
            upcomingTomorrow: upcomingRaces.upcomingTomorrow || [],
        };
        return data;
    }

    private updateLobbyNavigation(): void {
        const configs = this.configProviderService.provideSlotRacesConfig();
        if (configs.disableWhenNoPromotions) {
            combineLatest(this.liveRacesData, this.upcomingRacesData).subscribe((races: any) => {
                let newNavCategoryVisibility: boolean;
                if (
                    races &&
                    ((races[0] && races[0].liveNow && races[0].liveNow.length) ||
                        (races[1] &&
                            ((races[1].upcomingToday && races[1].upcomingToday.length) ||
                                (races[1].upcomingTomorrow && races[1].upcomingTomorrow.length))))
                ) {
                    newNavCategoryVisibility = true;
                } else {
                    newNavCategoryVisibility = false;
                }
                if (newNavCategoryVisibility !== this.isNavCategoryVisible) {
                    this.isNavCategoryVisible = newNavCategoryVisibility;
                    this.updateNavigationCategories.next(true);
                }
            });
        }
    }

    setSlotRacesParams(lobbyType: string): void {
        this.slotRacesCategoryId = this.casinoManager.getSlotRaceCategoryId(lobbyType);
        const configs = this.configProviderService.provideSlotRacesConfig();
        const features = this.configProviderService.provideFeaturesConfig();
        if (this.casinoManager.IsFeatureEnabledforLobbytype(lobbyType, features.enableSlotRacesForLobbyType)) {
            if (this.user.isAuthenticated || (!this.user.isAuthenticated && configs.enablePreLogin)) {
                this.isSlotRacesEnabled = true;
            }
        }
    }

    gameClose(category: string): void {
        if (this.isSlotRacesEnabled) {
            const slotRacesCategory = this.slotRacesCategoryId;
            const cardsCarousel = document.getElementById('casinotournamentscards');
            if (cardsCarousel) {
                this.startLiveSlotPolling();
                this.getRaces();
            } else if (category === slotRacesCategory) {
                this.getRaces();
            }
        }
    }

    getOrderedGames(race: any = []): string[] {
        const lmtGames = this.categoryMap?.length && this.categoryMap.filter((item: any) => item.categoryname == race.promoId)[0]?.gamelist;
        if (lmtGames?.length > 0) {
            const tempArray: any[] = [];
            const patGames = race.gameVariantList;
            for (let i = 0; i < lmtGames.length; i++) {
                if (patGames.includes(lmtGames[i].game)) {
                    tempArray.push(lmtGames[i].game);
                }
            }
            return Array.from(new Set(tempArray.concat(patGames)));
        } else {
            return race.gameVariantList;
        }
    }
    updateCoinIcon(value: boolean) {
        if (value) {
            this.isCoin = value;
        }
    }
    getcoinValue() {
        return this.isCoin;
    }

    selectedEntryFilter(entryFilter: any) {
        if (entryFilter) {
            this.selectedFilteredtOption = entryFilter;
        }
    }
    getselectedEntryFilter() {
        return this.selectedFilteredtOption;
    }
    getlmtData(lmtData: LobbyItem) {
        this.categoryMap = lmtData.categoriesMap?.values()?.next()?.value?.subcategories;
    }

    bindCoinImg(race: any, coinImg: string) {
        const raceObj = {
            isDisplayCoin: false,
            coinSrc: '',
        };
        if (race) {
            if (race?.additionalParams?.awardType !== 'COINS' && race?.additionalParams?.isCoinAward === 'YES') {
                raceObj.isDisplayCoin = true;
                raceObj.coinSrc = coinImg;
            } else {
                raceObj.isDisplayCoin = false;
                raceObj.coinSrc = '';
            }
        }
        return raceObj;
    }
    getCoinDetails(): Observable<any> {
        return this.api.get('CoinAnimation/GetCoinDetails', {}, { responseType: 'json', withCredentials: true, showSpinner: false });
    }
}
