import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { ConfigProviderService } from '../../config-provider/config-provider.service';
import { GlobalTranslateComponent } from '../../directives/globaltranslate/globaltranslate.component';
import { CasinoManager } from '../../shared/casino-manager.service';
import { GameLaunchPlaces } from '../../shared/game-launch-places.service';
import { CasinoCoreGameLaunchFactoryService } from '../../shared/gamelaunchfactory.service';
import { GameLaunch } from '../../shared/models/gamelaunch.model';

@Component({
    selector: 'cc-game-detail-buttons',
    templateUrl: 'game-detail-buttons.component.html',
    standalone: true,
    imports: [NgIf, GlobalTranslateComponent],
})
export class GameDetailButtonsComponent implements OnInit {
    @Input() game: any;
    @Input() launchType: string;
    @Input() place: any;
    @Input() cssClass: any;
    twoButtons: any;
    isInhouseGame: any;
    constructor(
        private gameLaunchService: CasinoCoreGameLaunchFactoryService,
        private configProviderService: ConfigProviderService,
        private casinoManager: CasinoManager,
        private gameLaunchPlaces: GameLaunchPlaces,
    ) {}
    ngOnInit() {
        const clientSideConfig = this.configProviderService.provideClientSideConfig();
        this.twoButtons = clientSideConfig.playForFunAndRealButtons;
        this.isInhouseGame = this.launchType === 'inhouse';
    }
    goToPlayGame(mode: boolean) {
        const gameModel = new GameLaunch();
        gameModel.game = this.game;
        gameModel.isTouch = this.casinoManager.isTouch();
        gameModel.lobbyType = this.game.lobbyType;
        gameModel.gamePosition = 0;
        gameModel.categoryType = this.game.categoryType;
        gameModel.from = this.gameLaunchPlaces.playNowOnDetailPage.toLocaleString();
        gameModel.replaceRouteInHistory = false;
        gameModel.isDemoLaunch = mode;
        gameModel.skipUnfinGame = false;
        gameModel.gameLaunchedFrom = '';
        gameModel.gameSection = this.game.gameSection;
        gameModel.gameContainer = 'PageContainer';
        gameModel.returnUrl = window.location.href;
        const gameLaunchService: any = this.gameLaunchService.getGameLaunchService();
        gameModel.clickTimeStamp = gameLaunchService.currentTimeStamp();
        gameLaunchService.launchGame(gameModel);
    }
}
