<div class="image-load-div">
    <img
        [ngStyle]="{ 'border-radius': icon?.gameTileBorderRadius }"
        *ngIf="!disableLazyLoading && isDll && enableFallback"
        (onStateChange)="onImageStateChange($event)"
        [lazyLoad]="src"
        [defaultImage]="defaultSrc"
        (error)="onGameIconError(src)" />
    <img
        [ngStyle]="
            isImageLoaded
                ? { 'background-color': 'unset', 'border-radius': icon?.gameTileBorderRadius }
                : { 'border-radius': icon?.gameTileBorderRadius }
        "
        *ngIf="!disableLazyLoading && isDll && !enableFallback"
        [lazyLoad]="src"
        (onStateChange)="onImageStateChanged($event)"
        [defaultImage]="isImageLoaded ? src : defaultSrc"
        (error)="onGameIconError(src)" />
    <img
        *ngIf="!disableLazyLoading && !isDll"
        [src]="lazySrc"
        ccIntersectionObserver
        [intersectionDebounce]="0"
        (visibilityChange)="onVisibilityChanged($event)"
        (error)="onGameIconError(lazySrc)" />
    <img *ngIf="disableLazyLoading" [src]="src" (error)="onGameIconError(src)" />
</div>
