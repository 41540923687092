@if (displayPlayerStatsWidget) {
    <div class="casino-lobby-container-main">
        <div
            class="ps-background-image"
            [ngClass]="{
                'casino-backgroungImage-layer': parallaxInput && parallaxInput.sitecoreData.backgroundImageLayer1,
                'casino-backgroungImage-parallax':
                    (parallaxInput && parallaxInput.sitecoreData.backgroundImageLayer2) ||
                    (parallaxInput && parallaxInput.sitecoreData.backgroundImageLayer3),
                'mobile': isMobile
            }">
            <cc-casino-core-lazy-placeholder
                [inputData]="{ subCategory: parallaxInput }"
                [componentName]="'ParallaxAnimationComponent'"></cc-casino-core-lazy-placeholder>
            <div class="player-stats" (window:resize)="onResized()">
                @if (isMobile) {
                    <div class="ps-m-container">
                        <div class="Stats-filter-list" [ngStyle]="{ 'white-space': 'nowrap' }" #StatsNavContainer>
                            @for (filter of filters; let i = $index; track filter) {
                                @if (filter.displayFilter) {
                                    <div
                                        class="header-btn btn btn-header filter-stats"
                                        *ngIf="filter.displayFilter"
                                        [ngStyle]="{
                                            'background': statsSitecoreData.filterNameBgColor,
                                            'font-size': filterTextFontsize,
                                            'font-weight': filterTextFontweight,
                                            'color': filterTextcolor
                                        }"
                                        [class.active]="filter.value === selectedTab"
                                        (click)="FilterType(filter, i)">
                                        {{ filter.firstValue }}
                                    </div>
                                }
                            }
                        </div>
                        @if (titleDisplay) {
                            <div class="p-title">
                                <span
                                    [ngStyle]="{
                                        'color': statsSitecoreData.titleTextColor,
                                        'font-size': mobileTitlefontSize,
                                        'font-weight': mobileTitlefontweight
                                    }"
                                    >{{ titleDisplay }}</span
                                >
                                <span
                                    [ngStyle]="{
                                        'color': statsSitecoreData.displayFilterNameColor,
                                        'font-size': mobileDisplayfilterNamefontsize,
                                        'font-weight': mobileDisplayfilterNamefontweight
                                    }"
                                    >{{ displayFilterName }}</span
                                >
                            </div>
                        }
                        @if (subtitleDisplay) {
                            <div
                                class="p-subtitle"
                                [ngStyle]="{
                                    'color': statsSitecoreData.subtitleTextColor,
                                    'font-size': mobileSubtitlefontsize,
                                    'font-weight': mobileSubtitlefontweight
                                }">
                                {{ subtitleDisplay }}
                            </div>
                        }
                        <div class="ps-game-widget-m" #statsWidgetMobile>
                            @if (!filterSwitched) {
                                <div class="ps-game-tiles-m" [ngClass]="{ 'slides-align': slidesCenterAlign }">
                                    @for (stats of playerStatsData; let i = $index; track stats) {
                                        <div class="ps-game-tile-m tile-{{ i }} tile-type-{{ stats.icon }}">
                                            <cc-game-lobby
                                                class="icon-type small-icon"
                                                id="gameIdentifier_{{ stats.gameId }}"
                                                [game]="stats"
                                                [gamePosition]="i"
                                                [category]="category"
                                                [gameLaunchedFrom]="'multiplier widget'"
                                                [gameIndex]="i + 1">
                                            </cc-game-lobby>
                                            <div class="multiplier-digits-m digits-{{ i }}" [style.borderColor]="multiplierBorderColor">
                                                @for (multipliers of stats.multiplierDisplay; let i = $index; track multipliers) {
                                                    <div
                                                        class="multiplier-digit"
                                                        [ngStyle]="{
                                                            'color': multiplierColor,
                                                            'background': multiplierBgColor,
                                                            'margin-left': i === 0 ? '0px' : '1px'
                                                        }">
                                                        {{ multipliers }}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            <div class="swipper-floor-image-m"></div>
                        </div>
                    </div>
                } @else {
                    <div class="ps-container">
                        <div class="ps-container-left">
                            <div class="alignitems">
                                <div class="ps-navContainer">
                                    <div class="Stats-filter-list" [ngStyle]="{ 'white-space': 'nowrap' }" #StatsNavContainer>
                                        @for (filter of filters; let i = $index; track filter) {
                                            @if (filter.displayFilter) {
                                                <div
                                                    class="header-btn btn btn-header filter-stats"
                                                    [ngStyle]="{
                                                        'background': statsSitecoreData.filterNameBgColor,
                                                        'font-size': filterTextFontsize,
                                                        'font-weight': filterTextFontweight,
                                                        'color': filterTextcolor
                                                    }"
                                                    [class.active]="filter.value === selectedTab"
                                                    (click)="FilterType(filter, i)">
                                                    {{ filter.firstValue }}
                                                </div>
                                            }
                                        }
                                    </div>
                                    @if (leftFilterScrollButton || rightFilterScrollButton) {
                                        <div class="filter-arrow-container">
                                            <div class="casino-carousel-bg left-sec">
                                                <div
                                                    class="scroll-btn theme-left left"
                                                    [ngStyle]="{ background: statsSitecoreData.filterCarousalIconBgColor }"
                                                    [class.disable]="!leftFilterScrollButton"
                                                    (click)="onMoveFilterContainer(true, $event)"></div>
                                            </div>
                                            <div class="casino-carousel-bg right-sec">
                                                <div
                                                    class="scroll-btn theme-right right"
                                                    [ngStyle]="{
                                                        background: statsSitecoreData.filterCarousalIconBgColor,
                                                        color: filterCarousalIconColor
                                                    }"
                                                    [class.disable]="!rightFilterScrollButton"
                                                    (click)="onMoveFilterContainer(false, $event)"></div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                @if (titleDisplay) {
                                    <div class="p-title">
                                        <span
                                            [ngStyle]="{
                                                'color': statsSitecoreData.titleTextColor,
                                                'font-size': titleFontsize,
                                                'font-weight': titleFontweight
                                            }"
                                            >{{ titleDisplay }}</span
                                        >
                                        <span
                                            [ngStyle]="{
                                                'color': statsSitecoreData.displayFilterNameColor,
                                                'font-size': displayFilterNameFontsize,
                                                'font-weight': displayFilterNameFontweight
                                            }"
                                            >{{ displayFilterName }}</span
                                        >
                                    </div>
                                }
                                @if (subtitleDisplay) {
                                    <div
                                        class="p-subtitle"
                                        [ngStyle]="{
                                            'color': statsSitecoreData.subtitleTextColor,
                                            'font-size': subtitleFontsize,
                                            'font-weight': subtitleFontweight
                                        }">
                                        {{ subtitleDisplay }}
                                    </div>
                                }
                            </div>
                        </div>
                        <div class="ps-container-right">
                            <div class="align-swipper-items">
                                <div class="stats-swiper">
                                    <vn-swiper #swiperRef [swiperOptions]="swiperOptionsPlayerStats">
                                        @if (!filterSwitched) {
                                            @for (stats of playerStatsData; let i = $index; track stats) {
                                                <div class="swiper-slide">
                                                    <div class="ps-game-tile tile-{{ i }} tile-type-{{ stats.icon }}">
                                                        <cc-game-lobby
                                                            class="icon-type small-icon"
                                                            id="gameIdentifier_{{ stats.gameId }}"
                                                            [game]="stats"
                                                            [gamePosition]="i"
                                                            [category]="category"
                                                            [gameLaunchedFrom]="'multiplier widget'"
                                                            [gameIndex]="i + 1">
                                                        </cc-game-lobby>
                                                        <div class="multiplier-digits digits-{{ i }}" [style.borderColor]="multiplierBorderColor">
                                                            @for (multipliers of stats.multiplierDisplay; let i = $index; track multipliers) {
                                                                <div
                                                                    class="multiplier-digit"
                                                                    [ngStyle]="{
                                                                        'color': multiplierColor,
                                                                        'background': multiplierBgColor,
                                                                        'margin-left': i === 0 ? '0px' : '1px'
                                                                    }">
                                                                    {{ multipliers }}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        }
                                    </vn-swiper>
                                </div>
                                <div class="swipper-floor-image"></div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
}
