import { NgIf } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

import { CasinoManager } from '@casinocore/platform/core';
import { DeviceService, NavigationService, TrackingService } from '@frontend/vanilla/core';

@Component({
    selector: 'cc-categoryicon-subtitle-moreinfo',
    templateUrl: 'categoryicon-subtitle-moreinfo.component.html',
    standalone: true,
    imports: [NgIf],
})
export class CategoryiconSubtitleMoreinfoComponent implements OnInit, AfterViewInit {
    categorymoreinfo: any;
    categoryname: string;
    seeAllCta: string;
    jackpotBadgeStatus: string;
    isTouch: boolean;
    seeallctaurl: string;
    jackpotMoreInfoCloseCtaText: string;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CategoryiconSubtitleMoreinfoComponent>,
        private sanitizer: DomSanitizer,
        private casinoManager: CasinoManager,
        private navigation: NavigationService,
        private deviceService: DeviceService,
        private tracking: TrackingService,
    ) {}

    ngOnInit(): void {
        this.categorymoreinfo = this.sanitizer.bypassSecurityTrustHtml(this.data?.subcategoryinfo);
        this.categoryname = this.data?.subcategorytittle;
        this.jackpotBadgeStatus = this.data?.jackpotBadgeStatus;
        this.jackpotMoreInfoCloseCtaText = this.data?.jackpotMoreInfoCloseCtaText;
        if (this.data?.seeAllCtaText && this.data?.showSeeAllGamesCta) {
            this.seeAllCta = this.data?.seeAllCtaText;
        }
        this.seeallctaurl = this.data?.seeallctaurl;
        this.isTouch = this.casinoManager.isTouch();
        window.addEventListener('resize', this.adjustModalSize.bind(this));
    }
    closeModel(trigger: boolean) {
        if (trigger) {
            this.jackpotMoreInfoCloseTracking();
        }
        this.dialogRef.close();
    }
    adjustModalSize() {
        const jackpotMoreinfoContent = document.getElementById('jackpot-Subcategorymodal');
        if (jackpotMoreinfoContent) {
            const contentHeight = jackpotMoreinfoContent.scrollHeight;
            const maxHeight = window.innerHeight * 0.9;
            if (contentHeight > maxHeight) {
                jackpotMoreinfoContent.style.height = maxHeight + 'px';
            } else {
                jackpotMoreinfoContent.style.height = 'auto';
            }
        }
    }

    seeAll(ctaUrl: any): void {
        this.seeAllTracking();
        if (ctaUrl) {
            this.navigation.goTo(ctaUrl);
            this.closeModel(false);
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 500);
        }
    }

    ngAfterViewInit(): void {
        this.adjustModalSize();
        this.setAnimation();
    }
    setAnimation() {
        if (this.deviceService.isiOS) {
            const jackpotInfoContent = document.getElementsByClassName('custom-dialog-container')[0] as HTMLElement;
            if (jackpotInfoContent) {
                jackpotInfoContent.style.opacity = '0';
                setTimeout(() => {
                    jackpotInfoContent.style.opacity = '1';
                    jackpotInfoContent.style.transition = 'opacity 1s';
                }, 300);
            }
        }
    }

    jackpotMoreInfoCloseTracking() {
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'slots',
            'component.LabelEvent': 'jackpot slots',
            'component.ActionEvent': 'close',
            'component.PositionEvent': this.categoryname,
            'component.LocationEvent': this.categoryname,
            'component.EventDetails': 'more info overlay',
        });
    }
    seeAllTracking() {
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'slots',
            'component.LabelEvent': 'jackpot slots',
            'component.ActionEvent': 'close',
            'component.PositionEvent': this.categoryname,
            'component.LocationEvent': 'more info overlay',
            'component.EventDetails': 'see all games',
        });
    }
}
