<div class="casino-lobby-container-main">
    <div class="casino-egv-modal-popup">
        <div id="casinoEmbeddedModal" class="casino-modal">
            <div class="modal-content">
                <div *ngIf="gameData">
                    <cc-embedded-game-view
                        [gameEmbedPop]="gameData"
                        [errorMessageQueue]="gameData.errorMessageQueue"
                        [lobbyType]="gameData.game.lobbyType"
                        (sendDataToEmbeddedGamePopUp)="eventFromEmbeddedGameView($event)"
                        (sendDialogCloseEventToEmbeddedGamePopUp)="dialogCloseEventFromEmbeddedGameView($event)" />
                </div>
            </div>
        </div>
    </div>
</div>
