@if (!isLegacy) {
    <cs-cta
        [kind]="kind"
        [variant]="variant"
        [size]="size"
        [casinoDsClass]="casinoDsClass"
        [disabled]="disabled"
        [cta_text]="'' + (coinsForOptin || cta_text)">
        <cs-class-icon
            [startIconClass]="startIconClass"
            slot="end"
            [style.display]="(startIconClass || '').trim() === '' ? 'none' : 'unset'"></cs-class-icon>

        <cs-image-icon
            [imgSrc]="imgSrc"
            slot="end"
            style="position: relative"
            [style.display]="(imgSrc || '').trim() === '' ? 'none' : 'unset'"></cs-image-icon>
    </cs-cta>
}

@if (isLegacy) {
    <button [class.btn-primary]="kind === 'primary'" class="btn btn-primary" [disabled]="disabled" data-decoratorclass-host data-legacy="true">
        {{ cta_text }}
        @if (coinsForOptin) {
            <ng-container>
                <span class="sr-card-total-coin">
                    <span>{{ coinsForOptin }}</span>
                    @if (imgSrc) {
                        <img class="coin-img" [src]="imgSrc" alt="Coin Image" width="23px" height="23px" alt="image not found" />
                    }
                </span>
            </ng-container>
        }
        @if (startIconClass) {
            <i class="alert-icon" [class]="startIconClass"></i>
        }
    </button>
}
