import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, HostListener, Input, OnInit, computed, input, signal } from '@angular/core';

import { CasinoLobbyService, CasinoManager, ConfigProviderService, ConstService } from '@casinocore/platform/core';
import { DeviceService, UserService } from '@frontend/vanilla/core';
import { RxFor } from '@rx-angular/template/for';
import { Subscription } from 'rxjs';

import { LMTCategoryWrapperComponent } from '../lmt-category-wrapper/lmt-category-wrapper.component';
import { LMTSubCategoryWrapperComponent } from '../lmt-sub-category-wrapper/lmt-sub-category-wrapper.component';

@Component({
    selector: 'cc-casino-page-container',
    templateUrl: 'casino-page-container.component.html',
    standalone: true,
    imports: [NgClass, NgIf, LMTCategoryWrapperComponent, NgFor, LMTSubCategoryWrapperComponent, RxFor],
})
export class CasinoPageContainerComponent implements OnInit {
    lobbyType = input.required<string>();
    categoryRoute = input.required<string>();
    @Input() gameContainer: string;
    @Input() seeAllProductLinkOverride: string;
    categoryInfo = computed(() => this.casinoLobbyService.getCategoryInfo(this.lobbyType(), this.categoryRoute()));
    currentCategoryInfo = computed(() => {
        return this.categoryInfo()?.categoryInfo;
    });
    bindCategory = computed(() => {
        if (!this.categoryInfo()?.categoryInfo) {
            this.casinoLobbyService.categoryNotFoundSubject.next(this.categoryRoute());
        }
    });
    isDefaultCategory = computed(() => this.casinoLobbyService.isDefaultCategory(this.lobbyType(), this.categoryRoute()));
    displayLMTCategoryData = computed(() => this.checkForLMTCategoryData());
    isLobbyHasNoSeo = computed(() => this.currentCategoryInfo()?.sitecoreData?.seoBoxes?.length > 0);
    lmtSubCategories = computed(() => {
        const featuresConfig = this.configProviderService.provideFeaturesConfig();
        const slotRacesConfig = this.configProviderService.provideSlotRacesConfig();
        const slotRacesCategory = this.casinoManager.getSlotRaceCategoryId(this.lobbyType());
        const isSlotRacesEnabled = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType(), featuresConfig.enableSlotRacesForLobbyType);
        const subCategories: any[] = [];
        if (this.currentCategoryInfo()?.lmtSubCategories?.length > 0) {
            for (let i = 0; i < this.currentCategoryInfo().lmtSubCategories.length; i++) {
                const subCatRoute: string = this.currentCategoryInfo().lmtSubCategories[i];
                const subCategoryInfo: any = this.casinoLobbyService.getCategoryInfo(this.lobbyType(), subCatRoute);
                if (subCategoryInfo && subCategoryInfo.categoryInfo && subCategoryInfo.categoryInfo.isSubCatVisible) {
                    let addCategory: boolean = true;
                    const isSlotRacesCategory =
                        slotRacesCategory == subCategoryInfo.categoryInfo.categoryid ||
                        subCategoryInfo.categoryInfo.parentCategoryId + slotRacesCategory == subCategoryInfo.categoryInfo.categoryid;
                    if (
                        slotRacesCategory &&
                        isSlotRacesCategory &&
                        (!isSlotRacesEnabled || (!slotRacesConfig.enablePreLogin && !this.user.isAuthenticated))
                    ) {
                        addCategory = false;
                    }
                    if (addCategory) {
                        subCategories.push(subCategoryInfo);
                    }
                }
            }
        }
        return subCategories;
    });
    hasLMTSubCategories = computed(() => this.lmtSubCategories().length > 0);
    visibleLmtSubCategories = computed(() => this.lmtSubCategories().slice(0, this.subCategoryLoadCount()));
    isTouch: boolean;
    lastScrollTop: number;
    gamesInCategory = computed(() => this.currentCategoryInfo()?.gamelist?.length > 0);
    subCategoryLoadCount = signal(2);
    subCategoryLoadLimit: number = 2;
    lobbyLoadSubscription: Subscription;

    constructor(
        private casinoLobbyService: CasinoLobbyService,
        private deviceService: DeviceService,
        private configProviderService: ConfigProviderService,
        private user: UserService,
        private casinoManager: CasinoManager,
    ) {}

    ngOnInit() {
        this.isTouch = this.deviceService.isTouch;
        this.bindCategory();
        this.setSubCategoryLoadCount();
    }

    checkForLMTCategoryData(): boolean {
        const discoverFeedConfig = this.configProviderService.provideDiscoverFeedConfig();
        const discoverFeedLmtkey: string =
            discoverFeedConfig.enableDiscoverFeed &&
            this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType(), discoverFeedConfig.discoverFeedLmtkey);
        if (this.currentCategoryInfo()?.categoryid) {
            const currentCategory: any = this.currentCategoryInfo();
            if (
                currentCategory?.categoryid?.toLowerCase() === ConstService.RECENT_GAMES_CATEGORY_ID ||
                currentCategory?.categoryid?.toLowerCase() === ConstService.FAVOURITE_GAMES_CATEGORY_ID
            ) {
                return true;
            } else if (
                discoverFeedConfig.enableDiscoverFeed &&
                discoverFeedLmtkey &&
                discoverFeedLmtkey?.toLowerCase() == currentCategory?.categoryid?.toLowerCase()
            ) {
                return true;
            } else if (this.isDefaultCategory()) {
                let result: boolean = false;
                const lmtSubCategories = currentCategory?.lmtSubCategories?.filter(Boolean);
                if (lmtSubCategories && lmtSubCategories.length > 0) {
                    result = false;
                } else if (currentCategory.gamelist && currentCategory.gamelist.length > 0) {
                    result = true;
                }
                return result;
            } else {
                return currentCategory.gamelist && currentCategory.gamelist.length > 0 && !this.isDefaultCategory();
            }
        }
        return false;
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: Event) {
        const scrollElement: any = event.target;
        if (scrollElement.scrollingElement) {
            const scrollTop = scrollElement.scrollingElement.scrollTop;

            //Getting next set of games in categories and sub categories.
            if (this.lastScrollTop > 0 && scrollTop > this.lastScrollTop) {
                // Load next LMT SubCategories
                this.loadNextSubCategories();

                this.casinoManager.loadNextGamesSet();
            }
            this.lastScrollTop = scrollTop;
        }
    }

    private setSubCategoryLoadCount(): void {
        const devSettings = this.configProviderService.provideDevSettingsConfig();
        if (devSettings) {
            if (this.gameContainer && this.gameContainer?.toLowerCase() === 'overlay' && devSettings.subCategoryLoadLimitOverlayOverride) {
                this.subCategoryLoadLimit = devSettings.subCategoryLoadLimitOverlayOverride;
                this.subCategoryLoadCount.set(devSettings.subCategoryLoadLimitOverlayOverride);
            } else if (devSettings.subCategoryLoadLimit) {
                this.subCategoryLoadLimit = devSettings.subCategoryLoadLimit;
                this.subCategoryLoadCount.set(devSettings.subCategoryLoadLimit);
            }
        }
    }

    private loadNextSubCategories(): void {
        if (this.hasLMTSubCategories() && this.lmtSubCategories().length > this.subCategoryLoadCount()) {
            this.subCategoryLoadCount.update((subCategoryLoadCount) => subCategoryLoadCount + this.subCategoryLoadLimit);
        }
    }
}
