import { NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { CasinoLobbyService, CasinoManager, ConfigProviderService } from '@casinocore/platform/core';
import { DeviceService } from '@frontend/vanilla/core';

import { ThumbnailNewgameComponent } from '../thumbnail-newgame/thumbnail-newgame.component';

@Component({
    selector: 'cs-thumbnail-section',
    templateUrl: 'thumbnail-section.component.html',
    standalone: true,
    imports: [NgIf, NgClass, NgStyle, ThumbnailNewgameComponent],
})
export class ThumbnailSectionComponent implements OnInit {
    @Input() lobbyType: string;
    category: any;
    isTouch: boolean;
    displayStaticThumbnail: boolean;
    thumnailHeaderHeight: any;
    isAuthenticated: boolean;
    readonly isMobileWidth = this.deviceService.isMobileWidth;
    desktopbreakpointforMobile: boolean;

    constructor(
        private deviceService: DeviceService,
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
        private casinoManager: CasinoManager,
    ) {}

    ngOnInit() {
        this.category = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(this.lobbyType);
        this.isTouch = this.casinoManager.isTouch();
        this.desktopbreakpointforMobile = this.isTouch && this.isMobileWidth();
        const currentCategory = this.category?.categoryInfo;
        this.displayThumbnail(currentCategory);
    }
    displayThumbnail(category: any) {
        const thumbnailConfig = this.configProviderService.provideThumbnailConfig();
        if (
            category &&
            category.sitecoreData &&
            category.sitecoreData.gameThumbnail &&
            category.sitecoreData.gameThumbnail.length > 0 &&
            this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, thumbnailConfig.displayStaticThumbnail)
        ) {
            this.displayStaticThumbnail = true;
            if (this.isTouch) {
                this.getHeaderHeight();
            }
        } else {
            this.displayStaticThumbnail = false;
        }
    }

    getHeaderHeight() {
        const hdrEle = document.querySelector('header');
        if (hdrEle && hdrEle.offsetHeight) {
            this.thumnailHeaderHeight = hdrEle.getBoundingClientRect().height;
        }
    }

    closeThumbnailBox(thumdnailData: any) {
        this.displayStaticThumbnail = thumdnailData;
    }
}
