import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csQuickInfo', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: quickInfoClientConfigFactory,
})
export class QuickInfoClientConfig {
    quickInfo: {
        cta: string;
        gameProviderHeader: string;
        preLogincta: string;
    };
}

export function quickInfoClientConfigFactory(service: LazyClientConfigService) {
    return service.get(QuickInfoClientConfig);
}
