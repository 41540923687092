import { IGameShowHubSectorItem } from './game-show-hub.model';
import { IImmersiveLobbySectorItem } from './immersive-lobby.model';
import { ILiveCasinoLoungeCustomLink } from './live-casino-lounge.model';

export interface IContentImage {
    src: string;
    alt?: string;
    height?: number;
    width?: number;
}

export enum ImageType {
    SVG,
    Image,
    Lottie,
}

export interface IContentLink {
    url: any;
    attributes: any;
    title: string;
    text: string;
}

export interface IContentVideo {
    id: string;
    src: string;
    width?: number;
    height?: number;
}

export interface IOption {
    title: string;
    key: string;
    condition: string;
    selected: boolean;
    selectedValue: number;
    defaultValue: number;
}

export const CUSTOM_LINK_TYPE_DRAWER = 'Drawer';
export const CUSTOM_LINK_TYPE_LINK = 'Link';

export interface IOnboarding {
    backgroundImage: IContentImage;
    actorImage: IContentImage;
    title: string;
    subTitle: string;
    ctaText: IContentLink;
    tourItems: Array<IOnboardingTourItem>;
    styles: any;
    condition: string;
}

export interface IOnboardingTourItem {
    backgroundImage: IContentImage;
    actorImage: IContentImage;
    title: string;
    subTitle: string;
    primaryCTAText: IContentLink;
    secondaryCTAText: IContentLink;
    styles: any;
    condition: string;
}

export class ImmersiveLobbySkeletonLoadingConfiguration {
    logo?: string;
    lottieUrl?: string;
    spinnerType: string;
    backgroundColor?: string;
    loadingText?: string;
    loadingTextColor?: string;
    gameShowLogoUrl?: string;
    ferrisSpinningWheelUrl?: string;
}

export class Dimension {
    width: string;
    height: string;
    y: string;
    x: string;
}

export interface IMobileLandscapeConfiguration {
    minWidth: number;
    maxWidth: number;
    minHeight: number;
    maxHeight: number;
}

export interface ICustomLinkDrawerContent {
    title: string;
    content: string;
    isOnboarding: boolean;
    onboardingCTAText: string;
    image?: IContentImage;
    imageCTA?: IContentLink;
    imageLink?: IContentLink;
    terms: string;
    primaryCTA?: IContentLink;
    condition: string;
    path: string;
}

export interface ICustomLinkModalInfo {
    index: number;
    event: Event;
    panelClass: string;
    isMobilePhone: boolean;
    isStats: boolean;
    modalItem: IGameShowHubSectorItem | ILiveCasinoLoungeCustomLink | IImmersiveLobbySectorItem;
    launchRealtiveUri: string;
    from: string;
}

export class ImmersiveLobbyConfig {
    lobbyType: string;
    loadingConfig: ImmersiveLobbySkeletonLoadingConfiguration;
    onboardingDataPath: string;
    enableSupportForLandscape: boolean;
    mobileLandscapeConfig: IMobileLandscapeConfiguration;
    defaultRenderer: string;
    fallbackRenderer: string;
    fallbackForIpad: boolean;
    idleTimeout: number;
    setMapAdjustedTimeout: number;
    gameLaunchUri: string;
    pauseLottieAnimations: boolean;
}

export class LobbyGameInfo {
    gameId: string;
    gameIconUrl: string;
    icon?: number;
    isLoaded: boolean;
    isDisabled: boolean;
    isGameIconFallback: boolean;
}

export enum LottieAnimationStatus {
    Play,
    Pause,
}

class ProgressBar {
    min: number;
    max: number;
    value: number;
    step: number;

    constructor(min: number, max: number, length: number) {
        this.min = min;
        this.max = max;
        this.step = max / length;
        this.value = 0;
    }
}

export class Wizard {
    currentIndex: number;
    firstIndex: number;
    lastIndex: number;
    totalItems: number;
    showPrev: boolean;
    progressBar: ProgressBar;

    constructor(length: number) {
        this.currentIndex = 0;
        this.totalItems = length;
        this.lastIndex = length - 1;
        this.showPrev = false;
        this.progressBar = new ProgressBar(0, 100, length);
    }
}
export class ISchedule {
    gameId: string;
    position: string;
    timestamp: Date;
}
export class ICustomLinkDrawer {
    content: Array<ICustomLinkDrawerContent>;
}

export class IPathBasedEnableORDisable {
    disablePaths: Array<string>;
    disableDelay: string;
    enablePaths: Array<string>;
    enableDelay: string;
}
