import { NgClass, NgFor, NgIf, NgStyle, UpperCasePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
    CasinoCoreGamelaunchService,
    CasinoCoreNpmParamsService,
    CasinoLobbyService,
    CasinoManager,
    CasinoWidgetService,
    ConfigProviderService,
    DesignSystemEnablerService,
    FavouriteService,
    FreespinSummaryService,
    GameDataManager,
    GameLaunch,
    JackpotDataService,
    NewQuickInfoService,
    OpenLoginDialog,
    UrlUtilityService,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, GameTileCtaComponent } from '@frontend/casino-ui';
import { CookieService, DeviceService, DslService, TrackingService, UserService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

import { QuickInfoFreeSpinsComponent } from '../nav-quick-info-free-spins/quick-info-free-spins.component';
import { QuickInfoGameDescriptionComponent } from '../nav-quick-info-game-description/quick-info-game-description.component';
import { QuickInfoMoreInfoComponent } from '../nav-quick-info-more-info/quick-info-more-info.component';

@Component({
    selector: 'cc-new-quick-info-modal',
    templateUrl: 'new-quick-info-modal.component.html',
    standalone: true,
    imports: [
        NgIf,
        NgStyle,
        NgClass,
        NgFor,
        QuickInfoGameDescriptionComponent,
        QuickInfoMoreInfoComponent,
        QuickInfoFreeSpinsComponent,
        UpperCasePipe,
        GameTileCtaComponent,
        DecoratorClassDirective,
    ],
})
export class NewQuickInfoModalComponent implements OnInit, OnDestroy {
    defaultSrc: string;
    hasVolatility: boolean;
    volatilityTypeText: any;
    isLaunchedFromUrl: any = false;
    gameLaunchedFrom: any;
    boxWidth: number;
    boxWidthInt: number;
    mobileQuickInfoSrc: string;
    gameProvider: string;
    vendorLogo: any = null;
    provider: string;
    gamePosition: number;
    game: any;
    lobbyType: string;
    stickers: any = [];
    isStickersForUk: boolean = false;
    favObs: Subscription;
    isTouch: boolean;
    demoEnabled: boolean;
    isMobile: boolean;
    favouriteStyle: string = 'theme-favorite-i';
    unFavouriteStyle: string = 'theme-favorite';
    displayFavouritesOnPreLogin: boolean = true;
    useFavouriteGames: boolean = false;
    isFavourite: boolean = false;
    jackpotHandle: any;
    isHotJackpotGroup: boolean = false;
    jackpotData: any = null;
    jackpotValue: any = null;
    jpBgColor: any;
    jpTextCOlor: any;
    hotIcon: any;
    newQuickInfoStyle: any = false;
    landscapeMode: boolean;
    isGameDescription: boolean = true;
    isMoreInfo: boolean;
    isFreeSpins: boolean;
    categoryType: any;
    gridPosition: any;
    gameHomeUrl: string;
    demoLinkText: string;
    cta: string;
    demoEnabledForProvider: boolean = true;
    gameTitle: string;
    gameDescription: string;
    gymlCategory: any;
    gymlGames: any = [];
    gameContainer: string;
    showFreeSpinCount: boolean;
    enableFreeSpinSummary: boolean;
    enableFreeSpinsOnQuickInfo: boolean;
    freespinCountGame: any;
    freespinObservable: any;
    freespinRewardDataObservable: any;
    rewardIds: any[] = [];
    rewardDetails: any;
    volatility: string;
    volatilityInfo: string;
    reels: string;
    lines: string;
    maxPayout: string;
    volatilityImgLink: string;
    isShowValuePart: boolean = true;
    showstickersinoverlay: boolean;
    showjackpotvalueinoverlay: boolean;
    isOnlyMaxpayout: boolean = false;
    isOnlyReels: boolean = false;
    enableHotIcon: boolean;
    showVolatilityTable: boolean;
    tabPosition: number;
    tabName: string;
    globalConfig: any;
    stickerKey: any = [];
    stickerStyles: any;
    enableStickerStyles: boolean;
    jpRibbonStyles: { jpBgColor: string; jpTextColor: string };
    isLegacy: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<NewQuickInfoModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private casinoNpmParamsService: CasinoCoreNpmParamsService,
        private tracking: TrackingService,
        private elementRef: ElementRef,
        private configProviderService: ConfigProviderService,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private user: UserService,
        private favouriteService: FavouriteService,
        private cookie: CookieService,
        private deviceService: DeviceService,
        private urlUtilityService: UrlUtilityService,
        private casinoManager: CasinoManager,
        private jackpotDataService: JackpotDataService,
        private casinoWidgetService: CasinoWidgetService,
        private loginDialog: OpenLoginDialog,
        private newQuickInfoService: NewQuickInfoService,
        private casinoLobbyService: CasinoLobbyService,
        private freespinSummaryService: FreespinSummaryService,
        private gameDataManager: GameDataManager,
        private dslService: DslService,
        private designSystemEnablerService: DesignSystemEnablerService,
    ) {}
    ngOnInit() {
        this.setLocalData();
        this.assignConfig();
        this.defaultSrc = this.urlUtilityService.getGrayImageUrl();
        this.isTouch = this.casinoManager.isTouch();
        this.isMobile = this.deviceService.isMobilePhone;
        this.favObs = this.favouriteService.favouriteGamesFeedObservable.subscribe((favouriteGames: any) => {
            if (favouriteGames) {
                this.isFavourite = this.favouriteService.checkForFavouriteGame(this.game);
            }
        });
        this.assignFeatures();
        this.newQuickInfoService.getGameDescription(this.game.gameId, this.game.provider)?.subscribe((res: any) => {
            if (res) {
                this.gameDescription = res?.gameDescription;
            }
        });
        if (this.showVolatilityTable) {
            this.newQuickInfoService.getGameCatalogDetails(this.game.gameId, this.game.provider)?.subscribe((res) => {
                if (res) {
                    this.volatility = res.volatility;
                    this.reels = res.reels;
                    this.lines = res.lines;
                    if (
                        (!this.reels || this.reels === 'null') &&
                        (!this.lines || this.lines === 'null') &&
                        (!this.maxPayout || this.maxPayout == '' || this.maxPayout == 'null')
                    ) {
                        this.isShowValuePart = false;
                    } else {
                        this.isShowValuePart = true;
                    }
                    if (!this.volatility || this.volatility === 'N/A') {
                        this.hasVolatility = false;
                    } else {
                        this.hasVolatility = true;
                        this.setVoltalityInformation(this.volatility);
                    }
                    if (
                        (!this.reels || this.reels === 'null' || this.reels === '0') &&
                        (!this.lines || this.lines === 'null' || this.lines === '0') &&
                        this.maxPayout &&
                        this.maxPayout != 'null'
                    ) {
                        this.isOnlyMaxpayout = true;
                    } else if (
                        this.reels &&
                        this.reels !== 'null' &&
                        this.reels !== '0' &&
                        (!this.lines || this.lines === 'null' || this.lines === '0') &&
                        (!this.maxPayout || this.maxPayout == '' || this.maxPayout == 'null')
                    ) {
                        this.isOnlyReels = true;
                    }
                }
            });
        } else {
            this.isOnlyReels = false;
            this.isShowValuePart = false;
            this.hasVolatility = false;
            this.isOnlyMaxpayout = false;
            this.isOnlyReels = false;
        }
        this.setJackpotValue();
        this.subscribtToJackpotFeed();
        this.getMoreInfoGames();
        this.isLegacy = !this.designSystemEnablerService.isEnabled();
    }
    ngAfterViewInit() {
        if (this.isStickersForUk) this.gameStickerStyleForUk();
        else this.gameStickerStyle();
        //this.checkForAdjustment();
    }
    assignFeatures() {
        let features: any = this.configProviderService.provideFeaturesConfig();
        this.globalConfig = this.configProviderService.provideGlobalConfig();
        this.enableStickerStyles = features.enableStickerStyles;
        this.showVolatilityTable = features.showVolatilityTable;
        this.dslService.evaluateExpression<boolean>(features.hideFavouritesOnPreLogin).subscribe((hideFavouritesOnPreLogin) => {
            if (hideFavouritesOnPreLogin && !this.user.isAuthenticated) {
                this.displayFavouritesOnPreLogin = false;
            }
        });
        let vendorsWithOnlyRealMode: any = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.game.lobbyType,
            this.configProviderService.provideGameConfig().vendorsWithOnlyRealModeLobbyType,
        );
        let demoModeExcludedGamesRegex = this.configProviderService.provideGameConfig().demoModeExcludedGamesRegex;
        demoModeExcludedGamesRegex = demoModeExcludedGamesRegex?.split(',');
        if (vendorsWithOnlyRealMode && vendorsWithOnlyRealMode.indexOf(this.provider) > -1) {
            this.demoEnabledForProvider = false;
        } else if (demoModeExcludedGamesRegex && demoModeExcludedGamesRegex.length > 0) {
            demoModeExcludedGamesRegex?.forEach((regex: any) => {
                if (new RegExp(regex, 'g').test(this.game.gameId)) {
                    this.demoEnabledForProvider = false;
                }
            });
        }
    }
    onGameIconError(hotIcon: any): void {
        this.defaultSrc = hotIcon;
    }
    onGameIconError1(): void {
        this.mobileQuickInfoSrc = this.defaultSrc;
    }
    setLocalData() {
        this.boxWidth = this.data.boxWidth;
        this.boxWidthInt = this.data.boxWidthInt;
        this.mobileQuickInfoSrc = this.data.mobileQuickInfoSrc;
        this.vendorLogo = this.data.vendorLogo;
        this.demoEnabled = this.data.demoEnabled;
        this.gameProvider = this.data.gameProvider;
        this.provider = this.data.provider;
        this.game = this.data.game;
        this.gamePosition = this.data.gamePosition;
        this.gameLaunchedFrom = this.data.gameLaunchedFrom;
        this.lobbyType = this.data.lobbyType;
        this.stickers = this.data.stickers;
        this.isStickersForUk = this.data.isStickersForUk;
        this.useFavouriteGames = this.data.useFavouriteGames;
        this.hotIcon = this.data.hotIcon;
        this.newQuickInfoStyle = this.data.newQuickInfoStyle;
        this.landscapeMode = this.data.landscapeMode;
        this.categoryType = this.data.categoryType;
        this.gridPosition = this.data.gridPosition;
        this.gameHomeUrl = this.data.gameHomeUrl;
        this.cta = this.data.cta;
        this.gameTitle = this.game.name;
        this.gameContainer = this.game.gameContainer;
        this.isLaunchedFromUrl = this.data.isLaunchedFromUrl;
        this.showjackpotvalueinoverlay = this.data.showjackpotvalueinoverlay;
        this.showstickersinoverlay = this.data.showstickersinoverlay;
        this.enableHotIcon = this.data.enableHotIcon;
    }

    launchGame(game: any, isDemo: boolean): void {
        if (this.data && this.data.gameLaunchedFrom == 'RecentlyPlayed_widget_RecentlyPlayed') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'RecentlyPlayed_QuickInfo_Overlay',
                'component.ActionEvent': 'CTA_Click',
                'component.PositionEvent': this.game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this.data.lobbyType,
            });
        }
        if (this.data && this.data.gameLaunchedFrom == 'RecentlyPlayed_widget_Favourites') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'Favourites_QuickInfo_Overlay',
                'component.ActionEvent': 'CTA_Click',
                'component.PositionEvent': this.game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this.data.lobbyType,
            });
        }
        this.hideInfo();
        let gameModel = new GameLaunch();
        gameModel.game = game;
        gameModel.isTouch = this.isTouch;
        gameModel.gamePosition = this.gamePosition;
        gameModel.categoryType = this.categoryType;
        gameModel.from = 'q';
        gameModel.replaceRouteInHistory = false;
        gameModel.isDemoLaunch = isDemo;
        gameModel.skipUnfinGame = false;
        gameModel.gameLaunchedFrom = this.gameLaunchedFrom;
        gameModel.gridPosition = this.gridPosition;
        gameModel.returnUrl = this.gameHomeUrl;
        gameModel.appendRUrlToLoginDialogWindow = true;
        gameModel.gameContainer = this.gameContainer;
        gameModel.isMoreInfoOverlay = true;
        gameModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
        this.gameLaunchService.launchGame(gameModel);
    }

    subscribtToJackpotFeed() {
        const jackpotData = this.configProviderService.provideJackpotConfig();
        const enableTickingForJackpotOnGameTiles = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.lobbyType,
            jackpotData.enableJpTickingForLobbyType,
        );
        this.jackpotHandle = this.jackpotDataService.jackpotFeedObservable.subscribe(() => {
            this.setJackpotValue();
        });
        if (enableTickingForJackpotOnGameTiles) {
            this.jackpotDataService.jpTickingObservable.subscribe(() => {
                this.setJackpotValue();
            });
        }
    }
    // setJackpotValue() {
    //     let jackpotValue = this.jackpotDataService.getGameJackpotValue(this.game.gameId);
    //     let jackpotConfig = this.configProviderService.provideJackpotConfig();
    //     if(jackpotValue) {
    //         this.jackpotValue = jackpotValue.amount;
    //         this.isHotJackpotGroup = jackpotValue.isHotJackpotGroup;
    //         if (jackpotConfig.enableRibbonConfig) {
    //             this.jpBgColor = this.jackpotValue?.jackpotAttributes?.JackpotRibbonBgColor;
    //             this.jpTextCOlor = this.jackpotValue?.jackpotAttributes?.JackpotRibbonTextColor;
    //             if (this.jackpotValue.globalJackpotAttributes) {
    //                 this.jpBgColor = this.jackpotValue?.globalJackpotAttributes?.JackpotRibbonBgColor;
    //                 this.jpTextCOlor = this.jackpotValue?.globalJackpotAttributes?.JackpotRibbonTextColor;
    //             }
    //         }
    //     }
    // }
    setJackpotValue() {
        let jackpotValue = this.jackpotDataService.getGameJackpotValue(this.game.gameId);
        let enableRibbonConfig = this.configProviderService.provideJackpotConfig().enableRibbonConfig;
        if (jackpotValue) {
            this.jackpotValue = jackpotValue.amount;
            this.game.jackpotValue = jackpotValue.amount;
            this.game.isHotJackpotGroup = jackpotValue.isHotJackpotGroup;
            if (enableRibbonConfig) {
                this.jpRibbonStyles = this.jackpotDataService.getJackpotRibbonStyles(jackpotValue);
            }
        }
    }
    assignConfig() {
        let global = this.configProviderService.provideGlobalConfig();
        let freespinsConfig = this.configProviderService.providefreeSpinsClientConfig();
        if (
            global.lobbyConfigurations.FavouriteHeart === 'true' ||
            global.lobbyConfigurations.FavouriteHeart === 'True' ||
            global.lobbyConfigurations.FavouriteHeart === 'TRUE'
        ) {
            this.favouriteStyle = 'theme-heart-solid';
            this.unFavouriteStyle = 'theme-heart-outline';
        }
        this.demoLinkText = global.messages.DemoLinkText;
        this.maxPayout = global.moreInfoConfiguration.MaxPayout;
        this.enableFreeSpinSummary = freespinsConfig.isFreeSpinSummaryEnabled;
        this.enableFreeSpinsOnQuickInfo = freespinsConfig.isEnableFreeSpinsOnQuickInfo;
        if (this.enableFreeSpinSummary && this.enableFreeSpinsOnQuickInfo) {
            this.freespinObservable = this.freespinSummaryService.freeSpinsGameDetailsObser.subscribe((freespinsdata: any) => {
                if (freespinsdata) {
                    this.bindFreeSpinInfo();
                }
            });
        }
        if (this.enableFreeSpinSummary && this.enableFreeSpinsOnQuickInfo) {
            this.freespinRewardDataObservable = this.freespinSummaryService.freeSpinsRewardDetailsObser.subscribe((freespinsdata: any) => {
                if (freespinsdata) {
                    this.bindFreeSpinInfo();
                }
            });
        }
    }

    bindFreeSpinInfo() {
        this.setfreeSpinsCount(this.freespinSummaryService.getFreespinCountInfoForGame(this.game.gameId));
    }
    /*This is to set freespins count for the respective game*/
    setfreeSpinsCount(freespinsCountResponse: any) {
        this.freespinCountGame = freespinsCountResponse;
        if (this.freespinCountGame != undefined) {
            this.showFreeSpinCount = true;
            this.rewardIds = this.freespinCountGame.rewardIds;
            this.rewardDetails = this.freespinSummaryService.getFreeSpinRewardDetails(this.rewardIds);
        } else {
            this.showFreeSpinCount = false;
        }
    }

    hideInfo($event: any = null): void {
        if ($event) {
            if (this.data && this.data.gameLaunchedFrom && this.data.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed') {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'RecentlyPlayed_Widget',
                    'component.LabelEvent': 'RecentlyPlayed_QuickInfo_Overlay',
                    'component.ActionEvent': 'Close Overlay - Gradient Click',
                    'component.PositionEvent': this.game.gameId + '_' + this.gamePosition,
                    'component.LocationEvent': this.data.lobbyType,
                });
            }
            if (this.data && this.data.gameLaunchedFrom == 'RecentlyPlayed_widget_Favourites') {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'RecentlyPlayed_Widget',
                    'component.LabelEvent': 'Favourites_QuickInfo_Overlay',
                    'component.ActionEvent': 'Close Overlay - Gradient Click',
                    'component.PositionEvent': this.game.gameId + '_' + this.gamePosition,
                    'component.LocationEvent': this.data.lobbyType,
                });
            }
            if (this.demoEnabled) {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'Games Options',
                    'component.LabelEvent': 'Quick info overlay',
                    'component.ActionEvent': 'CTA - Clicked',
                    'component.PositionEvent': this.gameTitle,
                    'component.LocationEvent': 'close(x)',
                    'component.EventDetails': 'User clicked on CTA in the quick info overlay',
                    'component.URLClicked': 'Quick Info overlay - Enabled',
                });
            }
        } else {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'games',
                'component.LabelEvent': 'game interactions',
                'component.ActionEvent': 'close',
                'component.PositionEvent': this.gamePosition,
                'component.LocationEvent': 'bacon',
                'component.EventDetails': 'moreinfo overlay',
                'component.URLClicked': 'not applicable',
            });
        }
        document.getElementsByTagName('html')[0].classList.remove('casino-quickinfo-open');
        $event && $event.stopImmediatePropagation();
        this.removeQuickInfoQueryParameterFromUrl();
        this.dialogRef.close();
    }

    removeQuickInfoQueryParameterFromUrl() {
        let casinoNpmParams = this.casinoNpmParamsService.getcasinoCoreNpmParams();
        if (casinoNpmParams && casinoNpmParams.isDll) {
            if (this.gameContainer != 'Overlay' && this.gameContainer != 'Widget') {
                let param = 'game-info';
                if (location.href.indexOf(param) > -1) {
                    window.history.pushState(location.href, '', location.href.substr(0, location.href.indexOf(param) - 1));
                }
            }
        }
    }

    gameStickerStyleForUk() {
        let stickerStyle = this.configProviderService.provideGlobalConfig().messages.UkStickerStyle1;
        let sticker = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-0');
        for (let i = 0; i < sticker.length; i++) {
            sticker[i].style.cssText = stickerStyle;
        }
        let stickerStyle1 = this.configProviderService.provideGlobalConfig().messages.UkStickerStyle2;
        let sticker1 = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-1');
        for (let i = 0; i < sticker1.length; i++) {
            sticker1[i].style.cssText = stickerStyle1;
        }
    }

    gameStickerStyle() {
        let stickerStyle = this.configProviderService.provideGlobalConfig().messages.GameStickerStyle;
        let sticker = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-0');
        for (let i = 0; i < sticker.length; i++) {
            sticker[i].style.cssText = stickerStyle;
        }
    }

    toggleFavourite(game: any, $event: any) {
        $event.stopImmediatePropagation();
        if (this.user.isAuthenticated) {
            if (
                this.data &&
                this.data.gameLaunchedFrom &&
                this.newQuickInfoStyle &&
                (this.data.gameLaunchedFrom == 'RecentlyPlayed_widget_RecentlyPlayed' ||
                    this.data.gameLaunchedFrom == 'RecentlyPlayed_widget_Favourites')
            ) {
                this.trackFavfromRpWidget(game);
            } else {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'games',
                    'component.LabelEvent': 'games interaction',
                    'component.ActionEvent': 'click',
                    'component.PositionEvent': game.gameId,
                    'component.LocationEvent': 'more info overlay',
                    'component.EventDetails': this.isFavouriteGame(game) ? 'UnFavourited' : 'Favourited',
                    'component.URLClicked': 'not applicable',
                });
            }
            this.favouriteService.toggleGameFavourite(game);
            this.isFavourite = !this.isFavourite;
        } else {
            this.cookie.putObject('favouriteGame', game);
            this.hideInfo();
            let loginResponseObj: any = {
                loginMessageKey: 'Favourite',
            };
            if (this.isTouch && this.casinoWidgetService.isCasinoWidget()) {
                this.casinoWidgetService.postMessageToParent('UserAction', { promptLogin: true, loginMessageKey: 'Favourite' });
                return;
            }
            this.loginDialog.open(loginResponseObj);
        }
    }

    trackFavfromRpWidget(game: any) {
        if (this.data && this.data.gameLaunchedFrom == 'RecentlyPlayed_widget_RecentlyPlayed') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'RecentlyPlayed_QuickInfo_Overlay',
                'component.ActionEvent': this.isFavouriteGame(game) ? 'UnFavourited' : 'Favourited',
                'component.PositionEvent': game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this.data.lobbyType,
            });
        }
        if (this.data && this.data.gameLaunchedFrom == 'RecentlyPlayed_widget_Favourites') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'Favourites_QuickInfo_Overlay',
                'component.ActionEvent': this.isFavouriteGame(game) ? 'UnFavourited' : 'Favourited',
                'component.PositionEvent': game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this.data.lobbyType,
            });
        }
    }

    isFavouriteGame(game: any) {
        return this.favouriteService.checkForFavouriteGame(game);
    }

    onNavClick(navItem: string): void {
        switch (navItem) {
            case 'isGameDescription':
                this.tabPosition = 1;
                this.tabName = 'GameDescription';
                this.isGameDescription = true;
                this.isMoreInfo = false;
                this.isFreeSpins = false;
                break;
            case 'isMoreInfo':
                this.tabPosition = 2;
                this.tabName = 'MoreInfo';
                this.isMoreInfo = true;
                this.isGameDescription = false;
                this.isFreeSpins = false;
                break;
            case 'isFreeSpins':
                this.tabPosition = 3;
                this.tabName = 'FreeSpins';
                this.isFreeSpins = true;
                this.isMoreInfo = false;
                this.isGameDescription = false;
                break;
        }
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'games',
            'component.LabelEvent': 'game interactions',
            'component.ActionEvent': 'click',
            'component.PositionEvent': this.tabPosition,
            'component.LocationEvent': 'moreinfo overlay',
            'component.EventDetails': this.tabName,
            'component.URLClicked': 'not applicable',
        });
    }

    getMoreInfoGames() {
        let gymlCategory = this.newQuickInfoService.getCategoryInfo(this.lobbyType);
        if (gymlCategory && gymlCategory.categoryInfo && gymlCategory.categoryInfo.gamelist && gymlCategory.categoryInfo.gamelist.length > 0) {
            let gameObj: any;
            let gameInfo: any;
            this.gymlCategory = gymlCategory.categoryInfo;
            for (let i = 0; i < gymlCategory.categoryInfo.gamelist.length; i++) {
                gameObj = gymlCategory.categoryInfo.gamelist[i].game;
                gameInfo = this.casinoLobbyService.getGameInfo(gameObj);
                this.gymlGames.push(gameInfo);
            }
            this.getGyMLGames(this.gymlGames);
        }
    }
    getGyMLGames(gamesModelList: any[]) {
        this.gymlGames = [];
        for (let i = 0; i < gamesModelList.length; i++) {
            let gameModelItem: any = gamesModelList[i];
            let gamelobbyModel = this.gameDataManager.setGameLobbyModel(gameModelItem, this.isTouch);
            this.gymlGames.push(gamelobbyModel);
        }
    }

    setVoltalityInformation(volatilityType: string) {
        let global = this.configProviderService.provideGlobalConfig();
        switch (volatilityType.toUpperCase()) {
            case 'HIGH':
            case 'MED-HIGH':
                this.volatilityTypeText = global.moreInfoConfiguration.HighVolatilityText;
                this.volatilityInfo = global.moreInfoConfiguration.High;
                this.volatilityImgLink = global.moreInfoConfiguration.HighVolatilityImagelink;
                break;
            case 'LOW':
                this.volatilityTypeText = global.moreInfoConfiguration.MildVolatilityText;
                this.volatilityInfo = global.moreInfoConfiguration.Low;
                this.volatilityImgLink = global.moreInfoConfiguration.MildVolatilityImagelink;
                break;
            case 'MED':
            case 'LOW-MED':
                this.volatilityTypeText = global.moreInfoConfiguration.RegVolatilityHeaderText;
                this.volatilityInfo = global.moreInfoConfiguration.Medium;
                this.volatilityImgLink = global.moreInfoConfiguration.RegVolatilityImagelink;
                break;
        }
    }
    getStyles(sticker: any, index: number) {
        this.stickerKey = Object.keys(this.globalConfig.stickerStyles);
        if (this.stickerKey.includes(sticker)) {
            this.stickerStyles = this.globalConfig.stickerStyles[sticker];
        } else if (this.isStickersForUk) {
            if (!this.stickerKey.includes(sticker) && index % 2 === 0) {
                this.stickerStyles = this.globalConfig.stickerStyles.UkStickerStyle1;
            } else {
                this.stickerStyles = this.globalConfig.stickerStyles.UkStickerStyle2;
            }
        } else {
            this.stickerStyles = this.globalConfig.stickerStyles.GameStickerStyle;
        }
        return this.stickerStyles;
    }
    ngOnDestroy() {
        if (this.favObs) {
            this.favObs.unsubscribe();
        }
        if (this.jackpotHandle) {
            this.jackpotHandle.unsubscribe();
        }
        if (this.freespinObservable) {
            this.freespinObservable.unsubscribe();
        }
    }
}
