<span class="casino-sign-posting" [class.active-sign-posting]="signPostingGamesCount() > 0" *ngIf="signPostingGamesCount() > 0">
    <span
        class="label arrowed"
        *ngIf="(isDisplayBadge || !isMobileWidth()) && !enableEpcotSubNavigation && category && category.sitecoreData.signPostingBadge"
        >{{ category.sitecoreData.signPostingBadge }}
        <span class="title-badge">{{ signPostingGamesCount() > 99 ? 99 : signPostingGamesCount() }}</span>
    </span>
    <span class="label arrowed" *ngIf="isDisplayBadge && enableEpcotSubNavigation && !isTouch && category && category.sitecoreData.signPostingBadge"
        >{{ category.sitecoreData.signPostingBadge }}
        <span class="title-badge">{{ signPostingGamesCount() > 99 ? 99 : signPostingGamesCount() }}</span>
    </span>
    <span
        *ngIf="!isDisplayBadge && isMobileWidth() && !enableEpcotSubNavigation"
        class="badge badge-circle badge-offset badge-t-r badge-danger mob-subnav-badge"
        >{{ signPostingGamesCount() > 99 ? 99 : signPostingGamesCount() }}</span
    >
    <span
        *ngIf="!isDisplayBadge && isTouch && enableEpcotSubNavigation"
        class="badge badge-circle badge-offset badge-t-r badge-danger mob-subnav-badge"
        >{{ signPostingGamesCount() > 99 ? 99 : signPostingGamesCount() }}</span
    >
</span>
