import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import {
    CasinoIFrameOverlayService,
    CasinoLobbyService,
    CasinoManager,
    CasinoWidgetService,
    ConfigProviderService,
    CozyBingoDataService,
    FastLoadingGamesService,
    FavouriteService,
    GameDataManager,
} from '@casinocore/platform/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { cloneDeep } from 'lodash-es';
import { Swiper, SwiperOptions } from 'swiper/types';

import { CozyBingoGameComponent } from '../cozy-bingo-game/cozy-bingo-game.component';
import { GameLobbyComponent } from '../gamelobby/gamelobby.component';

export class FavGymlWidgetGameModel {
    gameId: string;
    sticker: string;
}
export class Fav_GYML_WidgetGame {
    GameId: string;
    isFavourite: boolean;
    isGYML: boolean;
    sticker: string;
    fromCasino: boolean = true;
    name: string;
    ownerId: any;
    fromCozybingo: boolean = false;
    constructor(
        gameId: string,
        isFavourite: boolean,
        isGYML: boolean,
        fromCasino: boolean = true,
        name: any,
        ownerId: any,
        fromCozybingo: boolean = false,
    ) {
        this.GameId = gameId;
        this.isFavourite = isFavourite;
        this.isGYML = isGYML;
        this.fromCasino = fromCasino;
        this.name = name;
        this.ownerId = ownerId;
        this.fromCozybingo = fromCozybingo;
    }
}

@Component({
    selector: 'cc-fav-gyml-widget',
    templateUrl: 'fav-gyml-widget.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgClass, NgIf, NgStyle, NgFor, CozyBingoGameComponent, GameLobbyComponent, SwiperComponent],
})
export class Fav_GYML_WidgetComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() lobbyType: any;
    @Input() isTouch: any;
    @Input() isGymlInFavourite: any;
    @Input() gameContainer: string;
    @ViewChild('favouriteGYMLCarousel') swiperRef: SwiperComponent;
    elms: number = 6;
    gameLaunchedFromLoc: any;
    favouriteCategory: any;
    gymlCategory: any;
    favourtieandGymlGames: any = [];
    baseGameCount: number;
    recentStartIndex: number = 0;
    noFavouritesIcon: string;
    maxScrollPosition: number;
    staticGYML: boolean;
    unfinishedGames: any = [];
    Games: any[] = [];
    isCombinedCategories: boolean = false;
    subcategory: any;
    onlyRecentGames: any[] = [];
    onlyRecentGamesStrings: any[] = [];
    onlyGYMLGamesStrings: any[] = [];
    onlyGYMLGames: any[] = [];
    youMightLikeMessage: any;
    gymlGames: any = [];
    favGames: any = [];
    gymlGameStrings: any = [];
    favouriteAandGYMLGameIds: Fav_GYML_WidgetGame[] = [];
    unfinishedFavANDGYMLGames: any[] = [];
    favGamesCount: number = 0;
    favouriteGames: any = [];
    subcategoryNew: any;
    isCombinedCategoriesNew: boolean = false;
    recentsObs: any;
    unfinishedObv: any;
    categoryLookupObv: any;
    defaultCategoryLookupObv: any;
    lobbyObs: any;
    isUkHeading: boolean;
    alignIconRight: boolean;
    WidgetFrameClr: string;
    WidgetBackgroundClr: string;
    WidgetDefaultMsgTxtColor: string;
    casinoConfig: any;
    Global: any;
    features: any;
    cozyBingoFavouriteGames: any = [];
    cozybingoSubscription: any;
    lastElementIndex: number;
    zeroFavourite: boolean = false;
    favGamesSubscription: any;
    gymlCategoryRef: any;
    recommendedGamesBackgroundColor: string;

    swiperOptions: SwiperOptions = {
        speed: 400,
        autoHeight: true,
        spaceBetween: 5,
        slidesPerView: 6,
        navigation: true,
        slidesPerGroup: 6,
        on: {
            slideChange: (swiper) => this.onIndexChanged(swiper),
        },
    };

    constructor(
        private gameDataManager: GameDataManager,
        private favouriteService: FavouriteService,
        private fastLoadingGamesService: FastLoadingGamesService,
        private casinoLobbyService: CasinoLobbyService,
        private elementRef: ElementRef,
        private casinoManager: CasinoManager,
        private casinoIFrameOverlayService: CasinoIFrameOverlayService,
        private casinoWidgetService: CasinoWidgetService,
        private configProviderService: ConfigProviderService,
        private cozyBingoDataService: CozyBingoDataService,
    ) {}

    ngOnInit() {
        const global = this.configProviderService.provideGlobalConfig();
        this.recommendedGamesBackgroundColor = global?.messages['RecommendedGamesBackground'];
        this.youMightLikeMessage = this.Global.messages['LobbyGamesYouMightLike']
            ? this.Global.messages['LobbyGamesYouMightLike']
            : this.Global.messages['GamesYouMightLike'];
        this.gameLaunchedFromLoc = 'RecentlyPlayed_widget_Favourites';
        const gymlCategoryData = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
        if (gymlCategoryData) {
            this.gymlCategory = gymlCategoryData.categoryInfo;
        }

        // if (this.gymlCategory) {
        //     this.gymlCategory.rowCount = '-1';
        // }
        this.noFavouritesIcon = this.Global.messages['WidgetnoFavouritesIcon'];
        this.WidgetFrameClr = this.Global.messages['WidgetframeClr'];
        this.WidgetDefaultMsgTxtColor = this.Global.messages['WidgetDefaultMsgTxtColor'];
        this.WidgetBackgroundClr = this.Global.messages['WidgetBackgroundColor'];
        const favouriteCategoryData = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'favouritegames');
        if (favouriteCategoryData) {
            this.favouriteCategory = favouriteCategoryData.categoryInfo;
        }

        this.setGamesOnInit();
        this.favouriteGames = this.favouriteService.getFavouriteGames();
        this.cozyBingoFavouriteGames = this.cozyBingoDataService.getcozybingofavourites();
        this.favGamesSubscription = this.favouriteService.favouriteGamesFeedObservable.subscribe((e: any) => {
            if (this.favouriteCategory && e) {
                this.favouriteGames = e;
                let gymlCategoryInfo: any;
                const categoryInfoData = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
                if (categoryInfoData) {
                    gymlCategoryInfo = categoryInfoData.categoryInfo;
                }
                this.setFavouriteAndGYML(
                    this.favouriteGames,
                    this.getGymlGameIds(gymlCategoryInfo),
                    this.cozyBingoDataService.getcozybingofavourites(),
                );
            }
        });
        this.cozybingoSubscription = this.cozyBingoDataService.cozyBingoRoomsDataObservable.subscribe((response: any) => {
            if (response) {
                let gymlCategoryInfo: any;
                const categoryInfoData = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
                if (categoryInfoData) {
                    gymlCategoryInfo = categoryInfoData.categoryInfo;
                }
                this.cozyBingoFavouriteGames = this.cozyBingoDataService.getcozybingofavourites();
                const gymlGameIds = this.getGymlGameIds(gymlCategoryInfo);
                this.setFavouriteAndGYML(this.favouriteService.getFavouriteGames(), gymlGameIds, this.cozyBingoFavouriteGames);
            }
        });
        this.alignIconRight = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, this.features.alignIconRight);
        if (this.favouriteCategory) this.favouriteCategory.categoryIndex = 1;
        if (this.gymlCategory) this.gymlCategory.categoryIndex = 1;
        if (this.subcategory) this.subcategory.categoryIndex = 1;
        this.fastLoadingGamesService.fastLoadingGamesFeedObservable.subscribe((fastLoadingGames: any) => {
            this.fastLoadingGamesService.setFastLoadingGames(this.unfinishedFavANDGYMLGames, fastLoadingGames);
        });
        this.isUkHeading = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, this.features.isUkHeading);
    }
    ngOnChanges() {
        this.assignConfig();
        let gymlCategoryInfo: any;
        const categoryInfoData = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
        if (categoryInfoData) {
            gymlCategoryInfo = categoryInfoData.categoryInfo;
        }
        this.setFavouriteAndGYML(
            this.favouriteService.getFavouriteGames(),
            this.getGymlGameIds(gymlCategoryInfo),
            this.cozyBingoDataService.getcozybingofavourites(),
        );
    }
    assignConfig() {
        this.casinoConfig = this.configProviderService.provideCasinoConfig();
        this.Global = this.configProviderService.provideGlobalConfig();
        this.features = this.configProviderService.provideFeaturesConfig();
    }

    setGamesOnInit() {
        const favGames = this.favouriteService.getFavouriteGames();
        const categoryInfoData = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
        if (categoryInfoData) {
            this.gymlCategory = categoryInfoData.categoryInfo;
        }
        let gymlGames: any;
        if (this.gymlCategory) {
            //this.gymlCategory.rowCount = '-1';
            gymlGames = this.getGymlGameIds(this.gymlCategory);
        }
        this.setFavouriteAndGYML(favGames, gymlGames, this.cozyBingoDataService.getcozybingofavourites());
    }

    assignRecommendedWidgetColors(game: any) {
        if (game.isGYML) {
            if (this.casinoWidgetService.isCasinoWidget()) {
                return 'gyml-outerframe-casinowidget';
            } else if (this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                return 'gyml-outerframe-bg-overlay';
            } else {
                return 'gyml-outerframe-casino';
            }
        }
        return '';
    }
    getGymlGameIds(gymlCategory: any): any[] {
        const gymlGames: FavGymlWidgetGameModel[] = [];
        if (gymlCategory && gymlCategory.gamelist && gymlCategory.gamelist.length > 0) {
            for (let i = 0; i < gymlCategory.gamelist.length; i++) {
                const gameModel: FavGymlWidgetGameModel = new FavGymlWidgetGameModel();
                gameModel.gameId = gymlCategory.gamelist[i].game;
                gameModel.sticker = gymlCategory.gamelist[i].sticker;
                gymlGames.push(gameModel);
            }
        }
        return gymlGames;
    }

    setFavouriteAndGYML(favGames: string[], gymlGames: FavGymlWidgetGameModel[], cozyBingoFavGames: any) {
        if (!favGames) {
            favGames = [];
        }
        if (!cozyBingoFavGames) {
            cozyBingoFavGames = [];
        }
        if (!gymlGames) {
            gymlGames = [];
        }
        const favouriteArray: Fav_GYML_WidgetGame[] = this.concatUnfinishedAndFavouriteGames(favGames, cozyBingoFavGames);

        this.favouriteAandGYMLGameIds = this.concatFavouriteAndGYMLGames(favouriteArray, gymlGames);
        this.processFavouriteGYMLGames();
    }

    concatUnfinishedAndFavouriteGames(favGames: string[], cozyBingoFavGames: string[]): Fav_GYML_WidgetGame[] {
        const favouriteArray: Fav_GYML_WidgetGame[] = [];

        for (let i = 0; i < favGames.length; i++) {
            const gameInfo = this.casinoLobbyService.getGameInfo(favGames[i]);
            if (gameInfo) {
                //to do check for disabled
                favouriteArray.push(new Fav_GYML_WidgetGame(favGames[i], true, false, true, gameInfo.name, gameInfo.ownerId, false));
            }
        }
        const cozybingoConfig: any = this.configProviderService.provideCozyBingoConfig();
        if (cozybingoConfig.cozyBingoFavouritesInRpwidget) {
            for (let i = 0; i < cozyBingoFavGames.length; i++) {
                const FavouriteGame: Fav_GYML_WidgetGame = new Fav_GYML_WidgetGame(cozyBingoFavGames[i], true, false, false, null, null, true);
                favouriteArray.push(FavouriteGame);
            }
        }
        this.zeroFavourite = !favouriteArray || favouriteArray.length == 0;
        return favouriteArray;
    }

    concatFavouriteAndGYMLGames(favouriteGames: Fav_GYML_WidgetGame[], gymlGames: FavGymlWidgetGameModel[]): Fav_GYML_WidgetGame[] {
        favouriteGames = Array.from(new Set(favouriteGames));
        const FavGYMLArray: Fav_GYML_WidgetGame[] = favouriteGames;
        for (let gymlGamesItem of gymlGames) {
            const FavGYMLArray: Fav_GYML_WidgetGame[] = favouriteGames;
            const gymlGameInFavouriteArray: any = FavGYMLArray.find((a) => a.GameId === gymlGamesItem.gameId);
            if (!gymlGameInFavouriteArray) {
                const gymlItem: Fav_GYML_WidgetGame = new Fav_GYML_WidgetGame(gymlGamesItem.gameId, false, true, true, null, null, false);
                gymlItem.sticker = gymlGamesItem.sticker;
                const gameInfo = this.casinoLobbyService.getGameInfo(gymlItem.GameId);
                if (gameInfo) {
                    gymlItem.name = gameInfo.Title;
                    gymlItem.ownerId = gameInfo.ownerId;
                    FavGYMLArray.push(gymlItem);
                }
            }
        }
        return FavGYMLArray;
    }

    processFavouriteGYMLGames() {
        this.sliceGYMLLimitAndSetBaseGameCount();
        this.setGames();
        this.updateGYMLCategoryRowCount();
    }

    sliceGYMLLimitAndSetBaseGameCount() {
        const favGames = this.favouriteAandGYMLGameIds.filter((game: any) => game.isFavourite);
        if (favGames) {
            this.favGamesCount = favGames.length;
        }
        let gymlGames = this.favouriteAandGYMLGameIds.filter((game: any) => !game.isFavourite);
        if (this.casinoConfig) {
            gymlGames = gymlGames.slice(0, this.casinoConfig.gymlLimit);
            this.favouriteAandGYMLGameIds = [];
            this.favouriteAandGYMLGameIds = this.favouriteAandGYMLGameIds.concat(favGames);
            if (this.isGymlInFavourite) {
                this.favouriteAandGYMLGameIds = this.favouriteAandGYMLGameIds.concat(gymlGames);
            }
        }
    }

    setGames() {
        if (this.favouriteAandGYMLGameIds && this.favouriteAandGYMLGameIds.length > 0) {
            const gamesModel: any[] = [];
            let GameModelItem: any;
            for (let i = 0; i < this.favouriteAandGYMLGameIds.length; i++) {
                if (this.favouriteAandGYMLGameIds[i].fromCozybingo) {
                    GameModelItem = this.cozyBingoDataService.getRoomdatabyId(this.favouriteAandGYMLGameIds[i].GameId);
                    if (GameModelItem) {
                        GameModelItem.fromCozybingo = true;
                        GameModelItem.fromCasino = false;
                        GameModelItem.isFavourite = true;
                        GameModelItem.gameId = GameModelItem.id;
                        GameModelItem.lobbyType = this.lobbyType;
                    }
                } else if (this.favouriteAandGYMLGameIds[i].fromCasino) {
                    const gameInfo = this.casinoLobbyService.getGameInfo(this.favouriteAandGYMLGameIds[i].GameId);
                    GameModelItem = {
                        game: this.favouriteAandGYMLGameIds[i].GameId,
                        icon: '1',
                        isFavourite: this.favouriteAandGYMLGameIds[i].isFavourite,
                        isGYML: this.favouriteAandGYMLGameIds[i].isGYML,
                        sticker: this.favouriteAandGYMLGameIds[i].sticker,
                        fromCasino: this.favouriteAandGYMLGameIds[i].fromCasino,
                        name: gameInfo.name,
                        provider: gameInfo.provider,
                        lobbyType: this.lobbyType,
                        ownerId: gameInfo.ownerId,
                    };
                }
                if (GameModelItem) {
                    gamesModel.push(GameModelItem);
                }
            }

            this.setDefaultLMTGameIcons(gamesModel);
        } else if (this.favouriteAandGYMLGameIds && this.favouriteAandGYMLGameIds.length === 0) {
            this.Games = [];
            this.unfinishedFavANDGYMLGames = [];
        }
    }

    setDefaultLMTGameIcons(gamesModellist: any[]) {
        this.Games = [];
        this.unfinishedFavANDGYMLGames = [];
        const disabledGames: any = this.casinoLobbyService.getDisabledGames();
        if (this.favouriteGames && this.favouriteGames.length == 0 && this.cozyBingoFavouriteGames && this.cozyBingoFavouriteGames.length == 0) {
            this.elms = window.innerWidth < 321 ? 3 : 5;
        } else {
            this.elms = window.innerWidth < 321 ? 3 : 6;
        }

        if (gamesModellist && gamesModellist.length > 0) {
            for (let i = 0; i < gamesModellist.length; i++) {
                const gameModelItem: any = gamesModellist[i];
                let filteredGame: any;
                if (!gameModelItem.fromCozybingo) {
                    filteredGame = disabledGames.filter((disabledGame: any) => disabledGame === gameModelItem.game);
                }
                let gamelobbyModel: any = {};
                if (!gameModelItem.fromCozybingo) {
                    gamelobbyModel = this.gameDataManager.setGameLobbyModel(gameModelItem, this.isTouch);
                    if (!(filteredGame && filteredGame.length > 0)) {
                        gamelobbyModel.isFavourite = gamesModellist[i].isFavourite;
                        gamelobbyModel.isGYML = gamesModellist[i].isGYML;
                        gamelobbyModel.fromCasino = gamesModellist[i].fromCasino;
                        gamelobbyModel.gameSection = 'RecentlyPlayed_widget_Favourites';
                        gamelobbyModel.gameContainer = this.gameContainer;
                    }
                } else {
                    gamelobbyModel = gameModelItem;
                    gamelobbyModel.gameSection = 'RecentlyPlayed_widget_Favourites';
                    gamelobbyModel.gameContainer = this.gameContainer;
                }
                if (!(filteredGame && filteredGame.length > 0)) {
                    gamelobbyModel.dataIndex = i;
                    gamelobbyModel.data = JSON.parse(JSON.stringify(gamelobbyModel));
                    gamelobbyModel.infoIconClass = this.casinoManager.assignGameClass(this.casinoManager.getCurrentWidth(), '1');
                    this.Games.push(gamelobbyModel);
                    this.unfinishedFavANDGYMLGames.push(gamelobbyModel);
                }
            }

            const favouriteGamesModel: any = gamesModellist.filter((gameModel) => gameModel.isFavourite && gameModel.fromCasino);
            if (this.favouriteCategory) {
                this.favouriteCategory.gamesModel = favouriteGamesModel;
            }
        }
        const slidePerOptions = this.zeroFavourite ? 5 : 6;
        this.swiperOptions.slidesPerView = slidePerOptions;
        this.swiperOptions.slidesPerGroup = slidePerOptions;
        if (this.swiperRef) {
            this.swiperRef.swiper.params.slidesPerView = this.swiperOptions.slidesPerView;
            this.swiperRef.swiper.params.slidesPerGroup = this.swiperOptions.slidesPerGroup;
        }
    }
    isNoIconBoxVisible(): boolean {
        return this.favouriteGames && this.favouriteGames.length == 0 && this.cozyBingoFavouriteGames && this.cozyBingoFavouriteGames.length == 0;
    }

    ngAfterViewInit() {
        if (document.getElementById('gymlId')) {
            this.maxScrollPosition = document.getElementById('gymlId')!.offsetLeft;
        }
    }

    ngOnDestroy() {
        if (this.cozybingoSubscription) {
            this.cozybingoSubscription.unsubscribe();
        }
        if (this.favGamesSubscription) {
            this.favGamesSubscription.unsubscribe();
        }
    }

    setSubCategoryStyle() {
        if (this.isTouch) {
            return 'scrollable-games';
        }
        return 'unscrollable-games';
    }

    scrollGYML() {
        if (this.elementRef.nativeElement.getElementsByClassName('scrollable-games')[0].scrollLeft >= this.maxScrollPosition) {
            this.staticGYML = true;
            setTimeout(() => {
                const ele = document.getElementById('sticky-container');
                ele!.setAttribute('position', 'relative');
            }, 500);
        } else if (this.elementRef.nativeElement.getElementsByClassName('scrollable-games')[0].scrollLeft < this.maxScrollPosition) {
            this.staticGYML = false;
        }
    }

    onIndexChanged(swiper: Swiper): void {
        const index = swiper.realIndex;
        this.lastElementIndex = index + this.elms - 1;
    }

    updateGYMLCategoryRowCount() {
        if (this.gymlCategory) {
            this.gymlCategoryRef = cloneDeep(this.gymlCategory);
            // this.gymlCategoryRef = {...this.gymlCategory};
            this.gymlCategoryRef.rowCount = '-1';
        }
    }
}
