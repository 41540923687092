import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csModularBingo', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: modularBingoClientConfigFactory,
})
export class ModularBingoClientConfig {
    bingoTournamentsRoute: string;
    isEnabled: boolean;
    isBingoTournamentsEnabled: boolean;
    isEnabledOnServerSide: boolean;
    stopSchedulePolling: boolean;
}

export function modularBingoClientConfigFactory(service: LazyClientConfigService) {
    return service.get(ModularBingoClientConfig);
}
