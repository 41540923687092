<div
    class="{{ trackingClass }}"
    [ngClass]="{
        'casino-backgroungImage-Clr':
            !(
                parallaxInput?.sitecoreData?.backgroundImageLayer1 ||
                parallaxInput?.sitecoreData?.backgroundImageLayer2 ||
                parallaxInput?.sitecoreData?.backgroundImageLayer3
            ) &&
            !(
                subCategoryInfo?.sitecoreData?.backgroundImageLayer1 ||
                subCategoryInfo?.sitecoreData?.backgroundImageLayer2 ||
                subCategoryInfo?.sitecoreData?.backgroundImageLayer3
            )
    }"
    [ngStyle]="{ background: subCategoryInfo?.sitecoreData?.attributes?.backgroundColor }">
    @if (validGames && validGames.length > 0) {
        <div
            [ngClass]="{
                'casino-backgroungImage-layer': subCategoryInfo?.sitecoreData?.backgroundImageLayer1,
                'casino-backgroungImage-parallax':
                    subCategoryInfo?.sitecoreData?.backgroundImageLayer2 || subCategoryInfo?.sitecoreData?.backgroundImageLayer3,
                'categoryBgFull': categoryBackgroundFull,
                'casino-coinarcade-lobby': arcadeLobbyGameConfig == currentLobbyType
            }">
            @if (
                subCategoryInfo?.sitecoreData?.backgroundImageLayer1 ||
                subCategoryInfo?.sitecoreData?.backgroundImageLayer2 ||
                subCategoryInfo?.sitecoreData?.backgroundImageLayer3
            ) {
                <cc-casino-core-lazy-placeholder [inputData]="{ subCategory: subCategoryInfo }" [componentName]="'ParallaxAnimationComponent'">
                </cc-casino-core-lazy-placeholder>
            }

            <div
                class="casino-game-tile-wrapper casino-lobby-width-gametile"
                [ngClass]="{
                    'seeall-configs': changeCarouselView,
                    'coin-arcade-outline-shade': ShowArcadeCoinExpiry && ArcadeRecommendedStyles,
                    'coin-arcade-outline-shade-height': subCategoryStyle == 'unscrollable-games'
                }">
                <h2
                    class="game-tile-heading casino-lobby-header"
                    [ngClass]="{
                        'casino-uk-header': isUkHeading,
                        'epcot-heading': enableEpcotSubNavigation,
                        'coinarcade-game-title-heading': arcadeLobbyGameConfig == currentLobbyType && ShowArcadeCoinExpiry
                    }"
                    [ngStyle]="{ 'color': subCategoryTextColor, 'font-family': lobbyTileFontFamily }">
                    @if (changeCarouselView && !isTouch && carouselData?.showArrows) {
                        <span class="seeallarrows">
                            <span
                                class="arrows-left"
                                [ngClass]="themeArrowLeft"
                                [ngStyle]="{ 'pointer-events': carouselData?.disablePrev ? 'none' : 'auto' }"
                                (click)="onPrevClick()"></span>
                            <span
                                class="arrows-right"
                                [ngClass]="themeArrowRight"
                                [ngStyle]="{ 'pointer-events': carouselData?.disableNext ? 'none' : 'auto' }"
                                (click)="onNextClick()"></span>
                        </span>
                    }

                    @if (!isUkHeading) {
                        <span [ngStyle]="{ 'font-size': subCategoryTitleFontSize }" class="category-title">
                            {{ subCategoryInfo.categoryname }}

                            @if (
                                subCategoryInfo?.sitecoreData?.showJackpotMoreInfo &&
                                subCategoryInfo?.sitecoreData?.jackpotInfo &&
                                jackpotMoreInfoEnable &&
                                subCategoryInfo?.sitecoreData?.jackpotMoreInfoCloseCtaText
                            ) {
                                <p class="non-uk-jacpot-moreinfo theme-info" (click)="openDialog($event)"></p>
                            }

                            @if (isSignPosting) {
                                <cc-sign-posting [category]="subCategoryInfo"> </cc-sign-posting>
                            }
                        </span>
                    }

                    @if (isUkHeading) {
                        <cc-categoryicon-subtitle
                            [lobbyType]="lobbyType"
                            [subCategory]="subCategoryInfo"
                            [recentAndGyml]="'NotRecentAndGyml'"
                            [seeAllGamesCount]="seeAllGamesCount"
                            [alignIconRight]="alignIconRight"></cc-categoryicon-subtitle>
                    }

                    @if (seeAllGamesCount && !isLastNavigationLevel && !isUkHeading) {
                        <span
                            class="casino-seeall-text"
                            (click)="seeAll()"
                            [ngStyle]="{ 'color': subCategoryTextColor, 'font-size': subCategorySubTitleFontSize }">
                            @if (changeCarouselView) {
                                <div>
                                    <cc-global-translate [key]="'SeeAll'"></cc-global-translate>
                                </div>
                            }

                            @if (!changeCarouselView) {
                                <div>
                                    <cc-global-translate [key]="'SeeAll'"></cc-global-translate>
                                    @if (showSeeAllGamesCount && !(gameContainer === 'Overlay')) {
                                        <span class="seeall-count">({{ seeAllGamesCount }})</span>
                                    }
                                    <span class="theme-right"></span>
                                </div>
                            }
                        </span>
                    }

                    @if (seeAllGamesCount && !isLastNavigationLevel && isUkHeading) {
                        <ul
                            class="casino-seeall-text ul-icon"
                            (click)="seeAll()"
                            [ngClass]="{
                                'noicon-seeall':
                                    (subCategoryInfo.sitecoreData.displayCategoryIcon && subCategoryInfo.sitecoreData.attributes.icon) ||
                                    subCategoryInfo.sitecoreData.headerTitle
                            }"
                            [ngStyle]="{ color: subCategoryTextColor }">
                            @if (changeCarouselView) {
                                <div>
                                    <li [style.font-size]="subCategorySubTitleFontSize">
                                        {{ seeAllText }}
                                    </li>
                                </div>
                            }

                            @if (!changeCarouselView) {
                                <div>
                                    <li [style.font-size]="subCategorySubTitleFontSize">
                                        {{ seeAllText }}
                                    </li>
                                    @if (showSeeAllGamesCount && !(gameContainer === 'Overlay')) {
                                        <li [style.font-size]="subCategorySubTitleFontSize" class="seeall-count">({{ seeAllGamesCount }})</li>
                                    }
                                    <li class="theme-right" [style.font-size]="subCategorySubTitleFontSize" aria-hidden="true"></li>
                                </div>
                            }
                        </ul>
                    }

                    @if (arcadeLobbyGameConfig == currentLobbyType && showCoinExpiryText && ShowArcadeCoinExpiry) {
                        <div class="coinarcade-subtitle-text" [ngStyle]="{ background: arcadeCoinExpiryBgClr }">
                            <div
                                class="coin-arcade-titletext"
                                [ngStyle]="{ color: coinArcadeExpirytitle1Clr }"
                                [textContent]="
                                    arcadeLobbyMessages?.CoinExpiryStaticBannerText
                                        ? arcadeLobbyMessages?.CoinExpiryStaticBannerText
                                        : arcadeLobbyMessages?.CoinExpiryText1
                                "></div>
                            @if (!arcadeLobbyMessages?.CoinExpiryStaticBannerText) {
                                <div class="coin-arcade-subtitletext" [ngStyle]="{ background: coinArcadeExpirytitle2BgClr }">
                                    <span
                                        class="arcade-expiry-text"
                                        [ngStyle]="{ color: coinArcadeExpirytitle2Clr }"
                                        [textContent]="
                                            arcadeCoinExpiry
                                                ? arcadeLobbyMessages?.StoreWidgetExpiryText
                                                : arcadeLobbyMessages?.StoreWidgetExpiryTextToday
                                        ">
                                    </span>
                                    <span class="arcade-expiry" [ngStyle]="{ color: coinArcadeExpiryDaysClr, background: coinArcadeExpiryDaysBgClr }">
                                        {{ arcadeCoinExpiry ? arcadeCoinExpiry + ' ' + arcadeLobbyMessages?.Days : arcadeLobbyMessages?.Today }}
                                    </span>
                                </div>
                            }
                        </div>
                    }
                </h2>

                @if ((showTeasers || isShowEmbeddedAbove) && ((!isEnableOptimizedTeasers && showTeasersAboveGames) || isEnableOptimizedTeasers)) {
                    <div class="casino-lmt-sub-category">
                        <cc-teasers-wrapper
                            [teaserLocation]="SubCategoryTeaser"
                            [teaserCategory]="subcategory.categoryInfo.categoryid"
                            [teasersSource]="headerTeasers"
                            [optTeasersSource]="optimizedEmbeddedAboveTeasers"
                            [identifierClass]="subcategoryId + '_EmbeddedAbove'"
                            [lobbyType]="lobbyType"
                            [gameContainer]="gameContainer" />
                    </div>
                }

                @if (displayPromoEDSWidget && promoEDSWidgetPosition && promoEDSWidgetPosition === 'BeforeGames') {
                    <div class="casino-promo-eds-widget" [ngClass]="{ 'smart-eds-widget': displayEdsSmartWidget }">
                        <cc-casino-core-lazy-placeholder
                            [inputData]="{ position: promoEDSWidgetPosition, category: subcategoryId }"
                            [componentName]="'PromoEdsWidgetComponent'">
                        </cc-casino-core-lazy-placeholder>
                    </div>
                }

                @if (isEnabledGameSelector && gameSelectorWidgetPosition && gameSelectorWidgetPosition === 'BeforeGames') {
                    <div class="casino-game-selector">
                        <cc-casino-core-lazy-placeholder
                            [inputData]="{ subCategory: subCategoryInfo, widgetLocation: SubCategoryTeaser }"
                            [componentName]="'CasinoGameSelectorComponent'">
                        </cc-casino-core-lazy-placeholder>
                    </div>
                }

                @if (isSuperButtonEnabled && superButtonPosition && superButtonPosition === 'BeforeGames') {
                    <div class="casino-super-button">
                        <cc-casino-core-lazy-placeholder [inputData]="{ category: subCategoryInfo }" [componentName]="'SuperButtonComponent'">
                        </cc-casino-core-lazy-placeholder>
                    </div>
                }

                @if (enablePlayerstats && playerStatsWidgetPosition && playerStatsWidgetPosition === 'BeforeGames') {
                    <div class="casino-playerstats">
                        <cc-player-stat-filters [category]="subCategoryInfo"></cc-player-stat-filters>
                    </div>
                }

                <div
                    [ngClass]="subCategoryStyle"
                    [style.overflow-x]="isTouch == false ? 'hidden' : 'scroll'"
                    [class.non-touch-scrollable-games]="!isTouch && subCategoryStyle == 'scrollable-games'">
                    @if (showFeatureGrid()) {
                        @if (
                            Games &&
                            Games.length > 0 &&
                            !isSlotRacesCategory &&
                            !isBingoTournamentsCategory &&
                            arcadeLobbyGameConfig != currentLobbyType
                        ) {
                            <cc-feature-grid
                                #featureGrid
                                [games]="Games"
                                [subcategory]="subCategoryInfo"
                                [isTouch]="isTouch"
                                [Type]="subCategoryStyle"
                                (loadNextGames)="loadNextGamesSet()"
                                (carouselDataEvent)="getCarouselData($event)"
                                [changeCarouselView]="changeCarouselView"
                                [gameLaunchedFrom]="subCategoryInfo.categoryid"
                                [lobbyType]="lobbyType"
                                [newFeatureGridArr]="newFeatureGridArr">
                            </cc-feature-grid>
                        }
                    }

                    @if (
                        Games && Games.length > 0 && !isSlotRacesCategory && !isBingoTournamentsCategory && arcadeLobbyGameConfig == currentLobbyType
                    ) {
                        <cc-arcade-feature-grid
                            #featureGrid
                            [games]="Games"
                            [subcategory]="subCategoryInfo"
                            [isTouch]="isTouch"
                            [Type]="subCategoryStyle"
                            (loadNextGames)="loadNextGamesSet()"
                            (carouselDataEvent)="getCarouselData($event)"
                            [changeCarouselView]="changeCarouselView"
                            [gameLaunchedFrom]="subCategoryInfo.categoryid"
                            [lobbyType]="lobbyType"
                            [newFeatureGridArr]="newFeatureGridArr">
                        </cc-arcade-feature-grid>
                    }
                </div>

                @if (isSuperButtonEnabled && superButtonPosition && superButtonPosition === 'AfterGames') {
                    <div class="casino-super-button">
                        <cc-casino-core-lazy-placeholder [inputData]="{ category: subCategoryInfo }" [componentName]="'SuperButtonComponent'">
                        </cc-casino-core-lazy-placeholder>
                    </div>
                }

                @if (isEnabledGameSelector && gameSelectorWidgetPosition && gameSelectorWidgetPosition === 'AfterGames') {
                    <div class="casino-game-selector">
                        <cc-casino-core-lazy-placeholder
                            [inputData]="{ subCategory: subCategoryInfo, widgetLocation: SubCategoryTeaser }"
                            [componentName]="'CasinoGameSelectorComponent'">
                        </cc-casino-core-lazy-placeholder>
                    </div>
                }

                @if (displayPromoEDSWidget && promoEDSWidgetPosition && promoEDSWidgetPosition === 'AfterGames') {
                    <div class="casino-promo-eds-widget">
                        <cc-casino-core-lazy-placeholder
                            [inputData]="{ position: promoEDSWidgetPosition, category: subcategoryId }"
                            [componentName]="'PromoEdsWidgetComponent'">
                        </cc-casino-core-lazy-placeholder>
                    </div>
                }

                @if (isSlotRacesCategory && !isSlotRacesPhase2Enabled) {
                    <div>
                        <cc-casino-core-lazy-placeholder
                            [inputData]="{ component: 'carousel', lobbyType: lobbyType, category: subcategory }"
                            [componentName]="'SlotRacesComponent'">
                        </cc-casino-core-lazy-placeholder>
                    </div>
                }
                @if (isSlotRacesCategory && isSlotRacesPhase2Enabled) {
                    <div>
                        <cc-casino-core-lazy-placeholder
                            [inputData]="{ component: 'carousel', lobbyType: lobbyType, category: subcategory }"
                            [componentName]="'SlotRacesPhase2Component'">
                        </cc-casino-core-lazy-placeholder>
                    </div>
                }

                @if (isBingoTournamentsCategory) {
                    <div class="casino-bingo-tournaments">
                        <cc-casino-core-lazy-placeholder [inputData]="{ component: 'carousel' }" [componentName]="'BingoTournamentsComponent'">
                        </cc-casino-core-lazy-placeholder>
                    </div>
                }

                @if ((showTeasers || isShowEmbeddedBelow) && (!showTeasersAboveGames || isEnableOptimizedTeasers)) {
                    <div class="casino-lmt-sub-category">
                        <cc-teasers-wrapper
                            [teaserLocation]="SubCategoryTeaser"
                            [teaserCategory]="subcategory.categoryInfo.categoryid"
                            [teasersSource]="headerTeasers"
                            [optTeasersSource]="optimizedEmbeddedBelowTeasers"
                            [identifierClass]="subcategoryId + '_EmbeddedBelow'"
                            [lobbyType]="lobbyType"
                            [gameContainer]="gameContainer" />
                    </div>
                }
            </div>
        </div>
    }

    <!-- recent winners widget -->
    @if (isEnabledRecentWinners) {
        <div class="casino-recentWinners">
            <cc-recent-winners [category]="subCategoryInfo"> </cc-recent-winners>
        </div>
    }

    <!--  Prominent-freespins widget -->
    @if (isDisplayProminentFreeSpinsWidget && enableProminentFreespinsDisplay) {
        <div class="casino-prominent-freespins">
            <cc-prominent-freespins-widget [category]="subCategoryInfo"></cc-prominent-freespins-widget>
        </div>
    }

    <!--Player- Stats-->
    @if (enablePlayerstats && (!playerStatsWidgetPosition || playerStatsWidgetPosition === 'AfterGames')) {
        <div class="casino-playerstats">
            <cc-player-stat-filters [category]="subCategoryInfo"></cc-player-stat-filters>
        </div>
    }

    @if (subCategoryInfo?.sitecoreData?.showJackpotWidget || subCategoryInfo?.sitecoreData?.showMustGo) {
        <div
            class="casino-bgColorWidthfull"
            [ngClass]="{
                'casino-backgroungImage-layer': parallaxInput && parallaxInput.sitecoreData.backgroundImageLayer1,
                'casino-backgroungImage-parallax':
                    (parallaxInput && parallaxInput.sitecoreData.backgroundImageLayer2) ||
                    (parallaxInput && parallaxInput.sitecoreData.backgroundImageLayer3),
                'categoryBgFull': categoryBackgroundFull
            }"
            style.overflow="{{ !isTouch ? 'hidden' : 'unset' }}">
            @if (isJackpotdataForParallax && (enableSingleJackpot || enableMultiJackpot || enableMustGoJackpot)) {
                <div>
                    @if (
                        parallaxInput &&
                        (parallaxInput.sitecoreData.backgroundImageLayer1 ||
                            parallaxInput.sitecoreData.backgroundImageLayer2 ||
                            parallaxInput.sitecoreData.backgroundImageLayer3)
                    ) {
                        <cc-casino-core-lazy-placeholder [inputData]="{ subCategory: parallaxInput }" [componentName]="'ParallaxAnimationComponent'">
                        </cc-casino-core-lazy-placeholder>
                    }
                </div>
            }

            @if (isJackpotdataForParallax && (!enableSingleJackpot || !enableMultiJackpot || !enableMustGoJackpot)) {
                <div>
                    @if (
                        (subCategoryInfo?.sitecoreData?.jackpotWidget.length >= 1 || subCategoryInfo?.sitecoreData?.showMustGo) &&
                        (parallaxInput?.sitecoreData?.backgroundImageLayer1 ||
                            parallaxInput?.sitecoreData?.backgroundImageLayer2 ||
                            parallaxInput?.sitecoreData?.backgroundImageLayer3)
                    ) {
                        <cc-casino-core-lazy-placeholder [inputData]="{ subCategory: parallaxInput }" [componentName]="'ParallaxAnimationComponent'">
                        </cc-casino-core-lazy-placeholder>
                    }
                </div>
            }

            @if (subCategoryInfo?.sitecoreData?.showJackpotWidget && subCategoryInfo?.sitecoreData?.jackpotWidgetIds) {
                <div
                    class="casino-game-tile-wrapper casino-lobby-width-gametile"
                    [ngClass]="{ 'seeall-configs': changeCarouselView, 'multi-jackpot-main-container': enableMultiJackpot }">
                    @if (subCategoryInfo.sitecoreData.jackpotWidgetIds.length == 1) {
                        <div>
                            @if (!enableSingleJackpot) {
                                <cc-single-jackpot-widget
                                    id="SingleJackpotCategoryIdentifier_{{ subCategoryInfo.categoryid }}"
                                    [category]="subCategoryInfo"
                                    [changeCarouselView]="changeCarouselView"
                                    [isTouch]="isTouch"
                                    [lobbyType]="lobbyType"
                                    [gameContainer]="gameContainer"
                                    (isjackpotFeed)="getJackpotFeed($event)">
                                </cc-single-jackpot-widget>
                            }

                            @if (enableSingleJackpot) {
                                <cc-single-jackpot-redesign-widget
                                    id="SingleJackpotCategoryIdentifier_{{ subCategoryInfo.categoryid }}"
                                    [category]="subCategoryInfo"
                                    [changeCarouselView]="changeCarouselView"
                                    [isTouch]="isTouch"
                                    [lobbyType]="lobbyType"
                                    [gameContainer]="gameContainer"
                                    (isjackpotFeed)="getJackpotFeed($event)">
                                </cc-single-jackpot-redesign-widget>
                            }
                        </div>
                    }

                    @if (subCategoryInfo.sitecoreData.jackpotWidgetIds.length > 1) {
                        <div>
                            @if (!enableMultiJackpot) {
                                <cc-multi-jackpot-widget
                                    id="MultiJackpotCategoryIdentifier_{{ subCategoryInfo.categoryid }}"
                                    [category]="subCategoryInfo"
                                    [isTouch]="isTouch"
                                    [changeCarouselView]="changeCarouselView"
                                    [lobbyType]="lobbyType"
                                    [gameContainer]="gameContainer"
                                    (isjackpotFeed)="getJackpotFeed($event)"></cc-multi-jackpot-widget>
                            }

                            @if (enableMultiJackpot) {
                                <cc-multi-jackpot-widget-rebrand
                                    id="MultiJackpotRebrandCategoryIdentifier_{{ subCategoryInfo.categoryid }}"
                                    [category]="subCategoryInfo"
                                    [isTouch]="isTouch"
                                    [changeCarouselView]="changeCarouselView"
                                    [lobbyType]="lobbyType"
                                    [gameContainer]="gameContainer"
                                    (isjackpotFeed)="getJackpotFeed($event)">
                                </cc-multi-jackpot-widget-rebrand>
                            }
                        </div>
                    }
                </div>
            }

            @if (subCategoryInfo?.sitecoreData?.showMustGo) {
                <div class="casino-game-tile-wrapper casino-lobby-width-gametile" [ngClass]="{ 'seeall-configs': changeCarouselView }">
                    @if (!enableMustGoJackpot) {
                        <cc-must-go
                            id="MustGoCategoryIdentifier_{{ subCategoryInfo.categoryid }}"
                            [category]="subCategoryInfo"
                            [changeCarouselView]="changeCarouselView"
                            [isTouch]="isTouch"
                            [lobbyType]="lobbyType"
                            [gameContainer]="gameContainer"
                            (isjackpotFeed)="getJackpotFeed($event)">
                        </cc-must-go>
                    }

                    @if (enableMustGoJackpot) {
                        <cc-must-go-redesign-new
                            id="MustGoCategoryIdentifier_{{ subCategoryInfo.categoryid }}"
                            [category]="subCategoryInfo"
                            [changeCarouselView]="changeCarouselView"
                            [isTouch]="isTouch"
                            [lobbyType]="lobbyType"
                            [gameContainer]="gameContainer"
                            (isjackpotFeed)="getJackpotFeed($event)">
                        </cc-must-go-redesign-new>
                    }
                </div>
            }
        </div>
    }

    @if (videoUrl && (subCategoryInfo?.sitecoreData?.videoType || subCategoryInfo?.sitecoreData?.casinoVideo?.videoType)) {
        <div>
            <cc-casino-core-lazy-placeholder
                [inputData]="{
                    subcategory: subCategoryInfo,
                    type: videoType,
                    url: videoUrl,
                    launchUrl: launchUrl,
                    imageThumbnail: imageThumbnail,
                    videoName: videoName,
                    lobbyType: lobbyType,
                    videoBoxContent: videoBoxContent,
                    gameContainer: gameContainer,
                    displayVideoinLoop: displayVideoinLoop
                }"
                [componentName]="'CasinoGameVideosComponent'">
            </cc-casino-core-lazy-placeholder>
        </div>
    }
</div>
