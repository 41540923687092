import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csRecentlyPlayed', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: recentlyPlayedClientConfigFactory,
})
export class RecentlyPlayedClientConfig {
    recentlyPlayedRoutes: any;
    isMostRecentlyPlayedEnabled: boolean;
    recentlyPlayedFilterThreshold: number;
}

export function recentlyPlayedClientConfigFactory(service: LazyClientConfigService) {
    return service.get(RecentlyPlayedClientConfig);
}
