import { Injectable } from '@angular/core';

import { AppInfoConfig, Message, MessageLifetime, MessageQueueService, MessageType, TrackingService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { PlatformApiService } from '../platform-api/platform-api.service';
import { IGameSelectorWidget, IGameSelectorWidgetGlobalConfig } from './models/game-selector-widget.model';

@Injectable({
    providedIn: 'root',
})
export class GameSelectorWidgetService {
    widgetsMap: Map<string, IGameSelectorWidget> = new Map<string, IGameSelectorWidget>();
    private widgetsMapPublisher = new BehaviorSubject<boolean>(false);
    widgetsMapObservable: Observable<boolean> = this.widgetsMapPublisher.asObservable();

    globalConfig: IGameSelectorWidgetGlobalConfig;
    widgetProduct: string;
    constructor(
        private api: PlatformApiService,
        private messageQueue: MessageQueueService,
        private tracking: TrackingService,
        private appInfoConfig: AppInfoConfig,
    ) {}

    private emptyMap() {
        if (this.widgetsMap && this.widgetsMap.size > 0) {
            this.widgetsMap.clear();
        }
    }

    fetchWidgets(widgetPaths: Array<string>) {
        this.api
            .post('GameSelector/FetchWidgets', widgetPaths, {
                responseType: 'json',
                withCredentials: true,
                showSpinner: false,
            })
            .subscribe(
                (response: IFetchWidgetsResponse) => {
                    if (response.globalConfig) this.globalConfig = response.globalConfig;

                    this.emptyMap();
                    response.widgets.forEach((widget: IGameSelectorWidget) => {
                        this.widgetsMap.set(widget.path, widget);
                    });
                    this.widgetsMapPublisher.next(true);
                },
                (error: string) => {
                    let message: Message = {
                        html: error,
                        type: MessageType.Error,
                        lifetime: MessageLifetime.Single,
                        scope: 'casinocorelobby',
                    };
                    if (message) {
                        this.messageQueue.add(message);
                        setTimeout(() => {
                            this.messageQueue.clear();
                        }, 1000);
                    }
                },
            );
    }
    // NFR - Services optimization - Remove
    getWidgetsMap() {
        return this.widgetsMap;
    }

    getWidget(path: string) {
        return this.widgetsMap.get(path);
    }

    getGlobaConfig() {
        return this.globalConfig;
    }

    addTrackingEvent(
        categoryEvent: string,
        labelEvent: string,
        actionEvent: string,
        positionEvent: string,
        locationEvent: string,
        eventDetails: string,
        uRLClicked: string,
    ) {
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': categoryEvent,
            'component.LabelEvent': labelEvent,
            'component.ActionEvent': actionEvent,
            'component.PositionEvent': this.appInfoConfig.product + '-' + positionEvent,
            'component.LocationEvent': locationEvent,
            'component.EventDetails': eventDetails,
            'component.URLClicked': uRLClicked,
        });
    }
}

interface IFetchWidgetsResponse {
    widgets: Array<IGameSelectorWidget>;
    globalConfig: IGameSelectorWidgetGlobalConfig;
}
