import { Injectable } from '@angular/core';

import { NativeAppService, NavigationService } from '@frontend/vanilla/core';

@Injectable({ providedIn: 'root' })
export class CasinoWidgetService {
    product: string;
    constructor(
        private nativeAppService: NativeAppService,
        private navigationService: NavigationService,
    ) {}

    isCasinoWidget(): boolean {
        if (window.location.href.indexOf('casinowidget') > -1) {
            return true;
        }
        return false;
    }

    isfromCasino(): boolean {
        if (window.location.href.indexOf('launchEmbedded') == -1) {
            return true;
        }
        return false;
    }

    setWidgetProduct() {
        const widgetInvokerProduct = this.navigationService.location.search.get('invokerProduct');
        if (widgetInvokerProduct) {
            this.product = widgetInvokerProduct.toUpperCase();
        } else {
            this.product = '';
        }
    }

    getApiParams(params?: any): any {
        if (this.product) {
            const invokerProduct: string = params && params.invokerProduct && params.invokerProduct.length > 0 ? params.invokerProduct : this.product;
            if (params) {
                params.invokerProduct = invokerProduct;
            } else {
                params = {
                    invokerProduct: invokerProduct,
                };
            }
        }
        return params;
    }

    postMessageToParent(eventName: string, params: any): void {
        window.parent.postMessage({ eventName: eventName, params: params }, '*');
    }

    getWidgetPosition() {
        const widgetPosition = this.navigationService.location.search.get('widgetPosition');
        if (widgetPosition) {
            return widgetPosition?.toLowerCase();
        } else {
            return '';
        }
    }

    getWidgetProduct(): string {
        let widgetInvokerProduct: string = this.product;
        if (!widgetInvokerProduct || widgetInvokerProduct === '') {
            widgetInvokerProduct = this.navigationService.location.search.get('invokerProduct') || '';
        }
        if (this.nativeAppService.isNative) {
            widgetInvokerProduct = this.nativeAppService.product;
        }
        return widgetInvokerProduct;
    }

    setInvokerProduct(invokerProduct: string) {
        this.product = invokerProduct.toUpperCase();
    }
    isDisableCategoryTitle(): boolean {
        if (window.location.href.indexOf('disableCategoryTitle') > -1) {
            const disableCategory = this.navigationService.location.search.get('disableCategoryTitle');
            if (disableCategory?.toLowerCase() === 'true') {
                return true;
            }
        }
        return false;
    }
}
