import { Injectable } from '@angular/core';

import { ConfigProviderService, SearchSectionService } from '@casinocore/platform/core';
import { CookieService, MenuActionsService, NavigationService, TrackingService } from '@frontend/vanilla/core';

import { LibMockService } from '../services/lib-mock.service';
import { SportsOverlayComponent } from '../sports-overlay/sports-overlay.component';
import { NgDialogAnimationService } from './NgDialogAnimationService';

@Injectable({
    providedIn: 'root',
})
export class CasinoMenuActionsBootstrapService {
    isSearchOverlayVisible: boolean;
    constructor(
        private menuActions: MenuActionsService,
        private dialog: NgDialogAnimationService,
        private trackingService: TrackingService,
        private navigation: NavigationService,
        private configProviderService: ConfigProviderService,
        private searchSectionService: SearchSectionService,
        private libMockService: LibMockService,
        private cookie: CookieService,
    ) {}

    onAppInit() {
        if (!this.getFromCookie('dark-mode')) {
            document.getElementsByTagName('html')[0].classList.remove('casino-darkmode');
            this.setBackgroundColor();
        } else {
            document.getElementsByTagName('html')[0].classList.add('casino-darkmode');
        }
        this.menuActions.register('openSearch', () => {
            this.isSearchOverlayVisible = !this.isSearchOverlayVisible;
            this.searchSectionService.setSearchVisibility(this.isSearchOverlayVisible);
        });
        this.menuActions.register('casinoSportsOverlay', () => {
            this.trackingService.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'Sports Gaming Overlay',
                'component.LabelEvent': 'Gaming Overlay on Sports Lobby',
                'component.ActionEvent': 'Gaming Overlay - Loaded',
                'component.PositionEvent': 'not applicable',
                'component.LocationEvent': 'Sports Lobby',
                'component.EventDetails': 'not applicable',
                'component.URLClicked': 'not applicable',
            });
            document.getElementsByTagName('html')[0].classList.add('casino-gaming-cdk-overlay');
            const dialogRef = this.dialog.open(SportsOverlayComponent, {
                width: '100%',
                height: '100%',
                animation: { to: 'top' },
                position: { rowEnd: '0' },
                panelClass: 'casino-lobby-container-main',
            });
            this.libMockService.setDialogRef(dialogRef);
            setTimeout(() => {
                if (document.getElementById('casinocoreoverlay')) {
                    document.getElementById('casinocoreoverlay')!.scrollTo(0, 0);
                }
            }, 10);
            this.libMockService
                .getDialogRef()
                .afterClosed()
                .subscribe(() => {
                    document.getElementsByTagName('html')[0].classList.remove('casino-gaming-cdk-overlay');
                });
        });

        if (this.navigation.location.search.get('openCasinoCoreOverlay') === 'true') {
            document.getElementsByTagName('html')[0].classList.add('casino-gaming-cdk-overlay');
            const dialogRef = this.dialog.open(SportsOverlayComponent, {
                width: '100%',
                height: '100%',
                panelClass: 'casino-lobby-container-main',
            });
            this.libMockService.setDialogRef(dialogRef);
            setTimeout(() => {
                if (document.getElementById('casinocoreoverlay')) {
                    document.getElementById('casinocoreoverlay')!.scrollTo(0, 0);
                }
            }, 10);
            this.libMockService
                .getDialogRef()
                .afterClosed()
                .subscribe(() => {
                    document.getElementsByTagName('html')[0].classList.remove('casino-gaming-cdk-overlay');
                });
        }
    }

    getFromCookie(key: any): boolean {
        if (this.cookie.get(key) > '0') {
            return true;
        }
        return false;
    }

    setBackgroundColor() {
        const global = this.configProviderService.provideGlobalConfig();
        if (global?.lobbyConfigurations?.lobbyBackgroundColor) {
            document.body.style.backgroundColor = global.lobbyConfigurations.lobbyBackgroundColor;
            document.body.setAttribute('data-bg-color', global.lobbyConfigurations.lobbyBackgroundColor);
        }
    }

    setSearchOverlayVisibility(isSearchOverlayVisible: boolean) {
        this.isSearchOverlayVisible = isSearchOverlayVisible;
    }
}
