import { ModuleWithProviders, NgModule } from '@angular/core';

import { ApiServiceFactory } from '@frontend/vanilla/core';

import { ApiService } from './api.service';

@NgModule()
export class ApiModule {
    static forRoot(): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [{ provide: ApiService, deps: [ApiServiceFactory], useFactory: apiServiceFactory }],
        };
    }
}

export function apiServiceFactory(apiServiceFactory: ApiServiceFactory) {
    return apiServiceFactory.create(ApiService, { product: 'casino', area: 'games', forwardProductApiRequestHeader: true });
}
