import { Component, OnDestroy, OnInit } from '@angular/core';

import { CasinoManager } from '@casinocore/platform/core';
import { Subscription } from 'rxjs';

import { LobbyService } from '../../shared';
import { WidgetsComponent } from '../widgets/widgets.component';

@Component({
    selector: 'cs-widget-composition',
    templateUrl: 'widget-composition.component.html',
    standalone: true,
    imports: [WidgetsComponent],
})
export class WidgetCompositionComponent implements OnInit, OnDestroy {
    isDefaultComposition: boolean;
    lobbyType: string;
    categoryRoute: string;
    currentProduct: string;
    categoryChangeObs: Subscription;
    constructor(
        private lobbyService: LobbyService,
        private casinoManager: CasinoManager,
    ) {}

    ngOnInit() {
        this.categoryChangeObs = this.lobbyService.categoryChangeObservable.subscribe((containerMode) => {
            if (containerMode) {
                this.isDefaultComposition = containerMode.isRootOrDefault;
                this.lobbyType = this.casinoManager.getLobbyTypeForProduct(containerMode.routeCurrentProduct);
                this.categoryRoute = containerMode.routeCategoryType;
                this.currentProduct = containerMode.routeCurrentProduct;
            }
        });
    }

    ngOnDestroy() {
        if (this.categoryChangeObs) {
            this.categoryChangeObs.unsubscribe();
        }
    }
}
