import { Injectable } from '@angular/core';

import { onlyUnique } from '@casinocore/platform/utils';
import { DeviceService, Message, MessageLifetime, MessageQueueService, MessageType, TrackingService, WindowRef } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';

import { CasinoLobbyService } from '../casino-lobby-manager/casino-lobby.service';
import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';
import { CUSTOM_LINK_TYPE_DRAWER, ICustomLinkDrawerContent, IOnboarding, ImageType, LottieAnimationStatus } from './models/common.model';
import {
    CUSTOM_LINK_TYPE_JACKPOT_DRAWER,
    GameShowHubConfiguration,
    IGameShowHubGlobalClrConfig,
    IGameShowHubGlobalConfig,
    IGameShowHubJackpotDrawerContent,
    IGameShowHubMap,
    IGameShowHubSector,
    IGameShowHubSectorItem,
    ITEM_TYPE_CUSTOM_LINK,
} from './models/game-show-hub.model';

interface IGetMapResponse {
    map: IGameShowHubMap;
    globalConfig: IGameShowHubGlobalConfig;
    globalClrConfig: IGameShowHubGlobalClrConfig;
}

interface IGameShowHubJackpotSectorItem {
    sectorItem: IGameShowHubSectorItem;
    index: number;
}

@Injectable({
    providedIn: 'root',
})
export class GameShowHubService {
    document: Document;
    categoryRoutePrefix = '/c/';
    sectorIdPrefix = 'gsCat';
    loadingObservable: Observable<boolean>;
    mapObservable: Observable<boolean>;
    backgroundObservable: Observable<boolean>;
    animatedLayerObservable: Observable<boolean>;
    sectorsObservable: Observable<boolean>;
    sectorOpenedObservable: Observable<IGameShowHubSector | null>;
    chatWindowOpenedObservable: Observable<boolean | null>;
    lottieAnimationStatusObservable: Observable<LottieAnimationStatus | null>;
    drawerContentMapAvailable: Observable<boolean | null>;
    onboardingDataAvailable: Observable<boolean | null>;
    jackpotDrawerContentMapAvailable: Observable<boolean | null>;

    private currentLobbyType: string;
    private lobbyGames: Array<string>;
    private jackpotSectorItem: IGameShowHubJackpotSectorItem;
    private gameShowHubConfig: GameShowHubConfiguration;
    private loadingPublisher = new BehaviorSubject<boolean>(true);
    private mapPublisher = new BehaviorSubject<boolean>(false);
    private backgroundPublisher = new BehaviorSubject<boolean>(false);
    private animatedLayerPublisher = new BehaviorSubject<boolean>(false);
    private sectorsMap: Map<string, IGameShowHubSector> = new Map<string, IGameShowHubSector>();
    private sectorsPublisher = new BehaviorSubject<boolean>(false);
    private sectorOpenedPublisher = new BehaviorSubject<IGameShowHubSector | null>(null);
    private chatWindowOpenedPublisher = new BehaviorSubject<boolean | null>(null);
    private lottieAnimationStatusPublisher = new BehaviorSubject<LottieAnimationStatus | null>(null);
    private globalConfigData: IGameShowHubGlobalConfig;
    private mapData: IGameShowHubMap;
    private globalConfigClr: IGameShowHubGlobalClrConfig;
    private drawerContentMap: Map<string, ICustomLinkDrawerContent> = new Map<string, ICustomLinkDrawerContent>();
    private drawerContentMapPublisher = new BehaviorSubject<boolean | null>(null);
    private onboardingData: IOnboarding;
    private onboardingDataPublisher = new BehaviorSubject<boolean | null>(null);
    private jackpotDrawerContentMap: Map<string, IGameShowHubJackpotDrawerContent> = new Map<string, IGameShowHubJackpotDrawerContent>();
    private jackpotDrawerContentMapPublisher = new BehaviorSubject<boolean | null>(null);

    constructor(
        private windowRef: WindowRef,
        private api: PlatformApiService,
        private tracking: TrackingService,
        private deviceService: DeviceService,
        private messageQueue: MessageQueueService,
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
    ) {
        const nativeWindow = <any>this.windowRef.nativeWindow;
        this.document = nativeWindow.document;

        this.lobbyGames = [];
        this.gameShowHubConfig = this.configProviderService.provideGameShowHubConfig();
        this.loadingObservable = this.loadingPublisher.asObservable();
        this.mapObservable = this.mapPublisher.asObservable();
        this.backgroundObservable = this.backgroundPublisher.asObservable();
        this.animatedLayerObservable = this.animatedLayerPublisher.asObservable();
        this.sectorsObservable = this.sectorsPublisher.asObservable();
        this.sectorOpenedObservable = this.sectorOpenedPublisher.asObservable();
        this.chatWindowOpenedObservable = this.chatWindowOpenedPublisher.asObservable();
        this.lottieAnimationStatusObservable = this.lottieAnimationStatusPublisher.asObservable();
        this.drawerContentMapAvailable = this.drawerContentMapPublisher.asObservable();
        this.onboardingDataAvailable = this.onboardingDataPublisher.asObservable();
        this.gameShowHubConfig = this.configProviderService.provideGameShowHubConfig();
        this.jackpotDrawerContentMapAvailable = this.jackpotDrawerContentMapPublisher.asObservable();
    }

    get map(): IGameShowHubMap {
        return this.mapData;
    }

    get globalConfig(): IGameShowHubGlobalConfig {
        return this.globalConfigData;
    }

    get globalClrConfig(): IGameShowHubGlobalClrConfig {
        return this.globalConfigClr;
    }

    get isMobile(): boolean {
        return this.currentWidth <= 1024;
    }

    get lobbyType(): string {
        return this.currentLobbyType;
    }

    get mainElement(): HTMLDivElement {
        return this.document.getElementById('main-content')?.parentElement as HTMLDivElement;
    }

    get currentWidth(): number {
        return this.deviceService.isiOS
            ? (this.document.getElementById('casino-viewport') as HTMLElement)?.offsetWidth
            : this.deviceService.windowWidth();
    }

    get currentHeight(): number {
        return this.deviceService.isiOS ? (this.document.getElementById('casino-viewport') as HTMLElement)?.offsetHeight : window.innerHeight;
    }

    get isMobileLandscape(): boolean {
        return (
            this.deviceService.isMobilePhone &&
            this.currentWidth >= this.gameShowHubConfig.mobileLandscapeConfig.minWidth &&
            this.currentWidth <= this.gameShowHubConfig.mobileLandscapeConfig.maxWidth
        );
    }

    getScreenName(title: string): string {
        const content = document.createElement('span');
        content.className = 'removeHtmlTags';
        content.innerHTML = title;
        content.style.visibility = 'hidden';
        content.style.opacity = '0';
        content.style.height = '0';
        return content.innerHTML.split('<br>').join(' ');
    }

    getLoadingStatus() {
        return combineLatest([this.mapObservable, this.backgroundObservable, this.animatedLayerObservable, this.sectorsObservable]);
    }

    getMap(mapPath: string): void {
        this.api.post('GameShowHub/GetMap', { mapPath: mapPath }, { responseType: 'json', withCredentials: true, showSpinner: false }).subscribe(
            (response: IGetMapResponse) => {
                if (response) {
                    this.globalConfigData = response?.globalConfig;
                    this.globalConfigClr = response?.globalClrConfig;
                    this.mapData = response.map;
                    this.mapPublisher.next(true);

                    // Trigger Customlink Drawer Content
                    this.setCustomLinkDrawerContent();
                }
            },
            (error: string) => {
                const message: Message = {
                    html: error,
                    type: MessageType.Error,
                    lifetime: MessageLifetime.Single,
                    scope: 'casinocorelobby',
                };
                if (message) {
                    this.messageQueue.add(message);
                }
            },
            () => {},
        );
    }

    getLobbyGames(): Array<string> {
        return this.lobbyGames;
    }

    setLobbyGame(game: string): void {
        this.lobbyGames.push(game);
    }

    getJackpotSectorItem(): IGameShowHubJackpotSectorItem {
        return this.jackpotSectorItem;
    }

    setJackpotSectorItem(jackpotSectorItem: IGameShowHubSectorItem, index: number): void {
        this.jackpotSectorItem = { sectorItem: jackpotSectorItem, index: index } as IGameShowHubJackpotSectorItem;
    }

    initSectors(): void {
        if (this.sectorsMap.size) {
            const lottieSectors = Array.from(this.sectorsMap.values()).filter((sector: IGameShowHubSector) => sector.type === ImageType.Lottie);
            lottieSectors.forEach((m: IGameShowHubSector) => {
                m.isLoaded = false;
            });
            this.sectorsPublisher.next(true);
        }
    }

    initMap(): void {
        this.mainElement.classList.remove('casino-lobby-height');
        this.loadingPublisher.next(true);
        this.backgroundPublisher.next(false);
        this.animatedLayerPublisher.next(false);
        this.sectorsMap = new Map<string, IGameShowHubSector>();
        this.sectorsPublisher.next(false);
    }

    setLobbyType(lobbyType: string): void {
        this.currentLobbyType = lobbyType;
    }

    setBackgroundStatus(loaded: boolean): void {
        this.backgroundPublisher.next(loaded);
    }

    setAnimatedLayerStatus(loaded: boolean): void {
        this.animatedLayerPublisher.next(loaded);
    }

    setSectorLoaded(sectorId: string, sectorType: ImageType): void {
        if (this.sectorsMap.size && this.sectorsMap.has(sectorId)) {
            this.sectorsMap.set(sectorId, { ...this.sectorsMap.get(sectorId), isLoaded: true } as IGameShowHubSector);
        } else {
            this.sectorsMap.set(sectorId, { isLoaded: true, type: sectorType } as IGameShowHubSector);
        }

        this.sectorsPublisher.next(true);
    }

    getSectorsLoadedCount(): number {
        return this.sectorsMap.size ? Array.from(this.sectorsMap.values()).filter((sector: IGameShowHubSector) => sector.isLoaded).length : 0;
    }

    setSectorOpened(selectedMapItem: IGameShowHubSector | null): void {
        this.sectorOpenedPublisher.next(selectedMapItem);
    }

    setChatWindowOpened(isOpened: boolean): void {
        this.chatWindowOpenedPublisher.next(isOpened);
    }

    setLottieAnimationStatus(status: LottieAnimationStatus): void {
        this.lottieAnimationStatusPublisher.next(status);
    }

    setOnboardingData(): void {
        this.api.get('GameShowHub/GetOnboardingContent', {}, { responseType: 'json', withCredentials: true, showSpinner: false }).subscribe(
            (response: IOnboarding) => {
                if (response) {
                    this.onboardingData = response;
                    this.onboardingDataPublisher.next(true);
                }
            },
            (error: string) => {
                const message: Message = {
                    html: error,
                    type: MessageType.Error,
                    lifetime: MessageLifetime.Single,
                    scope: 'casinocorelobby',
                };
                if (message) {
                    this.messageQueue.add(message);
                }
            },
            () => {},
        );
    }

    getOnboardingData(): IOnboarding {
        return this.onboardingData;
    }

    fetchDrawerContent(paths: Array<string>): void {
        this.api
            .post('GameShowHub/FetchContent', { contentPaths: paths }, { responseType: 'json', withCredentials: true, showSpinner: false })
            .subscribe(
                (response: Array<ICustomLinkDrawerContent>) => {
                    if (response) {
                        this.emptyDrawerMap();
                        response.forEach((drawerContent: ICustomLinkDrawerContent) => {
                            this.drawerContentMap.set(drawerContent.path, drawerContent);
                        });
                        this.drawerContentMapPublisher.next(true);
                    }
                },
                (error: string) => {
                    const message: Message = {
                        html: error,
                        type: MessageType.Error,
                        lifetime: MessageLifetime.Single,
                        scope: 'casinocorelobby',
                    };
                    if (message) {
                        this.messageQueue.add(message);
                    }
                },
                () => {},
            );
    }
    // NFR - Services optimization - Remove
    getDrawerContentMap(): Map<string, ICustomLinkDrawerContent> {
        return this.drawerContentMap;
    }

    getDrawerContent(paths: Array<string>): ICustomLinkDrawerContent[] {
        const content = new Array<ICustomLinkDrawerContent>();
        paths.forEach((path: string) => {
            content.push(this.drawerContentMap.get(path) as ICustomLinkDrawerContent);
        });

        return content;
    }

    fetchJackpotDrawerContent(paths: Array<string>): void {
        this.api
            .post(
                'GameShowHub/FetchJackpotDrawerContent',
                { contentPaths: paths },
                { responseType: 'json', withCredentials: true, showSpinner: false },
            )
            .subscribe(
                (response: Array<IGameShowHubJackpotDrawerContent>) => {
                    if (response) {
                        this.emptyJackpotDrawerMap();
                        response.forEach((drawerContent: IGameShowHubJackpotDrawerContent) => {
                            this.jackpotDrawerContentMap.set(drawerContent.path, drawerContent);
                        });
                        this.jackpotDrawerContentMapPublisher.next(true);
                    }
                },
                (error: string) => {
                    const message: Message = {
                        html: error,
                        type: MessageType.Error,
                        lifetime: MessageLifetime.Single,
                        scope: 'casinocorelobby',
                    };
                    if (message) {
                        this.messageQueue.add(message);
                    }
                },
            );
    }
    // NFR - Services optimization - Remove
    getJackpotDrawerContentMap(): Map<string, IGameShowHubJackpotDrawerContent> {
        return this.jackpotDrawerContentMap;
    }

    getJackpotDrawerContent(paths: Array<string>): IGameShowHubJackpotDrawerContent[] {
        const content = new Array<IGameShowHubJackpotDrawerContent>();
        paths.forEach((path: string) => {
            content.push(this.jackpotDrawerContentMap.get(path) as IGameShowHubJackpotDrawerContent);
        });

        return content;
    }

    getJackpotAmount(jackpotAmount: string, jpTickerIncrement: number, formatCurrency: Function): { jpAmount: string; jpTickerIncrement: number } {
        const globalClientConfig = this.configProviderService.provideGlobalConfig();
        const jackpotConfig = this.configProviderService.provideJackpotConfig();
        let currency: string = '';

        jackpotAmount = jackpotAmount.replace(/\&nbsp;/g, '');
        const initialcurrency = jackpotAmount.match(/[^\d\.\,\s]/g);
        if (initialcurrency && initialcurrency.length > 0) {
            for (let i = 0; i < initialcurrency.length; i++) {
                currency = currency + initialcurrency[i];
            }
            currency = currency.replace(/\,/g, '');
            currency = currency.replace(/\./g, '');
        }

        jackpotAmount = jackpotAmount.replace(/\ /g, '');
        jackpotAmount = jackpotAmount.replace(/\ /g, '');
        jackpotAmount = jackpotAmount.replace(/\,/g, '');
        jackpotAmount = jackpotAmount.replace(/\./g, '');
        jackpotAmount = jackpotAmount.replace(currency, '');
        jackpotAmount = jackpotAmount.replace(/\s/g, '');
        jackpotAmount = jackpotAmount.match(/\b[\d, ]+\b/g)![0];

        if (jackpotAmount.length > 2) {
            const realpart = jackpotAmount.slice(0, jackpotAmount.length - 2);
            const decimalpart = jackpotAmount.slice(-2);
            jackpotAmount = realpart + '.' + decimalpart;
        }

        let jackpotTickValue: number | string = parseFloat(jackpotAmount.replace(/,/g, ''));
        if (globalClientConfig.lobbyConfigurations.IncrementTickingForJackpotOnGameTiles)
            jpTickerIncrement = +globalClientConfig.lobbyConfigurations.IncrementTickingForJackpotOnGameTiles;

        jackpotTickValue = jackpotTickValue + jpTickerIncrement * 0.01;
        jackpotTickValue = formatCurrency(jackpotTickValue.toFixed(2));
        if (jackpotConfig?.showJackpotCurrencyOnRight) {
            jackpotTickValue = (jackpotTickValue as string).replace(/[,.]/g, (m: any) => (m === ',' ? '.' : ','));
            return { jpAmount: jackpotTickValue + ' ' + currency + '.', jpTickerIncrement: jpTickerIncrement };
        } else return { jpAmount: currency + ' ' + jackpotTickValue, jpTickerIncrement: jpTickerIncrement };
    }

    showLoading(): void {
        if (!this.document.querySelector('.height-cls-skeleton')) this.loadingPublisher.next(true);
    }

    hideLoading(): void {
        if (this.document.querySelector('.height-cls-skeleton')) this.loadingPublisher.next(false);
    }

    getCategoryInfo(): { categoryId: string; categoryName: string } {
        const link = window.location.href;
        const subCategoryUrl = this.categoryRoutePrefix;
        let categoryId = link.substr(link.indexOf(subCategoryUrl) + (subCategoryUrl?.length || 0));
        if (categoryId.indexOf('?') !== -1) categoryId = categoryId.substr(0, categoryId.indexOf('?'));
        const lobbyInfo = this.casinoLobbyService.getLobbyInfo(this.lobbyType);
        const categoryInfo = lobbyInfo!.categoriesMap.get(categoryId);
        return { categoryId, categoryName: categoryInfo?.engCategoryName };
    }

    trackEvent(
        trackingType: string,
        categoryEvent: string,
        labelEvent: string,
        actionEvent: string,
        positionEvent: string,
        locationEvent: string,
        eventDetails: string,
        uRLClicked: string,
    ): void {
        this.tracking.triggerEvent(trackingType, {
            'component.CategoryEvent': categoryEvent,
            'component.LabelEvent': labelEvent,
            'component.ActionEvent': actionEvent,
            'component.PositionEvent': positionEvent,
            'component.LocationEvent': locationEvent,
            'component.EventDetails': eventDetails,
            'component.URLClicked': uRLClicked,
        });
    }

    private emptyDrawerMap(): void {
        if (this.drawerContentMap && this.drawerContentMap.size > 0) this.drawerContentMap.clear();
    }

    private emptyJackpotDrawerMap(): void {
        if (this.jackpotDrawerContentMap && this.jackpotDrawerContentMap.size > 0) this.jackpotDrawerContentMap.clear();
    }

    private setCustomLinkDrawerContent(): void {
        if (this.mapData) {
            const paths = new Map<string, Array<string>>();
            this.mapData?.sectors.forEach((sector: IGameShowHubSector) => {
                const customLinks = sector.customLinks.filter((m) => m?.itemType === ITEM_TYPE_CUSTOM_LINK) || [];
                const customLinkDrawers = customLinks
                    .filter((m) => m?.customLinkType === CUSTOM_LINK_TYPE_DRAWER && m?.customLinkDrawerContent)
                    .map((m) => m.customLinkDrawerContent);
                const customLinkDrawerContents = this.arrayString(customLinkDrawers);
                if (customLinkDrawerContents.length) {
                    if (paths.has(CUSTOM_LINK_TYPE_DRAWER))
                        paths.set(CUSTOM_LINK_TYPE_DRAWER, paths.get(CUSTOM_LINK_TYPE_DRAWER)!.concat(customLinkDrawerContents));
                    else paths.set(CUSTOM_LINK_TYPE_DRAWER, customLinkDrawerContents);
                }
                const customLinkJackpotDrawers = customLinks
                    .filter((m) => m?.customLinkType === CUSTOM_LINK_TYPE_JACKPOT_DRAWER && m?.customLinkDrawerContent)
                    .map((m) => m.customLinkDrawerContent);
                const customLinkJackpotDrawerContents = this.arrayString(customLinkJackpotDrawers);
                if (customLinkJackpotDrawerContents.length) {
                    if (paths.has(CUSTOM_LINK_TYPE_JACKPOT_DRAWER))
                        paths.set(
                            CUSTOM_LINK_TYPE_JACKPOT_DRAWER,
                            paths.get(CUSTOM_LINK_TYPE_JACKPOT_DRAWER)!.concat(customLinkJackpotDrawerContents),
                        );
                    else paths.set(CUSTOM_LINK_TYPE_JACKPOT_DRAWER, customLinkJackpotDrawerContents);
                }
            });

            if (paths && paths.size) {
                paths.forEach((value: string[], key: string) => {
                    if (!value?.length) return;
                    if (key === CUSTOM_LINK_TYPE_DRAWER) this.fetchDrawerContent(value.filter(onlyUnique));
                    if (key === CUSTOM_LINK_TYPE_JACKPOT_DRAWER) this.fetchJackpotDrawerContent(value.filter(onlyUnique));
                });
            }
        }
    }

    private arrayString(arr: any) {
        return new Array<string>().concat(...arr);
    }
}
