<div class="casino-lobby-container-main">
    <div class="embed-overlay-popup">
        <div mat-dialog-content>
            <div class="game-pop-out">
                <h1 class="iframe-theme-bg-popup">{{ gameLaunchNewWindow }}</h1>
                <h2 class="iframe-theme-bg-popup">{{ gameSessionRestart }}</h2>
            </div>
        </div>
        <div mat-dialog-actions class="iframe-theme-bg-popup">
            <button class="egv-pop-btn-left" mat-button [mat-dialog-close]="'continue'" cdkFocusInitial>{{ continue }}</button>
            <button class="egv-pop-btn-right" mat-button (click)="onNoClick()">{{ cancel }}</button>
        </div>
    </div>
</div>
