<!-- Testing Poker Routes -->
@if (isFirstTimeLoggedIn) {
    <div>
        <lh-home-page />
    </div>
}

<div id="casino-viewport" style="position: fixed; width: 100vw; height: 100vh; top: -100vh; left: -100vw"></div>

@if (!isFirstTimeLoggedIn) {
    <div class="casino-lobby-container casino-lobby-container-main">
        @if (routeInfoPublished) {
            <cs-lobby-conatiner [routeCurrentProduct]="routeCurrentProduct" [routeCategoryType]="routeCategoryType" />
        }
    </div>
}
