export class CasinoCoreNpmParams {
    isDll: boolean;
    disableFullLobbyFetch: boolean;
    fastLoadingVisibleOnLobby: any;
    isDisableLiveCasinoCalls: boolean;
    isDisableJackpotCalls: boolean;
    jackpotVisibleOnLobby: any;
    customLobbyTypeMaps: any;
    disableCasinoLocalStorage: boolean;
    targetOverlayLobby: boolean;
    disableSlotracesCallsOnBootstrap: boolean;
}
