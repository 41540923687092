import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { CasinoLobbyService, CasinoWidgetService, ConfigProviderService } from '@casinocore/platform/core';
import { DeviceService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

import { LMTSubCategoryLobbyComponent } from '../lmt-sub-category-lobby/lmt-sub-category-lobby.component';

@Component({
    selector: 'cc-lmt-sub-category-as-footer',
    templateUrl: 'lmt-sub-category-as-footer.component.html',
    standalone: true,
    imports: [NgIf, LMTSubCategoryLobbyComponent],
})
export class LMTSubCategoryAsFooterComponent implements OnInit, OnDestroy {
    isTouch: boolean;
    lobbyType: string;
    trackingIndex: number;
    subCategory: any;
    subCategoryRoute: string;
    gameContainer: string;
    isLobbyLoaded: boolean;
    lobbyLoadSubscription: Subscription;

    constructor(
        private deviceService: DeviceService,
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
        private casinoWidgetService: CasinoWidgetService,
    ) {}

    ngOnInit() {
        this.isTouch = this.deviceService.isTouch;
        this.trackingIndex = 1;
        this.gameContainer = 'Widget';
        this.lobbyLoadSubscription = this.casinoLobbyService.lobbyLoadedObservable.subscribe((response) => {
            if (response) {
                this.gettingInputVariables();
            }
        });
    }
    gettingInputVariables(): void {
        const widgetConfig: any = this.configProviderService.providewidgetConfig();
        this.lobbyType = widgetConfig.lobbyTypeForFooterOnOtherProducts;
        this.subCategoryRoute = widgetConfig.subCategoryRouteForFooterOnOtherProducts;
        if (this.subCategoryRoute) {
            this.subCategoryRoute = this.subCategoryRoute?.toLowerCase();
        }
        this.subCategory = this.casinoLobbyService.getCategoryInfo(this.lobbyType, this.subCategoryRoute);
        this.isLobbyLoaded = true;
        setTimeout(() => {
            this.casinoWidgetService.getWidgetHeight('SubCategoryAsFooter', 'iframeFooterId');
        }, 500);
    }
    ngOnDestroy() {
        if (this.lobbyLoadSubscription) {
            this.lobbyLoadSubscription.unsubscribe();
        }
    }
}
