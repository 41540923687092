<button
    style="display: inline-flex"
    class="btn btn-primary"
    [class.rcp-fav-btn]="isFav ? true : null"
    [class.rcp-widget-btn]="!isFav ? true : null"
    [class.activebtn]="setActive ? true : null"
    [class.inactive]="!setActive ? true : null"
    [style.color]="color"
    [style.background-color]="backgroundColor">
    <cs-class-icon [startIconClass]="startIconClass"></cs-class-icon>
    <ng-content></ng-content>
    <i class="theme-arrow-down" [class.theme-arrow-up]="!setActive ? true : null"></i>
</button>
