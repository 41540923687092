import { NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';

import {
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    DesignSystemEnablerService,
    FastLoadingGamesService,
    GameDataManager,
    GameLobbyModel,
    IContentImage,
    IContentLink,
    JackpotDataService,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, GameTileCtaComponent } from '@frontend/casino-ui';
import { NavigationService } from '@frontend/vanilla/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FlipclockRedesignComponent } from '../flipclock-redesign/flipclock-redesign.component';
import { JackpotWidgetRedesignGridComponent } from '../jackpot-widget-redesign-grid/jackpot-widget-redesign-grid.component';

@Component({
    selector: 'cc-single-jackpot-redesign-widget',
    templateUrl: 'single-jackpot-redesign-widget.component.html',
    standalone: true,
    imports: [NgIf, NgStyle, NgClass, FlipclockRedesignComponent, JackpotWidgetRedesignGridComponent, GameTileCtaComponent, DecoratorClassDirective],
})
export class SingleJackpotRedesignWidgetComponent implements OnInit, OnChanges, OnDestroy {
    @Input() category: any;
    @Input() isTouch: boolean;
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    @Input() jackpotValue: number;
    @Input() jackpotTikking: any;
    @Input() changeCarouselView: boolean = true;
    @Output() isjackpotFeed = new EventEmitter<object>();
    games: GameLobbyModel[] = [];
    jackpotType = 'single';
    showJackpotWidget: boolean = false;
    cta: string;
    // jackpotValue: number;
    jackpotName: string;
    enableJpGroupTickingForLobby: boolean;
    jackpotWidgetBackgroundImage: IContentImage;
    jackpotWidgetImage: IContentImage;
    singleJackpotBgClr: string;
    currency: string;
    singleJackpotPlayAnygameText: string;
    singleJackpotAlignment: string;
    singleJackpotGlassEffect: boolean;
    showJackpotName: boolean;
    ctaUrl: IContentLink;
    singleJackpotReleaseAmount: number;
    singleJackpoTitleTextColor: string;
    singleJackpotPlayAnyGameTextColor: string;
    singleJackpotTextClr: string;
    jackpotsCache: any;
    categoryObj: any;
    totalJpGroupList: any;
    subCategoryTextColor: string;
    private jackpotWidget: any;
    private jackpotWidgetItem: any;
    private seeAllText: string;
    private jackpotGames: string[] = [];
    private destroy$ = new Subject<void>();
    widgets: any;
    glassEffectColor: string;
    JackpotDisplayNameConfig: any;
    jackpotDisplayName: string;
    isLegacy: boolean = false;
    constructor(
        private jackpotDataService: JackpotDataService,
        private casinoLobbyService: CasinoLobbyService,
        private navigation: NavigationService,
        private gameDataManager: GameDataManager,
        private configProviderService: ConfigProviderService,
        private fastLoadingGamesService: FastLoadingGamesService,
        private casinoManager: CasinoManager,
        private designSystemEnablerService: DesignSystemEnablerService,
    ) {}
    ngOnChanges(): void {
        if (this.jackpotWidgetBackgroundImage && !this.jackpotWidgetBackgroundImage.src) {
            this.jackpotWidgetBackgroundImage.src = '';
        }
        if (this.jackpotWidgetImage && !this.jackpotWidgetImage.src) {
            this.jackpotWidgetImage.src = '';
        }
        this.startJackpotFeed();
    }
    ngOnInit(): void {
        const jackpotDataConfig = this.configProviderService.provideJackpotConfig();
        const global = this.configProviderService.provideGlobalConfig();
        this.singleJackpotBgClr = global?.lobbyConfigurations?.singleJackpotBgClr;
        this.enableJpGroupTickingForLobby = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.lobbyType,
            jackpotDataConfig.enableJpGroupTickingForLobbyType,
        );
        if (jackpotDataConfig.enableJackpotSortGames) {
            this.getSortingGames();
        }
        this.fastLoadingGamesService.fastLoadingGamesFeedObservable.pipe(takeUntil(this.destroy$)).subscribe((fastLoadingGames: any) => {
            this.fastLoadingGamesService.setFastLoadingGames(this.games, fastLoadingGames);
        });

        this.singleJackpotPlayAnygameText = jackpotDataConfig?.jackpotWidgetConfigurations?.SingleJackpotPlayAnyGameText;
        this.singleJackpotAlignment = jackpotDataConfig?.jackpotWidgetConfigurations?.SingleJackpotAlignment;
        this.singleJackpoTitleTextColor = jackpotDataConfig?.jackpotWidgetConfigurations?.SingleJackpoTitleTextColor;
        this.singleJackpotPlayAnyGameTextColor = jackpotDataConfig?.jackpotWidgetConfigurations?.SingleJackpotPlayAnyGameTextColor;
        this.singleJackpotReleaseAmount = jackpotDataConfig?.jackpotWidgetConfigurations?.SingleJackpotReleaseAmount;
        const glassEffect = jackpotDataConfig?.jackpotWidgetConfigurations?.SingleJackpotGlassEffect;
        if (glassEffect && glassEffect.toLowerCase() === 'true') {
            this.singleJackpotGlassEffect = true;
        }
        this.glassEffectColor = this.singleJackpotGlassEffect
            ? jackpotDataConfig?.jackpotWidgetConfigurations?.GlassEffectColor
            : this.singleJackpotBgClr;
        const showJackpotTitle = jackpotDataConfig?.jackpotWidgetConfigurations?.ShowJackpotName;
        if (showJackpotTitle && showJackpotTitle.toLowerCase() === 'true') this.showJackpotName = true;
        this.seeAllText = jackpotDataConfig?.jackpotWidgetConfigurations?.SeeAll;
        this.cta = this.seeAllText + ' ' + '(' + this.games?.length + ')';
        this.singleJackpotTextClr = global.lobbyConfigurations.singleJackpotTextClr;
        this.subCategoryTextColor = this.casinoLobbyService.getsubCategoryTextColor(this.category);
        this.isLegacy = !this.designSystemEnablerService.isEnabled();
    }

    gotoJackpots(ctaUrl: any): void {
        if (ctaUrl) {
            this.navigation.goTo(ctaUrl, { replace: false, forceReload: true });
        }
    }

    private startJackpotFeed(): void {
        this.showJackpotWidget = false;
        this.jackpotDataService.jackpotFeedObservable.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
            if (response) this.refreshJackpotsNew();
        });
    }
    private refreshJackpotsNew(): void {
        let sortGameslist: any;
        if (this.category.sitecoreData.showJackpotWidget) {
            this.JackpotDisplayNameConfig = this.configProviderService.provideGlobalConfig()?.jackpotDisplayNameConfigurations;
            this.jackpotsCache = this.jackpotDataService.getJackpotData();
            this.jackpotWidget = decodeURIComponent(this.category.sitecoreData.jackpotWidget[0]);
            if (this.jackpotsCache && this.jackpotWidget && this.jackpotWidget !== 'null') {
                this.jackpotWidgetItem = this.getJackpotWidgetItem(this.jackpotWidget, this.jackpotsCache);
                if (this.jackpotDataService && Object.keys(this.jackpotDataService).length) {
                    if (
                        !(
                            this.jackpotName &&
                            this.jackpotName?.toLowerCase() === this.jackpotWidget?.toLowerCase() &&
                            this.jackpotValue === this.jackpotWidgetItem.amount
                        )
                    ) {
                        this.jackpotDisplayName = this.JackpotDisplayNameConfig[this.jackpotWidgetItem.jackpotGroupId]
                            ? this.JackpotDisplayNameConfig[this.jackpotWidgetItem.jackpotGroupId]
                            : this.jackpotWidgetItem.jackpotGroupName;
                        this.jackpotName = this.jackpotWidgetItem.jackpotGroupName;
                        this.jackpotGames = this.jackpotWidgetItem.gamesList;
                        this.jackpotValue = this.jackpotWidgetItem?.value;
                        this.jackpotWidgetBackgroundImage = this.jackpotWidgetItem.backgroundImage;
                        this.jackpotWidgetImage = this.jackpotWidgetItem.jackpotImage;
                        if (this.totalJpGroupList && this.totalJpGroupList.length > 0) {
                            sortGameslist = this.jackpotDataService.filterJpGroup(this.totalJpGroupList, this.jackpotWidgetItem.jackpotGroupName);
                        }
                        if (this.jackpotGames && this.jackpotGames.length > 0) {
                            this.setGames(this.jackpotGames, sortGameslist);
                        }
                        if (this.jackpotWidgetImage && this.games && this.games.length > 0) {
                            this.showJackpotWidget = true;
                        }
                        this.cta = (this.jackpotWidgetItem?.ctaLink?.text ?? this.seeAllText) + ' (' + this.games?.length + ')';
                        this.ctaUrl = this.jackpotWidgetItem.ctaLink;
                    }
                }
            }
        } else {
            this.showJackpotWidget = false;
        }
    }
    private setGames(gameslist: string[], sortGameslist?: any): void {
        this.games = [];
        if (gameslist && gameslist.length > 0) {
            let starttileBgMode: boolean = false;
            const newgridbgcolor = this.category?.sitecoreData?.attributes?.newgridbgcolor;
            this.categoryObj = { ...this.category };
            this.categoryObj.rowCount = '-1';
            for (let i = 0; i < gameslist.length; i++) {
                const gameIdString: string = gameslist[i];
                const gameInfo = this.casinoLobbyService.getGameInfo(gameIdString);
                if (gameInfo) {
                    let disabledGame: boolean = false;
                    const disabledGames = this.casinoLobbyService.getDisabledGames();
                    disabledGames.forEach((game: string) => {
                        if (game.toLowerCase() === gameInfo.game.toLowerCase()) {
                            disabledGame = true;
                        }
                    });
                    if (!disabledGame) {
                        gameInfo.icon = this.category.isIconSixCategory ? '6' : this.category.isIconSevenCategory ? '7' : '1';
                        gameInfo.lobbyType = this.lobbyType;
                        const gameObj = this.gameDataManager.setGameLobbyModel(gameInfo, this.isTouch);
                        gameObj.gameContainer = this.gameContainer;
                        gameObj.bgTileMode = this.category.isIconSevenCategory ? (starttileBgMode ? starttileBgMode : false) : false;
                        gameObj.gameTileBorderRadius = this.casinoLobbyService.getBorderRadius(this.category);
                        gameObj.gameTileDropShadow = this.casinoLobbyService.getDropShadow(this.category);
                        gameObj.infoIconClass = this.casinoManager.assignGameClass(this.casinoManager.getCurrentWidth(), gameInfo.icon);
                        gameObj.newgridbgcolor = newgridbgcolor;
                        starttileBgMode = !starttileBgMode;
                        this.games.push(gameObj);
                    }
                }
            }
            if (this.games && this.games.length > 0) {
                this.isjackpotFeed.emit({ isJackpotdataForParallax: true });
            }

            if (sortGameslist && sortGameslist.length > 0) {
                this.games = this.jackpotDataService.filterGames(sortGameslist, this.games);
            }
        }
    }
    private getJackpotWidgetItem(jackpotWidgetKey: any, jackpots: any) {
        const widgetItem = {};
        for (const jackpot in jackpots) {
            const jackpotData = jackpots[jackpot];
            const currency = jackpotData.amount.match(/[^\d\.\,\s]/g);
            if (currency && currency.length > 0) {
                this.currency = '';
                for (let i = 0; i < currency.length; i++) {
                    this.currency = this.currency + currency[i];
                }
                this.currency = this.currency.replace(/\,/g, '');
                this.currency = this.currency.replace(/\./g, '');
            }
            if (jackpot.toLowerCase() === jackpotWidgetKey.toLowerCase()) {
                return jackpots[jackpot];
            } else if (jackpots[jackpot] && jackpots[jackpot].subJackpotDetails) {
                for (const subjackpot of jackpots[jackpot].subJackpotDetails) {
                    if (subjackpot && subjackpot.subJackpotName?.toLowerCase() === jackpotWidgetKey?.toLowerCase()) {
                        subjackpot.gamesList = jackpots[jackpot].gamesList;
                        subjackpot.jackpotGroupName = subjackpot.subJackpotName;
                        subjackpot.amount = subjackpot.subJackpotAmount;
                        subjackpot.Value = subjackpot.subJackpotValue;
                        return subjackpot;
                    }
                }
            }
        }
        return widgetItem;
    }
    getSortingGames(): void {
        this.jackpotDataService.jpSortGamesObservable.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
            if (data) {
                this.totalJpGroupList = data?.jackpotGroupGamesList;
                this.refreshJackpotsNew();
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
