<div id="jackpot-Subcategorymodal" class="jackpot-moreinfo-modal" *ngIf="categorymoreinfo">
    <div class="jackpot-category-tittle-info">
        <div class="jackpot-category-tittle-badge-layout">
            <div class="jackpot-category-tittle" *ngIf="categoryname">{{ categoryname }}</div>
            <div class="jackpot-category-badge-layout" *ngIf="jackpotBadgeStatus">
                <div class="jackpot-category-badge">{{ jackpotBadgeStatus }}</div>
            </div>
        </div>
        <div class="close-button" *ngIf="jackpotMoreInfoCloseCtaText">
            <div class="btn btn-light btn-md" (click)="closeModel(true)">
                <div class="close-text">{{ jackpotMoreInfoCloseCtaText }}</div>
            </div>
        </div>
    </div>
    <div class="jackpot-category-moreinfo-layout">
        <div class="jackpot-category-moreinfo" [innerHTML]="categorymoreinfo"></div>
    </div>
    <div class="see-all-cta" *ngIf="seeAllCta && seeallctaurl">
        <div class="btn btn-primary btn-lg see-all-layout" (click)="seeAll(seeallctaurl)">
            {{ seeAllCta }}
        </div>
    </div>
</div>
