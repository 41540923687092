import { NgIf } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';

import { ImmersiveLobbySkeletonLoadingConfiguration } from '@casinocore/platform/core';

import { LottieLoadingComponent } from './lottie-loading/lottie-loading.component';

@Component({
    selector: 'cs-immersive-lobby-skeleton',
    templateUrl: 'immersive-lobby-skeleton.component.html',
    standalone: true,
    imports: [NgIf, LottieLoadingComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ImmersiveLobbySkeletonComponent {
    @Input() loadingConfig: ImmersiveLobbySkeletonLoadingConfiguration;
}
