import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { NavOnProductComponent } from '@casinocore/platform';
import { CasinoLobbyService } from '@casinocore/platform/core';
import { Subscription } from 'rxjs';

import { LobbyService } from '../../shared';

@Component({
    selector: 'cs-games-nav-section',
    templateUrl: 'games-nav-section.component.html',
    standalone: true,
    imports: [NavOnProductComponent],
})
export class GamesNavSectionComponent implements OnInit, OnDestroy {
    @Input() data: any;
    @Input() lobbyType: string;
    product: string;
    categoryChangeObs: Subscription;
    routeCurrentProduct: string;
    routeCategoryType: string;

    constructor(
        private lobbyService: LobbyService,
        private casinoLobbyService: CasinoLobbyService,
    ) {}

    ngOnInit() {
        this.product = this.data?.staticProduct;
        this.categoryChangeObs = this.lobbyService.categoryChangeObservable.subscribe((productAndCat: any) => {
            if (productAndCat) {
                this.routeCurrentProduct = productAndCat.routeCurrentProduct;
                this.setRouteCategory(productAndCat.routeCategoryType);
            }
        });
    }

    ngOnDestroy() {
        if (this.categoryChangeObs) {
            this.categoryChangeObs.unsubscribe();
        }
    }

    setRouteCategory(routeCategory: string) {
        if (!routeCategory || routeCategory === '') {
            const defaultCatInfo: any = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(this.lobbyType);
            if (defaultCatInfo && defaultCatInfo.categoryInfo) {
                this.routeCategoryType = defaultCatInfo.categoryInfo.categoryid;
            }
        } else {
            this.routeCategoryType = routeCategory;
        }
    }
}
