<span id="gameDetailPage" class="gameDetailPage">
    <cc-header-box [title]="'DetailPageHeader'" [showInNative]="true" (navigateTo)="closeGameDetailDialog()"></cc-header-box>
    <vn-message-panel></vn-message-panel>
    <div class="casino-game-detail casino-lobby-games" [hidden]="!model">
        <div class="casino-game-detail-header">
            <div class="game casino-game-detail-header1">
                <a href="javascript:void(0)" class="game-image-link">
                    <img class="game-icon" [src]="model.icon.src" [alt]="model.icon.alt" [width]="model.icon.width" [height]="model.icon.height" />
                </a>
                <div *ngIf="useFavouriteGames" class="game-badge" [ngClass]="isFavouriteGame(model) ? favouriteStyle : unFavouriteStyle">
                    <span (click)="toggleFavourite(model, $event)"></span>
                </div>
            </div>
            <div class="casino-game-detail-header2" *ngIf="gameInfo">
                <h1>{{ gameInfo.game.game.name }}</h1>
            </div>
        </div>
        <cc-game-detail-buttons
            *ngIf="model"
            [game]="model"
            [cssClass]="'small-device'"
            [place]="1"
            [launchType]="model.provider"></cc-game-detail-buttons>
        <a *ngIf="model && model.responsibleGamingLink" [href]="model.responsibleGamingLink.url">{{ model.responsibleGamingLink.linkText }}</a>

        <div *ngIf="model && model.info && model.info !== ''" class="game-detail-info" [innerHTML]="model.info | trustAsHtml"></div>

        <div *ngIf="model && model.disclaimer && model.disclaimer !== ''" class="game-detail-disclaimer">
            <p>{{ model.disclaimer }}</p>
        </div>
        <div *ngIf="model && model.screenshots && model.screenshots.length > 0" class="game-preview">
            <h3>
                <cc-global-translate [key]="'PreviewTitle'"></cc-global-translate>
            </h3>
        </div>
        <button *ngIf="!twoButtons" class="btn">
            <cc-global-translate [key]="'ButtonPlayNow'"></cc-global-translate>
        </button>
    </div>
</span>
