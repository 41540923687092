<div
    [class.casino-playbtn-hover-effect]="playNowHoverEffect"
    #gameref
    class="casino-game-cls"
    (mouseover)="mouseOver(bingoItem.name)"
    (mouseleave)="mouseLeave()"
    [ngClass]="{ 'casino-new-styling-overlay': onMouseHover && !isTouch, 'casino-quick-info-active': useQuickInfo }">
    <div class="casino-overlay-bg" *ngIf="onMouseLeave && enableUkMouseOverStyle && !isTouch"></div>
    @if (QuickInfoVisible && QuickInfoVisible === bingoItem.name) {
        <cc-game-quick-info
            [game]="bingoItem"
            [gamePosition]="gamePosition"
            [categoryType]=""
            [icon]="quickInfoIcon"
            (hide)="hideQuickInfo()"
            [quickInfoScrollY]="quickInfoScrollY"
            [gameLaunchedFrom]="gameLaunchedFrom"
            [gridPosition]="1"
            [gameModel]="bingoItem"
            [isLiveGame]="false"
            [widgetFrameClr]="widgetFrameClr"
            [gameContainerLocation]="gameContainerLocation"
            [liveGameInfo]="{}"
            [product]="product"
            [stickersArray]="[]"
            [thumbImageSrc]="thumbImageSrc"
            [lobbyType]="lobbyType">
        </cc-game-quick-info>
    }
    <a class="game-image-link" (click)="gameLaunch()">
        <div class="image-load-div">
            <span class="game-name" [ngClass]="{ 'game-name-safari': isSafari }">{{ bingoItem.name }}</span>
            <img src="{{ thumbImageSrc }}" />
        </div>
    </a>
    <div class="game-icon-bottom-wrapper">
        <!-- i icon for quick info functionality in mobile -->
        <div
            *ngIf="isTouch && useQuickInfo; else Favourite"
            class="casino-game-fav-icon casino-mobile-fav theme-info casino-quick-info rcntly-gyml-quick-info rp-i-fav-icon"
            (click)="displayQuickInfo($event)">
            {{ moreInfo }}
        </div>
    </div>
</div>

<!--Mobile View -->
