import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csNonCriticalFeedConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: nonCriticalClientConfigFactory,
})
export class NonCriticalClientConfig {
    enableRecommendationGames: boolean;
}

export function nonCriticalClientConfigFactory(service: LazyClientConfigService) {
    return service.get(NonCriticalClientConfig);
}
