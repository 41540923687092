import { Injectable } from '@angular/core';

import { TrackingService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
})
export class GeolocationTrackingService {
    constructor(private trackingService: TrackingService) {}
    trackPosition(labelEvent: string, actionEvent: string, positionEvent: string) {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'US_NJ_GEOAPP',
            'component.LabelEvent': labelEvent,
            'component.ActionEvent': actionEvent,
            'component.PositionEvent': positionEvent,
            'component.LocationEvent': 'not_applicable',
            'component.EventDetails': 'not_applicable',
            'component.URLClicked': 'not_applicable',
        });
    }
    track(labelEvent: string, actionEvent: string, locationEvent?: string) {
        if (locationEvent) {
            this.trackingService.triggerEvent('Event.Tracking', {
                'component.CategoryEvent': 'US_NJ_GEOAPP',
                'component.LabelEvent': labelEvent,
                'component.ActionEvent': actionEvent,
                'component.PositionEvent': 'not_applicable',
                'component.LocationEvent': locationEvent,
                'component.EventDetails': 'not_applicable',
                'component.URLClicked': 'not_applicable',
            });
        } else {
            this.trackingService.triggerEvent('Event.Tracking', {
                'component.CategoryEvent': 'US_NJ_GEOAPP',
                'component.LabelEvent': labelEvent,
                'component.ActionEvent': actionEvent,
                'component.PositionEvent': 'not_applicable',
                'component.LocationEvent': 'not_applicable',
                'component.EventDetails': 'not_applicable',
                'component.URLClicked': 'not_applicable',
            });
        }
    }
}
