import { Component, Input, OnInit } from '@angular/core';

import { SubNavOnLobbyTypeComponent } from '@casinocore/platform';

@Component({
    selector: 'cs-games-sub-nav-section',
    templateUrl: 'games-sub-nav-section.component.html',
    standalone: true,
    imports: [SubNavOnLobbyTypeComponent],
})
export class GamesSubNavSectionComponent implements OnInit {
    @Input() data: any;
    @Input() lobbyType: string;
    lobbyTypePathSegment: string;

    ngOnInit() {
        this.lobbyTypePathSegment = this.lobbyType + '#' + this.data?.pathSegment + '#';
    }
}
