<div class="casino-lobby-container-main">
    @if (isTouch && showQuickInfo && !newQuickInfoStyle) {
        <div>
            <div class="casino-qi-overlay" (click)="hideInfo()"></div>
            <div class="casino-qi-rectangle" [style.width.px]="boxWidth">
                <div class="casino-qi-game">{{ gameTitle }}</div>
                <div class="casino-qi-rectangle-int">
                    <div class="casino-qi-image">
                        <img src="{{ src }}" />
                        <div *ngIf="useFavouriteGames">
                            <div class="casino-qi-gradient"></div>
                            <div
                                (click)="toggleFavourite(game, $event)"
                                class="casino-qi-favourite"
                                [ngClass]="isFavouriteGame(game) ? favouriteStyle : unFavouriteStyle"></div>
                        </div>
                    </div>
                    <div class="casino-qi-rectangle-right" [style.width.px]="boxWidthInt">
                        <div class="casino-qi-game-provider">{{ gameProvider }}</div>
                        <div class="casino-qi-provider">{{ game.launchType | uppercase }}</div>
                        <div class="casino-qi-cta" [style.width.px]="boxWidthInt" (click)="launchGame(game, false)">
                            <!-- <button class="casino-qi-play-now btn">{{ cta | uppercase }}</button> -->
                            <cs-gametile-cta
                                [isLegacy]="isLegacy"
                                [kind]="'primary'"
                                [csDecoratorClass]="'casino-qi-play-now'"
                                [cta_text]="cta | uppercase">
                            </cs-gametile-cta>
                        </div>
                    </div>
                    <div style="clear: both"></div>
                </div>
            </div>
        </div>
    }

    @if (isTouch && showQuickInfo && contentLoaded && newQuickInfoStyle) {
        <div>
            <div class="casino-qi-noverlay" (click)="hideInfo()"></div>
            <div class="casino-qi-nrectangle" [style.width.px]="boxWidth">
                <div class="casino-total-data">
                    <div class="casino-qi-nrectangle-int">
                        <div class="casino-qi-nimage">
                            <img src="{{ src }}" />
                            <div class="casino-relativ-div-quickinfo">
                                <cc-stickers-jackpot
                                    [demoEnabled]="demoEnabled"
                                    [stickers]="stickers"
                                    [isTouch]="isTouch"
                                    [jackpotValue]="jackpotValue"
                                    [isHotJackpotGroup]="isHotJackpotGroup"
                                    [isStickersForUk]="isStickersForUk"
                                    [lobbyType]="lobbyType"></cc-stickers-jackpot>
                            </div>
                            <div *ngIf="useFavouriteGames">
                                <div class="casino-qi-ngradient"></div>
                                <div
                                    (click)="toggleFavourite(game, $event)"
                                    class="casino-qi-nfavourite"
                                    [ngClass]="isFavourite ? favouriteStyle : unFavouriteStyle"></div>
                            </div>
                        </div>
                        <div class="casino-qi-nrectangle-right" [style.width.px]="boxWidthInt">
                            <div class="casino-qi-ngame-provider">{{ gameProvider | uppercase }}</div>

                            <div class="close theme-close qi-close" (click)="hideInfo()"></div>
                            <div *ngIf="vendorLogo" class="casino-qi-nlogo"><img src="{{ vendorLogo }}" /></div>
                            <div class="casino-qi-nlogo" *ngIf="!vendorLogo">{{ game.launchType | uppercase }}</div>

                            <div *ngIf="enableQuickInfoReels" class="casino-quickInfoReelsTopMobile">
                                <div *ngIf="reels" class="reels-dv casino-quickInfoReelsWidth">
                                    <div class="reelsValue">{{ reels }}</div>
                                    <div *ngIf="reels" class="Reels">
                                        {{ reelsTitle }}
                                    </div>
                                </div>
                                <div *ngIf="payLines" class="paylines casino-quickInfoReelsWidth">
                                    <div class="reelsValue">{{ payLines }}</div>
                                    <div *ngIf="payLines" class="Paylines">
                                        {{ payLinesTitle }}
                                    </div>
                                </div>
                                <div *ngIf="returnToPlayer" class="returntoplayer casino-quickInfoReelsWidth">
                                    <div class="returnToPlayerValue">{{ returnToPlayer }}</div>
                                    <div *ngIf="returnToPlayer" class="Return-to-Player">
                                        {{ returnToPlayerTitle }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both"></div>
                    </div>

                    <div class="gamedata">
                        <div class="casino-qi-ngame">{{ gameTitle }}</div>
                        <span class="gameData-body" [innerHTML]="gameDescription | trustAsHtml"></span>
                    </div>

                    <div class="casino-qi-btn-container">
                        <!-- <div (click)="launchGame(game, true)" class="casino-qi-demo-link"
                        *ngIf="demoEnabled && demoEnabledForProvider">
                        <a [ngStyle]="{ color: demoLinkTextColor }">{{ demoLinkText | uppercase }}</a>
                    </div> -->
                        @if (demoEnabled && demoEnabledForProvider) {
                            <cs-gametile-cta
                                [isLegacy]="isLegacy"
                                [kind]="'tertiary'"
                                [variant]="'outline'"
                                [customTextColor]="demoLinkTextColor"
                                [csDecoratorClass]="'casino-qi-demo-link'"
                                [cta_text]="demoLinkText"
                                (click)="launchGame(game, true)"></cs-gametile-cta>
                        }
                        <!-- <button
                        (click)="launchGame(game, false)"
                        class="casino-qi-nplay-now btn qi-demo-btn"
                        [ngClass]="{ 'epcot-btn': enableEpcotSubNavigation }">
                        {{ cta | uppercase }}
                    </button> -->

                        <cs-gametile-cta
                            [isLegacy]="isLegacy"
                            [kind]="'primary'"
                            (click)="launchGame(game, false)"
                            [csDecoratorClass]="'casino-qi-nplay-now qi-demo-btn'"
                            [cta_text]="cta | uppercase">
                        </cs-gametile-cta>
                    </div>
                </div>
            </div>
        </div>
    }
</div>
