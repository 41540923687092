import { Injectable } from '@angular/core';

import { CasinoGlobalSearchClientConfig } from '../../client-config';
import { WindowRef } from '../windowref.service';

@Injectable({ providedIn: 'root' })
export class GlobalSearchBootstrapper {
    private isGlobalSearchBootstrapperLoadInitialized: boolean = false;

    constructor(
        private windowRef: WindowRef,
        private casinoGlobalSearchClientConfig: CasinoGlobalSearchClientConfig,
    ) {}

    bootstrap(): void {
        if (
            this.casinoGlobalSearchClientConfig &&
            this.casinoGlobalSearchClientConfig.isEnabled &&
            this.casinoGlobalSearchClientConfig.bootstrapOrigin === 'client' &&
            !this.isGlobalSearchBootstrapperLoadInitialized
        ) {
            this.isGlobalSearchBootstrapperLoadInitialized = true;

            this.windowRef.nativeWindow.setTimeout(() => {
                this.loadGlobalSearch();
            }, this.casinoGlobalSearchClientConfig.bootstrapTimeout);
        }
    }

    private loadGlobalSearch(): void {
        dispatchEvent(new CustomEvent('gs:search:bootstrap:app'));
    }
}
