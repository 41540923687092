import { Component, Input, OnInit } from '@angular/core';

import { ConfigProviderService } from '../../config-provider/config-provider.service';

// declare var angular: angular.IAngularStatic;

@Component({
    selector: 'cc-global-translate',
    template: '{{value}}',
    standalone: true,
})
export class GlobalTranslateComponent implements OnInit {
    @Input() key: string;
    value: string;
    global: any;

    constructor(private configProviderService: ConfigProviderService) {}

    ngOnInit() {
        this.configProviderService.casinoClientConfigObservable.subscribe((isClientConfigLoaded) => {
            if (isClientConfigLoaded) {
                this.global = this.configProviderService.provideGlobalConfig();
                if (this.global.messages[this.key]) {
                    this.value = this.global.messages[this.key];
                }
            }
        });
    }
}
