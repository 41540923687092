<div
    id="sticky-container"
    *ngIf="isCombinedCategories"
    class="sticky-container casino-lobby-width-gametile"
    [ngClass]="{ 'casino-ios-bottom-nav-bar': isCrossSaleIOS }"
    class="gametracking {{ trackingClass }}">
    <div
        [ngClass]="setSubCategoryStyle()"
        style.overflow-x="{{ isTouch == false ? 'hidden' : 'scroll' }}"
        [class.non-touch-scrollable-games]="!isTouch && setSubCategoryStyle() == 'scrollable-games'"
        (scroll)="scrollGYML()">
        <div *ngIf="isTouch && unfinishedRPANDGYMLGames && unfinishedRPANDGYMLGames.length > 0" [ngClass]="{ 'header-static-div': staticGYML }">
            <div class="base-category" [class.casino-base-category-nontouch]="!isTouch">
                <div class="casino-allGames">
                    <div class="gameContainers" *ngIf="recentCategory">
                        <h2
                            *ngIf="recentGamesCount > recentStartIndex"
                            class="casino-lobby-header sticky-header-rp-gyml"
                            lang="{{ lang }}"
                            [class.non-touch-casino-lobby-header]="!isTouch"
                            [ngClass]="{ 'casino-uk-header': isUkHeading }">
                            <span *ngIf="!isUkHeading">{{ recentTitle }}</span>
                            <cc-categoryicon-subtitle
                                *ngIf="isUkHeading"
                                [title]="recentTitle"
                                [subCategory]="recentCategory"
                                [recentAndGyml]="'RecentAndGyml'"
                                [alignIconRight]="alignIconRight"
                                [lobbyType]="lobbyType"></cc-categoryicon-subtitle>
                        </h2>
                        <div>
                            <div
                                *ngFor="let game of unfinishedRPANDGYMLGames; let i = index"
                                class="casino-rcntly-gyml-game-touch"
                                [hidden]="!game.isRecentlyPlayed">
                                <cc-game-lobby
                                    [ngClass]="game.infoIconClass"
                                    [game]="game"
                                    [gamePosition]="i"
                                    *ngIf="!game.isDataNotAvailable && recentCategory"
                                    [class.casino-recentlyPromo]="game.isRecentlyPlayed"
                                    [category]="recentCategory.categoryInfo"
                                    [gameLaunchedFrom]="recentCategory.categoryInfo.categoryid"></cc-game-lobby>
                            </div>
                        </div>
                    </div>

                    <div
                        class="gameContainers mrgn-lft-cls"
                        *ngIf="
                            recentCategory &&
                            gymlCategoryRef &&
                            recentCategory.categoryInfo &&
                            gymlCategoryRef.categoryInfo &&
                            recentCategory.categoryInfo.categoryid &&
                            gymlCategoryRef.categoryInfo.categoryid
                        ">
                        <h2
                            class="casino-lobby-header sticky-header-rp-gyml"
                            [ngClass]="{ 'subcategoryheader-static': staticGYML, 'casino-uk-header': isUkHeading }"
                            lang="{{ lang }}"
                            [ngStyle]="{ top: topValueForGYML }"
                            [class.non-touch-casino-lobby-header]="!isTouch"
                            *ngIf="
                                gymlCategoryRef &&
                                gymlCategoryRef.categoryInfo &&
                                gymlCategoryRef.categoryInfo.gamelist &&
                                gymlCategoryRef.categoryInfo.gamelist.length > 0
                            ">
                            <span *ngIf="!isUkHeading"
                                >{{ youMightLikeMessage }}
                                <cc-sign-posting *ngIf="isSignPosting" [category]="gymlCategoryRef.categoryInfo"></cc-sign-posting>
                            </span>
                            <cc-categoryicon-subtitle
                                *ngIf="isUkHeading"
                                [title]="youMightLikeMessage"
                                [subCategory]="gymlCategoryRef.categoryInfo"
                                [recentAndGyml]="'RecentAndGyml'"
                                [alignIconRight]="alignIconRight"
                                [lobbyType]="lobbyType"></cc-categoryicon-subtitle>
                        </h2>
                        <div>
                            <div
                                *ngFor="let game of unfinishedRPANDGYMLGames; let i = index"
                                class="casino-rcntly-gyml-game-touch"
                                [hidden]="game.isRecentlyPlayed">
                                <cc-game-lobby
                                    [ngClass]="game.infoIconClass"
                                    [class.casino-recentlyPromo]="game.isRecentlyPlayed"
                                    [game]="game"
                                    [gamePosition]="i"
                                    *ngIf="!game.isDataNotAvailable && gymlCategoryRef"
                                    [category]="gymlCategoryRef.categoryInfo"
                                    [gameLaunchedFrom]="gymlCategoryRef.categoryInfo.categoryid"></cc-game-lobby>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!isTouch && unfinishedRPANDGYMLGames && unfinishedRPANDGYMLGames.length > 0 && recentCategory">
            <div class="base-category" [class.casino-base-category-nontouch]="!isTouch">
                <h2
                    *ngIf="recentGamesCount > recentStartIndex"
                    class="casino-lobby-header non-touch-sticky-header-rp-gyml"
                    lang="{{ lang }}"
                    [class.non-touch-casino-lobby-header]="!isTouch"
                    [ngClass]="{ 'casino-uk-header': isUkHeading }">
                    <span *ngIf="!isUkHeading">{{ recentTitle }}</span>
                    <cc-categoryicon-subtitle
                        *ngIf="isUkHeading"
                        [title]="recentTitle"
                        [subCategory]="recentCategory"
                        [recentAndGyml]="'RecentAndGyml'"
                        [alignIconRight]="alignIconRight"
                        [lobbyType]="lobbyType"></cc-categoryicon-subtitle>
                </h2>
                <h2
                    *ngIf="
                        gymlCategoryRef &&
                        gymlCategoryRef.categoryInfo &&
                        gymlCategoryRef.categoryInfo.gamelist &&
                        gymlCategoryRef.categoryInfo.gamelist.length > 0 &&
                        recentGamesCount < recentStartIndex
                    "
                    class="casino-lobby-header non-touch-sticky-header-rp-gyml"
                    lang="{{ lang }}"
                    [class.non-touch-casino-lobby-header]="!isTouch"
                    [ngClass]="{ 'casino-uk-header': isUkHeading }">
                    <span *ngIf="!isUkHeading"
                        >{{ youMightLikeMessage }}
                        <cc-sign-posting *ngIf="isSignPosting" [category]="gymlCategoryRef.categoryInfo"></cc-sign-posting>
                    </span>
                    <cc-categoryicon-subtitle
                        *ngIf="isUkHeading"
                        [title]="youMightLikeMessage"
                        [subCategory]="gymlCategoryRef.categoryInfo"
                        [recentAndGyml]="'RecentAndGyml'"
                        [alignIconRight]="alignIconRight"
                        [lobbyType]="lobbyType"></cc-categoryicon-subtitle>
                </h2>
                <ng-container *ngIf="showFeatureGrid()">
                    <ngu-carousel
                        #recentGYMLCarousel
                        [inputs]="carouselConfig"
                        *ngIf="setSubCategoryStyle() == 'unscrollable-games'"
                        [dataSource]="unfinishedRPANDGYMLGames"
                        (onMove)="onmoveFn($event)">
                        <ngu-item *nguCarouselDef="let game; let i = index" class="game">
                            <h2
                                class="casino-lobby-header"
                                *ngIf="
                                    gymlCategoryRef &&
                                    gymlCategoryRef.categoryInfo &&
                                    gymlCategoryRef.categoryInfo.gamelist &&
                                    gymlCategoryRef.categoryInfo.gamelist.length > 0 &&
                                    i == recentGamesCount &&
                                    recentGamesCount >= recentStartIndex
                                "
                                lang="{{ lang }}"
                                [class.non-touch-casino-lobby-header]="!isTouch"
                                [ngClass]="{ 'casino-uk-header': isUkHeading, 'casino-uk-header-gymltext': isUkHeading }">
                                <span *ngIf="!isUkHeading"
                                    >{{ youMightLikeMessage }}
                                    <cc-sign-posting
                                        *ngIf="isSignPosting && !(recentGamesCount < recentStartIndex)"
                                        [category]="gymlCategoryRef.categoryInfo"></cc-sign-posting>
                                </span>
                                <cc-categoryicon-subtitle
                                    *ngIf="isUkHeading && !(recentGamesCount < recentStartIndex)"
                                    [title]="youMightLikeMessage"
                                    [subCategory]="gymlCategoryRef.categoryInfo"
                                    [recentAndGyml]="'RecentAndGyml'"
                                    [alignIconRight]="alignIconRight"
                                    [lobbyType]="lobbyType"></cc-categoryicon-subtitle>
                            </h2>
                            <div class="game">
                                <cc-game-lobby
                                    [ngClass]="game.infoIconClass"
                                    *ngIf="!game.isDataNotAvailable && game.isRecentlyPlayed"
                                    [game]="game"
                                    [gamePosition]="i"
                                    [class.casino-recentlyPromo]="game.isRecentlyPlayed"
                                    [category]="recentCategory.categoryInfo"
                                    [gameLaunchedFrom]="recentCategory.categoryInfo.categoryid">
                                </cc-game-lobby>
                                <cc-game-lobby
                                    [ngClass]="game.infoIconClass"
                                    [class.casino-recentlyPromo]="game.isRecentlyPlayed"
                                    *ngIf="!game.isDataNotAvailable && !game.isRecentlyPlayed"
                                    [game]="game"
                                    [gamePosition]="i"
                                    [category]="gymlCategoryRef.categoryInfo"
                                    [gameLaunchedFrom]="gymlCategoryRef.categoryInfo.categoryid"></cc-game-lobby>
                            </div>
                        </ngu-item>
                        <button
                            [ngClass]="{ 'hidden-cls': isPreButtonVisible }"
                            NguCarouselPrev
                            class="theme-left ngx-button-left"
                            aria-hidden="true"></button>
                        <button
                            [ngClass]="{ 'hidden-cls': isNextButtonVisible }"
                            NguCarouselNext
                            class="theme-right ngx-button-right"
                            aria-hidden="true"></button>
                    </ngu-carousel>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<!-- <h1 style="color:white"> {{subcategory|json}} --- {{Games.length}}</h1> -->
<div
    id="separate-container"
    *ngIf="!isCombinedCategories && subcategory && subcategory.categoryInfo && Games.length > 0"
    class="casino-lobby-games casino-game-tile-wrapper casino-lobby-width-gametile"
    [ngClass]="{ 'casino-ios-bottom-nav-bar': isCrossSaleIOS }"
    class="gametracking {{ trackingClass }}">
    <h2 class="game-tile-heading casino-lobby-header" [ngClass]="{ 'casino-uk-header': isUkHeading, 'epcot-heading': enableEpcotSubNavigation }">
        <span *ngIf="!isUkHeading"
            >{{ subcategory.categoryInfo.categoryname }}
            <cc-sign-posting *ngIf="isSignPosting" [category]="subcategory.categoryInfo"></cc-sign-posting>
        </span>
        <cc-categoryicon-subtitle
            *ngIf="isUkHeading"
            [title]="subcategory.categoryInfo.categoryname"
            [subCategory]="subcategory.categoryInfo"
            [recentAndGyml]="'NotRecentAndGyml'"
            [alignIconRight]="alignIconRight"
            [lobbyType]="lobbyType"></cc-categoryicon-subtitle>
    </h2>
    <div class="scrollable-games" [style.overflow-x]="!isTouch ? 'hidden' : 'scroll'" [class.non-touch-scrollable-games]="!isTouch">
        <ng-container *ngIf="showFeatureGrid()">
            <cc-feature-grid
                *ngIf="
                    subcategory.categoryInfo &&
                    subcategory.categoryInfo.gamelist &&
                    subcategory.categoryInfo.gamelist.length > 0 &&
                    subcategory.categoryInfo.sitecoreData
                "
                [games]="Games"
                [subcategory]="categoryObj"
                [isTouch]="isTouch"
                [Type]="'scrollable-games'"
                [gameLaunchedFrom]="subcategory.categoryInfo.categoryid"
                [lobbyType]="lobbyType"
                [newFeatureGridArr]="newFeatureGridArr"></cc-feature-grid>
        </ng-container>
    </div>
</div>
