import { Dimension, IContentImage, IContentLink, ISchedule, ImageType, ImmersiveLobbyConfig, LobbyGameInfo } from './common.model';

export const ITEM_TYPE_GAME_LOGO = 'GameLogo';
export const ITEM_TYPE_GAME = 'Game';
export const ITEM_TYPE_CUSTOM_LINK = 'CustomLink';
export const CUSTOM_LINK_TYPE_JACKPOT_DRAWER = 'JackpotDrawer';
export const GSHUB_ONBOARDING_COOKIE_NAME = 'hideGSHubOnboarding';

export interface IContentParameters {
    CustomLinkDrawerBgClr: string;
    CustomLinkDrawerHeaderClr: string;
    GlowingIconColor: string;
}

export interface IGameShowHubSectorItem extends LobbyGameInfo {
    width: number;
    height: number;
    top: number;
    left: number;
    itemType: string;
    customLinkIcon?: IContentImage;
    customLinkType?: string;
    customLink?: IContentLink;
    customLinkDrawerTitle?: string;
    customLinkDrawerBackgroundImage: IContentImage;
    customLinkDrawerContent?: Array<string>;
    glowingIconType: string;
    messages: any;
    configurationsContent: any;
    constantMessages: any;
    condition: string;
    // Custom Props
    dimension: Dimension;
    isSquareIcon: boolean;
    showTimer: boolean;
    isOffline: boolean;
    schedule: string | undefined;
    timerPosition: string | undefined;
    cmsTimer: any;
}

export interface IGameShowHubSector {
    lmtCategoryId: string;
    top: number;
    left: number;
    width: number;
    height: number;
    sectorImage: IContentImage;
    lottieImage: string;
    zoomedOutClipPath: string;
    zoomedInClipPath: string;
    zoomForDesktop: number;
    zoomForMobile: number;
    displayJackpotTicker: boolean;
    jackpotTickerText?: string;
    jackpotTickerImage?: IContentImage;
    gameLogos: Array<IGameShowHubSectorItem>;
    customLinks: Array<IGameShowHubSectorItem>;
    games: Array<IGameShowHubSectorItem>;
    //schedules: Map<string, Date>;
    schedules: Array<ISchedule>;
    condition: string;
    // Custom Props
    categoryId: string;
    categoryIndex: number;
    sectorContainerId: string;
    isLoaded: boolean;
    type: ImageType;
}

export interface IGameShowHubMap {
    width: number;
    height: number;
    backgroundImage: IContentImage;
    lottieImage?: string;
    layeredImage?: IContentImage;
    audioFile?: string;
    sectors: Array<IGameShowHubSector>;
    condition: string;
}

export interface IGameShowHubJackpotDrawerContent {
    jackpotGroupName: string;
    jackpotImage?: IContentImage;
    jackpotContent: string;
    displayJackpotTicker: boolean;
    howItWorks: string;
    howCanIParticipate: string;
    terms: string;
    condition: string;
    // Custom Props
    path: string;
}

export class GameShowHubConfiguration extends ImmersiveLobbyConfig {
    triviaGameType: string;
    noOfHours: number;
    tiltingPhoneMessageImageUrl: string;
    scheduleGames: Array<string>;
    jackpotGroupName: string;
    // enableSupportForLandscape: boolean;
    enableAudioButton: boolean;
}

export interface IGameShowHubGlobalConfig {
    noOfDays: string;
    offlineTableContent: string;
    tiltingMessage: string;
}

export interface IGameShowHubGlobalClrConfig {
    offlineTableTextClr: string;
    mapBgClr: string;
    glowingIconClr: string;
    jackpotTickeringBgClr: string;
    jackpotTickeringTextClr: string;
}
