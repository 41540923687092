export class LobbyItem {
    categoriesMap: Map<string, any>;
    categoryNameLangMap: Map<string, string>;
    stickerNameMap: Map<string, string>;
    gameMetaDataMap: Map<string, any>;
    catVsFeatureMetadata: Map<string, any>;
    lbyTempName: string;
    lbyTargetType: string;
    optSegName: string;
    lobbyGameIconImageSuffix: string;
    lsCategoriesMap: [string, any][];
    lsCategorytNameLangMap: [string, string][];
    lsStickerNameMap: [string, string][];
    lsGameMetaDataMap: [string, any][];
    lsCatVsFeatureMetadata: [string, any][];
    setCategoriesMap(map: Map<string, any>) {
        this.categoriesMap = map;
        this.lsCategoriesMap = Array.from(map.entries());
    }

    setcategoryNameLangMap(map: Map<string, string>) {
        this.categoryNameLangMap = map;
        this.lsCategorytNameLangMap = Array.from(map.entries());
    }

    setStickerNameMap(map: Map<string, string>) {
        this.stickerNameMap = map;
        this.lsStickerNameMap = Array.from(map.entries());
    }

    setGameMetaDataMap(map: Map<string, any>) {
        this.gameMetaDataMap = map;
        this.lsGameMetaDataMap = Array.from(map.entries());
    }
    setCatVsFeatureMetadata(map: Map<string, any>) {
        this.catVsFeatureMetadata = map;
        this.lsCatVsFeatureMetadata = Array.from(map.entries());
    }

    setLobbyItemFromLocalStorage(lobbyInfoLocalStorage: any) {
        if (lobbyInfoLocalStorage) {
            if (lobbyInfoLocalStorage.lsCategoriesMap && lobbyInfoLocalStorage.lsCategoriesMap.length > 0) {
                this.categoriesMap = new Map(lobbyInfoLocalStorage.lsCategoriesMap);
            }
            if (lobbyInfoLocalStorage.lsCategorytNameLangMap && lobbyInfoLocalStorage.lsCategorytNameLangMap.length > 0) {
                this.categoryNameLangMap = new Map(lobbyInfoLocalStorage.lsCategorytNameLangMap);
            }
            if (lobbyInfoLocalStorage.lsStickerNameMap && lobbyInfoLocalStorage.lsStickerNameMap.length > 0) {
                this.stickerNameMap = new Map(lobbyInfoLocalStorage.lsStickerNameMap);
            }
            if (lobbyInfoLocalStorage.lsGameMetaDataMap && lobbyInfoLocalStorage.lsGameMetaDataMap.length > 0) {
                this.gameMetaDataMap = new Map(lobbyInfoLocalStorage.lsGameMetaDataMap);
            }
            if (lobbyInfoLocalStorage.lbyTempName) {
                this.lbyTempName = lobbyInfoLocalStorage.lbyTempName;
            }
            if (lobbyInfoLocalStorage.lbyTargetType) {
                this.lbyTargetType = lobbyInfoLocalStorage.lbyTargetType;
            }
            if (lobbyInfoLocalStorage.optSegName) {
                this.optSegName = lobbyInfoLocalStorage.optSegName;
            }
            if (lobbyInfoLocalStorage.lsCatVsFeatureMetadata) {
                this.catVsFeatureMetadata = new Map(lobbyInfoLocalStorage.lsCatVsFeatureMetadata);
            }

            this.lobbyGameIconImageSuffix = lobbyInfoLocalStorage.lobbyGameIconImageSuffix;
        }
    }
}
