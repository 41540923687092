import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ConstService {
    public static readonly Instant_Casino: string = 'instantCasino';
    public static readonly Live_Casino: string = 'LiveCasino';
    public static readonly Recent_Games: string = 'RECENTGAMES';
    public static readonly Favourite_Games: string = 'FAVOURITEGAMES';
    public static readonly LMC_GYML = 'LMC_GYML';
    public static readonly ALL_GAMES_CATEGORY_ID: string = 'allgames';
    public static readonly LIVE_DEALER_GAMES_CATEGORY_ID: string = 'livecasino';
    public static readonly LIVE_CASINO_ALL_GAMES_CATEGORY_ID: string = 'livecasinoallgames';
    public static readonly LOBBY_LOADING_COMPLETED_EVENT: string = '?event=lobby&action=loadingcompleted';
    public static readonly FAVOURITE_GAMES_CATEGORY_ID: string = 'favouritegames';
    public static readonly RECENT_GAMES_CATEGORY_ID: string = 'recentgames';
    public static readonly SPORTS_HOME_ICON_EVENT: string = 'Highlights';
    public static readonly UNFINISHED_GAMES_CATEGORY_ID: string = 'unfinishedgames';
    public static readonly GYML_GAMES_CATEGORY_ID: string = 'gamesyoumightlike';
    public static readonly HOME_CATEGORY_ID = 'home';
    public static readonly LMC_GYML_CATEGORY_ID = 'LMC_GYML';
    public static readonly EGV_GYML = 'GYML';
    public static readonly DETAIL_PAGE_MODE_PICKER = 'Picker';
    public static readonly DETAIL_PAGE_MODE_FORCEPICKER = 'ForcePicker';
    public static readonly DETAIL_PAGE_MODE_FORCE = 'Force';
    public static readonly DETAIL_PAGE_MODE_ALL = 'All';
    public static readonly GAME_OF_THE_DAY = 'gameoftheday';
    public static readonly HIGHLIGHT_JACKPOTS = 'highlightjackpots';
    public static readonly LIVE_CASINO = 'livecasino';
    public static readonly PROMOTIONS_DISCOVER = 'promotions';
    public static readonly QUICK_LINKS = 'quicklinks';
    public static readonly EDITORS_PICK = 'editorspick';
    public static readonly SEARCH = 'Search';
    public static readonly SEARCH_GYML = 'Search_GYML';
    public static readonly ADV_GAME = 'advanceGameDataModel';
}
