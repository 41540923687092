import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { ConfigProviderService, DesignSystemEnablerService } from '@casinocore/platform/core';
import { DecoratorClassDirective, GameTileCtaComponent } from '@frontend/casino-ui';
import { DynamicHtmlDirective, TrackingService } from '@frontend/vanilla/core';

@Component({
    selector: 'cc-quick-info-game-description',
    templateUrl: 'quick-info-game-description.component.html',
    standalone: true,
    imports: [NgIf, DynamicHtmlDirective, GameTileCtaComponent, DecoratorClassDirective],
})
export class QuickInfoGameDescriptionComponent implements OnInit {
    @Input() gameDescription: string;
    @Input() gameTitle: string;
    @Input() volatilityInfo: string;
    @Input() volatilityTypeText: string;
    @Input() reels: string;
    @Input() lines: string;
    @Input() maxPayout: string;
    @Input() volatilityImgLink: string;
    @Input() isShowValuePart: boolean;
    @Input() hasVolatility: boolean;
    @Input() isOnlyMaxpayout: boolean;
    @Input() isOnlyReels: boolean;
    @Input() showVolatilityTable: boolean;
    gotItCta: any;
    howItWorksLink: any;
    maxPayoutText: any;
    reelsText: any;
    linesText: any;
    isShowVoltalityPart: boolean = false;
    highVolatilityText: string;
    highVolatilityImagelink: string;
    highVolatilityDescription: string;
    mildVolatilityDescription: string;
    mildVolatilityImagelink: string;
    mildVolatilityText: string;
    regVolatilityText: string;
    regVolatilityImagelink: string;
    regVolatilityDescription: string;
    tabName: string;
    tabPosition: number;
    isLegacy: boolean = false;

    constructor(
        private configProviderService: ConfigProviderService,
        private tracking: TrackingService,
        private designSystemEnablerService: DesignSystemEnablerService,
    ) {}

    ngOnInit(): void {
        const global = this.configProviderService.provideGlobalConfig();
        this.maxPayoutText = global.moreInfoConfiguration.MaxPayoutText;
        this.reelsText = global.moreInfoConfiguration.ReelsText;
        this.linesText = global.moreInfoConfiguration.LinesText;
        this.howItWorksLink = global.moreInfoConfiguration.HowItWorksLink;
        this.gotItCta = global.moreInfoConfiguration.GotItCta;
        this.highVolatilityText = global.moreInfoConfiguration.HighVolatilityText;
        this.highVolatilityImagelink = global.moreInfoConfiguration.HighVolatilityImagelink;
        this.highVolatilityDescription = global.moreInfoConfiguration.HighVolatilityDescription;
        this.mildVolatilityText = global.moreInfoConfiguration.MildVolatilityText;
        this.mildVolatilityDescription = global.moreInfoConfiguration.MildVolatilityDescription;
        this.mildVolatilityImagelink = global.moreInfoConfiguration.MildVolatilityImagelink;
        this.regVolatilityText = global.moreInfoConfiguration.RegVolatilityText;
        this.regVolatilityImagelink = global.moreInfoConfiguration.RegVolatilityImagelink;
        this.regVolatilityDescription = global.moreInfoConfiguration.RegVolatilityDescription;
        this.isLegacy = !this.designSystemEnablerService.isEnabled();
    }

    showVoltalityPart() {
        this.isShowValuePart = !this.isShowValuePart;
        this.isShowVoltalityPart = !this.isShowVoltalityPart;
        this.tabName = 'MoreInfo';
        this.tabPosition = 2;
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'games',
            'component.LabelEvent': 'game interactions',
            'component.ActionEvent': 'click',
            'component.PositionEvent': 'GameDescription' + '-' + this.volatilityTypeText,
            'component.LocationEvent': 'moreinfo overlay',
            'component.EventDetails': this.howItWorksLink,
            'component.URLClicked': 'not applicable',
        });
    }

    hideVoltalityPart() {
        this.isShowValuePart = !this.isShowValuePart;
        this.isShowVoltalityPart = !this.isShowVoltalityPart;
        (document.getElementById('tabcontent') as HTMLElement).scrollTo(0, 0);
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'games',
            'component.LabelEvent': 'game interactions',
            'component.ActionEvent': 'click',
            'component.PositionEvent': 'GameDescription' + '-' + this.volatilityTypeText,
            'component.LocationEvent': 'moreinfo overlay',
            'component.EventDetails': this.gotItCta,
            'component.URLClicked': 'not applicable',
        });
    }
}
