import { NgClass } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';

import { ArcadeLobbyTrackingService, ConfigProviderService } from '@casinocore/platform/core';

import { ArcadeGameLobbyComponent } from '../arcade-game-lobby/arcade-game-lobby.component';

@Component({
    selector: 'cc-recentlyplayed-game-overlay',
    templateUrl: 'recentlyplayed-game-overlay.component.html',
    standalone: true,
    imports: [MatGridList, MatGridTile, ArcadeGameLobbyComponent, NgClass],
})
export class RecentlyPlayedGameOverlayComponent implements OnInit {
    lobbyType: string;
    rpData: any;
    subcategory: any;
    gameLaunchedFrom: string = '';
    breakpoint: number = 4;
    arcadeLobbyConfigurations: any;
    recentGamesCount: string;
    constructor(
        private dialogRef: MatDialogRef<RecentlyPlayedGameOverlayComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private configProviderService: ConfigProviderService,
        private arcadeLobbyTrackingService: ArcadeLobbyTrackingService,
    ) {
        this.rpData = data.rpData;
        this.subcategory = data.subcategory;
        this.recentGamesCount = data.recentGamesCount;
        this.gameLaunchedFrom = data.gameLaunchedFrom;
    }

    @HostListener('window:orientationchange')
    @HostListener('window:resize')
    adjustBreakpoint() {
        if (window.innerWidth < 768 || document.body.offsetWidth < 768) {
            this.breakpoint = this.configProviderService.provideAracadeLobbyConfig()?.rpCountOverlay - 1;
        } else {
            this.breakpoint = this.configProviderService.provideAracadeLobbyConfig()?.rpCountOverlay;
        }
    }

    ngOnInit() {
        this.arcadeLobbyConfigurations = this.configProviderService.provideGlobalConfig()?.arcadeLobbyConfigurations;
        this.breakpoint = this.configProviderService.provideAracadeLobbyConfig()?.rpCountOverlay;
        this.adjustBreakpoint();
        this.recentlyPlayedOverlayTracking();
    }

    recentlyPlayedOverlayTracking() {
        this.arcadeLobbyTrackingService.trackEvent(
            'contentView',
            'coinArcade',
            'immersive lobby',
            'load',
            this.recentGamesCount,
            this.arcadeLobbyConfigurations?.RecentlyPlayedGames,
            this.arcadeLobbyConfigurations?.RecentlyPlayedGames + '-' + 'overlay',
            'not applicable',
        );
    }

    close(dialogResult: any = {}) {
        this.arcadeLobbyTrackingService.trackEvent(
            'Event.Tracking',
            'coinArcade',
            'immersive lobby',
            'close',
            this.recentGamesCount,
            this.arcadeLobbyConfigurations?.RecentlyPlayedGames,
            this.arcadeLobbyConfigurations?.RecentlyPlayedGames + '-' + 'overlay',
            'not applicable',
        );
        this.dialogRef.close(dialogResult);
    }
}
