import { Injectable } from '@angular/core';

import { OnAppInitHandler } from '@casinocore/platform';
import {
    CasinoCoreGamelaunchService,
    CasinoCoreNpmParams,
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    GameLaunch,
    OobeeIntimationToasterService,
} from '@casinocore/platform/core';
import { BingoloaderService } from '@frontend/bingoloader';
import {
    CookieService,
    NativeAppService,
    NavigationService,
    OnAppInit,
    ProductHomepagesConfig,
    UserLoginEvent,
    UserService,
} from '@frontend/vanilla/core';
import { SearchAppQueryEventIdentifier } from '@globalsearch/productstub';
import { rxEffects } from '@rx-angular/state/effects';
import { filter } from 'rxjs/operators';

import { FeaturesClientConfig, GlobalClientConfig, McPageClientConfig } from '../client-config';
import { GLobalSearchService } from '../globalsearch/globalsearchservice';
import { CasinoWidgetService } from '../shared';
import { GlobalSearchBootstrapper } from '../shared/services/globalsearch-bootstrap.service';
import { PerformanceMarker, PerformanceMarkerIdentifiers } from '../shared/services/performance-marker.service';
import { TagManagerRenderer } from '../shared/services/tagmanager-renderer.service';
import { WindowRef } from '../shared/windowref.service';

@Injectable()
export class CasinoBootstrapService implements OnAppInit {
    previousUrl = this.mcPage.prefix;
    invokerProduct: string;
    private readonly isAppEnabledQuery = 'isappenabled';
    private readonly queryToken = 'global-jackpot-query-isappenabled';
    private readonly rxEffects = rxEffects();

    constructor(
        private user: UserService,
        private casinoManager: CasinoManager,
        private navigation: NavigationService,
        private mcPage: McPageClientConfig,
        private global: GlobalClientConfig,
        private nativeApp: NativeAppService,
        private productHomepages: ProductHomepagesConfig,
        private casinoWidgetService: CasinoWidgetService,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private readonly oobeeIntimationToasterService: OobeeIntimationToasterService,
        private cookie: CookieService,
        private casinoLobbyService: CasinoLobbyService,
        private onAppInitHandler: OnAppInitHandler,
        private tagManagerRenderer: TagManagerRenderer,
        private globalSearchBootstrapper: GlobalSearchBootstrapper,
        private performanceMarker: PerformanceMarker,
        private globalSearchService: GLobalSearchService,
        private featuresConfig: FeaturesClientConfig,
        private windowRef: WindowRef,
        private configProviderService: ConfigProviderService,
        private bingoLoaderService: BingoloaderService,
    ) {}

    onAppInit() {
        if (this.featuresConfig.enableHashText) {
            const currUrl = this.navigation.location.absUrl();
            const hashTextExists = currUrl.includes(this.featuresConfig.hashText);

            if (!hashTextExists) {
                this.navigation.goTo(currUrl + '#' + this.featuresConfig?.hashText, { replace: true });
            }
        }
        this.registerEventHandlers();
        this.raiseIsAppEnabledQuery();
        this.triggerGTMInjection();
        this.casinoWidgetService.setWidgetProduct();
        this.invokerProduct = this.navigation.location.search.get('invokerproduct') || '';
        //disable overflow-x for entire application
        document.body.classList.add('casino-body');

        // Subscribe to Lobby Load
        //this.triggerGTMInjection();
        this.subscribeToLobbyLoad();
        const casinoCoreNpmParams: CasinoCoreNpmParams = new CasinoCoreNpmParams();
        casinoCoreNpmParams.isDll = true;
        casinoCoreNpmParams.disableFullLobbyFetch = true;
        this.globalSearchService.init();
        this.casinoLobbyService.initiateCasinoLobby(casinoCoreNpmParams);
        this.getInitFeed();
        document.getElementsByTagName('html')[0].classList.add('hide-bingo-tournaments');
        if (!this.getFromCookie('dark-mode')) {
            document.getElementsByTagName('html')[0].classList.remove('casino-darkmode');
            this.setBackgroundColor();
        } else {
            document.getElementsByTagName('html')[0].classList.add('casino-darkmode');
        }
        const bingoNPMModular: any = this.configProviderService.provideModularBingoClientConfig();
        if (bingoNPMModular?.isEnabled) {
            const environment = process.env.NODE_ENV;
            const params = {
                isDll: false,
                isEnabledonServerSide: true,
            };
            this.bingoLoaderService.loadBingoNpmModule(environment ? environment : 'production', params);
        }
        this.onAppInitHandler.execute();
    }

    getInitFeed() {
        const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        const nonCasinoUrlPaths = featuresConfig?.nonCasinoUrls ? JSON.parse(featuresConfig.nonCasinoUrls) : [];
        this.rxEffects.register(this.navigation.locationChange, (e) => {
            if (e && e.previousUrl != null) {
                this.previousUrl = e.previousUrl;
            }
            const nextUrl = e?.nextUrl;
            const values = nonCasinoUrlPaths?.map((m: any) => nextUrl.toLocaleLowerCase().indexOf(m.toLocaleLowerCase()) > -1);
            const isTrueValue = values.indexOf(true) > -1;
            if (e && e.nextUrl && isTrueValue) {
                const casinoLobbyBgColor: string = document.body.getAttribute('data-bg-color') || '';
                if (casinoLobbyBgColor === this.global.lobbyConfigurations.lobbyBackgroundColor) {
                    document.body.removeAttribute('data-bg-color');
                    document.body.style.removeProperty('background-color');
                }
            } else {
                if (!this.getFromCookie('dark-mode')) {
                    this.setBackgroundColor();
                }
            }
        });

        this.setUserPreferenceData();

        this.rxEffects.register(this.user.events.pipe(filter((e: any) => e instanceof UserLoginEvent)), () => {
            this.postLoginCalls();
        });

        this.rxEffects.register(this.nativeApp.eventsFromNative, (e) => {
            const eventName = e.eventName.toUpperCase();
            if (eventName === 'CASINO_SESSION_KEY' && e.parameters) {
                const eventParams: any = JSON.parse(JSON.parse(JSON.stringify(e.parameters)));
                if (eventParams.sessionKey && eventParams.sessionKey !== '') {
                    const clientUrl: string =
                        this.productHomepages.casino +
                        '/casino?invokerProduct=' +
                        this.nativeApp.product +
                        '&_nativeApp=' +
                        this.nativeApp.applicationName +
                        '&_sso=' +
                        eventParams.sessionKey;
                    window.location.href = clientUrl;
                }
            }
        });
    }

    postLoginCalls(): void {
        this.handlePostloginDirectGameLaunch();
        this.oobeeIntimationToasterService.display();
    }

    getFromCookie(key: string): boolean {
        if (this.cookie.get(key) > '0') {
            return true;
        }
        return false;
    }

    getGoBackUrl() {
        if (this.previousUrl === this.navigation.location.absUrl()) return this.mcPage.prefix;
        else return this.previousUrl;
    }

    goBackImmediateUrl() {
        return this.navigation.location.absUrl();
    }
    // setTeasers() {
    //     this.casinoManager.setTeasers();
    // }

    handlePostloginDirectGameLaunch() {
        const currentHref = window.location.href;
        if (currentHref.indexOf('launchEmbedded') > -1) {
            const slashLastIndex = currentHref.lastIndexOf('/');
            const queryLastIndex = currentHref.lastIndexOf('?');
            const gameId = currentHref.substring(slashLastIndex + 1, queryLastIndex - slashLastIndex - 1);

            gameId && this.gameLaunchService.handleDirectDesktopGameLaunch(gameId);
        }
    }

    setBackgroundColor() {
        document.body.style.backgroundColor = this.global.lobbyConfigurations.lobbyBackgroundColor;
        document.body.setAttribute('data-bg-color', this.global.lobbyConfigurations.lobbyBackgroundColor);
    }

    setUserPreferenceData() {
        this.casinoManager.setPlayerSegmentationData();
    }

    private raiseIsAppEnabledQuery() {
        dispatchEvent(
            new CustomEvent(SearchAppQueryEventIdentifier, {
                detail: {
                    query: this.isAppEnabledQuery,
                    queryToken: this.queryToken,
                },
            }),
        );
        dispatchEvent(new CustomEvent('gs:search:service:context:fetch'));
    }

    private registerEventHandlers() {
        addEventListener('gs:globalsearch:casinogamelaunch', this.launchGameGlobalSearch);
    }
    private readonly launchGameGlobalSearch = (customevent: CustomEventInit) => {
        if (customevent && customevent.detail && customevent.detail.gameParams) {
            const gameLink = customevent.detail.gameParams;
            this.globalSearchGameLaunch(gameLink);
        }
    };

    globalSearchGameLaunch(gameLink: any) {
        const gameId = gameLink.searchParams.get('gameid');
        const launchLobbyType = gameLink.searchParams.get('launchLobbyType');
        const isTouch = this.casinoManager.isTouch();
        const gameInfo = this.casinoLobbyService.getGameInfo(gameId);
        let provider;
        if (gameInfo) {
            provider = gameInfo.provider;
        }
        const lobbyType = this.casinoManager.getCurrentLobby();
        const gameObj: any = {
            gameId: gameId,
            provider: provider,
            lobbyType: launchLobbyType ? launchLobbyType : lobbyType,
        };
        const gameHomeUrl = gameLink.searchParams.get('returnUrl');
        const gameModel = new GameLaunch();
        gameModel.game = gameObj;
        gameModel.trackingproduct = gameLink.searchParams.get('specialproduct');
        gameModel.returnUrl = gameHomeUrl;
        gameModel.isTouch = isTouch;
        gameModel.categoryType = gameLink.searchParams.get('specialproduct');
        gameModel.clickTimeStamp = gameLink.searchParams.get('clickTimeStamp');
        gameModel.recommendationType = gameLink.searchParams.get('recommendationType');
        if (!gameModel.clickTimeStamp) {
            gameModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
        }
        let Globalsearchsection: any;
        if (gameLink.searchParams.get('tracking:containerName')) {
            Globalsearchsection = gameLink.searchParams.get('tracking:containerName').split('+').join(' ');
        }
        const Globalsearchgameposition = parseInt(gameLink.searchParams.get('tracking:position'));
        gameModel.Globalsearchsection = Globalsearchsection;
        gameModel.Globalsearchgameposition = Globalsearchgameposition;
        gameModel.gameLaunchedFrom = gameModel.trackingproduct;
        gameModel.gamePosition = Number(gameLink.searchParams.get('tracking:position'));
        this.gameLaunchService.launchGame(gameModel);
    }

    private subscribeToLobbyLoad(): void {
        this.rxEffects.register(this.casinoLobbyService.lobbyLoadedObservable, (result) => {
            if (result) {
                this.clearLocalStorage();
                this.createPerformanceMark(PerformanceMarkerIdentifiers.LobbyLoadComplete);
                setTimeout(() => {
                    if (document.getElementById('casino-prerender')) {
                        document.getElementById('casino-prerender')!.innerHTML = ' window.prerenderReady = true';
                        this.windowRef.nativeWindow.prerenderReady = true;
                    }
                }, 0);
                this.bootstrapGlobalSearch();
            }
        });
    }
    private clearLocalStorage(): void {
        const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        const expirationInterval = featuresConfig.browserLocalStorageCategoryDataUpdateInterval * 1000;
        const allLSLookupItems = Object.keys(localStorage).filter((item: any) => item.indexOf('categoryCoreLookUp') > -1);
        allLSLookupItems.forEach((item: string) => {
            this.removeExpiredkey(item, expirationInterval);
        });
    }

    private removeExpiredkey(key: string, expirationInterval: any): void {
        const cachedItem = localStorage.getItem(key.trim());
        const parsedCacheItem = cachedItem && JSON.parse(cachedItem);
        if (parsedCacheItem) {
            const cachedAt = parsedCacheItem.createdAt;
            if (cachedAt && new Date().getTime() - cachedAt > expirationInterval) {
                localStorage.removeItem(key);
            }
        }
    }
    private bootstrapGlobalSearch(): void {
        this.globalSearchBootstrapper.bootstrap();
    }

    private triggerGTMInjection(): void {
        this.tagManagerRenderer.renderGTMTag();
    }

    private createPerformanceMark(markerName: string): void {
        this.performanceMarker.mark(markerName);
    }
}
