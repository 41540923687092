import { Injectable } from '@angular/core';

import { ApiBase, ApiServiceFactory } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    useFactory: apiServiceFactory,
    deps: [ApiServiceFactory],
})
export class ProductApiService extends ApiBase {}

export function apiServiceFactory(apiServiceFactory: ApiServiceFactory) {
    return apiServiceFactory.create(ProductApiService, { product: 'casino', area: 'games', forwardProductApiRequestHeader: true });
}
