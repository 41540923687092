import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ConfigProviderService, GlobalTranslateComponent } from '@casinocore/platform/core';
import { NavigationService, TrackingService } from '@frontend/vanilla/core';

import { LibMockService } from '../services/lib-mock.service';

@Component({
    selector: 'cc-overlay-header',
    templateUrl: 'overlay-header.component.html',
    standalone: true,
    imports: [GlobalTranslateComponent, NgIf],
})
export class OverlayHeaderComponent implements OnInit {
    @Input() hideCloseButton: any;
    overlayBgcolor: string;
    constructor(
        private trackingService: TrackingService,
        private configProviderService: ConfigProviderService,
        private navigation: NavigationService,
        private router: Router,
        private libMockService: LibMockService,
    ) {}

    ngOnInit(): void {
        const global: any = this.configProviderService.provideGlobalConfig();
        this.overlayBgcolor = global.messages['OverlayHeaderBgColor'];
    }

    closeSportsOverlay(): void {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'Sports Gaming Overlay',
            'component.LabelEvent': 'Gaming Overlay on Sports Lobby',
            'component.ActionEvent': 'Gaming Overlay - Closed',
            'component.PositionEvent': 'not applicable',
            'component.LocationEvent': 'Sports Lobby',
            'component.EventDetails': 'not applicable',
            'component.URLClicked': 'not applicable',
        });
        if (this.navigation.location.search.get('openCasinoCoreOverlay') === 'true') {
            this.router.navigate(['.']);
        }
        const dialogRef = this.libMockService.getDialogRef();
        this.libMockService.closeDialogRef(dialogRef);
    }
    logoClicked(): void {
        const mcPageConfig = this.configProviderService.provideMcPageConfig();
        if (mcPageConfig && mcPageConfig.gamingOverlayLogoUrl && mcPageConfig.gamingOverlayLogoUrl.url) {
            this.navigation.goTo(mcPageConfig?.gamingOverlayLogoUrl?.url);
        }
    }
}
