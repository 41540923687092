import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { CasinoLobbyService, ConfigProviderService, LobbyItem, OptimizedTeaserManager, TeaserService } from '@casinocore/platform/core';
import { Subscription } from 'rxjs';

import { TeasersWrapperComponent } from '../casino-teasers-wrapper/teasers-wrapper.component';

@Component({
    selector: 'cc-teaser-on-lobby-type',
    templateUrl: 'teaser-on-lobby-type.component.html',
    standalone: true,
    imports: [NgIf, TeasersWrapperComponent],
})
export class TeaserOnLobbyTypeComponent implements OnInit, OnDestroy, OnChanges {
    @Input() lobbyType: string;
    @Input() categoryRoute: string;
    @Input() lobbyProduct: string;
    isLobbyLoaded: boolean;
    lobbyLoadSubscription: Subscription;
    TopTeaser: string;
    teaserCategoryVal: string;
    isDefaultCategory: boolean = true;
    headerTeasers: any[] = [];
    optimizedHeaderTeasers: any[] = [];
    teaserObs: Subscription;
    teasersReady: boolean;

    constructor(
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
        private optimizedTeaserManager: OptimizedTeaserManager,
        private teaserService: TeaserService,
    ) {}

    ngOnInit() {
        this.lobbyLoadSubscription = this.casinoLobbyService.lobbyLoadedObservable.subscribe((response) => {
            if (response) {
                this.isLobbyLoaded = true;
                if (this.lobbyType && (!this.categoryRoute || this.categoryRoute === '')) {
                    this.setCategoryRoute();
                }
                this.teaserObs = this.teaserService.teaserDataObservable.subscribe((_response: any) => {
                    if (_response) {
                        this.bindTeasers();
                    }
                    this.TopTeaser = this.isDefaultCategory ? 'Top' : 'SubNavigation';
                });
            }
        });
    }

    ngOnChanges() {
        if (this.isLobbyLoaded) {
            if (this.lobbyType && (!this.categoryRoute || this.categoryRoute === '')) {
                this.setCategoryRoute();
            }
            this.bindTeasers();
        }
    }

    setCategoryRoute() {
        const lobbyInfo: LobbyItem | null = this.casinoLobbyService.getLobbyInfo(this.lobbyType);
        if (lobbyInfo?.categoriesMap && lobbyInfo.categoriesMap.size > 0) {
            this.categoryRoute = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(this.lobbyType).categoryInfo.categoryid; // lobbyInfo.categoriesMap.entries().next().value[1].categoryid;
        } else {
            //emit CategoryNotFound
        }
    }
    bindTeasers() {
        const catInfo = this.casinoLobbyService.getCategoryInfo(this.lobbyType, this.categoryRoute);
        if (catInfo && catInfo.categoryInfo) {
            const currentCategory = catInfo.categoryInfo;
            this.teaserCategoryVal = currentCategory.categoryid;
            this.isDefaultCategory = this.casinoLobbyService.isDefaultCategory(this.lobbyType, this.categoryRoute);
            const optTeaserConfig = this.configProviderService.provideOptimizedTeaserConfig();
            this.teasersReady = false;
            if (optTeaserConfig.teaserOptimization) {
                this.bindOptimizedTeaserForCategory(currentCategory);
            } else {
                this.bindNormalTeasersForCategory(currentCategory);
            }
        }
    }

    bindOptimizedTeaserForCategory(category: any) {
        const isDefault: boolean = this.casinoLobbyService.isDefaultCategory(this.lobbyType, this.categoryRoute);
        const displayMode: string = isDefault ? 'Top' : 'SubNavigation';
        const optTeaserResponse: any = this.optimizedTeaserManager.setOptimizedTeasers(category, displayMode);
        if (optTeaserResponse) {
            this.optimizedHeaderTeasers = optTeaserResponse;
        }
        this.teasersReady = true;
    }

    bindNormalTeasersForCategory(category: any) {
        this.headerTeasers = [];
        this.isDefaultCategory = this.casinoLobbyService.isDefaultCategory(this.lobbyType, this.categoryRoute);
        this.TopTeaser = this.isDefaultCategory ? 'Top' : 'SubNavigation';
        const headerTeasers = this.teaserService.headerTeasers();
        if (
            category &&
            category.sitecoreData.showTeasers &&
            category.sitecoreData.teasers &&
            category.sitecoreData.teasers.length > 0 &&
            headerTeasers &&
            headerTeasers.length > 0
        ) {
            headerTeasers.forEach((headerTeaser: any) => {
                const index = category.sitecoreData.teasers.indexOf(
                    category.sitecoreData.teasers.filter((categoryTeaser: any) => categoryTeaser.itemName === headerTeaser.teaserId)[0],
                );
                if (index > -1 && !headerTeaser.isEmbeddedTeaser) {
                    this.headerTeasers.push(headerTeaser);
                }
            });
        }
        this.teasersReady = true;
    }

    ngOnDestroy() {
        if (this.lobbyLoadSubscription) {
            this.lobbyLoadSubscription.unsubscribe();
        }
        if (this.teaserObs) {
            this.teaserObs.unsubscribe();
        }
    }
}
