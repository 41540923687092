import { Injectable } from '@angular/core';

import { Logger } from '@frontend/vanilla/core';

import { ConfigProviderService } from '../../config-provider/config-provider.service';
import { CasinoManager } from '../../shared/casino-manager.service';
import { CoordinatorStates, IErrorData } from '../coordinator.models';
import { GeolocationCoordinatorService } from './coordinator.service';
import { GeoLocationValidationService } from './geolocation-validation.service';
import { GeolocationInstallerDialogService } from './installer-dialog.service';

@Injectable({
    providedIn: 'root',
})
export class GeolocationCheckService {
    onCheckClosed: () => void;
    isOnErrorInvoked: boolean = false;
    errorData: IErrorData;
    geolocationConfig: any;
    constructor(
        private geolocationCoordinatorService: GeolocationCoordinatorService,
        private geolocationInstallerService: GeolocationInstallerDialogService,
        private geoLocationValidation: GeoLocationValidationService,
        private readonly casinoManager: CasinoManager,
        private configProviderService: ConfigProviderService,
        private logger: Logger,
    ) {
        this.geolocationConfig = this.configProviderService.provideGeoLocationConfig();
        this.geolocationInstallerService.onDialogClosed = this.onInstallerServiceDialogClosed.bind(this);
    }

    checkGeolocation(callback: any, errorCallback: any): void {
        this.isOnErrorInvoked = false;
        this.geolocationCoordinatorService.isSynchronizedUninit = false;
        this.geolocationCoordinatorService.onInitialized = (initializationStatus: CoordinatorStates) => {
            this.isOnErrorInvoked = false;
            this.logger.info('[GeolocationCheckService]', 'GeolocationCoordinatorService::', 'onInitialized::', initializationStatus);
            if (initializationStatus === CoordinatorStates.INITIALIZED) this.closeDialogAndLaunchGame(callback);
        };

        this.geolocationCoordinatorService.onError = (errorData: IErrorData) => {
            this.isOnErrorInvoked = true;
            this.errorData = errorData;
            this.logger.error('[GeolocationCheckService]', 'GeolocationCoordinatorService::', 'onError::', errorData);
            if (errorData && errorData.errorCode == -1111) {
                const appDownloadUrl: any = 'appDownloadUrl';
                this.showGeolocationInstallerPopup(
                    (errorData && errorData.additionalInfo && errorData.additionalInfo[appDownloadUrl]) ||
                        this.geolocationCoordinatorService.appDownloadUrl,
                    this.isMobileInstaller(),
                );
                errorCallback();
                return;
            } else {
                this.geolocationConfig = this.configProviderService.provideGeoLocationConfig();
                if (this.geolocationConfig?.desktopLocationValidation) {
                    this.geoLocationValidation?.validateLocation((response: boolean) => {
                        if (response != null && !response) {
                            callback();
                        } else {
                            errorCallback();
                        }
                    });
                } else this.closeDialogAndLaunchGame(callback);
                return;
            }
        };

        this.geolocationCoordinatorService.onUninitialized = () => {
            if (this.isOnErrorInvoked) this.isOnErrorInvoked = false;
            else if (!this.geolocationCoordinatorService.isSynchronizedUninit) {
                this.geolocationConfig = this.configProviderService.provideGeoLocationConfig();
                if (this.geolocationConfig?.desktopLocationValidation) {
                    this.geoLocationValidation.validateLocation((response: boolean) => {
                        if (response != null && !response) callback();
                        else errorCallback();
                    });
                } else this.closeDialogAndLaunchGame(callback);
            }
            this.logger.info('[GeolocationCheckService]', 'GeolocationCoordinatorService::', 'onUninitialized::');
        };

        this.geolocationCoordinatorService.initialize({
            showPLCPopup: false,
        });
    }

    closeDialogAndLaunchGame(callback: any): void {
        this.geolocationInstallerService.closeDialog();
        callback();
    }

    private showGeolocationInstallerPopup(downloadUrl: string, isMobileInstaller: boolean): void {
        this.geolocationInstallerService.hideLoader();
        if (!this.geolocationInstallerService.isDialogOpen) this.geolocationInstallerService.openDialog(downloadUrl, isMobileInstaller);
    }

    private isMobileInstaller(): boolean {
        return this.casinoManager.isTouch();
    }

    private onInstallerServiceDialogClosed(): void {
        if (this.onCheckClosed) {
            this.onCheckClosed();
        }
    }
}
