@if (category) {
    <div class="casino-game-tile-wrapper gametracking {{ trackingClass }}">
        @if (isMobileWidth() && enableFixedMobileNavigation && displayCatHeader) {
            <h2
                class="game-tile-heading casino-lobby-header displayCategoryHeader-{{ displayCatHeader }}"
                [style.color]="subCategoryTextColor"
                [style.font-family]="lobbyTileFontFamily">
                {{ categoryInfo.categoryname }}
            </h2>
        }
        @if ((showTeasers || isShowEmbeddedAbove) && ((!isEnableOptimizedTeasers && showTeasersAboveGames) || isEnableOptimizedTeasers)) {
            <div class="casino-lmt-sub-category">
                <cc-teasers-wrapper
                    [teaserLocation]="EmbeddedTeaser"
                    [teaserCategory]="teaserCategoryVal"
                    [teasersSource]="headerTeasers"
                    [optTeasersSource]="optimizedEmbeddedAboveTeasers"
                    [identifierClass]="categoryId + '_EmbeddedAbove'"
                    [lobbyType]="lobbyType"
                    [gameContainer]="gameContainer" />
            </div>
        }

        <div
            class="casino-bgColorWidthfull"
            [ngClass]="{
                'casino-backgroungImage-layer': parallaxInput && parallaxInput.sitecoreData.backgroundImageLayer1,
                'casino-backgroungImage-parallax':
                    (parallaxInput && parallaxInput.sitecoreData.backgroundImageLayer2) ||
                    (parallaxInput && parallaxInput.sitecoreData.backgroundImageLayer3),
                'categoryBgFull': categoryBackgroundFull
            }"
            style.overflow="{{ !isTouch ? 'hidden' : 'unset' }}">
            @if (isJackpotdataForParallax && (enableSingleJackpot || enableMultiJackpot || enableMustGoJackpot)) {
                <div>
                    @if (
                        parallaxInput &&
                        (parallaxInput.sitecoreData.backgroundImageLayer1 ||
                            parallaxInput.sitecoreData.backgroundImageLayer2 ||
                            parallaxInput.sitecoreData.backgroundImageLayer3)
                    ) {
                        <cc-casino-core-lazy-placeholder [inputData]="{ subCategory: parallaxInput }" [componentName]="'ParallaxAnimationComponent'">
                        </cc-casino-core-lazy-placeholder>
                    }
                </div>
            }

            @if (isJackpotdataForParallax && (!enableSingleJackpot || !enableMultiJackpot || !enableMustGoJackpot)) {
                <div>
                    @if (
                        categoryInfo &&
                        categoryInfo.sitecoreData &&
                        (categoryInfo.sitecoreData.jackpotWidget?.length > 1 ||
                            categoryInfo.sitecoreData.jackpotWidget?.length == 1 ||
                            categoryInfo.sitecoreData.showMustGo) &&
                        parallaxInput &&
                        (parallaxInput.sitecoreData.backgroundImageLayer1 ||
                            parallaxInput.sitecoreData.backgroundImageLayer2 ||
                            parallaxInput.sitecoreData.backgroundImageLayer3)
                    ) {
                        <cc-casino-core-lazy-placeholder [inputData]="{ subCategory: parallaxInput }" [componentName]="'ParallaxAnimationComponent'">
                        </cc-casino-core-lazy-placeholder>
                    }
                </div>
            }
            @if (categoryInfo?.sitecoreData?.showJackpotWidget) {
                <div class="casino-game-tile-wrapper casino-lobby-width-gametile" [class.seeall-configs]="changeCarouselView">
                    @if (categoryInfo.sitecoreData?.jackpotWidget?.length == 1) {
                        <div>
                            @if (!enableSingleJackpot) {
                                <cc-single-jackpot-widget
                                    id="SingleJackpotCategoryIdentifier_{{ categoryInfo.categoryid }}"
                                    [category]="categoryInfo"
                                    [isTouch]="isTouch"
                                    [lobbyType]="lobbyType"
                                    [changeCarouselView]="changeCarouselView"
                                    [gameContainer]="gameContainer"
                                    (isjackpotFeed)="getJackpotFeed($event)">
                                </cc-single-jackpot-widget>
                            }

                            @if (enableSingleJackpot) {
                                <cc-single-jackpot-redesign-widget
                                    id="SingleJackpotCategoryIdentifier_{{ categoryInfo.categoryid }}"
                                    [category]="categoryInfo"
                                    [isTouch]="isTouch"
                                    [lobbyType]="lobbyType"
                                    [changeCarouselView]="changeCarouselView"
                                    [gameContainer]="gameContainer"
                                    (isjackpotFeed)="getJackpotFeed($event)">
                                </cc-single-jackpot-redesign-widget>
                            }
                        </div>
                    }

                    @if (categoryInfo.sitecoreData?.jackpotWidget?.length > 1) {
                        <div>
                            @if (!enableMultiJackpot) {
                                <cc-multi-jackpot-widget
                                    id="MultiJackpotCategoryIdentifier_{{ categoryInfo.categoryid }}"
                                    [category]="categoryInfo"
                                    [isTouch]="isTouch"
                                    [changeCarouselView]="changeCarouselView"
                                    [lobbyType]="lobbyType"
                                    [gameContainer]="gameContainer"
                                    (isjackpotFeed)="getJackpotFeed($event)">
                                </cc-multi-jackpot-widget>
                            }

                            @if (enableMultiJackpot) {
                                <cc-multi-jackpot-widget-rebrand
                                    id="MultiJackpotCategoryIdentifier_{{ categoryInfo.categoryid }}"
                                    [category]="categoryInfo"
                                    [isTouch]="isTouch"
                                    [changeCarouselView]="changeCarouselView"
                                    [lobbyType]="lobbyType"
                                    [gameContainer]="gameContainer"
                                    (isjackpotFeed)="getJackpotFeed($event)">
                                </cc-multi-jackpot-widget-rebrand>
                            }
                        </div>
                    }
                </div>
            }

            @if (categoryInfo.sitecoreData && categoryInfo.sitecoreData.showMustGo) {
                <div class="casino-game-tile-wrapper casino-lobby-width-gametile" [class.seeall-configs]="changeCarouselView">
                    @if (!enableMustGoJackpot) {
                        <cc-must-go
                            id="MustGoCategoryIdentifier_{{ categoryInfo.categoryid }}"
                            [category]="categoryInfo"
                            [isTouch]="isTouch"
                            [lobbyType]="lobbyType"
                            [changeCarouselView]="changeCarouselView"
                            [gameContainer]="gameContainer"
                            (isjackpotFeed)="getJackpotFeed($event)">
                        </cc-must-go>
                    }

                    @if (enableMustGoJackpot) {
                        <cc-must-go-redesign-new
                            id="MustGoCategoryIdentifier_{{ categoryInfo.categoryid }}"
                            [category]="categoryInfo"
                            [isTouch]="isTouch"
                            [lobbyType]="lobbyType"
                            [changeCarouselView]="changeCarouselView"
                            [gameContainer]="gameContainer"
                            (isjackpotFeed)="getJackpotFeed($event)">
                        </cc-must-go-redesign-new>
                    }
                </div>
            }
        </div>

        @if (displayPromoEDSWidget && promoEDSWidgetPosition === 'BeforeGames') {
            <div class="casino-promo-eds-widget">
                <cc-casino-core-lazy-placeholder
                    [inputData]="{ position: promoEDSWidgetPosition, category: categoryId }"
                    [componentName]="'PromoEdsWidgetComponent'">
                </cc-casino-core-lazy-placeholder>
            </div>
        }

        @if (isEnabledGameSelector && gameSelectorWidgetPosition && gameSelectorWidgetPosition === 'BeforeGames') {
            <div class="casino-game-selector">
                <cc-casino-core-lazy-placeholder
                    [inputData]="{ subCategory: category?.categoryInfo, widgetLocation: 'Embedded' }"
                    [componentName]="'CasinoGameSelectorComponent'">
                </cc-casino-core-lazy-placeholder>
            </div>
        }

        <!-- recent winners -->
        @if (isEnabledRecentWinners) {
            <div class="casino-recentWinners">
                <cc-recent-winners [category]="categoryInfo"></cc-recent-winners>
            </div>
        }

        <!-- prominent free spins section -->
        @if (isDisplayProminentFreeSpinsWidget && enableProminentFreespinsDisplay) {
            <div class="casino-prominent-freespins">
                <cc-prominent-freespins-widget [category]="categoryInfo"></cc-prominent-freespins-widget>
            </div>
        }

        <!--player stats-->
        @if (enablePlayerstats && (!playerStatsWidgetPosition || playerStatsWidgetPosition === 'BeforeGames')) {
            <div class="casino-playerstats">
                <cc-player-stat-filters [category]="categoryInfo"></cc-player-stat-filters>
            </div>
        }

        @if (isSuperButtonEnabled && superButtonPosition && superButtonPosition === 'BeforeGames') {
            <div class="casino-super-button">
                <cc-casino-core-lazy-placeholder [inputData]="{ category: category?.categoryInfo }" [componentName]="'SuperButtonComponent'">
                </cc-casino-core-lazy-placeholder>
            </div>
        }

        @if (Games.length > 0) {
            <div
                class="overflow-sub casino-lobby-width-gametile"
                [ngClass]="categoryStyle"
                style.overflow-x="{{ !isTouch ? 'hidden' : 'scroll' }}"
                [class.non-touch-scrollable-games]="!isTouch && categoryStyle == 'scrollable-games'">
                @if (Games && Games.length > 0 && arcadeLobbyGameConfig != currentLobbyType) {
                    <cc-feature-grid
                        [games]="Games"
                        [subcategory]="categoryInfo"
                        [isTouch]="isTouch"
                        [Type]="categoryStyle"
                        (loadNextGames)="loadNextGamesSet()"
                        [gameLaunchedFrom]="categoryId"
                        [lobbyType]="lobbyType"
                        [newFeatureGridArr]="newFeatureGridArr">
                    </cc-feature-grid>
                }

                @if (Games && Games.length > 0 && arcadeLobbyGameConfig == currentLobbyType) {
                    <cc-arcade-feature-grid
                        [games]="Games"
                        [subcategory]="categoryInfo"
                        [isTouch]="isTouch"
                        [Type]="categoryStyle"
                        (loadNextGames)="loadNextGamesSet()"
                        [gameLaunchedFrom]="categoryId"
                        [lobbyType]="lobbyType"
                        [newFeatureGridArr]="newFeatureGridArr">
                    </cc-arcade-feature-grid>
                }
            </div>
        }

        @if (isSuperButtonEnabled && superButtonPosition && superButtonPosition === 'AfterGames') {
            <div class="casino-super-button">
                <cc-casino-core-lazy-placeholder [inputData]="{ category: category?.categoryInfo }" [componentName]="'SuperButtonComponent'">
                </cc-casino-core-lazy-placeholder>
            </div>
        }

        @if (isEnabledGameSelector && gameSelectorWidgetPosition && gameSelectorWidgetPosition === 'AfterGames') {
            <div class="casino-game-selector">
                <cc-casino-core-lazy-placeholder
                    [inputData]="{ subCategory: category?.categoryInfo, widgetLocation: 'Embedded' }"
                    [componentName]="'CasinoGameSelectorComponent'">
                </cc-casino-core-lazy-placeholder>
            </div>
        }

        @if (displayPromoEDSWidget && promoEDSWidgetPosition === 'AfterGames') {
            <div class="casino-promo-eds-widget">
                <cc-casino-core-lazy-placeholder
                    [inputData]="{ position: promoEDSWidgetPosition, category: categoryId }"
                    [componentName]="'PromoEdsWidgetComponent'">
                </cc-casino-core-lazy-placeholder>
            </div>
        }

        @if (enablePlayerstats && (!playerStatsWidgetPosition || playerStatsWidgetPosition === 'AfterGames')) {
            <div class="casino-playerstats">
                <cc-player-stat-filters [category]="categoryInfo"></cc-player-stat-filters>
            </div>
        }

        @if ((showTeasers || isShowEmbeddedBelow) && !showTeasersAboveGames) {
            <div class="casino-lmt-sub-category">
                <cc-teasers-wrapper
                    [teaserLocation]="EmbeddedTeaser"
                    [teaserCategory]="teaserCategoryVal"
                    [teasersSource]="headerTeasers"
                    [optTeasersSource]="optimizedEmbeddedBelowTeasers"
                    [identifierClass]="categoryId + '_EmbeddedBelow'"
                    [lobbyType]="lobbyType"
                    [gameContainer]="gameContainer" />
            </div>
        }

        @if (
            categoryInfo &&
            categoryInfo.sitecoreData &&
            videoUrl &&
            (categoryInfo.sitecoreData.videoType || (categoryInfo.sitecoreData.casinoVideo && categoryInfo.sitecoreData.casinoVideo.videoType))
        ) {
            <div>
                <cc-casino-core-lazy-placeholder
                    [inputData]="{
                        subcategory: categoryInfo,
                        type: videoType,
                        url: videoUrl,
                        launchUrl: launchUrl,
                        imageThumbnail: imageThumbnail,
                        videoName: videoName,
                        lobbyType: lobbyType,
                        videoBoxContent: videoBoxContent,
                        gameContainer: gameContainer,
                        displayVideoinLoop: displayVideoinLoop
                    }"
                    [componentName]="'CasinoGameVideosComponent'">
                </cc-casino-core-lazy-placeholder>
                @if (enablePlayerstats && playerStatsWidgetPosition && playerStatsWidgetPosition === 'AfterGames') {
                    <div class="casino-playerstats">
                        <cc-player-stat-filters [category]="categoryInfo"></cc-player-stat-filters>
                    </div>
                }
            </div>
        }
    </div>
}
