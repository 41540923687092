import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import {
    AppInfoConfig,
    ClaimsService,
    CookieService,
    GoToOptions,
    LoadingIndicatorService,
    Logger,
    Message,
    MessageLifetime,
    MessageQueueClearOptions,
    MessageQueueService,
    MessageType,
    NativeAppService,
    NavigationService,
    Page,
    ProductHomepagesConfig,
    UserService,
} from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { CasinoLobbyService } from '../casino-lobby-manager/casino-lobby.service';
import { ConfigProviderService } from '../config-provider/config-provider.service';
import { EmbeddedGamePopupComponent } from '../embedded-game-popup/embedded-game-popup.component';
import { GameDetailComponent } from '../game-detail/game-detail.component';
import { GeolocationActivatorService } from '../geolocation/services/activator.service';
import { GeolocationCheckService } from '../geolocation/services/geolocation-check.service';
import { GeoLocationValidationService } from '../geolocation/services/geolocation-validation.service';
import { PlatformApiService } from '../platform-api/platform-api.service';
import { SlotRacesService } from '../slot-races/shared/slot-races.service';
import { UrlUtilityService } from '../utilities/url-utility.service';
import { AdvanceGameDataService } from './advance-game-data.service';
import { ApiPathsHelperService } from './api-paths-helper-service';
import { CacheManagerService } from './cache-manager.service';
import { CasinoCoreNpmParamsService } from './casino-core-npm-params.service';
import { CasinoIFrameOverlayService } from './casino-iframe-overlay.service';
import { CasinoManager } from './casino-manager.service';
import { CasinoWidgetService } from './casino-widget.service';
import { ConstService } from './const.service';
import { FavouriteService } from './favourite.service';
import { GameLaunchPlaces } from './game-launch-places.service';
import { GameLaunchTrackingService } from './game-launch-tracking.service';
import { GameLaunchUrlFormationService } from './gamelaunch-url-formation.service';
import { GlobalSearchHelperService } from './globalsearch-helper.service';
import { InvokerProductService } from './invoker-product.service';
import { LiveDealerService } from './live-dealer.service';
import { GameLaunch } from './models/gamelaunch.model';
import { MonitorService } from './monitor.service';
import { NativeApiService } from './nativeapi.service';
import { NativeAppHelperService } from './nativeapphelper.service';
import { NavigatorService } from './navigator.service';
import { OpenLoginDialog } from './open-login-dialog-v6';
import { SearchSectionService } from './search-section.service';
import { UnfinishedGamesService } from './unfinished-games-service';
import { VCClientService } from './vc-client.service';

@Injectable({
    providedIn: 'root',
})
export class CasinoCoreGamelaunchService {
    OPEN_URL: string = 'OPEN_URL';
    navItems: any;
    isTouch: boolean;
    gameObj: any;
    gameModel: GameLaunch;

    customRecommendationType: string | undefined;
    private _gameLaunchInProgress: boolean;
    private updateFreespinsSubject = new BehaviorSubject<boolean>(false);
    updateFreespinsObs: Observable<boolean> = this.updateFreespinsSubject.asObservable();
    private gameDetailPageSubject = new BehaviorSubject<any>(null);
    gameDetailPageObservable: Observable<any> = this.gameDetailPageSubject.asObservable();
    private stopAudioSubject = new BehaviorSubject<boolean | null>(null);
    stopAudioObservable: Observable<boolean | null> = this.stopAudioSubject.asObservable();
    messageQueuePublisher = new BehaviorSubject<any>(null);
    messageQueueObservable: Observable<boolean> = this.messageQueuePublisher.asObservable();
    validationService: any;
    gameNotAvailableTimeOutData: any;
    delayAfterRefreshInterval: any;
    millisecondsToDisplayInterval: any;
    showDesktopSearch: boolean = true;
    showGSInDesktopSearch: boolean = true;
    loadingIndicatorHandler: any = null;
    navItemIndex: number = 0;
    isSingleMessage: boolean = true;

    generateGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    constructor(
        private configProviderService: ConfigProviderService,
        private claimsConfig: ClaimsService,
        private m2NativeApp: NativeAppService,
        private user: UserService,
        private messageQueue: MessageQueueService,
        private casinoLobbyService: CasinoLobbyService,
        private api: PlatformApiService,
        private navigation: NavigationService,
        private casinoWidgetService: CasinoWidgetService,
        private route: ActivatedRoute,
        private gameLaunchPlaces: GameLaunchPlaces,
        private productHomepagesConfig: ProductHomepagesConfig,
        private liveDealerService: LiveDealerService,
        private navigator: NavigatorService,
        private cookies: CookieService,
        private nativeApiService: NativeApiService,
        private vCClientService: VCClientService,
        private nativeAppHelperService: NativeAppHelperService,
        private monitorService: MonitorService,
        private casinoIFrameOverlayService: CasinoIFrameOverlayService,
        private geolocationCheckService: GeolocationCheckService,
        private loginDialog: OpenLoginDialog,
        private casinoManager: CasinoManager,
        private dialog: MatDialog,
        private cacheManagerService: CacheManagerService,
        private advanceGameDataService: AdvanceGameDataService,
        private gameLaunchUrlFormationService: GameLaunchUrlFormationService,
        private urlUtility: UrlUtilityService,
        private unfinishedGamesService: UnfinishedGamesService,
        private favouriteService: FavouriteService,
        private invokerProductService: InvokerProductService,
        private readonly geolocationActivator: GeolocationActivatorService,
        private slotRacesService: SlotRacesService,
        private router: Router,
        private apiPathsHelperService: ApiPathsHelperService,
        private loadingIndicatorService: LoadingIndicatorService,
        private casinoNpmParamsService: CasinoCoreNpmParamsService,
        private geoLocationValidation: GeoLocationValidationService,
        private appInfoConfig: AppInfoConfig,
        private page: Page,
        private searchSection: SearchSectionService,
        private gshService: GlobalSearchHelperService,
        private gameLaunchTrackingService: GameLaunchTrackingService,
        private logger: Logger,
    ) {}

    launchGame(gameLaunchParam: GameLaunch, callback?: any) {
        this.stopAudioSubject.next(true);
        const gameConfig = this.configProviderService.provideGameConfig();
        this.gameNotAvailableTimeOutData = gameConfig.gameNotAvailableTimeOut;
        if (this.gameNotAvailableTimeOutData && this.gameNotAvailableTimeOutData[0]) {
            this.delayAfterRefreshInterval = this.gameNotAvailableTimeOutData[0].delayAfterRefresh;
            this.millisecondsToDisplayInterval = this.gameNotAvailableTimeOutData[0].millisecondsToDisplay;
        }
        if (gameConfig.enableSpinnerOnGameLaunch && this.user.isAuthenticated) {
            if (this._gameLaunchInProgress) {
                return;
            }
            this._gameLaunchInProgress = true;
            gameLaunchParam.loadingIndicatorHandler = this.loadingIndicatorService.start();
        }
        this.messageQueue.clear();
        this.isTouch = gameLaunchParam.isTouch;
        this.gameObj = gameLaunchParam.game;
        this.gameObj.returnUrl = gameLaunchParam.returnUrl;
        this.customRecommendationType = gameLaunchParam.recommendationType;
        const featureConfig = this.configProviderService.provideFeaturesConfig();
        this.showDesktopSearch = featureConfig.showDesktopSearch;
        this.showGSInDesktopSearch = featureConfig.enableGSInRegularSearch;
        const advanceGameDataConfig = this.configProviderService.provideAdvanceGameDataConfig();
        const mcPageConfig = this.configProviderService.provideMcPageConfig();
        gameLaunchParam.mode = gameLaunchParam.isDemoLaunch ? 'play' : gameConfig.gameMode;
        if (!gameLaunchParam.gameSection) {
            gameLaunchParam.gameSection =
                gameLaunchParam.gameLaunchedFrom !== undefined ? gameLaunchParam.gameLaunchedFrom : gameLaunchParam.categoryType;
        }
        gameLaunchParam.game.isDemo = gameLaunchParam.isDemoLaunch;
        const forceGameLaunchParam = gameLaunchParam;
        gameLaunchParam.gridPosition = this.calculateGridPosition(gameLaunchParam);
        if (this.casinoIFrameOverlayService.isOverLayOnAProduct(gameLaunchParam.game.lobbyType)) {
            const customLobbyType: any = this.casinoIFrameOverlayService.fetchOverlayLobby();
            if (customLobbyType) gameLaunchParam.customLobbyTypeMap = Array(customLobbyType);
        } else {
            const casinoCoreNpmParams: any = this.casinoNpmParamsService.getcasinoCoreNpmParams();
            gameLaunchParam.customLobbyTypeMap = casinoCoreNpmParams.customLobbyTypeMaps
                ? [...new Set(casinoCoreNpmParams.customLobbyTypeMaps.map((cl: any) => cl.lobbyType))]
                : null;
        }

        //Advance Game Data Model
        this.setActiveCategory();
        if (advanceGameDataConfig.enableAdvanceGameData) {
            const iconType = gameLaunchParam.game.iconType;
            const iconTypeForTracking = gameLaunchParam.game.iconTypeForTracking;
            const advanceCatInfoObj = this.advanceGameDataService.getAdvanceCategoryInfo(gameLaunchParam);
            const rowNumber = advanceCatInfoObj.rowNumber == undefined ? gameLaunchParam.gridPosition : advanceCatInfoObj.rowNumber;
            this.advanceGameDataService.createAdvanceGameModel(
                advanceCatInfoObj.parentCategoryId,
                advanceCatInfoObj.subCategoryId,
                this.navItemIndex,
                iconType,
                iconTypeForTracking,
                rowNumber,
            );
        }

        this.gameLaunchTracking(gameLaunchParam);
        if (!this.user.claims.get('screenname') || this.user.claims.get('screenname') == '') {
            const guid = this.generateGuid();
            gameLaunchParam.screennameGameLaunchTracking = guid;
            if (!this.loadingIndicatorHandler) {
                this.loadingIndicatorHandler = gameLaunchParam.loadingIndicatorHandler;
                gameLaunchParam.loadingIndicatorHandler = null;
            }
            const gamelaunchlocal = JSON.stringify(gameLaunchParam);
            if (this.loadingIndicatorHandler) {
                gameLaunchParam.loadingIndicatorHandler = this.loadingIndicatorHandler;
                this.loadingIndicatorHandler = null;
            }
            localStorage.setItem(guid, gamelaunchlocal);
        }
        if (this.user.isAuthenticated) {
            if (this.claimsConfig.get('accBusinessPhase') == 'in-shop') {
                if (featureConfig.enableAccountUpgrade) {
                    this.navigation.goTo(mcPageConfig?.accountUpgrade);
                    return;
                }
            }
        }
        if (this.vCClientService.isPokerVC()) {
            this.vCClientService.handleVCGameLaunch(gameLaunchParam, true, callback);
            this.onGameLaunchDone(gameLaunchParam);
            return;
        } else if (gameConfig.nativeGameLaunchEnabled) {
            const params: any = this.getParams(gameLaunchParam);
            this.nativeAppHelperService.handleNativeGameLaunch(params, gameLaunchParam, callback);
            return;
        }

        //check whether game is present in gamedatamap
        if (!this.checkGameInGameMetaData(gameLaunchParam.game.gameId) && this.user.isAuthenticated && !gameLaunchParam.skipMetaDataCheck) {
            this.gameNotFoundAction(gameLaunchParam, callback);
            return;
        }
        // check for disabled game
        const disabledGames: Array<string> = this.casinoLobbyService.getDisabledGames();
        if (disabledGames) {
            disabledGames.forEach((element) => {
                if (element?.toLowerCase() == gameLaunchParam?.game?.gameId?.toLowerCase() && this.user.isAuthenticated) {
                    this.gameNotFoundAction(gameLaunchParam, callback);
                    return;
                }
            });
        }
        //todo: check for unfinished game

        // if (advanceGameDataConfig.enableAdvanceGameData) {
        //     let advanceCatInfoObj = this.advanceGameDataService.getAdvanceCategoryInfo(gameLaunchParam.gameSection);
        //     let rowNumber = (advanceCatInfoObj.rowNumber == undefined) ? gameLaunchParam.gridPosition : advanceCatInfoObj.rowNumber;
        //     playUrl = this.appendQueryString(playUrl, 'adv_parentcat', advanceCatInfoObj.parentCategoryId);
        //     playUrl = this.appendQueryString(playUrl, 'adv_subcat', advanceCatInfoObj.subCategoryId);
        //     playUrl = this.appendQueryString(playUrl, 'adv_pos', String(gameLaunchParam.gamePosition));
        //     playUrl = this.appendQueryString(playUrl, 'adv_icon', gameLaunchParam.game.iconType);
        //     playUrl = this.appendQueryString(playUrl, 'adv_grid', String(rowNumber));

        //     gameLaunchParam.game.advanceDataModel = {
        //         adv_parentcat : advanceCatInfoObj.parentCategoryId,
        //         adv_subcat : advanceCatInfoObj.subCategoryId,
        //         adv_pos : String(gameLaunchParam.gamePosition),
        //         adv_icon : gameLaunchParam.game.iconType,
        //         adv_grid : String(rowNumber)
        //     };
        // }
        let playUrl = this.getPlayUrl(gameLaunchParam);

        if (this.user.isAuthenticated) {
            if (this.slotRacesService.liveSlotPollingStarted && !(this.casinoLobbyService.isODR() || this.casinoLobbyService.isPokerApp())) {
                this.slotRacesService.stopLiveSlotPolling();
            }
            if (gameLaunchParam.isTouch) {
                this.searchSection.setSearchVisibility(false);
                if (this.casinoWidgetService.isCasinoWidget()) {
                    const params = {
                        promptLogin: false,
                        redirectUrl: (playUrl += '&returnUrl=' + document.referrer),
                        categoryid: 'rcp-widget',
                    };
                    this.casinoWidgetService.postMessageToParent('GameLaunch', params);
                } else {
                    const downloadAppFromDeviceConfig = this.configProviderService.provideDownloadAppFromDeviceConfig();
                    if (downloadAppFromDeviceConfig && downloadAppFromDeviceConfig.downloadAppFromDevice) {
                        this.goToPlayGame(gameLaunchParam, callback);
                    } else {
                        this.checkGeolocationAndLaunchMobileGame(
                            () => {
                                this.goToPlayGame(gameLaunchParam, callback);
                            },
                            () => {
                                if (gameLaunchParam.loadingIndicatorHandler) {
                                    gameLaunchParam.loadingIndicatorHandler.done();
                                    this._gameLaunchInProgress = false;
                                }
                            },
                        );
                    }
                }
            } else {
                if (this.casinoWidgetService.isCasinoWidget()) {
                    playUrl += '&returnUrl=' + document.referrer;
                }
                this.checkGeolocationAndLaunchDesktopGame(gameLaunchParam, playUrl, callback);
                setTimeout(() => {
                    if (this.showDesktopSearch && this.showGSInDesktopSearch) {
                        this.gshService.closeGlobaSearch();
                    }
                }, 1000);
            }
        } else {
            if (this.casinoWidgetService.isCasinoWidget() || this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                playUrl += '&returnUrl=' + document.referrer;
                this.casinoWidgetService.postMessageToParent('UserAction', {
                    promptLogin: true,
                    redirectUrl: playUrl,
                    loginMessageKey: 'GameLaunch',
                });
            } else {
                const loginOpenUrl = this.getPlayUrl(gameLaunchParam);
                const product: string = this.casinoWidgetService.getWidgetProduct();
                if (!product || product?.toLowerCase() == 'casino') {
                    this.appendQueryString(loginOpenUrl, 'skipreferrer', 'true');
                }

                const loginResponseObj: any = {
                    loginMessageKey: 'GameLaunch',
                };
                const forceGameLaunchObj: any = {
                    lobbyType: gameLaunchParam.game.lobbyType,
                    gameLaunchParam: forceGameLaunchParam,
                    timeStamp: new Date().getTime(),
                };

                if (!(gameLaunchParam.gameLaunchedFrom == 'playRedirect')) {
                    this.cacheManagerService.writeForceGameLaunchObjInSessionStorage('CasinoCoreForceGameObj', forceGameLaunchObj);
                }

                if (gameLaunchParam.appendRUrlToLoginDialogWindow) {
                    loginResponseObj.returnUrl = gameLaunchParam.returnUrl;
                }
                if (gameLaunchParam.gameLaunchedFrom == 'playRedirect') {
                    loginResponseObj.loginCloseUrl = gameLaunchParam.returnUrl;
                    if (document.getElementsByTagName('lh-login-standalone').length == 0) this.loginDialog.open(loginResponseObj);
                }
                if (!(gameLaunchParam.gameLaunchedFrom == 'globalsearch') && !(gameLaunchParam.gameLaunchedFrom == 'playRedirect')) {
                    this.loginDialog.open(loginResponseObj);
                }
                if (gameLaunchParam.gameLaunchedFrom == 'globalsearch') {
                    loginResponseObj.loginCloseUrl = gameLaunchParam.returnUrl;
                    this.loginDialog.open(loginResponseObj);
                }
            }
        }
    }

    setGameLaunchNavItems(navItems: any) {
        this.navItems = navItems;
    }

    setActiveCategory() {
        if (this.navItems !== undefined) {
            this.navItemIndex = this.navItems.findIndex((m: any) => m.pathname === location.pathname);
            this.navItemIndex = this.navItemIndex + 1;
        }
    }

    getLaunchData(params: any): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        const endPoint = this.apiPathsHelperService.transformApi(
            apiPathsConfig.gameData['gameLaunchDataOfProduct'],
            this.invokerProductService.getInvokerProduct(),
        );
        /*
            for e.g. endPoint = gamedata/casinocoregetlaunchdata?invokerProduct=casino
        */
        return this.api.get(endPoint, params, { withCredentials: true });
    }

    getParams(gameLaunchParam: GameLaunch) {
        let gameHomeUrl: string = this.casinoManager.getGameHomeUrl(); // need to change it to casinomanager
        if (!gameHomeUrl) {
            gameHomeUrl = gameLaunchParam.returnUrl;
        }
        if (!gameHomeUrl) {
            gameHomeUrl = window.location.href;
        }
        const gameConfig = this.configProviderService.provideGameConfig();
        if (!gameLaunchParam.skipUnfinGame) gameLaunchParam.skipUnfinGame = true;
        if (!gameLaunchParam.mode) gameLaunchParam.mode = gameConfig.gameMode;

        return {
            gameId: gameLaunchParam.game.gameId,
            mode: gameLaunchParam.mode,
            demo: gameLaunchParam.isDemoLaunch,
            launchProductId: this.navigation.location.search.get('launchProductId'),
            launchOrigin: this.navigation.location.search.get('launchOrigin'),
            skipUnfinGame: gameLaunchParam.skipUnfinGame,
            gameHomeUrl: encodeURIComponent(gameHomeUrl),
            isLiveGames: gameLaunchParam.isLive,
            skipMetaDataCheck: gameLaunchParam.skipMetaDataCheck,
            fetchLiveGameIFrame: gameLaunchParam.fetchLiveGameIFrame,
            customLobbyTypeMap: gameLaunchParam.customLobbyTypeMap,
            gamelaunchparams: gameLaunchParam.game.gamelaunchparams,
            screennameGameLaunchTracking: gameLaunchParam.screennameGameLaunchTracking,
        };
    }

    checkGameInGameMetaData(gameId: string): boolean {
        const gameMetaDataMap = this.casinoLobbyService.getGameInfo(gameId);
        if (gameMetaDataMap) {
            return true;
        }
        return false;
    }

    getPlayUrl(gameLaunchParam: GameLaunch) {
        const gameConfig = this.configProviderService.provideGameConfig();
        const globalJackpotClientConfig = this.configProviderService.provideGlobalJackpotConfig();
        if (!gameLaunchParam.mode) gameLaunchParam.mode = gameConfig.gameMode;
        let product: string = this.casinoManager.getProductForLobbyType(gameLaunchParam.game.lobbyType);
        if (gameLaunchParam.specialProduct) {
            product = gameLaunchParam.specialProduct;
        }
        if (!product) {
            product = 'games';
        }
        let result = this.urlUtility.getApiBaseUrl() + '/' + product?.toLowerCase();
        if (product?.toLowerCase() == 'globaljackpot') {
            result = this.productHomepagesConfig.casino + '/g/' + globalJackpotClientConfig?.globalJackpotPathSegments?.toLowerCase();
        }

        result += '/launchng/' + gameLaunchParam.game.gameId;

        if (this.casinoWidgetService.isCasinoWidget() || this.route.snapshot.queryParamMap.get('widgetGameLaunch')) {
            const queryStringMap: Map<string, string> = new Map<string, string>();
            queryStringMap.set('widgetGameLaunch', 'true');
            queryStringMap.set('invokerProduct', this.casinoWidgetService.getWidgetProduct());
            queryStringMap.set('widgetPostion', this.casinoWidgetService.getWidgetPosition());
            queryStringMap.set('launchCategory', gameLaunchParam.categoryType);
            result = this.appendQueryStringMap(result, queryStringMap);
        }
        // Sending the entire game launch url as rurl so that it works fine even if the user is not fully verified after login during force game launch
        if (gameLaunchParam.isDemoLaunch) {
            result = this.appendQueryString(result, 'demo', 'true');
        } else if (gameLaunchParam.mode) {
            result = this.appendQueryString(result, 'mode', gameLaunchParam.mode);
        }
        if (gameLaunchParam.from) {
            result = this.appendQueryString(result, 'place', gameLaunchParam.from);

            if (
                gameLaunchParam.from === this.gameLaunchPlaces.gameIconOnLobby ||
                gameLaunchParam.from === this.gameLaunchPlaces.gameTitleOnLobby ||
                gameLaunchParam.from === this.gameLaunchPlaces.gameInfoIconOnLobby
            ) {
                result = this.appendQueryString(result, 'gameLaunchCategory', gameLaunchParam.categoryType);
            }

            if (gameLaunchParam.gamePosition) {
                result = this.appendQueryString(result, 'gamePosition', gameLaunchParam.gamePosition.toString());
            }
        }
        return result;
    }

    goToPlayGame(gameLaunchParam: GameLaunch, callback?: any) {
        const featureConfig = this.configProviderService.provideFeaturesConfig();
        const downloadAppFromDeviceConfig = this.configProviderService.provideDownloadAppFromDeviceConfig();
        const mcPageConfig = this.configProviderService.provideMcPageConfig();
        const gamesConfig = this.configProviderService.provideGameConfig();
        // If download app on game launch is enabled in mobile web, then show the info message for downloading the app (post login game launch scenario)
        if (
            downloadAppFromDeviceConfig &&
            downloadAppFromDeviceConfig.downloadAppFromDevice &&
            this.user.isAuthenticated &&
            this.user.isAuthenticated
        ) {
            const errormessage: string = downloadAppFromDeviceConfig.downloadAppErrorMsgs;
            this.messageQueue.clear();
            const message: Message = {
                html: errormessage,
                type: MessageType.Information,
                lifetime: MessageLifetime.Single,
                scope: 'casinocorelobby',
            };
            if (message) {
                this.messageQueue.add(message);
            }
            this.onGameLaunchDone(gameLaunchParam);
        } else {
            if (
                this.casinoLobbyService.isGameInCategory(gameLaunchParam.game, ConstService.LIVE_DEALER_GAMES_CATEGORY_ID) &&
                !this.liveDealerService.isOpen()
            ) {
                const options: GoToOptions = {
                    appendReferrer: false,
                };
                const url = mcPageConfig.prefix + '/' + ConstService.LIVE_DEALER_GAMES_CATEGORY_ID;
                this.navigator.navigate(url, options);
                return;
            }
            //ASK RAM
            // let isDemoAvailable: boolean = this.user.isAuthenticated && game.demoAvailable ? game.demoAvailable : false;
            // let isDemoEnabled: boolean = this.user.isAuthenticated && game.demoEnabled ? game.demoEnabled : false;
            // if (isDemoAvailable && isDemoEnabled && !isDemoLaunch && !this.m2NativeApp.isNativeApp) {
            //     this.navigator.demoPlayPage(game.gameId); //widget: check this flow is any redirection is taking place?
            // }
            gameLaunchParam.isNewSportsNative =
                this.m2NativeApp.isNativeApp &&
                (this.m2NativeApp.product === 'SPORTSBOOK' || this.m2NativeApp.product === 'BETTING') &&
                (this.cookies.get('useNewOpenGames') === 'true' || this.navigation.location.search.get('useNewOpenGames') === 'true');
            //ASK RAM
            if (gameLaunchParam.isNewSportsNative && featureConfig.isAnonymousLobbyEnabled && !this.user.isAuthenticated) {
                this.casinoManager.gameEventId = this.nativeApiService.raiseOpenGameEvent(
                    this.casinoManager.gameEventId,
                    '',
                    gameLaunchParam.game.gameId,
                    false,
                );
                return;
            }

            const placeCheckForGameDetail: boolean =
                gameLaunchParam.from === this.gameLaunchPlaces.gameIconOnLobby ||
                gameLaunchParam.from === this.gameLaunchPlaces.searchOnLobby ||
                gameLaunchParam.from === this.gameLaunchPlaces.gameTitleOnLobby ||
                gameLaunchParam.from === 'q' ||
                gameLaunchParam.from === 's' ||
                gameLaunchParam.from === this.gameLaunchPlaces.teaser;

            if (placeCheckForGameDetail && gameLaunchParam.game.lobbyType) {
                gameLaunchParam.game = this.checkforGameDetailVisible(gameLaunchParam.game);
            }

            if (
                (placeCheckForGameDetail && gameLaunchParam.game.ForceDetailPage) ||
                (placeCheckForGameDetail && gameLaunchParam.game.EnableDetailPage)
            ) {
                if (gameLaunchParam.isNewSportsNative) {
                    this.casinoManager.gameEventId = this.nativeApiService.raiseOpenGameEvent(
                        this.casinoManager.gameEventId,
                        encodeURIComponent(this.navigator.detailPage(gameLaunchParam.game.gameId)),
                        gameLaunchParam.game.gameId,
                        this.user.isAuthenticated,
                    );
                }
                if (gameLaunchParam.isNewSportsNative) {
                    this.casinoManager.gameEventId = this.nativeApiService.raiseOpenGameEvent(
                        this.casinoManager.gameEventId,
                        encodeURIComponent(this.navigator.detailPage(gameLaunchParam.game.gameId)),
                        gameLaunchParam.game.gameId,
                        this.user.isAuthenticated,
                    );
                } else {
                    const gamedetailPopUpData: any = {
                        game: gameLaunchParam,
                    };
                    this.dialog.open(GameDetailComponent, { data: gamedetailPopUpData });
                    this.gameDetailPageSubject.next(gamedetailPopUpData);
                }
                return;
            }
            const options: MessageQueueClearOptions = {
                clearPersistent: true,
            };
            if (options) this.messageQueue.clear(options);
            const params: any = this.getParams(gameLaunchParam);
            this.handleGamelaunch(params, gameLaunchParam, gamesConfig, callback);
        }
    }

    handleGamelaunch(params: any, gameLaunchParam: GameLaunch, gamesConfig: any, callback?: any) {
        this.getLaunchData(params).subscribe(
            (response: any) => {
                // response is coming here instead of going to error so added this condition
                if (response && response.portalInfo && response.redirect) {
                    this.onGameLaunchDone(gameLaunchParam);
                    this.handleGameLaunchError(response, gameLaunchParam, callback);
                    return;
                }
                this.onGameLaunchDone(gameLaunchParam);
                if (response.data && response.data.aams) {
                } else {
                    let enableNativeOdrEvent = this.configProviderService.provideGameConfig()?.enableNativeOdrEvent;
                    const redirectUrl = this.gameLaunchUrlFormationService.launchMGSGame(response, gameLaunchParam);
                    response.redirect = redirectUrl;
                    if (
                        (enableNativeOdrEvent?.isPokerApp && this.casinoLobbyService.isPokerApp()) ||
                        (enableNativeOdrEvent?.isOdr && this.casinoLobbyService.isODR())
                    ) {
                        this.sendCCBEventToPokerAndOdr(redirectUrl);
                        return;
                    }

                    this.launchGameFromResponse(response, gameLaunchParam, callback);
                }
                if (gamesConfig.storeSsotokenInCookies)
                    this.cookies.put('ssoToken', this.user.ssoToken || '', {
                        domain: this.page.domain,
                    });
                const gameName = gameLaunchParam?.game?.name
                    ? gameLaunchParam?.game?.name
                    : this.casinoLobbyService.getGameDisplayName(gameLaunchParam?.game.gameId);
                this.storeUserInfo(gameName);
            },
            (error: any) => {
                this.onGameLaunchDone(gameLaunchParam);
                this.handleGameLaunchError(error, gameLaunchParam, callback);
            },
        );
    }

    onGameLaunchDone(gameLaunchParam: any) {
        this.messageQueue.clear(); //clearing any error messages from message queue
        const gameConfig = this.configProviderService.provideGameConfig();
        if (gameConfig.enableSpinnerOnGameLaunch && gameLaunchParam.loadingIndicatorHandler && this._gameLaunchInProgress) {
            gameLaunchParam.loadingIndicatorHandler.done();
            this._gameLaunchInProgress = false;
        }
    }

    sendCCBEventToPokerAndOdr(gameLaunchUrl: string) {
        const urlParameters = new URLSearchParams(gameLaunchUrl);
        const request: any = {};
        request.url = gameLaunchUrl;
        request.type = 'GAMELAUNCHURL';
        if (!urlParameters.get('ssoKey')) {
            request.sessionKey = 'ssoKey';
        }
        this.m2NativeApp.sendToNative({
            eventName: this.OPEN_URL,
            parameters: request,
        });
    }

    getDesktopGameLaunchUrl(gameLaunchParam: any, callback: (data: any) => void) {
        const params: any = this.getParams(gameLaunchParam);
        this.getLaunchData(params).subscribe(
            (response: any) => {
                if (response.data && response.data.aams) {
                } else {
                    const redirectUrl = this.gameLaunchUrlFormationService.launchMGSGame(response, gameLaunchParam);
                    const callbackResponse: any = {
                        iframeUrl: redirectUrl,
                    };
                    callback(callbackResponse);
                }
            },
            (error: any) => {
                this.handleGameLaunchError(error, gameLaunchParam);
            },
        );
    }

    appendQueryString(url: string, key: string, value: string): string {
        if (url.indexOf('?') > -1) {
            url += '&' + key + '=' + value;
        } else {
            url += '?' + key + '=' + value;
        }
        return url;
    }

    appendQueryStringMap(url: string, queryMap: Map<string, string>): string {
        if (queryMap && queryMap.size > 0) {
            queryMap.forEach((value, key) => {
                if (url.indexOf('?') > -1) {
                    url += '&' + key + '=' + value;
                } else {
                    url += '?' + key + '=' + value;
                }
            });
        }
        return url;
    }
    validateUser(playUrl: string, replaceRouteInHistory: boolean) {
        const mcPageConfig = this.configProviderService.provideMcPageConfig();
        if (!this.user.isAuthenticated) {
            const loginResponseObj: any = {
                returnUrl: playUrl,
                loginMessageKey: 'GameLaunch',
            };
            this.loginDialog.open(loginResponseObj);
            return false;
        }
        const birthDate: Date = new Date(this.user.dateOfBirth || '');
        const minAge: number = mcPageConfig.minAgeValidationCasinoPlay;
        if (minAge > 0) {
            const minDate: Date = new Date(),
                portalPage = true;
            minDate.setFullYear(minDate.getFullYear() - minAge);
            const options: GoToOptions = {
                replace: replaceRouteInHistory,
            };
            if (minDate < birthDate) {
                if (minAge === 18) {
                    this.navigator.publicPage('18plus', options, portalPage);
                }
                if (minAge === 21) {
                    this.navigator.publicPage('18_21plus', options, portalPage);
                }
                return false;
            }
        }
        return true;
    }

    checkforGameDetailVisible(game: any): boolean {
        const gameConfig = this.configProviderService.provideGameConfig();
        const casinoConfig = this.configProviderService.provideCasinoConfig();
        const lobbyType = game.lobbyType;
        if (game && (!game.provider || game.provider === '')) {
            const gameDetails: any = this.casinoLobbyService.getGameInfo(game.gameId);
            if (gameDetails) {
                game.provider = gameDetails.provider;
            }
        }

        const detailPageMode: string = this.casinoManager.IsFeatureEnabledforLobbytype(lobbyType, casinoConfig.detailPageModeLobbyType);
        const vendorsWithOnlyRealMode: any = this.casinoManager.IsFeatureEnabledforLobbytype(lobbyType, gameConfig.vendorsWithOnlyRealModeLobbyType);
        const enableDemoPlay: any = gameConfig.enableDemoMode;
        const rglinkOnDetailPage: any = this.casinoManager.IsFeatureEnabledforLobbytype(lobbyType, gameConfig.showRGLinkOnDetailPageLobbyType);

        if (detailPageMode == ConstService.DETAIL_PAGE_MODE_PICKER || detailPageMode == ConstService.DETAIL_PAGE_MODE_FORCEPICKER) {
            if (
                (!gameConfig.enablePlaytechLiveGameDetailsPage &&
                    vendorsWithOnlyRealMode.indexOf(game.provider) > -1 &&
                    !game.gameId.startsWith('ivy')) ||
                (game.provider == 'Playtech' && game.gameId.startsWith('playtechlive'))
            ) {
                game.EnableDetailPage = false;
                game.ForceDetailPage = false;
            } else {
                game.EnableDetailPage = true;
                game.ForceDetailPage = true;
            }
        } else {
            const force = detailPageMode == ConstService.DETAIL_PAGE_MODE_FORCE;
            const enabled = detailPageMode == ConstService.DETAIL_PAGE_MODE_ALL || force;
            if (
                (!gameConfig.enablePlaytechLiveGameDetailsPage &&
                    vendorsWithOnlyRealMode.indexOf(game.provider) > -1 &&
                    !game.gameId.startsWith('ivy')) ||
                (game.provider == 'Playtech' && game.gameId.startsWith('playtechlive'))
            ) {
                game.EnableDetailPage = false;
                game.ForceDetailPage = false;
            } else {
                game.EnableDetailPage = enabled;
                game.ForceDetailPage = force;
            }
        }
        if (enableDemoPlay && !(vendorsWithOnlyRealMode.indexOf(game.provider) > -1 && !game.gameId.startsWith('ivy'))) {
            game.DemoEnabled = true;
            game.DemoAvailable = true;
            if (rglinkOnDetailPage && rglinkOnDetailPage.length > 0) {
                const matchedProviderFound = rglinkOnDetailPage.filter((c: any) => c === game.provider);
                if (matchedProviderFound && matchedProviderFound[0]) {
                    game.ResponsibleGamingLink = gameConfig.responsibleGamingLink;
                }
            }
        }
        return game;
    }

    launchGameFromResponse(response: any, gameLaunchParam: GameLaunch, callback?: any) {
        this.monitorService.managePortalInfo(response, true);

        if (this.monitorService.isActive() === false) {
            this.proceed(response, gameLaunchParam, callback);
        } else {
            this.monitorService.monitorActiveObservable.subscribe((monitorStatus: any) => {
                if (monitorStatus && monitorStatus === this.monitorService.MONITOR_INACTIVE) {
                    this.proceed(response, gameLaunchParam, callback);
                }
            });
        }
    }

    proceed(response: any, gameLaunchParam: GameLaunch, callback?: any) {
        if (gameLaunchParam.isNewSportsNative && this.gameLaunchPlaces.isLobby(gameLaunchParam.from)) {
            this.casinoManager.gameEventId = this.nativeApiService.raiseOpenGameEvent(
                this.casinoManager.gameEventId,
                encodeURIComponent(response.redirect),
                gameLaunchParam.game.gameId,
                this.user.isAuthenticated,
            );
        } else {
            const options: GoToOptions = {
                replace: gameLaunchParam.replaceRouteInHistory,
                forceReload: true,
            };

            if (gameLaunchParam.from == 'thumbnail' && gameLaunchParam.lobbyType) {
                this.cacheManagerService.setThumbnailLocalStorageCacheMap(gameLaunchParam.lobbyType, gameLaunchParam.game.gameId);
            }

            if (callback) {
                const callbackResponse = {
                    isError: false,
                    errorMessage: '',
                    gameLaunchUrl: response.redirect,
                };
                callback(callbackResponse);
            } else {
                if (this.isTouch) {
                    this.storeUserInfo(gameLaunchParam?.game?.name);
                    this.navigator.navigate(response.redirect, options, true, 0);
                } else {
                    // designed for launching game as SingleHost
                    const sglProduct = this.gameLaunchUrlFormationService?.getCurrentProduct()
                        ? this.gameLaunchUrlFormationService?.getCurrentProduct()
                        : gameLaunchParam?.currentProduct;
                    if (this.casinoManager.isSingleHostLobbyType(gameLaunchParam?.game?.lobbyType, sglProduct)) {
                        this.storeUserInfo(gameLaunchParam?.game?.name);
                        this.navigator.navigate(response.redirect, options, true, 0);
                        return;
                    }
                    // end - designed for launching game as SingleHost
                    const features = this.configProviderService.provideFeaturesConfig();
                    if (features.enableNewEmbeddedViewIconsPosition) {
                        const multipleGameLaunched = this.getFromCookie('gamePopOutWindow');
                        if (multipleGameLaunched) {
                            const Global = this.configProviderService.provideGlobalConfig();
                            const errormessage = Global.messages['OnlyOneGameAtTime'];
                            this.messageQueue.clear();
                            setTimeout(() => {
                                if (errormessage && this.isSingleMessage)
                                    this.messageQueue.addError(errormessage, MessageLifetime.Single, 'casinocorelobby');
                                this.isSingleMessage = false;
                                window.scrollTo({ top: 0 });
                                setTimeout(() => {
                                    this.messageQueue.clear();
                                    this.isSingleMessage = true;
                                    if (this.navigation.location.url().indexOf('launchng') >= 0) {
                                        this.casinoManager.goToLobby();
                                    }
                                    this.stopAudio();
                                }, features.singleGameErrorTimeout);
                            }, 0);
                        } else {
                            const embedGamePopUpData: any = {
                                iframeUrl: response.redirect,
                                game: this.gameObj,
                                categoryType: gameLaunchParam.categoryType,
                            };
                            //open the mat dialog
                            this.openEmbeddedGameDailog(embedGamePopUpData);
                        }
                    } else {
                        const embedGamePopUpData: any = {
                            iframeUrl: response.redirect,
                            game: this.gameObj,
                            categoryType: gameLaunchParam.categoryType,
                        };
                        //open the mat dialog
                        this.openEmbeddedGameDailog(embedGamePopUpData);
                    }
                }
            }
        }
    }

    getFromCookie(key: any): boolean {
        if (this.cookies.get(key) === '1') {
            return true;
        }
        return false;
    }

    openEmbeddedGameDailog(data: any = null): void {
        const dialogRef = this.dialog.open(EmbeddedGamePopupComponent, {
            width: '790px',
            height: '779px',
            data: data,
        });
        dialogRef.afterClosed().subscribe(() => {
            this.updateFreespinsSubject.next(true);
            this.stopSchedulePollingInBingo(false);
        });
    }

    stopSchedulePollingInBingo(flag: boolean) {
        //for Iframe
        const cozyBingoIframe = document.getElementsByClassName('cozy-bingo-iFrame').length
            ? (document.getElementsByClassName('cozy-bingo-iFrame')[0] as HTMLIFrameElement)
            : null;
        if (cozyBingoIframe && cozyBingoIframe.contentWindow) {
            cozyBingoIframe.contentWindow.postMessage({ stopSchedulePolling: flag }, this.productHomepagesConfig.bingo);
        }
        if (this.invokerProductService?.getInvokerProduct()?.toLowerCase() == 'casino') {
            if (cozyBingoIframe) (window as any).Disable_SchedulePolling = flag;
            else (window as any).Disable_SchedulePolling = true;
        } else {
            (window as any).Disable_SchedulePolling = flag;
        }
    }

    checkGeolocationAndLaunchDesktopGame(gameLaunchParam: any, playUrl: string, callback?: any): void {
        const geolocationConfig = this.configProviderService.provideGeoLocationConfig();
        if (geolocationConfig.isEnabled) {
            this.geolocationCheckService.checkGeolocation(
                () => {
                    this.launchDesktopGame(gameLaunchParam, playUrl, callback);
                },
                () => {
                    if (gameLaunchParam.loadingIndicatorHandler) {
                        gameLaunchParam.loadingIndicatorHandler.done();
                        this._gameLaunchInProgress = false;
                    }
                },
            );
            return;
        }
        this.launchDesktopGame(gameLaunchParam, playUrl, callback);
    }

    handleGameLaunchError(error: any, gameLaunchParam: GameLaunch, callback?: any) {
        if (error) {
            let errormessages: any[] = [];
            if (error?.common && error?.common?.messages) {
                errormessages = error.common.messages;
            }
            if (error.redirect) {
                const isNewSportsNative = this.m2NativeApp.isNativeApp;
                if (isNewSportsNative && this.gameLaunchPlaces.isLobby(gameLaunchParam.from)) {
                    this.casinoManager.gameEventId = this.nativeApiService.raiseOpenGameEvent(
                        this.casinoManager.gameEventId,
                        encodeURIComponent(error.redirect),
                        gameLaunchParam.game.gameId,
                        this.user.isAuthenticated,
                    );
                    if (this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                        window.parent.postMessage(
                            {
                                eventName: 'UserAction',
                                params: { redirectUrl: error.redirect },
                            },
                            window.location.origin,
                        );
                    }
                } else {
                    const options: GoToOptions = {
                        replace: gameLaunchParam.replaceRouteInHistory,
                    };
                    if (this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                        window.parent.postMessage(
                            {
                                eventName: 'UserAction',
                                params: { redirectUrl: error.redirect },
                            },
                            window.location.origin,
                        );
                    } else {
                        //case when return error is same as current url
                        //check mobile/desktop
                        //if mobile then add to msg queue and navigate(msg queue will be displayed if screen name is coming with error)
                        //if desktop then open pop-up with error otherwise redirect(maybe compare url)
                        if (error.portalInfo && error.portalInfo.statusCode == 101) {
                            const forceGameLaunchObj: any = {
                                lobbyType: gameLaunchParam.game.lobbyType,
                                gameLaunchParam: gameLaunchParam,
                                timeStamp: new Date().getTime(),
                            };
                            if (!gameLaunchParam.fetchLiveGameIFrame) {
                                // check for thunderbite game
                                this.cacheManagerService.writeForceGameLaunchObjInSessionStorage('CasinoCoreScreenNameGameObj', forceGameLaunchObj);
                            }
                            const redirect = this.addClickTimeStamp(decodeURIComponent(error.redirect), gameLaunchParam);
                            this.navigator.navigate(redirect, options, true, -1, errormessages);
                        } else {
                            this.gameLaunchRedirectErrorAction(errormessages, callback);
                            this.navigator.navigate(decodeURIComponent(error.redirect), options, true, -1, errormessages);
                        }
                    }
                }
            } else {
                this.gameLaunchRedirectErrorAction(errormessages, callback);
            }
        }
    }

    launchDesktopGame(gameLaunchParam: any, playUrl: string, callback?: any): void {
        if (this.casinoWidgetService.isCasinoWidget()) {
            const params = {
                promptLogin: false,
                redirectUrl: playUrl,
                categoryid: 'rcp-widget',
            };
            this.casinoWidgetService.postMessageToParent('GameLaunch', params);
        } else {
            this.goToPlayGame(gameLaunchParam, callback);
        }
    }

    getGameLunchType(gameId: any) {
        const gameMetaData = this.casinoLobbyService.getGameMetaData();
        let launchType: string;
        if (gameMetaData && gameMetaData.size > 0) {
            const gameObj = gameMetaData.get(gameId);
            if (gameObj) {
                launchType = gameObj.provider;

                return launchType;
            }
        }
        return null;
    }

    getProductForLobbyType(lobbyType: string) {
        let product = this.appInfoConfig.product;
        if (lobbyType && product?.toLowerCase() == 'casino') {
            product = this.casinoLobbyService.getProductForGivenLobbyType(lobbyType);
            if (product?.toLowerCase() == 'games') {
                product = 'casino';
            }
        }
        return product?.toUpperCase();
    }

    gameLaunchTracking(gameLaunchParam: any) {
        const catInfo = this.casinoLobbyService.getCategoryInfo(gameLaunchParam.game.lobbyType, gameLaunchParam.categoryType);
        let gameContainerDescription!: string;
        let parentCategoryId!: string;
        let trackingContainer!: string;
        let childChildcategoryId!: string;
        let categoryEngName!: string;
        const categoryType = this.getProductForLobbyType(gameLaunchParam.game.lobbyType);
        if (catInfo && catInfo.categoryInfo) {
            parentCategoryId = catInfo.categoryInfo.parentCategoryId;
            childChildcategoryId = catInfo.categoryInfo.categoryid;
            trackingContainer = this.casinoLobbyService.getCategoryName(gameLaunchParam.game.lobbyType, childChildcategoryId);
            if (!parentCategoryId) {
                parentCategoryId = childChildcategoryId;
                trackingContainer = gameLaunchParam?.gameLaunchedFrom;
            }
            if (
                gameLaunchParam?.gameLaunchedFrom?.startsWith('MustGoJackpot_') ||
                gameLaunchParam?.gameLaunchedFrom?.startsWith('JackpotWidget_') ||
                gameLaunchParam?.gameLaunchedFrom?.startsWith('Netflix_Video_') ||
                gameLaunchParam?.gameLaunchedFrom?.startsWith('Teaser')
            ) {
                trackingContainer = gameLaunchParam.gameLaunchedFrom;
            }
            categoryEngName = this.casinoLobbyService.getCategoryName(gameLaunchParam.game.lobbyType, parentCategoryId);
            gameContainerDescription =
                gameLaunchParam?.game?.gameContainerDescription ||
                (catInfo.categoryInfo.sitecoreData &&
                (catInfo.categoryInfo.sitecoreData.backgroundImageLayer1 ||
                    catInfo.categoryInfo.sitecoreData.backgroundImageLayer2 ||
                    catInfo.categoryInfo.sitecoreData.backgroundImageLayer3)
                    ? 'Highlighted | image'
                    : 'Non Highlighted | nocolor');
        }
        const gameName = gameLaunchParam.game.name
            ? gameLaunchParam?.game?.name
            : this.casinoLobbyService.getGameDisplayName(gameLaunchParam?.game.gameId);
        const gameSection = parentCategoryId || gameLaunchParam.gameSection;
        let trackingGridPosition: string = gameLaunchParam.gridPosition;
        if (gameLaunchParam?.gameLaunchedFrom?.startsWith('RecentlyPlayed_widget_')) {
            trackingContainer = gameLaunchParam.gameLaunchedFrom;
            categoryEngName = this.casinoLobbyService.getCategoryName(gameLaunchParam.game.lobbyType, childChildcategoryId);
        }
        if (gameLaunchParam?.gameSection == 'Module_Discover_Promo' || gameLaunchParam?.gameSection?.toLowerCase() == 'globalsearch') {
            trackingGridPosition = 'Not Applicable';
        } else if (gameLaunchParam?.gridPosition) {
            trackingGridPosition = gameLaunchParam?.gamePosition
                ? `${gameLaunchParam?.gridPosition}:${gameLaunchParam?.gamePosition + 1}`
                : `${gameLaunchParam?.gridPosition}:1`;
        } else {
            trackingGridPosition = gameLaunchParam?.gamePosition ? `1:${gameLaunchParam?.gamePosition + 1}` : '1:1';
        }
        if (gameLaunchParam.Globalsearchgameposition) {
            gameLaunchParam.gamePosition = gameLaunchParam.Globalsearchgameposition;
        }
        const lobbyData: any = this.casinoLobbyService.getLobbyBasedOnLobbyType(gameLaunchParam.game.lobbyType);
        const gameTrackingObj: any = {
            name: gameLaunchParam?.game?.gameId,
            type: gameLaunchParam?.game?.provider ? gameLaunchParam?.game?.provider : this.getGameLunchType(gameLaunchParam.game.gameId),
            position: gameLaunchParam?.gamePosition + 1,
            section:
                gameLaunchParam?.game?.widgetName == 'RecentWinners' || gameLaunchParam?.game?.widgetName == 'PlayerStats'
                    ? gameLaunchParam.gameSection
                    : gameLaunchParam.moreInfoOverlay
                      ? gameLaunchParam?.gameSection
                      : trackingContainer || gameLaunchParam?.gameSection,
            gridPosition: trackingGridPosition,
            location:
                gameLaunchParam?.game?.widgetName == 'RecentWinners' || gameLaunchParam?.game?.widgetName == 'PlayerStats'
                    ? gameLaunchParam.gameSection + '-' + gameLaunchParam?.game?.currentTab
                    : gameLaunchParam.isMoreInfoOverlay
                      ? 'moreinfo overlay' + ' | ' + gameSection
                      : gameLaunchParam?.gameLaunchedLocation || (gameLaunchParam?.gameContainer || 'Page Container') + ' | ' + gameSection,
            status: gameLaunchParam?.game?.isSignPostedGame ? 'New' : 'Existing',
            description: gameContainerDescription || 'Not Applicable',
            favourite: this.favouriteService.checkForFavouriteGame(gameLaunchParam?.game) ? 'Favourite' : 'Non_favourite',
            playingStatus: this.unfinishedGamesService.checkForUnfinishedGame(gameLaunchParam?.game) ? 'Unfinished' : 'Start',
            displayName: gameName || gameLaunchParam?.game?.gameVariantName,
            categoryType: categoryType,
            categorySubType: gameLaunchParam?.Globalsearchsection || categoryEngName || gameLaunchParam?.gameSection,
            recommendationType: this.customRecommendationType || (catInfo?.categoryInfo?.havingCasiaGames ? 'Casia' : 'Default'),
            lobbyName: lobbyData?.lbyTempName,
            lobbySource: lobbyData?.lbyTargetType,
            optimoveSegment: lobbyData?.optSegName,
            categoryName: categoryEngName,
            params: gameLaunchParam,
        };
        this.gameLaunchTrackingService.track(gameTrackingObj);
    }

    checkGeolocationAndLaunchMobileGame(callback: any, errorCallback: any) {
        const geolocationConfig = this.configProviderService.provideGeoLocationConfig();
        if (this.geolocationActivator.isActive) {
            this.geolocationCheckService.onCheckClosed = () => {
                if (this.route.snapshot.queryParamMap.has('returnUrl')) {
                    const returnUrlInQueryParameter = this.route.snapshot.queryParamMap.get('returnUrl');
                    if (returnUrlInQueryParameter && returnUrlInQueryParameter.trim()) {
                        this.navigator.navigate(returnUrlInQueryParameter.trim());
                        return;
                    }
                }
                if (this.router.routerState.snapshot.url.split('/').some((segment) => segment?.trim()?.toLowerCase() === 'launchng')) {
                    const gameHomeUrl = this.casinoManager.getGameHomeUrl();
                    if (gameHomeUrl) {
                        this.navigator.navigate(gameHomeUrl);
                        return;
                    }
                }
            };
            this.geolocationCheckService.checkGeolocation(
                () => {
                    callback();
                },
                () => {
                    errorCallback();
                },
            );
            return;
        }
        if (geolocationConfig.shouldCheckNativeGeoLocation && (this.m2NativeApp.isNativeApp || this.m2NativeApp.isNativeWrapper)) {
            this.geoLocationValidation.validateLocation((response: boolean) => {
                if (response != null && !response) {
                    callback();
                } else {
                    errorCallback();
                }
            });
            return;
        }
        callback();
    }

    calculateGridPosition(gameLaunchParam: any) {
        const trackedClassList: any = document.getElementsByClassName('gametracking');
        const gridPosition: any = gameLaunchParam.gridPosition;
        if (gameLaunchParam.game.trackCategory && gameLaunchParam.game.trackCategory != '') {
            gameLaunchParam.categoryType = gameLaunchParam.game.trackCategory;
        }
        if (trackedClassList.length > 0) {
            for (let i = 0; i < trackedClassList.length; i++) {
                const value: any = trackedClassList[i];
                if (value.classList.contains('track_' + gameLaunchParam.categoryType)) {
                    return (i + 1).toString();
                }
            }
        }
        return gridPosition ? gridPosition.toString() : '';
    }

    gameNotFoundAction(gameLaunchParam: any, callback?: any) {
        this.onGameLaunchDone(gameLaunchParam);
        this.messageQueue.clear();
        const globalConfig = this.configProviderService.provideGlobalConfig();
        let errormessage: string = globalConfig.messages['GameNotAvailable'];
        errormessage = errormessage.replace('{0}', gameLaunchParam.game.gameId);
        const message: Message = {
            html: errormessage,
            type: MessageType.Information,
            lifetime: MessageLifetime.Single,
            scope: 'casinocorelobby',
        };
        if (callback) {
            const callbackResponse = {
                isError: true,
                errorMessage: errormessage,
                gameLaunchUrl: '',
            };
            callback(callbackResponse);
        } else {
            if (gameLaunchParam.isTouch) {
                //this.messageQueue.add(message);
                if (gameLaunchParam.returnUrl) {
                    const casinoNpmParams = this.casinoNpmParamsService.getcasinoCoreNpmParams();
                    let returnurl: string = gameLaunchParam?.returnUrl;
                    if (this.navigation.location.search.get('gamenotfound') === 'true') {
                        returnurl = gameLaunchParam?.returnUrl;
                    } else if (!casinoNpmParams.isDll) {
                        returnurl = this.appendQueryString(gameLaunchParam.returnUrl, 'gamenotfound', 'true');
                    }
                    sessionStorage.setItem('gameNotAvailable', JSON.stringify(message));
                    if (returnurl) {
                        this.navigation.goTo(returnurl);
                    }
                    setTimeout(() => {
                        this.getGameNotAvailable();
                    }, this.delayAfterRefreshInterval);
                } else {
                    sessionStorage.setItem('gameNotAvailable', JSON.stringify(message));
                    this.getGameNotAvailable();
                }
            } else {
                const features = this.configProviderService.provideFeaturesConfig();
                if (features.enableNewEmbeddedViewIconsPosition) {
                    const returnurl = gameLaunchParam?.returnUrl;
                    const homePageUrl = this.casinoManager.getGameHomeUrl();
                    if (errormessage) {
                        this.messageQueue.addError(errormessage, MessageLifetime.Single, 'casinocorelobby');
                    }
                    setTimeout(() => {
                        this.messageQueue.clear();
                        if (returnurl) this.navigation.goTo(returnurl);
                        else this.navigation.goTo(homePageUrl);
                    }, features.singleGameErrorTimeout);
                } else {
                    message.scope = 'embeddedGameError';
                    const embedGamePopUpData: any = {
                        game: this.gameObj,
                        errorMessageQueue: message,
                    };
                    this.openEmbeddedGameDailog(embedGamePopUpData);
                }
            }
        }
        return;
    }

    getGameNotAvailable() {
        this.messageQueue.clear();
        const message = JSON.parse(sessionStorage.getItem('gameNotAvailable') || '');
        if (message) {
            this.messageQueue.add(message);
        }
        setTimeout(() => {
            this.messageQueue.clear();
            sessionStorage.removeItem('gameNotAvailable');
        }, this.millisecondsToDisplayInterval);
    }

    gameLaunchRedirectErrorAction(errormessages: any, callback?: any) {
        if (callback) {
            const callbackResponse = {
                isError: true,
                errorMessage: errormessages[0].html?.value ? errormessages[0].html.value : errormessages[0].html,
                gameLaunchUrl: '',
            };
            callback(callbackResponse);
        } else if (errormessages && errormessages.length > 0) {
            this.messageQueue.clear();
            errormessages.forEach((msg: any) => {
                msg.html = msg.html?.value ? msg.html?.value : msg.html;
                msg.lifetime = 'Single';
                msg.scope = 'casinocorelobby';
            });
            if (errormessages) {
                this.messageQueue.addMultiple(errormessages);
            }
        }
    }

    handleDirectDesktopGameLaunch(gameId: string): void {
        if (this.user.isAuthenticated) {
            if (this.vCClientService.isPokerVC()) {
                let isNotLobby: boolean = false;
                if (this.cookies.get('fromLobby')) {
                    isNotLobby = true;
                }
                this.vCClientService.handleVCGameLaunch(gameId, isNotLobby);
                return;
            }

            const gameObject: any = {
                gameId: gameId,
            };
            this.checkGeolocationAndLaunchDesktopGame(gameObject, '');
        } else {
            this.loginDialog.open();
        }
    }

    currentTimeStamp() {
        return new Date().getTime();
    }

    addClickTimeStamp(redirectUrl: string, gameLaunchParams: any): string {
        if (gameLaunchParams && gameLaunchParams.clickTimeStamp) {
            const url = new URL(redirectUrl);
            const rurl = url.searchParams.get('rurl');
            if (url && rurl && rurl != '') {
                let appendString: string = '';
                if (gameLaunchParams.clickTimeStamp) {
                    appendString += '&clickTimeStamp=' + gameLaunchParams.clickTimeStamp;
                }
                if (gameLaunchParams.launchSource) {
                    appendString += '&launchSource=' + gameLaunchParams.launchSource;
                }
                url.searchParams.set('rurl', rurl + appendString);
                return url.toString();
            }
        }
        return redirectUrl;
    }

    validateGames(gameId: string): Observable<any> {
        return this.api.get('gamedata/validategame', { gameId: gameId }, { withCredentials: true });
    }

    closeGame(gameId: string): Observable<any> {
        return this.api.get('gamedata/closegame', { gameId: gameId }, { withCredentials: true });
    }

    playerPlayBreakStatus() {
        this.api
            .get('gamedata/GetUserPlayBreakStatus', null, { withCredentials: true })
            .toPromise()
            .then(
                (statusObj: any) => {
                    if (statusObj && (statusObj.playBreak || statusObj.gracePeriod)) {
                        window.location.reload();
                    }
                },
                (error) => {
                    // eslint-disable-next-line no-console
                    console.error('Unable to retrieve user play break status' + error);
                },
            );
    }

    stopAudio() {
        this.stopAudioSubject.next(false);
    }
    storeUserInfo(data: string) {
        this.logger.info(data);
        const gamesConfig = this.configProviderService.provideGameConfig();
        if (gamesConfig.storeUserDetails) {
            const cookieObj: any = {
                userToken: this.user.claims.get('usertoken') || '',
                sessionToken: this.user.claims.get('sessiontoken') || '',
                // gameDisplayname: data || '',
            };

            this.cookies.putObject('PCGameData', cookieObj, {
                domain: this.page.domain,
            });
        }
    }
}
