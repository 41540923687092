<div *ngIf="isLobbyLoaded">
    <cc-rp-gyml
        *ngIf="arcadeLobbyGameConfig != lobbyType?.toLowerCase()"
        id="rpGYMLSection"
        [lobbyType]="lobbyType"
        [gameContainer]="gameContainer"></cc-rp-gyml>
    <cc-arcade-rp-gyml
        *ngIf="arcadeLobbyGameConfig == lobbyType?.toLowerCase()"
        id="rpGYMLSection"
        [lobbyType]="lobbyType"
        [gameContainer]="gameContainer"></cc-arcade-rp-gyml>
</div>
