import { Compiler, Injectable, Injector, NgModuleFactory, Type, ViewContainerRef } from '@angular/core';

import { lazyMap } from '../lazy-routes';
import { LazyDynamic } from './lazy-dynamic.service';

export type ModuleWithRoot = Type<any> & { rootComponent: Type<any> };

@Injectable({
    providedIn: 'root',
})
export class LazyRouteService {
    constructor(
        private injector: Injector,
        private compiler: Compiler,
    ) {}

    async loadAndRenderComponents(data: any, container: ViewContainerRef) {
        container.clear();
        const { type, data: componentData } = data;
        let moduleMap = lazyMap[type];
        if (!moduleMap) {
            moduleMap = lazyMap['nolazypathkey'];
        }
        if (moduleMap) {
            const moduleOrFactory = await moduleMap.loadChildren();
            let moduleFactory;
            if (moduleOrFactory instanceof NgModuleFactory) {
                moduleFactory = moduleOrFactory; // AOT
            } else {
                moduleFactory = await this.compiler.compileModuleAsync(moduleOrFactory); //JIT
            }
            const moduleRef = moduleFactory.create(this.injector);
            const rootComponent = (moduleFactory.moduleType as ModuleWithRoot).rootComponent;
            const factory = moduleRef.componentFactoryResolver.resolveComponentFactory(rootComponent);
            const componentRef = container.createComponent(factory, 0, this.injector);
            (componentRef.instance as LazyDynamic).data = componentData;
        }
    }
    async loadAndRenderLazyComponents(data: any) {
        const { type } = data;
        const moduleMap = lazyMap[type];
        if (moduleMap) {
            const moduleOrFactory = await moduleMap.loadChildren();
            let moduleFactory;
            if (moduleOrFactory instanceof NgModuleFactory) {
                moduleFactory = moduleOrFactory; // AOT
            } else {
                moduleFactory = await this.compiler.compileModuleAsync(moduleOrFactory); //JIT
            }
            const moduleRef = moduleFactory.create(this.injector);
            const rootComponent = (moduleFactory.moduleType as ModuleWithRoot).rootComponent;
            const factory = moduleRef.componentFactoryResolver.resolveComponentFactory(rootComponent);
            return factory;
        }
        return '';
    }
}
