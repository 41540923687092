import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

import { IContentImage, IContentLink, IContentParameters, IContentVideo } from '../public-api';

@LazyClientConfig({ key: 'csGamePremiere', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: gamePremiereClientConfigFactory,
})
export class GamePremiereClientConfig {
    readonly enableGamePremiere: boolean;
    readonly gamePremiereOverlayConfiguration: GamePremiereOverlayConfiguration;
    readonly showOverlyByCampaignStatus: string[];
    readonly showGamePremiereByLobbyTypeCategory: any;
}
export interface GamePremiereOverlayConfiguration {
    readonly headerText: string;
    readonly closeCTA: string;
    readonly sticker: string;
    readonly desktopGameImage: IContentImage;
    readonly mobileGameImage: IContentImage;
    readonly desktopGameVideo: IContentVideo;
    readonly mobileGameVideo: IContentVideo;
    readonly enableVideo: boolean;
    readonly subTitle1: string;
    readonly subTitile2: string;
    readonly mainTitle: string;
    readonly stepsTitle: string;
    readonly step1: string;
    readonly step2: string;
    readonly step3: string;
    readonly additionalInfo: string;
    readonly optinCta: IContentLink;
    readonly playnowCta: IContentLink;
    readonly viewDetails: IContentLink;
    readonly moreInfoText: string;
    readonly lessInfoText: string;
    readonly moreInfoContent: string;
    readonly styles: IContentParameters;
}

export function gamePremiereClientConfigFactory(service: LazyClientConfigService) {
    return service.get(GamePremiereClientConfig);
}
