import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';
import { CacheManagerService } from './cache-manager.service';
import { GameLobbyModel } from './models/game-lobby.model';

@Injectable({
    providedIn: 'root',
})
export class FastLoadingGamesService {
    private fastLoadingGamesLookUp: Map<string, boolean> = new Map<string, boolean>();
    private fastLoadingGamesSubject = new BehaviorSubject<any>(null);
    fastLoadingGamesFeedObservable: Observable<any> = this.fastLoadingGamesSubject.asObservable();

    constructor(
        private api: PlatformApiService,
        private cacheManager: CacheManagerService,
        private configProviderService: ConfigProviderService,
    ) {}

    setFastLoadingGamesFromLMT(): void {
        const fastLodingGamesMap = this.cacheManager.getFastLoadingGamesFromLocalCache();
        if (!fastLodingGamesMap) {
            const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
            this.api.get(apiPathsConfig.gamesServices['fastLoadingGames']).subscribe(
                (response: string[]) => {
                    if (response && response.length > 0) {
                        response.forEach((item) => {
                            this.fastLoadingGamesLookUp.set(item, true);
                        });
                        this.cacheManager.setFastLoadingGamesLocalStorageCacheMap(this.fastLoadingGamesLookUp);
                        this.fastLoadingGamesSubject.next(this.fastLoadingGamesLookUp);
                    }
                },
                () => {},
            );
        } else {
            this.fastLoadingGamesLookUp = fastLodingGamesMap;
            this.fastLoadingGamesSubject.next(this.fastLoadingGamesLookUp);
        }
    }

    setFastLoadingGames(games: GameLobbyModel[], fastLoadingGames: Map<string, boolean>): void {
        if (fastLoadingGames && games && games.length > 0) {
            games.forEach((game: any) => {
                game.isFastLoadingGame = fastLoadingGames.get(game.gameId);
            });
        }
    }

    getFastLoadingGamesLookUp(): Map<string, boolean> {
        return this.fastLoadingGamesLookUp;
    }

    setFastLoadingGamesLocalStorageCacheMap(): void {
        const fastLoadingGamesFromCache: Map<string, boolean> = this.cacheManager.getFastLoadingGamesFromLocalCache();
        if (fastLoadingGamesFromCache) {
            this.fastLoadingGamesLookUp = fastLoadingGamesFromCache;
            this.fastLoadingGamesSubject.next(this.fastLoadingGamesLookUp);
        }
    }
}
