import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csGamingStoriesConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: gamingStoriesClientConfigFactory,
})
export class GamingStoriesClientConfig {
    localStorageExpirationDuration: number;
    trackViewedStateForPostLogin: boolean;
    trackViewedStateForPreLogin: boolean;
    showStoryViewedTickMark: boolean;
    enableOverlayMobileSearchTopPosition: boolean;
    gameLaunchSetTimeOut: number;
}

export function gamingStoriesClientConfigFactory(service: LazyClientConfigService) {
    return service.get(GamingStoriesClientConfig);
}
