import { Component, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { BingoQuickInfoModelComponent } from '@casinocore/platform/bingo-quick-info-model';
import { ConfigProviderService, CozyBingoDataService } from '@casinocore/platform/core';

@Component({
    selector: 'cc-cozybingo-quickinfo-direct',
    templateUrl: 'cozybingo-quickinfo-direct.component.html',
    standalone: true,
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
})
export class CozybingoQuickinfoDirectComponent implements OnInit, OnDestroy {
    cozybingoSubscription: any;
    private quickInfoScrollY: number;
    constructor(
        private cozyBingoDataService: CozyBingoDataService,
        private dialog: MatDialog,
        private configProviderService: ConfigProviderService,
    ) {}

    ngOnInit() {
        this.getDirectQuickInfo();
    }

    getDirectQuickInfo() {
        const cozibingoConfig = this.configProviderService.provideCozyBingoConfig();
        const global = this.configProviderService.provideGlobalConfig();
        let roomInfo: any;
        if (cozibingoConfig.cozyBingoFavouritesInRpwidget && window.location.pathname.indexOf('game-info') > -1) {
            const roomNameFromUrl = decodeURI(window.location.pathname.split('/').splice(-1)[0]).replace('-', ' ');
            this.cozybingoSubscription = this.cozyBingoDataService.cozyBingoRoomsDataObservable.subscribe((response: any) => {
                if (response) {
                    const cozybingodata = response;
                    cozybingodata.forEach((value: any) => {
                        if (value.name == roomNameFromUrl) {
                            roomInfo = value;
                        }
                    });
                    if (roomInfo) {
                        const room = this.cozyBingoDataService.getRoomdatabyId(roomInfo.id);
                        document.getElementsByTagName('html')[0].classList.add('casino-quickinfo-open');
                        const requiredModalData = {
                            room: { roomId: room.id },
                            fromCozybingo: true,
                            roomTitle: room.name,
                            roomDescription: room.sitecoreInfo.description,
                            gameProviderText: 'GAME PROVIDER',
                            providerName: 'Win Studios',
                            provider: 'https://casinogames.galaspins.com/htmllobby/images/newlmticons/square/ivylootenkhamun.jpg',
                            vendorLogo: 'https://casinogames.galaspins.com/htmllobby/images/newlmticons/vendorlogos/21.jpg',
                            cta: global.messages.UKPlayNow,
                            jackpotData: room.jackpot,
                            position: 0,
                            section: 'Page Container',
                            gameContainerLocation: 'Page Container|' + global.messages.BingoHeader + 'QuickInfo',
                        };
                        const dialogRef = this.dialog.open(BingoQuickInfoModelComponent, {
                            width: '790px',
                            data: requiredModalData,
                        });
                        dialogRef.afterClosed().subscribe(() => {
                            if (this.quickInfoScrollY) {
                                window.scrollTo(0, this.quickInfoScrollY);
                            }
                            this.hideInfo();
                        });
                    }
                }
            });
        }
    }
    hideInfo() {
        document.getElementsByTagName('html')[0].classList.remove('casino-quickinfo-open');
    }
    ngOnDestroy() {
        if (this.cozybingoSubscription) {
            this.cozybingoSubscription.unsubscribe();
        }
    }
}
