<div class="arcade-navigation-container">
    <div class="arcade-navigation">
        @for (item of arcadeNavigation; track item; let i = $index) {
            @if (item != 'HistoryButton') {
                <div class="arcade-navigation-item" (click)="itemClick(item)">
                    <label class="arcade-navigation-label" [ngClass]="item == selectedItem ? 'item-active' : ''" for="item{{ i + 1 }}">
                        {{ item }}
                    </label>
                </div>
            } @else {
                <div class="arcade-navigation-item history-btn" (click)="itemClick(item)">
                    <span
                        class="arcade-navigation-label"
                        [class.item-active]="item == selectedItem"
                        [ngClass]="item == 'HistoryButton' ? arcadeLobbyMessages?.HistoryButton : ''"></span>
                </div>
            }
        }
    </div>
</div>
