import { ModuleWithProviders, NgModule } from '@angular/core';

import { CasinoCoreLazyPlaceholderComponent } from './casino-core-lazy-placeholder.component';

@NgModule({
    imports: [CasinoCoreLazyPlaceholderComponent],
    exports: [CasinoCoreLazyPlaceholderComponent],
})
export class CasinoCoreLazyPlaceholderModule {
    public static forRoot(): ModuleWithProviders<CasinoCoreLazyPlaceholderModule> {
        return {
            ngModule: CasinoCoreLazyPlaceholderModule,
            providers: [],
        };
    }
}
