import { NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { LazyLoadImageModule, StateChange } from 'ng-lazyload-image';

import { CasinoLobbyService } from '../casino-lobby-manager/casino-lobby.service';
import { ConfigProviderService } from '../config-provider/config-provider.service';
import { IntersectionObserverDirective } from '../directives/intersection-observer/intersection-observer.directive';
import { CasinoCoreNpmParamsService } from '../shared/casino-core-npm-params.service';
import { UrlUtilityService } from '../utilities/url-utility.service';

@Component({
    selector: 'cc-image-loader',
    templateUrl: 'image-loader.component.html',
    standalone: true,
    imports: [NgIf, NgStyle, LazyLoadImageModule, IntersectionObserverDirective],
})
export class ImageLoaderComponent implements OnInit, OnChanges {
    @Input() icon: any;
    @Input() promo: any;
    @Input() forcevisible: boolean;
    @Input() format: string = 'jpg';
    @Input() gameInfo: string;
    @Input() gameTitle: string;
    @Input() iconType: number;
    @Input() alt: string;
    @Input() style: string;
    @Input() disableLazyLoading: boolean = false;

    src: string;
    isDll: boolean;
    lazySrc: string;
    defaultSrc: string;
    enableFallback: boolean;

    iconName: string;
    fallBackIconName: string;
    gameConfig: any;
    smartLobbyConfig: any;
    isImageLoaded: boolean;

    constructor(
        private urlUtilityService: UrlUtilityService,
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
        private casinoNpmParamsService: CasinoCoreNpmParamsService,
    ) {}

    private getFallbackIcon() {
        return this.icon.src.replace('/' + this.iconName + '/', '/' + this.fallBackIconName + '/');
    }

    private initialize() {
        this.isDll = this.casinoNpmParamsService.getcasinoCoreNpmParams().isDll;
        this.defaultSrc = this.urlUtilityService.getGrayImageUrl();
        if (!this.disableLazyLoading) {
            this.lazySrc = this.defaultSrc;
        }
        this.smartLobbyConfig = this.configProviderService.provideSmartLobbyConfig();
        if (!this.smartLobbyConfig.enableGameIconFallback) {
            this.src = this.icon.src;
        } else {
            this.gameConfig = this.configProviderService.provideGameConfig();
            this.iconName = this.gameConfig.iconConfigMap.find((m: any) => m.id === this.iconType?.toString())?.icon;
            this.fallBackIconName = this.gameConfig.iconConfigMap.find((m: any) => m.id === '0')?.icon;
            this.enableFallback = this.iconName !== this.fallBackIconName;
            if (this.enableFallback) {
                const isImageNotFound = this.casinoLobbyService.isGameIconNotAvailable(this.gameInfo, this.iconType);
                this.src = isImageNotFound ? this.getFallbackIcon() : this.icon.src;
            } else {
                this.src = this.icon.src;
            }
        }
        const feature = this.configProviderService.provideFeaturesConfig();
        if (feature.enableGameIconTheming) this.src = this.casinoLobbyService.appendTheme(this.src, this.gameInfo);
        if (this.disableLazyLoading) {
            const isImageNotFound = this.casinoLobbyService.isGameIconNotAvailable(this.gameInfo, this.iconType);
            if (isImageNotFound) {
                this.src = this.defaultSrc;
            } else {
                this.src = this.icon.src;
            }
        }
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges() {
        this.initialize();
    }

    onImageStateChange(event: StateChange) {
        switch (event.reason) {
            case 'loading-failed':
                this.casinoLobbyService.setGameIconNotFound(this.gameInfo, this.iconType);
                this.src = this.getFallbackIcon();
                break;
        }
    }

    onImageStateChanged(event: StateChange) {
        switch (event.reason) {
            case 'loading-succeeded':
                this.isImageLoaded = true;
                break;
        }
    }

    onGameIconError(src: any): void {
        this.defaultSrc = src;
    }

    onVisibilityChanged(elementIntersect: any) {
        try {
            const feature = this.configProviderService.provideFeaturesConfig();
            if (elementIntersect && elementIntersect.status == 'Visible') {
                if (feature.enableGameIconTheming) this.lazySrc = this.casinoLobbyService.appendTheme(this.lazySrc, this.gameInfo);
                this.lazySrc = this.src;
            }
        } catch {
            // ignored
        }
    }
}
