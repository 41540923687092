<div *ngIf="enableNewSkeletonUI" class="bg-home-skeleton sk-casino-container bg-dark-skeleton">
    <div class="sk-banner">
        <div class="sk-title"></div>
        <div class="sk-title"></div>
        <div class="sk-title"></div>
    </div>
    <div class="sk-title-wrapper">
        <div class="sk-title"></div>
    </div>
    <div class="sk-games sk-games--1">
        <div *ngFor="let number of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]" class="sk-game-card"></div>
    </div>
    <div class="sk-title-wrapper">
        <div class="sk-title"></div>
    </div>
    <div class="sk-games sk-games--2">
        <div *ngFor="let item of [2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]" [className]="'sk-game-card ' + 'sk-game-card-' + item"></div>
    </div>
</div>

<div *ngIf="!enableNewSkeletonUI" class="bg-home-skeleton sk-casino-container">
    <div class="sk-banner"></div>
    <div class="sk-title-wrapper">
        <div class="sk-title"></div>
    </div>
    <div class="sk-games">
        <!---->
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
    </div>
    <div class="sk-title-wrapper sk-title-right">
        <div class="sk-title"></div>
    </div>
    <div class="sk-games">
        <!---->
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
    </div>
    <div class="sk-title-wrapper">
        <div class="sk-title"></div>
        <div class="sk-title"></div>
    </div>
    <div class="sk-games">
        <!---->
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
        <div class="sk-game-card"></div>
    </div>
</div>
