import { Injectable } from '@angular/core';

import { ApiOptions } from '@frontend/vanilla/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api';
import { CasinoWidgetService } from './casino-widget.service';

@Injectable({
    providedIn: 'root',
})
export class CasinoApiService {
    constructor(
        private casinoWidgetService: CasinoWidgetService,
        private apiService: ApiService,
    ) {}
    request(url: string, options?: any): Observable<any> {
        return this.apiService.request(url, options);
    }

    get(url: string, params?: any, options?: ApiOptions): Observable<any> {
        const requestParams = this.casinoWidgetService.getApiParams(params);
        return this.apiService.get(url, requestParams, options);
    }

    post(url: string, data?: any, options?: any): Observable<any> {
        return this.apiService.post(url, data, options);
    }

    put(url: string, data?: any, options?: any): Observable<any> {
        return this.apiService.put(url, data, options);
    }

    delete(url: string, params?: any, options?: any): Observable<any> {
        return this.apiService.delete(url, params, options);
    }

    jsonp(url: string, callbackParam: string): Observable<Object> {
        return this.apiService.jsonp(url, callbackParam);
    }
}
