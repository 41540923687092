<div (click)="onFreeSpinClick($event)">
    <div
        class="casino-freespins-alignment theme-refresh active"
        [style.background-color]="freespinscountbg"
        [ngClass]="{ 'fsp-gt-count': isThreeDigit }"
        *ngIf="isOptedIn">
        <span class="fsp-text-info">Free<br />Spins</span>
        <span class="fsp-count-info" *ngIf="!isThreeDigit">
            <b class="active">{{ freespinAvailableCount }}</b
            >/{{ freespinTotalCount }}
        </span>
        <span class="fsp-count-info" *ngIf="isThreeDigit">
            <div>
                <b class="active">{{ freespinAvailableCount }}</b>
            </div>
            <div class="fsp-count-border"></div>
            <div>{{ freespinTotalCount }}</div>
        </span>
    </div>
    <div
        class="casino-freespins-alignment theme-refresh"
        [style.background-color]="freespinscountbg"
        [ngClass]="{ 'fsp-gt-count': isThreeDigit }"
        *ngIf="!isOptedIn">
        <span class="fsp-text-info">Free<br />Spins</span>
        <span class="fsp-count-info" *ngIf="freespinAvailableCount != 0 && freespinTotalCount != 0 && !isThreeDigit"
            >{{ freespinAvailableCount }}/{{ freespinTotalCount }}</span
        >
        <span class="fsp-count-info" *ngIf="freespinAvailableCount != 0 && freespinTotalCount != 0 && isThreeDigit">
            <div>{{ freespinAvailableCount }}</div>
            <div class="fsp-count-border"></div>
            <div>{{ freespinTotalCount }}</div>
        </span>
    </div>
</div>
