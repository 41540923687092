import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';

import { TimerService } from '@frontend/vanilla/core';

import { TimeZoneService } from '../shared/time-zone.service';

@Component({
    selector: 'cc-lc-timer',
    templateUrl: 'lc-timer.component.html',
    standalone: true,
    imports: [NgIf],
})
export class LCTimerComponent implements OnChanges, OnDestroy {
    @Input() showDays: boolean = false;
    @Input() daysDisplayText: string = '';
    @Input() showTimer: boolean = true;
    @Input() startTime: string;
    @Output() onTimerEnd: EventEmitter<boolean> = new EventEmitter<boolean>();

    timerInstance: any;
    displayTime: string;

    constructor(
        private timerService: TimerService,
        private timeZoneService: TimeZoneService,
    ) {}

    ngOnChanges() {
        this.displayTime = this.startTime;
        if (!this.timerInstance) {
            this.timerInstance = this.timerService.setIntervalOutsideAngularZone(() => {
                const seconds: number = this.timeZoneService.getSecondsFromTimeString(this.displayTime);
                if (seconds === 0) {
                    this.timerService.clearInterval(this.timerInstance);
                    this.onTimerEnd.emit(true);
                }
                this.displayTime = this.timeZoneService.formatTimeFromSeconds(seconds - 1);
            }, 1000);
        }
    }

    get time() {
        if (!this.showDays) {
            return this.displayTime;
        }

        const seconds: number = this.timeZoneService.getSecondsFromTimeString(this.displayTime);
        const days = Math.ceil(seconds / (3600 * 24));
        return days > 1 ? days - 1 + (this.daysDisplayText ? ' ' + this.daysDisplayText : '') : this.displayTime;
    }

    ngOnDestroy() {
        this.timerService.clearInterval(this.timerInstance);
    }
}
