export class AdvanceGameDataModel {
    parentcategoryId: string;
    subcategoryId: string;
    rowNumber: any;
    columnNumber: number;
    iconSize: any;
    iconTypeForTracking: string;
    browserName: string;
    browserVersion: any;
    osName: any;
    osVersion: any;
    userAgent: string;
    devicemake: any;
    featureId: any;
    constructor() {}
}
