import { LowerCasePipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
    computed,
    input,
    signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import {
    BingoGameLaunchService,
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    CustomCarouselPaginationComponent,
    DesignSystemEnablerService,
    IntersectionObserverDirective,
    LongPressDirective,
    OptimizedTeaserManager,
    SitecoreImageResizePipe,
    TeaserService,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, GameTileCtaComponent } from '@frontend/casino-ui';
import { DeviceService, Logger, NavigationService, TrackingService } from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { RxStrategyProvider } from '@rx-angular/cdk/render-strategies';
import { filter, tap } from 'rxjs';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperOptions } from 'swiper/types';

import { OptMiniTeasersComponent } from './opt-mini-teasers.component';
import { OptTeaserItemComponent } from './opt-teaser-item.component';

@Component({
    selector: 'cc-opt-teasers',
    templateUrl: 'opt-teasers.component.html',
    styles: ['.ProgressBar{background:green; height:10px}'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        NgIf,
        IntersectionObserverDirective,
        LongPressDirective,
        NgFor,
        OptTeaserItemComponent,
        MatProgressBarModule,
        OptMiniTeasersComponent,
        LowerCasePipe,
        TrustAsHtmlPipe,
        SitecoreImageResizePipe,
        SwiperComponent,
        CustomCarouselPaginationComponent,
        GameTileCtaComponent,
        DecoratorClassDirective,
    ],
})
export class OptTeasersComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() identifierClass: string;
    @Input() teaserCategory: string;
    @Input() teaserLocation: string;

    @Input() lobbyType: string;
    @Input() gameContainer: string;
    @ViewChild(SwiperComponent) swiperRef: SwiperComponent;
    @Output() optMiniTeasersEnabled: EventEmitter<any> = new EventEmitter<any>();
    teasersSource = input([], {
        transform: (source: any[]) => source.filter((m: any) => m.data.fields.iconTileCode == null),
    });
    private readonly isVideoTeaser = signal<boolean>(false);
    private readonly videoTeaserCount = computed<number>(() => {
        return this.teasersSource().filter((source) => source.isVideoUrl && source.videoUrl).length;
    });
    enablePagination: boolean = false;
    isOptMiniTeasersEnabled = false;
    hideTeasersPagination = false;
    enableAluminumTeaserSwiperHeight: boolean = false;
    optMiniTeasers: any;
    private readonly globalTeaserSettings = this.optimizedTeaserManager.getGlobalTeaserSettings();
    readonly isTouch = this.casinoManager.isTouch();
    optimizedTeaserClientConfig: any;
    optTeaser: boolean;
    arrowClass: string = '';
    miniArrowClass: string = '';
    teaserType: string = '';
    readonly displayHeight = signal<number>(0);
    paginationClass: string = '';
    teaserHeight: number;
    teaserDefaultSliderArrowBg: string = '';
    teaserDefaultSliderArrowShape: string = '';
    teaserDefaultSliderBullet: string = '';
    private showTimer = this.globalTeaserSettings?.sharedList?.showTimer?.toLowerCase() === 'true';
    teaserProgressbar: string = '';

    readonly progressbarValue = signal<number[]>([]);
    private progressBarInterval: ReturnType<typeof setInterval> | null = null;
    readonly displayProgressBar = signal<boolean>(false);
    readonly currentIndex = signal<number>(0);
    isOptTeaserPhase3: boolean;
    isMouseOverOnTeaser: boolean;
    activeSlides: any[] = [];
    enableLobbyBackgroundImage: boolean;
    swiperIndexSettimeoutValue: number;
    teaserDivIsInViewport: boolean;
    mercuryBgImage: any;
    miniTeaserGridAlignment: string = '';
    miniTeaserEnabledClass: string = '';
    bingocta: any;
    enablebingoplay: boolean;
    openBingoLobbyButtonPosition: string = '';
    enableEpcotSubNavigation: boolean;
    optTeasers: any;
    enableAluminiumTeasers: boolean = false;
    enableFixedWidthAluminiumTeasers: boolean = false;
    showPagination = false;
    aluminiumTeaserReady = false;
    normalTeaserReady = false;
    isLegacy: boolean = false;

    private swiperOptionsBase: SwiperOptions = {
        modules: [Autoplay, Pagination, Navigation],
        autoHeight: true,
        speed: 500,
        navigation: !this.isTouch,
        observer: true,
        observeParents: true,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
            waitForTransition: false,
        },
        on: {
            afterInit: (swiper) => this.handleAutoplayFromAfterInit(swiper, this.isVideoTeaser()),
            slidePrevTransitionStart: (swiper) => this.onSlidePrevTransitionStart(swiper),
            slideNextTransitionStart: (swiper) => this.onSlideNextTransitionStart(swiper),
        },
    };

    swiperOptionsOpt: SwiperOptions = {
        ...this.swiperOptionsBase,
        spaceBetween: 1,
        slidesPerView: 'auto',
        pagination: true,
        resizeObserver: true,
        observeSlideChildren: true,
        updateOnWindowResize: true,
        watchSlidesProgress: true,
        on: {
            ...this.swiperOptionsBase.on,
            // beforeInit: (swiper) => this.onSwiperBeforeInit(swiper),
            init: (swiper) => this.onSwiperInitBase(swiper),
            beforeResize: (swiper) => this.onSwiperObserverUpdate(swiper),
            observerUpdate: (swiper) => this.onSwiperObserverUpdate(swiper),
            resize: (swiper) => this.onSwiperObserverUpdate(swiper),
        },
    };

    swiperOptionsAluminium: SwiperOptions = {
        ...this.swiperOptionsBase,
        slideActiveClass: 'swiper-active',
        resizeObserver: true,
        observeSlideChildren: true,
        updateOnWindowResize: true,
        watchSlidesProgress: true,
        on: {
            ...this.swiperOptionsBase.on,
            // beforeInit: (swiper) => this.onSwiperBeforeInitAluminium(swiper),
            init: (swiper) => this.onSwiperInitBase(swiper),
            beforeResize: (swiper) => this.onSwiperObserverUpdate(swiper),
            observerUpdate: (swiper) => this.onSwiperObserverUpdate(swiper),
            resize: (swiper) => this.onSwiperObserverUpdate(swiper),
        },
    };

    constructor(
        private configProviderService: ConfigProviderService,
        private casinoLobbyService: CasinoLobbyService,
        private navigation: NavigationService,
        private tracking: TrackingService,
        private logger: Logger,
        private casinoManager: CasinoManager,
        private optimizedTeaserManager: OptimizedTeaserManager,
        private teaserService: TeaserService,
        private destroyRef: DestroyRef,
        private bingoGameLaunchService: BingoGameLaunchService,
        private deviceService: DeviceService,
        private strategy: RxStrategyProvider,
        private cdr: ChangeDetectorRef,
        private designSystemEnablerService: DesignSystemEnablerService,
    ) {
        casinoManager.lobbyObservable
            .pipe(
                filter((lobbyModel) => !!lobbyModel),
                tap(() => {
                    this.optTeaser = true;
                    setTimeout(() => {
                        this.arrowClass = this.teasersSource()?.length > 1 ? 'opt-arrows-multiple' : '';
                        this.miniArrowClass =
                            this.optimizedTeaserClientConfig.teaserOptimization &&
                            this.optimizedTeaserClientConfig.enableOptMiniTeasers &&
                            !this.isTouch &&
                            this.optMiniTeasers?.length > 1
                                ? 'opt-arrows-multiple'
                                : '';
                    }, 1000);
                }),
                takeUntilDestroyed(),
            )
            .subscribe();
    }

    ngOnChanges() {
        this.aluminiumTeaserReady = false;
        this.normalTeaserReady = false;
        this.optimizedTeaserClientConfig = this.configProviderService.provideOptimizedTeaserConfig();
        this.setOptMiniTeasers();
        this.enableAluminiumTeasers = this.optimizedTeaserClientConfig.enableAluminiumTeaser;
        this.enableFixedWidthAluminiumTeasers = this.optimizedTeaserClientConfig.enableFixedWidthAluminiumTeaser && this.teasersSource()?.length >= 4;
        if (this.validateShowTimerEnabled() && this.teasersSource().length > 1) {
            this.displayProgressBar.set(this.teaserLocation === 'Top' || this.teaserLocation === 'SubNavigation');
        } else {
            this.displayProgressBar.set(false);
        }
        const features = this.configProviderService.provideFeaturesConfig();
        this.enableLobbyBackgroundImage = features.enableLobbyBackgroundImage;
        this.swiperIndexSettimeoutValue = features.teaserSetIndexTimeout;
        if (this.globalTeaserSettings && this.globalTeaserSettings.sharedList) {
            this.teaserType = this.globalTeaserSettings.sharedList.teaserType || '';
            this.isOptTeaserPhase3 = this.teaserType.toLowerCase() === 'hydrogen' || this.teaserType.toLowerCase() === 'lithium';
            if (this.teaserType === 'lithium') {
                this.teaserType = 'lithium hydrogen';
            }
            this.teaserDefaultSliderArrowBg = this.globalTeaserSettings.sharedList.teaserDefaultSliderArrowBg || '';
            this.teaserDefaultSliderArrowShape = this.globalTeaserSettings.sharedList.teaserDefaultSliderArrowShape || '';
            this.teaserDefaultSliderBullet = this.globalTeaserSettings.sharedList.teaserDefaultSliderBullet || '';
            this.miniTeaserGridAlignment = this.globalTeaserSettings.sharedList.miniTeaserGridAlignment || '';
            this.openBingoLobbyButtonPosition = 'casino-playbingo-btn' + this.globalTeaserSettings.sharedList.openBingoLobbyButtonPosition;
        }
        if (this.enableLobbyBackgroundImage && ['Top', 'SubNavigation'].includes(this.teaserLocation)) {
            this.teaserService.indexChangeTeaserData(this.teasersSource()[0]);
        }
        this.configureSwiperRelatedSettings(this.swiperRef?.swiper, true);
        this.arrowClass = this.teasersSource()?.length > 1 ? 'opt-arrows-multiple' : '';
        if (this.isOptMiniTeasersEnabled) {
            if (this.enablePagination) {
                this.paginationClass = 'no-opt-pagination';
            } else {
                this.paginationClass = 'no-pagination';
            }
        }
        if (this.teasersSource()?.length) {
            if (this.validateShowTimerEnabled() || this.videoTeaserCount()) {
                this.teaserProgressbar = 'teaser-progress-bar';
            } else {
                this.teaserProgressbar = '';
            }
        }
    }

    ngOnInit() {
        const features = this.configProviderService.provideFeaturesConfig();
        this.enablebingoplay = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.enablePlayBingoButton);
        this.bingocta = this.configProviderService.provideMcPageConfig().playBingoButton;
        if (this.enablebingoplay) {
            this.playbingoTracking('Displayed');
        }
        this.enableEpcotSubNavigation = this.configProviderService.provideSubNavigationConfig().enableEpcotSubNavigation;
        this.enablePagination = this.globalTeaserSettings.sharedList.enablePagination?.toLowerCase() == 'true' ? true : false;
        this.enableAluminumTeaserSwiperHeight =
            this.globalTeaserSettings.sharedList.enableAluminumTeaserSwiperHeight?.toLowerCase() == 'true' ? true : false;
        this.optTeasers = this.optimizedTeaserManager.globalTeaserStyles;
        if (this.optTeasers?.css) {
            const style = document.createElement('style');
            style.textContent = this.optTeasers?.css;
            document.head.appendChild(style);
        }
        if (this.videoTeaserCount() > 0) {
            this.findActiveSlides();
        }
        this.configureSwiperRelatedSettings(this.swiperRef?.swiper, true);
        if (this.enableAluminiumTeasers) {
            this.setAluminiumTeaserHeight();
        } else {
            this.setTeaserHeight();
        }
        this.setSwiperTeaserConfig(this.swiperRef?.swiper, true);
        this.isLegacy = !this.designSystemEnablerService.isEnabled();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.swiperRef) {
                setTimeout(() => {
                    this.swiperRef.swiper?.update();
                }, this.swiperIndexSettimeoutValue);
            }
        }, 200);
    }

    private onSwiperInitBase(swiper: Swiper): void {
        this.isVideoTeaser.set(!!(this.teasersSource()[swiper.realIndex].isVideoUrl && this.teasersSource()[swiper.realIndex].videoUrl));
        this.showPagination = true;
        this.progressbarValue.set(new Array(swiper.slides.length).fill(0));
        this.startProgressBarOnLoad(swiper);
    }

    private onSwiperObserverUpdate(swiper: Swiper): void {
        this.configureSwiperRelatedSettings(swiper);
        swiper.update();
    }

    private configureSwiperRelatedSettings(swiper: Swiper, isSettingBeforeInitConfig: boolean = false): void {
        if (this.enableAluminiumTeasers) {
            this.setAluminiumTeaserHeight();
        } else {
            this.setTeaserHeight();
        }
        this.setSwiperTeaserConfig(swiper, isSettingBeforeInitConfig);
    }

    private setSwiperTeaserConfig(swiper: Swiper, isSettingBeforeInitConfig: boolean = false): void {
        let swiperConfigs = isSettingBeforeInitConfig ? this.swiperOptionsAluminium : swiper.params;
        if (this.validateShowTimerEnabled()) {
            swiperConfigs.autoplay = false;
        } else {
            swiperConfigs.autoplay = this.teasersSource()?.length > 1 ? swiperConfigs.autoplay : false;
        }
        swiperConfigs.loop = this.teasersSource()?.length > 1 ? true : false;
        if (this.enableAluminiumTeasers) {
            const windowWidth = this.deviceService.windowWidth();
            if (windowWidth <= 570) {
                swiperConfigs.slidesPerView = this.calculateSlidesPerViewBasedOnWindow(
                    windowWidth,
                    this.optimizedTeaserClientConfig.setAluminiumMobileTeaserWidth,
                    1.2,
                );
                swiperConfigs.spaceBetween = 8;
            } else if (windowWidth <= 1024) {
                swiperConfigs.slidesPerView = this.calculateSlidesPerViewBasedOnWindow(
                    windowWidth,
                    this.optimizedTeaserClientConfig.setAluminiumTabletTeaserWidth,
                    1.65,
                );
                swiperConfigs.spaceBetween = this.teasersSource()?.length > 1 ? 10 : 8;
                swiperConfigs.centeredSlides = this.teasersSource()?.length > 1 ? true : false;
            } else if (windowWidth <= 1919) {
                //fixing width to 1920 to set the teaser width properly for only fixed width Aluminium Teaser Requirement
                const offsetSlidesPerViewCalculation = windowWidth >= 1519 ? 0.02 : 0;
                swiperConfigs.slidesPerView = this.calculateSlidesPerViewBasedOnWindow(
                    windowWidth,
                    this.optimizedTeaserClientConfig.setAluminiumDesktopTeaserWidth,
                    1.65,
                    offsetSlidesPerViewCalculation,
                );
                swiperConfigs.spaceBetween = this.teasersSource()?.length > 1 ? 22 : 8;
                swiperConfigs.centeredSlides = this.teasersSource()?.length > 1 ? true : false;
            } else {
                //fixing width to 1920 to set the teaser properly for only fixed width Aluminium Teaser Requirement
                const offsetSlidesPerViewCalculation = 0.04;
                swiperConfigs.slidesPerView = this.calculateSlidesPerViewBasedOnWindow(
                    1920,
                    this.optimizedTeaserClientConfig.setAluminiumDesktopTeaserWidth,
                    1.65,
                    offsetSlidesPerViewCalculation,
                );
                swiperConfigs.spaceBetween = this.teasersSource()?.length > 1 ? 22 : 8;
                swiperConfigs.centeredSlides = this.teasersSource()?.length > 1 ? true : false;
            }
            if (isSettingBeforeInitConfig) this.aluminiumTeaserReady = true;
        } else {
            if (isSettingBeforeInitConfig) this.normalTeaserReady = true;
        }
    }

    private validateShowTimerEnabled(): boolean {
        return (
            this.showTimer &&
            !this.isOptMiniTeasersEnabled &&
            !this.enableAluminiumTeasers &&
            !this.enableFixedWidthAluminiumTeasers &&
            this.teaserLocation !== 'Embedded'
        );
    }

    //Used for windowWidth above 1024 and specific to fixed Width Aluminium Teaser requirement
    private calculateSlidesPerViewBasedOnWindow(
        currentWindowWidth: number,
        teaserWidth: number,
        defaultSlidesPerViewValue: number,
        offsetSlidesPerView: number = 0,
    ): number {
        let slidesPerView = 1;
        if (this.teasersSource()?.length < 4 || currentWindowWidth <= 0 || teaserWidth <= 0) return slidesPerView;

        if (this.enableFixedWidthAluminiumTeasers) {
            const slidesPerViewCalculation = currentWindowWidth / teaserWidth - offsetSlidesPerView;
            const slidesPerViewWidth = Math.floor(slidesPerViewCalculation * 100) / 100;
            if (slidesPerViewWidth <= this.teasersSource()?.length) {
                slidesPerView = slidesPerViewWidth;
            }
        } else {
            slidesPerView = defaultSlidesPerViewValue;
        }
        return slidesPerView;
    }

    private onSlideNextTransitionStart(swiper: Swiper): void {
        const realIndex = swiper.realIndex;
        const slidesCount = swiper.slides.length;
        const realEqualsActiveIndex = swiper.realIndex === swiper.activeIndex;
        let nextActiveIndex: number;

        if (realEqualsActiveIndex) {
            nextActiveIndex = realIndex < slidesCount - 1 ? swiper.activeIndex + 1 : 0;
        } else {
            const isLastSlide = swiper.activeIndex === slidesCount - 1 && realIndex < slidesCount - 2;
            nextActiveIndex = isLastSlide ? realIndex + 1 : swiper.activeIndex;
        }
        this.onTeaserIndexChanged(realIndex, nextActiveIndex, swiper);
    }

    private onSlidePrevTransitionStart(swiper: Swiper): void {
        let activeIndex = swiper.realIndex + 1;
        const realIndex = swiper.realIndex;
        const slidesCount = swiper.slides.length;
        const realEqualsActiveIndex = swiper.realIndex === swiper.activeIndex;

        if (realIndex < slidesCount - 1 && realEqualsActiveIndex) {
            activeIndex = swiper.activeIndex + 1;
        } else if (realIndex < slidesCount - 1 && swiper.activeIndex === 0) {
            activeIndex = swiper.realIndex + 1;
        } else if (realIndex === 0 && swiper.activeIndex === 2) {
            activeIndex = 1;
        } else if (realIndex === slidesCount - 1) {
            activeIndex = 0;
        }

        this.onTeaserIndexChanged(realIndex, activeIndex, swiper);
    }

    private setTeaserHeight(): void {
        const windowWidth = this.deviceService.windowWidth();
        if (windowWidth <= 767) {
            this.teaserHeight = this.optimizedTeaserClientConfig.mobileViewTeaserHeight;
        } else if (windowWidth <= 1024) {
            this.teaserHeight = this.optimizedTeaserClientConfig.tabletViewTeaserHeight;
        } else {
            this.teaserHeight = this.optimizedTeaserClientConfig.desktopViewTeaserHeight;
        }
        let displayHeight = this.teaserHeight;
        if (this.validateShowTimerEnabled() && this.teasersSource().length > 1) {
            displayHeight += windowWidth <= 767 ? 3 : 6;
        }
        this.displayHeight.set(displayHeight);
        this.cdr.detectChanges();
    }

    private initOptMiniTeasers(): void {
        this.mercuryBgImage = null;
        this.isOptMiniTeasersEnabled = false;
        this.optMiniTeasers = [];
        this.miniTeaserGridAlignment = '';
        this.miniTeaserEnabledClass = '';
    }

    private setOptMiniTeasers(): void {
        if (!this.optimizedTeaserClientConfig.teaserOptimization && !this.optimizedTeaserClientConfig.enableOptMiniTeasers) {
            return;
        }

        if (this.isTouch) {
            this.hideTeasersPagination = this.globalTeaserSettings.sharedList.showPaginationDots == 'false';
        } else {
            const category = this.casinoLobbyService.getCategoryInfo(this.lobbyType, this.identifierClass.toLowerCase());
            if (category?.categoryInfo?.sitecoreData?.optimisedMiniTeasers?.length) {
                this.teaserService.optMiniTeasersMapObservable
                    .pipe(
                        filter((isReady) => isReady),
                        tap(() => {
                            const path = category.categoryInfo.sitecoreData.optimisedMiniTeasers[0].path;
                            const optMiniTeasers = this.teaserService.getOptMiniTeasers(path);
                            if (this.teaserService.mercuryBgImage) {
                                this.mercuryBgImage = this.teaserService.mercuryBgImage.image;
                            }
                            this.isOptMiniTeasersEnabled = optMiniTeasers && optMiniTeasers.length > 0;
                            this.optMiniTeasersEnabled.emit(this.isOptMiniTeasersEnabled);
                            this.optMiniTeasers = optMiniTeasers;
                            if (this.showTimer && this.teasersSource().length > 1 && this.isOptMiniTeasersEnabled) {
                                this.displayProgressBar.set(false);
                                this.setTeaserHeight();
                            }
                            this.miniTeaserEnabledClass = this.isOptMiniTeasersEnabled ? 'casino-opt-mini-whole-bg' : '';
                        }),
                        takeUntilDestroyed(this.destroyRef),
                    )
                    .subscribe();
            } else {
                this.initOptMiniTeasers();
            }
        }
    }

    onTeaserHover(hovered: boolean): void {
        if (!this.isTouch && !this.isOptMiniTeasersEnabled) {
            this.isMouseOverOnTeaser = hovered;
        }
    }

    onTeaserLongPress(longPressed: boolean): void {
        if (this.isTouch && !this.isOptMiniTeasersEnabled) {
            this.isMouseOverOnTeaser = longPressed;
        }
    }

    private startProgressBar(): void {
        this.progressBarInterval = setInterval(() => {
            this.incrementProgressBar();
        }, 25);
    }

    private incrementProgressBar(): void {
        if (!this.isMouseOverOnTeaser) {
            if (this.progressbarValue()[this.currentIndex()] < 100) {
                this.progressbarValue.update((value) => {
                    value[this.currentIndex()] = value[this.currentIndex()] + 0.5;
                    return value;
                });
            } else {
                this.onProgressBarEnd();
                if (!this.isVideoTeaser()) {
                    this.strategy
                        .schedule(() => {
                            this.slideNext(300);
                        })
                        .subscribe();
                }
            }
        }
    }

    slideNext(time: number): void {
        if (this.swiperRef?.swiper) {
            this.swiperRef.swiper.animating = false;
            this.swiperRef.swiper.slideNext(time);
        }
    }

    onProgressBarEnd(): void {
        if (this.progressBarInterval) {
            clearInterval(this.progressBarInterval);
            this.progressBarInterval = null;
        }
    }

    private findActiveSlides(): void {
        this.activeSlides = this.teasersSource().reduce((acc: any, curr: any, i: number) => {
            if (curr.isVideoUrl) {
                acc[i] = [i + 1];
            }
            return acc;
        }, {});
        if (this.teasersSource().length > 1 && Object.keys(this.activeSlides).length > 0) {
            if (this.activeSlides[0]) this.activeSlides[0]?.splice(0, 0, this.teasersSource().length + 1);
            if (this.activeSlides[this.teasersSource().length - 1]) this.activeSlides[this.teasersSource().length - 1]?.push(0);
        }
    }

    private handleAutoplay(swiper: Swiper, isVideoTeaser: boolean) {
        if (isVideoTeaser && swiper?.autoplay?.running) {
            swiper.autoplay.stop();
        }
        if (!isVideoTeaser && !swiper?.autoplay?.running && !this.validateShowTimerEnabled()) {
            swiper.autoplay.start();
        }
    }

    private handleAutoplayFromAfterInit(swiper: Swiper, isVideoTeaser: boolean) {
        setTimeout(() => {
            this.handleAutoplay(swiper, isVideoTeaser);
        }, 2000);
    }

    private onTeaserIndexChanged(realIndex: number, activeIndex: number, swiper: Swiper): void {
        this.isVideoTeaser.set(!!(this.teasersSource()[swiper.realIndex].isVideoUrl && this.teasersSource()[swiper.realIndex].videoUrl));
        this.onProgressBarEnd();
        if (this.enableLobbyBackgroundImage && ['Top', 'SubNavigation'].includes(this.teaserLocation) && this.teaserDivIsInViewport) {
            this.teaserService.indexChangeTeaserData(this.teasersSource()[swiper.realIndex]);
        }
        if (this.displayProgressBar()) {
            this.progressbarValue.update((value) => {
                value[this.currentIndex()] = 0;
                return value;
            });
        }
        this.currentIndex.set(swiper.realIndex);
        if (this.isOptTeaserPhase3) this.setTeaserVideo(realIndex, activeIndex);
        this.progressBarRunning(swiper);
        this.handleAutoplay(swiper, this.isVideoTeaser());
    }

    private setTeaserVideo(realIndex: number, activeIndex: number): void {
        const json = {
            identifierClass: this.identifierClass,
            realIndex: realIndex,
            activeIndex: activeIndex,
            isVideoTeaser: this.isVideoTeaser(),
        };
        this.teaserService.setIndex(json);
    }

    getVideoEndEvent(): void {
        if (!this.isMouseOverOnTeaser && this.isVideoTeaser() && this.progressbarValue()[this.currentIndex()] >= 100) {
            this.strategy
                .schedule(() => {
                    this.slideNext(300);
                })
                .subscribe();
        }
    }

    videoError(): void {
        if (this.isVideoTeaser()) {
            this.strategy
                .schedule(() => {
                    this.displayProgressBar.set(false);
                    this.slideNext(300);
                })
                .subscribe();
        }
    }

    getTimeChangeEvent(event: number): void {
        if (!this.isMouseOverOnTeaser && this.isVideoTeaser()) {
            this.progressbarValue.update((value) => {
                value[this.currentIndex()] = event;
                return value;
            });
            if (this.progressbarValue()[this.currentIndex()] >= 100) {
                this.onProgressBarEnd();
            }
        }
    }

    onVisibilityChanged(teaserDivElementIntersect: any): void {
        if (teaserDivElementIntersect && teaserDivElementIntersect.status == 'Visible') {
            this.teaserDivIsInViewport = true;
            if (this.enableLobbyBackgroundImage && (this.teaserLocation === 'Top' || this.teaserLocation === 'SubNavigation')) {
                this.teaserService.indexChangeTeaserData(this.teasersSource()[this.swiperRef?.swiper?.realIndex]);
            }
        } else {
            this.teaserDivIsInViewport = false;
        }
    }

    private setAluminiumTeaserHeight(): void {
        const windowWidth = this.deviceService.windowWidth();
        if (this.enableFixedWidthAluminiumTeasers) {
            if (windowWidth <= 570) {
                this.teaserHeight = this.optimizedTeaserClientConfig.setAluminiumMobileTeaserHeight;
            } else if (windowWidth <= 1024) {
                this.teaserHeight = this.optimizedTeaserClientConfig.setAluminiumTabletTeaserHeight;
            } else {
                this.teaserHeight = this.optimizedTeaserClientConfig.setAluminiumDesktopTeaserHeight;
            }
        } else {
            if (windowWidth <= 767) {
                this.teaserHeight = this.optimizedTeaserClientConfig.setAluminiumMobileTeaserHeight;
            } else if (windowWidth <= 1024) {
                this.teaserHeight = this.optimizedTeaserClientConfig.setAluminiumTabletTeaserHeight;
            } else {
                this.teaserHeight = this.optimizedTeaserClientConfig.setAluminiumDesktopTeaserHeight;
            }
        }
        this.displayHeight.set(this.teaserHeight);
        this.cdr.detectChanges();
    }

    playbingoClick(playlink: any): void {
        this.playbingoTracking('Clicked');
        if (!playlink.htmlAttributes.gameId) {
            this.navigation.goTo(playlink.url);
        } else {
            const gameObj: any = {
                section: 'Teaser',
                gameContainerLocation: 'Teaser|Top Teaser',
                position: 0,
                isBingoVf: playlink.htmlAttributes.gameType == 'Bingovf' || false,
            };
            this.bingoGameLaunchService.initiateGameLaunch(
                playlink.htmlAttributes.gameName,
                playlink.htmlAttributes.gameType,
                playlink.htmlAttributes.ballType,
                playlink.htmlAttributes.launchType,
                playlink.htmlAttributes.gameIcon,
                playlink.htmlAttributes.gameId,
                playlink.htmlAttributes.favstatus,
                playlink?.link?.url,
                gameObj,
            );
        }
    }

    private playbingoTracking(buttonActionEvent: string): void {
        this.tracking.triggerEvent('Event.Clicks', {
            ' component.CategoryEvent': 'Play Bingo Button',
            'component.LabelEvent': 'Play Bingo Now',
            'component.ActionEvent': 'Play Bingo Button - ' + buttonActionEvent,
            'component.PositionEvent': this.deviceService.windowWidth() < 480 ? 'Below the Banner' : 'on the  Banner',
            'component.LocationEvent': 'Casino Lobby',
            'component.EventDetails': 'CTA to Launch Bingo',
            'component.URLClicked': this.bingocta.url,
        });
    }

    private progressBarRunning(swiper: Swiper): void {
        try {
            if (!this.isVideoTeaser() && !swiper?.autoplay?.running && this.displayProgressBar()) {
                this.startProgressBar();
            }
        } catch (err) {
            this.logger.error('Exception in onTeaserIndexChanged function of cc-optimized-teasers component: ' + err);
        }
    }

    private startProgressBarOnLoad(swiper: Swiper): void {
        try {
            if (this.displayProgressBar() && !this.isVideoTeaser()) {
                this.startProgressBar();
                if (swiper?.autoplay?.running) swiper?.autoplay.stop();
            }
        } catch (err) {
            this.logger.error('Exception in onTeaserIndexChanged function of cc-optimized-teasers component: ' + err);
        }
    }
}
