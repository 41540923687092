import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csSmartLobbyConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: smartLobbyClientConfigFactory,
})
export class SmartLobbyClientConfig {
    enableGameIconFallback: boolean;
}

export function smartLobbyClientConfigFactory(service: LazyClientConfigService) {
    return service.get(SmartLobbyClientConfig);
}
