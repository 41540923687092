<div class="casino-with-out-subnavigation" *ngIf="(!isTouch || desktopbreakpointforMobile) && !enableSubNav && displayStaticThumbnail">
    <div class="thumbnail-newgame">
        <cc-thumbnail-newgame
            [category]="currentCategory"
            [lobbyType]="lobbyType"
            [place]="'Product Switcher'"
            [isTouch]="isTouch"
            (closeThumbnail)="closeThumbnailBox($event)"></cc-thumbnail-newgame>
    </div>
</div>

<div class="casino-mobile-thumbnail-wrapper with" [ngStyle]="{ top: thumnailHeaderHeight + 'px' }">
    <div class="casino-thumbnail-mobile thumbnailAnimate" *ngIf="isTouch && isMobileWidth() && !enableSubNav && displayStaticThumbnail">
        <cc-thumbnail-newgame
            [category]="currentCategory"
            [isTouch]="isTouch"
            [lobbyType]="lobbyType"
            [place]="'Product Switcher'"
            (closeThumbnail)="closeThumbnailBox($event)"></cc-thumbnail-newgame>
    </div>
</div>
