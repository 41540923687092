import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    CasinoCoreGamelaunchService,
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    FastLoadingGamesService,
    GameDataManager,
    GameLaunch,
    GameLobbyModel,
    GlobalTranslateComponent,
    HighlightSearch,
    SearchSectionService,
} from '@casinocore/platform/core';
import { DeviceService, TrackingService, UserService, WindowRef } from '@frontend/vanilla/core';
import { DslPipe } from '@frontend/vanilla/shared/browser';
import { Subscription } from 'rxjs';

import { CasinoMenuActionsBootstrapService } from '../casino-menu/casino-menu-actions-bootstrap.service';
import { GameLobbyComponent } from '../gamelobby/gamelobby.component';

@Component({
    selector: 'cc-search',
    templateUrl: 'search.component.html',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        FormsModule,
        NgIf,
        NgClass,
        NgFor,
        GameLobbyComponent,
        GlobalTranslateComponent,
        AsyncPipe,
        DslPipe,
        HighlightSearch,
    ],
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren('searchInput') vc: any;
    @Input() lobbyType: any;
    show: boolean;
    isCancelVisible: boolean = true;
    loadNewSet: boolean = true;
    model: any = {
        query: '',
        results: [],
    };
    isMsgVisible: boolean = false;
    isEmbeddedVisible: boolean;
    readonly innerWidth = this.deviceService.windowWidth;
    event: KeyboardEvent;
    showGYML: boolean;
    isTouch: boolean;
    gymlText: string;
    GYMLGames: any = [];
    gymlCategory: any;
    SearchCloseIcon: boolean;
    SearchProductForMobile: any;
    Global: any;
    features: any;
    casinoConfiguration: any;
    fastLoadingObs: Subscription;

    constructor(
        private windowRef: WindowRef,
        private searchSection: SearchSectionService,
        private gameDataManager: GameDataManager,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private fastLoadingGamesService: FastLoadingGamesService,
        private casinoMenuActionsBootstrapService: CasinoMenuActionsBootstrapService,
        private casinoManager: CasinoManager,
        private tracking: TrackingService,
        private configProviderService: ConfigProviderService,
        private casinoLobbyService: CasinoLobbyService,
        private user: UserService,
        private deviceService: DeviceService,
    ) {}

    ngOnInit() {
        this.Global = this.configProviderService.provideGlobalConfig();
        this.features = this.configProviderService.provideFeaturesConfig();
        this.casinoConfiguration = this.configProviderService.provideCasinoConfig();
        this.gymlText = this.Global.messages['SearchGamesYouMightLike']
            ? this.Global.messages['SearchGamesYouMightLike']
            : this.Global.messages['GamesYouMightLike'];
        this.searchSection.searchVisibilityStatus.subscribe((isVisible) => {
            this.setSearchLayerVisibility(isVisible);
        });
        this.SearchCloseIcon = false;
        this.showGYML = this.user.isAuthenticated ? true : false;
        this.isTouch = this.casinoManager.isTouch();
        if (this.features.enableEnhancedSearch) {
            this.setGYML();
            if (!this.gymlCategory) {
                this.gymlCategory = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
                this.fastLoadingObs = this.fastLoadingGamesService.fastLoadingGamesFeedObservable.subscribe((fastLoadingGames: any) => {
                    this.fastLoadingGamesService.setFastLoadingGames(this.GYMLGames, fastLoadingGames);
                });
            }
        }
        this.SearchProductForMobile = this.Global.messages.SearchProductForMobile;
    }

    ngOnDestroy() {
        if (this.fastLoadingObs) {
            this.fastLoadingObs.unsubscribe();
        }
    }

    trackInput() {
        this.tracking.triggerEvent('GameSearch', {
            'eventAction': 'Search',
            'page.search': this.model.query,
        });
    }
    setGYML() {
        this.gymlCategory = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
        if (
            this.gymlCategory &&
            this.gymlCategory.categoryInfo &&
            this.gymlCategory.categoryInfo.gamelist &&
            this.gymlCategory.categoryInfo.gamelist.length > 0
        ) {
            this.gymlCategory.categoryInfo.gamesModel = this.gymlCategory.categoryInfo.gamelist.slice(0, this.casinoConfiguration.gymlLimitForSearch);
            const gymlGameIds: string[] = [];
            this.gymlCategory.categoryInfo.gamesModel.forEach((gameModel: any) => {
                gymlGameIds.push(gameModel.game);
            });
            const gamesModel: any = this.gameDataManager.setDefaultGameIcons(gymlGameIds);
            this.getGyMLGames(gamesModel);
        }
    }
    ngAfterViewInit() {
        this.vc.first.nativeElement.focus();
        const searchTempInterval = setInterval(() => {
            const serachTxtEl = document.getElementById('serachTxt');
            if (serachTxtEl) {
                serachTxtEl?.focus();
                clearInterval(searchTempInterval);
            }
        }, 1000);
        if (this.features.enableOverlayMobileSearchTopPosition && this.deviceService.isTouch) {
            this.searchOverlayTopPosition();
        }
    }
    onEvent(event: KeyboardEvent): void {
        this.event = event;
    }

    setSearchLayerVisibility(isVisible: boolean) {
        this.showGYML = this.user.isAuthenticated ? isVisible : false;
        this.show = isVisible;
        if (this.show && this.innerWidth() < 1365) {
            this.windowRef.nativeWindow.document.body.classList.add('casino-hidescroll');
        } else {
            this.windowRef.nativeWindow.document.body.classList.remove('casino-hidescroll');
        }
    }

    hideSearch() {
        this.setSearchLayerVisibility(false);
        this.model.query = [];
        this.model.results = [];
        this.isMsgVisible = false;
        this.searchSection.setSearchVisibility(false);
        this.casinoMenuActionsBootstrapService.setSearchOverlayVisibility(false);
    }

    launchGame(game: any, index: number) {
        this.setSearchLayerVisibility(false);
        this.searchSection.setSearchVisibility(false);
        game.lobbyType = this.lobbyType;
        game.iconType = 1; // for advance game data model
        const gameModel = new GameLaunch();
        gameModel.game = game;
        gameModel.isTouch = this.isTouch;
        gameModel.gamePosition = index;
        gameModel.categoryType = 'Search';
        gameModel.from = 's';
        gameModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
        this.gameLaunchService.launchGame(gameModel);
    }
    hideDisplayGYML(): boolean {
        if (!this.GYMLGames) {
            return false;
        }
        if (this.GYMLGames.length <= 0) {
            return false;
        }
        return this.showGYML;
    }
    closeSearchIcon() {
        this.model.query = '';
        if (this.model.query.length == 0) {
            this.SearchCloseIcon = false;
        }
    }
    queryChanged() {
        const features = this.configProviderService.provideFeaturesConfig();
        this.loadNewSet = true;
        if (this.model.query.length === 0) {
            this.showGYML = this.user.isAuthenticated ? true : false;
            this.SearchCloseIcon = false;
        } else {
            this.showGYML = false;
            this.SearchCloseIcon = true;
        }
        setTimeout(() => {
            if (this.model.query.length < 1) {
                this.model.results = [];
                return;
            }
            this.model.results = this.casinoLobbyService.searchGames(this.model.query);
            if (features.enableGameIconTheming) {
                this.model.results.map((ele: any) => {
                    ele.icon.src = this.casinoLobbyService.appendTheme(ele.icon.src, ele.gameId);
                });
            }
            this.isMsgVisible = this.model.results.length > 0 ? false : true;
        }, 500);
        this.isMsgVisible = false;
    }

    getGyMLGames(gamesModelList: any[]) {
        this.GYMLGames = [];
        for (let i = 0; i < gamesModelList.length; i++) {
            const gameModelItem: any = gamesModelList[i];
            const gamelobbyModel: GameLobbyModel = this.gameDataManager.setGameLobbyModel(gameModelItem, this.isTouch);
            this.GYMLGames.push(gamelobbyModel);
        }
    }
    searchOverlayTopPosition() {
        if (document.querySelector('.content-messages-header-top .content-message-container')) {
            const contentMessageElement: HTMLElement = document.querySelector('.content-messages-header-top .content-message-container')!;
            const casinosearchEl: HTMLElement = document.querySelector('.casino-lobby-container-main .casino-search')!;
            const search_olay: HTMLElement = document.querySelector('.casino-lobby-container-main .casino-search .overlay')!;
            casinosearchEl.style.top = contentMessageElement.clientHeight + 'px';
            search_olay.style.top = contentMessageElement.clientHeight + 'px';
        }
    }
}
