import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    signal,
} from '@angular/core';

import {
    CasinoCoreGamelaunchService,
    CasinoCoreNpmParamsService,
    CasinoLobbyService,
    CasinoManager,
    CasinoWidgetService,
    ConfigProviderService,
    FastLoadingGamesService,
    FavouriteService,
    FreespinSummaryService,
    GameDataManager,
    GameLaunch,
    GameLobbyModel,
    GlobalTranslateComponent,
    ImageLoaderComponent,
    JackpotDataService,
    LiveCasinoService,
    OpenLoginDialog,
    QuickInfoModalService,
    Race,
    RecentGamesService,
    SlotRacesService,
} from '@casinocore/platform/core';
import { CookieService, DeviceService, Logger, TimerService, TrackingService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { RxStrategyProvider } from '@rx-angular/cdk/render-strategies';
import { DestroyableDirective, InViewportAction, InViewportDirective } from 'ng-in-viewport';
import { Subscription, filter } from 'rxjs';

import { CasinoCoreLazyPlaceholderComponent } from '../casino-core-lazy-placeholder/casino-core-lazy-placeholder.component';
import { FreeSpinsSummaryComponent } from '../freespins-summary/freespins-summary.component';
import { GameQuickInfoComponent } from '../game-quick-info/game-quick-info.component';
import { LibMockService } from '../services/lib-mock.service';
import { QuickInfoService } from '../services/quick-info.service';

@Component({
    selector: 'cc-game-lobby',
    templateUrl: 'gamelobby.component.html',
    standalone: true,
    imports: [
        DestroyableDirective,
        InViewportDirective,
        NgClass,
        NgStyle,
        NgIf,
        FreeSpinsSummaryComponent,
        GameQuickInfoComponent,
        CasinoCoreLazyPlaceholderComponent,
        ImageLoaderComponent,
        GlobalTranslateComponent,
        NgFor,
    ],
})
export class GameLobbyComponent implements OnInit, AfterViewInit, DoCheck, OnChanges, OnDestroy {
    @Input() category: any;

    @Input()
    set game(value: GameLobbyModel) {
        this._game = value;
        // this.isFavouriteGame = this.favouriteService.checkForFavouriteGame(value);
    }

    get game(): GameLobbyModel {
        return this._game;
    }

    private _game: GameLobbyModel;

    // isFavouriteGame: boolean;

    @Input() radius: number;
    @Input() gamePosition: number;
    @Input() gameLaunchedFrom: string;
    @Input() gameModel: any;
    @Input() product: string = '';
    @Input() widgetFrameClr: string = '';
    @Input() gameIndex: any;
    @Input() lobbyType: string;

    unfinishedStyle: any;
    readonly isTouch = this.casinoManager.isTouch();
    useFavouriteGames: boolean;
    unfinishedBG: string;
    mouseHoveredOnGame: boolean = false;
    QuickInfoVisible: string = '';
    showJackpotOnGameTiles: any;
    jackpotHandle: any;
    showGameTitle: boolean;
    stickers: any = [];
    readonly liveGameInfo = signal<any>(undefined);
    icon: any = { src: '' };
    quickInfoIcon: any = { src: '' };
    useQuickInfo: boolean;
    onMouseHover: boolean;
    onMouseLeave: boolean;
    onLivecasinoMouseHover: boolean;
    onLivecasinoMouseLeave: boolean;
    favouriteStyle: string = 'theme-favorite-i';
    unFavouriteStyle: string = 'theme-favorite';
    playNowHoverEffect: boolean = false;
    gameTitle: string;
    quickInfoScrollY: number = 0;
    enableTickingForJackpotOnGameTiles: boolean;
    newJackpotRibbonStyling: boolean;
    isStickersForUk: boolean;
    hotIcon: any;
    epcotHotIcon: any;
    enableHotIcon: boolean;
    isAnchorTagVisible: boolean;
    enableUkMouseOverStyle: boolean;
    currentWidth: any;
    currentLobbyType: string;
    isLiveGame: boolean;
    liveCasinoApiSubscriber: any;
    enableQuickInfoforLiveCasinoApi: boolean;
    enableFavouriteGamesforLiveCasinoApi: boolean;
    isLiveCasinoEnabled: boolean;
    isTableOnline: boolean;
    widgetRecommendedTxtClr: string;
    widgetRecommendedBgClr: string;
    widgetRecommended: string;
    isUnfinishedGame: boolean;
    moreInfo: string;
    Favourite: boolean;
    recentObs: any;
    optedIn: any;
    availableCount: any = 0;
    totalCount: any = 0;
    freespinCountGame: any;
    freespinObservable: any;
    showFreeSpinCount: boolean = false;
    enableFreeSpinSummary: boolean;
    demoEnabled: boolean;
    isSignPosting: boolean;
    stickerMap: any;
    promotedGameBackgroundColor: string;
    promotedGameTextColor: string;
    promotedGameText: string;
    gameContainerLocation: string;
    races: Race[] = [];
    isStickerEnabled: boolean;
    slotRacesStickerTimeOut: any;
    slotRacesStickerDataSubScription: Subscription;
    slotRacesConfig: boolean;
    globalConfig: any;
    videoUrl: string = '';
    gameVideoFormat: string = '';
    isVideoPreviewEnabled: boolean = false;
    positiveVideoIconType = '10';
    negativeVideoIconType = '-10';
    afterLoaded: boolean;
    enableEpcotSubNavigation: boolean;
    readonly isMobile = this.deviceService.isMobile;
    readonly isTablet = this.deviceService.isTablet;
    playVideoFromStart: any;
    setVideoPlayTimeoutint: any;
    isNewQuickInfoEnabled: boolean;
    displayRecenetWinners: boolean;
    recommendedGamesBackgroundColor: string;
    recommendedGamesFontColours: string;
    stickerKey: any = [];
    stickerStyles: any;
    enableStickerStyles: boolean;

    isVideoLoaded = false;
    isGameHover = false;
    isInViewport = false;
    gameVideoUrl: string;

    jpRibbonStyles: { jpBgColor: string; jpTextColor: string };

    constructor(
        private jackpotDataService: JackpotDataService,
        private user: UserService,
        private elementRef: ElementRef<HTMLElement>,
        private openLoginDialog: OpenLoginDialog,
        private favouriteService: FavouriteService,
        private cookie: CookieService,
        private gameDataManager: GameDataManager,
        private casinoManager: CasinoManager,
        private recentGamesService: RecentGamesService,
        private liveCasinoService: LiveCasinoService,
        private tracking: TrackingService,
        private casinoWidgetService: CasinoWidgetService,
        private freespinSummaryService: FreespinSummaryService,
        private configProviderService: ConfigProviderService,
        private fastLoadingGamesService: FastLoadingGamesService,
        private casinoLobbyService: CasinoLobbyService,
        private deviceService: DeviceService,
        private casinoCoreGameLaunchService: CasinoCoreGamelaunchService,
        private slotRacesService: SlotRacesService,
        private timerService: TimerService,
        private casinoNpmParamsService: CasinoCoreNpmParamsService,
        private quickInfoService: QuickInfoService,
        private libMockService: LibMockService,
        private quickInfoModalService: QuickInfoModalService,
        private logger: Logger,
        private changeDetection: ChangeDetectorRef,
        private ngZone: NgZone,
        private strategy: RxStrategyProvider,
    ) {
        this.libMockService.setQuickInfoService(this.quickInfoService);
        this.user.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            if (this.user.isAuthenticated && (this.isMobile || this.isTablet)) {
                localStorage.removeItem('isVideoEnded');
                localStorage.setItem('isVideoEnded', 'first');
                localStorage.removeItem('isVideoRunning');
            }
        });
    }

    ngDoCheck() {
        if (this.afterLoaded) {
            if (
                this.isVideoPreviewEnabled &&
                (this._game.iconType === this.positiveVideoIconType || this._game.iconType === this.negativeVideoIconType) &&
                (this.isMobile || this.isTablet) &&
                (localStorage.getItem('isVideoEnded') === 'first' ||
                    (localStorage.getItem('isVideoEnded') &&
                        JSON.parse(localStorage.getItem('isVideoEnded') || '').indexOf(this._game.gameId + this.category.categoryid) === -1)) &&
                !localStorage.getItem('isVideoRunning') &&
                this.isVideoLoaded
            ) {
                if (this.isInViewport) {
                    localStorage.setItem('isVideoRunning', this.category.categoryid + this._game.gameId);
                    this.logger.info('docheck', this._game.gameId);
                    const video = this.elementRef.nativeElement?.querySelector('video');

                    if (video) {
                        video.style.backgroundImage = 'url(' + this._game?.icon?.src + ')';
                    }
                    setTimeout(() => {
                        if (video) {
                            if (this.playVideoFromStart) {
                                video.currentTime = 0;
                                video.play();
                                this.isGameHover = true;
                            } else {
                                video.play();
                                this.isGameHover = true;
                            }
                        }
                    }, this.setVideoPlayTimeoutint);
                    this.tracking.triggerEvent('Event.Tracking', {
                        'component.CategoryEvent': 'games',
                        'component.LabelEvent': 'Video Preview Game Tile',
                        'component.ActionEvent': 'autoplay',
                        'component.PositionEvent': this.gamePosition,
                        'component.LocationEvent': this.category.categoryname + '-' + this._game.name,
                        'component.EventDetails': 'game video preview play',
                        'component.URLClicked': 'not applicable',
                    });
                }
            } else if (
                (this._game.iconType === this.positiveVideoIconType || this._game.iconType === this.negativeVideoIconType) &&
                (this.isMobile || this.isTablet) &&
                this.isVideoPreviewEnabled &&
                this.isGameHover &&
                this.isVideoLoaded &&
                localStorage.getItem('isVideoRunning')
            ) {
                if (!this.isInViewport) {
                    this.elementRef.nativeElement.querySelector('video')?.pause();
                    this.videoEnded();
                }
            }
        }
    }

    ngOnInit(): void {
        this.displayRecenetWinners = this.category?.sitecoreData?.displayRecentWinnersWidget;
        if (this.isMobile || this.isTablet) {
            localStorage.setItem('isVideoEnded', 'first');
        }
        this.currentLobbyType = this._game.lobbyType;
        this.assignFeatures();
        const featureConfig = this.configProviderService.provideFeaturesConfig();
        if (featureConfig.gameVideo && Boolean(JSON.parse(featureConfig?.gameVideo?.enabled?.toString()?.toLowerCase()))) {
            // some cases flag returning from dynacon as False
            this.gameVideoFormat = featureConfig.gameVideo.videoFormat;
            this.isVideoPreviewEnabled = true;
        }

        //TODO:liveCasinoApiConfig
        const liveCasinoConfig = this.configProviderService.provideLiveCasinoConfig();
        this.isLiveCasinoEnabled = this.casinoManager.IsFeatureEnabledforLobbytype(this._game.lobbyType, liveCasinoConfig.enableLobbyType);

        this.setStickers();
        this.checkforLatestStickers(this.recentGamesService.getrecentGames());
        if (this.isSignPosting && this._game.sticker && this._game.sticker.indexOf('LMS_LATEST') > -1) {
            this.recentObs = this.recentGamesService.recentGamesFeedObservable.subscribe((response) => {
                if (response) {
                    this.checkforLatestStickers(response);
                }
            });
        }
        this.setGameIconSrc();
        this.setJackPotValue();
        this.subscribtToJackpotFeed();

        //TODO: liveCasinoApiConfig
        this.enableQuickInfoforLiveCasinoApi = liveCasinoConfig.enableQuickInfo;
        this.enableFavouriteGamesforLiveCasinoApi = liveCasinoConfig.enableFavouriteGames;
        this.liveCasinoApiSubscriber = this.liveCasinoService.liveGamesFeedObservable.subscribe((response) => {
            if (response && response.size >= 0) {
                this.setLiveGameInfo();
            }
        });
        this.setLiveGameInfo();
        this.enableFreeSpinSummary = this.configProviderService.providefreeSpinsClientConfig().isFreeSpinSummaryEnabled;
        if (this.enableFreeSpinSummary) {
            this.freespinObservable = this.freespinSummaryService.freeSpinsGameDetailsObser.subscribe((freespinsdata: any) => {
                if (freespinsdata) {
                    this.bindFreeSpinInfo();
                }
            });
        }

        this.fastLoadingGamesService.fastLoadingGamesFeedObservable.subscribe((fastLoadingGames: Map<string, boolean>) => {
            if (fastLoadingGames && fastLoadingGames.size > 0) {
                this.setFastLoading(fastLoadingGames);
            }
        });
        this.setGameTitle();
        this.gameContainerLocation = 'Widget|' + this.product;
        if (this.slotRacesConfig) {
            this.setSlotRaceStickers();
        }
        this.gameVideoUrl = this._game?.icon?.src?.substr(0, this._game?.icon?.src?.lastIndexOf('.')) + this.gameVideoFormat;
    }

    setFastLoading(fastLoadingGames: Map<string, boolean>) {
        if (fastLoadingGames && fastLoadingGames.size > 0) {
            for (const val of fastLoadingGames) {
                if (val[0] == this._game.gameId) {
                    this._game.isFastLoadingGame = true;
                    break;
                }
            }
        }
    }

    assignFeatures() {
        const features: any = this.configProviderService.provideFeaturesConfig();
        const subnavConfig: any = this.configProviderService.provideSubNavigationConfig();
        this.enableEpcotSubNavigation = subnavConfig.enableEpcotSubNavigation;
        this.useFavouriteGames = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.favouriteGamesLobbyType);
        this.showGameTitle = features.showGameTitle;
        this.isStickersForUk = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.stickersForUk);
        this.playNowHoverEffect = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.playNowHoverEffect);
        this.useQuickInfo = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.enableQuickInfoLobbyType);
        this.isNewQuickInfoEnabled = this.casinoManager.IsFeatureEnabledforLobbytypeWithInvokerProduct(
            this.currentLobbyType,
            features.enableNewQuickInfoLobbyType,
        );
        this.isAnchorTagVisible = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.isAnchorTagVisibleOnQuickInfo);
        this.newJackpotRibbonStyling = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.newJackpotRibbonStyling);
        this.enableUkMouseOverStyle = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.enableUkMouseOverStyle);
        this.enableHotIcon = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.enableHotIcon);
        this.isSignPosting = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.enableSignpostingSticker);
        const gameConfig: any = this.configProviderService.provideGameConfig();
        this.hotIcon = gameConfig.host + features.hotIconPath.hotIcon;
        this.epcotHotIcon = gameConfig.host + features.hotIconPath.epcotHotIcon;
        const jackpotData = this.configProviderService.provideJackpotConfig();
        this.showJackpotOnGameTiles = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.currentLobbyType,
            jackpotData.showJackpotOnGameTilesLobbyType,
        );
        this.enableTickingForJackpotOnGameTiles = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.currentLobbyType,
            jackpotData.enableJpTickingForLobbyType,
        );
        this.globalConfig = this.configProviderService.provideGlobalConfig();
        this.widgetRecommended = this.globalConfig.messages['WidgetRecommended'];
        this.widgetRecommendedTxtClr = this.globalConfig.messages['widgetRecommendedTxtclr'];
        this.widgetRecommendedBgClr = this.globalConfig.messages['widgetRecommendedBGclr'];
        this.promotedGameText = this.globalConfig.lobbyConfigurations['PromotedGamesText'];
        this.promotedGameBackgroundColor = this.globalConfig.lobbyConfigurations['promotedGamesFrameColor'];
        this.promotedGameTextColor = this.globalConfig.lobbyConfigurations['promotedGamesTextColor'];
        this.recommendedGamesBackgroundColor = this.globalConfig?.messages['RecommendedGamesBackground'];
        this.recommendedGamesFontColours = this.globalConfig?.messages['RecommendedGamesFontColour'];
        this.unfinishedBG = this.globalConfig.unfinishedStyle.unfinishedBackground
            ? this.globalConfig.unfinishedStyle.unfinishedBackground
            : 'transparent';
        if (
            this.globalConfig.lobbyConfigurations.FavouriteHeart &&
            this.globalConfig?.lobbyConfigurations?.FavouriteHeart?.toLowerCase() === 'true'
        ) {
            this.favouriteStyle = 'theme-heart-solid';
            this.unFavouriteStyle = 'theme-heart-outline';
        }
        const slotRaces: any = this.configProviderService.provideSlotRacesConfig();
        this.slotRacesConfig = slotRaces.enableSlotRacesStickers;
        const videoPreviewConfig = this.configProviderService.provideVideoPreviewConfig();
        this.playVideoFromStart = videoPreviewConfig.playVideoFromStart;
        this.setVideoPlayTimeoutint = videoPreviewConfig.setVideoPlayTimeoutint;
        this.enableStickerStyles = features.enableStickerStyles;
    }

    bindFreeSpinInfo() {
        this.setfreeSpinsCount(this.freespinSummaryService.getFreespinCountInfoForGame(this._game.gameId));
    }

    /*This is to set freespins count for the respective game*/
    setfreeSpinsCount(freespinsCountResponse: any) {
        this.freespinCountGame = freespinsCountResponse;
        if (this.freespinCountGame != undefined) {
            this.showFreeSpinCount = true;
            this.optedIn = this.freespinCountGame.optedIn;
            this.availableCount = this.freespinCountGame.availableCount;
            this.totalCount = this.freespinCountGame.totalCount;
        } else {
            this.showFreeSpinCount = false;
        }
    }

    ngAfterViewInit() {
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.afterLoaded = true;
            }, 200);
        });
        if (this.isStickersForUk) {
            this.gameStickerStyleForUk();
        } else {
            this.gameStickerStyle();
        }
    }

    ngOnChanges() {
        this.currentLobbyType = this._game.lobbyType;
        this.assignFeatures();
        if (this.enableFreeSpinSummary) {
            this.bindFreeSpinInfo();
        }
        this.setFastLoading(this.fastLoadingGamesService.getFastLoadingGamesLookUp());
    }

    assignRecommendedWidgetColors() {
        if (this.casinoWidgetService.isCasinoWidget()) {
            return 'gyml-frame-casinowidget';
        } else {
            return 'gyml-frame-casino';
        }
    }

    checkforLatestStickers(recentGames: any) {
        this.setStickers();
        if (this.stickers && this.stickers.length > 0) {
            this.stickers.forEach((item: any, i: number) => {
                if (item?.key?.indexOf('LMS_LATEST') > -1) {
                    if (recentGames && recentGames.length > 0 && recentGames.indexOf(this.game.gameId) > -1) {
                        this.stickers.splice(i, 1);
                    } else {
                        this.stickers[i].value = this.stickerMap.get(this.stickers[i].key);
                    }
                    this.stickers = Array.from(new Set(this.stickers));
                }
            });
        }
        this.casinoLobbyService.stickersDataSubject.next({ gameId: this._game.gameId, stickers: this.stickers || [] });
    }

    getStickerValues(keys: string[], map: any, isForUk: boolean): { key: string; value: string }[] {
        const size = map.size || 0;
        if (size <= 0 && !keys.length) {
            return [];
        }
        const stickerKeys = isForUk ? keys : [keys[0]];
        const values = stickerKeys.map((key) => ({ key, value: key === 'LMS_LATEST' ? key : map.get(key) }));
        return values.filter(({ value }) => !!value);
    }

    setStickers() {
        this.stickers = [];
        this.stickerMap = this.casinoLobbyService.getStickerMap(this.currentLobbyType);
        if (!this.game.sticker) {
            return;
        }
        if (this.game.sticker.indexOf('#') === -1 && this.stickerMap?.size > 0) {
            const stickerValue: string = this.game.sticker === 'LMS_LATEST' ? this.game.sticker : this.stickerMap.get(this.game.sticker);
            this.stickers = [{ key: this.game.sticker, value: stickerValue }];
        } else {
            const stickerKeys = this.game.sticker.split('#');
            this.stickers = this.getStickerValues(stickerKeys, this.stickerMap, this.isStickersForUk);
        }
    }

    ngOnDestroy() {
        if (this.jackpotHandle) {
            this.jackpotHandle.unsubscribe();
        }
        if (this.liveCasinoApiSubscriber) {
            this.liveCasinoApiSubscriber.unsubscribe();
        }
        if (this.recentObs) {
            this.recentObs.unsubscribe();
        }
        if (this.freespinObservable) {
            this.freespinObservable.unsubscribe();
        }
        if (this.slotRacesStickerTimeOut) {
            clearTimeout(this.slotRacesStickerTimeOut);
        }
        if (this.slotRacesStickerDataSubScription) {
            this.slotRacesStickerDataSubScription.unsubscribe();
        }
    }

    setGameTitle() {
        if (this._game) {
            this.gameTitle = this._game.name;
        }
    }

    mouseOver(hoveredgame: string) {
        this.mouseHoveredOnGame = true;
        if (!(this.isMobile || this.isTablet) && this.mouseHoveredOnGame) {
            this.QuickInfoVisible = hoveredgame;
            if (this.isLiveGame) {
                if (this.isTableOnline) {
                    this.onLivecasinoMouseHover = true;
                    this.onLivecasinoMouseLeave = true;
                }
            } else if (this.enableUkMouseOverStyle || this.displayRecenetWinners) {
                this.onMouseHover = true;
                this.onMouseLeave = true;
            }
        }
    }

    gameStickerStyle() {
        const global = this.configProviderService.provideGlobalConfig();
        const stickerStyle = global.messages.GameStickerStyle;
        const sticker: NodeListOf<HTMLElement> = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-0');
        for (let i = 0; i < sticker.length; i++) {
            sticker[i].style.cssText = stickerStyle;
        }
    }

    gameStickerStyleForUk() {
        const global = this.configProviderService.provideGlobalConfig();
        const sticker: NodeListOf<HTMLElement> = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-0');
        for (let i = 0; i < sticker.length; i++) {
            sticker[i].style.cssText = global.messages.UkStickerStyle1;
        }
        const stickerStyle1 = global.messages.UkStickerStyle2;
        const sticker1: NodeListOf<HTMLElement> = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-1');
        for (let i = 0; i < sticker1.length; i++) {
            sticker1[i].style.cssText = stickerStyle1;
        }
    }

    mouseLeave() {
        if (!(this.isMobile || this.isTablet)) {
            this.QuickInfoVisible = '';
            this.mouseHoveredOnGame = false;
            if (this.enableUkMouseOverStyle) {
                this.onMouseHover = false;
                this.onMouseLeave = false;
            }
            if (this.displayRecenetWinners) {
                this.onMouseHover = false;
                this.onMouseLeave = false;
            }
            if (this.isLiveGame) {
                this.onLivecasinoMouseHover = false;
                this.onLivecasinoMouseLeave = false;
            }
        }
    }

    hideQuickInfo() {
        window.scrollTo(0, this.quickInfoScrollY);
        this.removeQuickInfoQueryParameterFromUrl();
    }

    setJackPotValue() {
        const jackpotValue = this.jackpotDataService.getGameJackpotValue(this._game.gameId);
        const enableRibbonConfig = this.configProviderService.provideJackpotConfig().enableRibbonConfig;
        if (jackpotValue) {
            this._game.jackpotValue = jackpotValue.amount;
            this._game.isHotJackpotGroup = jackpotValue.isHotJackpotGroup;
            if (enableRibbonConfig) {
                this.jpRibbonStyles = this.jackpotDataService.getJackpotRibbonStyles(jackpotValue);
            }
        }
    }

    subscribtToJackpotFeed() {
        this.jackpotHandle = this.jackpotDataService.jackpotFeedObservable.subscribe(() => {
            this.setJackPotValue();
        });
        if (this.enableTickingForJackpotOnGameTiles) {
            this.jackpotDataService.jpTickingObservable.subscribe(() => {
                this.setJackPotValue();
                this.changeDetection.markForCheck();
            });
        }
    }

    toggleFavourite($event: MouseEvent) {
        $event.stopImmediatePropagation();
        if (this.user.isAuthenticated) {
            if (
                this.gameLaunchedFrom &&
                (this.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed' || this.gameLaunchedFrom === 'RecentlyPlayed_widget_Favourites')
            ) {
                this.trackFavfromRpWidget();
            }
            this.favouriteService.toggleGameFavourite(this._game);
        } else {
            this.cookie.putObject('favouriteGame', this._game);
            if (this.casinoWidgetService.isCasinoWidget()) {
                this.casinoWidgetService.postMessageToParent('UserAction', {
                    promptLogin: true,
                    loginMessageKey: 'Favourite',
                });
                return;
            }
            this.openLoginDialog.open({ loginMessageKey: 'Favourite' });
        }
        return;
    }

    trackFavfromRpWidget() {
        this.currentLobbyType = this._game.lobbyType;
        if (
            this.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed' ||
            this.gameLaunchedFrom === 'rpWidgRecentlyPlayed_widget_FavouritesetFAV'
        ) {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'Favourites',
                'component.ActionEvent': this.isFavouriteGame(this.game) ? 'UnFavourited' : 'Favourited',
                'component.PositionEvent': this._game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this.currentLobbyType,
            });
        }
    }

    isFavouriteGame(game: any) {
        return this.favouriteService.checkForFavouriteGame(game);
    }

    private getGameLaunch(from: string): GameLaunch {
        const gameConfig = this.configProviderService.provideGameConfig();
        this.demoEnabled = gameConfig.enableDemoMode;
        const gameModel = new GameLaunch();
        gameModel.game = this._game;
        gameModel.isTouch = this.isMobile || this.isTablet;
        gameModel.gamePosition = this.gamePosition;
        gameModel.categoryType = this.category.categoryid;
        gameModel.from = from;
        gameModel.replaceRouteInHistory = false;
        gameModel.isDemoLaunch = false;
        gameModel.skipUnfinGame = false;
        gameModel.gameLaunchedFrom = this.gameLaunchedFrom;
        gameModel.gridPosition = this.category.categoryIndex;
        gameModel.gameContainer = this._game.gameContainer;
        gameModel.clickTimeStamp = this.casinoCoreGameLaunchService.currentTimeStamp();
        return gameModel;
    }

    action(from: string) {
        this.strategy
            .schedule(
                () => {
                    const gameModel = this.getGameLaunch(from);
                    this.casinoCoreGameLaunchService.launchGame(gameModel);
                },
                {
                    scope: this,
                },
            )
            .subscribe();
    }

    setGameIconSrc() {
        if (this._game.isDataNotAvailable) {
            return;
        }
        if (this._game.icon && this._game.icon.src) {
            this.icon.src = this.quickInfoIcon.src = this._game.icon.src;
        } else {
            const gameConfig: any = this.configProviderService.provideGameConfig();
            const gameModel = this.category.gamesModel && this.category.gamesModel.filter((gameModel: any) => gameModel._game === this._game.gameId);
            if (gameModel && gameModel[0]) {
                const src =
                    gameConfig.host +
                    gameConfig.gameIconTemplate.replace('{0}', this.gameDataManager.getGameIcon(gameModel[0])).replace('{1}', gameModel[0]._game) +
                    '.' +
                    this.gameDataManager.getGameFormat(gameModel[0]);
                this.icon.src = this.quickInfoIcon.src = src;
            } else {
                const src =
                    gameConfig.host +
                    gameConfig.gameIconTemplate.replace('{0}', this.gameDataManager.getGameIcon(this._game)).replace('{1}', this._game.gameId) +
                    '.' +
                    this.gameDataManager.getGameFormat(this._game.gameId);
                this.icon.src = this.quickInfoIcon.src = src;
            }
        }
        this.icon.gameTileBorderRadius = this._game.gameTileBorderRadius;
        this.icon.gameTileDropShadow = this._game.gameTileDropShadow;
    }

    displayQuickInfo($event: any) {
        this.quickInfoScrollY = window.pageYOffset;
        this.appendQuickInfoQueryParameterToUrl();
        document.getElementsByTagName('html')[0].classList.add('casino-quickinfo-open');
        if (this.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'RecentlyPlayed_QuickInfo',
                'component.ActionEvent': 'Info_click',
                'component.PositionEvent': this._game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this._game.lobbyType,
            });
        }
        if (this.gameLaunchedFrom === 'RecentlyPlayed_widget_Favourites') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'Favourites_QuickInfo',
                'component.ActionEvent': 'Info_click',
                'component.PositionEvent': this._game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this._game.lobbyType,
            });
        }

        if (this.gameLaunchedFrom != 'RecentlyPlayed_widget_RecentlyPlayed' && this.gameLaunchedFrom != 'RecentlyPlayed_widget_Favourites') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'games',
                'component.LabelEvent': 'game interactions',
                'component.ActionEvent': 'click',
                'component.PositionEvent': this.gamePosition,
                'component.LocationEvent': 'bacon',
                'component.EventDetails': 'more info icon',
                'component.URLClicked': 'not applicable',
            });
        }
        if ($event) {
            $event.stopImmediatePropagation();
        }
        const requiredModalData = {
            game: this._game,
            gamePosition: this.gamePosition,
            gameLaunchedFrom: this.gameLaunchedFrom,
            categoryType: this.category.categoryid,
            icon: this.icon,
            stickers: this.stickers,
            gridPosition: this.category.categoryIndex,
            gameModel: this.gameModel,
            product: this.product,
            widgetFrameClr: this.widgetFrameClr,
            gameContainerLocation: this.gameContainerLocation + 'QuickInfo',
            lobbyType: this.currentLobbyType,
            moreInfoText: 'moreInfoEvent',
            quickInfoScrollY: this.quickInfoScrollY,
        };
        if (this.isNewQuickInfoEnabled) {
            this.quickInfoModalService.setQuickInfoModalData(requiredModalData);
        } else {
            const dialogRef = this.quickInfoService.openGameQuickInfoDialog(requiredModalData);
            dialogRef.afterClosed().subscribe(() => {
                this.hideQuickInfo();
            });
        }
    }

    appendQuickInfoQueryParameterToUrl() {
        const casinoNpmParams = this.casinoNpmParamsService.getcasinoCoreNpmParams();
        if (casinoNpmParams && casinoNpmParams.isDll) {
            if (
                !(this._game.fromCasino != undefined && !this._game.fromCasino) &&
                this._game.gameContainer != 'Overlay' &&
                this._game.gameContainer != 'Widget'
            ) {
                const windowUrl: string = window.location.href;
                let pushStateUrl: string;
                let queryStrings: string = '';
                if (this.urlHasQueryStrings(windowUrl)) {
                    pushStateUrl = windowUrl.substr(0, windowUrl.indexOf('?'));
                    queryStrings = windowUrl.substr(windowUrl.indexOf('?'));
                } else {
                    pushStateUrl = windowUrl;
                }
                if (this.isUrlEndsWithSlash(pushStateUrl)) {
                    pushStateUrl = pushStateUrl + 'game-info/' + this._game.gameId;
                } else {
                    pushStateUrl = pushStateUrl + '/game-info/' + this._game.gameId;
                }
                pushStateUrl = pushStateUrl + queryStrings;
                window.history.pushState(location.href, '', pushStateUrl);
            }
        }
    }

    removeQuickInfoQueryParameterFromUrl() {
        const casinoNpmParams = this.casinoNpmParamsService.getcasinoCoreNpmParams();
        if (casinoNpmParams && casinoNpmParams.isDll) {
            if (
                !(this._game.fromCasino != undefined && !this._game.fromCasino) &&
                this._game.gameContainer != 'Overlay' &&
                this._game.gameContainer != 'Widget'
            ) {
                const param = 'game-info';
                window.history.pushState(location.href, '', location.href.substr(0, location.href.indexOf(param) - 1));
            }
        }
    }

    setLiveGameInfo() {
        if (this._game && this._game.gameId) {
            this.liveGameInfo.set(this.liveCasinoService.getLiveGameLookUpInfo(this._game?.gameId?.toLowerCase()));
        }
        this.isLiveGame = !!this.liveGameInfo();
        if (this._game && this._game.gameId) {
            this.isTableOnline = this.liveCasinoService.isTableOnline(this._game?.gameId?.toLowerCase());
        }
    }

    onLiveCasinoTableTimerEnd(value: boolean) {
        if (value) {
            this.isTableOnline = value;
        }
    }

    isUrlEndsWithSlash(url: string): boolean {
        if (url.lastIndexOf('/') + 1 === url.length) {
            return true;
        }
        return false;
    }

    urlHasQueryStrings(url: string): boolean {
        if (url && url.indexOf('?') > -1) {
            return true;
        }
        return false;
    }

    setSlotRaceStickers() {
        this.slotRacesStickerDataSubScription = this.slotRacesService.stickerRacesData.subscribe((races) => {
            if (races.size > 0) {
                const racesDataObj = races.get(this._game.gameId);
                let expiryTime: number;
                if (racesDataObj) {
                    this.isStickerEnabled = true;
                    const stickerValue: string = racesDataObj.data.stickerText;
                    if (stickerValue.length > 0) {
                        this.stickers = [{ key: stickerValue, value: stickerValue }];
                    }
                    expiryTime = racesDataObj.data && racesDataObj.data.optinExpiryDate && racesDataObj.data.optinExpiryDate.getTime();
                    if (!this.slotRacesStickerTimeOut && expiryTime > Date.now()) {
                        this.slotRacesStickerTimeOut = this.timerService.setTimeout(() => {
                            this.setStickers();
                            this.slotRacesStickerTimeOut = null;
                        }, expiryTime - Date.now());
                    }
                }
            }
        });
    }

    GameEnter() {
        if (
            (this._game.iconType === this.positiveVideoIconType || this._game.iconType === this.negativeVideoIconType) &&
            !(this.isMobile || this.isTablet) &&
            this.isVideoPreviewEnabled
        ) {
            this.isGameHover = true;
            const video = this.elementRef.nativeElement?.querySelector('video');
            if (video) {
                video.currentTime = 0;
                video.play();
            }
            this.tracking.triggerEvent('Event.Tracking', {
                'component.CategoryEvent': 'games',
                'component.LabelEvent': 'Video Preview Game Tile',
                'component.ActionEvent': 'hover',
                'component.PositionEvent': this.gamePosition,
                'component.LocationEvent': this.category.categoryname + '-' + this._game.name,
                'component.EventDetails': 'game video preview play',
                'component.URLClicked': 'not applicable',
            });
        }
    }

    onLoadMetaData() {
        this.isVideoLoaded = true;
    }

    GameLeave() {
        if (
            (this._game.iconType === this.positiveVideoIconType || this._game.iconType === this.negativeVideoIconType) &&
            !(this.isMobile || this.isTablet) &&
            this.isVideoPreviewEnabled
        ) {
            if (this.isGameHover == true) {
                const videoLength: any = document.getElementById(this._game.gameId);
                this.isGameHover = false;
                this.isVideoLoaded = false;
                if (videoLength) {
                    const videoTime = videoLength.duration - videoLength.currentTime;
                    let videoPercentage = (100 * videoLength.currentTime) / videoLength.duration;
                    if (videoLength.currentTime === 0) {
                        videoPercentage = 0;
                    }
                    const actionEvent = videoLength.currentTime === 0 ? 'started' : videoTime === 0 ? 'ended' : 'inprogress';
                    this.tracking.triggerEvent('Event.Tracking', {
                        'component.CategoryEvent': 'games',
                        'component.LabelEvent': 'Video Preview Game Tile',
                        'component.ActionEvent': actionEvent,
                        'component.PositionEvent': this.gamePosition,
                        'component.LocationEvent': this.category.categoryname + '-' + this._game.name,
                        'component.EventDetails': videoPercentage.toFixed() + '%-{' + videoLength.currentTime + '} secs',
                        'component.URLClicked': 'not applicable',
                    });
                }
            }
        }
    }

    playbackFailed() {
        this.videoEnded();
    }

    videoEnded() {
        const id = this._game.gameId + this.category?.categoryid;
        this.isGameHover = false;
        if (this.isMobile || this.isTablet) {
            const videoLength: any = document.getElementById(this._game.gameId);
            this.logger.info(videoLength.duration, videoLength.currentTime);
            if (videoLength) {
                const videoTime = videoLength.duration - videoLength.currentTime;
                let videoPercentage = (100 * videoLength.currentTime) / videoLength.duration;
                if (videoLength.currentTime === 0) {
                    videoPercentage = 0;
                }
                const actionEvent = videoLength.currentTime === 0 ? 'started' : videoTime === 0 ? 'ended' : 'inprogress';
                this.tracking.triggerEvent('Event.Tracking', {
                    'component.CategoryEvent': 'games',
                    'component.LabelEvent': 'Video Preview Game Tile',
                    'component.ActionEvent': actionEvent,
                    'component.PositionEvent': this.gamePosition,
                    'component.LocationEvent': this.category.categoryname + '-' + this._game.name,
                    'component.EventDetails': videoPercentage.toFixed() + '%-{' + videoLength.currentTime + '} secs',
                    'component.URLClicked': 'not applicable',
                });
            }
            if (localStorage.getItem('isVideoEnded') && localStorage.getItem('isVideoEnded') != 'first') {
                const games = JSON.parse(localStorage.getItem('isVideoEnded') || '');
                games.push(id);
                localStorage.setItem('isVideoEnded', JSON.stringify(games));
            } else {
                localStorage.setItem('isVideoEnded', JSON.stringify([id]));
            }
            localStorage.removeItem('isVideoRunning');
        }
    }

    videoDesktopEnded() {
        this.isGameHover = false;
        this.isVideoLoaded = false;
        const videoLength: any = document.getElementById(this._game.gameId);
        if (videoLength) {
            const videoTime = videoLength.duration - videoLength.currentTime;
            let videoPercentage = (100 * videoLength.currentTime) / videoLength.duration;
            if (videoLength.currentTime === 0) {
                videoPercentage = 0;
            }
            const actionEvent = videoLength.currentTime === 0 ? 'started' : videoTime === 0 ? 'ended' : 'inprogress';
            this.tracking.triggerEvent('Event.Tracking', {
                'component.CategoryEvent': 'games',
                'component.LabelEvent': 'Video Preview Game Tile',
                'component.ActionEvent': actionEvent,
                'component.PositionEvent': this.gamePosition,
                'component.LocationEvent': this.category.categoryname + '-' + this._game.name,
                'component.EventDetails': videoPercentage.toFixed() + '%-{' + videoLength.currentTime + '} secs',
                'component.URLClicked': 'not applicable',
            });
        }
    }

    inViewportAction(action: InViewportAction): void {
        this.isInViewport = action.visible;
        localStorage.removeItem('isVideoEnded');
        localStorage.setItem('isVideoEnded', 'first');
    }

    gameMouseDown(event: any) {
        if (document.querySelector('.click-interaction')) {
            event.target.style.transform = 'scale(0.98)';
        }
    }

    gameMouseUp(event: any) {
        if (document.querySelector('.click-interaction')) {
            event.target.style.transform = 'scale(1)';
        }
    }

    @HostListener('window:orientationchange', ['$event'])
    onOrientationChange() {
        if (this.isGameHover) {
            this.isGameHover = false;
        }
        localStorage.removeItem('isVideoRunning');
        localStorage.removeItem('isVideoEnded');
        localStorage.setItem('isVideoEnded', 'first');
    }

    getStickerColor = (index: number) =>
        this.isStickersForUk
            ? index % 2 === 0
                ? this.globalConfig.stickerStyles.UkStickerStyle1
                : this.globalConfig.stickerStyles.UkStickerStyle2
            : this.globalConfig.stickerStyles.GameStickerStyle;
    getStyles(sticker: any, index: number) {
        this.stickerKey = Object.keys(this.globalConfig.stickerStyles);
        if (this.stickerKey.includes(sticker)) {
            this.stickerStyles = this.globalConfig.stickerStyles[sticker];
        } else if (this.isStickersForUk) {
            if (!this.stickerKey.includes(sticker) && index % 2 === 0) {
                this.stickerStyles = this.globalConfig.stickerStyles.UkStickerStyle1;
            } else {
                this.stickerStyles = this.globalConfig.stickerStyles.UkStickerStyle2;
            }
        } else {
            this.stickerStyles = this.globalConfig.stickerStyles.GameStickerStyle;
        }
        return this.stickerStyles;
    }
}
