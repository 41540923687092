<div
    class="teasers-skeleton-block-opt"
    [style.height.px]="displayHeight()"
    *ngIf="!isNPM() && isOptimizedTeaserEnabled && (teasersSource?.length || optTeasersSource?.length)">
    <cc-opt-teasers
        [teaserLocation]="teaserLocation"
        [teaserCategory]="teaserCategory"
        [teasersSource]="optTeasersSource"
        [identifierClass]="identifierClass"
        [lobbyType]="lobbyType"
        [gameContainer]="gameContainer"
        (optMiniTeasersEnabled)="getOptMiniTeasersEvent($event)" />
</div>

<div
    class="teasers-skeleton-block"
    [ngClass]="Modeset"
    *ngIf="!isNPM() && !isOptimizedTeaserEnabled && (teasersSource?.length || optTeasersSource?.length)">
    <cc-teasers
        [teaserLocation]="teaserLocation"
        [teaserCategory]="teaserCategory"
        [teasersSource]="teasersSource"
        [isDefaultCategory]="isDefaultCategory"
        [lobbyType]="lobbyType"
        [gameContainer]="gameContainer" />
</div>

<cc-teasers-npm
    *ngIf="isNPM() && !isOptimizedTeaserEnabled"
    [teaserLocation]="teaserLocation"
    [teaserCategory]="teaserCategory"
    [teasersSource]="teasersSource"
    [isDefaultCategory]="isDefaultCategory"
    [isNPM]="isNPM()"
    [lobbyType]="lobbyType"
    [gameContainer]="gameContainer"
    [enablePlayBingoButton]="enablePlayBingoButton"
    [isTouch]="isTouch"
    [bingocta]="bingocta"
    [isNewSportsNative]="isNewSportsNative"
    [teaserDynaconConfigs]="teaserDynaconConfigs"
    [bgGlobalConfig]="bgGlobalConfig()">
</cc-teasers-npm>

<cc-opt-teasers-npm
    *ngIf="isNPM() && isOptimizedTeaserEnabled"
    [teaserLocation]="teaserLocation"
    [teaserCategory]="teaserCategory"
    [teasersSource]="optTeasersSource"
    [identifierClass]="identifierClass"
    [lobbyType]="lobbyType"
    [gameContainer]="gameContainer"
    [isNPM]="isNPM()"
    [teaserDynaconConfigs]="teaserDynaconConfigs"
    [globalTeaserSettings]="teaserSettings()"
    [mercuryBgImage]="mercuryBgImage"
    [optminiTeaserSource]="optminiTeaserSource"
    [enableTeaserOptimization]="enableTeaserOptimization"
    [enableMiniTeasers]="enableMiniTeasers"
    [enablePlayBingoButton]="enablePlayBingoButton"
    [isTouch]="isTouch"
    [bingocta]="bingocta"
    [isNewSportsNative]="isNewSportsNative"
    [jackpotGroupNameMapTeaserNPM]="jackpotGroupNameMapTeaserNPM"
    [globalTeaserStyles]="teaserStyles()">
</cc-opt-teasers-npm>

<div
    class="coinarcade-takemestore btn btn-primary"
    *ngIf="(teasersSource?.length || optTeasersSource?.length) && isArcadeLobby"
    (click)="takeMeStoreClickEvent()">
    <div class="takemestore-img">
        <img src="{{ arcadeGlobalMessages()?.CoinImg }}" alt="coin-img" />
    </div>
    <div class="takemestore-text">{{ arcadeGlobalMessages()?.TakeMeToStore }}</div>
</div>
