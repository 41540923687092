import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, booleanAttribute } from '@angular/core';

import { CsClassIconComponent } from '../../../icons/class-icon/class-icon.component';

@Component({
    selector: 'cs-rp-fav-cta-legacy',
    templateUrl: './rp-fav-cta-legacy.component.html',
    standalone: true,
    imports: [CommonModule, CsClassIconComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RpFavCtaLegacyComponent implements RpFavCtaLegacyViewModel {
    @Input() color = '';
    @Input() backgroundColor = '';
    @Input() startIconClass = '';
    @Input() endIconClass = '';
    @Input({ transform: booleanAttribute }) isFav = false;
    @Input({ transform: booleanAttribute }) setActive = false;
}

export interface RpFavCtaLegacyViewModel {
    color: string;
    backgroundColor: string;
    startIconClass: string;
    endIconClass: string;
    isFav: boolean;
    setActive: boolean;
}
