<div class="casino-game-ctr-clr" [class.lobby-no-seo]="!isLobbyHasNoSeo()">
    @if (displayLMTCategoryData()) {
        <div class="casino-lobby-games">
            <cc-lmt-category-wrapper
                id="categoryIdentifier_{{ currentCategoryInfo().categoryid }}"
                [categoryId]="currentCategoryInfo().categoryid"
                [category]="categoryInfo()"
                [isTouch]="isTouch"
                [lobbyType]="lobbyType()"
                [gameContainer]="gameContainer">
            </cc-lmt-category-wrapper>
        </div>
    }
    @if (hasLMTSubCategories()) {
        <div class="casino-lobby-games">
            @for (subCategory of visibleLmtSubCategories(); track subCategory.categoryInfo.categoryid; let i = $index) {
                <div>
                    <cc-lmt-sub-category-wrapper
                        id="categoryIdentifier_{{ subCategory.categoryInfo.categoryid }}"
                        [trackingIndex]="gamesInCategory() ? i + 1 : i"
                        [coinExpiryIndex]="i + 1"
                        [subcategory]="subCategory"
                        [isTouch]="isTouch"
                        [lobbyType]="lobbyType()"
                        [gameContainer]="gameContainer"
                        [seeAllProductLinkOverride]="seeAllProductLinkOverride" />
                </div>
            }
        </div>
    }
</div>
