<div class="casino-lobby-container-main">
    <div class="recentlyplayed-game-overlay recentlyplayed-game-info-container">
        <div class="recentlyplayed-game-info-title-container">
            <span mat-dialog-title class="recentlyplayed-game-info-title">{{ arcadeLobbyConfigurations.RecentlyPlayedGames }}</span>
            <span class="theme-ex recentlyplayed-game-info-close" (click)="close()"></span>
        </div>
        <div></div>
        <div class="recentlyplayed-overlay-games-section">
            <mat-grid-list [cols]="breakpoint" rowHeight="1:1" [gutterSize]="'10px'">
                @for (game of rpData; let i = $index; track i) {
                    <mat-grid-tile>
                        <cc-arcade-game-lobby
                            id="gameIdentifier_{{ subcategory?.categoryInfo?.categoryid }}_{{ game?.gameId }}"
                            [ngClass]="game?.infoIconClass"
                            [game]="game"
                            [gamePosition]="i"
                            [category]="subcategory.categoryInfo"
                            [gameLaunchedFrom]="gameLaunchedFrom"
                            [gameIndex]="i + 1" />
                    </mat-grid-tile>
                }
            </mat-grid-list>
        </div>
    </div>
</div>
