import { LowerCasePipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { SitecoreImageResizePipe } from '@casinocore/platform/core';

@Component({
    selector: 'cc-opt-teaser-bg-image',
    templateUrl: 'opt-teaser-bg-image.component.html',
    standalone: true,
    imports: [NgIf, NgClass, NgStyle, LowerCasePipe, SitecoreImageResizePipe],
})
export class OptTeaserBgImageComponent implements OnInit {
    @Input() teaser: any;
    @Input() teaserBackgroundImageAlignment: string = '';
    @Input() teaserBackgroundPosition: any;
    @Input() isMobileImage: string;
    @Input() enableAluminiumTeasers: boolean;
    tabletImage: any;

    constructor() {}

    ngOnInit() {
        this.tabletImage = this.teaser?.tabletImage ? this.teaser?.tabletImage?.src : this.teaser?.desktopImage?.src;
    }
}
