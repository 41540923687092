import { Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { LottieAnimationStatus } from '../shared/models/common.model';

@Directive({
    selector: '[ccBrowserState]',
    standalone: true,
})
export class BroswerStateDirective implements OnInit {
    @Input() idleTimeoutIn: number;
    @Output() setLottieAnimationStatus: EventEmitter<LottieAnimationStatus>;

    private idleTimeout: any;

    constructor() {
        this.setLottieAnimationStatus = new EventEmitter();
    }

    @HostListener('window:blur')
    onBlur(): void {
        this.setLottieAnimationStatus.emit(LottieAnimationStatus.Pause);
    }

    @HostListener('window:focus')
    onFocus(): void {
        this.setLottieAnimationStatus.emit(LottieAnimationStatus.Play);
    }

    @HostListener('pointerdown')
    onPointerDown(): void {
        this.setLottieAnimationStatus.emit(LottieAnimationStatus.Play);
        this.checkIfIdle();
    }

    @HostListener('keydown')
    onKeyDown(): void {
        this.setLottieAnimationStatus.emit(LottieAnimationStatus.Play);
        this.checkIfIdle();
    }

    ngOnInit(): void {
        this.checkIfIdle();
    }

    private checkIfIdle(): void {
        clearTimeout(this.idleTimeout);
        this.idleTimeout = setTimeout(() => this.setLottieAnimationStatus.emit(LottieAnimationStatus.Pause), this.idleTimeoutIn);
    }
}
