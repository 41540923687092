import { Component, ComponentRef, Input, OnChanges, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { OutputEventModel } from '@casinocore/platform/core';

import { LazyRouteService } from '../../lazy-master/lazy-route.service';

@Component({
    selector: 'lazy-placeholder',
    templateUrl: 'lazy-placeholder.component.html',
    standalone: true,
})
export class LazyPlaceholderComponent implements OnInit, OnChanges {
    @Input() componentName: string;
    @Input() lobbyType: string;
    @Input() currentProduct: string;
    @Input() data: any;
    @Input() outputEvents: OutputEventModel[];
    componentRef_: ComponentRef<any>;
    @ViewChild('lazyTemplate', { read: ViewContainerRef, static: true }) lazyPlaceholdercontainer: ViewContainerRef;
    constructor(private lazyRouteService: LazyRouteService) {}
    ngOnInit() {
        const componentData = {
            type: this.componentName,
        };
        this.lazyRouteService.loadAndRenderLazyComponents(componentData).then((componentFactory: any) => {
            this.componentRef_ = this.lazyPlaceholdercontainer.createComponent(componentFactory);
            this.componentRef_.instance.lobbyType = this.lobbyType;
            this.componentRef_.instance.data = this.data;
            this.componentRef_.instance.currentProduct = this.currentProduct;
            if (this.outputEvents) {
                this.outputEvents.forEach((outputEvent: OutputEventModel) => {
                    this.componentRef_.instance[outputEvent.eventName].subscribe((data: any) => {
                        outputEvent.callback(data);
                    });
                });
            }
        });
    }
    ngOnChanges() {
        if (this.componentRef_) {
            this.componentRef_.instance.lobbyType = this.lobbyType;
            this.componentRef_.instance.currentProduct = this.currentProduct;
            this.componentRef_.instance.data = this.data;
            this.componentRef_.instance.ngOnChanges();
        }
    }
}
