import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ThumbnailService {
    private thumbnailPublisher = new BehaviorSubject<any>(null);
    thumbnailDataObservable: Observable<any> = this.thumbnailPublisher.asObservable();
    setThumbnailData(res: any) {
        this.thumbnailPublisher.next(res);
    }
}
