import { NgClass, NgIf, NgStyle, UpperCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import {
    CasinoCoreGamelaunchService,
    CasinoCoreNpmParamsService,
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    DesignSystemEnablerService,
    FavouriteService,
    GameDataManager,
    GameLaunch,
    JackpotDataService,
    OpenLoginDialog,
    QuickInfoModalService,
    WindowRef,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, GameTileCtaComponent } from '@frontend/casino-ui';
import { CookieService, NavigationService, UserService } from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { Subscription } from 'rxjs';

import type { QuickInfoModalComponent } from '../quick-info-modal/quick-info-modal.component';
import { QuickInfoService } from '../services/quick-info.service';
import { StickersJackpotComponent } from '../stickers-jackpot/stickers-jackpot.component';

@Component({
    selector: 'cc-quick-info-direct',
    templateUrl: 'quick-info-direct.component.html',
    standalone: true,
    imports: [NgIf, NgClass, StickersJackpotComponent, NgStyle, UpperCasePipe, TrustAsHtmlPipe, GameTileCtaComponent, DecoratorClassDirective],
})
export class QuickInfoDirectComponent implements OnInit, OnDestroy {
    @Input() lobbyType: string;
    @Input() gameLaunchedFrom: string = 'playRedirect';

    stickersDataSubscriber: any;
    width: number;
    boxWidth: number;
    boxWidthInt: number;
    GameManager: any;
    isTouch: boolean;
    defaultCategory: any;
    gameProvider: string;
    cta: string;
    gymlCategoryType: any;
    useFavouriteGames: boolean;
    favouriteStyle: string = 'theme-favorite-i';
    unFavouriteStyle: string = 'theme-favorite';
    provider: any;
    jackpotValue: any = null;
    isHotJackpotGroup: any = false;
    vendorLogo: any = null;
    gameDescription: any = null;
    contentLoaded: boolean = false;
    isFavourite: boolean = false;
    jackpotFeedObs: any;
    isShowFavouriteHeartEnabled: boolean;
    game: any = {};
    src: string;
    showQuickInfo: boolean = false;
    stickers: any = [];
    newQuickInfoStyle: boolean = false;
    isStickersForUk: boolean = false;
    quickInfoStyle: string;
    demoLinkText: string;
    demoLinkTextColor: string;
    demoEnabledForProvider: boolean = true;
    demoEnabled: boolean;
    gameTitle: string;
    enableQuickInfoReels: boolean;
    reels: any = null;
    payLines: any = null;
    returnToPlayer: any = null;
    enableQuicInfoReelskDiv: boolean = false;
    reelsTitle: string = '';
    payLinesTitle: string = '';
    returnToPlayerTitle: string = '';
    gameDetailPageObs: Subscription;
    favouriteFeedObs: Subscription;
    enableEpcotSubNavigation: boolean;
    isNewQuickInfoEnabled: boolean;
    quickInfoObs: Subscription;
    dialogRef: MatDialogRef<QuickInfoModalComponent, any>;
    isLegacy: boolean = false;

    constructor(
        private window: WindowRef,
        private navigation: NavigationService,
        private user: UserService,
        private loginDialog: OpenLoginDialog,
        private favouriteService: FavouriteService,
        private casinoCoreGameLaunchService: CasinoCoreGamelaunchService,
        private cookie: CookieService,
        private route: ActivatedRoute,
        private casinoManager: CasinoManager,
        private jackpotservice: JackpotDataService,
        private configProviderService: ConfigProviderService,
        private casinoLobbyService: CasinoLobbyService,
        private gameDataManager: GameDataManager,
        private casinoNpmParamsService: CasinoCoreNpmParamsService,
        private quickInfoService: QuickInfoService,
        private quickInfoModalService: QuickInfoModalService,
        private cdr: ChangeDetectorRef,
        private designSystemEnablerService: DesignSystemEnablerService,
    ) {
        this.setWidth();
    }
    ngOnInit() {
        this.cdr.detectChanges();
        const gameConfig = this.configProviderService.provideGameConfig();
        this.assignFeatures();
        this.setFavouriteOnLoad();
        this.getGameAndShowQuickInfo();
        if (this.game && this.game.gameId) {
            if (this.isTouch && this.window.nativeWindow.screen.availWidth < 830) {
                this.gameDataManager.getGameDescription(this.game.gameId, this.game.provider).subscribe((res) => {
                    if (res) {
                        this.gameDescription = res.gameDescription;
                        this.reels = res.reels;
                        this.payLines = res.payLines;
                        this.returnToPlayer = res.returnToPlayer;
                    }
                });
                if (!gameConfig.enableDemoMode) {
                    this.gameDetailPageObs = this.casinoCoreGameLaunchService.gameDetailPageObservable.subscribe((res: any) => {
                        if (res) {
                            this.hideInfo();
                        }
                    });
                }
            }
        }
        this.isLegacy = !this.designSystemEnablerService.isEnabled();
    }
    setFavouriteOnLoad() {
        this.favouriteFeedObs = this.favouriteService.favouriteGamesFeedObservable.subscribe((favouriteGameIds) => {
            if (favouriteGameIds) {
                this.isFavourite = this.isFavouriteGame(this.game);
            }
        });
    }
    assignFeatures() {
        const features = this.configProviderService.provideFeaturesConfig();
        const global = this.configProviderService.provideGlobalConfig();
        const gameConfig = this.configProviderService.provideGameConfig();
        const quickInfoConfig = this.configProviderService.provideQuickInfoConfig();
        const subnavConfig: any = this.configProviderService.provideSubNavigationConfig();
        this.enableQuickInfoReels = gameConfig.enableQuickInfoReels;
        this.reelsTitle = global.messages.Reels;
        this.payLinesTitle = global.messages.PayLines;
        this.returnToPlayerTitle = global.messages.ReturnToPlayer;
        this.useFavouriteGames = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.favouriteGamesLobbyType);
        this.isNewQuickInfoEnabled = this.casinoManager.IsFeatureEnabledforLobbytypeWithInvokerProduct(
            this.lobbyType,
            features.enableNewQuickInfoLobbyType,
        );
        this.gameProvider = quickInfoConfig.quickInfo.gameProviderHeader;
        this.cta = quickInfoConfig.quickInfo.cta;
        this.isTouch = this.casinoManager.isTouch();
        if (
            global.lobbyConfigurations.FavouriteHeart === 'true' ||
            global.lobbyConfigurations.FavouriteHeart === 'True' ||
            global.lobbyConfigurations.FavouriteHeart === 'TRUE'
        ) {
            this.favouriteStyle = 'theme-heart-solid';
            this.unFavouriteStyle = 'theme-heart-outline';
        }
        if (document.querySelector('.casino-qi-noverlay') as HTMLElement) {
            (document.querySelector('.casino-qi-noverlay') as HTMLElement).style.cssText = global.messages.quickInfoStyles;
        }
        this.newQuickInfoStyle = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.newQuickInfoStyle);
        this.isStickersForUk = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.stickersForUk);
        this.demoLinkText = global.messages.DemoLinkText;
        this.demoLinkTextColor = global.messages.DemoLinkTextColor;
        this.demoEnabled = gameConfig.enableDemoMode;
        this.enableEpcotSubNavigation = subnavConfig.enableEpcotSubNavigation;
    }

    getGameAndShowQuickInfo() {
        const gameConfig = this.configProviderService.provideGameConfig();
        let gameQuickInfoQueryParameter = this.route.snapshot.params['gameId'];
        const param = 'game-info';
        const link: any = window.location.href;
        if (link.indexOf(param) !== -1 && link.indexOf('?q=1') !== -1) {
            let gameId = link.substr(link.indexOf(param) + (param?.length || 0));
            gameId = gameId.indexOf('?') !== -1 ? gameId.substr(0, gameId.indexOf('?')) : gameId;
            gameId = gameId.replace(/\/\/*/g, '');
            gameQuickInfoQueryParameter = gameId;
        }
        const path: any = this.route.snapshot.routeConfig!.path;
        if (gameQuickInfoQueryParameter && (path.indexOf('game-info') > -1 || link.indexOf(param) !== -1)) {
            const gameObj: any = this.casinoLobbyService.getGameInfo(gameQuickInfoQueryParameter);
            if (gameObj) {
                this.game = this.gameDataManager.setGameLobbyModel(gameObj, this.isTouch);
                this.game.lobbyType = this.lobbyType;
                this.gameTitle = this.game.name;
                const vendorsWithOnlyRealMode: any = this.casinoManager.IsFeatureEnabledforLobbytype(
                    this.lobbyType,
                    gameConfig.vendorsWithOnlyRealModeLobbyType,
                );
                const demoModeExcludedGames = gameConfig.demoModeExcludedGamesRegex?.split(',');
                if (vendorsWithOnlyRealMode && vendorsWithOnlyRealMode.indexOf(this.game.provider) > -1) {
                    this.demoEnabledForProvider = false;
                } else if (demoModeExcludedGames && demoModeExcludedGames.length > 0) {
                    demoModeExcludedGames?.forEach((regex: any) => {
                        if (new RegExp(regex, 'g').test(this.game.gameId)) {
                            this.demoEnabledForProvider = false;
                        }
                    });
                }
                this.loadContentAfterStickersData(this.game);
            }
        } else {
            this.game = null;
            this.showQuickInfo = false;
        }
    }

    loadContentAfterStickersData(gameData: any) {
        this.isFavourite = this.favouriteService.checkForFavouriteGame(this.game);
        this.jackpotFeedObs = this.jackpotservice.jackpotFeedObservable.subscribe((_response: any) => {
            if (_response) {
                const jpFeed = _response.get(this.game.gameId);
                if (jpFeed) {
                    this.jackpotValue = jpFeed;
                    this.isHotJackpotGroup = this.jackpotValue.isHotJackpotGroup;
                }
            }
        });
        this.setGameTitleAndProvider();
        this.loadQuickInfo(gameData);
    }

    @HostListener('window:resize') onResize() {
        this.setWidth();
        this.isTouch = this.casinoManager.isTouch();
    }

    setGameTitleAndProvider() {
        const gameConfig = this.configProviderService.provideGameConfig();
        this.provider = this.game.provider;
        const gameOwnerId = gameConfig.gameOwnerIdMap?.find((g: any) => g.gameId == this.game.gameId);
        this.vendorLogo =
            gameConfig.host +
            gameConfig.gameIconTemplate.replace('{0}', 'vendorlogos').replace('{1}', gameOwnerId ? gameOwnerId?.ownerId : this.game.ownerId) +
            '.jpg';
    }

    loadQuickInfo(gameData: any) {
        if (!this.newQuickInfoStyle || (this.isTouch && window.innerWidth < 768 && !this.isNewQuickInfoEnabled)) {
            const param = 'game-info';
            const link: any = window.location.href;
            if (link.indexOf(param) !== -1) {
                this.contentLoaded = true;
                this.setImageIcon();
                this.showQuickInfo = true;
                document.getElementsByTagName('html')[0].classList.add('casino-quickinfo-open');
            }
        } else {
            this.stickersDataSubscriber = this.casinoLobbyService.stickersDataObservable.subscribe((gameRes: any) => {
                if (gameRes && gameRes.gameId === this.game.gameId && gameRes.stickers) {
                    const param = 'game-info';
                    const link: any = window.location.href;
                    if (!document.getElementsByTagName('html')[0].classList.contains('casino-quickinfo-open') && link.indexOf(param) !== -1) {
                        this.stickers = gameRes.stickers;
                        this.openDialog(gameData);
                        document.getElementsByTagName('html')[0].classList.add('casino-quickinfo-open');
                    }
                }
            });
        }
    }

    setWidth(): void {
        if (this.window.nativeWindow.screen.availWidth >= 768) this.width = 388;
        else if (this.window.nativeWindow.screen.availWidth < this.window.nativeWindow.screen.availHeight)
            this.width = this.window.nativeWindow.screen.availWidth;
        else this.width = this.window.nativeWindow.screen.availHeight;
        this.boxWidth = this.width;
        this.boxWidthInt = this.boxWidth - 176;
    }

    hideInfo(): void {
        document.getElementsByTagName('html')[0].classList.remove('casino-quickinfo-open');
        this.showQuickInfo = false;
        this.removeQuickInfoQueryParameterFromUrl();
    }

    launchGame(game: any, isDemo: boolean): void {
        const gameModel = new GameLaunch();
        gameModel.game = game;
        gameModel.isTouch = this.isTouch;
        gameModel.gamePosition = 0;
        gameModel.categoryType = 'QuickInfo';
        gameModel.from = 'q';
        gameModel.replaceRouteInHistory = false;
        gameModel.isDemoLaunch = isDemo;
        gameModel.skipUnfinGame = false;
        gameModel.gameLaunchedFrom = this.gameLaunchedFrom;
        gameModel.clickTimeStamp = this.casinoCoreGameLaunchService.currentTimeStamp();
        this.casinoCoreGameLaunchService.launchGame(gameModel);
    }

    toggleFavourite(game: any, $event: any) {
        $event.stopImmediatePropagation();
        if (this.user.isAuthenticated) {
            this.favouriteService.toggleGameFavourite(game);
            this.isFavourite = !this.isFavourite;
        } else {
            this.hideInfo();
            this.cookie.putObject('favouriteGame', game);
            const loginResponseObj: any = {
                loginMessageKey: 'Favourite',
            };
            this.loginDialog.open(loginResponseObj);
        }
    }

    isFavouriteGame(game: any) {
        return this.favouriteService.checkForFavouriteGame(game);
    }

    setImageIcon() {
        const features = this.configProviderService.provideFeaturesConfig();
        if (this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.enableQuickInfoLobbyType))
            this.src = this.casinoLobbyService.getImagePath(this.game.gameId, 'square');
    }

    removeQuickInfoQueryParameterFromUrl() {
        const casinoNpmParams = this.casinoNpmParamsService.getcasinoCoreNpmParams();
        if (casinoNpmParams?.isDll) {
            const param = 'game-info';
            if (location.href.indexOf(param) > -1 && !document.getElementsByTagName('html')[0].classList.contains('casino-quickinfo-open')) {
                window.history.pushState(location.href, '', location.href.substring(0, location.href.indexOf(param) - 1));
            }
        }
    }

    GameJackpotValue(game: any) {
        return this.jackpotservice.getGameJackpotValue(game.gameId);
    }
    async openDialog(data: any = null) {
        const requiredModalData = {
            game: this.game,
            gameTitle: this.gameTitle,
            src: data.icon.src,
            icon: data.icon,
            useFavouriteGames: this.useFavouriteGames,
            gameProvider: this.gameProvider,
            provider: this.provider ? this.provider : 'No provider',
            cta: this.cta,
            isTouch: this.isTouch,
            gamePosition: 0,
            gameLaunchedFrom: this.gameLaunchedFrom,
            categoryType: 'QuickInfo',
            jackpotData: this.jackpotValue,
            isLaunchedFromUrl: true,
            stickers: this.stickers,
            lobbyType: this.lobbyType,
            gameHomeUrl: window.location.href.slice(0, window.location.href.indexOf('/game-info')),
        };
        if (this.isNewQuickInfoEnabled) {
            this.quickInfoModalService.setQuickInfoModalData(requiredModalData);
        } else {
            this.dialogRef = this.quickInfoService.openQuickInfoDialog(requiredModalData);
            this.dialogRef.afterClosed().subscribe(() => {
                if (this.isTouch) {
                    this.removeQuickInfoQueryParameterFromUrl();
                } else {
                    this.handleEscClick();
                }
            });
        }
    }
    handleEscClick() {
        document.getElementsByTagName('html')[0].classList.remove('casino-quickinfo-open');
        const currenturl = this.navigation.location.absUrl();
        if (currenturl !== window.location.href) {
            this.navigation.goTo(currenturl);
        }
        this.removeQuickInfoQueryParameterFromUrl();
    }

    ngOnDestroy() {
        if (this.jackpotFeedObs) {
            this.jackpotFeedObs.unsubscribe();
        }
        if (this.gameDetailPageObs) {
            this.gameDetailPageObs.unsubscribe();
        }
        this.favouriteFeedObs?.unsubscribe();
        if (this.quickInfoObs) {
            this.quickInfoObs.unsubscribe();
        }
        if (this.stickersDataSubscriber) {
            this.stickersDataSubscriber.unsubscribe();
        }
        if (this.dialogRef) {
            this.quickInfoService.closeQuickInfoDialog(this.dialogRef);
        }
        if (
            !document.querySelector('.gameQuickInfo') &&
            !document.querySelector('.quickInfoModal') &&
            document.getElementsByTagName('html')[0].classList.contains('casino-quickinfo-open')
        ) {
            document.getElementsByTagName('html')[0].classList.remove('casino-quickinfo-open');
        }
    }
}
