@if (!isLegacy) {
    <cs-gametile-cta
        [kind]="kind"
        [variant]="variant"
        [size]="size"
        [customBgColor]="backgroundColor"
        [customTextColor]="color"
        [cta_text]="cta_text"
        [isLegacy]="isLegacy">
        @if (startIconClass) {
            <cs-class-icon [startIconClass]="startIconClass" slot="start"></cs-class-icon>
        }
        {{ cta_text }}
        <cs-chevron [chevronKind]="chevronKind" slot="end"></cs-chevron>
    </cs-gametile-cta>
}

<!-- This is legacy and can be omitted after moving to design system completely -->
@if (isLegacy) {
    <cs-rp-fav-cta-legacy
        [isFav]="isFav"
        [setActive]="setActive"
        [color]="color"
        [backgroundColor]="backgroundColor"
        [startIconClass]="startIconClass"
        [endIconClass]="endIconClass">
        {{ cta_text }}
    </cs-rp-fav-cta-legacy>
}
