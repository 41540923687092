import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ConfigProviderService } from '../config-provider/config-provider.service';

@Injectable({
    providedIn: 'root',
})
export class DesignSystemEnablerService {
    constructor(
        private readonly _queryParamReader: ActivatedRoute,
        private configProvider: ConfigProviderService,
    ) {}

    isEnabled() {
        const queryParams = this._queryParamReader.snapshot.queryParams;
        const activateDesignSystem = this.configProvider.provideFeaturesConfig().activateDesignSystem;

        return this.isEnabledInQueryParameter(queryParams) || activateDesignSystem;
    }
    private isEnabledInQueryParameter(queryParams: { [k: string]: string }) {
        const enablerKeyInQueryParam = Object.keys(queryParams).find((p) => p.trim().toLowerCase() === '_enablefeature');
        const enablerEntryInQueryParam = Object.entries(queryParams).find((p) => p[0].trim().toLowerCase() === '_enablefeature');
        if (enablerKeyInQueryParam) {
            if (enablerEntryInQueryParam && (enablerEntryInQueryParam[1] || '').trim().toLowerCase() === 'ds') {
                return true;
            }
        }
        return false;
    }
}
