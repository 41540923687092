import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';

import {
    ArcadeLobbyTrackingService,
    CacheManagerService,
    CasinoIFrameOverlayService,
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    GameDataManager,
    PromotedGamesService,
    RecentGamesService,
    UnfinishedGamesService,
} from '@casinocore/platform/core';
import { DeviceService, UserService } from '@frontend/vanilla/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { cloneDeep } from 'lodash-es';
import { Subscription, skip, tap } from 'rxjs';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { ArcadeFeatureGridComponent } from '../arcade-feature-grid/arcade-feature-grid.component';
import { ArcadeGameLobbyComponent } from '../arcade-game-lobby/arcade-game-lobby.component';
import { CategoryiconSubtitleComponent } from '../categoryicon-subtitle/categoryicon-subtitle.component';
import { RecentlyPlayedGameOverlayComponent } from '../recentlyplayed-game-overlay/recentlyplayed-game-overlay.component';
import { SignPostingComponent } from '../sign-posting/sign-posting.component';

export class ArcadeRgGameModel {
    gameId: string;
    sticker: string;
}

export class ArcadeRGGame {
    GameId: string;
    isRecentlyPlayed: boolean;
    isPromoted: boolean;
    sticker: string;
    fromCasino: boolean = true;
    constructor(gameId: string, isPromoted: boolean, isRecentlyPlayed: boolean, fromCasino: boolean) {
        this.GameId = gameId;
        this.isRecentlyPlayed = isRecentlyPlayed;
        this.isPromoted = isPromoted;
        this.fromCasino = fromCasino;
    }
}

@Component({
    selector: 'cc-arcade-rp-gyml',
    templateUrl: 'arcade-recentlyplayed-gyml.component.html',
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        CategoryiconSubtitleComponent,
        NgFor,
        ArcadeGameLobbyComponent,
        NgStyle,
        SignPostingComponent,
        ArcadeFeatureGridComponent,
    ],
})
export class ArcadeRecentlyPlayedandGymlComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    @Input() isMobile: boolean;
    @ViewChild('recentGYMLCarousel') recentGYMLCarousel: SwiperComponent;
    @Input() categoryId: string = '';
    recentCategory: any;
    gymlCategory: any;
    recentlyandGymlGames: any = [];
    baseGameCount: number;
    recentStartIndex: number = 0;
    maxScrollPosition: number;
    staticGYML: boolean;
    unfinishedGames: any = [];
    Games: any[] = [];
    isCombinedCategories: boolean = false;
    subcategory: any;
    onlyRecentGames: any[] = [];
    onlyRecentGamesStrings: any[] = [];
    onlyGYMLGamesStrings: any[] = [];
    onlyGYMLGames: any[] = [];
    youMightLikeMessage: any;
    gymlGames: any = [];
    recentGames: any = [];
    gymlGameStrings: any = [];
    isTouch: any;
    unfinishedRPANDGYMLGameIds: ArcadeRGGame[] = [];
    unfinishedRPANDGYMLGames: any = [];
    recentGamesCount: number = 0;
    subcategoryNew: any;
    isCombinedCategoriesNew: boolean = false;
    recentsObs: any;
    unfinishedObv: any;
    categoryLookupObv: any;
    defaultCategoryLookupObv: any;
    lobbyObs: any;
    isUkHeading: boolean;
    alignIconRight: boolean;
    lang: string;
    topValueForGYML: any;
    isCrossSaleIOS: boolean;
    isSignPosting: boolean;
    featureConfig: any;
    globalConfig: any;
    casinoConfig: any;
    isClientConfigLoaded: any;
    promotedGamesText: string;
    recentTitle: string;
    clientConfigLoadSubscription: Subscription;
    bingoRecentGames: any = [];
    trackingClass: string = '';
    recentlyPlayedGames: any = [];
    promotedFeedSub: Subscription;
    recentGamesSubscription: Subscription;
    newFeatureGridArr: any[] = [];
    categoryObj: any;
    gymlCategoryRef: any;
    enableEpcotSubNavigation: boolean;
    sliceFeatureGrid: any[] = [];
    remainingGameCount: number;
    arcadeLobbyConfig: any;
    arcadeLobbyMessages: any;
    readonly showFeatureGrid = signal(true);
    sliceGamesCount: number;

    swiperOptionsRecentlyPlayed: SwiperOptions = {
        modules: [Navigation, Pagination, Autoplay],
        //grid: { xs: 3, sm: 6, md: 6, lg: 6, all: 0 },
        loop: false,
        speed: 400,
        autoHeight: true,
        allowTouchMove: false,
        spaceBetween: 5,
        direction: 'horizontal',
        slidesPerView: 6,
        centeredSlides: false,
        autoplay: false,
        pagination: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        slidesPerGroup: 6,
        watchOverflow: true,
        mousewheel: false,
        scrollbar: false,
        preventClicks: false,
        preventClicksPropagation: false,
    };

    constructor(
        private casinoLobbyService: CasinoLobbyService,
        private gameDataManager: GameDataManager,
        private recentGameService: RecentGamesService,
        private configProviderService: ConfigProviderService,
        private elementRef: ElementRef,
        private unfinishedGameService: UnfinishedGamesService,
        private casinoManager: CasinoManager,
        private casinoIFrameOverlayService: CasinoIFrameOverlayService,
        private deviceService: DeviceService,
        private user: UserService,
        private promotedGamesService: PromotedGamesService,
        private dialog: MatDialog,
        private arcadeLobbyTrackingService: ArcadeLobbyTrackingService,
        private cdr: ChangeDetectorRef,
        private cacheManager: CacheManagerService,
    ) {
        deviceService.orientation
            .pipe(
                skip(1),
                tap(() => {
                    this.setIsCombinedCategories();
                }),
                takeUntilDestroyed(),
            )
            .subscribe();
    }

    ngOnInit() {
        this.isTouch = this.casinoManager.isTouch();
        this.isClientConfigLoaded = true;
        this.setRpGyml();
        const subnavConfig: any = this.configProviderService.provideSubNavigationConfig();
        this.enableEpcotSubNavigation = subnavConfig.enableEpcotSubNavigation;
    }

    ngOnDestroy() {
        if (this.clientConfigLoadSubscription) {
            this.clientConfigLoadSubscription.unsubscribe();
        }
        if (this.promotedFeedSub) {
            this.promotedFeedSub.unsubscribe();
        }
        if (this.recentGamesSubscription) {
            this.recentGamesSubscription.unsubscribe();
        }
        if (this.unfinishedObv) {
            this.unfinishedObv.unsubscribe();
        }
    }
    setRpGyml() {
        if (this.isClientConfigLoaded) {
            this.featureConfig = this.configProviderService.provideFeaturesConfig();
            this.globalConfig = this.configProviderService.provideGlobalConfig();
            this.casinoConfig = this.configProviderService.provideCasinoConfig();
            this.arcadeLobbyConfig = this.configProviderService.provideAracadeLobbyConfig();
            this.arcadeLobbyMessages = this.configProviderService.provideGlobalConfig()?.arcadeLobbyConfigurations;
            this.youMightLikeMessage = this.globalConfig.messages['LobbyGamesYouMightLike']
                ? this.globalConfig.messages['LobbyGamesYouMightLike']
                : this.globalConfig.messages['GamesYouMightLike'];

            this.gymlCategory = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
            this.promotedGamesText = this.globalConfig.lobbyConfigurations['PromotedGamesCategoryname'];
            // if (this.gymlCategory) {
            //     this.gymlCategory.rowCount = '-1';
            // }

            this.recentCategory = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'recentgames');
            this.setGamesOnInit();
            this.promotedFeedSub = this.promotedGamesService.promotedFeedObservable.subscribe((games: any) => {
                if (games) {
                    this.setUnfinishedRPAndGYML(
                        this.promotedGamesService.getPromotedGames(),
                        this.unfinishedGameService.getUnfinishedGames(),
                        this.recentGameService.getrecentGames(),
                        this.bingoRecentGames,
                        this.getGymlGameIds(this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML')),
                    );
                }
            });
            this.recentGamesSubscription = this.recentGameService.recentGamesFeedObservable.subscribe((e: any) => {
                if (this.recentCategory && e) {
                    this.setUnfinishedRPAndGYML(
                        this.promotedGamesService.getPromotedGames(),
                        this.unfinishedGameService.getUnfinishedGames(),
                        this.recentGameService.getrecentGames(),
                        this.bingoRecentGames,
                        this.getGymlGameIds(this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML')),
                    );
                }
            });
            this.alignIconRight = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, this.featureConfig.alignIconRight);
            this.unfinishedObv = this.unfinishedGameService.unfinishedFeedObservable.subscribe((response) => {
                if (response) {
                    this.unfinishedGames = response;
                    this.setUnfinishedRPAndGYML(
                        this.promotedGamesService.getPromotedGames(),
                        response,
                        this.recentGameService.getrecentGames(),
                        this.bingoRecentGames,
                        this.getGymlGameIds(this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML')),
                    );
                }
            });

            if (this.recentCategory && this.recentCategory.categoryInfo) this.recentCategory.categoryInfo.categoryIndex = 1;
            if (this.gymlCategory && this.gymlCategory.categoryInfo) this.gymlCategory.categoryInfo.categoryIndex = 1;
            if (this.subcategory && this.subcategory.categoryInfo) this.subcategory.categoryInfo.categoryIndex = 1;
            this.isUkHeading = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, this.featureConfig.isUkHeading);
            this.isCrossSaleIOS = this.casinoIFrameOverlayService.isOverLayOnAProduct() && this.deviceService.isiOS;
            this.isSignPosting = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, this.featureConfig.enableSignpostingSticker);
            this.trackingClass = 'track_' + this.categoryId;
            //this.categoryObj = {...this.subcategory?.categoryInfo };
            this.categoryObj = Object.assign({}, this.subcategory?.categoryInfo, true);
            this.categoryObj.rowCount = '-1';
        }
    }

    setGamesOnInit() {
        const unfinishedGames = this.unfinishedGameService.getUnfinishedGames();
        const recentGames = this.recentGameService.getrecentGames();
        const promotedGames = this.promotedGamesService.getPromotedGames();
        this.gymlCategory = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
        let gymlGames: any;
        if (this.gymlCategory) {
            //this.gymlCategory.rowCount = '-1';
            gymlGames = this.getGymlGameIds(this.gymlCategory);
        }

        this.setUnfinishedRPAndGYML(promotedGames, unfinishedGames, recentGames, this.bingoRecentGames, gymlGames);
    }

    filterRecentlyPlayedGames(recentGames: any) {
        const categoryCoreLookUp: any = this.cacheManager.getAllCategoriesFromLocalCache('coinArcade', this.casinoManager.getInvokerProduct());
        const gamesLookUp: any = categoryCoreLookUp?.lsGameMetaDataMap?.map((x: any) => x && x[0]);
        return recentGames?.filter((x: any) => gamesLookUp?.includes(x)) || [];
    }

    getGymlGameIds(gymlCategory: any): ArcadeRgGameModel[] {
        const gymlGames: ArcadeRgGameModel[] = [];
        if (gymlCategory && gymlCategory.categoryInfo && gymlCategory.categoryInfo.gamelist && gymlCategory.categoryInfo.gamelist.length > 0) {
            for (let i = 0; i < gymlCategory.categoryInfo.gamelist.length; i++) {
                const gameModel: ArcadeRgGameModel = new ArcadeRgGameModel();
                gameModel.gameId = gymlCategory.categoryInfo.gamelist[i].game;
                if (gymlCategory.categoryInfo.gamelist[i].sticker) {
                    gameModel.sticker = gymlCategory.categoryInfo.gamelist[i].sticker;
                }
                gymlGames.push(gameModel);
            }
        }
        return gymlGames;
    }

    setUnfinishedRPAndGYML(
        promotedGames: string[],
        unfinishedGames: string[],
        recentGames: string[],
        bingoRecentGames: string[],
        gymlGames: ArcadeRgGameModel[],
    ) {
        const arcadeWidgetGamesConfig = this.arcadeLobbyConfig?.widgetGamesConfig;
        if (!unfinishedGames) {
            unfinishedGames = [];
        }
        if (!recentGames) {
            recentGames = [];
        }
        if (!bingoRecentGames) {
            bingoRecentGames = [];
        }
        if (!gymlGames) {
            gymlGames = [];
        }
        if (!promotedGames) {
            promotedGames = [];
        }
        recentGames = recentGames.length > 0 ? this.filterRecentlyPlayedGames(recentGames) : [];
        const unfinishedRecentArray: ArcadeRGGame[] = this.concatUnfinishedAndRecentGames(
            arcadeWidgetGamesConfig?.PromotedGames ? promotedGames : [],
            arcadeWidgetGamesConfig?.UnfinishedGames ? unfinishedGames : [],
            arcadeWidgetGamesConfig?.RecentlyPlayedGames ? recentGames : [],
            arcadeWidgetGamesConfig?.BingoRecentGames ? bingoRecentGames : [],
        );
        if (!this.user.isAuthenticated) {
            unfinishedRecentArray.forEach((item: any) => {
                item.isRecentlyPlayed = false;
            });
        }
        gymlGames = arcadeWidgetGamesConfig?.GymlGames ? gymlGames : [];
        this.unfinishedRPANDGYMLGameIds = this.concatUnfinishedRecentANDGYMLGames(unfinishedRecentArray, gymlGames);
        this.processRecentGYMLGames();
    }

    concatUnfinishedAndRecentGames(
        promotedGames: string[],
        unfinishedGames: string[],
        recentGames: string[],
        bingoRecentGames: string[],
    ): ArcadeRGGame[] {
        const unfinishedRecentArray: ArcadeRGGame[] = [];

        for (let i = 0; i < unfinishedGames.length; i++) {
            unfinishedRecentArray.push(new ArcadeRGGame(unfinishedGames[i], false, true, true));
        }
        for (let i = 0; i < recentGames.length; i++) {
            const filteredRecentGame: any = unfinishedRecentArray.filter((unfinished) => {
                return unfinished.GameId === recentGames[i];
            });
            if (!(filteredRecentGame.length > 0)) {
                const recentGame: ArcadeRGGame = new ArcadeRGGame(recentGames[i], false, true, true);
                unfinishedRecentArray.push(recentGame);
            }
        }
        for (let i = 0; i < promotedGames.length; i++) {
            const filteredPromotedGame: any = unfinishedRecentArray.filter((promotedGame) => {
                return promotedGame.GameId === promotedGames[i];
            });
            if (!(filteredPromotedGame.length > 0)) {
                const promotedGame: ArcadeRGGame = new ArcadeRGGame(promotedGames[i], true, true, true);
                unfinishedRecentArray.push(promotedGame);
            }
        }

        for (let i = 0; i < bingoRecentGames.length; i++) {
            const filteredBingoRecentGame: any = unfinishedRecentArray.filter((unfinishedRecentGame) => {
                return unfinishedRecentGame.GameId === bingoRecentGames[i];
            });
            if (!(filteredBingoRecentGame.length > 0)) {
                const recentGame: ArcadeRGGame = new ArcadeRGGame(bingoRecentGames[i], false, true, false);
                unfinishedRecentArray.push(recentGame);
            }
        }
        if (
            unfinishedRecentArray.length == promotedGames.length &&
            unfinishedGames.length == 0 &&
            recentGames.length == 0 &&
            bingoRecentGames.length == 0
        ) {
            this.recentTitle = this.promotedGamesText;
        } else {
            if (
                this.recentCategory &&
                this.recentCategory.categoryInfo &&
                this.recentCategory.categoryInfo.sitecoreData &&
                this.recentCategory.categoryInfo.sitecoreData.title
            ) {
                this.recentTitle = this.recentCategory.categoryInfo.sitecoreData.title;
            }
        }
        return unfinishedRecentArray;
    }

    concatUnfinishedRecentANDGYMLGames(unfinishedRecentGames: ArcadeRGGame[], gymlGames: ArcadeRgGameModel[]): ArcadeRGGame[] {
        const unfinishedRecentGYMLArray: ArcadeRGGame[] = unfinishedRecentGames;
        for (let i = 0; i < gymlGames.length; i++) {
            const gymlGameInRecentArray: any = unfinishedRecentGYMLArray.find((a) => a.GameId === gymlGames[i].gameId);
            if (!gymlGameInRecentArray) {
                const gymlItem: ArcadeRGGame = new ArcadeRGGame(gymlGames[i].gameId, false, false, true);
                gymlItem.sticker = gymlGames[i].sticker;
                unfinishedRecentGYMLArray.push(gymlItem);
            }
        }
        return unfinishedRecentGYMLArray;
    }

    processRecentGYMLGames() {
        this.sliceGYMLLimitAndSetBaseGameCount();
        this.setGames();
        this.setIsCombinedCategories();
        this.updateGYMLCategoryRowCount();
    }

    sliceGYMLLimitAndSetBaseGameCount() {
        const unfinishedOrRecentGames = this.unfinishedRPANDGYMLGameIds.filter((game: any) => game.isRecentlyPlayed);
        if (unfinishedOrRecentGames) {
            this.recentGamesCount = unfinishedOrRecentGames.length;
        }

        let gymlGames = this.unfinishedRPANDGYMLGameIds.filter((game: any) => !game.isRecentlyPlayed);
        gymlGames = gymlGames.slice(0, this.casinoConfig.gymlLimit);
        this.unfinishedRPANDGYMLGameIds = [];
        this.unfinishedRPANDGYMLGameIds = this.unfinishedRPANDGYMLGameIds.concat(unfinishedOrRecentGames);
        this.unfinishedRPANDGYMLGameIds = this.unfinishedRPANDGYMLGameIds.concat(gymlGames);
    }

    setGames() {
        if (this.unfinishedRPANDGYMLGameIds && this.unfinishedRPANDGYMLGameIds.length > 0) {
            const gamesModel: any[] = [];
            let GameModelItem: any;
            for (let i = 0; i < this.unfinishedRPANDGYMLGameIds.length; i++) {
                const gameInfo = this.casinoLobbyService.getGameInfo(this.unfinishedRPANDGYMLGameIds[i].GameId);
                if (!gameInfo) continue;
                if (gameInfo) {
                    const gameName = gameInfo.name;
                    const provider = gameInfo.provider;
                    const ownerId = gameInfo.ownerId;
                    GameModelItem = {
                        game: this.unfinishedRPANDGYMLGameIds[i].GameId,
                        name: gameName,
                        provider: provider,
                        ownerId: ownerId,
                        icon: '1',
                        isRecentlyPlayed: this.unfinishedRPANDGYMLGameIds[i].isRecentlyPlayed,
                        sticker: this.unfinishedRPANDGYMLGameIds[i].sticker,
                        fromCasino: this.unfinishedRPANDGYMLGameIds[i].fromCasino,
                        gameContainer: this.gameContainer,
                        isPromoted: this.unfinishedRPANDGYMLGameIds[i].isPromoted,
                    };
                } else if (!this.unfinishedRPANDGYMLGameIds[i].fromCasino) {
                    GameModelItem = {
                        game: this.unfinishedRPANDGYMLGameIds[i].GameId,
                        icon: '1',
                        isRecentlyPlayed: this.unfinishedRPANDGYMLGameIds[i].isRecentlyPlayed,
                        sticker: this.unfinishedRPANDGYMLGameIds[i].sticker,
                        fromCasino: this.unfinishedRPANDGYMLGameIds[i].fromCasino,
                        gameContainer: this.gameContainer,
                        lobbyType: this.lobbyType,
                    };
                }
                if (GameModelItem) {
                    gamesModel.push(GameModelItem);
                }
            }

            this.setDefaultLMTGameIcons(gamesModel);
        } else if (this.unfinishedRPANDGYMLGameIds && this.unfinishedRPANDGYMLGameIds.length === 0) {
            this.Games = [];
            this.unfinishedRPANDGYMLGames = [];
        }
    }

    setDefaultLMTGameIcons(gamesModellist: any[]) {
        this.Games = [];
        this.unfinishedRPANDGYMLGames = [];
        let starttileBgMode: boolean = false;
        const disabledGames: any = this.casinoLobbyService.getDisabledGames();
        const currentWidth = this.casinoManager.getCurrentWidth();
        const arcadeWidgetGamesConfig = this.arcadeLobbyConfig?.widgetGamesConfig;
        if (gamesModellist && gamesModellist.length > 0) {
            for (let i = 0; i < gamesModellist.length; i++) {
                const gameModelItem: any = gamesModellist[i];
                const filteredGame: any = disabledGames.filter((disabledGame: any) => disabledGame === gameModelItem.game);
                if (!(filteredGame && filteredGame.length > 0)) {
                    const gamelobbyModel: any = this.gameDataManager.setGameLobbyModel(gameModelItem, this.isTouch);
                    gamelobbyModel.isRecentlyPlayed = gamesModellist[i].isRecentlyPlayed;
                    gamelobbyModel.isUnfinished = arcadeWidgetGamesConfig?.UnfinishedGames ? this.getUnfinishedStatus(gamelobbyModel.gameId) : false;
                    gamelobbyModel.fromCasino = gamesModellist[i].fromCasino;
                    gamelobbyModel.infoIconClass = this.casinoManager.assignGameClass(currentWidth, '1');
                    gamelobbyModel.lobbyType = this.lobbyType;
                    gamelobbyModel.gameContainer = this.gameContainer;
                    gamelobbyModel.isPromoted = gamesModellist[i].isPromoted;
                    gamelobbyModel.provider = gamesModellist[i].provider;
                    gamelobbyModel.ownerId = gamesModellist[i].ownerId;
                    gamelobbyModel.trackCategory = this.categoryId;
                    if (this.subcategory && this.subcategory.categoryInfo) {
                        gamelobbyModel.gameTileBorderRadius = this.casinoLobbyService.getBorderRadius(this.subcategory.categoryInfo);
                        gamelobbyModel.gameTileDropShadow = this.casinoLobbyService.getDropShadow(this.subcategory.categoryInfo);
                        gamelobbyModel.bgTileMode = this.subcategory.categoryInfo.isIconSevenCategory
                            ? starttileBgMode
                                ? starttileBgMode
                                : false
                            : false;
                    }
                    // to avoid duplicate insertion, checking if gameId already exists or not
                    if (!this.Games.find((x) => x.gameId === gamelobbyModel.gameId)) {
                        this.Games.push(gamelobbyModel);
                    }
                }
                starttileBgMode = !starttileBgMode;
            }
            this.unfinishedRPANDGYMLGames = this.Games;
            const promotedGames = this.unfinishedRPANDGYMLGames.filter((game: any) => game.isPromoted);
            const nonPromotedGames = this.unfinishedRPANDGYMLGames.filter((game: any) => !game.isPromoted);
            this.unfinishedRPANDGYMLGames = [];
            this.unfinishedRPANDGYMLGames = this.unfinishedRPANDGYMLGames.concat(promotedGames);
            this.unfinishedRPANDGYMLGames = this.unfinishedRPANDGYMLGames.concat(nonPromotedGames);

            // let iconType = '1';
            // let currentWidth = this.casinoManager.getCurrentWidth();
            // this.unfinishedRPANDGYMLGames.forEach((game: any) => {
            //     game.infoIconClass = this.casinoManager.assignGameClass(currentWidth, iconType);
            // });
            let filteredgames: any = [];
            const recentGamesModel: any = gamesModellist.filter((gameModel) => gameModel.isRecentlyPlayed);
            filteredgames = this.removeDuplicategames(recentGamesModel);
            if (this.recentCategory) {
                this.recentCategory.categoryInfo.gamelist = filteredgames;
            }
        }
    }

    getUnfinishedStatus(gameId: string): boolean {
        const unfinishedGamesArray: any = this.unfinishedGameService.getUnfinishedGames();
        return unfinishedGamesArray.indexOf(gameId) != -1;
    }
    setIsCombinedCategories() {
        this.sliceGamesCount = this.getDeviceType() === 'Tablet' ? this.arcadeLobbyConfig.tabletRpCount : this.arcadeLobbyConfig.rpCount;
        this.showFeatureGrid.set(false);
        const recentGamesCountLimit = this.casinoConfig.recentGamesCountLimit;
        if (this.unfinishedRPANDGYMLGames && this.unfinishedRPANDGYMLGames.length > 0) {
            const recentGames = this.unfinishedRPANDGYMLGames.filter((game: any) => game.isRecentlyPlayed);
            const gymlGames = this.unfinishedRPANDGYMLGames.filter((game: any) => !game.isRecentlyPlayed);
            if (!this.evaluateIsCombined(recentGames, gymlGames)) {
                this.isCombinedCategories = false;

                if (recentGames.length === 0 && this.gymlCategory && gymlGames.length > 0) {
                    this.subcategory = this.gymlCategory;
                    this.unfinishedRPANDGYMLGames = this.unfinishedRPANDGYMLGames.filter((game: any) => !game.isRecentlyPlayed);
                    this.Games = this.removeDuplicategames(this.unfinishedRPANDGYMLGames);
                } else if ((recentGames.length >= recentGamesCountLimit || recentGames.length > 0) && this.recentCategory) {
                    this.subcategory = this.recentCategory;
                    this.unfinishedRPANDGYMLGames = this.unfinishedRPANDGYMLGames.filter((game: any) => game.isRecentlyPlayed);
                    this.Games = this.removeDuplicategames(this.unfinishedRPANDGYMLGames);
                }
                this.newFeatureGridArr = [];
                for (let i = 0; i < this.Games.length; i++) {
                    this.newFeatureGridArr.push(this.Games[i]);
                }
            } else {
                this.isCombinedCategories = true;
            }
        }
        this.sliceRpGames();
        this.cdr.detectChanges();
        this.showFeatureGrid.set(true);
    }

    sliceRpGames() {
        this.sliceFeatureGrid = this.newFeatureGridArr.slice(0, this.sliceGamesCount);
        this.remainingGameCount = this.newFeatureGridArr.length - this.sliceFeatureGrid.length;
    }

    evaluateIsCombined(recentGames: any, gymlGames: any): boolean {
        const recentGamesCountLimit = this.casinoConfig.recentGamesCountLimit;
        if (!recentGames || (recentGames && recentGames.length === 0)) {
            return false;
        }
        if (!gymlGames || (gymlGames && gymlGames.length === 0)) {
            return false;
        }
        if (recentGames && recentGames.length > 0 && recentGames.length > recentGamesCountLimit) {
            return false;
        }
        return true;
    }

    ngAfterViewInit() {
        if (document.getElementById('gymlId')) {
            this.maxScrollPosition = document.getElementById('gymlId')!.offsetLeft;
        }
        setTimeout(() => {
            if (this.recentGYMLCarousel) {
                this.recentGYMLCarousel.swiper.on('slideChange', (event: any) => {
                    this.recentStartIndex = event.activeIndex;
                });
            }
        }, 300);
    }

    configureGYMLScenarios(recentGames: any) {
        const recentGamesCountLimit = this.casinoConfig.recentGamesCountLimit;
        this.subcategory = undefined;
        if (recentGames.length === 0 || recentGames.length >= recentGamesCountLimit) {
            this.isCombinedCategories = false;
        } else {
            this.isCombinedCategories = true;
        }
        if (
            (recentGames.length > 0 && !this.gymlCategory) ||
            (this.gymlCategory && this.gymlCategory.gamesModel && this.gymlCategory.gamesModel.length === 0)
        ) {
            this.isCombinedCategories = false;
        }

        if (!this.isCombinedCategories && recentGames.length === 0 && this.gymlCategory) {
            this.subcategory = this.gymlCategory;
        } else if (
            (!this.isCombinedCategories && recentGames.length >= recentGamesCountLimit && this.recentCategory) ||
            (recentGames.length > 0 && this.recentCategory && !this.gymlCategory)
        ) {
            this.subcategory = this.recentCategory;
        }
    }

    setSubCategoryStyle() {
        if (this.isTouch) {
            return 'scrollable-games';
        }
        return 'unscrollable-games';
    }

    scrollGYML() {
        if (this.elementRef.nativeElement.getElementsByClassName('scrollable-games')[0].scrollLeft >= this.maxScrollPosition) {
            this.staticGYML = true;
            setTimeout(() => {
                const ele = document.getElementById('sticky-container');
                ele!.setAttribute('position', 'relative');
            }, 500);
        } else if (this.elementRef.nativeElement.getElementsByClassName('scrollable-games')[0].scrollLeft < this.maxScrollPosition) {
            this.staticGYML = false;
        }
    }

    removeDuplicategames(recentGamesModel: any[]): any {
        let filteredgames: any = [];
        if (recentGamesModel && recentGamesModel.length > 0) {
            // deleting duplicate game ids if any
            const uniqueValuesSet = new Set();
            filteredgames = recentGamesModel.filter((obj) => {
                const isPresentInSet = uniqueValuesSet.has(obj.game != undefined ? obj.game : obj.gameId);
                uniqueValuesSet.add(obj.game != undefined ? obj.game : obj.gameId);
                return !isPresentInSet;
            });
        }
        return filteredgames;
    }
    updateGYMLCategoryRowCount() {
        if (this.gymlCategory) {
            this.gymlCategoryRef = cloneDeep(this.gymlCategory);
            //    this.gymlCategoryRef = JSON.parse(JSON.stringify(this.gymlCategory));
            if (this.gymlCategoryRef.categoryInfo) this.gymlCategoryRef.categoryInfo.rowCount = '-1';
        }
    }
    openRpMatGrid(deviceType: string = '') {
        if (this.newFeatureGridArr && this.newFeatureGridArr.length > 0) {
            const rpMatGridData: any = {
                rpData:
                    deviceType == 'mobile'
                        ? this.newFeatureGridArr
                        : this.newFeatureGridArr.slice(this.sliceGamesCount, this.newFeatureGridArr.length),
                subcategory: this.subcategory,
                gameLaunchedFrom: 'gamecontainer',
                recentGamesCount: deviceType == 'mobile' ? 'not applicable' : this.remainingGameCount.toString(),
            };
            this.arcadeLobbyTrackingService.trackEvent(
                'Event.Tracking',
                'coinArcade',
                'immersive lobby',
                'click',
                deviceType == 'mobile' ? 'not applicable' : this.remainingGameCount.toString(),
                this.arcadeLobbyMessages?.RecentlyPlayedGames,
                'show more',
                'not applicable',
            );
            const dialogRef = this.dialog.open(RecentlyPlayedGameOverlayComponent, {
                panelClass: deviceType == 'mobile' ? 'arcade-recentlyplayed-gyml-mobile' : 'arcade-recentlyplayed-gyml-desktop',
                width: '790px',
                height: '779px',
                data: rpMatGridData,
            });
            dialogRef.afterClosed().subscribe(() => {});
        }
    }
    getDeviceType() {
        const width = window.innerWidth;
        if (width <= 480) {
            return 'Mobile';
        }
        if (width <= 1024) {
            return 'Tablet';
        }
        return 'Desktop';
    }
}
