import { Injectable } from '@angular/core';

import { CasinoLobbyService } from '../casino-lobby-manager/casino-lobby.service';
import { BingoGameLaunchService } from './bingo-vf-services/bingo-game-launch.service';
import { CasinoCoreGamelaunchService } from './gamelaunch.service';

@Injectable({
    providedIn: 'root',
})
export class ForceGameLaunchHandlerService {
    constructor(
        private casinoLobbyService: CasinoLobbyService,
        private casinoCoreGamelaunchService: CasinoCoreGamelaunchService,
        private bingoGameLaunchService: BingoGameLaunchService,
    ) {}

    onAppInit() {
        this.casinoLobbyService.forceGameLaunchObservable.subscribe(
            (response: any) => {
                if (response) {
                    if (response.gameLaunchParam.vfBingo) {
                        const params = response.gameLaunchParam;
                        this.bingoGameLaunchService.initiateGameLaunch(
                            params.gameName,
                            params.gameType,
                            params.ballType,
                            params.launchType,
                            params.gameIcon,
                            params.gameId,
                            params.favstatus,
                            params.origin,
                            params.gameObj,
                        );
                    } else {
                        this.casinoCoreGamelaunchService.launchGame(response.gameLaunchParam);
                    }
                }
            },
            () => {},
        );
    }
}
