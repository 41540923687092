import { Injectable } from '@angular/core';

import { MessageQueueService, NativeAppService, NativeEvent, UserService } from '@frontend/vanilla/core';
import { Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { CasinoApiService } from './casino-api.service';
import { GameLaunchUrlFormationService } from './gamelaunch-url-formation.service';
import { OpenLoginDialog } from './open-login-dialog-v6';

class NativeAppFeatures {
    topBar: string;
    footer: string;
    navigationBar: string;
    fixednav: string;
    nativeStyle: string;
    constructor() {
        this.topBar = 'topBar';
        this.footer = 'footer';
        this.navigationBar = 'navigationBar';
        this.fixednav = 'fixednav';
        this.nativeStyle = 'nativeStyle';
    }
}

@Injectable({
    providedIn: 'root',
})
export class NativeAppHelperService {
    nativeAppFeatures: NativeAppFeatures = new NativeAppFeatures();

    constructor(
        private nativeApplication: NativeAppService,
        private configProviderService: ConfigProviderService,
        private messageQueue: MessageQueueService,
        private casinoapi: CasinoApiService,
        private user: UserService,
        private loginDialog: OpenLoginDialog,
        private gameLaunchUrlFormationService: GameLaunchUrlFormationService,
    ) {}

    isNativeFeatureEnabled(featureName: string) {
        switch (featureName) {
            case this.nativeAppFeatures.topBar:
            case this.nativeAppFeatures.footer:
            case this.nativeAppFeatures.navigationBar:
                if (!this.nativeApplication.isNativeApp || this.nativeApplication.isNativeWrapper) {
                    return true;
                }
                break;
            case this.nativeAppFeatures.fixednav:
                if (!this.nativeApplication.isNativeApp && !this.nativeApplication.isNativeWrapper) {
                    return true;
                }
                break;
            case this.nativeAppFeatures.nativeStyle:
                if (this.nativeApplication.isNativeApp) {
                    return true;
                }
                break;
        }
        return false;
    }

    handleNativeGameLaunch(params: any, gameLaunchModel: any, callback?: any): void {
        if (this.user.isAuthenticated) {
            this.launchGameForNativeApp(params, gameLaunchModel, callback);
        } else {
            this.loginDialog.open();
        }
    }

    launchGameForNativeApp(params: any, gameLaunchModel: any, callback?: any): void {
        const globalConfig = this.configProviderService.provideGlobalConfig();
        this.getLaunchData(params).subscribe(
            (response) => {
                if (response) {
                    if (response.redirect && response.redirect.indexOf('/unfinished') > -1) {
                        const message = globalConfig.messages['UnfinishedGames'];
                        if (message) {
                            this.messageQueue.addError(message);
                        }
                    } else {
                        const redirectUrl = this.gameLaunchUrlFormationService.launchMGSGame(response, gameLaunchModel);
                        response.redirect = redirectUrl;
                        if (callback) {
                            const callbackResponse = {
                                isError: false,
                                errorMessage: '',
                                gameLaunchUrl: redirectUrl,
                            };
                            callback(callbackResponse);
                        } else {
                            this.raiseNativeGameLaunchEvent(response.redirect);
                        }
                    }
                }
            },
            (error) => {
                if (error && error.data && error.data.common && error.data.common.messages && error.data.common.messages[0]) {
                    if (callback) {
                        const callbackResponse = {
                            isError: true,
                            errorMessage: error.data.common.messages[0],
                            gameLaunchUrl: '',
                        };
                        callback(callbackResponse);
                    } else {
                        this.messageQueue.addError(error.data.common.messages[0]);
                    }
                }
            },
        );
    }

    getLaunchData(params: any): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.casinoapi.get(apiPathsConfig.gameData['gameLaunchData'], params);
    }

    raiseNativeGameLaunchEvent(gameLaunchUrl: string): void {
        const event: NativeEvent = {
            eventName: 'Native_Game_Launch',
            parameters: {
                gameLaunchUrl: gameLaunchUrl,
            },
        };
        this.nativeApplication.sendToNative(event);
    }
}
