import { AfterViewInit, Component, DestroyRef, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { CasinoGridOnLobbyTypeComponent } from '@casinocore/platform';
import { CasinoLobbyService } from '@casinocore/platform/core';
import { Subscription } from 'rxjs';

import { LobbyService } from '../../shared';
import { PerformanceMarker, PerformanceMarkerIdentifiers } from '../../shared/services/performance-marker.service';

@Component({
    selector: 'cs-casino-games-section',
    templateUrl: 'casino-games-section.component.html',
    standalone: true,
    imports: [CasinoGridOnLobbyTypeComponent],
})
export class CasinoGamesSectionComponent implements OnInit, OnDestroy, AfterViewInit {
    categoryChangeObs: Subscription;
    routeCurrentProduct: string;
    routeCategoryType: string;
    @Input() lobbyType: string;

    constructor(
        private lobbyService: LobbyService,
        private casinoLobbyService: CasinoLobbyService,
        private performanceMarker: PerformanceMarker,
        private destroyRef: DestroyRef,
    ) {}

    ngOnInit() {
        this.categoryChangeObs = this.lobbyService.categoryChangeObservable
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((productAndCat: any) => {
                if (productAndCat) {
                    this.routeCurrentProduct = productAndCat.routeCurrentProduct;
                    this.setRouteCategory(productAndCat.routeCategoryType);
                }
            });
    }

    ngAfterViewInit(): void {
        this.performanceMarker.mark(PerformanceMarkerIdentifiers.TimeToInteractivity);
    }

    ngOnDestroy() {
        if (this.categoryChangeObs) {
            this.categoryChangeObs.unsubscribe();
        }
    }

    setRouteCategory(routeCategory: string) {
        if (!routeCategory || routeCategory === '') {
            const defaultCatInfo: any = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(this.lobbyType);
            if (defaultCatInfo && defaultCatInfo.categoryInfo) {
                this.routeCategoryType = defaultCatInfo.categoryInfo.categoryid;
            }
        } else {
            this.routeCategoryType = routeCategory;
        }
    }
}
