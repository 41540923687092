<div
    *ngIf="
        ((teaserVerticalPosition | lowercase) == 'title-first' || (verticalPosition | lowercase) == 'title-first') &&
        (teaserVerticalPosition | lowercase) != 'subtitle-first'
    "
    class="title">
    <div *ngIf="isJackpot && jackpotValue">{{ jackpotValue.jackpotGroupName }}</div>
    <div *ngIf="!isJackpot" [vnDynamicHtml]="teaserTitle"></div>
</div>
<div class="subtitle">
    <div *ngIf="isJackpot && jackpotValue">{{ jackpotValue.amount }}</div>
    <div *ngIf="!isJackpot" [vnDynamicHtml]="teaserSubTitle"></div>
</div>
<div *ngIf="(teaserVerticalPosition | lowercase) == 'subtitle-first' || (verticalPosition | lowercase) == 'subtitle-first'" class="title">
    <div *ngIf="isJackpot && jackpotValue">{{ jackpotValue.jackpotGroupName }}</div>
    <div *ngIf="!isJackpot" [vnDynamicHtml]="teaserTitle"></div>
</div>
