import { NgModule } from '@angular/core';

import { NewGameQuickInfoComponent } from './new-game-quick-info.component';

@NgModule({
    imports: [NewGameQuickInfoComponent],
    exports: [NewGameQuickInfoComponent],
})
export class NewGameQuickInfoModule {
    static rootComponent = NewGameQuickInfoComponent;
}
