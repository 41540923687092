import { Injectable } from '@angular/core';

import { Message, MessageLifetime, MessageQueueService, MessageType } from '@frontend/vanilla/core';
import { Observable } from 'rxjs';

import { PlatformApiService } from '../platform-api/platform-api.service';
import { ISuperButton } from './models/super-button.model';

@Injectable({
    providedIn: 'root',
})
export class SuperButtonService {
    constructor(
        private api: PlatformApiService,
        private messageQueue: MessageQueueService,
    ) {}

    findFromCategory = (paths: Array<string>): Observable<ISuperButton> => {
        return this.api.post('SuperButton/FindFromCategory', paths, {
            responseType: 'json',
            withCredentials: true,
            showSpinner: false,
        });
    };

    findFromComposition = (isDefaultComposition: boolean, containerPath: string): Observable<ISuperButton> => {
        return this.api.post(
            'SuperButton/FindFromComposition',
            { isDefaultComposition, containerPath },
            { responseType: 'json', withCredentials: true, showSpinner: false },
        );
    };

    addError = (errorMessage: string): void => {
        const message: Message = {
            html: errorMessage,
            type: MessageType.Error,
            lifetime: MessageLifetime.Single,
            scope: 'casinocorelobby',
        };
        if (message) {
            this.messageQueue.add(message);
            setTimeout(() => this.messageQueue.clear(), 1000);
        }
    };
}
