import { NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, OnInit, computed, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

import { CasinoManager, ConfigProviderService, GamificationWidgetConfig, SlotRacesService } from '@casinocore/platform/core';
import { DeviceService, UserService, WindowRef } from '@frontend/vanilla/core';
import { distinctUntilChanged, filter, of, startWith } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ArcadeRecentlyPlayedandGymlComponent } from '../arcadeRecentlyPlayedandGYML/arcade-recentlyPlayed-gyml.component';
import { CasinoCoreLazyPlaceholderComponent } from '../casino-core-lazy-placeholder/casino-core-lazy-placeholder.component';
import { LMTSubCategoryLobbyComponent } from '../lmt-sub-category-lobby/lmt-sub-category-lobby.component';
import { RecentlyPlayedandGymlComponent } from '../recentlyPlayedandGYML/recentlyPlayed-gyml.component';

@Component({
    selector: 'cc-lmt-sub-category-wrapper',
    templateUrl: 'lmt-sub-category-wrapper.component.html',
    standalone: true,
    imports: [
        NgSwitch,
        NgSwitchCase,
        NgIf,
        RecentlyPlayedandGymlComponent,
        CasinoCoreLazyPlaceholderComponent,
        NgClass,
        NgSwitchDefault,
        LMTSubCategoryLobbyComponent,
        ArcadeRecentlyPlayedandGymlComponent,
    ],
})
export class LMTSubCategoryWrapperComponent implements OnInit {
    private features = this.configProviderService.provideFeaturesConfig();
    private promoOfferConfig: any = this.configProviderService.providePromoOfferWidgetConfig();
    private gamificationWidgetConfig: GamificationWidgetConfig = this.configProviderService.provideGamificationWidgetConfig();

    trackingIndex = input.required<number>();
    coinExpiryIndex = input.required<number>();
    subcategory = input.required<any>();
    isTouch = input.required<boolean>();
    lobbyType = input.required<string>();
    gameContainer = input.required<string>();
    seeAllProductLinkOverride = input.required<string>();
    categoryInfo = computed(() => this.subcategory()?.categoryInfo);
    subcategoryid = computed(() => this.categoryInfo()?.categoryid);
    rpPositionLmtKey = computed(() =>
        this.casinoManager.IsFeatureEnabledforLobbytypeWithInvokerProduct(this.lobbyType(), this.features.rpPositionLmtKey),
    );
    isSlotRacesEnabled = computed(() => this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType(), this.features.enableSlotRacesForLobbyType));
    cozyBingoWidgetKey = computed(() => {
        const cozyBingoConfig: any = this.configProviderService.provideCozyBingoConfig();
        return this.casinoManager.IsFeatureEnabledforLobbytypeWithInvokerProduct(this.lobbyType(), cozyBingoConfig.cozyBingoWidgetPosition);
    });
    hideRpGyml = toSignal(
        this.user.events.pipe(
            map(() => this.user.isAuthenticated),
            startWith(this.user.isAuthenticated),
            distinctUntilChanged(),
            filter((authenticated) => !authenticated),
            map(() => !this.features.enablePreLoginRecentlyPlayed),
        ),
        { initialValue: false },
    );
    promoOfferWidgetKey = computed(() =>
        this.casinoManager.IsFeatureEnabledforLobbytypeWithInvokerProduct(this.lobbyType(), this.promoOfferConfig.promoOfferWidgetPosition),
    );
    promoWidgetEnabled = this.promoOfferConfig.enablePromoOfferWidget;
    promoProducts = this.promoOfferConfig.products;
    loadLMTCategory = toSignal(
        toObservable(
            computed(() => {
                return (
                    this.isSlotRacesEnabled() &&
                    this.categoryInfo().parentCategoryId + this.casinoManager.getSlotRaceCategoryId(this.lobbyType()) === this.subcategoryid()
                );
            }),
        ).pipe(
            switchMap((updateOnNavigation) => {
                if (updateOnNavigation) {
                    return this.slotRacesService.updateNavigation.pipe(
                        filter(Boolean),
                        map(() => {
                            const slotRacesConfig = this.configProviderService.provideSlotRacesConfig();
                            const slotRacesData = this.slotRacesService.getCurrentRaces();
                            if (slotRacesConfig.disableWhenNoPromotions) {
                                return (
                                    (slotRacesData.liveNow && slotRacesData.liveNow.length) ||
                                    (slotRacesData.upcomingToday && slotRacesData.upcomingToday.length) ||
                                    (slotRacesData.upcomingTomorrow && slotRacesData.upcomingTomorrow.length)
                                );
                            } else {
                                return true;
                            }
                        }),
                    );
                }
                return of(true);
            }),
        ),
        { initialValue: false },
    );
    gamificationWidgetEnabled: boolean = this.gamificationWidgetConfig?.enableGamificationWidget;
    arcadeLobbyGameConfig: string = this.configProviderService.provideGameConfig()?.arcadeLobbyType.toLowerCase();
    currentLobbyType = computed(() => this.lobbyType().toLowerCase());
    gamificationWidgetKey = computed(() =>
        this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType(), this.gamificationWidgetConfig?.enableGamificationForLobbyType),
    );

    constructor(
        private casinoManager: CasinoManager,
        private configProviderService: ConfigProviderService,
        private user: UserService,
        private slotRacesService: SlotRacesService,
        private deviceService: DeviceService,
        private readonly windowRef: WindowRef,
    ) {}

    ngOnInit() {
        if (this.gamificationWidgetConfig && this.gamificationWidgetEnabled) {
            this.windowRef.nativeWindow['gamification_initialized'] = false;
            sessionStorage.removeItem('gamification_is_loading');
            if (this.deviceService.isMobile) {
                dispatchEvent(new CustomEvent('gs:showFloatingEntry'));
            } else {
                dispatchEvent(new CustomEvent('gs:showHeaderEntry'));
            }
        }
    }
}
