<div class="casino-opt-mini-teasers-item">
    <div class="item-slide" (click)="onTeaserClick(clickEventTypes.Teaser, $event)" [ngClass]="customClass">
        <div class="opt-mini-teaser-body" [style.height.px]="iconBasedHeight">
            <cc-opt-teaser-bg-image-npm [teaser]="miniTeaserItem" [isMobileImage]="deviceName"> </cc-opt-teaser-bg-image-npm>
            <div [ngClass]="'opt-teaser-text-' + (horizontalPosition | lowercase)">
                <div
                    class="opt-teaser-info text-align-center"
                    [style.background]="isOptTeaserPhase3 ? teaserDefaultContentBg : ''"
                    [style.color]="miniTeaserItem.miniTeaserAttributes != '' ? miniTeaserItem.miniTeaserAttributes.TextColor : ''">
                    <cc-opt-teaser-text-position-npm
                        [teaser]="miniTeaserItem"
                        [teaserTitle]="miniTeaserItem.teaserTitle"
                        [teaserSubTitle]="miniTeaserItem.subTitle"
                        [verticalPosition]="verticalPosition"
                        [teaserVerticalPosition]="miniTeaserItem.textVerticalPosition"
                        [isNPM]="isNPM"
                        [teaserDynaconConfigs]="teaserDynaconConfigs"
                        [globalTeaserSettings]="globalTeaserSettings"
                        [showJackpotCurrencyOnRight]="showJackpotCurrencyOnRight"
                        [jackpotGroupNameMapTeaserNPM]="jackpotGroupNameMapTeaserNPM">
                    </cc-opt-teaser-text-position-npm>
                    <div class="opt-teaser-cta" *ngIf="miniTeaserItem.cta != null">
                        <!-- <a
                            class="btn"
                            (click)="onTeaserClick(clickEventTypes.PrimaryCTA, $event)"
                            [ngClass]="{ 'epcot-btn': enableEpcotSubNavigation }"
                            [innerHtml]="miniTeaserItem.cta.text | trustAsHtml"
                            [style.color]="miniTeaserItem.miniTeaserAttributes != '' ? miniTeaserItem.miniTeaserAttributes.CtaColor : ''"
                            [style.borderColor]="miniTeaserItem.miniTeaserAttributes != '' ? miniTeaserItem.miniTeaserAttributes.CtaColor : ''"></a> -->
                        <cs-gametile-cta
                            [isLegacy]="isLegacy"
                            [kind]="'primary'"
                            [size]="'large'"
                            [variant]="'filled'"
                            [csDecoratorClass]="{ 'epcot-btn': enableEpcotSubNavigation }"
                            [customTextColor]="miniTeaserItem.miniTeaserAttributes != '' ? miniTeaserItem.miniTeaserAttributes.CtaColor : ''"
                            (click)="onTeaserClick(clickEventTypes.PrimaryCTA, $event)"
                            [cta_text]="miniTeaserItem.cta.text">
                        </cs-gametile-cta>
                    </div>
                    <div class="opt-teaser-cta" *ngIf="isOptTeaserPhase3 && miniTeaserItem.secondaryCTA != null">
                        <!-- <a
                            class="btn btn-sec"
                            (click)="onTeaserClick(clickEventTypes.SecondaryCTA, $event)"
                            [ngClass]="{ 'epcot-btn': enableEpcotSubNavigation }"
                            [innerHtml]="miniTeaserItem.secondaryCTA.text | trustAsHtml"
                            [style.color]="miniTeaserItem.miniTeaserAttributes != '' ? miniTeaserItem.miniTeaserAttributes.CtaColor : ''"
                            [style.borderColor]="miniTeaserItem.miniTeaserAttributes != '' ? miniTeaserItem.miniTeaserAttributes.CtaColor : ''"></a> -->
                        <cs-gametile-cta
                            [isLegacy]="isLegacy"
                            [kind]="'secondary'"
                            [size]="'large'"
                            [variant]="'filled'"
                            [csDecoratorClass]="'btn-sec'"
                            [csDecoratorClass]="{ 'epcot-btn': enableEpcotSubNavigation }"
                            [customTextColor]="miniTeaserItem.miniTeaserAttributes != '' ? miniTeaserItem.miniTeaserAttributes.CtaColor : ''"
                            (click)="onTeaserClick(clickEventTypes.SecondaryCTA, $event)"
                            [cta_text]="miniTeaserItem.secondaryCTA.text">
                        </cs-gametile-cta>
                    </div>
                </div>
                <div *ngIf="miniTeaserItem.significantTermsandConditions" class="small-terms" (click)="onTeaserClick(clickEventTypes.Terms, $event)">
                    <div
                        class="text-terms"
                        [ngClass]="'text-align-' + termsTextPosition | lowercase"
                        [innerHTML]="miniTeaserItem.significantTermsandConditions | trustAsHtml"></div>
                </div>
            </div>
        </div>
    </div>
</div>
