import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { CasinoLobbyService, LobbyItem } from '@casinocore/platform/core';
import { Subscription } from 'rxjs';

import { SubNavigationMenuComponent } from '../navigation-bar/sub-navigation-menu/sub-navigation-menu.component';

@Component({
    selector: 'cc-sub-nav-on-lobby-type',
    templateUrl: 'sub-nav-on-lobby-type.component.html',
    standalone: true,
    imports: [SubNavigationMenuComponent],
})
export class SubNavOnLobbyTypeComponent implements OnInit, OnDestroy, OnChanges {
    @Input() lobbyType: string;
    @Input() categoryRoute: string;
    @Input() lobbyProduct: string;
    @Input() stickyInputQueryElement: string;
    isLobbyLoaded: boolean;
    lobbyLoadSubscription: Subscription;

    constructor(private casinoLobbyService: CasinoLobbyService) {}

    ngOnInit() {
        this.lobbyLoadSubscription = this.casinoLobbyService.lobbyLoadedObservable.subscribe((response) => {
            if (response) {
                this.isLobbyLoaded = true;
                if (this.lobbyType && (!this.categoryRoute || this.categoryRoute === '')) {
                    this.setCategoryRoute();
                }
            }
        });
    }

    ngOnChanges() {
        if (this.isLobbyLoaded) {
            if (this.lobbyType && (!this.categoryRoute || this.categoryRoute === '')) {
                this.setCategoryRoute();
            }
        }
    }

    setCategoryRoute() {
        const lobbyInfo: LobbyItem = this.casinoLobbyService.getLobbyInfo(this.lobbyType) || new LobbyItem();
        if (lobbyInfo.categoriesMap && lobbyInfo.categoriesMap.size > 0) {
            this.categoryRoute = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(this.lobbyType).categoryInfo.categoryid; // lobbyInfo.categoriesMap.entries().next().value[1].categoryid;
        }
    }

    ngOnDestroy() {
        if (this.lobbyLoadSubscription) {
            this.lobbyLoadSubscription.unsubscribe();
        }
    }
}
