import { NgFor, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { NativeAppService, NavigationService, Page, WindowRef } from '@frontend/vanilla/core';

import { ConfigProviderService } from '../../config-provider/config-provider.service';
import { CasinoManager } from '../../shared/casino-manager.service';
import { GDSTsMsg, RetryType } from '../coordinator.models';
import { GeoLocationValidationService } from '../services/geolocation-validation.service';

export enum TriggerGeoLocationOption {
    EventName = 'TRIGGER_GEO_LOCATION',
    Action = 'fetchLocation',
}

export enum GeoLocationOptionFetchType {
    NativeCcb = 'NATIVE_CCB',
    Gds = 'GDS',
}

@Component({
    selector: 'cc-geo-location-error',
    templateUrl: 'geo-location-error.html',
    standalone: true,
    imports: [NgFor, NgIf],
})
export class GeoLocationErrorComponent implements OnInit {
    geoLocationErrorMessages: GDSTsMsg[] = [];
    shouldRetryLocation = false;
    geolocationConfig: any;
    errorMessages: GDSTsMsg[] = [];
    constructor(
        public readonly dialogRef: MatDialogRef<GeoLocationErrorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private geoLocationValidationService: GeoLocationValidationService,
        private nativeAppService: NativeAppService,
        private configProviderService: ConfigProviderService,
        private navigator: NavigationService,
        private casinoManager: CasinoManager,
        private m2Page: Page,
        private route: ActivatedRoute,
        private windowRef: WindowRef,
    ) {}

    ngOnInit(): void {
        this.errorMessages = this.data.errorMessages;
        this.geolocationConfig = this.configProviderService.provideGeoLocationConfig();
        this.checkGeoLocationError();
    }

    getGlobalConfig(): any {
        return this.configProviderService.provideGlobalConfig();
    }

    get geoComplyErrorHeader(): string {
        return this.getGlobalConfig().messages['GeoComplyErrorHeader'] || 'We Cannot Place Your Bet';
    }

    get turnOnWiFiDescription(): string {
        return this.getGlobalConfig().messages['TurnOnWiFiDescription'];
    }

    get addressAboveItemsDescription(): string {
        return this.getGlobalConfig().messages['AddressAboveItemsDescription'] || 'Please address the items above, then try  again';
    }

    get recheckLocation(): string {
        return this.getGlobalConfig().messages['RecheckLocation'] || 'RECHECK LOCATION';
    }

    get ok(): string {
        return this.getGlobalConfig().messages['Ok'] || 'OK';
    }

    get invalidLocation(): string {
        return this.getGlobalConfig().messages['InvalidLocation'] || 'Ensure you are well within a permitted area to place your bet successfully';
    }

    close(): void {
        this.dialogRef && this.dialogRef.close();
        if (this.windowRef.nativeWindow.location?.pathname?.toLowerCase()?.indexOf('launchng') >= 0) {
            const returnUrl = this.route?.snapshot?.queryParamMap?.get('returnUrl') || this.route?.snapshot?.queryParamMap?.get('returnurl');
            if (returnUrl) {
                this.navigator?.goTo(returnUrl);
            } else {
                const lobbyType = this.casinoManager?.getLobbyType() || '';
                this.navigator?.goTo('/' + this.m2Page.lang + '/' + this.casinoManager?.getProductForLobbyType(lobbyType)?.toLowerCase());
            }
        }
    }

    reCheckLocation(): void {
        if (this.geolocationConfig.shouldCheckNativeGeoLocation) {
            this.fetchLocationDetailsfromNativeEvent();
        } else {
            this.geoLocationValidationService.reCheckLocationClicked.next(true);
        }
        this.close();
    }

    private checkGeoLocationError(): void {
        if (!this.errorMessages?.length) {
            this.geoLocationErrorMessages.push({ rule: '', retry: RetryType.NonRetry, message: this.invalidLocation });
            return;
        }
        this.geoLocationErrorMessages = [...this.errorMessages];
        this.shouldRetryLocation =
            this.geolocationConfig.isRetryButtonEnabled && this.errorMessages.some((errMsg) => errMsg.retry === RetryType.Retry);
    }

    private fetchLocationDetailsfromNativeEvent(): void {
        this.nativeAppService.sendToNative({
            eventName: TriggerGeoLocationOption.EventName,
            parameters: { action: TriggerGeoLocationOption.Action },
        });
    }
}
