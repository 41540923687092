import {
    Compiler,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Injectable,
    Injector,
    NgModuleFactory,
    NgModuleRef,
    Type,
} from '@angular/core';

import { BingoNpmParams } from '@frontend/bingo/core';
import { BehaviorSubject, Observable } from 'rxjs';

type ModuleWithDynamicComponents = Type<any> & {
    dynamicComponentsMap: Record<string, any>;
};

@Injectable({
    providedIn: 'root',
})
export class BingoloaderService {
    ngModuleRef: NgModuleRef<any>;
    ngModuleFactory: NgModuleFactory<any>;
    componentFactories: ComponentFactory<any>[];
    componentFactoryResolver: ComponentFactoryResolver;
    bingoNpmManagerService: any;
    bingoManagerService: any;
    onAppInitHandler: any;
    bingoModuleLoadedSubject = new BehaviorSubject<boolean>(false);
    bingoModuleLoadedObservable: Observable<boolean> = this.bingoModuleLoadedSubject.asObservable();
    bingoModuleLoaded: boolean;

    constructor(
        private injector: Injector,
        private compiler: Compiler,
    ) {}

    loadBingoNpmModule(environment: string, configs: any): void {
        // const isODR: boolean = this.nativeAppService?.applicationName?.toLowerCase() === 'casinowodr';
        // if (!isODR)
        this.lazyloader(environment, configs);
    }

    getLazyComponentFactory(componentName: string): ComponentFactory<any> {
        const componentType = (this.ngModuleFactory.moduleType as ModuleWithDynamicComponents).dynamicComponentsMap[componentName];
        const componentFactory = this.ngModuleRef.componentFactoryResolver.resolveComponentFactory(componentType);
        return componentFactory;
    }

    async GetComponent(componentName: string): Promise<ComponentRef<any> | void> {
        try {
            const componentType = await (this.ngModuleFactory.moduleType as ModuleWithDynamicComponents).dynamicComponentsMap[componentName];
            const componentFactory = this.ngModuleRef.componentFactoryResolver.resolveComponentFactory(componentType);
            return componentFactory.create(this.injector);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }

    isBingoModuleLoaded(): boolean {
        return this.bingoModuleLoaded;
    }

    getBingoNpmManagerService(): any {
        return this.bingoNpmManagerService;
    }

    lazyloader(env: string, configs: any) {
        import('./bingo-frontend-loader/frontend-bingo-loader.module')
            .then((m) => m.FrontendBingoLoaderModule)
            .then((moduleOrFactory) => {
                if (moduleOrFactory instanceof NgModuleFactory) {
                    return moduleOrFactory;
                } else {
                    return this.compiler.compileModuleAsync(moduleOrFactory);
                }
            })
            .then((moduleFactory) => {
                this.ngModuleFactory = moduleFactory;
                this.ngModuleRef = moduleFactory.create(this.injector);
                this.bingoManagerService = this.ngModuleRef.injector.get((<any>moduleFactory.moduleType).bingoManagerService);
                this.bingoNpmManagerService = this.ngModuleRef.injector.get((<any>moduleFactory.moduleType).bingoNpmManagerService);
                this.onAppInitHandler = this.ngModuleRef.injector.get((<any>moduleFactory.moduleType).onAppInitHandler);
                this.onAppInitHandler.execute();

                const bingoNpmParams: BingoNpmParams = new BingoNpmParams();
                bingoNpmParams.isDll = false;
                if (configs) {
                    bingoNpmParams.disableSchedulePostCall = configs.disableSchedulePostCall;
                    bingoNpmParams.nodeEnvironment = env;
                    bingoNpmParams.localEnvironment = configs.localEnvironment;
                    bingoNpmParams.isEnabledonServerSide = configs.isEnabledonServerSide;
                }
                this.bingoNpmManagerService.intiateBingoLobby(bingoNpmParams);

                this.bingoModuleLoaded = true;
                this.bingoModuleLoadedSubject.next(true);
            });
    }
}
