<div
    class="casino-mustgo"
    *ngIf="showMustGo && MustGoSitecoreObject && MustGoSubJackpotsList && MustGoFeedObject && games"
    [ngStyle]="{ 'background-color': bcolor }"
    (window:resize)="onResize()"
    [ngClass]="{
        'casino-ios-bottom-nav-bar': isCrossSaleIOS,
        'casino-mustgo-one': MustGoSubJackpotsList.length == 2,
        'casino-mustgo-two': MustGoSubJackpotsList.length == 3,
        'casino-jackpot-widget-container': changeCarouselView
    }">
    <div
        class="casino-mustgo1"
        *ngIf="windowWidth() < 768 && showMustGo && !isMustgoRedesign"
        [ngClass]="{ 'mustGo-ls': windowWidth() >= 480 && windowWidth() < 768 }">
        <div class="mustgo-wrapper">
            <div class="casino-top-logo casino-dsktp-logo left-padding" [ngClass]="{ 'top-log-ls': windowWidth() >= 480 && windowWidth() < 768 }">
                <img src="{{ MustGoSitecoreObject.mustGoLogo.src }}" />
            </div>
            <div class="mustgo-carousel" [ngClass]="{ 'mustGo-carousel-ls': windowWidth() >= 480 && windowWidth() < 768 }">
                <ngu-carousel [inputs]="mobileCarouselTile" [dataSource]="MustGoSubJackpotsList" (onMove)="onmoveFn($event)">
                    <ngu-tile *nguCarouselDef="let game">
                        <div class="casino-subjackpot lastchild ribbon-ticker-jpk">
                            <div class="casino-ribbon-container one">
                                <div class="bk l" [ngStyle]="{ 'background-color': ribbonsidecolor }">
                                    <div class="casino-arrow top" [ngStyle]="{ 'border-right-color': ribbonsidecolor }"></div>
                                    <div class="caino-arrow bottom" [ngStyle]="{ 'border-right-color': ribbonsidecolor }"></div>
                                </div>
                                <div class="casino-skew l" [ngStyle]="{ background: ribbontrianglecolor }"></div>
                                <div class="casino-ribbon-main" [ngStyle]="{ 'background-color': ribboncolor }">
                                    {{ game.ribbonName }}
                                </div>
                                <div class="casino-skew r" [ngStyle]="{ background: ribbontrianglecolor }"></div>
                                <div class="bk r" [ngStyle]="{ 'background-color': ribbonsidecolor }">
                                    <div class="arrow top" [ngStyle]="{ 'border-left-color': ribbonsidecolor }"></div>
                                    <div class="casino-arrow bottom" [ngStyle]="{ 'border-left-color': ribbonsidecolor }"></div>
                                </div>
                            </div>
                            <span></span>
                            <div class="ticker">
                                <cc-flipclock
                                    [isMega]="'false'"
                                    [currency]="currency"
                                    [amount]="game.subJackpotValue"
                                    [releaseamount]="game.releaseAmount">
                                </cc-flipclock>
                            </div>
                        </div>
                        <div style="clear: both"></div>
                        <!-- mustgo clock -->
                        <div
                            class="casino-mustgo-clock"
                            *ngIf="MustGoProperties.dailyjackpotname == game.subJackpotName"
                            [ngStyle]="{ display: isClockVisible === true ? 'block' : 'none' }">
                            <span class="clock-img theme-clock"></span>
                            <span class="casino-clock-time">{{ releaseTime }}</span>
                        </div>
                    </ngu-tile>
                    <span
                        NguCarouselPrev
                        *ngIf="!isPreButtonVisible && changeCarouselView"
                        class="seeallarrows button-left-multi-changeview-mobile"
                        [ngStyle]="{ 'color': subCategoryTextColor, 'font-family': lobbyTileFontFamily }">
                        <span class="arrows-left" [ngClass]="themeArrowLeft" [ngStyle]="{ 'pointer-events': 'auto' }"></span>
                    </span>
                    <span
                        NguCarouselNext
                        *ngIf="!isNextButtonVisible && changeCarouselView"
                        class="seeallarrows button-right-multi-changeview-mobile"
                        [ngStyle]="{ 'color': subCategoryTextColor, 'font-family': lobbyTileFontFamily }">
                        <span class="arrows-right" [ngClass]="themeArrowRight" [ngStyle]="{ 'pointer-events': 'auto' }"></span>
                    </span>
                    <button
                        [ngClass]="{ 'hidden-cls': isPreButtonVisible || changeCarouselView }"
                        NguCarouselPrev
                        class="theme-left leftRs carousel-btn must-go-carousel-btn">
                        &nbsp;
                    </button>
                    <button
                        [ngClass]="{ 'hidden-cls': isNextButtonVisible || changeCarouselView }"
                        NguCarouselNext
                        class="theme-right rightRs carousel-btn must-go-carousel-btn">
                        &nbsp;
                    </button>
                </ngu-carousel>
            </div>
        </div>
    </div>
    <!-- 786 and above ipad / ipad pro -->
    <div class="casino-mustgo1" *ngIf="windowWidth() >= 768 && showMustGo && !isMustgoRedesign">
        <div class="mustgo-wrapper">
            <div class="casino-top-logo casino-dsktp-logo left-padding">
                <img src="{{ MustGoSitecoreObject.mustGoLogo.src }}" />
            </div>

            <div class="casino-dsktp-primarybanner casino-jckpt" *ngIf="primaryJackpotDisplayname != ''">
                <div class="casino-ribbon-container one">
                    <div class="bk l" [ngStyle]="{ 'background-color': ribbonsidecolor }">
                        <div class="casino-arrow top" [ngStyle]="{ 'border-right-color': ribbonsidecolor }"></div>
                        <div class="casino-arrow bottom" [ngStyle]="{ 'border-right-color': ribbonsidecolor }"></div>
                    </div>
                    <div class="casino-skew l" [ngStyle]="{ background: ribbontrianglecolor }"></div>
                    <!-- <div class="casino-ribbon-main" [ngStyle]="{'background-color':ribboncolor}">
                                        {{MustGoProperties.primaryjackpotname}}
                                </div> -->
                    <div class="casino-ribbon-main" [ngStyle]="{ 'background-color': ribboncolor }">
                        {{ primaryJackpotDisplayname }}
                    </div>
                    <div class="casino-skew r" [ngStyle]="{ background: ribbontrianglecolor }"></div>
                    <div class="bk r" [ngStyle]="{ 'background-color': ribbonsidecolor }">
                        <div class="casino-arrow top" [ngStyle]="{ 'border-left-color': ribbonsidecolor }"></div>
                        <div class="casino-arrow bottom" [ngStyle]="{ 'border-left-color': ribbonsidecolor }"></div>
                    </div>
                </div>
                <div class="ticker first-ticker">
                    <cc-flipclock
                        [isMega]="'true'"
                        [currency]="currency"
                        [amount]="MustGoSubJackpotsList[0].subJackpotValue"
                        [releaseamount]="MustGoSitecoreObject.attributes.primaryjackpotreleaseamount"
                        class="some"></cc-flipclock>
                </div>
            </div>
            <div
                class="casino-combined-stuff casino-jckpt"
                [ngClass]="{
                    'casino-mustgo-subjackpot-centeralign':
                        (subJackpot1DisplayName != '' || subJackpot2DisplayName != '') && subJackpot3DisplayName == ''
                }">
                <div class="casino-subjackpot" *ngIf="subJackpot1DisplayName != ''">
                    <div class="casino-ribbon-container one">
                        <div class="bk l" [ngStyle]="{ 'background-color': ribbonsidecolor }">
                            <div class="casino-arrow top" [ngStyle]="{ 'border-right-color': ribbonsidecolor }"></div>
                            <div class="casino-arrow bottom" [ngStyle]="{ 'border-right-color': ribbonsidecolor }"></div>
                        </div>
                        <div class="casino-skew l" [ngStyle]="{ background: ribbontrianglecolor }"></div>
                        <div class="casino-ribbon-main" [ngStyle]="{ 'background-color': ribboncolor }" *ngIf="subJackpot1DisplayName != ''">
                            {{ subJackpot1DisplayName }}
                        </div>
                        <div class="casino-skew r" [ngStyle]="{ background: ribbontrianglecolor }"></div>
                        <div class="bk r" [ngStyle]="{ 'background-color': ribbonsidecolor }">
                            <div class="casino-arrow top" [ngStyle]="{ 'border-left-color': ribbonsidecolor }"></div>
                            <div class="casino-arrow bottom" [ngStyle]="{ 'border-left-color': ribbonsidecolor }"></div>
                        </div>
                    </div>
                    <span></span>

                    <div class="ticker">
                        <cc-flipclock
                            [isMega]="'false'"
                            [currency]="currency"
                            [amount]="MustGoSubJackpotsList[1].subJackpotValue"
                            [releaseamount]="MustGoSitecoreObject.attributes.subjackpot1releaseamount">
                        </cc-flipclock>
                    </div>
                    <!-- mustgo clock -->
                    <div class="casino-mustgo-clock" *ngIf="MustGoProperties.dailyjackpotname == MustGoSubJackpotsList[1].subJackpotName">
                        <span class="clock-img theme-clock"></span>
                        <span class="casino-clock-time">{{ releaseTime }}</span>
                    </div>
                </div>

                <div class="casino-subjackpot lastchild" *ngIf="subJackpot2DisplayName != ''">
                    <div class="casino-ribbon-container one">
                        <div class="bk l" [ngStyle]="{ 'background-color': ribbonsidecolor }">
                            <div class="casino-arrow top" [ngStyle]="{ 'border-right-color': ribbonsidecolor }"></div>
                            <div class="casino-arrow bottom" [ngStyle]="{ 'border-right-color': ribbonsidecolor }"></div>
                        </div>
                        <div class="casino-skew l" [ngStyle]="{ background: ribbontrianglecolor }"></div>
                        <div class="casino-ribbon-main" [ngStyle]="{ 'background-color': ribboncolor }" *ngIf="subJackpot2DisplayName != ''">
                            {{ subJackpot2DisplayName }}
                        </div>
                        <div class="casino-skew r" [ngStyle]="{ background: ribbontrianglecolor }"></div>
                        <div class="bk r" [ngStyle]="{ 'background-color': ribbonsidecolor }">
                            <div class="casino-arrow top" [ngStyle]="{ 'border-left-color': ribbonsidecolor }"></div>
                            <div class="casino-arrow bottom" [ngStyle]="{ 'border-left-color': ribbonsidecolor }"></div>
                        </div>
                    </div>

                    <span></span>
                    <div class="ticker">
                        <cc-flipclock
                            [isMega]="'false'"
                            [currency]="currency"
                            [amount]="MustGoSubJackpotsList[2].subJackpotValue"
                            [releaseamount]="MustGoSitecoreObject.attributes.subjackpot2releaseamount">
                        </cc-flipclock>
                    </div>
                    <!-- mustgo clock -->
                    <div class="casino-mustgo-clock" *ngIf="MustGoProperties.dailyjackpotname == MustGoSubJackpotsList[2].subJackpotName">
                        <span class="clock-img theme-clock"></span>
                        <span class="casino-clock-time">{{ releaseTime }}</span>
                    </div>
                </div>
                <div class="casino-subjackpot lastchild" *ngIf="subJackpot3DisplayName != ''">
                    <div class="casino-ribbon-container one">
                        <div class="bk l" [ngStyle]="{ 'background-color': ribbonsidecolor }">
                            <div class="casino-arrow top" [ngStyle]="{ 'border-right-color': ribbonsidecolor }"></div>
                            <div class="casino-arrow bottom" [ngStyle]="{ 'border-right-color': ribbonsidecolor }"></div>
                        </div>
                        <div class="casino-skew l" [ngStyle]="{ background: ribbontrianglecolor }"></div>
                        <div class="casino-ribbon-main" [ngStyle]="{ 'background-color': ribboncolor }" *ngIf="subJackpot3DisplayName != ''">
                            {{ subJackpot3DisplayName }}
                        </div>
                        <div class="casino-skew r" [ngStyle]="{ background: ribbontrianglecolor }"></div>
                        <div class="bk r" [ngStyle]="{ 'background-color': ribbonsidecolor }">
                            <div class="casino-arrow top" [ngStyle]="{ 'border-left-color': ribbonsidecolor }"></div>
                            <div class="casino-arrow bottom" [ngStyle]="{ 'border-left-color': ribbonsidecolor }"></div>
                        </div>
                    </div>
                    <span></span>
                    <div class="ticker">
                        <cc-flipclock
                            [isMega]="'false'"
                            [currency]="currency"
                            [amount]="MustGoSubJackpotsList[3].subJackpotValue"
                            [releaseamount]="MustGoSitecoreObject.attributes.subjackpot3releaseamount">
                        </cc-flipclock>
                    </div>
                    <!-- mustgo clock -->
                    <div class="casino-mustgo-clock" *ngIf="MustGoProperties.dailyjackpotname == MustGoSubJackpotsList[3].subJackpotName">
                        <span class="clock-img theme-clock"></span>
                        <span class="casino-clock-time">{{ releaseTime }}</span>
                    </div>
                </div>
            </div>
            <div class="space"></div>
        </div>
    </div>
    <div class="casino-mustgo1" *ngIf="isMustgoRedesign && isEnableMustgoRedesignModes">
        <cc-casino-core-lazy-placeholder
            [inputData]="{
                MustGoSitecoreObject: MustGoSitecoreObject,
                MustGoSubJackpotsList: MustGoSubJackpotsList,
                MustGoFeedObject: MustGoFeedObject,
                games: games,
                isTouch: isTouch,
                currency: currency
            }"
            [componentName]="'MustGoJackpotRedesignComponent'">
        </cc-casino-core-lazy-placeholder>
    </div>
    <div class="casino-mustgo1" *ngIf="showMustGo">
        <!-- games lobby starts -->
        <div class="casino-mustgo-games casino-lobby-width-gametile">
            <div class="multi-jackpot-seeall" *ngIf="changeCarouselView">
                <span
                    *ngIf="!isTouch && carouselData?.showArrows"
                    class="seeallarrows"
                    [ngStyle]="{ 'color': subCategoryTextColor, 'font-family': lobbyTileFontFamily }">
                    <span
                        class="arrows-left"
                        [ngClass]="themeArrowLeft"
                        [ngStyle]="{ 'pointer-events': carouselData?.disablePrev ? 'none' : 'auto' }"
                        (click)="onPrevClick()"></span>
                    <span
                        class="arrows-right"
                        [ngClass]="themeArrowRight"
                        [ngStyle]="{ 'pointer-events': carouselData?.disableNext ? 'none' : 'auto' }"
                        (click)="onNextClick()"></span>
                </span>
            </div>
            <div
                class="casino-carousel-wrapper scrollable-games"
                [class.non-touch-scrollable-games]="!isTouch"
                [ngStyle]="{ 'background-color': bcolor }"
                style.overflow-x="{{ !isTouch ? 'hidden' : 'scroll' }}">
                <cc-feature-grid
                    *ngIf="games && games.length > 0"
                    [games]="games"
                    #featuredGrid
                    [changeCarouselView]="changeCarouselView"
                    (carouselDataEvent)="getCarouselData($event)"
                    [subcategory]="categoryObj"
                    [EnableFutureGrid]="false"
                    [isTouch]="isTouch"
                    [Type]="'scrollable-games'"
                    [gameLaunchedFrom]="'MustGoJackpot_' + category.categoryid"
                    [newFeatureGridArr]="newFeatureGridArr"></cc-feature-grid>
            </div>
        </div>
    </div>
    <!-- games lobby ends -->
</div>
