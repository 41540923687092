<div *ngIf="messagesInfo" class="casino-bg-confirm-message" tabindex="-1" role="dialog" style="display: block !important; z-index: 9999">
    <div class="message-body">
        <div class="row">
            <div class="col-md-12 message-header">
                <div class="text-header">{{ messagesInfo.header }}</div>
                <div *ngIf="messagesInfo.closeBtn" (click)="cancelBtn()" class="mes-close"><i class="theme-ex"></i></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 message-text">
                <p class="text-message">{{ messagesInfo.message }}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 footer">
                <p class="btn-div">
                    <a (click)="cancelBtn()">
                        <button class="cancel btn btn-outline-secondary btn-block">Cancel</button>
                    </a>
                    <a (click)="depositBtn()">
                        <button class="purchase btn btn-primary btn-block">{{ messagesInfo.purchaseButtonText }}</button>
                    </a>
                </p>
            </div>
        </div>
    </div>
</div>
