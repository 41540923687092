import { Injectable } from '@angular/core';

export interface IExpirablePersistenceService {
    read(key: string, expirationInterval: number): any;

    write(key: string, cacheValue: any): void;
}

@Injectable({
    providedIn: 'root',
})
export class LocalStorageExpirablePersistenceService implements IExpirablePersistenceService {
    //expirationInterval to be given in miliseconds.
    read(key: string, expirationInterval: number): any {
        if (key && key.trim()) {
            const cachedItem = localStorage.getItem(key.trim());
            const parsedCacheItem: ICacheItem = cachedItem && JSON.parse(cachedItem);
            if (parsedCacheItem) {
                const cachedAt = parsedCacheItem.createdAt;
                if (cachedAt && new Date().getTime() - cachedAt < expirationInterval) {
                    return parsedCacheItem.value;
                }
            }
        }
        return null;
    }

    write(key: string, cacheValue: any): void {
        delete cacheValue.categoriesMap;
        delete cacheValue.categoryNameLangMap;
        delete cacheValue.stickerNameMap;
        delete cacheValue.gameMetaDataMap;
        delete cacheValue.lbyTempNameMap;
        delete cacheValue.lbyTargetTypeMap;
        delete cacheValue.optSegNameMap;
        delete cacheValue.catVsFeatureMetadataMap;
        delete cacheValue.lobbyGameIconImageSuffixMap;
        if (key && key.trim() && cacheValue) {
            const cachedItem: ICacheItem = { createdAt: new Date().getTime(), value: cacheValue };
            localStorage.setItem(key.trim(), JSON.stringify(cachedItem));
        }
    }
}

export interface ICacheItem {
    readonly createdAt: number;
    readonly value: any;
}

export class CacheItem implements ICacheItem {
    createdAt: number;
    value: any;
}
