import { IContentImage, IContentLink, IContentVideo, IOption } from './common.model';

export interface GamingStoriesPhase2Configuration {
    enabled: boolean;
    storyItemInterval: number;
    gamingStoriesNpmPath: Array<IGamingStoriesNpmModel>;
    expirationTime: number;
    localStorageExpirationDuration: number;
}

export interface IGamingStoriesNpmModel {
    product: string;
    productPath: string;
}

export interface IGamingStoryContainer {
    sharedList: any;
    versionedList: any;
    storyList: Array<IGamingStory>;
    storyIconType: string;
    titlePosition: string;
    condition: string;
    tiltImageUrl: IContentImage;
    tiltMessage: string;
}

export interface IGamingStory {
    // backgroundImage: IContentImage;
    storyImage: IContentImage;
    storyItems: Array<IGamingStoryItem>;
    title: string;
    storyViewed: boolean;
    storyName: string;
    condition: string;
    storyId: string;
}

export interface IGamingStoryItem {
    storyImage: IContentImage;
    backgroundImage: IContentImage;
    configurations: any;
    customCTA: IContentLink;
    actorImage: IContentImage;
    description: string;
    emojies: Array<IEmojis>;
    enableSwipeup: boolean;
    //gameId: string;
    yesNoPollOption: Array<IOption>;
    actionTypePosition: string;
    titlePosition: string;
    subTitlePosition: string;
    descriptionPosition: string;
    showTnC: boolean;
    showVideo: boolean;
    showAudio: boolean;
    audio: string;
    storyItemActionType: string;
    storyItemInterval: number;
    storyType: string;
    subTitle: string;
    swipeupStoryItemContent: any;
    title: string;
    tnCContent: string;
    videoURL: string;
    cmsVideoURL: IContentVideo;
    audioFile: string;
    storyItemsPath: any;
    activeStoryItemIndex: number;
    activeStoriesIndex: number;
    storyItemViewed: boolean;
    question: string;
    answers: Array<IOption>;
    showOptIn: boolean;
    optInCTAText: string;
    optedInCTAText: string;
    targetPromoId: string;
    targetPromoStatus: string;
    isValid: string;
    userId: string;
    emojieName: string;
    storyItemId: string;
    condition: string;
    storyId: string;
    optedInUrl: IContentLink;
    expiryTime: any;
}
export interface IMyDate {
    year: number;
    month: number;
    day: number;
}

export interface IEmojis {
    emojiName: string;
    emojiPath: string;
}
export class GamingStoriesStorageCloseModel {
    storyList: Array<IGamingStory>;
    activeStoriesIndex: number;
    activeStoryItemIndex: number;
}
export class PostUserRequestAPIModel {
    userId: string;
    brandId: string;
    storyInfo: Array<StoryInfoModel>;
}
export class StoryInfoModel {
    storyId: string;
    subStoryId: string;
    storyType: string;
    templateType: string;
    userInput: string;
    expTime: number;
}
export class FetchUserRequestAPIModel {
    userId: string;
    brandId: string;
    reqStoryIds: Array<ReqStoryIdsModel>;
}
export class ReqStoryIdsModel {
    storyId: string;
    subStoryId: string;
}
export class FetchUserResponseAPIModel {
    statusCode: string;
    status: string;
    errorMessage: string;
    uId: string;
    brId: string;
    response: Array<ResponseDataModel>;
}
export class ResponseDataModel {
    sId: string;
    subSId: string;
    tType: string;
    uInput: string;
    uAvg: Map<string, number>;

    constructor() {
        this.uAvg = new Map();
    }
}
export class StoryInfoData {
    storyId: string;
    subStoryId: string;
    storyType: string;
    templateType: string;
    userInput: string;
    expTime: number;
    storyIndex: number;
}
export class StoryInfoDataInSessionStorage {
    response: ResponseDataModel;
    expiryTime: number;
    userId: string;
    storyIndex: number;
}
export class OptInStoriesTrack {
    activeStoriesIndex: number;
    activeStoryItemIndex: number;
}
export class StoriesLocalStorageModel {
    userId: string;
    storyId: string;
    viewedTimeStamp: number;
}
