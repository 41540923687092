import { LowerCasePipe, NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'cc-opt-teaser-actor-image-npm',
    templateUrl: 'opt-teaser-actor-image-npm.component.html',
    standalone: true,
    imports: [NgIf, NgClass, LowerCasePipe],
})
export class OptTeaserActorImageNpmComponent {
    @Input() teaser: any;
    @Input() imagePosition: string;
    @Input() isMobileImage: boolean;
}
