import { NgForOf } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';

import { SwiperComponent } from '@frontend/vanilla/shared/swiper';

@Component({
    selector: 'cc-custom-carousel-pagination',
    template: `
        <div class="swiper-custom-pagination">
            <div
                class="swiper-pagination swiper-pagination-bullets"
                [class.swiper-pagination-clickable]="!disableClick"
                [style.background-color]="backgroundColor || ''">
                <ng-container *ngFor="let item of [].constructor(noOfItems); let i = index">
                    <span
                        (click)="paginationClick(i, $event)"
                        [class.swiper-pagination-bullet-active]="i === currentIndex"
                        class="swiper-pagination-bullet"
                        role="button"></span>
                </ng-container>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgForOf],
})
export class CustomCarouselPaginationComponent {
    @Input() loop: boolean = true;
    @Input() backgroundColor: boolean = false;
    @Input() disableClick: boolean = false;
    @Input() noOfItems: number;
    @Input() currentIndex: number;
    @Input() carousel: SwiperComponent;

    paginationClick(index: number, event: Event) {
        if (this.disableClick || this.currentIndex === index) {
            event.stopPropagation();
            event.stopImmediatePropagation();
        } else {
            const i = index;
            const slideIndex = this.carousel.swiper?.slides.length > i ? i : 0;
            if (this.carousel.swiper?.params.loop) {
                if (this.carousel.swiper?.realIndex === slideIndex) return;
                this.carousel.swiper?.slideToLoop(slideIndex);
            } else {
                this.carousel.swiper?.slideTo(slideIndex);
            }
        }
    }
}
