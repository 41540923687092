<div class="casino-lobby-container-main">
    <div class="gameQuickInfo" *ngIf="isTouch && !newQuickInfoStyle">
        <div class="casino-qi-overlay" (click)="hideInfo($event)"></div>
        <div class="casino-qi-rectangle" [style.width.px]="boxWidth">
            <div class="casino-qi-game">{{ gameTitle }}</div>
            <div class="casino-qi-rectangle-int">
                <div class="casino-qi-image">
                    <img src="{{ mobileQuickInfoSrc }}" />
                    <div *ngIf="useFavouriteGames">
                        <div class="casino-qi-gradient"></div>
                        <div
                            (click)="toggleFavourite(game, $event)"
                            class="casino-qi-favourite"
                            [ngClass]="isFavouriteGame(game) ? favouriteStyle : unFavouriteStyle"></div>
                    </div>
                </div>
                <div class="casino-qi-rectangle-right" [style.width.px]="boxWidthInt">
                    <div class="casino-qi-game-provider">{{ gameProvider }}</div>
                    <div class="casino-qi-provider">{{ provider | uppercase }}</div>
                    <div class="casino-qi-cta" [style.width.px]="boxWidthInt">
                        <!-- <button class="casino-qi-play-now btn btn-primary" (click)="launchGame(game, false)">{{ cta
                            }}</button> -->
                        <cs-gametile-cta
                            [isLegacy]="isLegacy"
                            [kind]="'primary'"
                            [csDecoratorClass]="'casino-qi-play-now'"
                            [cta_text]="cta"
                            (click)="launchGame(game, false)"></cs-gametile-cta>
                    </div>
                </div>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
    <div class="gameQuickInfo" *ngIf="isTouch && newQuickInfoStyle && loadQuickInfo">
        <div class="casino-qi-noverlay" (click)="hideInfo($event)"></div>
        <div class="casino-qi-nrectangle" [style.width.px]="boxWidth">
            <div class="casino-total-data z-index-1">
                <div class="casino-qi-nrectangle-int">
                    <div class="casino-qi-nimage">
                        <img src="{{ mobileQuickInfoSrc }}" />
                        <div class="casino-relativ-div-quickinfo">
                            <cc-stickers-jackpot
                                [demoEnabled]="demoEnabled"
                                [stickers]="stickers"
                                [isTouch]="isTouch"
                                [jackpotValue]="jackpotValue"
                                [isHotJackpotGroup]="isHotJackpotGroup"
                                [isStickersForUk]="isStickersForUk"
                                [lobbyType]="lobbyType"></cc-stickers-jackpot>
                        </div>
                        <div *ngIf="useFavouriteGames">
                            <div class="casino-qi-ngradient"></div>
                            <div
                                (click)="toggleFavourite(game, $event)"
                                class="casino-qi-nfavourite"
                                [ngClass]="isFavourite ? favouriteStyle : unFavouriteStyle"></div>
                        </div>
                    </div>
                    <div class="casino-qi-nrectangle-right" [style.width.px]="boxWidthInt">
                        <div class="casino-qi-ngame-provider">{{ gameProvider | uppercase }}</div>
                        <div class="close theme-close qi-close" (click)="hideInfo($event)"></div>
                        <div *ngIf="vendorLogo" class="casino-qi-nlogo">
                            <img src="{{ vendorLogo }}" />
                        </div>
                        <div class="casino-qi-nlogo" *ngIf="!vendorLogo">{{ provider | uppercase }}</div>
                        <div *ngIf="enableQuickInfoReels" class="casino-quickInfoReelsTopMobile">
                            <div *ngIf="reels" class="reels-dv casino-quickInfoReelsWidth">
                                <div class="reelsValue">{{ reels }}</div>
                                <div *ngIf="reels" class="Reels">
                                    {{ reelsTitle }}
                                </div>
                            </div>
                            <div *ngIf="payLines" class="paylines casino-quickInfoReelsWidth">
                                <div class="reelsValue">{{ payLines }}</div>
                                <div *ngIf="payLines" class="Paylines">
                                    {{ payLinesTitle }}
                                </div>
                            </div>
                            <div *ngIf="returnToPlayer" class="returntoplayer casino-quickInfoReelsWidth">
                                <div class="returnToPlayerValue">{{ returnToPlayer }}</div>
                                <div *ngIf="returnToPlayer" class="Return-to-Player">
                                    {{ returnToPlayerTitle }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="clear: both"></div>
                </div>
                <div class="gamedata">
                    <div class="casino-qi-ngame" [innerHTML]="gameTitle"></div>
                    <span *ngIf="gameDescription">
                        <span class="gameData-body" [innerHTML]="gameDescription | trustAsHtml"></span>
                    </span>
                </div>
            </div>
            <div class="casino-qi-btn-container casino-qi-btn-container-mobile">
                <!-- <div class="qi-margin-top casino-qi-demo-link" (click)="launchGame(game, true)" *ngIf="demoEnabled && demoEnabledForProvider">
                    <a [ngStyle]="{ color: demoLinkTextColor }">{{ demoLinkText | uppercase }}</a>
                </div> -->
                @if (demoEnabled && demoEnabledForProvider) {
                    <cs-gametile-cta
                        [isLegacy]="isLegacy"
                        [kind]="'tertiary'"
                        [variant]="'outline'"
                        [customTextColor]="demoLinkTextColor"
                        [csDecoratorClass]="'qi-margin-top casino-qi-demo-link btn-light'"
                        [cta_text]="demoLinkText"
                        (click)="launchGame(game, true)"></cs-gametile-cta>
                }
                <!-- <button class="casino-qi-nplay-now btn qi-demo-btn"
                    [ngClass]="{ 'epcot-btn': enableEpcotSubNavigation }" (click)="launchGame(game, false)">
                    {{ quickInfoCta }}
                </button> -->

                <cs-gametile-cta
                    [isLegacy]="isLegacy"
                    [kind]="'primary'"
                    [csDecoratorClass]="'casino-qi-nplay-now qi-demo-btn'"
                    [cta_text]="quickInfoCta"
                    (click)="launchGame(game, false)"></cs-gametile-cta>
            </div>
            <cc-freespin-quick-info *ngIf="showFreeSpinCount && enableFreeSpinsOnQuickInfo" [rewardDetails]="rewardDetails"></cc-freespin-quick-info>
        </div>
    </div>

    <div *ngIf="!isTouch && !isLiveGame && game?.widgetName != 'RecentWinners'">
        <div
            class="casino-qi-container-d"
            *ngIf="enableUkMouseOverStyle"
            [ngClass]="{ 'qi-freespins-class': showFreeSpinCount }"
            (click)="launchGame(game, false)">
            <cc-freespin-summary
                *ngIf="showFreeSpinCount"
                [freespinAvailableCount]="availableCount"
                [freespinTotalCount]="totalCount"
                [isOptedIn]="this.optedIn"
                (freespinsInfo)="openDialog($event)">
            </cc-freespin-summary>
            <div
                class="casino-game-fav-icon theme-favorite-i-non-touch"
                *ngIf="
                    useFavouriteGames &&
                    displayFavouritesOnPreLogin &&
                    !(useQuickInfo && newQuickInfoStyle) &&
                    categoryType !== 'recentgames' &&
                    categoryType !== gymlCategoryType &&
                    game.widgetName !== 'PlayerStats'
                "
                (click)="toggleFavourite(game, $event)"
                [ngClass]="isFavouriteGame(game) ? favouriteStyle : unFavouriteStyle"></div>

            <div
                class="casino-game-fav-icon theme-favorite-i-non-touch rcnt-gyml-quick-fav-icon"
                *ngIf="
                    useFavouriteGames && !(useQuickInfo && newQuickInfoStyle) && (categoryType === 'recentgames' || categoryType === gymlCategoryType)
                "
                (click)="toggleFavourite(game, $event)"
                [ngClass]="isFavouriteGame(game) ? favouriteStyle : unFavouriteStyle"></div>
            <div class="casino-qi-cta-container-d">
                <!-- <button
                    class="casino-qi-cta-btn-d btn btn-primary"
                    [ngClass]="{ 'casino-playbtn-hover-effect': playNowHoverEffect, 'epcot-btn': enableEpcotSubNavigation }">
                    {{ playNow }}
                </button> -->

                <cs-gametile-cta
                    [isLegacy]="isLegacy"
                    [kind]="'primary'"
                    [casinoDsClass]="'casino-ds-button'"
                    [csDecoratorClass]="'casino-qi-cta-btn-d'"
                    [csDecoratorClass]="{ 'casino-playbtn-hover-effect': playNowHoverEffect }"
                    [cta_text]="playNow"></cs-gametile-cta>

                <div class="overlay-moreinfo" *ngIf="!moreInfoClicked && useQuickInfo && newQuickInfoStyle" (click)="openDialog($event)">
                    {{ moreInfo }}
                </div>
            </div>
        </div>
        <div
            class="casino-qi-container-d"
            (click)="launchGame(game, false)"
            *ngIf="categoryType !== 'recentgames' && categoryType !== gymlCategoryType && !enableUkMouseOverStyle">
            <!-- <div [ngClass]="{'casino-qi-gradient-d': !isVideoPreviewEnabled || (isVideoPreviewEnabled && !isVideoEnabled)}" [ngStyle]="{'border-radius': game?.gameTileBorderRadius}">
                <img *ngIf="!isVideoPreviewEnabled || (isVideoPreviewEnabled && !isVideoEnabled)" src="{{src}}" class="casino-qi-image-d" />
            </div> -->
            <div
                *ngIf="game.iconType != '10' && game.iconType != '-10'"
                class="casino-qi-gradient-d"
                [ngStyle]="{ 'border-radius': game?.gameTileBorderRadius }">
                <img src="{{ src }}" class="casino-qi-image-d" />
            </div>

            <div
                class="casino-game-fav-icon theme-favorite-i-non-touch"
                *ngIf="useFavouriteGames && displayFavouritesOnPreLogin && game.widgetName !== 'PlayerStats'"
                (click)="toggleFavourite(game, $event)"
                [ngClass]="isFavouriteGame(game) ? favouriteStyle : unFavouriteStyle"></div>
            <div
                class="casino-qi-detail-d"
                *ngIf="
                    !isVideoPreviewEnabled ||
                    (isVideoPreviewEnabled && game.iconType != '10' && game.iconType != '-10' && game.widgetName !== 'PlayerStats')
                ">
                <div *ngIf="enableTitleOnGame" class="casino-qi-gamename-d" [innerHTML]="gameTitle"></div>
            </div>
            <div class="casino-qi-detail-d" *ngIf="game.widgetName == 'PlayerStats'">
                <div *ngIf="enableTitleOnGameIconPlayerStats" class="casino-qi-gamename-d" [innerHTML]="gameTitle"></div>
            </div>
            <div class="casino-qi-cta-container-d">
                <!-- <button class="casino-qi-cta-btn-d btn btn-primary" [style.width.%]="playNowBtnWidth"
                    [ngClass]="{ 'casino-playbtn-hover-effect': playNowHoverEffect, 'epcot-btn': enableEpcotSubNavigation }">
                    {{ cta }}
                </button> -->
                <cs-gametile-cta
                    [isLegacy]="isLegacy"
                    [kind]="'primary'"
                    [casinoDsClass]="'casino-ds-button'"
                    [csDecoratorClass]="'casino-qi-cta-btn-d'"
                    [csDecoratorClass]="{ 'casino-playbtn-hover-effect': playNowHoverEffect }"
                    [cta_text]="cta"
                    [ctaWidth]="playNowBtnWidth">
                </cs-gametile-cta>
            </div>
        </div>
        <div
            class="casino-rcnt-gyml-quick-container"
            (click)="launchGame(game, false)"
            *ngIf="(categoryType === 'recentgames' || categoryType === gymlCategoryType) && !enableUkMouseOverStyle">
            <div class="rcnt-gyml-quick-gradient">
                <img src="{{ src }}" class="quick-image" />
                <div class="casino-qi-cta-container-d">
                    <!-- <button class="casino-qi-cta-btn-d btn btn-primary"
                        [ngClass]="{ 'casino-playbtn-hover-effect': playNowHoverEffect, 'epcot-btn': enableEpcotSubNavigation }">
                        {{ cta }}
                    </button> -->

                    <cs-gametile-cta
                        [isLegacy]="isLegacy"
                        [kind]="'primary'"
                        [csDecoratorClass]="'casino-qi-cta-btn-d'"
                        [csDecoratorClass]="{ 'casino-playbtn-hover-effect': playNowHoverEffect }"
                        [cta_text]="cta"></cs-gametile-cta>
                </div>
            </div>
            <div
                class="casino-game-fav-icon theme-favorite-i-non-touch rcnt-gyml-quick-fav-icon"
                *ngIf="useFavouriteGames && displayFavouritesOnPreLogin"
                (click)="toggleFavourite(game, $event)"
                [ngClass]="isFavouriteGame(game) ? favouriteStyle : unFavouriteStyle"></div>
            <div class="rcnt-gyml-quick-detail">
                <div *ngIf="enableTitleOnGame" class="rcnt-gyml-quick-gamename" [innerHTML]="gameTitle"></div>
            </div>
        </div>
    </div>

    <!-- Live game desktop hover functionality -->
    <div
        *ngIf="!isTouch && isLiveGame && isTableOnline && game?.widgetName != 'RecentWinners'"
        class="casino-livecasino-m"
        [ngClass]="{
            'des-fav-lcapi-active': enableFavouriteGamesforLiveCasinoApi,
            'uk-mouse-hover-active': enableUkMouseOverStyle,
            'more-info-hide': hideMoreInfoLinkForLiveCasinoApi
        }">
        <div class="casino-qi-container-d" (click)="launchGame(game, false)">
            <div
                class="casino-game-fav-icon theme-favorite-i-non-touch"
                *ngIf="useFavouriteGames && displayFavouritesOnPreLogin && game?.widgetName != 'PlayerStats'"
                (click)="toggleFavourite(game, $event)"
                [ngClass]="isFavouriteGame(game) ? favouriteStyle : unFavouriteStyle"></div>
            <div class="casino-qi-detail-d">
                <div *ngIf="enableTitleOnGame && game?.widgetName != 'PlayerStats'" class="casino-qi-gamename-d" [innerHTML]="gameTitle"></div>
            </div>
            <div class="casino-qi-detail-d">
                <div
                    *ngIf="enableTitleOnGameIconPlayerStats && game?.widgetName == 'PlayerStats'"
                    class="casino-qi-gamename-d"
                    [innerHTML]="gameTitle"></div>
            </div>
            <div class="casino-qi-cta-container-d" [ngClass]="{ 'ukmouseoverstyle-playnow': !enableUkMouseOverStyle }">
                <!-- <button class="casino-qi-cta-btn-d btn btn-primary"
                    [ngClass]="{ 'epcot-btn': enableEpcotSubNavigation }" *ngIf="liveGameInfo.seatsTaken.length != 7"
                    [style.width.%]="playNowBtnWidth">
                    {{ liveCasinoPlayNowCTA }}
                </button>
                <button class="casino-qi-cta-btn-d btn btn-primary"
                    [ngClass]="{ 'epcot-btn': enableEpcotSubNavigation }" *ngIf="liveGameInfo.seatsTaken.length == 7"
                    [style.width.%]="playNowBtnWidth">
                    {{ playBehindButtonTitle }}
                </button> -->
                @if (liveGameInfo.seatsTaken.length != 7) {
                    <cs-gametile-cta
                        [isLegacy]="isLegacy"
                        [kind]="'primary'"
                        [csDecoratorClass]="'casino-qi-cta-btn-d'"
                        [cta_text]="liveCasinoPlayNowCTA"
                        [ctaWidth]="playNowBtnWidth">
                    </cs-gametile-cta>
                }

                @if (liveGameInfo.seatsTaken.length == 7) {
                    <cs-gametile-cta
                        [isLegacy]="isLegacy"
                        [kind]="'primary'"
                        [csDecoratorClass]="'casino-qi-cta-btn-d'"
                        [cta_text]="playBehindButtonTitle"
                        [ctaWidth]="playNowBtnWidth">
                    </cs-gametile-cta>
                }

                <div class="overlay-moreinfo" *ngIf="!moreInfoClicked && useQuickInfo && newQuickInfoStyle" (click)="openDialog($event)">
                    {{ moreInfo }}
                </div>
            </div>
        </div>
    </div>
    <!-- enable recent winners hover functionality -->
    <div
        *ngIf="!isTouch && game?.widgetName == 'RecentWinners'"
        class="casino-qi-container-d"
        [ngClass]="{
            'des-fav-lcapi-active': enableFavouriteGamesforLiveCasinoApi,
            'uk-mouse-hover-active': enableUkMouseOverStyle,
            'more-info-hide': hideMoreInfoLinkForLiveCasinoApi
        }">
        <div class="casino-qi-container-d" (click)="launchGame(game, false)">
            <div class="casino-qi-detail-d">
                <div
                    class="casino-qi-gamename-d"
                    *ngIf="enableTitleOnGameTitle"
                    [style.font-size]="winnersGameNameFontsize"
                    [innerHTML]="game.name"></div>
            </div>
            <div class="casino-qi-cta-container-d">
                <!-- <button class="casino-qi-cta-btn-d btn btn-primary"
                    [ngClass]="{ 'casino-playbtn-hover-effect': playNowHoverEffect, 'epcot-btn': enableEpcotSubNavigation }">
                    {{ playNow }}
                </button> -->

                <cs-gametile-cta
                    [isLegacy]="isLegacy"
                    [kind]="'primary'"
                    [csDecoratorClass]="'casino-qi-cta-btn-d'"
                    [csDecoratorClass]="{ 'casino-playbtn-hover-effect': playNowHoverEffect }"
                    [cta_text]="playNow"></cs-gametile-cta>
            </div>
        </div>
    </div>
</div>
