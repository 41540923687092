import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { CasinoManager, ConfigProviderService } from '@casinocore/platform/core';
import { Logger } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

import { NavOnLobbyTypeComponent } from '../nav-on-lobby-type/nav-on-lobby-type.component';

@Component({
    selector: 'cc-nav-on-product',
    templateUrl: 'nav-on-product.component.html',
    standalone: true,
    imports: [NavOnLobbyTypeComponent],
})
export class NavOnProductComponent implements OnInit, OnDestroy, OnChanges {
    @Input() casinoProduct: string;
    @Input() categoryRoute: string;
    @Input() stickyInputQueryElement: string;
    isClientConfigLoaded: boolean;
    clientConfigLoadSubscription: Subscription;
    lobbyType: string;

    constructor(
        private configProviderService: ConfigProviderService,
        private logger: Logger,
        private casinoManager: CasinoManager,
    ) {}

    ngOnInit() {
        this.clientConfigLoadSubscription = this.configProviderService.casinoClientConfigObservable.subscribe((isClientConfigLoaded) => {
            if (isClientConfigLoaded) {
                this.isClientConfigLoaded = isClientConfigLoaded;
                this.setLobbyType();
            }
        });
        this.setLobbyType();
    }

    ngOnChanges() {
        this.setLobbyType();
    }

    setLobbyType() {
        if (this.isClientConfigLoaded) {
            if (!this.casinoProduct || this.casinoProduct === '') {
                this.casinoProduct = 'games';
                //TODO: fetch default product from dynacon
            }

            this.lobbyType = this.casinoManager.getLobbyTypeForProduct(this.casinoProduct);

            if (!this.lobbyType || this.lobbyType === '') {
                this.logger.error('Please configure a valid lobby Type under lobbyTypeMap');
            }
        }
    }

    ngOnDestroy() {
        if (this.clientConfigLoadSubscription) {
            this.clientConfigLoadSubscription.unsubscribe();
        }
    }
}
