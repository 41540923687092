import { Component } from '@angular/core';

import { MessagePanelComponent } from '@frontend/vanilla/features/message-panel';

@Component({
    selector: 'cc-casinocore-error-handler',
    templateUrl: './casinocore-error-handler.component.html',
    standalone: true,
    imports: [MessagePanelComponent],
})
export class CasinocoreErrorHandlerComponent {}
