import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit, computed, effect } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { ArcadeLobbyTrackingService, ConfigProviderService, SmartBannerService, UrlUtilityService } from '@casinocore/platform/core';
import { HeaderService, NavigationService } from '@frontend/vanilla/core';
import { map } from 'rxjs/operators';

@Component({
    selector: 'cc-arcade-navigation',
    templateUrl: 'arcade-navigation.component.html',
    standalone: true,
    imports: [NgIf, NgClass, NgFor],
})
export class ArcadeNavigationComponent implements OnInit {
    @Input() lobbyType: string;
    arcadeLobbyMessages: any;
    selectedItem: string = 'Arcade';
    arcadeNavigation: string[];
    private readonly hasHeaderElement = toSignal(this.headerService.whenReady.pipe(map(() => this.headerService.hasHeaderElement())));
    private readonly headerElementHeight = computed(() => (this.hasHeaderElement() ? this.headerService.headerElementRect().height : 0));
    readonly arcadeNavigationTop = computed(() => {
        return this.headerElementHeight() + this.smartBannerService.smartBannerRect().height;
    });
    constructor(
        private configProviderService: ConfigProviderService,
        private urlUtilityService: UrlUtilityService,
        private navigation: NavigationService,
        private arcadeLobbyTrackingService: ArcadeLobbyTrackingService,
        private smartBannerService: SmartBannerService,
        private headerService: HeaderService,
    ) {
        effect(() => {
            const arcadeNavStickyOffsetTop = this.arcadeNavigationTop();
            if (arcadeNavStickyOffsetTop != null) {
                if (
                    document.getElementsByClassName('arcade-navigation-container') &&
                    document.getElementsByClassName('arcade-navigation-container').length > 0
                ) {
                    const arcadeNavSectionElement: any = document.getElementsByClassName('arcade-navigation-container')[0];
                    arcadeNavSectionElement.style.top = arcadeNavStickyOffsetTop - 16 + 'px';
                }
            }
        });
    }

    ngOnInit() {
        this.arcadeLobbyMessages = this.configProviderService.provideGlobalConfig()?.arcadeLobbyConfigurations;
        this.arcadeNavigation = [this.arcadeLobbyMessages.Collect, this.arcadeLobbyMessages.Shop, this.arcadeLobbyMessages.Arcade, 'HistoryButton'];
        this.selectedItem = this.arcadeLobbyMessages.Arcade;
    }
    itemClick(item: string) {
        this.selectedItem = item;
        const staticUrlName = 'Url';
        const filterItem = item?.split(' ').join('');
        const suffixUrl = this.arcadeLobbyMessages[filterItem + staticUrlName];
        this.itemTracking(this.selectedItem);
        if (suffixUrl) {
            const navigationUrl = `${this.urlUtilityService.getPromoSmartUrl()}/${suffixUrl}`;
            this.navigation.goTo(navigationUrl);
        }
    }
    itemTracking(selectedItem: string) {
        this.arcadeLobbyTrackingService.trackEvent(
            'Event.Tracking',
            'coinArcade',
            'immersive lobby',
            'click',
            'navigation',
            'coin arcade',
            selectedItem,
            'not applicable',
        );
    }
}
