import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CasinoLobbyService, ConfigProviderService } from '@casinocore/platform/core';
import { removeClass } from '@casinocore/platform/utils';
import { DeviceService, DslService, MessageQueueService } from '@frontend/vanilla/core';
import { HomePageComponent } from '@frontend/vanilla/features/home-page';
import { rxEffects } from '@rx-angular/state/effects';

import { FeaturesClientConfig, LMTDataClientConfig, LabelHostHomePageClientConfig } from '../client-config/casino-client-config.models';
import { LobbyContainerComponent } from '../mod-casino/lobby-container/lobby-container.component';
import { LobbyService } from '../shared';

@Component({
    selector: 'cs-homemod-lobby',
    templateUrl: 'homelobbymod.component.html',
    standalone: true,
    imports: [HomePageComponent, LobbyContainerComponent],
})
export class HomeLobbyModComponent implements OnInit {
    private readonly rxEffects = rxEffects();
    routeCurrentProduct: string;
    routeCategoryType: string;
    isFirstTimeLoggedIn: boolean = false;
    routeInfoPublished: boolean;

    constructor(
        private lobbyService: LobbyService,
        private route: ActivatedRoute,
        private dsl: DslService,
        private labelHostHomePageClientConfig: LabelHostHomePageClientConfig,
        private features: FeaturesClientConfig,
        private clientConfigprovider: ConfigProviderService,
        private lmtDataClientConfig: LMTDataClientConfig,
        private deviceService: DeviceService,
        private casinoLobbyService: CasinoLobbyService,
        private messageQueue: MessageQueueService,
    ) {
        if (this.deviceService.isMobile || this.deviceService.isTablet) {
            localStorage.removeItem('isVideoRunning');
            localStorage.removeItem('isVideoEnded');
        }
    }

    ngOnInit(): void {
        this.rxEffects.register(this.route.params, (params: any) => {
            this.messageQueue.clear();
            this.routeCurrentProduct = params.product;
            if (!this.routeCurrentProduct && !params.categoryType) {
                const defaultProduct: string = this.lmtDataClientConfig.defaultProductHomeUrl;

                this.routeCurrentProduct = defaultProduct && defaultProduct != '' ? defaultProduct : 'games';

                //fetch the default product from dynacon
            }
            const featuresConfig: any = this.clientConfigprovider.provideFeaturesConfig();
            this.routeCategoryType = params.categoryType;
            this.casinoLobbyService.categoryNotFoundSubject.next(null);
            this.lobbyService.publishCategoryChange(this.routeCurrentProduct?.toLowerCase(), this.routeCategoryType);
            this.routeInfoPublished = true;
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, featuresConfig.scrollOnTopTimeFrame);
        });
        this.rxEffects.register(this.dsl.evaluateExpression<boolean>(this.labelHostHomePageClientConfig.isEnabledCondition), (result: any) => {
            this.isFirstTimeLoggedIn = result && this.features.isVanillaHomePageEnabled && this.labelHostHomePageClientConfig.isEnabled;
            if (this.isFirstTimeLoggedIn && !this.casinoLobbyService.isDisjointFeedsInitiated()) {
                this.casinoLobbyService.setCasinoLobby(true);
            }
        });
        if (!this.features.enableClickInteraction) {
            setTimeout(() => {
                if (document.querySelector('.click-interaction')) {
                    removeClass('click-interaction');
                }
            }, this.features.clickInteractionTimeout);
        }
    }
}
