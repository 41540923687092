<cs-immersive-lobby-skeleton
    *ngIf="enableImmersiveLobbySkeletonLoading && !immersiveLobbyLoaded && (!enableGSPreloginPopup || userName)"
    [loadingConfig]="loadingConfig">
</cs-immersive-lobby-skeleton>
<div *ngIf="enableGSPreloginPopup && !userName && isGameShows; else loadWidget" class="casino-empty-stats-wrapper">
    <div class="casino-stats-title-bg-blur">
        <div class="casino-statstitle-container">
            <div class="casino-statslogintitle">{{ GameShowPopUpLoginContent }}</div>
            <div class="button_login_register">
                <button class="cltoactionbtn btn btn-primary btn-md" (click)="onRegistration()">{{ GameShowRegistrationButton | uppercase }}</button>
                <button class="cltoactionbtn btn btn-secondary btn-md" (click)="onLogin()">
                    {{ GameShowLoginButton | uppercase }}
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isTeasersLoaded && showSkeleton">
    <cs-skeleton *ngIf="skeletonLoadingEnabled"></cs-skeleton>
</div>
<ng-template #loadWidget>
    <div
        *ngIf="isTeasersLoaded || (isTeasersLoaded && lobbyLoaded)"
        [ngClass]="{
            'lobby-bg-clr': isLobbyBgclr,
            'lobby-bg-transparentclr': isLobbyTransparentBg,
            'height-cls-skeleton': enableImmersiveLobbySkeletonLoading && !immersiveLobbyLoaded
        }">
        <cs-full-takeover-background
            *ngIf="immersiveLobbyLoaded && lobbyLoaded"
            [routeCurrentProduct]="routeCurrentProduct"
            [routeCategoryType]="routeCategoryType"
            [currentLobbyType]="currentLobbyType"></cs-full-takeover-background>
        <cs-widget-container *ngIf="!pageNotFound && lobbyLoaded"></cs-widget-container>
        <span
            class="casino-go-to-top theme-arrow-right"
            [ngClass]="{ 'casino-gototop-hinc': isRpFavWidgetVisible, 'hide-top-arrow-for-immersive-lobby': enableImmersiveLobbySkeletonLoading }"
            *ngIf="lastScrollTop && immersiveLobbyLoaded"
            (click)="toggleGoToTop(); scrollToTop()">
        </span>
    </div>
</ng-template>
<div class="nm-page" *ngIf="lobbyLoaded && pageNotFound">
    <vn-page-matrix path="App-v1.0/partials/notfound"></vn-page-matrix>
</div>
<!-- <div class="im-page" *ngIf="immersiveLobbyLoaded && immersiveLobbyConfig.enabled && !isGameShows && pageNotFound">
    <vn-page-matrix path="App-v1.0/partials/notfound"></vn-page-matrix>
</div> -->
