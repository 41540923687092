import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { CasinoCoreNpmParamsService, CasinoLobbyService, CasinoManager, ConfigProviderService, LobbyItem } from '@casinocore/platform/core';
import { NewGameQuickInfoComponent } from '@casinocore/platform/new-game-quick-info';
import { Subscription } from 'rxjs';

import { CasinoPageContainerComponent } from '../casino-page-container/casino-page-container.component';

@Component({
    selector: 'cc-casino-grid-on-lobby-type',
    templateUrl: 'casino-grid-on-lobby-type.component.html',
    standalone: true,
    imports: [NgIf, CasinoPageContainerComponent, NewGameQuickInfoComponent],
})
export class CasinoGridOnLobbyTypeComponent implements OnInit, OnDestroy, OnChanges {
    @Input() lobbyType: string;
    @Input() categoryRoute: string;
    @Input() gameContainer: string;
    @Input() seeAllProductLinkOverride: string;

    isLobbyLoaded: boolean;
    lobbyLoadSubscription: Subscription;
    isNewQuickInfoEnabled: boolean;

    constructor(
        private casinoLobbyService: CasinoLobbyService,
        private casinoCoreNpmParamsService: CasinoCoreNpmParamsService,
        private casinoManager: CasinoManager,
        private configProviderService: ConfigProviderService,
    ) {}

    ngOnInit() {
        this.lobbyLoadSubscription = this.casinoLobbyService.lobbyLoadedObservable.subscribe((response) => {
            if (response) {
                this.isLobbyLoaded = true;
                const casinoNpmParams = this.casinoCoreNpmParamsService.getcasinoCoreNpmParams();
                const features: any = this.configProviderService.provideFeaturesConfig();
                if (!casinoNpmParams.isDll) {
                    this.isNewQuickInfoEnabled = this.casinoManager.IsFeatureEnabledforLobbytypeWithInvokerProduct(
                        this.lobbyType,
                        features.enableNewQuickInfoLobbyType,
                    );
                }
                if (this.lobbyType && (!this.categoryRoute || this.categoryRoute === '')) {
                    this.setCategoryRoute();
                }
            }
        });
    }

    ngOnChanges() {
        if (this.isLobbyLoaded) {
            if (this.lobbyType && (!this.categoryRoute || this.categoryRoute === '')) {
                this.setCategoryRoute();
            }
        }
    }

    setCategoryRoute() {
        const lobbyInfo: LobbyItem = this.casinoLobbyService.getLobbyInfo(this.lobbyType) || new LobbyItem();
        if (lobbyInfo && lobbyInfo.categoriesMap && lobbyInfo.categoriesMap.size > 0) {
            this.categoryRoute = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(this.lobbyType).categoryInfo.categoryid; // lobbyInfo.categoriesMap.entries().next().value[1].categoryid;
            this.casinoLobbyService.categoryNotFoundSubject.next(null);
        } else {
            //emit CategoryNotFound
            this.casinoLobbyService.categoryNotFoundSubject.next('CategoryNotFound');
        }
    }

    ngOnDestroy() {
        if (this.lobbyLoadSubscription) {
            this.lobbyLoadSubscription.unsubscribe();
        }
    }
}
