import { ChangeDetectionStrategy, Component, Input, booleanAttribute } from '@angular/core';

import { ChevronKind, CsChevronComponent } from '../../../chevron/chevron.component';
import { CsCtaKind, CsCtaSize, CsCtaVariant } from '../../../cs-cta/cs-cta.component';
import { GameTileCtaComponent } from '../../../gametile/gametile-cta/gametile-cta.component';
import { CsClassIconComponent } from '../../../icons/class-icon/class-icon.component';
import { RpFavCtaLegacyComponent, RpFavCtaLegacyViewModel } from './rp-fav-cta-legacy.component';

@Component({
    selector: 'cs-rp-fav-cta',
    templateUrl: './rp-fav-cta.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [GameTileCtaComponent, RpFavCtaLegacyComponent, CsChevronComponent, CsClassIconComponent],
})
export class RpFavWidgetCtaComponent implements RpFavWidgetCtaViewModel, RpFavWidgetCtaViewBinding, RpFavCtaLegacyViewModel {
    size: CsCtaSize = 'medium';
    variant: CsCtaVariant = 'filled';
    kind: CsCtaKind = 'primary';
    @Input() chevronKind: ChevronKind = 'down';
    @Input() cta_text = 'XXX';
    @Input() color = '';
    @Input() backgroundColor = '';
    @Input() startIconClass = '';
    @Input() endIconClass = '';
    @Input({ transform: booleanAttribute }) isLegacy = false;
    @Input({ transform: booleanAttribute }) isFav = false;
    @Input({ transform: booleanAttribute }) setActive = false;
}

export interface RpFavWidgetCtaViewModel {
    cta_text: string;
    color: string;
    backgroundColor: string;
    startIconClass: string;
    chevronKind: ChevronKind;
}

interface RpFavWidgetCtaViewBinding {
    size: CsCtaSize;
    variant: CsCtaVariant;
    kind: CsCtaKind;
}
