import { ModuleWithProviders, NgModule } from '@angular/core';

import { ClientConfigService } from '@frontend/vanilla/core';

import { ABTestingClientConfig } from './abtesting-config';
import { AdvanceGameDataClientConfig } from './advance-game-data.config';
import { ApiPathsClientConfig } from './api-paths.config';
import { ArcadeLobbyClientConfig } from './arcadelobby-config';
import { CasinoCoreConfigClientConfig } from './casino-config.config';
import { CasinoAsIFrameOverlayConfig } from './casino-overlay.config';
import { ClientSideConfigClientConfig } from './clientside-config';
import { CommonClientConfig } from './common.config';
import { CozyBingoClientConfig } from './cozy-bingo.config';
import { DevSettingsConfig } from './dev-settings.config';
import { DiscoverFeedClientConfig } from './discover-feed.config';
import { DownloadAppFromDeviceClientConfig } from './download-app-from-device.config';
import { FeaturesClientConfig } from './features.config';
import { FeedCacheClientConfig } from './feed-cache.config';
import { FreeSpinsClientConfig } from './freespins.config';
import { GameLaunchTrackingClientConfig } from './game-launch-tracking.config';
import { GameSelectorWidgetClientConfig } from './game-selector-widget.config';
import { GameShowHubClientConfig } from './game-show-hub.config';
import { GameConfigClientConfig } from './game.config';
import { GameImpressionsClientConfig } from './gameimpressions-config';
import { GamificationWidgetConfig } from './gamification-widget-config';
import { GamingStoriesClientConfig } from './gaming-stories-config';
import { GeolocationClientConfig } from './geo-location.config';
import { GlobalJackpotClientConfig } from './global-jackpot.config';
import { GlobalSearchClientConfig } from './global-search.config';
import { GlobalClientConfig } from './global.config';
import { HeaderTeasersClientConfig } from './header-teaser.config';
import { ImageLoadOptimizationClientConfig } from './image-load-optimization.config';
import { ImmersiveLobbyClientConfig } from './immersive-lobby.config';
import { JackpotDataClientConfig } from './jackpot-data.config';
import { LiveCasinoLoungeClientConfig } from './live-casino-lounge.config';
import { LiveDealerConfigClientConfig } from './live-dealer.config';
import { LiveCasinoClientConfig } from './livecasino-config';
import { LMTDataClientConfig } from './lmt-data.config';
import { McPageClientConfig } from './mc-page.config';
import { MinBetGamesClientConfig } from './minbetgames-config';
import { ModularBingoClientConfig } from './modular-bingo.config';
import { ModularSettingsClientConfig } from './modular-settings.config';
import { NativeGameLaunchClientConfig } from './native-game-launch.config';
import { NewsFeedConfigClientConfig } from './news-feed.config';
import { NonCriticalClientConfig } from './noncritical-config';
import { OptimizedTeaserClientConfig } from './optimized-teaser.config';
import { PreLoginClientConfig } from './pre-login.config';
import { PromoCoinEconomyClientConfig } from './promo-coin-economy-config';
import { PromoOfferWidgetConfig } from './promo-offer-widget-config';
import { PromotedGamesClientConfig } from './promoted-games.config';
import { PromotionsInfoClientConfig } from './promotions-info.config';
import { QuickInfoClientConfig } from './quick-info.config';
import { RecentlyPlayedClientConfig } from './recentlyplayed-games.config';
import { SearchClientConfig } from './search.config';
import { SlotRacesClientConfig } from './slot-races.config';
import { SmartLobbyClientConfig } from './smart-lobby.config';
import { SubNavigationClientConfig } from './sub-nav.config';
import { ThumbnailConfigClientConfig } from './thumbnailconfig';
import { VideoPreviewClientConfig } from './video-preview.config';
import { WidgetConfigClientConfig } from './widget.config';

@NgModule()
export class CasinoCoreClientConfigModule {
    static forRoot(): ModuleWithProviders<CasinoCoreClientConfigModule> {
        return {
            ngModule: CasinoCoreClientConfigModule,
            providers: [
                { provide: ABTestingClientConfig, deps: [ClientConfigService] },
                { provide: AdvanceGameDataClientConfig, deps: [ClientConfigService] },
                { provide: ApiPathsClientConfig, deps: [ClientConfigService] },
                { provide: CasinoCoreConfigClientConfig, deps: [ClientConfigService] },
                { provide: CasinoAsIFrameOverlayConfig, deps: [ClientConfigService] },
                { provide: ClientSideConfigClientConfig, deps: [ClientConfigService] },
                { provide: CommonClientConfig, deps: [ClientConfigService] },
                { provide: CozyBingoClientConfig, deps: [ClientConfigService] },
                { provide: DevSettingsConfig, deps: [ClientConfigService] },
                { provide: DiscoverFeedClientConfig, deps: [ClientConfigService] },
                { provide: DownloadAppFromDeviceClientConfig, deps: [ClientConfigService] },
                { provide: FeaturesClientConfig, deps: [ClientConfigService] },
                { provide: FeedCacheClientConfig, deps: [ClientConfigService] },
                { provide: FreeSpinsClientConfig, deps: [ClientConfigService] },
                { provide: GameSelectorWidgetClientConfig, deps: [ClientConfigService] },
                { provide: GameShowHubClientConfig, deps: [ClientConfigService] },
                { provide: GameConfigClientConfig, deps: [ClientConfigService] },
                { provide: GamificationWidgetConfig, deps: [ClientConfigService] },
                { provide: GamingStoriesClientConfig, deps: [ClientConfigService] },
                { provide: GeolocationClientConfig, deps: [ClientConfigService] },
                { provide: GlobalJackpotClientConfig, deps: [ClientConfigService] },
                { provide: GlobalSearchClientConfig, deps: [ClientConfigService] },
                { provide: GlobalClientConfig, deps: [ClientConfigService] },
                { provide: HeaderTeasersClientConfig, deps: [ClientConfigService] },
                { provide: ImageLoadOptimizationClientConfig, deps: [ClientConfigService] },
                { provide: ImmersiveLobbyClientConfig, deps: [ClientConfigService] },
                { provide: JackpotDataClientConfig, deps: [ClientConfigService] },
                { provide: LiveCasinoLoungeClientConfig, deps: [ClientConfigService] },
                { provide: LiveDealerConfigClientConfig, deps: [ClientConfigService] },
                { provide: LiveCasinoClientConfig, deps: [ClientConfigService] },
                { provide: LMTDataClientConfig, deps: [ClientConfigService] },
                { provide: McPageClientConfig, deps: [ClientConfigService] },
                { provide: ModularSettingsClientConfig, deps: [ClientConfigService] },
                { provide: NativeGameLaunchClientConfig, deps: [ClientConfigService] },
                { provide: NewsFeedConfigClientConfig, deps: [ClientConfigService] },
                { provide: NonCriticalClientConfig, deps: [ClientConfigService] },
                { provide: OptimizedTeaserClientConfig, deps: [ClientConfigService] },
                { provide: PreLoginClientConfig, deps: [ClientConfigService] },
                { provide: PromoOfferWidgetConfig, deps: [ClientConfigService] },
                { provide: PromotedGamesClientConfig, deps: [ClientConfigService] },
                { provide: PromotionsInfoClientConfig, deps: [ClientConfigService] },
                { provide: QuickInfoClientConfig, deps: [ClientConfigService] },
                { provide: RecentlyPlayedClientConfig, deps: [ClientConfigService] },
                { provide: SearchClientConfig, deps: [ClientConfigService] },
                { provide: SlotRacesClientConfig, deps: [ClientConfigService] },
                { provide: SmartLobbyClientConfig, deps: [ClientConfigService] },
                { provide: SubNavigationClientConfig, deps: [ClientConfigService] },
                { provide: ThumbnailConfigClientConfig, deps: [ClientConfigService] },
                { provide: VideoPreviewClientConfig, deps: [ClientConfigService] },
                { provide: WidgetConfigClientConfig, deps: [ClientConfigService] },
                { provide: GameLaunchTrackingClientConfig, deps: [ClientConfigService] },
                { provide: ModularBingoClientConfig, deps: [ClientConfigService] },
                { provide: ModularBingoClientConfig, deps: [ClientConfigService] },
                { provide: ArcadeLobbyClientConfig, deps: [ClientConfigService] },
                { provide: PromoCoinEconomyClientConfig, deps: [ClientConfigService] },
                { provide: MinBetGamesClientConfig, deps: [ClientConfigService] },
                { provide: GameImpressionsClientConfig, deps: [ClientConfigService] },
            ],
        };
    }
}
