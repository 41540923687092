import { LowerCasePipe, NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import {
    CasinoCoreGamelaunchService,
    CasinoIFrameOverlayService,
    CasinoLobbyService,
    CasinoManager,
    CasinoWidgetService,
    ConfigProviderService,
    DesignSystemEnablerService,
    GameLaunch,
    GameLaunchPlaces,
    GameLaunchTrackingService,
    NativeAppHelperService,
    OptimizedTeaserManager,
    VCClientService,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, GameTileCtaComponent } from '@frontend/casino-ui';
import { DeviceService, NativeAppService, NavigationService, TrackingService, UserService } from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

import { OptTeaserBgImageNpmComponent } from './opt-teaser-bg-image-npm.component';
import { OptTeaserTextPositionNpmComponent } from './opt-teaser-text-position-npm.component';

enum ClickEventType {
    Teaser,
    PrimaryCTA,
    SecondaryCTA,
    Terms,
}

@Component({
    selector: 'cc-opt-mini-teaser-item-npm',
    templateUrl: 'opt-mini-teaser-item-npm.component.html',
    standalone: true,
    imports: [
        NgClass,
        OptTeaserBgImageNpmComponent,
        OptTeaserTextPositionNpmComponent,
        NgIf,
        LowerCasePipe,
        TrustAsHtmlPipe,
        GameTileCtaComponent,
        DecoratorClassDirective,
    ],
})
export class OptMiniTeaserItemNpmComponent implements OnInit {
    @Input() teaserCategory: string;
    @Input() teasersSource: any;
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    @Input() teaserIndex: number;
    //added for Teaser NPM
    @Input() isNPM: boolean;
    @Input() isTouch: boolean;
    @Input() isNewSportsNative: any;
    @Input() teaserDynaconConfigs: any;
    @Input() globalTeaserSettings: any;
    @Input() showJackpotCurrencyOnRight: any;
    @Input() jackpotGroupNameMapTeaserNPM: any;
    @Input() globalTeaserStyles: any;
    //added for Teaser NPM
    clickEventTypes = ClickEventType;
    miniTeaserItem: any;
    isOptTeaserPhase3: boolean;
    horizontalPosition: string = '';
    customClass: string = '';
    customId: string = '';
    termsTextPosition: string = '';
    desktopViewTeaserHeight: number;
    teaserDefaultContentBg: string = '';
    teaserLaunchRealtiveUri: string = '';
    verticalPosition: string = '';
    iconBasedHeight: number;
    enableEpcotSubNavigation: boolean;
    readonly isMobileWidth = this.deviceService.isMobileWidth;
    deviceName: string;
    optTeasers: any;
    isLegacy: boolean = false;

    constructor(
        private configProviderService: ConfigProviderService,
        private casinoManager: CasinoManager,
        private casinoLobbyService: CasinoLobbyService,
        private vcClientService: VCClientService,
        private optimizedTeaserManager: OptimizedTeaserManager,
        private navigationService: NavigationService,
        private userService: UserService,
        private trackingService: TrackingService,
        private nativeAppService: NativeAppService,
        private nativeAppHelperService: NativeAppHelperService,
        private casinoWidgetService: CasinoWidgetService,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private gameLaunchPlaces: GameLaunchPlaces,
        private casinoIFrameOverlayService: CasinoIFrameOverlayService,
        private gameLaunchTrackingService: GameLaunchTrackingService,
        private deviceService: DeviceService,
        private designSystemEnablerService: DesignSystemEnablerService,
    ) {}

    ngOnInit() {
        this.miniTeaserItem = this.teasersSource;
        let optimizedTeaserClientConfig: any;
        if (!this.isNPM) {
            this.enableEpcotSubNavigation = this.configProviderService.provideSubNavigationConfig()?.enableEpcotSubNavigation;
            this.globalTeaserSettings = this.optimizedTeaserManager.getGlobalTeaserSettings();
            optimizedTeaserClientConfig = this.configProviderService.provideOptimizedTeaserConfig();
            this.isTouch = this.casinoManager.isTouch();
            this.isNewSportsNative =
                this.nativeAppService.isNativeApp && (this.nativeAppService.product === 'SPORTSBOOK' || this.nativeAppService.product === 'BETTING');
            this.teaserLaunchRealtiveUri = '/' + this.configProviderService.provideMcPageConfig().labelPrefix + '/launchng/';
        } else {
            optimizedTeaserClientConfig = this.teaserDynaconConfigs;
        }
        this.teaserDefaultContentBg = this.globalTeaserSettings?.sharedList?.teaserDefaultContentBg || '';
        const teaserDefaultTextHorizontalPosition: any =
            this.globalTeaserSettings.sharedList.teaserDefaultTextHorizontalPosition || optimizedTeaserClientConfig.teaserDefaultHorizontalAlignment;
        this.horizontalPosition =
            this.miniTeaserItem.textHorizontalPosition != 'Default'
                ? this.miniTeaserItem.textHorizontalPosition
                : teaserDefaultTextHorizontalPosition;
        const teaserDefaultTextVerticalPosition: any =
            this.globalTeaserSettings.sharedList.teaserDefaultTextVerticalPosition || optimizedTeaserClientConfig.teaserDefaultVerticalAlignment;
        this.verticalPosition =
            this.miniTeaserItem.textVerticalPosition != 'Default' ? this.miniTeaserItem.textVerticalPosition : teaserDefaultTextVerticalPosition;
        this.termsTextPosition =
            this.globalTeaserSettings?.sharedList?.teaserDefaultTermsTextPosition || optimizedTeaserClientConfig.teaserDefaultTermsTextAlignment;
        this.isOptTeaserPhase3 = ['hydrogen', 'lithium'].includes(this.globalTeaserSettings?.sharedList?.teaserType?.toLowerCase());
        this.customClass = this.miniTeaserItem.htmlClasses || this.miniTeaserItem.data.metadata.id.itemName;
        this.desktopViewTeaserHeight = optimizedTeaserClientConfig.desktopViewTeaserHeight;
        this.iconBasedHeight = [1, 2].includes(this.miniTeaserItem?.iconTileCode) ? this.desktopViewTeaserHeight / 2 : this.desktopViewTeaserHeight;
        switch (true) {
            case this.deviceService.windowWidth() <= 480:
                this.deviceName = 'Mobile';
                break;
            case this.deviceService.windowWidth() > 481 && this.deviceService.windowWidth() < 1023:
                this.deviceName = 'Tablet';
                break;
            case this.deviceService.windowWidth() > 1024:
                this.deviceName = 'Desktop';
                break;
        }
        const style = document.createElement('style');
        style.textContent = this.globalTeaserStyles?.css;
        document.head.appendChild(style);
        this.isLegacy = !this.designSystemEnablerService.isEnabled();
    }

    private trackEvent(url?: string) {
        this.trackingService.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Teaser_Banners',
            'component.LabelEvent': this.miniTeaserItem.data.metadata.id.itemName,
            'component.PositionEvent': 'Teaser_' + this.miniTeaserItem.index,
            'component.LocationEvent': 'sub_Teaser',
            'component.ActionEvent': 'Teaser - Click',
            'component.EventDetails': 'User taps on teasers',
            'component.URLClicked': url || '',
        });
    }

    private isGameUrl(link: string) {
        return link.indexOf(this.teaserLaunchRealtiveUri) > 0;
    }

    private track(where: any, what: any, isUrl: any) {
        this.trackingService.updateDataLayer({
            'page.name': 'mcTeasers',
            'event': 'pageView',
            'page.url': '?source=' + where + '&' + (isUrl ? 'href=' : 'gameId=') + what,
        });
    }

    private trackGameLaunch(gameLaunchInfo: any, isUrl: boolean, bannerPos: any) {
        let gameType = '';
        let gameTrackingObj: any = {};
        if (isUrl) {
            const launchUrlIndex: number = gameLaunchInfo.indexOf(this.teaserLaunchRealtiveUri);
            if (launchUrlIndex > 0) {
                const gameId: any = gameLaunchInfo.substr(launchUrlIndex + this.teaserLaunchRealtiveUri.length);
                const gameData: any = this.casinoLobbyService.getGameInfo(gameId);
                if (!gameData.isDataNotAvailable) {
                    gameType = gameData?.launchType || '';
                    const gameDisplayName = gameData?.title || '';
                    gameTrackingObj = {
                        name: gameId,
                        type: gameType,
                        position: bannerPos,
                        section: 'Teaser',
                        displayName: gameDisplayName,
                    };
                }
            }
        } else {
            gameTrackingObj = {
                name: gameLaunchInfo,
                type: gameType,
                position: 1,
                section: '',
            };
        }
        if (Object.keys(gameTrackingObj).length > 0) {
            this.gameLaunchTrackingService.track(gameTrackingObj);
        }
    }

    private getGameId(link: string) {
        return this.isGameUrl(link) ? link.substring(link.indexOf(this.teaserLaunchRealtiveUri) + this.teaserLaunchRealtiveUri.length) : undefined;
    }

    private launchVCGame(link: string, bannerPos: any) {
        if (this.isGameUrl(link)) {
            const gameId = this.getGameId(link);
            const game = { gameId: gameId };
            const gameLaunchModel = new GameLaunch();
            gameLaunchModel.game = game;
            gameLaunchModel.isTouch = this.isTouch;
            gameLaunchModel.gamePosition = Number(bannerPos);
            gameLaunchModel.categoryType = 'OptimisedMiniTeaser';
            gameLaunchModel.from = 'OptimisedMiniTeaser';
            this.vcClientService.handleVCGameLaunch(gameLaunchModel, false);
        }
    }

    private launchNativeGame(link: string, bannerPos: any) {
        if (this.isGameUrl(link)) {
            const gameId = this.getGameId(link);
            const params = { gameId: gameId, skipUnfinGame: false };
            const game = { gameId: gameId };
            const gameLaunchModel = new GameLaunch();
            gameLaunchModel.game = game;
            gameLaunchModel.isTouch = this.isTouch;
            gameLaunchModel.gamePosition = Number(bannerPos);
            gameLaunchModel.categoryType = 'OptimisedTeaser';
            gameLaunchModel.from = 'OptimisedTeaser';
            this.nativeAppHelperService.handleNativeGameLaunch(params, gameLaunchModel);
        }
    }

    private handleClick(actionUrl: string, attributes: any) {
        const options = { skipLoadingIndicator: actionUrl.substr(actionUrl.length - 4, 4).toLowerCase() !== '.apk' };
        const gameId = this.getGameId(actionUrl);
        if (this.configProviderService.provideGameConfig().nativeGameLaunchEnabled && gameId) {
            this.launchNativeGame(actionUrl, this.teaserIndex + 1);
            return;
        }
        if (gameId) {
            let gameHref: string = (' ' + actionUrl).slice(1);
            if (this.configProviderService.provideAdvanceGameDataConfig().enableAdvanceGameData) {
                gameHref = this.gameLaunchService.appendQueryString(actionUrl, 'adv_parentcat', 'Teaser');
                gameHref = this.gameLaunchService.appendQueryString(gameHref, 'adv_pos', String(this.teaserIndex));
            }
            if (this.casinoWidgetService.isCasinoWidget() || this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                if (this.isTouch) {
                    if (this.casinoWidgetService.isCasinoWidget() && gameId) {
                        const postData: any = {
                            promptLogin: !this.userService.isAuthenticated,
                            redirectUrl: gameHref,
                            redirectOptions: this.userService.isAuthenticated
                                ? { showSpinnerForExternalNavigation: options.skipLoadingIndicator }
                                : null,
                        };
                        this.casinoWidgetService.postMessageToParent('UserAction', postData);
                        return;
                    } else if (this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                        let redirectPlayUrl: string = gameHref;
                        redirectPlayUrl = this.gameLaunchService.appendQueryString(
                            redirectPlayUrl,
                            'invokerProduct',
                            this.casinoWidgetService.getWidgetProduct(),
                        );
                        if (this.userService.isAuthenticated) {
                            redirectPlayUrl = this.gameLaunchService.appendQueryString(redirectPlayUrl, 'returnurl', document.referrer);
                            redirectPlayUrl = this.gameLaunchService.appendQueryString(redirectPlayUrl, 'widgetGameLaunch', 'true');
                        }
                        const postData: any = {
                            promptLogin: !this.userService.isAuthenticated,
                            redirectUrl: redirectPlayUrl,
                        };
                        this.casinoWidgetService.postMessageToParent('UserAction', postData);
                        return;
                    }
                } else {
                    if (this.casinoWidgetService.isCasinoWidget()) {
                        gameHref = this.gameLaunchService.appendQueryString(gameHref, 'widgetHomeUrl', document.referrer);
                        this.casinoWidgetService.postMessageToParent('UserAction', {
                            promptLogin: !this.userService.isAuthenticated,
                            redirectUrl: gameHref,
                        });
                        return;
                    }
                }
            } else {
                const gameInfo: any = this.casinoLobbyService.getGameInfo(gameId);
                this.isTouch = this.casinoManager.isTouch();
                if (gameInfo) {
                    gameInfo.gameId = gameId;
                    const gameModel = new GameLaunch();
                    gameModel.game = gameInfo;
                    gameModel.isTouch = this.isTouch;
                    gameModel.gamePosition = this.teaserIndex;
                    gameModel.categoryType = this.teaserCategory;
                    gameModel.from = 'q';
                    gameModel.replaceRouteInHistory = false;
                    gameModel.isDemoLaunch = false;
                    gameModel.skipUnfinGame = false;
                    gameModel.gameLaunchedFrom = this.gameLaunchPlaces.teaser;
                    gameModel.gridPosition = 1;
                    gameModel.gameContainer = this.gameContainer;
                    gameModel.game.lobbyType = this.lobbyType;
                    gameModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
                    this.gameLaunchService.launchGame(gameModel);
                }
            }
        } else {
            if (this.casinoWidgetService.isCasinoWidget() || this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                this.casinoWidgetService.postMessageToParent('UserAction', {
                    redirectUrl: actionUrl,
                    redirectOptions: { showSpinnerForExternalNavigation: options.skipLoadingIndicator },
                });
            } else {
                this.handleNavigation(actionUrl, attributes, options.skipLoadingIndicator);
            }
        }
    }

    private handleNavigation(url: string, attributes: any, showSpinnerForExternalNavigation: boolean) {
        if (attributes && attributes.hasOwnProperty('target')) {
            window.open(url, attributes.target);
        } else {
            this.navigationService.goTo(url, { showSpinnerForExternalNavigation: showSpinnerForExternalNavigation });
        }
    }

    private handleNativeClick(actionUrl: any, attributes: any) {
        this.track('tp', actionUrl, true);
        this.trackGameLaunch(actionUrl, true, this.teaserIndex + 1);
        const options = { skipLoadingIndicator: actionUrl.substr(actionUrl.length - 4, 4).toLowerCase() !== '.apk' };
        const infiIndex = actionUrl.indexOf('?event=promo');
        if (infiIndex === -1) {
            if (this.vcClientService.isPokerVC() && this.isGameUrl(actionUrl)) {
                this.launchVCGame(actionUrl, this.teaserIndex + 1);
            } else {
                this.handleNavigation(actionUrl, attributes, options.skipLoadingIndicator);
            }
        } else {
            this.nativeAppService.sendToNative({ eventName: '', parameters: actionUrl.substr(infiIndex) });
        }
    }

    onTeaserClick(type: ClickEventType, event: Event) {
        event.stopPropagation();
        let actionUrl: string;
        let attributes: any;
        const isNativeClient: boolean = !this.isNPM && (this.isNewSportsNative || this.vcClientService.isPokerVC());
        switch (type) {
            case ClickEventType.Teaser:
                if (this.miniTeaserItem.iconTileCode !== 1) {
                    this.trackEvent();
                    return;
                }
                actionUrl = isNativeClient ? this.miniTeaserItem.teaserNativeLink.url : this.miniTeaserItem.teaserLink.url;
                attributes = isNativeClient ? this.miniTeaserItem.teaserNativeLink.attributes : this.miniTeaserItem.teaserLink.attributes;
                break;
            case ClickEventType.PrimaryCTA:
                actionUrl = this.miniTeaserItem.cta.url;
                attributes = this.miniTeaserItem.cta.attributes;
                break;
            case ClickEventType.SecondaryCTA:
                actionUrl = this.miniTeaserItem.secondaryCTA.url;
                attributes = this.miniTeaserItem.secondaryCTA.attributes;
                break;
            case ClickEventType.Terms:
                actionUrl = this.miniTeaserItem.termsNativeLink ? this.miniTeaserItem.termsNativeLink.url : '';
                attributes = this.miniTeaserItem.termsNativeLink ? this.miniTeaserItem.termsNativeLink.attributes : {};
                break;
        }
        this.trackEvent(actionUrl);
        if (!actionUrl) {
            return;
        }
        if (isNativeClient) {
            this.handleNativeClick(actionUrl, attributes);
        } else {
            if (!this.isNPM) {
                this.handleClick(actionUrl, attributes);
            } else {
                this.navigationService.goTo(actionUrl, { showSpinnerForExternalNavigation: true });
            }
        }
    }
}
