import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csDevSettingsConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: devSettingsConfigFactory,
})
export class DevSettingsConfig {
    categoryDataCacheTimeOut: number;
    enableCategoryDataCache: boolean;
    gameIconTemplate: string;
    getGameIconFromLMT: boolean;
    isOptimizedLobby: boolean;
    isFullyOptimizedLobby: boolean;
    multiProductSupport: boolean;
    showInhouseGamesConsole: boolean;
    masterUserPattern: string;
    isPreviewUser: boolean;
    subCategoryLoadLimit: number;
    subCategoryLoadLimitOverlayOverride: number;
}

export function devSettingsConfigFactory(service: LazyClientConfigService) {
    return service.get(DevSettingsConfig);
}
