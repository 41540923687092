import { Injectable } from '@angular/core';

import { CasinoLobbyService, ForceGameLaunchHandlerService, GlobalJackpotModService, LiveCasinoService } from '@casinocore/platform/core';

import { CasinoMenuActionsBootstrapService } from '../casino-menu/casino-menu-actions-bootstrap.service';

@Injectable({
    providedIn: 'root',
})
export class OnAppInitHandler {
    constructor(
        private casinoLobbyService: CasinoLobbyService,
        private casinoMenuActionsBootstrapService: CasinoMenuActionsBootstrapService,
        private forceGameLaunchHandlerService: ForceGameLaunchHandlerService,
        private globalJackpotModService: GlobalJackpotModService,
        private liveCasinoService: LiveCasinoService,
    ) {}

    execute() {
        this.casinoLobbyService.onAppInit();
        this.casinoMenuActionsBootstrapService.onAppInit();
        this.forceGameLaunchHandlerService.onAppInit();
        this.globalJackpotModService.onAppInit();
        this.liveCasinoService.onAppInit();
    }
}
