import { LowerCasePipe, NgClass, NgIf } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import {
    CasinoCoreGamelaunchService,
    CasinoIFrameOverlayService,
    CasinoLobbyService,
    CasinoManager,
    CasinoWidgetService,
    ConfigProviderService,
    GameLaunch,
    GameLaunchPlaces,
    GameLaunchTrackingService,
    NativeAppHelperService,
    OptimizedTeaserManager,
    VCClientService,
} from '@casinocore/platform/core';
import { DeviceService, NativeAppService, NativeEvent, NavigationService, TrackingService, UserService } from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { cloneDeep } from 'lodash-es';

import { OptTeaserActorImageNpmComponent } from './opt-teaser-actor-image-npm.component';
import { OptTeaserBgImageNpmComponent } from './opt-teaser-bg-image-npm.component';
import { OptTeaserTextPositionNpmComponent } from './opt-teaser-text-position-npm.component';
import { OptTeaserVideoNpmComponent } from './opt-teaser-video-npm.component';

@Component({
    selector: 'cc-opt-teaser-item-npm',
    templateUrl: 'opt-teaser-item-npm.component.html',
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        OptTeaserBgImageNpmComponent,
        OptTeaserVideoNpmComponent,
        OptTeaserActorImageNpmComponent,
        OptTeaserTextPositionNpmComponent,
        LowerCasePipe,
        TrustAsHtmlPipe,
    ],
})
export class OptTeaserItemNpmComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() teaser: any;
    @Input() activeSlides: any;
    @Input() teaserIndex: number;
    @Input() teaserHeight: number;
    @Input() teaserCategory: string;
    @Input() gameContainer: string;
    @Input() isHover: boolean;
    @Input() lobbyType: string;
    @Input() identifierClass: string;
    @Input() isOptMiniTeasersEnabled: boolean;
    @Input() isOptTeaserPhase3: boolean;
    @Output() onVideoEndEvent: EventEmitter<any> = new EventEmitter();
    @Output() onVideoLoadedEvent: EventEmitter<any> = new EventEmitter();
    @Output() onTimeChangeEvent: EventEmitter<any> = new EventEmitter();
    @Output() videoErrorEvent: EventEmitter<any> = new EventEmitter();
    //added for Teaser NPM
    @Input() isNPM: boolean;
    @Input() isTouch: boolean;
    @Input() isNewSportsNative: boolean;
    @Input() globalTeaserSettings: any;
    @Input() teaserDynaconConfigs: any;
    @Input() showJackpotCurrencyOnRight: any;
    @Input() jackpotGroupNameMapTeaserNPM: any;
    @Input() globalTeaserStyles: any;
    //added for Teaser NPM
    horizontalPosition: string = '';
    imagePosition: string = '';
    verticalPosition: string = '';
    horizontalAlignment: string = '';
    termsTextPositionClass: string = '';
    openBingoLobbyButtonPosition: string = '';
    customId: any;
    customClass: string = '';
    teaserLaunchRealtiveUri: string = '';

    readonly isMobileWidth = this.deviceService.isMobileWidth;
    teaserDefaultContentBg: string = '';
    showTimer: boolean;
    smallTermsClass: string = '';
    teaserBackgroundImageAlignment: string = '';
    teaserBackgroundPosition: number;
    teaserVideoAlignment: string = '';
    teaserVideoPosition: number;
    videoUrl: string;
    videoType: string;
    enableEpcotSubNavigation: boolean;
    deviceName: string;
    optTeasers: any;

    constructor(
        private configProviderService: ConfigProviderService,
        private casinoLobbyService: CasinoLobbyService,
        private navigation: NavigationService,
        private tracking: TrackingService,
        private casinoManager: CasinoManager,
        private NativeApplication: NativeAppService,
        private vcClientService: VCClientService,
        private user: UserService,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private gameLaunchPlaces: GameLaunchPlaces,
        private nativeAppHelper: NativeAppHelperService,
        private optimizedTeaserManager: OptimizedTeaserManager,
        private casinoWidgetService: CasinoWidgetService,
        private casinoIFrameOverlayService: CasinoIFrameOverlayService,
        private gameLaunchTrackingService: GameLaunchTrackingService,
        private deviceService: DeviceService,
    ) {
        this.teaserLaunchRealtiveUri = '/' + this.configProviderService?.provideMcPageConfig()?.labelPrefix + '/launchng/';
    }

    ngOnInit() {
        this.videoType = this.teaser?.videoUrl?.videoType;
        const videoFiltered: string[] = [this.teaser.videoUrl?.videoUrlVersioned, this.teaser.videoUrl?.videoUrlShared].filter((x) => x);
        const cmsFiltered: string[] = [this.teaser.videoUrl?.cmsVideoUrlVersioned?.id, this.teaser.videoUrl?.cmsVideoUrlShared?.id].filter((x) => x);
        if (cmsFiltered.length > 0) {
            this.videoUrl = cmsFiltered[0];
            this.videoType = 'cms';
        } else if (videoFiltered.length > 0) {
            this.videoUrl = videoFiltered[0];
        }
        if (!this.isNPM) this.enableEpcotSubNavigation = this.configProviderService.provideSubNavigationConfig()?.enableEpcotSubNavigation;
    }

    ngAfterViewInit() {
        const css = this.teaser.customCss;
        const head = document.getElementById('OptTeaserCarousel_' + this.identifierClass);
        const style1 = document.createElement('style');
        const cId = 'customstyle_' + this.customId;
        style1.id = cId;
        if (head != null) {
            document.getElementById(cId)?.remove();
            head.appendChild(style1);
            style1.type = 'text/css';
            style1.appendChild(document.createTextNode(css));
            const style = document.createElement('style');
            style.textContent = this.globalTeaserStyles?.css;
            document.head.appendChild(style);
        }
    }

    ngOnChanges() {
        let optimizedTeaserClientConfig: any;
        if (!this.isNPM) {
            this.globalTeaserSettings = this.optimizedTeaserManager.getGlobalTeaserSettings();
            optimizedTeaserClientConfig = this.configProviderService.provideOptimizedTeaserConfig();
            this.isTouch = this.casinoManager.isTouch();
            this.isNewSportsNative =
                this.NativeApplication.isNativeApp &&
                (this.NativeApplication.product === 'SPORTSBOOK' || this.NativeApplication.product === 'BETTING');
        } else {
            optimizedTeaserClientConfig = this.teaserDynaconConfigs;
        }
        const teaserDefaultTextHorizontalPosition: any =
            this.globalTeaserSettings.sharedList.teaserDefaultTextHorizontalPosition || optimizedTeaserClientConfig.teaserDefaultHorizontalAlignment;
        const teaserDefaultTextVerticalPosition: any =
            this.globalTeaserSettings.sharedList.teaserDefaultTextVerticalPosition || optimizedTeaserClientConfig.teaserDefaultVerticalAlignment;
        const teaserDefaultImagePosition: any =
            this.globalTeaserSettings.sharedList.teaserDefaultImagePosition || optimizedTeaserClientConfig.teaserDefaultImageAlignment;
        const teaserDefaultTermsTextAlignment: any =
            this.globalTeaserSettings.sharedList.teaserDefaultTermsTextPosition || optimizedTeaserClientConfig.teaserDefaultTermsTextAlignment;
        const teaserDefaultHorizontalTextAlignment: any =
            this.globalTeaserSettings.sharedList.teaserDefaultHorizontalTextAlignment ||
            optimizedTeaserClientConfig.teaserDefaultHorizontalTextAlignment;
        this.horizontalPosition =
            this.teaser.textHorizontalPosition != 'Default' ? this.teaser.textHorizontalPosition : teaserDefaultTextHorizontalPosition;
        this.verticalPosition = this.teaser.textVerticalPosition != 'Default' ? this.teaser.textVerticalPosition : teaserDefaultTextVerticalPosition;
        this.imagePosition = this.teaser.imagePosition != 'Default' ? this.teaser.imagePosition : teaserDefaultImagePosition;
        this.openBingoLobbyButtonPosition = 'casino-playbingo-btn' + this.globalTeaserSettings.sharedList.openBingoLobbyButtonPosition;
        this.termsTextPositionClass = ('text-align-' + teaserDefaultTermsTextAlignment).toLowerCase();
        if (this.teaser.optimizedTeaserAttributes) {
            this.teaserBackgroundImageAlignment =
                this.teaser.optimizedTeaserAttributes.teaserBackgroundImageAlignment ||
                this.globalTeaserSettings.sharedList.teaserBackgroundImageAlignment;
            this.teaserBackgroundPosition =
                this.teaser.optimizedTeaserAttributes.teaserBackgroundPosition || this.globalTeaserSettings.sharedList.teaserBackgroundPosition;
            this.teaserVideoAlignment =
                this.teaser.optimizedTeaserAttributes.teaserVideoAlignment || this.globalTeaserSettings.sharedList.teaserVideoAlignment;
            this.teaserVideoPosition =
                this.teaser.optimizedTeaserAttributes.teaserVideoPosition || this.globalTeaserSettings.sharedList.teaserVideoPosition;
        }
        this.horizontalAlignment = teaserDefaultHorizontalTextAlignment;
        if (this.globalTeaserSettings && this.globalTeaserSettings.sharedList) {
            this.teaserDefaultContentBg = this.globalTeaserSettings.sharedList.teaserDefaultContentBg || '';
            this.showTimer =
                (this.globalTeaserSettings &&
                    this.globalTeaserSettings.sharedList &&
                    this.globalTeaserSettings.sharedList.showTimer &&
                    this.globalTeaserSettings.sharedList.showTimer.toLowerCase()) == 'true'
                    ? true
                    : false;
        }
        this.smallTermsClass = this.showTimer ? 'small-terms-timer' : 'small-terms';
        this.customClass = this.teaser?.htmlClasses || this.teaser?.data.metadata.id.itemName;
        this.customId = this.teaser?.htmlId || this.teaser?.data.metadata.id.itemName;
        switch (true) {
            case this.deviceService.windowWidth() <= 480:
                this.deviceName = 'Mobile';
                break;
            case this.deviceService.windowWidth() >= 481 && this.deviceService.windowWidth() <= 1023:
                this.deviceName = 'Tablet';
                break;
            case this.deviceService.windowWidth() >= 1024:
                this.deviceName = 'Desktop';
                break;
        }
    }

    teaserClick(event: any, teaser: any, bannerPos: any) {
        event.stopPropagation();
        if (!this.isNPM) {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'Teaser_Banners',
                'component.LabelEvent': teaser.data.metadata.id.itemName,
                'component.PositionEvent': 'Teaser_' + bannerPos,
                'component.LocationEvent': 'main_Teaser',
                'component.ActionEvent': 'Teaser - Click',
                'component.EventDetails': 'User taps on teasers',
                'component.URLClicked': teaser?.teaserLink?.url,
            });
        }
        if (!this.isNPM && (this.isNewSportsNative || this.vcClientService.isPokerVC())) {
            const link: any = teaser?.teaserNativeLink?.url;
            if (link) {
                this.track('tp', link, true);
                this.trackGameLaunch(link, true, bannerPos);
                const options = { skipLoadingIndicator: false };
                if (link.substr(link.length - 4, 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                const infiIndex = link.indexOf('?event=promo');
                if (infiIndex === -1) {
                    if (this.vcClientService.isPokerVC() && this.isGameUrl(link)) {
                        this.launchVCGame(link, bannerPos);
                        return;
                    }
                    this.handleNavigation(link, teaser?.teaserNativeLink?.attributes, !options.skipLoadingIndicator);
                } else {
                    const event: NativeEvent = {
                        eventName: 'Native_Optimized_Teaser_Click',
                        parameters: {
                            teaserCtaNativeLinkUrl: link.substr(infiIndex),
                        },
                    };
                    this.NativeApplication.sendToNative(event);
                }
            }
        } else {
            if (teaser?.teaserLink?.url) {
                if (!this.isNPM) {
                    this.track('tp', teaser.teaserLink.url, true);
                    this.handleTeaserClick(teaser.teaserLink, bannerPos);
                } else {
                    const options = { skipLoadingIndicator: false };
                    if (teaser?.teaserLink?.url?.substr(teaser.teaserLink.url.length - 4, 4).toLowerCase() === '.apk') {
                        options.skipLoadingIndicator = true;
                    }
                    this.navigation.goTo(teaser?.teaserLink?.url, { showSpinnerForExternalNavigation: !options.skipLoadingIndicator });
                }
            }
        }
    }

    ctaClick(event: any, teaser: any, bannerPos: any, isPrimary: boolean) {
        event.stopPropagation();
        const cta: any = isPrimary ? teaser?.cta : teaser?.secondaryCTA;
        if (!this.isNPM) {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'Teaser_Banners',
                'component.LabelEvent': teaser.data.metadata.id.itemName,
                'component.PositionEvent': 'Teaser_' + bannerPos,
                'component.LocationEvent': 'main_Teaser',
                'component.ActionEvent': 'CTA Click',
                'component.EventDetails': 'CTA Name|' + (isPrimary ? teaser?.cta?.text : teaser?.secondaryCTA?.text),
                'component.URLClicked': cta?.url,
            });
        }
        if (!this.isNPM && (this.isNewSportsNative || this.vcClientService.isPokerVC())) {
            const link: any = cta?.url;
            if (link) {
                this.track('tp', link, true);
                this.trackGameLaunch(link, true, bannerPos);
                const options = { skipLoadingIndicator: false };
                if (link.substr(link.length - 4, 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                const infiIndex = link.indexOf('?event=promo');
                if (infiIndex === -1) {
                    if (this.vcClientService.isPokerVC() && this.isGameUrl(link)) {
                        this.launchVCGame(link, bannerPos);
                        return;
                    }
                    this.handleNavigation(link, cta.attributes, !options.skipLoadingIndicator);
                } else {
                    const event: NativeEvent = {
                        eventName: 'Native_Optimized_Teaser_Cta_Click',
                        parameters: {
                            teaserCtaNativeLinkUrl: link.substr(infiIndex),
                        },
                    };
                    this.NativeApplication.sendToNative(event);
                }
            }
        } else if (cta) {
            if (!this.isNPM) {
                this.handleTeaserClick(cta, bannerPos);
            } else {
                const options = { skipLoadingIndicator: false };
                if (cta?.url?.substr(cta.url.length - 4, 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                this.navigation.goTo(cta?.url, { showSpinnerForExternalNavigation: !options.skipLoadingIndicator });
            }
        }
    }

    public termsClick(event: any, teaser: any) {
        event.stopPropagation();
        if (!this.isNPM && this.isNewSportsNative) {
            const link: any = teaser?.termsNativeLink?.url;
            if (link) {
                this.track('tp', link, true);
                const options = { skipLoadingIndicator: false };
                if (link.substr(link.length - 4, 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                const infiIndex = link.indexOf('?event=promo');
                if (infiIndex === -1) {
                    this.handleNavigation(link, teaser.termsNativeLink.attributes, !options.skipLoadingIndicator);
                } else {
                    const event: NativeEvent = {
                        eventName: 'Native_Optimized_Teaser_Terms_Click',
                        parameters: {
                            teaserTermsNativeLinkUrl: link.substr(infiIndex),
                        },
                    };
                    this.NativeApplication.sendToNative(event);
                }
            }
        } else if (teaser?.termsNativeLink) {
            if (!this.isNPM) {
                this.handleTeaserClick(teaser.termsNativeLink, null);
            } else {
                const options = { skipLoadingIndicator: false };
                if (teaser?.termsNativeLink?.url?.substr(teaser.termsNativeLink.url.length - 4, 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                this.navigation.goTo(teaser?.termsNativeLink?.url, { showSpinnerForExternalNavigation: !options.skipLoadingIndicator });
            }
        }
    }

    public track(where: any, what: any, isUrl: any) {
        this.tracking.updateDataLayer({
            'page.name': 'mcTeasers',
            'event': 'pageView',
            'page.url': '?source=' + where + '&' + (isUrl ? 'href=' : 'gameId=') + what,
        });
    }

    public trackGameLaunch(gameLaunchInfo: any, isUrl: boolean, bannerPos: any) {
        let gameType = '';
        let gameTrackingObj: any = {};
        if (isUrl) {
            const launchUrlIndex: number = gameLaunchInfo.indexOf(this.teaserLaunchRealtiveUri);
            if (launchUrlIndex > 0) {
                const gameId: any = gameLaunchInfo.substr(launchUrlIndex + this.teaserLaunchRealtiveUri.length);
                const gameData: any = this.casinoLobbyService.getGameInfo(gameId);
                if (!gameData.isDataNotAvailable) {
                    gameType = gameData == null ? '' : gameData.launchType;
                    const gameDisplayName = gameData == null ? '' : gameData.title;
                    gameTrackingObj = {
                        name: gameId,
                        type: gameType,
                        position: bannerPos,
                        section: 'Teaser',
                        displayName: gameDisplayName,
                    };
                }
            }
        } else {
            gameTrackingObj = {
                name: gameLaunchInfo,
                type: gameType,
                position: 1,
                section: '',
            };
        }
        if (Object.keys(gameTrackingObj).length > 0) {
            this.gameLaunchTrackingService.track(gameTrackingObj);
        }
    }

    isGameUrl(link: string) {
        return link.indexOf(this.teaserLaunchRealtiveUri) > 0;
    }

    launchVCGame(link: string, bannerPos: any) {
        if (this.isGameUrl(link)) {
            const gameId = this.getGameIdFromTeaserHref(link);
            const gameLaunchModel = new GameLaunch();
            const game = { gameId: gameId };
            gameLaunchModel.game = game;
            gameLaunchModel.isTouch = this.isTouch;
            gameLaunchModel.gamePosition = Number(bannerPos);
            gameLaunchModel.categoryType = 'OptimisedTeaser';
            gameLaunchModel.from = 'OptimisedTeaser';
            this.vcClientService.handleVCGameLaunch(gameLaunchModel, false);
        }
    }

    getGameIdFromTeaserHref(link: string) {
        return this.isGameUrl(link) ? link.substring(link.indexOf(this.teaserLaunchRealtiveUri) + this.teaserLaunchRealtiveUri.length) : '';
    }

    handleTeaserClick(link: any, bannerPos: any) {
        const href = link?.url;
        const options: any = { skipLoadingIndicator: false };
        const gameId: string = this.getGameIdFromTeaserHref(href);
        if (href?.substr(href.length - 4, 4).toLowerCase() === '.apk') {
            options.skipLoadingIndicator = true;
        }
        if (this.configProviderService.provideGameConfig().nativeGameLaunchEnabled && gameId) {
            this.launchNativeGame(href, bannerPos);
            return;
        }
        if (gameId) {
            let gameHref: string = cloneDeep(href);
            if (this.configProviderService.provideAdvanceGameDataConfig().enableAdvanceGameData) {
                gameHref = this.gameLaunchService.appendQueryString(href, 'adv_parentcat', 'Teaser');
                gameHref = this.gameLaunchService.appendQueryString(gameHref, 'adv_pos', String(bannerPos - 1));
            }
            if (this.casinoWidgetService.isCasinoWidget() || this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                if (this.isTouch) {
                    if (this.casinoWidgetService.isCasinoWidget() && gameId) {
                        const postData: any = {
                            promptLogin: !this.user.isAuthenticated,
                            redirectUrl: gameHref,
                            redirectOptions: this.user.isAuthenticated ? { showSpinnerForExternalNavigation: !options.skipLoadingIndicator } : null,
                        };
                        this.casinoWidgetService.postMessageToParent('UserAction', postData);
                        return;
                    } else if (this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                        let redirectPlayUrl: string = gameHref;
                        redirectPlayUrl = this.gameLaunchService.appendQueryString(
                            redirectPlayUrl,
                            'invokerProduct',
                            this.casinoWidgetService.getWidgetProduct(),
                        );
                        if (this.user.isAuthenticated) {
                            redirectPlayUrl = this.gameLaunchService.appendQueryString(redirectPlayUrl, 'returnurl', document.referrer);
                            redirectPlayUrl = this.gameLaunchService.appendQueryString(redirectPlayUrl, 'widgetGameLaunch', 'true');
                        }
                        const postData: any = {
                            promptLogin: !this.user.isAuthenticated,
                            redirectUrl: redirectPlayUrl,
                        };
                        this.casinoWidgetService.postMessageToParent('UserAction', postData);
                        return;
                    }
                } else {
                    if (this.casinoWidgetService.isCasinoWidget()) {
                        gameHref = this.gameLaunchService.appendQueryString(gameHref, 'widgetHomeUrl', document.referrer);
                        this.casinoWidgetService.postMessageToParent('UserAction', {
                            promptLogin: !this.user.isAuthenticated,
                            redirectUrl: gameHref,
                        });
                        return;
                    }
                }
            } else {
                const gameInfo: any = this.casinoLobbyService.getGameInfo(gameId);
                this.isTouch = this.casinoManager.isTouch();
                if (gameInfo) {
                    gameInfo.gameId = gameId;
                    const gameModel = new GameLaunch();
                    gameModel.game = gameInfo;
                    gameModel.isTouch = this.isTouch;
                    gameModel.gamePosition = bannerPos - 1;
                    gameModel.categoryType = this.teaserCategory;
                    gameModel.from = 'q';
                    gameModel.replaceRouteInHistory = false;
                    gameModel.isDemoLaunch = false;
                    gameModel.skipUnfinGame = false;
                    gameModel.gameLaunchedFrom = this.gameLaunchPlaces.teaser;
                    gameModel.gridPosition = 1;
                    gameModel.gameContainer = this.gameContainer;
                    gameModel.game.lobbyType = this.lobbyType;
                    gameModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
                    this.gameLaunchService.launchGame(gameModel);
                }
            }
        } else {
            if (this.casinoWidgetService.isCasinoWidget() || this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                this.casinoWidgetService.postMessageToParent('UserAction', {
                    redirectUrl: href,
                    redirectOptions: { showSpinnerForExternalNavigation: !options.skipLoadingIndicator },
                });
                return;
            } else {
                this.handleNavigation(href, link.attributes, !options.skipLoadingIndicator);
            }
        }
    }

    launchNativeGame(link: string, bannerPos: any) {
        if (this.isGameUrl(link)) {
            const gameId = this.getGameIdFromTeaserHref(link);
            const params = { gameId: gameId, skipUnfinGame: false };
            const gameLaunchModel = new GameLaunch();
            const game = { gameId: gameId };
            gameLaunchModel.game = game;
            gameLaunchModel.isTouch = this.isTouch;
            gameLaunchModel.gamePosition = Number(bannerPos);
            gameLaunchModel.categoryType = 'OptimisedTeaser';
            gameLaunchModel.from = 'OptimisedTeaser';
            this.nativeAppHelper.handleNativeGameLaunch(params, gameLaunchModel);
        }
    }

    emitVideoLoadedEvent() {
        this.onVideoLoadedEvent.emit();
    }

    getVideoEndEvent() {
        this.onVideoEndEvent.emit();
    }

    videoError() {
        this.videoErrorEvent.emit();
    }

    getTimeChangeEvent(event: any) {
        this.onTimeChangeEvent.emit(event);
    }

    private handleNavigation(url: string, attributes: any, showSpinnerForExternalNavigation: boolean) {
        if (attributes && attributes.hasOwnProperty('target')) {
            window.open(url, attributes.target);
        } else {
            this.navigation.goTo(url, { showSpinnerForExternalNavigation: showSpinnerForExternalNavigation });
        }
    }
}
