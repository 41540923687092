<div class="casino-thumbnail-wrapper" [style.background]="thumbnailBgColor" *ngIf="displayThumbnail">
    <div class="thumbnail-data" (click)="action(game)">
        <div class="thumbnail-icon-div vthumbnail-inline" *ngIf="data.src">
            <img src="{{ data.src }}" class="thumbnail-icon" (error)="onGameIconError(data.src)" />
        </div>
        <div class="thumbnail-title-wrapper vthumbnail-inline" [style.color]="fontColor">
            <div class="thumbnail-title" *ngIf="data.title">{{ data.title }}</div>
            <div class="thumbnail-subtitle" *ngIf="data.subTitle">{{ data.subTitle }}</div>
            <div *ngIf="data.isVipThumbnail && data.sticker != ''" class="thumbnail-sticker-wrapper">
                <div
                    class="thumbnail-sticker"
                    *ngFor="let VIPSticker of data.sticker?.split(',')"
                    [ngStyle]="{ color: config.VIPStickerColor, background: config.VIPStickerBackgroundColor }">
                    {{ VIPSticker }}
                </div>
            </div>
        </div>
        <div class="thumbnail-ctalink vthumbnail-inline" [style.color]="fontColor">{{ buttonText }}</div>
        <div class="clear"></div>
    </div>
    <div class="thumbnail-close" [style.color]="fontColor">
        <div class="theme-ex" (click)="hideThumbnail(false)"></div>
        <div class="clear"></div>
    </div>
</div>
