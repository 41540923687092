import { Component, ComponentRef, Input, OnChanges, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { OutputEventModel } from '@casinocore/platform/core';

import { LazyRouteService } from './casino-core-lazy-route.service';

@Component({
    selector: 'cc-casino-core-lazy-placeholder',
    templateUrl: 'casino-core-lazy-placeholder.component.html',
    standalone: true,
})
export class CasinoCoreLazyPlaceholderComponent implements OnInit, OnChanges {
    @Input() componentName: string;
    @Input() inputData: any;
    @Input() outputEvents: OutputEventModel[];
    componentRef_: ComponentRef<any>;
    @ViewChild('casinoCoreLazyTemplate', { read: ViewContainerRef, static: true }) casinoCoreLazyPlaceholdercontainer: ViewContainerRef;
    constructor(private lazyRouteService: LazyRouteService) {}
    ngOnInit() {
        const componentData = {
            type: this.componentName,
        };
        this.lazyRouteService.loadAndRenderLazyComponents(componentData).then((componentFactory: any) => {
            this.componentRef_ = this.casinoCoreLazyPlaceholdercontainer.createComponent(componentFactory);
            for (const attribute in this.inputData) {
                this.componentRef_.instance[attribute] = this.inputData[attribute];
            }
            if (this.outputEvents) {
                this.outputEvents.forEach((outputEvent: OutputEventModel) => {
                    this.componentRef_.instance[outputEvent.eventName].subscribe((data: any) => {
                        outputEvent.callback(data);
                    });
                });
            }
            this.componentRef_.changeDetectorRef.detectChanges();
        });
    }
    ngOnChanges() {
        if (this.componentRef_) {
            for (const attribute in this.inputData) {
                this.componentRef_.instance[attribute] = this.inputData[attribute];
            }
            try {
                this.componentRef_.instance.ngOnChanges();
            } catch {
                // ignored;
            }
        }
    }
}
