import { Component, Input, ViewEncapsulation } from '@angular/core';

import { LottieComponent } from 'ngx-lottie';

@Component({
    selector: 'cc-lottie-loading',
    templateUrl: 'lottie-loading.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [LottieComponent],
})
export class LottieLoadingComponent {
    @Input() path: string;
}
