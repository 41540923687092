import { Injectable } from '@angular/core';

import { CasinoLobbyService, RecentGamesService } from '@casinocore/platform/core';

export interface ILocalStorageCollectedLobby {
    lobbyType: string;
    feed: any;
}

@Injectable({ providedIn: 'root' })
export class GLobalSearchService {
    constructor(
        private casinoLobbyService: CasinoLobbyService,
        private recentGamesService: RecentGamesService,
    ) {}

    init(): void {
        addEventListener('LocalStorage_Collected_Lobbies', this.handleCollectedLobbies, false);
        addEventListener('gs:search:Request_Casino_Source', this.getandDispatchData, false);
        addEventListener('casino:Recent_Games_Updated', this.handleRecentlyPlayedGames, false);
        addEventListener('gs:search:Request_Recent_Games_From_Casino', this.getAndDispatchRecentlyPlayed, false);
    }

    private getAndDispatchRecentlyPlayed = () => {
        const recentGames = this.recentGamesService.getRecentGames();
        recentGames && Object.keys(recentGames).length > 0 && this.dispatchRecentGames(recentGames);
    };

    private dispatchRecentGames = (recentGames: any) => {
        dispatchEvent(new CustomEvent('gs:search:Casino_Recent_Games_Updated', { detail: recentGames }));
    };

    private handleCollectedLobbies = (customevent: CustomEvent) => {
        const collectedLobbies = customevent && customevent.detail && customevent.detail.collectedLobbies;

        this.respondToDataConstributor(collectedLobbies);
    };

    private respondToDataConstributor(content: ILocalStorageCollectedLobby[]): void {
        const status: boolean = content && content.length > 0;
        dispatchEvent(new CustomEvent('gs:search:Casino_Data_Source', { detail: { status: status, content: content } }));
    }

    private getandDispatchData = () => {
        this.casinoLobbyService.readAndDispatchLobbyDataForGlobalSearch(true);
    };

    private handleRecentlyPlayedGames = (event: CustomEvent) => {
        if (event && event.detail) {
            this.dispatchRecentGames(event.detail);
        }
    };
}
