import { NgIf, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NativeAppService, NavigationService } from '@frontend/vanilla/core';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { GlobalTranslateComponent } from '../directives/globaltranslate/globaltranslate.component';

@Component({
    selector: 'cc-header-box',
    templateUrl: 'header-box.component.html',
    standalone: true,
    imports: [NgIf, NgStyle, GlobalTranslateComponent],
})
export class HeaderBoxComponent implements OnInit {
    @Input() showInNative?: boolean;
    @Input() title: string;
    @Output() navigateTo: EventEmitter<any> = new EventEmitter();
    isVisible: boolean;
    isNavigation: boolean;
    feature: boolean;
    error: boolean;
    goBackURL: string | null;
    constructor(
        private nativeApplication: NativeAppService,
        private navigation: NavigationService,
        private configProviderService: ConfigProviderService,
    ) {}

    ngOnInit() {
        const mcPage = this.configProviderService.provideMcPageConfig();
        this.isVisible = this.showInNative === true || !this.nativeApplication.isNative;

        this.isNavigation = false;
        if (this.navigateTo && this.navigateTo.observers && this.navigateTo.observers.length > 0) {
            this.isNavigation = true;
        }
        this.goBackURL = this.navigation.location.search.get('gobackUrl');
        if (!this.goBackURL || this.goBackURL === '') {
            this.goBackURL = mcPage.prefix;
        }
    }

    goBack() {
        this.navigateTo.emit();
        return;
    }
}
