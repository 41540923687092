<vn-swiper #miniCarousel [swiperOptions]="swiperOptionsOptMiniTeasers">
    <div class="swiper-slide" *ngFor="let teaserItem of carouselTileItems; let i = index">
        <mat-grid-list [cols]="2" rowHeight="{{ miniTeaserRowHeight }}px">
            <mat-grid-tile
                *ngFor="let tileItem of teaserItem | dsl | async; let j = index"
                [colspan]="tileItem.colSpan"
                [rowspan]="tileItem.rowSpan"
                [class]="'mat-grid-tile tileicon-' + tileItem.iconTileCode + ' tileicon-pos-' + j">
                <div [class]="'miniTeasers icon-' + tileItem.iconTileCode">
                    <cc-opt-mini-teaser-item
                        [teasersSource]="tileItem"
                        [teaserIndex]="i"
                        [teaserCategory]="teaserCategory"
                        [lobbyType]="lobbyType"
                        [gameContainer]="gameContainer">
                    </cc-opt-mini-teaser-item>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</vn-swiper>
<ng-container *ngIf="showPagination && carouselConfig.dots && !hidePagination">
    <cc-custom-carousel-pagination
        [noOfItems]="carouselTileItems.length"
        [currentIndex]="currentIndex"
        [carousel]="swiperRef"></cc-custom-carousel-pagination>
</ng-container>
