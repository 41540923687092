<div class="casino-lobby-container-main">
    <div class="casino-qi-noverlay" (click)="closeModel()"></div>
    <div class="casino-qi-nrectangle casino-qi-popup">
        <div class="casino-total-data">
            <div class="casino-qi-nrectangle-int">
                <div class="casino-qi-nimage casino-qi-desktop-nimage bingo-quick-info">
                    <img src="{{ data.src }}" />
                    <div *ngIf="data.jackpotData" class="casino-qi-stickers">
                        <div class="casino-qi-Jackpotcta">
                            <div class="casino-qi-hot-icon">
                                <img [src]="hotIcon" />
                            </div>
                            <div class="qi-jp-value">{{ data.jackpotData | vnCurrency }}</div>
                        </div>
                    </div>
                    <div>
                        <div class="casino-qi-ngradient"></div>
                        <div
                            (click)="toggleFavourite(data.room)"
                            class="casino-qi-nfavourite"
                            [ngClass]="data.room.isFavourite ? favouriteStyle : unFavouriteStyle"></div>
                    </div>
                </div>
                <div class="casino-qi-nrectangle-right bingo-qi-nrectangle-right" [style.width.px]="boxWidthInt">
                    <div class="close theme-close qi-close casino-qi-desktop-close" (click)="closeModel()"></div>
                </div>
                <div style="clear: both"></div>
            </div>
            <div class="gamedata">
                <div class="casino-qi-ngame">{{ data.roomTitle }}</div>
                <span class="gameData-body" [innerHTML]="data.roomDescription"></span>
            </div>
            <div class="casino-qi-ncta forbingo" (click)="launchGame()" [style.width.px]="boxWidthInt">
                <button class="casino-qi-nplay-now btn">{{ data.cta | uppercase }}</button>
            </div>
        </div>
    </div>
</div>
