import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SearchSectionService {
    private isMobileSearchVisible = new BehaviorSubject<boolean>(false);
    private isDesktopSearchGYMLVisible = new BehaviorSubject<boolean>(false);
    searchVisibilityStatus: Observable<boolean> = this.isMobileSearchVisible.asObservable();
    searchGYMLVisibilityStatus: Observable<boolean> = this.isDesktopSearchGYMLVisible.asObservable();

    setSearchVisibility(isVisible: boolean) {
        this.isMobileSearchVisible.next(isVisible);
    }
    // NFR - Services optimization - Remove
    setDesktopSearchGYMLVisibility(isDesktopSearchGYMLVisible: boolean) {
        this.isDesktopSearchGYMLVisible.next(isDesktopSearchGYMLVisible);
    }
}
