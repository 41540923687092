import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csSlotRaces', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: slotRacesClientConfigFactory,
})
export class SlotRacesClientConfig {
    enableOptIn: boolean;
    host: string;
    joinUrlTemplate: string;
    leaderboardUrl: string;
    raceListUrl: string;
    racesListPollingInterval: number;
    notificationPopupTimeout: number;
    leaderboardPollingInterval: number;
    showAwardsAndPrizes: boolean;
    slotRacesLMTCategoryIdForLobbyType: any;
    maxLeaderboardRows: number;
    upcomingSlotsDuration: number;
    noOfCards: number;
    resultsForNoOfDays: number;
    enablePreLogin: boolean;
    disableWhenNoPromotions: boolean;
    enableResultsHistoryPage: boolean;
    enableMaxBet: boolean;
    enableStickersFreeToPlay: boolean;
    enableStickersFreePlayTech: boolean;
    enableSlotRacesStickers: boolean;
    enableJpRibbonOnFreeTourney: boolean;
    enable24HrTimeFormat: boolean;
    enableSlotRaces: boolean;
    displayBonusAsText: boolean;
    upcomingSlotStickersDuration: number;
    slotraceLobbytypeForGamesOrdering: string;
    enableBetterVisualization: boolean;
    hideGenericPoints: boolean;
    enableUpcomingOptinForPlaytech: boolean;
    disablePreloginApiCalls: boolean;
    disablePrizesApiCall: boolean;
    enableRaceNotification: boolean;
    disableSlotRacesPoints: boolean;
    enableCoinEconomy: boolean;
    enableConfigMaps: boolean;
    enableCoinShower: boolean;
    resultsDateFormat: string;
    enableSlotRacesPhase2: boolean;
    raceInfoGameCount: number;
    characterIconPath: string;
    enableTermsNConditions: boolean;
}

export function slotRacesClientConfigFactory(service: LazyClientConfigService) {
    return service.get(SlotRacesClientConfig);
}
