import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';

import { CommonMessages } from '@frontend/vanilla/core';

import { ConfigProviderService } from '../config-provider/config-provider.service';

@Component({
    selector: 'cc-game-pop-out',
    templateUrl: 'game-pop-out.component.html',
    standalone: true,
    imports: [MatDialogContent, MatDialogActions, MatDialogClose],
})
export class GamePopoutComponent implements OnInit {
    gamePopOutUrl: string;
    continue: string;
    cancel: string;
    gameLaunchNewWindow: string;
    gameSessionRestart: string;

    constructor(
        public dialogRef: MatDialogRef<GamePopoutComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private commonMessages: CommonMessages,
        private configProviderService: ConfigProviderService,
    ) {}
    onNoClick(): void {
        this.dialogRef.close();
    }
    ngOnInit() {
        const global = this.configProviderService.provideGlobalConfig();
        const mcPage = this.configProviderService.provideMcPageConfig();
        this.continue = this.commonMessages['Continue'];
        this.cancel = this.commonMessages['Cancel'];
        this.gameLaunchNewWindow = global.messages['LaunchGameNewWindow'];
        this.gameSessionRestart = global.messages['GameSessionRestart'];
        this.gamePopOutUrl = mcPage.cashierDepositUrlTemplate;
    }
}
