import { Injectable } from '@angular/core';

import { AppInfoConfig, TimerService, TrackingService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';

@Injectable({
    providedIn: 'root',
})
export class WinnersService {
    private recentWinners = new BehaviorSubject<any>(null);
    recentWinnersObservable: Observable<any> = this.recentWinners.asObservable();
    sortInterval: any;
    feedType: any;
    constructor(
        private api: PlatformApiService,
        private timerService: TimerService,
        private configProviderService: ConfigProviderService,
        private tracking: TrackingService,
        private appInfoConfig: AppInfoConfig,
    ) {}
    fetchRecentWinnersFeed(val: any): void {
        this.feedType = val;
        this.api
            .post('recentwinners/GetRecentWinners', { feedType: val }, { responseType: 'json', withCredentials: true, showSpinner: false })
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.recentWinners.next(response);
                    }
                },
                error: () => {
                    //console.log(error);
                },
            });
    }
    winnersFeedInterval() {
        const recentWinnersConfig = this.configProviderService.recentWinnersWidgetConfig();
        if (this.sortInterval) {
            clearInterval(this.sortInterval);
        }
        this.sortInterval = this.timerService.setIntervalOutsideAngularZone(() => {
            this.fetchRecentWinnersFeed(this.feedType);
        }, recentWinnersConfig.recentWinnersInterval);
    }
    addTrackingEvent(
        categoryEvent: string,
        labelEvent: string,
        actionEvent: string,
        positionEvent: string,
        locationEvent: string,
        eventDetails: string,
        uRLClicked: string,
    ) {
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': categoryEvent,
            'component.LabelEvent': labelEvent,
            'component.ActionEvent': actionEvent,
            'component.PositionEvent': positionEvent,
            'component.LocationEvent': this.appInfoConfig.product,
            'component.EventDetails': eventDetails,
            'component.URLClicked': uRLClicked,
        });
    }
}
