<div class="casino-cozy-rp-bingorooms">
    <cc-cozybingo-game-info
        *ngIf="roomInfo"
        [bingoItem]="roomInfo"
        [featureConfigs]="featureConfigs"
        [globalMessages]="globalMessages"
        [categoryList]="categoryList"
        [gamePosition]="gamePosition"
        [gameLaunchedFrom]="gameLaunchedFrom"
        [product]="product"
        [lobbyType]="lobbyType"
        [widgetFrameClr]="widgetFrameClr"></cc-cozybingo-game-info>
</div>
