<div class="casino-game-cls small-icon">
    <a
        (click)="action(game)"
        class="game-image-link"
        [ngStyle]="{ 'border-radius': radius, 'background': unfinishedBG }"
        (window:resize)="onResize()">
        <cc-image-loader
            [icon]="game.icon"
            [iconType]="game.iconType"
            [gameInfo]="game.gameId"
            [forcevisible]="'true'"
            [alt]="game.icon.alt"
            [style]="setUnfinishedImageStyle()"
            [disableLazyLoading]="true"></cc-image-loader>
        <div
            class="jackpot"
            [ngStyle]="{ background: jpRibbonStyles?.jpBgColor, color: jpRibbonStyles?.jpTextColor }"
            [ngClass]="{ 'embed-jp-styles': newJackpotRibbonStyling }"
            [class.is-fast-loading-game-embed]="game.isFastLoadingGame"
            *ngIf="showJackpotOnGameTiles && game.jackpotValue">
            <span class="hot-icon" *ngIf="game.isHotJackpotGroup && enableHotIcon">
                <img [src]="hotIcon" *ngIf="!enableEpcotSubNavigation" />
                <img [src]="epcotHotIcon" *ngIf="enableEpcotSubNavigation" />
            </span>
            <span>{{ game.jackpotValue }}</span>
        </div>
        <div *ngIf="game.isFastLoadingGame" class="fast-loading-game">
            <img class="embed-fast-loading-img" src="/pictures/Fast-Loading-Game-Animation.gif" />
        </div>
        <div [ngClass]="gameClass"><span></span></div>
        <div *ngIf="isTouch">
            <div *ngIf="useQuickInfo; else Favourite" class="game-badge theme-info quick-info">
                <span (click)="displayQuickInfo(game, $event)"></span>
            </div>
            <ng-template #Favourite>
                <div *ngIf="useFavouriteGames" class="game-badge" [ngClass]="game.isFavourite ? 'theme-favorite-i' : 'theme-favorite'">
                    <span (click)="toggleFavourite(game, $event)"></span>
                </div>
            </ng-template>
        </div>
        <span class="theme-playbutton embed-play-icon"></span>
    </a>
    <div *ngIf="showGameTitle" class="game-title">
        <a (click)="action(game)">{{ game.title }}</a>
        <span *ngIf="game.enableDetailPage && !game.forceDetailPage">
            <a class="icon theme-info" (click)="action(game)"></a>
        </span>
    </div>
</div>
