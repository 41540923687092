import { LoadingIndicatorHandler } from '@frontend/vanilla/core';

export class GameLaunch {
    game: any;
    isTouch: boolean;
    gamePosition: number;
    categoryType: string;
    from: string;
    replaceRouteInHistory: boolean = false;
    isDemoLaunch: boolean = false;
    skipUnfinGame: boolean = false;
    gameLaunchedFrom?: string;
    gridPosition?: number;
    specialProduct: string = '';
    mode?: string = '';
    gameSection: string = '';
    trackingproduct?: string = undefined;
    isNewSportsNative: boolean = false;
    lobbyType: string = '';
    gameContainer: string;
    isLive: boolean;
    returnUrl: string = '';
    highlightedColor: string = '';
    Globalsearchsection?: string = '';
    Globalsearchgameposition?: number;
    appendRUrlToLoginDialogWindow: boolean;
    skipMetaDataCheck?: boolean = false;
    fetchLiveGameIFrame?: boolean = false;
    noGameTraking: boolean;
    clickTimeStamp: any;
    launchSource: string;
    loadingIndicatorHandler: LoadingIndicatorHandler | null;
    recommendationType?: string;
    gameLaunchedLocation?: string;
    customLobbyTypeMap?: any = null;
    moreInfoOverlay: boolean = false;
    isMoreInfoOverlay: boolean = false;
    audioFrom?: boolean = false;
    screennameGameLaunchTracking: any;
    isFavorite: boolean = false;
    currentProduct: string;
    promoId?: number;
    slotUniqueId?: number;
}
