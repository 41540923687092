import { NgClass, NgIf, NgStyle, SlicePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { ConfigProviderService, CurrencyFormatPipe, CurrencySymbolPipe } from '@casinocore/platform/core';

import { GameLobbyComponent } from '../../gamelobby/gamelobby.component';

@Component({
    selector: 'recent-winners-news-feed',
    templateUrl: 'recent-winners-news-feed.component.html',
    standalone: true,
    imports: [NgStyle, NgIf, GameLobbyComponent, NgClass, SlicePipe, CurrencySymbolPipe, CurrencyFormatPipe],
})
export class RecentWinnersNewsFeedComponent implements OnInit, OnChanges {
    @Input() winner: any;
    @Input() newsFeed: any;
    @Input() index: any;
    @Input() isLastItem: boolean;
    @Input() isLastIteration: boolean;
    @Input() newItem: boolean;
    @Input() lobbyType: string;
    @Input() gamePosition: any;
    @Input() category: any;
    WinnersConfig: any;
    globalMessages: any;
    constructor(private configProviderService: ConfigProviderService) {}
    ngOnInit(): void {
        this.WinnersConfig = this.configProviderService.provideRecentWinnersWidgetConfig()?.recentWinnerWidgetConfiguration;
        this.globalMessages = this.configProviderService.provideRecentWinnersWidgetConfig()?.recentWinnersWidgetGlobalMessages;
        this.setDisplayFeedItem();
    }
    ngOnChanges() {
        this.setDisplayFeedItem();
    }
    isNewItem() {
        return this.newItem;
    }
    setNewItemFalse() {
        this.newItem = false;
    }

    setDisplayFeedItem() {
        const newsfeedStyle = document?.documentElement?.style.getPropertyValue('--isNewsFeedDisplayed');
        if (this.isLastIteration && this.isLastItem) {
            document?.documentElement?.style.setProperty('--isNewsFeedDisplayed', 'block');
        }
        if ((newsfeedStyle == 'none' || newsfeedStyle == '') && !this.isNewItem()) {
            document?.documentElement?.style.setProperty('--isNewsFeedDisplayed', 'block');
        }
        this.setNewItemFalse();
    }
}
