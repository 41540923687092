import { Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { CookieService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SmartBannerService {
    private smartBannerStatus = new BehaviorSubject<boolean>(true);
    private smartBannersExists = new BehaviorSubject<boolean>(false);
    smartBannerStatusObservable: Observable<boolean> = this.smartBannerStatus.asObservable();
    smartBannersExistsObservable: Observable<boolean> = this.smartBannersExists.asObservable();

    readonly hasSmartBanner = toSignal(this.smartBannersExists);
    readonly smartBannerRect = signal({ width: 0, height: 0 });

    constructor(private cookieService: CookieService) {}

    smartBannerStatusChange(isOpened: boolean) {
        this.smartBannerStatus.next(isOpened);
    }
    smartBannersExistsChanged(isListExists: boolean) {
        this.smartBannersExists.next(isListExists);
    }

    getSbClosedCookieValue(sbId: any) {
        const sbName = 'sb-closed-' + sbId;
        return this.cookieService.get(sbName);
    }

    getSbInstalledCookieValue(sbId: any) {
        const sbInstalledName = 'sb-installed-' + sbId;
        return this.cookieService.get(sbInstalledName);
    }
    installSmartBanner(sbId: any, daysReminder: any) {
        const sbInstalledName = 'sb-installed-' + sbId;
        this.cookieService.put(sbInstalledName, 'true', daysReminder);
    }

    closeSmartBanner(sbId: any, daysReminder: any) {
        const sbName = 'sb-closed-' + sbId;
        this.cookieService.put(sbName, 'true', daysReminder);
    }

    setCookie(name: any, value: any, exdays: any) {
        const exdate = new Date();
        const days = parseInt(exdays, 10);
        if (!isNaN(days)) {
            exdate.setDate(exdate.getDate() + days);
            this.cookieService.put(name, value, { expires: exdate.toUTCString() });
        } else {
            this.cookieService.put(name, value);
        }
    }
}
