import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { CasinoLobbyService, ConfigProviderService, LobbyItem } from '@casinocore/platform/core';
import { DslService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

import { NavigationBarComponent } from '../navigation-bar/navigation-bar.component';

@Component({
    selector: 'cc-nav-on-lobby-type',
    templateUrl: 'nav-on-lobby-type.component.html',
    standalone: true,
    imports: [NgIf, NavigationBarComponent],
})
export class NavOnLobbyTypeComponent implements OnInit, OnDestroy, OnChanges {
    @Input() lobbyType: string;
    @Input() categoryRoute: string;
    @Input() lobbyProduct: string;
    @Input() productOverride: string;
    @Input() stickyInputQueryElement: string;
    isExtendTopMenuCalledfromOnChanges: boolean;
    isLobbyLoaded: boolean;
    lobbyLoadSubscription: Subscription;
    extendMenuDslEvalCompleted: boolean;
    extendMenuDslSubscription: Subscription;
    constructor(
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
        private dslService: DslService,
    ) {}

    ngOnInit() {
        if (!this.isExtendTopMenuCalledfromOnChanges) {
            this.evaluateExtendTopMenu();
        }
        this.lobbyLoadSubscription = this.casinoLobbyService.lobbyLoadedObservable.subscribe((response) => {
            if (response) {
                this.isLobbyLoaded = true;
                if (this.lobbyType && (!this.categoryRoute || this.categoryRoute === '')) {
                    this.setCategoryRoute();
                }
            }
        });
    }

    ngOnChanges() {
        this.evaluateExtendTopMenu();
        this.isExtendTopMenuCalledfromOnChanges = true;
        if (this.isLobbyLoaded) {
            if (this.lobbyType && (!this.categoryRoute || this.categoryRoute === '')) {
                this.setCategoryRoute();
            }
        }
    }

    setCategoryRoute() {
        const lobbyInfo: LobbyItem = this.casinoLobbyService.getLobbyInfo(this.lobbyType) || new LobbyItem();
        if (lobbyInfo && lobbyInfo.categoriesMap && lobbyInfo.categoriesMap.size > 0) {
            this.categoryRoute = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(this.lobbyType).categoryInfo.categoryid; // lobbyInfo.categoriesMap.entries().next().value[1].categoryid;
        } else {
            //emit CategoryNotFound
        }
    }
    evaluateExtendTopMenu() {
        const mcPage: any = this.configProviderService.provideMcPageConfig();
        const features: any = this.configProviderService.provideFeaturesConfig();
        let index: number = 0;
        if (features.extendedTopMenuStatus && mcPage.extendTopMenu && mcPage.extendTopMenu.length > 0) {
            mcPage.extendTopMenu.forEach((item: any) => {
                if (item.title) {
                    this.extendMenuDslSubscription = this.dslService.evaluateExpression(item.condition).subscribe((r) => {
                        item.dslResult = r;
                        index++;
                        if (index == mcPage.extendTopMenu.length) {
                            this.extendMenuDslEvalCompleted = true;
                            index = 0;
                        }
                    });
                }
            });
        } else {
            this.extendMenuDslEvalCompleted = true;
        }
    }
    ngOnDestroy() {
        if (this.lobbyLoadSubscription) {
            this.lobbyLoadSubscription.unsubscribe();
        }
        if (this.extendMenuDslSubscription) {
            this.extendMenuDslSubscription.unsubscribe();
        }
    }
}
