import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csGameConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: gameConfigClientConfigFactory,
})
export class GameConfigClientConfig {
    host: string;
    gameIconTemplate: string;
    iconTypeMapping: string[];
    maxGamesPerCategory: number;
    showLoadigIndicatorOnGamesLoading: boolean;
    nativeGameLaunchEnabled: boolean;
    vendorsWithOnlyRealModeLobbyType: any;
    enableDemoPlayLobbyType: any;
    showRGLinkOnDetailPageLobbyType: any;
    responsibleGamingLink: any;
    gameMode: string;
    iconConfigMap: any;
    enablePlaytechLiveGameDetailsPage: boolean;
    enableRCPFavWidgetOnPublicPages: boolean;
    enableDemoMode: boolean;
    mgsGameLaunchUrl: any;
    desktopMGSGameLaunchUrl: any;
    desktopMGSLiveGameLaunchURL: any;
    win: any;
    desktopChannelIds: Array<string>;
    demoModeExcludedGamesRegex: string;
    enableQuickInfoReels: boolean;
    removeAllQueryParamsForVendor: Array<string>;
    enableSpinnerOnGameLaunch: boolean;
    gameCloseDelay: number;
    isGameCloseApiEnabled: boolean;
    gameCloseApiPath: string;
    gameCloseApiTimeOut: number;
    isGameCloseAckEventEnabled: boolean;
    gameOwnerIdMap: any;
    enableRefreshonGameClose: boolean;
    iframeAllowattributes: string;
    storeSsotokenInCookies: boolean;
    enableGamestartCookie: boolean;
    gameNotAvailableTimeOut: any;
    enableArcPlayBreakEvent: boolean;
    passNativeAppCookieToGameLaunch: boolean;
    egvOverlayFlag: boolean;
    singleHostLobbyMap: any;
    arcadeLobbyType: string;
    singleGameLaunchUrl: string;
    storeUserDetails: boolean;
    enableNativeOdrEvent: any;
    backToPortalLobby: string;
    enableSlotRaceParams: boolean;
}

export function gameConfigClientConfigFactory(service: LazyClientConfigService) {
    return service.get(GameConfigClientConfig);
}
