<div class="casino-rcp-widget casino-loggedouthelpCusBtn" *ngIf="showHelpContactForPrelogin">
    <div [ngStyle]="{ 'background-color': rcpWidgetBackground }">
        <div
            [ngClass]="{ 'rcp-btns': rcpToggle }"
            class="rcp-btn"
            id="loggedoutWidgetToggle"
            #loggedoutWidgetToggle
            [ngStyle]="{ background: rcpToggle == true ? rcpWidgetBackground : 'none' }">
            <div class="casino-help-contact-buttons">
                <button
                    class="rcp-contact-button btn btn-primary"
                    (click)="getContactusUrl()"
                    [ngClass]="{ 'epcot-btn': enableEpcotSubNavigation }"
                    [ngStyle]="{ 'background-color': widgetContactUsBG, 'color': widgetContactUsClr }">
                    <div class="icon-widget">
                        <img src="{{ rcpContactUsicon }}" />
                    </div>
                    <span>{{ WidgetContactusButton }}</span>
                </button>
                <button
                    class="rcp-help-button btn"
                    (click)="getHelpUrl()"
                    [ngClass]="{ 'epcot-btn': enableEpcotSubNavigation }"
                    [ngStyle]="{ 'background-color': widgetHelpBG, 'color': widgetHelpClr }">
                    <div class="icon-widget">
                        <img src="{{ rcpHelpicon }}" />
                    </div>
                    <span>{{ WidgetHelpButton }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
