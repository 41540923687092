import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { CasinoLobbyService } from '@casinocore/platform/core';
import { Subscription } from 'rxjs';

import { ArcadeNavigationComponent } from '../../../../platform-lib/src/lib/arcade-navigation/arcade-navigation.component';

@Component({
    selector: 'cc-arcade-navigation-section',
    templateUrl: 'arcade-navigation-section.component.html',
    standalone: true,
    imports: [NgIf, ArcadeNavigationComponent],
})
export class ArcadeNavigationSectionComponent implements OnInit, OnDestroy {
    @Input() lobbyType: string;
    isLobbyLoaded: boolean;
    lobbyLoadSubscription: Subscription;
    constructor(private casinoLobbyService: CasinoLobbyService) {}

    ngOnInit() {
        this.lobbyLoadSubscription = this.casinoLobbyService.lobbyLoadedObservable.subscribe((response) => {
            if (response) {
                this.isLobbyLoaded = true;
            }
        });
    }

    ngOnDestroy() {
        if (this.lobbyLoadSubscription) {
            this.lobbyLoadSubscription.unsubscribe();
        }
    }
}
