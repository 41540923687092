<div *ngIf="(IsRpFavWidgetEnabled || isAbRecentlyPlayedWidget) && !isAbRecentlyPlayedContainer" class="casino-rcp-widget" id="widgettoggle">
    <div id="rpfavWidget" [ngStyle]="{ background: favToggle || rcpToggle ? rcpWidgetBackground : rcpWidgetBackgroundTrans }">
        <div
            [ngClass]="{ 'rcp-btns': rcpToggle || favToggle, 'sticky': IsFloatingEnabledForWidget }"
            class="rcp-btn"
            #widgettoggle
            [ngStyle]="{ 'background-color': favToggle || rcpToggle ? rcpWidgetBackground : rcpWidgetBackgroundTrans }">
            <div class="casino-rcp-buttons">
                <!-- <button
                    *ngIf="isAuthenticated"
                    class="rcp-widget-btn btn"
                    (click)="widgetToggleHandler('rcp')"
                    [ngClass]="{ activebtn: rcpToggle, inactive: !rcpToggle }"
                    [ngStyle]="{
                        'background-color': rcpToggle ? rpWidgetActiveBtBackground : rpWidgetInactiveBtBackground,
                        'color': rcpToggle ? rpWidgetActiveTxtcolor : rpWidgetInactiveTxtcolor
                    }">
                    <div [ngClass]="rcpWidgeticon" class="icon-widget"></div>
                    <span>{{ widgetRecentlyPlayed }}</span>
                    <i class="theme-arrow-down" [ngClass]="{ 'theme-arrow-up': !rcpToggle }"></i>
                </button>
                <button
                    *ngIf="isAuthenticated && displayFavInRPFavWidget"
                    class="rcp-fav-btn btn"
                    [ngClass]="{ activebtn: favToggle, inactive: !favToggle }"
                    (click)="widgetToggleHandler('fav')"
                    [ngStyle]="{
                        'background-color': favToggle ? rpWidgetActiveBtBackground : rpWidgetInactiveBtBackground,
                        'color': favToggle ? rpWidgetActiveTxtcolor : rpWidgetInactiveTxtcolor
                    }">
                    <div [ngClass]="rcpFavWidgeticon" class="icon-widget"></div>
                    <span>{{ widgetFavourites }}</span>
                    <i class="theme-arrow-down" [ngClass]="{ 'theme-arrow-up': !favToggle }"></i>
                </button> -->

                @if (isAuthenticated) {
                    <cs-rp-fav-cta
                        (click)="widgetToggleHandler('rcp')"
                        [isLegacy]="isLegacy"
                        [setActive]="rcpToggle"
                        [color]="rcpToggle ? rpWidgetActiveTxtcolor : rpWidgetInactiveTxtcolor"
                        [backgroundColor]="rcpToggle ? rpWidgetActiveBtBackground : rpWidgetInactiveBtBackground"
                        [startIconClass]="rcpWidgeticon"
                        [chevronKind]="rcp_chevronKind"
                        [csDecoratorClass]="'icon-widget'"
                        [cta_text]="widgetRecentlyPlayed">
                    </cs-rp-fav-cta>
                }

                @if (isAuthenticated && displayFavInRPFavWidget) {
                    <cs-rp-fav-cta
                        (click)="widgetToggleHandler('fav')"
                        [isLegacy]="isLegacy"
                        [isFav]="true"
                        [setActive]="favToggle"
                        [color]="favToggle ? rpWidgetActiveTxtcolor : rpWidgetInactiveTxtcolor"
                        [backgroundColor]="favToggle ? rpWidgetActiveBtBackground : rpWidgetInactiveBtBackground"
                        [startIconClass]="rcpFavWidgeticon"
                        [csDecoratorClass]="'icon-widget'"
                        [chevronKind]="fav_chevronKind"
                        [cta_text]="widgetFavourites">
                    </cs-rp-fav-cta>
                }
            </div>
            <div class="casino-help-contact-buttons">
                <div *ngIf="showRcpHelpContactUs">
                    <button
                        class="rcp-contact-button btn"
                        (click)="getContactusUrl()"
                        [ngStyle]="{ 'background-color': widgetContactUsBG, 'color': widgetContactUsClr }">
                        <div class="icon-widget">
                            <img src="{{ rcpContactUsicon }}" />
                        </div>
                        <span>{{ WidgetContactusButton }}</span>
                    </button>
                    <button
                        class="rcp-help-button btn"
                        (click)="getHelpUrl()"
                        [ngStyle]="{ 'background-color': widgetHelpBG, 'color': widgetHelpClr }">
                        <div class="icon-widget">
                            <img src="{{ rcpHelpicon }}" />
                        </div>
                        <span>{{ WidgetHelpButton }}</span>
                    </button>
                </div>
                <span
                    class="theme-close"
                    (click)="close()"
                    [ngStyle]="{
                        display: favToggle == true || rcpToggle == true ? 'inline-block' : 'none',
                        color: rpWidgetActiveBtBackground
                    }"></span>
            </div>
        </div>
        <div
            id="rpfavWidget_rcp_gyml"
            class="casino-rcp-container"
            [ngStyle]="{ 'display': !rcpToggle ? 'none' : 'block', 'background-color': rcpWidgetBackground }">
            <cc-rcp-gyml-widget [isTouch]="isTouch" [lobbyType]="lobbyType" [gameContainer]="gameContainer"> </cc-rcp-gyml-widget>
        </div>
        <div
            id="rpfavWidget_fav_gyml"
            class="casino-fav-container"
            [ngStyle]="{ 'display': !favToggle ? 'none' : 'block', 'background-color': rcpWidgetBackground }">
            <cc-fav-gyml-widget
                #favGYMLWidget
                [isTouch]="isTouch"
                [lobbyType]="lobbyType"
                [isGymlInFavourite]="IsGymlInFavourites"
                [gameContainer]="gameContainer"></cc-fav-gyml-widget>
        </div>
    </div>
</div>
