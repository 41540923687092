import { Injectable } from '@angular/core';

import { Page, UserService } from '@frontend/vanilla/core';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { CasinoCoreNpmParamsService } from './casino-core-npm-params.service';
import { CasinoWidgetService } from './casino-widget.service';
import { LocalStorageExpirablePersistenceService } from './localstorage.expirable.persistence.service';
import { GameDataFromCacheResponseModel } from './models/gameDataFromCacheResponse.model';

@Injectable({
    providedIn: 'root',
})
export class CacheManagerService {
    constructor(
        private localStorageExpirablePersistenceService: LocalStorageExpirablePersistenceService,
        private page: Page,
        private casinoWidgetService: CasinoWidgetService,
        private configProviderService: ConfigProviderService,
        private user: UserService,
        private casinoCoreNpmParamsService: CasinoCoreNpmParamsService,
    ) {}

    getCategoryCacheKey(lobbyType: string = '', invokerProduct: string = '') {
        let cachekey;
        let casinoConfig: any = this.configProviderService.provideCasinoConfig();
        if (this.user.isAuthenticated) {
            cachekey = 'categoryCoreLookUp_' + this.page.lang + '_' + lobbyType + '_' + this.user.username;
        } else {
            cachekey = 'categoryCoreLookUp_' + this.page.lang + '_' + lobbyType;
        }
        if (invokerProduct && invokerProduct != '') {
            cachekey += '_' + invokerProduct;
        } else if (this.casinoWidgetService.product) {
            cachekey += '_' + this.casinoWidgetService.product;
        } else {
            cachekey += '_' + casinoConfig.casinoContextValues.invokerProduct;
        }
        return cachekey;
    }
    readCategoryDataLocalCache(expirationIntervalInSeconds: number, lobbyType: string = '', invokerProduct: string = ''): Map<string, any> {
        let featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        let devSettingsConfig: any = this.configProviderService.provideDevSettingsConfig();
        let isPreviewUser = lobbyType === 'globalBonusJackpots' ? true : devSettingsConfig.isPreviewUser;
        let cachedCategoryData =
            featuresConfig.enableClientStorage && !isPreviewUser
                ? this.localStorageExpirablePersistenceService.read(
                      this.getCategoryCacheKey(lobbyType, invokerProduct),
                      expirationIntervalInSeconds * 1000,
                  )
                : null;
        return cachedCategoryData;
    }

    writeCategoryLocalStorageCacheMap(lobbyType: string, fullLobbyInfo: any, invokerProduct: string = '') {
        try {
            if (!this.casinoCoreNpmParamsService.getcasinoCoreNpmParams().disableCasinoLocalStorage) {
                this.localStorageExpirablePersistenceService.write(this.getCategoryCacheKey(lobbyType, invokerProduct), fullLobbyInfo);
            }
        } catch (error) {}
    }

    writeForceGameLaunchObjInSessionStorage(sessionStorageObjName: string, gameObj: any) {
        try {
            sessionStorage.setItem(sessionStorageObjName, JSON.stringify(gameObj));
        } catch (error) {}
    }
    getAuthenticatedThumbnailCacheKey(lobbyType: string = '') {
        if (this.user.username) {
            return 'thumbnailLookUp_' + this.user.username + '_' + lobbyType;
        }
        return '';
    }
    getAnonymousThumbnailCacheKey(lobbyType: string = '') {
        return 'thumbnailLookUp_' + lobbyType;
    }
    getThumbnailCacheKey(lobbyType: string = '') {
        let cachekey: any;
        if (this.user.isAuthenticated) {
            cachekey = this.getAuthenticatedThumbnailCacheKey(lobbyType);
        } else {
            cachekey = this.getAnonymousThumbnailCacheKey(lobbyType);
        }
        return cachekey;
    }

    setThumbnailLocalStorageCacheMap(lobbyType: string = '', thumbnailGames: any, forceLaunch: boolean = false) {
        try {
            if (!forceLaunch) {
                thumbnailGames && localStorage.setItem(this.getThumbnailCacheKey(lobbyType), thumbnailGames);
            } else {
                thumbnailGames && localStorage.setItem(this.getAuthenticatedThumbnailCacheKey(lobbyType), thumbnailGames);
                thumbnailGames && localStorage.setItem(this.getAnonymousThumbnailCacheKey(lobbyType), thumbnailGames);
            }
        } catch (error) {}
    }
    getThumbnailsfromLocalStorage(lobbyType: string = '') {
        try {
            return localStorage.getItem(this.getThumbnailCacheKey(lobbyType));
        } catch (error) {
            return null;
        }
    }

    removeThumbnailfromLocalStorage(lobbyType: string = '') {
        try {
            localStorage.removeItem(this.getThumbnailCacheKey(lobbyType));
        } catch (error) {}
    }

    readForceGameLaunchObjDataFromSessionStorage(objName: string) {
        if (sessionStorage.getItem(objName)) {
            let ForceGameLaunchObjData = sessionStorage.getItem(objName) || '';
            return JSON.parse(ForceGameLaunchObjData);
        }
    }

    readFastLoadingGamesLocalStorageCacheMap(expirtationIntervalInSeconds: number): Map<string, any> {
        let features = this.configProviderService.provideFeaturesConfig();
        let fastLoadingGames = features.enableClientStorage
            ? this.localStorageExpirablePersistenceService.read(this.getFastLoadingGamesCacheKey(), expirtationIntervalInSeconds * 1000)
            : null;
        return fastLoadingGames && new Map(fastLoadingGames);
    }
    getFastLoadingGamesCacheKey() {
        return 'fastLoadingGamesLookUp';
    }
    getFastLoadingGamesFromLocalCache(): Map<string, any> {
        let features = this.configProviderService.provideFeaturesConfig();
        return this.readFastLoadingGamesLocalStorageCacheMap(features.browserLocalStorageCategoryDataUpdateInterval);
    }
    setFastLoadingGamesLocalStorageCacheMap(fastLoadingGamesLookUp: any) {
        try {
            if (!this.casinoCoreNpmParamsService.getcasinoCoreNpmParams().disableCasinoLocalStorage) {
                this.localStorageExpirablePersistenceService.write(this.getFastLoadingGamesCacheKey(), Array.from(fastLoadingGamesLookUp.entries()));
            }
        } catch (error) {}
    }
    getAllCategoriesFromLocalCache(lobbyType: string = '', invokerProduct: string = ''): Map<string, any> {
        let features = this.configProviderService.provideFeaturesConfig();
        return this.readCategoryDataLocalCache(features.browserLocalStorageCategoryDataUpdateInterval, lobbyType, invokerProduct);
    }
    setCategoryLocalStorageCacheMap(allCategoriesResponse: any, lobbyType: string = '', invokerProduct: string = '') {
        try {
            this.localStorageExpirablePersistenceService.write(
                this.getCategoryCacheKey(lobbyType, invokerProduct),
                Array.from(allCategoriesResponse.entries()),
            );
        } catch (error) {}
    }
    setGameToStickersMap(data: any): any {
        data && localStorage.setItem('stickersMap', JSON.stringify(data));
    }
    getGamesDataFromCache(gamesList: string[]): GameDataFromCacheResponseModel {
        let features = this.configProviderService.provideFeaturesConfig();
        let devSettings = this.configProviderService.provideDevSettingsConfig();
        try {
            if (features.enableClientStorage && !devSettings.isPreviewUser) {
                let gameDataSet: Map<string, any> = new Map<string, any>(),
                    gamesNotInCache: string[] = [];
                gamesList.forEach((game) => {
                    let singleGameData: any = localStorage.getItem(game) && JSON.stringify(localStorage.getItem(game));
                    if (
                        singleGameData &&
                        singleGameData.localStorageTimeStamp + features.browserLocalStorageCacheDuration * 1000 > new Date().getTime()
                    ) {
                        gameDataSet.set(game, singleGameData);
                    } else {
                        if (singleGameData) {
                            localStorage.removeItem(game);
                        }
                        gamesNotInCache.push(game);
                    }
                });
                let data: GameDataFromCacheResponseModel = {
                    gameMetaData: gameDataSet,
                    gamesNotInCache: gamesNotInCache.length > 0 ? gamesNotInCache : [],
                };
                return data;
            } else return new GameDataFromCacheResponseModel();
        } catch (error) {
            return new GameDataFromCacheResponseModel();
        }
    }
    setGameDataCache(data: Map<string, any>) {
        try {
            Array.from(data.entries()).forEach((entry: any) => {
                if (!localStorage.getItem(entry[0])) {
                    entry[1].localStorageTimeStamp = new Date().getTime();
                    localStorage.setItem(entry[0], JSON.stringify(entry[1]));
                }
            });
        } catch (error) {}
    }
    getGameToStickersMap(): any {
        let data = localStorage.getItem('stickersMap');
        return data ? JSON.parse(data) : false;
    }
}
