import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';

import { PlayerStatFiltersComponent } from './player-stat-filters.component';

@NgModule({
    imports: [PlayerStatFiltersComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [PlayerStatFiltersComponent],
})
export class PlayerStatsModule {
    public static forRoot(): ModuleWithProviders<PlayerStatsModule> {
        return {
            ngModule: PlayerStatsModule,
            providers: [],
        };
    }
}
