import { LowerCasePipe, NgClass, NgIf } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, computed } from '@angular/core';

import {
    CasinoCoreGamelaunchService,
    CasinoIFrameOverlayService,
    CasinoLobbyService,
    CasinoManager,
    CasinoWidgetService,
    ConfigProviderService,
    DesignSystemEnablerService,
    GameLaunch,
    GameLaunchPlaces,
    GameLaunchTrackingService,
    NativeAppHelperService,
    OptimizedTeaserManager,
    VCClientService,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, GameTileCtaComponent } from '@frontend/casino-ui';
import { DeviceService, NativeAppService, NativeEvent, NavigationService, TrackingService, UserService } from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { cloneDeep } from 'lodash-es';

import { OptTeaserActorImageComponent } from './opt-teaser-actor-image.component';
import { OptTeaserBgImageComponent } from './opt-teaser-bg-image.component';
import { OptTeaserTextPositionComponent } from './opt-teaser-textPosition.component';
import { OptTeaserVideoComponent } from './opt-teaser-video.component';

@Component({
    selector: 'cc-opt-teaser-item',
    templateUrl: 'opt-teaser-item.component.html',
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        OptTeaserBgImageComponent,
        OptTeaserVideoComponent,
        OptTeaserActorImageComponent,
        OptTeaserTextPositionComponent,
        LowerCasePipe,
        TrustAsHtmlPipe,
        GameTileCtaComponent,
        DecoratorClassDirective,
    ],
})
export class OptTeaserItemComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() teaser: any;
    @Input() activeSlides: any;
    @Input() teaserIndex: number;
    @Input() teaserHeight: number;
    @Input() teaserCategory: string;
    @Input() gameContainer: string;
    @Input() isHover: boolean;
    @Input() lobbyType: string;
    @Input() identifierClass: string;
    @Input() isOptMiniTeasersEnabled: boolean;
    @Input() isOptTeaserPhase3: boolean;
    @Output() onVideoEndEvent: EventEmitter<any> = new EventEmitter();
    @Output() onVideoLoadedEvent: EventEmitter<any> = new EventEmitter();
    @Output() onTimeChangeEvent: EventEmitter<any> = new EventEmitter();
    @Output() videoErrorEvent: EventEmitter<any> = new EventEmitter();
    horizontalPosition: string = '';
    imagePosition: string = '';
    verticalPosition: string = '';
    horizontalAlignment: string = '';
    termsTextPositionClass: string = '';
    openBingoLobbyButtonPosition: string = '';
    isNewSportsNative: any;
    isTouch: boolean;
    customId: any;
    customClass: string = '';
    teaserLaunchRealtiveUri: string;
    readonly isMobileWidth = this.deviceService.isMobileWidth;
    teaserDefaultContentBg: string = '';
    showTimer: boolean;
    smallTermsClass: string = '';
    teaserBackgroundImageAlignment: string = '';
    teaserBackgroundPosition: any;
    teaserVideoAlignment: string = '';
    teaserVideoPosition: number;
    videoUrl: string;
    videoType: string;
    enableEpcotSubNavigation: boolean;
    isLegacy: boolean = false;

    readonly deviceType = computed(() => {
        const width = this.deviceService.windowWidth();
        if (width <= 480) {
            return 'Mobile';
        }
        if (width < 1023) {
            return 'Tablet';
        }
        return 'Desktop';
    });

    enableAluminiumTeasers: boolean;
    constructor(
        private configProviderService: ConfigProviderService,
        private casinoLobbyService: CasinoLobbyService,
        private navigation: NavigationService,
        private tracking: TrackingService,
        private casinoManager: CasinoManager,
        private NativeApplication: NativeAppService,
        private vcClientService: VCClientService,
        private user: UserService,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private gameLaunchPlaces: GameLaunchPlaces,
        private nativeAppHelper: NativeAppHelperService,
        private optimizedTeaserManager: OptimizedTeaserManager,
        private casinoWidgetService: CasinoWidgetService,
        private casinoIFrameOverlayService: CasinoIFrameOverlayService,
        private gameLaunchTrackingService: GameLaunchTrackingService,
        private deviceService: DeviceService,
        private designSystemEnablerService: DesignSystemEnablerService,
    ) {
        this.teaserLaunchRealtiveUri = '/' + this.configProviderService.provideMcPageConfig().labelPrefix + '/launchng/';
    }

    ngOnInit() {
        let subnavConfig: any = this.configProviderService.provideSubNavigationConfig();
        this.videoType = this.teaser?.videoUrl?.videoType;
        const videoFiltered: string[] = [this.teaser.videoUrl?.videoUrlVersioned, this.teaser.videoUrl?.videoUrlShared].filter((x) => x);
        const cmsFiltered: string[] = [this.teaser.videoUrl?.cmsVideoUrlVersioned?.id, this.teaser.videoUrl?.cmsVideoUrlShared?.id].filter((x) => x);
        if (cmsFiltered.length > 0) {
            this.videoUrl = cmsFiltered[0];
            this.videoType = 'cms';
        } else if (videoFiltered.length > 0) {
            this.videoUrl = videoFiltered[0];
        }
        this.enableEpcotSubNavigation = subnavConfig.enableEpcotSubNavigation;
        this.isLegacy = !this.designSystemEnablerService.isEnabled();
    }

    ngAfterViewInit() {
        let css = this.teaser?.customCss;
        let head = document.getElementById('OptTeaserCarousel_' + this.identifierClass);
        let style1 = document.createElement('style');
        let cId = 'customstyle_' + this.customId;
        style1.id = cId;
        if (head != null) {
            document.getElementById(cId)?.remove();
            head.appendChild(style1);
            style1.type = 'text/css';
            style1.appendChild(document.createTextNode('#' + this.customId + ' ' + css));
        }
    }

    ngOnChanges() {
        const optimizedTeaserClientConfig: any = this.configProviderService.provideOptimizedTeaserConfig();
        const globalTeaserSettings: any = this.optimizedTeaserManager.getGlobalTeaserSettings();
        this.isTouch = this.casinoManager.isTouch();
        this.isNewSportsNative =
            this.NativeApplication.isNativeApp && (this.NativeApplication.product === 'SPORTSBOOK' || this.NativeApplication.product === 'BETTING');
        const teaserDefaultTextHorizontalPosition: any =
            globalTeaserSettings.sharedList.teaserDefaultTextHorizontalPosition || optimizedTeaserClientConfig.teaserDefaultHorizontalAlignment;
        const teaserDefaultTextVerticalPosition: any =
            globalTeaserSettings.sharedList.teaserDefaultTextVerticalPosition || optimizedTeaserClientConfig.teaserDefaultVerticalAlignment;
        const teaserDefaultImagePosition: any =
            globalTeaserSettings.sharedList.teaserDefaultImagePosition || optimizedTeaserClientConfig.teaserDefaultImageAlignment;
        const teaserDefaultTermsTextAlignment: any =
            globalTeaserSettings.sharedList.teaserDefaultTermsTextPosition || optimizedTeaserClientConfig.teaserDefaultTermsTextAlignment;
        const teaserDefaultHorizontalTextAlignment: any =
            globalTeaserSettings.sharedList.teaserDefaultHorizontalTextAlignment || optimizedTeaserClientConfig.teaserDefaultHorizontalTextAlignment;
        this.horizontalPosition =
            this.teaser.textHorizontalPosition != 'Default' ? this.teaser.textHorizontalPosition : teaserDefaultTextHorizontalPosition;
        this.verticalPosition = this.teaser.textVerticalPosition != 'Default' ? this.teaser.textVerticalPosition : teaserDefaultTextVerticalPosition;
        this.imagePosition = this.teaser.imagePosition != 'Default' ? this.teaser.imagePosition : teaserDefaultImagePosition;
        this.openBingoLobbyButtonPosition = 'casino-playbingo-btn' + globalTeaserSettings.sharedList.openBingoLobbyButtonPosition;
        this.termsTextPositionClass = ('text-align-' + teaserDefaultTermsTextAlignment).toLowerCase();

        this.horizontalAlignment = teaserDefaultHorizontalTextAlignment;
        if (globalTeaserSettings && globalTeaserSettings.sharedList) {
            this.teaserDefaultContentBg = globalTeaserSettings.sharedList.teaserDefaultContentBg || '';
            this.showTimer = globalTeaserSettings?.sharedList?.showTimer?.toLowerCase() === 'true';
        }
        this.smallTermsClass = this.showTimer ? 'small-terms-timer' : 'small-terms';
        this.customClass = this.teaser?.htmlClasses || this.teaser?.data.metadata.id.itemName;
        this.customId = this.teaser?.htmlId || this.teaser?.data.metadata.id.itemName;

        if (this.teaser.optimizedTeaserAttributes) {
            this.teaserBackgroundImageAlignment =
                this.teaser.optimizedTeaserAttributes.teaserBackgroundImageAlignment ||
                globalTeaserSettings.sharedList.teaserBackgroundImageAlignment;
            // this.teaserBackgroundPosition = this.teaser.optimizedTeaserAttributes.teaserBackgroundPosition ||
            //     globalTeaserSettings.sharedList.teaserBackgroundPosition;
            this.teaserBackgroundPosition =
                this.deviceType() === 'Mobile'
                    ? this.teaser.optimizedTeaserAttributes.teaserBackgroundPosition ||
                      globalTeaserSettings.sharedList.teaserBackgroundPositionforMobile
                    : this.deviceType() === 'Tablet'
                      ? this.teaser.optimizedTeaserAttributes.teaserBackgroundPosition ||
                        globalTeaserSettings.sharedList.teaserBackgroundPositionforTablet
                      : this.teaser.optimizedTeaserAttributes.teaserBackgroundPosition ||
                        globalTeaserSettings.sharedList.teaserBackgroundPositionforDesktop;

            this.teaserVideoAlignment =
                this.teaser.optimizedTeaserAttributes.teaserVideoAlignment || globalTeaserSettings.sharedList.teaserVideoAlignment;
            this.teaserVideoPosition =
                this.teaser.optimizedTeaserAttributes.teaserVideoPosition || globalTeaserSettings.sharedList.teaserVideoPosition;
            this.enableAluminiumTeasers = optimizedTeaserClientConfig.enableAluminiumTeaser;
        }
    }

    teaserClick(event: any, teaser: any, bannerPos: any) {
        event.stopPropagation();
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Teaser_Banners',
            'component.LabelEvent': teaser.data.metadata.id.itemName,
            'component.PositionEvent': 'Teaser_' + bannerPos,
            'component.LocationEvent': 'main_Teaser',
            'component.ActionEvent': 'Teaser - Click',
            'component.EventDetails': 'User taps on teasers',
            'component.URLClicked': teaser?.teaserLink?.url,
        });
        if (this.enableAluminiumTeasers) {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'Teaser_Banners',
                'component.LabelEvent': teaser.data.metadata.id.itemName,
                'component.PositionEvent': 'Teaser_' + bannerPos,
                'component.LocationEvent': 'main_Teaser_Aluminium',
                'component.ActionEvent': 'Teaser - Click',
                'component.EventDetails': 'User taps on teasers',
                'component.URLClicked': teaser?.teaserLink?.url,
            });
        }
        if (this.isNewSportsNative || this.vcClientService.isPokerVC()) {
            let link: any = teaser?.teaserNativeLink?.url;
            if (link) {
                this.track('tp', link, true);
                this.trackGameLaunch(link, true, bannerPos);
                let options = { skipLoadingIndicator: false };
                if (link.substr(link.length - 4, 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                let infiIndex = link.indexOf('?event=promo');
                if (infiIndex === -1) {
                    if (this.vcClientService.isPokerVC() && this.isGameUrl(link)) {
                        this.launchVCGame(link, bannerPos);
                        return;
                    }
                    this.handleNavigation(link, teaser?.teaserNativeLink?.attributes, !options.skipLoadingIndicator);
                } else {
                    let event: NativeEvent = {
                        eventName: 'Native_Optimized_Teaser_Click',
                        parameters: {
                            teaserCtaNativeLinkUrl: link.substr(infiIndex),
                        },
                    };
                    this.NativeApplication.sendToNative(event);
                }
            }
        } else if (teaser?.teaserLink?.url) {
            this.handleTeaserClick(teaser.teaserLink, bannerPos);
        }
    }

    ctaClick(event: any, teaser: any, bannerPos: any, isPrimary: boolean) {
        event.stopPropagation();
        let cta: any = isPrimary ? teaser?.cta : teaser?.secondaryCTA;
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Teaser_Banners',
            'component.LabelEvent': teaser.data.metadata.id.itemName,
            'component.PositionEvent': 'Teaser_' + bannerPos,
            'component.LocationEvent': 'main_Teaser',
            'component.ActionEvent': 'CTA Click',
            'component.EventDetails': 'CTA Name|' + (isPrimary ? teaser?.cta?.text : teaser?.secondaryCTA?.text),
            'component.URLClicked': cta?.url,
        });
        if (this.enableAluminiumTeasers) {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'Teaser_Banners',
                'component.LabelEvent': teaser.data.metadata.id.itemName,
                'component.PositionEvent': 'Teaser_' + bannerPos,
                'component.LocationEvent': 'main_Teaser_Aluminium',
                'component.ActionEvent': 'CTA Click',
                'component.EventDetails': 'CTA Name|' + (isPrimary ? teaser?.cta?.text : teaser?.secondaryCTA?.text),
                'component.URLClicked': cta?.url,
            });
        }
        if (this.isNewSportsNative || this.vcClientService.isPokerVC()) {
            let link: any = cta?.url;
            if (link) {
                this.track('tp', link, true);
                this.trackGameLaunch(link, true, bannerPos);
                let options = { skipLoadingIndicator: false };
                if (link?.substr(link.length - 4, 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                let infiIndex = link.indexOf('?event=promo');
                if (infiIndex === -1) {
                    if (this.vcClientService.isPokerVC() && this.isGameUrl(link)) {
                        this.launchVCGame(link, bannerPos);
                        return;
                    }
                    this.handleNavigation(link, cta.attributes, !options.skipLoadingIndicator);
                } else {
                    let event: NativeEvent = {
                        eventName: 'Native_Optimized_Teaser_Cta_Click',
                        parameters: {
                            teaserCtaNativeLinkUrl: link.substr(infiIndex),
                        },
                    };
                    this.NativeApplication.sendToNative(event);
                }
            }
        } else if (cta) {
            this.handleTeaserClick(cta, bannerPos);
        }
    }

    public termsClick(event: any, teaser: any) {
        event.stopPropagation();
        if (this.isNewSportsNative) {
            const link: any = teaser?.termsNativeLink?.url;
            if (link) {
                this.track('tp', link, true);
                const options = { skipLoadingIndicator: false };
                if (link?.substr(link.length - 4, 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                const infiIndex = link.indexOf('?event=promo');
                if (infiIndex === -1) {
                    this.handleNavigation(link, teaser.termsNativeLink.attributes, !options.skipLoadingIndicator);
                } else {
                    const event: NativeEvent = {
                        eventName: 'Native_Optimized_Teaser_Terms_Click',
                        parameters: {
                            teaserTermsNativeLinkUrl: link.substr(infiIndex),
                        },
                    };
                    this.NativeApplication.sendToNative(event);
                }
            }
        } else if (teaser?.termsNativeLink) {
            this.handleTeaserClick(teaser.termsNativeLink, null);
        }
    }

    public track(where: any, what: any, isUrl: any) {
        this.tracking.updateDataLayer({
            'page.name': 'mcTeasers',
            'event': 'pageView',
            'page.url': '?source=' + where + '&' + (isUrl ? 'href=' : 'gameId=') + what,
        });
    }

    public trackGameLaunch(gameLaunchInfo: any, isUrl: boolean, bannerPos: any) {
        let gameType = '';
        let gameTrackingObj: any = {};
        if (isUrl) {
            const launchUrlIndex: number = gameLaunchInfo.indexOf(this.teaserLaunchRealtiveUri);
            if (launchUrlIndex > 0) {
                const gameId: any = gameLaunchInfo.substr(launchUrlIndex + this.teaserLaunchRealtiveUri.length);
                const gameData: any = this.casinoLobbyService.getGameInfo(gameId);
                if (!gameData.isDataNotAvailable) {
                    gameType = gameData == null ? '' : gameData.launchType;
                    const gameDisplayName = gameData == null ? '' : gameData.title;
                    gameTrackingObj = {
                        name: gameId,
                        type: gameType,
                        position: bannerPos,
                        section: 'Teaser',
                        displayName: gameDisplayName,
                    };
                }
            }
        } else {
            gameTrackingObj = {
                name: gameLaunchInfo,
                type: gameType,
                position: 1,
                section: '',
            };
        }
        if (Object.keys(gameTrackingObj).length > 0) {
            this.gameLaunchTrackingService.track(gameTrackingObj);
        }
    }

    isGameUrl(link: string) {
        return link.indexOf(this.teaserLaunchRealtiveUri) > 0;
    }

    launchVCGame(link: string, bannerPos: any) {
        if (this.isGameUrl(link)) {
            const gameId = this.getGameIdFromTeaserHref(link);
            const game = { gameId: gameId };
            const gameLaunchModel = new GameLaunch();
            gameLaunchModel.game = game;
            gameLaunchModel.isTouch = this.isTouch;
            gameLaunchModel.gamePosition = Number(bannerPos);
            gameLaunchModel.categoryType = 'OptimisedTeaser';
            gameLaunchModel.from = 'OptimisedTeaser';
            this.vcClientService.handleVCGameLaunch(gameLaunchModel, false);
        }
    }

    getGameIdFromTeaserHref(link: string) {
        return this.isGameUrl(link) ? link.substring(link.indexOf(this.teaserLaunchRealtiveUri) + this.teaserLaunchRealtiveUri.length) : '';
    }

    handleTeaserClick(link: any, bannerPos: any) {
        const href = link.url;
        const options: any = { skipLoadingIndicator: false };
        const gameId: string = this.getGameIdFromTeaserHref(href);
        if (href.substr(href.length - 4, 4).toLowerCase() === '.apk') {
            options.skipLoadingIndicator = true;
        }
        if (this.configProviderService.provideGameConfig().nativeGameLaunchEnabled && gameId) {
            this.launchNativeGame(href, bannerPos);
            return;
        }
        if (gameId) {
            let gameHref: string = cloneDeep(href);
            if (this.configProviderService.provideAdvanceGameDataConfig().enableAdvanceGameData) {
                gameHref = this.gameLaunchService.appendQueryString(href, 'adv_parentcat', 'Teaser');
                gameHref = this.gameLaunchService.appendQueryString(gameHref, 'adv_pos', String(bannerPos - 1));
            }
            if (this.casinoWidgetService.isCasinoWidget() || this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                if (this.isTouch) {
                    if (this.casinoWidgetService.isCasinoWidget() && gameId) {
                        let postData: any = {
                            promptLogin: !this.user.isAuthenticated,
                            redirectUrl: gameHref,
                            redirectOptions: this.user.isAuthenticated ? { showSpinnerForExternalNavigation: !options.skipLoadingIndicator } : null,
                        };
                        this.casinoWidgetService.postMessageToParent('UserAction', postData);
                        return;
                    } else if (this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                        let redirectPlayUrl: string = gameHref;
                        redirectPlayUrl = this.gameLaunchService.appendQueryString(
                            redirectPlayUrl,
                            'invokerProduct',
                            this.casinoWidgetService.getWidgetProduct(),
                        );
                        if (this.user.isAuthenticated) {
                            redirectPlayUrl = this.gameLaunchService.appendQueryString(redirectPlayUrl, 'returnurl', document.referrer);
                            redirectPlayUrl = this.gameLaunchService.appendQueryString(redirectPlayUrl, 'widgetGameLaunch', 'true');
                        }
                        let postData: any = {
                            promptLogin: !this.user.isAuthenticated,
                            redirectUrl: redirectPlayUrl,
                        };
                        this.casinoWidgetService.postMessageToParent('UserAction', postData);
                        return;
                    }
                } else {
                    if (this.casinoWidgetService.isCasinoWidget()) {
                        gameHref = this.gameLaunchService.appendQueryString(gameHref, 'widgetHomeUrl', document.referrer);
                        this.casinoWidgetService.postMessageToParent('UserAction', {
                            promptLogin: !this.user.isAuthenticated,
                            redirectUrl: gameHref,
                        });
                        return;
                    }
                }
            } else {
                let gameInfo: any = this.casinoLobbyService.getGameInfo(gameId);
                this.isTouch = this.casinoManager.isTouch();
                if (gameInfo) {
                    gameInfo.gameId = gameId;
                    let gameModel = new GameLaunch();
                    gameModel.game = gameInfo;
                    gameModel.isTouch = this.isTouch;
                    gameModel.gamePosition = bannerPos - 1;
                    gameModel.categoryType = this.teaserCategory;
                    gameModel.from = 'q';
                    gameModel.replaceRouteInHistory = false;
                    gameModel.isDemoLaunch = false;
                    gameModel.skipUnfinGame = false;
                    gameModel.gameLaunchedFrom = this.gameLaunchPlaces.teaser;
                    gameModel.gridPosition = 1;
                    gameModel.gameContainer = this.gameContainer;
                    gameModel.game.lobbyType = this.lobbyType;
                    gameModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
                    this.gameLaunchService.launchGame(gameModel);
                }
            }
        } else {
            if (this.casinoWidgetService.isCasinoWidget() || this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                this.casinoWidgetService.postMessageToParent('UserAction', {
                    redirectUrl: href,
                    redirectOptions: { showSpinnerForExternalNavigation: !options.skipLoadingIndicator },
                });
                return;
            } else {
                this.handleNavigation(href, link.attributes, !options.skipLoadingIndicator);
            }
        }
    }

    launchNativeGame(link: string, bannerPos: any) {
        if (this.isGameUrl(link)) {
            let gameId = this.getGameIdFromTeaserHref(link);
            let params = { gameId: gameId, skipUnfinGame: false };
            let game = { gameId: gameId };
            let gameLaunchModel = new GameLaunch();
            gameLaunchModel.game = game;
            gameLaunchModel.isTouch = this.isTouch;
            gameLaunchModel.gamePosition = Number(bannerPos);
            gameLaunchModel.categoryType = 'OptimisedTeaser';
            gameLaunchModel.from = 'OptimisedTeaser';
            this.nativeAppHelper.handleNativeGameLaunch(params, gameLaunchModel);
        }
    }

    emitVideoLoadedEvent() {
        this.onVideoLoadedEvent.emit();
    }

    getVideoEndEvent() {
        this.onVideoEndEvent.emit();
    }

    videoError() {
        this.videoErrorEvent.emit();
    }

    getTimeChangeEvent(event: any) {
        this.onTimeChangeEvent.emit(event);
    }

    private handleNavigation(url: string, attributes: any, showSpinnerForExternalNavigation: boolean) {
        if (attributes && attributes.hasOwnProperty('target')) {
            window.open(url, attributes.target);
        } else if (url) {
            this.navigation.goTo(url, { showSpinnerForExternalNavigation: showSpinnerForExternalNavigation });
        }
    }
}
