import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csAracdeLobbyConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: arcadeLobbyClientConfigFactory,
})
export class ArcadeLobbyClientConfig {
    rpCount: number;
    rpCountOverlay: number;
    widgetGamesConfig: any;
    arcadeIconConfigMap: any;
    tabletRpCount: number;
}

export function arcadeLobbyClientConfigFactory(service: LazyClientConfigService) {
    return service.get(ArcadeLobbyClientConfig);
}
