import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CasinoCoreGameLaunchFactoryService {
    gameLaunchService: any;
    gameService: any;
    constructor() {}
    setGameLaunchService(gameLaunchService: any): void {
        this.gameLaunchService = gameLaunchService;
    }

    getGameLaunchService(): any {
        return this.gameLaunchService;
    }
    // NFR - Services optimization - Remove
    setGameService(gameService: any): void {
        this.gameService = gameService;
    }

    getGameService(): any {
        return this.gameService;
    }
}
