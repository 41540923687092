import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SearchDesktopComponent } from './search-desktop.component';

@Component({
    selector: 'cc-lazy-search-desktop',
    template: `
        @defer (on immediate) {
            <cc-search-desktop
                [isTouch]="isTouch"
                [lobbyType]="lobbyType"
                [placeholder]="placeholder"
                [categoryRoute]="categoryRoute"
                (embedGameClicked)="embedGameClicked.emit($event)" />
        }
    `,
    standalone: true,
    imports: [SearchDesktopComponent],
})
export class LazySearchDesktopComponent {
    @Input() isTouch: boolean;
    @Input() lobbyType: string;
    @Input() placeholder: string;
    @Input() categoryRoute: string;
    @Output() embedGameClicked = new EventEmitter<any>();
}
