import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { FeaturesClientConfig } from '../client-config';

@Component({
    selector: 'cs-skeleton',
    templateUrl: 'skeleton.component.html',
    standalone: true,
    imports: [NgIf, NgFor],
})
export class SkeletonComponent implements OnInit {
    enableNewSkeletonUI: boolean;
    constructor(private featuresConfig: FeaturesClientConfig) {}
    ngOnInit() {
        this.enableNewSkeletonUI = this.featuresConfig.enableNewSkeletonUI;
    }
}
