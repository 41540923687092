@if (!isLegacy) {
    <cs-cta [kind]="kind" [variant]="variant" [size]="size" [casinoDsClass]="casinoDsClass" [cta_text]="cta_text" [inlineStyle]="inlineStyle">
        <ng-content slot="start" select="[slot=start]"></ng-content>
        {{ cta_text }}
        <ng-content slot="end" select="[slot=end]"></ng-content>
    </cs-cta>
}

@if (isLegacy) {
    <button [class.btn-primary]="kind === 'primary'" class="btn" [style]="inlineStyle" data-decoratorclass-host data-legacy="true">
        {{ cta_text }}
    </button>
}
