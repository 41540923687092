<div
    *ngIf="!enableAluminiumTeasers && !enableFixedWidthAluminiumTeasers"
    class="casino-opt-mini-teasers-bg"
    [ngClass]="[miniTeaserEnabledClass, miniTeaserGridAlignment]"
    [ngStyle]="isOptMiniTeasersEnabled ? { background: 'url(' + (mercuryBgImage && mercuryBgImage.src | sitecore_image_resize) + ')' } : ''">
    <div
        class="casino-opt-teasers-crousale"
        ccIntersectionObserver
        [intersectionDebounce]="0"
        (visibilityChange)="onVisibilityChanged($event)"
        [ngClass]="[
            arrowClass,
            teaserType,
            teaserDefaultSliderArrowBg,
            teaserDefaultSliderArrowShape,
            teaserDefaultSliderBullet,
            teaserProgressbar,
            paginationClass
        ]"
        [style.width]="!isOptMiniTeasersEnabled ? '100%' : ''"
        *ngIf="normalTeaserReady && teasersSource().length > 0"
        id="OptTeaserCarousel_{{ identifierClass }}">
        <div class="opt-teasers" [ngClass]="{ 'opt-arrows-single': teasersSource().length < 2 }" [style.height.px]="displayHeight()">
            <div (mouseenter)="onTeaserHover(true)" (mouseleave)="onTeaserHover(false)" ccLongPress (onMouseLongPress)="onTeaserLongPress($event)">
                <vn-swiper *ngIf="normalTeaserReady" [swiperOptions]="swiperOptionsOpt">
                    <div class="swiper-slide" *ngFor="let teaser of teasersSource(); let i = index">
                        <cc-opt-teaser-item
                            [teaser]="teaser"
                            [activeSlides]="activeSlides"
                            [teaserIndex]="i"
                            [isHover]="isMouseOverOnTeaser"
                            [teaserHeight]="teaserHeight"
                            [teaserCategory]="teaserCategory"
                            [lobbyType]="lobbyType"
                            [gameContainer]="gameContainer"
                            [identifierClass]="identifierClass"
                            [isOptTeaserPhase3]="isOptTeaserPhase3"
                            [isOptMiniTeasersEnabled]="isOptMiniTeasersEnabled"
                            (videoErrorEvent)="videoError()"
                            (onVideoEndEvent)="getVideoEndEvent()"
                            (onTimeChangeEvent)="getTimeChangeEvent($event)" />
                        <div *ngIf="displayProgressBar()" class="casino-line-bg">
                            <mat-progress-bar [mode]="'determinate'" [value]="progressbarValue()[i]" />
                        </div>
                    </div>
                </vn-swiper>
                @if (showPagination && isOptMiniTeasersEnabled && !hideTeasersPagination && enablePagination) {
                    <cc-custom-carousel-pagination [noOfItems]="teasersSource().length" [currentIndex]="currentIndex()" [carousel]="swiperRef" />
                }
            </div>
        </div>
    </div>
    <div
        class="casino-opt-mini-teasers-crousale"
        [ngClass]="[
            miniArrowClass,
            teaserType,
            teaserDefaultSliderArrowBg,
            teaserDefaultSliderArrowShape,
            teaserDefaultSliderBullet,
            teaserProgressbar,
            paginationClass
        ]"
        *ngIf="isOptMiniTeasersEnabled"
        id="OptTeaserCarousel_{{ identifierClass }}">
        <div class="opt-teasers" [ngClass]="{ 'opt-arrows-single': optMiniTeasers.length < 2 }" *ngIf="optTeaser" [style.height.px]="teaserHeight">
            <cc-opt-mini-teasers
                [teaserCategory]="teaserCategory"
                [teasersSource]="optMiniTeasers"
                [lobbyType]="lobbyType"
                [gameContainer]="gameContainer" />
        </div>
    </div>
    <div
        class="casino-playbingo-btn"
        [ngClass]="openBingoLobbyButtonPosition | lowercase"
        *ngIf="enablebingoplay && bingocta && teasersSource().length > 0">
        <!-- <div
            class="btn btn-primary playbingo-cta"
            [innerHtml]="bingocta.name | trustAsHtml"
            [ngClass]="{ 'epcot-btn': enableEpcotSubNavigation }"
            (click)="playbingoClick(bingocta)"></div> -->
        <cs-gametile-cta
            [isLegacy]="isLegacy"
            [kind]="'primary'"
            [size]="'large'"
            [variant]="'filled'"
            (click)="playbingoClick(bingocta)"
            [csDecoratorClass]="'playbingo-cta'"
            [cta_text]="bingocta.name">
        </cs-gametile-cta>
    </div>
</div>
<div *ngIf="enableAluminiumTeasers" class="alu-teasers" [ngClass]="{ fixedWidth: enableFixedWidthAluminiumTeasers }">
    <div
        class="casino-opt-teasers-crousale"
        ccIntersectionObserver
        [intersectionDebounce]="0"
        (visibilityChange)="onVisibilityChanged($event)"
        [ngClass]="[
            teaserType,
            arrowClass,
            teaserDefaultSliderArrowBg,
            teaserDefaultSliderArrowShape,
            teaserDefaultSliderBullet,
            teaserProgressbar,
            paginationClass
        ]"
        [style.width]="!isOptMiniTeasersEnabled ? '100%' : ''"
        *ngIf="aluminiumTeaserReady && teasersSource().length"
        id="OptTeaserCarousel_{{ identifierClass }}">
        <div class="opt-teasers" [ngClass]="{ 'opt-arrows-single': teasersSource().length < 2 }" [style.height.px]="displayHeight()">
            <div (mouseenter)="onTeaserHover(true)" (mouseleave)="onTeaserHover(false)" ccLongPress (onMouseLongPress)="onTeaserLongPress($event)">
                <vn-swiper *ngIf="aluminiumTeaserReady" [swiperOptions]="swiperOptionsAluminium" class="mySwiper">
                    <div class="swiper-slide" *ngFor="let teaser of teasersSource(); let i = index">
                        <cc-opt-teaser-item
                            [teaser]="teaser"
                            [activeSlides]="activeSlides"
                            [teaserIndex]="i"
                            [isHover]="isMouseOverOnTeaser"
                            [teaserHeight]="teaserHeight"
                            [teaserCategory]="teaserCategory"
                            [lobbyType]="lobbyType"
                            [gameContainer]="gameContainer"
                            [identifierClass]="identifierClass"
                            [isOptTeaserPhase3]="isOptTeaserPhase3"
                            [isOptMiniTeasersEnabled]="isOptMiniTeasersEnabled"
                            (videoErrorEvent)="videoError()"
                            (onVideoEndEvent)="getVideoEndEvent()"
                            (onTimeChangeEvent)="getTimeChangeEvent($event)" />
                        <div *ngIf="displayProgressBar()" class="casino-line-bg">
                            <mat-progress-bar [mode]="'determinate'" [value]="progressbarValue()[i]" />
                        </div>
                    </div>
                </vn-swiper>
            </div>
        </div>
        <div class="custom-carousel">
            @if (showPagination && !hideTeasersPagination && enablePagination) {
                <cc-custom-carousel-pagination [noOfItems]="teasersSource().length" [currentIndex]="currentIndex()" [carousel]="swiperRef" />
            }
        </div>
    </div>
</div>
