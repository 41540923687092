<div
    class="casino-lobby-container-main"
    [ngClass]="{
        'casino-backgroungImage-layer': parallaxInput && parallaxInput.sitecoreData.backgroundImageLayer1,
        'casino-backgroungImage-parallax':
            (parallaxInput && parallaxInput.sitecoreData.backgroundImageLayer2) || (parallaxInput && parallaxInput.sitecoreData.backgroundImageLayer3)
    }"
    style.overflow="{{ !isTouch ? 'hidden' : 'unset' }}">
    <cc-casino-core-lazy-placeholder [inputData]="{ subCategory: parallaxInput }" [componentName]="'ParallaxAnimationComponent'">
    </cc-casino-core-lazy-placeholder>
    <div class="r-winners">
        <div class="r-winners-touched">
            <div class="rw-m-container" *ngIf="isTouch || isMobileView() || isTabView()">
                <div class="filter-list" #winnersNavContainer>
                    <div
                        class="header-btn btn btn-header filter-item w-color"
                        [ngStyle]="{
                            'background': globalMessages.FilterBgColor,
                            'opacity': globalMessages.WinnersFilterOpacity,
                            'font-size': globalMessages.FiltersMobileFontSize
                        }"
                        *ngFor="let filter of filters; let i = index"
                        [class.active]="filter.value === selectedTab"
                        (click)="FilterType(filter, i)">
                        {{ filter.firstValue }}
                    </div>
                </div>
                <div class="m-title" [ngStyle]="{ color: globalMessages.WinnersTextColor }">
                    {{ displayFilterName }}
                    <div class="m-title winners" *ngIf="winnersCount || winnersCount == 0">{{ winnersCount }} {{ globalMessages.winners }}</div>
                </div>
                <div class="touch-device" #cardScroll>
                    <p [ngStyle]="{ color: globalMessages.WinnersErrorTextColor }" *ngIf="errorMsg && winnersCount == 0">
                        {{ errorMsg }}
                    </p>
                    <div *ngIf="enableLoadingIcon" [ngClass]="{ 'loading-v1': loadingIndicator }"></div>

                    <div *ngIf="enableNewsFeedOfWinnersFeed; else touchBlock">
                        <div class="casino-rw-news-feed-container" *ngIf="totalwinnersList && totalwinnersList.length > 0">
                            <div class="casino-rw-news-feed-ticker-wrap">
                                <span class="rw-news-feed-wrapper-container">
                                    <span class="casino-rw-news-feed-wrapper">
                                        <div
                                            class="casino-rw-news-feed-ul-box"
                                            *ngFor="let arr of fillingArray; let j = index"
                                            (touchcancel)="touchReleased()"
                                            (touchstart)="touchStarted()"
                                            (touchend)="touchReleased()">
                                            <!-- [ngClass]="{'casino-touchOnMobile': !triggerForMobileDevices}" -->
                                            <ul class="rw-card casino-news-feed-ticker-move">
                                                <ng-container *ngFor="let winner of totalwinnersList | dsl | async; let i = index">
                                                    <recent-winners-news-feed
                                                        [winner]="winner"
                                                        [newItem]="true"
                                                        [category]="category"
                                                        [gamePosition]="i"
                                                        [newsFeed]="totalwinnersList"
                                                        [index]="i + 1"
                                                        [isLastItem]="i == totalwinnersList.length - 1"
                                                        [isLastIteration]="j == fillingArray.length - 1"
                                                        [lobbyType]="lobbyType">
                                                    </recent-winners-news-feed>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <ng-template #touchBlock>
                        <div class="rw-card" *ngFor="let winner of totalwinnersList; let i = index">
                            <div
                                class="rw-card-details"
                                [ngStyle]="{ background: globalMessages.WinnerBgColor, opacity: globalMessages.WinnersFilterOpacity }">
                                <div class="rw-u-details">
                                    <div class="rw-u-name w-color">
                                        {{ winner.screenName | slice: 0 : 2 }}
                                        <span>****</span>
                                    </div>
                                    <div class="rw-u-amount w-color">{{ winner.gc | currencySymbol }} {{ getDecimal(winner.gcWin) }}</div>
                                </div>
                                <div class="rw-u-image">
                                    <img *ngIf="winner.jpWinFlag == 'N'" src="{{ WinnersConfig?.nonJackpotImage?.src }}" alt="textImage" />
                                    <img *ngIf="winner.jpWinFlag == 'Y'" src="{{ WinnersConfig?.jackpotImage?.src }}" alt="textImage" />
                                </div>
                            </div>
                            <div class="rw-game-tails">
                                <!-- <img
                src="https://tr-casino-pgrouter.ivycomptech.co.in/htmllobby/images/newlmticons/square/pngbookofdead.jpg"
                alt="textImage"
              /> -->
                                <cc-game-lobby
                                    [ngClass]="winner.infoIconClass"
                                    [game]="winner"
                                    [gamePosition]="i"
                                    [category]="category"
                                    [gameLaunchedFrom]="'Recent winners Widget'"
                                    [gameIndex]="i + 1">
                                </cc-game-lobby>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <!--  desktop view -->
        <div class="rw-container" *ngIf="!isTouch && !isMobileView()">
            <div class="rw-container-left">
                <div class="align">
                    <div class="w-title w-color" [ngStyle]="{ color: globalMessages.WinnersTextColor }">{{ displayFilterName }}</div>
                    <div class="w-subtitle w-color" *ngIf="winnersCount || winnersCount == 0" [ngStyle]="{ color: globalMessages.WinnersTextColor }">
                        {{ winnersCount }} {{ globalMessages.winners }}
                    </div>
                </div>
            </div>
            <div class="rw-container-right">
                <div class="navContainer">
                    <div class="filter-list" #winnersNavContainer>
                        <div
                            class="header-btn btn btn-header filter-item w-color"
                            [ngStyle]="{
                                'background': globalMessages.FilterBgColor,
                                'opacity': globalMessages.WinnersFilterOpacity,
                                'font-size': globalMessages.FiltersDesktopFontSize,
                                'width': filterMinWidth
                            }"
                            *ngFor="let filter of filters; let i = index"
                            [class.active]="filter.value === selectedTab"
                            (click)="FilterType(filter, i)">
                            {{ filter.firstValue }}
                        </div>
                    </div>
                    <div
                        class="arrow-container"
                        *ngIf="(!isTouch && (leftFilterScrollButton || rightFilterScrollButton)) || (isTouch && isIpadPro())">
                        <div class="casino-carousel-bg left-sec" *ngIf="!isTouch">
                            <div
                                class="scroll-btn theme-left left"
                                [ngStyle]="{ background: globalMessages.FilterCarousalIconBgColor }"
                                [class.disable]="!leftFilterScrollButton"
                                (click)="onMoveFilterContainer(true, $event)"></div>
                        </div>
                        <div class="casino-carousel-bg right-sec" *ngIf="!isTouch">
                            <div
                                class="scroll-btn theme-right right"
                                [ngStyle]="{ background: globalMessages.FilterCarousalIconBgColor }"
                                [class.disable]="!rightFilterScrollButton"
                                (click)="onMoveFilterContainer(false, $event)"></div>
                        </div>
                    </div>
                </div>
                <p [ngStyle]="{ color: globalMessages.WinnersErrorTextColor }" *ngIf="errorMsg && winnersCount == 0">
                    {{ errorMsg }}
                </p>

                <!--  Loading spinner if empty - and loading data-->
                <div *ngIf="enableLoadingIcon" [ngClass]="{ 'loading-v1': loadingIndicator }"></div>
                <!--  -->

                <div *ngIf="!loadingIndicator && totalwinnersList.length">
                    <div *ngIf="enableNewsFeedOfWinnersFeed; else swiperFeedBlock">
                        <div class="casino-rw-news-feed-container">
                            <div class="casino-rw-news-feed-ticker-wrap">
                                <span class="rw-news-feed-wrapper-container">
                                    <span class="casino-rw-news-feed-wrapper">
                                        <div
                                            class="casino-rw-news-feed-ul-box"
                                            *ngFor="let arr of fillingArray; let j = index"
                                            (touchcancel)="touchReleased()"
                                            (touchstart)="touchStarted()"
                                            (touchend)="touchReleased()"
                                            [ngClass]="{ 'casino-touchOnMobile': !triggerForMobileDevices }">
                                            <ul class="casino-news-feed-ticker-move">
                                                <ng-container *ngFor="let winner of totalwinnersList | dsl | async; let i = index">
                                                    <recent-winners-news-feed
                                                        class="r-winners"
                                                        [winner]="winner"
                                                        [newItem]="true"
                                                        [category]="category"
                                                        [gamePosition]="i"
                                                        [newsFeed]="totalwinnersList"
                                                        [index]="i + 1"
                                                        [isLastItem]="i == totalwinnersList.length - 1"
                                                        [isLastIteration]="j == fillingArray.length - 1"
                                                        [lobbyType]="lobbyType">
                                                    </recent-winners-news-feed>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <ng-template #swiperFeedBlock>
                        <div class="rw-swiper">
                            <vn-swiper [swiperOptions]="swiperOptions">
                                <div class="swiper-slide" *ngFor="let winner of totalwinnersList; let i = index">
                                    <div class="rw-card">
                                        <div
                                            class="rw-card-details"
                                            [ngStyle]="{ background: globalMessages.WinnerBgColor, opacity: globalMessages.WinnersFilterOpacity }">
                                            <div class="rw-u-details">
                                                <div class="rw-u-name w-color letter">
                                                    {{ winner.screenName | slice: 0 : 2 }}
                                                    <span>****</span>
                                                </div>
                                                <div class="rw-u-amount w-color">{{ winner.gc | currencySymbol }} {{ getDecimal(winner.gcWin) }}</div>
                                            </div>
                                            <div class="rw-u-image">
                                                <img
                                                    *ngIf="winner.jpWinFlag == 'N'"
                                                    src="{{ WinnersConfig?.nonJackpotImage?.src }}"
                                                    alt="textImage" />
                                                <img *ngIf="winner.jpWinFlag == 'Y'" src="{{ WinnersConfig?.jackpotImage?.src }}" alt="textImage" />
                                            </div>
                                        </div>
                                        <div class="rw-game-tails">
                                            <cc-game-lobby
                                                [ngClass]="winner.infoIconClass"
                                                [game]="winner"
                                                [gamePosition]="i"
                                                [category]="category"
                                                [gameLaunchedFrom]="'Recent winners Widget'"
                                                [gameIndex]="i + 1">
                                            </cc-game-lobby>
                                        </div>
                                    </div>
                                </div>
                            </vn-swiper>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
