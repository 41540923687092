<div class="discription-tab-container">
    <div class="game-dicription-container" [class.no-volatility]="!showVolatilityTable && !hasVolatility && !isShowValuePart">
        <div class="moreinfo-game-discription" *ngIf="gameDescription" [vnDynamicHtml]="gameDescription"></div>
    </div>
    <div *ngIf="showVolatilityTable">
        <div class="volatility-container" [class.no-discription]="!gameDescription">
            <div class="head-part" *ngIf="hasVolatility">
                <div class="img-part"><img src="{{ volatilityImgLink }}" alt="" /></div>
                <div class="title-part">
                    <div class="title">{{ volatilityTypeText }}</div>
                    <div class="link" (click)="showVoltalityPart()">{{ howItWorksLink }}</div>
                </div>
            </div>
            <div class="values-part" *ngIf="isShowValuePart" [class.no-header]="!hasVolatility">
                <div class="lines-block" *ngIf="lines && lines != 'null' && lines != '0'">
                    <span>{{ linesText }}</span
                    ><span class="values">{{ lines }}</span>
                </div>
                <div class="reels-block" [class.only-maxpayout]="isOnlyReels" *ngIf="reels && reels != 'null' && reels != '0'">
                    <span>{{ reelsText }}</span
                    ><span class="values">{{ reels }}</span>
                </div>
                <div class="maxpayout-block" [class.only-maxpayout]="isOnlyMaxpayout" *ngIf="maxPayout && maxPayout != 'null'">
                    <span>{{ maxPayoutText }}</span
                    ><span class="values">{{ maxPayout }}</span>
                </div>
            </div>
            <div class="volatility-part" *ngIf="isShowVoltalityPart">
                <div class="high-vo-container volatility">
                    <div class="img-part"><img src="{{ highVolatilityImagelink }}" alt="" /></div>
                    <div class="content-part">
                        <div class="title">{{ highVolatilityText }}</div>
                        <div class="discription">{{ highVolatilityDescription }}</div>
                    </div>
                </div>
                <div class="reg-vo-container volatility">
                    <div class="img-part"><img src="{{ regVolatilityImagelink }}" alt="" /></div>
                    <div class="content-part">
                        <div class="title">{{ regVolatilityText }}</div>
                        <div class="discription">{{ regVolatilityDescription }}</div>
                    </div>
                </div>
                <div class="mild-vo-container volatility">
                    <div class="img-part"><img src="{{ mildVolatilityImagelink }}" alt="" /></div>
                    <div class="content-part">
                        <div class="title">{{ mildVolatilityText }}</div>
                        <div class="discription">{{ mildVolatilityDescription }}</div>
                    </div>
                </div>
                <!-- <button class="gotit-brn btn btn-primary btn-sm" (click)="hideVoltalityPart()">{{ gotItCta }}</button> -->
                <cs-gametile-cta
                    [isLegacy]="isLegacy"
                    [kind]="'primary'"
                    [variant]="'filled'"
                    [size]="'medium'"
                    [csDecoratorClass]="'gotit-brn btn-sm'"
                    [cta_text]="gotItCta"
                    (click)="hideVoltalityPart()"></cs-gametile-cta>
            </div>
        </div>
    </div>
</div>
