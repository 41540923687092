import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { GeolocationInstallerComponent } from '../installer/installer.component';

@Injectable({
    providedIn: 'root',
})
export class GeolocationInstallerDialogService {
    onDialogClosed: () => void;

    private dialogRef: MatDialogRef<GeolocationInstallerComponent>;
    private _isDialogOpen: boolean = false;

    constructor(private readonly dialog: MatDialog) {}

    openDialog(downloadUrl: string, isMobileInstaller: boolean) {
        this.dialogRef = this.dialog.open(GeolocationInstallerComponent, {
            width: '250px',
            data: {
                downloadUrl: downloadUrl,
                isMobileInstaller: isMobileInstaller,
            },
            panelClass: 'geo-location-installer',
        });
        this._isDialogOpen = true;

        this.dialogRef.afterClosed().subscribe(() => {
            //console.info('The dialog was closed:', result);
            this._isDialogOpen = false;
            if (this.onDialogClosed) {
                this.onDialogClosed();
            }
        });
    }

    closeDialog(): void {
        this.dialogRef && this.dialogRef.close();
    }

    get isDialogOpen(): boolean {
        return this._isDialogOpen;
    }

    hideLoader(): void {
        this.dialogRef && this.dialogRef.componentInstance && this.dialogRef.componentInstance.hideLoader();
    }
}
