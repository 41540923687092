import { ChangeDetectionStrategy, Component, Input, booleanAttribute, numberAttribute } from '@angular/core';

import { CsCtaComponent, CsCtaKind, CsCtaSize, CsCtaVariant } from '../../cs-cta/cs-cta.component';

@Component({
    selector: 'cs-gametile-cta',
    templateUrl: './gametile-cta.component.html',
    standalone: true,
    imports: [CsCtaComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameTileCtaComponent implements GameTileCtaViewModel, GameTileCtaLegacyViewModel, GameTileCtaViewBinding {
    @Input() size: CsCtaSize = 'large';
    @Input() variant: CsCtaVariant = 'filled';
    @Input() kind: CsCtaKind = 'primary';
    @Input() cta_text = '';
    @Input() casinoDsClass = '';
    @Input() customBgColor = '';
    @Input() customTextColor = '';
    @Input() inlineStyle = '';
    @Input({ transform: booleanAttribute }) isLegacy = false;
    @Input({ transform: booleanAttribute }) disabled = false;
    @Input({ transform: numberAttribute }) ctaWidth: number | undefined;

    getComputerStyle() {
        return `width: ${this.ctaWidth}%; background-color: ${this.customBgColor}; color: ${this.customTextColor}; ${this.inlineStyle}`;
    }
}

export interface GameTileCtaViewModel {
    size: CsCtaSize;
    variant: CsCtaVariant;
    kind: CsCtaKind;
    cta_text: string;
    casinoDsClass: string;
    inlineStyle: string;
    customBgColor: string;
    customTextColor: string;
    disabled: boolean;
    ctaWidth: number | undefined;
}

export interface GameTileCtaLegacyViewModel {
    isLegacy: boolean;
}

interface GameTileCtaViewBinding {
    getComputerStyle(): string;
}
