<div class="mustGoFlip" [ngStyle]="{ 'margin-left': mustGoPtLsMargin(tickerValueLength) }">
    <div *ngIf="amount != undefined && isMega != 'true'" class="tickerpadding" [ngStyle]="{ 'margin-left': paddingValue(tickerValueLength) }">
        <div class="seg">
            <div class="flip-wrapper">
                <div class="curr">{{ currency }}</div>
            </div>
        </div>
        <div class="seg" [ngClass]="{ Comma: mjItem.value == ',' }" *ngFor="let mjItem of majorItems">
            <div *ngIf="mjItem.value === ','" class="flip-comma">{{ mjItem.value }}</div>
            <div class="flip-wrapper" *ngIf="mjItem.value !== ','">
                <div
                    class="flip"
                    *ngIf="mjItem.isFlipVisible"
                    [ngClass]="{
                        flipAmber: tickerValue < eightyPercentValue && tickerValue >= sixtyPercentValue,
                        flipRed: tickerValue >= eightyPercentValue,
                        flipPurple: tickerValue < sixtyPercentValue
                    }">
                    {{ mjItem.value }}
                </div>
                <div
                    class="flip"
                    [ngClass]="{
                        'flip--top': mjItem.isFlipVisible,
                        'flipAmber': tickerValue < eightyPercentValue && tickerValue >= sixtyPercentValue,
                        'flipRed': tickerValue >= eightyPercentValue,
                        'flipPurple': tickerValue < sixtyPercentValue
                    }">
                    {{ mjItem.value }}
                </div>
                <div
                    class="flip flip--top flip--back"
                    *ngIf="mjItem.isFlipVisible"
                    [ngClass]="{
                        flipAmber: tickerValue < eightyPercentValue && tickerValue >= sixtyPercentValue,
                        flipRed: tickerValue >= eightyPercentValue,
                        flipPurple: tickerValue < sixtyPercentValue
                    }">
                    {{ mjItem.value }}
                </div>
                <div
                    class="flip flip--bottom"
                    [ngClass]="{
                        flipAmber: tickerValue < eightyPercentValue && tickerValue >= sixtyPercentValue,
                        flipRed: tickerValue >= eightyPercentValue,
                        flipPurple: tickerValue < sixtyPercentValue
                    }">
                    {{ mjItem.value }}
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="amount != undefined && isMega == 'true'" class="flipleftpad" [ngStyle]="{ 'margin-left': paddingValueMega(tickerValueLength) }">
        <div class="seg-mega">
            <div class="flip-wrapper-mega">
                <div class="curr-mega">{{ currency }}</div>
            </div>
        </div>
        <div class="seg-mega" [ngClass]="{ 'comma-mega': mjItem.value == ',' }" *ngFor="let mjItem of majorItems; let i = index">
            <div *ngIf="mjItem.value === ','" class="flip-comma-mega">{{ mjItem.value }}</div>
            <div class="flip-wrapper-mega" *ngIf="mjItem.value !== ','">
                <div
                    class="flip-mega"
                    *ngIf="mjItem.isFlipVisible"
                    [ngClass]="{
                        flipAmberMega: tickerValue < eightyPercentValue && tickerValue >= sixtyPercentValue,
                        flipRedMega: tickerValue >= eightyPercentValue,
                        flipPurpleMega: tickerValue < sixtyPercentValue
                    }">
                    {{ mjItem.value }}
                </div>
                <div
                    class="flip-mega"
                    [ngClass]="{
                        'flip--top--mega': mjItem.isFlipVisible,
                        'flipAmberMega': tickerValue < eightyPercentValue && tickerValue >= sixtyPercentValue,
                        'flipRedMega': tickerValue >= eightyPercentValue,
                        'flipPurpleMega': tickerValue < sixtyPercentValue
                    }">
                    {{ mjItem.value }}
                </div>
                <div
                    class="flip-mega flip--top--mega flip--back--mega"
                    *ngIf="mjItem.isFlipVisible"
                    [ngClass]="{
                        flipAmberMega: tickerValue < eightyPercentValue && tickerValue >= sixtyPercentValue,
                        flipRedMega: tickerValue >= eightyPercentValue,
                        flipPurpleMega: tickerValue < sixtyPercentValue
                    }">
                    {{ mjItem.value }}
                </div>
                <div
                    class="flip-mega flip--bottom--mega"
                    [ngClass]="{
                        flipAmberMega: tickerValue < eightyPercentValue && tickerValue >= sixtyPercentValue,
                        flipRedMega: tickerValue >= eightyPercentValue,
                        flipPurpleMega: tickerValue < sixtyPercentValue
                    }">
                    {{ mjItem.value }}
                </div>
            </div>
        </div>
    </div>
</div>
