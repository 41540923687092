import { Injectable, signal } from '@angular/core';

import { Logger } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';

@Injectable({
    providedIn: 'root',
})
export class TeaserService {
    private readonly _headerTeasers = signal<any | undefined>(undefined);
    readonly headerTeasers = this._headerTeasers.asReadonly();
    public mercuryBgImage: any;

    teaserDataPublisher = new BehaviorSubject<any>(null);
    teaserDataObservable: Observable<any> = this.teaserDataPublisher.asObservable();
    optMiniTeasersMap: Map<string, any> = new Map<string, any>();
    private optMiniTeasersMapPublisher = new BehaviorSubject<boolean>(false);
    optMiniTeasersMapObservable: Observable<boolean> = this.optMiniTeasersMapPublisher.asObservable();
    teaserMutedSubject = new BehaviorSubject<any>(null);
    teaserMutedObservable: Observable<any> = this.teaserMutedSubject.asObservable();
    indexChangedSubject = new BehaviorSubject<any>(null);
    indexChangedObservable: Observable<any> = this.indexChangedSubject.asObservable();
    indexChangedDataSubject = new BehaviorSubject<any>(null);
    indexChangedDataObservable: Observable<any> = this.indexChangedDataSubject.asObservable();

    constructor(
        private api: PlatformApiService,
        private configProviderService: ConfigProviderService,
        private logger: Logger,
    ) {}

    setIndex(value: any) {
        this.indexChangedSubject.next(value);
    }

    muteVideo(identifier: string, index: number, value: boolean) {
        this.teaserMutedSubject.next({
            identifierClass: identifier,
            realIndex: index,
            isMuted: value,
        });
    }

    muteAllVideos() {
        this.teaserMutedSubject.next({ muteAllVideos: true });
    }

    setTeasers() {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        this.api.get(apiPathsConfig.teasers['teasersData'], {}, { withCredentials: true }).subscribe(
            (response) => {
                this._headerTeasers.set(response);
                this.teaserDataPublisher.next(response);
            },
            (error) => {
                this.logger.error('Unable to retrieve data from teasers API: ' + error);
            },
        );
    }

    getTeaserData() {
        return this.headerTeasers;
    }

    indexChangeTeaserData(teaserData: any) {
        this.indexChangedDataSubject.next(teaserData);
    }

    fetchOptMiniTeasers(optMiniTeaserPaths: Array<string>) {
        if (optMiniTeaserPaths && optMiniTeaserPaths.length) {
            const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
            this.api.post(apiPathsConfig.teasers['optMiniTeasersData'], optMiniTeaserPaths, { withCredentials: true }).subscribe(
                (response: IOptMiniTeasersResponse) => {
                    if (this.optMiniTeasersMap && this.optMiniTeasersMap.size > 0) {
                        this.optMiniTeasersMap.clear();
                    }
                    response.teasers.forEach((item) => {
                        this.optMiniTeasersMap.set(item.path, item.contents);
                    });
                    this.mercuryBgImage = response.mercuryBgImage;
                    this.optMiniTeasersMapPublisher.next(true);
                },
                (error) => {
                    this.logger.error('Unable to retrieve data from optMiniTeaser API: ' + error);
                },
            );
        }
    }

    getOptMiniTeasers(path: string) {
        return this.optMiniTeasersMap.get(path);
    }
}

class OptMiniTeasersResponse {
    path: string;
    contents: any;
}

interface IOptMiniTeasersResponse {
    teasers: Array<OptMiniTeasersResponse>;
    mercuryBgImage: any;
}
