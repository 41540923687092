import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class QuickInfoModalService {
    private dataSource = new BehaviorSubject<any>(null);
    gameDataObservable = this.dataSource.asObservable();

    constructor() {}

    setQuickInfoModalData(message: any) {
        this.dataSource.next(message);
    }

    getQuickInfoModalData() {
        return this.dataSource;
    }
}
