import { Component, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { addClass, getClientPosition, removeClass } from '@casinocore/platform/utils';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GameShowHubService } from '../../shared/game-show-hub.service';
import { CasinoCoreGamelaunchService } from '../../shared/gamelaunch.service';
import { ImmersiveLobbyService } from '../../shared/immersive-lobby.service';
import { LiveCasinoLoungeService } from '../../shared/live-casino-lounge.service';
import { LottieAnimationStatus } from '../../shared/models/common.model';

@Component({
    selector: 'cc-audio-button',
    templateUrl: 'casino-audio-button.component.html',
    standalone: true,
})
export class CasinoAudioButtonComponent implements OnInit, OnDestroy {
    @ViewChild('casinoAudio', { static: false }) casinoAudio: ElementRef<HTMLAudioElement>;
    @ViewChild('casinoAudioButton', { static: false }) casinoAudioButton: ElementRef<HTMLDivElement>;

    @Input() audioUrl: string;
    @Input() audioFrom: string;

    muted: boolean = true;
    audioStatus = 'mute';
    unsubscribe: Subject<CasinoAudioButtonComponent>;

    private audioOffsetTop: number = 0;

    constructor(
        private ngZone: NgZone,
        private gameShowHubService: GameShowHubService,
        private liveCasinoLoungeService: LiveCasinoLoungeService,
        private immersiveLobbyService: ImmersiveLobbyService,
        private casinoCoreGamelaunchService: CasinoCoreGamelaunchService,
    ) {
        this.unsubscribe = new Subject();
    }

    ngOnInit(): void {
        this.handleAudio();
    }

    @HostListener('window:scroll')
    onScroll(): void {
        if (this.casinoAudioButton?.nativeElement) {
            const footer = document.getElementsByTagName('vn-footer');
            const responsiveFooter = document.getElementsByTagName('vn-responsive-footer');
            if (footer?.length || responsiveFooter?.length) {
                const footerPosition = getClientPosition((footer?.length ? footer[0] : responsiveFooter[0]) as HTMLElement);
                const audioButtonPosition = getClientPosition(this.casinoAudioButton.nativeElement);
                this.audioOffsetTop = this.audioOffsetTop || Math.floor(audioButtonPosition.top + this.casinoAudioButton.nativeElement.clientHeight);
                footerPosition.top = Math.floor(footerPosition.top);
                if (footerPosition.top <= this.audioOffsetTop) addClass('item-hidden', this.casinoAudioButton.nativeElement);
                else removeClass('item-hidden', this.casinoAudioButton.nativeElement);
            }
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next(this);
        this.unsubscribe.complete();
        this.stopAudio();
    }

    onPlay(): void {
        if (this.muted) {
            this.muted = false;
            this.audioStatus = 'unmute';
            this.casinoAudio?.nativeElement?.play();
        } else {
            this.muted = true;
            this.audioStatus = 'mute';
            this.casinoAudio?.nativeElement?.pause();
        }
        this.audioFrom === 'game show'
            ? this.gameShowHubService.trackEvent(
                  'Event.Tracking',
                  'lobby',
                  'game show',
                  'click',
                  'not applicable',
                  'immersive lobby',
                  this.muted ? 'mute' : 'unmute',
                  'not applicable',
              )
            : this.audioFrom === 'live lounge'
              ? this.liveCasinoLoungeService.trackEvent(
                    'Event.Tracking',
                    'lobby',
                    'live lounge',
                    'click',
                    'not applicable',
                    'live casino lounge',
                    this.muted ? 'mute' : 'unmute',
                    'not applicable',
                )
              : this.immersiveLobbyService.trackEvent(
                    'Event.Tracking',
                    'lobby',
                    'immersive lobby',
                    'click',
                    'not applicable',
                    'immersive lobby',
                    this.muted ? 'mute' : 'unmute',
                    'not applicable',
                );
    }

    private stopAudio(): void {
        this.casinoAudio?.nativeElement?.pause();
        this.muted = true;
    }

    private handleAudio(): void {
        combineLatest([
            this.liveCasinoLoungeService.lottieAnimationStatusObservable,
            this.casinoCoreGamelaunchService.stopAudioObservable,
            this.gameShowHubService.lottieAnimationStatusObservable,
            this.immersiveLobbyService.lottieAnimationStatusObservable,
        ])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((results: any) => {
                const isStopped = results[1] as boolean | null;
                if (isStopped) this.stopAudio();
                else if (isStopped === false && this.audioStatus === 'unmute') {
                    this.casinoAudio?.nativeElement?.play();
                    this.muted = false;
                } else {
                }
                const liveLoungeLottieStatus = results[0] as LottieAnimationStatus;
                const gameShowLottieStatus = results[2] as LottieAnimationStatus;
                const immersiveLobbyLottieStatus = results[2] as LottieAnimationStatus;
                this.ngZone.runOutsideAngular(() => {
                    if (
                        liveLoungeLottieStatus === LottieAnimationStatus.Pause ||
                        gameShowLottieStatus === LottieAnimationStatus.Pause ||
                        immersiveLobbyLottieStatus === LottieAnimationStatus.Pause
                    ) {
                        this.casinoAudio?.nativeElement?.pause();
                        return;
                    } else if (
                        liveLoungeLottieStatus === LottieAnimationStatus.Play ||
                        gameShowLottieStatus === LottieAnimationStatus.Play ||
                        immersiveLobbyLottieStatus === LottieAnimationStatus.Play
                    ) {
                        this.casinoAudio?.nativeElement?.play();
                        return;
                    }
                });
            });
    }
}
