<div id="sticky-container" class="sticky-container casino-lobby-width-gametile">
    <div
        [ngClass]="setSubCategoryStyle()"
        style.overflow-x="{{ isTouch == false ? 'hidden' : 'auto' }}"
        [class.non-touch-scrollable-games]="!isTouch && setSubCategoryStyle() == 'scrollable-games'"
        (scroll)="scrollGYML()">
        <div *ngIf="isTouch && unfinishedFavANDGYMLGames" [ngClass]="{ 'header-static-div': staticGYML }">
            <div class="base-category" [class.casino-base-category-nontouch]="!isTouch">
                <div class="casino-allGames">
                    <div class="gameContainers" *ngIf="favouriteCategory && !gymlCategoryRef">
                        <div>
                            <div
                                class="casino-no-games-box"
                                *ngIf="zeroFavourite"
                                [ngClass]="{ 'casino-no-games-box-touch-1350': isTouch }"
                                [ngStyle]="{
                                    'margin-top': isTouch ? '0' : '20px',
                                    'border-color': WidgetFrameClr,
                                    'color': WidgetDefaultMsgTxtColor
                                }">
                                <span>
                                    {{ noFavouritesIcon }}
                                </span>
                            </div>
                            <div
                                *ngFor="let game of unfinishedFavANDGYMLGames; let i = index"
                                class="casino-rcntly-gyml-game-touch"
                                [hidden]="!game.isFavourite">
                                <div
                                    [ngClass]="assignRecommendedWidgetColors(game)"
                                    [ngStyle]="{ bottom: isTouch ? '2' : '0' }"
                                    [style.borderColor]="recommendedGamesBackgroundColor">
                                    <cc-cozy-bingo-game
                                        *ngIf="game.fromCozybingo"
                                        [roomInfo]="game"
                                        [gamePosition]="i"
                                        [gameLaunchedFrom]="gameLaunchedFromLoc"
                                        [product]="'rpWidgetFAV'"
                                        [lobbyType]="lobbyType"
                                        [widgetFrameClr]="WidgetBackgroundClr">
                                    </cc-cozy-bingo-game>
                                    <cc-game-lobby
                                        [ngClass]="game.infoIconClass"
                                        [game]="game"
                                        [gamePosition]="i"
                                        *ngIf="!game.fromCozybingo && !game.isDataNotAvailable && favouriteCategory"
                                        [class.casino-recentlyPromo]="game.isFavourite"
                                        [category]="favouriteCategory"
                                        [widgetFrameClr]="WidgetBackgroundClr"
                                        [gameLaunchedFrom]="gameLaunchedFromLoc"
                                        [product]="'rpWidgetFAV'">
                                    </cc-game-lobby>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="gameContainers mrgn-lft-cls" *ngIf="favouriteCategory && gymlCategoryRef">
                        <div>
                            <div
                                class="casino-no-games-box"
                                *ngIf="zeroFavourite"
                                [ngClass]="{ 'casino-no-games-box-touch-1350': isTouch }"
                                [ngStyle]="{
                                    'margin-top': isTouch ? '0' : '20px',
                                    'border-color': WidgetFrameClr,
                                    'color': WidgetDefaultMsgTxtColor
                                }">
                                <span>
                                    {{ noFavouritesIcon }}
                                </span>
                            </div>
                            <div *ngFor="let game of unfinishedFavANDGYMLGames; let i = index" class="casino-rcntly-gyml-game-touch">
                                <div
                                    [ngClass]="assignRecommendedWidgetColors(game)"
                                    [ngStyle]="{ bottom: isTouch ? '2' : '0' }"
                                    [style.borderColor]="recommendedGamesBackgroundColor">
                                    <cc-cozy-bingo-game
                                        *ngIf="game.fromCozybingo"
                                        [roomInfo]="game"
                                        [gamePosition]="i"
                                        [gameLaunchedFrom]="gameLaunchedFromLoc"
                                        [product]="'rpWidgetFAV'"
                                        [lobbyType]="lobbyType"
                                        [widgetFrameClr]="WidgetBackgroundClr">
                                    </cc-cozy-bingo-game>
                                    <cc-game-lobby
                                        [ngClass]="game.infoIconClass"
                                        [widgetFrameClr]="WidgetBackgroundClr"
                                        [class.casino-recentlyPromo]="game.isFavourite"
                                        [game]="game"
                                        [gamePosition]="i"
                                        *ngIf="!game.fromCozybingo && !game.isDataNotAvailable && gymlCategoryRef"
                                        [category]="gymlCategoryRef"
                                        [gameLaunchedFrom]="gameLaunchedFromLoc">
                                    </cc-game-lobby>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!isTouch && unfinishedFavANDGYMLGames.length">
            <div class="base-category" [class.casino-base-category-nontouch]="!isTouch">
                <div
                    class="casino-no-games-box"
                    *ngIf="zeroFavourite"
                    [ngStyle]="{ 'border-color': WidgetFrameClr, 'color': WidgetDefaultMsgTxtColor }">
                    <span>
                        {{ noFavouritesIcon }}
                    </span>
                </div>
                <div class="casino-fav-gyml-carousel-wrapper" [ngClass]="{ 'fav-gyml-zeroFavourite': zeroFavourite }">
                    <vn-swiper #favouriteGYMLCarousel [swiperOptions]="swiperOptions">
                        <div class="swiper-slide" *ngFor="let game of unfinishedFavANDGYMLGames; let i = index">
                            <div class="game item">
                                <div class="game">
                                    <div [ngClass]="assignRecommendedWidgetColors(game)" [style.borderColor]="recommendedGamesBackgroundColor">
                                        <cc-cozy-bingo-game
                                            *ngIf="game.fromCozybingo"
                                            [roomInfo]="game"
                                            [gamePosition]="i"
                                            [gameLaunchedFrom]="gameLaunchedFromLoc"
                                            [product]="'rpWidgetFAV'"
                                            [lobbyType]="lobbyType"
                                            [widgetFrameClr]="WidgetBackgroundClr">
                                        </cc-cozy-bingo-game>
                                        <cc-game-lobby
                                            [ngClass]="game.infoIconClass"
                                            [widgetFrameClr]="WidgetBackgroundClr"
                                            *ngIf="!game.fromCozybingo && !game.isDataNotAvailable && game.isFavourite"
                                            [game]="game"
                                            [gamePosition]="i"
                                            [class.casino-recentlyPromo]="game.isFavourite"
                                            [category]="favouriteCategory"
                                            [gameLaunchedFrom]="gameLaunchedFromLoc"
                                            [product]="'rpWidgetFAV'">
                                        </cc-game-lobby>
                                        <cc-game-lobby
                                            [ngClass]="game.infoIconClass"
                                            [class.casino-recentlyPromo]="game.isFavourite"
                                            *ngIf="!game.fromCozybingo && !game.isDataNotAvailable && !game.isFavourite"
                                            [game]="game"
                                            [gamePosition]="i"
                                            [category]="gymlCategoryRef"
                                            [widgetFrameClr]="WidgetBackgroundClr"
                                            [gameLaunchedFrom]="gameLaunchedFromLoc"
                                            [product]="'rpWidgetFAV'"></cc-game-lobby>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vn-swiper>
                </div>
            </div>
        </div>
    </div>
</div>
