import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyFormat',
    standalone: true,
})
export class CurrencyFormatPipe implements PipeTransform {
    transform(value: any): number {
        let decimal: any;
        // Convert it into a string
        const string = value.toString();

        // Split the dot
        const array = string.split('.');
        // Get both numbers
        // The '+' sign transforms the string into a number again
        const amount = array[0];
        if (array[1]) {
            decimal = array[1]?.length == 2 ? array[1] : array[1].length == 1 ? '0' + array[1] : '00';
        }

        const final: any = Number(amount).toLocaleString() + '.' + (decimal ? decimal : '00');

        return final;
    }
}
