import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csPlayerStatsConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: playerStatsWidgetClientConfigFactory,
})
export class PlayerStatsWidgetClientConfig {
    readonly enablePlayerstats: boolean;
    readonly playerStatFilters: Array<{ readonly name: string; readonly value: string }>;
    readonly playerStatsWidgetImagesContent: PlayerStatsWidgetImagesContent;
    readonly playerStatsSitecoreData: PlayerStatsSitecoreData;
    readonly playerStatsInterval: number;
    readonly minimumMuliplierBet: number;
    readonly enableTitleOnGameIcon: boolean;
}

export interface PlayerStatsWidgetImagesContent {
    readonly backgroundImageLayer1: string;
    readonly backgroundImageLayer2: string;
    readonly backgroundImageLayer3: string;
    readonly playerStatsWidgetMultiplier: any;
}
export interface PlayerStatsSitecoreData {
    readonly playerStatsWidgetSubtitleConfigs: any;
    readonly playerStatsWidgetFilterNameConfigs: any;
    readonly playerStatsWidgetTitleConfigs: any;
    readonly displayFilterNameColor: string;
    readonly titleTextColor: string;
    readonly subtitleTextColor: string;
    readonly statsWigetCarousalIconBgColor: string;
    readonly multiplierColor: string;
    readonly filterNameBgColor: string;
    readonly multiplierBorderColor: string;
    readonly multiplierBgColor: string;
    readonly filterCarousalIconBgColor: string;
    readonly multiplierComponent: string;
    readonly displayfilterNamefontsize: any;
    readonly displayfilterNamefontweight: string;
    readonly filterTextfontSize: string;
    readonly filterTextfontweight: string;
    readonly subtitlefontsize: string;
    readonly subtitlefontweight: string;
    readonly titlefontSize: string;
    readonly titlefontweight: string;
    readonly filterTextColor: string;
    readonly statsWigetCarousalIconColor: string;
    readonly filterCarousalIconColor: string;
    readonly mobileDisplayfilterNamefontsize: string;
    readonly mobileDisplayfilterNamefontweight: string;
    readonly mobileSubtitlefontsize: string;
    readonly mobileSubtitlefontweight: string;
    readonly mobileTitlefontSize: string;
    readonly mobileTitlefontweight: string;
}

export function playerStatsWidgetClientConfigFactory(service: LazyClientConfigService) {
    return service.get(PlayerStatsWidgetClientConfig);
}
