import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlightsearch',
    standalone: true,
})
export class HighlightSearch implements PipeTransform {
    transform(value: string, args: any): string {
        let pattern = args.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
        pattern = pattern
            .split(' ')
            .filter((t: any) => {
                return t.length > 0;
            })
            .join('|');
        const regex = new RegExp(pattern, 'gi');
        return args ? value.replace(regex, (match) => '<pre>' + match + '</pre>') : value;
    }
}
