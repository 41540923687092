import { NgClass, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';

import {
    CasinoLobbyService,
    ConfigProviderService,
    ConstService,
    FavouriteService,
    InvokerProductService,
    RecentGamesService,
    UrlUtilityService,
} from '@casinocore/platform/core';
import { DeviceService, NavigationService, Page, TrackingService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cc-bottom-navbar',
    templateUrl: 'bottom-navbar.component.html',
    standalone: true,
    imports: [NgClass, NgFor, NgIf],
})
export class BottomNavbarComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() lobbyType: string;

    navBarCategories: any;
    isIOS: boolean;
    currentLang: string;
    product: string;
    categoryHeaderForBottomNav: string;
    recentGamesFeedSubscription: Subscription;
    favouriteGamesFeedSubscription: Subscription;
    navBarUnfilteredCategories: any;

    constructor(
        private configProviderService: ConfigProviderService,
        private casinoLobbyService: CasinoLobbyService,
        private deviceService: DeviceService,
        private trackingService: TrackingService,
        private page: Page,
        private navigation: NavigationService,
        private urlUtilityService: UrlUtilityService,
        private recentGameService: RecentGamesService,
        private favouriteService: FavouriteService,
        private invokerProductService: InvokerProductService,
    ) {}

    ngOnInit() {
        const globalConfig = this.configProviderService.provideGlobalConfig();
        this.isIOS = this.deviceService.isiOS;
        this.currentLang = this.page.lang;
        this.product = 'games';
        const invokerProduct = 'CASINO';
        this.lobbyTypeForNavCategories(invokerProduct);
        this.categoryHeaderForBottomNav = globalConfig.messages.XSellOverlayBottomNavCategoryHeader;
        this.recentGamesFeedSubscription = this.recentGameService.recentGamesFeedObservable.subscribe((response: any) => {
            if (response) {
                this.navBarCategories = this.filterNavCategories(this.navBarUnfilteredCategories);
            }
        });
        this.favouriteGamesFeedSubscription = this.favouriteService.favouriteGamesFeedObservable.subscribe((response: any) => {
            if (response) {
                this.navBarCategories = this.filterNavCategories(this.navBarUnfilteredCategories);
            }
        });
    }

    ngAfterViewInit() {
        const overlayConfig: any = this.configProviderService.provideCasinoAsIFrameConfig();
        const bottomHeader = document.getElementById('bottom-category-header');
        if (overlayConfig.enableSearch) {
            bottomHeader!.style.marginBottom = '100px';
        } else {
            bottomHeader!.style.marginBottom = '';
        }
    }

    ngOnDestroy() {
        if (this.recentGamesFeedSubscription) {
            this.recentGamesFeedSubscription.unsubscribe();
        }
        if (this.favouriteGamesFeedSubscription) {
            this.favouriteGamesFeedSubscription.unsubscribe();
        }
    }

    lobbyTypeForNavCategories(invokerProduct: string) {
        const overlayConfig: any = this.configProviderService.provideCasinoAsIFrameConfig();
        let navLobbyFromConfig: string = '';
        if (overlayConfig && overlayConfig.overlayBottomNavLobbyTypeMap && overlayConfig.overlayBottomNavLobbyTypeMap.length > 0) {
            const overlayBottomNavLobbyTypeMap = overlayConfig.overlayBottomNavLobbyTypeMap.filter(
                (l: any) => l?.invokerProduct?.toLowerCase() === this.invokerProductService?.getInvokerProduct()?.toLowerCase(),
            );

            for (let i = 0; i < overlayBottomNavLobbyTypeMap.length; i++) {
                navLobbyFromConfig = overlayBottomNavLobbyTypeMap[i].lobbyType;
                this.navBarUnfilteredCategories = this.casinoLobbyService.getNavCategoriesForInvokerProduct(
                    overlayBottomNavLobbyTypeMap[i].lobbyType,
                );
                this.navBarCategories = this.filterNavCategories(this.navBarUnfilteredCategories);
            }
            if (this.navBarCategories && this.navBarCategories.length <= 0) {
                this.casinoLobbyService.setCasinoLobbyForInvokerProduct(navLobbyFromConfig, invokerProduct);
                this.casinoLobbyService.lobbyLoadedObservableForInvokerProduct.subscribe((lobbyLoadEvent) => {
                    if (lobbyLoadEvent) {
                        for (let j = 0; j < overlayBottomNavLobbyTypeMap.length; j++) {
                            this.navBarUnfilteredCategories = this.casinoLobbyService.getNavCategoriesForInvokerProduct(
                                overlayBottomNavLobbyTypeMap[j].lobbyType,
                            );
                            this.navBarCategories = this.filterNavCategories(this.navBarUnfilteredCategories);
                        }
                    }
                });
            }
        }
    }

    filterNavCategories(navCategories: any): any {
        return this.fetchCategories(navCategories);
    }

    fetchCategories(navCategories: any): any {
        const tempCategories: any = [];
        if (navCategories && navCategories.length > 0) {
            navCategories.forEach((category: any) => {
                if (
                    category?.categoryid?.toLowerCase() !== ConstService.FAVOURITE_GAMES_CATEGORY_ID?.toLowerCase() &&
                    category?.categoryid?.toLowerCase() !== ConstService.RECENT_GAMES_CATEGORY_ID?.toLowerCase() &&
                    category.gamelist &&
                    category.gamelist.length > 0
                ) {
                    tempCategories.push(category);
                }
            });
        }
        return tempCategories;
    }

    navItemClick($event: any, index: number, categoryId: string, url: string) {
        $event.preventDefault();
        const bottomNavBarCategoryBaseUrl = this.urlUtilityService?.getCategoryNavigationBaseUrl();
        const bottomNavBarCategoryUrl: string = bottomNavBarCategoryBaseUrl + url;
        this.trackingService.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Sports Gaming Overlay',
            'component.LabelEvent': 'Gaming Overlay on Sports Lobby',
            'component.ActionEvent': 'Gaming Overlay - ' + categoryId + ' - Click',
            'component.PositionEvent': index + 1 + ' - position of the category',
            'component.LocationEvent': 'Gaming Overlay - SubNav',
            'component.EventDetails': 'User taps on any Sub-Nav category',
            'component.URLClicked': bottomNavBarCategoryUrl,
        });
        //checking navigation
        if (bottomNavBarCategoryUrl) {
            this.navigation.goTo(bottomNavBarCategoryUrl);
        }
    }
}
