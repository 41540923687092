import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csSearchConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: searchClientConfigFactory,
})
export class SearchClientConfig {
    CategoryGamesSearchCriteria: any;
    EnableCategorySearchCriteria: any;
}

export function searchClientConfigFactory(service: LazyClientConfigService) {
    return service.get(SearchClientConfig);
}
