import { NgClass, NgIf } from '@angular/common';
import { Component, HostListener, Input, OnInit, computed, effect, input, signal } from '@angular/core';

import { ArcadeLobbyTrackingService, ConfigProviderService, TeaserService, UrlUtilityService } from '@casinocore/platform/core';
import { OptTeasersComponent } from '@casinocore/platform/opt-teasers';
import { OptTeasersNpmComponent } from '@casinocore/platform/opt-teasers-npm';
import { TeasersNpmComponent } from '@casinocore/platform/teasers-npm';
import { DeviceService, NavigationService } from '@frontend/vanilla/core';

import { TeasersComponent } from '../casino-teasers/teasers.component';

@Component({
    selector: 'cc-teasers-wrapper',
    templateUrl: 'teasers-wrapper.component.html',
    standalone: true,
    imports: [NgIf, OptTeasersComponent, NgClass, TeasersComponent, TeasersNpmComponent, OptTeasersNpmComponent],
})
export class TeasersWrapperComponent implements OnInit {
    isOptimizedTeaserEnabled: boolean;
    @Input() teaserCategory: any;
    @Input() teaserLocation: any;
    @Input() teasersSource: any;
    @Input() optTeasersSource: any;
    @Input() identifierClass?: any;
    @Input() isDefaultCategory?: boolean;
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    //added for NPM teasers
    @Input() enableTeaserOptimization: boolean;
    @Input() teaserDynaconConfigs: any;
    globalTeaserSettings = input<any>();
    @Input() enableMiniTeasers: boolean;
    isNPM = input<boolean>();
    @Input() optminiTeaserSource: any;
    @Input() mercuryBgImage: any;
    @Input() enablePlayBingoButton: boolean;
    @Input() bingocta: any;
    @Input() isNewSportsNative: any;
    @Input() showJackpotCurrencyOnRight: boolean;
    bgGlobalConfig = input<any>();
    @Input() jackpotGroupNameMapTeaserNPM: any;
    globalTeaserStyles = input<any>();
    @Input() arcadeBannerIdentifier: string;
    isTouch: boolean;
    teaserHeight: number;
    readonly displayHeight = signal<number | undefined>(undefined);
    optimizedTeaserClientConfig: any;
    enableAluminiumTeasers: boolean;
    enableFixedWidthAluminiumTeasers: boolean;
    Modeset: any;
    TeaserMode: any;
    categoryNavTeaserMode: any;
    subCategoryTeaserMode: any;
    isOptMiniTeasers: boolean;
    readonly optTeasers = this.teaserService.headerTeasers;
    optMiniTeasersAvialabe: boolean;
    readonly showTimer = computed(() => this.teaserSettings()?.sharedList.showTimer?.toLowerCase() === 'true');
    enableAluminumTeaserSwiperHeight: boolean;
    isArcadeLobby: boolean = false;
    readonly isMobileView = computed(() => this.deviceService.windowRect().width < 768);
    readonly provideGlobalConfig = signal<any | undefined>(undefined);
    readonly arcadeGlobalMessages = computed<any | undefined>(() => this.provideGlobalConfig()?.arcadeLobbyConfigurations);
    readonly teaserSettings = computed(() => (this.isNPM() ? this.globalTeaserSettings() : this.optTeasers()?.globalTeaserSettings));
    readonly teaserStyles = computed(() => (this.isNPM() ? this.globalTeaserStyles() : this.optTeasers()?.globalTeaserStyles));
    readonly globalConfig = computed(() => (this.isNPM() ? this.bgGlobalConfig() : this.provideGlobalConfig()));

    constructor(
        private configProviderService: ConfigProviderService,
        private teaserService: TeaserService,
        private urlUtilityService: UrlUtilityService,
        private navigation: NavigationService,
        private deviceService: DeviceService,
        private arcadeLobbyTrackingService: ArcadeLobbyTrackingService,
    ) {
        effect(() => {
            const teaserCss = this.globalTeaserStyles()?.css;
            if (teaserCss) {
                const style = document.createElement('style');
                style.textContent = teaserCss;
                document.head.appendChild(style);
            }
        });
    }

    ngOnInit() {
        this.provideGlobalConfig.set(this.configProviderService.provideGlobalConfig());
        this.isArcadeLobby = this.configProviderService.provideGameConfig()?.arcadeLobbyType.toLowerCase() == this.lobbyType.toLowerCase();
        //added for NPM teasers
        this.optimizedTeaserClientConfig = this.isNPM() ? this.teaserDynaconConfigs : this.configProviderService.provideOptimizedTeaserConfig();
        this.isOptimizedTeaserEnabled = this.isNPM() ? this.enableTeaserOptimization : this.optimizedTeaserClientConfig.teaserOptimization;
        this.TeaserMode = this.isNPM() ? this.globalConfig().TeaserMode : this.globalConfig()?.messages?.TeaserMode;
        this.subCategoryTeaserMode = this.isNPM() ? this.globalConfig().subCategoryTeaserMode : this.globalConfig()?.messages?.subCategoryTeaserMode;
        this.categoryNavTeaserMode = this.isNPM() ? this.globalConfig().categoryNavTeaserMode : this.globalConfig()?.messages?.categoryNavTeaserMode;
        this.isOptMiniTeasers = this.isNPM() ? this.enableMiniTeasers : this.optimizedTeaserClientConfig.enableOptMiniTeasers;
        this.enableAluminumTeaserSwiperHeight =
            this.globalTeaserSettings()?.sharedList.enableAluminumTeaserSwiperHeight?.toLowerCase() == 'true' ? true : false;
        this.enableAluminiumTeasers = this.optimizedTeaserClientConfig.enableAluminiumTeaser;
        this.enableFixedWidthAluminiumTeasers = this.optimizedTeaserClientConfig.enableFixedWidthAluminiumTeaser;
        this.isTouch = this.deviceService.isTouch;
        //added for NPM teasers
        this.updateTeaserSkeletonHeight();
    }
    @HostListener('window:resize') onResize() {
        setTimeout(() => {
            this.updateTeaserSkeletonHeight();
        }, 100);
    }
    getOptMiniTeasersEvent(optMiniTeasersAvialable: boolean) {
        if (!this.optMiniTeasersAvialabe && optMiniTeasersAvialable) {
            this.displayHeight.update((height) => (height || 0) + 80);
        }
        this.optMiniTeasersAvialabe = optMiniTeasersAvialable;
    }

    takeMeStoreClickEvent() {
        this.trackTakeMeStoreEvent();
        this.navigation.goTo(`${this.urlUtilityService.getPromoSmartUrl()}/${this.arcadeGlobalMessages()?.StoreUrl}`);
    }

    trackTakeMeStoreEvent() {
        this.arcadeLobbyTrackingService.trackEvent(
            'Event.Tracking',
            'coinArcade',
            'immersive lobby',
            'click',
            'top right corner',
            'coin Arcade',
            'coin shop',
            'not applicable',
        );
    }
    updateTeaserSkeletonHeight() {
        if (this.isOptimizedTeaserEnabled) {
            const windowWidth = this.deviceService.windowWidth();
            if (this.enableFixedWidthAluminiumTeasers) {
                if (windowWidth <= 570) {
                    this.teaserHeight = this.optimizedTeaserClientConfig.setAluminiumMobileTeaserHeight;
                } else if (windowWidth <= 1024) {
                    this.teaserHeight = this.optimizedTeaserClientConfig.setAluminiumTabletTeaserHeight;
                } else {
                    this.teaserHeight = this.optimizedTeaserClientConfig.setAluminiumDesktopTeaserHeight;
                }
            } else {
                if (this.isMobileView()) {
                    if (this.enableAluminiumTeasers) {
                        this.teaserHeight = this.optimizedTeaserClientConfig.setAluminiumMobileTeaserHeight;
                    } else {
                        this.teaserHeight = this.optimizedTeaserClientConfig.mobileViewTeaserHeight;
                    }
                } else if (this.deviceService.windowRect().width >= 768 && this.deviceService.windowRect().width <= 1024) {
                    if (this.enableAluminiumTeasers) {
                        this.teaserHeight = this.optimizedTeaserClientConfig.setAluminiumTabletTeaserHeight;
                    } else {
                        this.teaserHeight = this.optimizedTeaserClientConfig.tabletViewTeaserHeight;
                    }
                } else {
                    if (this.enableAluminiumTeasers) {
                        this.teaserHeight = this.optimizedTeaserClientConfig.setAluminiumDesktopTeaserHeight;
                    } else {
                        this.teaserHeight = this.optimizedTeaserClientConfig.desktopViewTeaserHeight;
                    }
                }
            }
            this.displayHeight.set(this.teaserHeight);
            if (this.showTimer()) {
                this.displayHeight.update((height) => (height || 0) + (this.isMobileView() ? 3 : 6));
            }
            if (this.enableAluminiumTeasers) {
                this.displayHeight.update((height) => (height || 0) + 32);
            }
        } else {
            if (this.teaserLocation === 'Top') {
                this.Modeset = this.TeaserMode;
            } else if (this.teaserLocation === 'SubNavigation') {
                this.Modeset = this.categoryNavTeaserMode;
            } else {
                this.Modeset = this.subCategoryTeaserMode;
            }
        }
    }
}
