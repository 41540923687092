import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ConfigProviderService } from '@casinocore/platform/core';

@Component({
    selector: 'cc-freespin-summary',
    templateUrl: 'freespins-summary.component.html',
    standalone: true,
    imports: [NgIf, NgClass],
})
export class FreeSpinsSummaryComponent implements OnInit {
    @Input() freespinAvailableCount: any;
    @Input() freespinTotalCount: any;
    @Input() isOptedIn: any;
    @Output() freespinsInfo = new EventEmitter<any>();
    enableFreeSpinSummary: boolean;
    isSpinShow: boolean;
    isSpinDefaultShow: boolean;
    freespinscountbg: string;
    isThreeDigit: boolean;
    constructor(private configProviderService: ConfigProviderService) {}
    ngOnInit() {
        this.enableFreeSpinSummary = this.configProviderService.providefreeSpinsClientConfig().isFreeSpinSummaryEnabled;
        this.freespinscountbg = this.configProviderService.provideGlobalConfig().freespinsCountBackgroundColor;
        this.isThreeDigit = this.freespinAvailableCount > 99 || this.freespinTotalCount > 99;
    }
    onFreeSpinClick(event: any) {
        this.freespinsInfo.emit(event);
    }
}
