import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    CookieService,
    DeviceService,
    DslService,
    Logger,
    NativeAppService,
    NavigationService,
    Page,
    UserLoggingOutEvent,
    UserLoginEvent,
    UserService,
} from '@frontend/vanilla/core';
import { GamificationService } from '@frontend/vanilla/features/gamification';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { GeolocationActivatorService } from '../geolocation/services/activator.service';
import { GeolocationCoordinatorService } from '../geolocation/services/coordinator.service';
import { PlatformApiService } from '../platform-api/platform-api.service';
import { ApiPathsHelperService } from '../shared/api-paths-helper-service';
import { CacheManagerService } from '../shared/cache-manager.service';
import { CasinoCoreNpmParamsService } from '../shared/casino-core-npm-params.service';
import { CasinoIFrameOverlayService } from '../shared/casino-iframe-overlay.service';
import { CasinoManager } from '../shared/casino-manager.service';
import { ConstService } from '../shared/const.service';
import { CozyBingoDataService } from '../shared/cozybingodata.service';
import { DiscoverFeedService } from '../shared/discover-feed.service';
import { FastLoadingGamesService } from '../shared/fast-loading-games.service';
import { FavouriteService } from '../shared/favourite.service';
import { FreespinSummaryService } from '../shared/freespins-summary.service';
import { GamePremiereService } from '../shared/game-premiere.service';
import { GameSelectorWidgetService } from '../shared/game-selector-widget.service';
import { InvokerProductService } from '../shared/invoker-product.service';
import { JackpotDataService } from '../shared/jackpotdata-service';
import { LiveCasinoService } from '../shared/livecasino.service';
import { CasinoCoreNpmParams } from '../shared/models/casino-core-npm.params';
import { GameLobbyModel } from '../shared/models/game-lobby.model';
import { NewsFeedService } from '../shared/news-feed.service';
import { PlayerSegmentationService } from '../shared/player-segmentation.service';
import { PromotedGamesService } from '../shared/promoted-games.service';
import { PromotionsBannerService } from '../shared/promotions-banner.service';
import { RecentGamesService } from '../shared/recent-games.service';
import { TeaserService } from '../shared/teaser.service';
import { ThumbnailService } from '../shared/thumbnail.service';
import { UnfinishedGamesService } from '../shared/unfinished-games-service';
import { WindowRef } from '../shared/windowref.service';
import { SlotRacesService } from '../slot-races/shared/slot-races.service';
import { UrlUtilityService } from '../utilities/url-utility.service';
import { LobbyItem } from './casino-lobby.models';

@Injectable({
    providedIn: 'root',
})
export class CasinoLobbyService {
    lobbySitecoreData: any;
    lobbySitecoreDataForInvokerProduct: any;
    lobbyTypeMaps: Map<string, any> = new Map<string, any>();
    gJLobbyTypeMaps: Map<string, any> = new Map<string, any>();
    lobbyTypeMapsForInvokerProduct: Map<string, any> = new Map<string, any>();
    casinoConfig: any;
    stickersDataSubject = new BehaviorSubject<any>(null);
    stickersDataObservable: Observable<any> = this.stickersDataSubject.asObservable();
    lobbyLoadedSubject = new BehaviorSubject<any>(null);
    lobbyLoadedSubjectForInvokerProduct = new BehaviorSubject<any>(null);
    gameMetaDataMap: Map<string, any> = new Map<string, any>();
    lobbyLoadedObservable: Observable<any> = this.lobbyLoadedSubject.asObservable();
    lobbyLoadedObservableForInvokerProduct: Observable<any> = this.lobbyLoadedSubjectForInvokerProduct.asObservable();
    disabledGames: any;
    //casinoNpmParams: CasinoCoreNpmParams;
    isTouchDevice: boolean;
    gameDataFromLMT: any = [];
    private forceGameLaunchBehaviourSubject = new BehaviorSubject<any>(null);
    coinBalancePublisher = new BehaviorSubject<any>(null);
    coinBalanceObservable: Observable<any> = this.coinBalancePublisher.asObservable();
    forceGameLaunchObservable: Observable<boolean> = this.forceGameLaunchBehaviourSubject.asObservable();
    disjointFeedsInitiated: boolean;
    categoryNotFoundSubject = new BehaviorSubject<any>(null);
    categoryNotFoundObservable: Observable<any> = this.categoryNotFoundSubject.asObservable();
    hideSlotRacesTitlePublisher = new BehaviorSubject<boolean>(false);
    hideSlotRacesTitleObs: Observable<any> = this.hideSlotRacesTitlePublisher.asObservable();
    GJpathSegment: string;
    optMiniTeasersSource: Array<string> = [];
    postLoginFeedsInitiated: boolean = false;
    gameSelectorWidgetSource: Array<string> = [];
    lmtConfig: any;
    rpfavandunfinishedcalled: boolean;
    isNewMoreInfoActive: boolean = false;
    private lobbyLoadEventTracked: boolean = false;
    postloginfirstTeaserCall: boolean = false;
    postloginfirstJpSortCall: boolean = false;
    postLoginFirstSlotRaceCall: boolean = false;
    postloginfirstLiveCasinoCall: boolean = false;
    userCoinBalance: number;
    arcadeCoinExpiry: number;
    path: any = 'path';
    hideGlobalSearchBasedOnPath: boolean = false;
    gameMetaDataPlayerStatsSubject = new BehaviorSubject<any>(null);
    gameMetaDataPlayerStatsObservable: Observable<any> = this.gameMetaDataPlayerStatsSubject.asObservable().pipe(
        map((arr) => (arr ? arr.size : 0)),
        distinctUntilChanged(),
    );
    constructor(
        private api: PlatformApiService,
        private cacheManager: CacheManagerService,
        private configProviderService: ConfigProviderService,
        private casinoManager: CasinoManager,
        private user: UserService,
        private fastLoadingGamesService: FastLoadingGamesService,
        private nativeAppService: NativeAppService,
        private deviceService: DeviceService,
        private jackpotDataService: JackpotDataService,
        private recentGamesService: RecentGamesService,
        private page: Page,
        private urlUtility: UrlUtilityService,
        private teaserService: TeaserService,
        private favouriteService: FavouriteService,
        private unfinishedGamesService: UnfinishedGamesService,
        private invokerProductService: InvokerProductService,
        private discoverFeedService: DiscoverFeedService,
        private liveCasinoService: LiveCasinoService,
        private newsFeedService: NewsFeedService,
        private promotedGamesService: PromotedGamesService,
        private freespinSummaryService: FreespinSummaryService,
        private apiPathsHelperService: ApiPathsHelperService,
        private geolocationCoordinatorService: GeolocationCoordinatorService,
        private playerSegmentationService: PlayerSegmentationService,
        private geolocationActivator: GeolocationActivatorService,
        private slotRacesService: SlotRacesService,
        private route: ActivatedRoute,
        private cozybingoService: CozyBingoDataService,
        private cookies: CookieService,
        private thumbnailService: ThumbnailService,
        private dslService: DslService,
        private windowRef: WindowRef,
        private casinoCoreNpmParamsService: CasinoCoreNpmParamsService,
        private gameSelectorWidgetService: GameSelectorWidgetService,
        private casinoIFrameOverlayService: CasinoIFrameOverlayService,
        private promotionsBannerService: PromotionsBannerService,
        private logger: Logger,
        private gamificationService: GamificationService,
        private navigation: NavigationService,
        private gamePremiereService: GamePremiereService,
    ) {}

    onAppInit() {
        this.configProviderService.casinoClientConfigObservable.subscribe((isClientConfigLoaded) => {
            if (isClientConfigLoaded) {
                this.initializeGeolocationCoordinator();
                const casinoNpmParams = this.casinoCoreNpmParamsService.getcasinoCoreNpmParams();
                if (!casinoNpmParams.disableFullLobbyFetch) {
                    this.setCasinoLobby(true);
                }
                if (casinoNpmParams.disableFullLobbyFetch && casinoNpmParams.customLobbyTypeMaps && casinoNpmParams.customLobbyTypeMaps.length > 0) {
                    this.setPartialCasinoLobbiesforOtherProducts(casinoNpmParams.customLobbyTypeMaps, true, false);
                    this.configProviderService.setLmtConfig(casinoNpmParams.customLobbyTypeMaps);
                } else if (casinoNpmParams.disableFullLobbyFetch && casinoNpmParams.targetOverlayLobby) {
                    this.setPartialCasinoLobbiesForOverlay();
                }
                //load Coin Expiry
                this.arcadeCoinExpiry = this.calculateCoinArcadeExpiry();
            }
        });
        this.lobbyLoadedObservable.subscribe((lobbyLoadEvent) => {
            if (lobbyLoadEvent) {
                this.onLobbyLoaded(lobbyLoadEvent.initiateFeeds);
            }
        });
        this.user.events.pipe(filter((e: any) => e instanceof UserLoginEvent)).subscribe(() => {
            if (this.lobbyTypeMaps && this.lobbyTypeMaps.size > 0) {
                this.lobbyTypeMaps.clear();
            }
            if (this.gameMetaDataMap && this.gameMetaDataMap.size > 0) {
                this.gameMetaDataMap.clear();
            }
            this.postLoginCalls(false, true);
            this.initializeGeolocationCoordinator();
            this.rpfavandunfinishedcalled = false;
        });
        this.user.events.pipe(filter((e: any) => e instanceof UserLoggingOutEvent)).subscribe(() => {
            this.cookies.remove('PCGameData');
        });
        const pcdata = this.cookies.getObject('PCGameData');
        if (pcdata) {
            this.cookies.remove('PCGameData');
        }
    }
    isODR() {
        const nativeAppCookie = this.cookies.get('NativeApp');
        const isODR: boolean =
            this.nativeAppService?.applicationName?.toLowerCase() === 'casinowodr' || nativeAppCookie?.toLowerCase() === 'casinowodr';
        return isODR;
    }
    isSportsODR() {
        const nativeAppCookie = this.cookies.get('NativeApp');
        const isSportsODR: boolean =
            this.nativeAppService?.applicationName?.toLowerCase() === 'sportswodr' || nativeAppCookie?.toLowerCase() === 'sportswodr';
        return isSportsODR;
    }

    isPokerApp() {
        const nativeAppCookie = this.cookies.get('NativeApp');
        return nativeAppCookie?.toLowerCase() == 'poker';
    }

    setPartialCasinoLobbiesForOverlay() {
        const overlayConfig = this.configProviderService.provideCasinoAsIFrameConfig();
        this.casinoIFrameOverlayService.setOverlayStatus(true);
        const invokerProduct: string = this.invokerProductService.getInvokerProduct();
        if (overlayConfig && overlayConfig.overlayLobbyTypeMap && overlayConfig.overlayLobbyTypeMap.length > 0) {
            const applicableLobbyTypeMaps: any = overlayConfig.overlayLobbyTypeMap.filter(
                (l: any) => l?.invokerProduct?.toLowerCase() === invokerProduct?.toLowerCase(),
            );
            if (applicableLobbyTypeMaps && applicableLobbyTypeMaps.length > 0) {
                this.setPartialCasinoLobbiesforOtherProducts(applicableLobbyTypeMaps, true, false);
                this.configProviderService.setLmtConfig(applicableLobbyTypeMaps);
            }
        }
    }

    onLobbyLoaded(initiateDisjointFeeds: boolean) {
        if (initiateDisjointFeeds) {
            this.initiateDisjointFeeds(initiateDisjointFeeds);
        }
        if (this.user.isAuthenticated) {
            this.postLoginCalls(true, true);
        }
        //Write postlobby calls here
    }
    getThumbnailbasedonroute(category: any) {
        if (category && category.sitecoreData && category.sitecoreData.gameThumbnail && category.sitecoreData.gameThumbnail.length > 0) {
            const vipThumail = category.sitecoreData.gameThumbnail.filter((gt: any) => {
                return gt.isVipThumbnail;
            });
            const nonVipThumail = category.sitecoreData.gameThumbnail.filter((gt: any) => {
                return !gt.isVipThumbnail;
            });
            if (!this.user.isAuthenticated && nonVipThumail && nonVipThumail[0]) {
                return nonVipThumail[0];
            } else if (this.user.isAuthenticated) {
                if (!this.user.claims.get('vipLevel') && nonVipThumail[0]) {
                    return nonVipThumail[0];
                } else if (this.user.claims.get('vipLevel') && vipThumail[0]) {
                    return vipThumail[0];
                } else if (vipThumail.length == 0 && nonVipThumail && nonVipThumail[0]) {
                    return nonVipThumail[0];
                }
            }
        }
    }

    get isLobbyLoadEventTracked(): boolean {
        return this.lobbyLoadEventTracked;
    }

    setLobbyLoadEventTrackingStatus(status: boolean): void {
        this.lobbyLoadEventTracked = status;
    }

    private initGameSelectorWidget(): void {
        if (this.gameSelectorWidgetSource?.length) {
            const widgetPaths = [...new Set(this.gameSelectorWidgetSource.map((item) => item[this.path]))];
            this.gameSelectorWidgetService.fetchWidgets(widgetPaths);
        }
    }

    refreshFeeds() {
        if (this.disjointFeedsInitiated && this.configProviderService.providePromotedGamesConfig()?.isEnabled) {
            this.promotedGamesService.setPromotedGames();
        }
        if (this.user.isAuthenticated) {
            if (this.postLoginFeedsInitiated) {
                this.recentGamesService.setRecentGames();
                this.unfinishedGamesService.setUnfinishedGames();
            }
        } else if (
            this.disjointFeedsInitiated &&
            this.configProviderService.provideFeaturesConfig()?.enablePreLoginRecentlyPlayed &&
            this.cookies.get('lastVisitor')
        ) {
            this.recentGamesService.setRecentGames();
        }
    }

    initiateDisjointFeeds(initiateDisjointFeeds: boolean) {
        if (!this.user.isAuthenticated || (this.user.isAuthenticated && !this.postloginfirstTeaserCall)) {
            this.teaserService.setTeasers();
            if (this.user.isAuthenticated) {
                this.postloginfirstTeaserCall = true;
            } else {
                this.initGameSelectorWidget();
            }
        }
        const jackpotDataClientConfig: any = this.configProviderService.provideJackpotConfig();
        const lmtConfig: any = this.configProviderService.provideLmtConfig();
        if (lmtConfig.enableDynamicSequentialCalls && !this.rpfavandunfinishedcalled) {
            this.recentGamesService.setRecentGames();
            this.favouriteService.setFavouriteGames();
            this.unfinishedGamesService.setUnfinishedGames();
            this.rpfavandunfinishedcalled = true;
        }
        const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        const feedCacheConfig: any = this.configProviderService.provideFeedCacheConfig();
        const casinoNpmParams = this.casinoCoreNpmParamsService.getcasinoCoreNpmParams();
        if (featuresConfig.enableFastLoadingGames) {
            this.fastLoadingGamesService.setFastLoadingGamesFromLMT();
        }

        if (!this.user.isAuthenticated || (this.user.isAuthenticated && !this.postloginfirstJpSortCall)) {
            if (feedCacheConfig.enableJackpotFeed && !casinoNpmParams?.isDisableJackpotCalls) {
                this.jackpotDataService.startFeed();
            }
            if (jackpotDataClientConfig.enableJackpotSortGames) {
                this.jackpotDataService.fetchJackpotSortGames();
            }
            if (this.user.isAuthenticated) this.postloginfirstJpSortCall = true;
        }
        const optMiniTeaserPaths = [...new Set(this.optMiniTeasersSource.map((item) => item[this.path]))];
        this.teaserService.fetchOptMiniTeasers(optMiniTeaserPaths);
        const discoverFeedConfig = this.configProviderService.provideDiscoverFeedConfig();
        if (discoverFeedConfig.enableDiscoverFeed) {
            this.discoverFeedService.GetDiscoverFeedSitecoreData();
        }
        const liveCasinoConfig = this.configProviderService.provideLiveCasinoConfig();
        if (!this.user.isAuthenticated || (this.user.isAuthenticated && !this.postloginfirstLiveCasinoCall)) {
            if (liveCasinoConfig.enabled && !casinoNpmParams?.isDisableLiveCasinoCalls) {
                this.liveCasinoService.getLiveCasinoData(true);
            }
            if (this.user.isAuthenticated) this.postloginfirstLiveCasinoCall = true;
        }
        const promotedGamesConfig = this.configProviderService.providePromotedGamesConfig();
        if (promotedGamesConfig.isEnabled) {
            this.promotedGamesService.setPromotedGames();
        }
        const newsFeedConfig = this.configProviderService.provideNewsFeedCasinoConfig();
        if (newsFeedConfig.isEnabled) {
            this.newsFeedService.setNewsFeed();
        }

        if (!this.user.isAuthenticated && featuresConfig.enablePreLoginRecentlyPlayed && this.cookies.get('lastVisitor')) {
            this.recentGamesService.setRecentGames();
        }
        this.initializeSlotRaces(initiateDisjointFeeds);
        //Write postlobby calls here
        this.disjointFeedsInitiated = true;
    }

    //   private initSlotRaces() {
    //     let casinoCoreNpmParams = this.casinoCoreNpmParamsService.getcasinoCoreNpmParams();
    //     if (casinoCoreNpmParams && !casinoCoreNpmParams.disableSlotracesCallsOnBootstrap) {
    //         this.initializeSlotRaces();
    //     }
    // }

    initializeSlotRaces(initiateDisjointFeeds: boolean) {
        if (initiateDisjointFeeds && (!this.user.isAuthenticated || (this.user.isAuthenticated && !this.postLoginFirstSlotRaceCall))) {
            const slotRacesConfig = this.configProviderService.provideSlotRacesConfig();
            const widgetConfig = this.configProviderService.providewidgetConfig();
            const casinoNpmParams = this.casinoCoreNpmParamsService.getcasinoCoreNpmParams();
            if (
                ((slotRacesConfig?.enableSlotRaces || slotRacesConfig?.enableSlotRacesPhase2) && !casinoNpmParams.disableSlotracesCallsOnBootstrap) ||
                ((slotRacesConfig?.enableSlotRaces || slotRacesConfig?.enableSlotRacesPhase2) &&
                    widgetConfig?.enableSlotRacesForOtherProducts &&
                    casinoNpmParams?.disableSlotracesCallsOnBootstrap)
            ) {
                this.slotRacesService.forceOptin();
                this.slotRacesService.forceCoinBalance();
                const gameDataMap = this.getGameMetaData();
                const disabledGames = this.getDisabledGames();
                this.slotRacesService.initSlotRaces(
                    gameDataMap,
                    disabledGames,
                    this.lobbyTypeMaps.get(slotRacesConfig.slotraceLobbytypeForGamesOrdering),
                    casinoNpmParams.isDll,
                    true,
                );
            }
            if (this.user.isAuthenticated) this.postLoginFirstSlotRaceCall = true;
        }
    }

    isDisjointFeedsInitiated(): boolean {
        return this.disjointFeedsInitiated;
    }

    postLoginCalls(fromOnLobbyLoaded: boolean, initiateDisjointFeeds: boolean) {
        this.lmtConfig = this.configProviderService.provideLmtConfig();
        const casinoNpmParams = this.casinoCoreNpmParamsService.getcasinoCoreNpmParams();
        if (!fromOnLobbyLoaded) {
            if (
                casinoNpmParams &&
                casinoNpmParams.disableFullLobbyFetch &&
                casinoNpmParams.customLobbyTypeMaps &&
                casinoNpmParams.customLobbyTypeMaps.length > 0
            ) {
                this.setPartialCasinoLobbiesforOtherProducts(casinoNpmParams.customLobbyTypeMaps, true, false);
            } else if (casinoNpmParams.disableFullLobbyFetch && casinoNpmParams.targetOverlayLobby) {
                this.setPartialCasinoLobbiesForOverlay();
            } else {
                const lmtConfig: any = this.configProviderService.provideLmtConfig();
                if (!lmtConfig.enablePostloginPartialCalls) {
                    this.setCasinoLobby(false, !fromOnLobbyLoaded);
                }
            }
        } else {
            this.initiatePostLoginDisjointFeeds(initiateDisjointFeeds);
        }
    }

    handleForceGameLaunch() {
        const modularSettingsConfig = this.configProviderService.provideModularSettingsConfig();
        setTimeout(() => {
            const forceLoginGameObjectExpirationTime = modularSettingsConfig.forceLoginGameObjectExpirationTime;
            const currentTimeStamp = new Date().getTime();
            const forceGameLaunchObjData = this.cacheManager.readForceGameLaunchObjDataFromSessionStorage('CasinoCoreForceGameObj');
            if (forceGameLaunchObjData) {
                if (forceGameLaunchObjData.gameLaunchParam && forceGameLaunchObjData.gameLaunchParam.from == 'thumbnail') {
                    this.cacheManager.setThumbnailLocalStorageCacheMap(
                        forceGameLaunchObjData.gameLaunchParam.lobbyType,
                        forceGameLaunchObjData.gameLaunchParam.game.gameId,
                        true,
                    );
                    this.thumbnailService.setThumbnailData(forceGameLaunchObjData.gameLaunchParam);
                }
                forceGameLaunchObjData.gameLaunchParam.noGameTraking = true;
                sessionStorage.removeItem('CasinoCoreForceGameObj');
                if (currentTimeStamp - forceGameLaunchObjData.timeStamp <= forceLoginGameObjectExpirationTime * 1000) {
                    this.forceGameLaunchBehaviourSubject.next(forceGameLaunchObjData);
                }
            }
        }, modularSettingsConfig.forceGameLaunchExecutionTimeOut);
    }
    checkIfGamePremiereEnabledForCategory(lobbyType: string, categoryRoute: string): boolean {
        if (lobbyType == undefined || lobbyType == null || lobbyType == '') return false;

        if (categoryRoute == undefined || categoryRoute == null || categoryRoute == '') return false;

        const gamePremiereConfig = this.configProviderService.provideGamePremiereConfig();
        const showGamePremiereByLobbyTypeCategory = gamePremiereConfig.showGamePremiereByLobbyTypeCategory;

        if (
            showGamePremiereByLobbyTypeCategory == null ||
            showGamePremiereByLobbyTypeCategory == undefined ||
            showGamePremiereByLobbyTypeCategory?.length <= 0
        ) {
            return false;
        }

        const gamePremiereCurrentLobbyType = showGamePremiereByLobbyTypeCategory.find(
            (x: any) => x?.lobbyType.toLowerCase() == lobbyType.toLowerCase(),
        );

        if (gamePremiereCurrentLobbyType == null || gamePremiereCurrentLobbyType == undefined) return false;

        //Enables for all the Categories if there are no categories
        if (gamePremiereCurrentLobbyType.category == null || gamePremiereCurrentLobbyType.category?.length <= 0) {
            return true;
        }

        if (gamePremiereCurrentLobbyType.category.some((x: any) => x?.toLowerCase() === categoryRoute.toLowerCase())) {
            return true;
        }

        return false;
    }

    initiatePostLoginDisjointFeeds(initiateDisjointFeeds: boolean) {
        if (!this.user.isAuthenticated || (this.user.isAuthenticated && !this.postloginfirstTeaserCall)) {
            this.teaserService.setTeasers();
            if (this.user.isAuthenticated) this.postloginfirstTeaserCall = true;
        }
        if (this.user.isAuthenticated && !this.postLoginFeedsInitiated) {
            //checkForForcedLogin
            this.handleForceGameLaunch();
            const lmtConfig: any = this.configProviderService.provideLmtConfig();
            if (!lmtConfig.enableDynamicSequentialCalls && !this.rpfavandunfinishedcalled) {
                this.recentGamesService.setRecentGames();
                this.favouriteService.setFavouriteGames();
                this.unfinishedGamesService.setUnfinishedGames();
                this.rpfavandunfinishedcalled = true;
            }
            const casinoNpmParams = this.casinoCoreNpmParamsService.getcasinoCoreNpmParams();
            this.initiatePostLoginFeatures(casinoNpmParams);
            this.initGameSelectorWidget();
            const optMiniTeaserPaths = [...new Set(this.optMiniTeasersSource.map((item) => item[this.path]))];
            this.teaserService.fetchOptMiniTeasers(optMiniTeaserPaths);
            this.initiatePostLoginRemainingFeed(casinoNpmParams, initiateDisjointFeeds);
            this.initiateJackpotandPromotionBanner(casinoNpmParams);
            this.subscribeCoinBalance();
            this.postLoginFeedsInitiated = true;
        }
    }
    initializeGeolocationCoordinator() {
        if (this.route) {
            const bootstrapCoordinationStart = this.route.snapshot.queryParamMap.get('_bootstrapCoordinationStart');
            if (bootstrapCoordinationStart && bootstrapCoordinationStart === 'false') {
                return;
            }
        }
        if (!this.casinoCoreNpmParamsService.getcasinoCoreNpmParams().isDll) this.checkGeolocationScript();
        if (this.user.isAuthenticated && this.geolocationActivator.isActive) {
            this.geolocationCoordinatorService.initialize({
                showPLCPopup: false,
            });
            this.geolocationCoordinatorService.registerUnload();
            this.geolocationCoordinatorService.registerSynchronization();
        }
    }
    checkGeolocationScript() {
        const geolocationConfig: any = this.configProviderService.provideGeoLocationConfig();
        geolocationConfig.assetsPath &&
            geolocationConfig.assetsPath.length > 0 &&
            geolocationConfig.assetsPath.forEach((assetScript: any) => {
                if (assetScript.indexOf('pathIdentifier=geocomplyjs') > -1) {
                    if (!this.windowRef.nativeWindow.GeoComply) {
                        this.appendGeolocationScript(assetScript);
                    }
                }
                if (assetScript.indexOf('pathIdentifier=oobeejs') > -1) {
                    if (!this.windowRef.nativeWindow.GCOobee) {
                        this.appendGeolocationScript(assetScript);
                    }
                }
                if (assetScript.indexOf('pathIdentifier=appintegrationjs') > -1) {
                    if (!this.windowRef.nativeWindow.geolocationCoordinator) {
                        this.appendGeolocationScript(assetScript);
                    }
                }
            });
    }
    appendGeolocationScript(assetScript: string) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = assetScript;
        document.body.appendChild(script);
    }
    getApplicableLobbyTypes(applicableLobbyTypeMaps: any) {
        applicableLobbyTypeMaps = applicableLobbyTypeMaps.filter(
            (af: any) => af?.invokerProduct?.toLowerCase() === this.invokerProductService.getInvokerProduct() && af.fetchMeta,
        );
        return applicableLobbyTypeMaps;
    }

    getApplicableLobbyTypeMapsDynacon(): any[] {
        const lmtConfig: any = this.configProviderService.provideLmtConfig();
        const applicableLobbyTypeMaps = lmtConfig.lobbyTypeMap;
        //TODO: filter as per invokerProduct
        return this.getApplicableLobbyTypes(applicableLobbyTypeMaps);
    }

    hideGlobalSearchHeaderAndStickyEntryBasedOnPath(): boolean {
        const getCurrentPath: string = this.navigation.location.pathname;
        const globalSearchConfig = this.configProviderService.provideGlobalSearchConfig();
        const globalSearchPathBasedEnabler = globalSearchConfig.pathBasedEnabler;
        const disabledGlobalSearchPaths: string[] = globalSearchPathBasedEnabler.disablePaths;
        const enabledGlobalSearchPaths: string[] = globalSearchPathBasedEnabler.enablePaths;
        if (globalSearchPathBasedEnabler) {
            this.hideGlobalSearchBasedOnPath = disabledGlobalSearchPaths.some((disablePath) => getCurrentPath.includes(disablePath));
        } else if (enabledGlobalSearchPaths) {
            this.hideGlobalSearchBasedOnPath = !enabledGlobalSearchPaths.some((enablePath) => getCurrentPath.includes(enablePath));
        } else {
            this.hideGlobalSearchBasedOnPath = false;
        }
        return this.hideGlobalSearchBasedOnPath;
    }

    hideGlobalSearch(): void {
        dispatchEvent(new CustomEvent('gs:hideHeaderEntry'));
        dispatchEvent(new CustomEvent('gs:hideFloatingEntry'));
    }

    showGlobalSearch(): void {
        dispatchEvent(new CustomEvent('gs:showHeaderEntry'));
        dispatchEvent(new CustomEvent('gs:showFloatingEntry'));
    }

    hidingGlobalSearch(): void {
        if (this.hideGlobalSearchHeaderAndStickyEntryBasedOnPath() && this.hideGlobalSearchBasedOnPath) {
            this.hideGlobalSearch();
        } else {
            this.showGlobalSearch();
        }
    }

    retrieveRawLobbyTypesMapDataFromLocalStorage(respondBack: boolean = false) {
        const applicableLobbyTypeMaps = this.getApplicableLobbyTypeMapsDynacon();
        const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        const collectedLobbies: any = [];
        let allApplicableLobbyDataPresent: boolean = false;

        if (applicableLobbyTypeMaps && applicableLobbyTypeMaps.length > 0) {
            for (let i = 0; i < applicableLobbyTypeMaps.length; i++) {
                const lobbyType: string = applicableLobbyTypeMaps[i].lobbyType;
                const lobbyTypeMapsInCache: any = this.cacheManager.readCategoryDataLocalCache(
                    featuresConfig.browserLocalStorageCategoryDataUpdateInterval,
                    lobbyType,
                    applicableLobbyTypeMaps[i]?.invokerProduct?.toLowerCase(),
                );
                if (lobbyTypeMapsInCache != null) {
                    collectedLobbies.push({
                        lobbyType: lobbyType,
                        feed: lobbyTypeMapsInCache,
                    });
                    allApplicableLobbyDataPresent = true;
                } else {
                    allApplicableLobbyDataPresent = false;
                    break;
                }
            }
            if (allApplicableLobbyDataPresent && collectedLobbies && collectedLobbies.length > 0) {
                dispatchEvent(
                    new CustomEvent('LocalStorage_Collected_Lobbies', {
                        detail: { collectedLobbies },
                    }),
                );
            } else if (respondBack) {
                dispatchEvent(new CustomEvent('LocalStorage_Collected_Lobbies'));
            }
            this.hidingGlobalSearch();
        }
    }

    checkAndRetrieveAllLobbyTypeMapsOnLocalStorage(applicableLobbyTypeMaps: any): Map<string, any> {
        const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        const lobbyTypeMaps: Map<string, any> = new Map<string, any>();
        let allApplicableLobbyDataPresent: boolean = false;

        if (applicableLobbyTypeMaps && applicableLobbyTypeMaps.length > 0) {
            for (let i = 0; i < applicableLobbyTypeMaps.length; i++) {
                const lobbyTypeMapsInCache: any = this.cacheManager.readCategoryDataLocalCache(
                    featuresConfig.browserLocalStorageCategoryDataUpdateInterval,
                    applicableLobbyTypeMaps[i].lobbyType,
                    applicableLobbyTypeMaps[i]?.invokerProduct?.toLowerCase(),
                );
                if (lobbyTypeMapsInCache != null) {
                    const UILobby: LobbyItem = new LobbyItem();
                    UILobby.setLobbyItemFromLocalStorage(lobbyTypeMapsInCache);
                    // filling gameMetaDataMap
                    for (let i = 0; i < lobbyTypeMapsInCache.lsGameMetaDataMap.length; i++) {
                        const gameItem = lobbyTypeMapsInCache.lsGameMetaDataMap[i][1];
                        this.gameMetaDataMap.set(gameItem.game, gameItem);
                    }
                    lobbyTypeMaps.set(applicableLobbyTypeMaps[i].lobbyType, UILobby);
                    allApplicableLobbyDataPresent = true;
                } else {
                    allApplicableLobbyDataPresent = false;
                    break;
                }
            }
            this.getUpdatedMetaDataPlayerStats();
        } else {
            this.logger.error('Please configure necessary lobbyTypeMaps in dynacon');
            allApplicableLobbyDataPresent = false;
        }

        if (this.gJLobbyTypeMaps && this.gJLobbyTypeMaps.size > 0) {
            this.gJLobbyTypeMaps.forEach((v: any, k: string) => {
                lobbyTypeMaps.set(k, v);
            });
        }

        if (lobbyTypeMaps && lobbyTypeMaps.size > 0) {
            this.gameSelectorWidgetSource = [];
            const gameSelectorWidgetConfig = this.configProviderService.provideGameSelectorWidgetConfig();

            this.optMiniTeasersSource = [];
            const optimizedTeaserConfig = this.configProviderService.provideOptimizedTeaserConfig();

            lobbyTypeMaps.forEach((lobbyType: any) => {
                if (lobbyType.categoriesMap && lobbyType.categoriesMap.size > 0) {
                    lobbyType.categoriesMap.forEach((category: any) => {
                        const catSitecoreData = category.sitecoreData;

                        if (
                            gameSelectorWidgetConfig &&
                            gameSelectorWidgetConfig.isEnabled &&
                            catSitecoreData?.displayGameSelectorWidget &&
                            catSitecoreData?.gameSelectorWidget
                        ) {
                            this.gameSelectorWidgetSource.push(catSitecoreData?.gameSelectorWidget);
                        }

                        if (
                            catSitecoreData?.optimisedMiniTeasers &&
                            catSitecoreData?.optimisedMiniTeasers.length &&
                            optimizedTeaserConfig.teaserOptimization &&
                            optimizedTeaserConfig.enableOptMiniTeasers &&
                            !this.isTouch()
                        ) {
                            this.optMiniTeasersSource.push(...catSitecoreData.optimisedMiniTeasers);
                        }
                    });
                }
            });
        }

        return allApplicableLobbyDataPresent ? lobbyTypeMaps : new Map<string, any>();
    }

    checkAndRetrieveAllCustomLobbyTypeMapsOnLocalStorage(customLobbyTypes: any): Map<string, any> {
        const customLobbyTypeMaps = this.getApplicableLobbyTypes(customLobbyTypes);
        return this.checkAndRetrieveAllLobbyTypeMapsOnLocalStorage(customLobbyTypeMaps);
    }
    checkAndRetrieveAllApplicableLobbyTypeMapsOnLocalStorage(): Map<string, any> {
        const applicableLobbyTypeMaps = this.getApplicableLobbyTypeMapsDynacon();
        return this.checkAndRetrieveAllLobbyTypeMapsOnLocalStorage(applicableLobbyTypeMaps);
    }
    setPartialCasinoLobby(lobbyType: string) {
        this.lmtConfig = this.configProviderService.provideLmtConfig();
        this.readAndDispatchLobbyDataForGlobalSearch();
        if (
            this.lobbyTypeMaps &&
            this.lobbyTypeMaps.size > 0 &&
            this.lobbyTypeMaps.get(lobbyType)?.categoriesMap &&
            this.lobbyTypeMaps.get(lobbyType).categoriesMap.size > 0
        ) {
            return;
        }
        this.lobbyTypeMaps = this.checkAndRetrieveAllApplicableLobbyTypeMapsOnLocalStorage();
        if (!this.lobbyTypeMaps) {
            this.lobbyTypeMaps = new Map<string, any>();
        }
        if (
            this.lobbyTypeMaps &&
            this.lobbyTypeMaps.size > 0 &&
            this.lobbyTypeMaps.get(lobbyType)?.categoriesMap &&
            this.lobbyTypeMaps.get(lobbyType).categoriesMap.size > 0
        ) {
            this.setCasinoLobbyFromLocalStorage(this.lobbyTypeMaps, true);
        } else {
            const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
            const invokerProduct: string = this.invokerProductService.getInvokerProduct();
            this.api.get(apiPathsConfig.lmt['lmtPartialFeed'], { lobbyType, invokerProduct }, { withCredentials: true }).subscribe(
                (response: any) => {
                    if (response) {
                        // for testing language content is not available from the feed please don't delete this commented block
                        // if (this.lmtConfig?.enableLMTMockData) {
                        //  delete response?.collectedLobby[0]?.lobbyFeedJson?.lobbyconfiguration?.languageContent;
                        // }
                        this.setLobbyContent(response, false);
                        if (this.lmtConfig.enablePostloginPartialCalls) {
                            const postLogin = this.user.isAuthenticated ? true : false;
                            this.setCasinoLobby(true, postLogin);
                        } else {
                            this.setCasinoLobby(true);
                        }
                        this.readAndDispatchLobbyDataForGlobalSearch();
                    }
                },
                (error) => {
                    this.logger.error('Unable to retrieve casino lobby data' + error);
                },
            );
        }
    }

    setPartialCasinoLobbiesforOtherProducts(customLobbyTypes: any, initiateDisjointFeeds: boolean, postLogin: boolean = false) {
        this.lobbyTypeMaps = this.checkAndRetrieveAllCustomLobbyTypeMapsOnLocalStorage(customLobbyTypes);
        if (!this.lobbyTypeMaps) {
            this.lobbyTypeMaps = new Map<string, any>();
        }
        if (this.lobbyTypeMaps && this.lobbyTypeMaps.size > 0) {
            this.setCasinoLobbyFromLocalStorage(this.lobbyTypeMaps, initiateDisjointFeeds, postLogin);
        } else {
            if (this.isODR() || this.isSportsODR()) {
                customLobbyTypes = this.configProviderService.provideLmtConfig()?.otherProductsLobbyTypeForOdr
                    ? this.configProviderService.provideLmtConfig().otherProductsLobbyTypeForOdr
                    : null;
            }
            const endPoint = this.apiPathsHelperService.transformApi(
                'lmtfeed/GetCustomLobbyTypesFeed?invokerProduct=${args[0]}',
                this.invokerProductService.getInvokerProduct(),
            );
            this.api.post(endPoint, customLobbyTypes, { withCredentials: true }).subscribe(
                (response: any) => {
                    if (response) {
                        this.setLobbyContent(response, initiateDisjointFeeds, postLogin);
                        this.readAndDispatchLobbyDataForGlobalSearch();
                    }
                },
                (error) => {
                    this.logger.error('Unable to retrieve casino lobby data' + error);
                },
            );
        }
    }
    readAndDispatchLobbyDataForGlobalSearch(respondBack: boolean = false) {
        const globalSearchConfig = this.configProviderService.provideGlobalSearchConfig();
        globalSearchConfig && globalSearchConfig.isEnabled && this.retrieveRawLobbyTypesMapDataFromLocalStorage(respondBack);
    }

    //Call this function to set the content of the lobby
    setCasinoLobby(initiateDisjointFeeds: boolean, postLogin: boolean = false, fromGlobalJackpotFeedInit: boolean = false) {
        const tempLobbyTypeMaps: Map<string, any> = cloneDeep(this.lobbyTypeMaps);

        // let tempLobbyTypeMaps: Map<string, any> = new Map<string, any>([...this.lobbyTypeMaps].map(x => Object.assign({}, x))) ;

        const lmtConfig: any = this.configProviderService.provideLmtConfig();
        this.lobbyTypeMaps = this.checkAndRetrieveAllApplicableLobbyTypeMapsOnLocalStorage();
        const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        if (!this.lobbyTypeMaps) {
            this.lobbyTypeMaps = new Map<string, any>();
        }
        if (this.lobbyTypeMaps && this.lobbyTypeMaps.size > 0) {
            this.setCasinoLobbyFromLocalStorage(this.lobbyTypeMaps, initiateDisjointFeeds, postLogin);
        } else {
            this.lobbyTypeMaps = tempLobbyTypeMaps || new Map<string, any>();
            const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
            const endPoint = this.apiPathsHelperService.transformApi(
                apiPathsConfig.lmt['lmtFeedOfProduct'],
                this.invokerProductService.getInvokerProduct(),
            );

            this.api.get(endPoint, null, { withCredentials: true }).subscribe(
                (response: any) => {
                    if (response) {
                        // for testing language content is not available from the feed please don't delete this commented block
                        // if (this.lmtConfig?.enableLMTMockData) {
                        //    response?.collectedLobby.forEach((lobbyType:any)=>{
                        //     delete lobbyType?.lobbyFeedJson?.lobbyconfiguration?.languageContent;
                        //    });
                        // }
                        if (lmtConfig.enableDynamicSequentialCalls && !this.rpfavandunfinishedcalled) {
                            this.recentGamesService.setRecentGames();
                            this.favouriteService.setFavouriteGames();
                            this.unfinishedGamesService.setUnfinishedGames();
                            this.rpfavandunfinishedcalled = true;
                        }
                        this.setLobbyContent(response, initiateDisjointFeeds, postLogin);
                        this.readAndDispatchLobbyDataForGlobalSearch();
                        if (featuresConfig?.storeVendorInfoInCache && !fromGlobalJackpotFeedInit) {
                            this.getAllVendorDetails();
                        }
                    }
                },
                (error) => {
                    this.logger.error('Unable to retrieve casino lobby data' + error);
                },
            );
        }
    }

    getAllVendorDetails() {
        this.api.get('QuickInfo/ProcessVendors').subscribe({
            next: () => {},
            error: (error) => {
                this.logger.error('Unable to retrieve vendors data' + error);
            },
        });
    }

    subscribeCoinBalance() {
        this.gamificationService.coinBalance.subscribe(
            (coinBalRes: any) => {
                this.userCoinBalance = parseInt(coinBalRes?.balance);
                this.coinBalancePublisher.next(this.userCoinBalance);
            },
            (error) => {
                this.logger.error('Unable to retrieve coin balance' + error);
            },
        );
    }

    getCoinBalance() {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(apiPathsConfig.slotRaces['racesCoinBalance'], {}, { withCredentials: true });
    }

    setCasinoLobbyFromLocalStorage(lobbyTypeMapsFromLocalStorage: Map<string, any>, initiateDisjointFeeds: boolean, postLogin: boolean = false) {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();

        this.api.get(apiPathsConfig.gamesServices['disabledGames'], null, { withCredentials: true }).subscribe(
            (response) => {
                this.disabledGames = response;
                const lobbyLoadEvent: any = {
                    lobbyTypeMaps: lobbyTypeMapsFromLocalStorage,
                    initiateFeeds: initiateDisjointFeeds,
                    userAuthenticated: this.user.isAuthenticated,
                    isPostLogin: postLogin,
                };
                this.publishLobbyLoadedEvent(lobbyLoadEvent);
            },
            (error) => {
                this.logger.error(error);
                const lobbyLoadEvent: any = {
                    lobbyTypeMaps: lobbyTypeMapsFromLocalStorage,
                    initiateFeeds: initiateDisjointFeeds,
                    userAuthenticated: this.user.isAuthenticated,
                    isPostLogin: postLogin,
                };
                this.publishLobbyLoadedEvent(lobbyLoadEvent);
            },
        );
    }

    setCasinoLobbyForInvokerProduct(lobbyType: string, invokerProduct: string) {
        const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        this.lobbyTypeMapsForInvokerProduct.clear();
        const lobbyTypeMapsInCache: any = this.cacheManager.readCategoryDataLocalCache(
            featuresConfig.browserLocalStorageCategoryDataUpdateInterval,
            lobbyType,
            invokerProduct?.toLowerCase(),
        );
        if (lobbyTypeMapsInCache != null) {
            const UILobby: LobbyItem = new LobbyItem();
            UILobby.setLobbyItemFromLocalStorage(lobbyTypeMapsInCache);
            this.lobbyTypeMapsForInvokerProduct.set(lobbyType, UILobby);
        }
        if (this.lobbyTypeMapsForInvokerProduct && this.lobbyTypeMapsForInvokerProduct.size > 0) {
            const lobbyLoadEvent: any = {
                lobbyTypeMaps: this.lobbyTypeMapsForInvokerProduct,
                userAuthenticated: this.user.isAuthenticated,
            };
            this.publishLobbyLoadedEventForInvokerProduct(lobbyLoadEvent);
        } else {
            const apiPathsConfig = this.configProviderService.provideApiPathsConfig();

            this.api.get(apiPathsConfig.lmt['lmtPartialFeed'], { lobbyType, invokerProduct }, { withCredentials: true }).subscribe(
                (response: any) => {
                    if (response) {
                        this.setLobbyContentForInvokerProduct(response, lobbyType, invokerProduct);
                    }
                },
                (error) => {
                    this.logger.error('Unable to retrieve casino lobby data' + error);
                },
            );
        }
    }

    initiateCasinoLobby(casinoCoreNpmParams: CasinoCoreNpmParams) {
        this.casinoCoreNpmParamsService.setcasinoCoreNpmParams(casinoCoreNpmParams);
        this.configProviderService.fillClientConfig(casinoCoreNpmParams.isDll);
        this.checkForTouchDevice();
    }

    publishLobbyLoadedEvent(lobbyLoadEvent: any) {
        this.setSearchCriteriaforCategoryGames();
        lobbyLoadEvent.isLoggedIn = this.user.isAuthenticated;
        this.lobbyLoadedSubject.next(lobbyLoadEvent);
        this.raiseLobbyLoadedEvent(lobbyLoadEvent);
    }

    publishLobbyLoadedEventForInvokerProduct(lobbyLoadEvent: any) {
        this.lobbyLoadedSubjectForInvokerProduct.next(lobbyLoadEvent);
        this.raiseLobbyLoadedEvent(lobbyLoadEvent);
    }

    setLobbyContent(lobbyData: any, initiateDisjointFeeds: boolean, postLogin: boolean = false) {
        this.setLobbySitecoreData(lobbyData.collectedSitecoreCategory);
        this.setLobbyTypeMaps(lobbyData.collectedLobby, initiateDisjointFeeds, postLogin);
        this.setDisabledGames(lobbyData.disabledGames);
    }
    setLobbyContentForInvokerProduct(lobbyData: any, lobbyType: string, invokerProduct: string) {
        this.setLobbySitecoreDataForInvokerProduct(lobbyData.collectedSitecoreCategory);
        this.setLobbyTypeMapsForInvokerProduct(lobbyData.collectedLobby, lobbyType, invokerProduct);
    }
    setLobbySitecoreData(lobbySitecoreData: any) {
        this.lobbySitecoreData = lobbySitecoreData;
    }
    setLobbySitecoreDataForInvokerProduct(lobbySitecoreData: any) {
        this.lobbySitecoreDataForInvokerProduct = lobbySitecoreData;
    }
    getLobbySitecoreData() {
        return this.lobbySitecoreData ? this.lobbySitecoreData : this.lobbySitecoreDataForInvokerProduct;
    }
    setLobbyTypeMaps(lobbiesInfo: any, initiateDisjointFeeds: boolean, postLogin: boolean = false) {
        if (lobbiesInfo && lobbiesInfo.length > 0) {
            for (let i = 0; i < lobbiesInfo.length; i++) {
                const lobbyMapItem: any = this.getLobbyTypeItemMap(lobbiesInfo[i]);
                this.lobbyTypeMaps.set(lobbyMapItem.key, lobbyMapItem.value);
                this.cacheManager.writeCategoryLocalStorageCacheMap(
                    lobbyMapItem.key,
                    cloneDeep(lobbyMapItem.value),
                    //{...lobbyMapItem.value},
                    this.invokerProductService.getInvokerProduct(),
                );
            }

            if (this.gJLobbyTypeMaps && this.gJLobbyTypeMaps.size > 0) {
                this.gJLobbyTypeMaps.forEach((v: any, k: string) => {
                    this.lobbyTypeMaps.set(k, v);
                });
            }
            const lobbyLoadEvent: any = {
                lobbyTypeMaps: this.lobbyTypeMaps,
                initiateFeeds: initiateDisjointFeeds,
                userAuthenticated: this.user.isAuthenticated,
                isPostLogin: postLogin,
            };
            this.publishLobbyLoadedEvent(lobbyLoadEvent);
        }
    }
    setLobbyTypeMapsForInvokerProduct(lobbiesInfo: any, lobbyType: string, invokerProduct: string) {
        let checkForLobbyTypeInLobbiesInfo: boolean = false;
        if (lobbiesInfo && lobbiesInfo.length > 0) {
            for (let i = 0; i < lobbiesInfo.length; i++) {
                if (lobbyType == lobbiesInfo[i].lobbyType) {
                    checkForLobbyTypeInLobbiesInfo = true;
                    lobbiesInfo = lobbiesInfo[i];
                    break;
                }
            }
            if (checkForLobbyTypeInLobbiesInfo) {
                const lobbyMapItem: any = this.getLobbyTypeItemMap(lobbiesInfo);
                this.lobbyTypeMapsForInvokerProduct.set(lobbyMapItem.key, lobbyMapItem.value);
                this.cacheManager.writeCategoryLocalStorageCacheMap(
                    lobbyMapItem.key,
                    cloneDeep(lobbyMapItem.value),
                    //{...lobbyMapItem.value},
                    invokerProduct?.toLowerCase(),
                );
            }
            const lobbyLoadEvent: any = {
                lobbyTypeMaps: this.lobbyTypeMapsForInvokerProduct,
                userAuthenticated: this.user.isAuthenticated,
            };
            this.publishLobbyLoadedEventForInvokerProduct(lobbyLoadEvent);
        }
    }
    getLobbyTypeItemMap(lobbyInfo: any) {
        const lobbyContent: LobbyItem = this.getLobbyContent(lobbyInfo.lobbyFeedJson.lobbyconfiguration, lobbyInfo.lobbyType);
        const MapKeyValuePair: any = {
            key: lobbyInfo.lobbyType,
            value: lobbyContent,
        };
        return MapKeyValuePair;
    }
    setDisabledGames(disabledGamesData: any) {
        this.disabledGames = disabledGamesData;
    }
    getDisabledGames() {
        if (this.disabledGames) {
            return this.disabledGames;
        }
        return [];
    }
    getLobbyContent(lobbyConfiguration: any, lobbyType: string): LobbyItem {
        let categoryTitleLanguageMap: Map<string, string> = new Map<string, string>();
        let stickerTitleLanguageMap: Map<string, string> = new Map<string, string>();
        let gameMetaDataMap: Map<string, string> = new Map<string, string>();
        if (lobbyConfiguration) {
            if (lobbyConfiguration.languageContent && lobbyConfiguration.languageContent[0]) {
                if (lobbyConfiguration.languageContent[0].categories && lobbyConfiguration.languageContent[0].categories.length > 0) {
                    categoryTitleLanguageMap = this.getLanguageContentMap(lobbyConfiguration.languageContent[0].categories);
                }
                if (lobbyConfiguration.languageContent[0].stickers && lobbyConfiguration.languageContent[0].stickers.length > 0) {
                    stickerTitleLanguageMap = this.getLanguageContentMap(lobbyConfiguration.languageContent[0].stickers);
                }
            }
            if (lobbyConfiguration?.gamemetadata && lobbyConfiguration.gamemetadata?.length > 0) {
                gameMetaDataMap = this.getGameMetaDataMap(lobbyConfiguration.gamemetadata, lobbyType);
            }
        }
        const lobbyCategoriesMap: Map<string, any> = new Map<string, any>();
        const lobbySubCategoriesMap: Map<string, any> = new Map<string, any>();

        if (lobbyConfiguration && lobbyConfiguration.categories && lobbyConfiguration.categories.length > 0) {
            for (let i = 0; i < lobbyConfiguration.categories.length; i++) {
                const lobbyCategory: any = this.getLobbyCategory(lobbyConfiguration.categories[i], categoryTitleLanguageMap, lobbyType);
                if (lobbyCategory.subcategories && lobbyCategory.subcategories.length > 0) {
                    lobbyCategory.lmtSubCategories = [];
                    for (let j = 0; j < lobbyCategory.subcategories.length; j++) {
                        const lobbySubCategory: any = this.getLobbySubCategory(lobbyCategory.subcategories[j], categoryTitleLanguageMap, lobbyType);
                        lobbySubCategory.parentCategoryId = lobbyCategory.categoryid; //this.getParentCategoryId(lobbyCategory.subcategories[j], allLobbyCategories);
                        lobbySubCategory.parentLMTCategoryId = lobbyCategory.lmtCategoryId;
                        lobbySubCategory.filterRecentlyPlayedGames = this.isFilterRecentlyPlayedGames(
                            lobbyConfiguration.catVsFeatureMetadata,
                            lobbySubCategory,
                        );
                        lobbySubCategory.havingCasiaGames = this.filterCasiaCategory(lobbyConfiguration.catVsFeatureMetadata, lobbySubCategory);
                        if (this.configProviderService.provideAdvanceGameDataConfig().enableFeatureId) {
                            lobbySubCategory.filterFeatureIds = this.isFilterFeatureIds(lobbyConfiguration.catVsFeatureMetadata, lobbySubCategory);
                        }
                        if (lobbySubCategory.route) lobbySubCategoriesMap.set(lobbySubCategory.route, lobbySubCategory);
                        lobbyCategory.lmtSubCategories.push(lobbySubCategory.categoryid);
                    }
                }
                if (lobbyCategory.route) lobbyCategoriesMap.set(lobbyCategory.route, lobbyCategory);
            }

            const recentPlayedCategory: any = {
                categoryid: ConstService?.Recent_Games?.toLowerCase(),
                route: ConstService?.Recent_Games?.toLowerCase(),
                categoryname: this.getCategoryNameFromSitecore(ConstService?.Recent_Games?.toLowerCase(), lobbyType, true),
                subTitle: this.getSubtitleFromSitecore(ConstService?.Recent_Games?.toLowerCase(), lobbyType),
                isNavigationCategory: true,
                sitecoreData: this.getSitecoreInfoForCategoryIdAndLobbyType(ConstService?.Recent_Games?.toLowerCase(), lobbyType),
                isSubCatVisible: false,
                lmtCategoryId: ConstService.Recent_Games.toUpperCase(),
                engCategoryName: 'RECENTGAMES',
            };

            lobbyCategoriesMap.set(recentPlayedCategory.route, recentPlayedCategory);

            const favouritesCategory: any = {
                categoryid: ConstService?.Favourite_Games?.toLowerCase(),
                route: ConstService?.Favourite_Games?.toLowerCase(),
                categoryname: this.getCategoryNameFromSitecore(ConstService?.Favourite_Games?.toLowerCase(), lobbyType, true),
                subTitle: this.getSubtitleFromSitecore(ConstService?.Favourite_Games?.toLowerCase(), lobbyType),
                isNavigationCategory: true,
                sitecoreData: this.getSitecoreInfoForCategoryIdAndLobbyType(ConstService?.Favourite_Games?.toLowerCase(), lobbyType),
                isSubCatVisible: true,
                lmtCategoryId: ConstService.Favourite_Games.toUpperCase(),
                engCategoryName: 'FAVOURITEGAMES',
            };
            lobbyCategoriesMap.set(favouritesCategory.route, favouritesCategory);
            if (lobbySubCategoriesMap.size > 0) {
                lobbySubCategoriesMap.forEach((subcategory: any, subcategoryRoute: string) => {
                    lobbyCategoriesMap.set(subcategoryRoute, subcategory);
                });
            }
        }

        const UILobby: LobbyItem = new LobbyItem();
        UILobby.setCategoriesMap(lobbyCategoriesMap);
        UILobby.setcategoryNameLangMap(categoryTitleLanguageMap);
        UILobby.setStickerNameMap(stickerTitleLanguageMap);
        UILobby.setGameMetaDataMap(gameMetaDataMap);
        UILobby.lbyTempName = lobbyConfiguration?.lbyTempName;
        UILobby.lbyTargetType = lobbyConfiguration?.lbyTargetType;
        UILobby.optSegName = lobbyConfiguration?.optSegName;
        UILobby.catVsFeatureMetadata = lobbyConfiguration?.catVsFeatureMetadata;
        UILobby.lobbyGameIconImageSuffix = lobbyConfiguration?.lobbyGameIconImageSuffix;
        return UILobby;
    }
    isFilterRecentlyPlayedGames(catVsFeatureMetadata: any[], lobbySubCategory: any): boolean {
        let isFilterRPGames: boolean = false;
        if (catVsFeatureMetadata && catVsFeatureMetadata.length != 0) {
            catVsFeatureMetadata.forEach((metadata: any) => {
                if (metadata.subCategoryId == lobbySubCategory.subcategoryid && metadata.featureIds.indexOf('FilterRecentlyPlayedGames') > -1) {
                    isFilterRPGames = true;
                    return;
                }
            });
        }
        return isFilterRPGames;
    }
    filterCasiaCategory(catVsFeatureMetadata: any[], lobbySubCategory: any): boolean {
        if (catVsFeatureMetadata && catVsFeatureMetadata.length > 0) {
            const category: any = catVsFeatureMetadata.filter((item) => item.subCategoryId == lobbySubCategory.subcategoryid);
            if (category && category.length > 0) {
                return category[0].featureIds.indexOf('CasiaGamesYouMightLike') > -1;
            }
        }
        return false;
    }
    isFilterFeatureIds(catVsFeatureMetadata: any[], lobbySubCategory: any) {
        let featureIds: any = null;
        if (catVsFeatureMetadata && catVsFeatureMetadata.length > 0) {
            catVsFeatureMetadata.forEach((metadata: any) => {
                if (metadata.subCategoryId == lobbySubCategory.subcategoryid) {
                    featureIds =
                        metadata?.featureIds.length == 1
                            ? metadata?.featureIds
                            : { ...metadata?.featureIds.filter((item: any) => item !== 'FilterRecentlyPlayedGames') };
                    return featureIds[0];
                } else {
                    return featureIds;
                }
            });
        }
        return featureIds;
    }
    getLanguageContentMap(languageArray: any): Map<string, string> {
        const languageMap: Map<string, string> = new Map<string, string>();
        if (languageArray && languageArray.length > 0) {
            for (let i = 0; i < languageArray.length; i++) {
                const langItem: string = languageArray[i];
                if (langItem.indexOf(':') > -1) {
                    const langResolve: any = langItem.split(':');
                    languageMap.set(langResolve[0], langResolve[1]);
                }
            }
        }
        return languageMap;
    }
    getGameMetaDataMap(gameMetaDataArray: any, lobbyType: string): Map<string, string> {
        const gameMetaDataMap: Map<string, string> = new Map<string, string>();
        if (gameMetaDataArray && gameMetaDataArray.length > 0) {
            for (let i = 0; i < gameMetaDataArray.length; i++) {
                const gameItem: any = gameMetaDataArray[i];
                gameItem.metaLobbyType = lobbyType;
                gameMetaDataMap.set(gameItem.game, gameItem);
                this.gameMetaDataMap.set(gameItem.game, gameItem);
            }
            this.getUpdatedMetaDataPlayerStats();
        }
        return gameMetaDataMap;
    }
    setSearchCriteriaforCategoryGames() {
        const categoryGamesConfig = this.configProviderService.provideSearchConfig();
        if (categoryGamesConfig.enableCategorySearchCriteria && categoryGamesConfig.categoryGamesSearchCriteria.length > 0) {
            for (const key of this.lobbyTypeMaps.keys()) {
                for (let j = 0; j < categoryGamesConfig.categoryGamesSearchCriteria.length; j++) {
                    const categoryRoute = categoryGamesConfig?.categoryGamesSearchCriteria[j]?.categoryRoute?.trim()?.toLowerCase();
                    const category = this.getCategoryInfo(key, categoryRoute);
                    if (category && category.categoryInfo && category.categoryInfo.gamelist && category.categoryInfo.gamelist.length > 0) {
                        for (let k = 0; k < category.categoryInfo.gamelist.length; k++) {
                            const gameId = category.categoryInfo.gamelist[k].game;
                            const gameObject = this.gameMetaDataMap.get(gameId);
                            if (gameObject) {
                                gameObject.condition = categoryGamesConfig.categoryGamesSearchCriteria[j].clientDsl;
                                this.gameMetaDataMap.set(gameId, gameObject);
                            }
                        }
                    }
                }
            }
            this.getUpdatedMetaDataPlayerStats();
        }
    }
    getLobbyCategory(lobbyCategory: any, categoryTitleLangMap: any, lobbyType: string) {
        if (lobbyCategory) {
            const lmtCategoryId: string = lobbyCategory.categoryid;
            lobbyCategory.lmtCategoryId = lmtCategoryId;
            if (lobbyCategory.route) {
                if (lobbyCategory.categoryid === ConstService.LMC_GYML) {
                    lobbyCategory.isSubCatVisible = false;
                } else {
                    lobbyCategory.categoryid = lobbyCategory?.route?.replace(/\s/g, '')?.toLowerCase();
                    lobbyCategory.isSubCatVisible = true;
                }
                lobbyCategory.route = lobbyCategory.categoryid;
            }
            lobbyCategory.engCategoryName = cloneDeep(lobbyCategory.categoryname);
            // lobbyCategory.engCategoryName = {...lobbyCategory.categoryname};
            lobbyCategory.categoryname = this.getCategoryNameFromLangMap(lmtCategoryId, categoryTitleLangMap, lobbyCategory.categoryname);
            lobbyCategory.isNavigationCategory = this.getNavigationVisibility(lobbyCategory.isHidden);
            if (lobbyCategory.route) {
                lobbyCategory.sitecoreData = this.getSitecoreInfoForCategoryIdAndLobbyType(lobbyCategory.route, lobbyType);
            }
            const titleAndSubTitle: any = this.getTitleAndSubTitle(lobbyCategory);
            lobbyCategory.categoryname = titleAndSubTitle.title;
            lobbyCategory.subTitle = titleAndSubTitle.subTitle;
            return lobbyCategory;
        }
    }

    getLobbySubCategory(lobbySubCategory: any, categoryTitleLangMap: any, lobbyType: string) {
        if (lobbySubCategory) {
            const lmtCategoryId: string = lobbySubCategory.subcategoryid;
            lobbySubCategory.lmtCategoryId = lmtCategoryId;
            if (lobbySubCategory.route) {
                if (lobbySubCategory.subcategoryid === ConstService.LMC_GYML) {
                    lobbySubCategory.categoryid = lobbySubCategory.subcategoryid;
                    lobbySubCategory.isSubCatVisible = false;
                } else {
                    lobbySubCategory.categoryid = lobbySubCategory?.route?.replace(/\s/g, '')?.toLowerCase();
                    lobbySubCategory.isSubCatVisible = true;
                }
                lobbySubCategory.categoryid =
                    lobbySubCategory.subcategoryid === ConstService.LMC_GYML
                        ? lobbySubCategory.subcategoryid
                        : lobbySubCategory?.route?.replace(/\s/g, '')?.toLowerCase();
                lobbySubCategory.route = lobbySubCategory.categoryid;
            }
            lobbySubCategory.engCategoryName = cloneDeep(lobbySubCategory.subcategoryname);
            // lobbySubCategory.engCategoryName = {...lobbySubCategory.subcategoryname};
            lobbySubCategory.categoryname = this.getCategoryNameFromLangMap(lmtCategoryId, categoryTitleLangMap, lobbySubCategory.subcategoryname);
            lobbySubCategory.sitecoreData = this.getSitecoreInfoForCategoryIdAndLobbyType(lobbySubCategory.categoryid, lobbyType);
            lobbySubCategory.hideSubCategory =
                lobbySubCategory?.subcategoryid?.toLowerCase() === ConstService?.Recent_Games?.toLowerCase() ||
                lobbySubCategory?.subcategoryid?.toLowerCase() === ConstService.LMC_GYML;
            const titleAndSubTitle: any = this.getTitleAndSubTitle(lobbySubCategory);
            lobbySubCategory.categoryname = titleAndSubTitle.title;
            lobbySubCategory.subTitle = titleAndSubTitle.subTitle;
            return lobbySubCategory;
        }
    }
    getCategoryNameFromLangMap(categoryId: string, langMap: any, defaultCategoryName: string): string {
        if (langMap && langMap.size > 0 && langMap.get(categoryId)) {
            return langMap.get(categoryId);
        }
        return defaultCategoryName;
    }
    getNavigationVisibility(hiddenStatus: string) {
        if (hiddenStatus) {
            return hiddenStatus?.toLowerCase().trim() == 'false';
        }
        return true;
    }
    getTitleAndSubTitle(lobbyCategory: any): any {
        let subTitle: any;
        let title: any;
        const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        if (lobbyCategory) {
            title = lobbyCategory?.sitecoreData?.overridenCategoryname || lobbyCategory?.categoryname;
            if (lobbyCategory?.sitecoreData) {
                subTitle = lobbyCategory?.sitecoreData?.headerTitle;
            }
            if (featuresConfig.delimiterForDynamicGameNames && featuresConfig.delimiterForDynamicGameNames != '') {
                const splitArr: string[] = (lobbyCategory?.sitecoreData?.overridenCategoryname || lobbyCategory.categoryname).split(
                    featuresConfig.delimiterForDynamicGameNames,
                );
                title = splitArr[0];
                if (splitArr[1]) {
                    subTitle = splitArr[1];
                }
            }
        }
        const titleAndSubTitle = {
            title: title,
            subTitle: subTitle,
        };
        return titleAndSubTitle;
    }

    getSitecoreInfoForCategoryIdAndLobbyType(categoryid: string, lobbyType: string) {
        const optimizedTeaserConfig = this.configProviderService.provideOptimizedTeaserConfig();
        const gameSelectorWidgetConfig = this.configProviderService.provideGameSelectorWidgetConfig();

        const allLobbiesSitecoreData: any = this.getLobbySitecoreData();
        if (allLobbiesSitecoreData) {
            for (let i = 0; i < allLobbiesSitecoreData.length; i++) {
                if (allLobbiesSitecoreData[i].lobbyType == lobbyType) {
                    const lobbySitecoreData = allLobbiesSitecoreData[i].sitecoreData;
                    for (let j = 0; j < lobbySitecoreData.length; j++) {
                        const catSitecoreData = lobbySitecoreData[j];
                        if (catSitecoreData?.categoryId?.toLowerCase() == categoryid?.toLowerCase()) {
                            if (catSitecoreData?.jackpotWidget && catSitecoreData?.jackpotWidget.length > 0) {
                                catSitecoreData.jackpotWidgetIds = this.getJackpotList(catSitecoreData?.jackpotWidget);
                            }

                            if (
                                gameSelectorWidgetConfig &&
                                gameSelectorWidgetConfig.isEnabled &&
                                catSitecoreData?.displayGameSelectorWidget &&
                                catSitecoreData?.gameSelectorWidget
                            ) {
                                this.gameSelectorWidgetSource.push(catSitecoreData?.gameSelectorWidget);
                            }

                            if (
                                optimizedTeaserConfig.teaserOptimization &&
                                optimizedTeaserConfig.enableOptMiniTeasers &&
                                !this.isTouch() &&
                                catSitecoreData?.optimisedMiniTeasers &&
                                catSitecoreData?.optimisedMiniTeasers.length
                            ) {
                                this.optMiniTeasersSource.push(...catSitecoreData.optimisedMiniTeasers);
                            }

                            return catSitecoreData;
                        }
                    }
                }
            }
        }

        return {
            attributes: {
                icon: 'theme-home-i',
            },
        };
    }

    getJackpotList(jackpotWidget: any) {
        const jackpots: any[] = [];

        jackpotWidget.forEach((jp: any) => {
            if (jp != null) {
                jackpots.push(jp.itemName);
            }
        });
        return jackpots;
    }
    getCategoryNameFromSitecore(categoryid: string, lobbyType: string, fallbackCategoryId: boolean): string {
        const sitecoreData: any = this.getSitecoreInfoForCategoryIdAndLobbyType(categoryid, lobbyType);
        if (sitecoreData) {
            return sitecoreData.title;
        }
        if (fallbackCategoryId) {
            return categoryid;
        }
        return '';
    }
    getSubtitleFromSitecore(categoryid: string, lobbyType: string): string {
        const sitecoreData: any = this.getSitecoreInfoForCategoryIdAndLobbyType(categoryid, lobbyType);
        if (sitecoreData) {
            return sitecoreData.headerTitle;
        }
        return '';
    }
    getCategoryInfo(lobbyType: string, categoryRoute: string) {
        lobbyType = this.checkIfGlobalJackpotLobby(lobbyType);
        if (this.lobbyTypeMaps && this.lobbyTypeMaps.size > 0) {
            const lobbyInfo: LobbyItem = this.lobbyTypeMaps.get(lobbyType);
            if (lobbyInfo && lobbyInfo.categoriesMap && lobbyInfo.categoriesMap.size > 0) {
                const catInfo: any = lobbyInfo.categoriesMap.get(categoryRoute);
                const categoryObject: any = {
                    categoryInfo: catInfo,
                    categoryNameLangMap: lobbyInfo.categoryNameLangMap,
                    stickerNameMap: lobbyInfo.stickerNameMap,
                };
                return categoryObject;
            }
        }
    }

    getLobbyInfo(lobbyType: string): LobbyItem | null {
        if (this.lobbyTypeMaps && this.lobbyTypeMaps.size > 0) {
            const lobbyInfo: LobbyItem = this.lobbyTypeMaps.get(lobbyType);
            return lobbyInfo;
        }
        return null;
    }

    getCategoryInfoOnLMTId(lobbyType: string, linkedLMTCategoryId: string) {
        const odrLobbyType = this.checkIfGlobalJackpotLobby(lobbyType);
        lobbyType = this.isODR() || this.isSportsODR() ? odrLobbyType : lobbyType;
        const lobbyInfo: LobbyItem = this.lobbyTypeMaps.get(lobbyType);
        if (lobbyInfo && lobbyInfo.categoriesMap && lobbyInfo.categoriesMap.size > 0) {
            const linkedCategoryInfo: any = Array.from(lobbyInfo.categoriesMap.values()).filter(
                (categoryItem: any) => categoryItem.lmtCategoryId === linkedLMTCategoryId,
            );
            if (linkedCategoryInfo && linkedCategoryInfo.length > 0) {
                return linkedCategoryInfo[0];
            }
        }
    }

    getGameMetaData() {
        return this.gameMetaDataMap;
    }
    getGameInfo(gameId: string) {
        if (this.gameMetaDataMap && this.gameMetaDataMap.size > 0) {
            return this.gameMetaDataMap.get(gameId);
        }
        return null;
    }

    getGameDisplayName(gameId: string): string {
        if (this.gameMetaDataMap && this.gameMetaDataMap.size > 0) {
            const gameInfo: any = this.gameMetaDataMap.get(gameId);
            if (gameInfo) {
                return gameInfo.name;
            }
        }
        return '';
    }

    setGameIconNotFound(gameId: string, iconType: number = 1) {
        if (this.gameMetaDataMap && this.gameMetaDataMap.size > 0) {
            const gameInfo: any = this.gameMetaDataMap.get(gameId);
            if (gameInfo) {
                if (!gameInfo.isImageNotFound) {
                    gameInfo.isImageNotFound = new Map();
                }
                gameInfo.isImageNotFound.set(iconType.toString(), true);
            }
        }
    }

    isGameIconNotAvailable(gameId: string, iconType: number = 1): boolean {
        if (this.gameMetaDataMap && this.gameMetaDataMap.size > 0) {
            const gameInfo: any = this.gameMetaDataMap.get(gameId);
            if (gameInfo) {
                const gameIconMap = gameInfo.isImageNotFound;
                if (gameIconMap && gameIconMap.size > 0) {
                    return gameIconMap.get(iconType.toString());
                }
            }
        }
        return false;
    }

    isGameInCategory(game: any, categoryType: string) {
        const lobbyType: string = game.lobbyType;
        let categoryLookUp: any;
        categoryLookUp = this.lobbyTypeMaps.get(lobbyType);
        if (categoryLookUp) {
            const category: any = categoryLookUp.categoriesMap.get(categoryType);
            if (category && category.gamelist)
                category.gamelist.forEach((gid: any) => {
                    if (gid?.game?.toLowerCase() === game?.gameId?.toLowerCase()) return true;
                    return false;
                });
        }
        return false;
    }

    isDefaultCategory(lobbyType: string, categoryRoute: string): boolean {
        if (lobbyType && lobbyType != '' && categoryRoute && categoryRoute != '') {
            const defaultCategory: any = this.getDefaultOrFirstCategoryInfo(lobbyType);
            if (defaultCategory && defaultCategory.categoryInfo) {
                if (defaultCategory.categoryInfo.categoryid === categoryRoute) {
                    return true;
                }
            }
        }
        return false;
    }

    getDefaultOrFirstCategoryInfo(lobbyType: string) {
        let defaultCategory: any;
        let lobbyInfo: any;
        lobbyType = this.checkIfGlobalJackpotLobby(lobbyType);
        if (this.lobbyTypeMaps && this.lobbyTypeMaps.size > 0) {
            lobbyInfo = this.lobbyTypeMaps.get(lobbyType);
            if (lobbyInfo && lobbyInfo.categoriesMap && lobbyInfo.categoriesMap.size > 0) {
                const casinoConfig: any = this.configProviderService.provideCasinoConfig();
                const defaultCategoryOnDynacon: string = this.casinoManager.IsFeatureEnabledforLobbytype(
                    lobbyType,
                    casinoConfig.defaultyLobbyTypeCategory,
                );

                if (defaultCategoryOnDynacon && defaultCategoryOnDynacon != '') {
                    defaultCategory = lobbyInfo.categoriesMap.get(defaultCategoryOnDynacon);
                }
                if (!defaultCategory) {
                    defaultCategory = lobbyInfo.categoriesMap.entries().next().value[1];
                }
            }
        }
        if (defaultCategory && lobbyInfo) {
            const defaultCategoryObject: any = {
                categoryInfo: defaultCategory,
                categoryNameLangMap: lobbyInfo.categoryNameLangMap,
                stickerNameMap: lobbyInfo.stickerNameMap,
            };
            return defaultCategoryObject;
        }
    }

    getDefaultOrFirstCategoryInfoForInvokerProduct(lobbyType: string) {
        let defaultCategory: any;
        let lobbyInfo: any;
        if (this.lobbyTypeMapsForInvokerProduct && this.lobbyTypeMapsForInvokerProduct.size > 0) {
            lobbyInfo = this.lobbyTypeMapsForInvokerProduct.get(lobbyType);
            if (lobbyInfo && lobbyInfo.categoriesMap && lobbyInfo.categoriesMap.size > 0) {
                const casinoConfig: any = this.configProviderService.provideCasinoConfig();
                const defaultCategoryOnDynacon: string = this.casinoManager.IsFeatureEnabledforLobbytype(
                    lobbyType,
                    casinoConfig.defaultyLobbyTypeCategory,
                );

                if (defaultCategoryOnDynacon && defaultCategoryOnDynacon != '') {
                    defaultCategory = lobbyInfo.categoriesMap.get(defaultCategoryOnDynacon);
                }
                if (!defaultCategory) {
                    defaultCategory = lobbyInfo.categoriesMap.entries().next().value[1];
                }
            }
        }
        if (defaultCategory && lobbyInfo) {
            const defaultCategoryObject: any = {
                categoryInfo: defaultCategory,
                categoryNameLangMap: lobbyInfo.categoryNameLangMap,
                stickerNameMap: lobbyInfo.stickerNameMap,
            };
            return defaultCategoryObject;
        }
    }

    checkForTouchDevice() {
        try {
            this.isTouchDevice =
                !this.nativeAppService.isDownloadClient &&
                this.deviceService.isTouch &&
                !window.matchMedia('(pointer: fine)').matches &&
                !navigator.userAgent.match(/Win64/ || /WOW64/);
        } catch (error) {
            this.isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        }
    }

    isTouch(): boolean {
        return this.isTouchDevice;
    }

    searchGames(query: string) {
        this.gameDataFromLMT = this.gameMetaDataMap;
        query = query?.toLowerCase();
        const disabledGames = this.getDisabledGames();
        if (this.gameDataFromLMT && this.gameDataFromLMT.size > 0) {
            const filteredGames: any[] = [];
            this.gameDataFromLMT.forEach((data: any) => {
                if (data?.name?.toLowerCase().indexOf(query) !== -1) {
                    if (data.condition) {
                        this.dslService.evaluateExpression(data.condition).subscribe((result: boolean) => {
                            if (result) {
                                const filteredGame: any = disabledGames.filter((disabledGame: any) => disabledGame === data.game);
                                if (!(filteredGame && filteredGame.length > 0)) {
                                    filteredGames.push(this.setSearchGameIcon(data.game, data.name, data.provider, data.condition));
                                }
                            }
                        });
                    } else {
                        const filteredGame: any = disabledGames.filter((disabledGame: any) => disabledGame === data.game);
                        if (!(filteredGame && filteredGame.length > 0)) {
                            filteredGames.push(this.setSearchGameIcon(data.game, data.name, data.provider, data.condition));
                        }
                    }
                }
            });

            let sortedArray: any = [];
            const elementsStartingWithQuery: any = [];
            const elementsContainingQuery: any = [];
            if (filteredGames && filteredGames.length > 0) {
                filteredGames.forEach((fGame: any) => {
                    if (fGame?.title?.toLowerCase().indexOf(query) == 0) {
                        elementsStartingWithQuery.push(fGame);
                    } else {
                        elementsContainingQuery.push(fGame);
                    }
                });
            }
            sortedArray = sortedArray.concat(elementsStartingWithQuery);
            sortedArray = sortedArray.concat(elementsContainingQuery);
            return sortedArray;
        }
    }
    setSearchGameIcon(gameId: string, gameTitle: string, gameProvider: string, condition: string) {
        const gameConfig = this.configProviderService.provideGameConfig();
        const gamelobbyModel: GameLobbyModel = new GameLobbyModel();
        gamelobbyModel.gameId = gameId;
        gamelobbyModel.internalGameName = gameId;
        gamelobbyModel.title = gameTitle;
        gamelobbyModel.provider = gameProvider;
        gamelobbyModel.condition = condition;
        let gameIcon = gameConfig.iconTypeMapping[1];
        let gameFloder = this.getIconsuppix();
        if (gameFloder) {
            gameIcon = gameIcon + gameFloder;
        }
        gamelobbyModel.icon = {
            src: gameConfig.host + gameConfig.gameIconTemplate.replace('{0}', gameIcon).replace('{1}', gamelobbyModel.gameId) + '.jpg',
        };
        gamelobbyModel.rowSpan = 1;
        gamelobbyModel.colSpan = 1;
        return gamelobbyModel;
    }

    getNavCategories(lobbyType: string, productOverride?: string) {
        const featuresConfig = this.configProviderService.provideFeaturesConfig();
        const slotRacesConfig = this.configProviderService.provideSlotRacesConfig();
        const subNavigationConfig = this.configProviderService.provideSubNavigationConfig();
        const lobby: any = this.getLobby(lobbyType);
        const product: string = this.getProductForGivenLobbyType(lobbyType);
        const navCategories: any = [];
        const defaultCategoryInfo: any = this.getDefaultOrFirstCategoryInfo(lobbyType)?.categoryInfo;
        if (!productOverride && subNavigationConfig.hardProductOverride) {
            productOverride = subNavigationConfig.hardProductOverride;
        }
        if (lobby && lobby.lobby) {
            const slotRacesCategory = this.casinoManager.getSlotRaceCategoryId(lobbyType);
            const isSlotRacesEnabled = this.casinoManager.IsFeatureEnabledforLobbytype(lobbyType, featuresConfig.enableSlotRacesForLobbyType);
            const lobbyCategoriesMap: Map<string, any> = cloneDeep(lobby.lobby.categoriesMap);

            // let lobbyCategoriesMap: Map<string, any> = new Map<string, any>([...lobby.lobby.categoriesMap].map(x => Object.assign({}, x))) ;

            if (lobbyCategoriesMap && lobbyCategoriesMap.size > 0) {
                lobbyCategoriesMap.forEach((category: any) => {
                    let addCategory: boolean = true;
                    const isSlotRacesCategory = slotRacesCategory == category.categoryid;
                    const categoryurl = category?.sitecoreData?.categoryUrl;
                    if (category.isNavigationCategory) {
                        if (!category.sitecoreData) {
                            const defaultSitecoreObj: any = {
                                attributes: {
                                    icon: 'theme-home',
                                },
                            };
                            category.sitecoreData = defaultSitecoreObj;
                        }
                        if (defaultCategoryInfo && defaultCategoryInfo.categoryid === category.categoryid) {
                            category.isdefault = true;
                            //SEO C ges code
                            const lmtData = this.configProviderService.provideLmtConfig();

                            category.url = this.urlUtility.getProductApiBaseUrl(productOverride) + '/' + product;
                            if (
                                lmtData.enableDefaultLink &&
                                this.casinoManager.getProductForGivenLobbyType(lobbyType)?.toLowerCase() ==
                                    lmtData?.defaultProductHomeUrl?.toLowerCase()
                            ) {
                                category.url = this.urlUtility.getProductBaseUrl(productOverride) + '/';
                                if (lmtData.appendCultureToDefaultUrl) {
                                    category.url = `${category.url}/${this.page.lang}`;
                                }
                            }
                        } else {
                            category.url = categoryurl
                                ? categoryurl.url
                                : this.urlUtility.getProductApiBaseUrl(productOverride) + '/' + product + '/c/' + category.categoryid;
                        }
                        addCategory = this.addCategoryForLivegamesIframe(lobbyType);
                        if (
                            slotRacesCategory &&
                            isSlotRacesCategory &&
                            (!isSlotRacesEnabled || (!slotRacesConfig.enablePreLogin && !this.user.isAuthenticated))
                        ) {
                            addCategory = false;
                        }
                        const lmtSubCategories = category?.lmtSubCategories?.filter(Boolean);
                        if (
                            addCategory &&
                            (ConstService.RECENT_GAMES_CATEGORY_ID === category.categoryid?.toLowerCase() ||
                                ConstService.FAVOURITE_GAMES_CATEGORY_ID === category.categoryid?.toLowerCase() ||
                                category?.gamelist?.length > 0 ||
                                lmtSubCategories?.length > 0)
                        ) {
                            navCategories.push(category);
                        }
                    }
                });
            }
        }
        return navCategories;
    }

    addCategoryForLivegamesIframe(lobbyType: string): boolean {
        const casinoConfig = this.configProviderService.provideCasinoConfig();
        const liveGamesCategories = casinoConfig.liveGamesCategories;
        const currentProduct: string = this.getProductForGivenLobbyType(lobbyType);
        if (liveGamesCategories && liveGamesCategories.length > 0) {
            return !(liveGamesCategories.indexOf(currentProduct) > -1);
        }
        return true;
    }

    getNavCategoriesForInvokerProduct(lobbyType: string): any {
        const lobby: any = this.getLobbyForInvokerProduct(lobbyType);
        const product: string = this.getProductForGivenLobbyType(lobbyType);
        const navCategories: any = [];
        const defaultCategoryInfo: any = this.getDefaultOrFirstCategoryInfoForInvokerProduct(lobbyType);
        if (lobby && lobby.lobby) {
            const lobbyCategoriesMap: Map<string, any> = lobby.lobby.categoriesMap;
            if (lobbyCategoriesMap && lobbyCategoriesMap.size > 0) {
                lobbyCategoriesMap.forEach((category: any) => {
                    if (category.isNavigationCategory) {
                        if (!category.sitecoreData) {
                            const defaultSitecoreObj: any = {
                                attributes: {
                                    icon: 'theme-home',
                                },
                            };
                            category.sitecoreData = defaultSitecoreObj;
                        }
                        if (defaultCategoryInfo && defaultCategoryInfo.categoryid === category.categoryid) {
                            category.isdefault = true;
                            category.url = '/' + this.page.lang + '/' + product;
                        } else {
                            category.url = '/' + this.page.lang + '/' + product + '/c/' + category.categoryid;
                        }
                        navCategories.push(category);
                    }
                });
            }
        }
        return navCategories;
    }

    getLobby(lobbyType: string) {
        const lobbyInfo: any = this.lobbyTypeMaps.get(lobbyType);
        const lobbyResult: any = {
            lobby: lobbyInfo,
            disabledGames: this.disabledGames,
        };
        return lobbyResult;
    }

    getLobbyForInvokerProduct(lobbyType: string) {
        const lobbyInfo: any = this.lobbyTypeMapsForInvokerProduct.get(lobbyType);
        const lobbyResult: any = {
            lobby: lobbyInfo,
        };
        return lobbyResult;
    }

    getProductForGivenLobbyType(lobbyType: string): string {
        const lmtDataClientConfig: any = this.configProviderService.provideLmtConfig();
        const lobbyTypeMap: any = lmtDataClientConfig.lobbyTypeMap;
        for (let i = 0; i < lobbyTypeMap.length; i++) {
            if (lobbyTypeMap[i].lobbyType == lobbyType) {
                if (lobbyTypeMap[i].product) return lobbyTypeMap[i]?.product?.toLowerCase();
            }
        }
        return '';
    }
    getStickerMap(lobbyType: string) {
        const lobbyInfo: any = this.lobbyTypeMaps.get(lobbyType);
        if (lobbyInfo) {
            const stickerNameMap = lobbyInfo.stickerNameMap;
            return stickerNameMap;
        }
        return null;
    }

    setGlobalJackpotLobbyTypeMap(lobbyType: any, lobbyData: any) {
        const lmtConfig = this.configProviderService.provideLmtConfig();
        const lobbyContent: LobbyItem = this.getLobbyContent(lobbyData, lobbyType);
        this.lobbyTypeMaps.set(lobbyType, lobbyContent);
        this.gJLobbyTypeMaps.set(lobbyType, lobbyContent);
        this.cacheManager.writeCategoryLocalStorageCacheMap(
            lobbyType,
            cloneDeep(lobbyContent),
            //{...lobbyContent},
            this.invokerProductService.getInvokerProduct(),
        );
        if (this.disjointFeedsInitiated) {
        }
        // set globalBonusJackpots lobbyType if cookie is ODR
        const odrConfig =
            lmtConfig?.otherProductsLobbyTypeForOdr &&
            lmtConfig.otherProductsLobbyTypeForOdr[0]?.product &&
            lmtConfig.otherProductsLobbyTypeForOdr[0]?.lobbyType &&
            lmtConfig.otherProductsLobbyTypeForOdr[0]?.invokerProduct;
        if (odrConfig && (this.isODR() || this.isSportsODR())) {
            this.setPartialCasinoLobbiesforOtherProducts(lmtConfig.otherProductsLobbyTypeForOdr, true, false);
        } else {
            this.setCasinoLobby(true);
        }
    }

    getGlobalJackpotLobbyDataFromLocalStorage(lobbyType: string) {
        const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        const lobbyTypeMapsInCache: any = this.cacheManager.readCategoryDataLocalCache(
            featuresConfig.browserLocalStorageCategoryDataUpdateInterval,
            lobbyType,
            this.invokerProductService.getInvokerProduct(),
        );
        if (lobbyTypeMapsInCache != null) {
            const UILobby: LobbyItem = new LobbyItem();
            UILobby.setLobbyItemFromLocalStorage(lobbyTypeMapsInCache);
            // filling gameMetaDataMap
            for (let i = 0; i < lobbyTypeMapsInCache.lsGameMetaDataMap.length; i++) {
                const gameItem = lobbyTypeMapsInCache.lsGameMetaDataMap[i][1];
                this.gameMetaDataMap.set(gameItem.game, gameItem);
            }
            this.lobbyTypeMaps.set(lobbyType, UILobby);
            this.gJLobbyTypeMaps.set(lobbyType, UILobby);

            if (this.disjointFeedsInitiated) {
            } else {
                const postLogin = this.user.isAuthenticated ? true : false;
                this.setCasinoLobby(true, postLogin, true);
            }
            this.getUpdatedMetaDataPlayerStats();
            return UILobby;
        }
        return null;
    }

    getLobbyBasedOnLobbyType(lobbyType: string) {
        return this.lobbyTypeMaps && this.lobbyTypeMaps.get(lobbyType);
    }

    getCategoryName(lobbyType: string, categoryId: string) {
        const catInfo = this.getCategoryInfo(lobbyType, categoryId);
        let categoryName: string = '';
        if (catInfo && catInfo.categoryInfo) {
            categoryName = catInfo.categoryInfo.engCategoryName ? catInfo.categoryInfo.engCategoryName : '';
        }
        return categoryName;
    }

    getLinkedToCategory(lobbyType: string, lmtCategoryId: string) {
        const lobby: any = this.getLobby(lobbyType);
        if (lobby && lobby.lobby) {
            const lobbyCategoriesMap: Map<string, any> = cloneDeep(lobby.lobby.categoriesMap);

            // let lobbyCategoriesMap: Map<string, any> = new Map<string, any>([...lobby.lobby.categoriesMap].map(x => Object.assign({}, x))) ;

            if (lobbyCategoriesMap && lobbyCategoriesMap.size > 0) {
                const categoryArray: any = [];
                lobbyCategoriesMap.forEach((category) => {
                    if (category.linkedCategory === lmtCategoryId) {
                        categoryArray.push(category);
                    }
                });
                return categoryArray;
            }
        }
    }

    setGJPathSegment(pathsegment: string) {
        this.GJpathSegment = pathsegment;
    }

    checkIfGlobalJackpotLobby(lobbyType: string) {
        if (lobbyType) {
            const globaljackpotconfig = this.configProviderService.provideGlobalJackpotConfig();
            const isGJLobby = globaljackpotconfig.globalJackpotPathSegmentsArray.filter(
                (lobby: any) => lobby.lobbyType && lobby.lobbyType?.toLowerCase() == lobbyType?.toLowerCase(),
            );
            if (isGJLobby && isGJLobby.length > 0) {
                return lobbyType + '#' + this.GJpathSegment + '#';
            } else {
                return lobbyType;
            }
        } else return lobbyType;
    }

    raiseLobbyLoadedEvent(lobbyLoadedEvent: any) {
        lobbyLoadedEvent.invokerProduct = this.invokerProductService.getInvokerProduct();
        lobbyLoadedEvent.disabledGames = this.disabledGames;
        const evt = new CustomEvent('cc:casinocore_lobby_data', {
            detail: lobbyLoadedEvent,
        });
        this.windowRef.nativeWindow['casinoLobbyData'] = lobbyLoadedEvent;
        window.dispatchEvent(evt);
    }

    getFromCookie(key: any): boolean {
        if (this.cookies.get(key) > '0') {
            return true;
        }
        return false;
    }

    getDropShadow(category: any): string {
        const global: any = this.configProviderService.provideGlobalConfig();
        const darkMode = this.getFromCookie('dark-mode');
        let dropShadow: string;
        if (darkMode) {
            dropShadow = category?.sitecoreData?.attributes?.darkmodegametiledropshadow
                ? category?.sitecoreData?.attributes?.darkmodegametiledropshadow
                : global.lobbyConfigurations['darkModeGameTileDropShadow'];
        } else {
            dropShadow = category?.sitecoreData?.attributes?.gametiledropshadow
                ? category?.sitecoreData?.attributes?.gametiledropshadow
                : global.lobbyConfigurations['gameTileDropShadow'];
        }
        return dropShadow;
    }

    getBorderRadius(category: any): string {
        const global: any = this.configProviderService.provideGlobalConfig();
        const gameTileBorderRadius = category?.sitecoreData?.attributes?.gametileborderradius
            ? category?.sitecoreData?.attributes?.gametileborderradius
            : global.lobbyConfigurations['gameTileBorderRadius'];
        return !category.isIconSixCategory ? gameTileBorderRadius : null; //filtering out iconsix game icon
    }
    getLTileFontFamily(category: any): string {
        const global: any = this.configProviderService.provideGlobalConfig();
        const lobbyTileFontFamily = category?.sitecoreData?.attributes?.lobbytilefontfamily
            ? category?.sitecoreData?.attributes?.lobbytilefontfamily
            : global.lobbyConfigurations['lobbyTileFontFamily'];
        return lobbyTileFontFamily;
    }
    getsubCategoryTextColor(category: any): string {
        const global: any = this.configProviderService.provideGlobalConfig();
        const subCategoryTextColor = category?.sitecoreData?.attributes?.subcategorytextcolor
            ? category?.sitecoreData?.attributes?.subcategorytextcolor
            : global.lobbyConfigurations['subCategoryTextColor'];
        return subCategoryTextColor;
    }
    getsubCategoryTitleFontSize(category: any): string {
        const global: any = this.configProviderService.provideGlobalConfig();
        const subCategoryTitleFontSize = category?.sitecoreData?.attributes?.subcategorytitlefontsize
            ? category?.sitecoreData?.attributes?.subcategorytitlefontsize
            : global.messages['subCategoryTitleFontSize'];
        return subCategoryTitleFontSize;
    }
    getsubCategorySubTitleFontSize(category: any): string {
        const global: any = this.configProviderService.provideGlobalConfig();
        const subCategorySubTitleFontSize = category?.sitecoreData?.attributes?.subcategorysubtitlefontsize
            ? category?.sitecoreData?.attributes?.subcategorysubtitlefontsize
            : global.messages['subCategorySubTitleFontSize'];
        return subCategorySubTitleFontSize;
    }
    appendTheme(src: any, gameInfo: any) {
        const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        let appendTheme = '';
        if (featuresConfig?.gameIconTheming) {
            const { [gameInfo]: gameId } = featuresConfig.gameIconTheming;
            if (gameId) {
                for (const theme in gameId) {
                    if (gameId[theme]) {
                        appendTheme = theme;
                    }
                }
            }
        }
        if (appendTheme && src && src.indexOf(appendTheme) < 0) {
            const arr = src?.split('/');
            arr?.splice(arr.length - 1, 0, appendTheme);
            src = arr.join('/');
        }
        return src;
    }
    setMoreInfoComponentStatus(value: boolean) {
        this.isNewMoreInfoActive = value;
    }
    getMoreInfoComponentStatus() {
        return this.isNewMoreInfoActive;
    }

    calculateCoinArcadeExpiry(): number {
        const coinExpiryDay = this.configProviderService.providePromoCoinEconomyConfig()?.coinExpiryDay - 1;
        const currentDay = new Date().getDate();
        if (coinExpiryDay > currentDay) {
            return coinExpiryDay - currentDay;
        } else if (coinExpiryDay < currentDay) {
            const currentMonthDays = this.getDaysInMonth(new Date().getFullYear(), new Date().getMonth() + 1);
            return currentMonthDays - currentDay + coinExpiryDay;
        } else {
            return 0;
        }
    }

    getLastMonthArcadeExpiry(): string {
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const coinExpiryDay = this.configProviderService.providePromoCoinEconomyConfig()?.coinExpiryDay - 1;
        const currentDay = new Date().getDate();
        if (currentDay <= coinExpiryDay) {
            return monthNames[new Date().getMonth() - 1];
        } else {
            return monthNames[new Date().getMonth()];
        }
    }

    private getDaysInMonth(year: number, month: number): number {
        return new Date(year, month, 0).getDate();
    }

    getMinBetBasedOnGameId(gameId: string): any {
        const minBetGamesConfig = this.configProviderService.provideMinBetGamesConfig()?.coinArcade_MinBetGames;
        return minBetGamesConfig[gameId] ? minBetGamesConfig[gameId] : minBetGamesConfig['default'];
    }
    getIconsuppix() {
        const lobbyType = this.casinoManager.getLobbyType() || '';
        const lobbySuffix = this.getLobbyInfo(lobbyType);
        return lobbySuffix?.lobbyGameIconImageSuffix;
    }
    getImagePath(gameId: string, iconType: string, format?: any) {
        const gameConfig: any = this.configProviderService.provideGameConfig();
        let featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        let gameFloder = this.getIconsuppix();
        if (gameFloder && featuresConfig.enableGameIconImageSuffix) {
            iconType = iconType + gameFloder;
        }
        const imageFormat = format ? '.' + format : '.jpg';
        const gameUrl = gameConfig.host + gameConfig.gameIconTemplate.replace('{0}', iconType).replace('{1}', gameId) + imageFormat;
        return gameUrl;
    }
    initiatePostLoginFeatures(casinoNpmParams: any) {
        const cozybingoConfig: any = this.configProviderService.provideCozyBingoConfig();
        const fresSpinsConfig: any = this.configProviderService.providefreeSpinsClientConfig();
        if (cozybingoConfig.cozyBingoFavouritesInRpwidget || cozybingoConfig.cozyBingoRecentGamesInRpwidget) {
            this.cozybingoService.getBingoData(true, cozybingoConfig.cozyBingoRecentGamesInRpwidget);
        }

        if (fresSpinsConfig.isFreeSpinSummaryEnabled) {
            this.freespinSummaryService.setFreespinsummary();
        }
        const liveCasinoConfig = this.configProviderService.provideLiveCasinoConfig();
        if (!this.user.isAuthenticated || (this.user.isAuthenticated && !this.postloginfirstLiveCasinoCall)) {
            if (liveCasinoConfig.enabled && !casinoNpmParams?.isDisableLiveCasinoCalls) {
                this.liveCasinoService.getLiveCasinoData(!this.liveCasinoService.getSitecoreInfoPublishedStatus());
            }
            if (this.user.isAuthenticated) this.postloginfirstLiveCasinoCall = true;
        }
    }
    initiatePostLoginRemainingFeed(casinoNpmParams: any, initiateDisjointFeeds: boolean) {
        const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        const newsFeedConfig = this.configProviderService.provideNewsFeedCasinoConfig();
        if (newsFeedConfig.isEnabled) {
            this.newsFeedService.setNewsFeed();
        }
        if (featuresConfig.enableOptimoveFavProduct) {
            this.playerSegmentationService.setPlayerSegmentationData();
        }
        const slotRacesConfig = this.configProviderService.provideSlotRacesConfig();
        const widgetConfig: any = this.configProviderService.providewidgetConfig();
        if (
            (slotRacesConfig?.enableSlotRaces && !casinoNpmParams.disableSlotracesCallsOnBootstrap) ||
            (slotRacesConfig?.enableSlotRaces && widgetConfig?.enableSlotRacesForOtherProducts && casinoNpmParams?.disableSlotracesCallsOnBootstrap)
        ) {
            this.initializeSlotRaces(initiateDisjointFeeds);
        }
    }
    initiateJackpotandPromotionBanner(casinoNpmParams: any) {
        const jackpotDataClientConfig: any = this.configProviderService.provideJackpotConfig();
        const promotionsBannerConfig: any = this.configProviderService.providePromotionsBannerConfig();
        const feedCacheConfig: any = this.configProviderService.provideFeedCacheConfig();
        if (!this.user.isAuthenticated || (this.user.isAuthenticated && !this.postloginfirstJpSortCall)) {
            if (feedCacheConfig.enableJackpotFeed && !casinoNpmParams?.isDisableJackpotCalls) {
                this.jackpotDataService.stopFeed();
                this.jackpotDataService.startFeed();
            }
            if (jackpotDataClientConfig.enableJackpotSortGames) {
                this.jackpotDataService.fetchJackpotSortGames();
            }
            if (this.user.isAuthenticated) this.postloginfirstJpSortCall = true;
        }
        if (promotionsBannerConfig?.isEnablePromotionsBanner && this.deviceService.isMobilePhone && casinoNpmParams.isDll) {
            this.promotionsBannerService.fetchPromotionsBannerData();
        }
    }
    getUpdatedMetaDataPlayerStats() {
        if (this.gameMetaDataMap) {
            this.gameMetaDataPlayerStatsSubject.next(this.gameMetaDataMap);
        }
    }
}
