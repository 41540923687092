import { NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import {
    CacheManagerService,
    CasinoCoreGamelaunchService,
    CasinoLobbyService,
    ConfigProviderService,
    GameLaunch,
    ThumbnailService,
    UrlUtilityService,
} from '@casinocore/platform/core';
import { NavigationService, TrackingService, UserService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cc-thumbnail-newgame',
    templateUrl: 'thumbnail-newgame.component.html',
    standalone: true,
    imports: [NgIf, NgFor, NgStyle],
})
export class ThumbnailNewgameComponent implements OnInit, OnDestroy {
    @Input() category: any;
    @Input() isTouch: boolean;
    @Input() place: string = '';
    @Input() lobbyType: string;
    @Output() closeThumbnail: EventEmitter<boolean> = new EventEmitter();

    defaultSrc: string;
    game: string;
    data: any;
    displayThumbnail: boolean = false;
    config: any;
    isVipUser: boolean;
    fontColor: string = '';
    thumbnailBgColor: string = '';
    buttonText: string = '';
    gameConfig: any;
    thumbnailConfig: any;
    thumbnailObs: Subscription;
    constructor(
        private configProviderService: ConfigProviderService,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private user: UserService,
        private cacheManager: CacheManagerService,
        private tracking: TrackingService,
        private navigation: NavigationService,
        private urlUtilityService: UrlUtilityService,
        private casinoLobbyService: CasinoLobbyService,
        private thumbnailService: ThumbnailService,
    ) {}

    ngOnInit() {
        //get from session storage
        this.gameConfig = this.configProviderService.provideGameConfig();
        this.defaultSrc = this.urlUtilityService.getGrayImageUrl();
        this.thumbnailConfig = this.configProviderService.provideThumbnailConfig();
        this.isVipUser = this.user.claims.get('vipLevel') != null;
        this.setThumbnail(true);
        this.thumbnailObs = this.thumbnailService.thumbnailDataObservable.subscribe((thumbnailRes: any) => {
            if (thumbnailRes) {
                this.setThumbnail(false);
            }
        });
    }

    setThumbnail(onInit: boolean) {
        const thumnailCategory = this.casinoLobbyService.getThumbnailbasedonroute(this.category);
        if (thumnailCategory) {
            this.game = thumnailCategory.gameId;
            if (this.game != this.cacheManager.getThumbnailsfromLocalStorage(this.lobbyType)) {
                this.cacheManager.removeThumbnailfromLocalStorage(this.lobbyType);
                if (onInit) {
                    this.trackingInitEvent();
                }
                this.displayThumbnail = true;
                this.bindThumbnailData(thumnailCategory);
                //session games and category
                this.bindStyles(thumnailCategory);
                const feature: any = this.configProviderService.provideFeaturesConfig();
                if (this.game && this.data) {
                    this.data.src = this.casinoLobbyService.getImagePath(this.game, 'square');
                    if (feature.enableGameIconTheming) {
                        this.data.src = this.casinoLobbyService.appendTheme(this.data.src, this.game);
                    }
                }
            } else {
                this.closeThumbnail.emit(false);
            }
        }
        //}
    }
    bindThumbnailData(thumnailCategory: any) {
        this.data = thumnailCategory;
        this.config = this.thumbnailConfig.thumbnailConfigurations;
    }
    onGameIconError(src: any): void {
        this.defaultSrc = src;
    }
    action(game: any) {
        if (game) {
            const thumbnailBGColor = this.isVipUser
                ? this.thumbnailConfig.thumbnailConfigurations.VIPThumbnailColor
                : this.thumbnailConfig.thumbnailConfigurations.AnonymousThumbnailColor;
            const gameItem: any = {};
            gameItem.gameId = game;
            gameItem.isDemo = false;
            gameItem.iconType = 1;
            gameItem.lobbyType = this.lobbyType;
            const gameModel = new GameLaunch();
            gameModel.game = gameItem;
            gameModel.isTouch = this.isTouch;
            gameModel.gamePosition = 1;
            gameModel.categoryType = this.category.categoryid;
            gameModel.from = !this.user.isAuthenticated ? 'thumbnail' : '';
            gameModel.replaceRouteInHistory = false;
            gameModel.isDemoLaunch = false;
            gameModel.skipUnfinGame = false;
            gameModel.gameLaunchedFrom = 'thumbnail';
            gameModel.gridPosition = this.category.categoryIndex;
            gameModel.gameSection = 'thumbnail';
            gameModel.gameContainer = 'Page Container';
            gameModel.lobbyType = this.lobbyType;
            gameModel.highlightedColor = thumbnailBGColor;
            gameModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
            this.gameLaunchService.launchGame(gameModel);

            //update session storage
            this.displayThumbnail = false;
            this.hideThumbnail(true);
        }
    }

    bindStyles(thumnailCategory: any) {
        if (this.thumbnailConfig.thumbnailConfigurations) {
            if (!thumnailCategory.isVipThumbnail) {
                this.fontColor = this.thumbnailConfig.thumbnailConfigurations.AnonymousTextColor;
                this.thumbnailBgColor = this.thumbnailConfig.thumbnailConfigurations.AnonymousThumbnailColor;
                this.buttonText = this.thumbnailConfig.thumbnailConfigurations.AnonymousCTAText;
            } else {
                this.fontColor = this.thumbnailConfig.thumbnailConfigurations.VIPTextColor;
                this.thumbnailBgColor = this.thumbnailConfig.thumbnailConfigurations.VIPThumbnailColor;
                this.buttonText = this.thumbnailConfig.thumbnailConfigurations.VIPCTAText;
            }
        }
    }

    hideThumbnail(fromGameLaunch: boolean) {
        this.cacheManager.setThumbnailLocalStorageCacheMap(this.lobbyType, this.game);
        if (!fromGameLaunch) this.trackingCloseEvent();
        this.displayThumbnail = false;
        this.closeThumbnail.emit(false);
    }
    getLocation() {
        const defaultCategory = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(this.lobbyType).categoryInfo;
        let isDefaultCategory: any;
        if (defaultCategory && defaultCategory.categoryid?.toLowerCase() === this.category?.categoryid?.toLowerCase()) {
            isDefaultCategory = true;
        }
        return isDefaultCategory ? this.casinoLobbyService.getProductForGivenLobbyType(this.lobbyType) : this.navigation.location.url();
    }

    trackingInitEvent() {
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'Game Containers',
            'component.LabelEvent': 'Thumbnail',
            'component.ActionEvent': 'Displayed',
            'component.PositionEvent': this.place,
            'component.LocationEvent': this.getLocation(),
            'component.EventDetails': this.game + '-' + this.gameLaunchService.getGameLunchType(this.game),
            'component.URLClicked': 'thumbnail | ' + this.category.categoryid,
        });
    }
    trackingCloseEvent() {
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'Game Containers',
            'component.LabelEvent': 'Thumbnail',
            'component.ActionEvent': 'Close (x) - Click',
            'component.PositionEvent': this.place,
            'component.LocationEvent': this.getLocation(),
            'component.EventDetails': this.game + '-' + this.gameLaunchService.getGameLunchType(this.game),
            'component.URLClicked': 'thumbnail | ' + this.category.categoryid,
        });
    }
    ngOnDestroy() {
        if (this.thumbnailObs) {
            this.thumbnailObs.unsubscribe();
        }
    }
}
