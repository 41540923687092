import { Injectable, InjectionToken, inject } from '@angular/core';

import { LDContext } from 'launchdarkly-js-client-sdk';
import { firstValueFrom } from 'rxjs';

import { CookieName } from '../browser/cookie/cookie.models';
import { CookieService } from '../browser/cookie/cookie.service';
import { DeviceService } from '../browser/device/device.service';
import { GeolocationService } from '../lazy/service-providers/geolocation.service';
import { UserService } from '../user/user.service';

// TODO: Move to features/launch-darkly once DTP-37432 is implemented
/**
 * @whatItDoes Providers contexts for LaunchDarkly.
 *
 * @howToUse
 *
 * ```
 * @Injectable
 * export class CustomLaunchDarklyContextProvider implements LaunchDarklyContextProvider {
 *     constructor() {
 *     }
 *
 *     getProviders(): LDContext {
 *         return {            
                device: {
                    key: osName ?? 'device-os',
                    kind: 'device',
                    anonymous: false,                
                },              
        };
 *     }
 * }
 *
 * @provide
 *{ provide: LAUNCH_DARKLY_CONTEXT_PROVIDER, useClass: CustomLaunchDarklyContextProvider }
 * ```
 *
 * @stable
 */
export interface LaunchDarklyContextProvider {
    getProviders(): Promise<LDContext>;
}

/**
 * @stable
 */
export const LAUNCH_DARKLY_CONTEXT_PROVIDER = new InjectionToken<LaunchDarklyContextProvider>('launch-darkly-context-provider');

@Injectable()
export class LaunchDarklyDefaultContextProvider implements LaunchDarklyContextProvider {
    deviceService = inject(DeviceService);
    cookieService = inject(CookieService);
    userService = inject(UserService);
    geolocationService = inject(GeolocationService);

    async getProviders(): Promise<LDContext> {
        const { osName, isAndroid, isChrome, isMobile, isMobilePhone, isNexus, isRobot, isTablet, isTouch, isiOS } = this.deviceService;
        const trackerId = this.cookieService.get(CookieName.TrackerId);
        await firstValueFrom(this.geolocationService.whenReady);
        const geolocation = this.geolocationService.currentPosition?.mappedLocation;

        return {
            kind: 'multi',
            device: {
                key: osName || 'device-os',
                kind: 'device',
                deviceConfiguration: { isAndroid, isChrome, isMobile, isMobilePhone, isNexus, isRobot, isTablet, isTouch, isiOS },
            },
            user: {
                key: this.userService.id || 'anonymous-user',
                kind: 'user',
                name: this.userService.displayName,
            },
            trackerId: {
                key: trackerId || 'empty-trackerId',
            },
            state: {
                key: geolocation?.stateClient || 'state',
                kind: 'state',
            },
            country: {
                key: geolocation?.countryClient || 'country',
                kind: 'country',
            },
            geoCountry: {
                key: this.userService.geoCountry || 'geo-country',
                kind: 'geoCountry',
            },
        };
    }
}
