import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class GJackpotConstService {
    public static readonly WelcomeOverlay: string = 'WelcomeOverlay';
    public static readonly ParentCard: string = 'ParentCard';
    public static readonly WelcomeCard: string = 'WelcomeCard';
    public static readonly MainCard: string = 'MainCard';
    public static readonly SubCard: string = 'SubCard';
    public static readonly main: string = 'main';
    public static readonly overlay: string = 'overlay';
    public static readonly parent: string = 'parent';
    public static readonly sub: string = 'sub';
    public static readonly DisabledClaimedJackpots: string = 'DisabledClaimedJackpots';
    public static readonly ClaimCardShown: string = 'ClaimCardShown';
    public static readonly ClearCardShown: string = 'ClearCardShown';
    public static readonly JackpotCardsNotToShow: string = 'JackpotCardsNotToShow';
    public static readonly Success: string = 'SUCCESS';
    public static readonly LoginFailed: string = 'LOGIN_FAILED';
    public static readonly WinExpired: string = 'WIN_EXPIRED';
    public static readonly improperRefId: string = 'IMPROPER REF_ID';
    public static readonly alreadyInProgress: string = 'ALREADY_IN_PROGRESS';
    public static readonly alreadyInProgressOrDataNotAvilable: string = 'ALREADY_PROCESSED_OR_DATA_NOT_AVAILABLE';
    public static readonly ClaimJackpotsDetails: string = 'ClaimJackpotsDetails';
    public static readonly WelcomeOverlayShown: string = 'WelcomeOverlayShown';
    public static readonly TRIGGER_TIME: string = 'TRIGGER_TIME';
    public static readonly tablet: string = 'Tablet';
    public static readonly mobile: string = 'Mobile';
    public static readonly desktop: string = 'Desktop';
    public static readonly ParentCardReveal: string = 'ParentCard-Reveal';
    public static readonly MainCardReveal: string = 'MainCard-Reveal';
    public static readonly SubCardReveal: string = 'SubCard-Reveal';
    public static readonly Overlay: string = 'Overlay';
    public static readonly clearContainer: string = 'clear-container';
    public static readonly claimContainer: string = 'claim-container';
    public static readonly ClaimCard: string = 'ClaimCard';
    public static readonly ClearCard: string = 'ClearCard';
}
