import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csCasinoAsIFrameOverlayConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: casinoAsIFrameOverlayConfigFactory,
})
export class CasinoAsIFrameOverlayConfig {
    bottomNavCategoriesToLobbyTypeMap: any;
    isIFrameOverlay: any;
    appendOpenCasinoCoreOverlayQueryParam: boolean;
    hideBottomNav: boolean;
    overlayLobbyTypeMap: any;
    overlayBottomNavLobbyTypeMap: any;
    enableSearch: boolean;
}

export function casinoAsIFrameOverlayConfigFactory(service: LazyClientConfigService) {
    return service.get(CasinoAsIFrameOverlayConfig);
}
