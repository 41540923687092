import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle, SlicePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild, computed } from '@angular/core';

import {
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    CurrencyFormatPipe,
    CurrencySymbolPipe,
    RecentWinnersWidgetClientConfig,
    WinnersService,
} from '@casinocore/platform/core';
import { elementScroll } from '@casinocore/platform/utils';
import { DeviceService } from '@frontend/vanilla/core';
import { DslPipe } from '@frontend/vanilla/shared/browser';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Subject, takeUntil } from 'rxjs';
import { Swiper, SwiperOptions } from 'swiper/types';

import { CasinoCoreLazyPlaceholderComponent } from '../casino-core-lazy-placeholder/casino-core-lazy-placeholder.component';
import { GameLobbyComponent } from '../gamelobby/gamelobby.component';
import { RecentWinnersNewsFeedComponent } from './recent-winners-news-feed/recent-winners-news-feed.component';

@Component({
    selector: 'cc-recent-winners',
    templateUrl: 'recent-winners.component.html',
    standalone: true,
    imports: [
        NgClass,
        CasinoCoreLazyPlaceholderComponent,
        NgIf,
        NgFor,
        NgStyle,
        RecentWinnersNewsFeedComponent,
        GameLobbyComponent,
        AsyncPipe,
        SlicePipe,
        DslPipe,
        CurrencySymbolPipe,
        SwiperComponent,
        CurrencyFormatPipe,
    ],
})
export class RecentWinnersComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @ViewChild('winnersNavContainer', { static: false })
    winnersNavContainer: ElementRef<HTMLDivElement>;
    @ViewChild('cardScroll', { static: false })
    cardScroll: ElementRef<HTMLDivElement>;
    @Input() category: any;
    parallaxInput: any;
    isTouch: boolean;
    totalwinnersList: any[] = [];
    selectedTab: string = 'RECENT';
    navigation: boolean;
    pagination: boolean;
    enableNewsFeedOfWinnersFeed: boolean;
    fillingArray: any;
    filters: any;
    private destroy$ = new Subject<void>();
    triggerForMobileDevices: boolean;
    categoryInfo: any;
    displayFilterName: string = '';
    loadingIndicator: boolean;
    errorMsg: string;
    winnersCount: number;
    globalMessages: any;
    leftFilterScrollButton: boolean;
    rightFilterScrollButton: boolean;
    readonly isMobileView = this.deviceService.isMobileWidth;
    readonly isTabView = computed(() => {
        return this.deviceService.windowWidth() >= 768 && this.deviceService.windowWidth() <= 1024;
    });
    readonly isIpadPro = computed(() => {
        return this.deviceService.windowWidth() === 1366;
    });
    WinnersConfig: any;

    lobbyType: any;
    maximumGamesLength: number;
    lastElementIndex: number;
    enableLoadingIcon: boolean;
    filterMinWidth: any;
    swiperOptions: SwiperOptions = {
        autoHeight: true,
        allowTouchMove: false,
        spaceBetween: 1,
        slidesPerView: 5,
        pagination: true,
        navigation: true,
        slidesPerGroup: 5,
        keyboard: true,
        preventClicks: false,
        preventClicksPropagation: false,
        on: {
            init: (swiper) => this.onIndexChanged(swiper),
            navigationNext: () => this.trackSwiperNavigation('Next'),
            navigationPrev: () => this.trackSwiperNavigation('Prev'),
        },
    };

    constructor(
        private deviceService: DeviceService,
        private configProviderService: ConfigProviderService,
        private winnersWidgetService: WinnersService,
        private casinoManager: CasinoManager,
        private casinoLobbyService: CasinoLobbyService,
    ) {}

    ngOnChanges(): void {
        const categoryInfoData = this.casinoLobbyService.getCategoryInfo(this.casinoManager.lobbyType, this.category.categoryid);
        this.categoryInfo = categoryInfoData?.categoryInfo || {};
    }
    ngOnInit(): void {
        this.fillingArray = new Array(5);
        this.lobbyType = this.casinoManager.getLobbyType();
        this.checkDeviceType();
        this.triggerForMobileDevices = true;
        this.navigation = true;
        const latestWinnersConfig: RecentWinnersWidgetClientConfig = this.configProviderService.provideRecentWinnersWidgetConfig();
        this.WinnersConfig = this.configProviderService.provideRecentWinnersWidgetConfig()?.recentWinnerWidgetConfiguration;
        this.globalMessages = this.configProviderService.provideRecentWinnersWidgetConfig()?.recentWinnersWidgetGlobalMessages;
        this.enableLoadingIcon = latestWinnersConfig?.enableLoadingIcon;
        // let replacers = this.globalMessages?.FilterKeys.split(",");
        // console.log("replacers  " + JSON.stringify(replacers));
        // if (replacers)
        //   latestWinnersConfig?.filters.map((value: any, i: any) => (value.name = replacers[i]));

        this.filters = latestWinnersConfig?.filters.map((item: any) => {
            return {
                ...item,
                firstValue: this.globalMessages[item.value]?.split(',')[0] ? this.globalMessages[item.value]?.split(',')[0] : item.name,
                displayName: this.globalMessages[item.value]?.split(',').length > 1 ? this.globalMessages[item.value]?.split(',')[1] : item.name,
            };
        });
        // this.filters = latestWinnersConfig?.filters;
        this.winnersWidgetService.fetchRecentWinnersFeed(this.filters[0]?.value);
        this.enableNewsFeedOfWinnersFeed = latestWinnersConfig?.enableNewsfeedStyle;
        this.swiperOptions.pagination = latestWinnersConfig?.enablePagination;
        this.maximumGamesLength = latestWinnersConfig?.maximumGamesLength;
        this.parallaxInput = {
            sitecoreData: {
                backgroundImageLayer1: latestWinnersConfig?.recentWinnerWidgetConfiguration?.backgroundImageLayer1,
                backgroundImageLayer2: latestWinnersConfig?.recentWinnerWidgetConfiguration?.backgroundImageLayer2,
                backgroundImageLayer3: latestWinnersConfig?.recentWinnerWidgetConfiguration?.backgroundImageLayer3,
            },
        };
        this.displayFilterName = this.filters[0].displayName;
        this.getRecentWinners();
    }
    @HostListener('window:resize') onResize() {
        this.checkDeviceType();
    }
    checkDeviceType(): void {
        this.isTouch = this.casinoManager.isTouch();
    }
    FilterType(item: any, index: any): void {
        this.errorMsg = '';
        this.winnersCount = 0;
        this.selectedTab = item.value;
        this.displayFilterName = item.displayName;
        this.winnersWidgetService.fetchRecentWinnersFeed(this.selectedTab);
        this.loadingIndicator = true;
        //recent widgets tracking events
        this.winnersWidgetService.addTrackingEvent(
            'casino widgets',
            'recent winners widget',
            'click',
            index,
            this.category?.name,
            this.selectedTab,
            'not applicable',
        );
        if (this.isTouch) {
            if (this.cardScroll)
                this.cardScroll.nativeElement.scrollTo({
                    left: 0,
                    behavior: 'smooth',
                });
        }
    }
    getRecentWinners() {
        this.winnersWidgetService.recentWinnersObservable.pipe(takeUntil(this.destroy$)).subscribe(
            (data: any) => {
                if (data) {
                    if (data?.response?.winInfo && data?.response?.winInfo.length > 0) {
                        this.filterGamesArray(data?.response?.winInfo);
                        if (this.enableNewsFeedOfWinnersFeed) {
                            document?.documentElement?.style.setProperty('--blur-edge-color', this.globalMessages?.NewsFeedBlurEdgeColor);
                            document?.documentElement?.style.setProperty('--animation-timing', this.globalMessages?.NewsFeedAnimationTiming);
                        }
                        this.loadingIndicator = false;
                    } else {
                        this.totalwinnersList = [];
                        this.winnersCount = this.totalwinnersList ? this.totalwinnersList.length : 0;
                        this.errorMsg = this.globalMessages?.winnersResult + this.displayFilterName;
                        this.loadingIndicator = false;
                    }
                }
            },
            () => {
                this.loadingIndicator = false;
            },
        );
    }
    filterGamesArray(totalGames: any) {
        this.totalwinnersList = [];
        const filterList: any = [];
        if (totalGames) {
            totalGames.forEach((item: any, index: any) => {
                const gameInfo = this.casinoLobbyService.getGameInfo(item.gvName);
                if (gameInfo) {
                    item.index = index;
                    item = Object.assign(item, gameInfo);
                    item.gameId = item.gvName;
                    item.icon = '2';
                    item.name = gameInfo?.name;
                    item.widgetName = 'RecentWinners';
                    item.currentTab = this.displayFilterName;
                    item.category = this.categoryInfo.categoryid;
                    item.lobbyType = this.casinoManager.getLobbyType();
                    const currentWidth = this.casinoManager.getCurrentWidth();
                    item.infoIconClass = this.casinoManager.assignGameClass(currentWidth, item.icon);
                    filterList.push(item);
                }
            });
            this.totalwinnersList = filterList?.slice(0, this.maximumGamesLength);
            this.winnersCount = this.totalwinnersList?.length;
            if (this.winnersCount == 0) {
                this.errorMsg = this.globalMessages?.winnersResult + this.displayFilterName;
            }
        }
    }
    onMoveFilterContainer(isLeft: boolean, event: Event) {
        const { showLeft, showRight } = elementScroll(this.winnersNavContainer.nativeElement, 148, isLeft, 0, event);
        this.leftFilterScrollButton = showLeft;
        this.rightFilterScrollButton = showRight;
        //recent widgets tracking events
        const PositionClick: string = isLeft ? 'prev' : 'next';
        this.winnersWidgetService.addTrackingEvent(
            'casino widgets',
            'recent winners widget',
            'click',
            'not applicable',
            this.category.name,
            PositionClick,
            'not applicable',
        );
    }

    private adjustfiltersContainerScroll(): void {
        const latestWinnersConfig: RecentWinnersWidgetClientConfig = this.configProviderService.provideRecentWinnersWidgetConfig();
        const itemWidth: HTMLElement | null = this.winnersNavContainer?.nativeElement?.querySelector('.filter-item');
        this.filterMinWidth = latestWinnersConfig.enableFilterWidth ? 140 + 'px' : 'auto';
        if (itemWidth) {
            const itemDoubleWidth = itemWidth?.clientWidth + itemWidth?.offsetLeft;
            const winnerWidth = this.winnersNavContainer?.nativeElement?.clientWidth;
            const isWinnerSmaller = itemDoubleWidth >= winnerWidth;
            const reducedWidth = itemDoubleWidth - winnerWidth;
            const newOffset = !isWinnerSmaller ? 0 : reducedWidth <= 148 ? 148 : reducedWidth;

            if (!this.winnersNavContainer?.nativeElement?.scrollLeft) {
                const { showLeft, showRight } = elementScroll(this.winnersNavContainer?.nativeElement, newOffset, false, 0, event);
                this.leftFilterScrollButton = showLeft;
                this.rightFilterScrollButton = showRight;
            }
        }
    }
    touchStarted() {
        this.triggerForMobileDevices = false;
    }
    touchReleased() {
        this.triggerForMobileDevices = true;
    }
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private trackSwiperNavigation(eventDetails: 'Next' | 'Prev'): void {
        this.winnersWidgetService.addTrackingEvent(
            'casino widgets',
            'recent winners widget',
            'click',
            'not applicable',
            this.category.name,
            eventDetails,
            'not applicable',
        );
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.adjustfiltersContainerScroll();
        }, 500);
    }

    private onIndexChanged(swiper: Swiper): void {
        if (swiper.slides.length) {
            this.lastElementIndex = swiper.realIndex + 5;
        }
    }
    getDecimal(data: number) {
        // this.setFontsize(data);
        const latestWinnersConfig: RecentWinnersWidgetClientConfig = this.configProviderService.provideRecentWinnersWidgetConfig();
        const dataString = data.toLocaleString();
        return dataString.length <= latestWinnersConfig.maxNumbersForDecimal ? dataString + '.00' : dataString;
    }
}
