export class GameLobbyModel {
    gameId: string;
    icon: any;
    internalGameName: string;
    ownerId: string;
    launchType: any;
    title: string;
    isDataNotAvailable: boolean = false;
    jackpotvalue: string;
    isFavourite: boolean;
    sticker: string;
    rowSpan: number;
    colSpan: number;
    isUnfinished: boolean;
    isFastLoadingGame: boolean = false;
    infoIconClass: string = '';
    lobbyType: string = '';
    iconType: string;
    iconTypeForTracking: string;
    format: string = 'jpg';
    secondsToStartTable: number;
    name: string;
    provider: string;
    gameSection: string;
    gameContainer: string;
    condition: string;
    gameTileBorderRadius: string;
    gameTileDropShadow: string;
    newgridbgcolor: string;
    amazonstylebottombgclr: string;
    gameIndex: number;
    bgTileMode: boolean;
    jackpotValue: string;
    widgetName: string;
    isPromoted: boolean;
    isGYML: boolean;
    enableDetailPage: boolean;
    forceDetailPage: boolean;
    isHotJackpotGroup: boolean;
    fromCasino?: boolean;
    constructor() {}
}
