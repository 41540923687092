import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csFeedCacheConfiguration', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: feedCacheClientConfigFactory,
})
export class FeedCacheClientConfig {
    disabledCacheTimeOut: number;
    disabledRequestThreshold: number;
    enableDisabledGames: boolean;
    favouriteCacheTimeOut: number;
    favouriteRequestThreshold: number;
    enableFavoritesFeed: boolean;
    jackpotCacheTimeOut: number;
    jackpotRequestThreshold: number;
    enableJackpotFeed: boolean;
    freeSpinsCacheTimeOut: number;
    freespinRequestThreshold: number;
    enableFreespinsFeed: boolean;
    recentCacheTimeOut: number;
    recentRequestThreshold: number;
    enableRecentlyPlayed: boolean;
    unFinishedCacheTimeOut: number;
    unFinishedRequestThreshold: number;
    enableUnfinishedGamesFeed: boolean;
    lmtCacheThreshold: number;
    enableLMTFeedCache: boolean;
}

export function feedCacheClientConfigFactory(service: LazyClientConfigService) {
    return service.get(FeedCacheClientConfig);
}
