import { LowerCasePipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import {
    BingoGameLaunchService,
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    DesignSystemEnablerService,
    IntersectionObserverDirective,
    LongPressDirective,
    OptimizedTeaserManager,
    SitecoreImageResizePipe,
    TeaserService,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, GameTileCtaComponent } from '@frontend/casino-ui';
import { DeviceService, Logger, NavigationService, TrackingService } from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Subject, Subscription, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { OptMiniTeasersNpmComponent } from './opt-mini-teasers-npm.component';
import { OptTeaserItemNpmComponent } from './opt-teaser-item-npm.component';

@Component({
    selector: 'cc-opt-teasers-npm',
    templateUrl: 'opt-teasers-npm.component.html',
    styles: ['.ProgressBar{background:green; height:10px}'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        NgIf,
        IntersectionObserverDirective,
        LongPressDirective,
        NgFor,
        OptTeaserItemNpmComponent,
        MatProgressBarModule,
        OptMiniTeasersNpmComponent,
        LowerCasePipe,
        TrustAsHtmlPipe,
        SitecoreImageResizePipe,
        SwiperComponent,
        GameTileCtaComponent,
        DecoratorClassDirective,
    ],
})
export class OptTeasersNpmComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input() identifierClass: string;
    @Input() teaserCategory: string;
    @Input() teaserLocation: string;
    @Input() teasersSource: any;
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    @ViewChild('swiper') swiperRef: SwiperComponent;
    //added for Teaser NPM
    @Input() globalTeaserSettings: any;
    @Input() isNPM: boolean;
    @Input() teaserDynaconConfigs: any;
    @Input() enableTeaserOptimization: boolean;
    @Input() optminiTeaserSource: any;
    @Input() enableMiniTeasers: boolean;
    @Input() mercuryBgImage: any;
    @Input() isTouch: boolean;
    @Input() enablePlayBingoButton: boolean;
    @Input() bingocta: any;
    @Input() isNewSportsNative: any;
    @Input() showJackpotCurrencyOnRight: any;
    @Input() jackpotGroupNameMapTeaserNPM: any;
    @Input() globalTeaserStyles: any;
    //added for Teaser NPM
    teasers: any;
    destroy$: Subject<any> = new Subject<any>();
    orientationSubscription: Subscription;
    isOptMiniTeasersEnabled: boolean = false;
    hideTeasersPagination: boolean = false;
    optMiniTeasers: any;
    optimizedTeaserClientConfig: any;
    optTeaser: boolean;
    arrowClass: string = '';
    miniArrowClass: string = '';
    teaserType: string = '';
    displayHeight: number;
    teaserHeight: number;
    teaserDefaultSliderArrowBg: string = '';
    teaserDefaultSliderArrowShape: string = '';
    teaserDefaultSliderBullet: string = '';
    showTimer: boolean;
    teaserProgressbar: string = '';
    widths: any;
    progressBarInterval: any;
    displayProgressBar: boolean;
    currentIndex: number = 0;
    isOptTeaserPhase3: boolean;
    isMouseOverOnTeaser: boolean;
    isVideoTeaser: boolean;
    noOfVideoTeasers: number = 0;
    activeSlides: any = {};
    enableLobbyBackgroundImage: boolean;
    swiperIndexSettimeoutValue: number;
    teaserDivIsInViewport: boolean;
    miniTeaserGridAlignment: string = '';
    miniTeaserEnabledClass: string = '';
    enablebingoplay: boolean;
    openBingoLobbyButtonPosition: string = '';
    teasersConfigChanged: boolean;
    enableEpcotSubNavigation: boolean;
    enablePagenation: string;
    isLegacy: boolean = false;

    swiperOptionsOptTeasersNpm: SwiperOptions = {
        modules: [Navigation, Pagination, Autoplay],
        loop: false,
        autoHeight: true,
        allowTouchMove: true,
        spaceBetween: 1,
        direction: 'horizontal',
        centeredSlides: false,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
            waitForTransition: false,
        },
        pagination: {
            type: 'bullets',
            clickable: true,
            el: '.swiper-pagination',
        },
        navigation: false,
        rewind: true,
        keyboard: true,
        watchOverflow: true,
        mousewheel: false,
        scrollbar: false,
        preventClicks: true,
        preventClicksPropagation: true,
    };

    constructor(
        private configProviderService: ConfigProviderService,
        private casinoLobbyService: CasinoLobbyService,
        private navigation: NavigationService,
        private tracking: TrackingService,
        private logger: Logger,
        private casinoManager: CasinoManager,
        private optimizedTeaserManager: OptimizedTeaserManager,
        private teaserService: TeaserService,
        private zone: NgZone,
        private cdr: ChangeDetectorRef,
        private bingoGameLaunchService: BingoGameLaunchService,
        private deviceService: DeviceService,
        private designSystemEnablerService: DesignSystemEnablerService,
    ) {}

    ngOnChanges() {
        this.teasersConfigChanged = false;
        //this.teasers = this.teasersSource;
        this.teasers = this.teasersSource.filter((m: any) => m.data.fields.iconTileCode == null);
        setTimeout(() => {
            this.swiperOptionsOptTeasersNpm.loop = this.teasers?.length > 1 ? true : false;
            if (this.noOfVideoTeasers > 0) this.findActiveSlides();
            this.teasersConfigChanged = true;
            this.widths = new Array(this.teasers.length).fill(0);
        }, 100);
        if (!this.isNPM) {
            this.isTouch = this.casinoManager.isTouch();
            this.globalTeaserSettings = this.optimizedTeaserManager.getGlobalTeaserSettings();
        }
        if (this.isTouch) {
            this.swiperOptionsOptTeasersNpm.navigation = false;
        } else {
            this.swiperOptionsOptTeasersNpm.navigation = {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            };
        }
        this.optimizedTeaserClientConfig = this.isNPM ? this.teaserDynaconConfigs : this.configProviderService.provideOptimizedTeaserConfig();
        this.showTimer = this.globalTeaserSettings?.sharedList?.showTimer?.toLowerCase() == 'true' ? true : false;
        this.isNPM ? this.setOptMiniTeasersNPM() : this.setOptMiniTeasers();
        if (this.showTimer && this.teasers.length > 1 && !this.isOptMiniTeasersEnabled) {
            this.displayProgressBar = this.teaserLocation === 'Top' || this.teaserLocation === 'SubNavigation';
        } else {
            this.displayProgressBar = false;
        }
        const config: any = this.isNPM ? this.teaserDynaconConfigs : this.configProviderService.provideFeaturesConfig();
        this.enableLobbyBackgroundImage = config.enableLobbyBackgroundImage;
        this.swiperIndexSettimeoutValue = config.teaserSetIndexTimeout;
        if (this.globalTeaserSettings && this.globalTeaserSettings.sharedList) {
            this.teaserType = this.globalTeaserSettings.sharedList.teaserType || '';
            this.isOptTeaserPhase3 = this.teaserType.toLowerCase() === 'hydrogen' || this.teaserType.toLowerCase() === 'lithium' ? true : false;
            if (this.teaserType == 'lithium') {
                this.teaserType = 'lithium hydrogen';
            }
            this.teaserDefaultSliderArrowBg = this.globalTeaserSettings.sharedList.teaserDefaultSliderArrowBg || '';
            this.teaserDefaultSliderArrowShape = this.globalTeaserSettings.sharedList.teaserDefaultSliderArrowShape || '';
            this.teaserDefaultSliderBullet = this.globalTeaserSettings.sharedList.teaserDefaultSliderBullet || '';
            this.miniTeaserGridAlignment = this.globalTeaserSettings.sharedList.miniTeaserGridAlignment || '';
            this.openBingoLobbyButtonPosition = 'casino-playbingo-btn' + this.globalTeaserSettings.sharedList.openBingoLobbyButtonPosition;
        }
        this.setTeaserHeight();
        this.handleOrientation();
        this.arrowClass = this.teasers?.length > 1 ? 'opt-arrows-multiple' : '';
        if (this.teasers?.length) {
            this.noOfVideoTeasers = this.teasers.filter((m: any) => m.isVideoUrl && m.videoUrl).length;
            if (this.showTimer || this.noOfVideoTeasers) {
                this.teaserProgressbar = 'teaser-progress-bar';
                this.swiperOptionsOptTeasersNpm.autoplay = false;
            } else {
                this.swiperOptionsOptTeasersNpm.loop = true;
                this.teaserProgressbar = '';
            }
        }
    }

    ngOnInit() {
        this.casinoManager.lobbyObservable.pipe(takeUntil(this.destroy$)).subscribe((lobbyModel: any) => {
            if (lobbyModel) {
                this.optTeaser = true;
                setTimeout(() => {
                    this.arrowClass = this.teasers?.length > 1 ? 'opt-arrows-multiple' : '';
                    this.miniArrowClass =
                        this.optimizedTeaserClientConfig.teaserOptimization &&
                        this.optimizedTeaserClientConfig.enableOptMiniTeasers &&
                        !this.isTouch &&
                        this.optMiniTeasers?.length > 1
                            ? 'opt-arrows-multiple'
                            : '';
                }, 1000);
            }
        });
        if (this.isNPM) {
            this.enablebingoplay = this.enablePlayBingoButton;
        } else {
            const features: any = this.configProviderService.provideFeaturesConfig();
            this.enablebingoplay = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.enablePlayBingoButton);
            this.bingocta = this.configProviderService.provideMcPageConfig().playBingoButton;
            this.enableEpcotSubNavigation = this.configProviderService.provideSubNavigationConfig()?.enableEpcotSubNavigation;
            this.enablePagenation = this.globalTeaserSettings.sharedList.enablePagination?.toLowerCase() == 'true' ? 'true' : 'false';
        }
        if (this.enablebingoplay) {
            this.playbingoTracking('Displayed');
        }
        this.isLegacy = !this.designSystemEnablerService.isEnabled();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.swiperRef) {
                this.swiperRef.swiper.on('slideChange', (event: any) => {
                    this.onTeaserIndexChanged(event);
                });
                setTimeout(() => {
                    this.swiperRef.swiper.update();
                    if (this.enableLobbyBackgroundImage && (this.teaserLocation === 'Top' || this.teaserLocation === 'SubNavigation')) {
                        this.teaserService.indexChangeTeaserData(this.teasers[0]);
                    }
                }, this.swiperIndexSettimeoutValue);
            }
        }, 200);
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
        if (this.orientationSubscription) this.orientationSubscription.unsubscribe();
    }

    private setTeaserHeight() {
        if (this.deviceService.windowWidth() <= 767) {
            this.teaserHeight = this.optimizedTeaserClientConfig.mobileViewTeaserHeight;
        } else if (this.deviceService.windowWidth() <= 1024) {
            this.teaserHeight = this.optimizedTeaserClientConfig.tabletViewTeaserHeight;
        } else {
            this.teaserHeight = this.optimizedTeaserClientConfig.desktopViewTeaserHeight;
        }
        this.displayHeight = this.teaserHeight;
        if (!this.isOptMiniTeasersEnabled && this.showTimer && this.teasers.length > 1) {
            this.displayHeight += this.deviceService.windowWidth() <= 767 ? 3 : 6;
        }
        this.cdr.detectChanges();
    }

    private handleOrientation() {
        this.zone.runOutsideAngular(() => {
            this.orientationSubscription = fromEvent(window, 'onorientationchange' in window ? 'orientationchange' : 'resize')
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    setTimeout(() => {
                        this.setTeaserHeight();
                    }, 0);
                });
        });
    }

    private initOptMiniTeasers() {
        this.mercuryBgImage = undefined;
        this.isOptMiniTeasersEnabled = false;
        this.optMiniTeasers = [];
        this.miniTeaserGridAlignment = '';
        this.miniTeaserEnabledClass = '';
    }

    private setOptMiniTeasers() {
        if (this.optimizedTeaserClientConfig.teaserOptimization && this.optimizedTeaserClientConfig.enableOptMiniTeasers) {
            const category = this.casinoLobbyService.getCategoryInfo(this.lobbyType, this.identifierClass.toLowerCase());
            if (this.isTouch) {
                this.hideTeasersPagination = this.globalTeaserSettings.sharedList.showPaginationDots == 'false';
            } else {
                if (
                    category.categoryInfo &&
                    category.categoryInfo.sitecoreData.optimisedMiniTeasers &&
                    category.categoryInfo.sitecoreData.optimisedMiniTeasers.length
                ) {
                    this.teaserService.optMiniTeasersMapObservable.pipe(takeUntil(this.destroy$)).subscribe((isReady: boolean) => {
                        if (isReady) {
                            const path = category.categoryInfo.sitecoreData.optimisedMiniTeasers[0].path;
                            const optMiniTeasers = this.teaserService.getOptMiniTeasers(path);
                            if (this.teaserService.mercuryBgImage) {
                                this.mercuryBgImage = this.teaserService.mercuryBgImage.image;
                            }
                            this.isOptMiniTeasersEnabled = optMiniTeasers && optMiniTeasers.length > 0;
                            this.optMiniTeasers = optMiniTeasers;
                            if (this.showTimer && this.teasers.length > 1 && this.isOptMiniTeasersEnabled) {
                                this.displayProgressBar = false;
                                this.setTeaserHeight();
                            }
                            this.miniTeaserEnabledClass = this.isOptMiniTeasersEnabled ? 'casino-opt-mini-whole-bg' : '';
                        }
                    });
                } else {
                    this.initOptMiniTeasers();
                }
            }
        }
    }

    //added for Teaser NPM
    private setOptMiniTeasersNPM() {
        if (this.enableTeaserOptimization && this.enableMiniTeasers) {
            this.isOptMiniTeasersEnabled = this.enableMiniTeasers;
            this.optMiniTeasers = this.optminiTeaserSource;
            this.hideTeasersPagination = this.globalTeaserSettings.sharedList.showPaginationDots == 'false';
            if (this.showTimer && this.teasers.length > 1 && this.isOptMiniTeasersEnabled) {
                this.displayProgressBar = false;
                this.setTeaserHeight();
            }
            this.miniTeaserEnabledClass = this.isOptMiniTeasersEnabled ? 'casino-opt-mini-whole-bg' : '';
        }
    }
    //added for Teaser NPM

    onTeaserHover(hovered: boolean) {
        if (!this.isTouch && !this.isOptMiniTeasersEnabled) {
            this.isMouseOverOnTeaser = hovered;
        }
    }

    onTeaserLongPress(longPressed: boolean) {
        if (this.isTouch && !this.isOptMiniTeasersEnabled) {
            this.isMouseOverOnTeaser = longPressed;
        }
    }

    startProgressBar() {
        if (!this.isVideoTeaser) {
            this.progressBarInterval = setInterval(() => {
                this.zone.run(() => {
                    this.incrementProgressBar();
                });
            }, 25);
        }
    }

    incrementProgressBar() {
        if (!this.isMouseOverOnTeaser) {
            if (this.widths[this.currentIndex] < 100) {
                this.widths[this.currentIndex] = this.widths[this.currentIndex] + 0.5;
            } else {
                this.onProgressBarEnd();
                setTimeout(() => {
                    this.slideNext();
                }, 100);
            }
        }
    }

    slideNext() {
        if (this.swiperRef?.swiper && !this.isVideoTeaser) {
            this.swiperRef.swiper.animating = false;
            this.swiperRef.swiper.slideNext(300);
        }
    }

    onProgressBarEnd() {
        if (this.progressBarInterval) {
            clearInterval(this.progressBarInterval);
            this.progressBarInterval = null;
        }
    }

    findActiveSlides() {
        this.activeSlides = this.teasers.reduce((acc: any, curr: any, i: number) => {
            if (curr.isVideoUrl) {
                acc[i] = [i + 1];
            }
            return acc;
        }, {});
        if (this.swiperOptionsOptTeasersNpm.loop && Object.keys(this.activeSlides).length > 0) {
            if (this.activeSlides[0]) this.activeSlides[0]?.splice(0, 0, this.teasers.length + 1);
            if (this.activeSlides[this.teasers.length - 1]) this.activeSlides[this.teasers.length - 1]?.push(0);
        }
    }

    onTeaserIndexChanged(swiperObj: any) {
        let realIndex = 0,
            activeIndex = 0;
        if (swiperObj) {
            realIndex = swiperObj.realIndex;
            activeIndex = swiperObj.activeIndex;
        }
        if (this.progressBarInterval) {
            clearInterval(this.progressBarInterval);
            this.progressBarInterval = null;
        }
        if (
            this.enableLobbyBackgroundImage &&
            (this.teaserLocation === 'Top' || this.teaserLocation === 'SubNavigation') &&
            this.teaserDivIsInViewport
        )
            this.teaserService.indexChangeTeaserData(this.teasers[realIndex]);
        this.widths[this.currentIndex] = 0;
        this.currentIndex = realIndex;
        if (this.isOptTeaserPhase3) this.setTeaserVideo(realIndex, activeIndex);
        try {
            if (this.teasers[realIndex].isVideoUrl && this.teasers[realIndex].videoUrl) {
                this.isVideoTeaser = true;
            } else {
                this.isVideoTeaser = false;
                if (swiperObj && !swiperObj.autoplay?.running) this.startProgressBar();
            }
        } catch (err) {
            this.logger.error('Exception in onTeaserIndexChanged function of cc-optimized-teasers component: ' + err);
        }
    }
    setTeaserVideo(realIndex: number, activeIndex: number) {
        const json = {
            identifierClass: this.identifierClass,
            realIndex: realIndex,
            activeIndex: activeIndex,
        };
        this.teaserService.setIndex(json);
    }

    getVideoEndEvent() {
        setTimeout(() => {
            if (!this.isMouseOverOnTeaser && this.swiperRef?.swiper && this.isVideoTeaser && this.widths[this.currentIndex] >= 100) {
                this.swiperRef.swiper.animating = false;
                this.swiperRef.swiper.slideNext(300);
            }
        }, 100);
    }

    onVideoLoaded() {}

    videoError() {
        setTimeout(() => {
            if (this.swiperRef?.swiper && this.isVideoTeaser) {
                this.displayProgressBar = false;
                this.swiperRef.swiper.animating = false;
                this.swiperRef.swiper.slideNext(300);
            }
        }, 100);
    }

    getTimeChangeEvent(event: number) {
        if (!this.isMouseOverOnTeaser && this.isVideoTeaser) {
            this.widths[this.currentIndex] = event;
            if (this.widths[this.currentIndex] >= 100) this.onProgressBarEnd();
        }
    }

    onVisibilityChanged(teaserDivElementIntersect: any) {
        if (teaserDivElementIntersect && teaserDivElementIntersect.status == 'Visible') {
            this.teaserDivIsInViewport = true;
            if (this.enableLobbyBackgroundImage && (this.teaserLocation === 'Top' || this.teaserLocation === 'SubNavigation')) {
                this.teaserService.indexChangeTeaserData(this.teasers[this.swiperRef?.swiper?.realIndex]);
            }
        } else {
            this.teaserDivIsInViewport = false;
        }
    }

    playbingoclick(playlink: any) {
        this.playbingoTracking('Clicked');
        if (!playlink.htmlAttributes.gameId) {
            this.navigation.goTo(playlink.url);
        } else {
            const gameObj: any = {
                section: 'Teaser',
                gameContainerLocation: 'Teaser|Top Teaser',
                position: 0,
                isBingoVf: playlink.htmlAttributes.gameType == 'Bingovf' || false,
            };
            this.bingoGameLaunchService.initiateGameLaunch(
                playlink.htmlAttributes.gameName,
                playlink.htmlAttributes.gameType,
                playlink.htmlAttributes.ballType,
                playlink.htmlAttributes.launchType,
                playlink.htmlAttributes.gameIcon,
                playlink.htmlAttributes.gameId,
                playlink.htmlAttributes.favstatus,
                playlink?.link?.url,
                gameObj,
            );
        }
    }

    playbingoTracking(buttonActionEvent: string) {
        this.tracking.triggerEvent('Event.Clicks', {
            ' component.CategoryEvent': 'Play Bingo Button',
            'component.LabelEvent': 'Play Bingo Now',
            'component.ActionEvent': 'Play Bingo Button - ' + buttonActionEvent,
            'component.PositionEvent': this.deviceService.windowWidth() < 480 ? 'Below the Banner' : 'on the  Banner',
            'component.LocationEvent': 'Casino Lobby',
            'component.EventDetails': 'CTA to Launch Bingo',
            'component.URLClicked': this.bingocta.url,
        });
    }
}
