import { InjectionToken, NgModuleFactory, Type } from '@angular/core';

export interface LazyModules {
    [key: string]: { loadChildren: () => Promise<NgModuleFactory<any> | Type<any>> };
}
export const lazyMap: LazyModules = {
    DiscoverFeedComponent: {
        loadChildren: () => import('@casinocore/platform/discover-feed').then((m) => m.DiscoverFeedModule),
    },
    CozyBingoWidgetComponent: {
        loadChildren: () => import('@casinocore/platform/cozy-bingo-widget').then((m) => m.CozyBingoWidgetModule),
    },
    LiveGamesIframeComponent: {
        loadChildren: () => import('@casinocore/platform/live-games-section').then((m) => m.LiveGamesIframeModule),
    },
    JackpotwidgetComponent: {
        loadChildren: () => import('@casinocore/platform/jackpot-widgets').then((m) => m.JackpotWidgetModule),
    },
    JackpotWidgetRedesignComponent: {
        loadChildren: () => import('@casinocore/platform/jackpot-widgets').then((m) => m.JackpotWidgetRedesignModule),
    },
    MustGoJackpotRedesignComponent: {
        loadChildren: () => import('@casinocore/platform/must-go-redesign').then((m) => m.MustGoJackpotRedesignModule),
    },
    LiveCasinoComponent: {
        loadChildren: () => import('@casinocore/platform/livecasino').then((m) => m.LiveCasinoModule),
    },
    CasinoGameVideosComponent: {
        loadChildren: () => import('@casinocore/platform/casino-game-videos').then((m) => m.CasinoGameVideosModule),
    },
    NewsFeedComponent: {
        loadChildren: () => import('@casinocore/platform/news-feed-section').then((m) => m.NewsFeedModule),
    },
    ParallaxAnimationComponent: {
        loadChildren: () => import('@casinocore/platform/parallax-animation').then((m) => m.ParallaxAnimationModule),
    },
    PromoOfferWidgetComponent: {
        loadChildren: () => import('@casinocore/platform/promo-offer-widget').then((m) => m.PromoOfferWidgetModule),
    },
    GamificationLoaderComponent: {
        loadChildren: () => import('@casinocore/platform/gamification').then((m) => m.GamificationLoaderModule),
    },
    PromoEdsWidgetComponent: {
        loadChildren: () => import('@casinocore/platform/promo-eds-widget').then((m) => m.PromoEdsWidgetModule),
    },
    NewGridGameMetaDataComponent: {
        loadChildren: () => import('@casinocore/platform/new-grid-game-metadata').then((m) => m.NewGridGameMetaDataModule),
    },
    SlotRacesComponent: {
        loadChildren: () => import('@casinocore/platform/slot-races').then((m) => m.SlotRacesModule),
    },
    SlotRacesPhase2Component: {
        loadChildren: () => import('@casinocore/platform/slot-races-phase2').then((m) => m.SlotRacesPhase2Module),
    },
    BingoTournamentsComponent: {
        loadChildren: () => import('@casinocore/platform/bingo-tournaments').then((m) => m.BingoTournamentsModule),
    },
    CasinoGameSelectorComponent: {
        loadChildren: () => import('@casinocore/platform/casino-game-selector').then((m) => m.CasinoGameSelectorModule),
    },
    SuperButtonComponent: {
        loadChildren: () => import('@casinocore/platform/super-button').then((m) => m.SuperButtonModule),
    },
    PromotionsBannerComponent: {
        loadChildren: () => import('@casinocore/platform/promotions-banner').then((m) => m.PromotionsBannerModule),
    },
    InstagramWrapperComponent: {
        loadChildren: () => import('@casinocore/platform/instagram-section-wrapper').then((m) => m.InstagramSectionWrapperModule),
    },
    NewGameQuickInfoComponent: {
        loadChildren: () => import('@casinocore/platform/new-game-quick-info').then((m) => m.NewGameQuickInfoModule),
    },
};
export const LAZY_MODULES_MAP = new InjectionToken('LAZY_MODULES_MAP', {
    factory: () => lazyMap,
});
