import { Directive, ElementRef, Input, NgZone, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { getHiddenValues, getTranslateValues } from '@casinocore/platform/utils';
import { Subject, fromEvent } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[ccDraggable]',
    standalone: true,
})
export class DraggableDirective implements OnDestroy, OnChanges, OnInit {
    @Input() dragContainer: HTMLElement;
    @Input() dragTarget: HTMLElement;
    @Input() dragLockAxis: string;
    @Input() createDrag: boolean;
    @Input() disableDrag: boolean;

    private dragHandle: HTMLElement;
    private moveOffset: number = window.devicePixelRatio;
    private isDragging: boolean;
    private delta = { x: 0, y: 0 };
    private offset = { x: 0, y: 0 };
    private hidden = { x: 0, y: 0 };
    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
    ) {}

    ngOnChanges(): void {
        if (this.createDrag) {
            this.offset = getTranslateValues(this.dragTarget);
            this.hidden = getHiddenValues(this.dragTarget, this.dragContainer);
            switch (this.dragLockAxis) {
                case 'x':
                    this.offset.x = 0;
                    this.hidden.x = 0;
                    break;
                case 'y':
                    this.offset.y = 0;
                    this.hidden.y = 0;
                    break;
            }
        }
    }

    ngOnInit(): void {
        this.dragHandle = this.elementRef.nativeElement;
        this.setupEvents();
    }

    ngOnDestroy(): void {
        this.destroyEvents();
    }

    private destroyEvents(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private setupEvents(): void {
        this.zone.runOutsideAngular(() => {
            const manager: any = new Hammer.Manager(this.dragHandle, {
                recognizers: [[Hammer.Pan, { direction: Hammer.DIRECTION_ALL, threshold: 0 }]],
            });

            const panStart$ = fromEvent(manager, 'panstart');
            const panMove$ = fromEvent(manager, 'panmove');
            const panEnd$ = fromEvent(manager, 'panend');

            const drag$ = panStart$.pipe(
                takeUntil(this.destroy$),
                switchMap((event: any) => {
                    event.preventDefault();
                    let startX: number, startY: number;
                    if (!this.disableDrag) {
                        this.isDragging = true;
                        startX = event.deltaX;
                        startY = event.deltaY;
                    }

                    return panMove$.pipe(
                        map((event: any) => {
                            event.preventDefault();
                            if (!this.disableDrag) {
                                this.delta = {
                                    x: event.deltaX - startX,
                                    y: event.deltaY - startY,
                                };
                            }
                        }),
                        takeUntil(panEnd$),
                    );
                }),
            );

            drag$.subscribe(() => {
                this.drag();
            });

            panEnd$.pipe(takeUntil(this.destroy$)).subscribe((event: any) => {
                event.preventDefault();
                this.dragEnd();
            });
        });
    }

    private get hasMovedFarEnough(): boolean {
        return Math.abs(this.delta.x) >= this.moveOffset || Math.abs(this.delta.y) >= this.moveOffset;
    }

    private drag(): void {
        if ((this.delta.x === 0 && this.delta.y === 0) || this.disableDrag) {
            return;
        }

        switch (this.dragLockAxis) {
            case 'x':
                this.offset.x = 0;
                this.delta.x = 0;
                this.hidden.x = 0;
                break;
            case 'y':
                this.offset.y = 0;
                this.delta.y = 0;
                this.hidden.y = 0;
                break;
        }

        if (this.isDragging && this.hasMovedFarEnough) {
            this.translate();
        }
    }

    private dragEnd(): void {
        if (!this.isDragging || this.disableDrag) {
            return;
        }

        this.offset.x += this.delta.x;
        this.offset.y += this.delta.y;
        this.delta = { x: 0, y: 0 };

        const outOfBoundary = { x: false, y: false };
        if (this.offset.x > 0) {
            outOfBoundary.x = true;
            this.offset.x = 0;
        } else if (this.hidden.x < Math.abs(this.offset.x)) {
            outOfBoundary.x = true;
            this.offset.x = -(this.hidden.x + 0);
        }

        let scrollToFooter: boolean = false;
        if (this.offset.y > 0) {
            outOfBoundary.y = true;
            this.offset.y = 0;
        } else if (this.hidden.y < Math.abs(this.offset.y)) {
            outOfBoundary.y = true;
            scrollToFooter = true;
            this.offset.y = -(this.hidden.y + 0);
        }

        this.isDragging = false;
        this.dragHandle.classList.remove('is-dragging');
        this.translate();

        if (scrollToFooter && !this.dragLockAxis) {
            window.scroll(0, 60);
        }
    }

    private translate(): void {
        requestAnimationFrame(() => {
            this.isDragging ? this.dragHandle.classList.add('is-dragging') : this.dragHandle.classList.remove('is-dragging');
            this.dragTarget.style.transform = `translate3d(${this.offset.x + this.delta.x}px, ${this.offset.y + this.delta.y}px, 0px)`;
        });
    }
}
