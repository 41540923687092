<div
    id="arcade-rpgyml-container"
    *ngIf="subcategory && subcategory.categoryInfo && Games.length > 0 && !isMobile"
    class="casino-lobby-games casino-game-tile-wrapper casino-lobby-width-gametile"
    [ngClass]="{ 'casino-ios-bottom-nav-bar': isCrossSaleIOS }"
    class="gametracking {{ trackingClass }}">
    <div class="rpgyml-container">
        <div class="info-icon theme-clock-solid"></div>
        <div class="container-name" [textContent]="arcadeLobbyMessages?.RecentlyPlayedGames"></div>
    </div>
    <div class="scrollable-games" [style.overflow-x]="!isTouch ? 'hidden' : 'scroll'" [class.non-touch-scrollable-games]="!isTouch">
        <ng-container *ngIf="showFeatureGrid()">
            <div class="featuregrid-container">
                <cc-arcade-feature-grid
                    *ngIf="
                        subcategory.categoryInfo &&
                        subcategory.categoryInfo.gamelist &&
                        subcategory.categoryInfo.gamelist.length > 0 &&
                        subcategory.categoryInfo.sitecoreData
                    "
                    [games]="Games"
                    [subcategory]="subcategory.categoryInfo"
                    [isTouch]="isTouch"
                    [Type]="'unscrollable-games'"
                    [gameLaunchedFrom]="subcategory.categoryInfo.categoryid"
                    [lobbyType]="lobbyType"
                    [newFeatureGridArr]="sliceFeatureGrid"></cc-arcade-feature-grid>
            </div>
            <div *ngIf="remainingGameCount > 0" class="placeholder-container" (click)="openRpMatGrid()">
                <div class="placeholder-count">+{{ remainingGameCount }}</div>
            </div>
        </ng-container>
    </div>
</div>

<div class="arcade-rpgyml-container-mobile" *ngIf="!isCombinedCategories && subcategory && subcategory.categoryInfo && Games.length > 0 && isMobile">
    <div class="arcade-rpgyml-container-mobile-main" (click)="openRpMatGrid('mobile')">
        <div class="arcade-rpgyml-container-mobile-text" [textContent]="arcadeLobbyMessages?.RecentlyPlayedGames"></div>
    </div>
</div>
