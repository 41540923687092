import { Injectable } from '@angular/core';

import {
    CookieService,
    Message,
    MessageLifetime,
    MessageQueueService,
    MessageType,
    NativeAppService,
    NavigationService,
    ProductHomepagesConfig,
    UserService,
    WindowRef,
} from '@frontend/vanilla/core';

import { CasinoLobbyService } from '../casino-lobby-manager/casino-lobby.service';
import { ConfigProviderService } from '../config-provider/config-provider.service';
import { CasinoApiService } from './casino-api.service';
import { CasinoManager } from './casino-manager.service';
import { GameLaunchUrlFormationService } from './gamelaunch-url-formation.service';

class VCConstService {
    public static readonly CASINO_OPEN_GAME_URL: string = 'CASINO_OPEN_GAME_URL';
    public static readonly CASINO_OPEN_GAME: string = 'CASINO_OPEN_GAME';
}

@Injectable({
    providedIn: 'root',
})
export class VCClientService {
    constructor(
        private windowRef: WindowRef,
        private api: CasinoApiService,
        private configProviderService: ConfigProviderService,
        private messageQueue: MessageQueueService,
        private user: UserService,
        private cookieService: CookieService,
        private casinoLobbyService: CasinoLobbyService,
        private navigationService: NavigationService,
        private casinoManager: CasinoManager,
        private gameLaunchUrlFormationService: GameLaunchUrlFormationService,
        private lhProductHomepagesConfig: ProductHomepagesConfig,
        private nativeAppService: NativeAppService,
    ) {}

    handleVCGameLaunch(gameLaunchParam: any, isNotLobby: boolean, callback?: any) {
        if (this.user.isAuthenticated) {
            this.launchGameForVC(gameLaunchParam, isNotLobby, callback);
        } else {
            this.raiseNativeVCEvent(gameLaunchParam.game.gameId, gameLaunchParam.returnUrl, '');
        }
    }

    launchGameForVC(gameLaunchParam: any, isNotLobby: boolean, callback?: any) {
        const globalConfig = this.configProviderService.provideGlobalConfig();
        //todo: controller side
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        this.api.get(apiPathsConfig.gameData['gameLaunchData'], { gameId: gameLaunchParam.game.gameId, skipUnfinGame: true }).subscribe(
            (response: any) => {
                if (response) {
                    if (response.redirect && response.redirect.indexOf('/unfinished') > -1) {
                        const message = globalConfig.messages['UnfinishedGames'];
                        if (message) {
                            this.messageQueue.addError(message);
                        }
                    } else {
                        const redirectUrl = this.gameLaunchUrlFormationService.launchMGSGame(response, gameLaunchParam);
                        response.redirect = redirectUrl;
                        if (callback) {
                            const callbackResponse = {
                                isError: false,
                                errorMessage: '',
                                gameLaunchUrl: redirectUrl,
                            };
                            callback(callbackResponse);
                        } else {
                            this.raiseNativeVCEvent(gameLaunchParam.game.gameId, gameLaunchParam.returnUrl, response.redirect, isNotLobby);
                        }
                    }
                }
            },
            (error) => {
                if (
                    error.data &&
                    error.data.common &&
                    error.data.common.messages &&
                    error.data.common.messages.length > 0 &&
                    error.data.common.messages[0]
                ) {
                    if (callback) {
                        const callbackResponse = {
                            isError: true,
                            errorMessage: error.data.common.messages[0].html,
                            gameLaunchUrl: '',
                        };
                        callback(callbackResponse);
                    } else {
                        this.showErrorMessageQueue(error.data.common.messages[0].html, error?.data?.redirect);
                    }
                } else if (error && error.common && error.common.messages && error.common.messages.length > 0 && error.common.messages[0]) {
                    if (callback) {
                        const callbackResponse = {
                            isError: true,
                            errorMessage: error.common.messages[0].html,
                            gameLaunchUrl: '',
                        };
                        callback(callbackResponse);
                    } else {
                        this.showErrorMessageQueue(error.common.messages[0].html, error?.redirect);
                    }
                }
            },
        );
    }
    showErrorMessageQueue(errorText: string, redirectUrl: any) {
        this.messageQueue.clear();
        const message: Message = {
            html: errorText,
            type: MessageType.Information,
            lifetime: MessageLifetime.Single,
            scope: 'casinocorelobby',
        };
        if (message) {
            this.messageQueue.add(message);
        }
        const homePageUrl = this.casinoManager.getGameHomeUrl();
        setTimeout(() => {
            this.messageQueue.clear();
            if (redirectUrl) this.navigationService.goTo(redirectUrl);
            else this.navigationService.goTo(homePageUrl);
        }, 5000);
    }
    raiseNativeVCEvent(gameId: string, returnUrl: string, launchUrl: string, isNotLobby: boolean = false, gameTitle: string = ''): void {
        const nativeGameLaunchConfig = this.configProviderService.provideNativeGameLaunchConfig();
        const nativeAppCookie = this.cookieService.get('NativeApp');
        const nativeDis: any = this.windowRef.nativeWindow.external['NativeDispatch' as keyof typeof this.windowRef.nativeWindow.external];
        if (nativeGameLaunchConfig.isOnlyGameidLaunchEvent) {
            const request: any = {
                CASINO_OPEN_GAME: gameId,
                gameName: gameId,
                clickTimeStamp: this.getQueryParameter(launchUrl, 'clickTimeStamp'),
                launchSource: this.getQueryParameter(launchUrl, 'launchSource'),
                lobbyType: this.casinoManager.getCurrentLobby(),
            };
            isNotLobby = !isNotLobby ? this.checkPokerVCOutsideCasinoLobby(returnUrl) : true;
            if (isNotLobby && nativeAppCookie?.toLowerCase() === 'pokervc') {
                const eventName: string = 'app:GAME_NAME=' + gameId + '#' + gameTitle;
                nativeDis(eventName, JSON.stringify(request));
                //console.log('app:GAME_NAME= event rised from GameidLaunchEvent');
            } else {
                const gameDetails: any = this.casinoLobbyService.getGameInfo(gameId);
                const vendorsList = nativeGameLaunchConfig.vendorsforShouldOverrideUserAgent;
                if (gameDetails && vendorsList && vendorsList.indexOf(gameDetails.provider) > -1) {
                    request.shouldOverrideUserAgent = '1';
                }
                if (nativeGameLaunchConfig.isNewMacEvent) {
                    this.nativeAppService.sendToNative({
                        eventName: VCConstService.CASINO_OPEN_GAME,
                        parameters: request,
                    });
                } else {
                    nativeDis(VCConstService.CASINO_OPEN_GAME, JSON.stringify(request));
                }
                //console.log('CASINO_OPEN_GAME event rised from GameidLaunchEvent');
            }
        } else {
            const request: any = {
                CASINO_OPEN_GAME_URL: launchUrl,
                casinoEvents: {
                    CASINO_OPEN_GAME: launchUrl,
                },
                gameName: gameId,
                height: '680',
                width: '960',
            };
            isNotLobby = !isNotLobby ? this.checkPokerVCOutsideCasinoLobby(returnUrl) : true;
            if (isNotLobby && nativeAppCookie?.toLowerCase() === 'pokervc') {
                const eventName: string = 'app:GAME_NAME=' + gameId + '#' + gameTitle;
                nativeDis(eventName, JSON.stringify(request));
                //console.log('app:GAME_NAME= event rised with GameDetails');
            } else {
                const gameDetails: any = this.casinoLobbyService.getGameInfo(gameId);
                const vendorsList = nativeGameLaunchConfig.vendorsforShouldOverrideUserAgent;
                if (gameDetails && vendorsList && vendorsList.indexOf(gameDetails.provider) > -1) {
                    request.shouldOverrideUserAgent = '1';
                }
                if (nativeGameLaunchConfig.isNewMacEvent) {
                    this.nativeAppService.sendToNative({
                        eventName: VCConstService.CASINO_OPEN_GAME_URL,
                        parameters: request,
                    });
                } else {
                    nativeDis(VCConstService.CASINO_OPEN_GAME_URL, JSON.stringify(request));
                }
                //console.log('CASINO_OPEN_GAME event rised with GameDetails');
            }
        }
        if (returnUrl) {
            this.navigationService.goTo(returnUrl);
        }
        return;
    }

    private getQueryParameter(gameLaunchUrl: string, key: string): string | null {
        const parameters = new URLSearchParams(gameLaunchUrl);
        return parameters.get(key);
    }

    isPokerVC() {
        const nativeAppCookie = this.cookieService.get('NativeApp');
        return nativeAppCookie && (nativeAppCookie?.toLowerCase() === 'pokervc' || nativeAppCookie?.toLowerCase() === 'pokermc');
    }

    checkPokerVCOutsideCasinoLobby(returnUrl: string): boolean {
        if (returnUrl != '') {
            const casinoURL = this.lhProductHomepagesConfig.casino;
            const indexOfCasinoURL = returnUrl.indexOf(casinoURL);
            if (indexOfCasinoURL == -1) {
                return true;
            }
        }
        return false;
    }
}
