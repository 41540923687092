import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csClientSideConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: clientSideConfigClientConfigFactory,
})
export class ClientSideConfigClientConfig {
    bonusInterval: number;
    playForFunAndRealButtons: boolean;
    labelId: string;
    playForFunAndRealButtonsLobbyType: any;
}

export function clientSideConfigClientConfigFactory(service: LazyClientConfigService) {
    return service.get(ClientSideConfigClientConfig);
}
