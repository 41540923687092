import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csLiveDealerConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: liveDealerConfigClientConfigFactory,
})
export class LiveDealerConfigClientConfig {
    operatingHoursEnabled: boolean;
    openHours: number;
    openMinutes: number;
    openSeconds: number;
    closeHours: number;
    closeMinutes: number;
    closeSeconds: number;
}

export function liveDealerConfigClientConfigFactory(service: LazyClientConfigService) {
    return service.get(LiveDealerConfigClientConfig);
}
