import { Injectable } from '@angular/core';

import { CasinoLobbyService, ConfigProviderService } from '@casinocore/platform/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LobbyService {
    private categoryChangeSubject = new BehaviorSubject<any>(null);
    categoryChangeObservable: Observable<any> = this.categoryChangeSubject.asObservable();

    routeCurrentProduct: string;
    routeCategoryType: string;
    lastKnownRouteProduct: string;
    featuresConfig: any;

    constructor(
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
    ) {
        this.featuresConfig = this.configProviderService.provideFeaturesConfig();
    }

    publishCategoryChange(routeProduct: string, routeCategoryType: string) {
        let isRootOrDefault: boolean;
        if (this.isNullOrEmpty(routeProduct) && this.isNullOrEmpty(routeCategoryType)) {
            isRootOrDefault = true;
        } else if (routeProduct && this.isNullOrEmpty(routeCategoryType)) {
            //TODO: check for valid product under lobbytypeMap
            isRootOrDefault = true;
        } else if (!this.isNullOrEmpty(routeProduct) && !this.isNullOrEmpty(routeCategoryType)) {
            isRootOrDefault = false;
        }
        if (this.featuresConfig?.rpGamesFilterInLiveCasino) {
            this.routeCurrentProduct = routeProduct;
            if (this.routeCurrentProduct != this.lastKnownRouteProduct) {
                this.lobbyChanged();
            }
        }
        this.categoryChangeSubject.next({
            routeCurrentProduct: routeProduct,
            routeCategoryType: routeCategoryType,
            isRootOrDefault: isRootOrDefault!,
        });
    }

    lobbyChanged() {
        if (this.routeCurrentProduct == 'livecasino' || this.lastKnownRouteProduct == 'livecasino') {
            setTimeout(() => {
                this.casinoLobbyService.refreshFeeds();
            }, 0);
        }
        this.lastKnownRouteProduct = this.routeCurrentProduct;
    }

    isNullOrEmpty(val: string): boolean {
        if (!val || val === '') {
            return true;
        }
        return false;
    }
}
