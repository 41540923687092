import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class GameLaunchPlaces {
    gameIconOnLobby: string;
    gameTitleOnLobby: string;
    gameInfoIconOnLobby: string;
    demoPlayPage: string;
    playNowOnDetailPage: number;
    playNowOnDetailPageAfterScreenshots: number;
    playRedirectCtrl: string;
    unfinGamePage: string;
    searchOnLobby: string;
    teaser: string;
    activeFreeSpin: string;
    constructor() {
        this.gameIconOnLobby = 'p';
        this.gameTitleOnLobby = 'l';
        this.gameInfoIconOnLobby = 'i';
        this.demoPlayPage = 'demoPlay';
        this.playNowOnDetailPage = 1;
        this.playNowOnDetailPageAfterScreenshots = 3;
        this.playRedirectCtrl = 'playRedirectCtrl';
        this.unfinGamePage = 'unfinGamePage';
        this.searchOnLobby = 'searchOnLobby';
        this.teaser = 'Teaser';
        this.activeFreeSpin = 'activeFreeSpin';
    }

    isLobby(place: string) {
        return (
            !place ||
            place === this.gameIconOnLobby ||
            place === this.gameTitleOnLobby ||
            place === this.gameInfoIconOnLobby ||
            place === this.searchOnLobby ||
            place === this.teaser
        );
    }
}
