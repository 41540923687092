import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csOptimizedTeaser', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: optimizedTeaserClientConfigFactory,
})
export class OptimizedTeaserClientConfig {
    teaserOptimization: boolean;
    teaserDefaultHorizontalAlignment: string;
    teaserDefaultVerticalAlignment: string;
    teaserDefaultImageAlignment: string;
    teaserDefaultHorizontalTextAlignment: string;
    teaserDefaultTermsTextAlignment: string;
    maxRenderedTeasers: number;
    desktopViewTeaserHeight: number;
    mobileViewTeaserHeight: number;
    tabletViewTeaserHeight: number;
    desktopViewEmbeddedTeaserheight: number;
    mobileViewEmbeddedTeaserHeight: number;
    tabletViewEmbeddedTeaserHeight: number;
    enableTickingForJackpotOnTeasers: boolean;
    enableOptMiniTeasers: boolean;
    enableAluminiumTeaser: boolean;
    enableFixedWidthAluminiumTeaser: boolean;
    setAluminiumDesktopTeaserHeight: number;
    setAluminiumTabletTeaserHeight: number;
    setAluminiumMobileTeaserHeight: number;
    setAluminiumDesktopTeaserWidth: number;
    setAluminiumTabletTeaserWidth: number;
    setAluminiumMobileTeaserWidth: number;
}

export function optimizedTeaserClientConfigFactory(service: LazyClientConfigService) {
    return service.get(OptimizedTeaserClientConfig);
}
