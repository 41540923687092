import { NgClass, NgFor, NgIf, NgStyle, SlicePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatGridListModule } from '@angular/material/grid-list';

import { CasinoManager, CasinoWidgetService, ConfigProviderService, ConstService } from '@casinocore/platform/core';
import { DeviceService } from '@frontend/vanilla/core';
import {
    NguCarousel,
    NguCarouselConfig,
    NguCarouselDefDirective,
    NguCarouselNextDirective,
    NguCarouselPointDirective,
    NguCarouselPrevDirective,
    NguCarouselStore,
    NguItemComponent,
} from '@ngu/carousel';

import { ArcadeGameLobbyComponent } from '../arcade-game-lobby/arcade-game-lobby.component';

@Component({
    selector: 'cc-arcade-feature-grid',
    templateUrl: 'arcade-feature-grid.component.html',
    standalone: true,
    imports: [
        NgIf,
        MatGridListModule,
        NgClass,
        NgFor,
        NgStyle,
        ArcadeGameLobbyComponent,
        SlicePipe,
        NguCarouselDefDirective,
        NguCarouselNextDirective,
        NguCarouselPrevDirective,
        NguItemComponent,
        NguCarousel,
        NguCarouselPointDirective,
    ],
})
export class ArcadeFeatureGridComponent implements OnChanges, OnInit, OnDestroy {
    @Input() games: any; //need this to calculate breakpoints and other configs
    @Input() subcategory: any;
    @Input() isTouch: boolean;
    @Input() Type: any;
    @Input() EnableFutureGrid: boolean = true;
    @Input() gameLaunchedFrom: string;
    @Input() lobbyType: string;
    @Input() changeCarouselView: boolean;
    @Input() newFeatureGridArr: []; //Game array
    breakpoint: number;
    sliceCount: number;
    @ViewChild('desktopGamesCarousel') desktopGamesCarousel: NguCarousel<any>;
    @ViewChild('mobileGamesCarousel') mobileGamesCarousel: NguCarousel<any>;
    public carouselConfigNonTouch: NguCarouselConfig = {
        grid: { xs: 3, sm: 6, md: 6, lg: 6, all: 0 },
        slide: 6,
        speed: 400,
        point: {
            visible: false,
        },
        loop: false,
        touch: false,
    };
    width: any;
    height: any;
    currentIndex: number = 0;
    currentSlideCount = 12;
    windowInnerWidth: number;
    isCrossSaleIOS: boolean;
    resizeSubscriber: any;
    isPreButtonVisible: boolean;
    isNextButtonVisible: boolean;
    multiRowScrollGameArray: Array<any> = new Array<any>();
    gutterSize: string = '0px';
    rowHeight: string = '171px';
    @Output() loadNextGames: EventEmitter<null> = new EventEmitter<null>();
    @Output() carouselDataEvent: EventEmitter<null> = new EventEmitter<null>();
    jackpot_justify_center_icon_7_xs: boolean;
    jackpot_justify_center_icon_7_sm: boolean;
    arcadeLobbyMessages: any;

    constructor(
        private configProviderService: ConfigProviderService,
        private casinoManager: CasinoManager,
        private casinoWidgetService: CasinoWidgetService,
        private deviceService: DeviceService,
    ) {
        this.resizeSubscriber = this.casinoManager.reSizeObservable.pipe(takeUntilDestroyed()).subscribe((width: any) => {
            if (width) {
                this.setInfoClassIconForGames();
            }
        });
    }

    ngOnInit() {
        this.arcadeLobbyMessages = this.configProviderService.provideGlobalConfig()?.arcadeLobbyConfigurations;
        this.isCrossSaleIOS = this.casinoWidgetService.isCasinoWidget() && this.deviceService.isiOS;
        //Carousel is not scrolling
        this.isPreButtonVisible = true;
        setTimeout(() => {
            this.loadNextGames.emit();
        }, 0);
        if (this.changeCarouselView && !this.isTouch) {
            this.emitCarouselData();
        }
    }

    onmoveFn(data: NguCarouselStore) {
        this.isPreButtonVisible = data.isFirst;
        this.isNextButtonVisible = data.isLast;
        if (this.changeCarouselView && !this.isTouch) {
            this.emitCarouselData();
        }
    }

    ngOnDestroy() {
        this.resizeSubscriber.unsubscribe();
    }

    prevItem() {
        this.desktopGamesCarousel.moveTo(this.desktopGamesCarousel.activePoint - 1);
    }

    nextItem() {
        this.desktopGamesCarousel.moveTo(this.desktopGamesCarousel.activePoint + 1);
    }

    emitCarouselData() {
        const data: any = {
            showArrows: this.Type == 'scrollable-games' && !(this.isPreButtonVisible && this.isNextButtonVisible),
            disablePrev: this.isPreButtonVisible,
            disableNext: this.isNextButtonVisible,
        };
        this.carouselDataEvent.emit(data);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.Type == 'scrollable-games' && this.isTouch && this.subcategory.categoryWithMultipleGameIconTypes) {
            this.Type = 'scrollable-games-multiple-icons';
        }
        if (!this.newFeatureGridArr) {
            this.newFeatureGridArr = this.games;
        }
        if (
            changes.subcategory ||
            (this.subcategory?.categoryid?.toLowerCase() === ConstService.FAVOURITE_GAMES_CATEGORY_ID && this.detectGamechangesLength(changes))
        ) {
            this.refreshGrid();
            if (this.desktopGamesCarousel) this.desktopGamesCarousel.moveTo(0);
        }
        if (changes.subcategory || this.detectGamechangesLength(changes)) {
            this.setInfoClassIconForGames();
        }

        if (this.games && this.games.length) {
            this.jackpot_justify_center_icon_7_xs =
                this.games.length == 1 &&
                this.subcategory.isIconSevenCategory &&
                this.gameLaunchedFrom == 'JackpotWidget_' + this.subcategory.engCategoryName;
            this.jackpot_justify_center_icon_7_sm =
                this.games.length == 2 &&
                this.subcategory.isIconSevenCategory &&
                this.gameLaunchedFrom == 'JackpotWidget_' + this.subcategory.engCategoryName;
            if (
                (this.subcategory.rowCount === '-1' || this.subcategory.rowCount === '-2' || this.subcategory.rowCount === '-3') &&
                this.games.length > this.carouselConfigNonTouch.grid.lg * Math.abs(this.subcategory.rowCount)
            ) {
                if (this.isNextButtonVisible) this.isNextButtonVisible = false;
            } else {
                this.isNextButtonVisible = true;
            }
        } else {
            this.isNextButtonVisible = true;
        }

        if (this.changeCarouselView && !this.isTouch) {
            this.emitCarouselData();
        }
    }
    detectGamechangesLength(changes: any) {
        if (
            changes.games &&
            (changes.games.previousValue && changes.games.previousValue.length) !== (changes.games.currentValue && changes.games.currentValue.length)
        ) {
            return true;
        }
        return false;
    }

    refreshGrid() {
        this.currentSlideCount = 12;
        if (this.subcategory?.isIconSevenCategory) {
            this.gutterSize = '3px';
            this.rowHeight = '112';
            this.carouselConfigNonTouch = {
                grid: { xs: 1, sm: 2, md: 4, lg: 4, all: 0 },
                slide: 6,
                speed: 400,
                point: {
                    visible: false,
                },
                loop: false,
                touch: false,
            };
        }

        this.windowWidth(document.body.offsetWidth);

        if (!this.subcategory.rowCount) {
            this.subcategory.rowCount = 0;
        }
        if (this.subcategory.rowCount === '1' || this.subcategory.rowCount === '2') {
            for (let i = 0; i < this.games.length; i++) {
                if (this.currentSlideCount > 0) {
                    if (this.games[i].iconType == '1') {
                        this.currentSlideCount = this.currentSlideCount - 1;
                    } else if (this.games[i].iconType == '2') {
                        this.currentSlideCount = this.currentSlideCount - 2;
                    } else if (this.games[i].iconType == '3') {
                        this.currentSlideCount = this.currentSlideCount - 2;
                    } else if (this.games[i].iconType == '4') {
                        this.currentSlideCount = this.currentSlideCount - 4;
                    } else if (this.games[i].iconType == '6') {
                        this.currentSlideCount = this.currentSlideCount - 1;
                    } else if (this.games[i].iconType == '7') {
                        if (this.isTouch) {
                            this.currentSlideCount = this.currentSlideCount - 2;
                        } else {
                            this.currentSlideCount = this.currentSlideCount - 1.5;
                        }
                    } else if (this.games[i].iconType == '10') {
                        this.currentSlideCount = this.currentSlideCount - 4;
                    } else {
                        if (this.isTouch) this.currentSlideCount = this.currentSlideCount - 4;
                        else this.currentSlideCount = this.currentSlideCount - 6;
                    }
                    this.currentIndex = i + 1;
                }
            }
        } else {
            this.currentIndex = this.subcategory?.gamelist?.length;
        }
    }

    onResize() {
        if (!this.isCrossSaleIOS) {
            if (!this.windowInnerWidth || this.windowInnerWidth !== document.body.offsetWidth) this.windowWidth(document.body.offsetWidth);
            if (this.desktopGamesCarousel) {
                this.desktopGamesCarousel.ngAfterViewInit();
            }
        }
    }

    windowWidth(width: number) {
        const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        this.windowInnerWidth = width;
        if (this.games && this.games.length > 0 && !this.isTouch && this.EnableFutureGrid) {
            switch (this.games[0].iconType) {
                case '1':
                case '3':
                    this.carouselConfigNonTouch.grid.sm = 3;
                    this.carouselConfigNonTouch.grid.md = 6;
                    this.carouselConfigNonTouch.grid.lg = 6;
                    break;
                case '2':
                case '4':
                    this.carouselConfigNonTouch.grid.sm = 3;
                    this.carouselConfigNonTouch.grid.md = 3;
                    this.carouselConfigNonTouch.grid.lg = 3;
                    break;
                case '5':
                    this.carouselConfigNonTouch.grid.sm = 2;
                    this.carouselConfigNonTouch.grid.md = 2;
                    this.carouselConfigNonTouch.grid.lg = 2;
                    break;
                case '7':
                    this.carouselConfigNonTouch.grid.xs = 1;
                    this.carouselConfigNonTouch.grid.sm = 2;
                    this.carouselConfigNonTouch.grid.md = 4;
                    this.carouselConfigNonTouch.grid.lg = 4;
                    break;
                case '10':
                    this.carouselConfigNonTouch.grid.sm = 3;
                    this.carouselConfigNonTouch.grid.md = 3;
                    this.carouselConfigNonTouch.grid.lg = 3;
                    break;
                default:
                    this.carouselConfigNonTouch.grid.sm = 3;
                    this.carouselConfigNonTouch.grid.md = 6;
                    this.carouselConfigNonTouch.grid.lg = 6;
            }
        }
        if (
            this.games &&
            this.games.length > 0 &&
            this.isTouch &&
            (this.EnableFutureGrid ||
                this.gameLaunchedFrom === 'JackpotWidget_' + this.subcategory.engCategoryName ||
                'MustGoJackpot_' + this.subcategory.categoryid)
        ) {
            switch (this.games[0].iconType) {
                case '1':
                case '3':
                case '6':
                    this.carouselConfigNonTouch.grid.xs = 2.2;
                    this.carouselConfigNonTouch.grid.sm = 4.5;
                    this.carouselConfigNonTouch.grid.md = 5.5;
                    this.carouselConfigNonTouch.grid.lg = 5.5;
                    if (window.innerWidth > 586 && window.innerWidth < 768) {
                        this.carouselConfigNonTouch.grid.xs = 4.5;
                    }
                    break;
                case '2':
                case '4':
                case '10':
                case '5':
                    this.carouselConfigNonTouch.grid.xs = 1.3;
                    this.carouselConfigNonTouch.grid.sm = 2.3;
                    this.carouselConfigNonTouch.grid.md = 2.7;
                    this.carouselConfigNonTouch.grid.lg = 2.7;
                    if (window.innerWidth > 586 && window.innerWidth < 768) {
                        this.carouselConfigNonTouch.grid.xs = 2.3;
                    }
                    break;
                case '7':
                    this.carouselConfigNonTouch.grid.xs = 1.2;
                    this.carouselConfigNonTouch.grid.sm = 2.2;
                    this.carouselConfigNonTouch.grid.md = 2.3;
                    this.carouselConfigNonTouch.grid.lg = 2.3;
                    if (window.innerWidth > 586 && window.innerWidth < 768) {
                        this.carouselConfigNonTouch.grid.xs = 2.2;
                    }
                    break;
                default:
                    this.carouselConfigNonTouch.grid.xs = 2.5;
                    this.carouselConfigNonTouch.grid.sm = 4.5;
                    this.carouselConfigNonTouch.grid.md = 5.5;
                    this.carouselConfigNonTouch.grid.lg = 5.5;
            }
        }

        if ((this.subcategory.rowCount === '1' || this.subcategory.rowCount === '2') && this.isTouch) {
            this.breakpoint = 6;
            const modularConfig = this.configProviderService.provideModularSettingsConfig();
            let featuredGridSetHeightTimeOut = this.casinoManager.IsFeatureEnabledforLobbytype(
                this.lobbyType,
                modularConfig.featuredGridSetHeightTimeOut,
            );
            if (!featuredGridSetHeightTimeOut) {
                featuredGridSetHeightTimeOut = 100;
            }
            setTimeout(() => {
                if (
                    this.games[0] &&
                    (this.games[0].iconType === '1' ||
                        this.games[0].iconType === '2' ||
                        this.games[0].iconType === '6' ||
                        this.games[0].iconType === '7')
                ) {
                    if (
                        this.games &&
                        document.getElementById('heightFix-' + this.subcategory.categoryid + '-' + this.games[0].gameId) &&
                        document.getElementById('heightFix-' + this.subcategory.categoryid + '-' + this.games[0].gameId)!.clientHeight
                    )
                        this.height =
                            20 +
                            document.getElementById('heightFix-' + this.subcategory.categoryid + '-' + this.games[0].gameId)!.clientHeight * 2 +
                            'px';
                } else {
                    if (
                        this.games &&
                        document.getElementById('heightFix-' + this.subcategory.categoryid + '-' + this.games[0].gameId) &&
                        document.getElementById('heightFix-' + this.subcategory.categoryid + '-' + this.games[0].gameId)!.clientHeight
                    )
                        this.height =
                            20 +
                            document.getElementById('heightFix-' + this.subcategory.categoryid + '-' + this.games[0].gameId)!.clientHeight +
                            'px';
                }
            }, featuredGridSetHeightTimeOut);

            if (width <= 320) {
                if (
                    this.games[0].iconType == '3' ||
                    this.games[0].iconType == '-3' ||
                    this.games[0].iconType == '1' ||
                    this.games[0].iconType == '-1'
                )
                    this.width = 2.8 * width + 'px';
                else this.width = 2.4 * width + 'px';
            } else if (width <= 480) {
                if (
                    this.games[0].iconType == '3' ||
                    this.games[0].iconType == '-3' ||
                    this.games[0].iconType == '1' ||
                    this.games[0].iconType == '-1'
                )
                    this.width = 2.8 * width + 'px';
                else this.width = 2.4 * width + 'px';
            } else if (width < 625) {
                this.width = 1.72 * width + 'px';
            } else if (width < 768) {
                this.width = 1.33 * width + 'px';
            } else if (width < 1000) {
                this.width = 1.33 * width + 'px';
            } else if (width < 1360) {
                this.width = 27 + width + 'px';
            } else {
                this.width = 37 + width + 'px';
            }
        } else {
            this.width = 'unset';
            this.height = 'unset';
            if (this.subcategory.isIconSevenCategory) {
                if ((this.subcategory.rowCount === '1' || this.subcategory.rowCount === '2') && !this.isTouch) {
                    this.breakpoint = 8;
                } else if (width < 568) {
                    this.breakpoint = 2;
                } else if (width < 1024) {
                    this.breakpoint = 4;
                } else {
                    this.breakpoint = 8;
                }
            } else if (this.subcategory.isIconSixCategory) {
                if (width < 568) {
                    this.breakpoint = 3;
                } else if (width < 768) {
                    this.breakpoint = 4;
                } else {
                    this.breakpoint = 6;
                }
            } else if (width < 375) {
                this.breakpoint = 2;
            } else if (width <= 480 && window.matchMedia('(orientation:portrait)').matches) {
                this.breakpoint = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, featuresConfig.matGridColCountForLobbyType);
            } else if (width < 568) {
                this.breakpoint = 2;
            } else if (width < 768 || (width >= 768 && width <= 1024)) {
                this.breakpoint = 4;
            } else {
                this.breakpoint = 6;
            }
        }
    }

    getClassByicon(game: any) {
        if (game && game.iconType && this.EnableFutureGrid) {
            return 'icon-' + game.iconType;
        } else if (
            game &&
            game.iconType &&
            (this.gameLaunchedFrom === 'JackpotWidget_' + this.subcategory.engCategoryName || 'MustGoJackpot_' + this.subcategory.categoryid)
        ) {
            return 'icon-' + game.iconType;
        } else {
            return 'icon-1';
        }
    }

    loadNextGamesSet() {
        setTimeout(() => {
            this.loadNextGames.emit();
        }, 0);
    }

    setInfoClassIconForGames() {
        const gamesArr: any = this.games;
        if (gamesArr && gamesArr.length > 0) {
            for (let i = 0; i < gamesArr.length; i++) {
                const isFromJackpot = this.gameLaunchedFrom && this.gameLaunchedFrom?.toLowerCase().indexOf('jackpotwidget') > -1;
                const iconType = isFromJackpot ? '1' : gamesArr[i].iconType;
                const currentWidth = this.casinoManager.getCurrentWidth();
                gamesArr[i].infoIconClass = this.casinoManager.assignGameClass(currentWidth, iconType);
            }
        }
        this.games = gamesArr;
    }
}
