<div class="casino-mustgo" *ngIf="showMustGo && mustGoFeedObject && mustGoSubJackpotsList && mustGoSitecoreObject">
    <div class="casino-mustgo1">
        <div class="mustgo-wrapper">
            <div
                class="casino-mustgo-jackpot-widget jackpot-widget-view-port {{ jackpotWidgetConfig?.MustoGoJackpotAlignment }}"
                [ngClass]="{
                    'no-bg-transparent': !backgroundTransparency,
                    'mustgojackpot-non-carousel-view': !changeCarouselView,
                    'mustgojackpot-carousel-view-danskepil': changeCarouselView
                }"
                [style.background]="glassEffectColor">
                <div class="casino-mj-left-sec jackpot-widget-left-width">
                    <div class="casino-mj-logo"><img src="{{ mustGoSitecoreObject?.mustGoLogo?.src }}" /></div>
                    <div class="casino-mega-jackpot" *ngIf="primaryJackpotDisplayname != ''">
                        <div class="casino-must-go-jackpot">
                            <div class="jackpot-type" [style.background]="jackpotHeadingBackground">
                                <div class="jackpot-type-text" [style.color]="jackpotHeadingColor">
                                    {{ primaryJackpotDisplayname }}
                                </div>
                            </div>
                            <div class="casino-must-go-jackpot-value">
                                <div class="left-gradient"><img src="{{ leftGradientImgSrc }}" /></div>
                                <div class="middle-gradient mega" [style.background]="middleGadient">
                                    <div
                                        class="jackpot-border"
                                        [ngClass]="{
                                            'yellow-border': tickerValue < eightyPercentValue && tickerValue >= sixtyPercentValue,
                                            'red-border': tickerValue >= eightyPercentValue
                                        }">
                                        <cc-flipclock-redesign
                                            [isMega]="'true'"
                                            [currency]="currency"
                                            [amount]="mustGoSubJackpotsList[0]?.subJackpotValue"
                                            [releaseamount]="mustGoSitecoreObject.attributes.primaryjackpotreleaseamount"
                                            [widgetType]="'MustGoJackpot'"
                                            [lobbyType]="lobbyType"
                                            class="some">
                                        </cc-flipclock-redesign>
                                    </div>
                                </div>
                                <div class="right-gradient"><img src="{{ rightGradientImgSrc }}" /></div>
                            </div>
                        </div>
                    </div>
                    <div class="casino-daily-jackpot" *ngIf="subJackpot1DisplayName != ''">
                        <div class="casino-must-go-jackpot">
                            <div class="jackpot-type" [style.background]="jackpotHeadingBackground">
                                <div class="jackpot-type-text" *ngIf="subJackpot1DisplayName == ''" [style.color]="jackpotHeadingColor">
                                    {{ mustGoProperties.subjackpot1name }}
                                </div>
                                <div class="jackpot-type-text" *ngIf="subJackpot1DisplayName != ''" [style.color]="jackpotHeadingColor">
                                    {{ subJackpot1DisplayName }}
                                </div>
                            </div>
                            <div class="casino-must-go-jackpot-value">
                                <div class="left-gradient"><img src="{{ leftGradientImgSrc }}" /></div>
                                <div class="middle-gradient daily" [style.background]="middleGadient">
                                    <div
                                        class="jackpot-border"
                                        [ngClass]="{
                                            'yellow-border': tickerValue < eightyPercentValue && tickerValue >= sixtyPercentValue,
                                            'red-border': tickerValue >= eightyPercentValue
                                        }">
                                        <cc-flipclock-redesign
                                            [isMega]="'false'"
                                            [currency]="currency"
                                            [amount]="mustGoSubJackpotsList[1]?.subJackpotValue"
                                            [releaseamount]="mustGoSitecoreObject.attributes.subjackpot1releaseamount"
                                            [widgetType]="'MustGoJackpot'"
                                            [lobbyType]="lobbyType">
                                        </cc-flipclock-redesign>
                                    </div>
                                </div>
                                <div class="right-gradient"><img src="{{ rightGradientImgSrc }}" /></div>
                            </div>
                        </div>
                    </div>
                    <div class="casino-hourly-jackpot" *ngIf="subJackpot2DisplayName != ''">
                        <div class="casino-must-go-jackpot">
                            <div class="jackpot-type" [style.background]="jackpotHeadingBackground">
                                <div class="jackpot-type-text" *ngIf="subJackpot2DisplayName == ''" [style.color]="jackpotHeadingColor">
                                    {{ mustGoProperties.subjackpot2name }}
                                </div>
                                <div class="jackpot-type-text" *ngIf="subJackpot2DisplayName != ''" [style.color]="jackpotHeadingColor">
                                    {{ subJackpot2DisplayName }}
                                </div>
                            </div>
                            <div class="casino-must-go-jackpot-value">
                                <div class="left-gradient"><img src="{{ leftGradientImgSrc }}" /></div>
                                <div class="middle-gradient hourly" [style.background]="middleGadient">
                                    <div
                                        class="jackpot-border"
                                        [ngClass]="{
                                            'yellow-border': tickerValue < eightyPercentValue && tickerValue >= sixtyPercentValue,
                                            'red-border': tickerValue >= eightyPercentValue
                                        }">
                                        <cc-flipclock-redesign
                                            [isMega]="'false'"
                                            [currency]="currency"
                                            [amount]="mustGoSubJackpotsList[2]?.subJackpotValue"
                                            [releaseamount]="mustGoSitecoreObject.attributes.subjackpot2releaseamount"
                                            [widgetType]="'MustGoJackpot'"
                                            [lobbyType]="lobbyType">
                                        </cc-flipclock-redesign>
                                    </div>
                                </div>
                                <div class="right-gradient"><img src="{{ rightGradientImgSrc }}" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="casino-mj-right-sec">
                    <div class="casino-jackpot-games-heading jackpot-right-content">
                        <div
                            class="casino-jackpot-games-text"
                            [ngStyle]="{ color: mustGoPlayAnyGameTextColor }"
                            [innerHtml]="jackpotWidgetConfig?.MustGoPlayAnyGameText"></div>
                        <div class="casino-jackpot-see-all" *ngIf="!changeCarouselView">
                            <!-- <button
                                *ngIf="jackpotCta?.url"
                                class="btn btn-primary see-all-btn"
                                [innerHtml]="seeAllText"
                                (click)="gotoJackpots(jackpotCta?.url)"></button> -->
                            @if (jackpotCta?.url) {
                                <cs-gametile-cta
                                    [isLegacy]="isLegacy"
                                    [kind]="'primary'"
                                    [size]="'medium'"
                                    (click)="gotoJackpots(jackpotCta?.url)"
                                    [csDecoratorClass]="'see-all-btn'"
                                    [cta_text]="seeAllText">
                                </cs-gametile-cta>
                            }
                        </div>

                        <div
                            class="casino-seeall-text"
                            [innerHtml]="seeAllText"
                            *ngIf="jackpotCta?.url && changeCarouselView"
                            (click)="gotoJackpots(jackpotCta?.url)"
                            [ngStyle]="{ color: subCategoryTextColor }"></div>
                    </div>
                    <div class="casino-jackpot-games">
                        <!-- games lobby starts -->
                        <div class="casino-mustgo-games casino-lobby-width-gametile" [style.overflow-x]="!isTouch ? 'unset' : 'scroll'">
                            <div class="casino-carousel-wrapper scrollable-games">
                                <cc-jackpot-widget-redesign-grid
                                    *ngIf="games && games.length > 0"
                                    [games]="games"
                                    [gridCols]="games.length"
                                    [categoryObj]="categoryObj"
                                    [widgetType]="'MustGoJackpot'">
                                </cc-jackpot-widget-redesign-grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- games lobby ends -->
</div>
