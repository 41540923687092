import { NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';

import {
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    DesignSystemEnablerService,
    FastLoadingGamesService,
    GameDataManager,
    GameLobbyModel,
    IContentImage,
    IContentLink,
    JackpotDataService,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, GameTileCtaComponent } from '@frontend/casino-ui';
import { NavigationService } from '@frontend/vanilla/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FlipclockRedesignComponent } from '../flipclock-redesign/flipclock-redesign.component';
import { JackpotWidgetRedesignGridComponent } from '../jackpot-widget-redesign-grid/jackpot-widget-redesign-grid.component';

@Component({
    selector: 'cc-must-go-redesign-new',
    templateUrl: 'must-go-redesign-new.component.html',
    standalone: true,
    imports: [NgIf, NgClass, FlipclockRedesignComponent, NgStyle, JackpotWidgetRedesignGridComponent, GameTileCtaComponent, DecoratorClassDirective],
})
export class MustGoJackpotRedesignNewComponent implements OnInit, OnDestroy, OnChanges {
    @Input() category: any;
    @Input() isTouch: boolean;
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    @Input() changeCarouselView: boolean;
    @Output() isjackpotFeed = new EventEmitter<object>();
    jackpotWidgetConfig: any;
    currency: string;
    releaseTime: string;
    games: GameLobbyModel[] = [];
    showMustGo: boolean;
    categoryObj: any;
    mustGoFeedObject: any;
    mustGoSubJackpotsList: any = [];
    mustGoSitecoreObject: any;
    mustGoProperties: any;
    subJackpot1DisplayName: string = '';
    subJackpot2DisplayName: string = '';
    subJackpot3DisplayName: string = '';
    leftGradientImgSrc: string;
    rightGradientImgSrc: string;
    middleGadient: string;
    jackpotHeadingBackground: string;
    jackpotHeadingColor: string;
    mustgoGamesAlignment: string;
    gamesHeadingText: string;
    backgroundTransparency: boolean;
    seeAllText: string;
    jackpotWidgetBackgroundImage: IContentImage;
    jackpotCta: IContentLink;
    mustGoPlayAnyGameTextColor: string;
    eightyPercentValue: number;
    sixtyPercentValue: number;
    tickerValue: number;
    primaryJackpotDisplayname: string = '';

    private jackpotsFeed: any;
    private bufferValue: number;
    private reftime: any;
    private ctaText: string;
    totalJpGroupList: any;
    glassEffectColor: string;
    private destroy$ = new Subject<void>();
    subCategoryTextColor: string;
    JackpotDisplayNameConfig: any;
    isLegacy: boolean = false;
    constructor(
        private configProviderService: ConfigProviderService,
        private jackpotDataService: JackpotDataService,
        private gameDataManager: GameDataManager,
        private fastLoadingGamesService: FastLoadingGamesService,
        private casinoLobbyService: CasinoLobbyService,
        private casinoManager: CasinoManager,
        private navigation: NavigationService,
        private designSystemEnablerService: DesignSystemEnablerService,
    ) {}
    ngOnChanges(): void {
        this.startJackpotFeed();
    }
    ngOnInit(): void {
        const jackpotDataClientConfig: any = this.configProviderService.provideJackpotConfig();
        if (jackpotDataClientConfig.enableJackpotSortGames) {
            this.getSortingGames();
        }
        this.reftime = jackpotDataClientConfig.mustGoJackpotTimerBase;
        this.reftime = this.reftime.split(':');
        this.jackpotWidgetConfig = jackpotDataClientConfig?.jackpotWidgetConfigurations;
        this.jackpotHeadingBackground = this.jackpotWidgetConfig?.MustGojackpotHeadingBackground;
        this.jackpotHeadingColor = this.jackpotWidgetConfig?.MustGojackpotHeadingColor;
        this.middleGadient = this.jackpotWidgetConfig?.MustGoMiddleGadient;
        this.mustGoPlayAnyGameTextColor = this.jackpotWidgetConfig?.MustGoPlayAnyGameTextColor;
        if (this.jackpotWidgetConfig?.MustGoGlassEffect && this.jackpotWidgetConfig?.MustGoGlassEffect.toLowerCase() === 'true') {
            this.backgroundTransparency = true;
            this.glassEffectColor = this.jackpotWidgetConfig?.GlassEffectColor;
        }
        this.leftGradientImgSrc = jackpotDataClientConfig?.mustGoJackpotLeftGradientImage?.src;
        this.rightGradientImgSrc = jackpotDataClientConfig?.mustGoJackpotRightGradientImage?.src;

        this.fastLoadingGamesService.fastLoadingGamesFeedObservable.pipe(takeUntil(this.destroy$)).subscribe((fastLoadingGames: any) => {
            this.fastLoadingGamesService.setFastLoadingGames(this.games, fastLoadingGames);
        });

        this.ctaText = jackpotDataClientConfig?.jackpotWidgetConfigurations?.SeeAll;
        this.subCategoryTextColor = this.casinoLobbyService.getsubCategoryTextColor(this.category);
        this.seeAllText = this.ctaText + ' ' + '(' + this.games?.length + ')';
        this.isLegacy = !this.designSystemEnablerService.isEnabled();
    }

    gotoJackpots(ctaUrl: any): void {
        if (ctaUrl) this.navigation.goTo(ctaUrl, { replace: false, forceReload: true });
    }

    private startJackpotFeed(): void {
        this.jackpotDataService.jackpotFeedObservable.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
            if (response) this.refreshJackpotsNew();
        });
    }

    private refreshJackpotsNew(): void {
        let sortGameslist: any;
        this.mustGoSitecoreObject = this.category.sitecoreData;
        this.mustGoProperties = this.mustGoSitecoreObject.attributes;
        this.JackpotDisplayNameConfig = this.configProviderService.provideGlobalConfig()?.jackpotDisplayNameConfigurations;
        if (this.mustGoSitecoreObject.showMustGo) {
            this.showMustGo = true;
            this.jackpotsFeed = this.jackpotDataService.getJackpotData();
            const mustGoJackpot = this.mustGoSitecoreObject.mustGoJackpot;
            if (this.jackpotsFeed && mustGoJackpot && mustGoJackpot !== '') {
                const jackpotFilterData: any = Object.keys(this.jackpotsFeed)
                    .filter((key) => key?.toLowerCase().includes(mustGoJackpot.toLowerCase()))
                    .reduce((cur, key) => {
                        return Object.assign(cur, { [key]: this.jackpotsFeed[key] });
                    }, {});
                if (jackpotFilterData && Object.keys(jackpotFilterData).length > 0) {
                    const jackpot = this.jackpotsFeed[mustGoJackpot.toLowerCase()];
                    this.mustGoFeedObject = jackpot;
                    (this.jackpotCta = this.mustGoFeedObject.ctaLink), (this.jackpotWidgetBackgroundImage = this.mustGoFeedObject?.backgroundImage);
                    const currency = this.mustGoFeedObject.amount.match(/[^\d\.\,\s]/g);
                    if (currency && currency.length > 0) {
                        this.currency = '';
                        for (let i = 0; i < currency.length; i++) {
                            this.currency = this.currency + currency[i];
                        }
                        this.currency = this.currency.replace(/\,/g, '');
                        this.currency = this.currency.replace(/\./g, '');
                    }
                    const allSubJackpotsList = jackpot.subJackpotDetails;
                    this.mustGoSubJackpotsList = [];
                    const sitecoreSubjackpots: any = {
                        'primaryJackpot-name': this.mustGoSitecoreObject.attributes.primaryjackpotname,
                        'subJackpot1-Name': this.mustGoSitecoreObject.attributes.subjackpot1name,
                        'subJackpot2-Name': this.mustGoSitecoreObject.attributes.subjackpot2name,
                        'subJackpot3-Name': this.mustGoSitecoreObject.attributes.subjackpot3name,
                    };
                    for (const i in sitecoreSubjackpots) {
                        allSubJackpotsList.forEach((j: any) => {
                            if (
                                sitecoreSubjackpots[i] &&
                                j.subJackpotName &&
                                sitecoreSubjackpots[i].toLowerCase() == j.subJackpotName.toLowerCase()
                            ) {
                                j.subJackpotValue = parseFloat(j.subJackpotValue);
                                j.ribbonName = this.mustGoProperties.subjackpot1name;
                                this.mustGoSubJackpotsList.push(j);
                            }
                        });
                    }

                    if (this.mustGoSubJackpotsList[0]) {
                        const jackpotDisplayName = this.JackpotDisplayNameConfig[this.mustGoSubJackpotsList[0].jackpotFeedId];
                        this.primaryJackpotDisplayname = jackpotDisplayName
                            ? jackpotDisplayName
                            : this.mustGoSubJackpotsList[0]?.subJackpotName?.toLowerCase() === this.mustGoProperties.primaryjackpotname?.toLowerCase()
                              ? this.mustGoProperties?.primaryjackpotname
                              : '';
                        this.mustGoSubJackpotsList[0].ribbonName =
                            this.primaryJackpotDisplayname != '' ? this.primaryJackpotDisplayname : this.mustGoProperties.primaryjackpotname;
                        this.mustGoSubJackpotsList[0].releaseAmount = this.mustGoProperties.primaryjackpotreleaseamount;
                    }
                    if (this.mustGoSubJackpotsList[1]) {
                        const jackpotDisplayName = this.JackpotDisplayNameConfig[this.mustGoSubJackpotsList[1].jackpotFeedId];
                        this.subJackpot1DisplayName = jackpotDisplayName
                            ? jackpotDisplayName
                            : this.mustGoSubJackpotsList[1]?.subJackpotName?.toLowerCase() === this.mustGoProperties?.subjackpot1name?.toLowerCase()
                              ? this.mustGoProperties?.subjackpot1name
                              : '';
                        this.mustGoSubJackpotsList[1].ribbonName =
                            this.subJackpot1DisplayName != '' ? this.subJackpot1DisplayName : this.mustGoProperties.subjackpot1name;
                        this.mustGoSubJackpotsList[1].releaseAmount = this.mustGoProperties.subjackpot1releaseamount;
                    }
                    if (this.mustGoSubJackpotsList[2]) {
                        const jackpotDisplayName = this.JackpotDisplayNameConfig[this.mustGoSubJackpotsList[2].jackpotFeedId];
                        this.subJackpot2DisplayName = jackpotDisplayName
                            ? jackpotDisplayName
                            : this.mustGoSubJackpotsList[2]?.subJackpotName?.toLowerCase() === this.mustGoProperties?.subjackpot2name?.toLowerCase()
                              ? this.mustGoProperties?.subjackpot2name
                              : '';
                        this.mustGoSubJackpotsList[2].ribbonName =
                            this.subJackpot2DisplayName != '' ? this.subJackpot2DisplayName : this.mustGoProperties.subjackpot2name;
                        this.mustGoSubJackpotsList[2].releaseAmount = this.mustGoProperties.subjackpot2releaseamount;
                    }
                    if (this.mustGoSubJackpotsList[3]) {
                        const jackpotDisplayName = this.JackpotDisplayNameConfig[this.mustGoSubJackpotsList[3].jackpotFeedId];
                        this.subJackpot3DisplayName = jackpotDisplayName
                            ? jackpotDisplayName
                            : this.mustGoSubJackpotsList[3]?.subJackpotName?.toLowerCase() === this.mustGoProperties?.subjackpot3name?.toLowerCase()
                              ? this.mustGoProperties?.subjackpot3name
                              : '';
                        this.mustGoSubJackpotsList[3].ribbonName =
                            this.subJackpot3DisplayName != '' ? this.subJackpot3DisplayName : this.mustGoProperties.subjackpot3name;
                        this.mustGoSubJackpotsList[3].releaseAmount = this.mustGoProperties.subjackpot3releaseamount;
                    }
                    this.primaryJackpotDisplayname === '' ? (this.showMustGo = false) : (this.showMustGo = true);
                    if (!this.primaryJackpotDisplayname) {
                        this.isjackpotFeed.emit({ isJackpotdataForParallax: false });
                    } else {
                        this.isjackpotFeed.emit({ isJackpotdataForParallax: true });
                    }
                }
                if (this.totalJpGroupList && this.totalJpGroupList.length > 0) {
                    sortGameslist = this.jackpotDataService.filterJpGroup(this.totalJpGroupList, this.mustGoFeedObject?.jackpotGroupName);
                }
                if (this.mustGoFeedObject && this.mustGoFeedObject.gamesList) {
                    this.setGames(this.mustGoFeedObject.gamesList, sortGameslist);
                }
                this.seeAllText = (this.jackpotCta?.text ? this.jackpotCta?.text : this.ctaText) + ' ' + '(' + this.games?.length + ')';
            }
        } else {
            this.showMustGo = false;
        }
        this.eightyPercentValue = 0.8 * parseInt(this.mustGoSitecoreObject.attributes.subjackpot1releaseamount);
        this.sixtyPercentValue = 0.6 * parseInt(this.mustGoSitecoreObject.attributes.subjackpot1releaseamount);
        this.bufferValue = parseInt(this.mustGoSubJackpotsList[0]?.subJackpotValue);
        this.tickerValue = this.bufferValue;
    }
    private setGames(gameslist: string[], sortGameslist?: any): void {
        this.games = [];
        if (gameslist && gameslist.length > 0) {
            let starttileBgMode: boolean = false;
            const newgridbgcolor = this.category?.sitecoreData?.attributes?.newgridbgcolor;
            this.categoryObj = { ...this.category };
            this.categoryObj.rowCount = '-1';
            for (let i = 0; i < gameslist.length; i++) {
                const gameIdString: string = gameslist[i];
                const gameInfo = this.casinoLobbyService.getGameInfo(gameIdString);
                if (gameInfo) {
                    let disabledGame: boolean = false;
                    const disabledGames = this.casinoLobbyService.getDisabledGames();
                    disabledGames.forEach((game: string) => {
                        if (game.toLowerCase() === gameInfo.game.toLowerCase()) {
                            disabledGame = true;
                        }
                    });
                    if (!disabledGame) {
                        gameInfo.icon = this.category.isIconSixCategory ? '6' : this.category.isIconSevenCategory ? '7' : '1';
                        gameInfo.lobbyType = this.lobbyType;
                        const gameObj = this.gameDataManager.setGameLobbyModel(gameInfo, this.isTouch);
                        gameObj.gameContainer = this.gameContainer;
                        gameObj.bgTileMode = this.category.isIconSevenCategory ? (starttileBgMode ? starttileBgMode : false) : false;
                        gameObj.gameTileBorderRadius = this.casinoLobbyService.getBorderRadius(this.category);
                        gameObj.gameTileDropShadow = this.casinoLobbyService.getDropShadow(this.category);
                        gameObj.infoIconClass = this.casinoManager.assignGameClass(this.casinoManager.getCurrentWidth(), gameInfo.icon);
                        gameObj.newgridbgcolor = newgridbgcolor;
                        starttileBgMode = !starttileBgMode;
                        this.games.push(gameObj);
                    }
                }
            }
            if (sortGameslist && sortGameslist.length > 0) {
                this.games = this.jackpotDataService.filterGames(sortGameslist, this.games);
            }
            const jackpotDataClientConfig: any = this.configProviderService.provideJackpotConfig();
            const seeAll = jackpotDataClientConfig?.jackpotWidgetConfigurations.SeeAll;
            this.seeAllText = seeAll + ' ' + '(' + this.games?.length + ')';
        }
    }

    getSortingGames(): void {
        this.jackpotDataService.jpSortGamesObservable.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
            if (data) {
                this.totalJpGroupList = data?.jackpotGroupGamesList;
                this.refreshJackpotsNew();
            }
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
