import { Pipe, PipeTransform } from '@angular/core';

import { ConfigProviderService } from '../../config-provider/config-provider.service';

@Pipe({
    name: 'sitecore_image_resize',
    standalone: true,
})
export class SitecoreImageResizePipe implements PipeTransform {
    constructor(private configProvider: ConfigProviderService) {}

    transform(value: string): string {
        const imageLoadOptimizationConfig = this.configProvider.provideImageLoadOptimizationClientConfig();
        if (
            imageLoadOptimizationConfig &&
            imageLoadOptimizationConfig.isDynamicResizingEnabled &&
            imageLoadOptimizationConfig.dynamicResizingQueryString &&
            imageLoadOptimizationConfig.dynamicResizingQueryString.length > 0 &&
            !this.isImageRezied(value)
        ) {
            const qs = (value.indexOf('?') !== -1 ? '&' : '?') + imageLoadOptimizationConfig.dynamicResizingQueryString;
            value = value + qs;
        }
        return value;
    }

    private isImageRezied(src: string): boolean {
        return src.indexOf('resized=true') !== -1;
    }
}
