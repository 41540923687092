export function getPosition(element: HTMLElement): { left: number; top: number } {
    let xPosition = 0;
    let yPosition = 0;

    while (element) {
        xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
        yPosition += element.offsetTop - element.scrollTop + element.clientTop;
        element = element.offsetParent as HTMLElement;
    }

    return { left: xPosition, top: yPosition };
}

export function getClientPosition(element: HTMLElement): { left: number; top: number } {
    const postion = element.getBoundingClientRect();
    return { left: postion.x, top: postion.y };
}

export function getHiddenValues(child: HTMLElement, parent: HTMLElement): { x: number; y: number } {
    return {
        x: child.clientWidth - parent.clientWidth,
        y: child.clientHeight - parent.clientHeight,
    };
}

export function isElementInView(element: HTMLElement, fullyInView: boolean = false) {
    const pageTop = document.documentElement.scrollTop;
    const pageBottom = pageTop + document.documentElement.clientHeight;
    const elementTop = element.offsetTop;
    const elementBottom = elementTop + element.clientHeight;

    if (fullyInView === true) {
        return pageTop < elementTop && pageBottom > elementBottom;
    } else {
        return elementTop <= pageBottom && elementBottom >= pageTop;
    }
}

export function getTranslateValues(element: HTMLElement): { x: number; y: number; z: number } {
    try {
        const style = window.getComputedStyle(element);
        const matrix = style['transform'];

        // No transform property. Simply return 0 values.
        if (matrix === 'none' || typeof matrix === 'undefined') {
            return { x: 0, y: 0, z: 0 };
        }

        // Can either be 2d or 3d transform
        const matrixType = matrix.includes('3d') ? '3d' : '2d';
        const matrixArray = matrix.match(/matrix.*\((.+)\)/);
        if (matrixArray && matrixArray.length) {
            const matrixValues = matrixArray[1]!.split(', ');

            // 2d matrices have 6 values
            // Last 2 values are X and Y.
            // 2d matrices does not have Z value.
            if (matrixType === '2d') {
                return { x: parseFloat(matrixValues[4]!), y: parseFloat(matrixValues[5]!), z: 0 };
            }

            // 3d matrices have 16 values
            // The 13th, 14th, and 15th values are X, Y, and Z
            if (matrixType === '3d') {
                return { x: parseFloat(matrixValues[12]!), y: parseFloat(matrixValues[13]!), z: parseFloat(matrixValues[14]!) };
            }

            return { x: 0, y: 0, z: 0 };
        }

        return { x: 0, y: 0, z: 0 };
    } catch {
        return { x: 0, y: 0, z: 0 };
    }
}

export function onlyUnique(value: string, index: number, self: Array<string>): boolean {
    return self.indexOf(value) === index;
}

export function hasClass(el: HTMLElement, className: string): boolean {
    if (el.classList) return el.classList.contains(className);
    return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
}

export function addClass(className: string, el?: HTMLElement): void {
    const elm = el || document.documentElement;
    if (elm.classList) elm.classList.add(className);
    else if (!hasClass(elm, className)) elm.className += ' ' + className;
}

export function removeClass(className: string, el?: HTMLElement): void {
    const elm = el || document.documentElement;
    if (elm.classList) elm.classList.remove(className);
    else if (hasClass(elm, className)) {
        const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
        elm.className = elm.className.replace(reg, ' ');
    }
}

export function endsWith(str: string, suffix: string): boolean {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

export function getAmount(amount: any, currency: string, locale: string): { value: string; isValid: boolean } {
    try {
        if (isNaN(amount) && amount.indexOf(' ') > -1) currency = amount.split(' ')[1];

        const formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
        });

        let value = parseFloat(amount);
        value = isNaN(value) || !value ? 0 : value;
        return { value: formatter.format(value), isValid: value !== 0 };
    } catch {
        return { value: amount, isValid: false };
    }
}

export function toDate(text: any): string {
    try {
        if (isNaN(text)) {
            const date = text.replace(/\./g, '/').replaceAll(' ', '');
            const year = date.substring(0, 4);
            const month = date.substring(5, 7);
            const day = date.substring(8, 10);
            return day + '/' + month + '/' + year;
        }

        const timestamp = parseFloat(text);
        return new Date(timestamp).toLocaleDateString('en-GB');
    } catch {
        return text;
    }
}

export function toTimeStamp(text: any): string {
    try {
        let totalMinutes = text.replace(/\./g, '/').replaceAll(' ', '');
        totalMinutes = totalMinutes && !isNaN(totalMinutes) ? parseInt(totalMinutes) : null;
        if (!totalMinutes) return text;

        const minutes = Math.floor(totalMinutes % 60);
        const hours = Math.floor(Math.floor(totalMinutes / 60) % 24);
        const days = Math.floor(totalMinutes / 60 / 24);

        let timeStamp = '';
        if (days) timeStamp = days + (days > 1 ? ' days' : ' day');
        if (hours) timeStamp = (timeStamp ? timeStamp + ' ' : '') + hours + (hours > 1 ? ' hrs' : ' hr');
        if (minutes) timeStamp = (timeStamp ? timeStamp + ' ' : '') + minutes + (minutes > 1 ? ' mins' : ' min');

        return timeStamp;
    } catch {
        return text;
    }
}

export function textToNumber(text: string) {
    const small: any = {
        zero: 0,
        one: 1,
        two: 2,
        three: 3,
        four: 4,
        five: 5,
        six: 6,
        seven: 7,
        eight: 8,
        nine: 9,
        ten: 10,
        eleven: 11,
        twelve: 12,
        thirteen: 13,
        fourteen: 14,
        fifteen: 15,
        sixteen: 16,
        seventeen: 17,
        eighteen: 18,
        nineteen: 19,
        twenty: 20,
        thirty: 30,
        forty: 40,
        fifty: 50,
        sixty: 60,
        seventy: 70,
        eighty: 80,
        ninety: 90,
    };

    const magnitude: any = {
        hundred: 100,
        thousand: 1000,
        million: 1000000,
        billion: 1000000000,
    };

    let finalNumber = 0;
    const words = text.toString().split(/[\s-]+/);

    for (let i = 0; i < words.length; i++) {
        const word = words[i];
        let number = small[word] || null;

        if (number !== null) finalNumber += number;
        else {
            number = magnitude[word] || null;
            if (number !== null) {
                finalNumber = finalNumber ? finalNumber * number : number;
            } else finalNumber = -1;
        }
    }

    return finalNumber;
}

export function elementScroll(
    element: HTMLElement,
    offsetWidth: number,
    isLeft: boolean,
    leftCheckValue: number = 0,
    event?: Event,
): { showLeft: boolean; showRight: boolean } {
    event && event.preventDefault();
    event && event.stopImmediatePropagation();

    let offset = element.scrollLeft;
    offset = isLeft ? offset - offsetWidth : offset + offsetWidth;
    offset = offset < 0 ? 0 : offset;

    if (!isLeft && element.scrollWidth - offset - offsetWidth < offsetWidth) offset = element.scrollWidth;

    if (isLeft && offset < offsetWidth) offset = 0;

    const showLeft = offset > leftCheckValue;
    const showRight = element.scrollWidth - leftCheckValue * 2 > element.clientWidth + offset;

    const isSafari = hasClass(document.documentElement, 'is-safari');
    if (isSafari) {
        element.style.overflow = 'auto';
        requestAnimationFrame(() => (element.scrollLeft = offset));
        setTimeout(() => (element.style.overflow = 'hidden'), 500);
    } else element.scrollLeft = offset;

    return { showLeft, showRight };
}

export function replaceUrlState(urlPrefix: string): void {
    const location = window.location.href;
    window.history.replaceState(null, '', location.substring(0, location.indexOf(urlPrefix)));
}

export function pushUrlState(urlParts: Array<string>): void {
    window.history.pushState({}, '', window.location.origin + '/' + urlParts.join('/'));
}

export function isNotInUrl(urlPrefix: string): boolean {
    return window.location.href.indexOf(urlPrefix) === -1;
}

export function findParent(element: HTMLElement, className: string): HTMLElement | null {
    // while (element.parentElement && (element = element.parentElement) && !element.classList.contains(className));
    // return element;
    return element.closest('.' + className);
}
