<div *ngIf="isVisible">
    <div class="casino-page-nav" [ngStyle]="{ 'background-color': '#1a1a1a' }" ms-feedback="after">
        <div class="left-nav">
            <a class="icon" (click)="goBack()">
                <i class="theme-left"></i>
            </a>
        </div>
        <a ms-marquee [hidden]="error" (click)="goBack()">
            <cc-global-translate [key]="title"></cc-global-translate>
        </a>
        <div class="clear"></div>
    </div>
</div>
