import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { PlatformApiService } from '../platform-api/platform-api.service';

@Injectable({
    providedIn: 'root',
})
export class NewsFeedV2Service {
    newsFeedData: any;
    isEnabled: boolean;
    isEnabledOnHome: boolean;
    isEnabledOnNonHome: boolean;
    private newsFeedV2Subject = new BehaviorSubject<any>(null);
    newsFeedV2Observable: Observable<any> = this.newsFeedV2Subject.asObservable();
    newsFeedMap: Map<string, any> = new Map<string, any>();
    newsFeedV2WidgetSubject = new BehaviorSubject<any>(null);
    newsFeedV2WidgetObservable: Observable<any> = this.newsFeedV2WidgetSubject.asObservable();
    constructor(private api: PlatformApiService) {}
    setNewsFeedMap(path: string) {
        this.api.get('newsfeed/getNewsFeedContent', { path: path }, { withCredentials: true }).subscribe(
            (response: any) => {
                if (response) {
                    this.newsFeedMap.set(path, response);
                    this.newsFeedV2Subject.next(this.newsFeedMap);
                }
            },
            () => {},
        );
    }
    getNewsFeedMap(path: string) {
        const newsFeedMapItem = this.newsFeedMap.get(path);
        if (newsFeedMapItem) {
            if (newsFeedMapItem.isSiteCoreDataFetched) return newsFeedMapItem;
            else return null;
        } else {
            this.setNewsFeedMap(path);
            return null;
        }
    }
    // NewsFeedWidget for other products
    setNewsFeedContent(path: string) {
        this.api.get('newsfeed/getNewsFeedContent', { path: path }, { withCredentials: true }).subscribe(
            (response: any) => {
                if (response) {
                    this.newsFeedV2WidgetSubject.next(response);
                }
            },
            () => {},
        );
    }

    setNewsFeedResponseArray(newsFeedResponse: any) {
        const tempArr: any = [];
        newsFeedResponse.forEach((element: any) => {
            const ele = element.fields;
            tempArr.push({
                header: ele['header'] != null ? ele['header'] : '',
                subHeader: ele['sub Header'] != null ? ele['sub Header'] : '',
                type: element['templateName'],
                iconClass: ele['icon Class'],
                gameID: ele['game ID'] != null ? ele['game ID'] : '',
                jackpotGroupName: ele['jackpot Group Name'] != null ? ele['jackpot Group Name'] : '',
                link: ele.link != null ? ele.link.url : '#',
                value: ele['value'] != null ? ele['value'] : '',
                condition: ele['condition'] != null ? ele['condition'] : '',
                subTitle: ele['subTitle'] != null ? ele['subTitle'] : '',
                displayLiveStatus: ele['displayLiveStatus'] != null ? ele['displayLiveStatus'] : '',
                displayDealerImage: ele['displayDealerImage'] != null ? ele['displayDealerImage'] : '',
            });
        });
        newsFeedResponse = tempArr;
        return newsFeedResponse;
    }
}
