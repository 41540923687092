import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csWidgetConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: widgetConfigClientConfigFactory,
})
export class WidgetConfigClientConfig {
    authenticatePromoEdsWidget: boolean;
    isRpFavWidgetEnabled: any;
    isGymlInFavourites: any;
    favouriteGamesBaseCount: any;
    recentGamesCountLimit: number;
    enableRcpwidgetHelpContactUs: any;
    isStickyEnabledForWidget: any;
    maxHeightCount: number;
    enableHelpContactForPrelogin: any;
    enableBingoWidgetForLabel: boolean;
    enableWidgetinFreespinsPage: boolean;
    enablePromoEdsWidget: boolean;
    widgetLinks: any;
    lobbyTypeForRpFavOnOtherProducts: string;
    lobbyTypeForRpGymlOnOtherProducts: string;
    lobbyTypeForFooterOnOtherProducts: string;
    lobbyTypeForSlotRacesOnOtherProducts: string;
    enableSlotRacesForOtherProducts: boolean;
    subCatRouteForSlotRacesOnOtherProducts: string;
    subCategoryRouteForFooterOnOtherProducts: string;
    rpGymlWidgetPostHeightTimeOut: number;
    rpFavWidgetPostHeightTimeOut: number;
    rpFavTogglePostHeightTimeOut: number;
    displayFavInRPFavWidget: boolean;
}

export function widgetConfigClientConfigFactory(service: LazyClientConfigService) {
    return service.get(WidgetConfigClientConfig);
}
