import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';

@Injectable({
    providedIn: 'root',
})
export class CashierService {
    constructor(
        private api: PlatformApiService,
        private configProviderService: ConfigProviderService,
    ) {}

    getQuickDepositAllowedStatus(): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(apiPathsConfig.cashier['csQuickDeposit']);
    }
}
