<div
    class="opt-teaser-bg-image"
    [ngClass]="'opt-teaser-bg-image-align-' + (teaserBackgroundImageAlignment | lowercase)"
    [style.background-position]="teaserBackgroundPosition"
    [ngStyle]="{ 'background-image': 'url(' + (teaser.backgroundImage.src | sitecore_image_resize) + ')' }"
    *ngIf="teaser.backgroundImage && isMobileImage === 'Mobile'"></div>
<div
    class="opt-teaser-bg-image"
    [ngClass]="'opt-teaser-bg-image-align-' + (teaserBackgroundImageAlignment | lowercase)"
    [style.background-position]="teaserBackgroundPosition"
    [ngStyle]="{ 'background-image': 'url(' + (tabletImage | sitecore_image_resize) + ')' }"
    *ngIf="(teaser.tabletImage || teaser.desktopImage) && isMobileImage === 'Tablet'"></div>
<div
    class="opt-teaser-bg-image"
    [style.background-position]="teaserBackgroundPosition"
    [ngStyle]="{ 'background-image': 'url(' + (teaser.desktopImage.src | sitecore_image_resize) + ')' }"
    *ngIf="teaser.desktopImage && isMobileImage === 'Desktop'"></div>
