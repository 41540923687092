import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'cslmtData', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: lmtDataClientConfigFactory,
})
export class LMTDataClientConfig {
    useLMTFeedForLobby: boolean;
    brand: string;
    invokerProd: string;
    enableLMTGameLaunch: boolean;
    lmtCacheTimeout: number;
    lmtCacheTimeoutAuth: number;
    lobbyType: string;
    noIconUrl: string;
    fallbackCategoryTheme: string;
    homeCategory: string;
    gamesYouMightLikeCategory: string;
    lobbyTypeMap: any;
    enableDefaultHomeUrl: any;
    defaultProductHomeUrl: string;
    enableValidateGames: boolean;
    otherProductsLobbyTypeForOdr: any;
    enableLMTMockData: boolean;
}

export function lmtDataClientConfigFactory(service: LazyClientConfigService) {
    return service.get(LMTDataClientConfig);
}
