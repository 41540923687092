import { LowerCasePipe, NgClass, NgIf } from '@angular/common';
import { Component, DestroyRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import {
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    CookieService,
    LobbyItem,
    SlotRacesService,
    TeaserService,
} from '@casinocore/platform/core';
import { UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { rxEffects } from '@rx-angular/state/effects';
import { filter } from 'rxjs/operators';

import { CoinEconomyAnimationComponent } from '../coin-economy-animation/coin-economy-animation.component';

@Component({
    selector: 'cs-full-takeover-background',
    templateUrl: 'full-takeover-background.component.html',
    standalone: true,
    imports: [NgIf, NgClass, CoinEconomyAnimationComponent, LowerCasePipe],
})
export class FullTakeoverBackgroundComponent implements OnInit, OnChanges, OnDestroy {
    private readonly rxEffects = rxEffects();
    @Input() routeCategoryType: string;
    @Input() routeCurrentProduct: string;
    @Input() currentLobbyType: string;
    showImage: boolean;
    isTouch: boolean;
    isEnableLobbyStaticImage: boolean;
    isEnableLobbyBgImage: boolean;
    isEnableTransparentLobbyBackground: any;
    teaserData: any;
    lobbyBackgroundImg: string = '';
    fullTakeoverBgToggleType: string = '';
    currentCategoryInfo: any;
    coinDetails: any;
    enableCoinShower: boolean;
    fullTakeOverLobbyBackgroundColor: string;

    constructor(
        private configProviderService: ConfigProviderService,
        private teaserService: TeaserService,
        private casinoLobbyService: CasinoLobbyService,
        private casinoManager: CasinoManager,
        private slotRaces: SlotRacesService,
        private user: UserService,
        private cookieService: CookieService,
        private destroyRef: DestroyRef,
    ) {}
    ngOnInit() {
        const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        this.isEnableLobbyBgImage = featuresConfig.enableLobbyBackgroundImage;
        this.isEnableTransparentLobbyBackground = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.currentLobbyType,
            featuresConfig.enableTransparentLobbyBackground,
        );
        this.fullTakeoverBgToggleType = this.configProviderService.provideGlobalConfig().lobbyConfigurations.fullTakeoverBgToggleType;

        this.currentLobbyType = this.casinoManager.getLobbyTypeForProduct(this.routeCurrentProduct);
        if (featuresConfig.enableLobbyBackgroundImage) {
            this.rxEffects.register(this.teaserService.indexChangedDataObservable, (teaserData: any) => {
                if (!this.isTouch && teaserData != null && teaserData.lobbyBackgroundImage) {
                    this.showImage = true;
                    this.teaserData = teaserData;
                    this.lobbyBackgroundImg = this.teaserData.lobbyBackgroundImage.src ? this.teaserData.lobbyBackgroundImage.src : '';
                } else {
                    this.showImage = false;
                }
            });
        }
        this.rxEffects.register(this.user.events.pipe(filter((e: any) => e instanceof UserLoginEvent)), () => {
            if (this.currentCategoryInfo?.categoryInfo?.sitecoreData?.coinShower?.length > 0 && !this.cookieService.getCookie('COIN_SHOWER')) {
                this.showShowerCoin(true);
            }
        });
    }

    ngOnChanges() {
        this.displayLobbyStaticImage();
    }

    displayLobbyStaticImage() {
        this.teaserService.indexChangeTeaserData(null);
        if (this.currentLobbyType && (!this.routeCategoryType || this.routeCategoryType === '')) {
            this.setCategoryRoute();
        }
        if (this.routeCategoryType) {
            this.currentCategoryInfo = this.casinoLobbyService.getCategoryInfo(this.currentLobbyType, this.routeCategoryType);
            const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
            this.isEnableLobbyStaticImage = featuresConfig.enableLobbyStaticImage;
            this.isTouch = this.casinoManager.isTouch();
            if (!featuresConfig.enableLobbyBackgroundImage) {
                this.fullTakeOverLobbyBackgroundColor =
                    featuresConfig?.fullTakeOverImageForAllCategories?.parameters?.FullTakeOverLobbyBackgroundColor;
                this.lobbyBackgroundImg = '';
                if (featuresConfig.enableFullTakeOverImageForAllCategories && featuresConfig.fullTakeOverImageForAllCategories != null) {
                    this.lobbyBackgroundImg = featuresConfig?.fullTakeOverImageForAllCategories.image.src;
                    this.showImage = true;
                } else if (featuresConfig.enableLobbyStaticImageForMobile || this.isEnableLobbyStaticImage) {
                    const imageData = this.currentCategoryInfo.categoryInfo?.sitecoreData;
                    if (
                        this.isTouch &&
                        featuresConfig.enableLobbyStaticImageForMobile &&
                        imageData != null &&
                        imageData?.fullTakeOverStaticBackgroundImage
                    ) {
                        this.lobbyBackgroundImg = imageData.fullTakeOverStaticBackgroundImage.src
                            ? imageData.fullTakeOverStaticBackgroundImage.src
                            : '';
                        this.showImage = true;
                    } else if (!this.isTouch && this.isEnableLobbyStaticImage && imageData != null && imageData?.fullTakeOverStaticBackgroundImage) {
                        this.lobbyBackgroundImg = '';
                        this.lobbyBackgroundImg = imageData.fullTakeOverStaticBackgroundImage.src
                            ? imageData.fullTakeOverStaticBackgroundImage.src
                            : '';
                        this.showImage = true;
                    }
                } else {
                    this.showImage = false;
                }
            }
        }
        if (this.currentCategoryInfo?.categoryInfo?.sitecoreData?.coinShower?.length > 0 && !this.cookieService.getCookie('COIN_SHOWER'))
            this.showShowerCoin();
    }
    setCategoryRoute() {
        const lobbyInfo: LobbyItem = this.casinoLobbyService.getLobbyInfo(this.currentLobbyType) || new LobbyItem();
        if (lobbyInfo && lobbyInfo.categoriesMap && lobbyInfo.categoriesMap.size > 0) {
            this.routeCategoryType = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(this.currentLobbyType).categoryInfo.categoryid; // lobbyInfo.categoriesMap.entries().next().value[1].categoryid;
            this.casinoLobbyService.categoryNotFoundSubject.next(null);
        } else {
            //emit CategoryNotFound
            this.casinoLobbyService.categoryNotFoundSubject.next('CategoryNotFound');
        }
    }

    showShowerCoin(postLogin: boolean = false) {
        const enableCoinShower = this.configProviderService.provideSlotRacesConfig().enableCoinShower;
        if (enableCoinShower) {
            this.rxEffects.register(this.slotRaces.getCoinDetails(), (response) => {
                this.coinDetails = response?.coinData;
                if (this.coinDetails && this.coinDetails?.length > 0) {
                    const filteredCoins: any = this.coinDetails.filter(
                        (cs: any) => cs.metadata.id.path === this.currentCategoryInfo?.categoryInfo?.sitecoreData?.coinShower[0].path,
                    );
                    if (filteredCoins.length > 0) {
                        this.enableCoinShower = true;
                        if (postLogin) this.slotRaces.coinDetailsPublisher.next(true);
                    }
                } else {
                    if (postLogin || this.user.isAuthenticated) this.cookieService.setCookie('COIN_SHOWER', 'true', 30);
                }
            });
        }
    }
    ngOnDestroy() {
        this.lobbyBackgroundImg = '';
    }
}
