import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csAdvanceGameDataModel', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: advanceGameDataClientConfigFactory,
})
export class AdvanceGameDataClientConfig {
    enableAdvanceGameData: boolean;
    advanceUrlPattern: string;
    enableFeatureId: boolean;
}

export function advanceGameDataClientConfigFactory(service: LazyClientConfigService) {
    return service.get(AdvanceGameDataClientConfig);
}
