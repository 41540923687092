import { Pipe, PipeTransform } from '@angular/core';

import { IntlService } from '@frontend/vanilla/core';

import { ConfigProviderService } from '../../config-provider/config-provider.service';

@Pipe({
    name: 'cultureDate',
    standalone: true,
})
export class CultureDatePipe implements PipeTransform {
    constructor(
        private configProviderService: ConfigProviderService,
        private intlService: IntlService,
    ) {}

    transform(date: any): any {
        const featureConfig = this.configProviderService.provideFeaturesConfig();
        if (date && featureConfig.enableLocalDateTimeFormat) {
            const cultureDate = this.intlService.formatDate(date, 'mediumDate');
            const shortTime = this.intlService.formatDate(date, 'shortTime');
            date = cultureDate + ' ' + shortTime;
        }
        return date;
    }
}
