import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';

import { CasinoManager, ConfigProviderService, CustomCarouselPaginationComponent, OptimizedTeaserManager } from '@casinocore/platform/core';
import { DslPipe } from '@frontend/vanilla/shared/browser';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { OptMiniTeaserItemNpmComponent } from './opt-mini-teaser-item-npm.component';

@Component({
    selector: 'cc-opt-mini-teasers-npm',
    templateUrl: 'opt-mini-teasers-npm.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, MatGridListModule, OptMiniTeaserItemNpmComponent, NgIf, CustomCarouselPaginationComponent, AsyncPipe, DslPipe, SwiperComponent],
})
export class OptMiniTeasersNpmComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() teaserCategory: string;
    @Input() teasersSource: any;
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    //added for Teaser NPM
    @Input() globalTeaserSettings: any;
    @Input() isNPM: boolean;
    @Input() teaserDynaconConfigs: any;
    @Input() isTouch: boolean;
    @Input() isNewSportsNative: any;
    @Input() showJackpotCurrencyOnRight: any;
    @Input() jackpotGroupNameMapTeaserNPM: any;
    @Input() globalTeaserStyles: any;
    @ViewChild('miniCarousel') swiperRef: SwiperComponent;
    //added for Teaser NPM
    miniTeasersData: any;
    hidePagination: boolean;
    carouselConfig: any;
    currentIndex: number = 0;
    carouselTileItems: Array<Array<any>>;
    iconTypeMapping = new Map<number, any>();
    miniTeaserRowHeight: number;
    autoPlay: any;
    enablePagenation: string;

    swiperOptionsOptMiniTeasersNpm: SwiperOptions = {
        modules: [Navigation, Pagination, Autoplay],
        loop: false,
        autoHeight: true,
        allowTouchMove: true,
        spaceBetween: 1,
        direction: 'horizontal',
        slidesPerView: 'auto',
        centeredSlides: false,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
            waitForTransition: false,
        },
        pagination: false,
        navigation: false,
        rewind: true,
        keyboard: true,
        watchOverflow: true,
        mousewheel: false,
        scrollbar: false,
        preventClicks: true,
        preventClicksPropagation: true,
    };

    constructor(
        private configProviderService: ConfigProviderService,
        private optimizedTeaserManager: OptimizedTeaserManager,
        private casinoManager: CasinoManager,
    ) {}

    ngOnInit() {
        const config: any = this.isNPM ? this.teaserDynaconConfigs : this.configProviderService.provideOptimizedTeaserConfig();
        this.miniTeaserRowHeight = config?.desktopViewTeaserHeight / 2;
    }

    ngAfterViewInit() {
        if (this.swiperRef) {
            this.swiperRef.swiper.on('slideChange', (event: any) => {
                this.sliderChanged(event);
            });
        }
    }

    private initCarouselConfig() {
        this.carouselConfig = {
            arrows: false,
            dots: false,
            loop: false,
        };
        this.autoPlay = {
            delay: 6000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        };
    }

    private setIconType() {
        const config: any = this.isNPM ? this.teaserDynaconConfigs : this.configProviderService.provideGameConfig();
        config?.iconTypeMapping?.forEach((_item: any, key: number) => {
            switch (key) {
                case 2:
                    this.iconTypeMapping.set(key, { rowSpan: 1, colSpan: 2 });
                    break;
                case 4:
                    this.iconTypeMapping.set(key, { rowSpan: 2, colSpan: 2 });
                    break;
                default:
                    this.iconTypeMapping.set(1, { rowSpan: 1, colSpan: 1 });
                    break;
            }
        });
    }

    private prepareCarouselItems() {
        let size = 0;
        let i: number = 1;
        const maxGridSize: number = 4;
        let matGridItems: any = [];
        this.carouselTileItems = [];
        this.miniTeasersData.forEach((m: any) => {
            m.index = i++;
            if (!m.hasOwnProperty('iconTileCode') || [...this.iconTypeMapping.keys()].indexOf(m.iconTileCode) < 0) {
                m.iconTileCode = 1;
            }
            const area = this.iconTypeMapping.get(m.iconTileCode);
            size += area.colSpan * area.rowSpan;
            const item = Object.assign(m, { colSpan: area.colSpan, rowSpan: area.rowSpan });
            if (size > maxGridSize) {
                this.carouselTileItems.push(matGridItems);
                size = item.colSpan * item.rowSpan;
                matGridItems = [];
                matGridItems.push(item);
            } else if (size === maxGridSize) {
                matGridItems.push(item);
                this.carouselTileItems.push(matGridItems);
                size = 0;
                matGridItems = [];
            } else {
                matGridItems.push(item);
            }
        });
        if (matGridItems.length) {
            this.carouselTileItems.push(matGridItems);
            size = 0;
            matGridItems = [];
        }
    }

    sliderChanged(swiperObj: any) {
        if (swiperObj) {
            this.currentIndex = swiperObj.realIndex;
        }
    }

    ngOnChanges() {
        this.isTouch = this.casinoManager.isTouch();
        if (this.isTouch) {
            this.swiperOptionsOptMiniTeasersNpm.navigation = false;
        } else {
            this.swiperOptionsOptMiniTeasersNpm.navigation = {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            };
        }
        this.initCarouselConfig();
        this.miniTeasersData = this.teasersSource;
        if (!this.isNPM) {
            this.globalTeaserSettings = this.optimizedTeaserManager.getGlobalTeaserSettings();
        }
        this.enablePagenation = this.globalTeaserSettings?.sharedList?.enablePagination;
        if (this.miniTeasersData && this.miniTeasersData.length) {
            this.miniTeasersData.length > 1 && this.enablePagenation == 'true' ? (this.hidePagination = false) : (this.hidePagination = true);
            this.carouselConfig.arrows = true;
            this.carouselConfig.dots = true;
            this.carouselConfig.loop = this.miniTeasersData.length > 1 ? true : false;
            this.swiperOptionsOptMiniTeasersNpm.loop = this.miniTeasersData.length > 1 ? true : false;
            this.setIconType();
            this.prepareCarouselItems();
        } else {
            this.hidePagination = true;
            this.carouselConfig.arrows = false;
            this.carouselConfig.dots = false;
            this.carouselConfig.loop = false;
            this.swiperOptionsOptMiniTeasersNpm.loop = false;
        }
    }
}
