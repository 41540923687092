<div id="casino-embed-game-view" class="casino-embed-game-view">
    <vn-message-panel [scope]="'embeddedGameError'"></vn-message-panel>
    <span class="game-launch-fail-close" *ngIf="!gameLaunchSuccess && !iconsNewPosition">
        <a (click)="close(true)">
            <span class="theme-close-i"></span>
        </a>
    </span>
    <div class="embed-left-section" *ngIf="gameLaunchSuccess">
        <div class="embed-left-section-width">
            <div class="embed-settings" *ngIf="!iconsNewPosition">
                <div class="client-time-full-screen" *ngIf="isFullScreen">
                    {{ date | date: 'shortTime' }}
                </div>
                <ul class="embed-list">
                    <li>
                        <button class="header-btn btn btn-primary ml-2" mat-raised-button (click)="openDialog()">{{ depositButtonString }}</button>
                    </li>
                    <li *ngIf="isFullScreen && useFavouriteGames">
                        <a (click)="addFavourate(Game, $event)">
                            <span *ngIf="Game" [ngClass]="Game.isFavourite ? favouriteStyle : unFavouriteStyle"></span>
                        </a>
                    </li>
                    <li *ngIf="!isFullScreen" [ngStyle]="summaryPopUpIsOpened && { 'color': 'grey', 'pointer-events': 'none' }">
                        <a (click)="openRequestedPopup()" src="" href="javascript:void(0)">
                            <span class="theme-pop-out" [title]="titlePopout"></span>
                        </a>
                    </li>
                    <li [ngStyle]="summaryPopUpIsOpened && { 'color': 'grey', 'pointer-events': 'none' }">
                        <a (click)="toggleFullScreen($event)">
                            <span
                                [ngClass]="isFullScreen ? 'theme-fullscreen-exit' : 'theme-fullscreen'"
                                [title]="isFullScreen ? titleExitFullScreen : titleFullScreen"></span>
                        </a>
                    </li>
                    <li *ngIf="!isFullScreen" [ngStyle]="summaryPopUpIsOpened && { 'color': 'grey', 'pointer-events': 'none' }">
                        <a (click)="close(true)">
                            <span class="theme-close-i"></span>
                        </a>
                    </li>
                    <li *ngIf="extrasColumnFlag && !isFullScreen" style="position: relative; right: -50px">
                        <a class="theme-left" (click)="toggleExtrasColumn()"></a>
                    </li>
                </ul>
            </div>
            <div class="embed-iframe">
                <ng-container *ngIf="iframeUrl">
                    <div class="gameOverlay">
                        <iframe
                            id="embedGameIframe"
                            class="iframeClass"
                            [ngClass]="{ 'iframe-width': isIframeVisible == true, 'iframeClassNew': iconsNewPosition }"
                            allowfullscreen="true"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            [src]="iframeUrl | trustAsResourceUrl"></iframe>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="embed-right-section" *ngIf="!extrasColumnFlag && !isFullScreen" [ngClass]="{ 'egv-disable-overlay': egvOverlayFlag }">
        <div class="casino-embed-search">
            <div *ngIf="!isFullScreen && !iconsNewPosition" class="search-right-icon" [ngClass]="iconsNewPosition ? 'search-right-icon-new' : ''">
                <a [ngClass]="extrasColumnFlag ? 'theme-left' : 'theme-right'" (click)="toggleExtrasColumn()"></a>
            </div>
            <div class="embed-settings" *ngIf="iconsNewPosition">
                <div class="client-time-full-screen" *ngIf="isFullScreen">
                    {{ date | date: 'shortTime' }}
                </div>
                <ul>
                    <li *ngIf="isFullScreen && useFavouriteGames">
                        <a (click)="addFavourate(Game, $event)">
                            <span *ngIf="Game" [ngClass]="Game.isFavourite ? favouriteStyle : unFavouriteStyle"></span>
                        </a>
                    </li>
                    <li *ngIf="!isFullScreen" [ngStyle]="summaryPopUpIsOpened && { 'color': 'grey', 'pointer-events': 'none' }">
                        <a (click)="openRequestedPopup()" src="" href="javascript:void(0)">
                            <span class="theme-pop-out" [title]="titlePopout"></span>
                        </a>
                    </li>
                    <li [ngStyle]="summaryPopUpIsOpened && { 'color': 'grey', 'pointer-events': 'none' }">
                        <a (click)="toggleFullScreen($event)">
                            <span
                                [ngClass]="isFullScreen ? 'theme-fullscreen-exit' : 'theme-fullscreen'"
                                [title]="isFullScreen ? titleExitFullScreen : titleFullScreen"></span>
                        </a>
                    </li>
                    <li *ngIf="!isFullScreen" [ngStyle]="summaryPopUpIsOpened && { 'color': 'grey', 'pointer-events': 'none' }">
                        <a (click)="close(true)">
                            <span class="theme-close-i"></span>
                        </a>
                    </li>
                    <li *ngIf="extrasColumnFlag && !isFullScreen" style="position: relative; right: -50px">
                        <a class="theme-left" (click)="toggleExtrasColumn()"></a>
                    </li>
                </ul>
            </div>
            <cc-lazy-search-desktop
                [ngClass]="iconsNewPosition ? 'search-field' : ''"
                [placeholder]="'embedgame'"
                (embedGameClicked)="gameClickedOnSearch($event, 0, 'Search')"
                [lobbyType]="lobbyType" />

            <div class="games-div" [ngClass]="iconsNewPosition ? 'games-div-new' : ''">
                <div class="embed-game-title">
                    <div class="game-title" *ngIf="Game" [innerHTML]="Game.title"></div>
                </div>
                <div class="add-fav-btn" *ngIf="useFavouriteGames">
                    <a class="favourite btn-s3" [ngClass]="{ 'epcot-btn': enableEpcotSubNavigation }" (click)="addFavourate(Game, $event)">
                        <span *ngIf="Game" [ngClass]="Game.isFavourite ? favouriteStyle : unFavouriteStyle"></span>
                        <span *ngIf="!Game.isFavourite" class="favaddbutton">{{ this.Global.messages.AddToFavorites }}</span>
                        <span *ngIf="Game.isFavourite" class="favaddbutton">{{ this.Global.messages.AddedToFavorites }}</span>
                    </a>
                </div>
                <div
                    id="categoryIdentifier_EmbedRecentlyPlayed"
                    class="embed-games-list sidepanel-recentlyplayed-games"
                    *ngIf="RecentlyPlayedGamesList && RecentlyPlayedGamesList.length > 0">
                    <div class="emb-header">{{ Global.messages.RecentlyPlayedGames }}</div>
                    <div class="games-container">
                        <div
                            *ngFor="let game of RecentlyPlayedGamesList; let i = index"
                            class="game embed-game"
                            [ngClass]="{ 'live-dealer-closed': !isLiveDealerOpen }">
                            <cc-game-embed
                                id="gameIdentifier_EmbedRecentlyPlayed_{{ game.gameId }}"
                                [game]="game"
                                [radius]="borderRadius"
                                [categorytype]=""
                                (embedGameClicked)="gameClickedOnSearch($event, i, recentGames)"
                                [lobbyType]="lobbyType"></cc-game-embed>
                        </div>
                    </div>
                </div>
                <div
                    id="categoryIdentifier_EmbedGamesyouMightLike"
                    class="embed-games-list sidepanel-suggested-games"
                    *ngIf="recommendationGamesList && recommendationGamesList.length > 0">
                    <div class="emb-header">{{ GamesyouMightLikeMessage }}</div>
                    <div class="games-container">
                        <div
                            *ngFor="let game of recommendationGamesList | slice: 0 : 6; let i = index"
                            class="game embed-game"
                            [ngClass]="{ 'live-dealer-closed': !isLiveDealerOpen }">
                            <cc-game-embed
                                id="gameIdentifier_EmbedGamesyouMightLike_{{ game.gameId }}"
                                [game]="game"
                                [radius]="borderRadius"
                                [categorytype]=""
                                (embedGameClicked)="gameClickedOnSearch($event, i, EGV_GYML)"
                                [lobbyType]="lobbyType"></cc-game-embed>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
