import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'cc-prominent-freespins-widget-timmer',
    templateUrl: 'prominent-freespins-timer.component.html',
    standalone: true,
})
export class ProminentFreespinsTimmerComponent implements OnInit {
    @Input() timmer: any;
    @Output() isTimmerZero = new EventEmitter<object>();
    clearInterval: any;
    currentTime: string;
    constructor() {}
    ngOnInit() {
        //console.log('data', this.data);
        this.setInterval();
    }
    getInterval() {
        let out = '';
        const myDate = new Date(this.timmer * 1000);
        const dateNow = new Date();
        //grab current date
        let amount = myDate.getTime() - dateNow.getTime();
        let days = 0;
        let hours = 0;
        let mins = 0;
        let secs = 0;
        amount = Math.floor(amount / 1000); //kill the "milliseconds" so just secs

        days = Math.floor(amount / 86400); //days
        amount = amount % 86400;

        hours = Math.floor(amount / 3600); //hours
        amount = amount % 3600;

        mins = Math.floor(amount / 60); //minutes
        amount = amount % 60;

        secs = Math.floor(amount); //seconds

        if (days > 0) {
            this.currentTime = out += days + ' day' + (days != 1 ? 's' : '');
        }

        if (days == 0 && hours > 0) {
            this.currentTime = out += hours + ' hour' + (hours != 1 ? 's' : '') + ' ' + mins + ' minute' + (mins != 1 ? 's' : '');
        }

        if (days == 0 && hours == 0 && mins > 0) {
            this.currentTime = out += mins + ' minute' + (mins > 0 ? 's' : '');
        }

        if (days == 0 && hours == 0 && mins == 0 && secs > 0) {
            this.currentTime = out += secs + ' seconds';
        }

        if (days == 0 && hours == 0 && mins == 0 && secs == 0) {
            this.isTimmerZero.emit({ IsTimmerZero: true });
        }
    }

    setInterval() {
        if (this.clearInterval) {
            clearInterval(this.clearInterval);
        }
        this.clearInterval = setInterval(() => this.getInterval(), 1000);
    }
}
