import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

/**
 * @stable
 */
@LazyClientConfig({ key: 'csLiveCasinoLoungeConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: liveCasinoLoungeClientConfigFactory,
})
export class LiveCasinoLoungeClientConfig {
    enabled: boolean;
    lobbyType: string;
    defaultCategoryId: string;
    loadingConfig: any;
    onboardingDataPath: string;
    mobileLandscapeConfig: any;
    mobilePortraitMaxWidth: number;
    defaultRenderer: string;
    fallbackRenderer: string;
    fallbackForIpad: boolean;
    idleTimeout: number;
    setMapAdjustedTimeout: number;
    gameLaunchUri: string;
    statCategoriesDataPath: string;
    statItemsDataPath: string;
    statsDataPath: string;
    pauseLottieAnimations: boolean;
    enableAudioButton: boolean;
    enableStats: boolean;
    enableFlagIconForVendor: any;
}

export function liveCasinoLoungeClientConfigFactory(service: LazyClientConfigService) {
    return service.get(LiveCasinoLoungeClientConfig);
}
