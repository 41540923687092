import { Injectable } from '@angular/core';

import { DevSettingsConfig } from '../../client-config';

@Injectable({ providedIn: 'root' })
export class PerformanceMarker {
    constructor(private devSettingsConfig: DevSettingsConfig) {}

    mark(markerName: string): void {
        if (this.devSettingsConfig.enablePerformanceMarking) {
            window.performance &&
                window.performance.getEntriesByName &&
                window.performance.getEntriesByName(markerName).length <= 0 &&
                window.performance.mark(markerName);
        }
    }

    clearMarks(): void {
        window.performance && window.performance.clearMarks && window.performance.clearMarks();
    }
}

export enum PerformanceMarkerIdentifiers {
    LobbyLoadComplete = 'Lobby_Load_Complete',
    WidgetsViewInitialized = 'Widgets_View_Initialized',
    FirstMeaningfulPaint = 'vn_fmp',
    TimeToInteractivity = 'vn_tti',
}
