import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class GlobalSearchHelperService {
    presentationBootstraped: EventEmitter<any> = new EventEmitter<any>();
    contextAvailable: EventEmitter<any> = new EventEmitter<any>();

    launchGlobalSearch() {
        dispatchEvent(new CustomEvent('gs:presentation:launchsearch', { detail: { launchChannel: 'desktop', launchedFrom: 'sub-nav' } }));
    }

    closeGlobaSearch() {
        dispatchEvent(new CustomEvent('gs:presentation:closesearch', { detail: { closedFrom: 'close', launchedFrom: 'sub-nav' } }));
    }

    registerEventHandlers() {
        addEventListener('gs:search:service:context:result', this.contextResultEventHandler);
        addEventListener('gs:presentation:bootstrapped', this.presentationBootstrappedEventHandler);
    }

    deregisterEvents() {
        removeEventListener('gs:search:service:context:result', this.contextResultEventHandler);
        removeEventListener('gs:presentation:bootstrapped', this.presentationBootstrappedEventHandler);
    }

    fetchContext() {
        dispatchEvent(new CustomEvent('gs:search:service:context:fetch'));
    }

    private readonly contextResultEventHandler = (messageEvent: CustomEventInit) => {
        this.contextAvailable.emit(messageEvent.detail);
        dispatchEvent(new CustomEvent('gs:productstub:entrypoint:active', { detail: { activeFrom: 'sub-nav' } }));
    };

    private readonly presentationBootstrappedEventHandler = (messageEvent: CustomEventInit) => {
        this.presentationBootstraped.emit(messageEvent.detail);
        dispatchEvent(new CustomEvent('gs:productstub:entrypoint:active', { detail: { activeFrom: 'sub-nav' } }));
    };
}
