<div class="moreinf0-related-games">{{ relatedGamesText }}</div>
<div id="sticky-container" class="sticky-container casino-lobby-width-gametile">
    <div
        [ngClass]="setSubCategoryStyle()"
        style.overflow-x="{{ isTouch == false ? 'hidden' : 'auto' }}"
        [class.non-touch-scrollable-games]="!isTouch && setSubCategoryStyle() == 'scrollable-games'"
        (scroll)="scrollGYML()">
        <div *ngIf="!isTouch && gymlGames">
            <div class="base-category" [class.casino-base-category-nontouch]="!isTouch">
                <div class="casino-fav-gyml-carousel-wrapper">
                    <vn-swiper #moreInfoGYMLCarousel [swiperOptions]="swiperOptions">
                        <div class="swiper-slide" *ngFor="let game of gymlGames; let i = index">
                            <div class="game item">
                                <div class="game">
                                    <a *ngIf="game.icon" (click)="action(game, 'p', i)" class="game-image-link">
                                        <img src="{{ game.icon.src }}" (error)="onGameIconError(game)" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </vn-swiper>
                    <button [hidden]="hidePreButton" class="theme-left ngx-button-left" (click)="goToPrevSlide()" aria-hidden="true"></button>
                    <button [hidden]="hideNextButton" class="theme-right ngx-button-right" (click)="goToNextSlide()" aria-hidden="true"></button>
                </div>
            </div>
        </div>
        <div *ngIf="isTouch && gymlGames">
            <div class="base-category" [class.casino-base-category-nontouch]="!isTouch">
                <div class="casino-allGames">
                    <div *ngFor="let game of gymlGames; let i = index" class="casino-rcntly-gyml-game-touch">
                        <div class="game">
                            <a *ngIf="game.icon" (click)="action(game, 'p', i)" class="game-image-link">
                                <img src="{{ game.icon.src }}" (error)="onGameIconError(game)" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
