import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

import { IGamingStoriesNpmModel } from '../shared/models/gaming-stories.model';
import { CasinoCoreClientConfigModule } from './casinoCore-client-config.module';

@LazyClientConfig({ key: 'csGamingStoriesPhase2Config', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: CasinoCoreClientConfigModule,
    deps: [LazyClientConfigService],
    useFactory: gamingStoriesPhase2ClientConfigFactory,
})
export class GamingStoriesPhase2ClientConfig {
    enabled: boolean;
    storyItemInterval: number;
    gamingStoriesNpmPath: Array<IGamingStoriesNpmModel>;
}

export function gamingStoriesPhase2ClientConfigFactory(service: LazyClientConfigService) {
    return service.get(GamingStoriesPhase2ClientConfig);
}
