import { Injectable } from '@angular/core';

import { DslService, TrackingService } from '@frontend/vanilla/core';
import { ReplaySubject, filter, first, map, merge, mergeMap, of, shareReplay } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { CasinoApiService } from './casino-api.service';
import { CasinoManager } from './casino-manager.service';

@Injectable({
    providedIn: 'root',
})
export class NewsFeedService {
    newsFeedData: any;
    isEnabled: boolean;

    private _newsFeed = new ReplaySubject<any>(1);
    readonly newsFeed$ = this._newsFeed.asObservable().pipe(
        shareReplay(),
        map(this.newsFeedMapper),
        mergeMap((data) => this.validateDisplayCondition(data)),
    );

    constructor(
        private api: CasinoApiService,
        private tracking: TrackingService,
        private configProviderService: ConfigProviderService,
        private casinoManager: CasinoManager,
        private dslService: DslService,
    ) {}

    setNewsFeed() {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        this.api.get(apiPathsConfig.disjointFeeds['nsFeedSitecoreData']).subscribe(
            (response: any) => {
                if (response) {
                    this.setNewsFeedResponse(response);
                }
            },
            () => {},
        );
    }
    setNewsFeedResponse(data: any): void {
        this.newsFeedData = data;
        this._newsFeed.next(data);
    }

    trackEvent(labelEvent: string, actionEvent: string, positionEvent: any, eventDetails: string, displayGameNameEvent?: any) {
        const newsFeedConfig = this.configProviderService.provideNewsFeedCasinoConfig();
        if (labelEvent != 'Game_Launch') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'NewsFeed',
                'component.LabelEvent': labelEvent,
                'component.ActionEvent': actionEvent,
                'component.PositionEvent': positionEvent,
                'component.LocationEvent': this.casinoManager.IsFeatureEnabledforLobbytype('lobbyType', newsFeedConfig.newsFeedPosition),
                'component.EventDetails': eventDetails,
                'component.URLClicked': 'not applicable',
            });
        } else {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'NewsFeed',
                'component.LabelEvent': labelEvent,
                'component.ActionEvent': actionEvent,
                'component.PositionEvent': positionEvent,
                'component.LocationEvent': this.casinoManager.IsFeatureEnabledforLobbytype('lobbyType', newsFeedConfig.newsFeedPosition),
                'component.EventDetails': eventDetails,
                'component.URLClicked': 'not applicable',
                'component.DisplayGameName': displayGameNameEvent,
            });
        }
    }

    private newsFeedMapper(newsFeed: any) {
        const newsFeedData = newsFeed.newsFeedData?.map((element: any) => ({
            header: element['header'] ? element['header'] : '',
            subHeader: element['sub Header'] ? element['sub Header'] : '',
            type: element['type'],
            iconClass: element['icon Class'],
            gameID: element['game ID'] ? element['game ID'] : '',
            jackpotGroupName: element['jackpot Group Name'] ? element['jackpot Group Name'] : '',
            link: element.link ? element.link.url : '#',
            value: element['value'] ? element['value'] : '',
            condition: element['condition'] ? element['condition'] : '',
        }));
        return { ...newsFeed, newsFeedData };
    }

    private validateDisplayCondition(newsFeed: any) {
        return merge(
            ...newsFeed.newsFeedData.map((data: { condition: string }) => {
                return data.condition === '' ? of(true) : this.dslService.evaluateExpression<boolean>(data.condition);
            }),
        ).pipe(
            filter(Boolean),
            first(),
            map(() => newsFeed),
        );
    }
}
