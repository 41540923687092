import { Injectable } from '@angular/core';

//need to check
import { DeviceService, NativeAppService } from '@frontend/vanilla/core';

import { CasinoLobbyService } from '../casino-lobby-manager/casino-lobby.service';
import { ConfigProviderService } from '../config-provider/config-provider.service';
import { ConstService } from './const.service';
import { AdvanceGameDataModel } from './models/advance-game-data.model';

@Injectable({
    providedIn: 'root',
})
export class AdvanceGameDataService {
    advanceGameDataModel = new AdvanceGameDataModel();
    featureid: any;
    constructor(
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
        private deviceService: DeviceService,
        private nativeAppService: NativeAppService,
    ) {}

    getAdvanceCategoryInfo(gameLaunchParam: any) {
        let parentcategoryId: string;
        let subcategoryId: string;
        const categoryType: string = gameLaunchParam.categoryType;
        const catInfoObject = this.casinoLobbyService.getCategoryInfo(gameLaunchParam.game.lobbyType, categoryType);

        let rowNumber: any;
        let catInfo: any;
        if (catInfoObject) {
            catInfo = catInfoObject.categoryInfo;
            this.featureid = catInfo?.filterFeatureIds ? catInfo?.filterFeatureIds[0] : null;
        }
        if (!catInfo) {
            parentcategoryId = categoryType;
            subcategoryId = '';
        } else if (!catInfo.parentLMTCategoryId) {
            if (!catInfo.lmtCategoryId) {
                parentcategoryId = categoryType;
                subcategoryId = '';
            } else {
                parentcategoryId = catInfo.lmtCategoryId;
                subcategoryId = '';
            }
        } else {
            parentcategoryId = catInfo.parentLMTCategoryId;
            subcategoryId = catInfo.lmtCategoryId;
        }
        if (categoryType == ConstService.SEARCH || categoryType == ConstService.SEARCH_GYML) {
            rowNumber = '';
        }
        const advanceCatInfoObj = {
            parentCategoryId: parentcategoryId,
            subCategoryId: subcategoryId,
            rowNumber: rowNumber,
        };
        return advanceCatInfoObj;
    }
    createAdvanceGameModel(
        parentCategory: string,
        subCategory: string,
        columnNumber: number,
        iconType: any,
        iconTypeForTracking: string,
        gridPosition?: any,
    ) {
        const advanceGameDataConfig = this.configProviderService.provideAdvanceGameDataConfig();
        const featureId = this.featureid;
        if (advanceGameDataConfig.enableAdvanceGameData) {
            const advanceGameDataModel: AdvanceGameDataModel = new AdvanceGameDataModel();
            advanceGameDataModel.parentcategoryId = parentCategory;
            advanceGameDataModel.subcategoryId = subCategory;
            advanceGameDataModel.rowNumber = gridPosition;
            advanceGameDataModel.columnNumber = columnNumber;
            advanceGameDataModel.iconSize = iconType;
            advanceGameDataModel.iconTypeForTracking = iconTypeForTracking;
            advanceGameDataModel.featureId = featureId;
            this.setRemainingAdvanceGameDataParameters(advanceGameDataModel);
        }
    }

    gameClickedFromSearchAdvanceGameDataModel(game: any, section: string, gridPos: any, index: any) {
        const advanceGameDataModel: AdvanceGameDataModel = new AdvanceGameDataModel();
        advanceGameDataModel.parentcategoryId = 'egv_' + section;
        advanceGameDataModel.subcategoryId = '';
        advanceGameDataModel.rowNumber = '';
        if (section != ConstService.SEARCH) {
            advanceGameDataModel.rowNumber = gridPos ? gridPos : '';
        }
        advanceGameDataModel.columnNumber = index + 1;
        advanceGameDataModel.iconSize = game.iconType;
        advanceGameDataModel.iconTypeForTracking = game.iconTypeForTracking;
        this.setRemainingAdvanceGameDataParameters(advanceGameDataModel);
    }

    setRemainingAdvanceGameDataParameters(advanceGameDataModel: AdvanceGameDataModel) {
        advanceGameDataModel.userAgent = window.navigator.userAgent;
        advanceGameDataModel.browserName = this.deviceService.getCapability('browserName') || '';
        advanceGameDataModel.browserVersion = this.deviceService.getCapability('browserVersion');
        advanceGameDataModel.osName = this.deviceService.osName;
        advanceGameDataModel.osVersion = this.deviceService.osVersion;
        advanceGameDataModel.devicemake = this.deviceService.vendor;
        this.setAdvanceGameModel(advanceGameDataModel);
    }
    setAdvanceGameModel(advanceGameDataModel: AdvanceGameDataModel) {
        this.advanceGameDataModel = advanceGameDataModel;
    }

    getAdvanceGameModel() {
        const advanceGameDataModelObj: AdvanceGameDataModel | null = {
            parentcategoryId: this.advanceGameDataModel.parentcategoryId,
            subcategoryId: this.advanceGameDataModel.subcategoryId,
            rowNumber: this.advanceGameDataModel.rowNumber,
            columnNumber: this.advanceGameDataModel.columnNumber,
            iconSize: this.advanceGameDataModel.iconSize,
            iconTypeForTracking: this.advanceGameDataModel.iconTypeForTracking,
            browserName: this.advanceGameDataModel.browserName,
            browserVersion: this.advanceGameDataModel.browserVersion,
            osName: this.advanceGameDataModel.osName,
            osVersion: this.advanceGameDataModel.osVersion,
            userAgent: this.advanceGameDataModel.userAgent,
            devicemake: this.advanceGameDataModel.devicemake,
            featureId: this.advanceGameDataModel.featureId,
        };
        if (Object.keys(advanceGameDataModelObj).length > 0) {
            return advanceGameDataModelObj;
        }
        return null;
    }

    appendAdvanceGameDataUrl(url: string, channelId: string): string {
        const advanceGameDataConfig = this.configProviderService.provideAdvanceGameDataConfig();
        const advanceGameDataModelObj = this.getAdvanceGameModel();
        const device = this.deviceService.isMobilePhone ? 'mobile' : this.deviceService.isTablet ? 'tablet' : 'desktop';
        const clientVersion: string = '';
        const model = this.deviceService.model != null ? this.deviceService.model : '';
        let appType;
        switch (channelId) {
            case 'VC':
                appType = 'VC_Client';
                break;
            case 'MC':
                appType = 'MAC_Client';
                break;
            case 'AW':
                appType = 'Android_Web';
                break;
            case 'IW':
                appType = 'IOS_Web';
                break;
            case 'AN':
            case 'IN':
                appType = this.nativeAppService.isNativeApp ? 'NativeApp' : this.nativeAppService.isNativeWrapper ? 'NativeWrapperApp' : '';
                break;
            case 'FC':
                appType = 'Desktop';
                break;
            default:
                appType = 'Web';
        }
        const advanceGameDataParamArray: Array<string> = [
            advanceGameDataModelObj?.parentcategoryId,
            advanceGameDataModelObj?.subcategoryId,
            advanceGameDataModelObj?.rowNumber,
            advanceGameDataModelObj?.columnNumber,
            advanceGameDataModelObj?.iconTypeForTracking,
            advanceGameDataModelObj?.browserName,
            advanceGameDataModelObj?.browserVersion,
            advanceGameDataModelObj?.osName,
            device,
            clientVersion,
            advanceGameDataModelObj?.userAgent,
            advanceGameDataModelObj?.osVersion,
            advanceGameDataModelObj?.devicemake,
            model,
            appType,
            advanceGameDataModelObj?.featureId,
        ];

        const advanceGameDataUrl = this.format(advanceGameDataConfig.advanceUrlPattern, advanceGameDataParamArray);
        const finalUrl = this.appendAdvanceGameDataQueryString(url, advanceGameDataUrl);
        if (
            (advanceGameDataModelObj?.featureId == null || advanceGameDataModelObj?.featureId == '') &&
            finalUrl &&
            finalUrl.indexOf('&featureId=') > -1
        ) {
            const replacedUrl = finalUrl.replace('&featureId=', '');
            return replacedUrl;
        } else {
            return finalUrl;
        }
    }
    format(content: string, array: Array<string>) {
        let theString = content;
        for (let i = 0; i < content.length; i++) {
            const regEx = new RegExp('\\{' + i + '\\}', 'gm');
            if (array[i] != undefined) {
                theString = theString.replace(regEx, encodeURIComponent(array[i]));
            } else {
                theString = theString.replace(regEx, '');
            }
        }
        return theString;
    }

    setGameLaunchProductsIndex() {
        let index = 1;
        const windowObj = window as any;
        if (windowObj.clientConfig.vnHeader !== undefined) {
            windowObj.clientConfig.vnHeader.products.forEach((headerPrduct: any) => {
                headerPrduct.ProductIndex = index++;
            });
        }
        if ((this.deviceService.isMobile || this.deviceService.isTouch) && windowObj.clientConfig.vnBottomNav !== undefined) {
            windowObj.clientConfig.vnBottomNav.items.forEach((bottmNavItems: any) => {
                bottmNavItems.ProductIndex = index++;
            });
        }
    }

    appendAdvanceGameDataQueryString(url: string, value: string) {
        if (url.indexOf('?') > -1) {
            url += '&' + value;
        } else {
            url += '?' + value;
        }
        return url;
    }
}
