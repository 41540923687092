import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csGameShowHubConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: gameShowHubClientConfigFactory,
})
export class GameShowHubClientConfig {
    lobbyType: string;
    loadingConfig: any;
    triviaGameType: string;
    onboardingDataPath: string;
    noOfHours: number;
    gameLaunchUri: string;
    tiltingPhoneMessageImageUrl: string;
    scheduleGames: Array<string>;
    tiltingMessage: string;
    idleTimeout: number;
    enableSupportForLandscape: boolean;
    mobileLandscapeConfig: any;
    mobilePortraitMaxWidth: number;
    defaultRenderer: string;
    fallbackRenderer: string;
    fallbackForIpad: boolean;
    setMapAdjustedTimeout: number;
    enableAudioButton: boolean;
    jackpotGroupName: string;
    pauseLottieAnimations: boolean;
    chatWindowEvents: object;
    enableGSPreloginPopup: boolean;
}

export function gameShowHubClientConfigFactory(service: LazyClientConfigService) {
    return service.get(GameShowHubClientConfig);
}
