import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectorRef, Component, DoCheck, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import {
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    DesignSystemEnablerService,
    FastLoadingGamesService,
    GameDataManager,
    JackpotDataService,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, GameTileCtaComponent } from '@frontend/casino-ui';
import { DeviceService, NavigationService } from '@frontend/vanilla/core';
import {
    NguCarousel,
    NguCarouselConfig,
    NguCarouselDefDirective,
    NguCarouselNextDirective,
    NguCarouselPointDirective,
    NguCarouselPrevDirective,
    NguCarouselStore,
    NguItemComponent,
} from '@ngu/carousel';

import { CasinoCoreLazyPlaceholderComponent } from '../casino-core-lazy-placeholder/casino-core-lazy-placeholder.component';
import { FeatureGridComponent } from '../feature-grid/feature-grid.component';
import { NgxCarousel3dSlideComponent } from '../ngx-carousel-3d/ngx-carousel-3d-slide/ngx-carousel-3d-slide.component';
import { NgxCarousel3dComponent } from '../ngx-carousel-3d/ngx-carousel-3d.component';

@Component({
    selector: 'cc-multi-jackpot-widget',
    templateUrl: 'multi-jackpot-widget.component.html',
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        NgStyle,
        NgFor,
        CasinoCoreLazyPlaceholderComponent,
        NgxCarousel3dComponent,
        NgxCarousel3dSlideComponent,
        FeatureGridComponent,
        NguCarouselNextDirective,
        NguCarouselPrevDirective,
        NguCarouselDefDirective,
        NguItemComponent,
        NguCarouselPointDirective,
        NguCarousel,
        GameTileCtaComponent,
        DecoratorClassDirective,
    ],
})
export class MultiJackpotwidgetComponent implements OnInit, DoCheck {
    @ViewChild('slide-3d') el: ElementRef;
    @ViewChild('featuredGrid') featuredGrid: FeatureGridComponent;
    @Input() category: any;
    @Input() isTouch: boolean;
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    @Input() changeCarouselView: boolean;
    @ViewChild('carousel') carousel: any;
    @ViewChild('myCarousel') myCarousel: NguCarousel<any>;
    @Output() carouselDataEvent: EventEmitter<null> = new EventEmitter<null>();
    @Output() isjackpotFeed = new EventEmitter<object>();
    showfeatureGrid: boolean = true;
    classNameTop: string = 'single-jackpot';
    jackpotType: string = 'multi';
    moreInfoMessage: string = 'More info';
    showJackpotWidget: boolean;
    jackpotsCache: any;
    MaxWidgets: number;
    jackpotWidgetData: any = [];
    widgets: any = [];
    widgetsData: any;
    games: any = [];
    items: any = [];
    cta: any;
    jackpotDesc: any;
    currentSlideIndex: number;
    ShowSeeAllJackpots: any;
    public carouselConfig: NguCarouselConfig;
    moveSlideTo: number;
    isPreButtonVisible: boolean;
    isNextButtonVisible: boolean;
    isCarousal: boolean;
    isJackpotWidgetGamesCarousel: boolean;
    indexCount: number;
    value: any = 'multi';
    options: Object = {};
    startSlide: number = 0;
    isIOS: boolean;
    mode: string;
    multiJackpotBgClr: string;
    multiJackpotLeftArrowBgClr: string;
    multiJackpotRightArrowBgClr: string;
    mJPaginationActiveClr: string;
    mJPaginationInactiveClr: string;
    multiJackpotmode: string;
    multiJackpotArrowOpacity: string;
    newFeatureGridArr: any[] = [];
    categoryObj: any;
    carouselData: any;

    themeArrowLeft: any;
    themeArrowRight: any;
    subCategorySubTitleFontSize: any;
    isUkHeading: any;
    subCategoryTextColor: any;
    lobbyTileFontFamily: any;
    enableEpcotSubNavigation: boolean;
    totalJpGroupList: any;
    enableJpGroupTickingForLobby: boolean;
    jackpotList: any = [];
    JackpotDisplayNameConfig: any;
    isLegacy: boolean = false;
    constructor(
        private sanitizer: DomSanitizer,
        private jackpotDataService: JackpotDataService,
        private navigation: NavigationService,
        private gameDataManager: GameDataManager,
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
        private fastLoadingGamesService: FastLoadingGamesService,
        private casinoManager: CasinoManager,
        private cdr: ChangeDetectorRef,
        private deviceService: DeviceService,
        private designSystemEnablerService: DesignSystemEnablerService,
    ) {
        this.currentSlideIndex = 0;
        this.carouselConfig = {
            grid: { xs: 1, sm: 6, md: 6, lg: 6, all: 0 },
            slide: 1,
            speed: 400,
            point: {
                visible: true,
            },
            loop: false,
            touch: true,
        };
        this.options3D();
        this.indexCount = 0;
        this.isCarousal = this.deviceService.windowWidth() >= 655;
    }
    ngOnInit() {
        const global = this.configProviderService.provideGlobalConfig();
        const subnavConfig: any = this.configProviderService.provideSubNavigationConfig();
        this.multiJackpotBgClr = global.lobbyConfigurations.multiJackpotBgClr;
        this.multiJackpotLeftArrowBgClr = global.lobbyConfigurations.multiJackpotLeftArrowBgClr;
        this.multiJackpotRightArrowBgClr = global.lobbyConfigurations.multiJackpotRightArrowBgClr;
        this.mJPaginationActiveClr = global.lobbyConfigurations.mJPaginationActiveClr;
        this.mJPaginationInactiveClr = global.lobbyConfigurations.mJPaginationInactiveClr;
        this.multiJackpotArrowOpacity = global.lobbyConfigurations.multiJackpotArrowOpacity;
        const multiJackpotmode = global.lobbyConfigurations.multiJackpotmode;
        this.themeArrowLeft = global.messages.ThemeArrowLeft;
        this.themeArrowRight = global.messages.ThemeArrowRight;
        const jackpotDataConfig = this.configProviderService.provideJackpotConfig();
        const features: any = this.configProviderService.provideFeaturesConfig();
        this.isLegacy = !this.designSystemEnablerService.isEnabled();
        this.enableJpGroupTickingForLobby = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.lobbyType,
            jackpotDataConfig.enableJpGroupTickingForLobbyType,
        );
        this.subCategorySubTitleFontSize = this.casinoLobbyService.getsubCategorySubTitleFontSize(this.category);
        this.isUkHeading = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.isUkHeading);
        this.subCategoryTextColor = this.casinoLobbyService.getsubCategoryTextColor(this.category);
        this.lobbyTileFontFamily = this.casinoLobbyService.getLTileFontFamily(this.category);
        if (jackpotDataConfig.enableJackpotSortGames) {
            this.getSortingGames();
        }
        if (multiJackpotmode === 'v2' || multiJackpotmode === 'v3') {
            this.mode = multiJackpotmode;
        } else {
            this.mode = 'v1';
        }
        //Handle jackpot feed update
        this.startJackpotFeed();
        this.isPreButtonVisible = true;
        if (this.widgets.length > 1) {
            this.isNextButtonVisible = false;
        } else {
            this.isNextButtonVisible = true;
        }
        this.fastLoadingGamesService.fastLoadingGamesFeedObservable.subscribe((fastLoadingGames: any) => {
            this.fastLoadingGamesService.setFastLoadingGames(this.games, fastLoadingGames);
        });
        this.enableEpcotSubNavigation = subnavConfig.enableEpcotSubNavigation;

        if (this.enableJpGroupTickingForLobby) {
            this.updateTickingValue();
        }
    }

    ngDoCheck() {
        let index;
        const list = document.getElementsByClassName('slide-3d');
        for (index = 0; index < list.length; ++index) {
            list[index].setAttribute('style', 'border-style: none !important;');
        }
    }
    onResize() {
        if (document.body.offsetWidth >= 655 || window.innerWidth >= 655) {
            this.isCarousal = true;
            this.options3D();
        } else {
            this.isCarousal = false;
            this.moveSlideTo = this.indexCount;
        }

        this.setCarousel();
    }
    options3D() {
        const { width, height, space } =
            document.body.offsetWidth >= 1345 ? { width: 260, height: 250, space: 260 } : { width: 210, height: 200, space: 200 };

        this.options = {
            clicking: true,
            sourceProp: 'src',
            visible: 3,
            perspective: 1,
            startSlide: 0,
            dir: 'rtr',
            autoRotationSpeed: 5000000000,
            loop: true,
            width,
            height,
            space,
        };
    }

    getCarouselData(data: any) {
        this.carouselData = data;
    }
    onPrevClick() {
        this.featuredGrid.prevItem();
    }

    onNextClick() {
        this.featuredGrid.nextItem();
    }
    startJackpotFeed() {
        this.showJackpotWidget = false;
        //Get jackpot feed data and update component if jackpot feed is available already

        this.refreshJackpotsNew();
        //Register to jackpot feed
        this.jackpotDataService.jackpotFeedObservable.subscribe(() => {
            this.refreshJackpotsNew();
            if (this.indexCount == this.widgets?.length - 1) {
                this.isNextButtonVisible = true;
            } else {
                this.isNextButtonVisible = false;
            }
        });
    }

    refreshJackpotsNew() {
        this.showfeatureGrid = false;
        if (this.category.sitecoreData.showJackpotWidget) {
            this.JackpotDisplayNameConfig = this.configProviderService.provideGlobalConfig()?.jackpotDisplayNameConfigurations;
            this.jackpotsCache = this.jackpotDataService.getJackpotData();
            const jackpotWidget = this.category.sitecoreData.jackpotWidget;
            for (const widgetTemp in jackpotWidget) {
                jackpotWidget[widgetTemp] = decodeURIComponent(jackpotWidget[widgetTemp]);
            }
            const jackpotWidgetsItems: any = [];
            this.jackpotWidgetData.length = 0;
            this.widgets.length = 0;
            this.ShowSeeAllJackpots = this.category.sitecoreData.showSeeAllJackpots ? this.category.sitecoreData.showSeeAllJackpots : false;
            if (this.jackpotsCache && Object.keys(this.jackpotsCache).length && jackpotWidget && jackpotWidget.length > 1) {
                let count = 0;
                for (const key in this.jackpotsCache) {
                    if (count >= this.MaxWidgets) {
                        break;
                    }
                    const jackpotWidgetItem = this.GetJackpotWidgetItem(key, this.jackpotsCache);

                    if (this.CheckJackpotWidget(key, jackpotWidget)) {
                        jackpotWidgetsItems.push({
                            isActive: false,
                            jackpotValue: jackpotWidgetItem.amount,
                            jackpotDisplayName: jackpotWidgetItem.content,
                            jackpotName: this.JackpotDisplayNameConfig[jackpotWidgetItem.jackpotGroupId]
                                ? this.JackpotDisplayNameConfig[jackpotWidgetItem.jackpotGroupId]
                                : jackpotWidgetItem.jackpotGroupName,
                            jackpotWidgetImage: jackpotWidgetItem.jackpotImage,
                            showBackgroundImage: true,
                            jackpotWidgetBackgroundImage: jackpotWidgetItem.backgroundImage,
                        });
                        this.jackpotWidgetData.push({
                            jackpotName: this.JackpotDisplayNameConfig[jackpotWidgetItem.jackpotGroupId]
                                ? this.JackpotDisplayNameConfig[jackpotWidgetItem.jackpotGroupId]
                                : jackpotWidgetItem.jackpotGroupName,
                            jackpotDisplayName: jackpotWidgetItem.content,
                            jackpotCta: jackpotWidgetItem.ctaLink,
                            jackpotGames: jackpotWidgetItem.gamesList,
                        });
                        count++;
                    }
                }
                if (this.jackpotWidgetData.length > 0) {
                    const defaultWidget = this.jackpotWidgetData[this.currentSlideIndex];
                    this.UpdateWidget(defaultWidget);
                    this.widgets = jackpotWidgetsItems;
                    this.widgets[this.currentSlideIndex].isActive = true;
                    this.widgetsData = this.jackpotWidgetData;
                    this.showJackpotWidget = true;
                    this.MaxWidgets = this.widgets.length;
                    this.SetActiveMultiJackpotWidget(this.currentSlideIndex);
                }
            }
        } else {
            this.showJackpotWidget = false;
        }
        this.cdr.detectChanges();
        this.showfeatureGrid = true;
    }

    GetJackpotWidgetItem(jackpotWidgetKey: any, jackpots: any) {
        for (const jackpot in jackpots) {
            if (jackpot?.toLowerCase() === jackpotWidgetKey?.toLowerCase()) {
                return jackpots[jackpot];
            } else if (jackpots[jackpot] && jackpots[jackpot].subJackpotDetails) {
                for (const subjackpot of jackpots[jackpot].subJackpotDetails) {
                    if (subjackpot && subjackpot.subJackpotName && subjackpot.subJackpotName?.toLowerCase() === jackpotWidgetKey?.toLowerCase()) {
                        subjackpot.gamesList = jackpots[jackpot].gamesList;
                        subjackpot.jackpotGroupName = subjackpot.subJackpotName;
                        subjackpot.amount = subjackpot.subJackpotAmount;
                        subjackpot.Value = subjackpot.subJackpotValue;
                        return subjackpot;
                    }
                }
            }
        }

        return {};
    }

    CheckJackpotWidget(jackpot: any, jackpotWidgets: any) {
        for (const jackpotWidget in jackpotWidgets) {
            if (jackpotWidgets[jackpotWidget]?.toLowerCase() === jackpot?.toLowerCase()) {
                return true;
            }
        }
        return false;
    }

    onmoveFn(data: NguCarouselStore) {
        this.showfeatureGrid = false;
        this.isPreButtonVisible = data.isFirst;
        this.moveSlideTo = data.slideItems;
        this.isNextButtonVisible = data.isLast;
        this.currentSlideIndex = data.currentSlide;
        this.UpdateWidget(this.jackpotWidgetData[this.currentSlideIndex]);
        this.SetActiveMultiJackpotWidget(this.currentSlideIndex);
        this.indexCount = this.currentSlideIndex;
        this.cdr.detectChanges();
        this.showfeatureGrid = true;
        if (this.carousel) this.carousel.slideClicked(this.indexCount);
    }

    setCarousel() {
        if (!this.isTouch) {
            this.isJackpotWidgetGamesCarousel = true;
        } else {
            this.isJackpotWidgetGamesCarousel = false;
        }
    }

    HandleMultiJackpotClick(index: any) {
        this.SetActiveMultiJackpotWidget(index);
        this.moveSlideTo = index;
    }
    slideClicked(value: any) {
        this.showfeatureGrid = false;
        if (value === true) {
            if (this.indexCount < this.widgets.length - 1) {
                this.indexCount++;
                this.carousel.slideClicked(this.indexCount);
                this.UpdateWidget(this.jackpotWidgetData[this.indexCount]);
                this.SetActiveMultiJackpotWidget(this.indexCount);
            } else if (this.indexCount === this.widgets.length - 1) {
                this.indexCount = 0;
                this.carousel.slideClicked(this.indexCount);
                this.UpdateWidget(this.jackpotWidgetData[this.indexCount]);
                this.SetActiveMultiJackpotWidget(this.indexCount);
            }
        } else {
            if (this.indexCount !== 0) {
                this.indexCount--;
                this.carousel.slideClicked(this.indexCount);
                this.UpdateWidget(this.jackpotWidgetData[this.indexCount]);
                this.SetActiveMultiJackpotWidget(this.indexCount);
            } else if (this.indexCount === 0) {
                this.indexCount = this.widgets.length - 1;
                this.carousel.slideClicked(this.indexCount);
                this.UpdateWidget(this.jackpotWidgetData[this.indexCount]);
                this.SetActiveMultiJackpotWidget(this.indexCount);
            }
        }
        this.HandleMultiJackpotClick(this.indexCount);
        this.currentSlideIndex = this.indexCount;
        this.cdr.detectChanges();
        this.showfeatureGrid = true;
        if (this.featuredGrid && this.featuredGrid.desktopGamesCarousel) {
            this.featuredGrid.desktopGamesCarousel.moveTo(0);
        }
        if (this.featuredGrid && this.featuredGrid.mobileGamesCarousel) {
            this.featuredGrid.mobileGamesCarousel.moveTo(0);
        }
    }

    pointClicked(index: any) {
        this.indexCount = index;
        this.carousel.goSlide(this.indexCount);
        this.UpdateWidget(this.jackpotWidgetData[this.indexCount]);
        this.SetActiveMultiJackpotWidget(this.indexCount);
        this.currentSlideIndex = this.indexCount;
    }
    GotoJackpots(ctaUrl: any) {
        this.navigation.goTo(ctaUrl, { replace: false, forceReload: true });
    }
    UpdateWidget(currentJackpotWidget: any) {
        let sortGamesList: any;
        if (currentJackpotWidget && currentJackpotWidget.jackpotGames && currentJackpotWidget.jackpotGames.length > 0) {
            if (this.totalJpGroupList && this.totalJpGroupList.length > 0) {
                sortGamesList = this.jackpotDataService.filterJpGroup(this.totalJpGroupList, currentJackpotWidget.jackpotName);
            }
            this.setGames(currentJackpotWidget.jackpotGames, sortGamesList);
        }
        this.jackpotDesc = this.sanitizer.bypassSecurityTrustResourceUrl(currentJackpotWidget.jackpotDesc);
        this.cta = currentJackpotWidget.jackpotCta;
    }
    setGames(gameslist: string[], sortGamesList?: any) {
        this.games = [];
        this.newFeatureGridArr = [];
        if (gameslist && gameslist.length > 0) {
            let starttileBgMode: boolean = false;
            const newgridbgcolor = this.category?.sitecoreData?.attributes?.newgridbgcolor;
            this.categoryObj = { ...this.category };
            this.categoryObj.rowCount = '-1';
            for (let i = 0; i < gameslist.length; i++) {
                const gameIdString: string = gameslist[i];
                const gameInfo: any = this.casinoLobbyService.getGameInfo(gameIdString);
                // TODO check for disable games
                if (gameInfo) {
                    gameInfo.icon = this.category.isIconSixCategory ? '6' : this.category.isIconSevenCategory ? '7' : '1';
                    gameInfo.lobbyType = this.lobbyType;
                    const gameObj = this.gameDataManager.setGameLobbyModel(gameInfo, this.isTouch);
                    gameObj.gameContainer = this.gameContainer;
                    gameObj.bgTileMode = this.category.isIconSevenCategory ? (starttileBgMode ? starttileBgMode : false) : false;
                    gameObj.gameTileBorderRadius = this.casinoLobbyService.getBorderRadius(this.category);
                    gameObj.gameTileDropShadow = this.casinoLobbyService.getDropShadow(this.category);
                    gameObj.infoIconClass = this.casinoManager.assignGameClass(this.casinoManager.getCurrentWidth(), gameInfo.icon);
                    gameObj.newgridbgcolor = newgridbgcolor;
                    starttileBgMode = !starttileBgMode;
                    this.games.push(gameObj);
                    this.newFeatureGridArr.push({
                        games: [gameObj],
                        maxRowSpan: gameObj.rowSpan,
                        maxColSpan: gameObj.colSpan,
                    });
                }
            }
            if (this.games && this.games.length > 0) {
                this.isjackpotFeed.emit({ isJackpotdataForParallax: true });
            }
        }
        if (sortGamesList && sortGamesList.length > 0) {
            this.games = this.jackpotDataService.filterGames(sortGamesList, this.games);
            this.newFeatureGridArr = this.jackpotDataService.newFeaturegridArray(this.games);
        }
    }

    SetActiveMultiJackpotWidget(currentActiveWidgetIndex: number) {
        try {
            if (this.widgets) {
                this.widgets.forEach((widget: any) => {
                    widget.isActive = false;
                });

                if (this.widgets[currentActiveWidgetIndex]) {
                    this.widgets[currentActiveWidgetIndex].isActive = true;
                }
            }
        } catch (e) {
            //Do nothing
        }
    }
    getSortingGames() {
        this.jackpotDataService.jpSortGamesObservable.subscribe((data: any) => {
            this.totalJpGroupList = data?.jackpotGroupGamesList;
            this.refreshJackpotsNew();
        });
    }

    updateTickingValue() {
        this.jackpotDataService.jpTickingObservable.subscribe((res: any) => {
            if (res) {
                this.jackpotList = [];
                res.forEach((jackpot: any) => {
                    this.jackpotList.push(jackpot);
                });
            }
            const ids = this.jackpotList?.map((jackpotName: any) => jackpotName);
            const filteredList = this.jackpotList?.filter((jackpotName: any, index: any) => !ids.includes(jackpotName, index + 1));
            filteredList?.forEach((item: any) => {
                this.widgets?.forEach((wid: any) => {
                    if (item.jackpotName.toLowerCase() == wid.jackpotName.toLowerCase()) {
                        wid.jackpotValue = item.amount;
                    }
                });
            });
        });
    }
}
