import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csCommonConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: commonClientConfigFactory,
})
export class CommonClientConfig {
    scriptsInjectionData: { [key: string]: { url: string; customAttributes: { [key: string]: string } } };
}

export function commonClientConfigFactory(service: LazyClientConfigService) {
    return service.get(CommonClientConfig);
}
