import { Injectable } from '@angular/core';

import { addClass, getAmount, onlyUnique, removeClass, textToNumber, toDate, toTimeStamp } from '@casinocore/platform/utils';
import { DeviceService, Message, MessageLifetime, MessageQueueService, MessageType, Page, TrackingService, WindowRef } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';

import { CasinoLobbyService } from '../casino-lobby-manager/casino-lobby.service';
import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';
import { CasinoCoreGamelaunchService } from './gamelaunch.service';
import { CUSTOM_LINK_TYPE_DRAWER, ICustomLinkDrawerContent, IOnboarding, LottieAnimationStatus } from './models/common.model';
import { GameLaunch } from './models/gamelaunch.model';
import {
    IGameTableName,
    ILiveCasinoLoungeGlobalClrConfig,
    ILiveCasinoLoungeGlobalConfig,
    ILiveCasinoLoungeMap,
    ILiveCasinoLoungeSector,
    ILiveCasinoLoungeStatCategory,
    ILiveCasinoLoungeStatItem,
    ILiveCasinoLoungeStats,
    LIVELOUNGE_STATS_CTA_TYPE_GAME,
    LIVELOUNGE_STATS_CTA_TYPE_SECTOR,
    LIVELOUNGE_STATS_CTA_TYPE_STATIC_GAME,
    LiveCasinoLoungeConfiguration,
    LiveCasinoLoungeStat,
} from './models/live-casino-lounge.model';

interface IGetMapResponse {
    map: ILiveCasinoLoungeMap;
    globalConfig: ILiveCasinoLoungeGlobalConfig;
    globalClrConfig: ILiveCasinoLoungeGlobalClrConfig;
}

@Injectable({
    providedIn: 'root',
})
export class LiveCasinoLoungeService {
    document: Document;
    categoryRoutePrefix = '/c/';
    sectorIdPrefix = 'lclCat';
    statsRoute = 'liveloungestats';
    loadingObservable: Observable<boolean | null>;
    mapObservable: Observable<boolean | null>;
    backgroundObservable: Observable<boolean | null>;
    animatedLayerObservable: Observable<boolean | null>;
    sectorsObservable: Observable<boolean | null>;
    sectorOpenedObservable: Observable<ILiveCasinoLoungeSector | null>;
    gotoSectorObservable: Observable<string | null>;
    lottieAnimationStatusObservable: Observable<LottieAnimationStatus | null>;
    drawerContentMapAvailable: Observable<boolean | null>;
    onboardingDataAvailable: Observable<boolean | null>;
    statsContentAvailable: Observable<boolean | null>;
    statsDataAvailable: Observable<boolean | null>;
    statsOpenedObservable: Observable<boolean | null>;
    statsFetched: boolean = false;

    private currentLobbyType: string;
    private lobbyGames: Array<string>;
    private sectorIds: Array<string>;
    private openedSector: ILiveCasinoLoungeSector | undefined;
    private liveCasinoLoungeConfig: LiveCasinoLoungeConfiguration;
    private loadingPublisher = new BehaviorSubject<boolean>(true);
    private mapPublisher = new BehaviorSubject<boolean>(false);
    private backgroundPublisher = new BehaviorSubject<boolean>(false);
    private animatedLayerPublisher = new BehaviorSubject<boolean>(false);
    private sectorsMap: Map<string, ILiveCasinoLoungeSector> = new Map<string, ILiveCasinoLoungeSector>();
    private sectorsPublisher = new BehaviorSubject<boolean>(false);
    private sectorOpenedPublisher = new BehaviorSubject<ILiveCasinoLoungeSector | null>(null);
    private gotoSectorPublisher = new BehaviorSubject<string | null>(null);
    private lottieAnimationStatusPublisher = new BehaviorSubject<LottieAnimationStatus | null>(null);
    private globalConfigData: ILiveCasinoLoungeGlobalConfig;
    private mapData: ILiveCasinoLoungeMap;
    private globalConfigClr: ILiveCasinoLoungeGlobalClrConfig;
    private drawerContentMap: Map<string, ICustomLinkDrawerContent> = new Map<string, ICustomLinkDrawerContent>();
    private drawerContentMapPublisher = new BehaviorSubject<boolean | null>(null);
    private onboardingData: IOnboarding;
    private onboardingDataPublisher = new BehaviorSubject<boolean | null>(null);
    private statsContent: ILiveCasinoLoungeStats;
    private statsContentPublisher = new BehaviorSubject<boolean | null>(null);
    private statsData: any;
    private statsDataPublisher = new BehaviorSubject<boolean | null>(null);
    private stats: Array<LiveCasinoLoungeStat>;
    private statsOpened: boolean;
    private statsOpendPublisher = new BehaviorSubject<boolean | null>(null);
    private statsError: boolean;
    private statsNotUpdated: boolean;

    constructor(
        private page: Page,
        private windowRef: WindowRef,
        private api: PlatformApiService,
        private tracking: TrackingService,
        private deviceService: DeviceService,
        private messageQueue: MessageQueueService,
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
        private casinoCoreGameLaunchService: CasinoCoreGamelaunchService,
    ) {
        const nativeWindow = <any>this.windowRef.nativeWindow;
        this.document = nativeWindow.document;

        this.lobbyGames = [];
        this.sectorIds = [];
        this.liveCasinoLoungeConfig = this.configProviderService.provideLiveCasinoLoungeConfig();
        this.loadingObservable = this.loadingPublisher.asObservable();
        this.mapObservable = this.mapPublisher.asObservable();
        this.backgroundObservable = this.backgroundPublisher.asObservable();
        this.animatedLayerObservable = this.animatedLayerPublisher.asObservable();
        this.sectorsObservable = this.sectorsPublisher.asObservable();
        this.sectorOpenedObservable = this.sectorOpenedPublisher.asObservable();
        this.gotoSectorObservable = this.gotoSectorPublisher.asObservable();
        this.lottieAnimationStatusObservable = this.lottieAnimationStatusPublisher.asObservable();
        this.drawerContentMapAvailable = this.drawerContentMapPublisher.asObservable();
        this.onboardingDataAvailable = this.onboardingDataPublisher.asObservable();
        this.statsContentAvailable = this.statsContentPublisher.asObservable();
        this.statsDataAvailable = this.statsDataPublisher.asObservable();
        this.statsOpenedObservable = this.statsOpendPublisher.asObservable();
        this.statsOpened = false;
        this.statsError = false;
        this.statsNotUpdated = false;
    }

    get map(): ILiveCasinoLoungeMap {
        return this.mapData;
    }

    getLobbyGames(): Array<string> {
        return this.lobbyGames;
    }

    setLobbyGame(game: string): void {
        this.lobbyGames.push(game);
    }

    getSectorIds(): Array<string> {
        return this.sectorIds;
    }

    setSectorId(sectorId: string): void {
        this.sectorIds.push(sectorId);
    }

    get config(): LiveCasinoLoungeConfiguration {
        return this.liveCasinoLoungeConfig;
    }

    get globalConfig(): ILiveCasinoLoungeGlobalConfig {
        return this.globalConfigData;
    }

    get globalClrConfig(): ILiveCasinoLoungeGlobalClrConfig {
        return this.globalConfigClr;
    }

    get isMobile(): boolean {
        return this.currentWidth <= 1024;
    }

    get lobbyType(): string {
        return this.currentLobbyType;
    }

    get mainElement(): HTMLDivElement {
        return this.document.getElementById('main-content')?.parentElement as HTMLDivElement;
    }

    get currentWidth(): number {
        const element = this.document.getElementById('casino-viewport');
        return this.deviceService.isiOS ? element?.offsetWidth || this.deviceService.windowWidth() : this.deviceService.windowWidth();
    }

    get currentHeight(): number {
        const element = this.document.getElementById('casino-viewport');
        return this.deviceService.isiOS ? element?.offsetHeight || window.innerHeight : window.innerHeight;
    }

    get isMobileLandscape(): boolean {
        return (
            this.deviceService.isMobilePhone &&
            this.currentWidth >= this.liveCasinoLoungeConfig.mobileLandscapeConfig.minWidth &&
            this.currentWidth <= this.liveCasinoLoungeConfig.mobileLandscapeConfig.maxWidth
        );
    }

    getScreenName(title: string): string {
        const content = document.createElement('span');
        content.className = 'removeHtmlTags';
        content.innerHTML = title;
        content.style.visibility = 'hidden';
        content.style.opacity = '0';
        content.style.height = '0';
        return content.innerHTML.split('<br>').join(' ');
    }

    getLoadingStatus() {
        return combineLatest([this.mapObservable, this.backgroundObservable, this.animatedLayerObservable, this.sectorsObservable]);
    }

    getMap(mapPath: string): void {
        this.api
            .post('LiveCasinoLounge/GetMap', { mapPath: mapPath }, { responseType: 'json', withCredentials: true, showSpinner: false })
            .subscribe({
                next: (response: IGetMapResponse) => {
                    if (response) {
                        this.globalConfigData = response?.globalConfig;
                        this.globalConfigClr = response?.globalClrConfig;
                        this.mapData = response.map;
                        this.lobbyGames = [];
                        this.sectorIds = [];
                        this.mapPublisher.next(true);
                        this.setCustomLinkDrawerContent();
                    }
                },
                error: (error: string) => {
                    const message: Message = {
                        html: error,
                        type: MessageType.Error,
                        lifetime: MessageLifetime.Single,
                        scope: 'casinocorelobby',
                    };
                    if (message) {
                        this.messageQueue.add(message);
                    }
                },
            });
    }

    initSectors(): void {
        if (this.sectorsMap.size) {
            const lottieSectors = Array.from(this.sectorsMap.values()).filter((sector: ILiveCasinoLoungeSector) => sector.imageType === 'LOTTIE');
            lottieSectors.forEach((m: ILiveCasinoLoungeSector) => {
                m.isLoaded = false;
            });
            this.sectorsPublisher.next(true);
        }
    }

    initMap(): void {
        this.mainElement.classList.remove('casino-lobby-height');
        this.loadingPublisher.next(true);
        this.backgroundPublisher.next(false);
        this.animatedLayerPublisher.next(false);
        this.sectorsMap = new Map<string, ILiveCasinoLoungeSector>();
        this.sectorsPublisher.next(false);
        this.gotoSectorPublisher.next(null);
        this.openedSector = undefined;
        this.statsOpened = false;
        this.sectorOpenedPublisher.next(null);
    }

    setLobbyType(lobbyType: string): void {
        this.currentLobbyType = lobbyType;
    }

    setBackgroundStatus(loaded: boolean): void {
        this.backgroundPublisher.next(loaded);
    }

    setAnimatedLayerStatus(loaded: boolean): void {
        this.animatedLayerPublisher.next(loaded);
    }

    setSectorLoaded(sectorId: string): void {
        if (this.sectorsMap.size && this.sectorsMap.has(sectorId)) {
            this.sectorsMap.set(sectorId, { ...this.sectorsMap.get(sectorId), isLoaded: true } as ILiveCasinoLoungeSector);
        } else {
            this.sectorsMap.set(sectorId, { isLoaded: true } as ILiveCasinoLoungeSector);
        }

        this.sectorsPublisher.next(true);
    }

    getSectorsLoadedCount(): number {
        return this.sectorsMap.size ? Array.from(this.sectorsMap.values()).filter((sector: ILiveCasinoLoungeSector) => sector.isLoaded).length : 0;
    }

    getOpenedSector(): ILiveCasinoLoungeSector | undefined {
        return this.openedSector;
    }

    setSectorOpened(selectedMapItem: ILiveCasinoLoungeSector | null): void {
        if (selectedMapItem) addClass('live-casino-lounge-sector-opened');
        else removeClass('live-casino-lounge-sector-opened');

        this.openedSector = selectedMapItem!;
        this.sectorOpenedPublisher.next(selectedMapItem);
    }

    gotoSector(categortId: string): void {
        this.gotoSectorPublisher.next(categortId);
    }

    setLottieAnimationStatus(status: LottieAnimationStatus): void {
        this.lottieAnimationStatusPublisher.next(status);
    }

    setOnboardingData(): void {
        this.api.post('LiveCasinoLounge/GetOnboardingContent', {}, { responseType: 'json', withCredentials: true, showSpinner: false }).subscribe({
            next: (response: IOnboarding) => {
                if (response) {
                    this.onboardingData = response;
                    this.onboardingDataPublisher.next(true);
                }
            },
            error: (error: string) => {
                const message: Message = {
                    html: error,
                    type: MessageType.Error,
                    lifetime: MessageLifetime.Single,
                    scope: 'casinocorelobby',
                };
                if (message) {
                    this.messageQueue.add(message);
                }
            },
        });
    }

    getOnboardingData(): IOnboarding {
        return this.onboardingData;
    }

    fetchDrawerContent(paths: Array<string>): void {
        this.api
            .post('LiveCasinoLounge/FetchContent', { contentPaths: paths }, { responseType: 'json', withCredentials: true, showSpinner: false })
            .subscribe({
                next: (response: any) => {
                    if (response) {
                        this.emptyDrawerMap();
                        response.forEach((drawerContent: ICustomLinkDrawerContent) => {
                            this.drawerContentMap.set(drawerContent.path, drawerContent);
                        });
                        this.drawerContentMapPublisher.next(true);
                    }
                },
                error: (error: string) => {
                    const message: Message = {
                        html: error,
                        type: MessageType.Error,
                        lifetime: MessageLifetime.Single,
                        scope: 'casinocorelobby',
                    };
                    if (message) {
                        this.messageQueue.add(message);
                    }
                },
            });
    }

    getDrawerContentMap(): Map<string, ICustomLinkDrawerContent> {
        return this.drawerContentMap;
    }

    getDrawerContent(paths: Array<string>): ICustomLinkDrawerContent[] {
        const content = new Array<ICustomLinkDrawerContent>();
        paths.forEach((path: string) => content.push(this.drawerContentMap.get(path) as ICustomLinkDrawerContent));
        return content;
    }

    fetchStatsContent(): void {
        if (this.liveCasinoLoungeConfig.enableStats) {
            this.api.post('LiveCasinoLounge/FetchStatsContent', {}, { responseType: 'json', withCredentials: true, showSpinner: false }).subscribe({
                next: (response: ILiveCasinoLoungeStats) => {
                    if (response) {
                        this.statsContent = response;
                        this.statsContentPublisher.next(true);
                    }
                },
                error: (error: string) => {
                    const message: Message = {
                        html: error,
                        type: MessageType.Error,
                        lifetime: MessageLifetime.Single,
                        scope: 'casinocorelobby',
                    };
                    if (message) {
                        this.messageQueue.add(message);
                    }
                },
            });
        }
    }

    get isStatsOpened(): boolean {
        return this.statsOpened;
    }

    getStatsContent(): ILiveCasinoLoungeStats {
        return this.statsContent;
    }

    fetchStatsData(): void {
        if (this.liveCasinoLoungeConfig.enableStats) {
            this.api.post('LiveCasinoLounge/FetchStats', {}, { responseType: 'json', withCredentials: true, showSpinner: false }).subscribe({
                next: (response: JSON) => {
                    if (response) {
                        this.emptyStats();
                        this.statsData = response;
                        this.statsDataPublisher.next(true);
                    }
                },
                error: () => {
                    this.statsError = true;
                },
            });
        }
    }

    getStatsData(): JSON {
        return this.statsData;
    }

    get isStatsError(): boolean {
        return this.statsError;
    }

    get isStatsNotUpdated(): boolean {
        return this.statsNotUpdated;
    }

    emptyStats(): void {
        this.stats = [];
    }

    setStatsOpened(isOpened: boolean): void {
        this.statsOpened = isOpened;
        this.statsOpendPublisher.next(isOpened);
    }

    getStats(): Array<LiveCasinoLoungeStat> {
        if (this.stats?.length) return this.stats;

        if (this.statsContent && this.statsData) {
            const loadFlag = this.statsData['dataFlag']?.['loadFlag'];
            if (loadFlag) this.statsNotUpdated = loadFlag === 'N';

            const disabledGames = this.casinoLobbyService.getDisabledGames();
            this.statsContent.categories.forEach((category: ILiveCasinoLoungeStatCategory) => {
                const stat = new LiveCasinoLoungeStat();
                const apiCategory = this.statsData[category.categoryId];
                if (category) {
                    stat.category = category;
                    const currency = this.statsData['ccy'];
                    let statIndex = 0;
                    this.statsContent.stats
                        .filter(
                            (m: ILiveCasinoLoungeStatItem) =>
                                m.itemId && m.itemId.indexOf('.') > -1 && m.itemId.split('.')[0] === category.categoryId,
                        )
                        .forEach((statItem: ILiveCasinoLoungeStatItem) => {
                            const itemKey = statItem.itemId.split('.')[1];
                            const statAPIItem = apiCategory[itemKey];
                            if (!statAPIItem || !statItem) return;

                            let isValid = true;
                            const locale = this.page.locale;
                            if (typeof statAPIItem === 'object') {
                                if (statItem.dataType === 'Currency') {
                                    const convertedCurrency = getAmount(statAPIItem['winAMT'], currency, locale);
                                    statItem.value = convertedCurrency.value;
                                    isValid = convertedCurrency.isValid;
                                } else statItem.value = statAPIItem['winAMT'];

                                const gameId = statAPIItem['vendorGmName'];
                                if (gameId) {
                                    const gameInfo = this.casinoLobbyService.getGameInfo(gameId);
                                    if (gameInfo) {
                                        statItem.gameId = gameId;
                                        statItem.gameName = gameInfo.name;
                                        statItem.isDynamicGame = true;
                                    }

                                    if (disabledGames.includes(gameId)) statItem.isDynamicGame = false;
                                }

                                if (statItem.showCTA && statItem.ctaType === LIVELOUNGE_STATS_CTA_TYPE_GAME)
                                    statItem.showCTA = statItem.isDynamicGame;

                                const betTS = statAPIItem['betTS'];
                                if (betTS) {
                                    const date = toDate(betTS);
                                    statItem.betTS = date;
                                }
                            } else {
                                let statValue = statAPIItem ? statAPIItem.toString() : '';
                                if (statValue) {
                                    statValue = statValue.indexOf('_') > -1 ? statValue : statValue?.toLowerCase();
                                    switch (statItem.dataType) {
                                        case 'Number':
                                            {
                                                if (isNaN(statValue)) {
                                                    const number = textToNumber(statValue);
                                                    statItem.value = number !== -1 ? number : statValue;
                                                } else statItem.value = statValue.indexOf('.') > -1 ? parseFloat(statValue).toFixed(2) : statValue;
                                            }
                                            break;
                                        case 'Time':
                                            statItem.value = toTimeStamp(statValue);
                                            break;
                                        case 'Date':
                                            statItem.value = toDate(statValue);
                                            break;
                                        case 'Currency':
                                            {
                                                const convertedCurrency = getAmount(statValue, currency, locale);
                                                statItem.value = convertedCurrency.value;
                                                isValid = convertedCurrency.isValid;
                                            }
                                            break;
                                        case 'Game':
                                            if (this.liveCasinoLoungeConfig.enableGameNameMapping) {
                                                const gameTableNames = this.statsContent?.gameTableNames;
                                                let gameTableName = statValue;
                                                if (gameTableNames?.length) {
                                                    const statAPIValue = statAPIItem ? statAPIItem.toString() : '';
                                                    const fiteredGameTableName = gameTableNames.find(
                                                        (m: IGameTableName) => m.physicalName === statAPIValue || m.gameTableName === statAPIValue,
                                                    );
                                                    gameTableName = fiteredGameTableName?.commercialGameTableName
                                                        ? fiteredGameTableName.commercialGameTableName
                                                        : gameTableName;
                                                }
                                                statItem.value = gameTableName;
                                            } else statItem.value = statValue;
                                            break;
                                        default:
                                            statItem.value = statValue;
                                            break;
                                    }
                                }

                                const hideCTAForGameDataType =
                                    this.liveCasinoLoungeConfig.enableGameNameMapping &&
                                    statItem.dataType === 'Game' &&
                                    statItem.showCTA &&
                                    statItem.ctaType === LIVELOUNGE_STATS_CTA_TYPE_STATIC_GAME;
                                if (statItem.ctaType === LIVELOUNGE_STATS_CTA_TYPE_GAME || hideCTAForGameDataType) statItem.showCTA = false;
                            }

                            if (statItem.showCTA && statItem.staticGameId && statItem.ctaType === LIVELOUNGE_STATS_CTA_TYPE_STATIC_GAME) {
                                const gameInfo = this.casinoLobbyService.getGameInfo(statItem.staticGameId);
                                if (gameInfo) {
                                    statItem.gameId = statItem.staticGameId;
                                    statItem.gameName = gameInfo.name;
                                    statItem.isStaticGame = true;
                                }

                                if (disabledGames.includes(statItem.staticGameId)) statItem.isStaticGame = false;

                                statItem.showCTA = statItem.isStaticGame;
                            }

                            if (statItem.showCTA && statItem.ctaType === LIVELOUNGE_STATS_CTA_TYPE_SECTOR)
                                statItem.showCTA = this.sectorIds.includes(statItem.sectorId);

                            if (statItem.value && statItem.value != '0' && isValid) {
                                if (category.showHighlights && statIndex <= 2) stat.impStats.push(statItem);
                                else stat.stats.push(statItem);
                                statIndex++;
                            }
                        });

                    stat.isAvailable = stat.impStats?.length > 0 || stat.stats?.length > 0;
                    this.stats.push(stat);
                }
            });
        }

        if (this.stats?.length) this.stats = this.stats.sort((a, b) => (a.isAvailable ? 0 : 1) - (b.isAvailable ? 0 : 1));

        return this.stats;
    }

    showLoading(): void {
        if (!this.document.querySelector('.height-cls-skeleton')) this.loadingPublisher.next(true);
    }

    hideLoading(): void {
        if (this.document.querySelector('.height-cls-skeleton')) this.loadingPublisher.next(false);
    }

    getRowSpan(icon: string): number {
        switch (icon) {
            case '2':
            case '-2':
                return 1;
            case '3':
            case '-3':
                return 2;
            case '4':
            case '-4':
                return 2;
            case '5':
            case '-5':
                return 2;
            case '6':
            case '-6':
                return 1;
            case '7':
            case '-7':
                return 1;
            default:
                return 1;
        }
    }

    getColSpan(icon: string, isTouch: boolean): number {
        switch (icon) {
            case '2':
            case '-2':
                return 2;
            case '3':
            case '-3':
                return 1;
            case '4':
            case '-4':
                return 2;
            case '5':
            case '-5':
                if (isTouch) {
                    return 2;
                } else {
                    return 3;
                }
            case '6':
            case '-6':
                return 1;
            case '7':
            case '-7':
                return 2;
            default:
                return 1;
        }
    }

    getCategoryInfo(): { categoryId: string; categoryName: string } {
        const link = window.location.href;
        const subCategoryUrl = this.categoryRoutePrefix;
        let categoryId = link.substr(link.indexOf(subCategoryUrl) + (subCategoryUrl?.length || 0));
        if (categoryId.indexOf('?') !== -1) categoryId = categoryId.substr(0, categoryId.indexOf('?'));
        const lobbyInfo = this.casinoLobbyService.getLobbyInfo(this.lobbyType);
        const categoryInfo = lobbyInfo?.categoriesMap.get(categoryId);
        return { categoryId, categoryName: categoryInfo?.engCategoryName };
    }

    trackEvent(
        trackingType: string,
        categoryEvent: string,
        labelEvent: string,
        actionEvent: string,
        positionEvent: string,
        locationEvent: string,
        eventDetails: string,
        uRLClicked: string,
    ): void {
        this.tracking.triggerEvent(trackingType, {
            'component.CategoryEvent': categoryEvent,
            'component.LabelEvent': labelEvent,
            'component.ActionEvent': actionEvent,
            'component.PositionEvent': positionEvent,
            'component.LocationEvent': locationEvent,
            'component.EventDetails': eventDetails,
            'component.URLClicked': uRLClicked,
        });
    }

    launchGame(gameId: string, categoryId: string, index: number): void {
        const gameModel = new GameLaunch();
        gameModel.isTouch = this.deviceService.isTouch;
        gameModel.replaceRouteInHistory = false;
        gameModel.isDemoLaunch = false;
        gameModel.skipUnfinGame = false;
        gameModel.categoryType = categoryId;
        gameModel.game = this.casinoLobbyService.getGameInfo(gameId) || gameId;
        gameModel.game.gameId = gameId;
        gameModel.game.lobbyType = this.lobbyType;
        gameModel.gameLaunchedFrom = categoryId;
        gameModel.gridPosition = Number(index + 1);
        gameModel.gamePosition = index;
        gameModel.from = 'q';
        gameModel.gameContainer = '';
        gameModel.audioFrom = true;
        gameModel.clickTimeStamp = this.casinoCoreGameLaunchService.currentTimeStamp();
        this.casinoCoreGameLaunchService.launchGame(gameModel);
    }

    private emptyDrawerMap(): void {
        if (this.drawerContentMap && this.drawerContentMap.size > 0) this.drawerContentMap.clear();
    }

    private setCustomLinkDrawerContent(): void {
        if (this.mapData) {
            const paths = new Map<string, Array<string>>();
            this.mapData?.sectors.forEach((sector: ILiveCasinoLoungeSector) => {
                const customLinkDrawers: any = sector.customLinkItems
                    .filter((m) => m?.customLinkType === CUSTOM_LINK_TYPE_DRAWER && m?.customLinkDrawerContent)
                    .map((m) => m.customLinkDrawerContent);
                const customLinkDrawerContents = new Array<string>().concat(...customLinkDrawers);
                if (customLinkDrawerContents.length) {
                    if (paths.has(CUSTOM_LINK_TYPE_DRAWER)) {
                        const content = paths.get(CUSTOM_LINK_TYPE_DRAWER);
                        paths.set(
                            CUSTOM_LINK_TYPE_DRAWER,
                            content && content.length ? content.concat(customLinkDrawerContents) : customLinkDrawerContents,
                        );
                    } else paths.set(CUSTOM_LINK_TYPE_DRAWER, customLinkDrawerContents);
                }
            });

            if (paths && paths.size) {
                paths.forEach((value: string[], key: string) => {
                    if (!value?.length) return;
                    if (key === CUSTOM_LINK_TYPE_DRAWER) this.fetchDrawerContent(value.filter(onlyUnique));
                });
            }
        }
    }
}
