import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csGameImpressionsConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: gameImpressionsClientConfigFactory,
})
export class GameImpressionsClientConfig {
    isEnabled: boolean;
    targetedCategoryRoutes: any;
}

export function gameImpressionsClientConfigFactory(service: LazyClientConfigService) {
    return service.get(GameImpressionsClientConfig);
}
