<!--component html goes here -->
<div class="bottom-category-header clearfix" id="bottom-category-header" [ngClass]="{ 'casino-ios-bottom-nav-bar': isIOS }">
    <span class="bottom-nav-heading">{{ categoryHeaderForBottomNav }}</span>
    <nav id="bottom-navbar" class="bottom-navbar">
        <div class="bottom-nav-category-container">
            <div class="bottom-nav-resizable-container">
                <ul class="bottom-nav-list">
                    <li *ngFor="let navItem of navBarCategories; let i = index" [hidden]="!navItem.isNavigationCategory">
                        <a (click)="navItemClick($event, i, navItem.route, navItem.url)">
                            <span *ngIf="navItem.isNavigationCategory">
                                <div [ngClass]="navItem.sitecoreData.attributes.icon" class="icon"></div>
                                <div [innerHtml]="navItem.categoryname" class="name"></div>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
