import { DOCUMENT, DatePipe, Location, NgClass, NgFor, NgIf, NgStyle, SlicePipe } from '@angular/common';
import { AfterViewInit, Component, DoCheck, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import {
    CommonMessages,
    CookieService,
    DeviceService,
    Logger,
    MessageLifetime,
    MessageQueueService,
    NavigationService,
    Page,
    ProductHomepagesConfig,
    TrackingService,
    UserService,
    WindowRef,
} from '@frontend/vanilla/core';
import { BalancePropertiesService } from '@frontend/vanilla/features/balance-properties';
import { MessagePanelComponent } from '@frontend/vanilla/features/message-panel';
import { TrustAsResourceUrlPipe } from '@frontend/vanilla/shared/browser';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CashierIframeComponent } from '../cashier-iframe/cashier-iframe.component';
import { GamePopoutComponent } from '../cashier-iframe/game-pop-out.component';
import { CasinoLobbyService } from '../casino-lobby-manager/casino-lobby.service';
import { ConfigProviderService } from '../config-provider/config-provider.service';
import { GameEmbedComponent } from '../game-embed/game-embed.component';
import { PlatformApiService } from '../platform-api/platform-api.service';
import { LazySearchDesktopComponent } from '../search-desktop/lazy-search-desktop.component';
import { AdvanceGameDataService } from '../shared/advance-game-data.service';
import { ApiPathsHelperService } from '../shared/api-paths-helper-service';
import { CashierService } from '../shared/cashier.service';
import { CasinoManager } from '../shared/casino-manager.service';
import { CasinoWidgetService } from '../shared/casino-widget.service';
import { ConstService } from '../shared/const.service';
import { FastLoadingGamesService } from '../shared/fast-loading-games.service';
import { FavouriteService } from '../shared/favourite.service';
import { FreespinSummaryService } from '../shared/freespins-summary.service';
import { GameDataManager } from '../shared/game-data-manager.service';
import { GameLaunchTrackingService } from '../shared/game-launch-tracking.service';
import { InvokerProductService } from '../shared/invoker-product.service';
import { JackpotService } from '../shared/jackpot.service';
import { JackpotDataService } from '../shared/jackpotdata-service';
import { GameLobbyModel } from '../shared/models/game-lobby.model';
import { GameLaunch } from '../shared/models/gamelaunch.model';
import { OpenLoginDialog } from '../shared/open-login-dialog-v6';
import { RecentGamesService } from '../shared/recent-games.service';
import { UnfinishedGamesService } from '../shared/unfinished-games-service';
import { SlotRacesService } from '../slot-races/shared/slot-races.service';
import { CasinoCoreGameLaunchFactoryService } from './../shared/gamelaunchfactory.service';

@Component({
    selector: 'cc-embedded-game-view',
    templateUrl: 'embedded-game-view.component.html',
    standalone: true,
    imports: [
        MessagePanelComponent,
        NgIf,
        NgClass,
        NgStyle,
        NgFor,
        GameEmbedComponent,
        SlicePipe,
        DatePipe,
        TrustAsResourceUrlPipe,
        LazySearchDesktopComponent,
    ],
})
export class EmbeddedGameViewComponent implements OnInit, DoCheck, OnDestroy, AfterViewInit {
    @Input() borderRadius: any;
    @Input() gameEmbedPop: any;
    @Input() lobbyType: any;
    @Output() sendDataToEmbeddedGamePopUp = new EventEmitter<any>();
    @Output() sendDialogCloseEventToEmbeddedGamePopUp = new EventEmitter<any>();
    @Input() errorMessageQueue: any;
    value: string;
    Game: any;
    favouriteGames: any;
    useFavouriteGames: boolean;
    Favorites: boolean;
    isIframeVisible: boolean = false;
    gameIds: any = [];
    isGreaterthan: boolean = false;
    iframe: any;
    RecentlyPlayedNGamesUMightLike: any;
    RecentlyPlayed: any;
    getCategory: any;
    jackpotsFeed: any;
    isFullScreen: boolean = false;
    extrasColumnFlag: boolean = false;
    RecentlyPlayedGamesList: any = [];
    gamesUMightLikeList: any = [];
    gamesUMightLikeStringList: any = [];
    recentlyPlayedGamesStringList: any = [];
    mobHeight: any;
    mobWidth: any;
    isLiveDealerOpen: any;
    date: Date;
    gameWindowReference: any;
    iframeUrl: string;
    iconShrinkClickedFirst: boolean = false;
    depositButtonString: string;
    catgeoryDataClientConfig: any;
    globalsearchconfig: any;
    embededPopUpWidth: any;
    embededPopUpHeight: any;
    gameLaunchSuccess: boolean;
    unfinishedGamesResource: any;
    unfinishedgameMessgaes: any;
    teaserLaunchRealtiveUri: string;
    recentGames: any = ConstService.RECENT_GAMES_CATEGORY_ID;
    GYML: any = ConstService.GYML_GAMES_CATEGORY_ID;
    EGV_GYML: any = ConstService.EGV_GYML;
    commonMessages: any;
    isLoaderHide: boolean;
    favouriteStyle: string = 'theme-favorite-i';
    unFavouriteStyle: string = 'theme-favorite';
    isTouch: boolean;
    recommendationGames: any = [];
    recommendationGamesList: any = [];
    combineData: any = [];
    multipleGameLaunched: boolean = false;
    isQDAllowed: boolean;
    gameclickedfromsearch: boolean;
    Global: any;
    recentObs: Subscription;
    GamesyouMightLikeMessage: string;
    category: string;
    unsubscribe: Subject<any>;
    gameCloseAckEventReceived: boolean;
    gameConfig: any;
    game: any;
    index: number;
    section: string;
    popUpClicked: boolean;
    summaryPopUpIsOpened: boolean;
    casinoCoreGamelaunchService: any;
    allowAttributes: string;
    isMobileView: boolean;
    iconsNewPosition: boolean;
    isGameCloseTracked: boolean = false;
    enableEpcotSubNavigation: boolean;
    rpGameClickedEGV: boolean = false;
    titlePopout: string = '';
    titleFullScreen: string = '';
    titleExitFullScreen: string = '';
    egvOverlayFlag: boolean = false;
    cozyBingoIframe: any;

    constructor(
        private user: UserService,
        private recentGameService: RecentGamesService,
        private favouriteService: FavouriteService,
        private fastLoadingGamesService: FastLoadingGamesService,
        private gameDataManager: GameDataManager,
        @Inject(DOCUMENT) private document: any,
        private api: PlatformApiService,
        private jackpotService: JackpotService,
        private messageQueue: MessageQueueService,
        private dialog: MatDialog,
        private balanceService: BalancePropertiesService,
        private cookie: CookieService,
        private navigationService: NavigationService,
        private tracking: TrackingService,
        private location: Location,
        private unfinishedGamesService: UnfinishedGamesService,
        private gameLaunchFactoryService: CasinoCoreGameLaunchFactoryService,
        private loginDialogService: OpenLoginDialog,
        private commonMessagesObject: CommonMessages,
        private casinoManager: CasinoManager,
        private jackpotDataService: JackpotDataService,
        private m2Page: Page,
        private route: ActivatedRoute,
        private casinoWidgetService: CasinoWidgetService,
        private productHomepagesConfig: ProductHomepagesConfig,
        private freespinSummaryService: FreespinSummaryService,
        private cashierService: CashierService,
        private readonly configProviderService: ConfigProviderService,
        private casinoLobbyService: CasinoLobbyService,
        private advanceGameDataService: AdvanceGameDataService,
        private slotRacesService: SlotRacesService,
        private apiPathsHelperService: ApiPathsHelperService,
        private deviceService: DeviceService,
        private readonly windowRef: WindowRef,
        private gameLaunchTrackingService: GameLaunchTrackingService,
        private invokerProductService: InvokerProductService,
        private logger: Logger,
    ) {
        this.unsubscribe = new Subject();
    }

    ngOnInit() {
        this.casinoCoreGamelaunchService = this.gameLaunchFactoryService.getGameLaunchService();
        this.gameConfig = this.configProviderService.provideGameConfig();
        this.allowAttributes = this.gameConfig.iframeAllowattributes;
        this.Game = this.gameEmbedPop.game;
        this.iframeUrl = this.gameEmbedPop.iframeUrl;
        this.cozyBingoIframe = document.getElementsByClassName('cozy-bingo-iFrame').length
            ? (document.getElementsByClassName('cozy-bingo-iFrame')[0] as HTMLIFrameElement)
            : null;
        this.category = this.gameEmbedPop.categoryType;
        this.setGameTitle();
        const features = this.configProviderService.provideFeaturesConfig();
        this.Global = this.configProviderService.provideGlobalConfig();
        this.globalsearchconfig = this.configProviderService.provideGlobalSearchConfig();
        this.GamesyouMightLikeMessage = this.Global.messages['EmbeddedYouMightLike']
            ? this.Global.messages['EmbeddedYouMightLike']
            : this.Global.messages['GamesYouMightLike'];
        this.isTouch = this.casinoManager.isTouch();
        this.iconsNewPosition = features.enableNewEmbeddedViewIconsPosition;
        const product = this.casinoManager.getProductForLobbyType(this.lobbyType);
        const homeUrl = this.route.snapshot.queryParamMap.get('widgetHomeUrl');
        if (this.Global.messages['TooltipPopOut']) this.titlePopout = this.Global.messages['TooltipPopOut'];
        if (this.Global.messages['TooltipFullScreen']) this.titleFullScreen = this.Global.messages['TooltipFullScreen'];
        if (this.Global.messages['TooltipExitFullScreen']) this.titleExitFullScreen = this.Global.messages['TooltipExitFullScreen'];
        if (homeUrl && homeUrl.length > 0) {
            this.casinoManager.setGameHomeUrl(homeUrl);
        }

        if (features.checkQDAllowedForDepositURL) {
            this.cashierService.getQuickDepositAllowedStatus().subscribe((response) => {
                this.isQDAllowed = response.isQuickDepositAllowed;
                this.depositButtonString = this.isQDAllowed ? this.Global.messages['QuickDeposit'] : this.Global.messages['Deposit'];
            });
        }

        this.teaserLaunchRealtiveUri = '/' + product + '/launchng/';
        this.messageQueue.clear({ clearPersistent: true });
        this.commonMessages = this.commonMessagesObject;
        if (!this.lobbyType) {
            this.lobbyType = this.casinoManager.getLobbyType();
        }
        this.useFavouriteGames = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.favouriteGamesLobbyType);
        this.recommendationFeed(this.Game.gameId);
        const nonCriticalConfig: any = this.configProviderService.provideNonCriticalConfig();
        if (this.user.isAuthenticated && !nonCriticalConfig.enableRecommendationGames) {
            this.getGamesYouMightLikeOnLMT();
        }

        const recentGamesString = this.recentGameService.getrecentGames();
        this.assignRecentlyPlayedGames(recentGamesString);
        this.recentObs = this.recentGameService.recentGamesFeedObservable.subscribe((response: any) => {
            if (response) {
                this.assignRecentlyPlayedGames(response);
            }
        });
        this.casinoLobbyService.lobbyLoadedObservable.subscribe((response: any) => {
            if (response) {
                this.setGameTitle();
            }
        });

        this.RecentlyPlayedNGamesUMightLike = this.casinoLobbyService.getCategoryInfo(this.lobbyType, ConstService.RECENT_GAMES_CATEGORY_ID);
        this.favouriteService.favouriteGamesFeedObservable.subscribe((favouriteGameIds) => {
            if (favouriteGameIds) {
                this.Game.isFavourite = favouriteGameIds.indexOf(this.Game.gameId) > -1;
            }
        });
        this.fastLoadingGamesService.fastLoadingGamesFeedObservable.subscribe((fastLoadingGames: any) => {
            this.fastLoadingGamesService.setFastLoadingGames(this.RecentlyPlayedGamesList, fastLoadingGames);
            this.fastLoadingGamesService.setFastLoadingGames(this.gamesUMightLikeList, fastLoadingGames);
        });

        if (
            this.Global.lobbyConfigurations.FavouriteHeart === 'true' ||
            this.Global.lobbyConfigurations.FavouriteHeart === 'True' ||
            this.Global.lobbyConfigurations.FavouriteHeart === 'TRUE'
        ) {
            this.favouriteStyle = 'theme-heart-solid';
            this.unFavouriteStyle = 'theme-heart-outline';
        }
        this.isLoaderHide = false;
        this.depositButtonString = this.isQDAllowed ? this.Global.messages['QuickDeposit'] : this.Global.messages['Deposit'];
        this.maximizeFullScreen();
        this.gameLaunchSuccess = true;
        if (features.useSingleCookieForGameIndexCountInEGV) {
            this.multipleGameLaunched = this.getFromCookie('gameIndexCount');
        } else {
            this.multipleGameLaunched = this.getFromCookie('gameLaunchedCount');
        }
        if (this.multipleGameLaunched) {
            this.gameLaunchSuccess = false;
            if (!this.iconsNewPosition) {
                const message = this.Global.messages['OnlyOneGameAtTime'];
                if (message) {
                    this.messageQueue.addError(message, MessageLifetime.Single, 'embeddedGameError');
                }
            } else {
                this.sendDialogCloseEventToEmbeddedGamePopUp.emit(this.Game);
                return;
            }
        } else {
            if (!this.iframeUrl && this.errorMessageQueue) {
                this.gameLaunchSuccess = false;
                this.messageQueue.add(this.errorMessageQueue);
            }
            const subnavConfig: any = this.configProviderService.provideSubNavigationConfig();
            this.enableEpcotSubNavigation = subnavConfig.enableEpcotSubNavigation;
        }

        this.date = new Date();
        this.Favorites = false;

        //emit event to stop schedule polling in bingo
        this.stopSchedulePollingInBingo(true, true);
    }

    ngAfterViewInit() {
        document.getElementById('embedGameIframe')?.setAttribute('allow', this.allowAttributes);
        const featuresConfig = this.configProviderService.provideFeaturesConfig();
        if (featuresConfig.enableOverlayMobileSearchTopPosition && this.deviceService.isTouch) {
            this.embeddedGameOverlayTopAllignment();
        }
    }
    recommendationFeed(gameid: string) {
        //when user logged into account,then hit recomendation feed
        const nonCriticalConfig: any = this.configProviderService.provideNonCriticalConfig();
        if (this.user.isAuthenticated && nonCriticalConfig.enableRecommendationGames) {
            //Url pattern creation
            const apiPathsConfig = this.configProviderService.provideApiPathsConfig();

            const recommendationUrl = this.apiPathsHelperService.transformApi(apiPathsConfig.gameData['gameRecommendations'], gameid);
            //api call
            this.api.get(`/games/api/${recommendationUrl}`).subscribe((response: any) => {
                if (response) {
                    //response
                    this.recommendationGames = response.recommendationgames;
                    //calling function
                    this.getGamesYouMightLikeOnLMT();
                }
            });
        }
    }
    setGameTitle() {
        const lmtGameMetaData: any = this.casinoLobbyService.getGameInfo(this.Game.gameId);
        if (this.Game && this.Game.name) {
            this.Game.title = this.Game.name;
        } else if (lmtGameMetaData) {
            this.Game.title = lmtGameMetaData.name;
        }
    }

    setToCookie(key: any, value: any) {
        this.cookie.put(key, value);
    }
    getFromCookie(key: any): boolean {
        if (this.cookie.get(key) === '1') {
            return true;
        }
        return false;
    }
    GetParam(name: any, url: any) {
        const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(url);
        if (!results) {
            return 0;
        }
        return results[1] || 0;
    }

    /*
  Author : Naresh Tatikonda
  Purpose : preparing  recommendation games array based on conditions
  */

    getGamesYouMightLikeOnLMT() {
        /*
        condution checks,when recommendationsgames length <6 ,then merging recommendation games and gamesyoumightlike games object
        */
        if (this.recommendationGames.length < 6 || this.recommendationGames.length == 0) {
            const disabledGames: any = this.casinoLobbyService.getDisabledGames();
            const gamesYouMightLikeDataSet = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
            if (gamesYouMightLikeDataSet && gamesYouMightLikeDataSet.categoryInfo && gamesYouMightLikeDataSet.categoryInfo.gamelist.length > 0) {
                const gamesModellist = gamesYouMightLikeDataSet.categoryInfo.gamelist;
                for (let i = 0; i < gamesModellist.length; i++) {
                    const gameModelItem: any = gamesModellist[i];
                    const filteredGame: any = disabledGames.filter((disabledGame: any) => disabledGame === gameModelItem.game);
                    if (!(filteredGame && filteredGame.length > 0)) {
                        gameModelItem.icon = '1';
                        const gamelobbyModel: GameLobbyModel = this.gameDataManager.setGameLobbyModel(gameModelItem, this.isTouch);
                        gamelobbyModel.jackpotvalue = this.getJackpotValueForGame(gamelobbyModel);
                        if (this.gamesUMightLikeList.length > 0) {
                            if (!this.gamesUMightLikeList.find((game: any) => game.gameId === gamelobbyModel.gameId)) {
                                this.gamesUMightLikeList.push(gamelobbyModel);
                            }
                        } else {
                            this.gamesUMightLikeList.push(gamelobbyModel);
                        }
                    }
                }
            }
            this.assignRecommendationGamesList(this.recommendationGames);
            //setting gamesyoumight like games to fastloading games
            this.fastLoadingGamesService.fastLoadingGamesFeedObservable.subscribe((fastLoadingGames: any) => {
                this.fastLoadingGamesService.setFastLoadingGames(this.gamesUMightLikeList, fastLoadingGames);
            });
            this.combineData = this.recommendationGamesList.concat(this.gamesUMightLikeList);
            //filter for remove duplicate objects from array object
            this.recommendationGamesList = Array.from(this.combineData.reduce((m: any, t: any) => m.set(t.gameId, t), new Map()).values());
        } else {
            //here recommendation games count >=6
            this.recommendationGamesList = this.assignRecommendationGamesList(this.recommendationGames);
        }
    }

    /*
      Author : Naresh Tatikonda
      Purpose : preparing  recommendation games array from list of string
      */
    assignRecommendationGamesList(gameslist: string[]) {
        const recommendationGamesData = this.assignGames(gameslist);
        this.recommendationGamesList = [];
        recommendationGamesData.forEach((game: any) => {
            this.recommendationGamesList.push(this.getJackpotValueForGame(game));
        });
        if (this.recommendationGamesList && this.recommendationGamesList.length > 0) {
            this.recommendationGamesList = this.recommendationGamesList.filter((item: any) => {
                return !item.isDataNotAvailable;
            });
            //setting gamesyoumight like games to fastloading games
            this.fastLoadingGamesService.fastLoadingGamesFeedObservable.subscribe((fastLoadingGames: any) => {
                this.fastLoadingGamesService.setFastLoadingGames(this.recommendationGamesList, fastLoadingGames);
            });
            return this.recommendationGamesList;
        }
    }

    assignRecentlyPlayedGames(gameslist: string[]) {
        const unfinishedGames = this.unfinishedGamesService.getUnfinishedGames();
        if (gameslist && gameslist.length > 0) {
            gameslist = gameslist.filter((a) => unfinishedGames.indexOf(a) === -1);
        }
        const recentandUnfinishedGames = unfinishedGames.concat(gameslist);
        const RecentlyPlayedGameData = this.assignGames(recentandUnfinishedGames);
        this.RecentlyPlayedGamesList = [];
        RecentlyPlayedGameData.forEach((game: any) => {
            this.RecentlyPlayedGamesList.push(this.getJackpotValueForGame(game));
        });
        if (this.RecentlyPlayedGamesList && this.RecentlyPlayedGamesList.length > 0) {
            this.RecentlyPlayedGamesList = this.RecentlyPlayedGamesList.filter((item: any) => {
                return !item.isDataNotAvailable;
            });
        }
    }

    assignGamesYouMightLike(gameslist: any[]) {
        const GYMLGameData = this.assignGames(gameslist);
        this.gamesUMightLikeList = [];
        if (GYMLGameData.length > 0) {
            GYMLGameData.forEach((games: any) => {
                if (!this.gamesUMightLikeList.find((game: any) => game.gameId === games.gameId)) {
                    this.gamesUMightLikeList.push(this.getJackpotValueForGame(games));
                }
            });
        }
    }

    assignGames(gameslist: any): any {
        const gamesData: any = [];
        const defaultGamesModel: any = this.gameDataManager.setDefaultGameIcons(gameslist);
        for (let i = 0; i < defaultGamesModel.length; i++) {
            const gameInfo: any = this.casinoLobbyService.getGameInfo(defaultGamesModel[i].game);
            if (gameInfo) {
                const gamelobbyModel: GameLobbyModel = this.gameDataManager.setGameLobbyModel(gameInfo, this.isTouch);
                gamelobbyModel.isUnfinished = this.getUnfinishedStatus(gamelobbyModel.gameId);
                gamelobbyModel.jackpotvalue = this.jackpotDataService.getGameJackpotValue(gamelobbyModel.gameId);
                gamesData.push(gamelobbyModel);
            }
        }
        return gamesData;
    }

    getUnfinishedStatus(gameId: string): boolean {
        const unfinishedGamesArray: any = this.unfinishedGamesService.getUnfinishedGames();
        return unfinishedGamesArray.indexOf(gameId) != -1;
    }

    //Assign Jackpot Stickers to game
    getJackpotValueForGame(game: any) {
        this.jackpotsFeed = this.jackpotService.getJackpotData();
        if (this.jackpotsFeed) {
            for (const key in this.jackpotsFeed) {
                if (this.jackpotsFeed[key].gamesAvailable.indexOf(game.gameId) !== -1) {
                    game.jackpotValue = this.jackpotsFeed[key].amount;
                    return game;
                }
            }
        }
        return game;
    }

    refreshJackpots() {
        this.jackpotsFeed = this.jackpotService.getJackpotData();
        if (this.jackpotsFeed) {
            for (const key in this.jackpotsFeed) {
                this.RecentlyPlayedGamesList.forEach((g: any) => {
                    if (this.jackpotsFeed[key].gamesAvailable.indexOf(g.gameId) !== -1) {
                        g.jackpotValue = this.jackpotsFeed[key].amount;
                    }
                });

                this.gamesUMightLikeList.forEach((g: any) => {
                    if (this.jackpotsFeed[key].gamesAvailable.indexOf(g.gameId) !== -1) {
                        g.jackpotValue = this.jackpotsFeed[key].amount;
                    }
                });
            }
        }
    }

    handleScreenChange(event: any) {
        this.maximizeFullScreen();
        event.stopImmediatePropagation();
    }

    @HostListener('window:message', ['$event']) closeOnRCPUClose(event: any) {
        if (event?.data?.gamechange === 'GAME_CHANGED') {
            this.gameCloseAckEventReceived = true;
            this.summaryPopUpIsOpened = false;
            this.updateGameInfo(event?.data?.gamevariantname);
            this.close(false, false, true, false, false);
        }
        if (event?.data?.type === 'GAME_CHANGED_MINIGAME') {
            this.gameCloseAckEventReceived = true;
            this.summaryPopUpIsOpened = false;
            this.updateGameInfo(event?.data?.data);
            this.close(false, false, true, false, false);
        }
        if ((event.data === 'CASINO_GAME_CLOSED' || event.data === 'CASINO_GAME_EXIT') && !this.rpGameClickedEGV) {
            this.logger.info('[Casino Portal]', '[EGV]', '[Event Handler]', '[CASINO_GAME_CLOSED]', this.get_formatted_timestamp());
            this.gameCloseAckEventReceived = true;
            this.summaryPopUpIsOpened = false;
            this.close(false);
        }
        if (this.gameConfig.enableArcPlayBreakEvent && event.data === 'ARC_CASINO_GAME_CLOSE') {
            this.gameCloseAckEventReceived = true;
            this.summaryPopUpIsOpened = false;
            this.logger.info('Received by Casino_Portal' + 'ARC_CASINO_GAME_CLOSE');
            this.close(false);
        }
        if (event.data === 'PREVIOUS_CASINO_GAME_CLOSED_ACK' && !this.gameclickedfromsearch) {
            this.gameCloseAckEventReceived = true;
            this.summaryPopUpIsOpened = false;
            this.logger.info('Received by Casino_Portal' + 'PREVIOUS_CASINO_GAME_CLOSED_ACK');
            this.close(true, false, false);
        }
        //Portugal game close changes
        if (event.data === 'PREVIOUS_CASINO_GAME_CLOSED_ACK' && this.gameConfig.isGameCloseAckEventEnabled) {
            this.gameCloseAckEventReceived = true;
            this.summaryPopUpIsOpened = false;
            this.logger.info('Received by Casino_Portal' + 'PREVIOUS_CASINO_GAME_CLOSED_ACK');
            if (this.gameclickedfromsearch) {
                this.gameclickedfromsearch = false;
                this.gameClickedProcessing(this.game, this.index, this.section);
            } else if (this.popUpClicked) {
                this.popUpClicked = false;
                this.popUpClickedProcessing();
            }
        }
        if (event.data === 'CASINOGAME_IDLE_LOGGED_OUT') {
            this.logger.info('[Casino Portal]', '[EGV]', '[Event Handler]', '[CASINOGAME_IDLE_LOGGED_OUT]', this.get_formatted_timestamp());
            this.gameCloseAckEventReceived = true;
            this.summaryPopUpIsOpened = false;
            this.close(true, true);
        }
        if (event.data === 'CASINO_GAME_STARTED') {
            this.rpGameClickedEGV = false;
            this.logger.info('Received by Casino_Portal' + 'CASINO_GAME_STARTED');
            if (this.gameConfig.enableGamestartCookie) this.setToCookie('gameInProgress', 1);
        }
        if (this.gameConfig.egvOverlayFlag) {
            if (event.data === 'EGV_SHOW_OVERLAY') {
                this.egvOverlayFlag = true;
            } else if (event.data === 'EGV_REMOVE_OVERLAY') {
                this.egvOverlayFlag = false;
            }
        }
    }

    updateGameInfo(gameId: string) {
        const gameMetaData: any = this.casinoLobbyService.getGameMetaData()?.get(gameId);
        if (gameMetaData) {
            const returnUrl: string = this.Game.returnUrl;
            this.Game = this.gameDataManager.setGameLobbyModel(gameMetaData, false);
            this.setGameTitle();
            this.Game.returnUrl = returnUrl;
            this.Game.isFavourite = this.favouriteService.checkForFavouriteGame(this.Game);
            const gameModel: any = {};
            gameModel.game = this.Game;
            gameModel.game.lobbyType = gameModel?.game?.lobbyType ? gameModel.game.lobbyType : this.lobbyType;
            this.casinoCoreGamelaunchService.getDesktopGameLaunchUrl(gameModel, (callbackResponse: any) => {
                setTimeout(() => {
                    this.iframeUrl = callbackResponse.iframeUrl;
                });
            });
        }
    }

    @HostListener('window:unload', [])
    unloadHandler() {
        this.cookie.remove('gameLaunchedCount');
        this.setToCookie('gameIndexCount', 0);
        this.cookie.remove('gameInProgress');
    }

    @HostListener('document:fullscreenchange', ['$event']) fullscreenchangeHandler(event: KeyboardEvent) {
        this.isFullScreen = this.document['webkitIsFullScreen'];
        this.handleScreenChange(event);
    }
    @HostListener('document:webkitfullscreenchange', ['$event']) webkitfullscreenchangeHandler(event: KeyboardEvent) {
        this.isFullScreen = this.document['webkitIsFullScreen'];
        this.handleScreenChange(event);
    }
    @HostListener('document:mozfullscreenchange', ['$event']) mozfullscreenchangeHandler(event: KeyboardEvent) {
        const doc: any = document;
        this.isFullScreen = doc.mozFullScreen;
        this.handleScreenChange(event);
    }
    @HostListener('document:MSFullscreenChange', ['$event']) MSFullscreenChangeHandler(event: KeyboardEvent) {
        this.isFullScreen = this.document['webkitIsFullScreen'];
        this.handleScreenChange(event);
    }

    ngDoCheck() {
        this.date = new Date();
    }
    close(
        fromEmbedded: boolean,
        skipRefresh: boolean = false,
        triggerRecentGameCall: boolean = true,
        closeGamePopUp: boolean = true,
        gameExited: boolean = true,
    ) {
        if (!this.summaryPopUpIsOpened) {
            dispatchEvent(new CustomEvent('portal_chat_reconnect'));
            this.windowRef.nativeWindow['gamification_initialized'] = false;
            this.slotRacesService.gameClose(this.category);
            this.emitVideoStatus();
            const freespinsConfig = this.configProviderService.providefreeSpinsClientConfig();
            const mcPage = this.configProviderService.provideMcPageConfig();
            const widgetPosition = this.casinoWidgetService.getWidgetPosition();
            const launchCategory = this.route.snapshot.queryParamMap.get('launchCategory');
            //let nav = this.Navigator;
            this.cookie.remove('PCGameData');
            this.messageQueue.clear({ clearPersistent: true });
            if (fromEmbedded && !this.getFromCookie('gamePopOutWindow')) {
                this.cookie.remove('gameLaunchedCount');
                this.setToCookie('gameIndexCount', 0);
                this.cookie.remove('gameInProgress');
            }
            if (fromEmbedded) {
                this.gameclickedfromsearch = false;
            }
            if (!skipRefresh) {
                this.balanceService.refresh();
                const apiPathsConfig = this.configProviderService.provideApiPathsConfig();

                this.api.get(apiPathsConfig.ping['pgKeepAlive'], null, { withCredentials: true }).subscribe(() => {});
            }
            // As per Zlatko, this should be removed Vanilla 8 onwards.
            if (gameExited) {
                const iframe = document.getElementById('embedGameIframe') as HTMLIFrameElement;
                if (iframe && iframe.contentWindow) {
                    iframe.contentWindow.postMessage('PREVIOUS_CASINO_GAME_CLOSED', '*'); //ask Integration to set cookie to 0 on receipt of this message
                    this.logger.info('Sent from Casino_Portal' + 'PREVIOUS_CASINO_GAME_CLOSED');
                    this.setToCookie('gameIndexCount', 0);
                }
            }
            const isWidget = this.route.snapshot.queryParamMap.get('widgetGameLaunch');
            if (this.gameConfig.isGameCloseApiEnabled) {
                this.casinoCoreGamelaunchService
                    .closeGame(this.Game.gameId)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(
                        (response: any) => {
                            if (response) {
                                this.gameCloseAction(closeGamePopUp);
                            }
                        },
                        () => {
                            this.gameCloseAction(closeGamePopUp);
                        },
                    );
            } else if (this.gameConfig.isGameCloseAckEventEnabled && !this.gameCloseAckEventReceived && this.gameLaunchSuccess) {
                this.summaryPopUpIsOpened = true;
                return;
            } else {
                setTimeout(() => {
                    this.gameCloseAction(closeGamePopUp);
                }, this.gameConfig.gameCloseDelay);
            }

            if (isWidget) {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': widgetPosition ? 'X-Sell Casino ' + widgetPosition : 'X-Sell - Sports Widget',
                    'component.LabelEvent': widgetPosition ? 'X-Sell Casino ' + widgetPosition + '|' + launchCategory : 'X-Sell - Sports Widget',
                    'component.ActionEvent': 'Game Exit',
                    'component.LocationEvent': widgetPosition ? 'X-Sell Casino ' + widgetPosition : 'X-Sell Widget',
                    'component.EventDetails': widgetPosition
                        ? 'User closes the casino game and is taken back to sports lobby'
                        : 'User exits the game and is returned back to sports page',
                    'component.URLClicked': mcPage.invokerProductHomePage.url,
                });
            }
            if (!this.isGameCloseTracked) {
                this.tracking.triggerEvent('gameClose', {
                    'component.gameName': this.Game.gameId,
                    'component.gameType': this.getGameLunchType(this.Game.gameId),
                    'component.gameDisplayName': this.casinoLobbyService.getGameDisplayName(this.Game.gameId),
                });
                this.isGameCloseTracked = true;
            }

            //To update unfinished game stickers.
            if (!skipRefresh) {
                this.unfinishedGamesService.setUnfinishedGames();
                if (triggerRecentGameCall) {
                    this.recentGameService.setRecentGames();
                }
            }
            if (freespinsConfig.isFreeSpinSummaryEnabled) {
                this.freespinSummaryService.setFreespinsummary();
            }
            if (this.gameConfig.enableRefreshonGameClose) {
                this.casinoCoreGamelaunchService.playerPlayBreakStatus();
            }
        }
    }

    emitVideoStatus() {
        if (!this.getFromCookie('gamePopOutWindow')) this.casinoCoreGamelaunchService?.stopAudio();
    }

    gameCloseAction(closeGamePopUp: boolean) {
        const globalJackpotClientConfig = this.configProviderService.provideGlobalJackpotConfig();
        document.body.classList.remove('embed-hide-scroll');
        if (closeGamePopUp) this.sendDataToEmbeddedGamePopUp.emit(this.Game);
        if (this.location.path().indexOf('forcedEmbeddedLaunch') > -1) {
            if (this.casinoManager?.getProductForLobbyType(this.lobbyType)?.toLowerCase() == 'globaljackpot') {
                this.navigationService.goTo('/' + this.m2Page.lang + '/g/' + globalJackpotClientConfig?.globalJackpotPathSegments?.toLowerCase());
            } else {
                this.navigationService.goTo('/' + this.m2Page.lang + '/' + this.casinoManager?.getProductForLobbyType(this.lobbyType)?.toLowerCase());
            }
        }
    }

    isCasinoDomain(gameHomeUrl: string): boolean {
        if (!gameHomeUrl) {
            //gameHomeUrl is not set in casino domain
            return true;
        }
        if (gameHomeUrl && gameHomeUrl != '' && gameHomeUrl.indexOf(this.productHomepagesConfig.casino) === 0) {
            return true;
        }
        return false;
    }

    urlParser(href: any) {
        const l = document.createElement('a');
        l.href = href;
        return l;
    }

    addFavourate(game: any, $event: any) {
        if (this.user.isAuthenticated) {
            $event.stopImmediatePropagation();
            this.favouriteService.toggleGameFavourite(game, false, true);
        } else {
            this.loginDialogService.open({ loginMessageKey: 'Favourite' });
        }
        if (this.Game.isFavourite) {
            this.tracking.triggerEvent('GameInteraction', {
                'component.Game.interaction': 'Remove_Game_Favorites_EGV',
            });
        } else {
            this.tracking.triggerEvent('GameInteraction', {
                'component.Game.interaction': 'Add_Game_Favorites_EGV',
            });
        }
    }

    openNav() {
        this.isIframeVisible = true;
        this.isGreaterthan = true;
        document.getElementById('mySidenav')!.style.width = '100%';
        document.getElementById('mySidenav')!.style.position = 'fixed';
    }
    closeNav() {
        this.isIframeVisible = false;
        this.isGreaterthan = false;
        document.getElementById('mySidenav')!.style.width = '100%';
        document.getElementById('mySidenav')!.style.position = 'inherit';
    }
    fullScreen() {
        if (this.checkFullScreen()) {
            this.adjustGamesPanel();
            this.requestFullScreen();
        } else {
            this.minimizeFullScreen();
        }
    }

    minimizeFullScreen() {
        this.isIframeVisible = false;
        document.getElementById('mySidenav')!.style.width = '100%';
        document.getElementById('mySidenav')!.style.position = 'inherit';
        this.isFullScreen = false;
        if (this.document.cancelFullScreen) {
            this.document.cancelFullScreen();
        } else if (this.document.mozCancelFullScreen) {
            this.document.mozCancelFullScreen();
        } else if (this.document.webkitCancelFullScreen) {
            this.document.webkitCancelFullScreen();
        } else if (this.document.msExitFullscreen) {
            this.document.msExitFullscreen();
        }
    }

    adjustGamesPanel() {
        document.getElementById('mySidenav')!.style.width = '100%';
        document.getElementById('mySidenav')!.style.position = 'fixed';
        this.isIframeVisible = true;
        this.isFullScreen = true;
        if (this.isFullScreen) {
            this.isGreaterthan = false;
        }
    }

    checkFullScreen(): boolean {
        return (
            (this.document.fullScreenElement !== undefined && this.document.fullScreenElement === null) ||
            (this.document.msFullscreenElement !== undefined && this.document.msFullscreenElement === null) ||
            (this.document.mozFullScreen !== undefined && !this.document.mozFullScreen) ||
            (this.document.webkitIsFullScreen !== undefined && !this.document.webkitIsFullScreen)
        );
    }

    requestFullScreen() {
        if (this.document.body.requestFullScreen) {
            this.document.body.requestFullScreen();
        } else if (this.document.body.mozRequestFullScreen) {
            this.document.body.mozRequestFullScreen();
        } else if (this.document.body.webkitRequestFullScreen) {
            this.document.querySelector('body').webkitRequestFullScreen();
        } else if (this.document.body.msRequestFullscreen) {
            this.document.body.msRequestFullscreen();
        }
    }

    toggleFullWindowScreen() {
        if (!this.document['webkitIsFullScreen']) {
            this.allowFullScreen();
        } else {
            this.cancelFullScreen();
        }
    }

    allowFullScreen() {
        const doc: any = document;
        if (this.document.body.requestFullScreen) {
            this.document.body.requestFullScreen();
        } else if (doc.body.mozRequestFullScreen) {
            const docElm: any = document.documentElement;
            docElm.mozRequestFullScreen();
        } else if (this.document.body.webkitRequestFullScreen) {
            this.document.querySelector('body').webkitRequestFullScreen();
        } else if (this.document.body.msRequestFullscreen) {
            this.document.body.msRequestFullscreen();
        }
    }
    cancelFullScreen() {
        if (this.document.cancelFullScreen) {
            this.document.cancelFullScreen();
        } else if (this.document.mozCancelFullScreen) {
            this.document.mozCancelFullScreen();
        } else if (this.document.webkitCancelFullScreen) {
            this.document.webkitCancelFullScreen();
        } else if (this.document.msExitFullscreen) {
            this.document.msExitFullscreen();
        }
    }

    toggleFullScreen(event: any) {
        if (!this.summaryPopUpIsOpened) {
            this.tracking.triggerEvent('GameInteraction', {
                'component.Game.interaction': 'EGV_PlayGame_FullScreen',
            });
            document.getElementsByClassName('embed-left-section')[0].setAttribute('style', 'width:100%');
            if (
                (this.document.fullScreenElement !== undefined && this.document.fullScreenElement === null) ||
                (this.document.msFullscreenElement !== undefined && this.document.msFullscreenElement === null) ||
                (this.document.mozFullScreen !== undefined && !this.document.mozFullScreen) ||
                (this.document.webkitIsFullScreen !== undefined && !this.document.webkitIsFullScreen)
            ) {
                this.iconShrinkClickedFirst = false;
                if (this.document.body.requestFullScreen) {
                    this.document.body.requestFullScreen();
                } else if (this.document.body.mozRequestFullScreen) {
                    this.document.body.mozRequestFullScreen();
                } else if (this.document.body.webkitRequestFullScreen) {
                    this.document.querySelector('body').webkitRequestFullScreen();
                } else if (this.document.body.msRequestFullscreen) {
                    this.document.body.msRequestFullscreen();
                }
                this.maximizeFullScreen();
            } else {
                if (event.target.className === 'icon-shrink') {
                    this.iconShrinkClickedFirst = true;
                }

                if (this.document.cancelFullScreen) {
                    this.document.cancelFullScreen();
                } else if (this.document.mozCancelFullScreen) {
                    this.document.mozCancelFullScreen();
                } else if (this.document.webkitCancelFullScreen) {
                    this.document.webkitCancelFullScreen();
                } else if (this.document.msExitFullscreen) {
                    this.document.msExitFullscreen();
                }
                this.maximizeFullScreen();
            }
        }
    }

    maximizeFullScreen() {
        setTimeout(() => {
            if (document.getElementById('casinoEmbeddedModal')) {
                document.getElementById('casinoEmbeddedModal')!.style.height = window.screen.height + 'px';
            }
        }, 200);
    }

    toggleExtrasColumn() {
        this.extrasColumnFlag = !this.extrasColumnFlag;
        document.getElementsByClassName('embed-left-section')[0].setAttribute('style', 'width:100%');
    }

    getGameLunchType(gameId: any) {
        const gameInfo: any = this.casinoLobbyService.getGameInfo(gameId);
        if (gameInfo) {
            return gameInfo.provider;
        }
        return null;
    }

    gameClickedOnSearch(game: any, index: number, section: string) {
        if (section == 'recentgames' || section == 'Search' || section == 'GYML') {
            this.rpGameClickedEGV = true;
        }
        this.gameclickedfromsearch = true;
        const iframe = document.getElementById('embedGameIframe') as HTMLIFrameElement;
        if (iframe && iframe.contentWindow) {
            iframe.contentWindow.postMessage('PREVIOUS_CASINO_GAME_CLOSED', '*');
            this.logger.info('Sent from Casino_Portal on gameClickedOnSearch' + 'PREVIOUS_CASINO_GAME_CLOSED');
        }
        if (!this.gameConfig.isGameCloseAckEventEnabled || !this.gameLaunchSuccess) {
            this.gameClickedProcessing(game, index, section);
        } else {
            this.game = game;
            this.index = index;
            this.section = section;
            this.summaryPopUpIsOpened = true;
        }
    }

    gameClickedProcessing(game: any, index: number, section: string) {
        const Global = this.configProviderService.provideGlobalConfig();
        const features = this.configProviderService.provideFeaturesConfig();
        this.gameCloseAckEventReceived = false;
        this.recommendationFeed(game.gameId);
        if (this.getFromCookie('gamePopOutWindow')) {
            const returnUrl: string = this.Game.returnUrl;
            this.Game = game;
            this.Game.returnUrl = returnUrl;

            this.gameLaunchSuccess = false;
            if (!this.iconsNewPosition) {
                const message = Global.messages['OnlyOneGameAtTime'];
                if (message) {
                    this.messageQueue.addError(message, MessageLifetime.Single, 'embeddedGameError');
                }
            }
            return;
        }

        this.messageQueue.clear();
        if (index || section) {
            let gridPos = 1;
            if (this.RecentlyPlayedGamesList && this.RecentlyPlayedGamesList.length > 0) {
                gridPos = 2;
            }
            if (game.GamePosition >= 0) {
                index = game.GamePosition;
            }
            const isSearch: boolean = section == 'Search';
            const lobbyData: any = this.casinoLobbyService.getLobbyBasedOnLobbyType(this.lobbyType);
            const gameTrackingObj: any = {
                name: game.gameId,
                type: game.provider || this.getGameLunchType(game.gameId),
                position: index + 1,
                section: 'egv_' + section,
                gridPosition: (isSearch ? gridPos : 1) + ':' + (index + 1),
                location: `${game?.gameContainer ? game?.gameContainer : 'Page Container'}`,
                displayName: !isSearch && game.name ? game.name : this.casinoLobbyService.getGameDisplayName(game.gameId),
                lobbyName: lobbyData?.lbyTempName ? lobbyData?.lbyTempName : null,
                lobbySource: lobbyData?.lbyTargetType ? lobbyData?.lbyTargetType : null,
                optimoveSegment: lobbyData?.optSegName ? lobbyData?.optSegName : null,
            };
            this.gameLaunchTrackingService.track(gameTrackingObj);
            const gridPosToBePass = section == this.recentGames ? 1 : gridPos;
            game.iconType = 1; // for advance game data model
            this.advanceGameDataService.gameClickedFromSearchAdvanceGameDataModel(game, section, gridPosToBePass, index);
            const gamemodel = new GameLaunch();
            game.lobbyType = game.lobbyType ? game.lobbyType : this.lobbyType;
            gamemodel.game = game;
            gamemodel.isTouch = this.isTouch;
            gamemodel.gamePosition = index;
            gamemodel.gameSection = section;
            gamemodel.clickTimeStamp = this.casinoCoreGamelaunchService.currentTimeStamp();
            //check whether game is present in gamedatamap
            this.messageQueue.clear();
            if (!this.casinoCoreGamelaunchService.checkGameInGameMetaData(gamemodel.game.gameId) && this.user.isAuthenticated) {
                this.casinoCoreGamelaunchService.gameNotFoundAction(gamemodel);
            }
            // check for disabled game
            const disabledGames: Array<string> = this.casinoLobbyService.getDisabledGames();
            if (disabledGames) {
                disabledGames.forEach((element) => {
                    if (element?.toLowerCase() == gamemodel?.game?.gameId?.toLowerCase() && this.user.isAuthenticated) {
                        this.casinoCoreGamelaunchService.gameNotFoundAction(gamemodel);
                    }
                });
            }
            this.casinoCoreGamelaunchService.getDesktopGameLaunchUrl(gamemodel, (callbackResponse: any) => {
                setTimeout(() => {
                    this.gameclickedfromsearch = true;
                    this.iframeUrl = callbackResponse.iframeUrl;
                    if (!features.useSingleCookieForGameIndexCountInEGV) {
                        this.setToCookie('gameLaunchedCount', '1');
                    }
                    this.casinoCoreGamelaunchService.storeUserInfo(gameTrackingObj?.displayName);
                    const returnUrl: string = this.Game.returnUrl;
                    this.Game = game;
                    this.Game.returnUrl = returnUrl;

                    this.setGameTitle();
                    this.gameLaunchSuccess = true;
                    this.recentGameService.setRecentGames();
                }, 400);
            });
        }
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(CashierIframeComponent, {
            width: '790px',
            data: { name: 'cashier' },
            panelClass: 'casino-lobby-container-main',
        });
        const instance = dialogRef.componentInstance;
        instance.isQDAllowed = this.isQDAllowed;

        dialogRef.afterClosed().subscribe(() => {});
    }

    public openRequestedPopup() {
        if (!this.summaryPopUpIsOpened) {
            const features = this.configProviderService.provideFeaturesConfig();
            this.tracking.triggerEvent('GameInteraction', {
                'component.Game.interaction': 'EGV_PlayGame_NewWindow',
            });

            const dialogRef = this.dialog.open(GamePopoutComponent, {
                width: '386px',
                data: { name: 'popOut' },
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result === 'continue') {
                    //emit event to start schedule polling in bingo
                    this.stopSchedulePollingInBingo(true, false);
                    if (!features.useSingleCookieForGameIndexCountInEGV) {
                        this.setToCookie('gameLaunchedCount', '1');
                    } else {
                        this.setToCookie('gameIndexCount', 0);
                        const iframe = document.getElementById('embedGameIframe') as HTMLIFrameElement;
                        if (iframe && iframe.contentWindow) {
                            iframe.contentWindow.postMessage('PREVIOUS_CASINO_GAME_CLOSED', '*');
                            this.logger.info('Sent from Casino_Portal on openRequestedPopup' + 'PREVIOUS_CASINO_GAME_CLOSED');
                        }
                    }
                    if (this.gameConfig.isGameCloseAckEventEnabled) {
                        this.popUpClicked = true;
                        this.summaryPopUpIsOpened = true;
                    } else {
                        this.popUpClickedProcessing();
                    }
                }
            });
        }
    }

    popUpClickedProcessing() {
        const windowOptions: any = 'resizable=no,toolbar=no,scrollbars=no,width=' + this.embededPopUpWidth + ',height=' + this.embededPopUpHeight;
        const gamePopOutWindow: any = window.open(this.iframeUrl, 'GameWindow', windowOptions);

        if (!gamePopOutWindow.closed) this.setToCookie('gamePopOutWindow', '1');

        this.close(true);
        const checkPopOutWindowOpenInterval = setInterval(() => {
            if (gamePopOutWindow.closed) {
                this.cookie.remove('gameLaunchedCount');
                this.cookie.remove('gamePopOutWindow');
                this.emitVideoStatus();
                this.setToCookie('gameIndexCount', 0);
                this.cookie.remove('gameInProgress');
                clearInterval(checkPopOutWindowOpenInterval);
            }
        }, this.gameConfig.gameCloseDelay);
    }

    ngOnDestroy() {
        if (this.recentObs) {
            this.recentObs.unsubscribe();
        }
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }
    embeddedGameOverlayTopAllignment() {
        if (document.querySelector('.content-messages-header-top .content-message-container')) {
            const contentMessageElement: HTMLElement =
                document.querySelector('.content-messages-header-top .content-message-container') || new HTMLElement();
            const egv_overlay: HTMLElement = document.querySelector('.game-container') || new HTMLElement();
            egv_overlay.style.top = contentMessageElement.clientHeight + 'px';
        }
    }

    private get_formatted_timestamp() {
        const dateNow = new Date();
        let datetime = dateNow.toString();
        datetime = datetime.substring(0, datetime.indexOf(' GMT')) + '.' + dateNow.getMilliseconds();
        return datetime;
    }

    stopSchedulePollingInBingo(fromEmbedded: boolean, flag: boolean) {
        if (fromEmbedded && !this.gameCloseAckEventReceived) {
            //for Iframe
            if (this.cozyBingoIframe && this.cozyBingoIframe.contentWindow) {
                this.cozyBingoIframe.contentWindow.postMessage({ stopSchedulePolling: flag }, this.productHomepagesConfig.bingo);
            }
            if (this.invokerProductService?.getInvokerProduct()?.toLowerCase() == 'casino') {
                if (this.cozyBingoIframe) (window as any).Disable_SchedulePolling = flag;
                else (window as any).Disable_SchedulePolling = true;
            } else {
                (window as any).Disable_SchedulePolling = flag;
            }
        }
    }
}
