import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import {
    CasinoCoreGamelaunchService,
    CasinoCoreNpmParamsService,
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    GameLaunch,
    UrlUtilityService,
} from '@casinocore/platform/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Swiper, SwiperOptions } from 'swiper/types';

@Component({
    selector: 'cc-quick-info-more-info',
    templateUrl: 'quick-info-more-info.component.html',
    standalone: true,
    imports: [NgClass, NgIf, NgFor, SwiperComponent],
})
export class QuickInfoMoreInfoComponent implements OnInit {
    @Input() gymlGames: any;
    @Input() gameLaunchedFrom: any;
    @Input() gridPosition: any;
    @Input() gamePosition: number;
    @Input() gameContainer: string;
    @Input() lobbyType: string;
    @ViewChild('moreInfoGYMLCarousel') swiperSlider: SwiperComponent;

    defaultSrc: string;
    elms: number = 3.5;
    hidePreButton: boolean = true;
    hideNextButton: boolean = true;
    lastElementIndex: number;
    isTouch: boolean;
    staticGYML: boolean;
    maxScrollPosition: number;
    relatedGamesText: string;
    position: number = 0;

    swiperOptions: SwiperOptions = {
        autoHeight: true,
        allowTouchMove: false,
        spaceBetween: 10,
        slidesPerView: 3.5,
        observer: true,
        rewind: true,
        slidesPerGroup: 3,
        keyboard: true,
        preventClicks: false,
        preventClicksPropagation: false,
        on: {
            slideChange: (swiper) => this.onIndexChanged(swiper),
        },
    };

    constructor(
        private dialogRef: MatDialogRef<QuickInfoMoreInfoComponent>,
        private casinoManager: CasinoManager,
        private elementRef: ElementRef,
        private urlUtilityService: UrlUtilityService,
        private configProviderService: ConfigProviderService,
        private casinoNpmParamsService: CasinoCoreNpmParamsService,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private casinoLobbyService: CasinoLobbyService,
    ) {}

    ngOnInit(): void {
        this.isTouch = this.casinoManager.isTouch();
        this.defaultSrc = this.urlUtilityService.getGrayImageUrl();
        if (this.gymlGames.length > this.elms) {
            this.hideNextButton = false;
        }
        const global = this.configProviderService.provideGlobalConfig();
        this.relatedGamesText = global.moreInfoConfiguration.RelatedGamesText;
    }

    ngAfterViewInit() {
        if (document.getElementById('gymlId') as HTMLElement) {
            this.maxScrollPosition = (document.getElementById('gymlId') as HTMLElement).offsetLeft;
        }
    }

    action(game: any, from: string, i: any) {
        // for(let j=0;j<this.gymlGames.length;j++) {
        //     if(game.gameId === this.gymlGames ) {
        //         break;
        //     }
        //     this.position++;
        // }
        document.getElementsByTagName('html')[0].classList.remove('casino-quickinfo-open');
        this.dialogRef.close();
        //let gameInfo: any = this.casinoLobbyService.getGameInfo(game);
        //   this.isTouch = this.casinoManager.isTouch();
        //if (gameInfo) {
        // gameInfo.gameId = game
        // }
        const gameModel = new GameLaunch();
        gameModel.game = game;
        gameModel.isTouch = this.isTouch;
        gameModel.game.lobbyType = this.lobbyType;
        gameModel.gamePosition = i;
        gameModel.categoryType = 'LMC_GYML';
        gameModel.from = from;
        gameModel.replaceRouteInHistory = false;
        gameModel.isDemoLaunch = false;
        gameModel.skipUnfinGame = false;
        gameModel.gameLaunchedFrom = this.gameLaunchedFrom;
        gameModel.gridPosition = this.gridPosition;
        gameModel.returnUrl = window.location.href;
        gameModel.gameContainer = game.gameId + '-' + 'More Info Overlay' + '-' + 'More Info tab';
        gameModel.gameSection = 'More Info Overlay';
        gameModel.moreInfoOverlay = true;
        gameModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
        gameModel.appendRUrlToLoginDialogWindow = true;
        this.gameLaunchService.launchGame(gameModel);
    }

    onIndexChanged(swiper: Swiper): void {
        const index = swiper.realIndex;
        this.hidePreButton = index === 0;
        if (this.gymlGames && this.gymlGames.length > 0) {
            this.lastElementIndex = index + this.elms - 1.5;
            this.hideNextButton = this.lastElementIndex === this.gymlGames.length - 1;
        }
    }
    goToPrevSlide() {
        if (this.swiperSlider?.swiper) {
            this.swiperSlider.swiper.slidePrev();
        }
    }
    goToNextSlide() {
        if (this.swiperSlider?.swiper) {
            this.swiperSlider.swiper.slideNext();
            this.hideNextButton = this.lastElementIndex === this.gymlGames.length - 1;
        }
    }

    onGameIconError(game: any): void {
        game.icon.src = this.defaultSrc;
    }

    setSubCategoryStyle() {
        if (this.isTouch) {
            return 'scrollable-games';
        }
        return 'unscrollable-games';
    }

    scrollGYML() {
        if (this.elementRef.nativeElement.getElementsByClassName('scrollable-games')[0].scrollLeft >= this.maxScrollPosition) {
            this.staticGYML = true;
            setTimeout(() => {
                const ele = document.getElementById('sticky-container');
                if (ele) ele.setAttribute('position', 'relative');
            }, 500);
        } else if (this.elementRef.nativeElement.getElementsByClassName('scrollable-games')[0].scrollLeft < this.maxScrollPosition) {
            this.staticGYML = false;
        }
    }
}
