<div
    class="multi-jackpot-rebrand jackpot-widget-view-port {{ multiJackpotAlignment }}"
    *ngIf="showJackpotWidget"
    [ngClass]="{ 'no-bg-transparent': !multiBackgroundTransparency, 'danskespil-arrow-styles': changeCarouselView }"
    [style.background]="glassEffectColor">
    <div class="casino-multi-jackpot">
        <ngu-carousel #myCarousel [inputs]="carouselConfig" [dataSource]="widgets" (onMove)="onmoveFn($event)">
            <ngu-item *nguCarouselDef="let widget; let track = index">
                <div
                    class="jackpot-widget-component"
                    style.background-image="{{
                        widget.jackpotWidgetBackgroundImage?.src ? 'url(' + widget.jackpotWidgetBackgroundImage.src + ')' : ''
                    }}"
                    [ngClass]="classNameTop">
                    <img class="jackpotIcon" [src]="widget.jackpotWidgetImage && widget.jackpotWidgetImage.src" />
                    <div
                        class="jackpotValue"
                        [ngClass]="{ 'jackpot-anim': multiJackpotAmountAnnimation }"
                        [ngStyle]="{ color: multiJackpotValueClr, background: multiJackpotAmountBtnBgClr }"
                        *ngIf="widget.jackpotValue">
                        <!-- <div class="remove-skew">{{widget.jackpotValue}}</div> -->
                        <div *ngIf="games?.length > 0 && games[0].jackpotValue" class="remove-skew">
                            {{ games[0].jackpotValue }}
                        </div>
                        <div *ngIf="games?.length > 0 && !games[0].jackpotValue" class="remove-skew">
                            {{ widget?.jackpotValue }}
                        </div>
                    </div>
                    <div class="jackpotValueText" [innerHTML]="jackpotAmountText" [ngStyle]="{ color: multiJackpotAmountClr }"></div>
                </div>
            </ngu-item>
            <span
                NguCarouselPrev
                *ngIf="!isPreButtonVisible && changeCarouselView"
                class="seeallarrows button-left-multi-changeview-mobile"
                [ngStyle]="{ 'color': subCategoryTextColor, 'font-family': lobbyTileFontFamily }">
                <span class="arrows-left" [ngClass]="themeArrowLeft" [ngStyle]="{ 'pointer-events': 'auto' }" (click)="slideClicked(false)"></span>
            </span>
            <span
                NguCarouselNext
                *ngIf="!isNextButtonVisible && changeCarouselView"
                class="seeallarrows button-right-multi-changeview-mobile"
                [ngStyle]="{ 'color': subCategoryTextColor, 'font-family': lobbyTileFontFamily }">
                <span class="arrows-right" [ngClass]="themeArrowRight" [ngStyle]="{ 'pointer-events': 'auto' }" (click)="slideClicked(true)"></span>
            </span>
            <button
                *ngIf="!changeCarouselView"
                [style]="multiJackpotLeftArrowStyles"
                [style.background-image]="'url(' + (jackpotLeftImg?.src || '') + ')'"
                [ngClass]="{ 'hidden-cls': isPreButtonVisible || changeCarouselView }"
                NguCarouselPrev
                class="theme-left multi-rebrand-left"
                aria-hidden="true"
                (click)="slideClicked(false)"></button>
            <button
                *ngIf="!changeCarouselView"
                [style]="multiJackpotRightArrowStyles"
                [style.background-image]="'url(' + (jackpotRightImg?.src || '') + ')'"
                [ngClass]="{ 'hidden-cls': isNextButtonVisible || changeCarouselView }"
                NguCarouselNext
                class="theme-right multi-rebrand-right"
                aria-hidden="true"
                (click)="slideClicked(true)"></button>
        </ngu-carousel>
        <div
            class="background-for-multijack"
            [style.background-image]="'url(' + (multiJackpotBottomImage?.src || '') + ')'"
            [style]="backgroundImageStyles"></div>
    </div>
    <div class="casino-multijackpot-right-sec" *ngIf="games && games.length > 0">
        <div class="casino-jackpot-games-heading jackpot-right-content">
            <div
                class="casino-jackpot-games-text"
                [ngStyle]="{ color: multiJackpotPlayAnyGameTextColor }"
                [innerHTML]="multiJackpotPlayAnyGameText"></div>
            <div *ngIf="cta && !changeCarouselView" class="casino-jackpot-see-all" [ngClass]="{ 'epcot-btn': enableEpcotSubNavigation }">
                <!-- <div class="btn btn-cta btn-primary see-all-btn" *ngIf="ctaUrl?.url" (click)="gotoJackpots(ctaUrl?.url)">
                    {{ cta }}
                </div> -->
                @if (ctaUrl?.url) {
                    <cs-gametile-cta
                        [isLegacy]="isLegacy"
                        [kind]="'primary'"
                        [size]="'medium'"
                        (click)="gotoJackpots(ctaUrl?.url)"
                        [csDecoratorClass]="'btn-cta see-all-btn'"
                        [cta_text]="cta">
                    </cs-gametile-cta>
                }
            </div>
            <div class="multi-jackpot-seeall" [ngClass]="{ 'multi-jackpot-seeall-mobile': isTouch }" *ngIf="changeCarouselView">
                <div class="casino-seeall-text" *ngIf="ctaUrl?.url" (click)="gotoJackpots(ctaUrl?.url)" [ngStyle]="{ color: subCategoryTextColor }">
                    {{ cta }}
                </div>
            </div>
        </div>
        <div class="casino-jackpot-games">
            <div class="casino-multijackpot-games casino-lobby-width-gametile" [style.overflow-x]="!isTouch ? 'unset' : 'scroll'">
                <div class="casino-carousel-wrapper scrollable-games">
                    <cc-jackpot-widget-redesign-grid
                        *ngIf="games && games.length > 0"
                        [games]="games"
                        [gridCols]="games.length"
                        [categoryObj]="categoryObj"
                        [widgetType]="'MultiJackpot'">
                    </cc-jackpot-widget-redesign-grid>
                </div>
            </div>
        </div>
    </div>
</div>
