/* eslint-disable @nx/enforce-module-boundaries */

/* eslint-disable @angular-eslint/component-max-inline-declarations */
import { ChangeDetectionStrategy, Component, Input, booleanAttribute } from '@angular/core';

import {
    DS_BUTTON_KIND_ARRAY as CS_CTA_KIND_ARRAY,
    DS_BUTTON_SIZES_ARRAY as CS_CTA_SIZES_ARRAY,
    DS_BUTTON_VARIANTS_ARRAY as CS_CTA_VARIANTS_ARRAY,
    DsButtonKind as CsCtaKind,
    DsButtonSize as CsCtaSize,
    DsButtonVariant as CsCtaVariant,
    DsButton,
} from '@frontend/ui/button';

@Component({
    selector: 'cs-cta',
    template: `
        <button
            ds-button
            [kind]="kind"
            [variant]="variant"
            [size]="size"
            [class]="casinoDsClass"
            [class.ds-btn-disabled]="disabled"
            [disabled]="disabled"
            [style]="inlineStyle">
            <ng-content slot="start" select="[slot=start]"></ng-content>
            {{ cta_text }}
            <ng-content slot="end" select="[slot=end]"></ng-content>
        </button>
    `,
    standalone: true,
    imports: [DsButton],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CsCtaComponent implements CsCtaViewModel {
    @Input() size: CsCtaSize = 'large';
    @Input() variant: CsCtaVariant = 'filled';
    @Input() kind: CsCtaKind = 'primary';
    @Input() cta_text = '';
    @Input() casinoDsClass = '';
    @Input() inlineStyle = '';
    @Input({ transform: booleanAttribute }) disabled = false;
}

export { CS_CTA_KIND_ARRAY, CS_CTA_SIZES_ARRAY, CS_CTA_VARIANTS_ARRAY, CsCtaKind, CsCtaSize, CsCtaVariant };

export interface CsCtaViewModel {
    size: CsCtaSize;
    variant: CsCtaVariant;
    kind: CsCtaKind;
    cta_text: string;
    casinoDsClass: string;
    disabled: boolean;
    // Color customisation properties, experimental, start
    inlineStyle: string;
    // Color customisation properties, experimental, end
}
