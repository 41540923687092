import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { NavigationService } from '@frontend/vanilla/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { CasinoLobbyService } from '../casino-lobby-manager/casino-lobby.service';
import { ConfigProviderService } from '../config-provider/config-provider.service';
import { CasinoManager } from '../shared/casino-manager.service';
import { CasinoCoreGamelaunchService } from '../shared/gamelaunch.service';
import { GameLaunch } from '../shared/models/gamelaunch.model';

@Component({
    selector: 'cc-gif-tag',
    templateUrl: 'gif-tag.component.html',
    standalone: true,
    imports: [NgIf, LazyLoadImageModule],
})
export class GifTagComponent implements OnInit {
    @Input() url: string;
    @Input() imageThumbnail: any;
    @Input() type: string;
    @Input() launchUrl: any;
    @Input() subcategory: any;
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    @Input() videoBoxContent: any;

    isTouch: boolean;
    gameObj: any = {};
    imageThumbnailSrc: string;
    videoLaunchRealtiveUri: string;

    constructor(
        private casinoManager: CasinoManager,
        private navigation: NavigationService,
        private casinoCoreGamelaunchService: CasinoCoreGamelaunchService,
        private configProviderService: ConfigProviderService,
        private casinoLobbyService: CasinoLobbyService,
    ) {
        this.isTouch = this.casinoManager.isTouch();
        const mcPageConfig = this.configProviderService.provideMcPageConfig();
        this.videoLaunchRealtiveUri = '/' + mcPageConfig.labelPrefix + '/launchng/';
    }
    ngOnInit() {
        if (this.imageThumbnail && this.imageThumbnail.src) {
            this.imageThumbnailSrc = this.imageThumbnail.src;
        } else {
            this.imageThumbnailSrc = '';
        }
    }
    launchGame() {
        let gameSection: string;
        if (this.type == 'gif') {
            gameSection = 'Netflix_gif';
        } else {
            gameSection = 'Netflix_svg';
        }
        const gameModel = new GameLaunch();
        const gameId: string = this.getGameIdFromLaunchUrl(this.launchUrl.url);
        gameModel.game = this.casinoLobbyService.getGameInfo(gameId);
        gameModel.game.gameId = gameId;
        gameModel.game.lobbyType = this.lobbyType;
        gameModel.isTouch = this.isTouch;
        gameModel.gamePosition = 1;
        gameModel.categoryType = this.subcategory;
        gameModel.from = 'q';
        gameModel.replaceRouteInHistory = false;
        gameModel.isDemoLaunch = false;
        gameModel.skipUnfinGame = false;
        gameModel.gameLaunchedFrom = 'Netflix_Video';
        gameModel.gridPosition = 1;
        gameModel.gameSection = gameSection;
        gameModel.gameContainer = this.gameContainer;
        gameModel.returnUrl = window.location.href;
        gameModel.clickTimeStamp = this.casinoCoreGamelaunchService.currentTimeStamp();
        if (this.launchUrl) {
            if (this.isGameUrl(this.launchUrl.url)) {
                this.casinoCoreGamelaunchService.launchGame(gameModel);
            } else {
                this.navigation.goTo(this.launchUrl?.url);
            }
        }
    }
    getGameIdFromLaunchUrl(link: string) {
        return link.substr(link.indexOf(this.videoLaunchRealtiveUri) + this.videoLaunchRealtiveUri.length);
    }

    isGameUrl(link: string) {
        return link.indexOf(this.videoLaunchRealtiveUri) > 0;
    }
}
