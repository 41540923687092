<div class="casino-lobby-container-main">
    <div *ngIf="!isMobile">
        <div class="casino-moreinfo-overlay" (click)="hideInfo($event)"></div>
        <div class="casino-moreinfo-outer-container">
            <div class="casino-moreinfo-inner-container">
                <div class="theme-close-small moreinfo-close" (click)="hideInfo()"></div>
                <div class="casino-moreinfo-sections">
                    <div class="images-section">
                        <!-- <div class="game-image" [class.casino-qi-desktop-nimage]="!isMobile"> -->
                        <div class="game-image">
                            <img src="{{ mobileQuickInfoSrc }}" (error)="onGameIconError1()" />
                            <div class="moreinfo-relative-container">
                                <div
                                    class="moreinfo-jackpotcta"
                                    *ngIf="jackpotValue && showjackpotvalueinoverlay"
                                    [ngStyle]="{ background: jpRibbonStyles?.jpBgColor, color: jpRibbonStyles?.jpTextColor }"
                                    [ngClass]="{ 'casino-qi-Jackpotcta-pos': demoEnabled }">
                                    <div class="moreinfo-hot-icon" *ngIf="game.isHotJackpotGroup && enableHotIcon">
                                        <img [src]="hotIcon" (error)="onGameIconError(hotIcon)" />
                                    </div>
                                    <div class="jp-value" [ngStyle]="{ color: jpRibbonStyles?.jpTextColor }">{{ jackpotValue }}</div>
                                </div>
                                <span *ngIf="enableStickerStyles; else standardStyles">
                                    <div
                                        class="moreinfo-game-badge"
                                        [hidden]="!showstickersinoverlay"
                                        *ngIf="stickers && stickers.length > 0"
                                        [ngClass]="{ 'stickers-uk': isStickersForUk }">
                                        <b
                                            class="moreinfo-promostickers"
                                            *ngFor="let sticker of stickers; let i = index"
                                            [style]="getStyles(sticker.key, i)"
                                            >{{ sticker.value }}</b
                                        >
                                    </div>
                                </span>
                                <ng-template #standardStyles>
                                    <div
                                        class="moreinfo-game-badge"
                                        [hidden]="!showstickersinoverlay"
                                        *ngIf="stickers && stickers.length > 0"
                                        [ngClass]="{ 'stickers-uk': isStickersForUk }">
                                        <b
                                            class="moreinfo-promostickers"
                                            *ngFor="let sticker of stickers; let i = index"
                                            [ngClass]="{ 'nonTouch': !isTouch, 'promo-text-badge-0': i % 2 === 0, 'promo-text-badge-1': i % 2 !== 0 }"
                                            >{{ sticker.value }}</b
                                        >
                                    </div>
                                </ng-template>
                            </div>
                            <div *ngIf="useFavouriteGames && displayFavouritesOnPreLogin">
                                <div class="favourite-gradient"></div>
                                <div
                                    (click)="toggleFavourite(game, $event)"
                                    class="moreinf-favourite"
                                    [ngClass]="isFavourite ? favouriteStyle : unFavouriteStyle"></div>
                            </div>
                        </div>
                        <div class="moreinfo-gamename-details">
                            <div class="game-provider">{{ gameProvider }}</div>
                            <div *ngIf="vendorLogo" class="moreinfo-vendor-logo">
                                <img src="{{ vendorLogo }}" />
                            </div>
                            <div class="moreingo-providerlogo" *ngIf="!vendorLogo">{{ provider | uppercase }}</div>
                            <div class="moreinfo-buttons-section">
                                <div class="moreinfo-game-name">{{ gameTitle }}</div>
                                <div class="moreinfo-btn-container">
                                    <!-- <button
                                        class="btn-light moreinfo-demo-paly-btn btn btn-sm"
                                        (click)="launchGame(game, true)"
                                        *ngIf="demoEnabled && demoEnabledForProvider && isTouch">
                                        {{ demoLinkText | uppercase }}
                                    </button> -->

                                    @if (demoEnabled && demoEnabledForProvider && isTouch) {
                                        <cs-gametile-cta
                                            [isLegacy]="isLegacy"
                                            [kind]="'tertiary'"
                                            [variant]="'outline'"
                                            [size]="'medium'"
                                            [csDecoratorClass]="'btn-light moreinfo-demo-paly-btn btn-sm'"
                                            [cta_text]="demoLinkText"
                                            (click)="launchGame(game, true)"></cs-gametile-cta>
                                    }

                                    <!-- <button class="moreinfo-paly-btn btn btn-primary btn-sm" (click)="launchGame(game, false)">
                                        {{ cta | uppercase }}
                                    </button> -->
                                    <cs-gametile-cta
                                        [isLegacy]="isLegacy"
                                        [kind]="'primary'"
                                        [size]="'medium'"
                                        [csDecoratorClass]="'moreinfo-paly-btn btn-sm'"
                                        [cta_text]="cta"
                                        (click)="launchGame(game, false)"></cs-gametile-cta>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="nav-section">
                        <nav>
                            <div class="moreinfo-tabs-section">
                                <ul class="moreinfo-nav-ul">
                                    <li class="moreinfo-nav-li" [class.activeCategory]="isGameDescription" (click)="onNavClick('isGameDescription')">
                                        <div class="moreinfo-game-provider">Game description</div>
                                    </li>
                                    <li
                                        class="moreinfo-nav-li"
                                        [class.activeCategory]="isMoreInfo"
                                        *ngIf="gymlCategory && gymlCategory.gamelist && gymlCategory.gamelist.length > 0"
                                        (click)="onNavClick('isMoreInfo')">
                                        <div class="moreinfo-game-provider">More info</div>
                                    </li>
                                    <li
                                        class="moreinfo-nav-li"
                                        *ngIf="showFreeSpinCount && enableFreeSpinsOnQuickInfo"
                                        [class.activeCategory]="isFreeSpins"
                                        (click)="onNavClick('isFreeSpins')">
                                        <div class="moreinfo-game-provider">Free spins</div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div class="moreinfo-tab-content-section" id="tabcontent" [class.moreinfo-tab]="isMoreInfo" [class.freespins-tab]="isFreeSpins">
                        <div *ngIf="isGameDescription">
                            <cc-quick-info-game-description
                                [showVolatilityTable]="showVolatilityTable"
                                [hasVolatility]="hasVolatility"
                                [isOnlyMaxpayout]="isOnlyMaxpayout"
                                [isOnlyReels]="isOnlyReels"
                                [isShowValuePart]="isShowValuePart"
                                [gameTitle]="gameTitle"
                                [gameDescription]="gameDescription"
                                [volatilityTypeText]="volatilityTypeText"
                                [volatilityImgLink]="volatilityImgLink"
                                [reels]="reels"
                                [lines]="lines"
                                [maxPayout]="maxPayout"
                                [volatilityInfo]="volatilityInfo"></cc-quick-info-game-description>
                        </div>
                        <div *ngIf="isMoreInfo">
                            <cc-quick-info-more-info
                                [gymlGames]="gymlGames"
                                [gameLaunchedFrom]="gameLaunchedFrom"
                                [gamePosition]="gamePosition"
                                [gameContainer]="gameContainer"
                                [lobbyType]="lobbyType"></cc-quick-info-more-info>
                        </div>
                        <div *ngIf="isFreeSpins">
                            <cc-quick-info-free-spins [rewardDetails]="rewardDetails" [gameTitle]="gameTitle"></cc-quick-info-free-spins>
                        </div>
                    </div>
                    <!-- <div class="moreinfo-tab-content-section" [class.freespins-tab]="showFreeSpinCount && enableFreeSpinsOnQuickInfo">
                            <cc-quick-info-free-spins [rewardDetails]="rewardDetails" [gameTitle]="gameTitle"></cc-quick-info-free-spins>
                        </div> -->
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isMobile">
        <div class="casino-moreinfo-overlay" (click)="hideInfo($event)"></div>
        <div class="casino-moreinfo-outer-container mobile-section">
            <div class="casino-moreinfo-inner-container">
                <div class="theme-close-small moreinfo-close" (click)="hideInfo()"></div>
                <div class="casino-moreinfo-sections">
                    <div class="images-section">
                        <!-- <div class="game-image" [class.casino-qi-desktop-nimage]="!isMobile"> -->
                        <div class="game-image">
                            <img src="{{ mobileQuickInfoSrc }}" (error)="onGameIconError1()" />
                            <div class="moreinfo-relative-container">
                                <div
                                    class="moreinfo-jackpotcta"
                                    *ngIf="jackpotValue && showjackpotvalueinoverlay"
                                    [ngStyle]="{ background: jpRibbonStyles?.jpBgColor, color: jpRibbonStyles?.jpTextColor }"
                                    [ngClass]="{ 'casino-qi-Jackpotcta-pos': demoEnabled }">
                                    <div class="moreinfo-hot-icon" *ngIf="game.isHotJackpotGroup && enableHotIcon">
                                        <img [src]="hotIcon" (error)="onGameIconError(hotIcon)" />
                                    </div>
                                    <div class="jp-value" [ngStyle]="{ color: jpRibbonStyles?.jpTextColor }">{{ jackpotValue }}</div>
                                </div>
                                <span *ngIf="enableStickerStyles; else standardStyles">
                                    <div
                                        class="moreinfo-game-badge"
                                        [hidden]="!showstickersinoverlay"
                                        *ngIf="stickers && stickers.length > 0"
                                        [ngClass]="{ 'stickers-uk': isStickersForUk }">
                                        <b
                                            class="moreinfo-promostickers"
                                            *ngFor="let sticker of stickers; let i = index"
                                            [style]="getStyles(sticker, i)"
                                            >{{ sticker.value }}</b
                                        >
                                    </div>
                                </span>
                                <ng-template #standardStyles>
                                    <div
                                        class="moreinfo-game-badge"
                                        [hidden]="!showstickersinoverlay"
                                        *ngIf="stickers && stickers.length > 0"
                                        [ngClass]="{ 'stickers-uk': isStickersForUk }">
                                        <b
                                            class="moreinfo-promostickers"
                                            *ngFor="let sticker of stickers; let i = index"
                                            [ngClass]="{ 'nonTouch': !isTouch, 'promo-text-badge-0': i % 2 === 0, 'promo-text-badge-1': i % 2 !== 0 }"
                                            >{{ sticker.value }}</b
                                        >
                                    </div>
                                </ng-template>
                            </div>
                            <div *ngIf="useFavouriteGames && displayFavouritesOnPreLogin">
                                <div class="favourite-gradient"></div>
                                <div
                                    (click)="toggleFavourite(game, $event)"
                                    class="moreinf-favourite"
                                    [ngClass]="isFavourite ? favouriteStyle : unFavouriteStyle"></div>
                            </div>
                        </div>
                        <div class="moreinfo-gamename-details">
                            <div class="game-provider">{{ gameProvider }}</div>
                            <div *ngIf="vendorLogo" class="moreinfo-vendor-logo">
                                <img src="{{ vendorLogo }}" />
                            </div>
                            <div class="moreingo-providerlogo" *ngIf="!vendorLogo">{{ provider | uppercase }}</div>
                            <div class="moreinfo-buttons-section">
                                <div class="moreinfo-game-name">{{ gameTitle }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="nav-section">
                        <nav>
                            <div class="moreinfo-tabs-section">
                                <ul class="moreinfo-nav-ul">
                                    <li class="moreinfo-nav-li" [class.activeCategory]="isGameDescription" (click)="onNavClick('isGameDescription')">
                                        <div class="moreinfo-game-provider">Game description</div>
                                    </li>
                                    <li
                                        class="moreinfo-nav-li"
                                        [class.activeCategory]="isMoreInfo"
                                        *ngIf="gymlCategory && gymlCategory.gamelist && gymlCategory.gamelist.length > 0"
                                        (click)="onNavClick('isMoreInfo')">
                                        <div class="moreinfo-game-provider">More info</div>
                                    </li>
                                    <li
                                        class="moreinfo-nav-li"
                                        [class.activeCategory]="isFreeSpins"
                                        *ngIf="showFreeSpinCount && enableFreeSpinsOnQuickInfo"
                                        (click)="onNavClick('isFreeSpins')">
                                        <div class="moreinfo-game-provider">Free spins</div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div class="moreinfo-tab-content-section" id="tabcontent" [class.moreinfo-tab]="isMoreInfo" [class.freespins-tab]="isFreeSpins">
                        <div *ngIf="isGameDescription">
                            <cc-quick-info-game-description
                                [showVolatilityTable]="showVolatilityTable"
                                [hasVolatility]="hasVolatility"
                                [isShowValuePart]="isShowValuePart"
                                [isOnlyMaxpayout]="isOnlyMaxpayout"
                                [isOnlyReels]="isOnlyReels"
                                [gameTitle]="gameTitle"
                                [gameDescription]="gameDescription"
                                [volatilityTypeText]="volatilityTypeText"
                                [volatilityImgLink]="volatilityImgLink"
                                [reels]="reels"
                                [lines]="lines"
                                [maxPayout]="maxPayout"
                                [volatilityInfo]="volatilityInfo"></cc-quick-info-game-description>
                        </div>
                        <div *ngIf="isMoreInfo">
                            <cc-quick-info-more-info
                                [gymlGames]="gymlGames"
                                [gameLaunchedFrom]="gameLaunchedFrom"
                                [gamePosition]="gamePosition"
                                [gameContainer]="gameContainer"></cc-quick-info-more-info>
                        </div>
                        <div *ngIf="isFreeSpins">
                            <cc-quick-info-free-spins [rewardDetails]="rewardDetails" [gameTitle]="gameTitle"></cc-quick-info-free-spins>
                        </div>
                    </div>
                </div>
            </div>
            <div class="moreinfo-btn-container">
                <!-- <button class="btn-light moreinfo-demo-paly-btn btn btn-sm" (click)="launchGame(game, true)"
                    *ngIf="demoEnabled && demoEnabledForProvider && isTouch">
                    {{ demoLinkText | uppercase }}
                </button> -->

                @if (demoEnabled && demoEnabledForProvider && isTouch) {
                    <cs-gametile-cta
                        [isLegacy]="isLegacy"
                        [kind]="'tertiary'"
                        [variant]="'outline'"
                        [size]="'medium'"
                        [csDecoratorClass]="'btn-light moreinfo-demo-paly-btn btn-sm'"
                        [cta_text]="demoLinkText"
                        (click)="launchGame(game, true)"></cs-gametile-cta>
                }

                <!-- <button class="moreinfo-paly-btn btn btn-primary btn-sm" (click)="launchGame(game, false)">{{ cta |
                    uppercase }}</button> -->
                <cs-gametile-cta
                    [isLegacy]="isLegacy"
                    [kind]="'primary'"
                    [size]="'medium'"
                    [csDecoratorClass]="'moreinfo-paly-btn btn-sm'"
                    [cta_text]="cta"
                    (click)="launchGame(game, false)"></cs-gametile-cta>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="isTouch && !landscapeMode">
        <div class="casino-qi-noverlay" (click)="hideInfo($event)"></div>
        <div class="casino-qi-nrectangle" [style.width.px]="boxWidth">
            <div class="casino-total-data z-index-1">
                <div class="casino-qi-nrectangle-int">
                    <div class="casino-qi-image">
                        <img src="{{mobileQuickInfoSrc}}" />
                        <div class="casino-relativ-div-quickinfo">
                            <div class="casino-qi-Jackpotcta" *ngIf="jackpotValue" [ngStyle]="{'background':jpBgColor}" [ngClass]="{'casino-qi-Jackpotcta-pos': demoEnabled}">
                                <div class="casino-qi-hot-icon" *ngIf="isHotJackpotGroup">
                                    <img [src]="hotIcon" />
                                </div>
                                <div class="qi-jp-value" [ngStyle]="{'color':jpTextCOlor}">{{jackpotValue}}</div>
                            </div>
                            <div class="casino-qi-game-badge" *ngIf="stickers && stickers.length>0 " [ngClass]="{'stickers-uk':isStickersForUk}">
                                <b class="casino-qi-promoStickers" *ngFor="let sticker of stickers;let i=index" [ngClass]="{'nonTouch':!isTouch,'promo-text-badge-0':i%2 === 0,'promo-text-badge-1':i%2 !== 0}">{{stickers}}</b>
                            </div>
                        </div>
                        <div *ngIf="useFavouriteGames">
                            <div class="casino-qi-ngradient"></div>
                            <div (click)="toggleFavourite(game, $event)" class="casino-qi-nfavourite" [ngClass]="isFavourite ? favouriteStyle : unFavouriteStyle"></div>
                        </div>
                    </div>
                    <div class="casino-qi-rectangle-right" [style.width.px]="boxWidthInt">
                        <div class="casino-qi-ngame-provider">{{gameProvider }}</div>
                        <div class="close theme-close qi-close" (click)="hideInfo($event)"></div>
                        <div *ngIf="vendorLogo" class="casino-qi-nlogo">
                            <img src={{vendorLogo}} />
                        </div>
                        <div class="casino-qi-nlogo" *ngIf="!vendorLogo">{{provider | uppercase }}</div>
                        <div>{{gameTitle}}</div>
                    </div>
                    <nav>
                        <div class="casino-qi-nrectangle-int">
                            <ul class="casino-qi-nav-ul">
                                <li class="casino-qi-nav-li" [class.activeCategory]="isGameDescription" (click)="onNavClick('isGameDescription')">
                                    <div class="casino-qi-ngame-provider"> Game description</div>
                                </li>
                                <li class="casino-qi-nav-li" [class.activeCategory]="isMoreInfo" *ngIf="gymlCategory && gymlCategory.gamelist && gymlCategory.gamelist.length > 0" (click)="onNavClick('isMoreInfo')">                                            
                                    <div class="casino-qi-ngame-provider">More info</div>
                                </li>
                                <li class="casino-qi-nav-li" *ngIf="showFreeSpinCount && enableFreeSpinsOnQuickInfo" [class.activeCategory]="isFreeSpins" (click)="onNavClick('isFreeSpins')">
                                    <div class="casino-qi-ngame-provider">Free spins</div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    <div class="casino-qi-nrectangle-int">
                        <div *ngIf="isGameDescription">
                            <cc-quick-info-game-description [gameTitle]="gameTitle" [gameDescription]="gameDescription"></cc-quick-info-game-description>
                        </div>
                        <div *ngIf="isMoreInfo">
                            <cc-quick-info-more-info [gymlGames]="gymlGames" [gameLaunchedFrom]="gameLaunchedFrom" [gamePosition]="gamePosition"
                            [gameContainer]="gameContainer"></cc-quick-info-more-info>
                        </div>  
                        <div *ngIf="isFreeSpins">
                            <cc-quick-info-free-spins 
                            [rewardDetails]="rewardDetails" [gameTitle]="gameTitle"></cc-quick-info-free-spins>
                        </div>
                    </div>
                    <div class="casino-qi-btn-container">
                        <button class="qi-margin-top casino-qi-demo-link btn" (click)="launchGame(game, true);" *ngIf="demoEnabled && demoEnabledForProvider && isTouch">
                            {{demoLinkText | uppercase}}
                        </button>
                        <button class="casino-qi-nplay-now btn btn-primary" (click)="launchGame(game, false);">{{cta | uppercase}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>
