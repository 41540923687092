import { Injectable } from '@angular/core';

import { Logger } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
})
export class ApiPathsHelperService {
    constructor(private logger: Logger) {}

    transformApi(apiPathConfig: string, ...args: any[]) {
        this.logger.info('Api Path Arguments::', args);
        return eval('`' + apiPathConfig + '`');
    }
}
