@if (isLobbyLoaded) {
    <div>
        <cc-lmt-sub-category-lobby
            [trackingIndex]="trackingIndex"
            [subcategoryId]="subCategoryRoute"
            [isTouch]="isTouch"
            [subcategory]="subCategory"
            [lobbyType]="lobbyType"
            [gameContainer]="gameContainer">
        </cc-lmt-sub-category-lobby>
    </div>
}
