import { Injectable } from '@angular/core';

import { Logger, TrackingService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';

@Injectable({
    providedIn: 'root',
})
export class FreespinSummaryService {
    freeSpinsGameDetails = new BehaviorSubject<any>(null);
    freeSpinsCategoryDetails = new BehaviorSubject<any>(null);
    freeSpinsRewardDetails = new BehaviorSubject<any>(null);
    freespinsMoreInfoClick = new BehaviorSubject<any>(null);
    freeSpinsSummaryData = new BehaviorSubject<any>(null);
    private freeSpinsSummaryDataPublisher = new BehaviorSubject<boolean>(false);
    freeSpinsSummaryDataMapObservable: Observable<boolean | null>;

    freeSpinsGameDetailsObser: Observable<any> = this.freeSpinsGameDetails.asObservable();
    freeSpinsCategoryDetailsObser: Observable<any> = this.freeSpinsCategoryDetails.asObservable();
    freeSpinsRewardDetailsObser: Observable<any> = this.freeSpinsRewardDetails.asObservable();
    freespinsMoreInfoClickObser: Observable<any> = this.freespinsMoreInfoClick.asObservable();
    freeSpinsGameDetailsMap: Map<string, any> = new Map<string, any>();
    freeSpinsCategoryDetailsMap: Map<string, any> = new Map<string, any>();
    freeSpinsRewardDetailsMap: Map<number, any> = new Map<number, any>();
    freeSpinsSummaryDataObser: Observable<any> = this.freeSpinsSummaryData.asObservable();
    timeZoneData: any;
    moreinfoUrl: string = '';
    constructor(
        private api: PlatformApiService,
        private tracking: TrackingService,
        private configProviderService: ConfigProviderService,
        private logger: Logger,
    ) {
        this.freeSpinsSummaryDataMapObservable = this.freeSpinsSummaryDataPublisher.asObservable();
    }

    setFreespinsummary() {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        this.api.get(apiPathsConfig.freeSpins['spinsSummary'], {}, { withCredentials: true }).subscribe(
            (response: any) => {
                this.freeSpinsSummaryDataPublisher.next(response);
                this.setFreespinsummaryData(response);
            },
            () => {},
        );
    }

    setFreespinsummaryData(freeSpinResponse: any) {
        if (freeSpinResponse) {
            const response = freeSpinResponse.freeSpinSummaryData;
            const rewardDetailsData = freeSpinResponse.freeSpinRewardDetails;
            this.timeZoneData = freeSpinResponse.timeZone;
            if (response) {
                if (response.gameDetails && Object.keys(response.gameDetails) && Object.keys(response.gameDetails).length > 0) {
                    for (const game in response.gameDetails) {
                        this.freeSpinsGameDetailsMap.set(game, response.gameDetails[game]);
                    }
                    this.freeSpinsGameDetails.next(this.freeSpinsGameDetailsMap);
                } else {
                    this.freeSpinsGameDetailsMap.clear();
                    this.freeSpinsGameDetails.next(this.freeSpinsGameDetailsMap);
                }
                if (response.gameTypeDetails) {
                    for (const game in response.gameTypeDetails) {
                        this.freeSpinsCategoryDetailsMap.set(game, response.gameTypeDetails[game]);
                    }
                    this.freeSpinsCategoryDetails.next(this.freeSpinsCategoryDetailsMap);
                }
                if (rewardDetailsData && rewardDetailsData.rewardDetails && rewardDetailsData.rewardDetails.length > 0) {
                    rewardDetailsData.rewardDetails.forEach((item: any) => {
                        this.freeSpinsRewardDetailsMap.set(item.userRewardId, item);
                    });
                    this.freeSpinsRewardDetails.next(this.freeSpinsRewardDetailsMap);
                } else {
                    this.freeSpinsRewardDetailsMap.clear();
                    this.freeSpinsRewardDetails.next(this.freeSpinsRewardDetailsMap);
                }
            }
        }
    }
    getTimeZoneInfo() {
        return this.timeZoneData;
    }
    getFreespinCountInfoForGame(gameId: string) {
        return this.freeSpinsGameDetailsMap.get(gameId);
    }
    // NFR - Services optimization - Remove
    getFreespinCountInfoForCategory(categoryId: any) {
        return this.freeSpinsCategoryDetailsMap.get(categoryId);
    }

    getFreeSpinRewardDetail(rewardId: number) {
        return this.freeSpinsRewardDetailsMap.get(rewardId);
    }
    getFreeSpinRewardDetails(rewardIds: number[]): any[] {
        if (this.configProviderService.providefreeSpinsClientConfig().freeSpinsMoreInfo) {
            this.moreinfoUrl = this.configProviderService.providefreeSpinsClientConfig().freeSpinsMoreInfo.url;
        }
        const rewardetails: any[] = [];
        if (rewardIds && rewardIds.length > 0) {
            rewardIds.forEach((id: number) => {
                const rewardDetailItem = this.getFreeSpinRewardDetail(id);
                if (rewardDetailItem) {
                    const freespinRewardId = String(rewardDetailItem.userRewardId);
                    const redirectUrl = this.moreinfoUrl.replace('{freespinId}', freespinRewardId);
                    rewardDetailItem.moreinfoUrl = redirectUrl;
                    rewardetails.push(rewardDetailItem);
                }
            });
        }
        return rewardetails;
    }

    handleRejection(rejection: any) {
        // error
        if (rejection) {
            this.logger.debug('http code: ' + rejection.status + '; message: ' + rejection.message);
            let errorCode = '';
            if (rejection && rejection.errorCode) {
                errorCode = rejection.errorCode;
            }
            this.tracking.updateDataLayer({ 'page.name': 'MyFreeSpins' });
            this.tracking.reportError({ message: 'Technical error', code: errorCode });
            // TODO: To be removed once all teams will migrate Omniture tracking to GTM data layer
            this.tracking.updateDataLayer({
                pageName: 'freespin',
                v68: 'Technical error ' + errorCode,
            });

            this.logger.debug('error', rejection);
        }
    }
    freespinMoreinfoClick() {
        this.freespinsMoreInfoClick.next(true);
    }
    addTrackingEvent(
        categoryEvent: string,
        labelEvent: string,
        actionEvent: string,
        positionEvent: string,
        locationEvent: string,
        eventDetails: string,
        index?: any,
    ) {
        const disPlaycontent = actionEvent == 'load' ? 'contentView' : 'Event.Tracking';
        this.tracking.triggerEvent(disPlaycontent, {
            'component.CategoryEvent': categoryEvent,
            'component.LabelEvent': labelEvent,
            'component.ActionEvent': actionEvent,
            'component.PositionEvent': positionEvent,
            'component.LocationEvent': locationEvent,
            'component.EventDetails': eventDetails,
            'component.ContentPosition': index,
            //   'component.URLClicked': uRLClicked
        });
    }
}
