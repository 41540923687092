import { NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';

import {
    CasinoManager,
    CasinoWidgetService,
    ConfigProviderService,
    CozyBingoDataService,
    DesignSystemEnablerService,
    FastLoadingGamesService,
    FavouriteService,
    FreespinSummaryService,
    RecentGamesService,
} from '@casinocore/platform/core';
import { ChevronKind, DecoratorClassDirective, RpFavWidgetCtaComponent } from '@frontend/casino-ui';
import { DeviceService, NavigationService, TrackingService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Fav_GYML_WidgetComponent } from '../fav-gyml-widget/fav-gyml-widget.component';
import { RpGymlWidgetComponent } from '../rp-gyml-widget/rp-gyml-widget.component';

@Component({
    selector: 'cc-rp-fav-widget',
    templateUrl: 'rp-fav-widget.component.html',
    standalone: true,
    imports: [NgIf, NgStyle, NgClass, RpGymlWidgetComponent, Fav_GYML_WidgetComponent, RpFavWidgetCtaComponent, DecoratorClassDirective],
})
export class RpFavWidgetComponent implements OnChanges, OnInit, OnDestroy {
    isMobile: boolean;
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    IsGymlInFavourites: boolean;
    IsRpFavWidgetEnabled: boolean;
    IsFloatingEnabledForWidget: boolean;
    recentGames: any;
    favouriteGames: any;
    widgetRecentlyPlayed: string = '';
    rcpToggle: boolean;
    widgetToggleInfo: any;
    favToggle: boolean;
    widgetContainerToggle: boolean;
    widgetFavourites: string = '';
    rcpWidgetBackground: string;
    rpWidgetInactiveBtBackground: string = '';
    rpWidgetActiveBtBackground: string = '';
    rpWidgetActiveTxtcolor: string = '';
    rpWidgetInactiveTxtcolor: string = '';
    isTouch: boolean;
    currentLobbyType: string;
    isAuthenticated: boolean = false;
    showRcpHelpContactUs: boolean;
    widgetContactUsBG: string;
    widgetContactUsClr: string;
    widgetHelpBG: string;
    widgetHelpClr: string;
    enableLiveCasinoApiLobbyType: any;
    rcpWidgeticon: string;
    rcpFavWidgeticon: string = '';
    rcpContactUsicon: string;
    rcpHelpicon: string;
    rcpWidgetBackgroundTrans: string;
    WidgetHelpButton: any;
    WidgetContactusButton: any;
    currentWidgetHeight: number = 0;
    interval: any;
    newWidgetHeight: number = 0;
    maxHeightCount: number;
    enableBingoWidgetOnCasino: boolean;
    useFavouriteGames: boolean;
    features: any;
    global: any;
    widgetConfig: any;
    freespinObs: Subscription;
    displayFavInRPFavWidget: boolean;
    cozyBingoSubscriber: any;
    favGamesSubscriber: any;
    recentGamesSubscriber: any;
    isAbRecentlyPlayedContainer: boolean;
    isAbRecentlyPlayedWidget: boolean;
    cozyUserSubscriber: Subscription;
    rcp_chevronKind: ChevronKind = 'up';
    fav_chevronKind: ChevronKind = 'up';
    @ViewChild('favGYMLWidget') favGYMLWidget: Fav_GYML_WidgetComponent;
    isLegacy: boolean = false;
    constructor(
        private recentGameService: RecentGamesService,
        public user: UserService,
        private casinoManager: CasinoManager,
        private deviceService: DeviceService,
        private navigation: NavigationService,
        private tracking: TrackingService,
        private casinoWidgetService: CasinoWidgetService,
        private fastLoadingGamesService: FastLoadingGamesService,
        private configProviderService: ConfigProviderService,
        private favouriteService: FavouriteService,
        private cozyBingoDataService: CozyBingoDataService,
        private freespinSummaryService: FreespinSummaryService,
        private designSystemEnablerService: DesignSystemEnablerService,
    ) {}
    ngOnInit() {
        this.isAuthenticated = this.user.isAuthenticated;
        this.assignConfig();
        this.assignFeatures();
        this.isTouch = this.deviceService.isTouch;
        this.isMobile = this.deviceService.isMobile;
        this.isLegacy = !this.designSystemEnablerService.isEnabled();
        const abTestingConfig: any = this.configProviderService.provideABTestingConfig();
        if (abTestingConfig) {
            this.isAbRecentlyPlayedWidget = abTestingConfig.isEnabled && abTestingConfig.recentlyPlayedAsWidget;
            this.isAbRecentlyPlayedContainer = abTestingConfig.isEnabled && abTestingConfig.recentlyPlayedAsContainer;
        }
        this.WidgetHelpButton =
            this.widgetConfig.widgetLinks.help && this.widgetConfig.widgetLinks.help.imageLink
                ? this.widgetConfig.widgetLinks.help.imageLink.text
                : '';
        this.WidgetContactusButton =
            this.widgetConfig.widgetLinks.contactus && this.widgetConfig.widgetLinks.contactus.imageLink
                ? this.widgetConfig.widgetLinks.contactus.imageLink.text
                : '';
        this.dataBindings();
        this.isDisplayRpandGyml();
        this.currentLobbyType = this.lobbyType;
        this.useFavouriteGames = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, this.features.favouriteGamesLobbyType);
        this.widgetToggleInfo = this.casinoManager.getWidgetToggleInfo();
        if ((this.widgetToggleInfo && this.widgetToggleInfo != '') || this.widgetToggleInfo != null) {
            this.recentGames = this.recentGameService.getrecentGamesFromAllLobby();
            this.favouriteGames = this.favouriteService.getFavouriteGames();
            this.isDisplayRpandGyml();
            this.widgetToggleHandler(this.widgetToggleInfo);
        }
        this.postWidgetHeight(this.widgetConfig?.rpFavWidgetPostHeightTimeOut);
        this.subscribeToUserEvents();
    }
    ngOnChanges() {
        this.isDisplayRpandGyml();
    }
    assignConfig() {
        this.global = this.configProviderService.provideGlobalConfig();
        this.widgetConfig = this.configProviderService.providewidgetConfig();
        this.features = this.configProviderService.provideFeaturesConfig();
    }
    assignFeatures() {
        this.enableBingoWidgetOnCasino = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, this.features.enableBingoWidgetOnCasino);
        this.displayFavInRPFavWidget = this.widgetConfig.displayFavInRPFavWidget;
    }
    setFastLoadingGames() {
        if (this.features.enableFastLoadingGames) {
            this.fastLoadingGamesService.setFastLoadingGamesFromLMT();
        }
    }

    dataBindings() {
        this.rcpWidgetBackground = this.global.messages['WidgetBackgroundColor'];
        this.rpWidgetActiveBtBackground = this.global.messages['WidgetActiveBtnBGColor'];
        this.rpWidgetInactiveBtBackground = this.global.messages['WidgetInActiveBtnBGColor'];
        this.rpWidgetActiveTxtcolor = this.global.messages['WidgetActiveTxtColor'];
        this.rpWidgetInactiveTxtcolor = this.global.messages['WidgetInActiveTxtColor'];
        this.widgetFavourites = this.global.messages['WidgetFavourites'];
        this.widgetRecentlyPlayed = this.global.messages['WidgetRecentlyPlayed'];
        this.widgetContactUsBG = this.global.messages['WidgetContactUsBG'];
        this.widgetContactUsClr = this.global.messages['WidgetContactUsClr'];
        this.widgetHelpBG = this.global.messages['WidgetHelpBG'];
        this.widgetHelpClr = this.global.messages['WidgetHelpClr'];
        this.rcpWidgeticon = this.global.messages['rcpWidgeticon'];
        this.rcpFavWidgeticon = this.global.messages['rcpFavWidgeticon'];
        this.rcpWidgetBackgroundTrans = this.global.messages['rcpWidgetBackgroundTrans'];
        this.rcpContactUsicon =
            this.widgetConfig.widgetLinks.contactus && this.widgetConfig.widgetLinks.contactus.image
                ? this.widgetConfig.widgetLinks.contactus.image.src
                : '';
        this.rcpHelpicon =
            this.widgetConfig.widgetLinks.help && this.widgetConfig.widgetLinks.help.image ? this.widgetConfig.widgetLinks.help.image.src : '';
    }
    getContactusUrl() {
        if (
            this.casinoWidgetService.isCasinoWidget() &&
            this.widgetConfig.widgetLinks.contactus &&
            this.widgetConfig.widgetLinks.contactus.imageLink
        ) {
            const contactUsParam = {
                categoryid: 'casino-rcp-widget',
                contactUrl: this.widgetConfig.widgetLinks.contactus.imageLink.url,
            };
            this.casinoWidgetService.postMessageToParent('ContactUs', contactUsParam);
        } else {
            this.navigation.goTo(this.widgetConfig?.widgetLinks?.contactus?.imageLink?.url);
        }
    }
    getHelpUrl() {
        if (this.casinoWidgetService.isCasinoWidget() && this.widgetConfig.widgetLinks.help && this.widgetConfig.widgetLinks.help.imageLink) {
            const helpParam = {
                categoryid: 'casino-rcp-widget',
                helpUrl: this.widgetConfig.widgetLinks.help.imageLink.url,
            };
            this.casinoWidgetService.postMessageToParent('Help', helpParam);
        } else {
            this.navigation.goTo(this.widgetConfig?.widgetLinks?.help?.imageLink?.url);
        }
    }

    subscribeToUserEvents() {
        this.user.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            this.isDisplayRpandGyml();
        });
        this.recentGamesSubscriber = this.recentGameService.recentGamesFeedFromAllLobbyObservable.subscribe((e: any) => {
            if (e) {
                this.recentGames = e;
                this.isDisplayRpandGyml();
            }
        });

        this.favGamesSubscriber = this.favouriteService.favouriteGamesFeedObservable.subscribe((favouriteGames: any) => {
            if (favouriteGames) {
                this.favouriteGames = favouriteGames;
                this.isDisplayRpandGyml();
            }
        });

        this.cozyBingoSubscriber = this.cozyBingoDataService.cozyBingoRoomsDataObservable.subscribe((response: any) => {
            if (response) {
                this.isDisplayRpandGyml();
            }
        });
        this.freespinObs = this.freespinSummaryService.freespinsMoreInfoClickObser.subscribe(() => {
            this.rcpToggle = false;
            this.favToggle = false;
            this.toggleChevron();
        });
    }
    @HostListener('window:message', ['$event']) receiveWidgetMessages(event: MessageEvent) {
        const cozyBingoConfig = this.configProviderService.provideCozyBingoConfig();
        if (event.data.eventName == 'favoriteUpdated' && cozyBingoConfig.cozyBingoFavouritesInRpwidget) {
            this.cozyBingoDataService.getBingoData(false, false);
        }
    }
    isDisplayRpandGyml() {
        this.isAuthenticated = this.user.isAuthenticated;
        const widgetConfig: any = this.configProviderService.providewidgetConfig();
        if (this.user.isAuthenticated) {
            let otherProductRpGames = [];
            let otherProductfav = [];
            otherProductRpGames = this.cozyBingoDataService.getcozybingorecentgames();
            otherProductfav = this.cozyBingoDataService.getcozybingofavourites();
            if (
                (this.recentGameService.getrecentGamesFromAllLobby() && this.recentGameService.getrecentGamesFromAllLobby().length > 0) ||
                otherProductRpGames.length > 0 ||
                (this.favouriteGames && this.favouriteGames.length > 0) ||
                otherProductfav.length > 0
            ) {
                this.IsRpFavWidgetEnabled = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, widgetConfig.isRpFavWidgetEnabled);
                this.IsGymlInFavourites = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, widgetConfig.isGymlInFavourites);
                this.IsFloatingEnabledForWidget = this.casinoManager.IsFeatureEnabledforLobbytype(
                    this.lobbyType,
                    widgetConfig.isStickyEnabledForWidget,
                );
                this.recentGameService.isRpfavDisplayed(this.IsRpFavWidgetEnabled);
            } else {
                this.IsRpFavWidgetEnabled = false;
                this.rcpToggle = false;
                this.favToggle = false;
                this.toggleChevron();
            }
            if (this.IsRpFavWidgetEnabled) {
                this.widgetHeight();
            }
        } else {
            this.IsRpFavWidgetEnabled = false;
            this.IsGymlInFavourites = false;
            this.IsFloatingEnabledForWidget = false;
        }
        this.showRcpHelpContactUs = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, widgetConfig.enableRcpwidgetHelpContactUs);
        if (!this.IsRpFavWidgetEnabled) {
            this.postWidgetHeight(this.widgetConfig?.rpFavWidgetPostHeightTimeOut);
        } else {
            if (!document.getElementsByTagName('html')[0].classList.contains('help-contact-bar')) {
                document.getElementsByTagName('html')[0].classList.add('help-contact-bar');
            }
        }
        if (this.IsRpFavWidgetEnabled) {
            document.getElementsByTagName('html')[0].classList.add('rpfav-widget-enabled');
        } else {
            document.getElementsByTagName('html')[0].classList.remove('rpfav-widget-enabled');
        }
    }

    postWidgetHeight(timeOutCount: number): void {
        setTimeout(() => {
            this.postHeight();
            this.widgetHeight();
        }, timeOutCount);
    }
    postHeight(): void {
        if (this.casinoWidgetService.isCasinoWidget()) {
            const widget = document.getElementById('widgettoggle');
            if (widget) {
                this.currentWidgetHeight = widget.scrollHeight;
                const params = {
                    isFooterWidget: this.casinoWidgetService.getWidgetPosition() ? true : false,
                    categoryid: 'casino-rcp-widget',
                    height: this.casinoWidgetService.getWidgetPosition() ? this.currentWidgetHeight + 10 : this.currentWidgetHeight,
                };
                this.casinoWidgetService.postMessageToParent('LobbyLoaded', params);
                if (!this.interval) {
                    this.interval = setInterval(() => {
                        this.checkAndUpdateWidgetHeight();
                    }, 500);
                }
            } else {
                this.casinoWidgetService.postMessageToParent('ReloadWidget', null);
            }
        }
    }

    checkAndUpdateWidgetHeight() {
        this.newWidgetHeight = document.getElementById('widgettoggle')!.scrollHeight;
        if (this.currentWidgetHeight === this.newWidgetHeight) {
            this.maxHeightCount--;
        }
        if (!this.maxHeightCount) {
            clearInterval(this.interval);
        }
        if (this.newWidgetHeight !== this.currentWidgetHeight) {
            this.currentWidgetHeight = this.newWidgetHeight;
            const params = {
                isFooterWidget: this.casinoWidgetService.getWidgetPosition() ? true : false,
                categoryid: 'casino-rcp-widget',
                height: document.getElementById('widgettoggle')!.scrollHeight,
            };
            this.casinoWidgetService.postMessageToParent('LobbyLoaded', params);
        }
    }
    onResize(): void {
        this.postWidgetHeight(this.widgetConfig?.rpFavTogglePostHeightTimeOut);
    }
    close() {
        this.postWidgetHeight(this.widgetConfig?.rpFavTogglePostHeightTimeOut);
        if (this.rcpToggle) {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'RecentlyPlayed_Widget',
                'component.ActionEvent': 'Close Widget- X Click',
                'component.PositionEvent': 'Not applicable',
                'component.LocationEvent': this.currentLobbyType,
            });
        }
        if (this.favToggle) {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'Favourites_Widget',
                'component.ActionEvent': 'Close Widget- X Click',
                'component.PositionEvent': 'Not applicable',
                'component.LocationEvent': this.currentLobbyType,
            });
        }
        this.rcpToggle = false;
        this.favToggle = false;
        this.toggleChevron();

        if (document.getElementsByTagName('html')[0].classList.contains('rpfav-widget-expanded')) {
            document.getElementsByTagName('html')[0].classList.remove('rpfav-widget-expanded');
        }
        this.casinoManager.setWidgetToggleInfo('');
    }
    widgetToggleHandler(value: string, fromLobbyChanged: boolean = false) {
        this.postWidgetHeight(this.widgetConfig?.rpFavTogglePostHeightTimeOut);
        if (fromLobbyChanged && value == 'rcp') {
            this.rcpToggle = !this.rcpToggle;
            this.toggleChevron();
        }
        if (fromLobbyChanged && value == 'fav') {
            this.favToggle = !this.favToggle;
        }
        if (value === 'rcp') {
            this.rcpToggle = !this.rcpToggle;
            if (this.rcpToggle) {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'RecentlyPlayed_Widget',
                    'component.LabelEvent': 'RecentlyPlayed_Widget',
                    'component.ActionEvent': 'Expand',
                    'component.LocationEvent': this.currentLobbyType,
                });
                this.casinoManager.setWidgetToggleInfo('rcp');
            } else {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'RecentlyPlayed_Widget',
                    'component.LabelEvent': 'RecentlyPlayed_Widget',
                    'component.ActionEvent': 'Close',
                    'component.LocationEvent': this.currentLobbyType,
                });

                this.casinoManager.setWidgetToggleInfo('');
            }
            this.favToggle = false;
            this.toggleChevron();
        } else if (value === 'fav') {
            this.favToggle = !this.favToggle;
            if (this.favToggle) {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'RecentlyPlayed_Widget',
                    'component.LabelEvent': 'Favourites_Widget',
                    'component.ActionEvent': 'Expand',
                    'component.LocationEvent': this.currentLobbyType,
                });
                this.casinoManager.setWidgetToggleInfo('fav');
                if (this.favGYMLWidget && this.favGYMLWidget.swiperRef && this.favGYMLWidget.swiperRef.swiper) {
                    this.favGYMLWidget.swiperRef.swiper.update();
                }
            } else {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'RecentlyPlayed_Widget',
                    'component.LabelEvent': 'Favourites_Widget',
                    'component.ActionEvent': 'Close',
                    'component.LocationEvent': this.currentLobbyType,
                });

                this.casinoManager.setWidgetToggleInfo('');
            }
            this.rcpToggle = false;
            this.toggleChevron();
        }
        if (this.rcpToggle || this.favToggle) {
            if (!document.getElementsByTagName('html')[0].classList.contains('rpfav-widget-expanded')) {
                document.getElementsByTagName('html')[0].classList.add('rpfav-widget-expanded');
            }
        } else {
            if (document.getElementsByTagName('html')[0].classList.contains('rpfav-widget-expanded')) {
                document.getElementsByTagName('html')[0].classList.remove('rpfav-widget-expanded');
            }
        }
        this.widgetContainerToggle = !this.widgetContainerToggle;
    }

    setAttributes(element: any, attributes: any) {
        Object.keys(attributes).forEach((attr) => {
            element.setAttribute(attr, attributes[attr]);
        });
    }
    widgetHeight() {
        if (document.getElementsByTagName('vn-bottom-nav') && document.getElementsByTagName('vn-bottom-nav').length > 0) {
            const rpFavWidgetHeight: number = 0;

            if (
                document.getElementsByClassName('bottom-nav') &&
                document.getElementsByClassName('bottom-nav')[0] &&
                document.getElementsByClassName('bottom-nav')[0].clientHeight
            ) {
                const rpFavWidgetHeight = document.getElementsByClassName('bottom-nav')[0].clientHeight;
                let attributes = {
                    style: 'z-index:5;bottom:' + rpFavWidgetHeight + 'px',
                };
                if (this.favToggle || this.rcpToggle) {
                    attributes = {
                        style: 'z-index:101;bottom:' + rpFavWidgetHeight + 'px',
                    };
                }
                if (document.getElementsByClassName('casino-rcp-widget') && document.getElementsByClassName('casino-rcp-widget')[0]) {
                    document.getElementsByClassName('casino-rcp-widget')[0].setAttribute('style', 'bottom:' + rpFavWidgetHeight + 'px;');
                    this.setAttributes(document.getElementsByClassName('casino-rcp-widget')[0], attributes);
                }
            } else {
                let attributes = {
                    style: 'z-index:5;bottom:' + rpFavWidgetHeight + 'px',
                };
                if (this.favToggle || this.rcpToggle) {
                    attributes = {
                        style: 'z-index:101;bottom:' + rpFavWidgetHeight + 'px',
                    };
                }
                if (document.getElementsByClassName('casino-rcp-widget') && document.getElementsByClassName('casino-rcp-widget')[0]) {
                    document.getElementsByClassName('casino-rcp-widget')[0].setAttribute('style', 'bottom:' + rpFavWidgetHeight + 'px;');
                    this.setAttributes(document.getElementsByClassName('casino-rcp-widget')[0], attributes);
                }
            }
        }
    }
    ngOnDestroy() {
        if (this.freespinObs) {
            this.freespinObs.unsubscribe();
        }
        if (this.cozyBingoSubscriber) {
            this.cozyBingoSubscriber.unsubscribe();
        }
        if (this.favGamesSubscriber) {
            this.favGamesSubscriber.unsubscribe();
        }
        if (this.recentGamesSubscriber) {
            this.recentGamesSubscriber.unsubscribe();
        }
    }
    private toggleChevron() {
        if (this.rcpToggle) {
            this.rcp_chevronKind = 'down';
        } else {
            this.rcp_chevronKind = 'up';
        }
        if (this.favToggle) {
            this.fav_chevronKind = 'down';
        } else {
            this.fav_chevronKind = 'up';
        }
    }
}
