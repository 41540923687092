import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { ConfigProviderService } from '@casinocore/platform/core';

import { GamingStoriesClientConfig } from '../../client-config';
import { GamingStoriesModuleLoadDirective } from '../gaming-stories-wrapper/gaming-stories-loader.directive';
import { GamingStoriesLoaderService } from '../gaming-stories-wrapper/gaming-stories-loader.service';

@Component({
    selector: 'cs-instagram-section',
    templateUrl: 'instagram-section.component.html',
    standalone: true,
    imports: [NgIf, GamingStoriesModuleLoadDirective],
})
export class InstagramSectionComponent implements OnInit {
    @Input() data: any;
    config: any;
    instagramHeight: any;
    noDataLoaded: string;

    constructor(
        private gamingStoriesLoaderService: GamingStoriesLoaderService,
        private gamingStoriesClientConfig: GamingStoriesClientConfig,
        private configProviderService: ConfigProviderService,
    ) {}
    ngOnInit() {
        const featureConfig = this.configProviderService.provideFeaturesConfig();
        this.instagramHeight = featureConfig.instagramHeight;
        this.config = {
            gamingstoriesExpirationTimeInterval: this.gamingStoriesClientConfig.localStorageExpirationDuration,
            trackViewedStateForPostLogin: this.gamingStoriesClientConfig.trackViewedStateForPostLogin,
            trackViewedStateForPreLogin: this.gamingStoriesClientConfig.trackViewedStateForPreLogin,
            showStoryViewedTickMark: this.gamingStoriesClientConfig.showStoryViewedTickMark,
            enableOverlayMobileSearchTopPosition: this.gamingStoriesClientConfig.enableOverlayMobileSearchTopPosition,
            gameLaunchSetTimeOut: this.gamingStoriesClientConfig.gameLaunchSetTimeOut,
        };
        if (!this.gamingStoriesLoaderService.isModuleLoaded()) {
            this.gamingStoriesLoaderService.loadGamingStoriesModule();
        }
    }

    dataNotExistsinstaemit(data: any) {
        this.noDataLoaded = data;
    }
}
