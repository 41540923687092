import { ContentImage, ContentItem } from '@frontend/vanilla/core';

export const FILTER_TYPE_ALL = 'ALL';
export const FILTER_TYPE_COINS_ENTRY = 'Coins_Entry';
export const FILTER_TYPE_CASH_ENTRY = 'Cash_Entry';
export const FILTER_TYPE_COIN_REWARD = 'Coins_Reward';
export const FILTER_TYPE_FREE_ENTRY = 'Free_Entry';

export interface SlotRacesLive {
    liveNow: Race[];
}

export interface SlotRacesUpcoming {
    upcomingToday: Race[];
    upcomingTomorrow: Race[];
}

export interface Leaderboard {
    content: IContent;
    rankDetails: any[];
}

export interface Race {
    rewardValue: any;
    promoId: number;
    slotUniqueId: number;
    subType: string;
    cmsTempId: string;
    gameVariantName: string;
    gameVariantList: string[];
    startTime: string;
    endTime: string;
    startDate: Date;
    optinExpiryDate: Date;
    optinExpiryTime: string;
    endDate: Date;
    targetedPlayerCount: number;
    optinLimit: number;
    notificationStatus: boolean;
    isNotify: boolean;
    currentRank: number;
    ctaList: string[];
    criteriaDtls: any;
    spinProgress: any;
    slotRaceRuleConfig: PrizeRule[];
    slotRaceRuleConfigV2: PrizeRule[];
    rankDetails: any[];
    accountName: string;
    expiryInDays: number;
    productList: string[];
    additionalAttribs: Map<string, string>;
    duration: string;
    startTimeAMPM: string;
    isLive: boolean;
    gameId: string;
    jackpot: string;
    content: IContent;
    additionalParams: { [item: string]: string };
    beatBanker: { [item: string]: string };
    firstPlayTech: boolean;
    isOptinWithCoins: boolean;
    coinsForOptin: number;
}

export interface PrizeRule {
    points: number;
    ruleType: string;
    ruleConfig: number;
    ruleNo: number;
    slabList: PrizeRule[];
}

export interface SlotApiParams {
    slotInput: string;
    slotDuration?: number;
}

export interface SitecoreContent {
    textTranslations: GenericList;

    rules: Rule[];

    tips: ContentItem[];

    rulesBanner: any;

    placeholderCardImage: any;

    coinEconomy: any;

    raceRuleConfigs: RaceRuleConfig[];

    errorMessages: GenericList;

    entryDetails: GenericList;

    npmHeader?: any;
}

export interface RaceRuleConfig {
    multiplierDescription?: string;
    multiplierValue?: number;
    multiplierImage?: ContentImage;
    pointsDescription?: string;
    pointsImage?: ContentImage;
    RuleConfigValue?: number;
    pointsText?: string;
    pointValue?: number;
    spinsDescription?: string;
    spinsImage?: ContentImage;
    spinsText?: string;
    spinValue?: number;
    metadata?: Metadata;
    ruleConfigTitleV2?: string;
    ruleConfigDescriptionV2?: string;
}

export interface Metadata {
    id: ItemId;
}

export interface ItemId {
    itemName: string;
}

export interface GenericList {
    sharedList: { [item: string]: string };
    versionedList: { [item: string]: string };
}

export interface ResultsHistory {
    rank: number;
    playerName: string;
    points: number;
    slotStartDate: number;
    slotEndDate: number;
    startDate: Date;
    endDate: Date;
    dateStr: string;
    gameVariantName: string;
    gameVariantList: string[];
    isWin: boolean;
    gameIconSrc: string;
    prizeIcon: string;
    content: IContent;
    configMap: IConfigMap;
    configMaps: IConfigMap[];
    isBeatBanker: boolean;
}

export interface IContent {
    promotion: IPromotion;
    rules: Rule[];
}

export interface IPromotion {
    prizes: string;
    beatBanker: string;
    termsAndConditionsText: string;
    promoName: string;
    promoTitle: string;
    promoIcon: ContentImage;
    data: IData;
    metadata: Metadata;
    promoBackgroundImage: ContentImage;
    promoBackgroundColor: string;
    gameId?: string;
}

export interface IData {
    metadata: Metadata;
    fields: IFields;
}

export interface IFields {
    prizes: string;
    termsAndConditionsText: string;
}

export interface Rule {
    image: ContentImage;
    title: string;
    text: string;
    metadata: Metadata;
}

export interface SlotRacesResponseError {
    errorCode: number;
    exception: any;
    status: string;
    message: string;
}

export interface IConfigMap {
    promoId: string;
    awardType: string;
    value: string;
    slotId: string;
    currency: string;
}

export const LEADERBOARD_COLUMNS_DISPLAYED = ['rank', 'prize', 'prize-img', 'player', 'points'];
export const LEADERBOARD_COLUMNS_DISPLAYED_PH2 = ['rank', 'prize-img', 'player', 'points', 'prize'];

export interface ITournamentIcon {
    src: string;
    colspan: number;
}
export interface SourceInfo {
    frontend: string;
    Brand: string;
    Product: ProductBalance[];
    Channel: string;
    Source: string;
}

export interface ProductBalance {
    Product: string;
    Balance: string;
}
