import { Injectable } from '@angular/core';

import { CasinoLobbyService } from '../casino-lobby-manager/casino-lobby.service';
import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';

@Injectable({
    providedIn: 'root',
})
export class NewQuickInfoService {
    constructor(
        private api: PlatformApiService,
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
    ) {}

    getGameDescription(gameId: string | null, providerId: string | null) {
        if (gameId) {
            const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
            return this.api.get(apiPathsConfig.gameData['gameDataDescription'], { gameId: gameId, providerId: providerId });
        }
        return null;
    }

    getCategoryInfo(lobbyType: string) {
        const gymlCategory = this.casinoLobbyService.getCategoryInfo(lobbyType, 'LMC_GYML');
        return gymlCategory;
    }

    getGameCatalogDetails(gameId: string | null, vendorId: string | null) {
        if (gameId) {
            return this.api.post('QuickInfo/GetGameCatalogDetails', { gameId: gameId, vendorId: vendorId });
        }
        return null;
    }
}
