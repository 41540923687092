import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

import { Subscription, fromEvent, merge } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Directive({
    selector: '[ccLongPress]',
    standalone: true,
})
export class LongPressDirective implements OnDestroy {
    private eventSubscribe: Subscription;
    threshold = 500;

    @Output()
    onMouseLongPress = new EventEmitter<boolean>();

    constructor(private elementRef: ElementRef) {
        const touchstart = fromEvent(this.elementRef.nativeElement, 'touchstart').pipe(map(() => true));

        const touchEnd = fromEvent(this.elementRef.nativeElement, 'touchend').pipe(map(() => false));

        const mousedown = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'mousedown').pipe(
            filter((event) => event.button == 0),
            map(() => true),
        );

        const mouseup = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'mouseup').pipe(
            filter((event) => event.button == 0),
            map(() => false),
        );

        this.eventSubscribe = merge(mousedown, mouseup, touchstart, touchEnd).subscribe((value) => this.onMouseLongPress.emit(value));
    }

    ngOnDestroy(): void {
        if (this.eventSubscribe) {
            this.eventSubscribe.unsubscribe();
        }
    }
}
