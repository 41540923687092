import { Injectable } from '@angular/core';

import { Logger } from '@frontend/vanilla/core';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { CacheManagerService } from './cache-manager.service';
import { CasinoApiService } from './casino-api.service';
import { CasinoManager } from './casino-manager.service';

@Injectable({
    providedIn: 'root',
})
export class PromotedGamesService {
    promotedGames: any = [];
    promotedGamesFromAllLobby: any = [];
    private promotedGamesSubject = new BehaviorSubject<any>(null);
    promotedFeedObservable: Observable<any> = this.promotedGamesSubject.asObservable();
    private promotedGamesFromAllLobbySubject = new BehaviorSubject<any>(null);
    promotedFeedFromAllLobbyObservable: Observable<any> = this.promotedGamesFromAllLobbySubject.asObservable();

    constructor(
        private api: CasinoApiService,
        private configProviderService: ConfigProviderService,
        private casinoManager: CasinoManager,
        private cacheManager: CacheManagerService,
        private logger: Logger,
    ) {}

    setPromotedGames() {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        this.api.get(apiPathsConfig.gamesServices['promotedGames']).subscribe(
            (response: any) => {
                this.promotedGames = [];
                if (response) {
                    for (let i = 0; i < response.length; i++) {
                        this.promotedGames.push(response[i].metadata.id.itemName);
                    }
                    this.promotedGamesFromAllLobby = cloneDeep(this.promotedGames);
                    // this.promotedGamesFromAllLobby = {...this.promotedGames};
                    if (featuresConfig?.rpGamesFilterInLiveCasino && this.casinoManager.getCurrentLobby()?.toLowerCase() == 'livecasino')
                        this.filterLiveCasinoGames(this.promotedGames);
                    if (featuresConfig?.rpWidgetGamesFilterInLiveCasino) this.promotedGamesFromAllLobby = this.promotedGames;
                    this.promotedGamesSubject.next(this.promotedGames);
                    this.promotedGamesFromAllLobbySubject.next(this.promotedGamesFromAllLobby);
                }
            },
            (error) => {
                this.logger.error('unable to retrieve data from promotedGames API' + error);
            },
        );
    }

    filterLiveCasinoGames(promotedGames: any) {
        const categoryCoreLookUp: any = this.cacheManager.getAllCategoriesFromLocalCache('LiveCasino', this.casinoManager.getInvokerProduct());
        this.promotedGames = categoryCoreLookUp?.lsGameMetaDataMap?.map((x: any) => x && x[0])?.filter((y: any) => promotedGames?.includes(y)) || [];
    }

    getPromotedGames() {
        return this.promotedGames;
    }

    getPromotedGamesFromAllLobby() {
        return this.promotedGamesFromAllLobby;
    }
}
