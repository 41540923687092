import { NgClass, NgIf, UpperCasePipe } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
    BingoFavouriteService,
    BingoGameLaunchService,
    CasinoWidgetService,
    ConfigProviderService,
    CozyBingoDataService,
    FavouriteService,
    OpenLoginDialog,
} from '@casinocore/platform/core';
import { CurrencyPipe, UserService, WindowRef } from '@frontend/vanilla/core';

@Component({
    selector: 'cc-bingo-quick-info-model',
    templateUrl: 'bingo-quick-info-model.component.html',
    standalone: true,
    imports: [NgIf, NgClass, UpperCasePipe, CurrencyPipe],
})
export class BingoQuickInfoModelComponent implements OnInit {
    public hotIcon = 'https://casinogames.galaspins.com/htmllobby/images/newlmticons/square/hotjackpot.png';
    public favouriteStyle: string = 'theme-favorite-i';
    public unFavouriteStyle: string = 'theme-favorite';
    public isFavourite = false;
    public isLaunchedFromUrl: boolean;
    width: number;
    boxWidth: number;
    boxWidthInt: number;
    gameContainer: string;
    cozyRoomData: any;
    constructor(
        public dialogRef: MatDialogRef<BingoQuickInfoModelComponent>,
        private user: UserService,
        private windowRef: WindowRef,
        private bingoGameLaunchService: BingoGameLaunchService,
        private bingoFavouriteService: BingoFavouriteService,
        private loginDialog: OpenLoginDialog,
        private favouriteService: FavouriteService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private configProviderService: ConfigProviderService,
        private cozyBingoDataService: CozyBingoDataService,
        private casinoWidgetService: CasinoWidgetService,
    ) {
        this.setWidth();
    }

    ngOnInit() {
        if (this.data.fromCozybingo) {
            this.cozyRoomData = this.cozyBingoDataService.getRoomdatabyId(this.data.room.roomId.replace(/[^A-Z0-9]/gi, '').toLocaleLowerCase());
            if (this.cozyRoomData) {
                this.setCozyBingoRoomInfo(this.cozyRoomData);
                this.data.room.isFavourite = this.cozyBingoDataService.checkForFavouriteGame({ fromCasino: false, gameId: this.data.room.roomId });
            }
        }
        const global: any = this.configProviderService.provideGlobalConfig();
        if (
            global.lobbyConfigurations.FavouriteHeart === 'true' ||
            global.lobbyConfigurations.FavouriteHeart === 'True' ||
            global.lobbyConfigurations.FavouriteHeart === 'TRUE'
        ) {
            this.favouriteStyle = 'theme-heart-solid';
            this.unFavouriteStyle = 'theme-heart-outline';
        }
        this.gameContainer = this.data.gameContainer;
        if (document.querySelector('.casino-qi-noverlay') as HTMLElement) {
            (document.querySelector('.casino-qi-noverlay') as HTMLElement).style.cssText = global.messages.quickInfoStyles;
        }
    }

    setCozyBingoRoomInfo(cozyRoomInfo: any) {
        this.data.roomTitle = cozyRoomInfo.name;
        this.data.roomDescription = cozyRoomInfo.sitecoreInfo.description;
        this.data.src = cozyRoomInfo.sitecoreInfo.desktopImage.src;
        this.data.jackpotData = cozyRoomInfo.jackpot;
    }
    @HostListener('window:resize') onResize() {
        this.setWidth();
    }

    setWidth(): void {
        if (this.windowRef.nativeWindow.screen.availWidth > 480) {
            this.width = 333;
        } else if (this.windowRef.nativeWindow.screen.availWidth < this.windowRef.nativeWindow.screen.availHeight)
            this.width = this.windowRef.nativeWindow.screen.availWidth;
        else this.width = this.windowRef.nativeWindow.screen.availHeight;
        this.boxWidth = this.width;
        this.boxWidthInt = this.boxWidth - 176;
    }
    closeModel() {
        document.getElementsByTagName('html')[0].classList.remove('casino-quickinfo-open');
        document.getElementsByTagName('html')[0].classList.remove('cdk-global-scrollblock');
        this.dialogRef.close();
        if (document.querySelector('.cdk-overlay-container')) {
            (document.querySelector('.cdk-overlay-container') as HTMLElement).innerHTML = '';
        }
    }

    launchGame() {
        this.closeModel();
        if (this.data.fromCozybingo) {
            const gameObj: any = {
                position: this.data.position,
                section: this.data.section,
                widgetFrameClr: this.data.widgetFrameClr,
                gameContainerLocation: this.data.gameContainerLocation,
            };
            this.bingoGameLaunchService.initiateGameLaunch(
                this.cozyRoomData.name,
                'BINGO',
                this.cozyRoomData.bingo_type,
                'room',
                this.cozyRoomData.sitecoreInfo.desktopImage.src,
                this.cozyRoomData.id,
                true,
                '',
                gameObj,
            );
        }
    }

    toggleFavourite(room: any) {
        const id = this.cozyRoomData.id;
        if (!this.user.isAuthenticated) {
            this.closeModel();

            const loginResponseObj: any = {
                loginMessageKey: 'Favourite',
            };
            this.loginDialog.open(loginResponseObj);
        } else {
            if (room.isFavourite) {
                this.data.room.isFavourite = false;
                this.bingoFavouriteService.removeFavourite(id, this.user.id ? this.user.id : '').subscribe(
                    (response: any) => {
                        if (response.result) {
                            const request = {
                                categoryid: 'rcp-widget',
                                roomid: id,
                                isFavourite: false,
                            };
                            this.casinoWidgetService.postMessageToParent('favupdated', request);
                            this.favouriteService.publishingFavObervable(false, id);
                            this.cozyBingoDataService.getBingoData(false, false);
                        }
                    },
                    () => {},
                );
            } else {
                this.data.room.isFavourite = true;
                this.bingoFavouriteService.addFavourite(id, this.user.id ? this.user.id : '').subscribe(
                    (response: any) => {
                        if (response.result) {
                            this.favouriteService.publishingFavObervable(true, id);
                            const request = {
                                categoryid: 'rcp-widget',
                                roomid: id,
                                isFavourite: true,
                            };
                            this.casinoWidgetService.postMessageToParent('favupdated', request);
                            this.cozyBingoDataService.getBingoData(false, false);
                        }
                    },
                    () => {},
                );
            }
        }
    }
}
