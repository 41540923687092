import { Injectable } from '@angular/core';

import { NativeAppService, WindowRef } from '@frontend/vanilla/core';

import { ConfigProviderService } from '../config-provider/config-provider.service';

@Injectable({
    providedIn: 'root',
})
export class NativeApiService {
    mcPage: any;
    constructor(
        private nativeApplication: NativeAppService,
        private windowRef: WindowRef,
        private configProviderService: ConfigProviderService,
    ) {
        this.mcPage = this.configProviderService.provideMcPageConfig();
    }
    count: number = 0;
    raiseEvent(event: string, action?: string, parameters?: string) {
        const actionValue = action ? action : 'action';
        if (this.nativeApplication.isNative) {
            this.count++;
            const id = 'NativeApi_iframe_' + this.count;
            const frame = document.createElement('iframe');
            frame.id = id;
            frame.src = `bwinex://?event=${event}&${actionValue}=${parameters}`;
            frame.style.display = 'none';
            this.windowRef.nativeWindow.document.body.appendChild(frame);
            return id;
        }
        return null;
    }

    raiseEventCommon(eventInfo: string) {
        if (this.nativeApplication.isNative || this.nativeApplication.isNativeWrapper) {
            this.count++;
            const id = 'NativeApi_iframe_' + this.count;
            const frame = document.createElement('iframe');
            frame.id = id;
            frame.src = `bwinex://${eventInfo}`;
            frame.style.display = 'none';
            this.windowRef.nativeWindow.document.body.appendChild(frame);
            return id;
        }
        return null;
    }

    raiseTeaserEvent(prevEventId: string, eventInfo: string) {
        if (prevEventId) {
            this.clearEvent(prevEventId);
        }

        return this.raiseEventCommon(eventInfo);
    }
    // NFR - Services optimization - Remove
    processPromoEvent(link: string, eventId: string) {
        const index = link.indexOf('?event=promo');
        if (index === -1) {
            return null;
        }
        if (eventId !== null) {
            this.clearEvent(eventId);
        }
        return this.raiseEventCommon(link.substr(index));
    }
    // NFR - Services optimization - Remove
    processLobbyEvent(link: string, eventId: string) {
        const index = link.indexOf('?event=lobby');
        if (index === -1) {
            return null;
        }
        if (eventId !== null) {
            this.clearEvent(eventId);
        }
        return this.raiseEventCommon(link.substr(index));
    }

    raiseOpenGameEvent(prevEventId: string, openUrl: string, gameId: string, isAuthenticated: boolean) {
        if (prevEventId) {
            this.clearEvent(prevEventId);
        }

        if (!isAuthenticated) {
            return this.raiseEvent('lobby', 'opengame', 'gameId=' + gameId);
        }

        return this.raiseEvent('lobby', 'opengame', 'url=' + openUrl);
    }

    raiseNativeAppEvent(eventInfo: string) {
        if (this.nativeApplication.isNative) {
            this.count++;
            const nativeScheme = this.mcPage.nativeAppScheme;
            const id = 'NativeApi_iframe_' + this.count;
            const frame = document.createElement('iframe');
            frame.id = id;
            frame.src = `${nativeScheme}://${eventInfo}`;
            frame.style.display = 'none';
            this.windowRef.nativeWindow.document.body.appendChild(frame);
            return id;
        }
        return null;
    }

    // NFR - Services optimization - Remove
    targetEvent(event: string, scheme: string = 'bwinex://') {
        if (this.nativeApplication.isNative) {
            const iframe: any = document.createElement('iframe');
            iframe.src = scheme + event;

            document.documentElement.appendChild(iframe);
            iframe.parentNode.removeChild(iframe);
        }
    }

    clearEvent(id: string) {
        if (this.nativeApplication.isNative && id !== null) {
            this.windowRef?.nativeWindow?.document?.getElementById(id)?.remove();
        }
    }
}
