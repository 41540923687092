import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { GamePremiereComponent } from './game-premiere.component';

@NgModule({
    imports: [CommonModule, GamePremiereComponent, BrowserModule],
    exports: [GamePremiereComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GamePremiereModule {
    static rootComponent = GamePremiereComponent;
    public static forRoot(): ModuleWithProviders<GamePremiereModule> {
        return {
            ngModule: GamePremiereModule,
            providers: [],
        };
    }
}
