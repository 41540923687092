import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';

import { CasinoManager, ConfigProviderService, CustomCarouselPaginationComponent, OptimizedTeaserManager } from '@casinocore/platform/core';
import { DslPipe } from '@frontend/vanilla/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper } from 'swiper/types';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { OptMiniTeaserItemComponent } from './opt-mini-teaser-item.component';

@Component({
    selector: 'cc-opt-mini-teasers',
    templateUrl: 'opt-mini-teasers.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, MatGridListModule, OptMiniTeaserItemComponent, AsyncPipe, DslPipe, SwiperComponent, CustomCarouselPaginationComponent, NgIf],
})
export class OptMiniTeasersComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() teaserCategory: string;
    @Input() teasersSource: any;
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    @ViewChild('miniCarousel') swiperRef: SwiperComponent;
    miniTeasersData: any;
    hidePagination: boolean = true;
    carouselConfig: any;
    currentIndex: number = 0;
    carouselTileItems: Array<Array<any>>;
    iconTypeMapping = new Map<number, any>();
    miniTeaserRowHeight: number;
    autoPlay: any;
    enablePagenation: string;
    globalTeaserSettings: any;
    showPagination: boolean = false;
    readonly isTouch = this.casinoManager.isTouch();

    private swiperOptionsBase: SwiperOptions = {
        modules: [Autoplay, Pagination, Navigation],
        autoHeight: true,
        speed: 500,
        navigation: !this.isTouch,
        observer: true,
        observeParents: true,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
            waitForTransition: false,
        },
    };

    swiperOptionsOptMiniTeasers: SwiperOptions = {
        ...this.swiperOptionsBase,
        spaceBetween: 1,
        slidesPerView: 'auto',
        pagination: true,
        resizeObserver: true,
        observeSlideChildren: true,
        updateOnWindowResize: true,
        watchSlidesProgress: true,
        on: {
            ...this.swiperOptionsBase.on,
            beforeResize: (swiper) => this.onSwiperObserverUpdate(swiper),
            observerUpdate: (swiper) => this.onSwiperObserverUpdate(swiper),
            resize: (swiper) => this.onSwiperObserverUpdate(swiper),
        },
    };

    constructor(
        private configProviderService: ConfigProviderService,
        private optimizedTeaserManager: OptimizedTeaserManager,
        private casinoManager: CasinoManager,
    ) {}

    ngOnInit() {
        this.miniTeaserRowHeight = this.configProviderService.provideOptimizedTeaserConfig().desktopViewTeaserHeight / 2;
    }

    private initCarouselConfig() {
        this.carouselConfig = {
            arrows: false,
            dots: false,
            loop: false,
        };
        this.autoPlay = {
            delay: 6000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        };
    }

    ngAfterViewInit() {
        if (this.swiperRef) {
            this.showPagination = true;
            this.swiperRef.swiper.on('slideChange', (event: any) => {
                this.sliderChanged(event);
            });
        }
    }

    private setIconType() {
        const gameConfig: any = this.configProviderService.provideGameConfig();
        gameConfig?.iconTypeMapping?.forEach((_item: any, key: number) => {
            switch (key) {
                case 2:
                    this.iconTypeMapping.set(key, { rowSpan: 1, colSpan: 2 });
                    break;
                case 4:
                    this.iconTypeMapping.set(key, { rowSpan: 2, colSpan: 2 });
                    break;
                default:
                    this.iconTypeMapping.set(1, { rowSpan: 1, colSpan: 1 });
                    break;
            }
        });
    }

    private prepareCarouselItems() {
        let size = 0;
        let i: number = 1;
        const maxGridSize: number = 4;
        let matGridItems: any = [];
        this.carouselTileItems = [];
        this.miniTeasersData.forEach((m: any) => {
            m.index = i++;
            if (!m.hasOwnProperty('iconTileCode') || [...this.iconTypeMapping.keys()].indexOf(m.iconTileCode) < 0) {
                m.iconTileCode = 1;
            }
            const area = this.iconTypeMapping.get(m.iconTileCode);
            size += area.colSpan * area.rowSpan;
            const item: any = Object.assign(m, { colSpan: area.colSpan, rowSpan: area.rowSpan });
            if (size > maxGridSize) {
                this.carouselTileItems.push(matGridItems);
                size = item.colSpan * item.rowSpan;
                matGridItems = [];
                matGridItems.push(item);
            } else if (size === maxGridSize) {
                matGridItems.push(item);
                this.carouselTileItems.push(matGridItems);
                size = 0;
                matGridItems = [];
            } else {
                matGridItems.push(item);
            }
        });
        if (matGridItems.length) {
            this.carouselTileItems.push(matGridItems);
            size = 0;
            matGridItems = [];
        }
    }

    sliderChanged(swiperObj: any) {
        if (swiperObj) {
            this.currentIndex = swiperObj.realIndex;
        }
    }

    ngOnChanges() {
        this.initCarouselConfig();
        this.miniTeasersData = this.teasersSource;
        this.globalTeaserSettings = this.optimizedTeaserManager.getGlobalTeaserSettings();
        this.enablePagenation = this.globalTeaserSettings.sharedList.enablePagination;
        if (this.miniTeasersData && this.miniTeasersData.length) {
            this.miniTeasersData.length > 1 && this.enablePagenation == 'true' ? (this.hidePagination = false) : (this.hidePagination = true);
            this.carouselConfig.arrows = true;
            this.carouselConfig.dots = true;
            this.carouselConfig.loop = this.miniTeasersData.length > 1 ? true : false;
            this.setIconType();
            this.prepareCarouselItems();
        } else {
            this.hidePagination = true;
            this.carouselConfig.arrows = false;
            this.carouselConfig.dots = false;
            this.carouselConfig.loop = false;
        }
    }
    private onSwiperObserverUpdate(swiper: Swiper): void {
        this.setSwiperTeaserConfig(swiper);
        swiper.update();
    }
    private setSwiperTeaserConfig(swiper: Swiper): void {
        let swiperConfigs = swiper.params;
        if (this.carouselTileItems.length < 2) swiperConfigs.navigation = false;
    }
}
