import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {
    CasinoIFrameOverlayService,
    CasinoLobbyService,
    CasinoManager,
    CasinoWidgetService,
    ConfigProviderService,
    GameDataManager,
    GameLobbyModel,
    GlobalTranslateComponent,
    InvokerProductService,
    LiveCasinoService,
    OptimizedTeaserManager,
    RecentGamesService,
    SlotRacesService,
    SlotRacesTrackingService,
    TeaserService,
    UrlUtilityService,
    UtilityService,
} from '@casinocore/platform/core';
import { TeasersWrapperComponent } from '@casinocore/platform/teasers';
import { DeviceService, NavigationService, Page, TrackingService, UserService } from '@frontend/vanilla/core';
import { cloneDeep } from 'lodash-es';
import { Subscription } from 'rxjs';

import { ArcadeFeatureGridComponent } from '../arcade-feature-grid/arcade-feature-grid.component';
import { CasinoCoreLazyPlaceholderComponent } from '../casino-core-lazy-placeholder/casino-core-lazy-placeholder.component';
import { CategoryiconSubtitleMoreinfoComponent } from '../categoryicon-subtitle/categoryicon-subtitle-moreinfo/categoryicon-subtitle-moreinfo.component';
import { CategoryiconSubtitleComponent } from '../categoryicon-subtitle/categoryicon-subtitle.component';
import { FeatureGridComponent } from '../feature-grid/feature-grid.component';
import { MultiJackpotwidgetRebrandComponent } from '../multi-jackpot-widget-rebrand/multi-jackpot-widget-rebrand.component';
import { MultiJackpotwidgetComponent } from '../multi-jackpot-widget/multi-jackpot-widget.component';
import { MustGoJackpotRedesignNewComponent } from '../must-go-redesign-new/must-go-redesign-new.component';
import { MustGoJackpotComponent } from '../must-go/must-go.component';
import { PlayerStatFiltersComponent } from '../player-stats/player-stat-filters.component';
import { ProminentFreespinsWidgetComponent } from '../prominent-freespins-widget/prominent-freespins-widget.component';
import { RecentWinnersComponent } from '../recent-winners/recent-winners.component';
import { SignPostingComponent } from '../sign-posting/sign-posting.component';
import { SingleJackpotRedesignWidgetComponent } from '../single-jackpot-redesign-widget/single-jackpot-redesign-widget.component';
import { SingleJackpotWidgetComponent } from '../single-jackpot-widget/single-jackpot-widget.component';

@Component({
    selector: 'cc-lmt-sub-category-lobby',
    templateUrl: 'lmt-sub-category-lobby.component.html',
    standalone: true,
    imports: [
        NgClass,
        CasinoCoreLazyPlaceholderComponent,
        NgStyle,
        SignPostingComponent,
        CategoryiconSubtitleComponent,
        GlobalTranslateComponent,
        TeasersWrapperComponent,
        FeatureGridComponent,
        RecentWinnersComponent,
        SingleJackpotWidgetComponent,
        SingleJackpotRedesignWidgetComponent,
        MultiJackpotwidgetComponent,
        MultiJackpotwidgetRebrandComponent,
        MustGoJackpotComponent,
        MustGoJackpotRedesignNewComponent,
        ProminentFreespinsWidgetComponent,
        ArcadeFeatureGridComponent,
        PlayerStatFiltersComponent,
    ],
})
export class LMTSubCategoryLobbyComponent implements OnChanges, OnInit, OnDestroy {
    @Input() subcategoryId: string;
    @Input() subcategory: any;
    @Input() isTouch: boolean;
    @Input() trackingIndex: any = 1;
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    @Input() coinExpiryIndex: number;
    @Input() seeAllProductLinkOverride: string;

    SubCategoryTeaser: string = 'Embedded';
    validGames: any[];
    subCategoryInfo: any;
    categoryBackgroundFull: boolean;
    themeArrowLeft: string;
    themeArrowRight: string;
    isLastNavigationLevel: boolean = false;
    subCategorySubTitleFontSize: string;
    seeAllGamesCountCategory: any;
    seeAllGamesCount: number;
    showSeeAllGamesCount: boolean;
    subCategoryStyle: string;
    showTeasersAboveGames: boolean;
    enableEmbeddedTeasers: boolean;
    Games: any = [];
    headerTeasers: any[] = [];
    loadnextGamesObs: any;
    loadNewSet: boolean = true;
    gamesLoadCount: number;
    startPosition: any;
    showTeasers: boolean;
    isUkHeading: boolean;
    isSignPosting: boolean;
    changeCarouselView: boolean;
    alignIconRight: boolean;
    offlineGamesArray: any = [];
    liveGameInfo: any;
    disabledGameArray: any = [];
    optimizedHeaderTeasers: any;
    isEnableOptimizedTeasers: boolean;
    optimizedEmbeddedAboveTeasers: any[] = [];
    optimizedEmbeddedBelowTeasers: any[] = [];
    isShowEmbeddedAbove: boolean = false;
    isShowEmbeddedBelow: boolean = false;
    teaserObs: Subscription;
    mostRecentlyPlayedRoutesKey: string;
    mostRecentlyPlayedRouteInfo: any;
    mostRecentlyPlayedGamesArr: any[] = [];
    recentGamesObs: Subscription;
    MustGoSitecoreObject: any;
    parallaxInput: any;
    trackingClass: string = '';
    videoType: string;
    videoUrl: string;
    launchUrl: any;
    imageThumbnail: any;
    videoName: string;
    displayVideoinLoop: boolean;
    gameTileBorderRadius: string;
    gameTileDropShadow: string;
    newFeatureGridArr: any[] = [];
    newgridbgcolor: string;
    amazonstylebottombgclr: string;
    videoBoxContent: any;
    lobbyTileFontFamily: string;
    subCategoryTextColor: string;
    subCategoryTitleFontSize: string;
    isEnabledGameSelector: boolean;
    gameSelectorWidgetPosition: string;
    isSuperButtonEnabled: boolean;
    superButtonPosition: string;
    displayPromoEDSWidget: boolean;
    promoEDSWidgetPosition: string;
    filterThreshold: number;
    recentGames: string[];
    skipRPCount: number = 0;
    isSlotRacesEnabled: boolean;
    isSlotRacesCategory: boolean;
    seeAllText: string;
    carouselData: any;
    slotRacesSubscription: Subscription;
    alreadyFilteredRecentGames: string[] = [];
    enableEpcotSubNavigation: boolean;
    enableSingleJackpot: boolean;
    enableMultiJackpot: boolean;
    enableMustGoJackpot: boolean;
    @ViewChild('featureGrid') featureGrid: FeatureGridComponent;
    isJackpotdataForParallax: boolean = false;
    isEnabledRecentWinners: boolean;
    recentWinnersWidgetPosition: any;
    //lastScrollPoint:number = 0;
    isBingoTournamentsCategory: boolean;
    isDisplayProminentFreeSpinsWidget: boolean;
    enableProminentFreespinsDisplay: boolean;
    jackpotMoreInfoEnable: boolean;
    currentLobbyType: string;
    arcadeLobbyGameConfig: any;
    arcadeLobbyMessages: any;
    arcadeCoinExpiry: number;
    ShowArcadeCoinExpiry: boolean = false;
    showCoinExpiryText: boolean = false;
    coinBalSubscription: Subscription;
    arcadeCoinExpiryBgClr: any;
    coinArcadeExpirytitle1Clr: any;
    coinArcadeExpirytitle2BgClr: any;
    coinArcadeExpirytitle2Clr: any;
    coinArcadeExpiryDaysClr: any;
    coinArcadeExpiryDaysBgClr: any;
    enablePlayerstats: boolean;
    playerStatsWidgetPosition: string;
    displayEdsSmartWidget: boolean;
    ArcadeRecommendedStyles: string;
    isSlotRacesPhase2Enabled: boolean;
    readonly showFeatureGrid = signal(true);
    constructor(
        private configProviderService: ConfigProviderService,
        private mcPage: Page,
        private casinoManager: CasinoManager,
        private casinoLobbyService: CasinoLobbyService,
        private gameDataManager: GameDataManager,
        private elementRef: ElementRef,
        private utility: UtilityService,
        private casinoIFrameOverlayService: CasinoIFrameOverlayService,
        private liveCasinoService: LiveCasinoService,
        private casinoWidgetService: CasinoWidgetService,
        private trackingService: TrackingService,
        private optimizedTeaserManager: OptimizedTeaserManager,
        private teaserService: TeaserService,
        private navigation: NavigationService,
        private urlUtilityService: UrlUtilityService,
        private recentGamesService: RecentGamesService,
        private slotRacesTracking: SlotRacesTrackingService,
        private slotRacesService: SlotRacesService,
        private deviceService: DeviceService,
        private user: UserService,
        private categoryiconSubtitleMoreinfo: MatDialog,
        private tracking: TrackingService,
        private invokerProductService: InvokerProductService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.assignFeatures();
        this.assignVideoParams();
        this.subscribeToLoadNextGames();
        this.subscribeToElementEventListeners();
        this.subcategory.categoryInfo.categoryIndex = this.trackingIndex + 1;
        this.liveCasinoService.liveGamesFeedObservable.subscribe((response) => {
            if (response && response.size > 0) {
                this.setOfflinesLivecasino();
            }
        });

        this.setTeasers();
        if (this.enableEmbeddedTeasers || this.configProviderService.provideOptimizedTeaserConfig().teaserOptimization) {
            this.teaserObs = this.teaserService.teaserDataObservable.subscribe((_response: any) => {
                if (_response) {
                    this.setTeasers();
                }
            });
        }
        this.parallexInputData();
        if (this.validGames && this.validGames.length > 0) {
            this.trackingClass = 'gametracking track_' + this.subcategoryId;
        } else if (this.subCategoryInfo && this.subCategoryInfo.sitecoreData) {
            if (this.subCategoryInfo.sitecoreData.showJackpotWidget && this.subCategoryInfo.sitecoreData.jackpotWidgetIds) {
                if (this.subCategoryInfo.sitecoreData.jackpotWidgetIds.length > 0 || this.subCategoryInfo.sitecoreData.videoType) {
                }
                this.trackingClass = 'gametracking track_' + this.subcategoryId;
            }
        }
        this.arcadeCoinExpiry = this.casinoLobbyService.arcadeCoinExpiry;
    }

    assignFeatures() {
        this.arcadeLobbyMessages = this.configProviderService.provideGlobalConfig()?.arcadeLobbyConfigurations;
        this.arcadeCoinExpiryBgClr = this.arcadeLobbyMessages.ArcadeCoinExpiryBgClr;
        this.coinArcadeExpirytitle1Clr = this.arcadeLobbyMessages.CoinArcadeExpirytitle1Clr;
        this.coinArcadeExpirytitle2BgClr = this.arcadeLobbyMessages.CoinArcadeExpirytitle2BgClr;
        this.coinArcadeExpirytitle2Clr = this.arcadeLobbyMessages.CoinArcadeExpirytitle2Clr;
        this.coinArcadeExpiryDaysClr = this.arcadeLobbyMessages.CoinArcadeExpiryDaysClr;
        this.coinArcadeExpiryDaysBgClr = this.arcadeLobbyMessages.CoinArcadeExpiryDaysBgClr;
        this.ArcadeRecommendedStyles = this.arcadeLobbyMessages.ArcadeRecommendedStyles;
        const features: any = this.configProviderService.provideFeaturesConfig();
        const subnavConfig: any = this.configProviderService.provideSubNavigationConfig();
        this.arcadeLobbyGameConfig = this.configProviderService.provideGameConfig()?.arcadeLobbyType.toLowerCase();
        this.currentLobbyType = this.casinoManager.getCurrentLobby()?.toLowerCase();
        this.showTeasersAboveGames = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.showEmbeddedTeasersAboveGames);
        this.isUkHeading = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.isUkHeading);
        this.isSignPosting = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.enableSignpostingSticker);
        this.changeCarouselView = features?.changeCarouselView;
        const global: any = this.configProviderService.provideGlobalConfig();
        this.subCategorySubTitleFontSize = this.casinoLobbyService.getsubCategorySubTitleFontSize(this.subCategoryInfo);
        this.categoryBackgroundFull = global.messages.CategoryBgFull;
        this.themeArrowLeft = global.messages.ThemeArrowLeft;
        this.themeArrowRight = global.messages.ThemeArrowRight;
        this.enableEpcotSubNavigation = subnavConfig.enableEpcotSubNavigation;

        const gameSelectorConfig: any = this.configProviderService.provideGameSelectorWidgetConfig();
        const superButtonConfig: any = this.configProviderService.provideSuperButtonConfig();
        const promoEdsConfig: any = this.configProviderService.providewidgetConfig();
        this.isEnabledGameSelector =
            gameSelectorConfig?.isEnabled &&
            this.subCategoryInfo &&
            this.subCategoryInfo.sitecoreData &&
            this.subCategoryInfo.sitecoreData.displayGameSelectorWidget;
        this.gameSelectorWidgetPosition = this.isEnabledGameSelector ? this.subCategoryInfo?.sitecoreData.gameSelectorWidgetPosition : undefined;
        this.isSuperButtonEnabled =
            superButtonConfig?.enabled && this.subCategoryInfo?.sitecoreData?.displaySuperButton && this.deviceService.isMobilePhone;
        this.superButtonPosition = this.isSuperButtonEnabled ? this.subCategoryInfo?.sitecoreData?.superButtonPosition : undefined;
        this.displayEdsSmartWidget = features?.displayEdsSmartWidget;
        const checkPromoEdsAuthentication = this.displayEdsSmartWidget ? true : this.user.isAuthenticated;
        this.displayPromoEDSWidget =
            promoEdsConfig?.enablePromoEdsWidget &&
            this.subCategoryInfo?.sitecoreData?.displayPromoEDSWidget &&
            this.gameContainer != 'Overlay' &&
            checkPromoEdsAuthentication;
        this.promoEDSWidgetPosition = this.subCategoryInfo?.sitecoreData?.promoEDSWidgetPosition;
        let slotRacesConfig = this.configProviderService.provideSlotRacesConfig();
        this.isSlotRacesPhase2Enabled = slotRacesConfig?.enableSlotRacesPhase2;
        if (this.subcategory.categoryInfo.filterRecentlyPlayedGames) {
            this.filterThreshold = this.configProviderService.provideRecentlyPlayedGamesConfig().recentlyPlayedFilterThreshold;
            if (
                this.subcategory &&
                this.subcategory.categoryInfo.sitecoreData &&
                this.subcategory.categoryInfo.sitecoreData.recentlyPlayedFilterThreshold &&
                this.subcategory.categoryInfo.sitecoreData.recentlyPlayedFilterThreshold != 0
            ) {
                this.filterThreshold = this.subcategory.categoryInfo.sitecoreData.recentlyPlayedFilterThreshold;
            }
        }
        const parentCategoryId = this.subcategory.categoryInfo.parentCategoryId;
        this.isSlotRacesEnabled = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.enableSlotRacesForLobbyType);
        if (this.isSlotRacesEnabled && parentCategoryId + this.casinoManager.getSlotRaceCategoryId(this.lobbyType) === this.subcategoryId) {
            this.isSlotRacesCategory = true;
        }
        this.showSeeAllGamesCount = features.showSeeAllGamesCount && !this.isSlotRacesCategory;
        if (this.isSlotRacesCategory) {
            this.slotRacesSubscription = this.slotRacesService.content.subscribe((content: any) => {
                this.seeAllText = content?.textTranslations?.versionedList?.SeeUpcoming ?? 'See Upcoming';
            });
        } else {
            this.seeAllText = global.messages.SeeAll;
        }
        const bingoNPM = this.configProviderService.provideModularBingoClientConfig();
        if (
            bingoNPM?.isEnabled &&
            bingoNPM?.isBingoTournamentsEnabled &&
            this.invokerProductService?.getInvokerProduct()?.toLowerCase() == 'casino'
        ) {
            if (parentCategoryId + bingoNPM?.bingoTournamentsRoute === this.subcategoryId) {
                this.isBingoTournamentsCategory = true;
                (window as any).Disable_SchedulePolling = false;
            } else {
                this.isBingoTournamentsCategory = false;
                (window as any).Disable_SchedulePolling = true;
            }
        }
        const jackpotDataConfig = this.configProviderService.provideJackpotConfig();
        this.enableSingleJackpot = jackpotDataConfig.enableSingleJackpot;
        this.enableMultiJackpot = jackpotDataConfig.enableMultiJackpot;
        this.enableMustGoJackpot = jackpotDataConfig.enableMustGoJackpot;
        // recent winners
        const latestWinnersConfig: any = this.configProviderService.provideRecentWinnersWidgetConfig();
        this.isEnabledRecentWinners =
            latestWinnersConfig?.isEnabled &&
            this.subCategoryInfo &&
            this.subCategoryInfo.sitecoreData &&
            this.subCategoryInfo.sitecoreData.displayRecentWinnersWidget;
        this.recentWinnersWidgetPosition = this.isEnabledRecentWinners ? this.subCategoryInfo?.sitecoreData.recentWinnersWidgetPosition : undefined;
        // prominent free spins
        this.isDisplayProminentFreeSpinsWidget = this.subCategoryInfo && this.subCategoryInfo?.sitecoreData?.displayProminentFreeSpinsWidget;
        this.enableProminentFreespinsDisplay = this.configProviderService.providefreeSpinsClientConfig().isEnableProminentFreeSpinsDisplay;
        this.jackpotMoreInfoEnable = jackpotDataConfig.jackpotMoreInfoEnable;
        this.ShowArcadeCoinExpiry = this.coinExpiryIndex == 1 && this.arcadeLobbyGameConfig == this.currentLobbyType && this.user.isAuthenticated;
        if (this.ShowArcadeCoinExpiry) {
            if (this.arcadeLobbyMessages?.CoinExpiryStaticBannerText) this.showCoinExpiryText = true;
            else this.subscribeToCoinBalance();
        }
        const playerStatsConfig: any = this.configProviderService.providePlayerStatsWidgetConfig();
        this.enablePlayerstats =
            playerStatsConfig?.enablePlayerstats &&
            this.subCategoryInfo &&
            this.subCategoryInfo?.sitecoreData &&
            this.subCategoryInfo?.sitecoreData.displayPlayerStats &&
            this.user.isAuthenticated;
        this.playerStatsWidgetPosition = this.enablePlayerstats ? this.subCategoryInfo?.sitecoreData.playerStatsWidgetPosition : undefined;
    }

    assignVideoParams() {
        const features: any = this.configProviderService.provideFeaturesConfig();
        const enableVideoPhase2 = features.enableVideoPhase2;
        if (this.subCategoryInfo && this.subCategoryInfo.sitecoreData) {
            if (enableVideoPhase2) {
                if (this.subCategoryInfo.sitecoreData.casinoVideo) {
                    this.videoType = this.subCategoryInfo.sitecoreData.casinoVideo.videoType;
                    const cmsUrlArray: string[] = [
                        this.subCategoryInfo.sitecoreData.casinoVideo?.cmsVideoUrlVersioned?.id,
                        this.subCategoryInfo.sitecoreData.casinoVideo?.cmsVideoUrlShared?.id,
                    ];
                    const videoUrlArray: string[] = [
                        this.subCategoryInfo.sitecoreData.casinoVideo.videoUrlVersioned,
                        this.subCategoryInfo.sitecoreData.casinoVideo.videoUrlShared,
                    ];
                    const videoFiltered = videoUrlArray.filter((x) => x !== undefined && x !== null);
                    const cmsFiltered = cmsUrlArray.filter((x) => x !== undefined && x !== null);
                    if (cmsFiltered.length > 0) {
                        this.videoUrl = cmsFiltered[0];
                        this.videoType = 'cms';
                    } else if (videoFiltered.length > 0) {
                        this.videoUrl = videoFiltered[0];
                    }
                    this.launchUrl = this.subCategoryInfo.sitecoreData.casinoVideo.link;
                    this.imageThumbnail = this.subCategoryInfo.sitecoreData.casinoVideo.thumbnailImage;
                    this.videoName = this.subCategoryInfo.sitecoreData.casinoVideo.videoName;
                    this.displayVideoinLoop = this.subCategoryInfo.sitecoreData.casinoVideo.displayVideoinLoop;
                    this.videoBoxContent = this.subCategoryInfo.sitecoreData.casinoVideo.videoBoxContent;
                }
            } else {
                this.videoType = this.subCategoryInfo.sitecoreData.videoType;
                this.videoUrl = this.subCategoryInfo.sitecoreData.imageOrVideoUrl;
                this.launchUrl = this.subCategoryInfo.sitecoreData.launchUrl;
                this.imageThumbnail = this.subCategoryInfo.sitecoreData.imageThumbnail;
                this.videoName = this.subCategoryInfo.sitecoreData.videoName;
            }
        }
    }

    ngOnChanges() {
        this.assignFeatures();
        this.assignVideoParams();
        this.validGames = [];
        this.newFeatureGridArr = [];
        this.offlineGamesArray = [];
        this.parallexInputData();
        if (this.subcategory && this.subcategory.categoryInfo) {
            this.subCategoryInfo = this.subcategory.categoryInfo;
            this.gameTileDropShadow = this.casinoLobbyService.getDropShadow(this.subCategoryInfo);
            this.newgridbgcolor = this.subCategoryInfo?.sitecoreData?.attributes?.newgridbgcolor;
            this.amazonstylebottombgclr = this.subCategoryInfo?.sitecoreData?.attributes?.amazonstylebottombgclr;
            this.lobbyTileFontFamily = this.casinoLobbyService.getLTileFontFamily(this.subCategoryInfo);
            this.subCategoryTextColor = this.casinoLobbyService.getsubCategoryTextColor(this.subCategoryInfo);
            this.subCategoryTitleFontSize = this.casinoLobbyService.getsubCategoryTitleFontSize(this.subCategoryInfo);
            this.subCategoryInfo.categoryIndex = this.trackingIndex + 1;
            this.mostRecentlyPlayedRouteInfo = this.recentGamesService.getMostRecentlyPlayedRouteInfo(this.subcategoryId);
            const recentlyPlayedClientConfig = this.configProviderService.provideRecentlyPlayedGamesConfig();
            if (this.mostRecentlyPlayedRouteInfo && recentlyPlayedClientConfig.isMostRecentlyPlayedEnabled) {
                this.mostRecentlyPlayedRoutesKey = this.mostRecentlyPlayedRouteInfo?.mostPlayedRoute?.toLowerCase();
            }
            if (this.subCategoryInfo) {
                if (this.subcategoryId?.toLowerCase() == this.mostRecentlyPlayedRoutesKey) {
                    this.bindMostRecentlyPlayedGames();
                }
                //if(window.scrollY < 200 || window.scrollY >= this.lastScrollPoint)
                this.setValidGames();
            }
            this.isEnableOptimizedTeasers = this.configProviderService.provideOptimizedTeaserConfig().teaserOptimization;
            this.enableEmbeddedTeasers = this.casinoManager.IsFeatureEnabledforLobbytype(
                this.lobbyType,
                this.configProviderService.provideFeaturesConfig().enableEmbeddedTeasers,
            );
            this.setTeasers();
        }
        this.subCategoryStyle = this.setSubCategoryStyle();
    }

    setValidGames(fromRecentGamesObs: boolean = false) {
        this.validGames = [];
        this.Games = [];
        if (this.subCategoryInfo.gamelist && this.subCategoryInfo.gamelist.length > 0) {
            let isCategoryHasAllIcon7: boolean = true;
            let isCategoryHasAllIcon6: boolean = true;
            let categoryWithMultipleGameIconTypes: boolean = false;
            for (let i = 0; i < this.subCategoryInfo.gamelist.length; i++) {
                const firstGameIcon = this.subCategoryInfo.gamelist[0].icon ? Math.abs(this.subCategoryInfo.gamelist[0].icon) : 1;
                const gameObj: any = this.subCategoryInfo.gamelist[i];
                //TODO: filter out disable games
                const gameInfo: any = this.casinoLobbyService.getGameInfo(gameObj.game);
                if (gameInfo) {
                    const gameIcon = gameObj.icon ? Math.abs(gameObj.icon) : 1;
                    if (gameObj.icon != '6' && gameObj.icon != '-6') {
                        isCategoryHasAllIcon6 = false;
                    }
                    if (gameObj.icon != '7' && gameObj.icon != '-7') {
                        isCategoryHasAllIcon7 = false;
                    }
                    if (firstGameIcon != gameIcon) {
                        categoryWithMultipleGameIconTypes = true;
                    }
                    let disabledGame: boolean = false;
                    const disabledGames = this.casinoLobbyService.getDisabledGames();
                    disabledGames.forEach((game: any) => {
                        if (game?.toLowerCase() == gameInfo?.game?.toLowerCase()) {
                            disabledGame = true;
                        }
                    });
                    if (!disabledGame) {
                        gameObj.name = gameInfo.name;
                        gameObj.provider = gameInfo.provider;
                        gameObj.sid = gameInfo.sid;
                        gameObj.ownerId = gameInfo.ownerId;
                        gameObj.gameIndex = i;
                        this.validGames.push(gameObj);
                    }
                }
            }
            if (isCategoryHasAllIcon6) {
                this.subCategoryInfo.isIconSixCategory = true;
            }
            if (isCategoryHasAllIcon7) {
                this.subCategoryInfo.isIconSevenCategory = true;
                if (this.subCategoryInfo.rowCount == '-3') {
                    this.subCategoryInfo.displayGameIndex = true;
                }
            }
            if (categoryWithMultipleGameIconTypes) {
                this.subCategoryInfo.categoryWithMultipleGameIconTypes = true;
            }
            this.gameTileBorderRadius = this.casinoLobbyService.getBorderRadius(this.subCategoryInfo);
            this.assignSeeAllGameCount();
            const casinoConfig: any = this.configProviderService.provideCasinoConfig();
            this.gamesLoadCount = this.isCategoryScrollable()
                ? casinoConfig.gamesLoadCount.loadHorizontalGamesCount
                : casinoConfig.gamesLoadCount.loadVerticalGamesCount;
            const absoluteRowCount = Math.abs(this.subCategoryInfo.rowCount);
            if (absoluteRowCount > 1) {
                this.gamesLoadCount = this.gamesLoadCount * absoluteRowCount;
            }
            this.gamesLoadCount = this.arcadeLobbyGameConfig == this.currentLobbyType ? this.validGames?.length : this.gamesLoadCount;
            if (this.validGames && this.validGames.length > 0) {
                this.setGames(this.validGames.slice(0, this.gamesLoadCount), fromRecentGamesObs, false);
            }
        }
    }

    setTeasers() {
        if (this.enableEmbeddedTeasers && !this.configProviderService.provideOptimizedTeaserConfig().teaserOptimization) {
            this.setEmbeddedTeasersForSubCategory(this.subcategory.categoryInfo);
        }
        if (this.configProviderService.provideOptimizedTeaserConfig().teaserOptimization) {
            this.setOptimizedEmbeddedTeasersForSubCategory(this.subcategory.categoryInfo);
        }
    }
    assignSeeAllGameCount() {
        let allSubCategoriesGameCount: number = 0;
        this.seeAllGamesCount = 0;
        if (this.subCategoryInfo && this.subCategoryInfo.linkedCategory) {
            this.seeAllGamesCountCategory = this.casinoLobbyService.getCategoryInfoOnLMTId(this.lobbyType, this.subCategoryInfo.linkedCategory);
            if (this.seeAllGamesCountCategory) {
                if (this.seeAllGamesCountCategory.gamelist && this.seeAllGamesCountCategory.gamelist.length > 0) {
                    this.seeAllGamesCount = this.seeAllGamesCountCategory.gamelist.length;
                }
                if (this.seeAllGamesCountCategory.lmtSubCategories && this.seeAllGamesCountCategory.lmtSubCategories.length > 0) {
                    for (let i = 0; i < this.seeAllGamesCountCategory.lmtSubCategories.length; i++) {
                        const sub: any = this.casinoLobbyService.getCategoryInfo(this.lobbyType, this.seeAllGamesCountCategory.lmtSubCategories[i]);
                        if (sub && sub.categoryInfo && sub.categoryInfo.gamelist && sub.categoryInfo.gamelist.length > 0) {
                            allSubCategoriesGameCount = allSubCategoriesGameCount + sub.categoryInfo.gamelist.length;
                        }
                    }
                }
            }

            if (allSubCategoriesGameCount > 0) {
                this.seeAllGamesCount = this.seeAllGamesCount + allSubCategoriesGameCount;
            }
        }
    }

    seeAll() {
        const features: any = this.configProviderService.provideFeaturesConfig();
        const categoryurl = this.subcategory?.categoryInfo?.sitecoreData?.categoryUrl;
        const dynamicSeeAllLink = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.dynamicSeeAllLink);
        if (dynamicSeeAllLink && categoryurl && categoryurl.url) {
            this.trackingService.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'Casino Games',
                'component.LabelEvent': 'See Rest Of Games',
                'component.ActionEvent': 'See ALL Link - Click',
                'component.PositionEvent': this.subcategory.categoryInfo.categoryname,
                'component.LocationEvent': this.subcategory.categoryInfo?.parentCategoryId,
                'component.EventDetails': this.seeAllText,
                'component.URLClicked': categoryurl.url,
            });
            this.navigation.goTo(categoryurl?.url);
            return;
        }
        const casinoBaseUrl = this.urlUtilityService.getCategoryNavigationBaseUrl();
        const categoryInfo = this.casinoLobbyService.getCategoryInfoOnLMTId(this.lobbyType, this.subcategory.categoryInfo.linkedCategory);
        const catRoute = categoryInfo.route;
        if (this.subcategory && this.subcategory?.categoryInfo && this.subcategory.categoryInfo?.linkedCategory) {
            if (this.casinoIFrameOverlayService.isOverLayOnAProduct(this.lobbyType)) {
                const navLobbyType: string = this.casinoIFrameOverlayService.lobbyTypeForNavCategories();
                const seeAllUrl: string =
                    casinoBaseUrl + '/' + this.mcPage.lang + '/' + this.casinoManager.getProductForGivenLobbyType(navLobbyType) + '/c/' + catRoute;
                this.trackingService.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'Sports Gaming Overlay',
                    'component.LabelEvent': 'Gaming Overlay on Sports Lobby',
                    'component.ActionEvent': 'See ALL Link - Click',
                    'component.LocationEvent': 'Gaming Overlay on Sports Lobby|' + this.subcategory.categoryInfo?.categoryname,
                    'component.URLClicked': seeAllUrl,
                });
                window.parent.postMessage({ eventName: 'UserAction', params: { redirectUrl: seeAllUrl } }, window.location.origin);
            } else if (this.casinoWidgetService.isCasinoWidget()) {
                const url: string = casinoBaseUrl + '/' + this.mcPage.lang + '/' + this.casinoManager.getProduct() + '/c/' + catRoute;
                if (this.casinoWidgetService.getWidgetPosition()) {
                    this.trackingService.triggerEvent('Event.Clicks', {
                        'component.CategoryEvent': 'X-Sell Casino Footer',
                        'component.LabelEvent': 'X-Sell Casino Footer|' + this.subcategory.categoryname,
                        'component.ActionEvent': 'See ALL Link - Click',
                        'component.LocationEvent': 'X-Sell Casino Footer|' + this.subcategory.categoryname,
                        'component.URLClicked': url,
                    });
                }
                window.parent.postMessage({ eventName: 'UserAction', params: { redirectUrl: url } }, window.location.origin);
            } else {
                let url: string;
                if (this.seeAllProductLinkOverride) {
                    const bingoUrl = this.urlUtilityService.getProductApiBaseUrl(this.seeAllProductLinkOverride);
                    url = bingoUrl + '/' + this.seeAllProductLinkOverride + '/games/' + catRoute;
                } else {
                    url = casinoBaseUrl + '/' + this.mcPage.lang + '/' + this.casinoManager.getLobbyUrlPrefix(this.lobbyType) + '/c/' + catRoute;
                }
                if (this.isSlotRacesCategory) {
                    this.slotRacesTracking.track(
                        'See upcoming - CTA Clicked',
                        'not applicable',
                        this.subcategory.categoryInfo.categoryname,
                        'User clicked on see upcoming Link',
                    );
                } else {
                    this.trackingService.triggerEvent('Event.Clicks', {
                        'component.CategoryEvent': 'Casino Games',
                        'component.LabelEvent': 'See Rest Of Games',
                        'component.ActionEvent': 'See ALL Link - Click',
                        'component.PositionEvent': this.subcategory.categoryInfo.categoryname,
                        'component.LocationEvent': this.subcategory.categoryInfo?.parentCategoryId,
                        'component.EventDetails': this.seeAllText,
                        'component.URLClicked': url,
                    });
                }
                if (url) {
                    this.navigation.goTo(url);
                }
            }

            return;
        }
    }

    setSubCategoryStyle() {
        if (this.isCategoryScrollable()) {
            return 'scrollable-games';
        } else {
            return 'unscrollable-games';
        }
    }

    isCategoryScrollable() {
        if (this.subcategory) {
            return Number(this.subCategoryInfo.rowCount) < 0;
        }
        return false;
    }

    subscribeToLoadNextGames() {
        this.loadnextGamesObs = this.casinoManager.loadNextGamesSetObservable.subscribe((loadNewGameSet: boolean) => {
            if (loadNewGameSet && this.loadNewSet && !this.isCategoryScrollable()) {
                this.loadNewSet = false;
                this.getNextGames(this.gamesLoadCount, (result: any) => {
                    if (result) {
                        this.loadNewSet = true;
                    }
                });
            }
        });
    }
    setEmbeddedTeasersForSubCategory(category: any) {
        this.headerTeasers = [];
        const headerTeasers = this.teaserService.headerTeasers();
        if (
            category &&
            category.sitecoreData.showTeasers &&
            category.sitecoreData.teasers &&
            category.sitecoreData.teasers.length > 0 &&
            headerTeasers &&
            headerTeasers.length > 0
        ) {
            headerTeasers.forEach((headerTeaser: any) => {
                const index = category.sitecoreData.teasers.indexOf(
                    category.sitecoreData.teasers.filter((categoryTeaser: any) => categoryTeaser.itemName === headerTeaser.teaserId)[0],
                );
                if (index > -1 && headerTeaser.isEmbeddedTeaser) {
                    headerTeaser.gameContainer = this.gameContainer;
                    this.headerTeasers.push(headerTeaser);
                }
            });
        }
        this.showTeasers = this.headerTeasers && this.headerTeasers.length > 0;
        this.isShowEmbeddedAbove = false;
        this.isShowEmbeddedBelow = false;
    }
    setOptimizedEmbeddedTeasersForSubCategory(category: any) {
        this.optimizedHeaderTeasers = this.optimizedTeaserManager.setOptimizedTeasersBasedOnCatery(category);
        if (this.optimizedHeaderTeasers) {
            if (this.optimizedHeaderTeasers.optimizedEmbeddedAboveTeasers.length > 0) {
                this.optimizedEmbeddedAboveTeasers = this.optimizedHeaderTeasers.optimizedEmbeddedAboveTeasers;
                this.isShowEmbeddedAbove = this.optimizedEmbeddedAboveTeasers && this.optimizedEmbeddedAboveTeasers.length > 0;
            } else {
                this.optimizedEmbeddedAboveTeasers = [];
                this.isShowEmbeddedAbove = false;
            }
            if (this.optimizedHeaderTeasers.optimizedEmbeddedBelowTeasers.length > 0) {
                this.optimizedEmbeddedBelowTeasers = this.optimizedHeaderTeasers.optimizedEmbeddedBelowTeasers;
                this.isShowEmbeddedBelow = this.optimizedEmbeddedBelowTeasers && this.optimizedEmbeddedBelowTeasers.length > 0;
            } else {
                this.optimizedEmbeddedBelowTeasers = [];
                this.isShowEmbeddedBelow = false;
            }
        }
        this.showTeasers = false;
    }

    loadNextGamesSet() {
        if (this.isCategoryScrollable()) {
            this.getNextGames(this.gamesLoadCount);
        }
    }

    getNextGames(gamesToLoad: number, callback?: any) {
        const casinoConfig: any = this.configProviderService.provideCasinoConfig();
        this.gamesLoadCount = this.isCategoryScrollable()
            ? casinoConfig.gamesLoadCount.loadHorizontalGamesCount
            : casinoConfig.gamesLoadCount.loadVerticalGamesCount;
        let offlineCountInGames = 0;
        if (this.validGames?.length > this.gamesLoadCount) {
            offlineCountInGames = this.Games.filter((value: any) => this.offlineGamesArray.includes(value))?.length;
        }
        const OfflineGamesCount = this.offlineGamesArray?.length - (offlineCountInGames ? offlineCountInGames : 0);
        if (this.validGames.length - (this.Games.length + OfflineGamesCount + this.disabledGameArray.length + this.skipRPCount) > 0) {
            if (
                this.subCategoryInfo.filterRecentlyPlayedGames &&
                this.Games.length +
                    OfflineGamesCount +
                    gamesToLoad -
                    (this.Games.length + OfflineGamesCount + this.disabledGameArray.length + this.skipRPCount) <
                    gamesToLoad
            ) {
                gamesToLoad = gamesToLoad + this.Games.length + OfflineGamesCount + this.disabledGameArray.length + this.skipRPCount;
            }
            const count = this.validGames.slice(
                this.Games.length + OfflineGamesCount + this.disabledGameArray.length + this.skipRPCount,
                this.Games.length + OfflineGamesCount + gamesToLoad,
            );
            this.setGames(count, false, callback);
        } else if (callback) {
            callback(true);
        }
    }

    setGames(gamesModellist: any[], fromRecentGamesObs: boolean = false, callback?: any) {
        // if (this.offlineGamesArray.length > 0) {
        //     this.Games.splice(this.Games.length - this.offlineGamesArray.length, this.offlineGamesArray.length);
        // }
        let starttileBgMode: boolean = false;
        if (this.subcategory.categoryInfo.filterRecentlyPlayedGames && this.recentGames && this.recentGames.length > 0) {
            if (fromRecentGamesObs) {
                this.skipRPCount = 0;
                this.newFeatureGridArr = [];
                this.alreadyFilteredRecentGames = [];
            }
            const newGamesModellist = this.filterRPGames(gamesModellist, [...this.recentGames]);
            this.skipRPCount += Math.abs(gamesModellist.length - newGamesModellist.length);
            gamesModellist = newGamesModellist;
        }
        if (this.isCategoryScrollable()) {
            this.formNewFeatureGridGameArray(gamesModellist);
        } else {
            for (let i = 0; i < gamesModellist.length; i++) {
                const gameModelItem: any = gamesModellist[i];
                const filteredGame: any = gameModelItem;
                if (!(filteredGame && filteredGame.length > 0)) {
                    const gamelobbyModel: GameLobbyModel = this.setGameModel(gameModelItem, starttileBgMode);
                    this.liveGameInfo = this.liveCasinoService.getLiveGameLookUpInfo(gamelobbyModel?.gameId?.toLowerCase());
                    const isTableOnline: boolean = this.liveCasinoService.isTableOnline(gamelobbyModel?.gameId?.toLowerCase());
                    // checking if  table is offline
                    if (this.liveGameInfo && this.liveGameInfo?.gameType && (!this.liveGameInfo.tableStatus || !isTableOnline)) {
                        gamelobbyModel.secondsToStartTable = this.assignSecondsLeftToStartTableOnline(this.liveGameInfo);
                        this.offlineGamesArray = [...this.offlineGamesArray, gamelobbyModel];
                    } else {
                        this.Games = [...this.Games, gamelobbyModel];
                    }
                } else {
                    this.disabledGameArray.push(filteredGame);
                }
                if (this.subCategoryInfo.isIconSevenCategory) {
                    //To handle background for alternate in non-scrollable games
                    const width = document.body.offsetWidth;
                    if (this.subCategoryInfo.rowCount == '1' || this.subCategoryInfo.rowCount == '2') {
                        if (!this.isTouch) {
                            if ((i + 1) % 4 == 0) {
                            } else {
                                starttileBgMode = !starttileBgMode;
                            }
                        } else {
                            starttileBgMode = !starttileBgMode;
                        }
                    } else if (width < 568) {
                        starttileBgMode = !starttileBgMode;
                    } else if (width < 1024) {
                        if (i % 2 == 0) {
                            starttileBgMode = !starttileBgMode;
                        }
                    } else {
                        if ((i + 1) % 4 == 0) {
                        } else {
                            starttileBgMode = !starttileBgMode;
                        }
                    }
                }
            }
            this.checkOfflineGames();
            this.newFeatureGridArr = [...this.Games];
        }
        if (callback) {
            callback(true);
        }
        if (this.Games && this.Games.length < this.gamesLoadCount) {
            this.getNextGames(this.gamesLoadCount);
        }
    }

    setGameModel(gameModelItem: any, starttileBgMode: boolean): GameLobbyModel {
        const currentWidth = this.casinoManager.getCurrentWidth();
        const gamelobbyModel: GameLobbyModel = this.gameDataManager.setGameLobbyModel(
            gameModelItem,
            this.isTouch,
            this.arcadeLobbyGameConfig,
            this.lobbyType,
        );
        gamelobbyModel.infoIconClass = this.casinoManager.assignGameClass(currentWidth, gamelobbyModel.iconType);
        gamelobbyModel.lobbyType = this.lobbyType;
        gamelobbyModel.gameSection = this.subcategory.categoryInfo.engCategoryName;
        gamelobbyModel.gameContainer = this.gameContainer;
        gamelobbyModel.gameTileBorderRadius = this.gameTileBorderRadius;
        gamelobbyModel.gameTileDropShadow = this.gameTileDropShadow;
        gamelobbyModel.bgTileMode = this.subCategoryInfo.isIconSevenCategory ? (starttileBgMode ? starttileBgMode : false) : false;
        gamelobbyModel.newgridbgcolor = this.newgridbgcolor;
        return gamelobbyModel;
    }

    formNewFeatureGridGameArray(gamesModellist: any[]) {
        const isTouch = this.casinoManager.isTouch();
        let rowCount = this.subCategoryInfo.rowCount;
        if (
            isTouch &&
            (this.subCategoryInfo.isIconSixCategory || this.subCategoryInfo.isIconSevenCategory) &&
            Number(this.subCategoryInfo.rowCount) > 0
        ) {
            rowCount = '-2';
        }
        const absoluteRowCount = Math.abs(rowCount);
        const gameArr = gamesModellist;
        let start = 0;
        const lastFeatureGridItem = this.newFeatureGridArr[this.newFeatureGridArr.length - 1]?.games;
        let starttileBgMode: boolean = lastFeatureGridItem ? lastFeatureGridItem[absoluteRowCount - 1].bgTileMode : gameArr[0]?.bgTileMode;
        if (lastFeatureGridItem && absoluteRowCount % 2 != 0) {
            starttileBgMode = !starttileBgMode;
        }
        while (start < gameArr.length) {
            let matgridArr: any[] = [];
            let maxRowSpan = 0;
            let maxColSpan = 0;
            for (let i = start; i < start + absoluteRowCount; i++) {
                if (gameArr[i]) {
                    const gameModel: GameLobbyModel = this.setGameModel(gameArr[i], starttileBgMode);
                    this.liveGameInfo = this.liveCasinoService.getLiveGameLookUpInfo(gameModel?.gameId?.toLowerCase());
                    const isTableOnline: boolean = this.liveCasinoService.isTableOnline(gameModel?.gameId?.toLowerCase());
                    if (this.liveGameInfo && this.liveGameInfo?.gameType && (!this.liveGameInfo.tableStatus || !isTableOnline)) {
                        gameModel.secondsToStartTable = this.assignSecondsLeftToStartTableOnline(this.liveGameInfo);
                        this.offlineGamesArray = [...this.offlineGamesArray, gameModel];
                    } else {
                        this.Games = [...this.Games, gameModel];
                    }

                    matgridArr = [...matgridArr, gameModel];
                    maxRowSpan = this.findMax(maxRowSpan, gameModel.rowSpan);
                    maxColSpan = this.findMax(maxColSpan, gameModel.colSpan);
                    starttileBgMode = !starttileBgMode;
                }
            }
            if (absoluteRowCount % 2 == 0) {
                starttileBgMode = !starttileBgMode;
            }
            start = start + absoluteRowCount;
            this.newFeatureGridArr.push({
                games: matgridArr,
                maxRowSpan: maxRowSpan,
                maxColSpan: maxColSpan,
            });
        }
        this.checkOfflineGames();
    }

    findMax(n1: number, n2: number): number {
        if (n1 > n2) return n1;
        return n2;
    }

    subscribeToElementEventListeners() {
        this.elementRef.nativeElement.addEventListener('touchstart', (e: any) => {
            this.startPosition = this.utility.getPositionXY(e);
        });

        this.elementRef.nativeElement.addEventListener('touchend', (e: any) => {
            const endPosition = this.utility.getPositionXY(e);
            if (this.isCategoryScrollable() && endPosition.x < this.startPosition.x) {
                this.getNextGames(this.gamesLoadCount);
            }
        });

        this.recentGamesObs = this.recentGamesService.recentGamesFeedObservable.subscribe((recentGames: any) => {
            if (recentGames && recentGames.length !== 0) {
                this.recentGames = recentGames;
                if (this.subcategoryId?.toLowerCase() == this.mostRecentlyPlayedRoutesKey) {
                    this.bindMostRecentlyPlayedGames();
                }
                if (
                    this.subcategoryId?.toLowerCase() == this.mostRecentlyPlayedRoutesKey ||
                    this.subcategory.categoryInfo.filterRecentlyPlayedGames
                ) {
                    this.setValidGames(true);
                }
            }
        });
    }

    filterRPGames(gamesList: any, recentGamesArr: any) {
        const newGamesList: any = [];
        if (gamesList && gamesList.length > 0) {
            gamesList.forEach((gameObj: any) => {
                const index = recentGamesArr.indexOf(gameObj.game);
                if (index === -1) {
                    newGamesList.push(gameObj);
                } else {
                    this.alreadyFilteredRecentGames.push(gameObj);
                }
            });
            let count;
            if (!this.isCategoryScrollable()) {
                count = this.filterThreshold - newGamesList.length;
            } else {
                if (gamesList[gamesList.length - 1].game == this.validGames[this.validGames.length - 1].game) {
                    count = this.filterThreshold - (this.Games.length + newGamesList.length);
                }
            }
            const orderedRecentGamesList: any = [];
            if (count && count > 0) {
                if (this.alreadyFilteredRecentGames && this.alreadyFilteredRecentGames.length != 0) {
                    this.alreadyFilteredRecentGames.forEach((g: any) => {
                        recentGamesArr.forEach((r: any) => {
                            if (g.game == r) orderedRecentGamesList.push(g);
                        });
                    });
                }
            }
            if (orderedRecentGamesList.length > 0) {
                const relevantRecentGamesList: [] = orderedRecentGamesList.splice(0, count);
                const features: any = this.configProviderService.provideFeaturesConfig();
                const mostPlayedRoute: string =
                    this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.mostPlayedRouteForLobbyType) || '';
                if (!this.subcategoryId?.toLowerCase()?.includes(mostPlayedRoute)) relevantRecentGamesList.reverse();
                newGamesList.push(...relevantRecentGamesList);
            }
        }
        return newGamesList;
    }

    setOfflinesLivecasino() {
        this.offlineGamesArray = [];
        this.Games.forEach((val: any, index: number) => {
            this.liveGameInfo = this.liveCasinoService.getLiveGameLookUpInfo(val?.gameId?.toLowerCase());
            const isTableOnline: boolean = this.liveCasinoService.isTableOnline(val?.gameId?.toLowerCase());
            //  checking if table is offline
            if (this.liveGameInfo && this.liveGameInfo?.gameType && (!this.liveGameInfo.tableStatus || !isTableOnline)) {
                this.Games[index].secondsToStartTable = this.assignSecondsLeftToStartTableOnline(this.liveGameInfo);
                this.offlineGamesArray.push(this.Games[index]);
            }
        });
        this.checkOfflineGames();
    }
    checkOfflineGames() {
        if (this.offlineGamesArray.length > 0) {
            if (this.isCategoryScrollable()) {
                this.showFeatureGrid.set(false);
            }
            this.offlineGamesArray = this.offlineGamesArray.sort(
                (l: GameLobbyModel, m: GameLobbyModel) => l.secondsToStartTable - m.secondsToStartTable,
            );
            this.offlineGamesArray.forEach((val: any) => {
                if (val?.iconType == '1' || val?.iconType == '2') {
                    const endlessIndex = this.Games.indexOf(val);
                    this.Games.splice(endlessIndex, endlessIndex > -1 ? 1 : 0);
                    this.Games = this.Games.concat(val);
                    if (this.isCategoryScrollable()) {
                        const offlineMatGridArr: any[] = [];
                        offlineMatGridArr.push({
                            games: [val],
                            maxRowSpan: val.rowSpan,
                            maxColSpan: val.colSpan,
                        });
                        const scrollableIndex = this.newFeatureGridArr
                            .flatMap((e) => {
                                return e.games;
                            })
                            .indexOf(val);
                        this.newFeatureGridArr.splice(scrollableIndex, scrollableIndex > -1 ? 1 : 0);
                        this.newFeatureGridArr = this.newFeatureGridArr.concat(offlineMatGridArr);
                    } else {
                        this.newFeatureGridArr = [...this.Games];
                    }
                }
            });
            if (this.isCategoryScrollable()) {
                this.cdr.detectChanges();
                this.showFeatureGrid.set(true);
            }
        }
    }

    assignSecondsLeftToStartTableOnline(liveGameInfo: any): number {
        let secondsLeftToStartTableOnline: number = 0;
        if (!liveGameInfo.tableStatus) {
            secondsLeftToStartTableOnline = 999999999;
        } else if (
            liveGameInfo.countdown &&
            liveGameInfo.countdown.type &&
            liveGameInfo.countdown.type?.toLowerCase() === 'bounded' &&
            liveGameInfo.countdown.time
        ) {
            secondsLeftToStartTableOnline = this.liveCasinoService.secondsToStartTable(liveGameInfo.countdown.time);
        }
        return secondsLeftToStartTableOnline;
    }
    bindMostRecentlyPlayedGames() {
        this.mostRecentlyPlayedGamesArr = cloneDeep(this.recentGamesService.getMostRecentlyPlayedGames());
        //    this.mostRecentlyPlayedGamesArr = {...this.recentGamesService.getMostRecentlyPlayedGames()};
        if (this.mostRecentlyPlayedRouteInfo.filterOn) {
            this.mostRecentlyPlayedGamesArr = this.mostRecentlyPlayedGamesArr.filter(
                (game: any) => game.gameType === this.mostRecentlyPlayedRouteInfo.filterOn,
            );
        }
        if (this.mostRecentlyPlayedRouteInfo.orderBy) {
            this.mostRecentlyPlayedGamesArr = this.mostRecentlyPlayedGamesArr.sort(
                (obj1: any, obj2: any) => obj2[this.mostRecentlyPlayedRouteInfo.orderBy] - obj1[this.mostRecentlyPlayedRouteInfo.orderBy],
            );
        }
        const filteredMostRecentlyPlayedGames: any = [];
        for (let i = 0; i < this.mostRecentlyPlayedGamesArr.length; i++) {
            filteredMostRecentlyPlayedGames.push({ game: this.mostRecentlyPlayedGamesArr[i].gamevariant });
        }
        this.subCategoryInfo.gamelist = filteredMostRecentlyPlayedGames;
        this.subCategoryInfo.rowCount = '1';
    }

    parallexInputData() {
        const global = this.configProviderService.provideGlobalConfig();
        const mustGoSitecoreObjectInfo = this.casinoLobbyService.getCategoryInfo(this.lobbyType, this.subcategoryId);
        if (mustGoSitecoreObjectInfo) {
            this.MustGoSitecoreObject = mustGoSitecoreObjectInfo.categoryInfo;
            this.parallaxInput = {
                sitecoreData: {
                    backgroundImageLayer1: this.MustGoSitecoreObject.sitecoreData.jackpotBackgroundImage1,
                    backgroundImageLayer2: this.MustGoSitecoreObject.sitecoreData.jackpotBackgroundImage2,
                    backgroundImageLayer3: this.MustGoSitecoreObject.sitecoreData.jackpotBackgroundImage3,
                },
            };
        }
        this.categoryBackgroundFull = global.messages.CategoryBgFull;
    }

    getCarouselData(data: any) {
        this.carouselData = data;
    }

    onPrevClick() {
        this.featureGrid.prevItem();
    }

    onNextClick() {
        this.featureGrid.nextItem();
        this.loadNextGamesSet();
    }
    openDialog($event: any): void {
        this.jackpotMoreInfoTracking();
        let requiredModalData: any;
        if ($event) {
            $event.stopImmediatePropagation();
        }
        if (this.subCategoryInfo?.sitecoreData?.jackpotInfo) {
            requiredModalData = {
                subcategoryinfo: this.subCategoryInfo?.sitecoreData?.jackpotInfo,
                subcategorytittle: this.subCategoryInfo?.categoryname,
                seeAllCtaText: this.subCategoryInfo?.sitecoreData?.seeAllGames,
                jackpotBadgeStatus: this.subCategoryInfo?.sitecoreData?.jackpotBadgeStatus,
                showSeeAllGamesCta: this.subCategoryInfo?.sitecoreData?.showSeeAllGamesCta,
                seeallctaurl: this.subCategoryInfo?.sitecoreData?.seeallctaurl,
                jackpotMoreInfoCloseCtaText: this.subCategoryInfo?.sitecoreData?.jackpotMoreInfoCloseCtaText,
            };
        }
        this.categoryiconSubtitleMoreinfo.open(CategoryiconSubtitleMoreinfoComponent, {
            data: requiredModalData,
            panelClass: 'custom-dialog-container',
        });
    }
    jackpotMoreInfoTracking() {
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'slots',
            'component.LabelEvent': 'jackpot slots',
            'component.ActionEvent': 'click',
            'component.PositionEvent': this.subCategoryInfo.categoryname,
            'component.LocationEvent': this.subCategoryInfo.categoryname,
            'component.EventDetails': 'more info',
        });
    }

    ngOnDestroy() {
        if (this.teaserObs) {
            this.teaserObs.unsubscribe();
        }
        if (this.recentGamesObs) {
            this.recentGamesObs.unsubscribe();
        }
        if (this.slotRacesSubscription) {
            this.slotRacesSubscription.unsubscribe();
        }
        this.coinBalSubscription?.unsubscribe();
    }
    getJackpotFeed(value: any) {
        if (!this.isJackpotdataForParallax && value?.isJackpotdataForParallax) {
            this.isJackpotdataForParallax = value?.isJackpotdataForParallax;
        }
    }

    subscribeToCoinBalance() {
        this.coinBalSubscription = this.casinoLobbyService.coinBalanceObservable.subscribe((coinBalance: number) => {
            if (coinBalance) {
                this.showCoinExpiryText = true;
                this.arcadeLobbyMessages.StoreWidgetExpiryText = this.arcadeLobbyMessages?.StoreWidgetExpiryText?.replace(
                    '{{coins}}',
                    coinBalance,
                ).replace('{{month}}', this.casinoLobbyService.getLastMonthArcadeExpiry());
                this.arcadeLobbyMessages.StoreWidgetExpiryTextToday = this.arcadeLobbyMessages?.StoreWidgetExpiryTextToday?.replace(
                    '{{coins}}',
                    coinBalance,
                ).replace('{{month}}', this.casinoLobbyService.getLastMonthArcadeExpiry());
            }
        });
    }
}
