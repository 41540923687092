import { Injectable } from '@angular/core';

import { ToastrQueueService, UserService } from '@frontend/vanilla/core';

import { ConfigProviderService } from '../../config-provider/config-provider.service';
import { GeolocationTrackingService } from '../../shared/geolocation-tracking.service';
import { GeolocationActivatorService } from './activator.service';

@Injectable({
    providedIn: 'root',
})
export class OobeeIntimationToasterService {
    constructor(
        private readonly _toasterService: ToastrQueueService,
        private readonly geolocationActivator: GeolocationActivatorService,
        private readonly _userService: UserService,
        private readonly _geolocationTrackingService: GeolocationTrackingService,
        private configProviderService: ConfigProviderService,
    ) {}

    display(): void {
        const _geolocationConfig = this.configProviderService.provideGeoLocationConfig();
        if (
            this._userService.isAuthenticated &&
            this.geolocationActivator.isActive &&
            _geolocationConfig.oobeeIntimation.isEnabled &&
            _geolocationConfig.oobeeIntimation.toasterId
        ) {
            this._toasterService.add(_geolocationConfig.oobeeIntimation.toasterId);
            this._geolocationTrackingService.track('GeoGaurdApp_Toaster', 'Toaster_Loaded', 'After Login');
        }
    }
}
