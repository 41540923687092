import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';

// declare let angular: angular.IAngularStatic;
@Component({
    selector: 'cc-flipclock',
    templateUrl: 'flipclock.component.html',
    standalone: true,
    imports: [NgStyle, NgIf, NgFor, NgClass],
})
export class FlipclockComponent implements OnInit, OnChanges {
    @Input() amount: any;
    @Input() currency: any;
    @Input() releaseamount: any;
    @Input() isMega: any;
    public bufferValue: any;
    public eightyPercentValue: any;
    public sixtyPercentValue: any;
    public index: number;
    tickerValue: any;
    majorItems: any = [];
    public fileSrcs: string[] = [];
    public debugSizeBefore: string[] = [];
    public debugSizeAfter: string[] = [];
    controlIntervalId: any;
    setFlipId: any;
    tickerValueLength: number;
    isMobile: boolean;

    constructor(private changeDetectorRef: ChangeDetectorRef) {
        if (document.body.offsetWidth >= 768 || window.innerWidth >= 768) {
            this.isMobile = false;
        } else {
            this.isMobile = true;
        }
    }
    ngOnInit() {
        this.eightyPercentValue = 0.8 * parseInt(this.releaseamount);
        this.sixtyPercentValue = 0.6 * parseInt(this.releaseamount);
        this.bufferValue = parseInt(this.amount);
        this.amount = parseInt(this.amount);
        this.tickerValue = this.bufferValue;
        this.updateTicker(this.tickerValue);
        this.tickerCode();
    }
    ngOnChanges() {
        clearInterval(this.controlIntervalId);
        clearInterval(this.setFlipId);
        if (parseInt(this.amount) > parseInt(this.tickerValue)) {
            this.tickerValue = parseInt(this.amount);
        }
        this.tickerCode();
    }
    updateTicker(tickerValue: any) {
        this.majorItems = [];
        const str: string = this.formatNumber(tickerValue);
        if (str && str?.toLowerCase() !== 'nan') {
            for (let i = 0; str[i] != '.' && i < str.length; i++) {
                this.majorItems.push({
                    isFlipVisible: false,
                    value: str[i],
                });
            }
        }
        this.tickerValueLength = this.majorItems.length;
    }
    formatNumber(num: number) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    paddingValue(num: number) {
        if (this.isMobile === false) {
            switch (num) {
                case 1: {
                    return '61px';
                }
                case 2: {
                    return '46px';
                }
                case 3: {
                    return '33px';
                }
                case 5: {
                    return '10px';
                }
                case 6: {
                    return '-1px';
                }
                default: {
                    return '0px';
                }
            }
        }
        return '0px';
    }
    paddingValueMega(num: number) {
        if (this.isMobile === false) {
            switch (num) {
                case 1: {
                    return '172px';
                }
                case 2: {
                    return '143px';
                }
                case 3: {
                    return '114px';
                }
                case 5: {
                    return '75px';
                }
                case 6: {
                    return '49px';
                }
                case 7: {
                    return '17px';
                }
                case 9: {
                    return '-10px';
                }
                default: {
                    return '0px';
                }
            }
        }
        return '0px';
    }
    tickerCode() {
        this.controlIntervalId = setInterval(() => {
            this.tickerValue = (parseFloat(this.tickerValue) + 0.5).toFixed(2);
            this.updateTicker(this.tickerValue);
        }, 6000);
    }

    //This method not being used
    // fileChange(input: any) {
    //     let filePath = input.value;
    //     let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    //     if (!allowedExtensions.exec(filePath)) {
    //         input.value = '';
    //         return false;
    //     }
    //     else {
    //         this.readFiles(input.files);
    //     }
    // }

    readFile(file: any, reader: any, callback: any) {
        reader.onload = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(file);
    }
    readFiles(files: any, index = 0) {
        const reader = new FileReader();
        if (index in files) {
            this.readFile(files[index], reader, (result: any) => {
                const img = document.createElement('img');
                img.src = result;
                this.resize(img, 150, 150, (resized_jpeg: any, before: any, after: any) => {
                    this.debugSizeBefore.push(before);
                    this.debugSizeAfter.push(after);
                    this.fileSrcs.pop();
                    this.fileSrcs.push(resized_jpeg);
                    this.readFiles(files, index + 1);
                });
            });
        } else {
            this.changeDetectorRef.detectChanges();
        }
    }
    resize(img: any, MAX_WIDTH: number, MAX_HEIGHT: number, callback: any) {
        return (img.onload = () => {
            let width = img.width;
            let height = img.height;
            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx!.drawImage(img, 0, 0, width, height);
            const dataUrl = canvas.toDataURL('image/jpeg');

            callback(dataUrl, img.src.length, dataUrl.length);
        });
    }
    mustGoPtLsMargin(tickerLength: number) {
        if (this.isMobile === true) {
            switch (tickerLength) {
                case 1:
                    {
                        if (document.body.offsetWidth <= 320) {
                            return '35.2%';
                        } else if (document.body.offsetWidth <= 360) {
                            return '37%';
                        } else if (document.body.offsetWidth <= 375) {
                            return '37%';
                        } else if (document.body.offsetWidth <= 414) {
                            return '38.5%';
                        } else if (document.body.offsetWidth <= 480) {
                            return '37%';
                        } else if (document.body.offsetWidth <= 568) {
                            return '40%';
                        } else if (document.body.offsetWidth <= 640) {
                            return '40.5%';
                        } else if (document.body.offsetWidth <= 667) {
                            return '41.3%';
                        } else if (document.body.offsetWidth <= 736) {
                            return '42%';
                        }
                    }
                    break;
                case 2:
                    {
                        if (document.body.offsetWidth <= 320) {
                            return '29%';
                        } else if (document.body.offsetWidth <= 360) {
                            return '31%';
                        } else if (document.body.offsetWidth <= 375) {
                            return '32%';
                        } else if (document.body.offsetWidth <= 414) {
                            return '33.5%';
                        } else if (document.body.offsetWidth <= 480) {
                            return '33.5%';
                        } else if (document.body.offsetWidth <= 568) {
                            return '35.8%';
                        } else if (document.body.offsetWidth <= 640) {
                            return '37.5%';
                        } else if (document.body.offsetWidth <= 667) {
                            return '38%';
                        } else if (document.body.offsetWidth <= 736) {
                            return '39%';
                        }
                    }
                    break;
                case 3:
                    {
                        if (document.body.offsetWidth <= 320) {
                            return '24%';
                        } else if (document.body.offsetWidth <= 360) {
                            return '26.5%';
                        } else if (document.body.offsetWidth <= 375) {
                            return '27.5%';
                        } else if (document.body.offsetWidth <= 414) {
                            return '29.5%';
                        } else if (document.body.offsetWidth <= 480) {
                            return '29%';
                        } else if (document.body.offsetWidth <= 568) {
                            return '32%';
                        } else if (document.body.offsetWidth <= 640) {
                            return '34.2%';
                        } else if (document.body.offsetWidth <= 667) {
                            return '34.8%';
                        } else if (document.body.offsetWidth <= 736) {
                            return '36.5%';
                        }
                    }
                    break;
                case 5:
                    {
                        if (document.body.offsetWidth <= 320) {
                            return '16.5%';
                        } else if (document.body.offsetWidth <= 360) {
                            return '20.5%';
                        } else if (document.body.offsetWidth <= 375) {
                            return '21.5%';
                        } else if (document.body.offsetWidth <= 414) {
                            return '24%';
                        } else if (document.body.offsetWidth <= 480) {
                            return '24%';
                        } else if (document.body.offsetWidth <= 568) {
                            return '27.6%';
                        } else if (document.body.offsetWidth <= 640) {
                            return '30.1%';
                        } else if (document.body.offsetWidth <= 667) {
                            return '31%';
                        } else if (document.body.offsetWidth <= 736) {
                            return '32.5%';
                        }
                    }
                    break;
                case 6:
                    {
                        if (document.body.offsetWidth <= 320) {
                            return '11%';
                        } else if (document.body.offsetWidth <= 360) {
                            return '15%';
                        } else if (document.body.offsetWidth <= 375) {
                            return '16.5%';
                        } else if (document.body.offsetWidth <= 414) {
                            return '19.8%';
                        } else if (document.body.offsetWidth <= 480) {
                            return '19.3%';
                        } else if (document.body.offsetWidth <= 568) {
                            return '23.8%';
                        } else if (document.body.offsetWidth <= 640) {
                            return '26.7%';
                        } else if (document.body.offsetWidth <= 667) {
                            return '27.7%';
                        } else if (document.body.offsetWidth <= 736) {
                            return '30%';
                        }
                    }
                    break;
                case 7:
                    {
                        if (document.body.offsetWidth <= 320) {
                            return '5.4%';
                        } else if (document.body.offsetWidth <= 360) {
                            return '10.5%';
                        } else if (document.body.offsetWidth <= 375) {
                            return '12%';
                        } else if (document.body.offsetWidth <= 414) {
                            return '15.5%';
                        } else if (document.body.offsetWidth <= 480) {
                            return '15%';
                        } else if (document.body.offsetWidth <= 568) {
                            return '20.1%';
                        } else if (document.body.offsetWidth <= 640) {
                            return '23.5%';
                        } else if (document.body.offsetWidth <= 667) {
                            return '24.6%';
                        } else if (document.body.offsetWidth <= 736) {
                            return '27%';
                        }
                    }
                    break;
                case 9:
                    {
                        if (document.body.offsetWidth <= 320) {
                            return '-2%';
                        } else if (document.body.offsetWidth <= 360) {
                            return '4%';
                        } else if (document.body.offsetWidth <= 375) {
                            return '5.8%';
                        } else if (document.body.offsetWidth <= 414) {
                            return '10%';
                        } else if (document.body.offsetWidth <= 480) {
                            return '9.3%';
                        } else if (document.body.offsetWidth <= 568) {
                            return '15.4%';
                        } else if (document.body.offsetWidth <= 640) {
                            return '19.4%';
                        } else if (document.body.offsetWidth <= 667) {
                            return '20.5%';
                        } else if (document.body.offsetWidth <= 736) {
                            return '23.3%';
                        }
                    }
                    break;
                default: {
                    return '0%';
                }
            }
        }
        return '0%';
    }
}
