import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csMinBetGamesConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: minBetGamesClientConfigFactory,
})
export class MinBetGamesClientConfig {
    CoinArcade_MinBetGames: any;
}

export function minBetGamesClientConfigFactory(service: LazyClientConfigService) {
    return service.get(MinBetGamesClientConfig);
}
