import { Time } from '@angular/common';
import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';
import { IPathBasedEnableORDisable } from 'packages/casino/platform-lib/core/shared/models/common.model';

import { GeolocationConfigurationModel, InstallerSettingsModel } from '../models';
import { OobeeIntimationModel } from '../models/geolocation.configuration.model';

@LazyClientConfig({ key: 'csCasinoConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: casinoConfigClientConfigFactory,
})
export class CasinoConfigClientConfig {
    gameLogUrl: string;
    frontendId: string;
    brandId: string;
    productId: string;
    defaultCategory: string;
    teasers: any;
    gameIconsQuality: string;
    desktopGameIconsQuality: string;
    liveGamesCategories: string[];
    maxNavigationLevel: number;
    gamesLoadCount: any;
    gymlLimit: number;
    gymlLimitForSearch: number;
    recentGamesCountLimit: number;
    defaultyLobbyTypeCategory: any;
    cacheKeys: any;
    casinoContextValues: any;
    detailPageModeLobbyType: any;
    enableInitialScrollTop: boolean;
    initialScrollTopTimeOut: number;
    enableLiveGames: boolean;
}

export function casinoConfigClientConfigFactory(service: LazyClientConfigService) {
    return service.get(CasinoConfigClientConfig);
}

@LazyClientConfig({ key: 'csPromotedGamesConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: promotedGamesClientConfigFactory,
})
export class PromotedGamesClientConfig {
    isEnabled: boolean;
}

export function promotedGamesClientConfigFactory(service: LazyClientConfigService) {
    return service.get(PromotedGamesClientConfig);
}

@LazyClientConfig({ key: 'csClientSideConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: clientSideConfigClientConfigFactory,
})
export class ClientSideConfigClientConfig {
    bonusInterval: number;
    playForFunAndRealButtons: boolean;
    labelId: string;
    playForFunAndRealButtonsLobbyType: any;
}

export function clientSideConfigClientConfigFactory(service: LazyClientConfigService) {
    return service.get(ClientSideConfigClientConfig);
}

@LazyClientConfig({ key: 'csBgRooms', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: roomClientConfigFactory,
})
export class RoomClientConfig {
    title: string;
    roomId: string;
    description: string;
    mobileImage: string;
    desktopImage: string;
    isNew: boolean;
    isExclusive: boolean;
    gameCode: string;
}

export function roomClientConfigFactory(service: LazyClientConfigService) {
    return service.get(RoomClientConfig);
}

@LazyClientConfig({ key: 'csComponentCompositionsConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: componentCompositionsClientConfigFactory,
})
export class ComponentCompositionsClientConfig {
    mobileDefaultHomeComposition: any;
    mobileNonDefaultHomeComposition: any;
    desktopDefaultHomeComposition: any;
    desktopNonDefaultHomeComposition: any;
    enabled: boolean;
}

export function componentCompositionsClientConfigFactory(service: LazyClientConfigService) {
    return service.get(ComponentCompositionsClientConfig);
}

@LazyClientConfig({ key: 'csFeatures', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: featuresClientConfigFactory,
})
export class FeaturesClientConfig {
    showMD3Teasers: boolean;
    useFavouriteGames: boolean;
    useRecentGames: boolean;
    useEmbeddedTeasers: boolean;
    sendJSErrorsToServer: boolean;
    showAddToHome: boolean;
    isAnonymousLobbyEnabled: boolean;
    isPromotionPagesEnabled: boolean;
    gamesSearch: boolean;
    gameVideo: any;
    showSearchInTopNavigation: boolean;
    showHomeInTopNavigation: boolean;
    taxWithheld: boolean;
    bonusSection: boolean;
    freespinsSection: boolean;
    showGameTitle: boolean;
    goToTopArrow: boolean;
    showSeoBoxes: boolean;
    showLastLoginTime: boolean;
    showUnfinishedTooltipIcon: boolean;
    disableFreeSpins: boolean;
    enableJackpotTickerTeasers: boolean;
    showHomeFixedNavigation: boolean;
    enableRefresh: boolean;
    aamsEnabled: boolean;
    quickInfo: boolean;
    useLMTFeed: boolean;
    isFullyOptimizedLobby: boolean;
    browserLocalStorageCacheDuration: any;
    browserLocalStorageCategoryDataValidityDuration: number;
    browserLocalStorageCategoryDataUpdateInterval: number;
    enableClientStorage: boolean;
    downloadAppOnGameLaunch: boolean;
    enableEnhancedSearch: boolean;
    enableHashText: boolean;
    hashText: string;
    showSeeAllGamesCount: boolean;
    hideHomeInSubNav: boolean;
    setNavBarLimitTimeout: number;
    showSmartBanner: boolean;
    hideFavouritesOnPreLogin: boolean;
    favouriteGamesLobbyType: any;
    enableQuickInfoLobbyType: any;
    matGridColCount: number;
    showSubNavigationAboveTeaser: boolean;
    matGridColCountForLobbyType: any;
    showSubNavigationAboveTeaserLobbyType: any;
    newJackpotRibbonStyling: boolean;
    stickersForUk: any;
    newQuickInfoStyle: any;
    showStickersInOverlay: any;
    showJackpotvalueInOverlay: any;
    enableHotIcon: any;
    isUkHeading: any;
    enableFastLoadingGames: boolean;
    enableSkeletonLoading: any;
    enableUkMouseOverStyle: boolean;
    alignIconRight: any;
    globalJackpotInterVal: any;
    isGlobalJackpotEnabled: boolean;
    isAnchorTagVisibleOnQuickInfo: boolean;
    extendedTopMenuStatus: any;
    globalSearchFixedNavMobile: boolean;
    enableGSInRegularSearch: boolean;
    enableTitleOnGameTile: any;
    playNowHoverEffect: boolean;
    showEmbeddedTeasersAboveGames: boolean;
    enableEmbeddedTeasers: boolean;
    enableAccountUpgrade: boolean;
    preLoginPlayNowText: boolean;
    extendedTopMenuToRight: boolean;
    highlightCategoryHeader: any;
    enableFixedMobileNavigation: boolean;
    enableFortifiedOnWithdrwal: boolean;
    enablePlayBingoButton: boolean;
    isVanillaHomePageEnabled: boolean;
    enableRpForPrelogin: any;
    enableSignpostingSticker: any;
    enablePreLoginRecentlyPlayed: any;
    enableFreeSpinSummary: boolean;
    isPromoHubExist: boolean;
    showGlobalSearch: boolean;
    useSingleCookieForGameIndexCountInEGV: boolean;
    delimiterForDynamicGameNames: any;
    checkQDAllowedForDepositURL: boolean;
    rpPositionLmtKey: any;
    liveGamesIFrameHeight: number;
    showDesktopSearch: boolean;
    enableBottomSubNavigation: boolean;
    enableHeaderforCategory: boolean;
    skipDocReferrer: boolean;
    enableOptimoveFavProduct: boolean;
    showNetflixVideo: boolean;
    enableSlotRacesForLobbyType: any;
    featuredGridSetHeightTimeOut: number;
    subscribeCurrentTabInProductMenu: boolean;
    enableFreeSpinsOnQuickInfo: boolean;
    defaultProduct: string;
    dynamicSeeAllLink: any;
    enableOverlayMobileSearchTopPosition: boolean;
    mostPlayedRouteForLobbyType: any;
    navigationBackgroundColor: boolean;
    hotIconPath: any;
    enableNewQuickInfoLobbyType: any;
    showVolatilityTable: boolean;
    storeVendorInfoInCache: boolean;
    gtmCheckInterval: number;
    ScrollOnTopTimeFrame: number;
    enableNewSkeletonUI: boolean;
    enableSuperButton: boolean;
    enableClickInteraction: boolean;
    clickInteractionTimeout: number;
    enableStickerStyles: boolean;
    superButtonTimeout: number;
    enableGameIconImageSuffix: boolean;
}

export function featuresClientConfigFactory(service: LazyClientConfigService) {
    return service.get(FeaturesClientConfig);
}

@LazyClientConfig({ key: 'csGlobal', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: globalClientConfigFactory,
})
export class GlobalClientConfig extends LazyClientConfigBase {
    messages: any;
    unfinishedTooltip: any;
    unfinishedStyle: any;
    lobbyConfigurations: any;
    gameDescriptionMapping: any;
    globalJackpotConfigurations: any;
    globalJackpotEnabled: boolean;
    moreInfoConfigurations: any;
    stickerStyles: any;
}

export function globalClientConfigFactory(service: LazyClientConfigService) {
    return service.get(GlobalClientConfig);
}

@LazyClientConfig({ key: 'csNativeGameLaunch', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: nativeGameLaunchClientConfigFactory,
})
export class NativeGameLaunchClientConfig {
    isOnlyGameidLaunchEvent: boolean;
    vendorsforShouldOverrideUserAgent: string[];
}

export function nativeGameLaunchClientConfigFactory(service: LazyClientConfigService) {
    return service.get(NativeGameLaunchClientConfig);
}

@LazyClientConfig({ key: 'cslhHomePage', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: labelHostHomePageClientConfigFactory,
})
export class LabelHostHomePageClientConfig {
    isEnabledCondition: string;
    isEnabled: boolean;
}

export function labelHostHomePageClientConfigFactory(service: LazyClientConfigService) {
    return service.get(LabelHostHomePageClientConfig);
}

@LazyClientConfig({ key: 'csDevSettingsConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: roomClientConfigFactory,
})
export class DevSettingsConfig {
    categoryDataCacheTimeOut: number;
    enableCategoryDataCache: boolean;
    gameIconTemplate: string;
    getGameIconFromLMT: boolean;
    isOptimizedLobby: boolean;
    isFullyOptimizedLobby: boolean;
    multiProductSupport: boolean;
    showInhouseGamesConsole: boolean;
    masterUserPattern: string;
    isPreviewUser: boolean;
    enablePerformanceMarking: boolean;
    subCategoryLoadLimit: number;
    subCategoryLoadLimitOverlayOverride: number;
}

export function devSettingsConfigFactory(service: LazyClientConfigService) {
    return service.get(DevSettingsConfig);
}

@LazyClientConfig({ key: 'csHeaderTeasers', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: headerTeasersClientConfigFactory,
})
export class HeaderTeasersClientConfig {}

export function headerTeasersClientConfigFactory(service: LazyClientConfigService) {
    return service.get(HeaderTeasersClientConfig);
}

export interface JackpotData {
    showOnTiles: boolean;
    jpInterval: number;
}

@LazyClientConfig({ key: 'csJackpotData', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: jackpotDataClientConfigFactory,
})
export class JackpotDataClientConfig {
    showOnTiles: boolean;
    jpInterval: number;
    mustGoJackpotTimerBase: Time;
    showJackpotOnGameTilesLobbyType: any;
    enableTickingForJackpotOnGameTiles: boolean;
    enableJpTickingForLobbyType: any;
    enableJpGroupTickingForLobbyType: any;
    showJackpotCurrencyOnRight: boolean;
}

export function jackpotDataClientConfigFactory(service: LazyClientConfigService) {
    return service.get(JackpotDataClientConfig);
}

@LazyClientConfig({ key: 'csLiveDealerConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: liveDealerConfigClientConfigFactory,
})
export class LiveDealerConfigClientConfig {
    operatingHoursEnabled: boolean;
    openHours: number;
    openMinutes: number;
    openSeconds: number;
    closeHours: number;
    closeMinutes: number;
    closeSeconds: number;
}

export function liveDealerConfigClientConfigFactory(service: LazyClientConfigService) {
    return service.get(LiveDealerConfigClientConfig);
}

@LazyClientConfig({ key: 'csMcPage', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: mcPageClientConfigFactory,
})
export class McPageClientConfig {
    cashierTrackerIds: any[];
    cashierWithdrawUrlTemplate: string;
    cashierDepositUrlTemplate: string;
    cashierQDUrlTemplate: string;
    prefix: string;
    myFreeSpinsHistoryItem: any;
    minAgeValidationCasinoPlay: number;
    defaultPage: any;
    defaultHomePage: any;
    casinoHomePage: any;
    portalUrl: any;
    langName: string;
    nativeAppScheme: string;
    extendTopMenu: any;
    labelPrefix: string;
    isAccountPage: boolean;
    isLobbyPage: boolean;
    isFreespinsPage: boolean;
    channelId: any;
    accountUpgrade: string;
    invokerProductHomePage: any;
    depositUrl: any;
    bingoPageUrl: any;
    bingoSeeAllOnCasino: any;
    playBingoButton: any;
    widgetHelpButton: any;
    widgetContactusButton: any;
    gamingHomeButtonUrl: any;
}

export function mcPageClientConfigFactory(service: LazyClientConfigService) {
    return service.get(McPageClientConfig);
}

@LazyClientConfig({ key: 'csMetaTags', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: mcMetaTagsClientConfigFactory,
})
export class McMetaTagsClientConfig {
    pages: any[];
}

export function mcMetaTagsClientConfigFactory(service: LazyClientConfigService) {
    return service.get(McMetaTagsClientConfig);
}

@LazyClientConfig({ key: 'csOptimizedImageLoading', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: optimizedImageLoadingClientConfigFactory,
})
export class OptimizedImageLoadingClientConfig {
    imageCheckPath: string;
    imageCheckSize: number;
    imageLoadLatency: number;
    imageQualityBandwidth: { [image: string]: string };
}

export function optimizedImageLoadingClientConfigFactory(service: LazyClientConfigService) {
    return service.get(OptimizedImageLoadingClientConfig);
}

@LazyClientConfig({ key: 'csPromotionsInfo', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: promotionsInfoClientConfigFactory,
})
export class PromotionsInfoClientConfig {
    showPromotions: boolean;
    promotionPageLink: any;
}

export function promotionsInfoClientConfigFactory(service: LazyClientConfigService) {
    return service.get(PromotionsInfoClientConfig);
}

@LazyClientConfig({ key: 'csTransactionsConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: transactionsConfigClientConfigFactory,
})
export class TransactionsConfigClientConfig {
    amountOfDisplayedSingleMonths: number;
    cutOffMonth: number;
    cutOffYear: number;
    dateCollectionLastYear: number;
    enableSummary: boolean;
    isAccountOverviewEnabled: boolean;
    pageSize: number;
    freeSpinHistoryRequestLimit: number;
}

export function transactionsConfigClientConfigFactory(service: LazyClientConfigService) {
    return service.get(TransactionsConfigClientConfig);
}

@LazyClientConfig({ key: 'csWidgetConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: widgetConfigClientConfigFactory,
})
export class WidgetConfigClientConfig {
    isRpFavWidgetEnabled: any;
    isGymlInFavourites: any;
    favouriteGamesBaseCount: any;
    recentGamesCountLimit: number;
    enableRcpwidgetHelpContactUs: any;
    isStickyEnabledForWidget: any;
    maxHeightCount: number;
    enableHelpContactForPrelogin: any;
    enableBingoWidgetForLabel: boolean;
    enableWidgetinFreespinsPage: boolean;
    displayFavInRPFavWidget: boolean;
    widgetLinks: any;
}

export function widgetConfigClientConfigFactory(service: LazyClientConfigService) {
    return service.get(WidgetConfigClientConfig);
}

@LazyClientConfig({ key: 'csThumbnail', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: thumbnailConfigClientConfigFactory,
})
export class ThumbnailConfigClientConfig {
    enableGameThumbnail: any;
    isGameThumbnailsVisible: any;
    thumbnailConfigurations: any;
    displayStaticThumbnail: any;
}

export function thumbnailConfigClientConfigFactory(service: LazyClientConfigService) {
    return service.get(ThumbnailConfigClientConfig);
}

@LazyClientConfig({ key: 'cslmtData', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: lmtDataClientConfigFactory,
})
export class LMTDataClientConfig {
    useLMTFeedForLobby: boolean;
    brand: string;
    invokerProduct: string;
    enableLMTGameLaunch: boolean;
    lmtCacheTimeout: number;
    lmtCacheTimeoutAuth: number;
    lobbyType: string;
    noIconUrl: string;
    fallbackCategoryTheme: string;
    homeCategory: string;
    gamesYouMightLikeCategory: string;
    lobbyTypeMap: any;
    enableDefaultHomeUrl: any;
    defaultProductHomeUrl: string;
    enableDefaultLink: boolean;
    appendCultureToDefaultUrl: boolean;
    enableValidateGames: boolean;
    enablePostloginPartialCalls: boolean;
    enableDynamicSequentialCalls: boolean;
}

export function lmtDataClientConfigFactory(service: LazyClientConfigService) {
    return service.get(LMTDataClientConfig);
}

@LazyClientConfig({ key: 'csGameConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: gameConfigClientConfigFactory,
})
export class GameConfigClientConfig {
    host: string;
    gameIconTemplate: string;
    iconTypeMapping: string[];
    maxGamesPerCategory: number;
    showLoadigIndicatorOnGamesLoading: boolean;
    nativeGameLaunchEnabled: boolean;
    vendorsWithOnlyRealModeLobbyType: any;
    enableDemoPlayLobbyType: any;
    showRGLinkOnDetailPageLobbyType: any;
    responsibleGamingLink: any;
    gameMode: string;
    iconConfigMap: any;
    enablePlaytechLiveGameDetailsPage: boolean;
    enableRCPFavWidgetOnPublicPages: boolean;
    enableQuickInfoReels: boolean;
    enableDemoMode: boolean;
    demoModeExcludedGamesRegex: string;
    launchGeolocationPollInSeparateWindow: boolean;
}

export function gameConfigClientConfigFactory(service: LazyClientConfigService) {
    return service.get(GameConfigClientConfig);
}

@LazyClientConfig({ key: 'csOptimizedTeaser', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: optimizedTeaserClientConfigFactory,
})
export class OptimizedTeaserClientConfig {
    teaserOptimization: boolean;
    teaserDefaultHorizontalAlignment: string;
    teaserDefaultVerticalAlignment: string;
    teaserDefaultImageAlignment: string;
    teaserDefaultHorizontalTextAlignment: string;
    teaserDefaultTermsTextAlignment: string;
    maxRenderedTeasers: number;
    enableAluminiumTeaser: boolean;
    enableFixedWidthAluminiumTeaser: boolean;
    setAluminiumDesktopTeaserHeight: number;
    setAluminiumTabletTeaserHeight: number;
    setAluminiumMobileTeaserHeight: number;
    setAluminiumDesktopTeaserWidth: number;
    setAluminiumTabletTeaserWidth: number;
    setAluminiumMobileTeaserWidth: number;
}

export function optimizedTeaserClientConfigFactory(service: LazyClientConfigService) {
    return service.get(OptimizedTeaserClientConfig);
}

@LazyClientConfig({ key: 'csGeolocationConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: geolocationClientConfigFactory,
})
export class GeolocationClientConfig {
    isEnabled: boolean;
    coordinatorSettings: GeolocationConfigurationModel;
    installerSettings: InstallerSettingsModel;
    oobeeIntimation: OobeeIntimationModel;
    synchronizationEvent: string;
    synchronizeCoordination: boolean;
}

export function geolocationClientConfigFactory(service: LazyClientConfigService) {
    return service.get(GeolocationClientConfig);
}

@LazyClientConfig({ key: 'csCasinoAsIFrameOverlayConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: casinoAsIFrameOverlayConfigFactory,
})
export class CasinoAsIFrameOverlayConfig {
    overlayBottomNavLobbyTypeMap: any;
    overlayLobbyTypeMap: any;
}

export function casinoAsIFrameOverlayConfigFactory(service: LazyClientConfigService) {
    return service.get(CasinoAsIFrameOverlayConfig);
}

@LazyClientConfig({ key: 'csGlobalSearch', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: globalSearchClientConfigFactory,
})
export class GlobalSearchClientConfig {
    appId: string;
    configPath: string;
    contentPath: string;
    indexPath: string;
    isEnabled: boolean;
    searchLanguage: string;
    targetId: string;
    textMapping: ITextMapping;
    transportToken: string;
}

export function globalSearchClientConfigFactory(service: LazyClientConfigService) {
    return service.get(GlobalSearchClientConfig);
}

@LazyClientConfig({ key: 'csCasinoGlobalSearch', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: casinoGlobalSearchClientConfigFactory,
})
export class CasinoGlobalSearchClientConfig {
    isEnabled: boolean;
    bootstrapOrigin: string;
    bootstrapTimeout: number;
    pathBasedEnabler: IPathBasedEnableORDisable;
}

export function casinoGlobalSearchClientConfigFactory(service: LazyClientConfigService) {
    return service.get(CasinoGlobalSearchClientConfig);
}

@LazyClientConfig({ key: 'csGlobalJackpot', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: globalJackpotClientConfigFactory,
})
export class GlobalJackpotClientConfig {
    globalJackpotPathSegments: string;
    lobbyType: string;
    enablePhase2: boolean;
    enablePhase3: boolean;
    enablePhase3Optimization: boolean;
    enableShadowCard: boolean;
    globalJackpotPathSegmentsArray: any;
    amountSeparators: any;
    defaultCurrency: string;
    isEnabled: boolean;
    updatedPoolFeedInterval: number;
    globalJackpotScrollSetTimeOut: number;
    enableWinnersFeed: boolean;
    globalJackpotWinType: Array<string>;
    showClaimToasterOnGlobalJackpot: boolean;
    clearToasterTimeout: number;
    enableNewWinnersFeedUI: boolean;
}

export function globalJackpotClientConfigFactory(service: LazyClientConfigService) {
    return service.get(GlobalJackpotClientConfig);
}

@LazyClientConfig({ key: 'csSubNavigationConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: subNavigationClientConfigFactory,
})
export class SubNavigationClientConfig {
    isEnabledCondition: string;
    enableSubNavOnLobbyType: any;
    enableDesktopIcon: boolean;
    enableEpcotSubNavigation: boolean;
    hideNavBarForEpcotTouch: any;
    enablePillsSubNavigation: boolean;
}

export function subNavigationClientConfigFactory(service: LazyClientConfigService) {
    return service.get(SubNavigationClientConfig);
}

@LazyClientConfig({ key: 'caAdvanceGameDataModel', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: advanceGameDataClientConfigFactory,
})
export class AdvanceGameDataClientConfig {
    enableAdvanceGameData: boolean;
    enableFeatureId: boolean;
}

export function advanceGameDataClientConfigFactory(service: LazyClientConfigService) {
    return service.get(AdvanceGameDataClientConfig);
}

@LazyClientConfig({ key: 'csCozyBingo', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: cozyBingoClientConfigFactory,
})
export class CozyBingoClientConfig {
    cozyBingoWidgetPosition: any;
    cozyBingoWidgetIframeSegment: string;
    cozyBingoFavouritesInRpwidget: boolean;
}

export function cozyBingoClientConfigFactory(service: LazyClientConfigService) {
    return service.get(CozyBingoClientConfig);
}

@LazyClientConfig({ key: 'csFeedCacheConfiguration', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: feedCacheClientConfigFactory,
})
export class FeedCacheClientConfig {
    disabledCacheTimeOut: number;
    disabledRequestThreshold: number;
    enableDisabledGames: boolean;
    favouriteCacheTimeOut: number;
    favouriteRequestThreshold: number;
    enableFavoritesFeed: boolean;
    jackpotCacheTimeOut: number;
    jackpotRequestThreshold: number;
    enableJackpotFeed: boolean;
    freeSpinsCacheTimeOut: number;
    freespinRequestThreshold: number;
    enableFreespinsFeed: boolean;
    recentCacheTimeOut: number;
    recentRequestThreshold: number;
    enableRecentlyPlayed: boolean;
    unFinishedCacheTimeOut: number;
    unFinishedRequestThreshold: number;
    enableUnfinishedGamesFeed: boolean;
    lmtCacheThreshold: number;
    enableLMTFeedCache: boolean;
}

export function feedCacheClientConfigFactory(service: LazyClientConfigService) {
    return service.get(FeedCacheClientConfig);
}

@LazyClientConfig({ key: 'csRecentlyPlayed', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: recentlyPlayedClientConfigFactory,
})
export class RecentlyPlayedClientConfig {
    recentlyPlayedRoutes: any;
    isMostRecentlyPlayedEnabled: boolean;
    recentlyPlayedFilterThreshold: number;
}

export function recentlyPlayedClientConfigFactory(service: LazyClientConfigService) {
    return service.get(RecentlyPlayedClientConfig);
}

@LazyClientConfig({ key: 'csModularSettings', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: modularSettingsClientConfigFactory,
})
export class ModularSettingsClientConfig {
    productMap: any;
    forceLoginGameObjectExpirationTime: any;
    featuredGridSetHeightTimeOut: any;
    forceGameLaunchExecutionTimeOut: number;
}

export function modularSettingsClientConfigFactory(service: LazyClientConfigService) {
    return service.get(ModularSettingsClientConfig);
}

export interface ITextMapping {
    shared: object;
    versioned: object;
}

@LazyClientConfig({ key: 'csFreeSpins', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: freeSpinsClientConfigFactory,
})
export class FreeSpinsClientConfig {
    freeSpinsConfigurations: any;
    freeSpinsMoreInfo: any;
    freespinHost: string;
    freeSpinSummaryPathSegment: string;
    rewardDetailsPathSegment: string;
    isFreeSpinSummaryEnabled: boolean;
    isEnableFreeSpinsOnQuickInfo: boolean;
    showPayedOutCashMessage: boolean;
    freespinsVanillaActiveHeader: string;
    prominentFreeSpinsConfigurations: any;
    isEnableProminentFreeSpinsDisplay: boolean;
}

export function freeSpinsClientConfigFactory(service: LazyClientConfigService) {
    return service.get(FreeSpinsClientConfig);
}

@LazyClientConfig({ key: 'csCasinoTagManager', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: tagManagerClientConfigFactory,
})
export class TagManagerClientConfig {
    triggerGoogleTagManagerClientInjection: boolean;
    googleTagManagerInjectionDelay: number;
}

export function tagManagerClientConfigFactory(service: LazyClientConfigService) {
    return service.get(TagManagerClientConfig);
}

@LazyClientConfig({ key: 'csImageLoadOptimizationConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: imageLoadOptimizationClientConfigFactory,
})
export class ImageLoadOptimizationClientConfig {
    isDynamicResizingEnabled: boolean;
    dynamicResizingQueryString: string;
}

export function imageLoadOptimizationClientConfigFactory(service: LazyClientConfigService) {
    return service.get(ImageLoadOptimizationClientConfig);
}

@LazyClientConfig({ key: 'csGamingStoriesConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: gamingStoriesClientConfigFactory,
})
export class GamingStoriesClientConfig {
    localStorageExpirationDuration: number;
    trackViewedStateForPostLogin: boolean;
    trackViewedStateForPreLogin: boolean;
    showStoryViewedTickMark: boolean;
    enableOverlayMobileSearchTopPosition: boolean;
    gameLaunchSetTimeOut: number;
}

export function gamingStoriesClientConfigFactory(service: LazyClientConfigService) {
    return service.get(GamingStoriesClientConfig);
}

// @ClientConfig('preRender')
// export class PreRenderClientConfig {
//     preRenderScriptTimeOut:number;
// }

export const CASINO_CLIENTCONFIGS = [
    CasinoConfigClientConfig,
    ClientSideConfigClientConfig,
    FeaturesClientConfig,
    GlobalClientConfig,
    HeaderTeasersClientConfig,
    JackpotDataClientConfig,
    LiveDealerConfigClientConfig,
    McPageClientConfig,
    OptimizedImageLoadingClientConfig,
    PromotionsInfoClientConfig,
    TransactionsConfigClientConfig,
    McMetaTagsClientConfig,
    LMTDataClientConfig,
    GameConfigClientConfig,
    GeolocationClientConfig,
    NativeGameLaunchClientConfig,
    WidgetConfigClientConfig,
    CasinoAsIFrameOverlayConfig,
    GlobalSearchClientConfig,
    CasinoGlobalSearchClientConfig,
    GlobalJackpotClientConfig,
    SubNavigationClientConfig,
    FeedCacheClientConfig,
    RecentlyPlayedClientConfig,
    ModularSettingsClientConfig,
    TagManagerClientConfig,
    ImageLoadOptimizationClientConfig,
    GamingStoriesClientConfig,
];
