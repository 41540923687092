import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';

//declare var angular: angular.IAngularStatic;

@Injectable({
    providedIn: 'root',
})
export class LiveDealerService {
    state: any;
    private liveDealerStateChange = new BehaviorSubject<any>(null);
    liveDealerStateChangeObservable: Observable<any> = this.liveDealerStateChange.asObservable();
    constructor(private configProviderService: ConfigProviderService) {}

    getOperatingTimes() {
        const liveDealerConfig = this.configProviderService.provideLiveDealerConfig();
        const now = this.getUtcTime();

        const openTime = this.getUtcTime();
        openTime.setHours(liveDealerConfig.openHours, liveDealerConfig.openMinutes, liveDealerConfig.openSeconds);

        const closeTime = this.getUtcTime();
        closeTime.setHours(liveDealerConfig.closeHours, liveDealerConfig.closeMinutes, liveDealerConfig.closeSeconds);

        if (closeTime > openTime) {
            if (now > closeTime) {
                closeTime.setDate(closeTime.getDate() + 1);
                openTime.setDate(openTime.getDate() + 1);
            }
        } else {
            if (now >= openTime) {
                closeTime.setDate(closeTime.getDate() + 1);
            } else if (now < closeTime) {
                openTime.setDate(openTime.getDate() - 1);
            }
        }

        return {
            openTime: openTime,
            closeTime: closeTime,
        };
    }

    onStateChange(open: any) {
        this.state = open;
        this.liveDealerStateChange.next({ open: open });
    }

    init() {
        this.state = this.isOpen();

        setInterval(() => {
            const open = this.isOpen();
            if (this.state !== open) {
                this.onStateChange(open);
            }
        }, 60000);
    }

    fireStateChange(open: any) {
        this.onStateChange(open);
    }

    isOpen(): boolean {
        const liveDealerConfig = this.configProviderService.provideLiveDealerConfig();
        if (!liveDealerConfig.operatingHoursEnabled) {
            return true;
        }
        const operatingTimes = this.getOperatingTimes();
        const now = this.getUtcTime();

        return now >= operatingTimes.openTime && now < operatingTimes.closeTime;
    }

    getSecondsUntilOpen(): number {
        const liveDealerConfig = this.configProviderService.provideLiveDealerConfig();
        if (!liveDealerConfig.operatingHoursEnabled) {
            return 0;
        }

        const operatingTimes = this.getOperatingTimes();
        const now = this.getUtcTime();

        return Math.abs((operatingTimes.openTime.getTime() - now.getTime()) / 1000);
    }

    getUtcTime(): Date {
        const date = new Date();
        date.setTime(date.getTime() + date.getTimezoneOffset() * 60000);
        return date;
    }
}
