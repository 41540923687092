import { NgIf } from '@angular/common';
import { AfterViewInit, Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { DynamicHtmlDirective, LoadingIndicatorHandler, LoadingIndicatorService, NavigationService, Page, WindowRef } from '@frontend/vanilla/core';
import { PopperContentComponent } from '@frontend/vanilla/features/popper';
import { NgxFloatUiDirective, NgxFloatUiModule } from 'ngx-float-ui';

import { ConfigProviderService } from '../../config-provider/config-provider.service';
import { BroswerStateDirective } from '../../directives/browser-state.directive';
import { CasinoManager } from '../../shared/casino-manager.service';
import { GeolocationTrackingService } from '../../shared/geolocation-tracking.service';
import { GeolocationCoordinatorService } from '../services/coordinator.service';
import { GeolocationCheckService } from '../services/geolocation-check.service';

@Component({
    selector: 'cc-geolocation-installer',
    templateUrl: 'installer.component.html',
    standalone: true,
    imports: [
        NgIf,
        MatDialogTitle,
        MatDialogContent,
        DynamicHtmlDirective,
        MatDialogActions,
        NgxFloatUiDirective,
        PopperContentComponent,
        BroswerStateDirective,
        PopperContentComponent,
        NgxFloatUiModule,
    ],
})
export class GeolocationInstallerComponent implements AfterViewInit {
    showLoader = false;
    @Input() data: any;
    loadingIndicatorHandler: LoadingIndicatorHandler;
    constructor(
        public readonly dialogRef: MatDialogRef<GeolocationInstallerComponent>,
        private readonly geolocationCoordinatorService: GeolocationCoordinatorService,
        private readonly geoLocationCheckService: GeolocationCheckService,
        private readonly _domSanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) private readonly additionalInfo: any,
        private readonly _geolocationTrackingService: GeolocationTrackingService,
        private configProviderService: ConfigProviderService,
        private navigationService: NavigationService,
        private casinoManager: CasinoManager,
        private m2Page: Page,
        private route: ActivatedRoute,
        private windowRef: WindowRef,
        private loadingIndicatorService: LoadingIndicatorService,
    ) {}

    get geoComplyMessage(): string {
        return this.configProviderService.provideDownloadAppFromDeviceConfig().geoComplyMessage;
    }

    get appDownloadLink(): SafeResourceUrl {
        return this._domSanitizer.bypassSecurityTrustUrl(
            /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
                ? 'javascript:void(0)'
                : /(Android)/g.test(navigator.userAgent)
                  ? this.getGlobalConfig().messages.AndroidAppDownloadLink
                  : '',
        );
    }

    get continueButtonText(): string {
        return this.getGlobalConfig().messages['GeoComplyContinueButtonText'];
    }

    get continuePlayOnAppInstructionText(): string {
        return this.getGlobalConfig().messages['ContinuePlayOnAppInstructionText'] || 'Continue play on our app, with automatic geolocation';
    }

    get continuePlayOnAppUserActionText(): string {
        return this.getGlobalConfig().messages['ContinuePlayOnAppUserActionText'] || 'CONTINUE PLAY ON PLAYMGM APP';
    }

    get continuePlayOnWebInstructionText(): string {
        return (
            this.getGlobalConfig().messages['ContinuePlayOnWebInstructionText'] ||
            'To play on web, Geoguard needs to be installed and running on your device'
        );
    }

    get oobeeInstallerDownloadUserActionText(): string {
        return this.getGlobalConfig().messages['OobeeInstallerDownloadUserActionText'] || 'INSTALL OR OPEN GEOGUARD';
    }

    get oobeeInstallerContinueUserActionText(): string {
        return this.getGlobalConfig().messages['OobeeInstallerContinueUserActionText'] || 'GEOGUARD IS INSTALLED AND RUNNING';
    }

    get downloadButtonText(): string {
        return this.getGlobalConfig().messages['GeoComplyDownloadButtonText'];
    }

    get geocomplyText(): string {
        return this.getGlobalConfig().messages['GeoComplyText'];
    }
    get geocomplyTitle(): string {
        return this.getGlobalConfig().messages['GeoComplyTitle'];
    }

    get geoguardEnquiryUserActionText(): string {
        return this.getGlobalConfig().messages['GeoguardEnquiryUserActionText'] || 'WHAT IS GEOGUARD?';
    }

    get oobeeInstallerDownloadStepText(): string {
        return this.getGlobalConfig().messages['OobeeInstallerDownloadStepText'] || 'Step 1';
    }

    get oobeeInstallerContinueStepText(): string {
        return this.getGlobalConfig().messages['OobeeInstallerContinueStepText'] || 'Step 2';
    }

    get geolocationMobileInstallerTitle(): string {
        return this.getGlobalConfig().messages['GeolocationMobileInstallerTitle'] || 'LOCATION';
    }

    get isMobileInstaller(): boolean {
        if (this.data && this.data.isMobileInstaller) {
            return this.data && this.data.isMobileInstaller;
        } else {
            return this.additionalInfo && this.additionalInfo.isMobileInstaller;
        }
    }

    get oobeeInstallerHeadingText(): string {
        return this.getGlobalConfig().messages['OobeeInstallerHeadingText'] || 'Want to continue playing?';
    }

    get oobeeInstallerHeadingInstruction(): string {
        return (
            this.getGlobalConfig().messages['OobeeInstallerHeadingInstruction'] ||
            'Before you launch a game we are required by law to confirm your location.'
        );
    }

    get playOnAppOrWebPlayerOptionsSeparatorText(): string {
        return this.getGlobalConfig().messages['PlayOnAppOrWebPlayerOptionsSeparatorText'] || 'OR';
    }

    get geoGuardDescriptionText(): string {
        return (
            this.getGlobalConfig().messages['GeoGuardDescriptionText'] ||
            `GeoGuard, a trusted 3rd party partner, allows you to obtain location data from your Android device. One time only install, launch every time you want to place a bet on web`
        );
    }
    continue(): void {
        this.showLoader = true;
        this.loadingIndicatorHandler = this.loadingIndicatorService.start();
        if (this.isMobileInstaller) {
            this._geolocationTrackingService.track('GeoLoc_Pop-up how to', 'Step 2 - Geoguard is Intalled and running - CTA Clicked');
        }
        this.geolocationCoordinatorService.initialize({
            showPLCPopup: true,
        });
        const callback = () => {
            this.showLoader = false;
            this.loadingIndicatorHandler.done();
        };
        this.geoLocationCheckService.checkGeolocation(callback, callback);
    }

    download(): void {
        if (this.isMobileInstaller) {
            this._geolocationTrackingService.track('GeoLoc_Pop-up how to', 'Step 1 - install or Open GeoGuard - CTA Clicked');
            if (this.data && this.data.downloadUrl) {
                window.open((this.data && this.data.downloadUrl) || '', '_blank');
                return;
            } else {
                window.open((this.additionalInfo && this.additionalInfo.downloadUrl) || '', '_blank');
                return;
            }
        }
        if (this.data && this.data.downloadUrl) {
            window.open((this.data && this.data.downloadUrl) || '', '_self');
        } else {
            window.open((this.additionalInfo && this.additionalInfo.downloadUrl) || '', '_self');
        }
    }

    close(): void {
        if (this.isMobileInstaller) {
            this._geolocationTrackingService.track('GeoLoc_Pop-up how to', 'Pop-up_Close');
        }
        this.dialogRef && this.dialogRef.close();
        if (
            this.navigationService.location.search.has('specialproduct') &&
            this.navigationService.location.search.get('specialproduct') === 'globalsearch'
        ) {
            const returnUrl = this.navigationService.location.search.has('returnurl')
                ? this.navigationService.location.search.get('returnurl')
                : this.navigationService.goToLastKnownProduct();
            if (returnUrl) {
                this.navigationService.goTo(returnUrl);
            }
        } else if (this.windowRef.nativeWindow.location?.pathname?.toLowerCase()?.indexOf('launchng') >= 0) {
            const returnUrl = this.route?.snapshot?.queryParamMap?.get('returnUrl') || this.route?.snapshot?.queryParamMap?.get('returnurl');
            if (returnUrl) {
                this.navigationService?.goTo(returnUrl);
            } else {
                const lobbyType = this.casinoManager?.getLobbyType() || '';
                this.navigationService?.goTo('/' + this.m2Page.lang + '/' + this.casinoManager?.getProductForLobbyType(lobbyType)?.toLowerCase());
            }
        } else if (this.windowRef.nativeWindow.location?.pathname?.toLowerCase()?.indexOf('forcedgamelaunch') >= 0) {
            const returnUrl =
                this.route?.snapshot?.queryParamMap?.get('originUrl') ||
                this.route?.snapshot?.queryParamMap?.get('originurl') ||
                this.route?.snapshot?.queryParamMap?.get('returnUrl') ||
                this.route?.snapshot?.queryParamMap?.get('returnurl');
            if (returnUrl) {
                this.navigationService?.goTo(returnUrl);
            } else {
                const lobbyType = this.casinoManager?.getLobbyType() || '';
                this.navigationService?.goTo('/' + this.m2Page.lang + '/' + this.casinoManager?.getProductForLobbyType(lobbyType)?.toLowerCase());
            }
        }
    }

    appDownloaderClicked(): void {
        let appDownloadLink = '';
        let appId = this.getGlobalConfig().messages.AndroidAppID ? this.getGlobalConfig().messages.AndroidAppID : '';
        if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
            appDownloadLink = this.getGlobalConfig().messages.iOSAppDownloadLink;
            appId = this.getGlobalConfig().messages.iOSAppID ? this.getGlobalConfig().messages.iOSAppID : '';
        }
        this._geolocationTrackingService.trackPosition('GeoLoc_Pop-up how to', 'Continue to play on an APP_ CTA Clicked', appId);
        if (!appDownloadLink) {
            return;
        }
        window.open(appDownloadLink, '_blank');
    }

    hideLoader(): void {
        this.showLoader = false;
    }

    info() {
        if (this.isMobileInstaller) {
            this._geolocationTrackingService.track('GeoLoc_Pop-up how to', 'InfoTool - What is Geoguard - CTA Clicked');
        }
    }

    ngAfterViewInit() {
        this._geolocationTrackingService.track('GeoLoc_Pop-up how to', 'Pop-up_Loaded');
    }

    getGlobalConfig(): any {
        return this.configProviderService.provideGlobalConfig();
    }
}
