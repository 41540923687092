import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csGameSelectorConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: gameSelectorWidgetClientConfigFactory,
})
export class GameSelectorWidgetClientConfig {
    isEnabled: boolean;
    gameSelectionLimit: number;
    gameSelectorWidgetPhaseTwo: boolean;
}

export function gameSelectorWidgetClientConfigFactory(service: LazyClientConfigService) {
    return service.get(GameSelectorWidgetClientConfig);
}
