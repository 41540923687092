import { DialogPosition, MatDialogConfig as _MatDialogConfig } from '@angular/material/dialog';

export const diractionMap: any = { left: 'left', right: 'left', top: 'top', bottom: 'top' };
export const multyMap: any = { left: 1, right: -1, top: 1, bottom: -1 };
export interface AnimationOption {
    keyframes?: Keyframe[];
    keyframeAnimationOptions: KeyframeAnimationOptions;
}
export interface MatDialogConfig extends _MatDialogConfig {
    title?: string;
    animation?:
        | {
              to: 'aside' | 'top' | 'bottom' | 'left' | 'right';
              incomingOptions?: { keyframes?: Keyframe[]; keyframeAnimationOptions: KeyframeAnimationOptions };
              outgoingOptions?: { keyframes?: Keyframe[]; keyframeAnimationOptions: KeyframeAnimationOptions };
          }
        | {
              to?: 'aside' | 'top' | 'bottom' | 'left' | 'right';
              incomingOptions?: { keyframes: Keyframe[]; keyframeAnimationOptions: KeyframeAnimationOptions };
              outgoingOptions?: { keyframes: Keyframe[]; keyframeAnimationOptions: KeyframeAnimationOptions };
          };
    position?: DialogPosition & { rowStart?: string; rowEnd?: string };
}
