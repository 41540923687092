import { Injectable } from '@angular/core';

import { Message, MessageLifetime, MessageQueueService, MessageType, TrackingService, UserService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';
import {
    FetchUserRequestAPIModel,
    FetchUserResponseAPIModel,
    GamingStoriesPhase2Configuration,
    GamingStoriesStorageCloseModel,
    IGamingStoryContainer,
    IGamingStoryItem,
    PostUserRequestAPIModel,
    ResponseDataModel,
    StoriesLocalStorageModel,
    StoryInfoData,
    StoryInfoDataInSessionStorage,
} from './models/gaming-stories.model';

interface IGetSwipeUpDataResponse {
    swipeUpStoryItemContent: Array<IGamingStoryItem>;
}

@Injectable({
    providedIn: 'root',
})
export class GamingStoriesService {
    observable: Observable<any | null>;
    swipeUpObservable: Observable<boolean | null>;
    postGamingStoriesAPIObservable: Observable<boolean | null>;
    fetchGamingStoriesAPIObservable: Observable<boolean | null>;
    storiesFeedObservable: Observable<boolean | null>;
    storyContainerCloseList: GamingStoriesStorageCloseModel;
    pausePlayStory: boolean = false;
    muteVideo: string = 'muted';
    storyPaused: boolean = false;
    storyHasVideo: boolean = false;
    spliceDuplicateStore: boolean = false;
    storyInfoPostRequestList: Array<StoryInfoData> = [];
    storyInfoDataInPreLoginSessionStorageList: Array<StoryInfoDataInSessionStorage> = [];
    storeFetchAPIDataResList: Array<StoryInfoDataInSessionStorage> = [];
    duplicateRecords: Array<StoryInfoDataInSessionStorage> = [];
    uInputUndefinedCheck: Array<StoryInfoDataInSessionStorage> = [];
    private videoPresentBehaviourSubject = new BehaviorSubject<any>(null);
    videoPresentObservable: Observable<boolean> = this.videoPresentBehaviourSubject.asObservable();
    private videoProgressBehaviourSubject = new Subject<number>();
    videoProgressObservable: Observable<number> = this.videoProgressBehaviourSubject.asObservable();
    private optInPublisher = new BehaviorSubject<boolean>(false);
    optInObservable: Observable<boolean | null>;
    private changePromoStatusBehaviourSubject = new BehaviorSubject<any>(null);
    changePromoStatusObservable: Observable<boolean> = this.changePromoStatusBehaviourSubject.asObservable();
    pauseStoryBehaviourSubject = new BehaviorSubject<any>(null);
    pauseStoryObservable: Observable<boolean> = this.pauseStoryBehaviourSubject.asObservable();
    videoMuteBehaviourSubject = new BehaviorSubject<string>('');
    videoMuteObservable: Observable<string> = this.videoMuteBehaviourSubject.asObservable();
    fetchAPIBehaviourSubject = new BehaviorSubject<any>(null);
    fetchAPIObservable: Observable<Message> = this.fetchAPIBehaviourSubject.asObservable();

    private fetchUserAPIResponse: FetchUserResponseAPIModel;
    private storyData: IGamingStoryContainer;
    private gamingStoriesPhase2Config: GamingStoriesPhase2Configuration;
    private publisher = new BehaviorSubject<boolean>(false);
    private swipeUpContentData: Array<IGamingStoryItem>;
    private swipeUpPublisher = new BehaviorSubject<boolean>(false);
    private postGamingStoriesAPIPublisher = new BehaviorSubject<boolean>(false);
    private fetchGamingStoriesAPIPublisher = new BehaviorSubject<boolean>(false);
    private storiesFeedPublisher = new BehaviorSubject<boolean>(false);
    private gamingStoryItemOptInData: any;

    constructor(
        private tracking: TrackingService,
        private api: PlatformApiService,
        private messageQueue: MessageQueueService,
        private configProviderService: ConfigProviderService,
        private user: UserService,
    ) {
        this.gamingStoriesPhase2Config = this.configProviderService.provideGamingStoriesPhase2Config();
        this.observable = this.publisher.asObservable();
        this.swipeUpObservable = this.swipeUpPublisher.asObservable();
        this.postGamingStoriesAPIObservable = this.postGamingStoriesAPIPublisher.asObservable();
        this.fetchGamingStoriesAPIObservable = this.fetchGamingStoriesAPIPublisher.asObservable();
        this.storiesFeedObservable = this.storiesFeedPublisher.asObservable();
        this.optInObservable = this.optInPublisher.asObservable();
    }

    get config(): GamingStoriesPhase2Configuration {
        return this.gamingStoriesPhase2Config;
    }
    get getStoryContainerData(): IGamingStoryContainer {
        return this.storyData;
    }
    get gamingStoryItemOptIn(): any {
        return this.gamingStoryItemOptInData;
    }
    get swipeUpContentList(): Array<IGamingStoryItem> {
        return this.swipeUpContentData;
    }
    get fetchGamingStoriesAPIRes(): FetchUserResponseAPIModel {
        return this.fetchUserAPIResponse;
    }
    get postStoryInfoRequestItemsList(): Array<StoryInfoData> {
        return this.storyInfoPostRequestList;
    }
    get fetchstoryInfoDataInPreLoginSessionStorageList(): Array<StoryInfoDataInSessionStorage> {
        return this.storyInfoDataInPreLoginSessionStorageList;
    }
    get fetchStoryInfoDataList(): Array<StoryInfoDataInSessionStorage> {
        return this.storeFetchAPIDataResList;
    }
    get getStoryContainerCloseList(): GamingStoriesStorageCloseModel {
        return this.storyContainerCloseList;
    }
    getGamingStories(storyContainerPath: string, product: string, productPath: string): void {
        this.api
            .post(
                'GamingStoriesPhase2/GetGamingStories',
                { storyContainerPath: storyContainerPath, product: product, productPath: productPath },
                { responseType: 'json', withCredentials: true, showSpinner: false },
            )
            .subscribe({
                next: (response: any) => {
                    if (response) {
                        this.storyData = response.gamingStories;
                        let data: any = {};
                        if (this.user.isAuthenticated) {
                            data = { AfterApiCall: true };
                        } else {
                            data = { AfterApiCall: false };
                        }
                        this.publisher.next(data);
                    }
                },
                error: (error: string) => {
                    const message: Message = {
                        html: error,
                        type: MessageType.Error,
                        lifetime: MessageLifetime.Single,
                        scope: 'casinocorelobby',
                    };
                    this.messageQueue.add(message);
                },
            });
    }
    getSwipeUpContent(swipeUpContentPath: string): void {
        this.api
            .post(
                'GamingStoriesPhase2/GetSwipeUpContent',
                { storyContainerPath: swipeUpContentPath },
                { responseType: 'json', withCredentials: true, showSpinner: false },
            )
            .subscribe({
                next: (response: IGetSwipeUpDataResponse) => {
                    if (response) {
                        this.swipeUpContentData = response.swipeUpStoryItemContent;
                        this.swipeUpPublisher.next(true);
                    }
                },
                error: (error: string) => {
                    const message: Message = {
                        html: error,
                        type: MessageType.Error,
                        lifetime: MessageLifetime.Single,
                        scope: 'casinocorelobby',
                    };
                    this.messageQueue.add(message);
                },
            });
    }
    getGamingOptIn(type: string, id: string, optIn: boolean, source: string): void {
        this.api
            .get(
                'optinpromo/GetOptedPromo',
                { type: type, id: id, optIn: optIn, source: source },
                { responseType: 'json', withCredentials: true, showSpinner: false },
            )
            .subscribe({
                next: (response: any) => {
                    if (response) {
                        this.gamingStoryItemOptInData = response;
                        this.optInPublisher.next(true);
                    }
                },
                error: (error: string) => {
                    const message: Message = {
                        html: error,
                        type: MessageType.Error,
                        lifetime: MessageLifetime.Single,
                        scope: 'casinocorelobby',
                    };
                    this.messageQueue.add(message);
                },
            });
    }

    postGamingStoriesAPI(postUserRequestAPIModel: PostUserRequestAPIModel): void {
        this.api
            .post('GamingStoriesPhase2/PostUserResponseAsync', postUserRequestAPIModel, {
                responseType: 'json',
                withCredentials: true,
                showSpinner: false,
            })
            .subscribe({
                next: (response: any) => {
                    if (response) {
                        this.postGamingStoriesAPIPublisher.next(true);
                    }
                },
                error: (error: string) => {
                    const message: Message = {
                        html: error,
                        type: MessageType.Error,
                        lifetime: MessageLifetime.Single,
                        scope: 'casinocorelobby',
                    };
                    this.messageQueue.add(message);
                },
            });
    }
    fetchGamingStoriesAPI(fetchUserRequestAPIModel: FetchUserRequestAPIModel, expirationTime: number): void {
        this.api
            .post('GamingStoriesPhase2/FetchingUserResponseAsync', fetchUserRequestAPIModel, {
                responseType: 'json',
                withCredentials: true,
                showSpinner: true,
            })
            .subscribe({
                next: (response: FetchUserResponseAPIModel) => {
                    if (response.status !== 'Failed') {
                        this.fetchUserAPIResponse = response;
                        this.fetchGamingStoriesAPIPublisher.next(true);
                        this.fetchGamingStoriesAPIRes.response.forEach((fetchDataRes) => {
                            const currentEpocTime = new Date().getTime() / 1000;
                            const storyInfoDataInSessionStorage: StoryInfoDataInSessionStorage = new StoryInfoDataInSessionStorage();
                            storyInfoDataInSessionStorage.response = new ResponseDataModel();
                            storyInfoDataInSessionStorage.response.sId = fetchDataRes.sId;
                            storyInfoDataInSessionStorage.response.subSId = fetchDataRes.subSId;
                            storyInfoDataInSessionStorage.response.uInput = fetchDataRes.uInput;
                            storyInfoDataInSessionStorage.response.uAvg = fetchDataRes.uAvg;
                            storyInfoDataInSessionStorage.expiryTime = Math.round(currentEpocTime + expirationTime * 60);
                            storyInfoDataInSessionStorage.userId = this.fetchGamingStoriesAPIRes.uId;
                            this.storeFetchAPIDataRes(storyInfoDataInSessionStorage);
                        });
                    } else {
                        const message: Message = {
                            html: 'failed',
                            type: MessageType.Error,
                            lifetime: MessageLifetime.Single,
                            scope: 'casinocorelobby',
                        };
                        this.fetchAPIBehaviourSubject.next(message);
                    }
                },
                error: (error: string) => {
                    let message: Message = {
                        html: error,
                        type: MessageType.Error,
                        lifetime: MessageLifetime.Single,
                        scope: 'casinocorelobby',
                    };
                    this.messageQueue.add(message);
                    this.fetchAPIBehaviourSubject.next(message);
                },
            });
    }
    trackingGamingStoriesPhase2Event(
        categoryEvent: string,
        labelEvent: string,
        actionEvent: string,
        positionEvent: string,
        locationEvent: string,
        eventDetails: string,
        contentPosition: string,
        uRLClicked: string,
    ): void {
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': categoryEvent,
            'component.LabelEvent': labelEvent,
            'component.ActionEvent': actionEvent,
            'component.PositionEvent': positionEvent,
            'component.LocationEvent': locationEvent,
            'component.EventDetails': eventDetails,
            'component.ContentPosition': contentPosition,
            'component.URLClicked': uRLClicked,
        });
    }
    pauseStory(pauseStoryTrigger: boolean) {
        this.storyPaused = pauseStoryTrigger;
        this.pauseStoryBehaviourSubject.next(pauseStoryTrigger);
    }
    pauseVideo() {
        let video = <HTMLVideoElement>document.getElementById('casino-gamingstory-video');
        let stream: HTMLMediaElement = {} as HTMLMediaElement;
        if (!video) stream = document.querySelector('#casino-gamingstory-cmsvideo stream') as HTMLMediaElement;
        if (video) {
            if (!video.paused) {
                video.pause();
            }
        } else if (stream) {
            if (!stream.paused) {
                stream.pause();
            }
        }
    }
    playVideo() {
        let video = <HTMLVideoElement>document.getElementById('casino-gamingstory-video');
        let stream: HTMLMediaElement = {} as HTMLMediaElement;
        if (!video) stream = document.querySelector('#casino-gamingstory-cmsvideo stream') as HTMLMediaElement;
        if (video) {
            if (video.paused) video.play();
        } else if (stream) {
            if (stream.paused) stream.play();
        }
    }
    storeGamingStoriesCloseContainer(storyContainerCloseList: GamingStoriesStorageCloseModel) {
        this.storyContainerCloseList = storyContainerCloseList;
    }
    storyInfoRequestItemsList(storyInfo: StoryInfoData) {
        this.storyInfoPostRequestList.push(storyInfo);
    }
    storingDataInPreLoginSessionStorage(storyInfoDataInSessionStorage: StoryInfoDataInSessionStorage) {
        this.storyInfoDataInPreLoginSessionStorageList.push(storyInfoDataInSessionStorage);
        sessionStorage.setItem('GS:StoryItemsInPreLoginSessionStorage', JSON.stringify(this.storyInfoDataInPreLoginSessionStorageList));
    }
    storeFetchAPIDataRes(storyInfoDataInSessionStorage: StoryInfoDataInSessionStorage) {
        this.spliceDuplicateStore = false;
        this.storeFetchAPIDataResList.push(storyInfoDataInSessionStorage);
        this.duplicateRecords = this.storeFetchAPIDataResList.filter(
            (x) =>
                x.userId === storyInfoDataInSessionStorage.userId &&
                x.response.sId === storyInfoDataInSessionStorage.response.sId &&
                x.response.subSId === storyInfoDataInSessionStorage.response.subSId,
        );
        this.uInputUndefinedCheck = this.storeFetchAPIDataResList.filter(
            (x) =>
                x.userId === storyInfoDataInSessionStorage.userId &&
                x.response.sId === storyInfoDataInSessionStorage.response.sId &&
                x.response.subSId === storyInfoDataInSessionStorage.response.subSId &&
                x.response.uInput === undefined,
        );
        if (this.duplicateRecords.length > 1 && this.uInputUndefinedCheck.length >= 1) {
            this.duplicateRecords.forEach((storyApi) => {
                if (!this.spliceDuplicateStore && storyApi.response.uInput === undefined) {
                    this.storeFetchAPIDataResList.splice(this.storeFetchAPIDataResList.indexOf(storyApi), 1);
                    this.spliceDuplicateStore = true;
                }
            });
        } else if (this.duplicateRecords.length > 1) {
            this.duplicateRecords.forEach((storyApi) => {
                if (!this.spliceDuplicateStore) {
                    this.storeFetchAPIDataResList.splice(this.storeFetchAPIDataResList.indexOf(storyApi), 1);
                    this.spliceDuplicateStore = true;
                }
            });
        }
        sessionStorage.setItem('GS:StoryItemsInPostLoginSessionStorage', JSON.stringify(this.storeFetchAPIDataResList));
    }
    videoPresentStatus(isVideoPresent: boolean) {
        this.videoPresentBehaviourSubject.next(isVideoPresent);
    }
    videoProgressStatus(videoProgress: number) {
        this.videoProgressBehaviourSubject.next(videoProgress);
    }
    changePromoStatus(storyStatusObject: any) {
        this.changePromoStatusBehaviourSubject.next(storyStatusObject);
    }
    writeGamingStoriesPhase2Array(gamingStoriesLocalStorageArray: Array<StoriesLocalStorageModel>) {
        if (this.user.isAuthenticated) {
            localStorage.setItem('gamingStoriesPhase2ViewedState_' + this.user.username, JSON.stringify(gamingStoriesLocalStorageArray));
        } else {
            localStorage.setItem('gamingStoriesPhase2ViewedState', JSON.stringify(gamingStoriesLocalStorageArray));
        }
    }
    readGamingStoriesPhase2Array() {
        let gamingStoriesArrayFromLocalStorage: any;
        if (this.user.isAuthenticated) {
            gamingStoriesArrayFromLocalStorage = JSON.parse(localStorage.getItem('gamingStoriesPhase2ViewedState_' + this.user.username)!);
        } else {
            gamingStoriesArrayFromLocalStorage = JSON.parse(localStorage.getItem('gamingStoriesPhase2ViewedState')!);
        }
        return gamingStoriesArrayFromLocalStorage;
    }
}
