import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csPromoCoinEconomyConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: promoCoinEconomyClientConfigFactory,
})
export class PromoCoinEconomyClientConfig {
    coinExpiryDay: number;
}

export function promoCoinEconomyClientConfigFactory(service: LazyClientConfigService) {
    return service.get(PromoCoinEconomyClientConfig);
}
