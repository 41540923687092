/* eslint-disable @angular-eslint/component-max-inline-declarations */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'cs-chevron',
    templateUrl: './chevron.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            .theme-arrow-up:before {
                vertical-align: middle;
                margin-top: 0;
                padding-right: 0;
                font-size: 0.75rem;
                transform: rotate(-180deg);
            }
        `,
    ],
})
export class CsChevronComponent {
    @Input() chevronKind: ChevronKind = 'down';
}

export type ChevronKind = 'up' | 'down';
