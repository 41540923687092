<!-- unscrollable-featured-games  for mobile and desktop-->
<!-- <h1>{{Type}}</h1> -->
<div [style.height]="height ? height : '500px'">
    <mat-grid-list
        [cols]="breakpoint"
        [style.width]="width"
        [style.height]="height"
        [rowHeight]="rowHeight"
        [gutterSize]="gutterSize"
        (window:resize)="onResize()"
        *ngIf="Type === 'unscrollable-games'"
        class="rowCount-{{ subcategory.rowCount }}"
        [ngClass]="{ 'casino-newgridtypesicon6': subcategory.isIconSixCategory, 'casino-newgridtypes': subcategory.isIconSevenCategory }">
        <mat-grid-tile
            *ngFor="let game of newFeatureGridArr | slice: 0 : currentIndex; let i = index"
            [colspan]="game.colSpan"
            [rowspan]="game.rowSpan"
            [ngStyle]="{ 'border-radius': game?.gameTileBorderRadius }">
            <div [ngClass]="getClassByicon(game)" id="heightFix-{{ subcategory.categoryid }}-{{ game.gameId }}" class="game">
                <cc-arcade-game-lobby
                    id="gameIdentifier_{{ subcategory.categoryid }}_{{ game.gameId }}"
                    [attr.data-productid]="subcategory?.categoryid + '_' + game?.gameId"
                    [ngClass]="game?.infoIconClass"
                    [game]="game"
                    [gamePosition]="i"
                    [category]="subcategory"
                    [gameLaunchedFrom]="gameLaunchedFrom"
                    [Type]="Type"
                    [gameIndex]="i + 1"></cc-arcade-game-lobby>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
</div>
<!--scrollbale-games for both desktop & mobile with multi row support(new feature grid) -->
<div *ngIf="Type === 'scrollable-games' && newFeatureGridArr && newFeatureGridArr.length > 0" class="coinarcade-scrollable-games">
    <ngu-carousel
        #desktopGamesCarousel
        (onMove)="onmoveFn($event)"
        [inputs]="carouselConfigNonTouch"
        [dataSource]="newFeatureGridArr"
        (window:resize)="onResize()"
        [ngClass]="{
            'jackpot-justify-center-icon-7-xs': jackpot_justify_center_icon_7_xs,
            'jackpot-justify-center-icon-7-sm': jackpot_justify_center_icon_7_sm,
            'casino-newgridtypesicon6': subcategory.isIconSixCategory,
            'casino-newgridtypes': subcategory.isIconSevenCategory,
            'ngu-touch-scrollable-games': isTouch
        }">
        <ngu-item *nguCarouselDef="let gameArr; let i = index">
            <mat-grid-list [cols]="gameArr.maxColSpan" [rowHeight]="rowHeight" [gutterSize]="gutterSize">
                <mat-grid-tile
                    [colspan]="gameArr.maxColSpan"
                    [rowspan]="gameArr.maxRowSpan"
                    *ngFor="let game of gameArr.games"
                    [ngStyle]="{ 'border-radius': game?.gameTileBorderRadius }">
                    <div class="game" [ngClass]="getClassByicon(game)">
                        <cc-arcade-game-lobby
                            id="gameIdentifier_{{ subcategory.categoryid }}_{{ game.gameId }}"
                            [attr.data-productid]="subcategory?.categoryid + '_' + game?.gameId"
                            [ngClass]="game?.infoIconClass"
                            [game]="game"
                            [gamePosition]="i"
                            [category]="subcategory"
                            [gameLaunchedFrom]="gameLaunchedFrom"
                            [Type]="Type"
                            [gameIndex]="game.gameIndex + 1"></cc-arcade-game-lobby>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </ngu-item>
        <button
            *ngIf="!isTouch && !changeCarouselView"
            [ngClass]="{ 'hidden-cls': isPreButtonVisible }"
            NguCarouselPrev
            class="theme-left ngx-button-left"
            aria-hidden="true"></button>
        <button
            *ngIf="!isTouch && !changeCarouselView"
            [ngClass]="{ 'hidden-cls': isNextButtonVisible }"
            NguCarouselNext
            class="theme-right ngx-button-right"
            (click)="loadNextGamesSet()"
            aria-hidden="true"></button>
    </ngu-carousel>
    <div class="arcadelobby-terms" [textContent]="arcadeLobbyMessages?.TncTextOnScrollableGrid"></div>
    <ul class="myPoint" NguCarouselPoint>
        <li
            *ngFor="let point of desktopGamesCarousel.pointNumbers; let p = index"
            [class.active]="p == desktopGamesCarousel.activePoint"
            (click)="desktopGamesCarousel.moveTo(p); loadNextGamesSet()"></li>
    </ul>
</div>

<!-- scrollable-games with different icons mobile-->
<div
    *ngIf="Type === 'scrollable-games-multiple-icons' && isTouch"
    (window:resize)="onResize()"
    class="scrollable-games-multiple-icons"
    [ngClass]="{ 'jackpot-games-display': games.length < 6 && gameLaunchedFrom == 'JackpotWidget_' + subcategory.engCategoryName }">
    <div class="game" *ngFor="let game of games; let i = index" [ngClass]="getClassByicon(game)">
        <div [ngClass]="{ 'gyml-frame': game.isGYML }">
            <mat-grid-list [cols]="game.colSpan" rowHeight="1:1">
                <mat-grid-tile [colspan]="game.colSpan" [rowspan]="game.rowSpan" [ngStyle]="{ 'border-radius': game?.gameTileBorderRadius }">
                    <cc-arcade-game-lobby
                        id="gameIdentifier_{{ subcategory.categoryid }}_{{ game.gameId }}"
                        [attr.data-productid]="subcategory?.categoryid + '_' + game?.gameId"
                        [ngClass]="game.infoIconClass"
                        [game]="game"
                        [gamePosition]="i"
                        [category]="subcategory"
                        [gameLaunchedFrom]="gameLaunchedFrom"
                        [Type]="Type"
                        [gameIndex]="i + 1"></cc-arcade-game-lobby>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>
</div>
