import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { CasinoLobbyService, CasinoWidgetService, ConfigProviderService } from '@casinocore/platform/core';
import { Subscription } from 'rxjs';

import { ArcadeRecentlyPlayedandGymlComponent } from '../arcadeRecentlyPlayedandGYML/arcade-recentlyPlayed-gyml.component';
import { RecentlyPlayedandGymlComponent } from '../recentlyPlayedandGYML/recentlyPlayed-gyml.component';

@Component({
    selector: 'cc-rp-gyml-wrapper',
    templateUrl: 'recentlyPlayed-gyml-wrapper.component.html',
    standalone: true,
    imports: [NgIf, RecentlyPlayedandGymlComponent, ArcadeRecentlyPlayedandGymlComponent],
})
export class RecentlyplayedGymlWrapperComponent implements OnInit, OnDestroy {
    lobbyType: string;
    gameContainer: string;
    isLobbyLoaded: boolean;
    lobbyLoadSubscription: Subscription;
    arcadeLobbyGameConfig: any;

    constructor(
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
        private casinoWidgetService: CasinoWidgetService,
    ) {}

    ngOnInit() {
        this.gameContainer = 'Widget';
        this.lobbyLoadSubscription = this.casinoLobbyService.lobbyLoadedObservable.subscribe((response) => {
            if (response) {
                const widgetConfig: any = this.configProviderService.providewidgetConfig();
                this.arcadeLobbyGameConfig = this.configProviderService.provideGameConfig()?.arcadeLobbyType.toLowerCase();
                this.lobbyType = widgetConfig.lobbyTypeForRpGymlOnOtherProducts;
                this.isLobbyLoaded = true;
                setTimeout(() => {
                    this.casinoWidgetService.getWidgetHeight('RpGymlWidget', 'rpGymlId');
                }, widgetConfig.rpGymlWidgetPostHeightTimeOut);
            }
        });
    }
    ngOnDestroy() {
        if (this.lobbyLoadSubscription) {
            this.lobbyLoadSubscription.unsubscribe();
        }
    }
}
