import { NgClass } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import {
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    ConstService,
    FavouriteService,
    GameDataManager,
    GameLobbyModel,
    LiveCasinoService,
    OptimizedTeaserManager,
    RecentGamesService,
    TeaserService,
    UnfinishedGamesService,
    UtilityService,
} from '@casinocore/platform/core';
import { TeasersWrapperComponent } from '@casinocore/platform/teasers';
import { DeviceService, NavigationService, Page, UserService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

import { ArcadeFeatureGridComponent } from '../arcade-feature-grid/arcade-feature-grid.component';
import { CasinoCoreLazyPlaceholderComponent } from '../casino-core-lazy-placeholder/casino-core-lazy-placeholder.component';
import { FeatureGridComponent } from '../feature-grid/feature-grid.component';
import { MultiJackpotwidgetRebrandComponent } from '../multi-jackpot-widget-rebrand/multi-jackpot-widget-rebrand.component';
import { MultiJackpotwidgetComponent } from '../multi-jackpot-widget/multi-jackpot-widget.component';
import { MustGoJackpotRedesignNewComponent } from '../must-go-redesign-new/must-go-redesign-new.component';
import { MustGoJackpotComponent } from '../must-go/must-go.component';
import { PlayerStatFiltersComponent } from '../player-stats/player-stat-filters.component';
import { ProminentFreespinsWidgetComponent } from '../prominent-freespins-widget/prominent-freespins-widget.component';
import { RecentWinnersComponent } from '../recent-winners/recent-winners.component';
import { SingleJackpotRedesignWidgetComponent } from '../single-jackpot-redesign-widget/single-jackpot-redesign-widget.component';
import { SingleJackpotWidgetComponent } from '../single-jackpot-widget/single-jackpot-widget.component';

@Component({
    selector: 'cc-lmt-category-lobby',
    templateUrl: 'lmt-category-lobby.component.html',
    standalone: true,
    imports: [
        TeasersWrapperComponent,
        NgClass,
        CasinoCoreLazyPlaceholderComponent,
        SingleJackpotWidgetComponent,
        SingleJackpotRedesignWidgetComponent,
        MultiJackpotwidgetComponent,
        MultiJackpotwidgetRebrandComponent,
        MustGoJackpotComponent,
        MustGoJackpotRedesignNewComponent,
        RecentWinnersComponent,
        FeatureGridComponent,
        ProminentFreespinsWidgetComponent,
        ArcadeFeatureGridComponent,
        PlayerStatFiltersComponent,
    ],
})
export class LMTCategoryLobbyComponent implements OnChanges, OnInit, OnDestroy {
    @Input() categoryId: string;
    @Input() isTouch: boolean;
    @Input() category: any;
    @Input() lobbyType: string;
    @Input() gameContainer: string;

    validGames: any[];
    isDefaultCategory: boolean;
    enableFixedMobileNavigation: boolean = false;
    readonly isMobileWidth = this.deviceService.isMobileWidth;
    categoryInfo: any;
    gamesLoadCount: number;
    offlineGamesArray: any = [];
    Games: any = [];
    liveGameInfo: any;
    favObs: any;
    recentObs: any;
    categoryStyle: string;
    loadnextGamesObs: any;
    loadNewSet: boolean = true;
    startPosition: any;
    teaserCategoryVal: any;
    SubCategoryTeaser: any;
    headerTeasers: any[] = [];
    unfinishedObs: any;
    isFavouriteObservableFired: boolean;
    showTeasersAboveGames: boolean;
    isUnfinishedObservableFired: boolean;
    isRecentObservableFired: boolean;
    showTeasers: boolean;
    enableEmbeddedTeasers: boolean;
    displayCatHeader: boolean;
    changeCarouselView: boolean;
    isEnableOptimizedTeasers: boolean;
    optimizedHeaderTeasers: any;
    optimizedSubNavigationTeasers: any[] = [];
    optimizedEmbeddedAboveTeasers: any[] = [];
    optimizedEmbeddedBelowTeasers: any[] = [];
    isShowEmbeddedAbove: boolean;
    isShowEmbeddedBelow: boolean;
    isShowSubnavigation: boolean;
    teaserObs: Subscription;
    EmbeddedTeaser: any;
    teaserOptimization: boolean;
    fastLoadingObs: Subscription;
    MustGoSitecoreObject: any;
    parallaxInput: any;
    categoryBackgroundFull: boolean;
    trackingClass: string = '';
    videoType: string;
    videoUrl: string;
    launchUrl: any;
    imageThumbnail: any;
    videoName: string;
    displayVideoinLoop: boolean;
    gameTileBorderRadius: string;
    gameTileDropShadow: string;
    newFeatureGridArr: any[] = [];
    newgridbgcolor: string;
    amazonstylebottombgclr: string;
    videoBoxContent: any;
    lobbyTileFontFamily: string;
    subCategoryTextColor: string;

    isEnabledGameSelector: boolean;
    gameSelectorWidgetPosition: string;
    isSuperButtonEnabled: boolean;
    superButtonPosition: string;
    displayPromoEDSWidget: boolean;
    promoEDSWidgetPosition: string;

    enableSingleJackpot: boolean;
    enableMultiJackpot: boolean = this.configProviderService.provideJackpotConfig().enableMultiJackpot;
    enableMustGoJackpot: boolean;
    isJackpotdataForParallax: boolean = false;
    isEnabledRecentWinners: boolean;
    recentWinnersWidgetPosition: any;
    isDisplayProminentFreeSpinsWidget: boolean;
    enableProminentFreespinsDisplay: boolean;

    arcadeLobbyGameConfig: any;
    currentLobbyType: string;
    enablePlayerstats: boolean;
    playerStatsWidgetPosition: any;
    displayEdsSmartWidget: boolean;
    constructor(
        private configProviderService: ConfigProviderService,
        private casinoLobbyService: CasinoLobbyService,
        private gameDataManager: GameDataManager,
        private casinoManager: CasinoManager,
        private elementRef: ElementRef,
        private utility: UtilityService,
        private favouriteService: FavouriteService,
        private recentService: RecentGamesService,
        private page: Page,
        private navigation: NavigationService,
        private unfinishedGamesService: UnfinishedGamesService,
        private liveCasinoService: LiveCasinoService,
        private optimizedTeaserManager: OptimizedTeaserManager,
        private deviceService: DeviceService,
        private teaserService: TeaserService,
        private user: UserService,
    ) {}
    ngOnInit() {
        this.assignFeatures();
        this.assignVideoParams();
        this.EmbeddedTeaser = 'Embedded';
        this.SubCategoryTeaser = 'SubNavigation';
        this.subscribeToLoadNextGames();
        this.subscribeToElementEventListeners();
        this.category.categoryInfo.categoryIndex = 1;
        this.liveCasinoService.liveGamesFeedObservable.subscribe((response) => {
            if (response && response.size > 0) {
                this.setOfflinesLivecasino();
            }
        });
        this.parallexInputData();
        this.trackingClass = 'track_' + this.categoryId;
        this.categoryHeaderForSubCat();
    }

    assignFeatures() {
        this.arcadeLobbyGameConfig = this.configProviderService.provideGameConfig()?.arcadeLobbyType.toLowerCase();
        this.currentLobbyType = this.casinoManager.getCurrentLobby()?.toLowerCase();
        const features: any = this.configProviderService.provideFeaturesConfig();
        this.enableFixedMobileNavigation = features.enableFixedMobileNavigation;
        this.showTeasersAboveGames = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.lobbyType,
            this.configProviderService.provideFeaturesConfig().showEmbeddedTeasersAboveGames,
        );
        this.enableEmbeddedTeasers = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.enableEmbeddedTeasers);
        this.changeCarouselView = features?.changeCarouselView;
        const optTeaserConfig: any = this.configProviderService.provideOptimizedTeaserConfig();
        this.isEnableOptimizedTeasers = optTeaserConfig.teaserOptimization;

        const gameSelectorConfig: any = this.configProviderService.provideGameSelectorWidgetConfig();
        const superButtonConfig: any = this.configProviderService.provideSuperButtonConfig();
        const promoEdsConfig: any = this.configProviderService.providewidgetConfig();
        this.isEnabledGameSelector = gameSelectorConfig?.isEnabled && this.category?.categoryInfo?.sitecoreData?.displayGameSelectorWidget;
        this.gameSelectorWidgetPosition = this.isEnabledGameSelector
            ? this.category?.categoryInfo?.sitecoreData?.gameSelectorWidgetPosition
            : undefined;
        this.isSuperButtonEnabled =
            superButtonConfig?.enabled && this.category?.categoryInfo?.sitecoreData?.displaySuperButton && this.deviceService.isMobilePhone;
        this.superButtonPosition = this.isSuperButtonEnabled ? this.category?.categoryInfo?.sitecoreData?.superButtonPosition : undefined;
        this.displayEdsSmartWidget = features?.displayEdsSmartWidget;
        const checkPromoEdsAuthentication = this.displayEdsSmartWidget ? true : this.user.isAuthenticated;
        this.displayPromoEDSWidget =
            promoEdsConfig?.enablePromoEdsWidget &&
            this.category?.categoryInfo?.sitecoreData?.displayPromoEDSWidget &&
            this.gameContainer != 'Overlay' &&
            checkPromoEdsAuthentication;
        this.promoEDSWidgetPosition = this.category?.categoryInfo?.sitecoreData?.promoEDSWidgetPosition;

        const jackpotDataConfig = this.configProviderService.provideJackpotConfig();
        this.enableSingleJackpot = jackpotDataConfig.enableSingleJackpot;
        this.enableMultiJackpot = jackpotDataConfig.enableMultiJackpot;
        this.enableMustGoJackpot = jackpotDataConfig.enableMustGoJackpot;
        // recent winners
        const latestWinnersConfig: any = this.configProviderService.provideRecentWinnersWidgetConfig();
        this.isEnabledRecentWinners =
            latestWinnersConfig?.isEnabled &&
            this.category &&
            this.category.categoryInfo?.sitecoreData &&
            this.category.categoryInfo?.sitecoreData.displayRecentWinnersWidget;
        this.recentWinnersWidgetPosition = this.isEnabledRecentWinners
            ? this.category.categoryInfo?.sitecoreData.recentWinnersWidgetPosition
            : undefined;
        //ProminentFreeSpinsWidget
        this.isDisplayProminentFreeSpinsWidget = this.category && this.category?.categoryInfo?.sitecoreData?.displayProminentFreeSpinsWidget;
        this.enableProminentFreespinsDisplay = this.configProviderService.providefreeSpinsClientConfig().isEnableProminentFreeSpinsDisplay;
        const playerStatsConfig: any = this.configProviderService.providePlayerStatsWidgetConfig();
        this.enablePlayerstats =
            playerStatsConfig?.enablePlayerstats &&
            this.category &&
            this.category.categoryInfo?.sitecoreData &&
            this.category.categoryInfo?.sitecoreData.displayPlayerStats &&
            this.user.isAuthenticated;
        this.playerStatsWidgetPosition = this.enablePlayerstats ? this.category.categoryInfo?.sitecoreData.playerStatsWidgetPosition : undefined;
    }

    assignVideoParams() {
        const features: any = this.configProviderService.provideFeaturesConfig();
        const enableVideoPhase2 = features.enableVideoPhase2;
        if (this.category?.categoryInfo?.sitecoreData && this.categoryInfo && this.categoryInfo.sitecoreData) {
            if (enableVideoPhase2) {
                if (this.category.categoryInfo.sitecoreData.casinoVideo) {
                    this.videoType = this.category.categoryInfo.sitecoreData.casinoVideo.videoType;
                    const cmsUrlArray: string[] = [
                        this.categoryInfo.sitecoreData.casinoVideo?.cmsVideoUrlVersioned?.id,
                        this.categoryInfo.sitecoreData.casinoVideo?.cmsVideoUrlShared?.id,
                    ];
                    const videoUrlArray: string[] = [
                        this.categoryInfo.sitecoreData.casinoVideo?.videoUrlVersioned,
                        this.categoryInfo.sitecoreData.casinoVideo?.videoUrlShared,
                    ];
                    const videoFiltered = videoUrlArray.filter((x) => x !== undefined && x !== null);
                    const cmsFiltered = cmsUrlArray.filter((x) => x !== undefined && x !== null);
                    if (cmsFiltered.length > 0) {
                        this.videoUrl = cmsFiltered[0];
                        this.videoType = 'cms';
                    } else if (videoFiltered.length > 0) {
                        this.videoUrl = videoFiltered[0];
                    }
                    this.launchUrl = this.category.categoryInfo.sitecoreData.casinoVideo.link;
                    this.imageThumbnail = this.category.categoryInfo.sitecoreData.casinoVideo.thumbnailImage;
                    this.videoName = this.category.categoryInfo.sitecoreData.casinoVideo.videoName;
                    this.displayVideoinLoop = this.category.categoryInfo.sitecoreData.casinoVideo.displayVideoinLoop;
                    this.videoBoxContent = this.category.categoryInfo.sitecoreData.casinoVideo.videoBoxContent;
                }
            } else {
                this.videoType = this.category.categoryInfo.sitecoreData.videoType;
                this.videoUrl = this.category.categoryInfo.sitecoreData.imageOrVideoUrl;
                this.launchUrl = this.category.categoryInfo.sitecoreData.launchUrl;
                this.imageThumbnail = this.category.categoryInfo.sitecoreData.imageThumbnail;
                this.videoName = this.category.categoryInfo.sitecoreData.videoName;
            }
        }
    }

    ngOnChanges() {
        this.assignFeatures();
        this.assignVideoParams();
        this.isDefaultCategory = this.casinoLobbyService.isDefaultCategory(this.lobbyType, this.category.categoryInfo.route);
        this.Games = [];
        this.newFeatureGridArr = [];
        this.validGames = [];
        this.offlineGamesArray = [];
        const casinoConfig: any = this.configProviderService.provideCasinoConfig();
        this.parallexInputData();
        if (this.category && this.category.categoryInfo) {
            this.category.categoryInfo.categoryIndex = 1;
            this.categoryInfo = this.category.categoryInfo;
            if (this.categoryInfo) {
                this.gameTileDropShadow = this.casinoLobbyService.getDropShadow(this.categoryInfo);
                this.newgridbgcolor = this.categoryInfo?.sitecoreData?.attributes?.newgridbgcolor;
                this.amazonstylebottombgclr = this.categoryInfo?.sitecoreData?.attributes?.amazonstylebottombgclr;
                this.lobbyTileFontFamily = this.casinoLobbyService.getLTileFontFamily(this.categoryInfo);
                this.subCategoryTextColor = this.casinoLobbyService.getsubCategoryTextColor(this.categoryInfo);
                if (this.categoryInfo.gamelist && this.categoryInfo.gamelist.length > 0) {
                    let isCategoryHasAllIcon6: boolean = true;
                    let isCategoryHasAllIcon7: boolean = true;
                    let categoryWithMultipleGameIconTypes: boolean = false;
                    for (let i = 0; i < this.categoryInfo.gamelist.length; i++) {
                        const firstGameIcon = this.categoryInfo.gamelist[0].icon ? Math.abs(this.categoryInfo.gamelist[0].icon) : 1;
                        const gameObj: any = this.categoryInfo.gamelist[i];
                        //TODO: filter out disable games
                        const gameInfo: any = this.casinoLobbyService.getGameInfo(gameObj.game);
                        if (gameInfo) {
                            const gameIcon = gameObj.icon ? Math.abs(gameObj.icon) : 1;
                            if (gameObj.icon != '6' && gameInfo.icon != '-6') {
                                isCategoryHasAllIcon6 = false;
                            }
                            if (gameObj.icon != '7' && gameInfo.icon != '-7') {
                                isCategoryHasAllIcon7 = false;
                            }
                            if (firstGameIcon != gameIcon) {
                                categoryWithMultipleGameIconTypes = true;
                            }
                            let disabledGame: boolean = false;
                            const disabledGames = this.casinoLobbyService.getDisabledGames();
                            disabledGames.forEach((game: any) => {
                                if (game?.toLowerCase() == gameInfo?.game?.toLowerCase()) {
                                    disabledGame = true;
                                }
                            });
                            if (!disabledGame) {
                                gameObj.name = gameInfo.name;
                                gameObj.provider = gameInfo.provider;
                                gameObj.sid = gameInfo.sid;
                                gameObj.ownerId = gameInfo.ownerId;
                                gameObj.gameIndex = i;
                                this.validGames.push(gameObj);
                            }
                        }
                    }
                    if (isCategoryHasAllIcon6) {
                        this.categoryInfo.isIconSixCategory = true;
                    }
                    if (isCategoryHasAllIcon7) {
                        this.categoryInfo.isIconSevenCategory = true;
                        if (this.categoryInfo.rowCount == '-3') {
                            this.categoryInfo.displayGameIndex = true; //for displaying game index on game tile
                        }
                    }
                    if (categoryWithMultipleGameIconTypes) {
                        this.categoryInfo.categoryWithMultipleGameIconTypes = true;
                    }
                }
                this.gameTileBorderRadius = this.casinoLobbyService.getBorderRadius(this.categoryInfo);
                this.gamesLoadCount = this.isCategoryScrollable()
                    ? casinoConfig.gamesLoadCount.loadHorizontalGamesCount
                    : casinoConfig.gamesLoadCount.loadVerticalGamesCount;
                const absoluteRowCount = Math.abs(this.categoryInfo.rowCount);
                if (absoluteRowCount > 1) {
                    this.gamesLoadCount = this.gamesLoadCount * absoluteRowCount;
                }
                if (this.categoryId?.toLowerCase() === ConstService.FAVOURITE_GAMES_CATEGORY_ID) {
                    this.setFavouriteGames(this.favouriteService.getFavouriteGames());
                } else if (this.categoryId?.toLowerCase() === ConstService.RECENT_GAMES_CATEGORY_ID) {
                    this.setRecentAndUnfinishedGames(this.unfinishedGamesService.getUnfinishedGames(), this.recentService.getrecentGames());
                } else if (this.validGames && this.validGames.length > 0) {
                    this.setGames(this.validGames.slice(0, this.gamesLoadCount), false);
                }
                this.teaserCategoryVal = this.categoryInfo.categoryid;
                this.setTeasers();
                this.categoryStyle = this.getCategoryStyle();
                this.categoryHeaderForSubCat();
            }
        }
    }

    loadNextGamesSet() {
        if (this.isCategoryScrollable()) {
            this.getNextGames(this.gamesLoadCount);
        }
    }
    setEmbeddedTeasersForSubCategory(category: any) {
        this.headerTeasers = [];
        const headerTeasers: any = this.teaserService.headerTeasers();
        if (
            category &&
            category.sitecoreData.showTeasers &&
            category.sitecoreData.teasers &&
            category.sitecoreData.teasers.length > 0 &&
            headerTeasers &&
            headerTeasers.length > 0
        ) {
            headerTeasers.forEach((headerTeaser: any) => {
                const index = category.sitecoreData.teasers.indexOf(
                    category.sitecoreData.teasers.filter((categoryTeaser: any) => categoryTeaser.itemName === headerTeaser.teaserId)[0],
                );
                if (index > -1 && headerTeaser.isEmbeddedTeaser) {
                    this.headerTeasers.push(headerTeaser);
                }
            });
        }
        this.showTeasers = this.headerTeasers && this.headerTeasers.length > 0;
        this.isShowEmbeddedAbove = false;
        this.isShowEmbeddedBelow = false;
        this.isShowSubnavigation = false;
    }
    setOptimizedEmbeddedTeasersForSubCategory(category: any) {
        this.optimizedHeaderTeasers = this.optimizedTeaserManager.setOptimizedTeasersBasedOnCatery(category);
        if (this.optimizedHeaderTeasers) {
            if (this.optimizedHeaderTeasers.optimizedSubNavigationTeasers.length > 0) {
                this.optimizedSubNavigationTeasers = this.optimizedHeaderTeasers.optimizedSubNavigationTeasers;
                this.isShowSubnavigation =
                    this.optimizedSubNavigationTeasers && this.optimizedSubNavigationTeasers.length > 0 && !this.isDefaultCategory;
            } else {
                this.optimizedSubNavigationTeasers = [];
                this.isShowSubnavigation = false;
            }
            if (this.optimizedHeaderTeasers.optimizedEmbeddedAboveTeasers.length > 0) {
                this.optimizedEmbeddedAboveTeasers = this.optimizedHeaderTeasers.optimizedEmbeddedAboveTeasers;
                this.isShowEmbeddedAbove = this.optimizedEmbeddedAboveTeasers && this.optimizedEmbeddedAboveTeasers.length > 0;
            } else {
                this.optimizedEmbeddedAboveTeasers = [];
                this.isShowEmbeddedAbove = false;
            }
            if (this.optimizedHeaderTeasers.optimizedEmbeddedBelowTeasers.length > 0) {
                this.optimizedEmbeddedBelowTeasers = this.optimizedHeaderTeasers.optimizedEmbeddedBelowTeasers;
                this.isShowEmbeddedBelow = this.optimizedEmbeddedBelowTeasers && this.optimizedEmbeddedBelowTeasers.length > 0;
            } else {
                this.optimizedEmbeddedBelowTeasers = [];
                this.isShowEmbeddedBelow = false;
            }
        }
        this.showTeasers = false;
    }

    getNextGames(gamesToLoad: number, callback?: any) {
        if (this.validGames.length - this.Games.length > 0) {
            this.setGames(this.validGames.slice(this.Games.length, this.Games.length + gamesToLoad), false, callback);
        } else if (callback) {
            callback(true);
        }
    }

    setGames(gamesModellist: any[], setUnfinishedBadge: boolean, callback?: any) {
        let starttileBgMode: boolean = false;
        if (this.isCategoryScrollable()) {
            this.formNewFeatureGridGameArray(gamesModellist, setUnfinishedBadge);
        } else {
            for (let i = 0; i < gamesModellist.length; i++) {
                const gameModelItem: any = gamesModellist[i];
                let filteredGame: any;
                if (!(filteredGame && filteredGame.length > 0)) {
                    const gamelobbyModel: GameLobbyModel = this.setGameModel(gameModelItem, starttileBgMode);
                    if (setUnfinishedBadge) {
                        gamelobbyModel.isUnfinished = this.getUnfinishedStatus(gamelobbyModel.gameId);
                    }
                    this.liveGameInfo = this.liveCasinoService.getLiveGameLookUpInfo(gamelobbyModel?.gameId?.toLowerCase());
                    const isTableOnline: boolean = this.liveCasinoService.isTableOnline(gamelobbyModel?.gameId?.toLowerCase());
                    if (this.liveGameInfo && this.liveGameInfo?.gameType && (!this.liveGameInfo.tableStatus || !isTableOnline)) {
                        gamelobbyModel.secondsToStartTable = this.assignSecondsLeftToStartTableOnline(this.liveGameInfo);
                        this.offlineGamesArray = [...this.offlineGamesArray, gamelobbyModel];
                        this.Games = [...this.Games, gamelobbyModel];
                    } else {
                        this.Games = [...this.Games, gamelobbyModel];
                    }
                }
                if (this.categoryInfo.isIconSevenCategory) {
                    //To handle background for alternate in non-scrollable games
                    const width = document.body.offsetWidth;
                    if (this.isTouch && (this.categoryInfo.rowCount == '1' || this.categoryInfo.rowCount == '2')) {
                        starttileBgMode = !starttileBgMode;
                    } else if (width < 568) {
                        starttileBgMode = !starttileBgMode;
                    } else if (width < 1024) {
                        if (i % 2 == 0) {
                            starttileBgMode = !starttileBgMode;
                        }
                    } else {
                        if ((i + 1) % 4 != 0) {
                            starttileBgMode = !starttileBgMode;
                        }
                    }
                }
            }
            this.checkOfflineGames();
            this.newFeatureGridArr = [...this.Games];
        }

        if (callback) {
            callback(true);
        }
    }

    setGameModel(gameModelItem: any, starttileBgMode: boolean): GameLobbyModel {
        const currentWidth = this.casinoManager.getCurrentWidth();
        const gamelobbyModel: GameLobbyModel = this.gameDataManager.setGameLobbyModel(gameModelItem, this.isTouch);
        gamelobbyModel.infoIconClass = this.casinoManager.assignGameClass(currentWidth, gamelobbyModel.iconType);
        gamelobbyModel.lobbyType = this.lobbyType;
        gamelobbyModel.gameSection = this.category.categoryInfo.engCategoryName;
        gamelobbyModel.gameContainer = this.gameContainer;
        gamelobbyModel.gameTileBorderRadius = this.gameTileBorderRadius;
        gamelobbyModel.gameTileDropShadow = this.gameTileDropShadow;
        gamelobbyModel.bgTileMode = this.categoryInfo.isIconSevenCategory ? (starttileBgMode ? starttileBgMode : false) : false;
        gamelobbyModel.newgridbgcolor = this.newgridbgcolor;
        return gamelobbyModel;
    }

    formNewFeatureGridGameArray(gamesModellist: any[], setUnfinishedBadge: boolean) {
        const isTouch = this.casinoManager.isTouch();
        let rowCount = this.categoryInfo.rowCount;
        if (isTouch && (this.categoryInfo.isIconSixCategory || this.categoryInfo.isIconSevenCategory) && Number(this.categoryInfo.rowCount) > 0) {
            rowCount = '-2';
        }
        const absoluteRowCount = Math.abs(rowCount);
        const gameArr = gamesModellist;
        let start = 0;
        const lastFeatureGridItem = this.newFeatureGridArr[this.newFeatureGridArr.length - 1]?.games;
        let starttileBgMode: boolean = lastFeatureGridItem ? lastFeatureGridItem[absoluteRowCount - 1].bgTileMode : gameArr[0].bgTileMode;
        if (lastFeatureGridItem && absoluteRowCount % 2 != 0) {
            starttileBgMode = !starttileBgMode;
        }
        while (start < gameArr.length) {
            let matgridArr: any[] = [];
            let maxRowSpan = 0;
            let maxColSpan = 0;
            for (let i = start; i < start + absoluteRowCount; i++) {
                if (gameArr[i]) {
                    const gameModel: GameLobbyModel = this.setGameModel(gameArr[i], starttileBgMode);
                    if (setUnfinishedBadge) {
                        gameModel.isUnfinished = this.getUnfinishedStatus(gameModel.gameId);
                    }
                    this.liveGameInfo = this.liveCasinoService.getLiveGameLookUpInfo(gameModel?.gameId?.toLowerCase());
                    const isTableOnline: boolean = this.liveCasinoService.isTableOnline(gameModel?.gameId?.toLowerCase());
                    if (this.liveGameInfo && this.liveGameInfo?.gameType && (!this.liveGameInfo.tableStatus || !isTableOnline)) {
                        gameModel.secondsToStartTable = this.assignSecondsLeftToStartTableOnline(this.liveGameInfo);
                        this.offlineGamesArray = [...this.offlineGamesArray, gameModel];
                    } else {
                        this.Games = [...this.Games, gameModel];
                    }
                    matgridArr = [...matgridArr, gameModel];
                    maxRowSpan = this.findMax(maxRowSpan, gameModel.rowSpan);
                    maxColSpan = this.findMax(maxColSpan, gameModel.colSpan);
                    starttileBgMode = !starttileBgMode;
                }
            }
            if (absoluteRowCount % 2 == 0) {
                starttileBgMode = !starttileBgMode;
            }
            start = start + absoluteRowCount;
            this.newFeatureGridArr.push({
                games: matgridArr,
                maxRowSpan: maxRowSpan,
                maxColSpan: maxColSpan,
            });
        }
        this.checkOfflineGames();
    }

    findMax(n1: number, n2: number): number {
        if (n1 > n2) return n1;
        return n2;
    }

    getUnfinishedStatus(gameId: string): boolean {
        const unfinishedGamesArray: any = this.unfinishedGamesService.getUnfinishedGames();
        return unfinishedGamesArray.indexOf(gameId) != -1;
    }
    isCategoryScrollable() {
        if (this.category) {
            return Number(this.categoryInfo.rowCount) < 0;
        }
        return '';
    }
    subscribeToLoadNextGames() {
        this.loadnextGamesObs = this.casinoManager.loadNextGamesSetObservable.subscribe((loadNewGameSet: boolean) => {
            if (loadNewGameSet && this.loadNewSet && !this.isCategoryScrollable()) {
                this.loadNewSet = false;
                this.getNextGames(this.gamesLoadCount, (result: any) => {
                    if (result) {
                        this.loadNewSet = true;
                    }
                });
            }
        });
    }
    subscribeToElementEventListeners() {
        this.elementRef.nativeElement.addEventListener('touchstart', (e: any) => {
            this.startPosition = this.utility.getPositionXY(e);
        });
        this.elementRef.nativeElement.addEventListener('touchend', (e: any) => {
            const endPosition = this.utility.getPositionXY(e);
            if (this.isCategoryScrollable() && endPosition.x < this.startPosition.x) {
                this.getNextGames(this.gamesLoadCount);
            }
        });
        this.favObs = this.favouriteService.favouriteGamesFeedObservable.subscribe((favouriteGames: any) => {
            if (favouriteGames && this.categoryId?.toLowerCase() === ConstService.FAVOURITE_GAMES_CATEGORY_ID) {
                this.isFavouriteObservableFired = true;
                this.setFavouriteGames(favouriteGames);
            }
        });
        this.recentObs = this.recentService.recentGamesFeedObservable.subscribe((recentGames: any) => {
            if (recentGames) {
                this.isRecentObservableFired = true;
            }
            if (
                recentGames &&
                recentGames.length > 0 &&
                this.categoryId?.toLowerCase() === ConstService.RECENT_GAMES_CATEGORY_ID &&
                this.isRecentObservableFired &&
                this.isUnfinishedObservableFired
            ) {
                this.setRecentAndUnfinishedGames(this.unfinishedGamesService.getUnfinishedGames(), recentGames);
            }
        });
        this.unfinishedObs = this.unfinishedGamesService.unfinishedFeedObservable.subscribe((unfinishedGames: any) => {
            if (unfinishedGames) {
                this.isUnfinishedObservableFired = true;
            }
            const recentGames = this.recentService.getrecentGames();
            if (
                ((unfinishedGames && unfinishedGames.length > 0) || (recentGames && recentGames.length > 0)) &&
                this.categoryId?.toLowerCase() === ConstService.RECENT_GAMES_CATEGORY_ID &&
                this.isRecentObservableFired &&
                this.isUnfinishedObservableFired
            ) {
                this.setRecentAndUnfinishedGames(unfinishedGames, this.recentService.getrecentGames());
            }
        });

        if (this.enableEmbeddedTeasers || this.isEnableOptimizedTeasers) {
            this.teaserObs = this.teaserService.teaserDataObservable.subscribe((_response: any) => {
                if (_response) {
                    this.setTeasers();
                }
            });
        }
    }
    setFavouriteGames(favGameIds: string[]) {
        this.Games = [];
        this.validGames = [];
        this.offlineGamesArray = [];
        if (favGameIds.length > 0) {
            const gamesModel: any[] = this.setDefaultGameIcons(favGameIds);
            this.categoryInfo.gamelist = gamesModel;
            for (let i = 0; i < this.categoryInfo.gamelist.length; i++) {
                const gameObj: any = this.categoryInfo.gamelist[i];
                //TODO: filter out disable games
                const gameInfo: any = this.casinoLobbyService.getGameInfo(gameObj.game);
                if (gameInfo) {
                    let disabledGame: boolean = false;
                    const disabledGames = this.casinoLobbyService.getDisabledGames();
                    disabledGames.forEach((game: any) => {
                        if (game?.toLowerCase() == gameInfo?.game?.toLowerCase()) {
                            disabledGame = true;
                        }
                    });
                    if (!disabledGame) {
                        gameObj.name = gameInfo.name;
                        gameObj.provider = gameInfo.provider;
                        gameObj.sid = gameInfo.sid;
                        gameObj.ownerId = gameInfo.ownerId;
                        this.validGames.push(gameObj);
                    }
                }
            }
            this.setGames(this.validGames, false);
        } else if (favGameIds.length === 0) {
            this.favouriteService.removeQuickInfoClass();
            if (this.isFavouriteObservableFired) {
                //TODO: navigate to default
                return this.navigation.goTo('/' + this.page?.lang + '/' + this.casinoManager.getLobbyUrlPrefix(this.lobbyType));
            }
        }
        return '';
    }
    setRecentAndUnfinishedGames(unfinishedGameIds: string[], recentGameIds: string[]) {
        let recentAndUnfinishedGameIds: string[] = [];
        if (unfinishedGameIds && unfinishedGameIds.length > 0) {
            recentAndUnfinishedGameIds = recentAndUnfinishedGameIds.concat(unfinishedGameIds);
        }
        if (recentGameIds && recentGameIds.length > 0) {
            for (let i = 0; i < recentGameIds.length; i++) {
                if (recentAndUnfinishedGameIds.indexOf(recentGameIds[i]) === -1) {
                    recentAndUnfinishedGameIds.push(recentGameIds[i]);
                }
            }
        }
        const gamesModel: any[] = this.setDefaultGameIcons(recentAndUnfinishedGameIds);
        if (this.categoryId?.toLowerCase() === ConstService.RECENT_GAMES_CATEGORY_ID) {
            this.categoryInfo.gamelist = gamesModel;
        }
        this.Games = [];
        this.validGames = [];
        for (let i = 0; i < this.categoryInfo.gamelist.length; i++) {
            const gameObj: any = this.categoryInfo.gamelist[i];
            //TODO: filter out disable games
            const gameInfo: any = this.casinoLobbyService.getGameInfo(gameObj.game);
            if (gameInfo) {
                let disabledGame: boolean = false;
                const disabledGames = this.casinoLobbyService.getDisabledGames();
                disabledGames.forEach((game: any) => {
                    if (game?.toLowerCase() == gameInfo?.game?.toLowerCase()) {
                        disabledGame = true;
                    }
                });
                if (!disabledGame) {
                    gameObj.name = gameInfo.name;
                    gameObj.provider = gameInfo.provider;
                    gameObj.sid = gameInfo.sid;
                    gameObj.ownerId = gameInfo.ownerId;
                    this.validGames.push(gameObj);
                }
            }
        }
        this.setGames(this.validGames, true);
    }

    setDefaultGameIcons(gameIds: string[]) {
        const gamesModel: any[] = [];
        if (gameIds && gameIds.length > 0) {
            gameIds.forEach((gameId: string) => {
                const GameModelItem: any = {
                    game: gameId,
                    icon: '1',
                };
                gamesModel.push(GameModelItem);
            });
        }
        return gamesModel;
    }
    getCategoryStyle(): string {
        if (this.categoryInfo && this.isCategoryScrollable()) {
            return 'scrollable-games';
        } else {
            return 'unscrollable-games';
        }
    }
    setOfflinesLivecasino() {
        this.offlineGamesArray = [];
        this.Games.forEach((val: any, index: number) => {
            this.liveGameInfo = this.liveCasinoService.getLiveGameLookUpInfo(val?.gameId?.toLowerCase());
            const isTableOnline: boolean = this.liveCasinoService.isTableOnline(val?.gameId?.toLowerCase());
            if (this.liveGameInfo?.gameType && (!this.liveGameInfo.tableStatus || !isTableOnline)) {
                this.Games[index].secondsToStartTable = this.assignSecondsLeftToStartTableOnline(this.liveGameInfo);
                this.offlineGamesArray.push(this.Games[index]);
            }
        });
        this.checkOfflineGames();
    }

    checkOfflineGames() {
        if (this.offlineGamesArray.length > 0) {
            this.offlineGamesArray = this.offlineGamesArray.sort(
                (l: GameLobbyModel, m: GameLobbyModel) => l.secondsToStartTable - m.secondsToStartTable,
            );
            this.offlineGamesArray.forEach((val: any) => {
                if (val?.iconType == '1' || val?.iconType == '2') {
                    const endlessIndex = this.Games.indexOf(val);
                    this.Games.splice(endlessIndex, endlessIndex > -1 ? 1 : 0);
                    this.Games = this.Games.concat(val);
                    if (this.isCategoryScrollable()) {
                        const offlineMatGridArr: any[] = [];
                        offlineMatGridArr.push({
                            games: [val],
                            maxRowSpan: val.rowSpan,
                            maxColSpan: val.colSpan,
                        });
                        const scrollableIndex = this.newFeatureGridArr
                            .flatMap((e) => {
                                return e.games;
                            })
                            .indexOf(val);
                        this.newFeatureGridArr.splice(scrollableIndex, scrollableIndex > -1 ? 1 : 0);
                        this.newFeatureGridArr = this.newFeatureGridArr.concat(offlineMatGridArr);
                    } else {
                        this.newFeatureGridArr = [...this.Games];
                    }
                }
            });
        }
    }

    assignSecondsLeftToStartTableOnline(liveGameInfo: any): number {
        let secondsLeftToStartTableOnline: number = 0;
        if (!liveGameInfo.tableStatus) {
            secondsLeftToStartTableOnline = 999999999;
        } else if (
            liveGameInfo.countdown &&
            liveGameInfo.countdown.type &&
            liveGameInfo.countdown.type?.toLowerCase() === 'bounded' &&
            liveGameInfo.countdown.time
        ) {
            secondsLeftToStartTableOnline = this.liveCasinoService.secondsToStartTable(liveGameInfo.countdown.time);
        }
        return secondsLeftToStartTableOnline;
    }

    setTeasers() {
        if (this.enableEmbeddedTeasers && !this.configProviderService.provideOptimizedTeaserConfig().teaserOptimization) {
            this.setEmbeddedTeasersForSubCategory(this.categoryInfo);
        }
        if (this.configProviderService.provideOptimizedTeaserConfig().teaserOptimization) {
            this.setOptimizedEmbeddedTeasersForSubCategory(this.categoryInfo);
        }
    }

    ngOnDestroy() {
        if (this.favObs) {
            this.favObs.unsubscribe();
        }
        if (this.recentObs) {
            this.recentObs.unsubscribe();
        }
        if (this.loadnextGamesObs) {
            this.loadnextGamesObs.unsubscribe();
        }
        if (this.teaserObs) {
            this.teaserObs.unsubscribe();
        }
        if (this.unfinishedObs) {
            this.unfinishedObs.unsubscribe();
        }
        if (this.fastLoadingObs) {
            this.fastLoadingObs.unsubscribe();
        }
    }

    parallexInputData() {
        const global = this.configProviderService.provideGlobalConfig();
        const mustGoSitecoreObjectInfo = this.casinoLobbyService.getCategoryInfo(this.lobbyType, this.categoryId);
        if (mustGoSitecoreObjectInfo) {
            this.MustGoSitecoreObject = mustGoSitecoreObjectInfo.categoryInfo;
            this.parallaxInput = {
                sitecoreData: {
                    backgroundImageLayer1: this.MustGoSitecoreObject.sitecoreData.jackpotBackgroundImage1,
                    backgroundImageLayer2: this.MustGoSitecoreObject.sitecoreData.jackpotBackgroundImage2,
                    backgroundImageLayer3: this.MustGoSitecoreObject.sitecoreData.jackpotBackgroundImage3,
                },
            };
        }

        this.categoryBackgroundFull = global.messages.CategoryBgFull;
    }
    categoryHeaderForSubCat() {
        if (this.categoryInfo && this.categoryInfo.gamelist && this.categoryInfo.gamelist.length > 0) {
            if (
                this.isMobileWidth() &&
                this.enableFixedMobileNavigation &&
                (this.category.categoryInfo.lmtSubCategories == undefined || this.category.categoryInfo.lmtSubCategories.length == 0) &&
                this.optimizedSubNavigationTeasers.length == 0
            ) {
                this.displayCatHeader = true;
            }
        } else {
            this.displayCatHeader = false;
        }
    }
    getJackpotFeed(value: any) {
        if (!this.isJackpotdataForParallax && value?.isJackpotdataForParallax) {
            this.isJackpotdataForParallax = value?.isJackpotdataForParallax;
        }
    }
}
