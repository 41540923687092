import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import {
    CasinoCoreNpmParamsService,
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    QuickInfoModalService,
    WindowRef,
} from '@casinocore/platform/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NewQuickInfoModalComponent } from './new-quick-info-modal/new-quick-info-modal.component';

@Component({
    selector: 'cc-new-game-quick-info',
    template: '',
    standalone: true,
})
export class NewGameQuickInfoComponent implements OnInit {
    isLaunchedFromUrl: any = false;
    hotIcon: string;
    isTouch: any;
    data: any;
    width: number;
    boxWidth: number;
    boxWidthInt: number;
    icon: any;
    gameLaunchedFrom: string;
    game: any;
    gridPosition: any;
    gamePosition: number;
    categoryType: any;
    gameModel: any;
    product: string;
    src: string;
    mobileQuickInfoSrc: string;
    useQuickInfo: boolean;
    newQuickInfoStyle: any = false;
    gameProvider: string;
    cta: string;
    vendorLogo: any = null;
    provider: string;
    lobbyType: string;
    demoEnabled: boolean;
    stickers: any = [];
    isStickersForUk: boolean = false;
    useFavouriteGames: boolean = false;
    quickInfoObs: Subscription;
    landscapeMode: boolean = false;
    gameHomeUrl: string;
    showstickersinoverlay: boolean = true;
    showjackpotvalueinoverlay: boolean = true;
    enableHotIcon: boolean;
    isNewQuickInfoEnabled: boolean;
    quickInfoScrollY: number;
    quickInfoService: any;
    private destroy$ = new Subject<void>();
    constructor(
        private quickInfoModalService: QuickInfoModalService,
        private casinoManager: CasinoManager,
        private configProviderService: ConfigProviderService,
        private casinoLobbyService: CasinoLobbyService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private casinoNpmParamsService: CasinoCoreNpmParamsService,
        private windowRef: WindowRef,
        private casinoCoreNpmParamsService: CasinoCoreNpmParamsService,
    ) {
        this.setWidth();
    }

    ngOnInit() {
        const casinoNpmParams = this.casinoCoreNpmParamsService.getcasinoCoreNpmParams();
        if (this.casinoLobbyService.getMoreInfoComponentStatus()) return;
        else this.casinoLobbyService.setMoreInfoComponentStatus(true);

        const gameQuickInfoQueryParameter = this.route.snapshot.params['gameId'];
        const path = this.route?.snapshot?.routeConfig?.path ? this.route?.snapshot?.routeConfig?.path : '';
        this.isTouch = this.casinoManager.isTouch();
        this.quickInfoObs = this.quickInfoModalService.gameDataObservable.subscribe((receivedData) => {
            if (receivedData) {
                this.data = receivedData;
                if (
                    (gameQuickInfoQueryParameter && path.indexOf('game-info') > -1) ||
                    window.location.href.indexOf('game-info') > -1 ||
                    !casinoNpmParams.isDll
                ) {
                    if (document.getElementsByClassName('casino-moreinfo-outer-container').length <= 0) {
                        this.openDialog();
                    }
                }
            }
        });
    }

    @HostListener('window:resize') onResize() {
        this.setWidth();
        this.isTouch = this.casinoManager.isTouch();
    }

    setWidth(): void {
        if (this.windowRef.nativeWindow.screen.availWidth >= 768) this.width = 388;
        else if (this.windowRef.nativeWindow.screen.availWidth < this.windowRef.nativeWindow.screen.availHeight)
            this.width = this.windowRef.nativeWindow.screen.availWidth;
        else this.width = this.windowRef.nativeWindow.screen.availHeight;
        this.boxWidth = this.width;
        this.boxWidthInt = this.boxWidth - 176;
    }

    setLocalData() {
        this.icon = this.data.icon;
        this.game = this.data.game;
        this.gamePosition = this.data.gamePosition;
        this.gameLaunchedFrom = this.data.gameLaunchedFrom;
        this.categoryType = this.data.categoryType;
        this.gridPosition = this.data.gridPosition;
        this.gameModel = this.data.gameModel;
        this.product = this.data.product;
        this.provider = this.game.provider;
        this.src = this.data.src;
        this.lobbyType = this.data.lobbyType;
        this.stickers = this.data.stickers;
        this.useFavouriteGames = this.data.useFavouriteGames;
        this.gameHomeUrl = this.data.gameHomeUrl;
        this.cta = this.data.cta;
        this.isLaunchedFromUrl = this.data.isLaunchedFromUrl;
        this.quickInfoScrollY = this.data.quickInfoScrollY;
    }

    openDialog() {
        this.setLocalData();
        this.assignFeatures();
        this.setImageIcon();
        const isCasinoGame = !(this.game.fromCasino != undefined && !this.game.fromCasino) || !this.game.fromCozybingo;
        if (isCasinoGame && this.newQuickInfoStyle && this.isTouch) {
            if (this.windowRef.nativeWindow.screen.availWidth >= 768 || window.innerWidth >= 768 || document.body.offsetWidth >= 768) {
                this.landscapeMode = true;
            }
        }
        const requiredModalData = {
            boxWidth: this.boxWidth,
            boxWidthInt: this.boxWidthInt,
            mobileQuickInfoSrc: this.mobileQuickInfoSrc,
            gameProvider: this.gameProvider,
            vendorLogo: this.vendorLogo,
            provider: this.provider,
            gamePosition: this.gamePosition,
            game: this.game,
            gameLaunchedFrom: this.gameLaunchedFrom,
            lobbyType: this.lobbyType,
            gameTitle: this.game.name,
            demoEnabled: this.demoEnabled,
            stickers: this.stickers,
            isStickersForUk: this.isStickersForUk,
            useFavouriteGames: this.useFavouriteGames,
            newQuickInfoStyle: this.newQuickInfoStyle,
            hotIcon: this.hotIcon,
            landscapeMode: this.landscapeMode,
            categoryType: this.categoryType,
            gridPosition: this.gridPosition,
            gameHomeUrl: this.gameHomeUrl,
            cta: this.cta,
            isLaunchedFromUrl: this.isLaunchedFromUrl,
            showstickersinoverlay: this.showstickersinoverlay,
            showjackpotvalueinoverlay: this.showjackpotvalueinoverlay,
            enableHotIcon: this.enableHotIcon,
        };
        const desktopDialogRef = this.dialog.open(NewQuickInfoModalComponent, {
            data: requiredModalData,
        });
        desktopDialogRef
            .afterOpened()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                document.getElementsByTagName('html')[0].classList.add('casino-quickinfo-open');
            });

        desktopDialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                if (this.quickInfoScrollY) {
                    if (this.isTouch) {
                        window.scrollTo(0, this.quickInfoScrollY);
                    } else {
                        this.windowRef.nativeWindow.scrollTo(0, this.quickInfoScrollY);
                    }
                }
                if (this.isTouch) {
                    this.removeQuickInfoQueryParameterFromUrl();
                } else {
                    this.handleEscClick();
                }
            });
    }

    handleEscClick() {
        document.getElementsByTagName('html')[0].classList.remove('casino-quickinfo-open');
        // if ((!closeDialogData || !closeDialogData.noNavigation) && currenturl) {
        //     this.navigation.goTo(currenturl);
        // }
        this.removeQuickInfoQueryParameterFromUrl();
        // if (this.quickInfoScrollY) window.scrollTo(0, this.quickInfoScrollY);
    }

    removeQuickInfoQueryParameterFromUrl() {
        const casinoNpmParams = this.casinoNpmParamsService.getcasinoCoreNpmParams();
        if (casinoNpmParams && casinoNpmParams.isDll) {
            // let param = 'game-info';
            // window.history.pushState(location.href, '', location.href.substr(0, location.href.indexOf(param) - 1));
            if (this.game.gameContainer != 'Overlay' && this.game.gameContainer != 'Widget') {
                const param = 'game-info';
                if (location.href.indexOf(param) > -1) {
                    window.history.pushState(location.href, '', location.href.substr(0, location.href.indexOf(param) - 1));
                }
            }
        }
    }

    setImageIcon() {
        if (this.icon && this.icon.src) {
            this.src = JSON.parse(JSON.stringify(this.icon.src));
            if (this.newQuickInfoStyle || this.useQuickInfo) {
                // const gameConfig: any = this.configProviderService.provideGameConfig();
                this.mobileQuickInfoSrc = this.casinoLobbyService.getImagePath(this.game.gameId, 'square');
                // gameConfig.host + gameConfig.gameIconTemplate.replace('{0}', 'square').replace('{1}', this.game.gameId) + '.jpg';
            }
        }
        if (this.game.fromCozybingo) {
            this.src = this.game['sitecoreInfo']['desktopImage']['src'];
        }
        if (this.src && !this.isTouch) {
            this.mobileQuickInfoSrc = this.src;
        }
        const feature: any = this.configProviderService.provideFeaturesConfig();
        if (feature.enableGameIconTheming) {
            this.src = this.casinoLobbyService.appendTheme(this.src, this.game.gameId);
            this.mobileQuickInfoSrc = this.casinoLobbyService.appendTheme(this.mobileQuickInfoSrc, this.game.gameId);
        }
    }

    assignFeatures() {
        const features: any = this.configProviderService.provideFeaturesConfig();
        this.newQuickInfoStyle = this.casinoManager.IsFeatureEnabledforLobbytype(this.game.lobbyType, features.newQuickInfoStyle);
        this.useQuickInfo = this.casinoManager.IsFeatureEnabledforLobbytype(this.game.lobbyType, features.enableQuickInfoLobbyType);
        this.showstickersinoverlay = this.casinoManager.IsFeatureEnabledforLobbytype(this.game.lobbyType, features.showStickersInOverlay);
        this.showjackpotvalueinoverlay = this.casinoManager.IsFeatureEnabledforLobbytype(this.game.lobbyType, features.showJackpotvalueInOverlay);
        this.isStickersForUk = this.casinoManager.IsFeatureEnabledforLobbytype(this.game.lobbyType, features.stickersForUk);
        this.useFavouriteGames = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.favouriteGamesLobbyType);
        const quickInfoconfig = this.configProviderService.provideQuickInfoConfig();
        if (quickInfoconfig) {
            this.cta = quickInfoconfig.quickInfo.cta;
        }
        const global = this.configProviderService.provideGlobalConfig();
        this.gameProvider = global.moreInfoConfiguration.GameProviderHeader;
        const gameConfig = this.configProviderService.provideGameConfig();
        const gameOwnerId = gameConfig.gameOwnerIdMap?.find((g: any) => g.gameId == this.game.gameId);
        this.vendorLogo =
            gameConfig.host +
            gameConfig.gameIconTemplate.replace('{0}', 'vendorlogos').replace('{1}', gameOwnerId ? gameOwnerId?.ownerId : this.game.ownerId) +
            '.jpg';
        this.demoEnabled = gameConfig.enableDemoMode;
        this.enableHotIcon = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.enableHotIcon);
        this.hotIcon = gameConfig.host + '/htmllobby/images/newlmticons/square/hotjackpot.png';
    }

    ngOnDestroy() {
        if (this.quickInfoObs) {
            this.quickInfoObs.unsubscribe();
        }
        this.casinoLobbyService.setMoreInfoComponentStatus(false);
        this.quickInfoModalService.setQuickInfoModalData(null);
        this.destroy$.next();
        this.destroy$.complete();
    }
}
