import { CurrencyPipe, NgFor } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { ConfigProviderService, FreespinSummaryService, TimeZoneFormatPipe } from '@casinocore/platform/core';
import { NavigationService, TrackingService } from '@frontend/vanilla/core';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { LibMockService } from '../../services/lib-mock.service';

@Component({
    selector: 'cc-freespin-quick-info',
    templateUrl: 'freespins-quick-info.component.html',
    standalone: true,
    imports: [NgFor, NgCircleProgressModule, CurrencyPipe, TimeZoneFormatPipe],
})
export class FreeSpinsQuickInfoComponent implements OnInit {
    @Input() rewardDetails: any;
    enableFreeSpinSummary: boolean;
    isSpinShow: boolean;
    isSpinDefaultShow: boolean;
    freespinscountbg: string;
    freeSpinsRemainingText: string;
    totalAmountText: string;
    ofText: string;
    expiryDateText: string;
    freeSpinsBoxColor: string;
    expiryDetailsBoxColor: string;
    freeSpinsCountCircleColor: string;
    freeSpinsFont: string;
    moreInfoText: string;
    moreInfoUrl: any;
    fontColor: any;
    currencySymbol: any;
    timeZoneData: any;
    rewardPercent: number;
    quickInfoService: any;

    constructor(
        private configProviderService: ConfigProviderService,
        private tracking: TrackingService,
        private freespinSummaryService: FreespinSummaryService,
        private navigation: NavigationService,
        private libMockService: LibMockService,
    ) {}
    ngOnInit() {
        this.quickInfoService = this.libMockService.getQuickInfoService();
        if (this.rewardDetails) {
            this.timeZoneData = this.freespinSummaryService.getTimeZoneInfo();
        }

        this.freespinscountbg = this.configProviderService.provideGlobalConfig().freespinsCountBackgroundColor;
        this.enableFreeSpinSummary = this.configProviderService.providefreeSpinsClientConfig().isFreeSpinSummaryEnabled;
        this.freespinscountbg = this.configProviderService.provideGlobalConfig().lobbyConfigurations.freespinsCountBackgroundColor;
        this.freeSpinsRemainingText = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.freeSpinsRemainingText;
        this.totalAmountText = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.totalAmountText;
        this.ofText = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.ofText;
        this.expiryDateText = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.expiryDateText;
        this.freeSpinsBoxColor = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.freeSpinsBoxColor;
        this.expiryDetailsBoxColor = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.expiryDetailsBoxColor;
        this.freeSpinsCountCircleColor = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.freeSpinsCountCircleColor;
        this.freeSpinsFont = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.freeSpinsFont;
        this.moreInfoText = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.moreInfoText;
        this.fontColor = this.configProviderService.providefreeSpinsClientConfig().freeSpinsConfigurations.fontColor;
    }
    moreBoxInfoClick(event: any, rewardDataIndex: number) {
        this.onMoreInfoclick(event, rewardDataIndex, 'promotion area');
    }
    onMoreInfoclick(event: any, rewardDataIndex: number, clickArea: string) {
        const rewardData = this.rewardDetails[rewardDataIndex - 1];
        event.stopPropagation();
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'teasers',
            'component.LabelEvent': 'casino game promotions',
            'component.ActionEvent': 'click',
            'component.PositionEvent': 'FreeSpins' + rewardDataIndex,
            'component.LocationEvent': this.quickInfoService.getQuickInfoGameTitle(),
            'component.EventDetails': clickArea,
            'component.URLClicked': rewardData.moreinfoUrl,
        });
        this.navigationAndDialogClose();
        this.navigation.goTo(rewardData?.moreinfoUrl);
    }
    public moreInfoClick(event: any, rewardDataIndex: number) {
        this.onMoreInfoclick(event, rewardDataIndex, 'moreInfoClick');
    }
    navigationAndDialogClose() {
        const onCloseDialogData: any = {
            noNavigation: true,
        };
        this.quickInfoService.closeQuickInfoDialog(onCloseDialogData);
        this.freespinSummaryService.freespinMoreinfoClick();
    }
    public calculateRewardPercent(rewardData: any) {
        this.rewardPercent = (rewardData.availableCount / rewardData.totalCount) * 100;
        return this.rewardPercent;
    }
}
