import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TimeZoneService {
    convertToClientTimeZone(timeString: string, timeZone: string) {
        const clientTimeZoneSeconds: number = this.getClientTimeZoneSeconds(timeString, timeZone);
        return this.formatTimeFromSeconds(clientTimeZoneSeconds);
    }

    getClientTimeZoneSeconds(timeString: string, timeZone: string): number {
        const totalSeconds: number = this.getSecondsFromTimeString(timeString);
        const UTCSeconds: number = this.getUTCSeconds(totalSeconds, timeZone);
        const clientDate: Date = new Date();
        const clientTimeZoneSeconds: number = UTCSeconds - clientDate.getTimezoneOffset() * 60;
        return clientTimeZoneSeconds;
    }

    getUTCSeconds(totalSeconds: number, timezone: string): number {
        const offSetSeconds: number = this.getOffsetFromTimeZoneSeconds(timezone);
        return totalSeconds - offSetSeconds;
    }

    getOffsetFromTimeZoneSeconds(timezone: string): number {
        if (timezone === 'UTC') {
            return 0;
        }
        //TODO: Maintain a map in configuration
        return -14400 * 60;
    }

    formatTimeNumber(value: number): string {
        if (value === 0 || !value) {
            return '00';
        } else if (value < 10) {
            return '0' + value;
        }
        return value.toString();
    }

    getSecondsFromTimeString(timeString: string): number {
        if (timeString) {
            const timeArray: any = timeString.split(':');

            const hours: number = timeArray[0] ? Number(timeArray[0]) : 0;
            const minutes: number = timeArray[1] ? Number(timeArray[1]) : 0;
            const seconds: number = timeArray[2] ? Number(timeArray[2]) : 0;
            const totalSeconds: number = hours * 60 * 60 + minutes * 60 + seconds;
            return totalSeconds;
        }
        return 0;
    }

    formatTimeFromSeconds(totalSeconds: number): string {
        const hours = Math.floor(totalSeconds / (60 * 60));
        const minutes = Math.floor((totalSeconds - hours * 60 * 60) / 60);
        const seconds = totalSeconds - (hours * 60 * 60 + minutes * 60);
        return this.formatTimeNumber(hours) + ':' + this.formatTimeNumber(minutes) + ':' + this.formatTimeNumber(seconds);
    }

    getTimerStartInstance(timeString: string, timeZone: string) {
        const startTime: string = this.convertToClientTimeZone(timeString, timeZone);
        const startTimeSeconds: number = this.getSecondsFromTimeString(startTime);
        const currentDate: Date = new Date();
        const currentDateSeconds: number = currentDate.getHours() * 60 * 60 + currentDate.getMinutes() * 60 + currentDate.getSeconds();
        const durationSeconds: number = startTimeSeconds - currentDateSeconds;
        if (durationSeconds < 0) {
            return null;
        }
        return this.formatTimeFromSeconds(durationSeconds);
    }

    getDateFormat(dateFormat: any) {
        const mm = dateFormat.getMonth() + 1;
        const dd = dateFormat.getDate();
        const yyyy = dateFormat.getFullYear();
        const format = dd + '/' + mm + '/' + yyyy;
        const hour = dateFormat.getHours();
        const minutes = dateFormat.getMinutes();
        const newformat = hour >= 12 ? 'PM' : 'AM';
        return format + '  | ' + hour + ':' + minutes + ' ' + newformat;
    }
}
