<div *ngIf="displayTitle" class="casino-game-tile-wrapper casino-lobby-width-gametile" [ngClass]="isUkHeading ? 'wished' : ''">
    <h2 class="game-tile-heading casino-lobby-header" [ngClass]="{ 'casino-uk-header': isUkHeading, 'epcot-heading': enableEpcotSubNavigation }">
        <ng-container *ngIf="isUkHeading">
            <ul class="ul-icon" *ngIf="displayTitleIcon">
                <li [ngStyle]="{ color: displayTitleColor }" class="uk-icon right-align" [ngClass]="displayTitleIcon">&nbsp;</li>
            </ul>
            <ul class="ul-icon">
                <li>
                    <h2 class="title-uk" [ngStyle]="{ 'font-size': displayTitleFontSize, 'color': displayTitleColor }">{{ displayTitle }}</h2>
                </li>
            </ul>
        </ng-container>
        <span *ngIf="!isUkHeading" [ngStyle]="{ 'font-size': displayTitleFontSize, 'color': displayTitleColor }" class="category-title">{{
            displayTitle
        }}</span>
    </h2>
</div>
