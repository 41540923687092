import { Injectable } from '@angular/core';

import { Message, MessageLifetime, MessageQueueService, MessageType } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { PlatformApiService } from '../platform-api/platform-api.service';
import { IPromotionsBanner } from './models/promotions-banner.model';

@Injectable({
    providedIn: 'root',
})
export class PromotionsBannerService {
    promotionsBannerData: IPromotionsBanner;
    private promotionsDataPublisher = new BehaviorSubject<any>(null);
    promotionsDataObservable: Observable<any> = this.promotionsDataPublisher.asObservable();

    constructor(
        private api: PlatformApiService,
        private messageQueue: MessageQueueService,
    ) {}

    fetchPromotionsBannerData(): void {
        this.api
            .post('PromotionsBanner/FetchPromotionsBannerData', {
                responseType: 'json',
                withCredentials: true,
                showSpinner: false,
            })
            .subscribe({
                next: (response: any) => {
                    if (response) {
                        this.promotionsBannerData = response;
                        this.promotionsDataPublisher.next(true);
                    }
                },
                error: (error: string) => {
                    const message: Message = {
                        html: error,
                        type: MessageType.Error,
                        lifetime: MessageLifetime.Single,
                        scope: 'casinocorelobby',
                    };
                    if (message) {
                        this.messageQueue.add(message);
                    }
                },
            });
    }
    getPromotionsBannerData(): any {
        return this.promotionsBannerData;
    }
    addError = (errorMessage: string): void => {
        const message: Message = {
            html: errorMessage,
            type: MessageType.Error,
            lifetime: MessageLifetime.Single,
            scope: 'casinocorelobby',
        };
        if (message) {
            this.messageQueue.add(message);
            setTimeout(() => this.messageQueue.clear(), 1000);
        }
    };
}
