import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ClaimsService, CookieService, DeviceService, NativeAppService } from '@frontend/vanilla/core';

import { CasinoLobbyService } from '../casino-lobby-manager/casino-lobby.service';
import { ConfigProviderService } from '../config-provider/config-provider.service';
import { UrlUtilityService } from '../utilities/url-utility.service';
import { AdvanceGameDataService } from './advance-game-data.service';
import { ApiPathsHelperService } from './api-paths-helper-service';
import { CasinoManager } from './casino-manager.service';
import { FavouriteService } from './favourite.service';
import { GameLaunch } from './models/gamelaunch.model';

@Injectable({
    providedIn: 'root',
})
export class GameLaunchUrlFormationService {
    currentProduct: string;
    isPokerOROdr: boolean;
    constructor(
        private configProviderService: ConfigProviderService,
        private claimsConfig: ClaimsService,
        private advanceGameDataService: AdvanceGameDataService,
        private urlUtilityService: UrlUtilityService,
        private favouriteService: FavouriteService,
        private casinoManager: CasinoManager,
        private apiPathsHelperService: ApiPathsHelperService,
        private readonly nativeAppService: NativeAppService,
        private cookieService: CookieService,
        private router: Router,
        private deviceService: DeviceService,
        private casinoLobbyService: CasinoLobbyService,
    ) {}

    launchMGSGame(response: any, gameLaunchParam: GameLaunch) {
        let url: string;
        let defaultUrlPattern: any;
        const gameLaunchParams = response.casinoCoreGameLaunchResponseParams;
        const gameLaunchQueryParams = response.casinoCoreGameLaunchQueryResponseParams; //parameters from api response
        const gameConfig = this.configProviderService.provideGameConfig();
        const featureConfig = this.configProviderService.provideFeaturesConfig();
        const casinoConfig = this.configProviderService.provideCasinoConfig();
        const advanceGameDataConfig = this.configProviderService.provideAdvanceGameDataConfig();
        const product = this.getInvokerProduct(gameLaunchParams.product);
        const channelId = gameLaunchParams.channelId;
        const currencyCode = this.claimsConfig.get('currency');
        const ssoToken = this.claimsConfig.get('ssoToken');
        const enableMultigameView = featureConfig.enableMultiGameVIew;
        const egvFavourites = featureConfig.egvFavourites;
        const brandId = casinoConfig.brandId;
        const frontendId = casinoConfig.frontendId;
        const pingUrl = this.getPingUrlWithToken() ? this.getPingUrlWithToken() : '';
        const accountUrl = this.getAccountUrl();
        const historyUrl = this.getHistoryUrl();
        const indexFilePath = this.getIndexFilePath();
        const isFavGame = this.favouriteService.checkForFavouriteGame(gameLaunchParam.game);
        const isGeoGuardEnabled = this.nativeAppService.isNativeApp || this.nativeAppService.isNativeWrapper ? false : true;
        const geoPollingUrl = gameLaunchParams.geoPollingUrl;
        const launchType = this.casinoManager.isTouch() ? 'EL' : 'IL';
        const casinoAsIFrameConfig = this.configProviderService.provideCasinoAsIFrameConfig();
        let depositRedirectUrl = gameLaunchParams.depositRedirectUrl;
        if (gameLaunchParam.gameLaunchedFrom != 'playRedirect') {
            depositRedirectUrl = this.updateDepositRedirectUrl(gameLaunchParams.depositRedirectUrl);
        }
        gameLaunchParams.gameHomeUrl = decodeURIComponent(gameLaunchParams.gameHomeUrl);
        gameLaunchParams.gameHomeUrl = gameLaunchParams.gameHomeUrl.replace('/?', '?');

        if (gameLaunchParam.gameContainer === 'Overlay' && casinoAsIFrameConfig.appendOpenCasinoCoreOverlayQueryParam) {
            if (!(gameLaunchParams.gameHomeUrl.indexOf('openCasinoCoreOverlay=true') > -1)) {
                gameLaunchParams.gameHomeUrl = this.appendQueryString(gameLaunchParams.gameHomeUrl, 'openCasinoCoreOverlay', 'true');
            }
        }
        if (gameLaunchParam.returnUrl && gameLaunchParam.returnUrl != '') {
            gameLaunchParams.gameHomeUrl = gameLaunchParam.returnUrl;
        }
        gameLaunchParams.gameHomeUrl = encodeURIComponent(gameLaunchParams.gameHomeUrl);
        this.advanceGameDataService.setGameLaunchProductsIndex();
        const windowObj = window as any;
        let pageNumber = 0;
        if (windowObj.clientConfig.vnHeader !== undefined) {
            windowObj.clientConfig.vnHeader.products.forEach((headerPrduct: any) => {
                if (headerPrduct?.name?.toLowerCase() === this.currentProduct?.toLowerCase()) {
                    pageNumber = headerPrduct.ProductIndex;
                }
            });
        }
        if ((this.deviceService.isMobile || this.deviceService.isTouch) && windowObj.clientConfig.vnBottomNav !== undefined) {
            windowObj.clientConfig.vnBottomNav.items.forEach((bottmNavItems: any) => {
                if (bottmNavItems?.name?.toLowerCase() === this.currentProduct?.toLowerCase()) {
                    pageNumber = bottmNavItems.ProductIndex;
                }
            });
        }
        let sglProduct = this.currentProduct ? this.currentProduct : gameLaunchParam?.currentProduct;
        sglProduct = sglProduct ? sglProduct : 'games'; // if current product is not available by default consider games as product
        const isLiveConfig = gameLaunchParam?.isLive && gameConfig.desktopChannelIds.indexOf(channelId) > -1;
        this.isPokerOROdr = this.casinoLobbyService.isPokerApp() || this.casinoLobbyService.isODR();
        if ((this.casinoManager.isSingleHostLobbyType(gameLaunchParam?.game?.lobbyType, sglProduct) || this.isPokerOROdr) && !isLiveConfig) {
            url = this.singleGameLaunchUrlFormation(
                gameConfig,
                gameLaunchParams,
                gameLaunchParam,
                channelId,
                product,
                pageNumber,
                brandId,
                ssoToken,
                frontendId,
                currencyCode,
            );
        } else {
            const mobileUrlParamArray: Array<string> = [
                ssoToken,
                gameLaunchParams.internalGameName,
                gameLaunchParams.languageCode,
                channelId,
                product,
                gameLaunchParams.gameHomeUrl,
                encodeURIComponent(pingUrl),
                encodeURIComponent(accountUrl),
                encodeURIComponent(depositRedirectUrl),
                encodeURIComponent(gameLaunchParams.responsibleGamingUrl),
                brandId,
                gameLaunchParam.game.lobbyType,
                gameConfig.win.casinoEnvironment,
                gameLaunchParams.userHostAddress,
                gameConfig.win.keepAliveInterval,
                historyUrl,
                encodeURIComponent(gameLaunchParams.generalTermsAndConditionsUrl),
                encodeURIComponent(gameLaunchParams.casinoHomeUrl),
                gameLaunchParam.mode,
                gameLaunchParam.isDemoLaunch,
                encodeURIComponent(indexFilePath),
                gameLaunchParams.portalLanguage.routeValue,
                encodeURIComponent(geoPollingUrl),
                isGeoGuardEnabled,
                frontendId,
                pageNumber,
                gameLaunchParam.gamePosition + 1,
            ];

            const desktopUrlParamArray: Array<string> = [
                gameLaunchParams.languageCode,
                gameLaunchParams.internalGameName,
                product,
                channelId,
                gameLaunchParams.languageCode,
                currencyCode,
                ssoToken,
                'lobby',
                pageNumber,
                gameLaunchParam.gamePosition + 1,
                '1024',
                '770',
                encodeURIComponent(gameLaunchParams.casinoNoLanguageBaseUrl),
                enableMultigameView,
                egvFavourites.toString(),
                brandId,
                frontendId,
                isFavGame.toString(),
                gameLaunchParams.portalLanguage.routeValue,
                gameConfig.win.keepAliveInterval,
                encodeURIComponent(pingUrl),
                launchType,
                gameLaunchParams.gameHomeUrl,
                gameLaunchParam.game.lobbyType,
            ];
            if (gameConfig.desktopChannelIds.indexOf(channelId) > -1) {
                //  todo ---- check for live games
                if (gameLaunchParam.fetchLiveGameIFrame) {
                    defaultUrlPattern = gameConfig.desktopMGSLiveGameLaunchURL;
                    url = this.format(defaultUrlPattern, desktopUrlParamArray);
                } else if (!response.isLiveGame) {
                    defaultUrlPattern = gameConfig.desktopMGSGameLaunchUrl.defaultUrlPattern;
                    url = this.format(defaultUrlPattern, desktopUrlParamArray);
                } else {
                    defaultUrlPattern = gameConfig.desktopMGSLiveGameLaunchURL;
                    url = this.format(defaultUrlPattern, desktopUrlParamArray);
                }
            } else {
                defaultUrlPattern = gameConfig.mgsGameLaunchUrl.defaultUrlPattern;
                url = this.format(defaultUrlPattern, mobileUrlParamArray);
            }
        }
        if (gameLaunchQueryParams) {
            if (gameConfig?.enableSlotRaceParams && (gameLaunchParam?.promoId || gameLaunchParam?.slotUniqueId)) {
                gameLaunchQueryParams.promoId = gameLaunchParam.promoId;
                gameLaunchQueryParams.slotUniqueId = gameLaunchParam.slotUniqueId;
            }

            if (gameLaunchQueryParams.clickTimeStamp) {
                gameLaunchQueryParams.clickTimeStamp = gameLaunchParam.clickTimeStamp;
            }
            if (gameLaunchQueryParams.launchSource) {
                if (gameLaunchParam.launchSource) {
                    gameLaunchQueryParams.launchSource = gameLaunchParam.launchSource;
                } else {
                    gameLaunchQueryParams.launchSource = this.launchSource();
                }
            }
            Object.keys(gameLaunchQueryParams).forEach((key: string) => {
                url = this.appendQueryString(url, key, gameLaunchQueryParams[key]);
            });
        }
        if (advanceGameDataConfig.enableAdvanceGameData) {
            url = this.advanceGameDataService.appendAdvanceGameDataUrl(url, channelId);
        }
        url = url.split('//').join('/');
        url = this.encodeQueryParam(url, 'bgColor');
        url = this.addHost(url);
        if (this.configProviderService?.gameConfig?.passNativeAppCookieToGameLaunch) {
            const nativeApp: string = 'nativeApp';
            const nativeAppCookie = this.cookieService.get('NativeApp');
            if (nativeAppCookie != null) url = this.appendQueryString(url, nativeApp, nativeAppCookie);
        }
        //console.log('gamelaunchurl:' + url);
        return url;
    }

    singleGameLaunchUrlFormation(
        gameConfig: any,
        gameLaunchParams: any,
        gameLaunchParam: GameLaunch,
        channelId: string,
        product: string,
        pageNumber: number,
        brandId: string,
        ssoToken: string | null,
        frontendId: string,
        currencyCode: string | null,
    ) {
        let url: string;
        let defaultUrlPattern: any;
        let isDesktopContainer: boolean = gameConfig.desktopChannelIds.indexOf(channelId) > -1;
        let coinGame: boolean = gameLaunchParam?.game?.lobbyType?.toLowerCase() == gameConfig?.arcadeLobbyType?.toLowerCase();
        gameLaunchParams.gameHomeUrl = this.isPokerOROdr ? encodeURIComponent(gameConfig?.backToPortalLobby) : gameLaunchParams.gameHomeUrl;

        let singleGameUrlParamArray: Array<any> = [
            gameLaunchParams.internalGameName,
            gameLaunchParams.languageCode,
            channelId,
            product,
            pageNumber,
            gameLaunchParam.gamePosition + 1,
            brandId,
            gameLaunchParam.game.lobbyType,
            gameLaunchParams.userHostAddress,
            gameLaunchParam.mode,
            ssoToken,
            gameLaunchParams.gameHomeUrl,
            gameLaunchParams.portalLanguage.routeValue,
            frontendId,
            currencyCode,
            'lobby',
            isDesktopContainer,
            coinGame,
        ];
        defaultUrlPattern = gameConfig?.singleGameLaunchUrl?.defaultUrlPattern;
        url = this.format(defaultUrlPattern, singleGameUrlParamArray);
        return url;
    }

    format(content: string, array: Array<string>) {
        let theString = content;
        for (let i = 0; i < content.length; i++) {
            const regEx = new RegExp('\\{' + i + '\\}', 'gm');
            if (array[i] != undefined) {
                theString = theString.replace(regEx, array[i]);
            } else {
                theString = theString.replace(regEx, '');
            }
        }
        return theString;
    }
    getPingUrlWithToken() {
        const userToken = this.claimsConfig.get('userToken');
        const sessionToken = this.claimsConfig.get('sessionToken');
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.apiPathsHelperService.transformApi(
            apiPathsConfig.ping['pgUrlWithToken'],
            this.urlUtilityService.getApiBaseUrl(),
            userToken,
            sessionToken,
        );
    }
    getAccountUrl() {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.urlUtilityService.getApiBaseUrl() + apiPathsConfig.gameData['myCasino'];
    }
    getHistoryUrl() {
        const casinoConfig = this.configProviderService.provideCasinoConfig();
        const gameLogUrl = casinoConfig.gameLogUrl;
        if (gameLogUrl == null) {
            return null;
        } else {
            const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
            return encodeURIComponent(this.urlUtilityService.getApiBaseUrl() + apiPathsConfig.gameData['gameLogs']);
        }
    }
    getIndexFilePath() {
        const gameConfig = this.configProviderService.provideGameConfig();
        const indexFilePath: string = gameConfig.win.winGameLaunchFile;
        return indexFilePath.substring(0, indexFilePath.lastIndexOf('/'));
    }
    addHost(url: string) {
        const gameConfig = this.configProviderService.provideGameConfig();
        if (url.indexOf('http') != -1) {
            url = gameConfig.host + '/' + url;
        }
        return url;
    }
    appendQueryString(url: string, key: string, value: string): string {
        if (url.indexOf('?') > -1) {
            url += '&' + key + '=' + value;
        } else {
            url += '?' + key + '=' + value;
        }
        return url;
    }

    launchSource() {
        const nativeAppCookie = this.cookieService.get('NativeApp');
        if (nativeAppCookie && (nativeAppCookie?.toLowerCase() === 'pokervc' || nativeAppCookie?.toLowerCase() === 'pokermc')) {
            return 'poker_portal';
        } else if (nativeAppCookie?.toLowerCase() === 'poker') {
            return 'pk_pc_native';
        }
        return 'casino_portal';
    }

    setCurrentProduct(routeCurrentProduct: string) {
        this.currentProduct = routeCurrentProduct;
    }

    getCurrentProduct() {
        return this.currentProduct;
    }

    encodeQueryParam(url: string, key: string): string {
        const queryParamValue = this.getQueryParameter(url, key);
        if (queryParamValue) {
            let updatedUrl = this.removeParam(url, key);
            updatedUrl = this.appendQueryString(updatedUrl, key, encodeURIComponent(queryParamValue));
            return updatedUrl;
        }
        return url;
    }

    getQueryParameter(url: string, key: string): string {
        const parameters = new URLSearchParams(url);
        return parameters.get(key) || '';
    }

    removeParam(url: string, key: string) {
        let splittedURL = url.split('?')[0];
        let params_arr: string[] = [];
        const queryString = url.indexOf('?') !== -1 ? url.split('?')[1] : '';
        if (queryString !== '') {
            params_arr = queryString.split('&');
            for (let i = params_arr.length - 1; i >= 0; i -= 1) {
                const param = params_arr[i].split('=')[0];
                if (param === key) {
                    params_arr.splice(i, 1);
                }
            }
            if (params_arr?.length > 0) {
                splittedURL = splittedURL + '?' + params_arr.join('&');
            }
        }
        return splittedURL;
    }

    getInvokerProduct(product: string) {
        const modularSettingsConfig = this.configProviderService.provideModularSettingsConfig();
        const productMap = modularSettingsConfig.productMap;
        const invokerProduct = productMap.filter((af: any) => af?.product?.toLowerCase() === product?.toLowerCase());
        return invokerProduct[0].invokerProduct.toUpperCase();
    }

    updateDepositRedirectUrl(depositRedirectUrl: string): string {
        let depositUrl: string = decodeURIComponent(depositRedirectUrl);
        if (depositUrl) {
            let parentUrl = depositUrl.slice(depositUrl.indexOf('parentUrl=') + 'parentUrl='.length);
            parentUrl = this.appendQueryString(parentUrl, 'returnUrl', window.location.href);
            parentUrl = parentUrl.replace('/?', '?');
            depositUrl = this.removeParam(depositUrl, 'parentUrl');
            depositUrl = this.appendQueryString(depositUrl, 'parentUrl', parentUrl);
        }
        return depositUrl;
    }
}
