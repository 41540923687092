import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

import {
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    FastLoadingGamesService,
    GameDataManager,
    GameLobbyModel,
    JackpotDataService,
} from '@casinocore/platform/core';
import { DeviceService } from '@frontend/vanilla/core';
import {
    NguCarousel,
    NguCarouselConfig,
    NguCarouselDefDirective,
    NguCarouselNextDirective,
    NguCarouselPrevDirective,
    NguCarouselStore,
    NguTileComponent,
} from '@ngu/carousel';

import { CasinoCoreLazyPlaceholderComponent } from '../casino-core-lazy-placeholder/casino-core-lazy-placeholder.component';
import { FeatureGridComponent } from '../feature-grid/feature-grid.component';
import { FlipclockComponent } from '../flipclock/flipclock.component';

@Component({
    selector: 'cc-must-go',
    templateUrl: 'must-go.component.html',
    standalone: true,
    imports: [
        NgIf,
        NgStyle,
        NgClass,
        NgFor,
        FlipclockComponent,
        CasinoCoreLazyPlaceholderComponent,
        FeatureGridComponent,
        NguCarouselNextDirective,
        NguCarouselPrevDirective,
        NguCarousel,
        NguCarouselDefDirective,
        NguTileComponent,
    ],
})
export class MustGoJackpotComponent implements OnInit, OnChanges {
    @ViewChild('featuredGrid') featuredGrid: FeatureGridComponent;
    @Input() category: any;
    gameModel: GameLobbyModel;
    @Input() isTouch: boolean;
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    @Input() changeCarouselView: boolean;
    @Output() isjackpotFeed = new EventEmitter<object>();
    public bcolor: any;
    public image: any;
    public mobileCarouselTile: NguCarouselConfig;
    public ribboncolor: any;
    public ribbonsidecolor: any;
    public ribbontrianglecolor: any;
    currency: any;
    jackpotsFeed: any;
    games: any = [];
    showMustGo: boolean;
    MustGoFeedObject: any;
    public MustGoSubJackpotsList: any = [];
    MustGoSitecoreObject: any;
    MustGoProperties: any;
    isCarousal: boolean;
    windowWidth = this.deviceService.windowWidth;
    isPreButtonVisible: boolean;
    isNextButtonVisible: boolean;
    releaseTime: any;
    reftime: any;
    isClockVisible: boolean;
    currentSlide: any = 0;
    moveSlideTo: number;
    startSlide: number = 0;
    isCrossSaleIOS: boolean;
    isMustgoRedesign: boolean = false;
    primaryJackpotDisplayname: string = '';
    subJackpot1DisplayName: string = '';
    subJackpot2DisplayName: string = '';
    subJackpot3DisplayName: string = '';
    newFeatureGridArr: any[] = [];
    categoryObj: any;
    carouselData: any;
    themeArrowLeft: any;
    themeArrowRight: any;
    subCategorySubTitleFontSize: any;
    isUkHeading: any;
    subCategoryTextColor: any;
    lobbyTileFontFamily: any;
    totalJpGroupList: any;
    isEnableMustgoRedesignModes: boolean = false;
    JackpotDisplayNameConfig: any;
    constructor(
        private configProviderService: ConfigProviderService,
        private jackpotDataService: JackpotDataService,
        private gameDataManager: GameDataManager,
        private fastLoadingGamesService: FastLoadingGamesService,
        private casinoLobbyService: CasinoLobbyService,
        private casinoManager: CasinoManager,
        private deviceService: DeviceService,
    ) {
        this.isClockVisible = false;
    }
    ngOnInit() {
        const global = this.configProviderService.provideGlobalConfig();
        this.themeArrowLeft = global.messages.ThemeArrowLeft;
        this.themeArrowRight = global.messages.ThemeArrowRight;
        const features: any = this.configProviderService.provideFeaturesConfig();
        this.subCategorySubTitleFontSize = this.casinoLobbyService.getsubCategorySubTitleFontSize(this.category);
        this.isUkHeading = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.isUkHeading);
        this.subCategoryTextColor = this.casinoLobbyService.getsubCategoryTextColor(this.category);
        this.lobbyTileFontFamily = this.casinoLobbyService.getLTileFontFamily(this.category);
        const JackpotDataClientConfig: any = this.configProviderService.provideJackpotConfig();
        this.isEnableMustgoRedesignModes = JackpotDataClientConfig.isEnableMustgoRedesignModes;
        this.reftime = JackpotDataClientConfig.mustGoJackpotTimerBase;
        this.reftime = this.reftime.split(':');
        this.setCarousel();
        if (!this.isMustgoRedesign) {
            setInterval(() => {
                const time1 = new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate(),
                    this.reftime[0],
                    this.reftime[1],
                    this.reftime[2],
                    new Date().getMilliseconds(),
                );
                const time2 = new Date();
                let difference = time1.getTime() - time2.getTime();
                if (difference < 0) {
                    difference = difference + 86400000;
                }
                let hours: any = Math.floor((difference / (1000 * 60 * 60)) % 24);
                let minutes: any = Math.floor((difference / (1000 * 60)) % 60);
                let seconds: any = Math.floor((difference / 1000) % 60);
                hours = hours < 10 ? '0' + hours : hours;
                minutes = minutes < 10 ? '0' + minutes : minutes;
                seconds = seconds < 10 ? '0' + seconds : seconds;
                this.releaseTime = hours + ':' + minutes + ':' + seconds;
            }, 1000);
        }

        this.mobileCarouselTile = {
            grid: { xs: 1, sm: 4.5, md: 4.5, lg: 6, all: 0 },
            slide: 1,
            speed: 400,
            point: {
                visible: false,
            },
            loop: false,
            touch: true,
        };
        if (JackpotDataClientConfig.enableJackpotSortGames) {
            this.getSortingGames();
        }
        this.startJackpotFeed();
        this.fastLoadingGamesService.fastLoadingGamesFeedObservable.subscribe((fastLoadingGames: any) => {
            this.fastLoadingGamesService.setFastLoadingGames(this.games, fastLoadingGames);
        });
    }
    onResize() {
        this.setCarousel();

        if (this.currentSlide == 0) {
            this.isPreButtonVisible = true;
        }
        this.moveSlideTo = this.currentSlide;
    }
    startJackpotFeed() {
        this.refreshJackpotsNew();

        //TODO: Not necessary as feed is started on casino bootstrap
        //this.jackpotService.startFeed();
        //ToDO
        //  this.mustGoJackpotHandleForWidget = this.m2PubSub.subscribe('Jackpot.feedUpdate', () => {
        this.jackpotDataService.jackpotFeedObservable.subscribe(() => {
            this.refreshJackpotsNew();
        });
    }

    getCarouselData(data: any) {
        this.carouselData = data;
    }
    onPrevClick() {
        this.featuredGrid.prevItem();
    }

    onNextClick() {
        this.featuredGrid.nextItem();
    }

    refreshJackpotsNew() {
        let sortGameslist: any;
        this.MustGoSitecoreObject = this.category.sitecoreData;
        this.MustGoProperties = this.MustGoSitecoreObject.attributes;
        this.ribboncolor = this.MustGoSitecoreObject.attributes.mustgojackpotribboncolor;
        this.bcolor = this.MustGoSitecoreObject.attributes.mustgobackgroundcolor;
        this.ribbonsidecolor = this.MustGoSitecoreObject.attributes.mustgoribbonsidecolor;
        this.ribbontrianglecolor = this.MustGoSitecoreObject.attributes.mustgoribbontrianglecolor;
        this.JackpotDisplayNameConfig = this.configProviderService.provideGlobalConfig()?.jackpotDisplayNameConfigurations;
        if (this.MustGoSitecoreObject.showMustGo) {
            this.showMustGo = true;
            //TODO
            this.jackpotsFeed = this.jackpotDataService.getJackpotData();
            const MustGoJackpot = this.MustGoSitecoreObject.mustGoJackpot;
            if (this.jackpotsFeed && MustGoJackpot !== '' && MustGoJackpot) {
                for (const key in this.jackpotsFeed) {
                    const jackpot = this.jackpotsFeed[key];
                    if (jackpot.jackpotGroupName && MustGoJackpot && jackpot.jackpotGroupName?.toLowerCase() == MustGoJackpot?.toLowerCase()) {
                        this.MustGoFeedObject = jackpot;
                        const currency = this.MustGoFeedObject.amount.match(/[^\d\.\,\s]/g);
                        if (currency && currency.length > 0) {
                            this.currency = '';
                            for (let i = 0; i < currency.length; i++) {
                                this.currency = this.currency + currency[i];
                            }
                            this.currency = this.currency.replace(/\,/g, '');
                            this.currency = this.currency.replace(/\./g, '');
                        }

                        const AllSubJackpotsList = jackpot.subJackpotDetails;
                        this.MustGoSubJackpotsList = [];
                        const SitecoreSubjackpots = {
                            'primaryJackpot-name': this.MustGoSitecoreObject.attributes.primaryjackpotname,
                            'subJackpot1-Name': this.MustGoSitecoreObject.attributes.subjackpot1name,
                            'subJackpot2-Name': this.MustGoSitecoreObject.attributes.subjackpot2name,
                            'subJackpot3-Name': this.MustGoSitecoreObject.attributes.subjackpot3name,
                        };

                        for (const i in SitecoreSubjackpots) {
                            AllSubJackpotsList.forEach((j: any) => {
                                if (
                                    SitecoreSubjackpots[i as keyof typeof SitecoreSubjackpots] &&
                                    j.subJackpotName &&
                                    SitecoreSubjackpots[i as keyof typeof SitecoreSubjackpots]?.toLowerCase() == j?.subJackpotName?.toLowerCase()
                                ) {
                                    j.subJackpotValue = parseFloat(j.subJackpotValue);
                                    j.ribbonName = this.MustGoProperties.subjackpot1name;
                                    this.MustGoSubJackpotsList.push(j);
                                }
                            });
                        }

                        if (this.MustGoSubJackpotsList[0]) {
                            const jackpotDisplayName = this.JackpotDisplayNameConfig[this.MustGoSubJackpotsList[0].jackpotFeedId];
                            this.primaryJackpotDisplayname = jackpotDisplayName
                                ? jackpotDisplayName
                                : this.MustGoSubJackpotsList[0]?.subJackpotName?.toLowerCase() ===
                                    this.MustGoProperties.primaryjackpotname?.toLowerCase()
                                  ? this.MustGoProperties?.primaryjackpotname
                                  : '';
                            this.MustGoSubJackpotsList[0].ribbonName =
                                this.primaryJackpotDisplayname != '' ? this.primaryJackpotDisplayname : this.MustGoProperties.primaryjackpotname;
                            this.MustGoSubJackpotsList[0].releaseAmount = this.MustGoProperties.primaryjackpotreleaseamount;
                        }
                        if (this.MustGoSubJackpotsList[1]) {
                            const jackpotDisplayName = this.JackpotDisplayNameConfig[this.MustGoSubJackpotsList[1].jackpotFeedId];
                            this.subJackpot1DisplayName = jackpotDisplayName
                                ? jackpotDisplayName
                                : this.MustGoSubJackpotsList[1]?.subJackpotName?.toLowerCase() ===
                                    this.MustGoProperties?.subjackpot1name?.toLowerCase()
                                  ? this.MustGoProperties?.subjackpot1name
                                  : '';
                            this.MustGoSubJackpotsList[1].ribbonName =
                                this.subJackpot1DisplayName != '' ? this.subJackpot1DisplayName : this.MustGoProperties.subjackpot1name;
                            this.MustGoSubJackpotsList[1].releaseAmount = this.MustGoProperties.subjackpot1releaseamount;
                        }
                        if (this.MustGoSubJackpotsList[2]) {
                            const jackpotDisplayName = this.JackpotDisplayNameConfig[this.MustGoSubJackpotsList[2].jackpotFeedId];
                            this.subJackpot2DisplayName = jackpotDisplayName
                                ? jackpotDisplayName
                                : this.MustGoSubJackpotsList[2]?.subJackpotName?.toLowerCase() ===
                                    this.MustGoProperties?.subjackpot2name?.toLowerCase()
                                  ? this.MustGoProperties?.subjackpot2name
                                  : '';
                            this.MustGoSubJackpotsList[2].ribbonName =
                                this.subJackpot2DisplayName != '' ? this.subJackpot2DisplayName : this.MustGoProperties.subjackpot2name;
                            this.MustGoSubJackpotsList[2].releaseAmount = this.MustGoProperties.subjackpot2releaseamount;
                        }
                        if (this.MustGoSubJackpotsList[3]) {
                            const jackpotDisplayName = this.JackpotDisplayNameConfig[this.MustGoSubJackpotsList[3].jackpotFeedId];
                            this.subJackpot3DisplayName = jackpotDisplayName
                                ? jackpotDisplayName
                                : this.MustGoSubJackpotsList[3]?.subJackpotName?.toLowerCase() ===
                                    this.MustGoProperties?.subjackpot3name?.toLowerCase()
                                  ? this.MustGoProperties?.subjackpot3name
                                  : '';
                            this.MustGoSubJackpotsList[3].ribbonName =
                                this.subJackpot3DisplayName != '' ? this.subJackpot3DisplayName : this.MustGoProperties.subjackpot3name;
                            this.MustGoSubJackpotsList[3].releaseAmount = this.MustGoProperties.subjackpot3releaseamount;
                        }

                        break;
                    }
                }
                this.primaryJackpotDisplayname == '' ? (this.showMustGo = false) : (this.showMustGo = true);

                if (this.totalJpGroupList && this.totalJpGroupList.length > 0) {
                    sortGameslist = this.jackpotDataService.filterJpGroup(this.totalJpGroupList, this.MustGoFeedObject?.jackpotGroupName);
                }
                if (this.MustGoFeedObject && this.MustGoFeedObject.gamesList) {
                    this.setGames(this.MustGoFeedObject.gamesList, sortGameslist);
                }
                if (
                    this.isEnableMustgoRedesignModes &&
                    this.MustGoProperties.primaryjackpotname !== '' &&
                    (this.MustGoProperties.subjackpot1name == '' ||
                        this.MustGoProperties.subjackpot2name == '' ||
                        this.MustGoProperties.subjackpot3name == '') &&
                    !(
                        this.MustGoProperties.subjackpot1name == '' &&
                        this.MustGoProperties.subjackpot2name == '' &&
                        this.MustGoProperties.subjackpot3name == ''
                    )
                ) {
                    this.isMustgoRedesign = true;
                }
            }
        } else {
            this.showMustGo = false;
        }
    }

    onmoveFn(data: NguCarouselStore) {
        if (this.MustGoProperties.dailyjackpotname == this.MustGoSubJackpotsList[data.currentSlide].subJackpotName) {
            this.isClockVisible = true;
        } else {
            this.isClockVisible = false;
        }
        this.isPreButtonVisible = data.isFirst;
        this.currentSlide = data.currentSlide;
        this.isNextButtonVisible = data.isLast;
        this.moveSlideTo = data.slideItems;
    }

    setGames(gameslist: string[], sortGameslist?: any) {
        this.games = [];
        this.newFeatureGridArr = [];
        if (gameslist && gameslist.length > 0) {
            let starttileBgMode: boolean = false;
            const newgridbgcolor = this.category?.sitecoreData?.attributes?.newgridbgcolor;
            this.categoryObj = { ...this.category };
            this.categoryObj.rowCount = '-1';
            for (let i = 0; i < gameslist.length; i++) {
                const gameIdString: string = gameslist[i];
                const gameInfo: any = this.casinoLobbyService.getGameInfo(gameIdString);
                if (gameInfo) {
                    let disabledGame: boolean = false;
                    const disabledGames = this.casinoLobbyService.getDisabledGames();
                    disabledGames.forEach((game: any) => {
                        if (game?.toLowerCase() == gameInfo?.game?.toLowerCase()) {
                            disabledGame = true;
                        }
                    });
                    if (!disabledGame) {
                        gameInfo.icon = this.category.isIconSixCategory ? '6' : this.category.isIconSevenCategory ? '7' : '1';
                        gameInfo.lobbyType = this.lobbyType;
                        const gameObj = this.gameDataManager.setGameLobbyModel(gameInfo, this.isTouch);
                        gameObj.gameContainer = this.gameContainer;
                        gameObj.bgTileMode = this.category.isIconSevenCategory ? (starttileBgMode ? starttileBgMode : false) : false;
                        gameObj.gameTileBorderRadius = this.casinoLobbyService.getBorderRadius(this.category);
                        gameObj.gameTileDropShadow = this.casinoLobbyService.getDropShadow(this.category);
                        gameObj.infoIconClass = this.casinoManager.assignGameClass(this.casinoManager.getCurrentWidth(), gameInfo.icon);
                        gameObj.newgridbgcolor = newgridbgcolor;
                        starttileBgMode = !starttileBgMode;
                        this.games.push(gameObj);
                        this.newFeatureGridArr.push({
                            games: [gameObj],
                            maxRowSpan: gameObj.rowSpan,
                            maxColSpan: gameObj.colSpan,
                        });
                    }
                }
            }
            if (this.games && this.games.length > 0) {
                this.isjackpotFeed.emit({ isJackpotdataForParallax: true });
            }
            // if((this.isPreButtonVisible == undefined || this.isNextButtonVisible == undefined) || (this.currentSlide == (this.MustGoSubJackpotsList.length-1))){
            //     this.isPreButtonVisible = true;
            //     if (this.MustGoSubJackpotsList && this.MustGoSubJackpotsList.length > 1) {
            //         this.isNextButtonVisible = false;
            //     } else {
            //         this.isNextButtonVisible = true;
            //     }
            // }
            this.isPreButtonVisible = false;
            this.isNextButtonVisible = false;
            if (this.currentSlide == 0) this.isPreButtonVisible = true;
            if (this.currentSlide == this.MustGoSubJackpotsList?.length - 1) this.isNextButtonVisible = true;
        }
        if (sortGameslist && sortGameslist.length > 0) {
            this.games = this.jackpotDataService.filterGames(sortGameslist, this.games);
            this.newFeatureGridArr = this.jackpotDataService.newFeaturegridArray(this.games);
        }
    }

    setCarousel() {
        if (this.isTouch === false) {
            this.isCarousal = true;
        } else {
            this.isCarousal = false;
        }
    }
    getSortingGames() {
        this.jackpotDataService.jpSortGamesObservable.subscribe((data: any) => {
            this.totalJpGroupList = data?.jackpotGroupGamesList;
            this.refreshJackpotsNew();
        });
    }
    ngOnChanges() {
        this.refreshJackpotsNew();
    }
}
