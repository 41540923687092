<div class="item-slide" [ngClass]="customClass" id="{{ customId }}" (click)="teaserClick($event, teaser, teaserIndex + 1)">
    <div class="opt-teaser-body" [style.height.px]="teaserHeight">
        <cc-opt-teaser-bg-image-npm
            *ngIf="!teaser.isVideoUrl"
            [teaser]="teaser"
            [isMobileImage]="deviceName"
            [teaserBackgroundImageAlignment]="teaserBackgroundImageAlignment"
            [teaserBackgroundPosition]="teaserBackgroundPosition">
        </cc-opt-teaser-bg-image-npm>
        <div *ngIf="isOptTeaserPhase3 && teaser.isVideoUrl">
            <cc-opt-teaser-video-npm
                *ngIf="teaser.videoUrl"
                [activeSlides]="activeSlides"
                [teaserVideoAlignment]="teaserVideoAlignment"
                [teaserVideoPosition]="teaserVideoPosition"
                [isHover]="isHover"
                [subcategory]="teaserCategory"
                [lobbyType]="lobbyType"
                [gameContainer]="gameContainer"
                [type]="videoType"
                [url]="videoUrl"
                [imageThumbnail]="teaser.videoUrl.thumbnailImage"
                [isMobileImage]="isMobileWidth()"
                [teaserHeight]="teaserHeight"
                [realIndex]="teaserIndex"
                [identifierClass]="identifierClass"
                [isOptMiniTeasersEnabled]="isOptMiniTeasersEnabled"
                [isNPM]="isNPM"
                [globalTeaserSettings]="globalTeaserSettings"
                (onVideoLoadedEvent)="emitVideoLoadedEvent()"
                (onVideoEndEvent)="getVideoEndEvent()"
                (videoErrorEvent)="videoError()"
                (onTimeChangeEvent)="getTimeChangeEvent($event)">
            </cc-opt-teaser-video-npm>
        </div>
        <cc-opt-teaser-actor-image-npm *ngIf="!teaser.isVideoUrl" [teaser]="teaser" [imagePosition]="imagePosition" [isMobileImage]="isMobileWidth()">
        </cc-opt-teaser-actor-image-npm>
        <div [ngClass]="'opt-teaser-text-' + (horizontalPosition | lowercase)">
            <div
                class="opt-teaser-info"
                [ngClass]="'text-align-' + (horizontalAlignment | lowercase)"
                [style.background]="isOptTeaserPhase3 ? teaserDefaultContentBg : ''">
                <cc-opt-teaser-text-position-npm
                    [teaser]="teaser"
                    [teaserTitle]="teaser.teaserTitle"
                    [teaserSubTitle]="teaser.subTitle"
                    [verticalPosition]="verticalPosition"
                    [teaserVerticalPosition]="teaser.textVerticalPosition"
                    [isNPM]="isNPM"
                    [globalTeaserSettings]="globalTeaserSettings"
                    [teaserDynaconConfigs]="teaserDynaconConfigs"
                    [showJackpotCurrencyOnRight]="showJackpotCurrencyOnRight"
                    [jackpotGroupNameMapTeaserNPM]="jackpotGroupNameMapTeaserNPM">
                </cc-opt-teaser-text-position-npm>
                <div class="opt-teaser-cta" *ngIf="teaser.cta">
                    <a
                        class="btn btn-primary"
                        (click)="ctaClick($event, teaser, teaserIndex + 1, true)"
                        [innerHtml]="teaser.cta.text | trustAsHtml"
                        [ngClass]="{ 'epcot-btn': enableEpcotSubNavigation }">
                    </a>
                </div>
                <div class="opt-teaser-cta" *ngIf="isOptTeaserPhase3 && teaser.secondaryCTA">
                    <a
                        class="btn btn-primary btn-sec"
                        (click)="ctaClick($event, teaser, teaserIndex + 1, false)"
                        [innerHtml]="teaser.secondaryCTA.text | trustAsHtml"
                        [ngClass]="{ 'epcot-btn': enableEpcotSubNavigation }">
                    </a>
                </div>
            </div>
        </div>
        <div
            *ngIf="teaser.significantTermsandConditions"
            [ngClass]="[smallTermsClass, openBingoLobbyButtonPosition | lowercase]"
            (click)="termsClick($event, teaser)">
            <div class="text-terms" [ngClass]="termsTextPositionClass" [innerHTML]="teaser.significantTermsandConditions | trustAsHtml"></div>
        </div>
    </div>
</div>
