import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csDiscoverFeed', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: discoverFeedClientConfigFactory,
})
export class DiscoverFeedClientConfig {
    discoverFeedLmtkey: any;
    enableDiscoverFeed: boolean;
    staticDiscoverfeedLobbytype: string;
}

export function discoverFeedClientConfigFactory(service: LazyClientConfigService) {
    return service.get(DiscoverFeedClientConfig);
}
