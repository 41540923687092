<div [ngClass]="'jackpot-right-content-' + (widgetType | lowercase)">
    <div *ngIf="!changeCarouselView">
        <div class="scroll-btn theme-left left" *ngIf="showLeftScrollButton" (click)="onMoveGames(true, $event)"></div>
        <div class="scroll-btn theme-right right" *ngIf="showRightScrollButton" (click)="onMoveGames(false, $event)"></div>
    </div>
    <div *ngIf="changeCarouselView">
        <span
            *ngIf="!isTouch && games.length > 6"
            class="seeallarrows"
            [ngStyle]="{ 'color': subCategoryTextColor, 'font-family': lobbyTileFontFamily }">
            <span
                class="arrows-left"
                [ngClass]="themeArrowLeft"
                [ngStyle]="{ 'pointer-events': !showLeftScrollButton ? 'none' : 'auto' }"
                (click)="onMoveGames(true, $event)"></span>
            <span
                class="arrows-right"
                [ngClass]="themeArrowRight"
                [ngStyle]="{ 'pointer-events': !showRightScrollButton ? 'none' : 'auto' }"
                (click)="onMoveGames(false, $event)"></span>
        </span>
    </div>
    <div class="jackpot-widget-redesign-content scrollable-games" [style.overflow-x]="!isTouch ? 'hidden' : 'scroll'" #scrollerContainer>
        <mat-grid-list [cols]="gamesGridCols" [rowHeight]="rowHeight + 'px'" [style.width.px]="gamesGridWidth">
            <mat-grid-tile *ngFor="let gameItem of games; let i = index" [colspan]="gameItem.colSpan" [rowspan]="gameItem.rowSpan">
                <div [class]="'game icon-' + gameItem.iconType">
                    <cc-game-lobby
                        [ngClass]="gameItem?.infoIconClass"
                        [game]="gameItem"
                        [gamePosition]="i"
                        [category]="categoryObj"
                        [gameLaunchedFrom]="'JackpotWidget_' + categoryObj.engCategoryName"
                        [gameIndex]="i + 1">
                    </cc-game-lobby>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>
