<div
    class="small-icon"
    *ngIf="showJackpotWidget"
    [ngClass]="{
        'casino-ios-bottom-nav-bar': isIOS,
        'casino-jackpot-widget-container': mode === 'v1',
        'casino-style2': mode === 'v2',
        'casino-style3': mode === 'v3',
        'multi-jckt-gamealigned': games && games.length < 6,
        'multi-jk-gamealigned-mobile': games && games.length < 3
    }"
    [ngStyle]="{ 'background-color': multiJackpotBgClr }">
    <!--Jackpot widget section   -->
    <div *ngIf="widgets.length > 0" (window:resize)="onResize()" class="casino-multi-jackpot">
        <ngu-carousel #myCarousel [inputs]="carouselConfig" (onMove)="onmoveFn($event)" [dataSource]="widgets" *ngIf="!isCarousal">
            <ngu-item *nguCarouselDef="let widget; let track = index">
                <cc-casino-core-lazy-placeholder
                    *ngIf="mode === 'v1'"
                    [inputData]="{
                        jackpotName: widget.jackpotName,
                        jackpotValue: widget.jackpotValue,
                        showBackgroundImage: widget.showBackgroundImage,
                        jackpotWidgetBackgroundImage: widget.jackpotWidgetBackgroundImage,
                        jackpotWidgetImage: widget.jackpotWidgetImage,
                        className: classNameTop,
                        jackpotType: jackpotType
                    }"
                    [componentName]="'JackpotwidgetComponent'"></cc-casino-core-lazy-placeholder>

                <cc-casino-core-lazy-placeholder
                    *ngIf="mode === 'v2' || mode === 'v3'"
                    [inputData]="{
                        jackpotName: widget.jackpotName,
                        jackpotValue: widget.jackpotValue,
                        jackpotGameData: games?.length > 0 ? games[0] : [],
                        showBackgroundImage: widget.showBackgroundImage,
                        jackpotWidgetBackgroundImage: widget.jackpotWidgetBackgroundImage,
                        jackpotWidgetImage: widget.jackpotWidgetImage,
                        className: classNameTop,
                        jackpotType: jackpotType,
                        jackpotNameFromSitecore: widget.jackpotDisplayName,
                        fromMulti: true
                    }"
                    [componentName]="'JackpotWidgetRedesignComponent'"></cc-casino-core-lazy-placeholder>
            </ngu-item>
            <span
                NguCarouselPrev
                *ngIf="!isPreButtonVisible && changeCarouselView"
                class="seeallarrows button-left-multi-changeview-mobile"
                [ngStyle]="{ 'color': subCategoryTextColor, 'font-family': lobbyTileFontFamily }">
                <span class="arrows-left" [ngClass]="themeArrowLeft" [ngStyle]="{ 'pointer-events': 'auto' }" (click)="slideClicked(false)"></span>
            </span>
            <span
                NguCarouselNext
                *ngIf="!isNextButtonVisible && changeCarouselView"
                class="seeallarrows button-right-multi-changeview-mobile"
                [ngStyle]="{ 'color': subCategoryTextColor, 'font-family': lobbyTileFontFamily }">
                <span class="arrows-right" [ngClass]="themeArrowRight" [ngStyle]="{ 'pointer-events': 'auto' }" (click)="slideClicked(true)"></span>
            </span>
            <button
                [ngStyle]="{ 'background-color': multiJackpotLeftArrowBgClr, 'opacity': multiJackpotArrowOpacity }"
                [ngClass]="{ 'hidden-cls': isPreButtonVisible || changeCarouselView }"
                NguCarouselPrev
                class="theme-left button-left-multi"
                aria-hidden="true"></button>
            <button
                [ngStyle]="{ 'background-color': multiJackpotRightArrowBgClr, 'opacity': multiJackpotArrowOpacity }"
                [ngClass]="{ 'hidden-cls': isNextButtonVisible || changeCarouselView }"
                NguCarouselNext
                class="theme-right button-right-multi"
                aria-hidden="true"></button>
            <ul class="myPoint" NguCarouselPoint>
                <li
                    *ngFor="let j of myCarousel.pointNumbers; let count = index"
                    [class.active]="count == myCarousel.activePoint"
                    (click)="myCarousel.moveTo(count)"></li>
            </ul>
        </ngu-carousel>

        <cc-ngx-carousel-3d
            [slides]="widgets"
            [options]="options"
            [category]="category.categoryid"
            #carousel
            *ngIf="isCarousal"
            id="{{ category.categoryid }}">
            <cc-ngx-carousel-3d-slide *ngFor="let widget of widgets; let track = index" [ngClass]="{ slide: !widget.isActive }">
                <cc-casino-core-lazy-placeholder
                    *ngIf="mode === 'v1'"
                    [inputData]="{
                        jackpotName: widget.jackpotName,
                        jackpotValue: widget.jackpotValue,
                        showBackgroundImage: widget.showBackgroundImage,
                        jackpotWidgetBackgroundImage: widget.jackpotWidgetBackgroundImage,
                        jackpotWidgetImage: widget.jackpotWidgetImage,
                        className: classNameTop,
                        jackpotType: jackpotType
                    }"
                    [componentName]="'JackpotwidgetComponent'"></cc-casino-core-lazy-placeholder>

                <cc-casino-core-lazy-placeholder
                    *ngIf="mode === 'v2' || mode === 'v3'"
                    [inputData]="{
                        jackpotName: widget.jackpotName,
                        jackpotValue: widget.jackpotValue,
                        showBackgroundImage: widget.showBackgroundImage,
                        jackpotWidgetBackgroundImage: widget.jackpotWidgetBackgroundImage,
                        jackpotWidgetImage: widget.jackpotWidgetImage,
                        className: classNameTop,
                        jackpotType: jackpotType,
                        jackpotNameFromSitecore: widget.jackpotDisplayName,
                        fromMulti: true
                    }"
                    [componentName]="'JackpotWidgetRedesignComponent'"></cc-casino-core-lazy-placeholder>
            </cc-ngx-carousel-3d-slide>
            <span
                *ngIf="changeCarouselView"
                class="seeallarrows button-left-multi-changeview"
                [ngStyle]="{ 'color': subCategoryTextColor, 'font-family': lobbyTileFontFamily }">
                <span class="arrows-left" [ngClass]="themeArrowLeft" [ngStyle]="{ 'pointer-events': 'auto' }" (click)="slideClicked(false)"></span>
            </span>
            <span
                *ngIf="changeCarouselView"
                class="seeallarrows button-right-multi-changeview"
                [ngStyle]="{ 'color': subCategoryTextColor, 'font-family': lobbyTileFontFamily }">
                <span class="arrows-right" [ngClass]="themeArrowRight" [ngStyle]="{ 'pointer-events': 'auto' }" (click)="slideClicked(true)"></span>
            </span>
            <button
                *ngIf="!changeCarouselView"
                [ngStyle]="{ 'background-color': multiJackpotLeftArrowBgClr, 'opacity': multiJackpotArrowOpacity }"
                class="theme-left button-left-multi"
                (click)="slideClicked(false)"
                aria-hidden="true"></button>
            <button
                *ngIf="!changeCarouselView"
                [ngStyle]="{ 'background-color': multiJackpotRightArrowBgClr, 'opacity': multiJackpotArrowOpacity }"
                class="theme-right button-right-multi"
                (click)="slideClicked(true)"
                aria-hidden="true"></button>
        </cc-ngx-carousel-3d>
        <div class="swiper-pagination-dot" *ngIf="isCarousal">
            <span
                class="swiper-pagination-bullet"
                *ngFor="let widget of widgets; let i = index"
                [ngStyle]="{ 'background-color': widget.isActive ? mJPaginationActiveClr : mJPaginationInactiveClr }"
                [ngClass]="{ 'swiper-pagination-bullet-active': widget.isActive }"
                (click)="pointClicked(i)"></span>
        </div>
    </div>
    <div class="jackpot-widget-content casino-lobby-width-gametile" [ngClass]="{ 'no-Cta': !(cta && cta.text), 'casino-uk-header': isUkHeading }">
        <div class="subcategory-start" *ngIf="games.length > 0" (window:resize)="onResize()" [ngClass]="{ 'jackpot-margin': isTouch }">
            <div class="multi-jackpot-seeall" [ngClass]="{ 'multi-jackpot-seeall-mobile': isTouch }" *ngIf="changeCarouselView">
                <span
                    class="casino-seeall-text"
                    *ngIf="!isUkHeading && cta && cta.text"
                    (click)="GotoJackpots(cta.url)"
                    [ngStyle]="{ 'color': subCategoryTextColor, 'font-size': subCategorySubTitleFontSize }">
                    <div>
                        {{ cta?.text }}
                    </div>
                </span>
                <ul
                    class="casino-seeall-text ul-icon"
                    (click)="GotoJackpots(cta.url)"
                    *ngIf="isUkHeading && cta && cta.text"
                    [ngClass]="{
                        'noicon-seeall':
                            (category.sitecoreData.displayCategoryIcon && category.sitecoreData.attributes.icon) || category.sitecoreData.headerTitle
                    }"
                    [ngStyle]="{ color: subCategoryTextColor }">
                    <div>
                        <li [style.font-size]="subCategorySubTitleFontSize">
                            {{ cta?.text }}
                        </li>
                    </div>
                </ul>
                <span
                    *ngIf="!isTouch && carouselData?.showArrows"
                    class="seeallarrows"
                    [ngStyle]="{ 'color': subCategoryTextColor, 'font-family': lobbyTileFontFamily }">
                    <span
                        class="arrows-left"
                        [ngClass]="themeArrowLeft"
                        [ngStyle]="{ 'pointer-events': carouselData?.disablePrev ? 'none' : 'auto' }"
                        (click)="onPrevClick()"></span>
                    <span
                        class="arrows-right"
                        [ngClass]="themeArrowRight"
                        [ngStyle]="{ 'pointer-events': carouselData?.disableNext ? 'none' : 'auto' }"
                        (click)="onNextClick()"></span>
                </span>
            </div>

            <div
                class="scrollable-games"
                [class.non-touch-scrollable-games]="!isTouch"
                [ngClass]="{ 'jackpot-games-display': games.length < 6 }"
                [style.overflow-x]="isTouch == false ? 'hidden' : 'scroll'">
                <ng-container *ngIf="showfeatureGrid">
                    <cc-feature-grid
                        #featuredGrid
                        *ngIf="games && games.length > 0"
                        [changeCarouselView]="changeCarouselView"
                        [games]="games"
                        [subcategory]="categoryObj"
                        [EnableFutureGrid]="false"
                        [isTouch]="isTouch"
                        [Type]="'scrollable-games'"
                        (carouselDataEvent)="getCarouselData($event)"
                        [ngClass]="{ 'jackpot-games-display': games.length < 6, 'casino-jackpot-justifycenter': games.length < 3 }"
                        [gameLaunchedFrom]="'JackpotWidget_' + category.engCategoryName"
                        [newFeatureGridArr]="newFeatureGridArr"></cc-feature-grid>
                </ng-container>
            </div>
        </div>
    </div>
    <div *ngIf="!changeCarouselView" class="jackpot-widget-content casino-lobby-width">
        <div class="see-all-button" *ngIf="cta && cta.text">
            <!-- <div
                class="btn btn-cta btn-primary"
                [innerHTML]="cta.text || moreInfoMessage"
                [ngClass]="{ 'epcot-btn': enableEpcotSubNavigation }"
                (click)="GotoJackpots(cta.url)"></div> -->
            <cs-gametile-cta
                [isLegacy]="isLegacy"
                [kind]="'primary'"
                [size]="'medium'"
                (click)="GotoJackpots(cta.url)"
                [csDecoratorClass]="'btn-cta' + (enableEpcotSubNavigation ? 'epcot-btn' : '')"
                [cta_text]="cta.text || moreInfoMessage">
            </cs-gametile-cta>
        </div>
    </div>
</div>
