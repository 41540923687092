<span *ngIf="displayHeading">
    <ul
        class="ul-icon"
        *ngIf="subCategoryData?.sitecoreData?.displayCategoryIcon && subCategoryData?.sitecoreData?.attributes.icon && !alignIconRight">
        <li aria-hidden="true" class="uk-icon right-align" [ngClass]="subCategoryData?.sitecoreData?.attributes.icon"></li>
    </ul>
    <ul class="ul-icon">
        <li>
            <h2
                class="title-uk"
                [ngStyle]="{ 'color': subCategoryTextColor, 'font-size': subCategoryTitleFontSize, 'font-family': lobbyTileFontFamily }"
                [innerHTML]="subCategoryData.categoryname"></h2>
            <p
                class="jacpot-moreinfo theme-info"
                *ngIf="
                    subCategoryData?.sitecoreData?.showJackpotMoreInfo &&
                    subCategoryData?.sitecoreData?.jackpotInfo &&
                    jackpotMoreInfoEnable &&
                    subCategoryData?.sitecoreData?.jackpotMoreInfoCloseCtaText
                "
                (click)="openDialog($event)"></p>
            <cc-sign-posting *ngIf="isSignPosting" [category]="subCategory"></cc-sign-posting>
            <p
                class="subtitle-uk"
                [ngClass]="{ 'icon-enable': subCategoryData?.sitecoreData?.displayCategoryIcon, 'see-all-url': seeAllGamesCount > 0 }"
                [ngStyle]="{ 'color': subCategoryTextColor, 'font-size': subCategorySubTitleFontSize, 'font-family': lobbyTileFontFamily }"
                *ngIf="subCategoryData && subCategoryData?.subTitle && recentAndGyml === 'NotRecentAndGyml'">
                {{ subCategoryData.subTitle }}
            </p>
        </li>
    </ul>
    <ul
        class="ul-icon"
        *ngIf="subCategoryData?.sitecoreData?.displayCategoryIcon && subCategoryData?.sitecoreData?.attributes.icon && alignIconRight">
        <li aria-hidden="true" class="uk-icon left-align" [ngClass]="subCategoryData?.sitecoreData?.attributes.icon"></li>
    </ul>
</span>
