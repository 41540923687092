import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

import { CasinoCoreClientConfigModule } from './casinoCore-client-config.module';

/**
 * @stable
 */
@LazyClientConfig({ key: 'csPromotionsBannerConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: CasinoCoreClientConfigModule,
    deps: [LazyClientConfigService],
    useFactory: promotionsBannerClientConfigFactory,
})
export class PromotionsBannerClientConfig {
    isEnablePromotionsBanner: boolean;
    showPromotionsCount: boolean;
}

export function promotionsBannerClientConfigFactory(service: LazyClientConfigService) {
    return service.get(PromotionsBannerClientConfig);
}
