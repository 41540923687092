import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, DestroyRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DeviceService, DslService, MessageQueueService, TrackingService, UserService } from '@frontend/vanilla/core';
import { DslPipe } from '@frontend/vanilla/shared/browser';
import { cloneDeep } from 'lodash-es';
import { Subscription } from 'rxjs';

import { CasinoLobbyService } from '../casino-lobby-manager/casino-lobby.service';
import { ConfigProviderService } from '../config-provider/config-provider.service';
import { GlobalTranslateComponent } from '../directives/globaltranslate/globaltranslate.component';
import { HighlightSearch } from '../directives/search-highlight-filter/search-highlight.filter';
import { PlatformApiService } from '../platform-api/platform-api.service';
import { ApiPathsHelperService } from '../shared/api-paths-helper-service';
import { CasinoManager } from '../shared/casino-manager.service';
import { CategoryManager } from '../shared/category-manager.service';
import { FastLoadingGamesService } from '../shared/fast-loading-games.service';
import { FavouriteService } from '../shared/favourite.service';
import { GameDataManager } from '../shared/game-data-manager.service';
import { CasinoCoreGameLaunchFactoryService } from '../shared/gamelaunchfactory.service';
import { GlobalSearchHelperService } from '../shared/globalsearch-helper.service';
import { GameLobbyModel } from '../shared/models/game-lobby.model';
import { GameLaunch } from '../shared/models/gamelaunch.model';
import { UrlUtilityService } from '../utilities/url-utility.service';

@Component({
    selector: 'cc-search-desktop',
    templateUrl: 'search-desktop.component.html',
    standalone: true,
    imports: [NgIf, NgClass, ReactiveFormsModule, FormsModule, GlobalTranslateComponent, NgFor, AsyncPipe, HighlightSearch, DslPipe],
})
export class SearchDesktopComponent implements OnInit, OnChanges, OnDestroy {
    @Input() isTouch: boolean;
    @Input() placeholder: string;
    @Input() lobbyType: string;
    @Input() categoryRoute: string;
    @Output() embedGameClicked = new EventEmitter<any>();

    readonly defaultSrc = this.urlUtilityService.getGrayImageUrl();
    idSuffix: string;
    model = {
        query: '',
        results: <any>[],
    };
    index: number = 1;
    activeIndex: number = 0;
    keypressed: string = 'default';
    isMsgVisible: boolean = false;
    readonly innerWidth = this.deviceService.windowWidth;
    GYMLGames: any = [];
    gymlCategory: any;
    displayValue: boolean;
    showGymlSearch: boolean;
    showGlobalSearch: boolean = false;
    wasInside: boolean;
    gymlText: string;
    SearchCloseIcon: boolean;
    recommendationGames: any = [];
    recommendationGamesList: any = [];
    combineData: any = [];
    showDesktopSearch: boolean = true;
    showGSInDesktopSearch: boolean = true;
    lobbyLoadedObs: Subscription;
    gameLaunchService: any;
    favGamesSubscribe: Subscription;
    constructor(
        private gameDataManager: GameDataManager,
        private fastLoadingGamesService: FastLoadingGamesService,
        private categoryManager: CategoryManager,
        private user: UserService,
        private messageQueue: MessageQueueService,
        private casinoManager: CasinoManager,
        private tracking: TrackingService,
        private api: PlatformApiService,
        private deviceService: DeviceService,
        private configProviderService: ConfigProviderService,
        private casinoLobbyService: CasinoLobbyService,
        private gameLaunchFactoryService: CasinoCoreGameLaunchFactoryService,
        private apiPathsHelperService: ApiPathsHelperService,
        private dslService: DslService,
        private gshService: GlobalSearchHelperService,
        private favouriteService: FavouriteService,
        private urlUtilityService: UrlUtilityService,
        private destroyRef: DestroyRef,
    ) {
        this.displayValue = true;
    }

    ngOnInit() {
        const features = this.configProviderService.provideFeaturesConfig();
        this.showGSInDesktopSearch = features.enableGSInRegularSearch;
        //Initializing globalSearch in RegularSearch
        if (this.showGSInDesktopSearch) {
            this.gshService.contextAvailable.subscribe((context: any) => {
                if (context?.appContext.isEnabled) {
                    this.showDesktopSearch = true;
                }
            });

            this.gshService.presentationBootstraped.subscribe(() => {
                this.showDesktopSearch = true;
            });

            this.gshService.registerEventHandlers();
            this.gshService.fetchContext();
        }
        this.gameLaunchService = this.gameLaunchFactoryService.getGameLaunchService();
        const Global = this.configProviderService.provideGlobalConfig();
        const casinoConfiguration = this.configProviderService.provideCasinoConfig();
        this.showGlobalSearch = features.showGlobalSearch;
        this.showDesktopSearch = features.showDesktopSearch;
        if (this.placeholder === 'embedgame') {
            if (!this.showGSInDesktopSearch) {
                this.showDesktopSearch = true;
            }
        }
        this.SearchCloseIcon = false;
        this.setIdSuffix();
        this.gymlText = Global.messages['SearchGamesYouMightLike']
            ? Global.messages['SearchGamesYouMightLike']
            : Global.messages['GamesYouMightLike'];
        if (this.enableEnhancedSearch()) {
            this.setGYML();
            this.categoryManager.categoryLookUpObservable.subscribe((response) => {
                if (response && response.size > 0) {
                    this.gymlCategory = cloneDeep(response.get('LMC_GYML'));
                    //    this.gymlCategory = {...response.get('LMC_GYML')};
                    if (
                        this.gymlCategory &&
                        this.gymlCategory.categoryInfo &&
                        this.gymlCategory.categoryInfo.gamelist &&
                        this.gymlCategory.categoryInfo.gamelist.length > 0
                    ) {
                        this.gymlCategory.gamesModel = this.gymlCategory.categoryInfo.gamelist.slice(0, casinoConfiguration.gymlLimitForSearch);
                        const gymlGameIds: string[] = [];
                        this.gymlCategory.gamesModel.forEach((gameModel: any) => {
                            gymlGameIds.push(gameModel.game);
                        });
                        const gamesModel: any = this.gameDataManager.setDefaultGameIcons(gymlGameIds);
                        this.getGyMLGames(gamesModel);
                    }
                }
            });
            this.casinoManager.lobbyObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((lobbyModel: any) => {
                if (lobbyModel) {
                    this.displayValue = true;
                }
            });

            this.gameDataManager.gameLookUpObservable.subscribe(() => {
                this.gymlCategory = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
                if (
                    this.gymlCategory &&
                    this.gymlCategory.categoryInfo &&
                    this.gymlCategory.categoryInfo.gamelist &&
                    this.gymlCategory.categoryInfo.gamelist.length > 0
                ) {
                    this.gymlCategory.gamesModel = this.gymlCategory.categoryInfo.gamelist.slice(0, casinoConfiguration.gymlLimitForSearch);
                    const gymlGameIds: string[] = [];
                    this.gymlCategory.gamesModel.forEach((gameModel: any) => {
                        gymlGameIds.push(gameModel.game);
                    });
                    const gamesModel: any = this.gameDataManager.setDefaultGameIcons(gymlGameIds);
                    this.getGyMLGames(gamesModel);
                }
            });
            this.fastLoadingGamesService.fastLoadingGamesFeedObservable.subscribe((fastLoadingGames: any) => {
                this.fastLoadingGamesService.setFastLoadingGames(this.GYMLGames, fastLoadingGames);
            });
            this.lobbyLoadedObs = this.casinoLobbyService.lobbyLoadedObservable.subscribe((response) => {
                if (response) {
                    this.gymlCategory = cloneDeep(this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML'));
                    // this.gymlCategory = {...this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML')};
                    if (
                        this.gymlCategory &&
                        this.gymlCategory.categoryInfo &&
                        this.gymlCategory.categoryInfo.gamelist &&
                        this.gymlCategory.categoryInfo.gamelist.length > 0
                    ) {
                        this.gymlCategory.gamesModel = this.gymlCategory.categoryInfo.gamelist.slice(0, casinoConfiguration.gymlLimitForSearch);
                        const gymlGameIds: string[] = [];
                        this.gymlCategory.gamesModel.forEach((gameModel: any) => {
                            gymlGameIds.push(gameModel.game);
                        });
                        const gamesModel: any = this.gameDataManager.setDefaultGameIcons(gymlGameIds);
                        this.getGyMLGames(gamesModel);
                    }
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.categoryRoute?.currentValue !== changes?.categoryRoute?.previousValue) {
            this.model.query = '';
            this.model.results = [];
            this.SearchCloseIcon = false;
            this.showGymlSearch = false;
            this.displayValue = true;
        }
        this.setIdSuffix();
    }

    displaySearchCloseIcon() {
        if (this.model.query.length == 0) {
            this.SearchCloseIcon = false;
        }
    }

    closeSearchIcon() {
        this.model.query = '';
        this.displaySearchCloseIcon();
    }

    setGYML() {
        const casinoConfiguration = this.configProviderService.provideCasinoConfig();
        this.gymlCategory = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
        if (
            this.gymlCategory &&
            this.gymlCategory.categoryInfo &&
            this.gymlCategory.categoryInfo.gamelist &&
            this.gymlCategory.categoryInfo.gamelist.length > 0
        ) {
            this.gymlCategory.categoryInfo.gamesModel = this.gymlCategory.categoryInfo.gamelist.slice(0, casinoConfiguration.gymlLimitForSearch);
            const gymlGameIds: string[] = [];
            this.gymlCategory.categoryInfo.gamesModel.forEach((gameModel: any) => {
                gymlGameIds.push(gameModel.game);
            });
            const gamesModel: any = this.gameDataManager.setDefaultGameIcons(gymlGameIds);
            this.getGyMLGames(gamesModel);
        }
    }

    setIdSuffix(): void {
        if (this.placeholder === 'navigation') {
            this.idSuffix = 'nav';
        } else {
            this.idSuffix = '';
        }
    }

    onGameIconError(game: any): void {
        game.icon.src = this.defaultSrc;
    }

    getGyMLGames(gamesModelList: any[]) {
        this.GYMLGames = [];
        for (let i = 0; i < gamesModelList.length; i++) {
            const gameModelItem: any = this.casinoLobbyService.getGameInfo(gamesModelList[i].game);
            if (gameModelItem) {
                gameModelItem.icon = '1';
                const gamelobbyModel: GameLobbyModel = this.gameDataManager.setGameLobbyModel(gameModelItem, this.isTouch);
                this.GYMLGames.push(gamelobbyModel);
            }
        }
        this.GYMLGames = this.checkGameIconTheming(this.GYMLGames);
    }

    /*
    Author : Naresh Tatikonda
    Purpose : preparing recommendation games object from recommendation feed string list
    */
    getRecomendationGames(gamesModelList: string[]) {
        this.recommendationGamesList = [];
        for (let i = 0; i < gamesModelList.length; i++) {
            const lmtGameData: any = this.casinoLobbyService.getGameInfo(gamesModelList[i]);
            //TODO: check against disabled games
            if (lmtGameData) {
                const gamelobbyModel: GameLobbyModel = this.gameDataManager.setGameLobbyModel(lmtGameData, this.isTouch);
                if (lmtGameData) {
                    gamelobbyModel.title = lmtGameData.name;
                }
                this.recommendationGamesList.push(gamelobbyModel);
            }
        }
    }

    /*
   Author : Naresh Tatikonda
   Purpose : preparing  recommendation games array based on conditions
   */
    getGamesYouMightLikeOnLMT() {
        /*
        condution checks,when recommendationsgames length <6 ,then merging recommendation games and gamesyoumightlike games object
        */
        if (this.recommendationGames.length < 6 || this.recommendationGames.length == 0) {
            //setting gamesyoumight like games to fastloading games
            this.fastLoadingGamesService.fastLoadingGamesFeedObservable.subscribe((fastLoadingGames: any) => {
                this.fastLoadingGamesService.setFastLoadingGames(this.GYMLGames, fastLoadingGames);
            });
            this.recommendationGamesList = this.recommendationGames.length > 0 ? this.getRecomendationGames(this.recommendationGames) : [];
            this.combineData = this.recommendationGamesList.concat(this.GYMLGames);
            //filter for remove duplicate objects from array object
            this.GYMLGames = Array.from(this.combineData.reduce((m: any, t: any) => m.set(t.gameId, t), new Map()).values());
        } else {
            //here recommendation games count >=6
            this.GYMLGames = this.getRecomendationGames(this.recommendationGames);
        }
        this.GYMLGames = this.checkGameIconTheming(this.GYMLGames);
    }

    recommendationFeed() {
        //adding recommendation feed integration (Naresh Tatikonda)
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        const recommendationUrl = this.apiPathsHelperService.transformApi(apiPathsConfig.gameData['gameRecommendations']);
        this.api.get(recommendationUrl).subscribe((response: any) => {
            if (response) {
                //getting response from recommendation feed
                this.recommendationGames = response.recommendationgames;
                //adding by Naresh Tatikonda
                this.getGamesYouMightLikeOnLMT();
            }
        });
    }

    launchGame(game: any, isgYml: boolean, gamePos: any) {
        this.messageQueue.clear();
        const nonCriticalConfig: any = this.configProviderService.provideNonCriticalConfig();
        if (this.user.isAuthenticated && nonCriticalConfig.enableRecommendationGames && this.placeholder === 'embedgame') {
            this.recommendationFeed();
        }

        game.iconType = 1; // for advance game data model
        if (this.placeholder === 'navigation') {
            if (isgYml) {
                const gameModel = new GameLaunch();
                gameModel.game = game;
                gameModel.isTouch = this.isTouch;
                gameModel.categoryType = 'Search';
                gameModel.from = 'q';
                gameModel.replaceRouteInHistory = false;
                gameModel.isDemoLaunch = false;
                gameModel.skipUnfinGame = false;
                gameModel.gameLaunchedFrom = 'Search_GYML';
                gameModel.gameSection = 'Search_GYML';
                gameModel.gameContainer = 'Search';
                gameModel.game.lobbyType = this.lobbyType;
                gameModel.gamePosition = gamePos;
                gameModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
                this.gameLaunchService.launchGame(gameModel);
            } else {
                const gameModel = new GameLaunch();
                gameModel.game = game;
                gameModel.isTouch = this.isTouch;
                gameModel.categoryType = 'Search';
                gameModel.from = 'q';
                gameModel.replaceRouteInHistory = false;
                gameModel.isDemoLaunch = false;
                gameModel.skipUnfinGame = false;
                gameModel.gameLaunchedFrom = 'Search';
                gameModel.gameSection = 'Search';
                gameModel.gameContainer = 'Search';
                gameModel.game.lobbyType = this.lobbyType;
                gameModel.gamePosition = gamePos;
                gameModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
                this.gameLaunchService.launchGame(gameModel);
            }
        } else if (this.placeholder === 'embedgame') {
            game.GamePosition = gamePos;
            game.lobbyType = this.lobbyType;
            this.favGamesSubscribe = this.favouriteService.favouriteGamesFeedObservable.subscribe((favouriteGameIds: any) => {
                if (favouriteGameIds) {
                    game.isFavourite = favouriteGameIds.indexOf(game.gameId) > -1;
                }
            });
            this.embedGameClicked.emit(game);
        }
        this.model.query = '';
        this.model.results = [];
        this.displayValue = true;
        this.showGymlSearch = false;
        this.SearchCloseIcon = false;
    }

    trackInput() {
        this.tracking.triggerEvent('GameSearch', {
            'eventAction': this.placeholder == 'embedgame' ? 'EGV_Search' : 'Search',
            'page.search': this.model.query,
        });
    }

    queryChanged(event: any) {
        this.displayValue = false;
        this.showGymlSearch = false;

        if (this.enableEnhancedSearch() && this.model.query.length === 1 && this.placeholder === 'navigation') {
        }
        if (this.model.query.length !== 0) {
            this.displayValue = true;
            this.SearchCloseIcon = true;
        } else {
            this.SearchCloseIcon = false;
            if (this.enableEnhancedSearch() && this.placeholder === 'navigation') {
                this.displayValue = false;
                if (this.user.isAuthenticated) {
                    this.showGymlSearch = true;
                }
            }
        }

        if (this.model.query.length < 1) {
            this.model.results = [];
            this.isMsgVisible = false;
            if (this.placeholder === 'embedgame') {
                this.displayValue = true;
            }
            if (!this.user.isAuthenticated) {
                this.displayValue = true;
            }
            if (this.enableEnhancedSearch() && this.model.query === '' && this.placeholder === 'navigation') {
            }
            return;
        } else if (event.key === 'ArrowUp') {
            if (this.index === 1 || this.index === 0) {
                this.activeIndex = 0;
                this.index = 1;
            } else {
                if (this.keypressed === 'ArrowDown') {
                    this.activeIndex = this.activeIndex - 1;
                    this.index = this.index - 2;
                } else {
                    this.activeIndex = this.index - 1;
                }
            }
            this.index--;
            let list, index;
            list = document.querySelectorAll('.search-non-touch li');
            for (index = 0; index < list.length; ++index) {
                list[index].classList.remove('active');
            }
            list[this.activeIndex].classList.add('active');
            if (this.activeIndex > 5) {
                const container = document.getElementsByClassName('search-non-touch');
                container[0].scrollTop = container[0].scrollTop - 65;
            }
            this.keypressed = 'ArrowUp';
        } else if (event.key === 'ArrowDown') {
            if (this.index === 1) {
                this.activeIndex = 0;
            } else {
                if (this.keypressed === 'ArrowUp') {
                    this.activeIndex = this.activeIndex + 1;
                    this.index = this.index + 2;
                } else {
                    this.activeIndex = this.index - 1;
                }
            }
            this.index++;
            let index;
            const list = document.querySelectorAll('.search-non-touch li');
            for (index = 0; index < list.length; ++index) {
                list[index].classList.remove('active');
            }
            list[this.activeIndex].classList.add('active');
            if (this.activeIndex > 5) {
                const container = document.getElementsByClassName('search-non-touch');
                container[0].scrollTop = container[0].scrollTop + 65;
            }
            this.keypressed = 'ArrowDown';
        } else if (event.key === 'Enter') {
            this.index = 1;
            if (this.model.results[this.activeIndex] && this.model.results[this.activeIndex].condition) {
                this.dslService.evaluateExpression(this.model.results[this.activeIndex].condition).subscribe((result) => {
                    if (result) {
                        this.launchGame(this.model.results[this.activeIndex], false, 0);
                    }
                });
            } else {
                this.launchGame(this.model.results[this.activeIndex], false, 0);
            }
        } else if (event.key === 'Backspace') {
            this.index = 1;
            this.displaySearchResults();
            if (this.placeholder === 'embedgame') {
                this.displayValue = true;
            }
        } else {
            setTimeout(() => {
                if (this.model.query.length < 1) {
                    this.model.results = [];
                    return;
                }
                this.displaySearchResults();
            }, 500);
            this.isMsgVisible = false;
        }
    }

    displaySearchResults() {
        this.model.results = this.casinoLobbyService.searchGames(this.model.query);
        this.model.results = this.checkGameIconTheming(this.model.results);
        this.isMsgVisible = this.model.results.length > 0 ? false : true;
    }

    checkGameIconTheming(gamesList: any) {
        const features = this.configProviderService.provideFeaturesConfig();
        if (features.enableGameIconTheming) {
            gamesList.map((ele: any) => {
                ele.icon.src = this.casinoLobbyService.appendTheme(ele.icon.src, ele.gameId);
            });
        }
        return gamesList;
    }

    hideDisplayGYML(): boolean {
        if (this.placeholder === 'embedgame') {
            return true;
        }
        if (!this.GYMLGames) {
            return true;
        }
        if (this.GYMLGames.length <= 0) {
            return true;
        }
        return this.displayValue;
    }

    enableEnhancedSearch(): boolean {
        const features = this.configProviderService.provideFeaturesConfig();
        return features.enableEnhancedSearch && this.GYMLGames && this.GYMLGames.length >= 0;
    }

    docClick(event: any) {
        if (
            event.target.className != 'casino-search-input ng-untouched ng-pristine ng-valid' &&
            event.target.className != 'casino-search-input ng-pristine ng-valid ng-touched' &&
            event.target.className != 'casino-search-input ng-valid ng-touched ng-dirty' &&
            event.target.className != 'casino-search-input ng-valid ng-dirty ng-touched'
        ) {
            this.model.query = '';
            this.model.results = [];
        }
    }

    @HostListener('click', ['$event'])
    clickInside(event: any) {
        const casinoConfiguration = this.configProviderService.provideCasinoConfig();
        const gymlCategory = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
        if (gymlCategory && gymlCategory.categoryInfo) {
            this.gymlCategory = gymlCategory;
            this.getGyMLGames(this.gymlCategory.categoryInfo.gamelist.slice(0, casinoConfiguration.gymlLimitForSearch));
        } else {
            this.gymlCategory = null;
            this.getGyMLGames([]);
        }
        this.wasInside = true;

        if (
            event &&
            event.target &&
            (event.target.id === 'gyml-desktop-id' || event.target.id === 'gym-desktop-heading-id' || event.target.id === 'lobby-games-id')
        ) {
            return;
        }
        if (event && event.target && event.target.id === 'search-text' && (!this.model.query || this.model.query === '')) {
            if (this.enableEnhancedSearch() && this.user.isAuthenticated && this.placeholder === 'navigation') {
                this.displayValue = false;

                if (this.user.isAuthenticated) {
                    this.showGymlSearch = true;
                }
            }
        } else {
            this.displayValue = true;
            this.showGymlSearch = false;
        }
    }

    @HostListener('document:click')
    clickout() {
        const features = this.configProviderService.provideFeaturesConfig();
        if (!this.wasInside) {
            if (features.enableEnhancedSearch && this.GYMLGames && this.GYMLGames.length > 0) {
            }
            this.model.query = '';
            this.model.results = [];
            this.displayValue = true;
            this.showGymlSearch = false;
            this.SearchCloseIcon = false;
        }
        this.wasInside = false;
    }

    clickGymlHeading(event: any) {
        event.stopImmediatePropagation();
    }

    clickGymlGames(event: any) {
        event.stopImmediatePropagation();
    }

    //For launching globalSearch in regularSearch
    showGlobalSearchInRegularSearch() {
        if (this.showDesktopSearch && this.showGSInDesktopSearch && this.placeholder === 'navigation') {
            this.gshService.launchGlobalSearch();
        } else {
            this.showGSInDesktopSearch = false;
        }
    }

    ngOnDestroy() {
        if (this.lobbyLoadedObs) {
            this.lobbyLoadedObs.unsubscribe();
        }
        if (this.favGamesSubscribe)
            // {this.favGamesSubscribe.unsubscribe(); }
            this.gshService.deregisterEvents();
    }
}
