import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csPromoOfferWidget', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: promoOfferWidgetConfigFactory,
})
export class PromoOfferWidgetConfig {
    promoOfferWidgetPosition: any;
    products: any;
    enablePromoOfferWidget: boolean;
    headerColor: string;
    viewallColor: string;
    subTitleFontSize: string;
    titleFontSize: string;
    fontFamily: string;
    titleFontWeight: number;
    viewallSize: string;
    isUkHeading: string;
    viewallTextTransform: string;
    viewallFontFamily: string;
    viewallSizeMobile: string;
    subtitleMobileFontSize: string;
    titleMobileFontSize: string;
    subtitleFontFamily: string;
}

export function promoOfferWidgetConfigFactory(service: LazyClientConfigService) {
    return service.get(PromoOfferWidgetConfig);
}
