import { Injectable } from '@angular/core';

import { Logger } from '@frontend/vanilla/core';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';
import { CacheManagerService } from './cache-manager.service';
import { CasinoManager } from './casino-manager.service';
import { InvokerProductService } from './invoker-product.service';

@Injectable({
    providedIn: 'root',
})
export class UnfinishedGamesService {
    unfinishedGames: any = [];
    unfinishedGamesFromAllLobby: any = [];
    private unfinishedGamesSubject = new BehaviorSubject<any>(null);
    unfinishedFeedObservable: Observable<any> = this.unfinishedGamesSubject.asObservable();
    private unfinishedGamesFromAllLobbySubject = new BehaviorSubject<any>(null);
    unfinishedFeedFromAllLobbyObservable: Observable<any> = this.unfinishedGamesFromAllLobbySubject.asObservable();

    constructor(
        private api: PlatformApiService,
        private readonly configProviderService: ConfigProviderService,
        private casinoManager: CasinoManager,
        private cacheManager: CacheManagerService,
        private invokerProductService: InvokerProductService,
        private logger: Logger,
    ) {}

    setUnfinishedGames() {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        const apiurl: string = `${apiPathsConfig.gamesServices['unfinishedGames']}?invokerProduct=${this.invokerProductService.getInvokerProduct()}`;
        this.api.get(apiurl, null, { withCredentials: true }).subscribe(
            (response: any) => {
                this.unfinishedGames = [];
                if (response) {
                    this.unfinishedGames = response?.games;
                    this.unfinishedGamesFromAllLobby = cloneDeep(this.unfinishedGames);
                    // this.unfinishedGamesFromAllLobby = [...this.unfinishedGames];
                    if (featuresConfig?.rpGamesFilterInLiveCasino && this.casinoManager.getCurrentLobby()?.toLowerCase() == 'livecasino')
                        this.filterLiveCasinoGames(this.unfinishedGames);
                    if (featuresConfig?.rpWidgetGamesFilterInLiveCasino) this.unfinishedGamesFromAllLobby = this.unfinishedGames;
                    this.unfinishedGamesSubject.next(this.unfinishedGames);
                    this.unfinishedGamesFromAllLobbySubject.next(this.unfinishedGamesFromAllLobby);
                }
            },
            (error) => {
                this.logger.error('unable to retrieve the data from  unfinishedGames api ' + error);
            },
        );
    }

    filterLiveCasinoGames(unfinishedGames: any) {
        const categoryCoreLookUp: any = this.cacheManager.getAllCategoriesFromLocalCache('LiveCasino', this.casinoManager.getInvokerProduct());
        this.unfinishedGames =
            categoryCoreLookUp?.lsGameMetaDataMap?.map((x: any) => x && x[0])?.filter((y: any) => unfinishedGames?.includes(y)) || [];
    }

    getUnfinishedGamesFromAllLobby() {
        return this.unfinishedGamesFromAllLobby;
    }

    getUnfinishedGames() {
        return this.unfinishedGames;
    }

    checkForUnfinishedGame(game: any) {
        return this.unfinishedGamesFromAllLobby && this.unfinishedGamesFromAllLobby.indexOf(game.gameId) > -1;
    }
}
