<div class="casino-gifsvg-img-wrapper">
    <div class="mp4video" *ngIf="type == 'mp4'">
        <div class="videocontainer" *ngIf="url != ''" [style.height.px]="teaserHeight">
            <div id="outofviewport">
                <video
                    id="teaserVideoPlayer"
                    #teaserVideoPlayer
                    ccIntersectionObserver
                    playsinline
                    [intersectionDebounce]="0"
                    [src]="url"
                    [poster]="imageThumbnailSrc"
                    [ngClass]="videoBackgroundClass"
                    [style.object-position]="teaserVideoPosition"
                    (onabort)="videoError()"
                    (onemptied)="videoError()"
                    (onerror)="videoError()"
                    (onstalled)="videoError()"
                    (onsuspend)="videoError()"
                    (ended)="videoEnded()"
                    (loadedmetadata)="videoLoaded()"
                    (visibilityChange)="onVisibilityChanged($event)"
                    (timeupdate)="timeUpdate()"></video>
                <button
                    *ngIf="isVideoLoaded"
                    [ngStyle]="{ 'background-color': TeaserVideoIconBgClr, 'color': TeaserVideoIconClr }"
                    class="btn btn-primary sound-btn"
                    [ngClass]="TeaserVideoVolumbtn"
                    (click)="toggleMute($event)"></button>
            </div>
        </div>
    </div>
    <div class="mp4video" *ngIf="type == 'cms'">
        <div class="videocontainer" *ngIf="url && url.length" [style.height.px]="teaserHeight">
            <div
                id="outofviewport"
                [ngClass]="{
                    'mercury-450-teaser': isOptMiniTeasersEnabled && teaserHeight == 450,
                    'mercury-350-teaser': isOptMiniTeasersEnabled && teaserHeight == 350,
                    'desktop-hydrogen-lithium-450-teaser': !isOptMiniTeasersEnabled && teaserHeight == 450
                }">
                <cloudflare-stream
                    id="teaserCMSVideoPlayer"
                    #teaserCMSVideoPlayer
                    ccIntersectionObserver
                    [intersectionDebounce]="0"
                    [controls]="false"
                    [src]="url"
                    [autoplay]="!isVideoLoaded"
                    [ngClass]="videoBackgroundClass"
                    [style.object-position]="teaserVideoPosition"
                    (abort)="videoError()"
                    (error)="videoError()"
                    (stalled)="videoError()"
                    (suspend)="videoError()"
                    (ended)="videoEnded()"
                    (loadedmetadata)="videoLoaded()"
                    (visibilityChange)="onVisibilityChanged($event)"
                    (timeupdate)="timeUpdate()">
                </cloudflare-stream>
                <button
                    *ngIf="isVideoLoaded"
                    [ngStyle]="{ 'background-color': TeaserVideoIconBgClr, 'color': TeaserVideoIconClr }"
                    class="btn btn-primary sound-btn"
                    [ngClass]="TeaserVideoVolumbtn"
                    (click)="toggleMute($event)"></button>
            </div>
        </div>
    </div>
    <div class="gifsvg_img" *ngIf="type == 'gif' || type == 'svg'">
        <cc-gif-tag
            [subcategory]="subcategory"
            [type]="type"
            [url]="url"
            [launchUrl]=""
            [imageThumbnail]="imageThumbnail"
            [lobbyType]="lobbyType"
            [gameContainer]="gameContainer">
        </cc-gif-tag>
    </div>
</div>
