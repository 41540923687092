import { Observable, Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

export enum IntersectionStatus {
    Visible = 'Visible',
    Pending = 'Pending',
    NotVisible = 'NotVisible',
}

export const fromIntersectionObserver = (element: HTMLElement, config: IntersectionObserverInit, debounce = 0) =>
    new Observable<any>((subscriber) => {
        const subject$ = new Subject<{
            entry: IntersectionObserverEntry;
            observer: IntersectionObserver;
        }>();

        const intersectionObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (isIntersecting(entry)) {
                    subject$.next({ entry, observer });
                } else {
                    const videoElementStatus = {
                        status: IntersectionStatus.NotVisible,
                    };
                    subscriber.next(videoElementStatus);
                }
            });
        }, config);

        subject$.pipe(debounceTime(debounce), filter(Boolean)).subscribe(async (result: any) => {
            const isEntryVisible = await isVisible(result.entry.target as HTMLElement);

            if (isEntryVisible) {
                const videoElementStatus = {
                    status: IntersectionStatus.Visible,
                    src: getSrc(result.entry.target),
                };
                subscriber.next(videoElementStatus);
            }
        });

        intersectionObserver.observe(element);

        return {
            unsubscribe() {
                intersectionObserver.disconnect();
                subject$.unsubscribe();
            },
        };
    });

function getSrc(target: any) {
    return target.currentSrc ?? target.childNodes[0]?.attributes?.src?.nodeValue;
}

async function isVisible(element: HTMLElement) {
    return new Promise((resolve) => {
        const observer = new IntersectionObserver(([entry]) => {
            resolve(entry.isIntersecting);
        });

        observer.observe(element);
    });
}

function isIntersecting(entry: IntersectionObserverEntry) {
    return entry.isIntersecting && entry.intersectionRatio > 0;
}
