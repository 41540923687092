import { Injectable } from '@angular/core';

import { LoginDialogService, NavigationService, UserService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { CasinoCoreGameLaunchFactoryService } from './gamelaunchfactory.service';

@Injectable({
    providedIn: 'root',
})
export class OpenLoginDialog {
    casinoCoreGamelaunchService: any;
    constructor(
        private loginDialogService: LoginDialogService,
        private navigation: NavigationService,
        private user: UserService,
        private configProviderService: ConfigProviderService,
        private gameLaunchFactoryService: CasinoCoreGameLaunchFactoryService,
    ) {}
    open(options?: any) {
        const preLoginConfig = this.configProviderService.providePreLoginConfig();
        if (preLoginConfig.redirectForLogin) {
            const redirectUrl =
                preLoginConfig?.nemIDHost +
                preLoginConfig?.nemIdPathSegment.replace('__encodeURL_location_href__', encodeURIComponent(window?.location?.href));
            if (redirectUrl) {
                this.navigation.goTo(redirectUrl);
            }
            return;
        }
        if (options) {
            this.loginDialogService.whenReady.subscribe(() => {
                const loginDialogRef = this.loginDialogService.open(options);
                const afterclosesubcription: Subscription = loginDialogRef.afterClosed().subscribe(() => {
                    if (afterclosesubcription) {
                        this.casinoCoreGamelaunchService = this.gameLaunchFactoryService.getGameLaunchService();
                        this.casinoCoreGamelaunchService?.stopAudio();
                        afterclosesubcription.unsubscribe();
                    }
                    if (!this.user.isAuthenticated) {
                        sessionStorage.removeItem('CasinoCoreForceGameObj');
                        sessionStorage.removeItem('CasinoCoreScreenNameGameObj');
                        // added newly for force coinbalance and force option
                        sessionStorage.removeItem('forceCoinBalance');
                        sessionStorage.removeItem('ForceOptin');
                        if (options?.loginCloseUrl) {
                            this.navigation.goTo(options.loginCloseUrl);
                        }
                    }
                });
            });
        } else {
            this.loginDialogService.whenReady.subscribe(() => {
                this.loginDialogService.open();
            });
        }
    }
}
