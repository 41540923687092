import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csFeatures', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: featuresClientConfigFactory,
})
export class FeaturesClientConfig {
    showMD3Teasers: boolean;
    useFavouriteGames: boolean;
    useRecentGames: boolean;
    useEmbeddedTeasers: boolean;
    sendJSErrorsToServer: boolean;
    showAddToHome: boolean;
    isAnonymousLobbyEnabled: boolean;
    isPromotionPagesEnabled: boolean;
    gamesSearch: boolean;
    gameVideo: any;
    showSearchInTopNavigation: boolean;
    showHomeInTopNavigation: boolean;
    taxWithheld: boolean;
    bonusSection: boolean;
    freespinsSection: boolean;
    showGameTitle: boolean;
    goToTopArrow: boolean;
    showSeoBoxes: boolean;
    showLastLoginTime: boolean;
    showUnfinishedTooltipIcon: boolean;
    instagramHeight: string;
    disableFreeSpins: boolean;
    enableJackpotTickerTeasers: boolean;
    showHomeFixedNavigation: boolean;
    enableRefresh: boolean;
    aamsEnabled: boolean;
    quickInfo: boolean;
    useLMTFeed: boolean;
    isFullyOptimizedLobby: boolean;
    browserLocalStorageCacheDuration: any;
    browserLocalStorageCategoryDataValidityDuration: number;
    browserLocalStorageCategoryDataUpdateInterval: number;
    enableClientStorage: boolean;
    downloadAppOnGameLaunch: boolean;
    enableEnhancedSearch: boolean;
    enableHashText: boolean;
    hashText: string;
    showSeeAllGamesCount: boolean;
    hideHomeInSubNav: boolean;
    setNavBarLimitTimeout: number;
    showSmartBanner: boolean;
    hideFavouritesOnPreLogin: string;
    favouriteGamesLobbyType: any;
    enableQuickInfoLobbyType: any;
    matGridColCount: number;
    showSubNavigationAboveTeaser: boolean;
    matGridColCountForLobbyType: any;
    showSubNavigationAboveTeaserLobbyType: any;
    newJackpotRibbonStyling: boolean;
    stickersForUk: any;
    newQuickInfoStyle: any;
    showStickersInOverlay: any;
    showJackpotvalueInOverlay: any;
    enableHotIcon: any;
    isUkHeading: any;
    enableFastLoadingGames: boolean;
    enableSkeletonLoading: any;
    enableUkMouseOverStyle: boolean;
    alignIconRight: any;
    globalJackpotInterVal: any;
    isGlobalJackpotEnabled: boolean;
    isAnchorTagVisibleOnQuickInfo: boolean;
    globalSearchFixedNavMobile: boolean;
    enableGSInRegularSearch: boolean;
    extendedTopMenuStatus: any;
    enableTitleOnGameTile: any;
    playNowHoverEffect: boolean;
    showEmbeddedTeasersAboveGames: boolean;
    enableEmbeddedTeasers: boolean;
    enableAccountUpgrade: boolean;
    preLoginPlayNowText: boolean;
    extendedTopMenuToRight: boolean;
    highlightCategoryHeader: any;
    enableFixedMobileNavigation: boolean;
    enableFortifiedOnWithdrwal: boolean;
    enablePlayBingoButton: boolean;
    isVanillaHomePageEnabled: boolean;
    enableRpForPrelogin: any;
    enableSignpostingSticker: any;
    enablePreLoginRecentlyPlayed: any;
    enableFreeSpinSummary: boolean;
    isPromoHubExist: boolean;
    showGlobalSearch: boolean;
    useSingleCookieForGameIndexCountInEGV: boolean;
    delimiterForDynamicGameNames: any;
    checkQDAllowedForDepositURL: boolean;
    rpPositionLmtKey: any;
    liveGamesIFrameHeight: number;
    showDesktopSearch: boolean;
    enableSkeleton: boolean;
    enableBottomSubNavigation: boolean;
    enableHeaderforCategory: boolean;
    skipDocReferrer: boolean;
    enableOptimoveFavProduct: boolean;
    showNetflixVideo: boolean;
    enableSlotRacesForLobbyType: any;
    enableVideoPhase2: boolean;
    downloadAppFromDevice: boolean;
    enableLobbyBackgroundImage: boolean;
    enableLobbyStaticImage: boolean;
    teaserSetIndexTimeout: number;
    enableTransparentLobbyBackground: any;
    enableDefaultCategoryStaticImage: any;
    enableStaticImageNonDefault: any;
    gameIconTheming: any;
    enableGameIconTheming: any;
    enableLobbyStaticImageForMobile: boolean;
    enableLocalDateTimeFormat: boolean;
    changeCarouselView: boolean;
    dynamicSeeAllLink: any;
    rpGamesFilterInLiveCasino: boolean;
    rpWidgetGamesFilterInLiveCasino: boolean;
    enableOverlayMobileSearchTopPosition: boolean;
    navigationBackgroundColor: boolean;
    hotIconPath: any;
    enableNewQuickInfoLobbyType: any;
    showVolatilityTable: any;
    storeVendorInfoInCache: boolean;
    nonCasinoUrls: any;
    scrollOnTopTimeFrame: number;
    clickInteractionTimeout: number;
    enableTrackingEntireNavigation: boolean;
    singleGameErrorTimeout: number;
    scrollTopTimeout: number;
    enableFullTakeOverImageForAllCategories: boolean;
    fullTakeOverImageForAllCategories: any;
    gameLaunchTrackingParams: boolean;
    enableStickerStyles: boolean;
    superButtonTimeout: number;
    enableGameIconImageSuffix: boolean;
    displayEdsSmartWidget: boolean;
    activateDesignSystem: boolean;
}

export function featuresClientConfigFactory(service: LazyClientConfigService) {
    return service.get(FeaturesClientConfig);
}
