import { NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { NavigationService } from '@frontend/vanilla/core';

import { ConfigProviderService } from '../config-provider/config-provider.service';

@Component({
    selector: 'cc-confirm-message',
    templateUrl: './confirm-message.component.html',
    standalone: true,
    imports: [NgIf],
})
export class ConfirmMessageComponent implements OnInit {
    message: any;
    messagesInfo: any;
    constructor(
        private navigator: NavigationService,
        public dialogRef: MatDialogRef<ConfirmMessageComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private configProviderService: ConfigProviderService,
    ) {}

    ngOnInit() {
        this.messagesInfo = this.data;
    }

    cancelBtn(): void {
        this.dialogRef.close();
    }

    depositBtn() {
        const mcPage: any = this.configProviderService.provideMcPageConfig();
        this.navigator.goTo(mcPage.depositUrl.url);
    }
}
