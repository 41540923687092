<div id="sticky-container" class="sticky-container casino-lobby-width-gametile">
    <div
        [ngClass]="setSubCategoryStyle()"
        style.overflow-x="{{ isTouch == false ? 'hidden' : 'auto' }}"
        [class.non-touch-scrollable-games]="!isTouch && setSubCategoryStyle() == 'scrollable-games'"
        (scroll)="scrollGYML()">
        <div *ngIf="isTouch && unfinishedRPANDGYMLGames" [ngClass]="{ 'header-static-div': staticGYML }">
            <div class="base-category" [class.casino-base-category-nontouch]="!isTouch">
                <div class="casino-allGames">
                    <div class="gameContainers" *ngIf="recentCategory && !gymlCategoryRef">
                        <div>
                            <div
                                class="casino-no-games-box"
                                *ngIf="zeroRecentGames"
                                [ngClass]="{ 'casino-no-games-box-touch-1350': isTouch }"
                                [ngStyle]="{
                                    'margin-top': isTouch ? '0' : '20px',
                                    'border-color': WidgetFrameClr,
                                    'color': WidgetDefaultMsgTxtColor
                                }">
                                <span>
                                    {{ noRecentGamesIcon }}
                                </span>
                            </div>
                            <div
                                *ngFor="let game of unfinishedRPANDGYMLGames; trackBy: trackGame; let i = index"
                                class="casino-rcntly-gyml-game-touch"
                                [hidden]="!game.isRecentlyPlayed">
                                <cc-cozy-bingo-game
                                    *ngIf="game.fromCozybingo"
                                    [roomInfo]="game"
                                    [gamePosition]="i"
                                    [gameLaunchedFrom]="gameLaunchedFromLoc"
                                    [product]="'rpWidgetRP'"
                                    [lobbyType]="lobbyType"
                                    [widgetFrameClr]="WidgetBackgroundClr">
                                </cc-cozy-bingo-game>
                                <cc-game-lobby
                                    [ngClass]="game.infoIconClass"
                                    [game]="game"
                                    [gamePosition]="i"
                                    *ngIf="!game.fromCozybingo && !game.isDataNotAvailable && recentCategory"
                                    [class.casino-recentlyPromo]="game.isRecentlyPlayed"
                                    [category]="recentCategory"
                                    [widgetFrameClr]="WidgetBackgroundClr"
                                    [gameLaunchedFrom]="gameLaunchedFromLoc"
                                    [product]="'rpWidgetRP'">
                                </cc-game-lobby>
                            </div>
                        </div>
                    </div>
                    <div class="gameContainers mrgn-lft-cls" *ngIf="recentCategory && gymlCategoryRef">
                        <div>
                            <div
                                class="casino-no-games-box"
                                *ngIf="zeroRecentGames"
                                [ngClass]="{ 'casino-no-games-box-touch-1350': isTouch }"
                                [ngStyle]="{
                                    'margin-top': isTouch ? '0' : '20px',
                                    'border-color': WidgetFrameClr,
                                    'color': WidgetDefaultMsgTxtColor
                                }">
                                <span>
                                    {{ noRecentGamesIcon }}
                                </span>
                            </div>
                            <div
                                *ngFor="let game of unfinishedRPANDGYMLGames; trackBy: trackGame; let i = index"
                                class="casino-rcntly-gyml-game-touch">
                                <div
                                    [ngClass]="assignRecommendedWidgetColors(game)"
                                    [ngStyle]="{ bottom: isTouch ? '2' : '0' }"
                                    [style.borderColor]="recommendedGamesBackgroundColor">
                                    <cc-cozy-bingo-game
                                        *ngIf="game.fromCozybingo"
                                        [roomInfo]="game"
                                        [gamePosition]="i"
                                        [gameLaunchedFrom]="gameLaunchedFromLoc"
                                        [product]="'rpWidgetRP'"
                                        [lobbyType]="lobbyType"
                                        [widgetFrameClr]="WidgetBackgroundClr">
                                    </cc-cozy-bingo-game>
                                    <cc-game-lobby
                                        [ngClass]="game.infoIconClass"
                                        [class.casino-recentlyPromo]="game.isRecentlyPlayed"
                                        [game]="game"
                                        [gamePosition]="i"
                                        *ngIf="!game.fromCozybingo && !game.isDataNotAvailable && gymlCategoryRef"
                                        [category]="gymlCategoryRef"
                                        [widgetFrameClr]="WidgetBackgroundClr"
                                        [gameLaunchedFrom]="gameLaunchedFromLoc"
                                        [product]="'rpWidgetRP'">
                                    </cc-game-lobby>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isTouch && unfinishedRPANDGYMLGames && recentCategory">
            <div class="base-category" [class.casino-base-category-nontouch]="!isTouch">
                <div
                    class="casino-no-games-box"
                    *ngIf="zeroRecentGames"
                    [ngStyle]="{ 'border-color': WidgetFrameClr, 'color': WidgetDefaultMsgTxtColor }">
                    <span>
                        {{ noRecentGamesIcon }}
                    </span>
                </div>
                <div class="casino-rp-gyml-carousel-wrapper" [ngClass]="{ 'rp-gyml-zeroRecentGames': zeroRecentGames }">
                    <vn-swiper #recentGYMLCarousel [swiperOptions]="swiperOptions">
                        <div class="swiper-slide" *ngFor="let games of unfinishedRPANDGYMLGames; let i = index">
                            <div class="game item">
                                <div class="game">
                                    <div [ngClass]="assignRecommendedWidgetColors(games)" [style.borderColor]="recommendedGamesBackgroundColor">
                                        <cc-cozy-bingo-game
                                            *ngIf="games.fromCozybingo"
                                            [roomInfo]="games"
                                            [gamePosition]="i"
                                            [gameLaunchedFrom]="gameLaunchedFromLoc"
                                            [product]="'rpWidgetRP'"
                                            [lobbyType]="lobbyType"
                                            [widgetFrameClr]="WidgetBackgroundClr">
                                        </cc-cozy-bingo-game>
                                        <cc-game-lobby
                                            [ngClass]="games.infoIconClass"
                                            *ngIf="!games.fromCozybingo && !games.isDataNotAvailable && games.isRecentlyPlayed"
                                            [game]="games"
                                            [gamePosition]="i"
                                            [class.casino-recentlyPromo]="games.isRecentlyPlayed"
                                            [widgetFrameClr]="WidgetBackgroundClr"
                                            [category]="recentCategory"
                                            [gameLaunchedFrom]="gameLaunchedFromLoc"
                                            [product]="'rpWidgetRP'">
                                        </cc-game-lobby>
                                        <cc-game-lobby
                                            [ngClass]="games.infoIconClass"
                                            [class.casino-recentlyPromo]="games.isRecentlyPlayed"
                                            *ngIf="!games.fromCozybingo && !games.isDataNotAvailable && !games.isRecentlyPlayed"
                                            [game]="games"
                                            [gamePosition]="i"
                                            [category]="gymlCategoryRef"
                                            [widgetFrameClr]="WidgetBackgroundClr"
                                            [gameLaunchedFrom]="gameLaunchedFromLoc"
                                            [product]="'rpWidgetRP'">
                                        </cc-game-lobby>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vn-swiper>
                </div>
            </div>
        </div>
    </div>
</div>
