import { Injectable } from '@angular/core';

import { GoToOptions, NavigationService, Page, WindowRef } from '@frontend/vanilla/core';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { CasinoIFrameOverlayService } from './casino-iframe-overlay.service';
import { CasinoWidgetService } from './casino-widget.service';

@Injectable({
    providedIn: 'root',
})
export class NavigatorService {
    firstLoadFlag: number = 2;
    goingBack: boolean = false;
    prevUrl: string;
    returnUrl: string;
    historySupport: boolean;
    lastClicked: any;
    showSpinnerForExternalNavigation: boolean | undefined = true;
    loadingIndicator: any;
    defaultNavigateOptions: GoToOptions = {
        forceReload: false,
        appendReferrer: false,
        replace: false,
        showSpinnerForExternalNavigation: true,
    };

    constructor(
        private navigation: NavigationService,
        private configProviderService: ConfigProviderService,
        private windowRef: WindowRef,
        private m2Page: Page,
        private casinoWidgetService: CasinoWidgetService,
        private casinoIFrameOverlayService: CasinoIFrameOverlayService,
    ) {}

    detailPage(gameId: string) {
        const mcPageConfig = this.configProviderService.provideMcPageConfig();
        const window = this.windowRef.nativeWindow;
        return window.location.protocol + '://' + window.location.host + mcPageConfig.prefix + '/gamedetail/' + gameId + window.location.search;
    }
    demoPlayPage(gameId: string) {
        const mcPageConfig = this.configProviderService.provideMcPageConfig();
        const window = this.windowRef.nativeWindow;
        return window.location.protocol + '://' + window.location.host + mcPageConfig.prefix + '/demoplay/' + gameId + window.location.search;
    }

    publicPage(publicPageName: string, options: GoToOptions, portalPage: boolean) {
        let appendUrlPrefix: any = 'lang';
        const mcPageConfig = this.configProviderService.provideMcPageConfig();
        options = Object.assign({}, this.defaultNavigateOptions, options);
        let url: string;
        if (portalPage) {
            url = mcPageConfig.portalUrl + '/p/' + publicPageName;
            appendUrlPrefix = false;
        } else {
            url = 'p/' + publicPageName;
        }
        this.navigate(url, options, appendUrlPrefix);
    }

    navigate(url: string, options?: GoToOptions, appendUrlPrefix?: any, statusCode?: number, errorMessages?: any[]) {
        options = Object.assign({}, this.defaultNavigateOptions, options);
        const mcPageConfig = this.configProviderService.provideMcPageConfig();
        this.showSpinnerForExternalNavigation = this.defaultNavigateOptions.showSpinnerForExternalNavigation;
        if (url.indexOf('http') === -1) {
            if (url.charAt(0) !== '/') {
                url = '/' + url;
            }
            if (appendUrlPrefix === true) {
                url = mcPageConfig.prefix + url;
            } else if (appendUrlPrefix === 'lang') {
                url = '/' + this.m2Page.lang + url;
            }
        }
        if (this.casinoWidgetService.isCasinoWidget() || this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
            if (statusCode === 0) {
                this.casinoWidgetService.postMessageToParent('GameLaunch', { promptLogin: false, redirectUrl: url });
            } else if (statusCode === -1) {
                this.casinoWidgetService.postMessageToParent('Error', { promptLogin: false, redirectUrl: url, errorMessages: errorMessages });
            }
        } else {
            this.navigation.goTo(url, options);
        }
    }
}
