<div class="sportsOverlay-top-position">
    <cc-overlay-header *ngIf="isLobbyLoaded" [hideCloseButton]="hideCloseButton"></cc-overlay-header>
    <cc-overlay-search *ngIf="isLobbyLoaded && enableSearch" [lobbyType]="overlayLobbyType"></cc-overlay-search>
    <div id="casinocoreoverlay" *ngIf="isLobbyLoaded">
        <vn-message-panel [scope]="'casinocorelobby'"></vn-message-panel>
        <div *ngIf="!rpPositionLmtKey">
            <cc-new-game-quick-info *ngIf="isNewQuickInfoEnabled"></cc-new-game-quick-info>
            <cc-rp-gyml
                id="rpGYMLSection"
                [lobbyType]="overlayLobbyType"
                [gameContainer]="'Overlay'"
                [categoryId]="overlayCategoryRoute"></cc-rp-gyml>
        </div>
        <cc-casino-grid-on-lobby-type [lobbyType]="overlayLobbyType" [categoryRoute]="overlayCategoryRoute" [gameContainer]="'Overlay'">
        </cc-casino-grid-on-lobby-type>
        <div *ngIf="!hideBottomNav; else elseblock">
            <cc-bottom-navbar [lobbyType]="overlayLobbyType"></cc-bottom-navbar>
        </div>
        <ng-template #elseblock>
            <div class="noBottomNav"></div>
        </ng-template>
        <button (click)="goToCasinoHome()" class="btn btn-primary casino-gaming-home-button" tabindex="-1">
            {{ gamingHomeBtnText }}
        </button>
    </div>
</div>
