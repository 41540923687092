<div *ngIf="showClaimToster" class="claim-card-toster" (click)="closeCardToster()">
    <div class="message-panel">
        <div class="theme-info-i">
            <div>
                <div class="cms-container" [textContent]="ToasterText"></div>
                <div *ngIf="displayShowMore" class="show-more-link">
                    <a [href]="navigateUrl" (click)="showMore()" [textContent]="winnersFeedShowMoreLinkText"></a>
                </div>
            </div>
            <div class="theme-ex"></div>
        </div>
    </div>
</div>
