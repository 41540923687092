import { Injectable } from '@angular/core';

import { TagManagerService, TrackingService } from '@frontend/vanilla/core';
import { rxEffects } from '@rx-angular/state/effects';

import { TagManagerClientConfig } from '../../client-config';
import { WindowRef } from '../windowref.service';

@Injectable({ providedIn: 'root' })
export class TagManagerRenderer {
    private readonly rxEffects = rxEffects();
    private isGoogleTagManagerLoadInitialized: boolean = false;

    constructor(
        private tagManagerService: TagManagerService,
        private vanillaTrackingService: TrackingService,
        private tagManagerClientConfig: TagManagerClientConfig,
        private windowRef: WindowRef,
    ) {}

    renderGTMTag(): void {
        if (
            this.tagManagerClientConfig &&
            this.tagManagerClientConfig.triggerGoogleTagManagerClientInjection &&
            !this.isGoogleTagManagerLoadInitialized
        ) {
            this.isGoogleTagManagerLoadInitialized = true;

            this.windowRef.nativeWindow.setTimeout(() => {
                this.loadGTM();
            }, this.tagManagerClientConfig.googleTagManagerInjectionDelay);
        }
    }

    private loadGTM(): void {
        // Call function if attached on window to handle fixes via sitecore.
        this.windowRef.nativeWindow.CasinoPreGTMLoad &&
            this.windowRef.nativeWindow.CasinoPreGTMLoad.Execute &&
            this.windowRef.nativeWindow.CasinoPreGTMLoad.Execute();

        // Load GTM Script
        this.rxEffects.register(this.tagManagerService.whenReady, () => {
            this.tagManagerService.load('GoogleTagManagerRenderer');
        });
        // Fire App Loaded Event for use as a trigger in GTM
        this.vanillaTrackingService.triggerEvent('Vanilla_Portal_App_Loaded', {
            'component.CategoryEvent': '',
        });
    }
}
