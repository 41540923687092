<cc-casino-core-lazy-placeholder
    [inputData]="{
        isDefaultCategory: '',
        identifierClass: '',
        noslide: '',
        teaserLocation: TopTeaser,
        teaserCategory: '',
        teasersSource: headerTeasers,
        mode: 'teaser',
        fromNative: true,
        isLand: isLandscape
    }"
    [componentName]="''"></cc-casino-core-lazy-placeholder>
