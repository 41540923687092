import { AfterViewInit, Directive, DoCheck, ElementRef, Input } from '@angular/core';

type NgClassSupportedTypes = string[] | Set<string> | { [klass: string]: any } | null | undefined;

const WS_REGEXP = /\s+/;

const EMPTY_ARRAY: string[] = [];

interface CssClassState {
    enabled: boolean;
    changed: boolean;
    touched: boolean;
}

@Directive({
    selector: '[csDecoratorClass]',
    standalone: true,
})
export class DecoratorClassDirective implements DoCheck, AfterViewInit {
    private initialClasses = EMPTY_ARRAY;
    // private rawClass: NgClassSupportedTypes;
    private rawClasses: Array<NgClassSupportedTypes> = [];

    private stateMap = new Map<string, CssClassState>();
    private decoratorClassHost!: HTMLElement | null;
    constructor(private _ngEl: ElementRef) {}

    ngAfterViewInit(): void {
        this.decoratorClassHost = (this._ngEl.nativeElement as HTMLElement).querySelector('[data-decoratorclass-host]');
        this._applyStateDiff();
    }

    @Input()
    set csDecoratorClass(value: string | string[] | Set<string> | { [klass: string]: any } | null | undefined) {
        this.rawClasses.push(typeof value === 'string' ? value.trim().split(WS_REGEXP) : value);
    }

    ngDoCheck(): void {
        // classes from the [class] binding
        for (const klass of this.initialClasses) {
            this._updateState(klass, true);
        }

        // classes from the [decoratorClass] binding
        this.rawClasses.forEach((rawClass) => {
            if (Array.isArray(rawClass) || rawClass instanceof Set) {
                for (const klass of rawClass) {
                    this._updateState(klass, true);
                }
            } else if (rawClass != null) {
                for (const klass of Object.keys(rawClass)) {
                    this._updateState(klass, Boolean(rawClass[klass]));
                }
            }
        });

        this._applyStateDiff();
    }

    private _updateState(klass: string, nextEnabled: boolean) {
        const state = this.stateMap.get(klass);
        if (state === undefined) {
            this.stateMap.set(klass, { enabled: nextEnabled, changed: true, touched: true });
        } else {
            if (state.enabled !== nextEnabled) {
                state.changed = true;
                state.enabled = nextEnabled;
            }
            state.touched = true;
        }
    }

    private _applyStateDiff() {
        for (const stateEntry of this.stateMap) {
            const klass = stateEntry[0];
            const state = stateEntry[1];

            if (state.changed) {
                this._toggleClass(klass, state.enabled);
                state.changed = false;
            } else if (!state.touched) {
                // A class that was previously active got removed from the new collection of classes -
                // remove from the DOM as well.
                if (state.enabled) {
                    this._toggleClass(klass, false);
                }
                this.stateMap.delete(klass);
            }

            state.touched = false;
        }
    }

    private _toggleClass(klass: string, enabled: boolean): void {
        klass = klass.trim();
        if (klass.length > 0) {
            klass.split(WS_REGEXP).forEach((c_lass) => {
                if (enabled) {
                    if (this.decoratorClassHost) {
                        this.decoratorClassHost.classList.add(c_lass);
                    }
                } else {
                    if (this.decoratorClassHost) {
                        this.decoratorClassHost.classList.remove(c_lass);
                    }
                }
            });
        }
    }
}
