import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csApiPathsConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: apiPathsClientConfigFactory,
})
export class ApiPathsClientConfig {
    bingo: any;
    globalJackpots: any;
    lmt: any;
    slotRaces: any;
    jackpots: any;
    gameData: any;
    teasers: any;
    playerSegmentation: any;
    portal: any;
    cashier: any;
    liveCasino: any;
    freeSpins: any;
    nonCriticalFeeds: any;
    gamesServices: any;
    disjointFeeds: any;
    ping: any;
}

export function apiPathsClientConfigFactory(service: LazyClientConfigService) {
    return service.get(ApiPathsClientConfig);
}
