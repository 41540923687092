import { Injectable } from '@angular/core';

import { Logger, UserService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';

@Injectable({
    providedIn: 'root',
})
export class PlayerSegmentationService {
    private playerSegmentionSubject = new BehaviorSubject<any>(null);
    playerSegmentDataObservable: Observable<any> = this.playerSegmentionSubject.asObservable();
    playerSegmentData: any;
    constructor(
        private user: UserService,
        private api: PlatformApiService,
        private configProviderService: ConfigProviderService,
        private logger: Logger,
    ) {}

    setPlayerSegmentationData() {
        if (this.user.isAuthenticated) {
            const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
            this.api.get(apiPathsConfig.playerSegmentation['playerData']).subscribe(
                (response) => {
                    this.playerSegmentData = response;
                    this.playerSegmentionSubject.next(response);
                },
                (error) => {
                    this.logger.error('unable to retrieve data from playerData API : ' + error);
                },
            );
        }
    }
    // NFR - Services optimization - Remove
    getPlayerSegmentationData() {
        return this.playerSegmentData;
    }
}
