import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csRecentWinnersConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: recentWinnersWidgetClientConfigFactory,
})
export class RecentWinnersWidgetClientConfig {
    readonly enablePagination: boolean;
    readonly enableLoadingIcon: boolean;
    readonly enableNewsfeedStyle: boolean;
    readonly enableODR: boolean;
    readonly filters: Array<{ readonly name: string; readonly value: string }>;
    readonly isEnabled: boolean;
    readonly maximumGamesLength: number;
    readonly recentWinnersInterval: number;
    readonly recentWinnersWidgetGlobalMessages: RecentWinnersWidgetGlobalMessages;
    readonly recentWinnerWidgetConfiguration: RecentWinnersWidgetConfiguration;
    readonly winnersFeedUrl: string;
    readonly enableFilterWidth: boolean;
    readonly enableTitleOnGameIcon: boolean;
    readonly maxNumbersForDecimal: number;
}

interface RecentWinnersWidgetConfiguration {
    readonly backgroundImageLayer1: string;
    readonly backgroundImageLayer2: string;
    readonly backgroundImageLayer3: string;
}

interface RecentWinnersWidgetGlobalMessages {
    readonly FilterBgColor?: string;
    readonly FilterCarousalIconBgColor?: string;
    readonly NewsFeedAnimationTiming?: string;
    readonly WinnerBgColor?: string;
    readonly WinnersErrorTextColor?: string;
    readonly WinnersTextColor?: string;
    readonly winners?: string;
    readonly winnersResult?: string;
    readonly RECENT?: string;
    readonly TEN_DAYS?: string;
    readonly PREVIOUS_MONTH?: string;
    readonly CURRENT_MONTH?: string;
    readonly PREVIOUS_YEAR?: string;
    readonly LIFE_TIME?: string;
    readonly WinnersGameNameFontsize?: string;
}

function recentWinnersWidgetClientConfigFactory(service: LazyClientConfigService) {
    return service.get(RecentWinnersWidgetClientConfig);
}
