import { Injectable, NgZone } from '@angular/core';

import { Logger, TimerService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';
import { ApiPathsHelperService } from './api-paths-helper-service';
import { InvokerProductService } from './invoker-product.service';

@Injectable({
    providedIn: 'root',
})
export class JackpotDataService {
    private jackpotSubject = new BehaviorSubject<any>(null);
    private jpTickingSubject = new BehaviorSubject<any>(null);
    private jackpotSortGames = new BehaviorSubject<any>(null);
    jpSortGamesObservable: Observable<any> = this.jackpotSortGames.asObservable();
    private multiJackpotWidgetCarouselSubject = new BehaviorSubject<boolean>(false);
    jackpotFeedObservable: Observable<any> = this.jackpotSubject.asObservable();
    multiJackpotWidgetCarouselChangedObservable: Observable<boolean> = this.multiJackpotWidgetCarouselSubject.asObservable();
    jpTickingObservable: Observable<any> = this.jpTickingSubject.asObservable();
    jackpotsCache: any;
    gameJackpotMap: Map<string, any> = new Map<string, any>();
    jackpotGroupNameMap: Map<string, any> = new Map<string, any>();
    fetchJackpotPromise: any;
    listenersCnt = 0;
    tickingInterval: any;
    sortInterval: any;
    incrementTickingForJackpotOnGameTiles: any = 1;
    overlayLobbyType: any;
    jackpotValue: any;
    sortGames: any;
    enableJpGroupTicking: boolean;
    currentLobbyType: any;
    constructor(
        private api: PlatformApiService,
        private timerService: TimerService,
        private configProviderService: ConfigProviderService,
        private apiPathsHelperService: ApiPathsHelperService,
        private invokerProductService: InvokerProductService,
        private logger: Logger,
        private ngZone: NgZone,
    ) {
        //  this.currentLobbyType=this.casinoManager.getLobbyType();
    }

    fetchJackpot() {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        const endPoint = this.apiPathsHelperService.transformApi(
            apiPathsConfig.jackpots['jpDataOfProduct'],
            this.invokerProductService.getInvokerProduct(),
        );

        this.api.get(endPoint).subscribe(
            (response: any) => {
                this.jackpotsCache = response;
                this.setJackpots(response);
            },
            () => {
                this.logger.error('Unable to retrieve data from jpDataOfProduct API');
            },
        );
    }
    startFeed() {
        const JackpotData = this.configProviderService.provideJackpotConfig();
        const feedCacheConfig = this.configProviderService.provideFeedCacheConfig();
        if (feedCacheConfig.enableJackpotFeed) {
            this.listenersCnt++;
            if (!this.fetchJackpotPromise) {
                this.fetchJackpot();

                if (JackpotData.enableTickingForJackpotOnGameTiles) {
                    this.setJackpotTickers();
                }
                this.fetchJackpotPromise = this.timerService.setIntervalOutsideAngularZone(() => {
                    this.fetchJackpot();
                    if (JackpotData.enableTickingForJackpotOnGameTiles) {
                        this.setJackpotTickers();
                    }
                }, JackpotData.jpInterval);
            }
        }
    }

    setJackpots(jackpotFeed: any) {
        if (jackpotFeed) {
            Object.keys(jackpotFeed).forEach((jackpotKey: string) => {
                if (jackpotFeed[jackpotKey]?.jackpotGroupName) {
                    this.jackpotGroupNameMap?.set(jackpotFeed[jackpotKey].jackpotGroupName?.toLowerCase(), jackpotFeed[jackpotKey]);
                }
                if (jackpotFeed[jackpotKey] && jackpotFeed[jackpotKey].gamesList && jackpotFeed[jackpotKey].gamesList.length > 0) {
                    jackpotFeed[jackpotKey].gamesList.forEach((gameId: string) => {
                        const jackpotObject: any = {
                            amount: jackpotFeed[jackpotKey].amount,
                            isHotJackpotGroup: jackpotFeed[jackpotKey].isHotJackpotGroup,
                            jackpotName: jackpotKey,
                            jackpotAttributes: jackpotFeed[jackpotKey].attributes,
                            globalJackpotAttributes: jackpotFeed[jackpotKey].globalJackpotAttributes,
                        };
                        this.gameJackpotMap.set(gameId, jackpotObject);
                    });
                }
            });
        }
        this.jackpotSubject.next(this.gameJackpotMap);
        this.incrementJackpotValues();
    }
    // NFR - Services optimization - Remove
    changeActiveMultiJackpotWidget(isChanged: boolean) {
        this.multiJackpotWidgetCarouselSubject.next(isChanged);
    }

    getJackpotData() {
        return this.jackpotsCache;
    }
    private get globalMessages(): any {
        return this.configProviderService.provideGlobalConfig()?.messages;
    }

    get jpRibbonBgColor(): string {
        return this.globalMessages?.JackpotRibbonBgColor;
    }

    get jpRibbonTextColor(): string {
        return this.globalMessages?.JackpotRibbonTextColor;
    }

    getJackpotRibbonStyles(jackpotValue: any): { jpBgColor: string; jpTextColor: string } {
        let jpRibbonBgClr: string = '';
        let jpRibbonTextClr: string = '';
        if (jackpotValue && this.jpRibbonBgColor && this.jpRibbonTextColor) {
            jpRibbonBgClr = this.jpRibbonBgColor;
            jpRibbonTextClr = this.jpRibbonTextColor;
        }
        if (jackpotValue?.jackpotAttributes?.JackpotRibbonBgColor && jackpotValue?.jackpotAttributes?.JackpotRibbonTextColor) {
            jpRibbonBgClr = jackpotValue?.jackpotAttributes?.JackpotRibbonBgColor;
            jpRibbonTextClr = jackpotValue?.jackpotAttributes?.JackpotRibbonTextColor;
        }
        if (jackpotValue?.globalJackpotAttributes) {
            jpRibbonBgClr = jackpotValue?.globalJackpotAttributes?.JackpotRibbonBgColor;
            jpRibbonTextClr = jackpotValue?.globalJackpotAttributes?.JackpotRibbonTextColor;
        }
        return { jpBgColor: jpRibbonBgClr, jpTextColor: jpRibbonTextClr };
    }

    getJackpotGroupNameMap() {
        return this.jackpotGroupNameMap;
    }

    getJackpotByGroupName(groupName: string): any {
        if (this.jackpotGroupNameMap && this.jackpotGroupNameMap.size > 0) {
            return this.jackpotGroupNameMap.get(groupName);
        }
        return null;
    }

    getGameJackpotValue(gameId: string) {
        return this.gameJackpotMap.get(gameId);
    }

    stopFeed() {
        this.listenersCnt--;
        if (this.listenersCnt === 0 && this.fetchJackpotPromise) {
            this.timerService.clearInterval(this.fetchJackpotPromise);
            this.fetchJackpotPromise = undefined;
            this.jackpotsCache = undefined;
            this.gameJackpotMap = new Map<string, string>();
        }
    }

    setJackpotTickers() {
        clearInterval(this.tickingInterval);
        this.tickingInterval = this.timerService.setIntervalOutsideAngularZone(() => {
            this.incrementJackpotValues();
        }, 1500);
    }

    formatcurrency(value: any) {
        const inputarr = value.toString().split('.');
        const realpart = inputarr[0];
        const decimalpart = inputarr[1];
        let amt = '';
        for (let i = realpart.length; i > 0; i = i - 3) {
            if (i - 3 > 0) {
                amt = ',' + realpart.substring(i - 3, i) + amt;
            } else {
                amt = realpart.substring(0, i) + amt;
            }
        }
        return amt + '.' + decimalpart;
    }

    incrementJackpotValues() {
        const globalClientConfig = this.configProviderService.provideGlobalConfig();
        const JackpotData = this.configProviderService.provideJackpotConfig();

        this.gameJackpotMap.forEach((value, key) => {
            let currency: any = '';
            let valueStr = value.amount;
            valueStr = valueStr.replace(/\&nbsp;/g, '');
            const initialcurrency = valueStr.match(/[^\d\.\,\s]/g);
            if (initialcurrency && initialcurrency.length > 0) {
                for (let i = 0; i < initialcurrency.length; i++) {
                    currency = currency + initialcurrency[i];
                }
                currency = currency.replace(/\,/g, '');
                currency = currency.replace(/\./g, '');
            }
            valueStr = valueStr.replace(/\ /g, '');
            valueStr = valueStr.replace(/\,/g, '');
            valueStr = valueStr.replace(/\./g, '');
            valueStr = valueStr.replace(currency, '');
            valueStr = valueStr.replace(/\s/g, '');
            valueStr = valueStr.match(/\b[\d, ]+\b/g)[0];
            if (valueStr.length > 2) {
                const realpart = valueStr.slice(0, valueStr.length - 2);
                const decimalpart = valueStr.slice(-2);
                valueStr = realpart + '.' + decimalpart;
            }

            let jackpotTickValue: any = parseFloat(valueStr.replace(/,/g, ''));
            if (globalClientConfig.lobbyConfigurations.IncrementTickingForJackpotOnGameTiles) {
                this.incrementTickingForJackpotOnGameTiles = +globalClientConfig.lobbyConfigurations.IncrementTickingForJackpotOnGameTiles;
            }
            jackpotTickValue = jackpotTickValue + this.incrementTickingForJackpotOnGameTiles * 0.01;

            jackpotTickValue = this.formatcurrency(jackpotTickValue.toFixed(2));
            if (JackpotData && JackpotData.showJackpotCurrencyOnRight) {
                jackpotTickValue = jackpotTickValue.replace(/[,.]/g, (m: any) => (m === ',' ? '.' : ','));
                value.amount = jackpotTickValue + ' ' + currency + '.';
            } else {
                value.amount = currency + ' ' + jackpotTickValue;
            }

            this.gameJackpotMap.set(key, value);
        });

        this.ngZone.run(() => {
            // observable fire
            this.jpTickingSubject.next(this.gameJackpotMap);
        });
    }
    currencyFormat(value: any) {
        const JackpotData = this.configProviderService.provideJackpotConfig();
        let currency: any = '';
        let valueStr = value;
        valueStr = valueStr.replace(/\&nbsp;/g, '');
        const initialcurrency = valueStr.match(/[^\d\.\,\s]/g);
        if (initialcurrency && initialcurrency.length > 0) {
            for (let i = 0; i < initialcurrency.length; i++) {
                currency = currency + initialcurrency[i];
            }
            currency = currency.replace(/\,/g, '');
            currency = currency.replace(/\./g, '');
        }
        valueStr = valueStr.replace(/\ /g, '');
        valueStr = valueStr.replace(/\,/g, '');
        valueStr = valueStr.replace(/\./g, '');
        valueStr = valueStr.replace(currency, '');
        valueStr = valueStr.replace(/\s/g, '');
        valueStr = valueStr.match(/\b[\d, ]+\b/g)[0];
        if (valueStr.length > 2) {
            const realpart = valueStr.slice(0, valueStr.length - 2);
            const decimalpart = valueStr.slice(-2);
            valueStr = realpart + '.' + decimalpart;
        }
        let jackpotTickValue: any = parseFloat(valueStr.replace(/,/g, ''));
        jackpotTickValue = this.formatcurrency(jackpotTickValue.toFixed(2));
        if (JackpotData && JackpotData.showJackpotCurrencyOnRight) {
            jackpotTickValue = jackpotTickValue.replace(/[,.]/g, (m: any) => (m === ',' ? '.' : ','));
            this.jackpotValue = jackpotTickValue + ' ' + currency + '.';
        } else {
            this.jackpotValue = currency + ' ' + jackpotTickValue;
        }
        return this.jackpotValue;
    }

    fetchJackpotSortGames(): void {
        this.api.get('jackpot/GetJackpotGamesSort').subscribe((response: any) => {
            this.jackpotSortGames.next(response);
            this.sortgamesInterval(response.nextUpdateTime);
        });
    }
    sortgamesInterval(sortDataNextTime: any) {
        let resultTime: number = 0;
        const jackpotDataConfig = this.configProviderService.provideJackpotConfig();
        const nextUpdateTime = sortDataNextTime - Math.floor(Date.now());
        if (nextUpdateTime > 0 && nextUpdateTime < jackpotDataConfig?.jackpotGamesSortingTime) {
            resultTime = nextUpdateTime;
        } else {
            resultTime = jackpotDataConfig?.jackpotGamesSortingTime;
        }

        if (this.sortInterval) {
            clearInterval(this.sortInterval);
        }
        this.sortInterval = this.timerService.setIntervalOutsideAngularZone(() => {
            this.fetchJackpotSortGames();
        }, resultTime);
    }
    /// filter Jackport group games
    filterJpGroup(jackpotGroups: any, gpGroupName: any) {
        const gamesList = jackpotGroups.filter((sortData: any) => sortData.jackpotGroupName == gpGroupName);
        if (gamesList && gamesList[0]?.gamesAvailable) return gamesList[0]?.gamesAvailable;
    }
    // filter sort games and un sort games
    filterGames(sortGamesList: any, gamesList: any) {
        const sortGames: any = [];
        sortGamesList.forEach((sgame: any) => {
            gamesList.forEach((game: any) => {
                if (sgame.game == game.gameId) {
                    sortGames.push(game);
                }
            });
        });
        const unSortGames: any = gamesList.filter(({ gameId: id1 }: any) => !sortGames.some(({ gameId: id2 }: any) => id2 === id1));
        const sortList = sortGames.concat(unSortGames);
        return sortList;
    }
    //sort feature grid Array games and rowspan and calspan in single jackpot
    newFeaturegridArray(gamesList: any) {
        const featureGrid: any = [];
        gamesList.forEach((game: any) => {
            featureGrid.push({
                games: [game],
                maxRowSpan: game.rowSpan,
                maxColSpan: game.colSpan,
            });
        });
        return featureGrid;
    }
}
