import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'cs-image-icon',
    template: ` @if (imgSrc) {
        <img [src]="imgSrc" width="100%" height="100%" [alt]="altText" />
    }`,
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CsImageIconComponent implements CsImageIconViewModel {
    @Input() imgSrc = '';
    @Input() altText = 'image not found';
}

export interface CsImageIconViewModel {
    imgSrc: string;
    altText: string;
}
