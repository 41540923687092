import { NgClass, NgIf, NgStyle, UpperCasePipe } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { BingoQuickInfoModelComponent } from '@casinocore/platform/bingo-quick-info-model';
import {
    BingoGameLaunchService,
    CasinoCoreGamelaunchService,
    CasinoCoreNpmParamsService,
    CasinoLobbyService,
    CasinoManager,
    CasinoWidgetService,
    ConfigProviderService,
    DesignSystemEnablerService,
    FavouriteService,
    FreespinSummaryService,
    GameDataManager,
    GameLaunch,
    JackpotDataService,
    LiveCasinoService,
    OpenLoginDialog,
    QuickInfoModalService,
    UrlUtilityService,
    WindowRef,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, GameTileCtaComponent } from '@frontend/casino-ui';
import { CookieService, DeviceService, DslService, Page, TrackingService, UserService } from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { Subject } from 'rxjs';

import { FreeSpinsQuickInfoComponent } from '../freespins-summary/freespins-quick-info/freespins-quick-info.component';
import { FreeSpinsSummaryComponent } from '../freespins-summary/freespins-summary.component';
import { LibMockService } from '../services/lib-mock.service';
import { StickersJackpotComponent } from '../stickers-jackpot/stickers-jackpot.component';

@Component({
    selector: 'cc-game-quick-info',
    templateUrl: 'game-quick-info.component.html',
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        StickersJackpotComponent,
        NgStyle,
        FreeSpinsQuickInfoComponent,
        FreeSpinsSummaryComponent,
        UpperCasePipe,
        TrustAsHtmlPipe,
        GameTileCtaComponent,
        DecoratorClassDirective,
    ],
})
export class GameQuickInfoComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    a: any;
    width: number;
    boxWidth: number;
    boxWidthInt: number;
    isTouch: boolean;
    defaultCategory: any;
    gameProvider: string;
    cta: string;
    quickInfoCta: string;
    moreInfo: string;
    playNow: string;
    gymlCategoryType: string = 'lmc_gyml';
    useFavouriteGames: boolean;
    provider: string;
    displayFavouritesOnPreLogin: boolean = true;
    isMobileView: boolean;
    @Input() game: any;
    @Input() categoryType: any;
    @Input() gamePosition: number;
    @Input() icon: any;
    @Input() gameLaunchedFrom: string;
    @Input() gameModel: any;
    @Input() gridPosition: any;
    @Input() quickInfoScrollY: any = 0;
    @Input() product: string = '';
    @Input() isLiveGame: any;
    @Input() liveGameInfo: any;
    @Input() stickersArray: any;
    @Input() lobbyType: string = '';
    @Input() thumbImageSrc: any;
    @Input() widgetFrameClr: any;
    @Input() gameContainerLocation: any;
    @Input() displayRecenetWinners: boolean;
    //@Input() isVideoEnabled: boolean;
    src: string;
    mobileQuickInfoSrc: string;
    vendorLogo: any = null;
    favouriteStyle: string = 'theme-favorite-i';
    unFavouriteStyle: string = 'theme-favorite';
    isShowFavouriteHeartEnabled: boolean;
    gameTitle: string;
    playNowBtnWidth: number;
    stickerName: any;
    isStickersForUk: boolean;
    newJackpotRibbonStyling: boolean = false;
    newQuickInfoStyle: any = false;
    stickers: any = [];
    isFavourite: boolean = false;
    jackpotValue: any = null;
    isHotJackpotGroup: boolean = false;
    gameDescription: any = null;
    useQuickInfo: boolean;
    enableQuickInfoforLiveCasinoApi: boolean;
    enableFavouriteGamesforLiveCasinoApi: boolean;
    hideMoreInfoLinkForLiveCasinoApi: boolean;
    enableUkMouseOverStyle: boolean;
    playNowHoverEffect: boolean = false;
    loadQuickInfo: boolean = false;
    enableTitleOnGame: boolean;
    liveCasinoApiSitecoreInfo: any;
    playBehindButtonTitle: string;
    liveCasinoPlayNowCTA: string;
    quickInfoStyle: string;
    isTableOnline: boolean;
    bingoGames: any = [];
    bingoRooms: any = [];
    demoLinkText: string;
    demoEnabledForProvider: boolean = true;
    demoEnabled: boolean;
    demoLinkTextColor: string;
    GameStickerStyle: string;
    UkStickerStyle1: string;
    UkStickerStyle2: string;
    UKPlayNow: string;
    enableQuickInfoReels: boolean;
    reelsTitle: string = '';
    payLinesTitle: string = '';
    returnToPlayerTitle: string = '';
    reels: any = null;
    payLines: any = null;
    returnToPlayer: any = null;
    enableQuicInfoReelskDiv: boolean = false;
    showFreeSpinCount: boolean;
    enableFreeSpinSummary: boolean;
    enableFreeSpinsOnQuickInfo: boolean;
    freespinCountGame: any;
    freespinObservable: any;
    rewardIds: any[] = [];
    rewardDetails: any;
    optedIn: any;
    availableCount: any = 0;
    totalCount: any = 0;
    quickInfoService: any;
    isVideoPreviewEnabled: boolean = false;
    moreInfoClicked: boolean = false;
    enableEpcotSubNavigation: boolean;
    isNewQuickInfoEnabled: boolean;
    private destroy$ = new Subject<void>();
    winnersGameNameFontsize: any;
    enableTitleOnGameTitle: boolean;
    enableTitleOnGameIconPlayerStats: boolean;

    isLegacy: boolean = false;
    @Output() onMouseLeaveEvent: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private configProviderService: ConfigProviderService,
        private windowRef: WindowRef,
        private bingoQuickInfo: MatDialog,
        private user: UserService,
        private loginDialog: OpenLoginDialog,
        private favouriteService: FavouriteService,
        private cookie: CookieService,
        private gameDataManager: GameDataManager,
        private jackpotservice: JackpotDataService,
        private casinoManager: CasinoManager,
        private elementRef: ElementRef,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private liveCasinoService: LiveCasinoService,
        private tracking: TrackingService,
        private casinoWidgetService: CasinoWidgetService,
        private deviceService: DeviceService,
        private casinoLobbyService: CasinoLobbyService,
        private casinoCoreGameLaunchService: CasinoCoreGamelaunchService,
        private bingoGameLaunchService: BingoGameLaunchService,
        private freespinSummaryService: FreespinSummaryService,
        private urlUtilityService: UrlUtilityService,
        private page: Page,
        private casinoNpmParamsService: CasinoCoreNpmParamsService,
        private libMockService: LibMockService,
        private quickInfoModalService: QuickInfoModalService,
        private dslService: DslService,
        private designSystemEnablerService: DesignSystemEnablerService,
    ) {
        this.quickInfoService = this.libMockService.getQuickInfoService();
        this.isTouch = this.casinoManager.isTouch();
        this.setWidth();
    }

    ngOnInit() {
        this.isLegacy = !this.designSystemEnablerService.isEnabled();
        if (this.data && this.data.game) {
            this.game = this.data.game;
        }
        this.setGameTitleAndProvider();
        if (this.data && this.data.lobbyType) {
            this.lobbyType = this.data.lobbyType;
        }
        const featureConfig = this.configProviderService.provideFeaturesConfig();
        if (featureConfig.gameVideo && Boolean(JSON.parse(featureConfig?.gameVideo?.enabled?.toString()?.toLowerCase()))) {
            this.isVideoPreviewEnabled = true;
        }
        if (this.data && this.data.product != '') {
            this.trackWidgetGames();
        }
        this.assignFeatures();
        if (this.isTouch && this.data) {
            this.setLocalData();
        }
        this.setImageIcon();
        this.isFavourite = this.favouriteService.checkForFavouriteGame(this.game);
        this.setPlayNowBtnWidth(this.gameModel);
        if (this.windowRef.nativeWindow.screen.availWidth < 768 && this.isTouch) {
            this.gameDataManager.getGameDescription(this.game.gameId, this.game.provider).subscribe((res) => {
                if (res) {
                    this.gameDescription = res.gameDescription;
                    this.reels = res.reels;
                    this.payLines = res.payLines;
                    this.returnToPlayer = res.returnToPlayer;
                }
            });
        }
        if (this.game.fromCozybingo && this.deviceService.isTouch) {
            this.openDialog(null);
        }

        this.stickers = this.stickersArray || this.data.stickers;
        if (this.game.sticker && !this.stickers) {
            if (this.isStickersForUk && this.game.sticker.indexOf(',') !== -1) {
                this.stickers = this.game.sticker.split(',');
            } else {
                if (this.game.sticker.indexOf(',') !== -1) {
                    this.stickers.push(this.game.sticker.split(',')[0]);
                } else {
                    this.stickers.push(this.game.sticker);
                }
            }
        }
        this.enableFreeSpinSummary = this.configProviderService.providefreeSpinsClientConfig().isFreeSpinSummaryEnabled;
        this.enableFreeSpinsOnQuickInfo = this.configProviderService.providefreeSpinsClientConfig().isEnableFreeSpinsOnQuickInfo;
        if (this.enableFreeSpinSummary && this.enableFreeSpinsOnQuickInfo) {
            this.freespinObservable = this.freespinSummaryService.freeSpinsGameDetailsObser.subscribe((freespinsdata: any) => {
                if (freespinsdata) {
                    this.bindFreeSpinInfo();
                }
            });
        }
        const isCasinoGame = !(this.game.fromCasino != undefined && !this.game.fromCasino) || !this.game.fromCozybingo;
        if (isCasinoGame && this.newQuickInfoStyle && this.isTouch) {
            if (this.windowRef.nativeWindow.screen.availWidth >= 768 || window.innerWidth >= 768 || document.body.offsetWidth >= 768) {
                setTimeout(() => {
                    this.openDialog(null);
                }, 100);
            } else {
                this.loadQuickInfo = true;
            }
        }
        this.jackpotValue = this.GameJackpotValue(this.game);
        if (this.jackpotValue) {
            this.isHotJackpotGroup = this.jackpotValue.isHotJackpotGroup;
        }
        this.liveCasinoApiSitecoreInfo = this.liveCasinoService.getLiveCasinoApiSitecoreInfo();
        if (this.liveCasinoApiSitecoreInfo && this.liveCasinoApiSitecoreInfo.messages) {
            this.liveCasinoPlayNowCTA = this.liveCasinoApiSitecoreInfo.messages.LiveCasinoPlayNowCTA;
            this.playBehindButtonTitle = this.liveCasinoApiSitecoreInfo.messages.PlayBehind;
            this.isTableOnline = this.liveCasinoService.isTableOnline(this.game.gameId);
        }
        if (
            this.gameLaunchedFrom != 'RecentlyPlayed_widget_Favourites' &&
            this.gameLaunchedFrom != 'RecentlyPlayed_widget_RecentlyPlayed' &&
            this.data.moreInfoText
        ) {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'games',
                'component.LabelEvent': 'game interactions',
                'component.ActionEvent': 'load',
                'component.PositionEvent': this.data.gamePosition,
                'component.LocationEvent': 'bacon',
                'component.EventDetails': 'more info overlay',
                'component.URLClicked': 'not applicable',
            });
        }
        const globalMessages = this.configProviderService.provideRecentWinnersWidgetConfig()?.recentWinnersWidgetGlobalMessages;
        this.winnersGameNameFontsize = globalMessages?.WinnersGameNameFontsize;
        this.enableTitleOnGameTitle = this.configProviderService.provideRecentWinnersWidgetConfig()?.enableTitleOnGameIcon;
        this.enableTitleOnGameIconPlayerStats = this.configProviderService.providePlayerStatsWidgetConfig()?.enableTitleOnGameIcon;
    }

    assignFeatures() {
        const features: any = this.configProviderService.provideFeaturesConfig();
        const global = this.configProviderService.provideGlobalConfig();
        const quickInfoConfig = this.configProviderService.provideQuickInfoConfig();
        const liveCasinoApiConfig = this.configProviderService.provideLiveCasinoConfig();
        const subnavConfig: any = this.configProviderService.provideSubNavigationConfig();
        this.useFavouriteGames = this.casinoManager.IsFeatureEnabledforLobbytype(this.game.lobbyType, features.favouriteGamesLobbyType);
        this.newJackpotRibbonStyling = this.casinoManager.IsFeatureEnabledforLobbytype(this.game.lobbyType, features.newJackpotRibbonStyling);
        this.newQuickInfoStyle = this.casinoManager.IsFeatureEnabledforLobbytype(this.game.lobbyType, features.newQuickInfoStyle);
        this.isStickersForUk = this.casinoManager.IsFeatureEnabledforLobbytype(this.game.lobbyType, features.stickersForUk);
        this.enableUkMouseOverStyle = this.casinoManager.IsFeatureEnabledforLobbytype(this.game.lobbyType, features.enableUkMouseOverStyle);
        this.useQuickInfo = this.casinoManager.IsFeatureEnabledforLobbytype(this.game.lobbyType, features.enableQuickInfoLobbyType);
        this.isNewQuickInfoEnabled = this.casinoManager.IsFeatureEnabledforLobbytypeWithInvokerProduct(
            this.game.lobbyType,
            features.enableNewQuickInfoLobbyType,
        );
        this.playNowHoverEffect = this.casinoManager.IsFeatureEnabledforLobbytype(this.game.lobbyType, features.playNowHoverEffect);
        this.enableTitleOnGame = this.casinoManager.IsFeatureEnabledforLobbytype(this.game.lobbyType, features.enableTitleOnGameTile);
        this.enableQuickInfoforLiveCasinoApi = liveCasinoApiConfig.enableQuickInfo;
        this.enableFavouriteGamesforLiveCasinoApi = liveCasinoApiConfig.enableFavouriteGames;
        this.hideMoreInfoLinkForLiveCasinoApi = liveCasinoApiConfig.hideMoreInfoLink;
        if (quickInfoConfig && quickInfoConfig.quickInfo) {
            this.gameProvider = quickInfoConfig.quickInfo.gameProviderHeader;
        }
        this.cta = this.isNewQuickInfoEnabled ? quickInfoConfig?.quickInfo.cta : global?.messages?.ButtonPlayNow;
        this.quickInfoCta = quickInfoConfig?.quickInfo.cta || global?.messages?.ButtonPlayNow;
        this.playNow = global.messages.UKPlayNow;
        if (
            !this.user.isAuthenticated &&
            this.casinoManager.IsFeatureEnabledforLobbytype(this.game.lobbyType, features.preLoginPlayNowText) &&
            quickInfoConfig
        ) {
            if (this.isNewQuickInfoEnabled) {
                this.cta = quickInfoConfig?.quickInfo?.preLogincta;
            }
            this.quickInfoCta = quickInfoConfig?.quickInfo.preLogincta || global?.messages?.ButtonPlayNow;
            this.playNow = global.messages.UKPreLoginPlayNow;
        }
        this.moreInfo = global.messages.UKMoreInfo;
        if (
            global.lobbyConfigurations.FavouriteHeart === 'true' ||
            global.lobbyConfigurations.FavouriteHeart === 'True' ||
            global.lobbyConfigurations.FavouriteHeart === 'TRUE'
        ) {
            this.favouriteStyle = 'theme-heart-solid';
            this.unFavouriteStyle = 'theme-heart-outline';
        }
        this.dslService.evaluateExpression<boolean>(features.hideFavouritesOnPreLogin).subscribe((hideFavouritesOnPreLogin) => {
            if (hideFavouritesOnPreLogin && !this.user.isAuthenticated) {
                this.displayFavouritesOnPreLogin = false;
            }
        });
        this.quickInfoStyle = global.messages.quickInfoStyles;
        this.demoLinkText = global.messages.DemoLinkText;
        this.demoLinkTextColor = global.messages.DemoLinkTextColor;
        this.GameStickerStyle = global.messages.GameStickerStyle;
        this.UkStickerStyle1 = global.messages.UkStickerStyle1;
        this.UkStickerStyle2 = global.messages.UkStickerStyle2;
        this.UKPlayNow = global.messages.UKPlayNow;
        this.reelsTitle = global.messages.Reels;
        this.payLinesTitle = global.messages.PayLines;
        this.returnToPlayerTitle = global.messages.ReturnToPlayer;

        const gameConfig = this.configProviderService.provideGameConfig();
        const gameOwnerId = gameConfig.gameOwnerIdMap?.find((g: any) => g.gameId == this.game.gameId);
        this.vendorLogo =
            gameConfig.host +
            gameConfig.gameIconTemplate.replace('{0}', 'vendorlogos').replace('{1}', gameOwnerId ? gameOwnerId?.ownerId : this.game.ownerId) +
            '.jpg';
        this.demoEnabled = gameConfig.enableDemoMode;
        const vendorsWithOnlyRealMode: any = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.game.lobbyType,
            gameConfig.vendorsWithOnlyRealModeLobbyType,
        );
        const demoModeExcludedGames = gameConfig.demoModeExcludedGamesRegex?.split(',');
        if (vendorsWithOnlyRealMode && vendorsWithOnlyRealMode.indexOf(this.provider) > -1) {
            this.demoEnabledForProvider = false;
        } else if (demoModeExcludedGames && demoModeExcludedGames.length > 0) {
            demoModeExcludedGames?.forEach((regex: any) => {
                if (new RegExp(regex, 'g').test(this.game.gameId)) {
                    this.demoEnabledForProvider = false;
                }
            });
        }
        this.enableQuickInfoReels = gameConfig.enableQuickInfoReels;
        this.enableEpcotSubNavigation = subnavConfig.enableEpcotSubNavigation;
    }
    bindFreeSpinInfo() {
        this.setfreeSpinsCount(this.freespinSummaryService.getFreespinCountInfoForGame(this.game.gameId));
    }

    /*This is to set freespins count for the respective game*/
    setfreeSpinsCount(freespinsCountResponse: any) {
        this.freespinCountGame = freespinsCountResponse;
        if (this.freespinCountGame != undefined) {
            this.showFreeSpinCount = true;
            this.optedIn = this.freespinCountGame.optedIn;
            this.availableCount = this.freespinCountGame.availableCount;
            this.totalCount = this.freespinCountGame.totalCount;
            this.rewardIds = this.freespinCountGame.rewardIds;
            this.rewardDetails = this.freespinSummaryService.getFreeSpinRewardDetails(this.rewardIds);
        } else {
            this.showFreeSpinCount = false;
        }
    }
    ngOnChanges() {
        this.stickers = this.stickersArray || this.data.stickers;
    }
    trackWidgetGames() {
        if (this.data.gameLaunchedFrom && this.data.gameLaunchedFrom === 'RecentlyPlayed_widget_Favourites') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'Favourites_QuickInfo_Overlay',
                'component.ActionEvent': 'Load',
                'component.PositionEvent': this.data.game.gameId + '_' + this.data.gamePosition,
                'component.LocationEvent': this.game.lobbyType,
            });
        }
        if (this.data.gameLaunchedFrom && this.data.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'RecentlyPlayed_QuickInfo_Overlay',
                'component.ActionEvent': 'Load',
                'component.PositionEvent': this.data.game.gameId + '_' + this.data.gamePosition,
                'component.LocationEvent': this.game.lobbyType,
            });
        }
    }

    setGameTitleAndProvider() {
        if (this.game) {
            this.gameTitle = this.game.name;
            this.provider = this.game.provider;
        }
    }
    @HostListener('window:resize') onResize() {
        this.setWidth();
        this.isTouch = this.casinoManager.isTouch();
    }

    setWidth(): void {
        if (this.windowRef.nativeWindow.screen.availWidth >= 768) {
            this.width = 388;
        } else if (this.windowRef.nativeWindow.screen.availWidth < this.windowRef.nativeWindow.screen.availHeight) {
            this.width = this.windowRef.nativeWindow.screen.availWidth;
        } else {
            this.width = this.windowRef.nativeWindow.screen.availHeight;
        }
        this.boxWidth = this.width;
        this.boxWidthInt = this.boxWidth - 176;
    }
    ngAfterViewInit() {
        if (!this.newQuickInfoStyle || this.loadQuickInfo) {
            if (this.isStickersForUk) {
                this.gameStickerStyleForUk();
            } else {
                this.gameStickerStyle();
            }
        }
        if (document.querySelector('.casino-qi-noverlay') as HTMLElement) {
            (document.querySelector('.casino-qi-noverlay') as HTMLElement).style.cssText = this.quickInfoStyle;
        }
        this.isMobileView = window.innerWidth <= 767 || document.body.offsetWidth <= 767 ? true : false;
        setTimeout(() => {
            if (this.isMobileView) {
                this.quickInfoOverlayTopAllignment();
            }
            if (!this.isTouch) {
                gameTileReduction('casino-game-cls.casino-new-styling-overlay', 'casino-overlay-bg');
                gameTileReduction('casino-game-cls', 'casino-qi-gradient-d');
                gameTileReduction('casino-game-cls.casino-livecasino-overlay', 'casino-livecasino-overlay-bg');
            }
        }, 100);
    }
    setLocalData() {
        this.icon = this.data.icon;
        this.game = this.data.game;
        this.gamePosition = this.data.gamePosition;
        this.gameLaunchedFrom = this.data.gameLaunchedFrom;
        this.categoryType = this.data.categoryType;
        this.gridPosition = this.data.gridPosition;
        this.gameModel = this.data.gameModel;
        this.product = this.data.product;
    }
    gameStickerStyle() {
        const stickerStyle = this.GameStickerStyle;
        const sticker = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-0');
        for (let i = 0; i < sticker.length; i++) {
            sticker[i].style.cssText = stickerStyle;
        }
    }
    gameStickerStyleForUk() {
        const stickerStyle = this.UkStickerStyle1;
        const sticker = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-0');
        for (let i = 0; i < sticker.length; i++) {
            sticker[i].style.cssText = stickerStyle;
        }
        const stickerStyle1 = this.UkStickerStyle2;
        const sticker1 = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-1');
        for (let i = 0; i < sticker1.length; i++) {
            sticker1[i].style.cssText = stickerStyle1;
        }
    }
    async openDialog($event: any): Promise<void> {
        this.moreInfoClicked = true;
        if (this.game.fromCasino != undefined && !this.game.fromCasino) {
            let bingoRoomInfo: any;
            //==============================
            let requiredModalData: any;
            if (event) {
                event.stopImmediatePropagation();
                this.quickInfoScrollY = window.pageYOffset;
            }
            const isLaunchedFromUrl = false;
            document.getElementsByTagName('html')[0].classList.add('casino-quickinfo-open');

            //================================
            if (bingoRoomInfo) {
                requiredModalData = {
                    room: bingoRoomInfo,
                    roomTitle: bingoRoomInfo.roomName,
                    fromCozybingo: this.game.fromCozybingo,
                    roomDescription:
                        bingoRoomInfo.description ||
                        'Win a lot on the Jack in a Pot slot! This cheeky game lets you win up to 2,000x your stake using Jack the leprechaun’s lucky features',
                    src: bingoRoomInfo.image,
                    gameProviderText: 'GAME PROVIDER',
                    providerName: 'Win Studios',
                    provider: 'https://casinogames.galaspins.com/htmllobby/images/newlmticons/square/ivylootenkhamun.jpg',
                    vendorLogo: 'https://casinogames.galaspins.com/htmllobby/images/newlmticons/vendorlogos/21.jpg',
                    cta: this.UKPlayNow,
                    jackpotData: bingoRoomInfo.jackpot,
                    isLaunchedFromUrl,
                    section: this.data.gameLaunchedFrom || this.gameLaunchedFrom,
                    position: this.data.gamePosition || this.gamePosition,
                    gameContainer: this.game.gameContainer,
                    gameContainerLocation: this.data.gameContainerLocation || this.gameContainerLocation + 'QuickInfo',
                    widgetFrameClr: this.data.widgetFrameClr || this.widgetFrameClr,
                };
            } else {
                requiredModalData = {
                    room: { roomId: this.game.gameId },
                    fromCozybingo: this.game.fromCozybingo,
                    roomDescription: this.game.sitecoreInfo.description,
                    gameProviderText: 'GAME PROVIDER',
                    providerName: 'Win Studios',
                    provider: 'https://casinogames.galaspins.com/htmllobby/images/newlmticons/square/ivylootenkhamun.jpg',
                    vendorLogo: 'https://casinogames.galaspins.com/htmllobby/images/newlmticons/vendorlogos/21.jpg',
                    cta: this.UKPlayNow,
                    isLaunchedFromUrl,
                    section: this.data.gameLaunchedFrom || this.gameLaunchedFrom,
                    position: this.data.gamePosition || this.gamePosition,
                    gameContainer: this.game.gameContainer,
                    gameContainerLocation: this.data.gameContainerLocation || this.gameContainerLocation + 'QuickInfo',
                    widgetFrameClr: this.data.widgetFrameClr || this.widgetFrameClr,
                };
            }
            const dialogRef = this.bingoQuickInfo.open(BingoQuickInfoModelComponent, {
                width: '790px',
                data: requiredModalData,
            });
            dialogRef.afterClosed().subscribe(() => {
                this.moreInfoClicked = false;
                if (this.quickInfoScrollY) {
                    window.scrollTo(0, this.quickInfoScrollY);
                }
                if (this.isTouch) {
                    this.recentlyPlayedTracking();
                    dialogRef.close();
                }
            });
            setTimeout(() => {
                this.quickInfoOverlayTopAllignment();
            }, 0);
        } else {
            if ($event) {
                $event.stopImmediatePropagation();
                this.quickInfoScrollY = window.scrollY;
            }
            if (this.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed') {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'RecentlyPlayed_Widget',
                    'component.LabelEvent': 'RecentlyPlayed_QuickInfo',
                    'component.ActionEvent': 'Info_click',
                    'component.PositionEvent': this.game.gameId + '_' + this.gamePosition,
                    'component.LocationEvent': this.game.lobbyType,
                });
            }
            if (this.gameLaunchedFrom === 'RecentlyPlayed_widget_Favourites') {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'RecentlyPlayed_Widget',
                    'component.LabelEvent': 'Favourites_QuickInfo',
                    'component.ActionEvent': 'Info_click',
                    'component.PositionEvent': this.game.gameId + '_' + this.gamePosition,
                    'component.LocationEvent': this.game.lobbyType,
                });
            }

            if (this.gameLaunchedFrom != 'RecentlyPlayed_widget_Favourites' && this.gameLaunchedFrom != 'RecentlyPlayed_widget_RecentlyPlayed') {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'games',
                    'component.LabelEvent': 'game interactions',
                    'component.ActionEvent': 'click',
                    'component.PositionEvent': this.gamePosition,
                    'component.LocationEvent': this.game.gameId,
                    'component.EventDetails': 'more info overlay',
                    'component.URLClicked': 'not applicable',
                });
            }
            if (this.gameLaunchedFrom != 'RecentlyPlayed_widget_Favourites' && this.gameLaunchedFrom != 'RecentlyPlayed_widget_RecentlyPlayed') {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'games',
                    'component.LabelEvent': 'game interactions',
                    'component.ActionEvent': 'load',
                    'component.PositionEvent': this.gamePosition,
                    'component.LocationEvent': this.game.gameId,
                    'component.EventDetails': 'more info overlay',
                    'component.URLClicked': 'not applicable',
                });
            }

            const requiredModalData = {
                game: this.game,
                gameTitle: this.gameTitle,
                src: this.mobileQuickInfoSrc,
                useFavouriteGames: this.useFavouriteGames,
                gameProvider: this.gameProvider,
                provider: this.provider,
                cta: this.quickInfoCta,
                isTouch: this.isTouch,
                gamePosition: this.gamePosition,
                gameLaunchedFrom: this.gameLaunchedFrom,
                categoryType: this.categoryType,
                isLaunchedFromUrl: $event ? false : true,
                stickers: this.stickers,
                gridPosition: this.gridPosition,
                routeParams: this.route.snapshot.params,
                style: this.quickInfoStyle,
                product: this.product,
                lobbyType: this.game.lobbyType,
                gameHomeUrl: window.location.href,
                moreInfoText: 'moreInfoEvent',
                quickInfoScrollY: this.quickInfoScrollY,
            };

            if (this.isNewQuickInfoEnabled) {
                this.onMouseLeaveEvent.emit();
                this.appendQuickInfoQueryParameterToUrl();
                this.quickInfoModalService.setQuickInfoModalData(requiredModalData);
            } else {
                const dialogRef = await this.quickInfoService.openQuickInfoDialog(requiredModalData);
                document.getElementsByTagName('html')[0].classList.add('casino-quickinfo-open');
                dialogRef.afterClosed().subscribe(() => {
                    this.moreInfoClicked = false;
                    if (this.quickInfoScrollY) {
                        window.scrollTo(0, this.quickInfoScrollY);
                    }
                    this.isTouch ? this.hideInfo() : this.handleQuickInfoonESC();
                });
            }
            this.quickInfoOverlayTopAllignment();
        }
    }

    recentlyPlayedTracking() {
        if (this.gameLaunchedFrom != 'RecentlyPlayed_widget_Favourites' && this.gameLaunchedFrom != 'RecentlyPlayed_widget_RecentlyPlayed') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'games',
                'component.LabelEvent': 'game interactions',
                'component.ActionEvent': 'close',
                'component.PositionEvent': this.data.gamePosition,
                'component.LocationEvent': 'bacon',
                'component.EventDetails': 'more info overlay',
                'component.URLClicked': 'not applicable',
            });
        }
        document.getElementsByTagName('html')[0].classList.remove('casino-quickinfo-open');
    }
    handleQuickInfoonESC() {
        document.getElementsByTagName('html')[0].classList.remove('casino-quickinfo-open');
        // if ((!closeDialogData || !closeDialogData.noNavigation) && currenturl) {
        //     this.navigation.goTo(currenturl);
        // }
        this.removeQuickInfoQueryParameterFromUrl();
    }
    removeQuickInfoQueryParameterFromUrl() {
        const casinoNpmParams = this.casinoNpmParamsService.getcasinoCoreNpmParams();
        if (casinoNpmParams && casinoNpmParams.isDll) {
            if (this.game.gameContainer != 'Overlay' && this.game.gameContainer != 'Widget') {
                const param = 'game-info';
                if (location.href.indexOf(param) > -1 && !document.getElementsByTagName('html')[0].classList.contains('casino-quickinfo-open')) {
                    window.history.pushState(location.href, '', location.href.substr(0, location.href.indexOf(param) - 1));
                }
            }
        }
    }
    launchGame(game: any, isDemo: boolean): void {
        const liveCasinoLoungeConfig = this.configProviderService.provideLiveCasinoLoungeConfig();
        const defaultCategoryInfo = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(this.lobbyType)?.categoryInfo;
        const isLiveLoungeDefaultCategory: boolean =
            defaultCategoryInfo && defaultCategoryInfo.categoryid === liveCasinoLoungeConfig?.defaultCategoryId;
        if (this.data && this.data.gameLaunchedFrom && this.newQuickInfoStyle) {
            if (this.data.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed') {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'RecentlyPlayed_Widget',
                    'component.LabelEvent': 'RecentlyPlayed_QuickInfo_Overlay',
                    'component.ActionEvent': 'CTA_Click',
                    'component.PositionEvent': this.data.game.gameId + '_' + this.data.gamePosition,
                    'component.LocationEvent': this.game.lobbyType,
                });
            }
            if (this.data && this.data.gameLaunchedFrom && this.data.gameLaunchedFrom === 'RecentlyPlayed_widget_Favourites') {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'RecentlyPlayed_Widget',
                    'component.LabelEvent': 'Favourites_QuickInfo_Overlay',
                    'component.ActionEvent': 'CTA_Click',
                    'component.PositionEvent': this.data.game.gameId + '_' + this.data.gamePosition,
                    'component.LocationEvent': this.game.lobbyType,
                });
            }
        }
        if (!this.newQuickInfoStyle && this.product) {
            if (this.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed') {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'RecentlyPlayed_Widget',
                    'component.LabelEvent': 'RecentlyPlayed_QuickInfo_Overlay',
                    'component.ActionEvent': 'CTA_Click',
                    'component.PositionEvent': this.game.gameId + '_' + this.gamePosition,
                    'component.LocationEvent': this.game.lobbyType,
                });
            }
            if (this.gameLaunchedFrom === 'RecentlyPlayed_widget_Favourites') {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'RecentlyPlayed_Widget',
                    'component.LabelEvent': 'Favourites_QuickInfo_Overlay',
                    'component.ActionEvent': 'CTA_Click',
                    'component.PositionEvent': this.game.gameId + '_' + this.gamePosition,
                    'component.LocationEvent': this.game.lobbyType,
                });
            }
        }
        if (this.demoEnabled) {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'Games Options',
                'component.LabelEvent': 'Quick info overlay',
                'component.ActionEvent': 'CTA - Clicked',
                'component.PositionEvent': this.gameTitle,
                'component.LocationEvent': isDemo ? 'Play Demo' : 'Playnow',
                'component.EventDetails': 'User clicked on CTA in the quick info overlay',
                'component.URLClicked': 'Quick Info overlay - Enabled',
            });
        }
        if (this.isTouch) {
            this.hideInfo();
        }
        if (this.product == '' && this.data && this.data.product) {
            this.product = this.data.product;
        }
        let gameHomeUrl: string = '';
        if (this.casinoWidgetService.isCasinoWidget()) {
            gameHomeUrl = document.referrer;
        } else {
            if (this.isTouch) {
                if (this.lobbyType?.toLowerCase() == 'dice') {
                    gameHomeUrl = this.urlUtilityService.getDiceSmartUrl();
                }
            } else {
                gameHomeUrl = window.location.href;
            }
        }
        this.casinoManager.setGameHomeUrl(gameHomeUrl);
        const gameObj: any = {
            position: this.gamePosition,
            section: this.gameLaunchedFrom,
            gameContainerLocation: this.gameContainerLocation,
            widgetFrameClr: this.widgetFrameClr,
        };
        if (this.game.fromCozybingo) {
            this.bingoGameLaunchService.initiateGameLaunch(
                game.name,
                'BINGO',
                game.bingo_type,
                'room',
                this.thumbImageSrc,
                game.id,
                true,
                undefined,
                gameObj,
            );
        } else {
            const gameModel = new GameLaunch();
            gameModel.game = game;
            gameModel.isTouch = this.isTouch;
            gameModel.gamePosition = this.gamePosition;
            gameModel.categoryType = this.categoryType;
            gameModel.from = 'q';
            gameModel.replaceRouteInHistory = false;
            gameModel.isDemoLaunch = isDemo;
            gameModel.skipUnfinGame = false;
            gameModel.gameLaunchedFrom = this.gameLaunchedFrom;
            gameModel.gridPosition = this.gridPosition;
            gameModel.specialProduct = this.product;
            gameModel.gameContainer = this.game.gameContainer;
            gameModel.clickTimeStamp = this.casinoCoreGameLaunchService.currentTimeStamp();
            if (liveCasinoLoungeConfig && isLiveLoungeDefaultCategory) {
                gameModel.audioFrom = true;
            }
            this.casinoCoreGameLaunchService.launchGame(gameModel);
        }
    }

    toggleFavourite(game: any, $event: any) {
        $event.stopImmediatePropagation();
        if (this.user.isAuthenticated) {
            if (
                !this.isTouch &&
                !this.newQuickInfoStyle &&
                this.gameLaunchedFrom &&
                (this.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed' || this.gameLaunchedFrom === 'RecentlyPlayed_widget_Favourites')
            ) {
                this.trackFavforDisbaledQuickInfoforDesktop(game);
            }
            if (
                this.isTouch &&
                this.data.gameLaunchedFrom &&
                this.newQuickInfoStyle &&
                (this.data.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed' ||
                    this.data.gameLaunchedFrom === 'RecentlyPlayed_widget_Favourites')
            ) {
                this.trackFavfromRpWidget(game);
            }
            if (
                this.isTouch &&
                !this.newQuickInfoStyle &&
                this.data.gameLaunchedFrom &&
                (this.data.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed' ||
                    this.data.gameLaunchedFrom === 'RecentlyPlayed_widget_Favourites')
            ) {
                this.trackFavforDisbaledQuickInfoforDesktop(game);
            }
            this.favouriteService.toggleGameFavourite(game);
            this.isFavourite = !this.isFavourite;
        } else {
            if (this.isTouch) {
                this.hideInfo();
            }
            this.cookie.putObject('favouriteGame', game);
            if (this.casinoWidgetService.isCasinoWidget()) {
                this.casinoWidgetService.postMessageToParent('UserAction', { promptLogin: true, loginMessageKey: 'Favourite' });
                return;
            }
            const loginResponseObj: any = {
                loginMessageKey: 'Favourite',
            };
            this.loginDialog.open(loginResponseObj);
        }
    }

    trackFavforDisbaledQuickInfoforDesktop(game: any) {
        if (this.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed' || this.gameLaunchedFrom === 'RecentlyPlayed_widget_Favourites') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'Favourites',
                'component.ActionEvent': this.isFavouriteGame(game) ? 'UnFavourited' : 'Favourited',
                'component.PositionEvent': this.game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this.game.lobbyType,
            });
        }
    }

    trackFavfromRpWidget(game: any) {
        if (this.data.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'RecentlyPlayed_QuickInfo_Overlay',
                'component.ActionEvent': this.isFavouriteGame(game) ? 'UnFavourited' : 'Favourited',
                'component.PositionEvent': game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this.game.lobbyType,
            });
        }
        if (this.data.gameLaunchedFrom === 'RecentlyPlayed_widget_Favourites') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'Favourites_QuickInfo_Overlay',
                'component.ActionEvent': this.isFavouriteGame(game) ? 'UnFavourited' : 'Favourited',
                'component.PositionEvent': game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': game.lobbyType,
            });
        }
    }
    hideInfo($event: any = null): void {
        if ($event) {
            if (this.data.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed') {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'RecentlyPlayed_Widget',
                    'component.LabelEvent': 'RecentlyPlayed_QuickInfo_Overlay',
                    'component.ActionEvent': 'Close Overlay - Gradient Click',
                    'component.PositionEvent': this.data.game.gameId + '_' + this.data.gamePosition,
                    'component.LocationEvent': this.game.lobbyType,
                });
            }
            if (this.data.gameLaunchedFrom === 'RecentlyPlayed_widget_Favourites') {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'RecentlyPlayed_Widget',
                    'component.LabelEvent': 'Favourites_QuickInfo_Overlay',
                    'component.ActionEvent': 'Close Overlay - Gradient Click',
                    'component.PositionEvent': this.data.game.gameId + '_' + this.data.gamePosition,
                    'component.LocationEvent': this.game.lobbyType,
                });
            }
            if (this.demoEnabled) {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'Games Options',
                    'component.LabelEvent': 'Quick info overlay',
                    'component.ActionEvent': 'CTA - Clicked',
                    'component.PositionEvent': this.gameTitle,
                    'component.LocationEvent': 'close(x)',
                    'component.EventDetails': 'User clicked on CTA in the quick info overlay',
                    'component.URLClicked': 'Quick Info overlay - Enabled',
                });
            }
        }
        this.recentlyPlayedTracking();
        $event && $event.stopImmediatePropagation();
        this.quickInfoService.closeGameQuickInfoDialog();
    }
    GameJackpotValue(game: any) {
        return this.jackpotservice.getGameJackpotValue(game.gameId);
    }

    isFavouriteGame(game: any) {
        //  return false;
        return this.favouriteService.checkForFavouriteGame(game);
    }

    setImageIcon() {
        if (this.icon && this.icon.src) {
            this.src = JSON.parse(JSON.stringify(this.icon.src));
            if (this.newQuickInfoStyle || this.useQuickInfo) {
                this.mobileQuickInfoSrc = this.casinoLobbyService.getImagePath(this.game.gameId, 'square');
            }
        }
        if (this.game.fromCozybingo) {
            this.src = this.game['sitecoreInfo']['desktopImage']['src'];
        }
        const feature: any = this.configProviderService.provideFeaturesConfig();
        if (feature.enableGameIconTheming) {
            this.src = this.casinoLobbyService.appendTheme(this.src, this.game.gameId);
            this.mobileQuickInfoSrc = this.casinoLobbyService.appendTheme(this.mobileQuickInfoSrc, this.game.gameId);
        }
    }

    setPlayNowBtnWidth(gameModel: any) {
        if (!gameModel || !gameModel.icon || this.enableUkMouseOverStyle) {
            this.playNowBtnWidth = 93;
        } else {
            const gameConfig: any = this.configProviderService.provideGameConfig();
            switch (gameConfig.iconTypeMapping[gameModel.icon]) {
                case gameConfig.iconTypeMapping[0]: {
                    this.playNowBtnWidth = 93;
                    break;
                }
                case gameConfig.iconTypeMapping[1]: {
                    this.playNowBtnWidth = 93;
                    break;
                }
                case gameConfig.iconTypeMapping[2]: {
                    this.playNowBtnWidth = 60;
                    break;
                }
                case gameConfig.iconTypeMapping[3]: {
                    this.playNowBtnWidth = 93;
                    break;
                }
                case gameConfig.iconTypeMapping[4]: {
                    this.playNowBtnWidth = 90;
                    break;
                }
                case gameConfig.iconTypeMapping[5]: {
                    this.playNowBtnWidth = 65;
                    break;
                }
            }
        }
    }

    quickInfoOverlayTopAllignment() {
        const featuresConfig = this.configProviderService.provideFeaturesConfig();
        if (featuresConfig.enableOverlayMobileSearchTopPosition) {
            if (document.querySelector('.content-messages-header-top .content-message-container')) {
                if (document.querySelector('.casino-lobby-container-main .casino-qi-overlay') as HTMLElement) {
                    (document.querySelector('.casino-lobby-container-main .casino-qi-overlay') as HTMLElement).style.top =
                        (document.querySelector('.content-messages-header-top .content-message-container') as HTMLElement).clientHeight + 'px';
                }
                if (document.querySelector('.casino-lobby-container-main .casino-qi-noverlay') as HTMLElement) {
                    (document.querySelector('.casino-lobby-container-main .casino-qi-noverlay') as HTMLElement).style.top =
                        (document.querySelector('.content-messages-header-top .content-message-container') as HTMLElement).clientHeight + 'px';
                }
            }
        }
    }

    appendQuickInfoQueryParameterToUrl() {
        const casinoNpmParams = this.casinoNpmParamsService.getcasinoCoreNpmParams();
        if (casinoNpmParams && casinoNpmParams.isDll) {
            if (
                !(this.game.fromCasino != undefined && !this.game.fromCasino) &&
                this.game.gameContainer != 'Overlay' &&
                this.game.gameContainer != 'Widget'
            ) {
                const windowUrl: string = window.location.href;
                let pushStateUrl: string;
                let queryStrings: string = '';
                if (this.urlHasQueryStrings(windowUrl)) {
                    pushStateUrl = windowUrl.substr(0, windowUrl.indexOf('?'));
                    queryStrings = windowUrl.substr(windowUrl.indexOf('?'));
                } else {
                    pushStateUrl = windowUrl;
                }
                if (this.isUrlEndsWithSlash(pushStateUrl)) {
                    pushStateUrl = pushStateUrl + 'game-info/' + this.game.gameId;
                } else {
                    pushStateUrl = pushStateUrl + '/game-info/' + this.game.gameId;
                }
                pushStateUrl = pushStateUrl + queryStrings;
                window.history.pushState(location.href, '', pushStateUrl);
            }
        }
    }

    isUrlEndsWithSlash(url: string): boolean {
        if (url.lastIndexOf('/') + 1 === url.length) {
            return true;
        }
        return false;
    }

    urlHasQueryStrings(url: string): boolean {
        if (url && url.indexOf('?') > -1) {
            return true;
        }
        return false;
    }

    ngOnDestroy() {
        if (this.freespinObservable) {
            this.freespinObservable.unsubscribe();
            this.destroy$.next();
            this.destroy$.complete();
        }
    }
}

export function gameTileReduction(gameClass: string, overlayBgClass: string): void {
    if (document.querySelector('.click-interaction')) {
        const qiHoverDiv: any = document.querySelectorAll('.' + gameClass);
        if (qiHoverDiv.length > 0) {
            for (let i = 0; i < qiHoverDiv.length; i++) {
                const qiHoverContent: HTMLElement = qiHoverDiv[i].querySelector('.casino-lobby-container-main .casino-qi-container-d');
                const qiHoverOverlay: HTMLElement = qiHoverDiv[i].querySelector('.' + overlayBgClass);
                const qiImage: HTMLElement = qiHoverDiv[i].querySelector('.image-load-div');
                const gameTileFreespins: HTMLElement = qiHoverDiv[i].querySelector(
                    '.casino-lobby-container-main .casino-qi-container-d .casino-freespins-alignment',
                );
                const newgridTileHover: HTMLElement = qiHoverDiv[i].querySelector('.image-section-newgrid');
                if (qiHoverContent && qiHoverOverlay && qiImage) {
                    qiHoverDiv[i].onmousedown = function () {
                        qiHoverContent.style.transform = 'scale(1)';
                        qiHoverOverlay.style.transform = 'scale(1)';
                        qiImage.style.transform = 'scale(1)';
                        if (gameTileFreespins) {
                            gameTileFreespins.style.top = '0';
                            gameTileFreespins.style.right = '0';
                        }
                        if (newgridTileHover) {
                            newgridTileHover.style.setProperty('--click-interaction-transform', 'scale(1)');
                        }
                    };
                    qiHoverDiv[i].onmouseup = function () {
                        qiHoverContent.style.transform = 'scale(1.07)';
                        qiHoverOverlay.style.transform = 'scale(1.07)';
                        qiImage.style.transform = 'scale(1.07)';
                        if (gameTileFreespins) {
                            gameTileFreespins.style.top = '0.25rem';
                            gameTileFreespins.style.right = '0.25rem';
                        }
                        if (newgridTileHover) {
                            qiHoverContent.style.transform = 'scale(1.05)';
                            qiHoverOverlay.style.transform = 'scale(1)';
                            qiImage.style.transform = 'scale(1.05)';
                            newgridTileHover.style.setProperty('--click-interaction-transform', 'scale(1.05)');
                        }
                    };
                    qiHoverDiv[i].onmouseover = function () {
                        qiHoverOverlay.style.transform = 'scale(1.07)';
                        qiImage.style.transform = 'scale(1.07)';
                        if (newgridTileHover) {
                            qiHoverOverlay.style.transform = 'scale(1)';
                            qiImage.style.transform = 'scale(1.05)';
                            newgridTileHover.style.setProperty('--click-interaction-transform', 'scale(1.05)');
                        }
                    };
                    qiHoverDiv[i].onmouseout = function () {
                        qiHoverOverlay.style.transform = 'scale(1)';
                        qiImage.style.transform = 'scale(1)';
                    };
                }
            }
        }
    }
}
