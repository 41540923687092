import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { CozyBingoDataService } from '@casinocore/platform/core';

import { CozyBingoGameInfoComponent } from './cozybingo-game-info.component';

@Component({
    selector: 'cc-cozy-bingo-game',
    templateUrl: 'cozy-bingo-game.component.html',
    standalone: true,
    imports: [NgIf, CozyBingoGameInfoComponent],
})
export class CozyBingoGameComponent implements OnInit {
    @Input() roomInfo: any;
    @Input() gamePosition: any;
    @Input() gameLaunchedFrom: any;
    @Input() product: any;
    @Input() lobbyType: any;
    @Input() widgetFrameClr: any;
    featureConfigs: any;
    globalMessages: any;
    categoryList: any;
    bingoConfiguration: any;
    constructor(private cozybingoService: CozyBingoDataService) {}
    ngOnInit() {
        this.featureConfigs = this.cozybingoService.getFeatureConfigs();
        this.globalMessages = this.cozybingoService.getGlobalMessages();
        this.categoryList = this.cozybingoService.getcategoryList();
        this.bingoConfiguration = this.cozybingoService.getbingoConfiguration();
    }
}
