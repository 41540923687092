<div
    class="casino-opt-mini-teasers-bg"
    [ngClass]="[miniTeaserEnabledClass, miniTeaserGridAlignment]"
    [ngStyle]="isOptMiniTeasersEnabled ? { background: 'url(' + (mercuryBgImage && mercuryBgImage.src | sitecore_image_resize) + ')' } : ''">
    <div
        class="casino-opt-teasers-crousale"
        ccIntersectionObserver
        [intersectionDebounce]="0"
        (visibilityChange)="onVisibilityChanged($event)"
        [ngClass]="[arrowClass, teaserType, teaserDefaultSliderArrowBg, teaserDefaultSliderArrowShape, teaserDefaultSliderBullet, teaserProgressbar]"
        [style.width]="!isOptMiniTeasersEnabled ? '100%' : ''"
        *ngIf="teasers.length > 0"
        id="OptTeaserCarousel_{{ identifierClass }}">
        <div
            class="opt-teasers"
            [ngClass]="{ 'opt-arrows-single': teasers.length < 2 }"
            *ngIf="teasersConfigChanged"
            [style.height.px]="displayHeight">
            <div (mouseenter)="onTeaserHover(true)" (mouseleave)="onTeaserHover(false)" ccLongPress (onMouseLongPress)="onTeaserLongPress($event)">
                <vn-swiper #swiper *ngIf="teasersConfigChanged" [swiperOptions]="swiperOptionsOptTeasersNpm">
                    <div class="swiper-slide" *ngFor="let teaser of teasers; let i = index">
                        <cc-opt-teaser-item-npm
                            [teaser]="teaser"
                            [activeSlides]="activeSlides"
                            [teaserIndex]="i"
                            [isHover]="isMouseOverOnTeaser"
                            [teaserHeight]="teaserHeight"
                            [teaserCategory]="teaserCategory"
                            [lobbyType]="lobbyType"
                            [gameContainer]="gameContainer"
                            [identifierClass]="identifierClass"
                            [isOptTeaserPhase3]="isOptTeaserPhase3"
                            [isOptMiniTeasersEnabled]="isOptMiniTeasersEnabled"
                            (onVideoEndEvent)="getVideoEndEvent()"
                            (onTimeChangeEvent)="getTimeChangeEvent($event)"
                            (onVideoLoadedEvent)="onVideoLoaded()"
                            (videoErrorEvent)="videoError()"
                            [isNPM]="isNPM"
                            [isTouch]="isTouch"
                            [isNewSportsNative]="isNewSportsNative"
                            [teaserDynaconConfigs]="teaserDynaconConfigs"
                            [globalTeaserSettings]="globalTeaserSettings"
                            [showJackpotCurrencyOnRight]="showJackpotCurrencyOnRight"
                            [jackpotGroupNameMapTeaserNPM]="jackpotGroupNameMapTeaserNPM"
                            [globalTeaserStyles]="globalTeaserStyles">
                        </cc-opt-teaser-item-npm>
                        <div *ngIf="displayProgressBar" class="casino-line-bg">
                            <mat-progress-bar [mode]="'determinate'" [value]="widths[i]"> </mat-progress-bar>
                        </div>
                    </div>
                </vn-swiper>
            </div>
        </div>
    </div>
    <div
        class="casino-opt-mini-teasers-crousale"
        [ngClass]="[
            miniArrowClass,
            teaserType,
            teaserDefaultSliderArrowBg,
            teaserDefaultSliderArrowShape,
            teaserDefaultSliderBullet,
            teaserProgressbar
        ]"
        *ngIf="isOptMiniTeasersEnabled"
        id="OptTeaserCarousel_{{ identifierClass }}">
        <div class="opt-teasers" [ngClass]="{ 'opt-arrows-single': optMiniTeasers.length < 2 }" *ngIf="optTeaser" [style.height.px]="teaserHeight">
            <cc-opt-mini-teasers-npm
                [teaserCategory]="teaserCategory"
                [teasersSource]="optminiTeaserSource"
                [lobbyType]="lobbyType"
                [gameContainer]="gameContainer"
                [isNPM]="isNPM"
                [isTouch]="isTouch"
                [isNewSportsNative]="isNewSportsNative"
                [teaserDynaconConfigs]="teaserDynaconConfigs"
                [globalTeaserSettings]="globalTeaserSettings"
                [showJackpotCurrencyOnRight]="showJackpotCurrencyOnRight"
                [jackpotGroupNameMapTeaserNPM]="jackpotGroupNameMapTeaserNPM"
                [globalTeaserStyles]="globalTeaserStyles">
            </cc-opt-mini-teasers-npm>
        </div>
    </div>
    <div class="casino-playbingo-btn" [ngClass]="openBingoLobbyButtonPosition | lowercase" *ngIf="enablebingoplay && bingocta && teasers.length > 0">
        <!-- <div
            class="btn btn-primary playbingo-cta"
            [innerHtml]="bingocta.name | trustAsHtml"
            [ngClass]="{ 'epcot-btn': enableEpcotSubNavigation }"
            (click)="playbingoclick(bingocta)"></div> -->
        <cs-gametile-cta
            [isLegacy]="isLegacy"
            [kind]="'primary'"
            [size]="'large'"
            [variant]="'filled'"
            (click)="playbingoclick(bingocta)"
            [csDecoratorClass]="'playbingo-cta'"
            [csDecoratorClass]="{ 'epcot-btn': enableEpcotSubNavigation }"
            [cta_text]="bingocta.name">
        </cs-gametile-cta>
    </div>
</div>
