import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';
import { PlatformBingoApiService } from '../platform-api/platform-bingo-api.service';
import { UrlUtilityService } from '../utilities/url-utility.service';
import { ApiPathsHelperService } from './api-paths-helper-service';

@Injectable({
    providedIn: 'root',
})
export class BingoFavouriteService {
    _favouriteAddedMessage: string;
    _favouriteRemovedMessage: string;
    _favouriteAddedErrorMessage: string;
    _favouriteRemovedErrorMessage: string;

    constructor(
        private apiBingoService: PlatformBingoApiService,
        private api: PlatformApiService,
        private router: Router,
        private urlUtilityService: UrlUtilityService,
        private apiPathsHelperService: ApiPathsHelperService,
        private readonly configProviderService: ConfigProviderService,
    ) {}

    addFavourite(roomId: string, accountName: string) {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        if (apiPathsConfig.bingo['newApiStructure'] && apiPathsConfig.bingo['newApiStructure'] == 'enabled') {
            return this.apiBingoService.get(apiPathsConfig.bingo['bgSetFavourite'], { roomId: roomId, isFavourite: true, accountName: accountName });
        } else {
            const endPoint = this.apiPathsHelperService.transformApi(
                apiPathsConfig.bingo['bgSetFavourite'],
                this.urlUtilityService.getBingoApiBaseUrl(),
            );
            return this.api.get(endPoint, { roomId: roomId, isFavourite: true, accountName: accountName });
        }
    }

    removeFavourite(roomId: string, accountName: string) {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        if (apiPathsConfig.bingo['newApiStructure'] && apiPathsConfig.bingo['newApiStructure'] == 'enabled') {
            return this.apiBingoService.get(apiPathsConfig.bingo['bgSetFavourite'], { roomId: roomId, isFavourite: false, accountName: accountName });
        } else {
            const endPoint = this.apiPathsHelperService.transformApi(
                apiPathsConfig.bingo['bgSetFavourite'],
                this.urlUtilityService.getBingoApiBaseUrl(),
            );
            return this.api.get(endPoint, { roomId: roomId, isFavourite: false, accountName: accountName });
        }
    }
    // NFR - Services optimization - Remove
    getFavouriteUrl(roomId: string, isFavourite: boolean, favAddClass: string, favRemClass: string): string {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        const result = this.apiPathsHelperService.transformApi(
            apiPathsConfig.bingo['bgForcedFavourite'],
            this.urlUtilityService.getBingoApiBaseUrl(),
            roomId,
            isFavourite,
            favAddClass,
            favRemClass,
            this.router.url,
        );
        return result;
    }

    getFavouriteWidgetGames(sso: string, accountName: string) {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        if (apiPathsConfig.bingo['newApiStructure'] && apiPathsConfig.bingo['newApiStructure'] == 'enabled') {
            return this.apiBingoService.get(apiPathsConfig.bingo['bgSchedule'], { sso: sso, accountName: accountName });
        } else {
            const endPoint = this.apiPathsHelperService.transformApi(apiPathsConfig.bingo['bgSchedule'], this.urlUtilityService.getBingoApiBaseUrl());
            return this.api.get(endPoint, { sso: sso, accountName: accountName });
        }
    }
}
