import { NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';

import { MessageQueueService, UserService } from '@frontend/vanilla/core';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { ImageLoaderComponent } from '../image-loader/image-loader.component';
import { CasinoManager } from '../shared/casino-manager.service';
import { ConstService } from '../shared/const.service';
import { FavouriteService } from '../shared/favourite.service';
import { CasinoCoreGameLaunchFactoryService } from '../shared/gamelaunchfactory.service';
import { JackpotDataService } from '../shared/jackpotdata-service';
import { UnfinishedGamesService } from '../shared/unfinished-games-service';

@Component({
    selector: 'cc-game-embed',
    templateUrl: 'game-embed.component.html',
    standalone: true,
    imports: [NgStyle, ImageLoaderComponent, NgIf, NgClass],
})
export class GameEmbedComponent implements OnInit, OnChanges, OnDestroy {
    @Input() game: any;
    @Input() radius: number;
    @Input() categorytype: string;
    @Input() lobbyType: any;
    @Output() embedGameClicked: EventEmitter<any> = new EventEmitter<any>();
    Navigator: any;
    FavouriteGames: any;
    showJackpotOnGameTiles: any;
    unfinishedStyle: any;
    unfinishedBG: any;
    unfinishedBGOpacity: any;
    unfinishedText: any;
    gameClass: string[] = [];
    forceVisible: boolean = true;
    useFavouriteGames: boolean;
    useQuickInfo: boolean;
    showGameTitle: boolean;
    isTouch: boolean;
    isOverlay: boolean;
    QuickInfoDisplay = new Map();
    unfinishedFeedSubscription: any;
    jackpotHandle: any;
    enableTickingForJackpotOnGameTiles: boolean;
    newJackpotRibbonStyling: boolean;
    enableHotIcon: boolean;
    hotIcon: any;
    epcotHotIcon: any;
    gameService: any;
    enableEpcotSubNavigation: boolean;
    jpRibbonStyles: { jpBgColor: string; jpTextColor: string };
    constructor(
        private user: UserService,
        private favouriteService: FavouriteService,
        private messageQueue: MessageQueueService,
        private unfinishedGamesService: UnfinishedGamesService,
        private casinoManager: CasinoManager,
        private jackpotDataService: JackpotDataService,
        private configProviderService: ConfigProviderService,
        private gameLaunchFactoryService: CasinoCoreGameLaunchFactoryService,
    ) {}

    ngOnInit() {
        this.gameService = this.gameLaunchFactoryService.getGameService();
        if (this.game && !this.game.icon) {
            this.game.icon = {
                alt: '',
                height: 0,
                width: 0,
            };
        }
        const global = this.configProviderService.provideGlobalConfig();
        const jackpotData = this.configProviderService.provideJackpotConfig();
        const subnavConfig: any = this.configProviderService.provideSubNavigationConfig();
        this.enableEpcotSubNavigation = subnavConfig.enableEpcotSubNavigation;
        this.enableTickingForJackpotOnGameTiles = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.lobbyType,
            jackpotData.enableJpTickingForLobbyType,
        );
        this.showJackpotOnGameTiles = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, jackpotData.showJackpotOnGameTilesLobbyType);
        this.unfinishedStyle = global.unfinishedStyle.bageStyle;
        this.unfinishedBG = global.unfinishedStyle.unfinishedBackground ? global.unfinishedStyle.unfinishedBackground : 'transparent';
        this.unfinishedBGOpacity = global.unfinishedStyle.unfinishedBGOpacity ? global.unfinishedStyle.unfinishedBGOpacity : '0.5';
        this.unfinishedText = global.messages.UNFINISHED;
        const features = this.configProviderService.provideFeaturesConfig();
        const gameConfig = this.configProviderService.provideGameConfig();
        this.isTouch = this.casinoManager.isTouch();
        this.useFavouriteGames = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.favouriteGamesLobbyType);
        this.useQuickInfo = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.enableQuickInfoLobbyType);
        this.showGameTitle = features.showGameTitle;
        this.setGameClass();
        this.game.isFavourite = this.favouriteService.checkForFavouriteGame(this.game);
        this.QuickInfoDisplay = new Map();
        this.subscribrToUnfinishedFeed();
        this.setJackPotValue();
        this.subscribtToJackpotFeed();
        this.newJackpotRibbonStyling = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.newJackpotRibbonStyling);
        this.enableHotIcon = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.enableHotIcon);
        this.hotIcon = gameConfig.host + features.hotIconPath.hotIcon;
        this.epcotHotIcon = gameConfig.host + features.hotIconPath.epcotHotIcon;
    }

    ngOnDestroy() {
        this.unfinishedFeedSubscription.unsubscribe();
        this.jackpotHandle.unsubscribe();
    }

    onResize() {
        this.isTouch = this.casinoManager.isTouch();
    }
    ngOnChanges() {
        const features = this.configProviderService.provideFeaturesConfig();
        this.newJackpotRibbonStyling = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.newJackpotRibbonStyling);
    }
    toggleFavourite(game: any, $event: any) {
        if (this.user.isAuthenticated) {
            $event.stopImmediatePropagation();
            this.FavouriteGames.toggleGameFavourite(game);
        } else {
            this.Navigator.navigateToLogin();
        }
    }

    displayQuickInfo(game: any, $event: any) {
        $event.stopImmediatePropagation();
        this.QuickInfoDisplay.set(game.gameId + this.categorytype, true);
    }

    hideQuickInfo(game: any) {
        this.QuickInfoDisplay.set(game.gameId + this.categorytype, false);
    }

    mouseOver(game: any) {
        this.gameService.overlayVisibilityStatus.subscribe((overlayStatus: any) => {
            if (!this.isTouch && !overlayStatus) {
                this.QuickInfoDisplay.set(game.gameId + this.categorytype, true);
            }
        });
    }

    mouseLeave(game: any) {
        if (!this.isTouch) {
            this.QuickInfoDisplay.set(game.gameId + this.categorytype, false);
        }
    }

    //Manish- I don't see any use of below method.

    //Need to call this function for to track game launch based on position
    // gameLaunchTracking() {
    //     //Game launch tracking implementation
    //     let gameLuanchCategory;
    //     let selectedCategoryType = this.casinoManager.getActiveCategory();

    //     if (selectedCategoryType) {
    //         gameLuanchCategory = selectedCategoryType;
    //     }

    //     if (this.categorytype) {
    //         gameLuanchCategory = gameLuanchCategory + '/' + this.categorytype;
    //     }

    // }

    action(game: any) {
        this.messageQueue.clear({ clearPersistent: true });
        this.embedGameClicked.emit(game);
    }
    setUnfinishedImageStyle() {
        if (
            this.game.isUnfinishedBadge &&
            (this.categorytype === ConstService.RECENT_GAMES_CATEGORY_ID || this.categorytype === ConstService.UNFINISHED_GAMES_CATEGORY_ID)
        ) {
            return 'opacity: ' + this.unfinishedBGOpacity + ' ; border-radius : ' + this.radius;
        }

        return null;
    }
    setBageStyle() {
        let fullStyle = this.unfinishedStyle;

        if (this.unfinishedText.length > 11) {
            fullStyle += '; font-size: 0.56em;';
        }

        const isiOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
        if (
            this.game.isUnfinishedBadge &&
            isiOS &&
            (this.categorytype === ConstService.RECENT_GAMES_CATEGORY_ID || this.categorytype === ConstService.UNFINISHED_GAMES_CATEGORY_ID)
        ) {
            const textLength = this.unfinishedText.length * 5.5;
            fullStyle += ' ; padding : 2px ' + textLength + 'px 2px 2px ; width: -web-fit-content !important;';
        }

        return fullStyle;
    }
    subscribrToUnfinishedFeed() {
        this.unfinishedFeedSubscription = this.unfinishedGamesService.unfinishedFeedObservable.subscribe(() => {
            this.game.isUnfinishedBadge = this.unfinishedGamesService.checkForUnfinishedGame(this.game);
            if (
                this.game.isUnfinishedBadge &&
                (this.categorytype === ConstService.RECENT_GAMES_CATEGORY_ID || this.categorytype === ConstService.UNFINISHED_GAMES_CATEGORY_ID)
            ) {
                this.gameClass.push('showunfinishedbage');
            }
        });
    }

    setJackPotValue() {
        const jackpotValue = this.jackpotDataService.getGameJackpotValue(this.game.gameId);
        const enableRibbonConfig = this.configProviderService.provideJackpotConfig().enableRibbonConfig;
        if (jackpotValue) {
            this.game.jackpotValue = jackpotValue.amount;
            this.game.isHotJackpotGroup = jackpotValue.isHotJackpotGroup;
            if (enableRibbonConfig) {
                this.jpRibbonStyles = this.jackpotDataService.getJackpotRibbonStyles(jackpotValue);
            }
        }
    }

    subscribtToJackpotFeed() {
        this.jackpotHandle = this.jackpotDataService.jackpotFeedObservable.subscribe(() => {
            this.setJackPotValue();
        });
        if (this.enableTickingForJackpotOnGameTiles) {
            this.jackpotDataService.jpTickingObservable.subscribe(() => {
                this.setJackPotValue();
            });
        }
    }
    private setGameClass() {
        if (this.game) {
            if (!this.user.isAuthenticated && this.game.demoAvailable) {
                this.gameClass.push('demo');
            }
            if (this.game.isNewBadge) {
                this.gameClass.push('new');
            }
            if (this.game.isCashbackBadge) {
                this.gameClass.push('cashback');
            }
            if (this.game.isCasinoLiveBadge) {
                this.gameClass.push('casino-live');
            }
            if (this.game.isFlagBadge) {
                this.gameClass.push('flag');
            }
            if (this.game['isExclusiveBadge']) {
                this.gameClass.push('exclusive');
            }
            if (
                this.game.isUnfinishedBadge &&
                (this.categorytype === ConstService.RECENT_GAMES_CATEGORY_ID || this.categorytype === ConstService.UNFINISHED_GAMES_CATEGORY_ID)
            ) {
                this.gameClass.push('showunfinishedbage');
            }
        }
        if (this.gameClass.length > 0) {
            this.gameClass = ['game-badge'].concat(this.gameClass);
        }
    }
}
