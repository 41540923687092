import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LibMockService {
    quickInfoService: any;
    sportsOverLayService: any;
    bootstrapService: any;
    dialogRef: any;
    constructor() {}

    setQuickInfoService(quickInfoService: any) {
        this.quickInfoService = quickInfoService;
    }

    getQuickInfoService() {
        return this.quickInfoService;
    }

    setsportsOverLayService(sportsOverLayService: any) {
        this.sportsOverLayService = sportsOverLayService;
    }

    getsportsOverLayService() {
        return this.sportsOverLayService;
    }
    setCasinoBootstrapService(casinoBootstrapService: any) {
        this.bootstrapService = casinoBootstrapService;
    }
    getCasinoBootstrapService() {
        return this.bootstrapService;
    }
    setDialogRef(dialogRef: any) {
        this.dialogRef = dialogRef;
    }
    getDialogRef() {
        return this.dialogRef;
    }
    closeDialogRef(dialogRef: any) {
        dialogRef.close();
    }
}
