import { NgClass, NgIf, NgStyle, UpperCasePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
    CasinoCoreGamelaunchService,
    CasinoCoreNpmParamsService,
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    DesignSystemEnablerService,
    FavouriteService,
    FreespinSummaryService,
    GameDataManager,
    GameLaunch,
    JackpotDataService,
    OpenLoginDialog,
    UrlUtilityService,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, GameTileCtaComponent } from '@frontend/casino-ui';
import { CookieService, DeviceService, DslService, DynamicHtmlDirective, TrackingService, UserService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

import { FreeSpinsQuickInfoComponent } from '../freespins-summary/freespins-quick-info/freespins-quick-info.component';
import { LibMockService } from '../services/lib-mock.service';
import { QuickInfoService } from '../services/quick-info.service';
import { StickersJackpotComponent } from '../stickers-jackpot/stickers-jackpot.component';

@Component({
    //moduleId: module.id,
    selector: 'cc-quick-info-modal',
    templateUrl: 'quick-info-modal.component.html',
    standalone: true,
    imports: [
        NgClass,
        StickersJackpotComponent,
        NgIf,
        DynamicHtmlDirective,
        NgStyle,
        FreeSpinsQuickInfoComponent,
        UpperCasePipe,
        GameTileCtaComponent,
        DecoratorClassDirective,
    ],
})
export class QuickInfoModalComponent implements OnInit, AfterViewInit, OnDestroy {
    defaultSrc: string;
    isLaunchedFromUrl: any = false;
    game: any;
    gameTitle: string;
    src: any;
    gameProvider: any;
    provider: any;
    useFavouriteGames: boolean = false;
    cta: string;
    isTouch: boolean;
    isMobile: boolean;
    gamePosition: any;
    gameLaunchedFrom: any;
    categoryType: any;
    gameDescription: any = null;
    reelsTitle: string = '';
    payLinesTitle: string = '';
    returnToPlayerTitle: string = '';
    reels: any = null;
    payLines: any = null;
    returnToPlayer: any = null;
    jackpotValue: any = null;
    favouriteStyle: string = 'theme-favorite-i';
    unFavouriteStyle: string = 'theme-favorite';
    isFavourite: boolean = false;
    stickers: any = [];
    product: string = '';
    isHotJackpotGroup: boolean = false;
    jackpotData: any = null;
    vendorLogo: any = null;
    gridPosition: any;
    isStickersForUk: boolean = false;
    demoEnabledForProvider: boolean = true;
    demoEnabled: boolean;
    enableQuickInfoReels: boolean;
    demoLinkText: string;
    demoLinkTextColor: string;
    gameContainer: string;
    lobbyType: string;
    //Freespins
    showFreeSpinCount: boolean;
    enableFreeSpinSummary: boolean;
    enableFreeSpinsOnQuickInfo: boolean;
    freespinCountGame: any;
    freespinObservable: any;
    rewardIds: any[] = [];
    rewardDetails: any;
    favObs: Subscription;
    displayFavouritesOnPreLogin: boolean = true;
    gameHomeUrl: string;
    enableEpcotSubNavigation: boolean;
    isLegacy: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<QuickInfoModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private user: UserService,
        private configProviderService: ConfigProviderService,
        private favouriteService: FavouriteService,
        private cookie: CookieService,
        private urlUtilityService: UrlUtilityService,
        private loginDialog: OpenLoginDialog,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private tracking: TrackingService,
        private casinoManager: CasinoManager,
        private gameDataManager: GameDataManager,
        private jackpotservice: JackpotDataService,
        private elementRef: ElementRef,
        private deviceService: DeviceService,
        private freespinSummaryService: FreespinSummaryService,
        private casinoLobbyService: CasinoLobbyService,
        private casinoNpmParamsService: CasinoCoreNpmParamsService,
        private quickInfoService: QuickInfoService,
        private libMockService: LibMockService,
        private dslService: DslService,
        private designSystemEnablerService: DesignSystemEnablerService,
    ) {
        this.libMockService.setQuickInfoService(this.quickInfoService);
    }

    ngOnInit() {
        this.setLocalData();
        this.assignConfig();
        this.defaultSrc = this.urlUtilityService.getGrayImageUrl();
        if (this.data && this.data.product != '') {
            this.trackWidgetGames();
        }
        this.gameDataManager.getGameDescription(this.game.gameId, this.game.provider).subscribe((res) => {
            if (res) {
                this.gameDescription = res.gameDescription;
                this.reels = res.reels;
                this.payLines = res.payLines;
                this.returnToPlayer = res.returnToPlayer;
            }
            this.checkForAdjustment();
        });
        this.isTouch = this.casinoManager.isTouch();
        this.isMobile = this.deviceService.isMobilePhone;
        this.favObs = this.favouriteService.favouriteGamesFeedObservable.subscribe((favouriteGames: any) => {
            if (favouriteGames) {
                this.isFavourite = this.favouriteService.checkForFavouriteGame(this.game);
            }
        });
        if (
            this.gameLaunchedFrom != 'RecentlyPlayed_widget_RecentlyPlayed' &&
            this.gameLaunchedFrom != 'RecentlyPlayed_widget_Favourites' &&
            this.data.moreInfoText
        ) {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'games',
                'component.LabelEvent': 'game interactions',
                'component.ActionEvent': 'load',
                'component.PositionEvent': this.gamePosition,
                'component.LocationEvent': 'bacon',
                'component.EventDetails': 'moreinfo overlay',
                'component.URLClicked': 'not applicable',
            });
        }
        this.isLaunchedFromUrl || this.appendQuickInfoQueryParameterToUrl();
        this.assignFeatures();
        this.isLegacy = !this.designSystemEnablerService.isEnabled();
    }
    assignFeatures() {
        const features: any = this.configProviderService.provideFeaturesConfig();
        const vendorsWithOnlyRealMode: any = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.game.lobbyType,
            this.configProviderService.provideGameConfig().vendorsWithOnlyRealModeLobbyType,
        );
        let demoModeExcludedGamesRegex = this.configProviderService.provideGameConfig().demoModeExcludedGamesRegex;
        demoModeExcludedGamesRegex = demoModeExcludedGamesRegex?.split(',');
        if (vendorsWithOnlyRealMode && vendorsWithOnlyRealMode.indexOf(this.provider) > -1) {
            this.demoEnabledForProvider = false;
        } else if (demoModeExcludedGamesRegex && demoModeExcludedGamesRegex.length > 0) {
            demoModeExcludedGamesRegex?.forEach((regex: any) => {
                if (new RegExp(regex, 'g').test(this.game.gameId)) {
                    this.demoEnabledForProvider = false;
                }
            });
        }
        this.isStickersForUk = this.casinoManager.IsFeatureEnabledforLobbytype(this.game.lobbyType, features.stickersForUk);
        this.dslService.evaluateExpression<boolean>(features.hideFavouritesOnPreLogin).subscribe((hideFavouritesOnPreLogin) => {
            if (hideFavouritesOnPreLogin && !this.user.isAuthenticated) {
                this.displayFavouritesOnPreLogin = false;
            }
        });
    }
    assignConfig() {
        const global = this.configProviderService.provideGlobalConfig();
        const gameConfig = this.configProviderService.provideGameConfig();
        const freespinsConfig = this.configProviderService.providefreeSpinsClientConfig();
        this.reelsTitle = global.messages.Reels;
        this.payLinesTitle = global.messages.PayLines;
        this.returnToPlayerTitle = global.messages.ReturnToPlayer;
        const subnavConfig: any = this.configProviderService.provideSubNavigationConfig();

        if (document.querySelector('.casino-qi-noverlay') as HTMLElement) {
            (document.querySelector('.casino-qi-noverlay') as HTMLElement).style.cssText = global.messages.quickInfoStyles;
        }
        if (
            global.lobbyConfigurations.FavouriteHeart === 'true' ||
            global.lobbyConfigurations.FavouriteHeart === 'True' ||
            global.lobbyConfigurations.FavouriteHeart === 'TRUE'
        ) {
            this.favouriteStyle = 'theme-heart-solid';
            this.unFavouriteStyle = 'theme-heart-outline';
        }
        const gameOwnerId = gameConfig.gameOwnerIdMap?.find((g: any) => g.gameId == this.game.gameId);
        this.vendorLogo =
            gameConfig.host +
            gameConfig.gameIconTemplate.replace('{0}', 'vendorlogos').replace('{1}', gameOwnerId ? gameOwnerId?.ownerId : this.game.ownerId) +
            '.jpg';
        this.demoEnabled = gameConfig.enableDemoMode;
        this.enableQuickInfoReels = gameConfig.enableQuickInfoReels;
        this.demoLinkText = global.messages.DemoLinkText;
        this.demoLinkTextColor = global.messages.DemoLinkTextColor;

        this.enableFreeSpinSummary = freespinsConfig.isFreeSpinSummaryEnabled;
        this.enableFreeSpinsOnQuickInfo = freespinsConfig.isEnableFreeSpinsOnQuickInfo;
        if (this.enableFreeSpinSummary && this.enableFreeSpinsOnQuickInfo) {
            this.freespinObservable = this.freespinSummaryService.freeSpinsGameDetailsObser.subscribe((freespinsdata: any) => {
                if (freespinsdata) {
                    this.bindFreeSpinInfo();
                }
            });
        }
        this.enableEpcotSubNavigation = subnavConfig.enableEpcotSubNavigation;
    }
    bindFreeSpinInfo() {
        this.setfreeSpinsCount(this.freespinSummaryService.getFreespinCountInfoForGame(this.game.gameId));
    }
    /*This is to set freespins count for the respective game*/
    setfreeSpinsCount(freespinsCountResponse: any) {
        this.freespinCountGame = freespinsCountResponse;
        if (this.freespinCountGame != undefined) {
            this.showFreeSpinCount = true;
            this.rewardIds = this.freespinCountGame.rewardIds;
            this.rewardDetails = this.freespinSummaryService.getFreeSpinRewardDetails(this.rewardIds);
        } else {
            this.showFreeSpinCount = false;
        }
    }
    ngAfterViewInit() {
        if (this.isStickersForUk) this.gameStickerStyleForUk();
        else this.gameStickerStyle();
        this.checkForAdjustment();
    }
    trackWidgetGames() {
        if (this.data && this.data.gameLaunchedFrom && this.data.gameLaunchedFrom == 'RecentlyPlayed_widget_Favourites') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'Favourites_QuickInfo_Overlay',
                'component.ActionEvent': 'Load',
                'component.PositionEvent': this.game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this.data.lobbyType,
            });
        }
        if (this.data && this.data.gameLaunchedFrom && this.data.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'RecentlyPlayed_QuickInfo_Overlay',
                'component.ActionEvent': 'Load',
                'component.PositionEvent': this.game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this.data.lobbyType,
            });
        }
    }
    checkForAdjustment() {
        if (this.isTouch && this.deviceService.windowWidth() <= 480 && this.gameDescription) {
            const overlay = this.elementRef.nativeElement.querySelectorAll('.casino-qi-desktop-nrectangle');
            if (overlay.length > 0) overlay[0].style.top = '10%';
        }
    }
    setLocalData() {
        this.gameTitle = this.data.gameTitle;
        this.src = this.data.src;
        this.gameProvider = this.data.gameProvider;
        this.provider = this.data.provider;
        this.game = this.data.game;
        this.useFavouriteGames = this.data.useFavouriteGames;
        this.cta = this.data.cta;
        this.gamePosition = this.data.gamePosition;
        this.gameLaunchedFrom = this.data.gameLaunchedFrom;
        this.categoryType = this.data.categoryType;
        this.isLaunchedFromUrl = this.data.isLaunchedFromUrl;
        this.stickers = this.data.stickers;
        this.gridPosition = this.data.gridPosition;
        this.product = this.data.product;
        this.gameContainer = this.game.gameContainer;
        this.lobbyType = this.data.lobbyType;
        this.gameHomeUrl = this.data.gameHomeUrl;
        this.jackpotservice.jackpotFeedObservable.subscribe((_response: any) => {
            if (_response) {
                const jpFeed = _response.get(this.game.gameId);
                if (jpFeed) {
                    this.jackpotData = jpFeed;
                    this.jackpotValue = this.jackpotData.amount;
                    this.isHotJackpotGroup = this.jackpotData.isHotJackpotGroup;
                }
            }
        });
        const feature: any = this.configProviderService.provideFeaturesConfig();
        if (feature.enableGameIconTheming) this.src = this.casinoLobbyService.appendTheme(this.src, this.game.gameId);
    }
    GameJackpotValue(game: any) {
        return this.jackpotservice.getGameJackpotValue(game.gameId);
    }
    closeModal($event: any = null): void {
        if ($event) {
            if (this.data && this.data.gameLaunchedFrom && this.data.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed') {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'RecentlyPlayed_Widget',
                    'component.LabelEvent': 'RecentlyPlayed_QuickInfo_Overlay',
                    'component.ActionEvent': 'Close Overlay - Gradient Click',
                    'component.PositionEvent': this.game.gameId + '_' + this.gamePosition,
                    'component.LocationEvent': this.data.lobbyType,
                });
            }
            if (this.data && this.data.gameLaunchedFrom == 'RecentlyPlayed_widget_Favourites') {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'RecentlyPlayed_Widget',
                    'component.LabelEvent': 'Favourites_QuickInfo_Overlay',
                    'component.ActionEvent': 'Close Overlay - Gradient Click',
                    'component.PositionEvent': this.game.gameId + '_' + this.gamePosition,
                    'component.LocationEvent': this.data.lobbyType,
                });
            }
        } else {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'games',
                'component.LabelEvent': 'game interactions',
                'component.ActionEvent': 'close',
                'component.PositionEvent': this.gamePosition,
                'component.LocationEvent': 'bacon',
                'component.EventDetails': 'moreinfo overlay',
                'component.URLClicked': 'not applicable',
            });
        }
        document.getElementsByTagName('html')[0].classList.remove('casino-quickinfo-open');
        $event && $event.stopImmediatePropagation();
        this.removeQuickInfoQueryParameterFromUrl();
        this.dialogRef.close();
    }
    isFavouriteGame(game: any) {
        return this.favouriteService.checkForFavouriteGame(game);
    }
    toggleFavourite(game: any, $event: any) {
        $event.stopImmediatePropagation();
        if (this.user.isAuthenticated) {
            if (
                this.data &&
                this.data.gameLaunchedFrom &&
                (this.data.gameLaunchedFrom == 'RecentlyPlayed_widget_RecentlyPlayed' ||
                    this.data.gameLaunchedFrom == 'RecentlyPlayed_widget_Favourites')
            ) {
                this.trackFavfromRpWidget(game);
            } else {
                this.tracking.triggerEvent('Event.Clicks', {
                    'component.CategoryEvent': 'games',
                    'component.LabelEvent': 'games interaction',
                    'component.ActionEvent': 'click',
                    'component.PositionEvent': game.gameId,
                    'component.LocationEvent': 'more info overlay',
                    'component.EventDetails': this.isFavouriteGame(game) ? 'UnFavourited' : 'Favourited',
                    'component.URLClicked': 'not applicable',
                });
            }
            this.favouriteService.toggleGameFavourite(game);
            this.isFavourite = !this.isFavourite;
        } else {
            this.cookie.putObject('favouriteGame', game);
            this.closeModal();
            const loginResponseObj: any = {
                loginMessageKey: 'Favourite',
            };
            this.loginDialog.open(loginResponseObj);
        }
    }

    trackFavfromRpWidget(game: any) {
        if (this.data && this.data.gameLaunchedFrom == 'RecentlyPlayed_widget_RecentlyPlayed') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'RecentlyPlayed_QuickInfo_Overlay',
                'component.ActionEvent': this.isFavouriteGame(game) ? 'UnFavourited' : 'Favourited',
                'component.PositionEvent': game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this.data.lobbyType,
            });
        }
        if (this.data && this.data.gameLaunchedFrom == 'RecentlyPlayed_widget_Favourites') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'Favourites_QuickInfo_Overlay',
                'component.ActionEvent': this.isFavouriteGame(game) ? 'UnFavourited' : 'Favourited',
                'component.PositionEvent': game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this.data.lobbyType,
            });
        }
    }
    gameStickerStyle() {
        const stickerStyle = this.configProviderService.provideGlobalConfig().messages.GameStickerStyle;
        const sticker = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-0');
        for (let i = 0; i < sticker.length; i++) {
            sticker[i].style.cssText = stickerStyle;
        }
    }
    gameStickerStyleForUk() {
        const stickerStyle = this.configProviderService.provideGlobalConfig().messages.UkStickerStyle1;
        const sticker = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-0');
        for (let i = 0; i < sticker.length; i++) {
            sticker[i].style.cssText = stickerStyle;
        }
        const stickerStyle1 = this.configProviderService.provideGlobalConfig().messages.UkStickerStyle2;
        const sticker1 = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-1');
        for (let i = 0; i < sticker1.length; i++) {
            sticker1[i].style.cssText = stickerStyle1;
        }
    }
    launchGame(game: any, isDemo: boolean): void {
        if (this.data && this.data.gameLaunchedFrom == 'RecentlyPlayed_widget_RecentlyPlayed') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'RecentlyPlayed_QuickInfo_Overlay',
                'component.ActionEvent': 'CTA_Click',
                'component.PositionEvent': this.game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this.data.lobbyType,
            });
        }
        if (this.data && this.data.gameLaunchedFrom == 'RecentlyPlayed_widget_Favourites') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'Favourites_QuickInfo_Overlay',
                'component.ActionEvent': 'CTA_Click',
                'component.PositionEvent': this.game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this.data.lobbyType,
            });
        }
        this.closeModal();
        const gameModel = new GameLaunch();
        gameModel.game = game;
        gameModel.isTouch = this.isTouch;
        gameModel.gamePosition = this.gamePosition;
        gameModel.categoryType = this.categoryType;
        gameModel.from = 'q';
        gameModel.replaceRouteInHistory = false;
        gameModel.isDemoLaunch = isDemo;
        gameModel.skipUnfinGame = false;
        gameModel.gameLaunchedFrom = this.gameLaunchedFrom;
        gameModel.gridPosition = this.gridPosition;
        gameModel.returnUrl = this.gameHomeUrl;
        gameModel.appendRUrlToLoginDialogWindow = true;
        gameModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
        this.gameLaunchService.launchGame(gameModel);
    }
    appendQuickInfoQueryParameterToUrl() {
        const casinoNpmParams = this.casinoNpmParamsService.getcasinoCoreNpmParams();
        if (casinoNpmParams && casinoNpmParams.isDll) {
            if (this.gameContainer != 'Overlay' && this.gameContainer != 'Widget') {
                const currentUrl: string = window.location.href.substring(window.location.href.lastIndexOf('/'));
                const param = currentUrl.length > 1 ? '/game-info/' : 'game-info/';
                window.history.pushState(location.href, '', location.href + param + this.game.gameId);
            }
        }
    }
    onGameIconError(): void {
        this.src = this.defaultSrc;
    }
    removeQuickInfoQueryParameterFromUrl() {
        const casinoNpmParams = this.casinoNpmParamsService.getcasinoCoreNpmParams();
        if (casinoNpmParams && casinoNpmParams.isDll) {
            if (this.gameContainer != 'Overlay' && this.gameContainer != 'Widget') {
                const param = 'game-info';
                if (location.href.indexOf(param) > -1 && !document.getElementsByTagName('html')[0].classList.contains('casino-quickinfo-open')) {
                    window.history.pushState(location.href, '', location.href.substr(0, location.href.indexOf(param) - 1));
                }
            }
        }
    }
    ngOnDestroy() {
        if (this.favObs) {
            this.favObs.unsubscribe();
        }
        if (this.freespinObservable) {
            this.freespinObservable.unsubscribe();
        }
    }
}
