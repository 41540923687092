import { LowerCasePipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, ViewChild } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';

import { CasinoLobbyService, CasinoManager, ConfigProviderService, GameLobbyModel } from '@casinocore/platform/core';
import { elementScroll } from '@casinocore/platform/utils';

import { GameLobbyComponent } from '../gamelobby/gamelobby.component';

enum Types {
    SingleJackpot = 'SingleJackpot',
    MustGoJackpot = 'MustGoJackpot',
    MultiJackpot = 'MultiJackpot',
}
@Component({
    selector: 'cc-jackpot-widget-redesign-grid',
    templateUrl: 'jackpot-widget-redesign-grid.component.html',
    standalone: true,
    imports: [NgClass, NgIf, NgStyle, MatGridListModule, NgFor, GameLobbyComponent, LowerCasePipe],
})
export class JackpotWidgetRedesignGridComponent implements OnChanges, AfterViewInit {
    @ViewChild('scrollerContainer', { static: false }) scrollerContainer: ElementRef<HTMLDivElement>;
    @Input() categoryObj: any;
    @Input() category: any;
    @Input() games: GameLobbyModel[];
    @Input() gridCols: number;
    @Input() widgetType: string;
    // @Input() changeCarouselView: boolean;
    types = Types;
    gamesGridWidth: number;
    rowHeight: number = 190;
    showLeftScrollButton: boolean;
    showRightScrollButton: boolean;
    gamesGridCols: number;
    isTouch: boolean;
    subCategoryTextColor: string;
    lobbyTileFontFamily: string;
    private jackpotDivGridwidth: any;
    private isMobileView: boolean;
    private isMobileLandscapeView: boolean;
    private isDesktopView: boolean;
    private isTabView: boolean;
    private isTabLandscapeView: boolean;
    themeArrowLeft: any;
    themeArrowRight: any;
    changeCarouselView: boolean;
    constructor(
        private casinoManager: CasinoManager,
        private configProviderService: ConfigProviderService,
        private casinoLobbyService: CasinoLobbyService,
    ) {}
    @HostListener('window:resize') onResize() {
        this.isMobileView = window.innerWidth <= 767 || document.body.offsetWidth <= 767;
        this.isMobileLandscapeView =
            (window.orientation == 90 || window.orientation == -90) && (window.innerWidth <= 767 || document.body.offsetWidth <= 767);
        this.isDesktopView = window.innerWidth >= 768 || document.body.offsetWidth >= 768;
        this.isTabView =
            (window.innerWidth >= 768 || document.body.offsetWidth >= 768) && (window.innerWidth <= 1024 || document.body.offsetWidth <= 1024)
                ? true
                : false;
        this.isTabLandscapeView =
            (window.orientation == 90 || window.orientation == -90) &&
            (window.innerWidth >= 768 || document.body.offsetWidth >= 768) &&
            (window.innerWidth <= 1024 || document.body.offsetWidth <= 1024)
                ? true
                : false;
        this.jackpotWidgetRowHeight();
        this.jackpotGridGamesWidthCols();
    }
    ngOnChanges(): void {
        this.isMobileView = window.innerWidth <= 767 || document.body.offsetWidth <= 767;
        this.isMobileLandscapeView = window.orientation == 90 || window.orientation == -90;
        this.isDesktopView = window.innerWidth >= 768 || document.body.offsetWidth >= 768;
        this.isTabView =
            (window.innerWidth >= 768 || document.body.offsetWidth >= 768) && (window.innerWidth <= 1024 || document.body.offsetWidth <= 1024)
                ? true
                : false;
        this.isTabLandscapeView =
            (window.orientation == 90 || window.orientation == -90) &&
            (window.innerWidth >= 768 || document.body.offsetWidth >= 768) &&
            (window.innerWidth <= 1024 || document.body.offsetWidth <= 1024)
                ? true
                : false;
        this.isTouch = this.casinoManager.isTouch();
        const global = this.configProviderService.provideGlobalConfig();
        const features = this.configProviderService.provideFeaturesConfig();
        this.themeArrowLeft = global.messages.ThemeArrowLeft;
        this.themeArrowRight = global.messages.ThemeArrowRight;
        this.subCategoryTextColor = this.casinoLobbyService.getsubCategoryTextColor(this.category);
        this.lobbyTileFontFamily = this.casinoLobbyService.getLTileFontFamily(this.category);
        this.changeCarouselView = features?.changeCarouselView;
        this.jackpotWidgetRowHeight();
        this.jackpotGridGamesWidthCols();
        this.adjustSectorItemsContainerScroll();
    }
    ngAfterViewInit(): void {
        this.adjustSectorItemsContainerScroll();
        setTimeout(() => {
            this.jackpotWidgetRowHeight();
            this.jackpotGridGamesWidthCols();
        }, 500);
    }
    onMoveGames(isLeft: boolean, event: Event): void {
        const { showLeft, showRight } = elementScroll(this.scrollerContainer.nativeElement, this.rowHeight * 2, isLeft, 0, event);
        this.showLeftScrollButton = showLeft;
        this.showRightScrollButton = showRight;
    }
    private adjustSectorItemsContainerScroll(): void {
        this.showLeftScrollButton = false;
        this.showRightScrollButton = false;
        if (this.scrollerContainer?.nativeElement) this.scrollerContainer.nativeElement.scrollLeft = 0;
        if (
            (this.scrollerContainer?.nativeElement && !this.isTouch && this.games?.length > 6) ||
            (this.scrollerContainer?.nativeElement &&
                !this.isTouch &&
                this.games?.length > 4 &&
                this.widgetType &&
                this.widgetType === this.types.SingleJackpot)
        ) {
            this.showLeftScrollButton = false;
            this.showRightScrollButton = true;
        }
    }
    private jackpotWidgetRowHeight(): void {
        const jackpotWidgetFullWidth = document.querySelector('.casino-lobby-container-main .jackpot-widget-view-port');
        const jackpotWidgetDivFullWidth = jackpotWidgetFullWidth?.clientWidth || 0;
        if (this.widgetType && this.widgetType === this.types.SingleJackpot) {
            const jackpotWidgetLeftWidth = document.querySelector('.casino-lobby-container-main .single-jackpot-right-container');
            const jackpotWidgetDivLeftWidth: number = jackpotWidgetLeftWidth?.clientWidth || 0;
            //const jackpotWidgetDivLeftContent = Math.round(jackpotWidgetDivLeftWidth);
            const jackpotDivContent = this.isMobileView ? jackpotWidgetDivFullWidth : Math.round(jackpotWidgetDivLeftWidth);
            const jackpotWidgetContent =
                this.isMobileView && !this.isMobileLandscapeView ? Math.round(jackpotDivContent / 2.5) : Math.round(jackpotDivContent / 4);
            const jackpotRowHeight =
                this.isTabView || this.isTabLandscapeView ? Math.round(jackpotWidgetContent + 20) : Math.round(jackpotWidgetContent - 3);
            this.rowHeight =
                this.isDesktopView && !this.isTouch && !this.isTabView && !this.isTabLandscapeView && !this.isMobileLandscapeView
                    ? 190
                    : Math.round(jackpotRowHeight);
            const gamesGridColumns = this.games?.length ? this.games?.length : 0;
            this.gamesGridWidth = this.rowHeight * gamesGridColumns;
            this.gamesGridCols = this.games?.length;
        }
        if (this.widgetType && this.widgetType !== this.types.SingleJackpot) {
            const jackpotWidgetRightContentWidth = this.isMobileView
                ? Math.round(jackpotWidgetDivFullWidth)
                : Math.round(jackpotWidgetDivFullWidth / 2);
            const jackpotgamesArrows =
                this.isMobileView && !this.isMobileLandscapeView ? jackpotWidgetRightContentWidth - 3 : jackpotWidgetRightContentWidth - 35;
            const gamesRightSectionWidth =
                this.isMobileView && !this.isMobileLandscapeView ? Math.round(jackpotgamesArrows / 2.5) : Math.round(jackpotgamesArrows / 3);
            this.rowHeight = Math.round(gamesRightSectionWidth);
        }
    }
    private jackpotGridGamesWidthCols(): void {
        if (this.widgetType && (this.widgetType === this.types.MultiJackpot || this.widgetType === this.types.MustGoJackpot)) {
            const jackpotRightContentWidth = document.querySelector('.casino-lobby-container-main .jackpot-right-content');
            this.jackpotDivGridwidth = jackpotRightContentWidth?.clientWidth || 0;
            const jackpotGridCols = this.jackpotDivGridwidth / this.rowHeight;
            const gridCols = Math.round(jackpotGridCols);
            if (this.isMobileView) {
                const gamesGridColumns = this.games?.length ? this.games?.length : 0;
                this.gamesGridWidth = this.rowHeight * gamesGridColumns;
                this.gamesGridCols = this.games?.length;
            } else {
                const gamesCols = Math.round(this.games?.length / 2);
                this.gamesGridCols = gamesCols > gridCols ? gamesCols : gridCols;
                this.gamesGridWidth = this.gamesGridCols * this.rowHeight;
            }
        }
    }
}
