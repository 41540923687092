import { Injectable } from '@angular/core';

import { Logger, Message, MessageLifetime, MessageType } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService, GamePremiereConfig, PlatformApiService, UrlUtilityService, UserRequestModel } from '../public-api';

@Injectable({
    providedIn: 'root',
})
export class GamePremiereService {
    observable: Observable<any | null>;
    private optInPublisher = new BehaviorSubject<boolean>(false);
    optInObservable: Observable<boolean | null>;
    private changePromoStatusBehaviourSubject = new BehaviorSubject<any>(null);
    private publisher = new BehaviorSubject<boolean>(false);
    gamePremiereConfig: GamePremiereConfig;
    changePromoStatusObservable: Observable<boolean> = this.changePromoStatusBehaviourSubject.asObservable();
    gamePremiereOptInData: any;

    constructor(
        private urlUtility: UrlUtilityService,
        private configProviderService: ConfigProviderService,
        private api: PlatformApiService,
        private logger: Logger,
    ) {
        this.gamePremiereConfig = this.configProviderService.provideGamePremiereConfig();
        this.observable = this.publisher.asObservable();
        this.optInObservable = this.optInPublisher.asObservable();
    }

    get gamePremiereOptIn(): any {
        return this.gamePremiereOptInData;
    }

    getGamePremiereUserStatus(type: string, id: string): Observable<any> {
        return this.api.get(
            'gamepremiere/GetGamePremiereStatus',
            { type: type, id: id },
            {
                responseType: 'json',
                withCredentials: true,
                showSpinner: true,
            },
        );
    }
    getGamepremiereOptIn(postRequestModel: UserRequestModel): void {
        this.api
            .post('gamePremiere/PostOptinStatus', postRequestModel, {
                responseType: 'json',
                withCredentials: true,
                showSpinner: false,
            })
            .subscribe({
                next: (response: any) => {
                    if (response) {
                        this.optInPublisher.next(response);
                    }
                },
                error: (error: string) => {
                    const message: Message = {
                        html: error,
                        type: MessageType.Error,
                        lifetime: MessageLifetime.Single,
                        scope: 'casinocorelobby',
                    };
                    this.logger.error(message);
                },
            });
    }
}
