import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import {
    BingoFavouriteService,
    BingoGameLaunchService,
    CasinoManager,
    CasinoWidgetService,
    ConfigProviderService,
    CozyBingoDataService,
    FavouriteService,
    ToastMessageService,
} from '@casinocore/platform/core';
import { DeviceService, TrackingService, UserService } from '@frontend/vanilla/core';

import { GameQuickInfoComponent } from '../game-quick-info/game-quick-info.component';
import { QuickInfoService } from '../services/quick-info.service';

@Component({
    selector: 'cc-cozybingo-game-info',
    templateUrl: 'cozybingo-game-info.component.html',
    standalone: true,
    imports: [NgClass, NgIf, GameQuickInfoComponent],
})
export class CozyBingoGameInfoComponent implements OnInit {
    @Input() bingoItem: any;
    @Input() globalMessages: any;
    @Input() featureConfigs: any;
    @Input() categoryList: any;
    @Input() gamePosition: any;
    @Input() gameLaunchedFrom: any;
    @Input() product: any;
    @Input() lobbyType: any;
    @Input() widgetFrameClr: any;
    isMobileView: boolean;
    thumbImageSrc: string;
    isTabletView: boolean;
    quickInfoScrollY: number = 0;
    ingame_rooms_status: boolean = false;
    cartegoriesList: any;
    roomIconslist: any;
    roomnavlist: any;
    toasterRequired: any;
    _favouriteAddedMessage: string;
    _favouriteRemovedMessage: string;
    _favouriteAddedErrorMessage: string;
    _favouriteRemovedErrorMessage: string;
    playNowHoverEffect: boolean = false;
    mouseHoveredOnGame: boolean = false;
    isTouch: boolean;
    QuickInfoVisible: string = '';
    enableUkMouseOverStyle: boolean;
    onMouseHover: boolean;
    onMouseLeave: boolean;
    visiblestatus: boolean;
    useQuickInfo: boolean;
    Favourite: boolean;
    moreInfo: string;
    quickInfoIcon: any = {
        src: '',
    };
    gameContainerLocation: any;
    isSafari: boolean;
    constructor(
        private activeroute: ActivatedRoute,
        private deviceService: DeviceService,
        private gameLaunchService: BingoGameLaunchService,
        private user: UserService,
        private bingoFavouriteService: BingoFavouriteService,
        private cozyBingoDataService: CozyBingoDataService,
        private toastMessageService: ToastMessageService,
        private casinoManager: CasinoManager,
        private tracking: TrackingService,
        private dialog: MatDialog,
        private casinoWidgetService: CasinoWidgetService,
        private favouriteService: FavouriteService,
        private configProvider: ConfigProviderService,
        private quickInfoService: QuickInfoService,
    ) {}

    ngOnInit() {
        const features = this.configProvider.provideFeaturesConfig();
        this.isSafari =
            navigator.vendor &&
            navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            !navigator.userAgent.match('CriOS') &&
            !navigator.userAgent.match('FxiOS')
                ? true
                : false;
        this.playNowHoverEffect = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.playNowHoverEffect);
        this.isTouch = this.casinoManager.isTouch();
        this.useQuickInfo = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.enableQuickInfoLobbyType);
        this.enableUkMouseOverStyle = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.enableUkMouseOverStyle);
        if (
            this.bingoItem['sitecoreInfo'] &&
            this.bingoItem['sitecoreInfo']['desktopImage'] &&
            this.bingoItem['sitecoreInfo']['desktopImage']['src']
        ) {
            this.thumbImageSrc = this.bingoItem['sitecoreInfo']['desktopImage']['src'];
            this.quickInfoIcon.src = this.bingoItem['sitecoreInfo']['desktopImage']['src'];
        }
        this.toasterRequired = this.featureConfigs['favouritesConfirmationPopUp'];
        this._favouriteAddedMessage = this.globalMessages['favouriteAddedMessage'];
        this._favouriteRemovedMessage = this.globalMessages['favouriteRemovedMessage'];
        this._favouriteAddedErrorMessage = this.globalMessages['errorOccuredWhileAddingToFavMessage'];
        this._favouriteRemovedErrorMessage = this.globalMessages['errorOccuredWhileRemovingFromFavMessage'];
        this.roomnavlist = this.categoryList.find((x: any) => x.categoryType == 'schedule');
        this.roomIconslist = this.categoryList.find((x: any) => x.categoryType == 'rooms');
        this.isTabletView = this.deviceService.isTablet;
        this.isMobileView = this.deviceService.isMobilePhone;
        const iframe_ingame_status = this.activeroute.snapshot.queryParamMap.get('ingame');
        if (iframe_ingame_status !== null && iframe_ingame_status !== undefined) {
            if (iframe_ingame_status) {
                this.ingame_rooms_status = true;
            }
        }
        this.gameContainerLocation = 'Widget|' + this.product;
    }

    gameLaunch() {
        const gameObj: any = {
            position: this.gamePosition,
            section: this.gameLaunchedFrom,
            gameContainerLocation: this.gameContainerLocation,
            widgetFrameClr: this.widgetFrameClr,
        };
        this.gameLaunchService.initiateGameLaunch(
            this.bingoItem.name,
            'BINGO',
            this.bingoItem.bingo_type,
            'room',
            this.thumbImageSrc,
            this.bingoItem.id,
            true,
            undefined,
            gameObj,
        );
    }
    toggleFavourite() {
        if (this.bingoItem.isFavourite) {
            this.bingoFavouriteService.removeFavourite(this.bingoItem.id, this.user.id || '').subscribe(
                (response: any) => {
                    if (response.result) {
                        if (this.toasterRequired) this.toastMessageService.on('theme-heart-broken', this._favouriteRemovedMessage, true);
                        this.cozyBingoDataService.getBingoData(false, false);
                        const request = {
                            categoryid: 'rcp-widget',
                            roomid: this.bingoItem.id,
                            isFavourite: true,
                        };
                        this.casinoWidgetService.postMessageToParent('favupdated', request);
                        this.favouriteService.publishingFavObervable(true, this.bingoItem.id);
                    }
                },
                (error: any) => {
                    if (error && this.toasterRequired) {
                        this.toastMessageService.on('theme-heart-broken', this._favouriteRemovedErrorMessage, true);
                    }
                },
            );
        } else {
            this.bingoFavouriteService.addFavourite(this.bingoItem.id, this.user.id || '').subscribe(
                (response: any) => {
                    if (response.result) {
                        if (this.toasterRequired) this.toastMessageService.on('theme-bingo-heart', this._favouriteAddedMessage, true);
                        this.cozyBingoDataService.getBingoData(false, false);
                        const request = {
                            categoryid: 'rcp-widget',
                            roomid: this.bingoItem.id,
                            isFavourite: false,
                        };
                        this.casinoWidgetService.postMessageToParent('favupdated', request);
                        this.favouriteService.publishingFavObervable(false, this.bingoItem.id);
                    }
                },
                (error) => {
                    if (error && this.toasterRequired) {
                        this.toastMessageService.on('theme-bingo-heart', this._favouriteAddedErrorMessage, true);
                    }
                },
            );
        }
    }
    hideQuickInfo() {
        window.scrollTo(0, this.quickInfoScrollY);
        this.removeQuickInfoQueryParameterFromUrl();
    }
    removeQuickInfoQueryParameterFromUrl() {
        const param = 'game-info';
        window.history.pushState(location.href, '', location.href.substr(0, location.href.indexOf(param) - 1));
    }
    mouseOver(hoveredgame: string) {
        this.mouseHoveredOnGame = true;
        if (!this.isTouch && this.mouseHoveredOnGame) {
            this.QuickInfoVisible = hoveredgame;
            if (this.enableUkMouseOverStyle) {
                this.onMouseHover = true;
                this.onMouseLeave = true;
            }
        }
    }
    mouseLeave() {
        if (!this.isTouch) {
            this.QuickInfoVisible = '';
            this.visiblestatus = false;
            this.mouseHoveredOnGame = false;
            if (this.enableUkMouseOverStyle) {
                this.onMouseHover = false;
                this.onMouseLeave = false;
            }
        }
    }
    displayQuickInfo($event: any) {
        this.quickInfoScrollY = window.pageYOffset;
        document.getElementsByTagName('html')[0].classList.add('casino-quickinfo-open');
        if (this.gameLaunchedFrom === 'RecentlyPlayed_widget_Favourites') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'Favourites_QuickInfo',
                'component.ActionEvent': 'Info_click',
                'component.PositionEvent': this.bingoItem.id + '_' + this.gamePosition,
                'component.LocationEvent': this.casinoManager.getLobbyType(),
            });
        }
        this.bingoItem.lobbyType = this.lobbyType;
        const requiredModalData = {
            game: this.bingoItem,
            gamePosition: this.gamePosition,
            gameLaunchedFrom: this.gameLaunchedFrom,
            categoryType: '',
            icon: this.thumbImageSrc,
            stickers: '',
            gridPosition: 1,
            gameModel: this.bingoItem,
            product: this.product,
            gameContainerLocation: this.gameContainerLocation + 'QuickInfo',
            widgetFrameClr: this.widgetFrameClr,
        };
        if ($event) {
            $event.stopImmediatePropagation();
        }
        const dialogRef = this.quickInfoService.openGameQuickInfoDialog(requiredModalData);
        dialogRef.afterClosed().subscribe(() => {
            this.hideQuickInfo();
        });
    }
}
