import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csSubNavigationConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: subNavigationClientConfigFactory,
})
export class SubNavigationClientConfig {
    isEnabledCondition: string;
    showFavInSubNav: any;
    showRecentInSubNav: any;
    hardProductOverride: string;
    enableDesktopIcon: boolean;
    isInstaStoriesEnabled: any;
    enableSubNavigationBgColor: boolean;
    enableIconTextColor: boolean;
    enableEpcotSubNavigation: boolean;
    hideNavBarForEpcotTouch: any;
}

export function subNavigationClientConfigFactory(service: LazyClientConfigService) {
    return service.get(SubNavigationClientConfig);
}
