import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import {
    ClaimsService,
    DeviceService,
    LoginDialogService,
    MessageQueueService,
    NavigationService,
    SessionStoreService,
    UserService,
} from '@frontend/vanilla/core';
import { CurrentSessionConfig } from '@frontend/vanilla/shared/current-session';
import { toNumber } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ConfigProviderService } from '../../config-provider/config-provider.service';
import { ConfirmMessageComponent } from '../../confirm-message/confirm-message.component';
import { PlatformApiService } from '../../platform-api/platform-api.service';
import { PlatformBingoApiService } from '../../platform-api/platform-bingo-api.service';
import { UrlUtilityService } from '../../utilities/url-utility.service';
import { ApiPathsHelperService } from '../api-paths-helper-service';
import { CacheManagerService } from '../cache-manager.service';
import { CasinoWidgetService } from '../casino-widget.service';
import { CozyBingoDataService } from '../cozybingodata.service';
import { GameLaunchTrackingService } from '../game-launch-tracking.service';
import { RecentGamesService } from '../recent-games.service';

@Injectable({
    providedIn: 'root',
})
export class BingoGameLaunchService {
    isInGameClient: boolean;
    isRealPlayer: any;
    activeroom: string;
    openedRooms: string[];
    private destroy$ = new Subject<void>();
    constructor(
        private activatedRoute: ActivatedRoute,
        private user: UserService,
        private loginDialog: LoginDialogService,
        private messageQueue: MessageQueueService,
        private claimsConfig: ClaimsService,
        private navigator: NavigationService,
        private session: SessionStoreService,
        private recentGamesService: RecentGamesService,
        private deviceService: DeviceService,
        private casinoWidgetService: CasinoWidgetService,
        private dialog: MatDialog,
        private apiBingoService: PlatformBingoApiService,
        private api: PlatformApiService,
        private urlUtilityService: UrlUtilityService,
        private configProviderService: ConfigProviderService,
        private cacheManagerService: CacheManagerService,
        private cozyBingoDataService: CozyBingoDataService,
        private apiPathsHelperService: ApiPathsHelperService,
        private currentSessionConfig: CurrentSessionConfig,
        private gameLaunchTrackingService: GameLaunchTrackingService,
    ) {
        if (this.activatedRoute.snapshot.queryParamMap.get('hideHeader') == 'true' || this.activatedRoute.snapshot.queryParamMap.get('.box')) {
            this.isInGameClient = true;
        }
    }

    checkUserStatus(): boolean {
        if (this.isRealPlayer === undefined || this.isRealPlayer == null) {
            this.isRealPlayer = this.claimsConfig.get('realplayer');
        }
        if (this.isRealPlayer == 'True') {
            return true;
        }

        return false;
    }

    bingoGameLaunchTracking(gameObj: any) {
        let data: any;
        data = this.cozyBingoDataService.getRoomdatabyId(gameObj.id);
        if (data?.sitecoreInfo) {
            data.isNew = data.sitecoreInfo.isNew;
        }
        data = data || {};
        const gameTrackingObj: any = {
            name: gameObj.gameName,
            type: gameObj.gameType,
            position: Number.isInteger(gameObj.position) ? gameObj.position + 1 : undefined,
            section: gameObj.section,
            gridPosition: Number.isInteger(gameObj.position) ? gameObj.position + 1 + ':1' : undefined,
            location: gameObj.gameContainerLocation,
            status: data.isNew ? 'New' : 'Existing',
            description: gameObj.widgetFrameClr ? 'Highlighted|' + gameObj.widgetFrameClr : 'Non-Highlighted|No Color Code',
            favourite: data.isFavourite ? 'Favourite' : 'Non-Favourite',
            playingStatus: 'Not Applicable',
            displayName: gameObj.gameName,
            categoryType: 'Bingo',
            categorySubType: 'Bingo',
        };
        this.gameLaunchTrackingService.track(gameTrackingObj);
    }

    initiateGameLaunch(
        gameName: string,
        gameType: string,
        ballType: string,
        launchType: string,
        gameIcon: string,
        gameId: string,
        favstatus: boolean,
        originUrl: string | undefined,
        gameObj: any = {},
    ): void {
        let origin: string;
        const globalMessages: any = this.configProviderService.provideGlobalConfig();

        if (originUrl) {
            origin = originUrl;
        } else {
            origin = window.location.href;
        }

        gameObj.id = gameId;
        gameObj.gameType = gameType;
        gameObj.gameName = gameName;

        if (!this.validateUser(gameName, gameType, ballType, launchType, gameIcon, gameId, favstatus, origin, gameObj)) {
            return;
        }
        if (!this.checkUserStatus()) {
            const isPlayUser: boolean = this.claimsConfig?.get('realplayer')?.toLowerCase() === 'false';
            if (isPlayUser) {
                this.openBingoPlayUserDialog(origin);
            }

            return;
        }
        this.bingoGameLaunchTracking(gameObj);
        this.messageQueue.clear();
        if (this.deviceService.isTouch) {
            const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
            const bingoGameUrl = this.apiPathsHelperService.transformApi(
                apiPathsConfig.bingo['bgMenuLaunchRoom'],
                this.urlUtilityService.getBingoApiBaseUrl(),
                gameName,
            );
            const params = {
                promptLogin: false,
                redirectUrl: bingoGameUrl,
                categoryid: 'rcp-widget',
            };
            this.casinoWidgetService.postMessageToParent('GameLaunch', params);
        }
        if (this.deviceService.isMobilePhone && gameType?.toLowerCase() === 'casino') {
            this.getCasinoGameLaunchUrl(gameId).subscribe(
                (response) => {
                    if (response.redirect) {
                        this.launchGame(response.redirect);
                    } else {
                        if (globalMessages?.messages['gameLaunchErrorMessage']) {
                            this.messageQueue.addError(globalMessages.messages['gameLaunchErrorMessage']);
                        }
                    }
                },
                (error) => {
                    if (error && globalMessages?.messages['gameLaunchErrorMessage'])
                        this.messageQueue.addError(globalMessages.messages['gameLaunchErrorMessage']);
                },
            );
        } else {
            this.getGameLaunchUrl(gameName, gameType, ballType, launchType, gameIcon, gameId, favstatus, origin).subscribe(
                (response) => {
                    if (response.redirectUrl) {
                        this.launchBingoGames(response.redirectUrl, gameName, gameType, origin);
                    } else if (response.responseCode && response.responseCode != '0') {
                        let errorMessage = globalMessages.messages[response.responseCode];
                        errorMessage = globalMessages.messages['gl_' + response.responseCode];
                        if (!errorMessage) {
                            errorMessage = globalMessages.messages['gameLaunchErrorMessage'];
                        }
                        if (errorMessage) {
                            this.messageQueue.addError(errorMessage);
                        }
                        if (this.isForcedGameLaunch()) {
                            this.session.set('GameLaunchErrorCode', 'gl_' + response.responseCode);
                            this.navigator.goTo(origin);
                        }
                    } else {
                        if (globalMessages?.messages['gameLaunchErrorMessage']) {
                            this.messageQueue.addError(globalMessages.messages['gameLaunchErrorMessage']);
                        }
                    }
                    setTimeout(() => this.messageQueue.clear(), 3000);
                },
                (error) => {
                    if (error && globalMessages?.messages['gameLaunchErrorMessage'])
                        this.messageQueue.addError(globalMessages.messages['gameLaunchErrorMessage']);
                },
            );
        }
    }

    isForcedGameLaunch() {
        if (window.location.href.indexOf('bingoForcedGameLaunch') > -1) return true;
        else return false;
    }
    openBingoPlayUserDialog(origin: string): void {
        if (this.user.isAuthenticated && this.claimsConfig?.get('realplayer')?.toLowerCase() == 'false') {
            let requiredMessages;
            const cozyBingoConfig: any = this.configProviderService.provideCozyBingoConfig();
            if (cozyBingoConfig.cozyBingoFavouritesInRpwidget) {
                const bingoGlobalMessages = this.cozyBingoDataService.getGlobalMessages();
                requiredMessages = {
                    message: bingoGlobalMessages.preBuyUserDepositMessage,
                    header: bingoGlobalMessages.preBuyUserDepositHeader,
                    closeBtn: bingoGlobalMessages.confirmCloseButton,
                    purchaseButtonText: bingoGlobalMessages.preBuyUserDepositBtn,
                };
            } else {
                const globalMessages: any = this.configProviderService.provideGlobalConfig();
                requiredMessages = {
                    message: globalMessages.messages.preBuyUserDepositMessage,
                    header: globalMessages.messages.preBuyUserDepositHeader,
                    closeBtn: globalMessages.messages.confirmCloseButton,
                    purchaseButtonText: globalMessages.messages.preBuyUserDepositBtn,
                };
            }
            const dialogRef = this.dialog.open(ConfirmMessageComponent, {
                width: '378px',
                data: requiredMessages,
            });

            dialogRef.afterClosed().subscribe(() => {
                this.navigator.goTo(origin);
            });
        }
    }

    getPlayUrl(
        gameName: string,
        gameType: string,
        ballType: string,
        launchType: string,
        gameIcon: string,
        gameId: string,
        favstatus: boolean,
        origin: string,
    ): string {
        let result: string = '';
        if (origin.indexOf('?') === -1) {
            result =
                origin +
                '?bingoForcedGameLaunch=true&gameName=' +
                gameName +
                '&gameType=' +
                gameType +
                '&ballType=' +
                ballType +
                '&launchType=' +
                launchType +
                '&gameIcon=' +
                gameIcon +
                '&gameId=' +
                gameId +
                '&favstatus=' +
                favstatus +
                '&originUrl=' +
                origin;
        } else {
            result =
                origin +
                '&bingoForcedGameLaunch=true&gameName=' +
                gameName +
                '&gameType=' +
                gameType +
                '&ballType=' +
                ballType +
                '&launchType=' +
                launchType +
                '&gameIcon=' +
                gameIcon +
                '&gameId=' +
                gameId +
                '&favstatus=' +
                favstatus +
                '&originUrl=' +
                origin;
        }
        return result;
    }

    validateUser(
        gameName: string,
        gameType: any,
        ballType: any,
        launchType: any,
        gameIcon: any,
        gameId: any,
        favstatus: any,
        origin: any,
        gameObj: any,
    ): boolean {
        let forceGameLaunchObj: any;
        const playUrlObj: any = {};
        playUrlObj.gameName = gameName;
        playUrlObj.gameType = gameType;
        playUrlObj.ballType = ballType;
        playUrlObj.launchType = launchType;
        playUrlObj.gameIcon = gameIcon;
        playUrlObj.gameId = gameId;
        playUrlObj.favstatus = favstatus;
        playUrlObj.origin = origin;
        playUrlObj.vfBingo = true;
        playUrlObj.gameObj = gameObj;

        forceGameLaunchObj = {
            gameLaunchParam: playUrlObj,
            timeStamp: new Date().getTime(),
        };
        if (!this.user.isAuthenticated) {
            this.cacheManagerService.writeForceGameLaunchObjInSessionStorage('CasinoCoreForceGameObj', forceGameLaunchObj);
            this.loginDialog.whenReady.pipe(takeUntil(this.destroy$)).subscribe(() => this.loginDialog.open());
            return false;
        }

        return true;
    }

    launchBingoGames(redirectUrl: any, _gameName: string, gameType: string, origin: string): void {
        //setting overlay visibility.
        redirectUrl = decodeURIComponent(redirectUrl);
        if (gameType == 'Bingovf') {
            if (this.deviceService.isTouch) {
                this.navigator.goTo(redirectUrl);
            } else {
                this.openedRooms = [];
                if (!this.session.get('openedRooms')) this.session.set('openedRooms', this.openedRooms);
                this.openedRooms = this.session.get('openedRooms') || [];

                if (this.openedRooms.indexOf(_gameName) > -1) {
                    this.activeroom = _gameName;
                    return;
                }
                const gamePopOutWindow = window.open(
                    redirectUrl,
                    '_blank',
                    'height=605,width=975,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes',
                );
                if (origin) {
                    this.navigator.goTo(origin);
                }
                this.openedRooms.push(_gameName);
                this.session.set('openedRooms', this.openedRooms);
                const checkPopOutWindowOpenInterval = setInterval(() => {
                    if (this.activeroom == _gameName) {
                        this.activeroom = '';
                        gamePopOutWindow?.focus();
                    }
                    if (gamePopOutWindow?.closed) {
                        this.openedRooms = this.openedRooms.filter((a: string) => {
                            return a != _gameName;
                        });
                        this.session.set('openedRooms', this.openedRooms);
                        clearInterval(checkPopOutWindowOpenInterval);
                        this.recentGamesService.setRecentGames();
                    }
                }, 500);
            }
        } else this.navigator.goTo(redirectUrl);
    }
    launchGame(redirectUrl: any) {
        redirectUrl = decodeURIComponent(redirectUrl);
        if (this.isInGameClient) {
            window.parent.postMessage({ type: 'LaunchGame', data: redirectUrl }, window.location.origin);
            //return true;
        }
        this.navigator.goTo(redirectUrl);
    }

    getGameLaunchUrl(
        gameName: string,
        gameType: string,
        ballType: string,
        launchType: string,
        gameIcon: string,
        gameId: string,
        favstatus: boolean,
        originUrl: string,
    ): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        let endPoint = this.apiPathsHelperService.transformApi(apiPathsConfig.bingo['bgGameLaunch'], this.urlUtilityService.getBingoApiBaseUrl());
        if (apiPathsConfig.bingo['newApiStructure'] && apiPathsConfig.bingo['newApiStructure'] == 'enabled') {
            endPoint = apiPathsConfig.bingo['bgGameLaunch'];
        }
        const cozyBingoConfig = this.configProviderService.provideCozyBingoConfig();
        if (cozyBingoConfig.enableBingoGameLaunchPostMethod) {
            let lastLoginTime: any;
            if (this.currentSessionConfig.loginDuration != null) {
                const clientLoginDuration = toNumber(this.session.get('clientLoginDuration'));
                this.session.remove('clientLoginDuration');
                if (cozyBingoConfig.enableLastLoginTimeCheck) {
                    lastLoginTime = clientLoginDuration ? Date.now() - clientLoginDuration : Date.now() - this.currentSessionConfig.loginDuration;
                }
            }
            const postObj = {
                gameName: gameName,
                gameType: gameType,
                ballType: ballType,
                launchType: launchType,
                gameIcon: gameIcon,
                gameId: gameId,
                favstatus: favstatus,
                originUrl: originUrl,
                sso: this.claimsConfig.get('ssotoken'),
                themeId: '',
                originGameId: '',
                isReplaceGameSrc: false,
                lastLoginTime: lastLoginTime,
                isInGameClient: false,
            };
            if (apiPathsConfig.bingo['newApiStructure'] && apiPathsConfig.bingo['newApiStructure'] == 'enabled') {
                this.apiBingoService.post(endPoint, postObj, { withCredentials: true });
            } else {
                this.api.post(endPoint, postObj, { withCredentials: true });
            }
        }
        let res: Observable<any>;
        if (apiPathsConfig.bingo['newApiStructure'] && apiPathsConfig.bingo['newApiStructure'] == 'enabled') {
            res = this.apiBingoService.get(
                endPoint,
                {
                    gameName: gameName,
                    gameType: gameType,
                    ballType: ballType,
                    launchType: launchType,
                    gameIcon: gameIcon,
                    gameId: gameId,
                    favstatus: favstatus,
                    originUrl: originUrl,
                    sso: this.claimsConfig.get('ssotoken'),
                },
                { withCredentials: true },
            );
        } else {
            res = this.api.get(
                endPoint,
                {
                    gameName: gameName,
                    gameType: gameType,
                    ballType: ballType,
                    launchType: launchType,
                    gameIcon: gameIcon,
                    gameId: gameId,
                    favstatus: favstatus,
                    originUrl: originUrl,
                    sso: this.claimsConfig.get('ssotoken'),
                },
                { withCredentials: true },
            );
        }

        return res;
    }
    getCasinoGameLaunchUrl(variantName: string): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(apiPathsConfig.bingo['ccGameLaunch'], { variantName: variantName });
    }
    ngOnDestroy() {
        this.destroy$.complete();
    }
}
