import { NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { CasinoManager, ConfigProviderService } from '@casinocore/platform/core';
import { ClaimsService } from '@frontend/vanilla/core';

@Component({
    selector: 'cs-dynamic-player-title',
    templateUrl: 'dynamic-player-title.component.html',
    standalone: true,
    imports: [NgIf, NgClass, NgStyle],
})
export class DynamicPlayerTitleComponent implements OnInit {
    @Input() lobbyType: string;
    displayTitle: string;
    displayTitleIcon: string;
    displayTitleFontSize: string;
    displayTitleColor: string;
    isUkHeading: boolean;
    enableEpcotSubNavigation: boolean;

    constructor(
        private configProviderService: ConfigProviderService,
        private casinoManager: CasinoManager,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit() {
        if (this.claimsService.get('ssotoken')) {
            const globalConfig: any = this.configProviderService.provideGlobalConfig();
            if (globalConfig?.lobbyConfigurations['DisplayTitle'] && globalConfig?.messages) {
                const features: any = this.configProviderService.provideFeaturesConfig();
                const subnavConfig: any = this.configProviderService.provideSubNavigationConfig();
                this.isUkHeading = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.isUkHeading);
                const hours: number = new Date().getHours();
                const playerName: string = this.claimsService.get('givenname') || '';
                let displayTitle: string = globalConfig.lobbyConfigurations['DisplayTitle'];
                this.displayTitleIcon = globalConfig.lobbyConfigurations['DisplayTitleIcon'];
                this.displayTitleFontSize = globalConfig.messages['subCategoryTitleFontSize'];
                this.displayTitleColor = globalConfig.messages['DisplayDynamicTitleColor'];
                displayTitle = displayTitle.replace(/\[PlayerName\]/i, playerName);
                if (hours < 12) displayTitle = displayTitle.replace(/\[DayTime\]/i, globalConfig.messages['Morning'] || 'Morning');
                else if (hours < 18) displayTitle = displayTitle.replace(/\[DayTime\]/i, globalConfig.messages['Afternoon'] || 'Afternoon');
                else displayTitle = displayTitle.replace(/\[DayTime\]/i, globalConfig.messages['Evening'] || 'Evening');
                this.displayTitle = displayTitle;
                this.enableEpcotSubNavigation = subnavConfig.enableEpcotSubNavigation;
            }
        }
    }
}
