import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { CasinoLobbyService } from '@casinocore/platform/core';
import { Subscription } from 'rxjs';

import { CasinoPageContainerComponent } from '../casino-page-container/casino-page-container.component';

@Component({
    selector: 'cc-casino-view',
    templateUrl: 'casino-view.component.html',
    standalone: true,
    imports: [NgIf, CasinoPageContainerComponent],
})
export class CasinoViewComponent implements OnInit, OnDestroy {
    isLobbyLoaded: boolean;
    lobbyLoadSubscription: Subscription;

    constructor(private casinoLobbyService: CasinoLobbyService) {}

    ngOnInit() {
        this.lobbyLoadSubscription = this.casinoLobbyService.lobbyLoadedObservable.subscribe((response) => {
            if (response) {
                this.isLobbyLoaded = true;
                this.casinoLobbyService.categoryNotFoundSubject.next(null);
            }
        });
    }

    ngOnDestroy() {
        if (this.lobbyLoadSubscription) {
            this.lobbyLoadSubscription.unsubscribe();
        }
    }
}
