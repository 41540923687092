import { Injectable } from '@angular/core';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { TeaserService } from './teaser.service';

@Injectable({
    providedIn: 'root',
})
export class OptimizedTeaserManager {
    optimizedHeaderTeasers: any[] = [];
    optimizedTeaserResult: any;
    lMTCategories: any[] = [];
    globalTeaserSettings: any;
    optimizedTeasersData: any;
    maxRenderedTeasers: number;
    globalTeaserStyles: any;

    constructor(
        private configProviderService: ConfigProviderService,
        private teaserService: TeaserService,
    ) {}

    setOptimizedTeasers(category: any, displayMode: string) {
        this.maxRenderedTeasers = this.configProviderService.provideOptimizedTeaserConfig().maxRenderedTeasers;
        const maxTeasersToBeRendered: number = this.maxRenderedTeasers > 0 ? this.maxRenderedTeasers : 999;
        this.optimizedHeaderTeasers = [];
        const teaserResponse: any = this.teaserService.headerTeasers();
        if (teaserResponse) {
            const optimizedHeaderTeasers: any = teaserResponse.optimizedTeaserContents;
            this.globalTeaserSettings = teaserResponse.globalTeaserSettings;
            this.globalTeaserStyles = teaserResponse.globalTeaserStyles;
            if (
                category &&
                category.sitecoreData.showTeasers &&
                category.sitecoreData.optimizedTeasers &&
                category.sitecoreData.optimizedTeasers.length > 0 &&
                optimizedHeaderTeasers &&
                optimizedHeaderTeasers.length > 0
            ) {
                for (let i = 0; i < category.sitecoreData.optimizedTeasers.length; i++) {
                    const optimizedHeader: any = category.sitecoreData.optimizedTeasers[i];
                    const filteredTeaser: any = optimizedHeaderTeasers.filter((ot: any) => ot.data.metadata.id.parent.path === optimizedHeader.path);
                    if (filteredTeaser && filteredTeaser.length > 0) {
                        filteredTeaser.forEach((teaserItem: any) => {
                            if (teaserItem.displayMode == displayMode && this.optimizedHeaderTeasers.length < maxTeasersToBeRendered) {
                                this.optimizedHeaderTeasers.push(teaserItem);
                            }
                        });
                    }
                }
            }
        }
        return this.optimizedHeaderTeasers;
    }

    setOptimizedTeasersBasedOnCatery(category: any) {
        this.maxRenderedTeasers = this.configProviderService.provideOptimizedTeaserConfig().maxRenderedTeasers;
        const optimizedSubNavigationTeasers: any[] = [];
        const optimizedEmbeddedBelowTeasers: any[] = [];
        const optimizedEmbeddedAboveTeasers: any[] = [];
        const optimizedTopTeasers: any[] = [];
        const teaserHeaders = this.teaserService.headerTeasers();
        if (teaserHeaders) {
            const optimizedHeaderTeasers: any = teaserHeaders.optimizedTeaserContents;
            this.globalTeaserSettings = teaserHeaders.globalTeaserSettings;
            if (
                category &&
                category.sitecoreData.showTeasers &&
                category.sitecoreData.optimizedTeasers &&
                category.sitecoreData.optimizedTeasers.length > 0 &&
                optimizedHeaderTeasers &&
                optimizedHeaderTeasers.length > 0
            ) {
                category.sitecoreData.optimizedTeasers.forEach((optimizedHeader: any) => {
                    const filteredTeaser: any = optimizedHeaderTeasers.filter((ot: any) => ot.data.metadata.id.parent.path === optimizedHeader.path);
                    if (filteredTeaser && filteredTeaser.length > 0) {
                        filteredTeaser.forEach((teaserItem: any) => {
                            const displayMode = teaserItem.displayMode;
                            switch (displayMode) {
                                case 'SubNavigation': {
                                    optimizedSubNavigationTeasers.push(teaserItem);
                                    break;
                                }
                                case 'EmbeddedBelowGames': {
                                    optimizedEmbeddedBelowTeasers.push(teaserItem);
                                    break;
                                }
                                case 'EmbeddedAboveGames': {
                                    optimizedEmbeddedAboveTeasers.push(teaserItem);
                                    break;
                                }
                                case 'Top': {
                                    optimizedTopTeasers.push(teaserItem);
                                    break;
                                }
                                default: {
                                    break;
                                }
                            }
                        });
                    }
                });
            }
            let optimizedTeasersData: any;
            optimizedTeasersData = {
                optimizedSubNavigationTeasers:
                    this.maxRenderedTeasers > 0 ? optimizedSubNavigationTeasers.splice(0, this.maxRenderedTeasers) : optimizedSubNavigationTeasers,
                optimizedEmbeddedBelowTeasers:
                    this.maxRenderedTeasers > 0 ? optimizedEmbeddedBelowTeasers.splice(0, this.maxRenderedTeasers) : optimizedEmbeddedBelowTeasers,
                optimizedEmbeddedAboveTeasers:
                    this.maxRenderedTeasers > 0 ? optimizedEmbeddedAboveTeasers.splice(0, this.maxRenderedTeasers) : optimizedEmbeddedAboveTeasers,
                optimizedTopTeasers: this.maxRenderedTeasers > 0 ? optimizedTopTeasers.splice(0, this.maxRenderedTeasers) : optimizedTopTeasers,
            };
            return optimizedTeasersData;
        }
    }

    getGlobalTeaserSettings() {
        return this.globalTeaserSettings;
    }
}
