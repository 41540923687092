import { NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { CasinoCoreGamelaunchService, CasinoLobbyService, ConfigProviderService, GameLaunch } from '@casinocore/platform/core';
import { LoginDialogService, Message, MessageLifetime, MessageQueueService, MessageType, UserService, WindowRef } from '@frontend/vanilla/core';
import { MessagePanelComponent } from '@frontend/vanilla/features/message-panel';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cc-game-iframe-launcher',
    templateUrl: 'game-iframe-launcher.component.html',
    standalone: true,
    imports: [MessagePanelComponent, NgIf, NgStyle],
})
export class GameIframeLauncherComponent implements OnInit, OnDestroy {
    @Input() gameId: string;
    @Input() thunderBiteIframeHeight: number;
    isIframeReady: boolean;
    iframeUrl: any;
    heightIframe: number;
    widthIframe: number;
    lobbyLoadedSubscription: Subscription;
    private destroy$ = new Subject<void>();

    constructor(
        private casinoCoreGamelaunchService: CasinoCoreGamelaunchService,
        private window: WindowRef,
        private sanitizer: DomSanitizer,
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
        private messageQueue: MessageQueueService,
        private loginDialog: LoginDialogService,
        private user: UserService,
    ) {}

    ngOnInit() {
        this.lobbyLoadedSubscription = this.casinoLobbyService.lobbyLoadedObservable.subscribe((response: any) => {
            if (response) {
                if (this.user.isAuthenticated) {
                    this.heightIframe = this.thunderBiteIframeHeight;
                    this.setIframeURL();
                } else {
                    const loginResponseObj = {
                        returnUrl: window.location.href,
                        loginMessageKey: 'GameLaunch',
                    };
                    this.loginDialog.whenReady.pipe(takeUntil(this.destroy$)).subscribe(() => this.loginDialog.open(loginResponseObj));
                }
            }
        });
    }

    setIframeURL() {
        let gameInfo: any;
        let gameObj: any;

        gameInfo = this.casinoLobbyService.getGameInfo(this.gameId);
        if (gameInfo && gameInfo.metaLobbyType) {
            gameObj = {
                gameId: this.gameId,
                lobbyType: gameInfo.metaLobbyType,
            };
            const gameModel = new GameLaunch();
            gameModel.game = gameObj;
            gameModel.isTouch = false;
            gameModel.gamePosition = 0;
            gameModel.categoryType = '';
            gameModel.from = 'LiveGamesIframe';
            gameModel.replaceRouteInHistory = false;
            gameModel.isDemoLaunch = false;
            gameModel.skipUnfinGame = false;
            gameModel.gameLaunchedFrom = 'LiveGamesIframe';
            gameModel.gridPosition = 1;
            gameModel.gameSection = 'LiveGamesIframe';
            gameModel.lobbyType = gameInfo.metaLobbyType;
            gameModel.gameContainer = 'PageContainer';
            gameModel.returnUrl = window.location.href;
            gameModel.fetchLiveGameIFrame = true;
            gameModel.clickTimeStamp = this.casinoCoreGamelaunchService.currentTimeStamp();
            this.casinoCoreGamelaunchService.launchGame(gameModel, (callbackResponse: any) => {
                if (!callbackResponse.isError && callbackResponse.gameLaunchUrl) {
                    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(callbackResponse.gameLaunchUrl);
                    this.isIframeReady = true;
                    this.widthIframe = this.window.nativeWindow.innerWidth;
                } else {
                    this.gameNotFoundAction(callbackResponse.errorMessage);
                }
            });
        } else {
            this.gameNotFoundAction();
        }
    }

    onResize() {
        this.widthIframe = this.window.nativeWindow.innerWidth;
    }

    gameNotFoundAction(errorMessage?: any) {
        this.messageQueue.clear();
        const globalConfig = this.configProviderService.provideGlobalConfig();
        const errormessage: string = errorMessage ? errorMessage : globalConfig.messages['GameNotAvailable'].replace('{0}', this.gameId);
        const message: Message = {
            html: errormessage,
            type: MessageType.Information,
            lifetime: MessageLifetime.Single,
            scope: 'gameiframelauncher',
        };
        if (message) {
            this.messageQueue.add(message);
        }
        return;
    }

    ngOnDestroy() {
        if (this.lobbyLoadedSubscription) {
            this.lobbyLoadedSubscription.unsubscribe();
        }
        this.destroy$.complete();
    }
}
