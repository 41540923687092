//import { PlatformModule } from '../platform.module';
import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

/**
 * @stable
 */
@LazyClientConfig({ key: 'csVideoPreviewConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: videoPreviewClientConfigFactory,
})
export class VideoPreviewClientConfig {
    playVideoFromStart: boolean;
    setVideoPlayTimeoutint: number;
}

export function videoPreviewClientConfigFactory(service: LazyClientConfigService) {
    return service.get(VideoPreviewClientConfig);
}
