import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { NavigationService, ProductHomepagesConfig } from '@frontend/vanilla/core';

import { ConfigProviderService } from '../config-provider/config-provider.service';

@Component({
    selector: 'cc-toaster-msg',
    templateUrl: 'toaster-msg.component.html',
    standalone: true,
    imports: [NgIf],
})
export class ToasterMsgComponent implements OnInit {
    @Input() showClaimToster: boolean;
    @Input() ToasterText: string;
    @Input() displayShowMore: boolean;
    navigateUrl: string;
    winnersFeedShowMoreLinkText: string;
    globalConfig: any;

    constructor(
        private navigator: NavigationService,
        private pageConfig: ProductHomepagesConfig,
        private configProviderService: ConfigProviderService,
    ) {}
    ngOnInit() {
        this.globalConfig = this.configProviderService.provideGlobalConfig();
        this.winnersFeedShowMoreLinkText = this.globalConfig.messages['WinnersFeedShowMoreLinkText'];
    }

    closeCardToster() {
        this.showClaimToster = false;
    }

    showMore(): void {
        const globalJackpotClientConfig = this.configProviderService.provideGlobalJackpotConfig();
        this.navigateUrl = this.pageConfig?.casino + '/g/' + globalJackpotClientConfig?.globalJackpotPathSegments?.toLowerCase();
        this.navigator.goTo(this.navigateUrl);
    }
}
