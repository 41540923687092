<div
    class="small-icon single-jackpot-redesign-widget jackpot-widget-view-port"
    *ngIf="showJackpotWidget"
    style.background-image="{{ jackpotWidgetBackgroundImage?.src ? 'url(' + jackpotWidgetBackgroundImage.src + ')' : '' }}"
    [ngStyle]="{ 'background-color': glassEffectColor }"
    [ngClass]="{
        'single-jackpot-glasseffect': singleJackpotGlassEffect,
        'jackpot-alignment-left': singleJackpotAlignment == 'left',
        'jackpot-alignment-right': singleJackpotAlignment == 'right',
        'danskespil-arrow-styles-singleJackpot': changeCarouselView
    }">
    <div
        class="single-jackpot-left-container jackpot-widget-left-width"
        style.background-image="{{ jackpotWidgetBackgroundImage?.src ? 'url(' + jackpotWidgetBackgroundImage.src + ')' : '' }}"
        *ngIf="jackpotType == 'single'">
        <img class="jackpotIcon" [src]="jackpotWidgetImage && jackpotWidgetImage.src" />
        <div class="single-jpk-ticker" [ngClass]="{ jackpotnamehidden: !showJackpotName }">
            <div
                [ngStyle]="{ color: singleJackpotTextClr }"
                class="jackpotText"
                *ngIf="showJackpotName && jackpotDisplayName && jackpotDisplayName.trim().length > 0">
                {{ jackpotDisplayName }}
            </div>
            <cc-flipclock-redesign
                [isMega]="'false'"
                [currency]="currency"
                [amount]="jackpotValue"
                [jackpotName]="jackpotName"
                [widgetType]="'SingleJackpot'"
                [releaseamount]="singleJackpotReleaseAmount"
                [lobbyType]="lobbyType">
            </cc-flipclock-redesign>
        </div>
    </div>
    <div class="single-jackpot-right-container jackpot-right-content">
        <div class="sjpk-widget-header">
            <div class="sjpkText" [ngStyle]="{ color: singleJackpotPlayAnyGameTextColor }" [innerHTML]="singleJackpotPlayAnygameText"></div>
            <div class="see-all-button" *ngIf="ctaUrl?.url && !changeCarouselView">
                <!-- <div class="btn btn-cta btn-primary" [innerHTML]="cta" (click)="gotoJackpots(ctaUrl?.url)"></div> -->
                <cs-gametile-cta
                    [isLegacy]="isLegacy"
                    [kind]="'primary'"
                    [size]="'medium'"
                    (click)="gotoJackpots(ctaUrl?.url)"
                    [csDecoratorClass]="'btn-cta'"
                    [cta_text]="cta">
                </cs-gametile-cta>
            </div>
            <div *ngIf="ctaUrl?.url && changeCarouselView">
                <div
                    class="casino-seeall-text"
                    (click)="gotoJackpots(ctaUrl?.url)"
                    [ngStyle]="{ color: subCategoryTextColor }"
                    [innerHTML]="cta"></div>
            </div>
        </div>
        <div class="singlejackpot-games-container scrollable-games" [style.overflow-x]="!isTouch ? 'hidden' : 'scroll'">
            <cc-jackpot-widget-redesign-grid
                *ngIf="games && games.length > 0"
                [games]="games"
                [categoryObj]="categoryObj"
                [widgetType]="'SingleJackpot'"></cc-jackpot-widget-redesign-grid>
        </div>
    </div>
</div>
