import { EventEmitter, Injectable } from '@angular/core';

import { DeviceService, UserService, WindowRef } from '@frontend/vanilla/core';

import { GeolocationClientConfig } from '../../client-config/geo-location.config';
import { ConfigProviderService } from '../../config-provider/config-provider.service';
import { CasinoManager } from '../../shared/casino-manager.service';
import {
    CoordinatorStates,
    ICoordinatorProbeResult,
    IErrorData,
    IInitializationInput,
    IIntegrationApp,
    OnCoordinatorProbed,
    OnError,
    OnInitialized,
    OnUninitialized,
    Optional,
    PLCIdentifier,
} from '../coordinator.models';

@Injectable({
    providedIn: 'root',
})
export class GeolocationCoordinatorService implements IIntegrationApp {
    private _onInitialized: OnInitialized<CoordinatorStates>;
    private _onUninitialized: OnUninitialized;
    private _onError: OnError;
    private _onProbed: OnCoordinatorProbed;
    private geolocationCoordinator: Optional<IIntegrationApp>;
    private geolocationConfig: GeolocationClientConfig;
    private _initializePending: boolean = false;
    private _uninitializePending: boolean = false;
    private _initializationInput: IInitializationInput | null | undefined;
    isSynchronizedUninit: boolean = false;
    constructor(
        private windowRef: WindowRef,
        private casinoManager: CasinoManager,
        private deviceservice: DeviceService,
        private readonly userService: UserService,
        private readonly configProviderService: ConfigProviderService,
    ) {
        this.geolocationConfig = this.configProviderService.provideGeoLocationConfig();
        this.hookupCoordinator();
        addEventListener(
            'geolocationcoordinator:bootstrapped',
            () => {
                this.hookupCoordinator();
            },
            {
                once: true,
            },
        );
    }

    onAvailable: EventEmitter<GeolocationCoordinatorService>;

    get appDownloadUrl(): string {
        return this.geolocationCoordinator?.appDownloadUrl || '';
    }

    probe(): void {
        this.geolocationCoordinator!.probe();
    }

    initialize(input?: IInitializationInput): void {
        this.isSynchronizedUninit = false;
        if (this.geolocationCoordinator) {
            this.geolocationCoordinator.initialize({
                session: (input && input.session) || (this.userService && this.userService.ssoToken) || 'NotAvailable',
                channelId: input && input.channelId,
                plcIdentifier: input && input?.plcIdentifier,
                showPLCPopup: input && input.showPLCPopup,
            });
        } else {
            this._initializePending = true;
            this._initializationInput = input;
        }
    }

    uninitialize(): void {
        if (this.geolocationCoordinator) {
            this.geolocationCoordinator.uninitialize();
        } else {
            this._uninitializePending = true;
        }
    }

    set onInitialized(callback_funvction: OnInitialized<CoordinatorStates>) {
        this._onInitialized = callback_funvction;
        if (this.geolocationCoordinator) {
            this.geolocationCoordinator.onInitialized = this._onInitialized;
        }
    }

    set onUninitialized(value: OnUninitialized) {
        this._onUninitialized = value;
        if (this.geolocationCoordinator) {
            this.geolocationCoordinator.onUninitialized = value!;
        }
    }

    set onError(value: OnError) {
        this._onError = value;
        if (this.geolocationCoordinator) {
            this.geolocationCoordinator.onError = value!;
        }
    }

    set onProbed(value: OnCoordinatorProbed) {
        this._onProbed = value;
        if (this.geolocationCoordinator) {
            this.geolocationCoordinator.onProbed = value!;
        }
    }

    registerUnload() {
        this.windowRef.nativeWindow.onunload = () => {
            //console.info('Window onunload::');
            this.uninitialize();
        };
        this.windowRef.nativeWindow.removeEventListener('message', this.coordinationSynchronizer);
    }

    registerSynchronization() {
        this.geolocationConfig = this.configProviderService.provideGeoLocationConfig();
        if (this.geolocationConfig.isEnabled && this.geolocationConfig.synchronizeCoordination) {
            this.windowRef.nativeWindow.addEventListener('message', this.coordinationSynchronizer);
        }
    }

    getPLCIdentifier(): PLCIdentifier {
        if (this.casinoManager.isTouch()) {
            if (this.deviceservice.isiOS) {
                return 'oobeeiOS';
            }
            return 'oobeeAndroid';
        }
        return 'geocomply';
    }

    private onGeolocationError(errorData: IErrorData): void {
        if (this.onError) {
            this.onError(errorData);
        } else {
            if (this._onError) {
                this._onError(errorData);
            }
        }
    }

    private onGeolocationInitialized(initializationData: CoordinatorStates): void {
        if (this.onInitialized) {
            this.onInitialized(initializationData);
        } else {
            if (this._onInitialized) {
                this._onInitialized(initializationData);
            }
        }
    }

    private onGeolocationUninitialized(): void {
        if (this.onUninitialized) {
            this.onUninitialized();
        } else {
            if (this._onUninitialized) {
                this._onUninitialized();
            }
        }
    }

    private onGeolocationProbed(probeResult: ICoordinatorProbeResult): void {
        if (this.onProbed) {
            this.onProbed(probeResult);
        } else {
            if (this._onProbed) {
                this._onProbed(probeResult);
            }
        }
    }

    private hookupCoordinator(): void {
        const geolocationCoordinator = (window as any).geolocationCoordinator as IIntegrationApp;
        if (geolocationCoordinator) {
            this.geolocationCoordinator = geolocationCoordinator;
            this.geolocationCoordinator.onError = this.onGeolocationError.bind(this);
            this.geolocationCoordinator.onInitialized = this.onGeolocationInitialized.bind(this);
            this.geolocationCoordinator.onUninitialized = this.onGeolocationUninitialized.bind(this);
            this.geolocationCoordinator.onProbed = this.onGeolocationProbed.bind(this);
            this.onAvailable && this.onAvailable.emit(this);

            if (this._initializePending) {
                this._initializePending = false;
                this.initialize(this._initializationInput || undefined);
                this._initializationInput = null;
                return;
            }
            if (this._uninitializePending) {
                this._uninitializePending = false;
                this.uninitialize();
            }
        }
    }

    private readonly coordinationSynchronizer = (messageEvent: MessageEvent): void => {
        if (this.geolocationConfig.isEnabled && this.geolocationConfig.synchronizeCoordination) {
            if (
                messageEvent &&
                messageEvent.data &&
                messageEvent.data.trim &&
                messageEvent.data.trim() === this.geolocationConfig.synchronizationEvent.trim()
            ) {
                this.isSynchronizedUninit = true;
                this.geolocationCoordinator?.uninitialize();
            }
        }
    };
}
