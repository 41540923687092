<div *ngIf="widgetType !== 'SingleJackpot'">
    <div *ngIf="amount !== undefined && isMega !== 'true'">
        <div class="jackpot">
            <div class="jackpot-digit" *ngIf="currency !== 'Kr.'">{{ currency }}</div>
            <div class="jackpot-digit" [ngClass]="{ comma: mjItem.value === ',', dot: mjItem.value === '.' }" *ngFor="let mjItem of majorItems">
                {{ mjItem.value }}
            </div>
            <div class="jackpot-digit kr-currency" *ngIf="currency === 'Kr.'">Kr.</div>
        </div>
    </div>
    <div *ngIf="amount != undefined && isMega === 'true'">
        <div class="jackpot">
            <div class="jackpot-digit" *ngIf="currency !== 'Kr.'">{{ currency }}</div>
            <div class="jackpot-digit" [ngClass]="{ comma: mjItem.value === ',', dot: mjItem.value === '.' }" *ngFor="let mjItem of majorItems">
                {{ mjItem.value }}
            </div>
            <div class="jackpot-digit kr-currency" *ngIf="currency === 'Kr.'">Kr.</div>
        </div>
    </div>
</div>

<div *ngIf="widgetType === 'SingleJackpot'">
    <div *ngIf="amount != undefined && isMega !== 'true'" class="tickerpadding">
        <div class="seg">
            <div class="flip-wrapper" *ngIf="!enableJpGroupTickingForLobby">
                <div [ngStyle]="{ 'color': singlejackpotvalueDigitTxtClr, 'background-color': singlejackpotvalueDigitBgClr }" class="curr">
                    {{ currency }}
                </div>
            </div>
        </div>
        <div class="seg" [ngClass]="{ Comma: mjItem.value === ',', dot: mjItem.value === '.' }" *ngFor="let mjItem of majorItems">
            <div *ngIf="mjItem.value === ','" class="flip-comma" [ngStyle]="{ color: singlejackpotvalueDigitTxtClr }">
                {{ mjItem?.value }}
            </div>
            <div class="flip-wrapper" *ngIf="mjItem.value !== ','">
                <div class="flip" [ngStyle]="{ 'color': singlejackpotvalueDigitTxtClr, 'background-color': singlejackpotvalueDigitBgClr }">
                    {{ mjItem?.value }}
                </div>
            </div>
        </div>
    </div>
</div>
