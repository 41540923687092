<!-- unscrollable-featured-games  for mobile and desktop-->
<!-- <h1>{{Type}}</h1> -->
<div [style.height.px]="height()">
    <mat-grid-list
        [cols]="breakpoint()"
        [style.width.px]="width()"
        [style.height.px]="height()"
        [style.min-height.px]="unscrollableHeight()"
        [rowHeight]="rowHeight()"
        [gutterSize]="gutterSize()"
        *ngIf="Type() === 'unscrollable-games'"
        class="rowCount-{{ rowCount() }}"
        [class.casino-newgridtypesicon6]="subcategory().isIconSixCategory"
        [class.casino-newgridtypes]="isIconSevenCategory()">
        @for (game of newFeatureGridArr$ | async; let i = $index; track game.gameId) {
            <mat-grid-tile [colspan]="game.colSpan" [rowspan]="game.rowSpan" [style.border-radius]="game?.gameTileBorderRadius">
                <div [ngClass]="getClassByicon(game)" id="heightFix-{{ subcategory().categoryid }}-{{ game.gameId }}" class="game">
                    <cc-game-lobby
                        *rxLet="[]"
                        id="gameIdentifier_{{ subcategory().categoryid }}_{{ game.gameId }}"
                        [attr.data-productid]="subcategory().categoryid + '_' + game.gameId"
                        [ngClass]="game?.infoIconClass"
                        [game]="game"
                        [gamePosition]="i"
                        [category]="subcategory()"
                        [gameLaunchedFrom]="gameLaunchedFrom()"
                        [gameIndex]="i + 1"></cc-game-lobby>
                </div>
            </mat-grid-tile>
        }
    </mat-grid-list>
</div>
<!--scrollbale-games for both desktop & mobile with multi row support(new feature grid) -->

<ngu-carousel
    #desktopGamesCarousel
    (onMove)="onmoveFn($event)"
    [inputs]="carouselConfigNonTouch()"
    [dataSource]="newFeatureGridArr()"
    *ngIf="Type() === 'scrollable-games' && newFeatureGridArr() && newFeatureGridArr().length > 0"
    [class.jackpot-justify-center-icon-7-xs]="jackpot_justify_center_icon_7_xs()"
    [class.jackpot-justify-center-icon-7-sm]="jackpot_justify_center_icon_7_sm()"
    [class.casino-newgridtypesicon6]="subcategory().isIconSixCategory"
    [class.casino-newgridtypes]="subcategory().isIconSevenCategory"
    [class.ngu-touch-scrollable-games]="isTouch()">
    <ngu-item *nguCarouselDef="let gameArr; let nguindex = index">
        <mat-grid-list [cols]="gameArr.maxColSpan" [rowHeight]="rowHeight()" [gutterSize]="gutterSize()">
            @for (game of gameArr.games; track game.gameId; let i = $index) {
                <mat-grid-tile [colspan]="gameArr.maxColSpan" [rowspan]="gameArr.maxRowSpan" [style.border-radius]="game?.gameTileBorderRadius">
                    <div class="game" [ngClass]="getClassByicon(game)">
                        <cc-game-lobby
                            *rxLet="[]"
                            id="gameIdentifier_{{ subcategory().categoryid }}_{{ game.gameId }}"
                            [attr.data-productid]="subcategory().categoryid + '_' + game.gameId"
                            [ngClass]="game?.infoIconClass"
                            [game]="game"
                            [gamePosition]="nguindex"
                            [category]="subcategory()"
                            [gameLaunchedFrom]="gameLaunchedFrom()"
                            [gameIndex]="game.gameIndex + 1"></cc-game-lobby>
                    </div>
                </mat-grid-tile>
            }
        </mat-grid-list>
    </ngu-item>
    <button
        *ngIf="!isTouch() && !changeCarouselView()"
        [class.hidden-cls]="isPreButtonVisible()"
        NguCarouselPrev
        class="theme-left ngx-button-left"
        aria-hidden="true"></button>
    <button
        *ngIf="!isTouch() && !changeCarouselView()"
        [class.hidden-cls]="isNextButtonVisible()"
        NguCarouselNext
        class="theme-right ngx-button-right"
        (click)="loadNextGames.next()"
        aria-hidden="true"></button>
</ngu-carousel>

<!-- scrollable-games with different icons mobile-->
<div
    *ngIf="Type() === 'scrollable-games-multiple-icons' && isTouch()"
    class="scrollable-games-multiple-icons"
    [style.min-height.px]="scrollableMultipleIconsHeight()"
    [class.jackpot-games-display]="games().length < 6 && gameLaunchedFrom() === 'JackpotWidget_' + subcategory().engCategoryName">
    @for (game of games$ | async; let i = $index; track game.gameId) {
        <div class="game" [ngClass]="getClassByicon(game)">
            <div [class.gyml-frame]="game.isGYML">
                <mat-grid-list [cols]="game.colSpan" rowHeight="1:1">
                    <mat-grid-tile [colspan]="game.colSpan" [rowspan]="game.rowSpan" [style.border-radius]="game?.gameTileBorderRadius">
                        <cc-game-lobby
                            *rxLet="[]"
                            id="gameIdentifier_{{ subcategory().categoryid }}_{{ game.gameId }}"
                            [attr.data-productid]="subcategory().categoryid + '_' + game.gameId"
                            [ngClass]="game?.infoIconClass"
                            [game]="game"
                            [gamePosition]="i"
                            [category]="subcategory()"
                            [gameLaunchedFrom]="gameLaunchedFrom()"
                            [gameIndex]="i + 1"></cc-game-lobby>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>
    }
</div>
