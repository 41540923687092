import { ChangeDetectionStrategy, Component, Input, booleanAttribute } from '@angular/core';

import { CsCtaComponent, CsCtaKind, CsCtaSize, CsCtaVariant } from '../cs-cta/cs-cta.component';

@Component({
    selector: 'cs-global-jackpot-cta',
    templateUrl: './global-jackpot-cta.component.html',
    standalone: true,
    imports: [CsCtaComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalJackpotCtaComponent implements GlobalJackpotCtaViewModel, GlobalJackpotCtaLegacyViewModel {
    @Input() size: CsCtaSize = 'large';
    @Input() variant: CsCtaVariant = 'filled';
    @Input() kind: CsCtaKind = 'primary';
    @Input() cta_text = '';
    @Input({ transform: booleanAttribute }) isLegacy = false;
    @Input() casinoDsClass = '';
    @Input() inlineStyle = '';
}

export interface GlobalJackpotCtaViewModel {
    size: CsCtaSize;
    variant: CsCtaVariant;
    kind: CsCtaKind;
    cta_text: string;
    casinoDsClass: string;
    inlineStyle: string;
}

export interface GlobalJackpotCtaLegacyViewModel {
    isLegacy: boolean;
}
