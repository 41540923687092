import { Injectable } from '@angular/core';

import { Logger, TimerService } from '@frontend/vanilla/core';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';

@Injectable({
    providedIn: 'root',
})
export class JackpotService {
    jackpotsCache: any = {};
    fetchJackpotPromise: any;
    listenersCnt = 0;
    timer: number;

    constructor(
        private api: PlatformApiService,
        private timerService: TimerService,
        private configProviderService: ConfigProviderService,
        private logger: Logger,
    ) {}
    fetchJackpot() {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        this.api.get(apiPathsConfig.jackpots['jpData'], null, { showSpinner: false }).subscribe(
            (response: any) => {
                this.jackpotsCache = response;
            },
            (error) => {
                this.logger.error('Unable to retrieve data from jpData API : ' + error);
            },
        );
    }
    startFeed() {
        const JackpotData = this.configProviderService.provideJackpotConfig();
        this.listenersCnt++;
        if (!this.fetchJackpotPromise) {
            this.fetchJackpot();
            this.fetchJackpotPromise = this.timerService.setIntervalOutsideAngularZone(() => {
                this.fetchJackpot();
            }, JackpotData.jpInterval);
        }
    }
    getJackpotData() {
        return this.jackpotsCache;
    }
    stopFeed() {
        this.listenersCnt--;
        if (this.listenersCnt === 0 && this.fetchJackpotPromise) {
            this.timerService.clearInterval(this.fetchJackpotPromise);
            this.fetchJackpotPromise = undefined;
            this.jackpotsCache = [];
        }
    }
}
