import { Compiler, ComponentFactory, ComponentFactoryResolver, Injectable, Injector, NgModuleFactory, NgModuleRef, Type } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

type ModuleWithDynamicComponents = Type<any> & {
    dynamicComponentsMap: {};
};
@Injectable({
    providedIn: 'root',
})
export class GamingStoriesLoaderService {
    ngModuleRef: NgModuleRef<any>;
    ngModuleFactory: NgModuleFactory<any>;
    componentFactories: ComponentFactory<any>[];
    componentFactoryResolver: ComponentFactoryResolver;
    onAppInitHandler: any;
    gamingStoriesModuleLoadedSubject = new BehaviorSubject<boolean>(false);
    gamingStoriesModuleLoadedObservable: Observable<boolean> = this.gamingStoriesModuleLoadedSubject.asObservable();
    gamingStoriesModuleLoaded: boolean;

    constructor(
        private injector: Injector,
        private compiler: Compiler,
    ) {}

    loadGamingStoriesModule(): void {
        this.lazyloader();
    }

    getLazyComponentFactory(componentName: string): ComponentFactory<any> {
        const dynacmiComp = (this.ngModuleFactory.moduleType as ModuleWithDynamicComponents).dynamicComponentsMap;
        const componentType = dynacmiComp[componentName as keyof typeof dynacmiComp];
        const componentFactory = this.ngModuleRef.componentFactoryResolver.resolveComponentFactory(componentType);
        return componentFactory;
    }

    isModuleLoaded() {
        return this.gamingStoriesModuleLoaded;
    }

    lazyloader() {
        import('@casinocore/platform/gaming-stories')
            .then((m) => m.GamingStoriesModule)
            .then((moduleOrFactory) => {
                if (moduleOrFactory instanceof NgModuleFactory) {
                    return moduleOrFactory;
                } else {
                    return this.compiler.compileModuleAsync(moduleOrFactory);
                }
            })
            .then((moduleFactory) => {
                this.ngModuleFactory = moduleFactory;
                this.ngModuleRef = moduleFactory.create(this.injector);
                this.onAppInitHandler = this.ngModuleRef.injector.get((<any>moduleFactory.moduleType).onAppInitHandler);
                this.onAppInitHandler.execute();
                this.gamingStoriesModuleLoaded = true;
                this.gamingStoriesModuleLoadedSubject.next(true);
            });
    }
}
