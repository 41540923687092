import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
    BingoGameLaunchService,
    CasinoCoreGamelaunchService,
    CasinoIFrameOverlayService,
    CasinoLobbyService,
    CasinoManager,
    CasinoWidgetService,
    ConfigProviderService,
    DesignSystemEnablerService,
    GameLaunch,
    GameLaunchPlaces,
    GameLaunchTrackingService,
    JackpotDataService,
    NativeAppHelperService,
    SitecoreImageResizePipe,
    TeaserService,
    VCClientService,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, GameTileCtaComponent } from '@frontend/casino-ui';
import {
    DeviceService,
    Logger,
    NativeAppService,
    NativeEvent,
    NavigationService,
    TrackingService,
    UserService,
    WindowRef,
} from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { cloneDeep } from 'lodash-es';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Component({
    selector: 'cc-teasers',
    templateUrl: 'teasers.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        NgFor,
        NgStyle,
        TrustAsHtmlPipe,
        SitecoreImageResizePipe,
        SwiperComponent,
        GameTileCtaComponent,
        DecoratorClassDirective,
    ],
})
export class TeasersComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input() teaserCategory: any;
    @Input() teaserLocation: any;
    @Input() teasersSource: any;
    @Input() isDefaultCategory?: boolean;
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    @ViewChild('swiper') swiperRef: SwiperComponent;
    EnableJackpotTickerTeasers: any;
    teaserHeight: any;
    elementWidth: any;
    jackpot_image: any;
    index: any = 0;
    isNewSportsNative: any;
    jpTitle: any = 24;
    jpContainer: any = 37;
    jpCta: any = 48;
    teasers: any = [];
    enablebingoplay: boolean;
    isLandscape: any;
    teaserLandscape: any;
    isButtonVisible: boolean;
    isTouch: boolean;
    TeaserMode: any;
    teaserLaunchRealtiveUri: string;
    subCategoryTeaserMode: any;
    categoryNavTeaserMode: any;
    Modeset: any;
    bingocta: any;
    enableLobbyBackgroundImage: boolean;
    teasersconfigloaded: any;
    enableEpcotSubNavigation: boolean;
    isLegacy: boolean = false;

    swiperOptionsTeasers: SwiperOptions = {
        modules: [Navigation, Pagination, Autoplay],
        loop: false,
        autoHeight: true,
        allowTouchMove: true,
        spaceBetween: 1,
        direction: 'horizontal',
        slidesPerView: 'auto',
        centeredSlides: false,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
            waitForTransition: false,
        },
        pagination: {
            type: 'bullets',
            clickable: true,
            el: '.swiper-pagination',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        observer: true,
        rewind: true,
        slidesPerGroup: 1,
        keyboard: true,
        watchOverflow: true,
        mousewheel: false,
        scrollbar: false,
        preventClicks: true,
        preventClicksPropagation: true,
    };

    constructor(
        private windowRef: WindowRef,
        private configProviderService: ConfigProviderService,
        private jackpot: JackpotDataService,
        private tracking: TrackingService,
        private NativeApplication: NativeAppService,
        private logger: Logger,
        private vcClientService: VCClientService,
        private user: UserService,
        private navigation: NavigationService,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private gameLaunchPlaces: GameLaunchPlaces,
        private casinoManager: CasinoManager,
        private nativeAppHelper: NativeAppHelperService,
        private casinoIFrameOverlayService: CasinoIFrameOverlayService,
        private casinoWidgetService: CasinoWidgetService,
        private bingoGameLaunchService: BingoGameLaunchService,
        private casinoLobbyService: CasinoLobbyService,
        private teaserService: TeaserService,
        private gameLaunchTrackingService: GameLaunchTrackingService,
        private deviceService: DeviceService,
        private destroyRef: DestroyRef,
        private designSystemEnablerService: DesignSystemEnablerService,
    ) {
        this.EnableJackpotTickerTeasers = this.configProviderService.provideFeaturesConfig().enableJackpotTickerTeasers;
        this.teaserLaunchRealtiveUri = '/' + this.configProviderService.provideMcPageConfig().labelPrefix + '/launchng/';
    }

    ngOnInit() {
        if (this.teaserLocation === 'Top') {
            this.Modeset = this.TeaserMode;
        } else if (this.teaserLocation === 'SubNavigation') {
            this.Modeset = this.categoryNavTeaserMode;
        } else {
            this.Modeset = this.subCategoryTeaserMode;
        }
        this.isButtonVisible = true;
        this.isLandscape = this.isInLandscape();
        this.teaserLandscape = this.elementWidth >= 480;
        this.JackpotFeed();
        if (this.deviceService.windowWidth() >= 1366) {
            this.isButtonVisible = false;
        }
        if (this.enablebingoplay) {
            this.playbingoTracking('Displayed');
        }
        this.checkPlayerSegmentData();
        this.isLegacy = !this.designSystemEnablerService.isEnabled();
    }

    assignFeatures() {
        const features: any = this.configProviderService.provideFeaturesConfig();
        this.enablebingoplay = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.enablePlayBingoButton);
        this.enableLobbyBackgroundImage = features.enableLobbyBackgroundImage;
    }

    assignConfigs() {
        const globalConfig: any = this.configProviderService.provideGlobalConfig();
        const subnavConfig: any = this.configProviderService.provideSubNavigationConfig();
        this.TeaserMode = globalConfig.messages.TeaserMode;
        this.subCategoryTeaserMode = globalConfig.messages.subCategoryTeaserMode;
        this.categoryNavTeaserMode = globalConfig.messages.categoryNavTeaserMode;
        this.enableEpcotSubNavigation = subnavConfig.enableEpcotSubNavigation;
    }

    checkPlayerSegmentData() {
        this.casinoManager.playerSegmentDataObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: any) => {
            if (data != null && data.playerValueSegment != null && data.playerValueSegment.favoriteProductLT) {
                if (data.playerValueSegment.favoriteProductLT == 'Casino') this.enablebingoplay = false;
            }
        });
    }

    assignBrowserSpecificClass() {
        if (navigator.userAgent.indexOf('Edge') != -1) {
            return 'edge';
        }
        return '';
    }

    onResize() {
        this.isButtonVisible = this.deviceService.windowWidth() < 1366;
    }

    ngOnChanges(changes: any) {
        this.assignFeatures();
        this.assignConfigs();
        this.isTouch = this.casinoManager.isTouch();
        if (this.isTouch) {
            this.swiperOptionsTeasers.navigation = false;
        } else {
            this.swiperOptionsTeasers.navigation = {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            };
        }
        this.bingocta = this.configProviderService.provideMcPageConfig().playBingoButton;
        this.isNewSportsNative =
            this.NativeApplication.isNativeApp && (this.NativeApplication.product === 'SPORTSBOOK' || this.NativeApplication.product === 'BETTING');
        if (this.configProviderService.provideCasinoConfig().teasers.takeOver && this.isDefaultCategory) {
            this.TeaserMode += ' take-over';
            document.getElementsByTagName('html')[0].classList.add('take-over-teaser');
        } else if (this.isDefaultCategory === false) {
            document.getElementsByTagName('html')[0].classList.remove('take-over-teaser');
        }
        if (changes.teasersSource) {
            this.initializeTeasers();
            this.updatedInfo();
        }
        // if(!changes?.identifierClass) {
        //     this.teasersconfigloaded = true;
        // }
        // else {
        //     this.teasersconfigloaded=false;
        // }
    }

    onTeaserIndexChanged(swiperObj: any) {
        if (this.enableLobbyBackgroundImage && (this.teaserLocation === 'Top' || this.teaserLocation === 'SubNavigation') && swiperObj) {
            this.teaserService.indexChangeTeaserData(this.teasers[swiperObj.realIndex]);
        }
    }
    ngOnDestroy() {
        if (this.isLandscape) {
            document.documentElement.classList.remove('landscape');
        }
    }

    public isInLandscape() {
        return this.deviceService.windowWidth() >= 480;
    }

    public getHeight() {
        this.elementWidth = this.deviceService.windowWidth();
        let margin = 0;
        if (this.elementWidth >= 1024) {
            margin = this.elementWidth * 0.01 * 7.2 + 8;
        }
        this.elementWidth = this.elementWidth - margin * 2;
        const ctaButtonHeight = 48;
        let termsHeight = 45;
        const titleHeight = 30;
        if (480 <= this.elementWidth && this.elementWidth < 667) {
            termsHeight = titleHeight;
        }
        const teaserCtaAndTermsHeight = ctaButtonHeight + termsHeight + titleHeight;
        if (this.isInLandscape()) {
            this.teaserHeight = (this.elementWidth / 2 / this.index.toFixed(2) + termsHeight - 1).toFixed(0);
        } else {
            this.teaserHeight = (this.elementWidth / this.index.toFixed(2) + teaserCtaAndTermsHeight - 1).toFixed(0);
        }
        return this.teaserHeight;
    }

    public extendImageData(teasers: any) {
        teasers.forEach((teaser: any) => {
            if (teaser.image && teaser.image.src) {
                if (this.index === 0 || isNaN(this.index)) {
                    this.index = teaser.image.width / teaser.image.height;
                }
            }
        });
    }

    public initializeTeasers() {
        this.teasersconfigloaded = false;
        this.teasers = this.teasersSource;
        let navTeaser = false;
        const allTeasers: any = [];
        this.teasers.forEach((t: any) => {
            if (!t.teaserMode) {
                t.teaserMode = '';
            }
            if (t.teaserMode.toUpperCase() === 'MOBILE' && this.isTouch) {
                allTeasers.push(t);
            } else if (t.teaserMode.toUpperCase() === 'DESKTOP' && !this.isTouch) {
                allTeasers.push(t);
            } else if (t.teaserMode == '' || t.teaserMode.toUpperCase() === 'BOTH') {
                allTeasers.push(t);
            }

            if (t.isEmbeddedTeaser) {
                navTeaser = false;
            } else {
                navTeaser = true;
            }
        });
        const categoryNavTeaserMode = this.configProviderService.provideGlobalConfig().messages.categoryNavTeaserMode;
        if (navTeaser && this.isDefaultCategory === false) {
            this.Modeset = categoryNavTeaserMode;
        } else if (this.isDefaultCategory) {
            this.Modeset = this.TeaserMode;
        }
        this.teasers = [];
        this.teasers = allTeasers;
        this.isLandscape = this.isInLandscape();
        if (this.teasers && this.teasers.length) {
            this.elementWidth = screen.width;
            this.extendImageData(this.teasers);
            this.refreshTeaserJackpots();
            this.getHeight();
            setTimeout(() => {
                this.swiperOptionsTeasers.loop = this.teasers?.length > 1 ? true : false;
                this.teasersconfigloaded = true;
            }, 100);
        }
    }

    public track(where: any, what: any, isUrl: any) {
        this.tracking.updateDataLayer({
            'page.name': 'mcTeasers',
            'event': 'pageView',
            'page.url': '?source=' + where + '&' + (isUrl ? 'href=' : 'gameId=') + what,
        });
    }

    public trackGameLaunch(gameLaunchInfo: any, isUrl: boolean, bannerPos: any) {
        let gameType = '',
            gameTrackingObj: any = {};
        const launchUrlIndex: number = gameLaunchInfo.indexOf(this.teaserLaunchRealtiveUri);
        if (isUrl && launchUrlIndex > 0) {
            const gameId: any = gameLaunchInfo.substr(launchUrlIndex + this.teaserLaunchRealtiveUri.length);
            const gameData: any = this.casinoLobbyService.getGameInfo(gameId);
            if (gameData && !gameData.isDataNotAvailable) {
                gameType = gameData == null ? '' : gameData.launchType;
                const gameDisplayName = gameData == null ? '' : gameData.title;
                gameTrackingObj = {
                    name: gameId,
                    type: gameType,
                    position: bannerPos,
                    section: 'Teaser',
                    displayName: gameDisplayName,
                };
            }
        } else {
            gameTrackingObj = {
                name: gameLaunchInfo,
                type: gameType,
                position: 1,
                section: '',
            };
        }
        if (Object.keys(gameTrackingObj).length > 0) {
            this.gameLaunchTrackingService.track(gameTrackingObj);
        }
    }

    public JackpotFeed() {
        this.jackpot.jackpotFeedObservable.subscribe(() => {
            this.refreshTeaserJackpots();
        });
    }

    public refreshTeaserJackpots() {
        this.teasers.forEach((teaser: any) => {
            if (teaser.jackpot) {
                const jackpotsCache = this.jackpot.getJackpotGroupNameMap();
                const jackpot = jackpotsCache.get(teaser.jackpot);
                if (jackpotsCache && jackpot) {
                    if (teaser.jackpotValue !== jackpot.amount) {
                        teaser.jackpotTickValue = parseFloat(jackpot.value);
                        teaser.jackpotValue = jackpot.amount;
                        teaser.jpCurSymbol = jackpot.amount.split(' ')[0];
                    }
                }
            }
        });
    }

    public updatedInfo() {
        this.isLandscape = this.isInLandscape();
        this.getHeight();
        try {
            this.isLandscape ? document.documentElement.classList.add('landscape') : document.documentElement.classList.remove('landscape');
        } catch (err) {
            this.logger.error('Exception in updatedInfo function of cc-teasers component: ' + err);
        }
        this.teaserLandscape = this.elementWidth >= 480;
    }

    public teaserClick(event: any, teaser: any, bannerPos: any) {
        event.stopPropagation();
        if (this.casinoIFrameOverlayService.isOverLayOnAProduct() || this.gameContainer == 'Overlay') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'Sports Gaming Overlay',
                'component.LabelEvent': 'Embeded Teaser',
                'component.ActionEvent': 'Embeded Teaser - Click',
                'component.LocationEvent': 'Gaming Overlay on Sports Lobby',
                'component.EventDetails': 'User taps on Embedded teasers configured on Gaming overlay',
                'component.URLClicked': teaser?.teaserLink?.url,
            });
        } else {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'Teaser_Banners',
                'component.LabelEvent':
                    (this.casinoLobbyService.getProductForGivenLobbyType(this.lobbyType)
                        ? this.casinoLobbyService.getProductForGivenLobbyType(this.lobbyType).toUpperCase()
                        : '') +
                    '_' +
                    unescape(teaser.teaserId),
                'component.PositionEvent': 'Teaser_' + bannerPos,
                'component.LocationEvent':
                    this.teaserLocation +
                    '_Teaser' +
                    (this.casinoLobbyService.getCategoryName(this.lobbyType, this.teaserCategory) != '' ? ' ' : '') +
                    this.casinoLobbyService.getCategoryName(this.lobbyType, this.teaserCategory),
                'component.ActionEvent': 'Teaser - Click',
                'component.EventDetails': 'User taps on teasers',
                'component.URLClicked': teaser?.teaserLink?.url,
            });
        }
        if (this.isNewSportsNative || this.vcClientService.isPokerVC()) {
            const link: any = teaser?.teaserNativeLink?.url;
            if (link) {
                this.track('tp', link, true);
                this.trackGameLaunch(link, true, bannerPos);
                const options = { skipLoadingIndicator: false };
                if (link.substring(link.length - 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                const infiIndex = link.indexOf('?event=promo');
                if (infiIndex === -1) {
                    if (this.vcClientService.isPokerVC() && this.isGameUrl(link)) {
                        this.launchVCGame(link, bannerPos);
                        return;
                    }
                    this.handleNavigation(link, teaser.teaserNativeLink.attributes, !options.skipLoadingIndicator);
                } else {
                    const event: NativeEvent = {
                        eventName: 'Native_MD5_Teaser_Click',
                        parameters: {
                            teaserNativeLinkUrl: link.substring(infiIndex),
                        },
                    };
                    this.NativeApplication.sendToNative(event);
                }
            }
        } else {
            if (teaser?.teaserLink?.url) {
                this.track('tp', teaser?.teaserLink?.url, true);
                this.handleTeaserClick(teaser?.teaserLink, bannerPos);
            }
        }
    }

    public ctaClick(event: any, teaser: any, bannerPos: any) {
        event.stopPropagation();
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Teaser_Banners',
            'component.LabelEvent':
                (this.casinoLobbyService.getProductForGivenLobbyType(this.lobbyType)
                    ? this.casinoLobbyService.getProductForGivenLobbyType(this.lobbyType).toUpperCase()
                    : '') +
                '_' +
                unescape(teaser.teaserId),
            'component.PositionEvent': 'Teaser_' + bannerPos,
            'component.LocationEvent':
                this.teaserLocation +
                '_Teaser' +
                (this.casinoLobbyService.getCategoryName(this.lobbyType, this.teaserCategory) != '' ? '_' : '') +
                this.casinoLobbyService.getCategoryName(this.lobbyType, this.teaserCategory),
            'component.ActionEvent': 'CTA Click',
            'component.EventDetails': 'CTA Name|' + teaser?.ctaTitle,
            'component.URLClicked': teaser?.ctaLink?.url,
        });
        if (this.isNewSportsNative || this.vcClientService.isPokerVC()) {
            const link: any = teaser?.ctaNativeLink?.url;
            if (link) {
                this.track('tp', link, true);
                this.trackGameLaunch(link, true, bannerPos);
                const options = { skipLoadingIndicator: false };
                if (link.substring(link.length - 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                const infiIndex = link.indexOf('?event=promo');
                if (infiIndex === -1) {
                    if (this.vcClientService.isPokerVC() && this.isGameUrl(link)) {
                        this.launchVCGame(link, bannerPos);
                        return;
                    }
                    this.handleNavigation(link, teaser.ctaNativeLink.attributes, !options.skipLoadingIndicator);
                } else {
                    const event: NativeEvent = {
                        eventName: 'Native_MD5_Teaser_Cta_Click',
                        parameters: {
                            teaserCtaNativeLinkUrl: link.substring(infiIndex),
                        },
                    };
                    this.NativeApplication.sendToNative(event);
                }
            }
        } else if (teaser.ctaLink) {
            this.handleTeaserClick(teaser?.ctaLink, bannerPos);
        }
    }

    public termsClick(event: any, teaser: any) {
        event.stopPropagation();
        if (this.isNewSportsNative) {
            const link: any = teaser?.termsNativeLink?.url;
            if (link) {
                this.track('tp', link, true);
                const options = { skipLoadingIndicator: false };
                if (link.substring(link.length - 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                const infiIndex = link.indexOf('?event=promo');
                if (infiIndex === -1) {
                    this.handleNavigation(link, teaser.termsNativeLink.attributes, !options.skipLoadingIndicator);
                } else {
                    const event: NativeEvent = {
                        eventName: 'Native_MD5_Teaser_Terms_Click',
                        parameters: {
                            teaserTermsNativeLinkUrl: link.substring(infiIndex),
                        },
                    };
                    this.NativeApplication.sendToNative(event);
                }
            }
        } else if (teaser?.termsLink) {
            this.track('tp', teaser.termsLink.url, true);
            const options = { skipLoadingIndicator: false };
            if (teaser?.termsLink?.url.substr(teaser.termsLink.url.length - 4, 4).toLowerCase() === '.apk') {
                options.skipLoadingIndicator = true;
            }
            if (this.casinoWidgetService.isCasinoWidget() || this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                this.casinoWidgetService.postMessageToParent('UserAction', {
                    redirectUrl: teaser.termsLink.url,
                    redirectOptions: { showSpinnerForExternalNavigation: !options.skipLoadingIndicator },
                });
                return;
            } else {
                this.handleNavigation(teaser?.termsLink?.url, teaser.termsLink.attributes, !options.skipLoadingIndicator);
            }
        }
    }

    @HostListener('window:resize', []) resize() {
        if (this.windowRef.nativeWindow.document.activeElement?.nodeType == Node.TEXT_NODE && !this.isLandscape) {
            return;
        }
        this.updatedInfo();
    }

    launchVCGame(link: string, bannerPos?: any) {
        if (this.isGameUrl(link)) {
            const gameId = this.getGameIdFromTeaserHref(link);
            const gameLaunchModel = new GameLaunch();
            const game = { gameId: gameId };
            gameLaunchModel.game = game;
            gameLaunchModel.isTouch = this.isTouch;
            gameLaunchModel.gamePosition = Number(bannerPos);
            gameLaunchModel.categoryType = 'Teaser';
            gameLaunchModel.from = 'Teaser';
            this.vcClientService.handleVCGameLaunch(gameLaunchModel, false);
        }
    }

    launchNativeGame(link: string, bannerPos?: any) {
        if (this.isGameUrl(link)) {
            const gameId = this.getGameIdFromTeaserHref(link);
            const params = { gameId: gameId, skipUnfinGame: false };
            const gameLaunchModel = new GameLaunch();
            const game = { gameId: gameId };
            gameLaunchModel.game = game;
            gameLaunchModel.isTouch = this.isTouch;
            gameLaunchModel.gamePosition = Number(bannerPos);
            gameLaunchModel.categoryType = 'Teaser';
            gameLaunchModel.from = 'Teaser';
            this.nativeAppHelper.handleNativeGameLaunch(params, gameLaunchModel);
        }
    }

    getGameIdFromTeaserHref(link: string): string {
        return this.isGameUrl(link) ? link.substring(link.indexOf(this.teaserLaunchRealtiveUri) + this.teaserLaunchRealtiveUri.length) : '';
    }

    isGameUrl(link: string) {
        return link.indexOf(this.teaserLaunchRealtiveUri) > 0;
    }

    playbingoTracking(buttonActionEvent: string) {
        const actionEvent = buttonActionEvent;
        let positionEvent: string;
        if (this.deviceService.windowWidth() < 480) {
            positionEvent = 'Below the Banner';
        } else {
            positionEvent = 'on the  Banner';
        }
        this.tracking.triggerEvent('Event.Clicks', {
            ' component.CategoryEvent': 'Play Bingo Button',
            'component.LabelEvent': 'Play Bingo Now',
            'component.ActionEvent': 'Play Bingo Button - ' + actionEvent,
            'component.PositionEvent': positionEvent,
            'component.LocationEvent': 'Casino Lobby',
            'component.EventDetails': 'CTA to Launch Bingo',
            'component.URLClicked': this.bingocta.url,
        });
    }

    playbingoclick(playlink: any) {
        this.playbingoTracking('Clicked');
        if (!playlink.htmlAttributes.gameId) {
            this.navigation.goTo(playlink?.url);
        } else {
            const gameObj: any = {
                section: 'Teaser',
                gameContainerLocation: 'Teaser|Top Teaser',
                position: 0,
                isBingoVf: playlink.htmlAttributes.gameType == 'Bingovf' || false,
            };
            this.bingoGameLaunchService.initiateGameLaunch(
                playlink.htmlAttributes.gameName,
                playlink.htmlAttributes.gameType,
                playlink.htmlAttributes.ballType,
                playlink.htmlAttributes.launchType,
                playlink.htmlAttributes.gameIcon,
                playlink.htmlAttributes.gameId,
                playlink.htmlAttributes.favstatus,
                playlink?.link?.url,
                gameObj,
            );
        }
    }

    handleTeaserClick(link: any, bannerPos: any) {
        const href = link.url;
        const options: any = { skipLoadingIndicator: false };
        const gameId: string = this.getGameIdFromTeaserHref(href);
        if (href.substr(href.length - 4, 4).toLowerCase() === '.apk') {
            options.skipLoadingIndicator = true;
        }
        if (this.configProviderService.provideGameConfig().nativeGameLaunchEnabled && gameId) {
            this.launchNativeGame(href, bannerPos);
            return;
        }
        if (gameId) {
            let gameHref: string = cloneDeep(href);
            if (this.configProviderService.provideAdvanceGameDataConfig().enableAdvanceGameData) {
                gameHref = this.gameLaunchService.appendQueryString(href, 'adv_parentcat', 'Teaser');
                gameHref = this.gameLaunchService.appendQueryString(gameHref, 'adv_pos', String(bannerPos - 1));
            }
            if (this.casinoWidgetService.isCasinoWidget() || this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                if (this.isTouch) {
                    if (this.casinoWidgetService.isCasinoWidget() && gameId) {
                        const postData: any = {
                            promptLogin: !this.user.isAuthenticated,
                            redirectUrl: gameHref,
                            redirectOptions: this.user.isAuthenticated ? { showSpinnerForExternalNavigation: !options.skipLoadingIndicator } : null,
                        };
                        this.casinoWidgetService.postMessageToParent('UserAction', postData);
                        return;
                    } else if (this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                        let redirectPlayUrl: string = gameHref;
                        redirectPlayUrl = this.gameLaunchService.appendQueryString(
                            redirectPlayUrl,
                            'invokerProduct',
                            this.casinoWidgetService.getWidgetProduct(),
                        );
                        if (this.user.isAuthenticated) {
                            redirectPlayUrl = this.gameLaunchService.appendQueryString(redirectPlayUrl, 'returnurl', document.referrer);
                            redirectPlayUrl = this.gameLaunchService.appendQueryString(redirectPlayUrl, 'widgetGameLaunch', 'true');
                        }
                        const postData: any = {
                            promptLogin: !this.user.isAuthenticated,
                            redirectUrl: redirectPlayUrl,
                        };
                        this.casinoWidgetService.postMessageToParent('UserAction', postData);
                        return;
                    }
                } else {
                    if (this.casinoWidgetService.isCasinoWidget()) {
                        gameHref = this.gameLaunchService.appendQueryString(gameHref, 'widgetHomeUrl', document.referrer);
                        this.casinoWidgetService.postMessageToParent('UserAction', {
                            promptLogin: !this.user.isAuthenticated,
                            redirectUrl: gameHref,
                        });
                        return;
                    }
                }
            } else {
                let gameInfo: any = this.casinoLobbyService.getGameInfo(gameId);
                if (!gameInfo) {
                    gameInfo = {};
                }
                gameInfo.gameId = gameId;
                const gameModel = new GameLaunch();
                gameModel.game = gameInfo;
                gameModel.isTouch = this.isTouch;
                gameModel.gamePosition = bannerPos - 1;
                gameModel.categoryType = this.teaserCategory;
                gameModel.from = 'q';
                gameModel.replaceRouteInHistory = false;
                gameModel.isDemoLaunch = false;
                gameModel.skipUnfinGame = false;
                gameModel.gameLaunchedFrom = this.gameLaunchPlaces.teaser;
                gameModel.gameContainer = this.gameContainer;
                gameModel.game.lobbyType = this.lobbyType;
                gameModel.returnUrl = window.location.href;
                gameModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
                this.gameLaunchService.launchGame(gameModel);
            }
        } else {
            if (this.casinoWidgetService.isCasinoWidget() || this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                this.casinoWidgetService.postMessageToParent('UserAction', {
                    redirectUrl: href,
                    redirectOptions: { showSpinnerForExternalNavigation: !options.skipLoadingIndicator },
                });
                return;
            } else {
                this.handleNavigation(href, link.attributes, !options.skipLoadingIndicator);
            }
        }
    }

    private handleNavigation(url: string, attributes: any, showSpinnerForExternalNavigation: boolean) {
        if (attributes.hasOwnProperty('target')) {
            window.open(url, attributes.target);
        } else if (url) {
            this.navigation.goTo(url, { showSpinnerForExternalNavigation: showSpinnerForExternalNavigation });
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.swiperRef) {
                this.swiperRef.swiper.on('slideChange', (event: any) => {
                    this.onTeaserIndexChanged(event);
                });
            }
            const features: any = this.configProviderService.provideFeaturesConfig();
            setTimeout(() => {
                if (this.swiperRef?.swiper) {
                    this.swiperRef.swiper.update();
                    if (this.enableLobbyBackgroundImage && (this.teaserLocation === 'Top' || this.teaserLocation === 'SubNavigation')) {
                        this.teaserService.indexChangeTeaserData(this.teasers[0]);
                    }
                }
            }, features.teaserSetIndexTimeout);
        }, 200);
    }
}
