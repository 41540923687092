<div *ngIf="showDesktopSearch">
    <div class="desktop-search{{ idSuffix }}" *ngIf="innerWidth() > 768 && !isTouch">
        <div class="casino-search-input-div">
            <span
                class="theme-search"
                [ngClass]="{ 'gyml-search-icon': showGymlSearch && idSuffix }"
                (click)="showGlobalSearchInRegularSearch()"></span>
            <span class="close-button theme-close-i" *ngIf="SearchCloseIcon" (click)="closeSearchIcon()"></span>
            <input
                id="search-text"
                class="casino-search-input"
                #searchInput
                type="text"
                (change)="queryChanged($event)"
                [ngClass]="{ 'casino-gyml-search': showGymlSearch && idSuffix }"
                [(ngModel)]="model.query"
                (keyup)="queryChanged($event)"
                (focusout)="trackInput()"
                (focusin)="showGlobalSearchInRegularSearch()"
                placeholder="{{ SearchProduct.value }}..."
                (focus)="searchInput.placeholder = ''"
                (blur)="searchInput.placeholder = SearchProduct.value + '...'"
                autocomplete="off" />
            <cc-global-translate [key]="'SearchProduct'" #SearchProduct [hidden]="true"></cc-global-translate>
        </div>

        <div class="casino-desktop-gyml-results" [hidden]="hideDisplayGYML()">
            <span class="gym-heading" id="gym-desktop-heading-id" (click)="clickGymlHeading($event)">
                {{ gymlText }}
            </span>
            <ul class="gyml-list">
                <li *ngFor="let game of GYMLGames; let gamePos = index" (click)="launchGame(game, true, gamePos)">
                    <img src="{{ game.icon.src }}" (error)="onGameIconError(game)" />
                    <span class="search-result-title" [innerHTML]="game.name"></span>
                </li>
            </ul>
        </div>

        <div class="casino-desktop-search-results" *ngIf="model.query">
            <ul class="search-results-list" [ngClass]="{ 'search-non-touch': !isTouch, 'emptyResult': isMsgVisible }">
                <li *ngFor="let game of model.results | dsl | async; let gamePos = index" (click)="launchGame(game, false, gamePos)">
                    <img src="{{ game.icon.src }}" (error)="onGameIconError(game)" />
                    <span class="search-result-title" [innerHTML]="game.title | highlightsearch: model.query"></span>
                </li>
            </ul>

            <p [hidden]="!isMsgVisible" class="no-result">
                <cc-global-translate [key]="'SearchNoResults'"></cc-global-translate>
            </p>
        </div>
    </div>
</div>
