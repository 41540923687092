<span [ngSwitch]="categoryId">
    <ng-container *ngIf="isSlotRacesPhase2Enabled">
        <cc-casino-core-lazy-placeholder
            [inputData]="{ component: 'page', lobbyType: lobbyType, category: category }"
            [componentName]="'SlotRacesPhase2Component'"
            *ngSwitchCase="slotRacesCategoryId">
        </cc-casino-core-lazy-placeholder>
    </ng-container>
    <ng-container *ngIf="!isSlotRacesPhase2Enabled">
        <cc-casino-core-lazy-placeholder
            [inputData]="{ component: 'page', lobbyType: lobbyType, category: category }"
            [componentName]="'SlotRacesComponent'"
            *ngSwitchCase="slotRacesCategoryId">
        </cc-casino-core-lazy-placeholder>
    </ng-container>
    <cc-casino-core-lazy-placeholder
        [inputData]="{ categoryId: categoryId, lobbyType: lobbyType }"
        [componentName]="'DiscoverFeedComponent'"
        *ngSwitchCase="discoverFeedLmtkey"></cc-casino-core-lazy-placeholder>
    <cc-casino-core-lazy-placeholder
        [inputData]="{ liveGamesCategory: liveGamesCategory, liveGameInfo: liveGameInfo, lobbyType: lobbyType }"
        *ngSwitchCase="liveGameIframeCategory"
        [componentName]="'LiveGamesIframeComponent'">
    </cc-casino-core-lazy-placeholder>
    <cc-lmt-category-lobby
        [categoryId]="categoryId"
        [category]="category"
        [isTouch]="isTouch"
        [lobbyType]="lobbyType"
        [gameContainer]="gameContainer"
        *ngSwitchDefault>
    </cc-lmt-category-lobby>
</span>
