<div class="casino-search">
    <div class="casino-search-content">
        <div class="search-query">
            <input
                #searchInput
                id="serachTxt"
                name="searchText"
                type="text"
                autofocus
                (change)="queryChanged()"
                [(ngModel)]="model.query"
                (keyup)="queryChanged()"
                (focusout)="trackInput()"
                (keydown)="onEvent($event)"
                placeholder="{{ SearchProductForMobile }}"
                autocomplete="off" />
            <a (click)="hideSearch()" *ngIf="isCancelVisible" class="casino-search-cancel">
                <span class="theme-ex"></span>
            </a>
            <span class="close-button theme-close-i" *ngIf="SearchCloseIcon" (click)="closeSearchIcon()"></span>
        </div>
    </div>

    <div class="casino-games-you-like casino-gyml-tablet" id="gyml-id" [ngClass]="{ 'casino-gyml-touch': isTouch }" *ngIf="hideDisplayGYML()">
        <span class="gym-heading" id="gyml-heading-id">
            {{ gymlText }}
        </span>
        <div class="lobby-games-gyml" *ngIf="gymlCategory && gymlCategory.categoryInfo && gymlCategory.categoryInfo.categoryid === 'LMC_GYML'">
            <div *ngFor="let game of GYMLGames; let i = index" class="game">
                <cc-game-lobby [game]="game" [gamePosition]="i" [category]="gymlCategory" [gameLaunchedFrom]="'Search_GYML'"></cc-game-lobby>
            </div>
        </div>
    </div>

    <div class="casino-search-results">
        <ul class="search-results-list" [hidden]="!(model.results.length > 0)">
            <li *ngFor="let game of model.results | dsl | async; let i = index" (click)="launchGame(game, i)">
                <img src="{{ game.icon.src }}" />
                <span class="search-result-title" [innerHTML]="game.title | highlightsearch: model.query"></span>
            </li>
        </ul>

        <p [hidden]="!isMsgVisible">
            <cc-global-translate [key]="'SearchNoResults'"></cc-global-translate>
        </p>
    </div>
    <div class="overlay"></div>
</div>
