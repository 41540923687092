import { Injectable } from '@angular/core';

import { AppInfoConfig, DeviceService, NativeAppService, Page, TrackingService, UserService, WindowRef } from '@frontend/vanilla/core';

import { ConfigProviderService } from '../config-provider/config-provider.service';

enum Type {
    GA3 = 'GA3',
    GA4 = 'GA4',
    MP = 'MP',
    ALL = 'ALL',
}

enum Group {
    EU = 'EU',
    LCG = 'LCG',
    US = 'US',
}

const NA = 'not applicable';

@Injectable({
    providedIn: 'root',
})
export class GameLaunchTrackingService {
    private config: any;
    constructor(
        private configProviderService: ConfigProviderService,
        private user: UserService,
        private page: Page,
        private appInfoConfig: AppInfoConfig,
        private nativeAppService: NativeAppService,
        private tracking: TrackingService,
        private windowRef: WindowRef,
        private deviceService: DeviceService,
    ) {}
    //Alex and Oliver from Analytics team told, that dataLayer and API approaches should work together. So removing the toggle
    track(gameTrackingObj: any) {
        this.config = this.configProviderService.provideGameLaunchTrackingConfig();
        this.trackDataLayer(gameTrackingObj);
        //Added this if according to POR-41059
        let onetrustKey: any = window[this.config?.onetrustTracking.key];
        if (this.config?.onetrustTracking?.enabled === 'True' && onetrustKey?.split(',')?.indexOf(this.config?.onetrustTracking?.value) === -1) {
            return;
        } //POR-41059
        let url: string, payload: any;
        for (let type in this.config?.endpoint) {
            url = this.config?.endpoint[type];
            payload = this.getPayload(gameTrackingObj, type?.toUpperCase());
            if (url && payload) this.trackAPI(url, payload.params, payload.body);
        }
    }

    /* This block will be restored when above block is removed
    track(gameTrackingObj: any) {
        this.config = this.configProviderService.provideGameLaunchTrackingConfig();
        if (this.config.isEnabled) {
            let url: string, payload: any;
            for (let type in this.config?.endpoint) {
                url = this.config?.endpoint[type];
                payload = this.getPayload(gameTrackingObj, type?.toUpperCase());
                if (url && payload)
                    this.trackAPI(url, payload.params, payload.body);
            }
        } else {
            this.trackDataLayer(gameTrackingObj);
        }
    }
    */

    private trackDataLayer(gameTrackingObj: any) {
        this.tracking.triggerEvent('gameOpen', {
            'component.gameName': gameTrackingObj.name,
            'component.gameType': gameTrackingObj.type,
            'component.Game.position': gameTrackingObj.position,
            'component.Game.section': gameTrackingObj.section,
            'component.Game.GridPosition': gameTrackingObj.gridPosition,
            'component.gameContainerLocation': gameTrackingObj.location,
            'component.gameStatus': gameTrackingObj.status,
            'component.gameContainerDescription': gameTrackingObj.description,
            'component.gameFavourite': gameTrackingObj.favourite,
            'component.gamePlayingStatus': gameTrackingObj.playingStatus,
            'component.gameDisplayName': gameTrackingObj.displayName,
            'component.categoryType': gameTrackingObj.categoryType,
            'component.categorySubType': gameTrackingObj.categorySubType,
            'component.recommendationtype': gameTrackingObj.recommendationType,
            'casino.lobbyName': gameTrackingObj.lobbyName,
            'casino.lobbySource': gameTrackingObj.lobbySource,
            'casino.optimoveSegment': gameTrackingObj.optimoveSegment,
        });
    }

    private getTimeStamp(gameTrackingObj: any) {
        return gameTrackingObj?.params?.clickTimeStamp || new Date().getTime();
    }

    private getPageName() {
        return window.location?.pathname?.replace('/' + this.page.lang + '/', '')?.toLowerCase();
    }

    private getCookieValue(name: string) {
        return document.cookie
            ?.split(';')
            .find((x) => x.includes(name))
            ?.split('=')[1];
    }

    private getSessionValue(sessionId: string) {
        let item: any = document.cookie?.split(';').find((x) => x.includes(sessionId));
        let sessionValue: any = item?.split('.')[2];
        return sessionValue?.match(/^\d{10}$/) ? sessionValue : '';
    }

    private getDistinctId(id: string) {
        if (this.user.isAuthenticated) return this.user.accountId;
        const key: string = 'mp_' + this.config?.mp.token + '_mixpanel';
        let item: any = JSON.parse(localStorage.getItem(key) || 'null');
        return item ? item[id] : null;
    }

    //Get Frontend values based on Vanilla Logic
    private getFrontEndValue() {
        if (this.nativeAppService.isNative) {
            return this.nativeAppService.applicationName;
        }
        if (this.deviceService.isMobile) {
            return 'mobile';
        }
        return 'desktop';
    }

    private getClientId(id: string) {
        const cid: any = this.windowRef.nativeWindow['ga']?.getAll && this.windowRef.nativeWindow['ga']?.getAll()[0].get(id);
        if (cid) return cid;
        let cookie: string[] | undefined = document.cookie
            .split(';')
            .find((x) => x.includes('_ga='))
            ?.split('.');
        return cookie && cookie.length == 4 ? cookie[2] + '.' + cookie[3] : null;
    }

    private getPayload(gameTrackingObj: any, type: string) {
        let payload: any;
        switch (type) {
            case Type.GA3:
                payload = this.getGA3Payload(gameTrackingObj);
                break;
            case Type.GA4:
                payload = this.getGA4Payload(gameTrackingObj);
                break;
            case Type.MP:
                payload = this.getMPPayload(gameTrackingObj);
                break;
            default:
                payload = null;
                break;
        }
        return payload;
    }
    private getGA3Payload(gameTrackingObj: any) {
        let specificParams: any,
            commonParams: any = {
                v: 1,
                t: 'event',
                tid: this.config?.gA3.measurementId,
                cid: this.getClientId('clientId'),
                ds: this.nativeAppService.isNative ? 'native' : 'web',
                z: Math.floor(Math.random() * 1000000 + 1),
                ua: navigator.userAgent?.toLowerCase(),
                de: 'UTF-8',
                je: 0,
                dt: document.title,
                dl: document.URL,
                dr: document.referrer,
                dh: document.location.host,
                ul: this.page.culture?.toLowerCase(),
                geoid: this.user.geoCountry?.toLowerCase(),
                ec: 'game_launch_api',
                el: this.getPageName(),
                ea: gameTrackingObj.displayName?.toLowerCase(),
                ni: 0,
                sid: this.getSessionValue(this.config?.gA4.sessionId),
            };
        if (this.config?.gA3.group === Group.LCG) {
            specificParams = {
                cd1: this.user.accountId,
                cd5: this.user.currency?.toLowerCase(),
                cd6: this.appInfoConfig.brand?.toLowerCase(),
                cd8: commonParams.ds,
                cd11: gameTrackingObj.position,
                cd12: gameTrackingObj.section?.toLowerCase(),
                cd13: gameTrackingObj.type?.toLowerCase(),
                cd84: commonParams.dr,
                cd126: gameTrackingObj.gridPosition,
                cd127: gameTrackingObj.status?.toLowerCase(),
                cd128: gameTrackingObj.location?.toLowerCase(),
                cd129: gameTrackingObj.description?.toLowerCase(),
                cd130: gameTrackingObj.favourite?.toLowerCase(),
                cd131: gameTrackingObj.playingStatus?.toLowerCase(),
                cd132: commonParams.ea,
                cd133: gameTrackingObj.categoryType?.toLowerCase(),
                cd134: gameTrackingObj.categorySubType?.toLowerCase(),
                cd135: commonParams.dl,
                cd164: gameTrackingObj.name?.toLowerCase(),
                cd154: this.appInfoConfig.product?.toLowerCase(),
                cd155: gameTrackingObj.recommendationType?.toLowerCase(),
                cd170: gameTrackingObj.lobbyName?.toLowerCase(),
                cd171: gameTrackingObj.lobbySource?.toLowerCase(),
                cd172: gameTrackingObj.optimoveSegment?.toLowerCase(),
            };
        } else {
            specificParams = {
                cd1: this.user.accountId,
                cd3: this.user.country?.toLowerCase(),
                cd6: this.user.isAuthenticated ? 1 : 0,
                cd11: this.nativeAppService.isNative,
                cd12: this.appInfoConfig.frontend,
                cd15: commonParams.el,
                cd33: commonParams.ea,
                cd35: gameTrackingObj.position,
                cd36: gameTrackingObj.section?.toLowerCase(),
                cd38: gameTrackingObj.gridPosition,
                cd39: gameTrackingObj.type?.toLowerCase(),
                cd40: gameTrackingObj.favourite?.toLowerCase(),
                cd41: gameTrackingObj.name?.toLowerCase(),
                cd42: gameTrackingObj.status?.toLowerCase(),
                cd43: gameTrackingObj.location?.toLowerCase(),
                cd44: gameTrackingObj.description?.toLowerCase(),
                cd96: gameTrackingObj.categoryType?.toLowerCase(),
                cd97: gameTrackingObj.categorySubType?.toLowerCase(),
                cd100: commonParams.dl,
                cd134: this.user.accountId,
                cd157: gameTrackingObj.recommendationType?.toLowerCase(),
                cd174: commonParams.ua,
                cd194: gameTrackingObj.lobbyName?.toLowerCase(),
                cd195: gameTrackingObj.lobbySource?.toLowerCase(),
                cd196: gameTrackingObj.optimoveSegment?.toLowerCase(),
                cd119: document.referrer,
            };
        }
        let params: any = Object.assign({}, commonParams, specificParams);
        let body;
        return { params, body };
    }

    private getGA4Payload(gameTrackingObj: any) {
        let params: any = {
            measurement_id: this.config?.gA4.measurementId,
            api_secret: this.config?.gA4.apiSecretKey,
        };
        let data: any = {
            client_id: this.getClientId('clientId'),
            user_id: this.user.accountId,
            timestamp_micros: this.getTimeStamp(gameTrackingObj) * 1000,
            user_properties: {
                appinfo: {
                    value: this.appInfoConfig.channel?.toLowerCase(),
                },
                device_native: {
                    value: this.nativeAppService.isNative,
                },
                prof_country: {
                    value: this.user.country?.toLowerCase(),
                },
                prof_customerid: {
                    value: this.user.accountId,
                },
                prof_loggedin: {
                    value: this.user.isAuthenticated ? 'logged_in' : 'not_logged_in',
                },
                page_fe: {
                    value: this.getFrontEndValue(),
                },
            },
            events: [
                {
                    name: 'game_launch_api',
                    params: {
                        z: Math.floor(Math.random() * 1000000 + 1),
                        page_title: document.title,
                        page_location: document.URL,
                        page_referrer: document.referrer,
                        page_hostname: document.location.host,
                        ul: this.page.culture?.toLowerCase(),
                        session_id: this.getSessionValue(this.config?.gA4.sessionId),
                        seg: 1,
                        category_event: 'game_launch_api',
                        label_event: this.getPageName()?.toLowerCase(),
                        action_event: gameTrackingObj.displayName?.toLowerCase(),
                        location_event: NA,
                        event_details: NA,
                        prof_currency: this.user.currency?.toLowerCase(),
                        clean_page_URL: document.URL,
                        game_name: gameTrackingObj.displayName?.toLowerCase(),
                        game_position: gameTrackingObj.position,
                        game_category: gameTrackingObj.section?.toLowerCase(),
                        game_grid: gameTrackingObj.gridPosition,
                        game_type: gameTrackingObj.type?.toLowerCase(),
                        game_fav: gameTrackingObj.favourite?.toLowerCase(),
                        game_id: gameTrackingObj.name?.toLowerCase(),
                        game_status: gameTrackingObj.status?.toLowerCase(),
                        game_containerloc: gameTrackingObj.location?.toLowerCase(),
                        game_containerdesc: gameTrackingObj.description?.toLowerCase(),
                        position_event: NA,
                        test_abtest: this.getCookieValue('gvc_expt'),
                        category_type: gameTrackingObj.categoryType?.toLowerCase(),
                        category_subtype: gameTrackingObj.categorySubType?.toLowerCase(),
                        game_recommendation_type: gameTrackingObj.recommendationType?.toLowerCase(),
                        lobby_name: gameTrackingObj.lobbyName?.toLowerCase(),
                        lobby_source: gameTrackingObj.lobbySource?.toLowerCase(),
                        optimove_segment: gameTrackingObj.optimoveSegment?.toLowerCase() || NA,
                        custom_timestamp: this.getTimeStamp(gameTrackingObj) * 1000,
                        user_agent: navigator.userAgent?.toLowerCase(),
                        page_name: this.getPageName(),
                    },
                },
            ],
        };
        let body = JSON.stringify(data);
        return { params, body };
    }

    private getMPPayload(gameTrackingObj: any) {
        let params: any = {
            ip: 1,
            verbose: 1,
            _: this.getTimeStamp(gameTrackingObj),
        };
        let data: any = [
            {
                event: 'game_open_api',
                properties: {
                    token: this.config?.mp.token,
                    distinct_id: this.getDistinctId('distinct_id'),
                    time: this.getTimeStamp(gameTrackingObj),
                    app: this.appInfoConfig,
                    game: {
                        GridPosition: gameTrackingObj.gridPosition,
                        Position: gameTrackingObj.position,
                        Section: gameTrackingObj.section?.toLowerCase(),
                        CategoryType: gameTrackingObj.categoryType?.toLowerCase(),
                        CategorySubType: gameTrackingObj.categorySubType?.toLowerCase(),
                        ContainerDescription: gameTrackingObj.description?.toLowerCase(),
                        ContainerLocation: gameTrackingObj.location,
                        DisplayName: gameTrackingObj.displayName?.toLowerCase(),
                        Favourite: gameTrackingObj.favourite?.toLowerCase(),
                        Name: gameTrackingObj.name?.toLowerCase(),
                        PlayingStatus: gameTrackingObj.playingStatus?.toLowerCase(),
                        Status: gameTrackingObj.status?.toLowerCase(),
                        Type: gameTrackingObj.type?.toLowerCase(),
                        RecommendationType: gameTrackingObj.recommendationType?.toLowerCase(),
                        LobbyName: gameTrackingObj.lobbyName?.toLowerCase(),
                        LobbySource: gameTrackingObj.lobbySource?.toLowerCase(),
                        OptimoveSegment: gameTrackingObj.optimoveSegment?.toLowerCase() || NA,
                    },
                    page: {
                        Brand: this.appInfoConfig.brand,
                        Host: document.location.host,
                        Label: this.page.domain.replace('.', ''),
                        Name: this.getPageName(),
                        Referrer: document.referrer,
                        Path: document.location.href.replace(document.location.origin, ''),
                    },
                },
            },
        ];
        let body = new URLSearchParams({ data: JSON.stringify(data) });
        return { params, body };
    }

    private async trackAPI(url: string, params: any, body: any) {
        let xhr = new XMLHttpRequest();
        xhr.open('POST', url + '?' + new URLSearchParams(params));
        xhr.send(body);
    }
}
