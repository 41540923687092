<div class="casino-game-premiere-wrapper">
    @if (!isMobilePhone) {
        <div class="desktop-game-premiere-overlay">
            <div class="overlay-header-data">
                @if (headerText) {
                    <div class="header-title">{{ headerText }}</div>
                }
                @if (sticker) {
                    <div class="header-tag-sticker">
                        <div class="sticker-badge">{{ sticker }}</div>
                    </div>
                }
            </div>
            @if (closeCTA) {
                <div class="close-button">
                    <button class="btn btn-light btn-md" (click)="closeOverlay(true)">{{ closeCTA }}</button>
                </div>
            }
        </div>
    }

    <div [ngClass]="{ 'show-content': showContent }" class="overlay-container">
        <img [src]="overlayAnimationImage.src" class="background-gif" alt="Animated GIF" />
        <div class="overlay-content">
            @if (!isMobilePhone) {
                <div class="desktop-image-container" [ngStyle]="{ background: desktopBackgroundImage }">
                    <div *ngIf="showPosterImage">
                        <div class="game-premiere-sticker">{{ sticker }}</div>
                        <div class="text-content">
                            <div class="bonus-text">
                                <p class="sub-title-text1">{{ subTitle1 }}</p>
                                <h2 class="title-text">{{ mainTitle }}</h2>
                                <p class="sub-title-text2">{{ subTitle2 }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="theme-playbutton" (click)="playDesktopVideo()"></div>
                    <video
                        *ngIf="!showPosterImage"
                        #videoPlayer
                        id="videoPlayer"
                        width="600"
                        controls
                        (pause)="pauseDesktopVideo()"
                        (ended)="resetVideo()">
                        <source [src]="promoVideo" type="video/mp4" />
                    </video>
                </div>
            }
            @if (isMobilePhone) {
                <div class="mobile-game-premiere-overlay">
                    <div class="closebtn">
                        <span class="close theme-ex" (click)="closeOverlay(true)"></span>
                    </div>
                    <div class="mobile-text-content">
                        <div *ngIf="mobileSubTitle1" class="mobile-title-text1">{{ mobileSubTitle1 }}</div>
                        <h3 *ngIf="mobileSubTitle2" class="mobile-title-text2">{{ mobileSubTitle2 }}</h3>
                        @if (enableVideo && isTouch) {
                            <div class="image-container">
                                <div class="stripes">
                                    <div class="theme-playbutton" *ngIf="showPosterImage" (click)="playDesktopVideo()"></div>
                                    <video
                                        *ngIf="!showPosterImage"
                                        #videoPlayer
                                        id="videoPlayer"
                                        controls
                                        (pause)="pauseDesktopVideo()"
                                        (ended)="resetVideo()">
                                        <source [src]="promoVideo" type="video/mp4" />
                                    </video>
                                    <img [src]="mobileGameImage" alt="Poster Image" class="play-button" [hidden]="!showPosterImage" />
                                    <div class="game-premiere-sticker" *ngIf="showPosterImage">
                                        {{ sticker }}
                                    </div>
                                </div>
                            </div>
                        }
                        @if (mainTitle) {
                            <h3 *ngIf="mainTitle" class="title-text">{{ mainTitle }}</h3>
                        }
                    </div>
                </div>
            }
            @if (stepsTitle) {
                <div class="steps">
                    <h3 class="steps-title">{{ stepsTitle }}</h3>
                    <ul class="circle-ordered-list">
                        @if (step1) {
                            <li>{{ step1 }}</li>
                        }
                        @if (step2) {
                            <li>{{ step2 }}</li>
                        }
                        @if (step3) {
                            <div class="theme-present">
                                <span class="gift-step-info">{{ step3 }}</span>
                            </div>
                        }
                    </ul>
                    <div class="add-info">{{ additionalInfo }}</div>
                </div>
            }
            @if (!enableStatic) {
                <div class="cta-buttons">
                    <div class="optin-Cta">
                        <button
                            (click)="optinClick($event)"
                            class="btn"
                            [ngClass]="{ 'btn-primary': !optInDisabled, 'btn-dark': optInDisabled }"
                            [attr.disabled]="optInDisabled ? true : null">
                            <span *ngIf="optInDisabled" class="theme-success-i"></span>
                            {{ optInText }}
                        </button>
                    </div>
                    <div class="play-now-Cta">
                        <button
                            (click)="handlePlaynowClick($event)"
                            class="btn"
                            [ngClass]="{ 'btn-dark': !optInDisabled, 'btn-primary': optInDisabled }">
                            {{ playNowText }}
                        </button>
                    </div>
                </div>
            }
            @if (enableStatic) {
                <div class="cta-buttons" *ngIf="enableStatic">
                    <div class="play-now-Cta">
                        <a>
                            <button (click)="handlePlaynowClick($event)" class="btn btn-primary more-info-btn">
                                {{ playNowText }}
                            </button>
                        </a>
                    </div>
                </div>
            }
            @if (moreInfoContent && moreInfoText) {
                <div #game_premiere_more_info class="game-premiere-more-info">
                    <div class="terms-sec">
                        <div class="arcade-terms">
                            <span class="arcade-term-left" (click)="toggleMoreInfoTerms()">
                                {{ moreInfoDefaultExpandedView ? lessInfoText : moreInfoText }}
                            </span>
                            <span class="arcade-term-right" (click)="toggleMoreInfoTerms()">
                                <span [ngClass]="moreInfoDefaultExpandedView ? 'theme-up' : 'theme-down'"></span>
                            </span>
                        </div>
                        <div class="terms-show-hide" [style.display]="moreInfoDefaultExpandedView ? 'block' : 'none'">
                            <div class="terms-info" [innerHTML]="moreInfoContent"></div>
                            <div class="horizontal-bar"></div>
                            <div (click)="handleViewDetailsClick()" class="view-details-link">{{ viewDetails }}</div>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
</div>
