<li class="casino-rw-news-feed-ticker-item">
    <div class="rw-card">
        <div class="rw-card-details" [ngStyle]="{ background: globalMessages.WinnerBgColor, opacity: globalMessages.WinnersFilterOpacity }">
            <div class="rw-u-details">
                <div class="rw-u-name w-color letter">{{ winner.screenName | slice: 0 : 2 }}<span>****</span></div>
                <div class="rw-u-amount w-color">{{ winner.gc | currencySymbol }} {{ winner.gcWin | currencyFormat }}</div>
            </div>
            <div class="rw-u-image">
                <img *ngIf="winner.jpWinFlag == 'N'" src="{{ WinnersConfig?.nonJackpotImage?.src }}" alt="textImage" />
                <img *ngIf="winner.jpWinFlag == 'Y'" src="{{ WinnersConfig?.jackpotImage?.src }}" alt="textImage" />
            </div>
        </div>
        <div class="rw-game-tails">
            <cc-game-lobby
                [ngClass]="winner.infoIconClass"
                [game]="winner"
                [gamePosition]="gamePosition"
                [category]="category"
                [gameLaunchedFrom]="'Recent winners Widget'"
                [gameIndex]="index">
            </cc-game-lobby>
        </div>
    </div>
</li>
