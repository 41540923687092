import { Injectable } from '@angular/core';

import {
    CookieService,
    MessageLifetime,
    MessageQueueService,
    MessageType,
    NavigationService,
    TrackingService,
    UserService,
} from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';
import { BingoFavouriteService } from './bingo-favorite-service';
import { CasinoIFrameOverlayService } from './casino-iframe-overlay.service';
import { CasinoWidgetService } from './casino-widget.service';
import { CozyBingoDataService } from './cozybingodata.service';

@Injectable({
    providedIn: 'root',
})
export class FavouriteService {
    favouriteGames: string[] = [];
    otherProductFavouriteGames: string[] = [];
    private favouriteGamesSubject = new BehaviorSubject<any>(null);
    favouriteGamesFeedObservable: Observable<any> = this.favouriteGamesSubject.asObservable();
    private otherProductFavouriteGamesSubject = new BehaviorSubject<any>(null);
    private bingoProductFavouriteGamesSubject = new BehaviorSubject<any>(null);
    otherProductFavouriteGamesFeedObservable: Observable<any> = this.otherProductFavouriteGamesSubject.asObservable();
    bingoProductFavouriteGamesFeedObservable: Observable<any> = this.bingoProductFavouriteGamesSubject.asObservable();
    private quickInfoPublisher = new BehaviorSubject<boolean>(false);
    quickIconObservable: Observable<boolean> = this.quickInfoPublisher.asObservable();

    constructor(
        private api: PlatformApiService,
        private user: UserService,
        private messageQueueService: MessageQueueService,
        private navigator: NavigationService,
        private casinoWidgetService: CasinoWidgetService,
        private configProviderService: ConfigProviderService,
        private cookie: CookieService,
        private bingoFavouriteService: BingoFavouriteService,
        private tracking: TrackingService,
        private cozyBingoService: CozyBingoDataService,
        private casinoIFrameOverlayService: CasinoIFrameOverlayService,
    ) {
        window.addEventListener('cc:casinocore_AB_fav_game', (event) => {
            if (event) {
                this.setFavouriteGames();
            }
        });
    }

    setFavouriteGames() {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        const isOverlay: boolean = this.casinoIFrameOverlayService.getOverlayStatus();
        this.api.get(apiPathsConfig.nonCriticalFeeds['favGames'], { isOverlay: isOverlay }, { withCredentials: true }).subscribe(
            (response: any) => {
                this.favouriteGames = response;
                this.favouriteGamesSubject.next(this.favouriteGames);
                if (this.cookie.getObject('favouriteGame')) {
                    this.toggleGameFavourite(this.cookie.getObject('favouriteGame'), true);
                    this.cookie.remove('favouriteGame');
                }
            },
            () => {
                this.favouriteGamesSubject.next(this.favouriteGames);
            },
        );
    }

    getFavouriteGames() {
        if (this.favouriteGames && this.favouriteGames.length > 0) {
            return this.favouriteGames;
        }
        return [];
    }
    // NFR - Services optimization - Remove
    updateOtherProductFavouriteGames(isFavourite: boolean, gameId: string) {
        if (isFavourite) {
            this.otherProductFavouriteGames.push(gameId);
        } else if (this.otherProductFavouriteGames) {
            this.otherProductFavouriteGames.splice(this.otherProductFavouriteGames.indexOf(gameId), 1);
        }
        this.otherProductFavouriteGamesSubject.next(this.otherProductFavouriteGames);
        this.publishingFavObervable(isFavourite, gameId);
    }

    publishingFavObervable(isFavourite: boolean, gameId: string) {
        let bingoFavData: any;
        bingoFavData = {
            isFavourite: isFavourite,
            gameName: gameId,
        };
        this.bingoProductFavouriteGamesSubject.next(bingoFavData);
    }

    fetchGames(game?: any, preLoginFavourite: boolean = false, isFromEgv: boolean = false) {
        const global = this.configProviderService.provideGlobalConfig();
        if (preLoginFavourite && game && this.checkForFavouriteGame(game)) {
            this.tracking.triggerEvent('GameInteraction', {
                'component.Game.interaction': 'Remove_Game_Favorites',
            });
            return;
        }
        if (this.user.isAuthenticated) {
            const gameVariantName = game.gameId;
            const isFavourite = !this.checkForFavouriteGame(game);
            if (game.fromCasino != undefined && !game.fromCasino) {
                if (game.isFavourite) {
                    this.bingoFavouriteService.removeFavourite(game.gameId, this.user.id || '').subscribe(
                        (response: any) => {
                            if (response.result) {
                                this.cozyBingoService.getBingoData(false, false);
                                this.publishingFavObervable(false, game.gameId);
                                if (this.casinoWidgetService.isCasinoWidget() || game.fromCozybingo) {
                                    const contactUsParam = {
                                        categoryid: 'rcp-widget',
                                        roomid: game.gameId,
                                        isFavourite: false,
                                    };
                                    this.casinoWidgetService.postMessageToParent('favupdated', contactUsParam);
                                }
                            }
                        },
                        () => {},
                    );
                } else {
                    this.bingoFavouriteService.addFavourite(game.gameId, this.user.id || '').subscribe(
                        (response: any) => {
                            if (response.result) {
                                this.cozyBingoService.getBingoData(false, false);
                                this.publishingFavObervable(true, game.gameId);
                                if (this.casinoWidgetService.isCasinoWidget() || game.fromCozybingo) {
                                    const contactUsParam = {
                                        categoryid: 'rcp-widget',
                                        roomid: game.gameId,
                                        isFavourite: true,
                                    };
                                    this.casinoWidgetService.postMessageToParent('favupdated', contactUsParam);
                                }
                            }
                        },
                        () => {},
                    );
                }
            } else {
                const requestBody = { gameVariantName, isFavourite };
                this.api.put('update-favourite-games', requestBody, { withCredentials: true, showSpinner: false }).subscribe(
                    (response: any) => {
                        if (response) {
                            if (response && response.errorcode === 0) {
                                this.favouriteGames = response.favourites;
                                const arr = response.favourites;
                                this.raiseFavEvent(game);
                                this.favouriteGamesSubject.next(this.favouriteGames);
                                if (arr && arr.indexOf(game.gameId) > -1 && !isFromEgv) {
                                    this.tracking.triggerEvent('GameInteraction', {
                                        'component.Game.interaction': 'Add_Game_Favorites',
                                    });
                                } else if (!isFromEgv) {
                                    this.tracking.triggerEvent('GameInteraction', {
                                        'component.Game.interaction': 'Remove_Game_Favorites',
                                    });
                                }
                            }
                        }
                    },
                    (response) => {
                        if (response && response.redirect) {
                            const message = this.messageQueueService.addError('SessionExpired');
                            if (message) {
                                this.messageQueueService.remove(message);
                            }
                            if (global?.messages['SessionExpired']) {
                                this.messageQueueService.add(global.messages['SessionExpired'], MessageType.Information, MessageLifetime.TempData);
                            }
                            this.navigator.goTo(response.redirect, { forceReload: true });
                        }
                    },
                );
            }
        }
    }

    removeQuickInfoClass() {
        this.quickInfoPublisher.next(true);
    }
    toggleGameFavourite(game: any, preLoginFavourite: boolean = false, isFromEgv: boolean = false) {
        this.fetchGames(game, preLoginFavourite, isFromEgv);
    }

    checkForFavouriteGame(game: any): boolean {
        if (this.user.isAuthenticated && game != undefined) {
            if (game.fromCasino != undefined && !game.fromCasino && this.otherProductFavouriteGames && !game.fromCozybingo) {
                return this.otherProductFavouriteGames.indexOf(game.gameId) > -1;
            } else if (game.fromCozybingo) {
                return game.isFavourite;
            } else if (this.favouriteGames) {
                return this.favouriteGames && this.favouriteGames.indexOf(game.gameId) > -1;
            }
        }
        return false;
    }

    raiseFavEvent(game: any) {
        const isFavourite = this.checkForFavouriteGame(game);
        const favEvent = {
            gameId: game.gameId,
            isFavourite: isFavourite,
        };
        const evt = new CustomEvent('cc:casinocore_fav_game', { detail: favEvent });
        window.dispatchEvent(evt);
    }
}
