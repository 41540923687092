import { ModuleWithProviders, NgModule } from '@angular/core';

import { ArcadeNavigationComponent } from './arcade-navigation.component';

@NgModule({
    imports: [ArcadeNavigationComponent],
    exports: [ArcadeNavigationComponent],
})
export class ArcadeNavigationModule {
    public static forRoot(): ModuleWithProviders<ArcadeNavigationModule> {
        return {
            ngModule: ArcadeNavigationModule,
            providers: [],
        };
    }
}
