import { Directive, EventEmitter, Input, NgModuleRef, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';

import { Subscription } from 'rxjs';

import { GamingStoriesLoaderService } from './gaming-stories-loader.service';

@Directive({
    selector: '[csGamingStoriesModuleLoad]',
    standalone: true,
})
export class GamingStoriesModuleLoadDirective implements OnInit, OnDestroy {
    @Input('csGamingStoriesModuleLoad') selectorName: string;
    @Input() data: any;
    @Input() config: any;
    @Output() dataNotExistsinsta = new EventEmitter<boolean>();
    gamingStoriesModuleLoaded: boolean;
    gamingurl: string = 'gamingstory/GetStories';
    gamingStoriesModuleLoadedSubscription: Subscription;

    private ngModuleRef: NgModuleRef<any>;

    constructor(
        private gamingStoriesLoaderService: GamingStoriesLoaderService,
        private viewContainerRef: ViewContainerRef,
    ) {}

    ngOnInit(): void {
        this.gamingStoriesModuleLoadedSubscription = this.gamingStoriesLoaderService.gamingStoriesModuleLoadedObservable.subscribe((moduleLoaded) => {
            this.gamingStoriesModuleLoaded = moduleLoaded;
            if (moduleLoaded) {
                this.createComponent();
            }
        });
    }

    createComponent(): void {
        const cmpFactory = this.gamingStoriesLoaderService.getLazyComponentFactory(this.selectorName);
        this.viewContainerRef.clear();
        const component = this.viewContainerRef.createComponent(cmpFactory, 0).instance;
        component.source = this.gamingurl;
        component.data = this.data;
        component.config = this.config;
        component.dataNotExists.subscribe((data: any) => {
            if (data) {
                this.dataNotExistsinsta.emit(true);
            }
        });
        //gaming stories configurations
    }

    ngOnDestroy(): void {
        this.ngModuleRef && this.ngModuleRef.destroy();
        if (this.gamingStoriesModuleLoadedSubscription) {
            this.gamingStoriesModuleLoadedSubscription.unsubscribe();
        }
    }
}
