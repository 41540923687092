/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, Input, booleanAttribute, numberAttribute } from '@angular/core';

import { CsCtaComponent, CsCtaKind, CsCtaSize, CsCtaVariant } from '../cs-cta/cs-cta.component';
import { CsClassIconComponent } from '../icons/class-icon/class-icon.component';
import { CsImageIconComponent } from '../icons/image-icon/image-icon.component';

@Component({
    selector: 'cs-slotrace-cta',
    templateUrl: './slotrace-cta.component.html',
    standalone: true,
    imports: [CsCtaComponent, CsImageIconComponent, CsClassIconComponent],
    host: {
        '[style]': "disabled ? 'pointer-events:none' : ''",
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlotRaceCtaComponent implements SlotRaceCtaViewModel, SlotRaceCtaLegacyViewModel {
    @Input() cta_text = '';
    @Input() imgSrc = '';
    @Input() startIconClass = '';
    @Input({ transform: booleanAttribute }) isLegacy = false;
    @Input({ transform: booleanAttribute }) disabled = false;
    @Input() casinoDsClass = '';

    // Legacy Properties, start
    @Input({ transform: numberAttribute }) coinsForOptin: number | undefined;
    @Input() size: CsCtaSize = 'large';
    @Input() variant: CsCtaVariant = 'filled';
    @Input() kind: CsCtaKind = 'primary';
}

export interface SlotRaceCtaViewModel {
    size: CsCtaSize;
    variant: CsCtaVariant;
    kind: CsCtaKind;
    cta_text: string;
    casinoDsClass: string;
    startIconClass: string;
    imgSrc: string;
    disabled: boolean;
    coinsForOptin: number | undefined;
}

export interface SlotRaceCtaLegacyViewModel {
    isLegacy: boolean;
}
