<button *ngIf="!isInhouseGame && twoButtons" class="btn {{ cssClass }}" (click)="goToPlayGame(false)">
    <cc-global-translate [key]="'ButtonPlayForReal'"></cc-global-translate>
</button>
<button *ngIf="!isInhouseGame && twoButtons" class="btn {{ cssClass }} playforfun" (click)="goToPlayGame(true)">
    <cc-global-translate [key]="'ButtonPlayForFun'"></cc-global-translate>
</button>
<button *ngIf="isInhouseGame && twoButtons" class="btn {{ cssClass }}" (click)="goToPlayGame(false)">
    <cc-global-translate [key]="'ButtonPlayNow'"></cc-global-translate>
</button>
<button *ngIf="!twoButtons" class="btn {{ cssClass }}" (click)="goToPlayGame(false)">
    <cc-global-translate [key]="'ButtonPlayNow'"></cc-global-translate>
</button>
