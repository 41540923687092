import { ModuleWithProviders, NgModule } from '@angular/core';

import { runOnAppInit } from '@frontend/vanilla/core';

import { CasinoBootstrapService } from './casino-bootstrap.service';

@NgModule({})
export class CasinoBootstrapModule {
    public static forRoot(): ModuleWithProviders<CasinoBootstrapModule> {
        return {
            ngModule: CasinoBootstrapModule,
            providers: [runOnAppInit(CasinoBootstrapService)],
        };
    }
}
