import { Injectable } from '@angular/core';

import { Logger, UserService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { UrlUtilityService } from '../utilities/url-utility.service';
import { ApiPathsHelperService } from './api-paths-helper-service';
import { CasinoApiService } from './casino-api.service';

@Injectable({
    providedIn: 'root',
})
export class CozyBingoDataService {
    private cozyBingoRoomsData = new BehaviorSubject<any>(null);
    cozyBingoRoomsDataObservable: Observable<any> = this.cozyBingoRoomsData.asObservable();
    private cozyBingoRecentGamesData = new BehaviorSubject<any>(null);
    cozyBingoRecentGamesObservable: Observable<any> = this.cozyBingoRecentGamesData.asObservable();
    cozyBingoRoomsMap: Map<string, any> = new Map<string, any>();
    cozyBingofavourites: any = [];
    sitecoreResponseRooms: any;
    sitecoreResponseRoomnames: any;
    featuresConfiguration: any;
    globalMessages: any;
    categoryList: any;
    bingoConfiguration: any;
    schedulePolling: any;
    cozyBingoRecentGames: any = [];
    constructor(
        private casinoApiService: CasinoApiService,
        private urlUtilityService: UrlUtilityService,
        private configProviderService: ConfigProviderService,
        private apiPathsHelperService: ApiPathsHelperService,
        private logger: Logger,
        private user: UserService,
    ) {}

    getBingoData(fetchBingoRoomsSitecoreData: boolean, fetchRecentlyPlayed: boolean) {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        let endPoint = this.apiPathsHelperService.transformApi(apiPathsConfig.bingo['bgExternalBingo'], this.urlUtilityService.getBingoApiBaseUrl());
        if (apiPathsConfig.bingo['newApiStructure'] && apiPathsConfig.bingo['newApiStructure'] == 'enabled') {
            // Remove this once bingo moved to new api structure
            endPoint = apiPathsConfig.bingo['bgExternalBingo'];
        }
        this.casinoApiService
            .getBingoApi(
                endPoint,
                { fetchBingoRoomsSitecoreData: fetchBingoRoomsSitecoreData, fetchRecentlyPlayed: fetchRecentlyPlayed },
                { withCredentials: true },
            )
            .subscribe(
                (response) => {
                    if (response) {
                        if (fetchBingoRoomsSitecoreData) {
                            this.sitecoreResponseRooms = response['bingoRoomsSitecoreResponse']['rooms'];
                            this.sitecoreResponseRoomnames = response['bingoRoomsSitecoreResponse']['roomNames'];
                            this.featuresConfiguration = response['dynaconConfigurations']['featuresConfiguration'];
                            this.globalMessages = response['dynaconConfigurations']['globalMessages'];
                            this.categoryList = response['dynaconConfigurations']['categoryList'];
                            this.bingoConfiguration = response['dynaconConfigurations']['bingoConfiguration'];
                        }
                        if (response.scheduleData?.result?.games_list) {
                            this.roomDataMap(
                                response['scheduleData']['result']['games_list'],
                                this.sitecoreResponseRooms,
                                this.sitecoreResponseRoomnames,
                            );
                        }
                        const cozybingoConfig: any = this.configProviderService.provideCozyBingoConfig();
                        if (cozybingoConfig.cozyBingoFavouritesInRpwidget) {
                            this.cozyBingofavourites = response['scheduleData']['result']['favouriteRooms'];
                        }
                        if (cozybingoConfig.cozyBingoRecentGamesInRpwidget && response['recentGamesData']) {
                            this.cozyBingoRecentGames = response['recentGamesData']['result']['rooms'];
                            //Send recently played games to Global Search
                            this.raiseEventForRecentlyPlayed(this.cozyBingoRecentGames);
                        }
                        if (this.cozyBingoRoomsMap) {
                            this.cozyBingoRoomsData.next(this.cozyBingoRoomsMap);
                        }
                    }
                },
                (error) => {
                    this.logger.error('unable to retrieve data from bgExternalBingo API : ' + error);
                },
            );
    }

    // Method to send Recent Games to Global Search
    private raiseEventForRecentlyPlayed(recentGamesObject: any): void {
        const recentgames: any = {};
        recentgames['cozybingo'] = recentGamesObject;
        const globalsearchconfig: any = this.configProviderService.provideGlobalSearchConfig();
        if (globalsearchconfig.isEnabled && recentGamesObject.length > 0) {
            this.cozyBingoRecentGamesData.next(recentgames);
        }
    }

    roomDataMap(rooms: any, sitecorerooms: any, sitecoreroomnames: any) {
        if (this.schedulePolling) clearInterval(this.schedulePolling);
        if (rooms && sitecorerooms) {
            let defaultRoomSitecoreInfo: any = sitecorerooms.filter((item: any) => item.roomId == 'default');
            if (defaultRoomSitecoreInfo) {
                defaultRoomSitecoreInfo = defaultRoomSitecoreInfo[0];
            }
            rooms &&
                rooms.forEach((room: any) => {
                    let roomSitecoreInfo = sitecorerooms.filter((sitecoreroom: any) => sitecoreroom.roomId == room.id);

                    if (roomSitecoreInfo && roomSitecoreInfo.length > 0) {
                        roomSitecoreInfo = roomSitecoreInfo[0];
                        if (!roomSitecoreInfo.desktopImage) {
                            roomSitecoreInfo.desktopImage = defaultRoomSitecoreInfo.desktopImage;
                        }
                    } else {
                        roomSitecoreInfo = defaultRoomSitecoreInfo;
                    }
                    const cozybingoConfig: any = this.configProviderService.provideCozyBingoConfig();
                    if (cozybingoConfig.enableWidgetImageForBingoGame) {
                        roomSitecoreInfo.desktopImage = roomSitecoreInfo?.widgetImage
                            ? roomSitecoreInfo.widgetImage
                            : defaultRoomSitecoreInfo.widgetImage;
                    }
                    if (sitecoreroomnames && roomSitecoreInfo && sitecoreroomnames[roomSitecoreInfo.roomId]) {
                        roomSitecoreInfo.titleFromRoomNames = sitecoreroomnames[roomSitecoreInfo.roomId];
                    }
                    room.sitecoreInfo = roomSitecoreInfo;
                    if (room.sitecoreInfo.roomId !== 'default' && room.sitecoreInfo.titleFromRoomNames) {
                        room.name = room.sitecoreInfo.titleFromRoomNames;
                    }
                    this.cozyBingoRoomsMap.set(room.id, room);
                });
        }
    }
    getRoomdatabyId(roomid: any) {
        return this.cozyBingoRoomsMap.get(roomid);
    }
    getcozybingofavourites() {
        let cozyFavs: any = [];
        if (this.cozyBingoRoomsMap && this.cozyBingoRoomsMap.size > 0 && this.cozyBingofavourites && this.cozyBingofavourites.length > 0) {
            cozyFavs = this.cozyBingofavourites.filter((item: any) => this.cozyBingoRoomsMap.has(item));
            if (cozyFavs && cozyFavs.length > 0) {
                return cozyFavs;
            }
        }
        return cozyFavs;
    }
    checkForFavouriteGame(game: any): boolean {
        if (this.user.isAuthenticated && game?.fromCasino != undefined && game?.fromCasino && this.cozyBingofavourites.length > 0) {
            return this.cozyBingofavourites.indexOf(game.gameId) > -1;
        }
        return false;
    }
    getcozybingorecentgames() {
        let cozyrpgames: any = [];
        if (this.cozyBingoRoomsMap && this.cozyBingoRoomsMap.size > 0 && this.cozyBingoRecentGames && this.cozyBingoRecentGames.length > 0) {
            cozyrpgames = this.cozyBingoRecentGames.filter((item: any) => this.cozyBingoRoomsMap.has(item));
            if (cozyrpgames && cozyrpgames.length > 0) {
                return cozyrpgames;
            }
        }
        return cozyrpgames;
    }

    getFeatureConfigs() {
        return this.featuresConfiguration;
    }
    getGlobalMessages() {
        return this.globalMessages;
    }
    getcategoryList() {
        return this.categoryList;
    }
    getbingoConfiguration() {
        return this.bingoConfiguration;
    }
    // NFR - Services optimization - Remove
    ReloadFeed(gameData: any) {
        let timeOut = 0;
        const curentTime = new Date().getTime();
        if (gameData && gameData.length != 0) {
            const games = gameData.filter((g: any) => {
                return (
                    this.cozyBingofavourites &&
                    this.cozyBingofavourites.indexOf(g.id) > -1 &&
                    g.status.state_end_time != null &&
                    new Date(g.status.state_end_time * 1000).getTime() > curentTime
                );
            });
            const f_game = Math.min.apply(
                Math,
                games.map((g: any) => {
                    return g.status.state_end_time;
                }),
            );
            if (f_game) timeOut = Math.max(0, new Date(f_game * 1000).getTime() - curentTime);
        }
        if (this.bingoConfiguration && this.bingoConfiguration['schedulePagePolling']) {
            if (timeOut > this.bingoConfiguration['scheduleLobbyRefreshTimeFrame'] || timeOut == 0)
                timeOut = this.bingoConfiguration['scheduleLobbyRefreshTimeFrame'];
        }
        if (timeOut > 0)
            this.schedulePolling = setTimeout(() => {
                this.getBingoData(false, false);
            }, timeOut);
    }
}
