<div
    [ngClass]="assignPromotedGameThumbail(game)"
    [style.border-color]="promotedGameBackgroundColor"
    class="casino-verticalicon-games"
    (mouseenter)="GameEnter(game)"
    (mouseleave)="GameLeave(game)"
    inViewport
    [inViewportOptions]="{ threshold: [0], partial: true }"
    (inViewportAction)="isElementinViewPort($event, game)">
    <div
        [class.casino-playbtn-hover-effect]="playNowHoverEffect"
        #gameref
        class="casino-game-cls"
        (mouseover)="mouseOver(game.gameId + category?.categoryid)"
        (mouseleave)="mouseLeave()"
        [ngClass]="{
            'recnt-gyml-non-jpk-banner': showJackpotOnGameTiles && !game.jackpotValue && isTouch,
            'casino-new-styling-overlay':
                onMouseHover &&
                !isTouch &&
                (!((game.iconType === positiveVideoIconType || game.iconType === negativeVideoIconType) && isVideoPreviewEnabled) ||
                    game.widgetName === 'RecentWinners'),
            'casino-quick-info-active': useQuickInfo,
            'casino-livecasino-api-enabled': isLiveCasinoEnabled && game.widgetName != 'RecentWinners',
            'casino-livecasino-overlay':
                onLivecasinoMouseHover &&
                !isTouch &&
                liveGameInfo &&
                liveGameInfo.tableStatus &&
                !((game.iconType === positiveVideoIconType || game.iconType === negativeVideoIconType) && isVideoPreviewEnabled),
            'casino-live-game': isLiveGame,
            'fav-lcapi-active': enableFavouriteGamesforLiveCasinoApi,
            'casino-table-offline': !isTableOnline,
            'casino-new-grid-icon-6': game.iconType == '6' || game.iconType == '-6',
            'casino-new-grid-icon-7': game.iconType == '7' || game.iconType == '-7'
        }"
        [ngStyle]="{ filter: game?.gameTileDropShadow }">
        <div
            class="casino-overlay-bg"
            [ngClass]="
                (game.iconType === positiveVideoIconType || game.iconType === negativeVideoIconType) && isVideoPreviewEnabled
                    ? 'casino-video-overlay-bg'
                    : ''
            "
            *ngIf="onMouseLeave && (enableUkMouseOverStyle || game.widgetName === 'RecentWinners') && !isTouch"
            [ngStyle]="{ 'border-radius': game?.gameTileBorderRadius }"></div>

        <div class="casino-livecasino-overlay-bg" *ngIf="onLivecasinoMouseLeave && isLiveGame && !isTouch && liveGameInfo.tableStatus"></div>
        <cc-freespin-summary
            *ngIf="this.showFreeSpinCount"
            [freespinAvailableCount]="this.availableCount"
            [freespinTotalCount]="this.totalCount"
            [isOptedIn]="this.optedIn"
            (freespinsInfo)="displayQuickInfo($event)">
        </cc-freespin-summary>
        <cc-game-quick-info
            *ngIf="QuickInfoVisible && QuickInfoVisible === game.gameId + category?.categoryid"
            [game]="game"
            [gamePosition]="gamePosition"
            [categoryType]="category?.categoryid"
            [icon]="quickInfoIcon"
            (hide)="hideQuickInfo()"
            [quickInfoScrollY]="quickInfoScrollY"
            [gameLaunchedFrom]="gameLaunchedFrom"
            [widgetFrameClr]="widgetFrameClr"
            [gridPosition]="category?.categoryIndex"
            [gameModel]="gameModel"
            [isLiveGame]="isLiveGame"
            [gameContainerLocation]="gameContainerLocation"
            [liveGameInfo]="liveGameInfo"
            [product]="product"
            [stickersArray]="stickers"
            [lobbyType]="currentLobbyType"
            [displayRecenetWinners]="displayRecenetWinners">
        </cc-game-quick-info>
        <a
            *ngIf="game.icon"
            (click)="action(game, 'p', $event)"
            class="game-image-link"
            (touchstart)="gameMouseDown($event)"
            (touchend)="gameMouseUp($event)"
            [ngClass]="{ 'arcade-not-recentlyplayed': category?.categoryid != 'recentgames' }"
            [style]="arcadeLobbyMessages?.ArcadeGridStyle">
            <cc-casino-core-lazy-placeholder
                [inputData]="{
                    game: game,
                    QuickInfoVisible: QuickInfoVisible,
                    isLiveGame: isLiveGame,
                    stickers: stickers,
                    gameIndex: gameIndex,
                    icon: icon,
                    category: category
                }"
                [componentName]="'NewGridGameMetaDataComponent'"
                *ngIf="game.iconType == '6' || game.iconType == '-6' || game.iconType == '7' || game.iconType == '-7'">
            </cc-casino-core-lazy-placeholder>

            <cc-image-loader
                *ngIf="
                    game.iconType != '10' &&
                    game.iconType != '-10' &&
                    !game.isGameHover &&
                    game.iconType != '6' &&
                    game.iconType != '-6' &&
                    game.iconType != '7' &&
                    game.iconType != '-7'
                "
                [icon]="icon"
                [iconType]="game.iconType"
                [gameInfo]="game.gameId"
                [format]="game.format"
                [forcevisible]="'true'"
                [alt]="game.icon.alt">
            </cc-image-loader>
            <cc-image-loader
                *ngIf="game.iconType == '10' || game.iconType == '-10'"
                [hidden]="game.isGameHover"
                [icon]="icon"
                [iconType]="game.iconType"
                [gameInfo]="game.gameId"
                [format]="game.format"
                [forcevisible]="'true'"
                [alt]="game.icon.alt">
            </cc-image-loader>

            <video
                [id]="game.gameId"
                #videoPlayer
                *ngIf="isVideoPreviewEnabled && (isMobile || isTablet) && (game.iconType == '10' || game.iconType == '-10') && game.isInViewport"
                [hidden]="!game.isGameHover"
                poster="{{ game.icon.src }}"
                [src]="game.gameVideoUrl"
                autoplay="autoplay"
                [muted]="true"
                playsinline
                (ended)="videoEnded(game, game.gameId + category?.categoryid)"
                (loadeddata)="onLoadMetaData(game)"
                (error)="playbackFailed(game, game.gameId + category?.categoryid)"></video>
            <video
                [id]="game.gameId"
                #videoPlayer
                *ngIf="isVideoPreviewEnabled && !isTablet && !isMobile && (game.iconType == '10' || game.iconType == '-10')"
                [hidden]="!game.isGameHover"
                [src]="game.gameVideoUrl"
                autoplay="autoplay"
                [muted]="true"
                playsinline
                (ended)="videoDesktopEnded(game, game.gameId + category?.categoryid)"
                (loadeddata)="onLoadMetaData(game)"
                (error)="playbackFailed(game, game.gameId + category?.categoryid)"></video>

            <cc-casino-core-lazy-placeholder
                [outputEvents]="[{ eventName: 'onTimerElapsed', callback: onLiveCasinoTableTimerEnd }]"
                [inputData]="{ game: game, liveGameInfo: liveGameInfo, category: category }"
                [componentName]="'LiveCasinoComponent'"
                *ngIf="isLiveGame"></cc-casino-core-lazy-placeholder>

            <div class="game-icon-bottom-wrapper" [class.is-fast-loading-game]="game.isFastLoadingGame" *ngIf="game.widgetName != 'RecentWinners'">
                <a style="visibility: hidden" href="/game-info/{{ game.gameId }}" *ngIf="isAnchorTagVisible"></a>
                <ng-container *ngIf="!enableEpcotSubNavigation">
                    <div
                        class="jackpot"
                        [ngStyle]="{ background: jpRibbonStyles?.jpBgColor, color: jpRibbonStyles?.jpTextColor }"
                        [ngClass]="{
                            'casino-new-jp-styles': newJackpotRibbonStyling,
                            'rcnt-new-jp-styles': game.isFastLoadingGame,
                            'rp-jackpot': (game.isGYML || game.isPromoted) && game.isFastLoadingGame
                        }"
                        [hidden]="QuickInfoVisible"
                        *ngIf="showJackpotOnGameTiles && game.jackpotValue && !game.isGameHover">
                        <span class="hot-icon" *ngIf="game.isHotJackpotGroup && enableHotIcon">
                            <img [src]="hotIcon" *ngIf="!enableEpcotSubNavigation" alt="hot-icon" />
                            <img [src]="epcotHotIcon" *ngIf="enableEpcotSubNavigation" alt="epcot-hot-icon" />
                        </span>
                        <span class="jp-value">{{ game.jackpotValue }}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="enableEpcotSubNavigation">
                    <div
                        class="jackpot"
                        [ngStyle]="{ background: jpRibbonStyles?.jpBgColor, color: jpRibbonStyles?.jpTextColor }"
                        [ngClass]="{
                            'casino-new-jp-styles': newJackpotRibbonStyling,
                            'rcnt-new-jp-styles': game.isFastLoadingGame,
                            'rp-jackpot': (game.isGYML || game.isPromoted) && game.isFastLoadingGame,
                            'epcot-jackpot': enableEpcotSubNavigation
                        }"
                        [hidden]="QuickInfoVisible"
                        *ngIf="showJackpotOnGameTiles && game.jackpotValue && !game.isGameHover"></div>
                    <div class="epcot-jackpot-value" [hidden]="QuickInfoVisible">
                        <span class="hot-icon">
                            <ng-container *ngIf="game.isHotJackpotGroup && enableHotIcon">
                                <img [src]="hotIcon" *ngIf="!enableEpcotSubNavigation" alt="hot-icon" />
                                <img [src]="epcotHotIcon" *ngIf="enableEpcotSubNavigation" alt="epcot-hot-icon" />
                            </ng-container>
                        </span>
                        <span class="jp-value">{{ game.jackpotValue }}</span>
                    </div>
                </ng-container>

                <!-- i icon for quick info functionality in mobile -->

                <div
                    *ngIf="isTouch && useQuickInfo; else Favourite"
                    class="casino-game-fav-icon casino-mobile-fav theme-info casino-quick-info rcntly-gyml-quick-info rp-i-fav-icon"
                    (click)="displayQuickInfo($event)"
                    [ngClass]="{
                        'rcntly-gyml-bage-available': isUnfinishedGame,
                        'qi-lcapi-active': enableQuickInfoforLiveCasinoApi && isLiveGame,
                        'rp-i-fav-icon-with-jpk-fld': game.isFastLoadingGame && game.jackpotValue,
                        'rp-i-fav-icon-with-fast-loading': game.isFastLoadingGame
                    }">
                    {{ moreInfo }}
                </div>
            </div>
            <div
                *ngIf="game.isFastLoadingGame"
                class="fast-loading-game"
                [hidden]="QuickInfoVisible"
                [ngClass]="{ 'rp-fast-loading': game.isGYML || game.isPromoted, 'casino-nojackpot-value': !game.jackpotValue }">
                <img src="/pictures/Fast-Loading-Game-Animation.gif" alt="pictures" />
            </div>
            <div
                *ngIf="game.isGYML"
                [ngClass]="assignRecommendedWidgetColors()"
                [style.background]="recommendedGamesBackgroundColor"
                [style.color]="recommendedGamesFontColours">
                <span>{{ widgetRecommended }}</span>
            </div>
            <div
                *ngIf="game.isPromoted"
                [ngClass]="assignRecommendedWidgetColors()"
                [style.background-color]="promotedGameBackgroundColor"
                [style.color]="promotedGameTextColor">
                <span>{{ promotedGameText }}</span>
            </div>

            <div [hidden]="QuickInfoVisible" *ngIf="game.isUnfinished && !isLiveGame" class="casino-bageUnfinished">
                <cc-global-translate [key]="'UNFINISHED'"></cc-global-translate>
            </div>
            <span *ngIf="enableStickerStyles; else standardStyles">
                <div
                    [hidden]="QuickInfoVisible"
                    class="game-badge"
                    *ngIf="
                        stickers &&
                        stickers.length > 0 &&
                        !isLiveGame &&
                        !game.isVideoLoaded &&
                        game.iconType != '6' &&
                        game.iconType != '-6' &&
                        game.iconType != '7' &&
                        game.iconType != '-7'
                    "
                    [ngClass]="{ 'stickers-uk': isStickersForUk }">
                    <b class="promo-text" *ngFor="let sticker of stickers" [style]="getStyles(sticker)"> {{ sticker }}</b>
                </div>
            </span>
            <ng-template #standardStyles>
                <div
                    [hidden]="QuickInfoVisible"
                    class="game-badge"
                    *ngIf="
                        stickers &&
                        stickers.length > 0 &&
                        !isLiveGame &&
                        !game.isVideoLoaded &&
                        game.iconType != '6' &&
                        game.iconType != '-6' &&
                        game.iconType != '7' &&
                        game.iconType != '-7'
                    "
                    [ngClass]="{ 'stickers-uk': isStickersForUk }">
                    <b
                        class="promo-text"
                        *ngFor="let sticker of stickers; let i = index"
                        [style]="getStickerColor(i)"
                        [ngClass]="{ 'nonTouch': !isTouch, 'promo-text-badge-0': i % 2 === 0, 'promo-text-badge-1': i % 2 !== 0 }"
                        >{{ sticker }}</b
                    >
                </div>
            </ng-template>
            <div class="arcade-small-tile" *ngIf="category?.categoryid != 'recentgames'">
                <div class="small-tile">
                    <div class="title-name" [ngStyle]="{ color: arcadeLobbyMessages?.ArcadeGametitleClr }" [textContent]="game.name"></div>
                    <!-- <div class="btn-primary btn btn-lg">Login</div> -->
                    <div class="coin-arcade-btn">
                        <button class="btn-primary btn btn-md">
                            {{ isLoggedIn ? arcadeLobbyMessages?.PlayNow : arcadeLobbyMessages?.LoginBtn }}
                            <img src="{{ arcadeLobbyMessages?.CoinImg }}" alt="coin-img" />
                        </button>
                    </div>
                </div>
                <div
                    class="tandcs"
                    *ngIf="Type == 'unscrollable-games'"
                    [ngStyle]="{ color: arcadeLobbyMessages?.TncTextOnGameTileTextClr, background: arcadeLobbyMessages?.TncTextOnGameTileBgClr }"
                    [textContent]="arcadeLobbyMessages?.TncTextOnGameTile"></div>
            </div>
        </a>
        <ng-template #Favourite>
            <div
                class="casino-game-fav-icon casino-mobile-fav fav"
                *ngIf="useFavouriteGames && isTouch"
                [ngClass]="isFavouriteGame(game) ? favouriteStyle : unFavouriteStyle"
                (click)="toggleFavourite(game, $event)"></div>
        </ng-template>
        <div *ngIf="showGameTitle" class="game-title">
            <a (click)="action(game, 'l')">{{ gameTitle }}</a>
            <span *ngIf="game.enableDetailPage && !game.forceDetailPage">
                <a class="icon theme-info" (click)="action(game, 'i')"></a>
            </span>
        </div>
        <div
            class="arcade-sticker"
            [ngClass]="arcadeLobbyMessages?.arcadeStickerStyle"
            [ngStyle]="{ background: arcadeLobbyMessages?.ArcadeStickerBgClr }"
            *ngIf="category?.categoryid != 'recentgames'">
            <div class="arcade-sticker-txt" [ngStyle]="{ color: arcadeLobbyMessages?.ArcadeStickerTextClr }">
                {{ arcadeLobbyMessages?.MinBetText }}{{ gameMinBet }}
            </div>
            <div class="arcade-coin-img">
                <img src="{{ arcadeLobbyMessages?.CoinImg }}" alt="coin-img" />
            </div>
        </div>
    </div>
</div>
