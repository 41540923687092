import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csDownloadAppFromDevice', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: downloadAppFromDeviceClientConfigFactory,
})
export class DownloadAppFromDeviceClientConfig {
    downloadAppFromDevice: boolean;
    downloadAppErrorMsgs: string;
    geoComplyMessage: any;
}

export function downloadAppFromDeviceClientConfigFactory(service: LazyClientConfigService) {
    return service.get(DownloadAppFromDeviceClientConfig);
}
