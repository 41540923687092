import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { TimerService, UserService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService } from '../../config-provider/config-provider.service';
import { GDSResponse, RetryType } from '../coordinator.models';
import { GeoLocationErrorComponent } from '../geo-location-error/geo-location-error.component';
import { GeolocationCoordinatorService } from './coordinator.service';

@Injectable({
    providedIn: 'root',
})
export class GeoLocationValidationService {
    private dialogRef: MatDialogRef<GeoLocationErrorComponent>;
    isLocationFetchTimeOut: boolean;
    isLocationFetched: boolean;
    resetTimerAndRefetchLocation: boolean;
    errorResponse: GDSResponse;
    gdsApiInterval: any;
    resetTimer: any;
    geolocationConfig: any;
    reCheckLocationClicked = new BehaviorSubject<any>(null);
    reCheckLocationClickedObservable: Observable<any> = this.reCheckLocationClicked.asObservable();

    constructor(
        private apiService: HttpClient,
        private timerService: TimerService,
        private readonly dialog: MatDialog,
        private configProviderService: ConfigProviderService,
        private geolocationCoordinatorService: GeolocationCoordinatorService,
        private user: UserService,
    ) {}

    public validateLocation(callback: (isLocationFailed: boolean) => void): void {
        this.geolocationConfig = this.configProviderService.provideGeoLocationConfig();
        this.isLocationFetchTimeOut = false;
        this.isLocationFetched = false;
        this.resetTimerAndRefetchLocation = false;
        this.errorResponse = {} as GDSResponse;
        this.gdsApiInterval = this.geolocationConfig.initialRetryInterval;
        this.getLocationDetailsfromGDS(callback);
        this.startTimer(callback);
    }

    private startTimer(callback: (isLocationFailed: boolean) => void): void {
        this.clearTimer();
        this.resetTimer = this.timerService.setTimeout(() => {
            this.isLocationFetchTimeOut = true;
            if (!this.isLocationFetched) {
                this.showGeoLocationError();
                callback(true);
                return;
            }
        }, this.gdsApiInterval);
    }

    private getLocationDetailsfromGDS(callback: (isLocationFailed: boolean) => void): void {
        if (!this.isLocationFetchTimeOut) {
            this.apiService.post(this.geolocationConfig.gdsUrl, 'ssoKey=' + this.user.ssoToken).subscribe((result) => {
                const results: any = result;
                if (results?.isLocationAvailable) {
                    if (results?.locationStatus === 'VALID_LOCATION' && results?.errorCode === 0) {
                        this.isLocationFetched = true;
                        callback(false);
                        return;
                    }
                    const isRetryableErrCode =
                        this.geolocationConfig.isRetryButtonEnabled &&
                        results?.troubleShooterMessages?.tsMsgs?.find((errMsg: any) => errMsg.retry === RetryType.Retry);

                    this.errorResponse = results;
                    if (isRetryableErrCode) {
                        this.isLocationFetched = false;
                        this.refetchLocationOnRetryableErrorCodes(callback);
                        this.refetchGdsApi(callback);
                    } else {
                        this.clearTimer();
                        this.showGeoLocationError();
                        callback(true);
                        return;
                    }
                } else {
                    this.errorResponse = results;
                    this.refetchGdsApi(callback);
                }
            });
        }
    }

    private refetchLocationOnRetryableErrorCodes(callback: (isLocationFailed: boolean) => void): void {
        if (!this.resetTimerAndRefetchLocation) {
            this.isLocationFetchTimeOut = false;
            this.gdsApiInterval = this.geolocationConfig.retryInterval;
            this.resetTimerAndRefetchLocation = true;
            this.startTimer(callback);
        }
    }

    private clearTimer(): void {
        if (this.resetTimer) {
            this.timerService.clearTimeout(this.resetTimer);
        }
    }

    private refetchGdsApi(callback: (isLocationFailed: boolean) => void): void {
        this.timerService.setTimeout(() => {
            this.getLocationDetailsfromGDS(callback);
        }, 1000);
    }

    private showGeoLocationError(): void {
        const tsMsgs = this.errorResponse?.troubleShooterMessages?.tsMsgs;
        this.dialogRef = this.dialog.open(GeoLocationErrorComponent, {
            data: {
                errorMessages: tsMsgs,
            },
            disableClose: true,
            panelClass: 'geo-comply-error-dialog',
        });
        //this.doTracking();
    }
    assignToCoordinatorService() {
        this.geolocationCoordinatorService.onError = undefined;
        this.geolocationCoordinatorService.onInitialized = undefined;
    }
}
