import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef } from '@angular/material/dialog';

import { AppInfoConfig, Page, UserService, UtilsService, WindowRef } from '@frontend/vanilla/core';
import { TrustAsResourceUrlPipe } from '@frontend/vanilla/shared/browser';

import { ConfigProviderService } from '../config-provider/config-provider.service';

@Component({
    selector: 'cc-cashier-iframe',
    templateUrl: 'cashier-iframe.component.html',
    standalone: true,
    imports: [MatDialogContent, MatDialogActions, TrustAsResourceUrlPipe],
})
export class CashierIframeComponent implements OnInit, OnDestroy {
    @Input() isQDAllowed: boolean;
    cashierIframeUrl: string;
    private iframeCommunicationEnabled: boolean = false;
    private qdUrl: string;
    private depositUrl: string;

    constructor(
        public dialogRef: MatDialogRef<CashierIframeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private user: UserService,
        private page: Page,
        private windowRef: WindowRef,
        private utils: UtilsService,
        private appInfoConfig: AppInfoConfig,
        private configProviderService: ConfigProviderService,
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
        const casinoConfig = this.configProviderService.provideCasinoConfig();
        const mcPage = this.configProviderService.provideMcPageConfig();
        const features = this.configProviderService.provideFeaturesConfig();
        if (!this.iframeCommunicationEnabled) {
            this.enableIframeCommunication();
            this.iframeCommunicationEnabled = true;
        }

        this.qdUrl = this.utils.format(
            mcPage.cashierQDUrlTemplate,
            this.user.id,
            this.appInfoConfig.brand,
            casinoConfig.productId,
            this.appInfoConfig.channel,
            this.page.lang,
            this.user.ssoToken,
        );
        this.qdUrl += '&hideCloseButton=true';

        this.depositUrl = mcPage.cashierDepositUrlTemplate;
        this.depositUrl = this.depositUrl.replace('{0}', 'this.user.ssoToken');
        this.depositUrl = this.depositUrl.replace('{1}', this.page.lang);
        this.depositUrl = this.depositUrl.replace('{2}', '');
        this.depositUrl = this.depositUrl.replace('{3}', '');
        this.depositUrl = this.depositUrl + '&hideCloseButton=true';

        if (features.checkQDAllowedForDepositURL && this.isQDAllowed) {
            this.cashierIframeUrl = this.qdUrl;
        } else {
            this.cashierIframeUrl = this.depositUrl;
        }
    }

    ngOnDestroy(): void {
        this.windowRef.nativeWindow.removeEventListener('message', () => {});
    }

    private enableIframeCommunication() {
        const cashierIframe = document.getElementById('casinocashieriframe') as HTMLIFrameElement;
        this.windowRef.nativeWindow.addEventListener('message', (event) => {
            if (!event || !event.data || typeof event.data !== 'string') {
                return;
            }
            const message = unescape(event.data)
                .split('&')
                .reduce((seed, cur): any => {
                    const param: any = cur.trim().split('=');
                    const paramIndex: any = param[0];
                    seed[paramIndex as keyof Object] = param[1];
                    return seed;
                }, {}) as {
                action: string;
                [key: string]: any;
            };
            const action = (message.action || '').toUpperCase();
            if (action === 'CLOSE') {
                if (message.showAllOptions === 'true') {
                    cashierIframe.src = this.depositUrl;
                } else {
                    this.dialogRef.close();
                }
            }
        });
    }
}
