import { Injectable } from '@angular/core';

import { CookieService, NativeAppService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { CasinoLobbyService } from '../casino-lobby-manager/casino-lobby.service';
import { ConfigProviderService } from '../config-provider/config-provider.service';

@Injectable({
    providedIn: 'root',
})
export class GlobalJackpotModService {
    globalJackpotPoolInfoMap: Map<string, any> = new Map<string, any>();
    siteCoreData: Map<string, any> = new Map<string, any>();
    lobbyData: Map<string, any> = new Map<string, any>();
    jackpotConfigData: Map<string, any> = new Map<string, any>();
    jackpotTeaserData: Map<string, any> = new Map<string, any>();
    jackpotSharedContentData: Map<string, any> = new Map<string, any>();
    historyFeedData: Map<string, any> = new Map<string, any>();
    private defaultTandCData: Map<string, any> = new Map<string, any>();
    private defaultTandCDataSubject = new BehaviorSubject<any>(null);
    defaultTandCDataSubjectObservable: Observable<any> = this.defaultTandCDataSubject.asObservable();

    constructor(
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
        private nativeAppService: NativeAppService,
        private cookies: CookieService,
    ) {}

    onAppInit() {}

    setGlobalJackpotPoolInfoMap(lobbyType: string, pathSegment: string, jackpotPoolInfo: any) {
        const key = lobbyType + '#' + pathSegment + '#';
        this.globalJackpotPoolInfoMap.set(key, jackpotPoolInfo);
    }

    getGlobalJackpotPoolInfoMap(key: string) {
        return this.globalJackpotPoolInfoMap.get(key);
    }

    setSitecoreTemplateMap(lobbyType: string, pathSegment: string, siteCoreData: any) {
        const key = lobbyType + '#' + pathSegment + '#';
        this.siteCoreData.set(key, siteCoreData);
    }

    getSitecoreTemplateMap(key: string) {
        return this.siteCoreData.get(key);
    }

    setGlobalJackpotConfigData(lobbyType: string, pathSegment: string, jackpotConfigData: any) {
        const key = lobbyType + '#' + pathSegment + '#';
        this.jackpotConfigData.set(key, jackpotConfigData);
    }

    getGlobalJackpotConfigData(key: string) {
        return this.jackpotConfigData.get(key);
    }
    setJackpotTeaserContentMap(lobbyType: string, pathSegment: string, siteCoreData: any) {
        let key = lobbyType + '#' + pathSegment + '#';
        this.jackpotTeaserData.set(key, siteCoreData);
    }
    getJackpotTeaserContentMap(key: string) {
        return this.jackpotTeaserData.get(key);
    }
    setGlobalJackpotSharedContentData(lobbyType: string, pathSegment: string, jackpotSharedContentData: any) {
        let key = lobbyType + '#' + pathSegment + '#';
        this.jackpotSharedContentData?.set(key, jackpotSharedContentData);
    }
    getGlobalJackpotSharedContentData(key: string) {
        return this.jackpotSharedContentData?.get(key);
    }
    setGlobalJackpotLobbyTypeMap(lobbyType: string, pathSegment: string, lobbyData: any) {
        const key = lobbyType + '#' + pathSegment + '#';
        this.lobbyData.set(key, lobbyData);
        this.casinoLobbyService.setGlobalJackpotLobbyTypeMap(key, lobbyData);
    }

    getGlobalJackpotLobbyDataFromLocalStorage(key: string) {
        const lobbyData: any = this.casinoLobbyService.getGlobalJackpotLobbyDataFromLocalStorage(key);
        return lobbyData;
    }

    setHistoryFeedData(HistoryFeedData: any, lobbyType: string, pathSegment: string) {
        const key = lobbyType + '#' + pathSegment + '#';
        this.historyFeedData.set(key, HistoryFeedData);
    }
    getHistoryFeedData(key: string) {
        return this.historyFeedData.get(key);
    }

    setDisabledGames(DisabledGames: any) {
        this.casinoLobbyService.setDisabledGames(DisabledGames);
    }

    setDefaultTandCData(lobbyType: string, pathSegment: string, defaultTandCData: any) {
        const key = lobbyType + '#' + pathSegment + '#';
        this.defaultTandCData.set(key, defaultTandCData);
        this.defaultTandCDataSubject.next(defaultTandCData);
    }

    getDefaultTandCData(key: string) {
        return this.defaultTandCData.get(key);
    }
}
