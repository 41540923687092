import { NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { ConfigProviderService, GifTagComponent, IntersectionObserverDirective, TeaserService } from '@casinocore/platform/core';
import { CloudflareStreamComponent, CloudflareStreamModule } from '@cloudflare/stream-angular';
import { Logger } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cc-opt-teaser-video-npm',
    templateUrl: 'opt-teaser-video-npm.component.html',
    standalone: true,
    imports: [NgIf, IntersectionObserverDirective, NgClass, NgStyle, CloudflareStreamModule, GifTagComponent],
})
export class OptTeaserVideoNpmComponent implements OnInit, OnChanges, OnDestroy {
    @Input() activeSlides: any;
    @Input() url: string;
    @Input() type: string;
    @Input() imageThumbnail: any;
    @Input() subcategory: string;
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    @Input() teaserHeight: number;
    @Input() realIndex: number;
    @Input() identifierClass: string;
    @Input() isHover: boolean;
    @Input() teaserVideoAlignment: string;
    @Input() teaserVideoPosition: number;
    @Input() isOptMiniTeasersEnabled: boolean;
    @Input() isMobileImage: boolean;
    //added for NPM
    @Input() isNPM: boolean;
    @Input() globalTeaserSettings: any;
    //added for NPM
    @ViewChild('teaserVideoPlayer') videoPlayer: ElementRef;
    @ViewChild('teaserCMSVideoPlayer') cmsVideoPlayer: CloudflareStreamComponent;
    @Output() onVideoLoadedEvent: EventEmitter<any> = new EventEmitter();
    @Output() onVideoEndEvent: EventEmitter<any> = new EventEmitter();
    @Output() videoErrorEvent: EventEmitter<any> = new EventEmitter();
    @Output() onTimeChangeEvent: EventEmitter<any> = new EventEmitter();
    TeaserVideoIconBgClr: string = '';
    TeaserVideoIconClr: string = '';
    TeaserVideoVolumbtn: any = '';
    videoBackgroundClass: string = '';
    imageThumbnailSrc: string;
    isVideoLoaded: boolean;
    activeIndex: number;
    mutedStatus: boolean = true;
    teaserMutedSubscription: Subscription;
    indexChangedSubscription: Subscription;
    video: HTMLVideoElement;

    constructor(
        private configProviderService: ConfigProviderService,
        private teaserService: TeaserService,
        private logger: Logger,
    ) {}

    ngOnInit() {
        this.activeIndex = this.activeSlides[this.realIndex]?.pop();
        this.teaserVideoPosition = this.isMobileImage ? this.teaserVideoPosition : 0;
        this.videoBackgroundClass = this.isMobileImage ? 'video-bg-' + this.teaserVideoAlignment : '';
        this.teaserMutedSubscription = this.teaserService.teaserMutedObservable.subscribe((value: any) => {
            if (this.video) {
                if (value?.identifierClass == this.identifierClass && value?.realIndex == this.realIndex) {
                    this.mutedStatus = !!value?.isMuted;
                    this.video.muted = this.mutedStatus;
                } else if (value?.muteAllVideos) {
                    this.video.muted = value.muteAllVideos;
                    this.assignMuteButtonClass();
                }
            }
        });
        this.indexChangedSubscription = this.teaserService.indexChangedObservable.subscribe((value: any) => {
            if (this.video && value?.identifierClass == this.identifierClass) {
                if (value?.realIndex == this.realIndex && value?.activeIndex == this.activeIndex) {
                    this.video.currentTime = 0;
                    this.video.play().catch((e) => e);
                } else {
                    this.video.pause();
                }
            }
        });
        this.dataBindings();
    }

    ngOnChanges() {
        this.imageThumbnailSrc = this.imageThumbnail?.src || '';
    }

    ngOnDestroy() {
        if (this.teaserMutedSubscription) {
            this.teaserMutedSubscription.unsubscribe();
        }
        if (this.indexChangedSubscription) {
            this.indexChangedSubscription.unsubscribe();
        }
    }

    videoLoaded() {
        if (!this.isVideoLoaded) {
            this.isVideoLoaded = true;
            this.video = this.type === 'cms' ? this.cmsVideoPlayer?.streamEl?.nativeElement : this.videoPlayer?.nativeElement;
            if (this.video) {
                this.video.muted = true;
                this.assignMuteButtonClass();
                this.realIndex == 0 && this.activeIndex == 1 ? this.video.play().catch((e) => e) : this.video.pause();
            }
        }
        this.onVideoLoadedEvent.emit();
    }

    videoEnded() {
        this.isHover ? this.video.play().catch((e) => e) : this.onVideoEndEvent.emit();
    }

    videoError() {
        this.video.pause();
        this.videoErrorEvent.emit();
    }

    timeUpdate() {
        if (this.video) {
            const percentage = (this.video.currentTime / this.video.duration) * 100;
            if (percentage <= 100) this.onTimeChangeEvent.emit(percentage);
        }
    }

    toggleMute(event: any) {
        event.stopImmediatePropagation();
        if (this.video) {
            this.video.muted = !this.video.muted;
            this.mutedStatus = this.video.muted;
            this.assignMuteButtonClass();
            this.teaserService.muteVideo(this.identifierClass, this.realIndex, this.mutedStatus);
        }
    }

    assignMuteButtonClass() {
        const attributes: any = this.isNPM ? this.globalTeaserSettings.sharedList : this.configProviderService.provideGlobalConfig()?.messages;
        this.TeaserVideoVolumbtn = attributes[this.video?.muted ? 'TeaserVideoMuteicon' : 'TeaserVideoSoundicon'];
    }

    dataBindings() {
        const attributes: any = this.isNPM ? this.globalTeaserSettings.sharedList : this.configProviderService.provideGlobalConfig()?.messages;
        this.TeaserVideoIconBgClr = attributes?.messages?.TeaserVideoIconBgClr;
        this.TeaserVideoIconClr = attributes?.messages?.TeaserVideoIconClr;
    }

    onVisibilityChanged(videoElementIntersect: any) {
        try {
            if (this.video) {
                if (videoElementIntersect?.status == 'Visible') {
                    this.video.muted = this.mutedStatus;
                    this.assignMuteButtonClass();
                } else {
                    this.video.muted = true;
                }
            }
        } catch (err) {
            this.logger.error('Exception in onVisibilityChanged function of cc-opt-teaser-video component: ' + err);
        }
    }
}
