import { Injectable } from '@angular/core';

import { NativeAppService, NavigationService } from '@frontend/vanilla/core';

import { InvokerProductService } from './invoker-product.service';

@Injectable({
    providedIn: 'root',
})
export class CasinoWidgetService {
    product: string | null;
    currentWidgetHeight: number;
    newWidgetHeight: number;
    interval: any;
    maxHeightCount: number;
    constructor(
        private nativeAppService: NativeAppService,
        private navigationService: NavigationService,
        private invokerProductService: InvokerProductService,
    ) {}

    isCasinoWidget(): boolean {
        if (window.location.href.indexOf('casinowidget') > -1) {
            return true;
        }
        return false;
    }
    // NFR - Services optimization - Remove
    isfromCasino(): boolean {
        if (window.location.href.indexOf('launchEmbedded') == -1) {
            return true;
        }
        return false;
    }

    setWidgetProduct(): void {
        const invokerProduct: string = this.invokerProductService.getInvokerProduct();
        const widgetInvokerProduct: string | null = this.navigationService.location.search.get('invokerProduct');
        if (widgetInvokerProduct && widgetInvokerProduct != '') {
            this.product = widgetInvokerProduct.toUpperCase();
        } else if (invokerProduct) {
            this.product = invokerProduct;
        } else {
            this.product = null;
        }
    }

    getApiParams(params?: any): any {
        if (this.product) {
            const invokerProduct: string = params && params.invokerProduct && params.invokerProduct.length > 0 ? params.invokerProduct : this.product;
            if (params) {
                params.invokerProduct = invokerProduct;
            } else {
                params = {
                    invokerProduct: invokerProduct,
                };
            }
        }
        return params;
    }

    postMessageToParent(eventName: string, params: any): void {
        window.parent.postMessage({ eventName: eventName, params: params }, '*');
    }

    getWidgetPosition() {
        const widgetPosition = this.navigationService.location.search.get('widgetPosition');
        if (widgetPosition) {
            return widgetPosition?.toLowerCase();
        } else {
            return '';
        }
    }

    getWidgetHeight(categoryId: string, elementId: string) {
        if (this.isCasinoWidget()) {
            const widget = document.getElementById(elementId);
            if (widget) {
                this.currentWidgetHeight = widget.scrollHeight;
                this.assigningWidgetParams(categoryId, this.currentWidgetHeight);
            }
            if (!this.interval) {
                this.interval = setInterval(() => {
                    this.checkAndUpdateWidgetHeight(categoryId, elementId);
                }, 500);
            }
        }
    }

    checkAndUpdateWidgetHeight(categoryId: string, elementId: string) {
        this.newWidgetHeight = document.getElementById(elementId)?.scrollHeight || 0;
        if (this.currentWidgetHeight === this.newWidgetHeight) {
            this.maxHeightCount--;
        }
        if (!this.maxHeightCount) {
            clearInterval(this.interval);
        }
        if (this.newWidgetHeight !== this.currentWidgetHeight) {
            this.currentWidgetHeight = this.newWidgetHeight;
            this.assigningWidgetParams(categoryId, this.currentWidgetHeight);
        }
    }

    assigningWidgetParams(categoryId: string, currentWidgetHeight: number) {
        const isFooterWidget: boolean = this.getWidgetPosition() === 'footer' ? true : false;
        if (isFooterWidget) {
            const params = {
                isFooterWidget: this.getWidgetPosition() ? true : false,
                categoryid: categoryId,
                height: currentWidgetHeight + 30,
            };
            this.postMessageToParent('FooterLobbyLoaded', params);
        } else {
            const params = {
                isFooterWidget: this.getWidgetPosition() ? true : false,
                categoryid: categoryId,
                height: currentWidgetHeight + 30,
            };
            this.postMessageToParent('LobbyLoaded', params);
        }
    }

    getWidgetProduct(): string {
        let widgetInvokerProduct: string = this.invokerProductService.getInvokerProduct();
        if (!widgetInvokerProduct || widgetInvokerProduct === '') {
            widgetInvokerProduct = this.navigationService.location.search.get('invokerProduct') || '';
        }
        if (this.nativeAppService.isNative) {
            widgetInvokerProduct = this.nativeAppService.product;
        }
        return widgetInvokerProduct;
    }
    // NFR - Services optimization - Remove
    setInvokerProduct(invokerProduct: string) {
        this.product = invokerProduct.toUpperCase();
    }
}
