import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

import { CasinoCoreClientConfigModule } from './casinoCore-client-config.module';

/**
 * @stable
 */
@LazyClientConfig({ key: 'csSuperButtonConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: CasinoCoreClientConfigModule,
    deps: [LazyClientConfigService],
    useFactory: superButtonClientConfigFactory,
})
export class SuperButtonClientConfig {
    enabled: boolean;
}

export function superButtonClientConfigFactory(service: LazyClientConfigService) {
    return service.get(SuperButtonClientConfig);
}
