<div class="geo-comply-error-validation-container">
    <div class="main-header">
        <div class="main-header-text">{{ geoComplyErrorHeader }}</div>
        <div class="btn-close" (click)="close()"><i class="theme-ex"></i></div>
    </div>
    <div class="geo-comply-error-validation-body">
        <div class="header-description">{{ turnOnWiFiDescription }}</div>
        <div class="description-text">
            <ul class="geo-error-list">
                <li *ngFor="let errorMessage of geoLocationErrorMessages">{{ errorMessage.message }}</li>
            </ul>
        </div>
        <div class="address-error-info">{{ addressAboveItemsDescription }}</div>
        <div>
            <button *ngIf="shouldRetryLocation" class="btn btn-primary" (click)="reCheckLocation()">
                {{ recheckLocation }}
            </button>
            <button *ngIf="!shouldRetryLocation" class="btn btn-primary" (click)="close()">
                {{ ok }}
            </button>
        </div>
    </div>
</div>
