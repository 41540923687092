import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

/**
 * @stable
 */
@LazyClientConfig({ key: 'csImmersiveLobbyConfig', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: immersiveLobbyClientConfigFactory,
})
export class ImmersiveLobbyClientConfig {
    enabled: boolean;
    lobbyType: string;
    defaultCategoryId: string;
    loadingConfig: any;
    onboardingDataPath: string;
    mobileLandscapeConfig: any;
    mobilePortraitMaxWidth: number;
    defaultRenderer: string;
    fallbackRenderer: string;
    fallbackForIpad: boolean;
    idleTimeout: number;
    setMapAdjustedTimeout: number;
    gameLaunchUri: string;
    statCategoriesDataPath: string;
    statItemsDataPath: string;
    statsDataPath: string;
    pauseLottieAnimations: boolean;
    enableAudioButton: boolean;
    enableStats: boolean;
    chatWindowEvents: object;
    enableGameNameMapping: boolean;
    enableSupportForLandscape: boolean;
    scheduleGames: Array<string>;
    triviaGameType: string;
    noOfHours: number;
    tiltingPhoneMessageImageUrl: string;
    tiltingMessage: string;
    jackpotGroupName: string;
    enableGSPreloginPopup: boolean;
}
export function immersiveLobbyClientConfigFactory(service: LazyClientConfigService) {
    return service.get(ImmersiveLobbyClientConfig);
}
