<div
    class="casino-qi-freespins-sec cBoth"
    [style.font-family]="freeSpinsFont"
    [style.color]="fontColor"
    *ngFor="let rewardData of rewardDetails; let i = index"
    (click)="moreBoxInfoClick($event, i + 1)">
    <div class="freespins-info fLeft" [style.background-color]="quickFreeSpinsBoxColor">
        <!-- <div class="spin-count-sec fLeft" [style.border-color]="freeSpinsCountCircleColor"
            [style.color]="freeSpinsCountCircleColor">{{rewardData.availableCount}}</div> -->
        <div class="newspin-count-sec fLeft">
            <circle-progress
                [percent]="calculateRewardPercent(rewardData)"
                [radius]="20"
                [titleColor]="freeSpinsCountCircleColor"
                [outerStrokeColor]="freeSpinsCountCircleColor"
                [innerStrokeColor]="'#808080'"
                [animation]="false"
                [clockwise]="false"
                [title]="rewardData.availableCount">
            </circle-progress>
        </div>

        <div class="content-sec fLeft">
            <div class="spins-left">{{ ofText }} {{ rewardData.totalCount }} {{ this.freeSpinsRemainingText }}</div>
            <div class="amount">
                <span class="amt-color" [style.color]="freeSpinsCountCircleColor">{{
                    rewardData.winAmountSoFar == null
                        ? (0 | currency: rewardData.currency)
                        : (rewardData.winAmountSoFar / 100 | currency: rewardData.currency)
                }}</span>
                {{ this.totalAmountText }}
            </div>
            <div class="tooltip-sec fLeft" [style.background-color]="quickExpiryDetailsBoxColor">
                <!-- <span class="theme-arrow-down" [style.color]="quickExpiryDetailsBoxColor"></span> -->
                <span>{{ expiryDateText }}: {{ rewardData.expiryDate | timezoneformat }} </span>
            </div>
        </div>

        <div class="link-sec fRight">
            <a (click)="moreInfoClick($event, i + 1)" [style.color]="fontColor">{{ this.moreInfoText }}</a
            ><span class="theme-right"></span>
        </div>
    </div>
</div>
