import { ModuleWithProviders, NgModule } from '@angular/core';

import { ClientConfigService } from '@frontend/vanilla/core';

import * as models from './casino-client-config.models';

@NgModule()
export class CasinoClientConfigModule {
    static forRoot(): ModuleWithProviders<CasinoClientConfigModule> {
        return {
            ngModule: CasinoClientConfigModule,
            providers: [
                { provide: models.CasinoConfigClientConfig, deps: [ClientConfigService], useFactory: casinoConfigFactory },
                { provide: models.ClientSideConfigClientConfig, deps: [ClientConfigService], useFactory: clientSideConfigFactory },
                { provide: models.FeaturesClientConfig, deps: [ClientConfigService], useFactory: featuresFactory },
                { provide: models.GlobalClientConfig, deps: [ClientConfigService], useFactory: globalFactory },
                { provide: models.HeaderTeasersClientConfig, deps: [ClientConfigService], useFactory: headerTeasersFactory },
                { provide: models.JackpotDataClientConfig, deps: [ClientConfigService], useFactory: jackpotDataFactory },
                { provide: models.LiveDealerConfigClientConfig, deps: [ClientConfigService], useFactory: liveDealerConfigFactory },
                { provide: models.McPageClientConfig, deps: [ClientConfigService], useFactory: mcPageFactory },
                { provide: models.OptimizedImageLoadingClientConfig, deps: [ClientConfigService], useFactory: optimizedImageLoadingFactory },
                { provide: models.PromotionsInfoClientConfig, deps: [ClientConfigService], useFactory: promotionsInfoFactory },
                { provide: models.TransactionsConfigClientConfig, deps: [ClientConfigService], useFactory: transactionsConfigFactory },
                { provide: models.McMetaTagsClientConfig, deps: [ClientConfigService], useFactory: mcMetaTagsFactory },
                { provide: models.LMTDataClientConfig, deps: [ClientConfigService], useFactory: lmtDataFactory },
                { provide: models.GameConfigClientConfig, deps: [ClientConfigService], useFactory: gameConfigFactory },
                { provide: models.DevSettingsConfig, deps: [ClientConfigService], useFactory: devSettingsConfigFactory },
                { provide: models.GeolocationClientConfig, deps: [ClientConfigService], useFactory: geolocationConfigFactory },
                { provide: models.NativeGameLaunchClientConfig, deps: [ClientConfigService], useFactory: nativegamelaunchConfigFactory },
                { provide: models.WidgetConfigClientConfig, deps: [ClientConfigService], useFactory: widgetConfigClientConfigFactory },
                { provide: models.ThumbnailConfigClientConfig, deps: [ClientConfigService], useFactory: thumbnailConfigClientConfigFactory },
                { provide: models.LabelHostHomePageClientConfig, deps: [ClientConfigService], useFactory: LabelHostHomePageClientConfigFactory },
                { provide: models.CasinoAsIFrameOverlayConfig, deps: [ClientConfigService], useFactory: casinoAsIFrameOverlayConfigFactory },
                { provide: models.GlobalSearchClientConfig, deps: [ClientConfigService], useFactory: globalSearchConfigfactory },
                { provide: models.CasinoGlobalSearchClientConfig, deps: [ClientConfigService], useFactory: casinoglobalSearchConfigfactory },
                { provide: models.SubNavigationClientConfig, deps: [ClientConfigService], useFactory: subNavigationFactory },
                { provide: models.GlobalJackpotClientConfig, deps: [ClientConfigService], useFactory: GlobalJackpotConfigFactory },
                { provide: models.AdvanceGameDataClientConfig, deps: [ClientConfigService], useFactory: advanceGameDataConfigClientConfigFactory },
                { provide: models.CozyBingoClientConfig, deps: [ClientConfigService], useFactory: cozyBingoConfigFactory },
                { provide: models.OptimizedTeaserClientConfig, deps: [ClientConfigService], useFactory: optimizedTeaserfactory },
                { provide: models.FeedCacheClientConfig, deps: [ClientConfigService], useFactory: feedCacheFactory },
                { provide: models.PromotedGamesClientConfig, deps: [ClientConfigService], useFactory: PromotedgamesConfigFactory },
                { provide: models.RecentlyPlayedClientConfig, deps: [ClientConfigService], useFactory: RecentlyPlayedConfigFactory },
                { provide: models.FreeSpinsClientConfig, deps: [ClientConfigService], useFactory: freeSpinsFactory },
                { provide: models.PromotedGamesClientConfig, deps: [ClientConfigService], useFactory: PromotedgamesConfigFactory },
                { provide: models.ComponentCompositionsClientConfig, deps: [ClientConfigService], useFactory: ComponentCompositionsConfigFactory },
                { provide: models.TagManagerClientConfig, deps: [ClientConfigService], useFactory: TagManagerConfigFactory },
                { provide: models.ImageLoadOptimizationClientConfig, deps: [ClientConfigService], useFactory: ImageLoadOptimizationConfigFactory },
                { provide: models.GamingStoriesClientConfig, deps: [ClientConfigService], useFactory: GamingStoriesConfigFactory },
                // { provide: models.PreRenderClientConfig, deps: [ClientConfigService], useFactory: PreRenderConfigFactory }
            ],
        };
    }
}

export function casinoConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.CasinoConfigClientConfig);
}

export function clientSideConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.ClientSideConfigClientConfig);
}

export function featuresFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.FeaturesClientConfig);
}

export function globalFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.GlobalClientConfig);
}

export function headerTeasersFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.HeaderTeasersClientConfig);
}

export function jackpotDataFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.JackpotDataClientConfig);
}

export function liveDealerConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.LiveDealerConfigClientConfig);
}

export function mcPageFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.McPageClientConfig);
}

export function optimizedImageLoadingFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.OptimizedImageLoadingClientConfig);
}

export function promotionsInfoFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.PromotionsInfoClientConfig);
}

export function transactionsConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.TransactionsConfigClientConfig);
}
export function widgetConfigClientConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.WidgetConfigClientConfig);
}

export function thumbnailConfigClientConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.ThumbnailConfigClientConfig);
}

export function mcMetaTagsFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.McMetaTagsClientConfig);
}

export function lmtDataFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.LMTDataClientConfig);
}

export function gameConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.GameConfigClientConfig);
}

export function devSettingsConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.DevSettingsConfig);
}
export function geolocationConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.GeolocationClientConfig);
}

export function nativegamelaunchConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.NativeGameLaunchClientConfig);
}
export function casinoAsIFrameOverlayConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.CasinoAsIFrameOverlayConfig);
}
export function LabelHostHomePageClientConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.LabelHostHomePageClientConfig);
}

export function globalSearchConfigfactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.GlobalSearchClientConfig);
}

export function casinoglobalSearchConfigfactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.CasinoGlobalSearchClientConfig);
}

export function GlobalJackpotConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.GlobalJackpotClientConfig);
}
export function subNavigationFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.SubNavigationClientConfig);
}
export function advanceGameDataConfigClientConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.AdvanceGameDataClientConfig);
}

export function cozyBingoConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.CozyBingoClientConfig);
}
export function optimizedTeaserfactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.OptimizedTeaserClientConfig);
}
export function feedCacheFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.FeedCacheClientConfig);
}
export function PromotedgamesConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.PromotedGamesClientConfig);
}

export function RecentlyPlayedConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.RecentlyPlayedClientConfig);
}

export function freeSpinsFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.FreeSpinsClientConfig);
}

export function ComponentCompositionsConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.ComponentCompositionsClientConfig);
}
export function ThumbnailConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.ThumbnailConfigClientConfig);
}

export function TagManagerConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.TagManagerClientConfig);
}

export function ImageLoadOptimizationConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.ImageLoadOptimizationClientConfig);
}

export function GamingStoriesConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.GamingStoriesClientConfig);
}
