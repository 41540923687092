import { NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MessageLifetime, MessageQueueService, NavigationService } from '@frontend/vanilla/core';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { EmbeddedGameViewComponent } from '../embedded-game-view/embedded-game-view.component';
import { CasinoCoreGamelaunchService } from '../shared/gamelaunch.service';
import { CasinoCoreGameLaunchFactoryService } from '../shared/gamelaunchfactory.service';
import { TeaserService } from '../shared/teaser.service';

@Component({
    selector: 'cc-embedded-game-popup',
    templateUrl: './embedded-game-popup.component.html',
    standalone: true,
    imports: [NgIf, EmbeddedGameViewComponent],
})
export class EmbeddedGamePopupComponent implements OnInit {
    constructor(
        private teaserService: TeaserService,
        private messageQueue: MessageQueueService,
        private navigationService: NavigationService,
        @Inject(MAT_DIALOG_DATA) public gameData: any,
        private gameLaunchService: CasinoCoreGamelaunchService,
        public dialogRef: MatDialogRef<EmbeddedGamePopupComponent>,
        private gameLaunchFactoryService: CasinoCoreGameLaunchFactoryService,
        private configProviderService: ConfigProviderService,
    ) {
        this.gameLaunchFactoryService.setGameLaunchService(this.gameLaunchService);
    }

    ngOnInit(): void {
        this.teaserService.muteAllVideos();
    }
    dialogCloseEventFromEmbeddedGameView(data: any): void {
        const Global = this.configProviderService.provideGlobalConfig();
        const features = this.configProviderService.provideFeaturesConfig();
        const message = Global.messages['OnlyOneGameAtTime'];
        this.dialogRef.close();
        this.messageQueue.clear();
        setTimeout(() => {
            this.messageQueue.addError(message, MessageLifetime.Single, 'casinocorelobby');
            window.scrollTo(0, 0);
            setTimeout(() => {
                this.messageQueue.clear();
                if (
                    window.location.href.includes('games/launchng') ||
                    window.location.href.includes('slots/launchng') ||
                    window.location.href.includes('livecasino/launchng')
                ) {
                    this.navigationService.goTo(data.returnUrl);
                }
                this.gameLaunchService?.stopAudio();
            }, features.singleGameErrorTimeout);
        }, features.scrollTopTimeout);
        this.gameLaunchService.messageQueuePublisher.next({
            showMessageInBingo: true,
            message: Global.messages['OnlyOneGameAtTime'],
        });
    }
    eventFromEmbeddedGameView(data: any): void {
        const returnUrl =
            new URLSearchParams(window.location.search).get('returnUrl') || new URLSearchParams(window.location.search).get('returnurl');
        if (returnUrl) {
            this.dialogRef.close();
            this.navigationService.goTo(returnUrl);
        } else if (
            window.location.href.includes('games/launchng') ||
            window.location.href.includes('slots/launchng') ||
            window.location.href.includes('livecasino/launchng')
        ) {
            this.messageQueue.clear();
            this.dialogRef.close();
            this.navigationService.goTo(data.returnUrl);
        } else this.dialogRef.close();
    }
}
