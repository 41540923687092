import { IContentImage, IContentLink } from './common.model';

export interface IGamePremiere {
    headerText: string;
    closeCTA: IContentLink;
    sticker: string;
    overlayAnimationimage: IContentImage;
    staticPromoImage: IContentImage;
    promoVideo: string;
    enableVideo: boolean;
    subTitle1: string;
    subTitle2: string;
    mainTitle: string;
    mobileSubTitle1: string;
    mobileSubTitle2: string;
    stepsTitle: string;
    step1: string;
    steps2: string;
    steps3: string;
    additionalInfo: string;
    optinCTA: IContentLink;
    playnowCTA: IContentLink;
    moreInfoText: string;
    lessInfoText: string;
    viewDetails: IContentLink;
    moreInfoContent: string;
    styles: any;
}

export interface GamePremiereConfig {
    enableGamePremiere: boolean;
}

export class UserRequestModel {
    type: string;
    id: string;
    optIn: boolean;
    source: string;
    actionType: string;
}
