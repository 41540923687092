import { Injectable, effect } from '@angular/core';

import { CookieService, DeviceService, Logger, NativeAppService, NavigationService, UserService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable, distinctUntilChanged } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';
import { CasinoWidgetService } from './casino-widget.service';
import { InvokerProductService } from './invoker-product.service';
import { HomeLobbyModel } from './models/home-lobby.model';
import { PublishLobbyModel } from './models/publish-lobby.model';
import { NativeApiService } from './nativeapi.service';

@Injectable({
    providedIn: 'root',
})
export class CasinoManager {
    lobbyType: string;
    gameEventId: any;
    private isTouchDevice = this.checkTouchDevice();
    toggleEnabled: string;
    gameHomeUrl: string;
    get currentWidth() {
        return this.deviceService.windowRect().width;
    }
    product: string;
    headerTeasers: any;
    currentlobbyType: any;

    private loadNextGamesSetPublisher = new BehaviorSubject<any>(null);
    loadNextGamesSetObservable: Observable<any> = this.loadNextGamesSetPublisher.asObservable();

    private lobbyModelPublisher = new BehaviorSubject<HomeLobbyModel>(new HomeLobbyModel());
    lobbyObservable: Observable<HomeLobbyModel> = this.lobbyModelPublisher.asObservable();

    private reSizePublisher = new BehaviorSubject<number>(this.deviceService.windowRect().width);
    reSizeObservable = this.reSizePublisher.pipe(distinctUntilChanged());

    categoryLookup: any;
    lobbyModel: HomeLobbyModel = new HomeLobbyModel();

    private playerSegmentionSubject = new BehaviorSubject<any>(null);
    playerSegmentDataObservable: Observable<any> = this.playerSegmentionSubject.asObservable();

    previousProduct: string;
    invokerProduct: string;
    constructor(
        private api: PlatformApiService,
        private deviceService: DeviceService,
        private user: UserService,
        private casinoWidgetService: CasinoWidgetService,
        private cookieService: CookieService,
        private nativeAppService: NativeAppService,
        private nativeApiService: NativeApiService,
        private navigation: NavigationService,
        private configProviderService: ConfigProviderService,
        private invokerProductService: InvokerProductService,
        private logger: Logger,
    ) {
        effect(() => {
            this.reSizePublisher.next(this.deviceService.windowRect().width);
        });
    }
    setCategoryLobby(publishCategory: PublishLobbyModel) {
        const sourceCategory = this.navigation.location;
        const publishCtgry = publishCategory.category?.toLowerCase();
        let defCategory: any;
        if (this.deviceService.windowRect().width > 500) {
            if (
                publishCtgry != defCategory &&
                (sourceCategory.pathname.indexOf(defCategory) > -1 || sourceCategory.pathname === '/' || sourceCategory.pathname == '/en/games')
            ) {
                document.documentElement.style.setProperty('--isNewsFeedDisplayed', 'none');
            }
            if (publishCtgry == defCategory && !(sourceCategory.pathname.indexOf(defCategory) > -1)) {
                document.documentElement.style.setProperty('--isNewsFeedDisplayed', 'none');
            }
        } else {
            document.documentElement.style.setProperty('--isNewsFeedDisplayed', 'none');
        }
        this.lobbyModel.activeCategory = publishCategory.category;
        this.lobbyModel.navigationLevel = publishCategory.navigationLevel;
        if (this.previousProduct !== publishCategory.product) {
            this.previousProduct = publishCategory.product;
            this.lobbyModel.currentCategory = null;
        }
        this.lobbyModelPublisher.next(this.lobbyModel);
    }

    setWidgetToggleInfo(enableToggle: string) {
        this.toggleEnabled = enableToggle;
    }
    getWidgetToggleInfo() {
        return this.toggleEnabled;
    }

    getActiveCategory() {
        return this.lobbyModel.activeCategory;
    }

    getLobbyType() {
        let lobbyType: string = this.cookieService.get('LobbyType');
        const lmtConfig: any = this.configProviderService.provideLmtConfig();
        if (lobbyType) {
            return lobbyType;
        } else if (lmtConfig.lobbyTypeMap) {
            lobbyType = lmtConfig.lobbyTypeMap[0].lobbyType;
            return lobbyType;
        } else {
            return null;
        }
    }

    getProductForLobbyType(lobbyType: string): string {
        const lmtConfig: any = this.configProviderService.provideLmtConfig();
        const lobbyTypeMap: any = lmtConfig.lobbyTypeMap;
        if (lobbyTypeMap) {
            for (let i = 0; i < lobbyTypeMap.length; i++) {
                if (lobbyTypeMap[i].lobbyType == lobbyType) {
                    return lobbyTypeMap[i].product;
                }
            }
        } else {
            return '';
        }
        return '';
    }

    private checkTouchDevice(): boolean {
        try {
            return (
                !this.nativeAppService.isDownloadClient &&
                this.deviceService.isTouch &&
                !window.matchMedia('(pointer: fine)').matches &&
                !navigator.userAgent.match(/Win64/ || /WOW64/)
            );
        } catch (error) {
            return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        }
    }

    isTouch(): boolean {
        return this.isTouchDevice;
    }

    setGameHomeUrl(gameHomeUrl: string) {
        this.gameHomeUrl = gameHomeUrl;
    }
    getGameHomeUrl(): string {
        return this.gameHomeUrl;
    }

    loadNextGamesSet() {
        this.loadNextGamesSetPublisher.next(true);
    }

    getCurrentWidth() {
        return this.currentWidth;
    }

    getDefaultCategoryType1(): string {
        const casinoConfig = this.configProviderService.provideCasinoConfig();
        const defaultCategory = casinoConfig.defaultCategory;

        if (this.categoryLookup && this.categoryLookup.filter((c: any) => c.sitecoreData.categoryId === defaultCategory)[0]) {
            return defaultCategory;
        }

        return this.categoryLookup[0] ? this.categoryLookup[0].sitecoreData.categoryId : undefined;
    }

    getDefaultCategoryType(): string {
        const casinoConfig = this.configProviderService.provideCasinoConfig();
        return casinoConfig.defaultCategory;
    }

    goToLobby() {
        const mcPage = this.configProviderService.provideMcPageConfig();
        if (this.nativeAppService.isNative && this.nativeAppService.product === 'CASINO') {
            if (this.gameEventId) {
                this.nativeApiService.clearEvent(this.gameEventId);
            }

            this.gameEventId = this.nativeApiService.raiseEventCommon('?event=promo&action=openlobbytab');

            return;
        }
        this.navigation.goTo(mcPage?.prefix);
    }

    setPlayerSegmentationData() {
        const features = this.configProviderService.provideFeaturesConfig();
        if (this.user.isAuthenticated && features.enableOptimoveFavProduct) {
            const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
            this.api.get(apiPathsConfig.playerSegmentation['playerData']).subscribe(
                (response) => {
                    this.playerSegmentionSubject.next(response);
                },
                () => {
                    this.logger.error('unable to retrieve the data from playerData API');
                },
            );
        }
    }

    getLobbyUrlPrefix(lobbyType: string = ''): string {
        if (!this.lobbyType) {
            this.lobbyType = lobbyType;
        }
        const lmtDataClientConfig = this.configProviderService.provideLmtConfig();
        for (let i = 0; i < lmtDataClientConfig.lobbyTypeMap.length; i++) {
            if (lmtDataClientConfig.lobbyTypeMap[i].lobbyType === lobbyType) {
                return lmtDataClientConfig.lobbyTypeMap[i].product?.toLowerCase();
            }
        }
        return '';
    }
    getProduct(): string {
        const lmtDataClientConfig = this.configProviderService.provideLmtConfig();
        if (!this.product && lmtDataClientConfig.lobbyTypeMap) {
            this.product = lmtDataClientConfig.lobbyTypeMap[0].product;
        }
        return this.product;
    }

    getProductForGivenLobbyType(lobbyType: string): string {
        const lmtDataClientConfig = this.configProviderService.provideLmtConfig();
        const lobbyTypeMap: any = lmtDataClientConfig.lobbyTypeMap;
        for (let i = 0; i < lobbyTypeMap.length; i++) {
            if (lobbyTypeMap[i].lobbyType == lobbyType) {
                if (lobbyTypeMap[i].product) return lobbyTypeMap[i]?.product?.toLowerCase();
            }
        }
        return '';
    }

    IsFeatureEnabledforLobbytype(lobbyType: string, feature: any): any {
        if (feature && lobbyType) {
            const featureType = feature.find((x: any) => x?.lobbyType?.toLowerCase() == lobbyType?.toLowerCase());
            if (featureType && featureType.countValue) {
                return featureType.countValue;
            }
            if (featureType && featureType.value) {
                return true;
            }
            if (featureType && featureType.stringValue) {
                return featureType.stringValue;
            }
            if (featureType && featureType.stringArray && featureType.stringArray.length > 0) {
                return featureType.stringArray;
            }
            if (featureType && featureType.jsonValue) {
                return featureType.jsonValue;
            }
            if (featureType && featureType.defaultCategory) {
                return featureType.defaultCategory;
            }
            if (featureType && featureType.forceMobileNav) {
                return featureType.forceMobileNav;
            }
            if (featureType && featureType.fetchMeta) {
                return featureType.fetchMeta;
            }
            return false;
        }
        return false;
    }

    IsFeatureEnabledforLobbytypeWithInvokerProduct(lobbyType: string, feature: any): any {
        if (feature && feature[0].invokerProduct) {
            let invokerproduct: string = this.getInvokerProduct();
            if (!invokerproduct || invokerproduct === '') {
                invokerproduct = 'casino';
            }
            feature = feature.filter((x: any) => x?.invokerProduct?.toLowerCase() == invokerproduct?.toLowerCase());
        }
        if (!lobbyType || lobbyType === '') {
            lobbyType = this.cookieService.get('LobbyType');
        }
        if (feature && lobbyType) {
            const featureType = feature.find((x: any) => x?.lobbyType?.toLowerCase() == lobbyType?.toLowerCase());
            if (featureType && featureType.countValue) {
                return featureType.countValue;
            }
            if (featureType && featureType.value) {
                return true;
            }
            if (featureType && featureType.stringValue) {
                return featureType.stringValue;
            }
            if (featureType && featureType.stringArray && featureType.stringArray.length > 0) {
                return featureType.stringArray;
            }
            return false;
        }
        return false;
    }

    assignGameClass(currentWidth: any, iconType: any): any {
        if (currentWidth < 383) {
            switch (iconType) {
                case '1':
                    return 'xs-icon';

                case '2':
                    return 'medium-icon';

                case '3':
                    return 'xs-icon';

                case '4':
                    return 'medium-icon';

                case '5':
                    return 'large-icon';

                case '6':
                    return 'xs-icon';

                case '7':
                    return 'xs-icon';
                case '10':
                    return 'medium-icon';
                default:
                    return 'xs-icon';
            }
        } else if (currentWidth < 480) {
            switch (iconType) {
                case '1':
                case '2':
                case '3':
                    return 'small-icon';

                case '4':
                case '5':
                    return 'medium-icon';

                case '6':
                case '7':
                    return 'small-icon';

                case '10':
                    return 'medium-icon';

                default:
                    return 'small-icon';
            }
        } else if (currentWidth < 533) {
            switch (iconType) {
                case '1':
                    return 'xs-icon';

                case '2':
                    return 'medium-icon';

                case '3':
                    return 'xs-icon';

                case '4':
                    return 'medium-icon';

                case '5':
                    return 'large-icon';

                case '6':
                    return 'xs-icon';

                case '7':
                    return 'xs-icon';

                case '10':
                    return 'medium-icon';

                default:
                    return 'xs-icon';
            }
        } else if (currentWidth < 625) {
            switch (iconType) {
                case '1':
                    return 'small-icon';

                case '2':
                    return 'medium-icon';

                case '3':
                    return 'small-icon';

                case '4':
                    return 'large-icon';

                case '5':
                    return 'large-icon';

                case '6':
                    return 'small-icon';

                case '7':
                    return 'small-icon';

                case '10':
                    return 'large-icon';

                default:
                    return 'small-icon';
            }
        } else if (currentWidth < 689) {
            switch (iconType) {
                case '1':
                    return 'xs-icon';

                case '2':
                    return 'medium-icon';

                case '3':
                    return 'xs-icon';

                case '4':
                    return 'medium-icon';

                case '5':
                    return 'large-icon';

                case '6':
                    return 'xs-icon';

                case '7':
                    return 'xs-icon';

                case '10':
                    return 'medium-icon';

                default:
                    return 'xs-icon';
            }
        } else if (currentWidth < 768) {
            switch (iconType) {
                case '1':
                    return 'small-icon';

                case '2':
                    return 'medium-icon';

                case '3':
                    return 'small-icon';

                case '4':
                    return 'large-icon';

                case '5':
                    return 'large-icon';

                case '6':
                    return 'small-icon';

                case '7':
                    return 'small-icon';

                case '10':
                    return 'large-icon';

                default:
                    return 'small-icon';
            }
        } else if (currentWidth < 1024) {
            switch (iconType) {
                case '1':
                    return 'xs-icon';

                case '2':
                    return 'medium-icon';

                case '3':
                    return 'small-icon';

                case '4':
                    return 'medium-icon';

                case '5':
                    return 'medium-icon';

                case '6':
                    return 'xs-icon';

                case '7':
                    return 'xs-icon';

                case '10':
                    return 'medium-icon';

                default:
                    return 'small-icon';
            }
        } else {
            switch (iconType) {
                case '1':
                    return 'small-icon';

                case '2':
                    return 'large-icon';

                case '3':
                    return 'small-icon';

                case '4':
                    return 'large-icon';

                case '5':
                    return 'large-icon';

                case '6':
                    return 'small-icon';

                case '7':
                    return 'small-icon';

                case '10':
                    return 'large-icon';

                default:
                    return 'small-icon';
            }
        }
    }

    getInvokerProduct(): string {
        return this.casinoWidgetService.getWidgetProduct();
    }

    getSlotRaceCategoryId(lobbyType: string): string {
        const slotRacesConfig = this.configProviderService.provideSlotRacesConfig();
        for (let i = 0; i < slotRacesConfig.slotRacesLMTCategoryIdForLobbyType.length; i++) {
            if (slotRacesConfig.slotRacesLMTCategoryIdForLobbyType[i].lobbyType === lobbyType) {
                return slotRacesConfig.slotRacesLMTCategoryIdForLobbyType[i].stringValue;
            }
        }
        return '';
    }

    getLobbyTypeForProduct(productRoute: string): string {
        const lmtDataClientConfig = this.configProviderService.provideLmtConfig();
        if (lmtDataClientConfig && lmtDataClientConfig.lobbyTypeMap && lmtDataClientConfig.lobbyTypeMap.length > 0) {
            let invokerProduct: string = this.invokerProductService.getInvokerProduct();
            if (!invokerProduct || invokerProduct === '') {
                invokerProduct = 'CASINO';
            }
            const productLobbyTypes: any = lmtDataClientConfig.lobbyTypeMap.filter((lobbytype: any) => {
                return lobbytype?.invokerProduct?.toLowerCase() === invokerProduct?.toLowerCase();
            });
            if (productLobbyTypes && productLobbyTypes.length > 0) {
                return this.extractLobbyType(productLobbyTypes, productRoute);
            } else {
                this.logger.error(
                    'No lobby types configured for invoker product::',
                    invokerProduct,
                    ':: Considering all configured values for product route.',
                );
                return this.extractLobbyType(lmtDataClientConfig.lobbyTypeMap, productRoute);
            }
        }
        this.logger.error('Lobby Type Map values not configured.');
        return '';
    }

    setCurrentLobby(lobbyType: string) {
        this.currentlobbyType = lobbyType;
    }

    getCurrentLobby() {
        return this.currentlobbyType;
    }
    private extractLobbyType(lobbyTypeMap: any, product: string): string {
        if (lobbyTypeMap && lobbyTypeMap.length > 0) {
            for (let i = 0; i < lobbyTypeMap.length; i++) {
                if (lobbyTypeMap[i]?.product?.toLowerCase() == product?.toLowerCase()) {
                    return lobbyTypeMap[i].lobbyType;
                }
            }
            this.logger.error('No lobby type found for product route::', product);
        }
        return '';
    }

    isSingleHostLobbyType(lobbyType: string, currentProduct: string): boolean {
        let singleHostLobbyItem: any = null;
        let singleHostLobbyMap: any = this.configProviderService.provideGameConfig()?.singleHostLobbyMap;
        if (singleHostLobbyMap) {
            singleHostLobbyItem = singleHostLobbyMap.filter((sh: any) => {
                return currentProduct && sh?.lobbyType?.toLowerCase() == lobbyType?.toLowerCase();
            });
        }
        return singleHostLobbyItem?.length && singleHostLobbyItem[0].singleHost ? true : false;
    }
}
