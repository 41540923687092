import { NgIf, NgSwitch } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { DeviceService } from '@frontend/vanilla/core';
import { timer } from 'rxjs';

import { NgxCarousel3dService } from './ngx-carousel-3d.service';

@Component({
    selector: 'cc-ngx-carousel-3d',
    templateUrl: 'ngx-carousel-3d.component.html',
    standalone: true,
    imports: [NgSwitch, NgIf],
})
export class NgxCarousel3dComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('corousel3dcontainer') carousel3dcontainer: ElementRef;
    @ViewChild('carousel3D') carousel3DnatEle: ElementRef;
    @Input() options: any;
    @Input() slides: Array<Object>;
    @Input() onBeforeChange: Function;
    @Input() onLastSlide: Function;
    @Input() onSlideChange: Function;
    @Input() onSelectedClick: Function;
    @Input() category: any;
    autoRotationLocked: boolean;
    imageLocationSubscription: any;
    autoRotation: any = null;
    public isLoading = true;
    public isSuccessful = false;
    public isRendered = false;
    public percentLoaded = 0;
    public controls: any;
    private percentSubscription: any;
    private carousel3d: any;
    private $wrapper: HTMLDivElement;
    private $slides: HTMLCollection;
    private $carouselService: NgxCarousel3dService;
    private $timer: any;
    private carousel3dcontainerDivElement: HTMLDivElement;

    constructor(private deviceService: DeviceService) {}

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        if (this.percentSubscription) {
            this.percentSubscription.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['slides'] && changes['options']) {
            this.buildCarousel();
        } else if (changes['slides']) {
            this.buildCarousel();
        } else if (changes['options']) {
            this.buildCarousel();
        }
    }

    ngOnInit() {
        this.buildCarousel();
    }

    buildCarousel() {
        this.$carouselService = new NgxCarousel3dService(this.slides, this.options);
        this.percentSubscription = this.$carouselService.getPercent().subscribe((sub: any) => {
            this.percentLoaded = sub.percent;
        });
        this.autoRotationLocked = false;
        return this.$carouselService.build(this.options || {}).then(
            (carousel: any) => {
                this.carousel3d = carousel;

                this.slides = this.carousel3d.slides;
                this.controls = this.carousel3d.controls;
                this.isLoading = false;
                this.isSuccessful = true;

                const outerHeight = this.carousel3d.getOuterHeight(),
                    outerWidth = this.carousel3d.getOuterWidth();

                this.carousel3dcontainerDivElement = this.carousel3dcontainer.nativeElement;
                this.carousel3dcontainerDivElement.style.height = outerHeight + 'px';
                this.$wrapper = this.carousel3DnatEle.nativeElement;
                this.$wrapper.style.width = outerWidth + 'px';
                this.$wrapper.style.height = outerHeight + 'px';
                this.$slides = this.$wrapper.children;
                Promise.resolve(this.render(true, this.carousel3d.animationSpeed)).then(() => {
                    this.animationEnd();
                });
            },
            // == Preloaded images reject  handler
            (carousel: any) => {
                this.handleReject(carousel);
            },
            // == Preloaded images notify handler which is executed multiple times during preload
            (event: any) => {
                this.handleNotify(event);
            },
        );
    }

    handleReject(carousel: any) {
        if (this) {
            this.carousel3d = carousel;
            this.isLoading = false;
            this.isSuccessful = false;
        }
        carousel.setAttribute('style', 'height:' + carousel.getOuterHeight() + 'px;');
    }

    handleNotify(event: any) {
        this.percentLoaded = event.percent;
    }

    render(animate: any, speedTime: any) {
        this.carousel3d.setSlides();

        const outerHeight = this.carousel3d.getOuterHeight(),
            outerWidth = this.carousel3d.getOuterWidth(),
            slideTop = this.carousel3d.topSpace === 'auto' ? 0 : this.carousel3d.height / 2 - outerHeight / 2,
            slideLeft = this.carousel3d.width / 2 - outerWidth / 2,
            speed = speedTime ? speedTime / 1000 : this.carousel3d.animationSpeed / 1000;
        let zIndex = 999;

        // == Set other slides styles
        this.carousel3d.slides.forEach((slide: any, index: number) => {
            let currentSlide: HTMLElement = this.getSlide(index) as HTMLElement;

            const css = {
                'position': 'absolute',
                'opacity': 0,
                'visibility': 'hidden',
                'overflow': 'hidden',
                'top': slideTop + 'px',
                'border-width': this.carousel3d.border + 'px',
                'width': outerWidth + 'px',
                'height': outerHeight + 'px',
                'display': 'block',
            };

            if (animate) {
                const css2 = {
                    ...css,
                    '-webkit-transition': 'all ' + speed + 's ',
                    '-moz-transition': 'all ' + speed + 's ',
                    '-o-transition': 'all ' + speed + 's ',
                    '-ms-transition': 'all ' + speed + 's ',
                    'transition': 'all ' + speed + 's ',
                };
                this.attachJSONCss(currentSlide, css2);
            }
            this.attachJSONCss(currentSlide, css);
        });

        // == Set first slide styles

        let jsonCss: any = {
            'zIndex': zIndex,
            'opacity': 1,
            'visibility': 'visible',
            '-webkit-transform': 'none',
            '-moz-transform': 'none',
            '-o-transform': 'none',
            '-ms-transform': 'none',
            'transform': 'none',
            'left': slideLeft + 'px',
            'top': slideTop + 'px',
            'width': outerWidth + 'px',
            'height': outerHeight + 'px',
            'display': 'block',
        };

        let slideElement = this.getSlide(this.carousel3d.currentIndex);
        slideElement!.classList.add('current');
        this.attachJSONCss(slideElement as HTMLElement, jsonCss);

        this.carousel3d.rightSlides.forEach((slide: number, index: any) => {
            const isMultiSlides = this.options?.visible === 5;
            let opacity = 0.5;
            if (isMultiSlides && index === 0) opacity = 1;

            const css = this.setCss(index, zIndex, true, isMultiSlides);
            zIndex -= index + 1;

            let rightSlideElement = this.getSlide(slide) as HTMLElement;
            this.attachJSONCss(this.attachJSONCss(rightSlideElement, css), {
                opacity: opacity,
                visibility: 'visible',
                zIndex: zIndex,
            });
        });

        this.carousel3d.leftSlides.forEach((slide: any, index: any) => {
            const isMultiSlides = this.options?.visible === 5;
            let opacity = 0.5;
            if (isMultiSlides && index === 0) opacity = 1;

            const css = this.setCss(index, zIndex, false, isMultiSlides);
            zIndex -= index + 1;

            this.attachJSONCss(this.getSlide(slide) as HTMLElement, css);
            this.attachJSONCss(this.getSlide(slide) as HTMLElement, {
                opacity: opacity,
                visibility: 'visible',
                zIndex: zIndex,
            });
        });

        if (this.carousel3d.total > this.carousel3d.visible) {
            const rCSS = this.setCss(this.carousel3d.rightSlides.length - 1, this.carousel3d.rightSlides.length - 1, true),
                lCSS = this.setCss(this.carousel3d.leftSlides.length - 1, this.carousel3d.leftSlides.length - 1, true);
            this.attachJSONCss(this.getSlide(this.carousel3d.rightOutSlide) as HTMLElement, rCSS);
            this.attachJSONCss(this.getSlide(this.carousel3d.leftOutSlide) as HTMLElement, lCSS);
        }

        this.isRendered = true;
        return true;
    }

    setCss(i: any, zIndex: any, positive: any, isMultiSlides: boolean = false) {
        let division = 1.5;
        if (isMultiSlides)
            division =
                i === 0
                    ? this.deviceService.isMobilePhone
                        ? 2.5
                        : this.deviceService.isTablet
                          ? 1.5
                          : 1.25
                    : this.deviceService.isMobilePhone
                      ? 3.5
                      : this.deviceService.isTablet
                        ? 2
                        : 1.65;

        const leftRemain = this.carousel3d.space === 'auto' ? (i + 1) * (this.carousel3d.width / division) : (i + 1) * this.carousel3d.space,
            transform = positive
                ? 'translateX(' +
                  leftRemain +
                  'px) ' +
                  (isMultiSlides ? 'translateY(-25px)' : '') +
                  ' translateZ(-' +
                  (this.carousel3d.inverseScaling + (i + 1) * 100) +
                  'px) rotateY(-' +
                  this.carousel3d.perspective +
                  'deg)'
                : 'translateX(-' +
                  leftRemain +
                  'px) ' +
                  (isMultiSlides ? 'translateY(-25px)' : '') +
                  ' translateZ(-' +
                  (this.carousel3d.inverseScaling + (i + 1) * 100) +
                  'px) rotateY(' +
                  this.carousel3d.perspective +
                  'deg)',
            left = '0%',
            top = this.carousel3d.topSpace === 'auto' ? 'none' : (i + 1) * this.carousel3d.space,
            width = 'none',
            height = 'none',
            overflow = 'visible';

        return {
            '-webkit-transform': transform,
            '-moz-transform': transform,
            '-o-transform': transform,
            '-ms-transform': transform,
            'transform': transform,
            'left': left,
            'top': top,
            'width': width,
            'height': height,
            'zIndex': zIndex,
            'overflow': overflow,
            'display': 'block',
        };
    }

    goSlide(index: number) {
        let keepChanging = false;

        if (typeof this.onBeforeChange === 'function') {
            keepChanging = this.onBeforeChange({
                index: this.carousel3d.currentIndex,
            });

            if (keepChanging === false) {
                return;
            }
        }

        this.carousel3d.setCurrentIndex(index < 0 || index > this.carousel3d.total - 1 ? 0 : index);

        if (this.carousel3d.isLastSlide()) {
            if (typeof this.onLastSlide === 'function') {
                this.onLastSlide({
                    index: this.carousel3d.currentIndex,
                });
            }
        }

        for (let slide of this.$slides) {
            slide.classList.remove('current');
        }

        this.carousel3d.setLock(true);
        Promise.resolve(this.render(true, this.carousel3d.animationSpeed)).then(() => {
            this.animationEnd();
        });
    }

    goNext() {
        if (this.carousel3d.getLock() || (!this.carousel3d.loop && this.carousel3d.isLastSlide())) {
            return false;
        }

        if (this.carousel3d.isLastSlide()) {
            this.goSlide(0);
        } else {
            this.goSlide(this.carousel3d.currentIndex + 1);
        }

        return true;
    }

    goPrev() {
        if (this.carousel3d.getLock() || (!this.carousel3d.loop && this.carousel3d.isFirstSlide())) {
            return false;
        }

        if (this.carousel3d.isFirstSlide()) {
            this.goSlide(this.carousel3d.total - 1);
        } else {
            this.goSlide(this.carousel3d.currentIndex - 1);
        }

        return true;
    }

    goFar(index: number) {
        if (index === this.carousel3d.currentIndex) {
            return;
        }

        const visibleSlides = this.carousel3d.getVisibleSlidesIndex();

        const indexInVisibleSlides = visibleSlides.findIndex((val: any) => val === index);
        if (indexInVisibleSlides < 0) {
            return;
        }
        const currentIndexInVisibleSlides = visibleSlides.findIndex((val: any) => val === this.carousel3d.currentIndex);
        if (currentIndexInVisibleSlides < 0) {
            return;
        }

        let diff = indexInVisibleSlides - currentIndexInVisibleSlides;

        if (this.carousel3d.dir === 'ltr') {
            diff = -diff;
        }

        const diff2 = Math.abs(diff);

        let timeBuff = 0;
        const timeout = this.carousel3d.animationSpeed / diff2;

        for (let i = 0; i < diff2; i++, timeBuff += timeout) {
            setTimeout(() => {
                if (diff >= 0) {
                    this.goNext();
                } else {
                    this.goPrev();
                }
            }, timeBuff);
        }
    }

    animationEnd() {
        this.carousel3d.setLock(false);

        if (typeof this.onSlideChange === 'function') {
            this.onSlideChange({
                index: this.carousel3d.currentIndex,
            });
        }
        if (!this.$timer) {
            this.subscribe();
        }
    }

    getSlide(index: number) {
        return index >= 0 ? this.$slides.item(index) : this.$slides.item(this.carousel3d.total + index);
    }

    //This method is using for old multijackpot 3d carousel slideclicked.
    slideClicked(index: number) {
        if (this.$timer) {
            this.$timer.unsubscribe();
        }

        this.$timer = null;

        if (this.carousel3d.currentIndex !== index) {
            if (this.carousel3d.clicking) {
                this.goFar(index);
            }
        } else {
            if (typeof this.onSelectedClick === 'function') {
                this.onSelectedClick({
                    index: this.carousel3d.currentIndex,
                });
            }
        }
    }
    setSlideLock(value: any) {
        this.autoRotationLocked = value;
    }
    private subscribe() {
        this.$timer = timer(this.carousel3d.autoRotationSpeed, this.carousel3d.autoRotationSpeed).subscribe(() => {
            if (!this.carousel3d.autoRotationLocked) {
                if (this.options['dir'] === 'ltr') {
                    this.goNext();
                } else {
                    this.goPrev();
                }
            }
        });
    }

    private attachJSONCss(element: HTMLElement, jsonCss: any): HTMLElement {
        if (jsonCss && element) {
            let keys: any = Object.keys(jsonCss);
            if (keys && keys.length > 0) {
                for (let i = 0; i < keys.length; i++) {
                    element.style.setProperty(keys[i], jsonCss[keys[i]]);
                }
            }
        }
        return element;
    }
}
