import { Time } from '@angular/common';
import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

import { IContentImage } from '../shared/models/common.model';

@LazyClientConfig({ key: 'csJackpotData', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: jackpotDataClientConfigFactory,
})
export class JackpotDataClientConfig {
    showOnTiles: boolean;
    jpInterval: number;
    mustGoJackpotTimerBase: Time;
    showJackpotOnGameTilesLobbyType: any;
    enableTickingForJackpotOnGameTiles: boolean;
    enableJpTickingForLobbyType: any;
    enableJpGroupTickingForLobbyType: any;
    showJackpotCurrencyOnRight: boolean;
    enableRibbonConfig: boolean;
    enableSingleJackpot: boolean;
    enableMultiJackpot: boolean;
    enableMustGoJackpot: boolean;
    jackpotWidgetConfigurations: any;
    multiJackpotLeftArrowImage: IContentImage;
    multiJackpotRightArrowImage: IContentImage;
    mustGoJackpotRightGradientImage: IContentImage;
    mustGoJackpotLeftGradientImage: IContentImage;
    multiJackpotBottomImage: IContentImage;
    jackpotGamesSortingTime: any;
    enableJackpotSortGames: boolean;
    isEnableMustgoRedesignModes: boolean;
    jackpotMoreInfoEnable: boolean;
}

export function jackpotDataClientConfigFactory(service: LazyClientConfigService) {
    return service.get(JackpotDataClientConfig);
}
