import { Injectable } from '@angular/core';

import { CasinoCoreNpmParams } from './models/casino-core-npm.params';

@Injectable({
    providedIn: 'root',
})
export class CasinoCoreNpmParamsService {
    private casinoNpmParams: CasinoCoreNpmParams;

    constructor() {}

    setcasinoCoreNpmParams(casinoCoreNpmParams: CasinoCoreNpmParams) {
        this.casinoNpmParams = casinoCoreNpmParams;
    }

    getcasinoCoreNpmParams(): CasinoCoreNpmParams {
        return this.casinoNpmParams;
    }
}
