import { Injectable } from '@angular/core';

import { TrackingService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
})
export class SlotRacesTrackingService {
    constructor(private trackingService: TrackingService) {}

    track(actionEvent: string, positionEvent: string, locationEvent: string, eventDetails: string, coinEconomyFilter: boolean = false) {
        this.trackingService?.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'Slots',
            'component.LabelEvent': coinEconomyFilter ? 'filter tab' : 'Race Cards',
            'component.ActionEvent': actionEvent,
            'component.PositionEvent': positionEvent,
            'component.LocationEvent': locationEvent,
            'component.EventDetails': eventDetails,
            'component.URLClicked': 'not applicable',
        });
    }
}
