<vn-message-panel [scope]="'gameiframelauncher'"></vn-message-panel>
<div *ngIf="iframeUrl" (window:resize)="onResize()" [ngStyle]="{ overflow: 'hidden', position: 'relative' }">
    <div class="wrapper">
        <div class="livegames_iframe" #iframeId>
            <iframe
                id="live-games"
                allowfullscreen="true"
                [style.height]="heightIframe + 'vw'"
                [style.width]="widthIframe + 'px'"
                frameborder="0"
                scrolling="yes"
                [src]="iframeUrl">
                Preview not available</iframe
            >
        </div>
    </div>
</div>
