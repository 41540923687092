<!-- <h1 mat-dialog-title>Hi {{data.name}}</h1> -->
<div mat-dialog-content class="cashier-iframe-content embed-overlay-popup">
    <div class="cashier-iframe">
        <div mat-dialog-actions class="iframe-close iframe-theme-bg-popup">
            <button mat-button (click)="onNoClick()">
                <span class="theme-ex"></span>
            </button>
        </div>
        <iframe
            id="casinocashieriframe"
            scrolling="yes"
            horizontalscrolling="yes"
            verticalscrolling="yes"
            frameborder="0"
            allowtransparency="true"
            [src]="cashierIframeUrl | trustAsResourceUrl">
        </iframe>
    </div>
</div>
