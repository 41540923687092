import { Injectable } from '@angular/core';

import { addClass, getAmount, onlyUnique, removeClass, textToNumber, toDate, toTimeStamp } from '@casinocore/platform/utils';
import { DeviceService, Message, MessageLifetime, MessageQueueService, MessageType, Page, TrackingService, WindowRef } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';

import { CasinoLobbyService } from '../casino-lobby-manager/casino-lobby.service';
import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';
import { CasinoCoreGamelaunchService } from '../shared/gamelaunch.service';
import { GameLaunch } from '../shared/models/gamelaunch.model';
import { CUSTOM_LINK_TYPE_DRAWER, ICustomLinkDrawer, ICustomLinkDrawerContent, IOnboarding, LottieAnimationStatus } from './models/common.model';
import {
    IDrawerContent,
    IGameTableNameMapping,
    IImmersiveLobbyGlobalClrConfig,
    IImmersiveLobbyGlobalConfig,
    IImmersiveLobbyJackpotDrawerContent,
    IImmersiveLobbyMap,
    IImmersiveLobbySector,
    IImmersiveLobbySectorItem,
    IImmersiveLobbyStatCategory,
    IImmersiveLobbyStatItem,
    IImmersiveLobbyStats,
    IMMERSIVELOBBY_CUSTOM_LINK_TYPE_JACKPOT_DRAWER,
    IMMERSIVELOBBY_ITEM_TYPE_CUSTOM_LINK,
    IMMERSIVELOBBY_STATS_CTA_TYPE_GAME,
    IMMERSIVELOBBY_STATS_CTA_TYPE_SECTOR,
    IMMERSIVELOBBY_STATS_CTA_TYPE_STATIC_GAME,
    ImmersiveLobbyConfiguration,
    ImmersiveLobbyStat,
} from './models/immersive-lobby.model';

interface IGetMapResponse {
    map: IImmersiveLobbyMap;
    globalConfigMsg: IImmersiveLobbyGlobalConfig;
    globalConfigColor: IImmersiveLobbyGlobalClrConfig;
}
interface IImmersiveLobbyJackpotSectorItem {
    sectorItem: IImmersiveLobbySectorItem;
    index: number;
}
@Injectable({
    providedIn: 'root',
})
export class ImmersiveLobbyService {
    document: Document;
    categoryRoutePrefix = '/c/';
    sectorIdPrefix = 'lclCat';
    statsRoute = 'liveloungestats';
    loadingObservable: Observable<boolean | null>;
    mapObservable: Observable<boolean | null>;
    backgroundObservable: Observable<boolean | null>;
    animatedLayerObservable: Observable<boolean | null>;
    sectorsObservable: Observable<boolean | null>;
    sectorOpenedObservable: Observable<IImmersiveLobbySector | null>;
    gotoSectorObservable: Observable<string | null>;
    lottieAnimationStatusObservable: Observable<LottieAnimationStatus | null>;
    drawerContentMapAvailable: Observable<boolean | null>;
    onboardingDataAvailable: Observable<boolean | null>;
    statsContentAvailable: Observable<boolean | null>;
    statsDataAvailable: Observable<boolean | null>;
    statsOpenedObservable: Observable<boolean | null>;
    chatWindowOpenedObservable: Observable<boolean | null>;
    private jackpotSectorItem: IImmersiveLobbyJackpotSectorItem;

    private currentLobbyType: string;
    private lobbyGames: Array<string>;
    private sectorIds: Array<string>;
    private openedSector: IImmersiveLobbySector | undefined;
    private immersiveLobbyConfig: ImmersiveLobbyConfiguration;
    private loadingPublisher = new BehaviorSubject<boolean>(true);
    private mapPublisher = new BehaviorSubject<boolean>(false);
    private backgroundPublisher = new BehaviorSubject<boolean>(false);
    private animatedLayerPublisher = new BehaviorSubject<boolean>(false);
    private sectorsMap: Map<string, IImmersiveLobbySector> = new Map<string, IImmersiveLobbySector>();
    private sectorsPublisher = new BehaviorSubject<boolean>(false);
    private sectorOpenedPublisher = new BehaviorSubject<IImmersiveLobbySector | null>(null);
    private gotoSectorPublisher = new BehaviorSubject<string | null>(null);
    private lottieAnimationStatusPublisher = new BehaviorSubject<LottieAnimationStatus | null>(null);
    private globalConfigData: IImmersiveLobbyGlobalConfig;
    private mapData: IImmersiveLobbyMap;
    private globalConfigClr: IImmersiveLobbyGlobalClrConfig;
    private drawerContentMap: Map<string, ICustomLinkDrawerContent> = new Map<string, ICustomLinkDrawerContent>();
    private drawerContentMapPublisher = new BehaviorSubject<boolean | null>(null);
    private onboardingData: IOnboarding;
    private onboardingDataPublisher = new BehaviorSubject<boolean | null>(null);
    private statsContent: IImmersiveLobbyStats;
    private statsContentPublisher = new BehaviorSubject<boolean | null>(null);
    private statsData: any;
    private statsDataPublisher = new BehaviorSubject<boolean | null>(null);
    private stats: Array<ImmersiveLobbyStat>;
    private statsOpened: boolean;
    private statsOpendPublisher = new BehaviorSubject<boolean | null>(null);
    private statsError: boolean;
    private statsNotUpdated: boolean;
    private chatWindowOpenedPublisher = new BehaviorSubject<boolean | null>(null);
    jackpotDrawerContentMapAvailable: Observable<boolean | null>;
    statsFetched: boolean = false;

    private jackpotDrawerContentMap: Map<string, IImmersiveLobbyJackpotDrawerContent> = new Map<string, IImmersiveLobbyJackpotDrawerContent>();
    private jackpotDrawerContentMapPublisher = new BehaviorSubject<boolean | null>(null);
    constructor(
        private page: Page,
        private windowRef: WindowRef,
        private api: PlatformApiService,
        private tracking: TrackingService,
        private deviceService: DeviceService,
        private messageQueue: MessageQueueService,
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
        private casinoCoreGameLaunchService: CasinoCoreGamelaunchService,
    ) {
        const nativeWindow = <any>this.windowRef.nativeWindow;
        this.document = nativeWindow.document;

        this.lobbyGames = [];
        this.sectorIds = [];
        this.immersiveLobbyConfig = this.configProviderService.provideImmersiveLobbyConfig();
        this.loadingObservable = this.loadingPublisher.asObservable();
        this.mapObservable = this.mapPublisher.asObservable();
        this.backgroundObservable = this.backgroundPublisher.asObservable();
        this.animatedLayerObservable = this.animatedLayerPublisher.asObservable();
        this.sectorsObservable = this.sectorsPublisher.asObservable();
        this.sectorOpenedObservable = this.sectorOpenedPublisher.asObservable();
        this.gotoSectorObservable = this.gotoSectorPublisher.asObservable();
        this.lottieAnimationStatusObservable = this.lottieAnimationStatusPublisher.asObservable();
        this.drawerContentMapAvailable = this.drawerContentMapPublisher.asObservable();
        this.onboardingDataAvailable = this.onboardingDataPublisher.asObservable();
        this.statsContentAvailable = this.statsContentPublisher.asObservable();
        this.statsDataAvailable = this.statsDataPublisher.asObservable();
        this.statsOpenedObservable = this.statsOpendPublisher.asObservable();
        this.chatWindowOpenedObservable = this.chatWindowOpenedPublisher.asObservable();
        this.statsOpened = false;
        this.statsError = false;
        this.statsNotUpdated = false;
        this.jackpotDrawerContentMapAvailable = this.jackpotDrawerContentMapPublisher.asObservable();
    }

    get map(): IImmersiveLobbyMap {
        return this.mapData;
    }

    getLobbyGames(): Array<string> {
        return this.lobbyGames;
    }

    setLobbyGame(game: string): void {
        this.lobbyGames.push(game);
    }

    getSectorIds(): Array<string> {
        return this.sectorIds;
    }

    setSectorId(sectorId: string): void {
        this.sectorIds.push(sectorId);
    }

    get config(): ImmersiveLobbyConfiguration {
        return this.immersiveLobbyConfig;
    }

    get globalConfig(): IImmersiveLobbyGlobalConfig {
        return this.globalConfigData;
    }

    get globalClrConfig(): IImmersiveLobbyGlobalClrConfig {
        return this.globalConfigClr;
    }

    get isMobile(): boolean {
        return this.currentWidth <= 1024;
    }

    get lobbyType(): string {
        return this.currentLobbyType;
    }

    get mainElement(): HTMLDivElement {
        return this.document.getElementById('main-content')?.parentElement as HTMLDivElement;
    }

    get currentWidth(): number {
        const element = this.document.getElementById('casino-viewport');
        return this.deviceService.isiOS ? element?.offsetWidth || window.innerWidth : window.innerWidth;
    }

    get currentHeight(): number {
        const element = this.document.getElementById('casino-viewport');
        return this.deviceService.isiOS ? element?.offsetHeight || window.innerHeight : window.innerHeight;
    }

    get isMobileLandscape(): boolean {
        return (
            this.deviceService.isMobilePhone &&
            this.currentWidth >= this.immersiveLobbyConfig.mobileLandscapeConfig.minWidth &&
            this.currentWidth <= this.immersiveLobbyConfig.mobileLandscapeConfig.maxWidth
        );
    }

    getScreenName(title: string): string {
        const content = document.createElement('span');
        content.className = 'removeHtmlTags';
        content.innerHTML = title;
        content.style.visibility = 'hidden';
        content.style.opacity = '0';
        content.style.height = '0';
        return content.innerHTML.split('<br>').join(' ');
    }

    getLoadingStatus() {
        return combineLatest([this.mapObservable, this.backgroundObservable, this.animatedLayerObservable, this.sectorsObservable]);
    }

    getMap(mapPath: string): void {
        this.api.post('ImmersiveLobby/GetMap', { mapPath: mapPath }, { responseType: 'json', withCredentials: true, showSpinner: false }).subscribe({
            next: (response: IGetMapResponse) => {
                if (response) {
                    this.globalConfigData = response?.globalConfigMsg;
                    this.globalConfigClr = response?.globalConfigColor;
                    this.mapData = response?.map;
                    this.lobbyGames = [];
                    this.sectorIds = [];
                    this.mapPublisher.next(true);
                    this.setCustomLinkDrawerContent();
                }
            },
            error: (error: string) => {
                const message: Message = {
                    html: error,
                    type: MessageType.Error,
                    lifetime: MessageLifetime.Single,
                    scope: 'casinocorelobby',
                };
                if (message) {
                    this.messageQueue.add(message);
                }
            },
        });
    }

    initSectors(): void {
        if (this.sectorsMap.size) {
            const lottieSectors = Array.from(this.sectorsMap.values()).filter((sector: IImmersiveLobbySector) => sector.imageType === 'LOTTIE');
            lottieSectors.forEach((m: IImmersiveLobbySector) => {
                m.isLoaded = false;
            });
            this.sectorsPublisher.next(true);
        }
    }

    initMap(): void {
        this.mainElement.classList.remove('casino-lobby-height');
        this.loadingPublisher.next(true);
        this.backgroundPublisher.next(false);
        this.animatedLayerPublisher.next(false);
        this.sectorsMap = new Map<string, IImmersiveLobbySector>();
        this.sectorsPublisher.next(false);
        this.gotoSectorPublisher.next(null);
        this.openedSector = undefined;
        this.statsOpened = false;
        this.sectorOpenedPublisher.next(null);
    }

    setLobbyType(lobbyType: string): void {
        this.currentLobbyType = lobbyType;
    }

    setBackgroundStatus(loaded: boolean): void {
        this.backgroundPublisher.next(loaded);
    }

    setAnimatedLayerStatus(loaded: boolean): void {
        this.animatedLayerPublisher.next(loaded);
    }

    setSectorLoaded(sectorId: string): void {
        if (this.sectorsMap.size && this.sectorsMap.has(sectorId)) {
            this.sectorsMap.set(sectorId, { ...this.sectorsMap.get(sectorId), isLoaded: true } as IImmersiveLobbySector);
        } else {
            this.sectorsMap.set(sectorId, { isLoaded: true } as IImmersiveLobbySector);
        }

        this.sectorsPublisher.next(true);
    }

    getSectorsLoadedCount(): number {
        return this.sectorsMap.size ? Array.from(this.sectorsMap.values()).filter((sector: IImmersiveLobbySector) => sector.isLoaded).length : 0;
    }

    getOpenedSector(): IImmersiveLobbySector | undefined {
        return this.openedSector;
    }

    setSectorOpened(selectedMapItem: IImmersiveLobbySector | null): void {
        if (selectedMapItem) addClass('live-casino-lounge-sector-opened');
        else removeClass('live-casino-lounge-sector-opened');

        this.openedSector = selectedMapItem!;
        this.sectorOpenedPublisher.next(selectedMapItem);
    }

    gotoSector(categortId: string): void {
        this.gotoSectorPublisher.next(categortId);
    }

    setLottieAnimationStatus(status: LottieAnimationStatus): void {
        this.lottieAnimationStatusPublisher.next(status);
    }
    setChatWindowOpened(isOpened: boolean): void {
        this.chatWindowOpenedPublisher.next(isOpened);
    }

    setOnboardingData(): void {
        this.api.post('ImmersiveLobby/GetOnboardingContent', {}, { responseType: 'json', withCredentials: true, showSpinner: false }).subscribe({
            next: (response: IOnboarding) => {
                if (response) {
                    this.onboardingData = response;
                    this.onboardingDataPublisher.next(true);
                }
            },
            error: (error: string) => {
                const message: Message = {
                    html: error,
                    type: MessageType.Error,
                    lifetime: MessageLifetime.Single,
                    scope: 'casinocorelobby',
                };
                if (message) {
                    this.messageQueue.add(message);
                }
            },
        });
    }

    getOnboardingData(): IOnboarding {
        return this.onboardingData;
    }

    fetchDrawerContent(paths: Array<string>): void {
        this.api
            .post('ImmersiveLobby/FetchContent', { contentPaths: paths }, { responseType: 'json', withCredentials: true, showSpinner: false })
            .subscribe({
                next: (response: ICustomLinkDrawer) => {
                    if (response) {
                        this.emptyDrawerMap();
                        response?.content?.forEach((drawerContent: ICustomLinkDrawerContent) => {
                            this.drawerContentMap.set(drawerContent.path, drawerContent);
                        });
                        this.drawerContentMapPublisher.next(true);
                    }
                },
                error: (error: string) => {
                    const message: Message = {
                        html: error,
                        type: MessageType.Error,
                        lifetime: MessageLifetime.Single,
                        scope: 'casinocorelobby',
                    };
                    if (message) {
                        this.messageQueue.add(message);
                    }
                },
            });
    }

    getDrawerContentMap(): Map<string, ICustomLinkDrawerContent> {
        return this.drawerContentMap;
    }

    getDrawerContent(paths: Array<string>): ICustomLinkDrawerContent[] {
        const content = new Array<ICustomLinkDrawerContent>();
        paths.forEach((path: string) => content.push(this.drawerContentMap.get(path) as ICustomLinkDrawerContent));
        return content;
    }

    fetchStatsContent(): void {
        if (this.immersiveLobbyConfig.enableStats) {
            this.api.post('ImmersiveLobby/FetchStatsContent', {}, { responseType: 'json', withCredentials: true, showSpinner: false }).subscribe({
                next: (response: IImmersiveLobbyStats) => {
                    if (response) {
                        this.statsContent = response;
                        this.statsContentPublisher.next(true);
                    }
                },
                error: (error: string) => {
                    const message: Message = {
                        html: error,
                        type: MessageType.Error,
                        lifetime: MessageLifetime.Single,
                        scope: 'casinocorelobby',
                    };
                    if (message) {
                        this.messageQueue.add(message);
                    }
                },
            });
        }
    }

    get isStatsOpened(): boolean {
        return this.statsOpened;
    }

    getStatsContent(): IImmersiveLobbyStats {
        return this.statsContent;
    }

    fetchStatsData(): void {
        if (this.immersiveLobbyConfig.enableStats) {
            this.api.post('ImmersiveLobby/FetchStats', {}, { responseType: 'json', withCredentials: true, showSpinner: false }).subscribe({
                next: (response: JSON) => {
                    if (response) {
                        this.emptyStats();
                        this.statsData = response;
                        this.statsDataPublisher.next(true);
                    }
                },
                error: () => {
                    this.statsError = true;
                },
            });
        }
    }

    getStatsData(): JSON {
        return this.statsData;
    }

    get isStatsError(): boolean {
        return this.statsError;
    }

    get isStatsNotUpdated(): boolean {
        return this.statsNotUpdated;
    }

    emptyStats(): void {
        this.stats = [];
    }

    setStatsOpened(isOpened: boolean): void {
        this.statsOpened = isOpened;
        this.statsOpendPublisher.next(isOpened);
    }

    getStats(): Array<ImmersiveLobbyStat> {
        if (this.stats?.length) return this.stats;

        if (this.statsContent && this.statsData) {
            const loadFlag = this.statsData['dataFlag']?.['loadFlag'];
            if (loadFlag) this.statsNotUpdated = loadFlag === 'N';

            const disabledGames = this.casinoLobbyService.getDisabledGames();
            this.statsContent.categories.forEach((category: IImmersiveLobbyStatCategory) => {
                const stat = new ImmersiveLobbyStat();
                const apiCategory = this.statsData[category.categoryId];
                if (category) {
                    stat.category = category;
                    const currency = this.statsData['ccy'];
                    let statIndex = 0;
                    this.statsContent?.stats
                        ?.filter(
                            (m: IImmersiveLobbyStatItem) => m.itemId && m.itemId.indexOf('.') > -1 && m.itemId.split('.')[0] === category.categoryId,
                        )
                        .forEach((statItem: IImmersiveLobbyStatItem) => {
                            const itemKey = statItem.itemId.split('.')[1];
                            const statAPIItem = apiCategory[itemKey];
                            if (!statAPIItem || !statItem) return;

                            let isValid = true;
                            const locale = this.page.locale;
                            if (typeof statAPIItem === 'object') {
                                if (statItem.dataType === 'Currency') {
                                    const convertedCurrency = getAmount(statAPIItem['winAMT'], currency, locale);
                                    statItem.value = convertedCurrency.value;
                                    isValid = convertedCurrency.isValid;
                                } else statItem.value = statAPIItem['winAMT'];

                                const gameId = statAPIItem['vendorGmName'];
                                if (gameId) {
                                    const gameInfo = this.casinoLobbyService.getGameInfo(gameId);
                                    if (gameInfo) {
                                        statItem.gameId = gameId;
                                        statItem.gameName = gameInfo.name;
                                        statItem.isDynamicGame = true;
                                    }

                                    if (disabledGames.includes(gameId)) statItem.isDynamicGame = false;
                                }

                                if (statItem.showCTA && statItem.ctaType === IMMERSIVELOBBY_STATS_CTA_TYPE_GAME)
                                    statItem.showCTA = statItem.isDynamicGame;

                                const betTS = statAPIItem['betTS'];
                                if (betTS) {
                                    const date = toDate(betTS);
                                    statItem.betTS = date;
                                }
                            } else {
                                let statValue = statAPIItem ? statAPIItem.toString() : '';
                                if (statValue) {
                                    statValue = statValue.indexOf('_') > -1 ? statValue : statValue?.toLowerCase();
                                    switch (statItem.dataType) {
                                        case 'Number':
                                            {
                                                if (isNaN(statValue)) {
                                                    const number = textToNumber(statValue);
                                                    statItem.value = number !== -1 ? number : statValue;
                                                } else statItem.value = statValue.indexOf('.') > -1 ? parseFloat(statValue).toFixed(2) : statValue;
                                            }
                                            break;
                                        case 'Time':
                                            statItem.value = toTimeStamp(statValue);
                                            break;
                                        case 'Date':
                                            statItem.value = toDate(statValue);
                                            break;
                                        case 'Currency':
                                            {
                                                const convertedCurrency = getAmount(statValue, currency, locale);
                                                statItem.value = convertedCurrency.value;
                                                isValid = convertedCurrency.isValid;
                                            }
                                            break;
                                        case 'Game':
                                            if (this.immersiveLobbyConfig.enableGameNameMapping) {
                                                const gameTableNames = this.statsContent?.gameTableNames;
                                                let gameTableName = statValue;
                                                if (gameTableNames?.length) {
                                                    const statAPIValue = statAPIItem ? statAPIItem.toString() : '';
                                                    const fiteredGameTableName = gameTableNames.find(
                                                        (m: IGameTableNameMapping) =>
                                                            m.physicalName === statAPIValue || m.gameTableName === statAPIValue,
                                                    );
                                                    gameTableName = fiteredGameTableName?.commercialGameTableName
                                                        ? fiteredGameTableName.commercialGameTableName
                                                        : gameTableName;
                                                }
                                                statItem.value = gameTableName;
                                            } else statItem.value = statValue;
                                            break;
                                        default:
                                            statItem.value = statValue;
                                            break;
                                    }
                                }

                                const hideCTAForGameDataType =
                                    this.immersiveLobbyConfig.enableGameNameMapping &&
                                    statItem.dataType === 'Game' &&
                                    statItem.showCTA &&
                                    statItem.ctaType === IMMERSIVELOBBY_STATS_CTA_TYPE_STATIC_GAME;
                                if (statItem.ctaType === IMMERSIVELOBBY_STATS_CTA_TYPE_GAME || hideCTAForGameDataType) statItem.showCTA = false;
                            }

                            if (statItem.showCTA && statItem.staticGameId && statItem.ctaType === IMMERSIVELOBBY_STATS_CTA_TYPE_STATIC_GAME) {
                                const gameInfo = this.casinoLobbyService.getGameInfo(statItem.staticGameId);
                                if (gameInfo) {
                                    statItem.gameId = statItem.staticGameId;
                                    statItem.gameName = gameInfo.name;
                                    statItem.isStaticGame = true;
                                }

                                if (disabledGames.includes(statItem.staticGameId)) statItem.isStaticGame = false;

                                statItem.showCTA = statItem.isStaticGame;
                            }

                            if (statItem.showCTA && statItem.ctaType === IMMERSIVELOBBY_STATS_CTA_TYPE_SECTOR)
                                statItem.showCTA = this.sectorIds.includes(statItem.sectorId);

                            if (statItem.value && statItem.value != '0' && isValid) {
                                if (category.showHighlights && statIndex <= 2) stat.impStats.push(statItem);
                                else stat.stats.push(statItem);
                                statIndex++;
                            }
                        });

                    stat.isAvailable = stat.impStats?.length > 0 || stat.stats?.length > 0;
                    this.stats.push(stat);
                }
            });
        }

        if (this.stats?.length) this.stats = this.stats.sort((a, b) => (a.isAvailable ? 0 : 1) - (b.isAvailable ? 0 : 1));

        return this.stats;
    }

    showLoading(): void {
        if (!this.document.querySelector('.height-cls-skeleton')) this.loadingPublisher.next(true);
    }

    hideLoading(): void {
        if (this.document.querySelector('.height-cls-skeleton')) this.loadingPublisher.next(false);
    }

    getRowSpan(icon: string): number {
        switch (icon) {
            case '2':
            case '-2':
                return 1;
            case '3':
            case '-3':
                return 2;
            case '4':
            case '-4':
                return 2;
            case '5':
            case '-5':
                return 2;
            case '6':
            case '-6':
                return 1;
            case '7':
            case '-7':
                return 1;
            default:
                return 1;
        }
    }

    getColSpan(icon: string, isTouch: boolean): number {
        switch (icon) {
            case '2':
            case '-2':
                return 2;
            case '3':
            case '-3':
                return 1;
            case '4':
            case '-4':
                return 2;
            case '5':
            case '-5':
                if (isTouch) {
                    return 2;
                } else {
                    return 3;
                }
            case '6':
            case '-6':
                return 1;
            case '7':
            case '-7':
                return 2;
            default:
                return 1;
        }
    }

    getCategoryInfo(): { categoryId: string; categoryName: string } {
        const link = window.location.href;
        const subCategoryUrl = this.categoryRoutePrefix;
        let categoryId = link.substr(link.indexOf(subCategoryUrl) + (subCategoryUrl?.length || 0));
        if (categoryId.indexOf('?') !== -1) categoryId = categoryId.substr(0, categoryId.indexOf('?'));
        const lobbyInfo = this.casinoLobbyService.getLobbyInfo(this.lobbyType);
        const categoryInfo = lobbyInfo?.categoriesMap.get(categoryId);
        return { categoryId, categoryName: categoryInfo?.engCategoryName };
    }

    trackEvent(
        trackingType: string,
        categoryEvent: string,
        labelEvent: string,
        actionEvent: string,
        positionEvent: string,
        locationEvent: string,
        eventDetails: string,
        uRLClicked: string,
    ): void {
        this.tracking.triggerEvent(trackingType, {
            'component.CategoryEvent': categoryEvent,
            'component.LabelEvent': labelEvent,
            'component.ActionEvent': actionEvent,
            'component.PositionEvent': positionEvent,
            'component.LocationEvent': locationEvent,
            'component.EventDetails': eventDetails,
            'component.URLClicked': uRLClicked,
        });
    }
    getJackpotSectorItem(): IImmersiveLobbyJackpotSectorItem {
        return this.jackpotSectorItem;
    }

    setJackpotSectorItem(jackpotSectorItem: IImmersiveLobbySectorItem, index: number): void {
        this.jackpotSectorItem = { sectorItem: jackpotSectorItem, index: index } as IImmersiveLobbyJackpotSectorItem;
    }

    launchGame(gameId: string, categoryId: string, index: number): void {
        const gameModel = new GameLaunch();
        gameModel.isTouch = this.deviceService.isTouch;
        gameModel.replaceRouteInHistory = false;
        gameModel.isDemoLaunch = false;
        gameModel.skipUnfinGame = false;
        gameModel.categoryType = categoryId;
        gameModel.game = this.casinoLobbyService.getGameInfo(gameId) || gameId;
        gameModel.game.gameId = gameId;
        gameModel.game.lobbyType = this.lobbyType;
        gameModel.gameLaunchedFrom = categoryId;
        gameModel.gridPosition = Number(index + 1);
        gameModel.gamePosition = index;
        gameModel.from = 'q';
        gameModel.gameContainer = '';
        gameModel.audioFrom = true;
        gameModel.clickTimeStamp = this.casinoCoreGameLaunchService.currentTimeStamp();
        this.casinoCoreGameLaunchService.launchGame(gameModel);
    }

    private emptyDrawerMap(): void {
        if (this.drawerContentMap && this.drawerContentMap.size > 0) this.drawerContentMap.clear();
    }

    private emptyJackpotDrawerMap(): void {
        if (this.jackpotDrawerContentMap && this.jackpotDrawerContentMap.size > 0) this.jackpotDrawerContentMap.clear();
    }

    // private setCustomLinkDrawerContentLiveLounge(): void {
    //     if (this.mapData) {
    //         let paths = new Map<string, Array<string>>();
    //         this.mapData?.sectors.forEach((sector: IImmersiveLobbySector) => {
    //             const customLinkDrawers =  sector?.customLinks?.filter(m => m?.customLinkType === CUSTOM_LINK_TYPE_DRAWER && m?.customLinkDrawerContent).map(m => m.customLinkDrawerContent);
    //             const customLinkDrawerContents = new Array<string>().concat(...customLinkDrawers);
    //             if (customLinkDrawerContents.length) {
    //                 if (paths.has(CUSTOM_LINK_TYPE_DRAWER)) {
    //                     const content = paths.get(CUSTOM_LINK_TYPE_DRAWER);
    //                     paths.set(CUSTOM_LINK_TYPE_DRAWER, (content && content.length) ? content.concat(customLinkDrawerContents) : customLinkDrawerContents);
    //                 }
    //                 else
    //                     paths.set(CUSTOM_LINK_TYPE_DRAWER, customLinkDrawerContents);
    //             }
    //         });

    //         if (paths && paths.size) {
    //             paths.forEach((value: string[], key: string) => {
    //                 if (!value?.length) return;
    //                 if (key === CUSTOM_LINK_TYPE_DRAWER)
    //                     this.fetchDrawerContent(value.filter(onlyUnique));
    //             });
    //         }
    //     }
    // }
    fetchJackpotDrawerContent(paths: Array<string>): void {
        this.api
            .post(
                'ImmersiveLobby/FetchJackpotDrawerContent',
                { contentPaths: paths },
                { responseType: 'json', withCredentials: true, showSpinner: false },
            )
            .subscribe(
                (response: IDrawerContent) => {
                    if (response) {
                        this.emptyJackpotDrawerMap();
                        response?.content?.forEach((drawerContent: IImmersiveLobbyJackpotDrawerContent) => {
                            this.jackpotDrawerContentMap.set(drawerContent.path, drawerContent);
                        });
                        this.jackpotDrawerContentMapPublisher.next(true);
                    }
                },
                (error: string) => {
                    const message: Message = {
                        html: error,
                        type: MessageType.Error,
                        lifetime: MessageLifetime.Single,
                        scope: 'casinocorelobby',
                    };
                    if (message) {
                        this.messageQueue.add(message);
                    }
                },
            );
    }

    private setCustomLinkDrawerContent(): void {
        if (this.mapData) {
            const paths = new Map<string, Array<string>>();
            this.mapData?.sectors?.forEach((sector: IImmersiveLobbySector) => {
                if (sector.sectorType == 'Default') {
                    const customLinkDrawers: any = sector?.customLinkItems
                        ?.filter((m) => m?.customLink.customLinkType === CUSTOM_LINK_TYPE_DRAWER && m?.customLink.customLinkDrawerContent)
                        .map((m) => m.customLink.customLinkDrawerContent);
                    const customLinkDrawerContents = customLinkDrawers ? new Array<string>().concat(...customLinkDrawers) : [];
                    this.customLink(customLinkDrawerContents, paths);
                    this.customUniquePath(paths);
                } else {
                    const customLinks = sector?.customLinks?.filter((m) => m?.itemType === IMMERSIVELOBBY_ITEM_TYPE_CUSTOM_LINK) || [];
                    const customLinkDrawers: any = customLinks
                        .filter((m) => m?.customLinkType === CUSTOM_LINK_TYPE_DRAWER && m?.customLinkDrawerContent)
                        .map((m) => m.customLinkDrawerContent);
                    const customLinkDrawerContents = new Array<string>().concat(...customLinkDrawers);
                    this.customLink(customLinkDrawerContents, paths);
                    const customLinkJackpotDrawers: any = customLinks
                        .filter((m) => m?.customLinkType === IMMERSIVELOBBY_CUSTOM_LINK_TYPE_JACKPOT_DRAWER && m?.customLinkDrawerContent)
                        .map((m) => m.customLinkDrawerContent);
                    const customLinkJackpotDrawerContents = new Array<string>().concat(...customLinkJackpotDrawers);
                    this.customLinkJackpot(customLinkJackpotDrawerContents, paths);
                }
            });
            this.customPathSize(paths);
        }
    }
    customLink(customLinkDrawerContents: any, paths: any) {
        if (customLinkDrawerContents?.length) {
            if (paths.has(CUSTOM_LINK_TYPE_DRAWER)) {
                const content = paths.get(CUSTOM_LINK_TYPE_DRAWER);
                paths.set(CUSTOM_LINK_TYPE_DRAWER, content && content.length ? content.concat(customLinkDrawerContents) : customLinkDrawerContents);
            } else paths.set(CUSTOM_LINK_TYPE_DRAWER, customLinkDrawerContents);
        }
    }
    customLinkJackpot(customLinkJackpotDrawerContents: any, paths: any) {
        if (customLinkJackpotDrawerContents?.length) {
            if (paths.has(IMMERSIVELOBBY_CUSTOM_LINK_TYPE_JACKPOT_DRAWER)) {
                const content = paths.get(IMMERSIVELOBBY_CUSTOM_LINK_TYPE_JACKPOT_DRAWER);
                paths.set(
                    IMMERSIVELOBBY_CUSTOM_LINK_TYPE_JACKPOT_DRAWER,
                    content && content.length ? content.concat(customLinkJackpotDrawerContents) : customLinkJackpotDrawerContents,
                );
            } else paths.set(IMMERSIVELOBBY_CUSTOM_LINK_TYPE_JACKPOT_DRAWER, customLinkJackpotDrawerContents);
        }
    }
    customPathSize(paths: any) {
        if (paths && paths.size) {
            paths.forEach((value: string[], key: string) => {
                if (!value?.length) return;
                if (key === CUSTOM_LINK_TYPE_DRAWER) this.fetchDrawerContent(value.filter(onlyUnique));
                if (key === IMMERSIVELOBBY_CUSTOM_LINK_TYPE_JACKPOT_DRAWER) this.fetchJackpotDrawerContent(value.filter(onlyUnique));
            });
        }
    }
    customUniquePath(paths: any) {
        if (paths && paths.size) {
            paths.forEach((value: string[], key: string) => {
                if (!value?.length) return;
                if (key === CUSTOM_LINK_TYPE_DRAWER) this.fetchDrawerContent(value.filter(onlyUnique));
            });
        }
    }
    // NFR - Services optimization - Remove
    getJackpotDrawerContentMap(): Map<string, IImmersiveLobbyJackpotDrawerContent> {
        return this.jackpotDrawerContentMap;
    }

    getJackpotDrawerContent(paths: Array<string>): IImmersiveLobbyJackpotDrawerContent[] {
        const content = new Array<IImmersiveLobbyJackpotDrawerContent>();
        paths.forEach((path: string) => {
            content.push(this.jackpotDrawerContentMap.get(path) as IImmersiveLobbyJackpotDrawerContent);
        });

        return content;
    }
    getJackpotAmount(jackpotAmount: string, jpTickerIncrement: number, formatCurrency: Function): { jpAmount: string; jpTickerIncrement: number } {
        const globalClientConfig = this.configProviderService.provideGlobalConfig();
        const jackpotConfig = this.configProviderService.provideJackpotConfig();
        let currency: string = '';

        jackpotAmount = jackpotAmount.replace(/\&nbsp;/g, '');
        const initialcurrency = jackpotAmount.match(/[^\d\.\,\s]/g);
        if (initialcurrency && initialcurrency.length > 0) {
            for (let i = 0; i < initialcurrency.length; i++) {
                currency = currency + initialcurrency[i];
            }
            currency = currency.replace(/\,/g, '');
            currency = currency.replace(/\./g, '');
        }

        jackpotAmount = jackpotAmount.replace(/\ /g, '');
        jackpotAmount = jackpotAmount.replace(/\�/g, '');
        jackpotAmount = jackpotAmount.replace(/\,/g, '');
        jackpotAmount = jackpotAmount.replace(/\./g, '');
        jackpotAmount = jackpotAmount.replace(currency, '');
        jackpotAmount = jackpotAmount.replace(/\s/g, '');
        jackpotAmount = jackpotAmount.match(/\b[\d, ]+\b/g)![0];

        if (jackpotAmount.length > 2) {
            const realpart = jackpotAmount.slice(0, jackpotAmount.length - 2);
            const decimalpart = jackpotAmount.slice(-2);
            jackpotAmount = realpart + '.' + decimalpart;
        }

        let jackpotTickValue: number | string = parseFloat(jackpotAmount.replace(/,/g, ''));
        if (globalClientConfig.lobbyConfigurations.IncrementTickingForJackpotOnGameTiles)
            jpTickerIncrement = +globalClientConfig.lobbyConfigurations.IncrementTickingForJackpotOnGameTiles;

        jackpotTickValue = jackpotTickValue + jpTickerIncrement * 0.01;
        jackpotTickValue = formatCurrency(jackpotTickValue.toFixed(2));
        if (jackpotConfig?.showJackpotCurrencyOnRight) {
            jackpotTickValue = (jackpotTickValue as string).replace(/[,.]/g, (m: any) => (m === ',' ? '.' : ','));
            return { jpAmount: jackpotTickValue + ' ' + currency + '.', jpTickerIncrement: jpTickerIncrement };
        } else return { jpAmount: currency + ' ' + jackpotTickValue, jpTickerIncrement: jpTickerIncrement };
    }
}
