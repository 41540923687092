import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { CasinoManager, ConfigProviderService, JackpotDataService } from '@casinocore/platform/core';

@Component({
    selector: 'cc-stickers-jackpot',
    templateUrl: 'stickers-jackpot.component.html',
    standalone: true,
    imports: [NgIf, NgClass, NgFor, NgStyle],
})
export class StickersJackpotComponent implements OnInit {
    showstickersinoverlay: boolean = true;
    showjackpotvalueinoverlay: boolean = true;
    @Input() demoEnabled: boolean = false;
    @Input() stickers: any = [];
    @Input() isStickersForUk: boolean = false;
    @Input() isTouch: boolean;
    @Input() jackpotValue: any = null;
    @Input() isHotJackpotGroup: boolean = false;
    @Input() lobbyType: string = '';
    jpBgColor: any;
    enableHotIcon: boolean = false;
    hotIcon: any;
    epcotHotIcon: any;
    enableEpcotSubNavigation: boolean;
    jpRibbonStyles: { jpBgColor: string; jpTextColor: string };
    stickerKey: any = [];
    stickerStyles: any;
    enableStickerStyles: boolean;
    globalConfig: any;

    constructor(
        private casinoManager: CasinoManager,
        private configProviderService: ConfigProviderService,
        private jackpotDataService: JackpotDataService,
    ) {}
    ngOnInit() {
        let features = this.configProviderService.provideFeaturesConfig();
        this.globalConfig = this.configProviderService.provideGlobalConfig();
        this.enableStickerStyles = features.enableStickerStyles;
        let gameConfig = this.configProviderService.provideGameConfig();
        let subnavConfig: any = this.configProviderService.provideSubNavigationConfig();
        this.enableEpcotSubNavigation = subnavConfig.enableEpcotSubNavigation;
        this.showstickersinoverlay = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.showStickersInOverlay);
        this.showjackpotvalueinoverlay = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.showJackpotvalueInOverlay);
        this.enableHotIcon = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.enableHotIcon);
        this.hotIcon = gameConfig.host + features.hotIconPath.hotIcon;
        this.epcotHotIcon = gameConfig.host + features.hotIconPath.epcotHotIcon;
        if (this.jackpotValue) {
            this.setJackpotValue();
        }
    }

    // setJackpotValue() {
    //     let jackpotConfig = this.configProviderService.provideJackpotConfig();
    //     if (jackpotConfig.enableRibbonConfig) {
    //         this.jpBgColor = this.jackpotValue?.jackpotAttributes?.JackpotRibbonBgColor;
    //         this.jpTextCOlor = this.jackpotValue?.jackpotAttributes?.JackpotRibbonTextColor;
    //         if (this.jackpotValue.globalJackpotAttributes) {
    //             this.jpBgColor = this.jackpotValue?.globalJackpotAttributes?.JackpotRibbonBgColor;
    //             this.jpTextCOlor = this.jackpotValue?.globalJackpotAttributes?.JackpotRibbonTextColor;
    //         }
    //     }
    // }
    setJackpotValue() {
        let enableRibbonConfig = this.configProviderService.provideJackpotConfig().enableRibbonConfig;
        if (enableRibbonConfig) {
            this.jpRibbonStyles = this.jackpotDataService.getJackpotRibbonStyles(this.jackpotValue);
        }
    }
    getStyles(sticker: any, index: number) {
        this.stickerKey = Object.keys(this.globalConfig.stickerStyles);
        if (this.stickerKey.includes(sticker)) {
            this.stickerStyles = this.globalConfig.stickerStyles[sticker];
        } else if (this.isStickersForUk) {
            if (!this.stickerKey.includes(sticker) && index % 2 === 0) {
                this.stickerStyles = this.globalConfig.stickerStyles.UkStickerStyle1;
            } else {
                this.stickerStyles = this.globalConfig.stickerStyles.UkStickerStyle2;
            }
        } else {
            this.stickerStyles = this.globalConfig.stickerStyles.GameStickerStyle;
        }
        return this.stickerStyles;
    }
}
