import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { PlatformApiService } from '@casinocore/platform/core';
import { BalancePropertiesService } from '@frontend/vanilla/features/balance-properties';

import { GameIframeLauncherComponent } from '../game-iframe-launcher/game-iframe-launcher.component';

@Component({
    selector: 'cc-thunderbite-iframe-launcher',
    templateUrl: 'thunderbite-iframe-launcher.component.html',
    standalone: true,
    imports: [NgIf, GameIframeLauncherComponent],
})
export class ThunderbiteIframeLauncherComponent implements OnInit, OnDestroy {
    @Input() gameIdPath: string;
    @Input() thunderBiteIframeHeight: number;
    thunderbiteGameId: string;
    loadIframe: boolean = false;

    constructor(
        private api: PlatformApiService,
        private balanceService: BalancePropertiesService,
    ) {}

    ngOnInit() {
        this.api.get('content/GetThunderBiteGameId', { path: this.gameIdPath }, { withCredentials: true }).subscribe((response: any) => {
            if (response) {
                this.thunderbiteGameId = response;
                this.loadIframe = true;
            }
        });
    }

    ngOnDestroy() {
        this.balanceService.refresh();
    }
}
