<div class="casino-lobby-container-main">
    <div class="quickInfoModal">
        <div class="casino-qi-noverlay" (click)="closeModal($event)"></div>
        <div
            class="casino-qi-nrectangle casino-qi-desktop-nrectangle"
            [class.casino-qi-desktop-nrectangle]="!isMobile"
            [style.top]="rewardDetails && rewardDetails.length >= 1 ? '14%' : '30%'"
            [ngClass]="rewardDetails && rewardDetails.length >= 1 ? 'casino-height600' : 'casino-height450'">
            <div class="casino-total-data">
                <div class="casino-qi-nrectangle-int">
                    <div class="casino-qi-nimage casino-qi-desktop-nimage reel-quickinfo" [class.casino-qi-desktop-nimage]="!isMobile">
                        <img src="{{ src }}" (error)="onGameIconError()" alt="error Icon" />
                        <div class="casino-relativ-div-quickinfo">
                            <cc-stickers-jackpot
                                [demoEnabled]="demoEnabled"
                                [stickers]="stickers"
                                [isTouch]="isTouch"
                                [jackpotValue]="jackpotData"
                                [isHotJackpotGroup]="isHotJackpotGroup"
                                [isStickersForUk]="isStickersForUk"
                                [lobbyType]="lobbyType"></cc-stickers-jackpot>
                        </div>
                        <div *ngIf="useFavouriteGames && displayFavouritesOnPreLogin">
                            <div class="casino-qi-ngradient"></div>
                            <div
                                (click)="toggleFavourite(game, $event)"
                                class="casino-qi-nfavourite"
                                [ngClass]="isFavourite ? favouriteStyle : unFavouriteStyle"></div>
                        </div>
                    </div>
                    <div class="casino-qi-nrectangle-right">
                        <div class="casino-qi-ngame-provider">{{ gameProvider | uppercase }}</div>

                        <div class="close theme-close qi-close casino-qi-desktop-close" (click)="closeModal()"></div>
                        <div *ngIf="vendorLogo" class="casino-qi-nlogo">
                            <img src="{{ vendorLogo }}" alt="vendorLogo" />
                        </div>
                        <div class="casino-qi-nlogo" *ngIf="!vendorLogo">{{ provider | uppercase }}</div>

                        <div *ngIf="enableQuickInfoReels" class="casino-quickInfoReelsTop">
                            <div *ngIf="reels" class="reels-dv casino-quickInfoReelsWidth">
                                <div class="reelsValue">{{ reels }}</div>
                                <div *ngIf="reels" class="Reels">
                                    {{ reelsTitle }}
                                </div>
                            </div>
                            <div *ngIf="payLines" class="paylines casino-quickInfoReelsWidth">
                                <div class="reelsValue">{{ payLines }}</div>
                                <div *ngIf="payLines" class="Paylines">
                                    {{ payLinesTitle }}
                                </div>
                            </div>
                            <div *ngIf="returnToPlayer" class="returntoplayer casino-quickInfoReelsWidth">
                                <div class="returnToPlayerValue">{{ returnToPlayer }}</div>
                                <div *ngIf="returnToPlayer" class="Return-to-Player">
                                    {{ returnToPlayerTitle }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="clear: both"></div>
                </div>
                <div class="gamedata">
                    <div class="casino-qi-ngame" [vnDynamicHtml]="gameTitle"></div>
                    <span class="gameData-body" [vnDynamicHtml]="gameDescription"></span>
                </div>

                <div class="casino-qi-btn-container">
                    <!-- <div class="qi-margin-top casino-qi-demo-link" (click)="launchGame(game, true)"
                        *ngIf="demoEnabled && demoEnabledForProvider && isTouch">
                        <a [ngStyle]="{ color: demoLinkTextColor }">{{ demoLinkText | uppercase }}</a>
                    </div> -->

                    @if (demoEnabled && demoEnabledForProvider && isTouch) {
                        <cs-gametile-cta
                            [isLegacy]="isLegacy"
                            [kind]="'tertiary'"
                            [variant]="'outline'"
                            (click)="launchGame(game, true)"
                            [customTextColor]="demoLinkTextColor"
                            [csDecoratorClass]="'qi-margin-top casino-qi-demo-link btn-light'"
                            [cta_text]="demoLinkText">
                        </cs-gametile-cta>
                    }

                    <!-- <button
                        class="casino-qi-nplay-now btn btn-primary"
                        [ngClass]="{ 'epcot-btn': enableEpcotSubNavigation }"
                        (click)="launchGame(game, false)">
                        {{ cta | uppercase }}
                    </button> -->

                    <cs-gametile-cta
                        [isLegacy]="isLegacy"
                        [kind]="'primary'"
                        (click)="launchGame(game, false)"
                        [csDecoratorClass]="'casino-qi-nplay-now btn-md'"
                        [cta_text]="cta">
                    </cs-gametile-cta>
                </div>
            </div>
            <cc-freespin-quick-info *ngIf="showFreeSpinCount && enableFreeSpinsOnQuickInfo" [rewardDetails]="rewardDetails"> </cc-freespin-quick-info>
        </div>
    </div>
</div>
