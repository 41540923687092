import { Injectable } from '@angular/core';

import { AppInfoConfig, TimerService, TrackingService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';

@Injectable({
    providedIn: 'root',
})
export class PlayerStatsService {
    private playerstats = new BehaviorSubject<any>(null);
    playerstatsObservable: Observable<any> = this.playerstats.asObservable();
    sortInterval: any;
    feedTypeArray: any;
    isFeedRequestRecieved: boolean;
    constructor(
        private api: PlatformApiService,
        private timerService: TimerService,
        private configProviderService: ConfigProviderService,
        private tracking: TrackingService,
        private appInfoConfig: AppInfoConfig,
    ) {}
    fetchPlayerStatsFeed(feedTypeArray: any): void {
        this.feedTypeArray = feedTypeArray;
        if (feedTypeArray) {
            this.isFeedRequestRecieved = true;
            const request: Observable<any>[] = [];
            for (const feedtype of feedTypeArray) {
                request.push(
                    this.api.post(
                        'playerstats/getPlayerStats',
                        { feedType: feedtype },
                        { responseType: 'json', withCredentials: true, showSpinner: false },
                    ),
                );
            }
            forkJoin(request).subscribe({
                next: (responses) => {
                    if (responses) {
                        this.playerstats.next(responses);
                    }
                },
                error: () => {
                    //console.log(error);
                },
            });
        }
    }
    playerStatsFeedInterval() {
        const playerstatsconfig = this.configProviderService.providePlayerStatsWidgetConfig();
        if (this.sortInterval) {
            clearInterval(this.sortInterval);
        }
        this.sortInterval = this.timerService.setIntervalOutsideAngularZone(() => {
            this.fetchPlayerStatsFeed(this.feedTypeArray);
        }, playerstatsconfig.playerStatsInterval);
    }
    addTrackingEvent(
        categoryEvent: string,
        labelEvent: string,
        actionEvent: string,
        positionEvent: string,
        locationEvent: string,
        eventDetails: string,
        uRLClicked: string,
    ) {
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': categoryEvent,
            'component.LabelEvent': labelEvent,
            'component.ActionEvent': actionEvent,
            'component.PositionEvent': positionEvent,
            'component.LocationEvent': this.appInfoConfig.product,
            'component.EventDetails': eventDetails,
            'component.URLClicked': uRLClicked,
        });
    }
    addMultiplierImpressionTracking(gameImpressions: any) {
        this.tracking.triggerEvent('gameMultiplier', {
            game_multiplier_impressions: gameImpressions,
        });
    }
}
