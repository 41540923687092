import { NgIf } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    CasinoCoreNpmParamsService,
    CasinoIFrameOverlayService,
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    JackpotDataService,
    LiveCasinoService,
} from '@casinocore/platform/core';
import { NewGameQuickInfoComponent } from '@casinocore/platform/new-game-quick-info';
import { DeviceService, Logger, NavigationService, ProductHomepagesConfig, TrackingService } from '@frontend/vanilla/core';
import { MessagePanelComponent } from '@frontend/vanilla/features/message-panel';
import { Subscription } from 'rxjs';

import { BottomNavbarComponent } from '../bottom-navbar/bottom-navbar.component';
import { CasinoGridOnLobbyTypeComponent } from '../casino-grid-on-lobby-type/casino-grid-on-lobby-type.component';
import { CasinoMenuActionsBootstrapService } from '../casino-menu/casino-menu-actions-bootstrap.service';
import { OverlayHeaderComponent } from '../overlay-header/overlay-header.component';
import { OverlaySearchComponent } from '../overlay-search/overlay-search.component';
import { RecentlyPlayedandGymlComponent } from '../recentlyPlayedandGYML/recentlyPlayed-gyml.component';
import { LibMockService } from '../services/lib-mock.service';

@Component({
    selector: 'cc-sports-overlay',
    templateUrl: 'sports-overlay.component.html',
    standalone: true,
    imports: [
        NgIf,
        OverlayHeaderComponent,
        OverlaySearchComponent,
        MessagePanelComponent,
        NewGameQuickInfoComponent,
        RecentlyPlayedandGymlComponent,
        CasinoGridOnLobbyTypeComponent,
        BottomNavbarComponent,
    ],
})
export class SportsOverlayComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() hideCloseButton: boolean;
    isLobbyLoaded: boolean;
    lobbyLoadSubscription: Subscription;
    overlayLobbyType: string;
    overlayCategoryRoute: string;
    rpPositionLmtKey: string;
    lobbyResponse: any;
    mcPageConfig: any;
    gamingHomeBtnText: string;
    hideBottomNav: boolean;
    enableSearch: boolean;
    isNewQuickInfoEnabled: boolean;

    constructor(
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
        private deviceService: DeviceService,
        private casinoManager: CasinoManager,
        private lhProductHomepagesConfig: ProductHomepagesConfig,
        private navigate: NavigationService,
        private trackingService: TrackingService,
        private route: ActivatedRoute,
        private jackpotDataService: JackpotDataService,
        private liveCasinoService: LiveCasinoService,
        private casinoCoreNpmParamsService: CasinoCoreNpmParamsService,
        private casinoIFrameOverlayService: CasinoIFrameOverlayService,
        private libMockService: LibMockService,
        private casinoMenuActionsBootstrapService: CasinoMenuActionsBootstrapService,
        private logger: Logger,
    ) {
        this.libMockService.setCasinoBootstrapService(this.casinoMenuActionsBootstrapService);
    }

    ngOnInit() {
        const params = { ...this.route.snapshot.queryParams };
        delete params.openCasinoCoreOverlay;
        this.lobbyLoadSubscription = this.casinoLobbyService.lobbyLoadedObservable.subscribe((response) => {
            if (response) {
                this.isLobbyLoaded = true;
                this.lobbyResponse = response;
                this.mcPageConfig = this.configProviderService.provideMcPageConfig();
                this.gamingHomeBtnText = this.mcPageConfig.gamingHomeButtonLink.text;
                this.bindOverlay();
                const casinoNpmParams = this.casinoCoreNpmParamsService.getcasinoCoreNpmParams();
                const liveCasinoConfig = this.configProviderService.provideLiveCasinoConfig();
                const feedCacheConfig: any = this.configProviderService.provideFeedCacheConfig();
                const overlayConfig: any = this.configProviderService.provideCasinoAsIFrameConfig();
                this.hideBottomNav = overlayConfig.hideBottomNav;
                this.enableSearch = overlayConfig.enableSearch;
                const features: any = this.configProviderService.provideFeaturesConfig();
                if (!casinoNpmParams.isDll) {
                    this.isNewQuickInfoEnabled = this.casinoManager.IsFeatureEnabledforLobbytypeWithInvokerProduct(
                        this.overlayLobbyType,
                        features.enableNewQuickInfoLobbyType,
                    );
                }
                if (feedCacheConfig.enableJackpotFeed && casinoNpmParams?.isDisableJackpotCalls) {
                    this.jackpotDataService.startFeed();
                }
                if (liveCasinoConfig.enabled && casinoNpmParams?.isDisableLiveCasinoCalls) {
                    this.liveCasinoService.getLiveCasinoData(true);
                }
            }
        });
    }

    bindOverlay() {
        const features: any = this.configProviderService.provideFeaturesConfig();
        this.overlayLobbyType = this.casinoIFrameOverlayService.fetchOverlayLobby();
        if (this.overlayLobbyType && this.overlayLobbyType != '') {
            const overlayDefaultCatInfo: any = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(this.overlayLobbyType);
            if (overlayDefaultCatInfo) {
                this.overlayCategoryRoute = overlayDefaultCatInfo.categoryInfo.categoryid;
            }
            this.rpPositionLmtKey = this.casinoManager.IsFeatureEnabledforLobbytype(this.overlayLobbyType, features.rpPositionLmtKey);
            this.raiseOverlayLoadedEvent();
        } else {
            this.logger.error('Overlay lobby is not available');
        }
    }

    goToCasinoHome() {
        this.trackingService.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Sports Gaming Overlay',
            'component.LabelEvent': 'Gaming Overlay on Sports Lobby',
            'component.ActionEvent': 'CTA - Click',
            'component.PositionEvent': 'sticky CTA',
            'component.LocationEvent': 'Gaming Overlay on Sports Lobby',
            'component.EventDetails': 'User taps CTA and is taken to Casino Home',
            'component.URLClicked': this.mcPageConfig.gamingHomeButtonLink.url,
        });
        this.navigate.goTo(this.mcPageConfig.gamingHomeButtonLink.url);
    }

    ngOnDestroy() {
        if (this.lobbyLoadSubscription) {
            this.lobbyLoadSubscription.unsubscribe();
        }
        const casinoNpmParams = this.casinoCoreNpmParamsService.getcasinoCoreNpmParams();
        const liveCasinoConfig = this.configProviderService.provideLiveCasinoConfig();
        const feedCacheConfig: any = this.configProviderService.provideFeedCacheConfig();
        if (feedCacheConfig.enableJackpotFeed && casinoNpmParams?.isDisableJackpotCalls) {
            this.jackpotDataService.stopFeed();
        }
        if (liveCasinoConfig.enabled && casinoNpmParams?.isDisableLiveCasinoCalls) {
            this.liveCasinoService.stopFeed();
        }
    }

    getCasinoHomeUrl(): string {
        return this.lhProductHomepagesConfig.casino;
    }

    raiseOverlayLoadedEvent() {
        const overlayData = {
            lobbyLoaded: this.isLobbyLoaded,
            overlayLobbyType: this.overlayLobbyType,
            overlayCategoryRoute: this.overlayCategoryRoute,
            lobbyResponse: this.lobbyResponse,
        };

        this.logger.info('Casino Overlay Data' + overlayData);
        const event = new CustomEvent('sports-overlay-loaded', { detail: { overlayData } });
        document.dispatchEvent(event);
    }
    ngAfterViewInit() {
        const featuresConfig = this.configProviderService.provideFeaturesConfig();
        if (featuresConfig && featuresConfig.enableOverlayMobileSearchTopPosition && this.deviceService.isTouch) {
            this.sportsGameOverlayTopAllignment();
        }
    }
    sportsGameOverlayTopAllignment() {
        const contentMessageElement = document.querySelector('.content-messages-header-top .content-message-container');
        const sports_olay: HTMLElement | null = document.querySelector('sportsOverlay-top-position');
        if (contentMessageElement && sports_olay) {
            sports_olay.style.top = contentMessageElement.clientHeight + 'px';
        }
    }
}
