import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { CasinoLobbyService } from '@casinocore/platform/core';
import { Subscription } from 'rxjs';

import { WidgetCompositionComponent } from '../widget-composition/widget-composition.component';

@Component({
    selector: 'cs-widget-container',
    templateUrl: 'widget-container.component.html',
    standalone: true,
    imports: [NgIf, WidgetCompositionComponent],
})
export class WidgetContainerComponent implements OnInit, OnDestroy {
    isLobbyLoaded: boolean;
    lobbyLoadedObs: Subscription;

    constructor(private casinoLobbyService: CasinoLobbyService) {}

    ngOnInit() {
        this.lobbyLoadedObs = this.casinoLobbyService.lobbyLoadedObservable.subscribe((lobbyTypeMaps: any) => {
            if (lobbyTypeMaps) {
                this.isLobbyLoaded = true;
            }
        });
    }

    ngOnDestroy() {
        if (this.lobbyLoadedObs) {
            this.lobbyLoadedObs.unsubscribe();
        }
    }
}
