import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';

import { CasinoManager, ConfigProviderService, JackpotDataService } from '@casinocore/platform/core';

@Component({
    selector: 'cc-flipclock-redesign',
    templateUrl: 'flipclock-redesign.component.html',
    standalone: true,
    imports: [NgIf, NgFor, NgClass, NgStyle],
})
export class FlipclockRedesignComponent implements OnInit, OnChanges {
    @Input() amount: any;
    @Input() currency: string;
    @Input() releaseamount: number;
    @Input() isMega: string;
    @Input() widgetType: string;
    @Input() jackpotName: any;
    @Input() lobbyType: string;

    majorItems: any = [];
    majorItemslist: any = [];
    singlejackpotvalueDigitTxtClr: string;
    singlejackpotvalueDigitBgClr: string;
    jackpotList: any;
    jackpotValue: any;
    enableJpGroupTickingForLobby: boolean;
    private bufferValue: any;
    private index: number;
    private tickerValue: any;
    private controlIntervalId: any;
    private setFlipId: any;
    private tickerValueLength: number;
    private isMobile: boolean;
    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private jackpotDataService: JackpotDataService,
        private configProviderService: ConfigProviderService,
        private casinoManager: CasinoManager,
    ) {
        if (document.body.offsetWidth >= 768 || window.innerWidth >= 768) {
            this.isMobile = false;
        } else {
            this.isMobile = true;
        }
    }
    ngOnChanges(): void {
        const jackpotData = this.configProviderService.provideJackpotConfig();
        this.enableJpGroupTickingForLobby = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.lobbyType,
            jackpotData.enableJpGroupTickingForLobbyType,
        );
        clearInterval(this.controlIntervalId);
        clearInterval(this.setFlipId);
        this.amount = parseFloat(this.amount?.toString()?.replace(/,/g, '')).toFixed(2);
        if (this.amount > this.tickerValue) {
            this.tickerValue = this.amount;
        }
        this.subscribtToJackpotFeed();
    }
    ngOnInit(): void {
        const jackpotData = this.configProviderService.provideJackpotConfig();
        this.enableJpGroupTickingForLobby = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.lobbyType,
            jackpotData.enableJpGroupTickingForLobbyType,
        );
        this.amount = parseFloat(this.amount?.toString()?.replace(/,/g, '')).toFixed(2);
        this.bufferValue = this.amount;
        // this.amount = this.amount.toFixed(2);
        this.tickerValue = parseFloat(this.bufferValue?.toString()?.replace(/,/g, '')).toFixed(2);
        const jackpotDataConfig = this.configProviderService.provideJackpotConfig();
        this.singlejackpotvalueDigitTxtClr = jackpotDataConfig?.jackpotWidgetConfigurations?.SinglejackpotvalueDigitTxtClr;
        this.singlejackpotvalueDigitBgClr = jackpotDataConfig?.jackpotWidgetConfigurations?.SinglejackpotvalueDigitBgClr;
        this.subscribtToJackpotFeed();
        if (this.jackpotValue !== undefined) {
            this.jackpotValue = (parseFloat(this.jackpotValue) + 0.01).toFixed(2);
            this.updateTicker();
        }
    }
    subscribtToJackpotFeed() {
        if (this.enableJpGroupTickingForLobby && this.widgetType == 'SingleJackpot') {
            this.updateTickingValue();
        } else if (this.enableJpGroupTickingForLobby && this.widgetType == 'MustGoJackpot') {
            this.jackpotValue = this.amount;
            this.tickerCode();
        } else {
            this.jackpotValue = this.amount;
            this.updateTicker();
        }
    }
    tickerCode() {
        this.controlIntervalId = setInterval(() => {
            this.jackpotValue = (parseFloat(this.jackpotValue) + 0.01).toFixed(2);
            this.updateTicker();
        }, 6000);
    }

    updateTickingValue() {
        this.jackpotDataService.jpTickingObservable.subscribe((res: any) => {
            if (res) {
                this.jackpotList = [];
                res.forEach((jackpot: any) => {
                    this.jackpotList.push(jackpot);
                });
            }

            const ids = this.jackpotList?.map((jackpotName: any) => jackpotName);
            const filteredList = this.jackpotList?.filter((jackpotName: any, index: any) => !ids.includes(jackpotName, index + 1));
            if (filteredList?.length > 0) {
                filteredList?.forEach((item: any) => {
                    if (item?.jackpotName && item?.jackpotName?.toLowerCase() == this.jackpotName?.toLowerCase()) {
                        this.jackpotValue = item.amount.replace(/\s/g, '');
                        this.updateTicker();
                    }
                });
            }
        });
    }

    private updateTicker(): void {
        this.majorItems = [];
        const str: string = this.formatNumber(this.jackpotValue);
        if (str && str.toLowerCase() !== 'nan') {
            for (let i = 0; i < str.length; i++) {
                this.majorItems.push({
                    isFlipVisible: false,
                    value: str[i],
                });
            }
        } else {
            this.tickerValueLength = this.majorItems.length;
        }
    }

    formatNumber(num: number) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
}
