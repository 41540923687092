import { Pipe, PipeTransform } from '@angular/core';

import { FreespinSummaryService } from './freespins-summary.service';
import { TimeZoneService } from './time-zone.service';

@Pipe({
    name: 'timezoneformat',
    standalone: true,
})
export class TimeZoneFormatPipe implements PipeTransform {
    constructor(
        private freespinSummaryService: FreespinSummaryService,
        private timeZoneService: TimeZoneService,
    ) {}
    transform(timespan: number): any {
        if (timespan) {
            const userTimeZoneInfo = this.freespinSummaryService.getTimeZoneInfo();
            let userUTCOffsetSecs: number = 0;
            if (userTimeZoneInfo) {
                const userOffsetString = userTimeZoneInfo.BaseUtcOffset;
                userUTCOffsetSecs = this.timeZoneService.getSecondsFromTimeString(userOffsetString);
            }
            let userUTCOffsetMilliSecs = userUTCOffsetSecs * 1000;
            if (userTimeZoneInfo?.AdjustmentRules?.length > 0 && userTimeZoneInfo.AdjustmentRules[0].DaylightDelta) {
                const daylightDeltaOffsetSecs = this.timeZoneService.getSecondsFromTimeString(userTimeZoneInfo.AdjustmentRules[0].DaylightDelta);
                if (daylightDeltaOffsetSecs > 0) {
                    userUTCOffsetMilliSecs = userUTCOffsetMilliSecs + daylightDeltaOffsetSecs * 1000;
                }
            }
            const dt = new Date();
            const timeStamp = timespan * 1000 + userUTCOffsetMilliSecs + dt.getTimezoneOffset() * 60 * 1000;
            const userExpiryTime = new Date(timeStamp);
            const dateFormat = this.timeZoneService.getDateFormat(new Date(userExpiryTime));
            return dateFormat;
        }
    }
}
