import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    constructor() {}
    getPositionXY(e: any) {
        const res = { x: 0, y: 0 };
        if (e.type === 'touchstart' || e.type === 'touchmove' || e.type === 'touchend' || e.type === 'touchcancel') {
            const touch = e.touches[0] || e.changedTouches[0];
            res.x = touch.pageX;
            res.y = touch.pageY;
        } else if (
            e.type === 'mousedown' ||
            e.type === 'mouseup' ||
            e.type === 'mousemove' ||
            e.type === 'mouseover' ||
            e.type === 'mouseout' ||
            e.type === 'mouseenter' ||
            e.type === 'mouseleave'
        ) {
            res.x = e.pageX;
            res.y = e.pageY;
        }
        return res;
    }

    deepMergeObjects(mergeAccumulator: any, ...mergees: any[]) {
        if (mergeAccumulator == null) {
            mergeAccumulator = {};
        }

        const accumulatedMerge = Object(mergeAccumulator);

        //iterative vs recursive
        for (let argumentIndex = 0; argumentIndex < mergees.length; argumentIndex++) {
            const nextMergee = mergees[argumentIndex];

            if (nextMergee) {
                // Skip over if undefined or null
                for (const nextKeyToBeMerged in nextMergee) {
                    // Avoid bugs when hasOwnProperty is shadowed
                    if (Object.prototype.hasOwnProperty.call(nextMergee, nextKeyToBeMerged)) {
                        if (
                            typeof accumulatedMerge[nextKeyToBeMerged] === 'object' &&
                            accumulatedMerge[nextKeyToBeMerged] &&
                            typeof nextMergee[nextKeyToBeMerged] === 'object' &&
                            nextMergee[nextKeyToBeMerged]
                        ) {
                            this.deepMergeObjects(accumulatedMerge[nextKeyToBeMerged]);
                        } else {
                            accumulatedMerge[nextKeyToBeMerged] = nextMergee[nextKeyToBeMerged];
                        }
                    }
                }
            }
        }
        return accumulatedMerge;
    }
}
