import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MonitorService {
    active: boolean = false;
    portalInfoAvailable: boolean = false;
    portalInfo: any = null;
    MONITOR_INACTIVE: string = 'monitor:inactive';

    private monitorActiveStatus = new BehaviorSubject<string>('');
    monitorActiveObservable: Observable<string> = this.monitorActiveStatus.asObservable();

    setMonitorActiveStatus(status: string) {
        this.monitorActiveStatus.next(status);
    }

    activate() {
        this.active = true;
        return true;
    }
    deactivate() {
        this.active = false;
        this.setMonitorActiveStatus(this.MONITOR_INACTIVE);
        return true;
    }

    isActive() {
        return this.active;
    }
    // NFR - Services optimization - Remove
    isPortalInfoAvailable() {
        return this.portalInfoAvailable;
    }
    // NFR - Services optimization - Remove
    getPortalInfo() {
        return this.portalInfo;
    }

    managePortalInfo(response: any, success: any) {
        if (!this.active) {
            return;
        }

        let info;

        if (response) {
            if (success && response.portalInfo) {
                info = response.portalInfo;
            } else if (response.data && response.data.data && response.data.data.portalInfo) {
                info = response.data.data.portalInfo;
            }
        }

        if (!info) {
            info = {};
        }
        if (info.statusCode == null) {
            info.statusCode = -1;
        }
        if (!info.statusMessage) {
            info.statusMessage = 'Result unknown. Unable to receive Portal Info from server';
            info.isProtected = false;
        }

        this.portalInfo = info;
        this.portalInfoAvailable = true;
    }
}
