import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { ConfigProviderService, InvokerProductService } from '@casinocore/platform/core';
import { Logger } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

import { CasinoGridOnLobbyTypeComponent } from '../casino-grid-on-lobby-type/casino-grid-on-lobby-type.component';

@Component({
    selector: 'cc-casino-grid-on-product',
    templateUrl: 'casino-grid-on-product.component.html',
    standalone: true,
    imports: [CasinoGridOnLobbyTypeComponent],
})
export class CasinoGridOnProductComponent implements OnInit, OnDestroy, OnChanges {
    @Input() casinoProduct: string;
    @Input() categoryRoute: string;

    isClientConfigLoaded: boolean;
    clientConfigLoadSubscription: Subscription;
    lobbyType: string;

    constructor(
        private configProviderService: ConfigProviderService,
        private logger: Logger,
        private invokerProductService: InvokerProductService,
    ) {}

    ngOnInit() {
        this.clientConfigLoadSubscription = this.configProviderService.casinoClientConfigObservable.subscribe((isClientConfigLoaded) => {
            if (isClientConfigLoaded) {
                this.isClientConfigLoaded = isClientConfigLoaded;
                this.setLobbyType();
            }
        });
        this.setLobbyType();
    }

    ngOnChanges() {
        this.setLobbyType();
    }

    setLobbyType() {
        if (this.isClientConfigLoaded) {
            const lmtDataClientConfig: any = this.configProviderService.provideLmtConfig();
            if (!this.casinoProduct || this.casinoProduct === '') {
                this.casinoProduct = 'games';
                //TODO: fetch default product from dynacon
            }
            const invokerProduct: string = this.invokerProductService.getInvokerProduct();
            if (lmtDataClientConfig.lobbyTypeMap && lmtDataClientConfig.lobbyTypeMap.length > 0) {
                for (let i = 0; i < lmtDataClientConfig.lobbyTypeMap.length; i++) {
                    const item: any = lmtDataClientConfig.lobbyTypeMap[i];
                    if (item?.product?.toLowerCase() === this.casinoProduct?.toLowerCase() && item.invokerProduct === invokerProduct?.toLowerCase()) {
                        this.lobbyType = item.lobbyType;
                        break;
                    }
                }
                if (!this.lobbyType || this.lobbyType === '') {
                    this.logger.error('Please configure a valid lobby Type under lobbyTypeMap');
                }
            }
        }
    }

    ngOnDestroy() {
        if (this.clientConfigLoadSubscription) {
            this.clientConfigLoadSubscription.unsubscribe();
        }
    }
}
