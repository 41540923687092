<cc-teasers-wrapper
    *ngIf="teasersReady"
    [teaserLocation]="TopTeaser"
    [teaserCategory]="teaserCategoryVal"
    [optTeasersSource]="optimizedHeaderTeasers"
    [teasersSource]="headerTeasers"
    [identifierClass]="categoryRoute"
    [lobbyType]="lobbyType"
    [isDefaultCategory]="isDefaultCategory">
</cc-teasers-wrapper>
