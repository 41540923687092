import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DeviceService, Page, UserService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { EmbeddedGamePopupComponent } from '../embedded-game-popup/embedded-game-popup.component';

@Injectable({
    providedIn: 'root',
})
export class GameService {
    constructor(
        private page: Page,
        private user: UserService,
        private dialog: MatDialog,
        private deviceService: DeviceService,
    ) {}

    gameDataVar: any = {};
    dialogRef: any;
    private isVisible = new BehaviorSubject<boolean>(false);
    private gameData = new BehaviorSubject<any>(this.gameDataVar);
    overlayVisibilityStatus: Observable<boolean> = this.isVisible.asObservable();

    setOverlayVisibility(isVisible: boolean) {
        this.isVisible.next(isVisible);
    }
    // NFR - Services optimization - Remove
    openDialog(gameData: any): void {
        gameData.fromEmbedPopUp = true;
        this.dialogRef = this.dialog.open(EmbeddedGamePopupComponent, {
            width: this.deviceService.windowWidth() + 'px',
            data: gameData,
        });
    }

    closeDialog() {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

    gameDataInfo(data: any) {
        this.gameData.next(data);
    }
    // NFR - Services optimization - Remove
    getCategoryGamesFromLocalStorage(categoryType: string) {
        const dataFromSessionStorage = this.getDataFromSessionStorage(this.getCacheKey());
        if (dataFromSessionStorage) {
            const categoryLookUp = JSON.parse(dataFromSessionStorage);
            if (categoryLookUp[categoryType] && categoryLookUp[categoryType].games && categoryLookUp[categoryType].games.length > 0) {
                return this.findGamesInLocalStorage(categoryLookUp[categoryType].games);
            }
        }
        return null;
    }

    getGameDataByGameId(gameId: any) {
        const dataFromLocalStorage = this.getDataFromLocalStorage(gameId + '_GameMetaData');
        if (dataFromLocalStorage) {
            return JSON.parse(dataFromLocalStorage);
        }
        return null;
    }

    findGamesInLocalStorage(gameIds: any[]) {
        const gameDataSet: any = {
            gamesInLocalStorage: null,
            gamesNotAvailableInLocalStorage: null,
        };
        const localStorageGames: any[] = [];
        const localStorageGamesNotAvailable: string[] = [];
        gameIds.forEach((gameId: string) => {
            const dataFromLocalStorage = this.getDataFromLocalStorage(gameId + '_GameMetaData');
            if (dataFromLocalStorage) {
                localStorageGames.push(dataFromLocalStorage);
            } else {
                localStorageGamesNotAvailable.push(gameId);
            }
        });
        gameDataSet.gamesInLocalStorage = localStorageGames;
        gameDataSet.gamesNotAvailableInLocalStorage = localStorageGamesNotAvailable;
        return gameDataSet;
    }
    // NFR - Services optimization - Remove
    getCacheKey() {
        if (this.user.isAuthenticated) {
            return 'allCategoryLookupData_' + this.page.lang + '_' + this.user.username;
        } else {
            return 'allCategoryLookupData_' + this.page.lang;
        }
    }
    // NFR - Services optimization - Remove
    getDataFromSessionStorage(key: string) {
        try {
            return sessionStorage.getItem(key);
        } catch (error) {
            //console.log('error occurred while getting categories from session storage' + error);
            return null;
        }
    }

    getDataFromLocalStorage(key: string) {
        try {
            return localStorage.getItem(key);
        } catch (error) {
            return null;
        }
    }
}
