import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnInit } from '@angular/core';

import { SearchSectionService } from '@casinocore/platform/core';

import { SearchComponent } from '../search/search.component';

@Component({
    selector: 'cc-mobile-search',
    templateUrl: './mobile-search.component.html',
    standalone: true,
    imports: [SearchComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MobileSearchComponent implements OnInit {
    @Input() lobbyType: any;
    isSearchOverlayVisible: any;

    constructor(private searchSectionService: SearchSectionService) {}

    ngOnInit() {
        this.subscribeToSearchOverlayObservable();
    }

    subscribeToSearchOverlayObservable() {
        this.searchSectionService.searchVisibilityStatus.subscribe((searchOverlayVisibilityStatus) => {
            this.isSearchOverlayVisible = searchOverlayVisibilityStatus;
            const htmltag = document.getElementsByTagName('html');
            if (htmltag && htmltag[0]) {
                if (this.isSearchOverlayVisible) {
                    if (!htmltag[0].classList.contains('cdk-global-scrollblock')) {
                        htmltag[0].classList.add('cdk-global-scrollblock');
                    }
                } else {
                    if (htmltag[0].classList.contains('cdk-global-scrollblock')) {
                        htmltag[0].classList.remove('cdk-global-scrollblock');
                    }
                }
            }
        });
    }
}
