<div *ngIf="showstickersinoverlay || showjackpotvalueinoverlay" [ngClass]="{ 'casino-qi-stickers-pos': demoEnabled }">
    <span *ngIf="enableStickerStyles; else standardStyles">
        <div
            [hidden]="!showstickersinoverlay"
            class="casino-qi-game-badge"
            *ngIf="stickers && stickers.length > 0"
            [ngClass]="{ 'stickers-uk': isStickersForUk }">
            <b class="casino-qi-promoStickers" *ngFor="let sticker of stickers; let i = index" [style]="getStyles(sticker.key, i)">{{
                sticker.value
            }}</b>
        </div>
    </span>
    <ng-template #standardStyles>
        <div
            [hidden]="!showstickersinoverlay"
            class="casino-qi-game-badge"
            *ngIf="stickers && stickers.length > 0"
            [ngClass]="{ 'stickers-uk': isStickersForUk }">
            <b
                class="casino-qi-promoStickers"
                *ngFor="let sticker of stickers; let i = index"
                [ngClass]="{ 'nonTouch': !isTouch, 'promo-text-badge-0': i % 2 === 0, 'promo-text-badge-1': i % 2 !== 0 }">
                {{ sticker.value }}</b
            >
        </div>
    </ng-template>
    <div
        class="casino-qi-Jackpotcta"
        *ngIf="showjackpotvalueinoverlay && jackpotValue"
        [ngStyle]="{ background: jpRibbonStyles?.jpBgColor }"
        [ngClass]="{ 'casino-qi-Jackpotcta-pos': demoEnabled }">
        <div class="casino-qi-hot-icon" *ngIf="isHotJackpotGroup && enableHotIcon">
            <img [src]="hotIcon" *ngIf="!enableEpcotSubNavigation" />
            <img [src]="epcotHotIcon" *ngIf="enableEpcotSubNavigation" />
        </div>
        <div class="qi-jp-value" [ngStyle]="{ color: jpRibbonStyles?.jpTextColor }">{{ jackpotValue.amount }}</div>
    </div>
</div>
