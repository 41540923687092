import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import {
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    DesignSystemEnablerService,
    FastLoadingGamesService,
    GameDataManager,
    GameLobbyModel,
    IContentImage,
    IContentLink,
    JackpotDataService,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, GameTileCtaComponent } from '@frontend/casino-ui';
import { NavigationService } from '@frontend/vanilla/core';
import {
    NguCarousel,
    NguCarouselConfig,
    NguCarouselDefDirective,
    NguCarouselNextDirective,
    NguCarouselPrevDirective,
    NguCarouselStore,
    NguItemComponent,
} from '@ngu/carousel';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { JackpotWidgetRedesignGridComponent } from '../jackpot-widget-redesign-grid/jackpot-widget-redesign-grid.component';

@Component({
    selector: 'cc-multi-jackpot-widget-rebrand',
    templateUrl: 'multi-jackpot-widget-rebrand.component.html',
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        NgFor,
        NgStyle,
        JackpotWidgetRedesignGridComponent,
        NguCarouselNextDirective,
        NguCarouselPrevDirective,
        NguCarouselDefDirective,
        NguItemComponent,
        NguCarousel,
        GameTileCtaComponent,
        DecoratorClassDirective,
    ],
})
export class MultiJackpotwidgetRebrandComponent implements OnInit, OnDestroy {
    @ViewChild('myCarousel') myCarousel: NguCarousel<any>;
    @ViewChild('carousel') carousel: any;
    @Input() category: any;
    @Input() isTouch: boolean;
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    @Input() changeCarouselView: boolean;
    @Output() isjackpotFeed = new EventEmitter<object>();
    categoryObj: any;
    jackpotList: any = [];
    classNameTop: string = 'single-jackpot';
    showJackpotWidget: boolean;
    carouselConfig: NguCarouselConfig;
    games: GameLobbyModel[] = [];
    cta: string;
    ctaUrl: IContentLink;
    moveSlideTo: number;
    enableJpGroupTickingForLobby: boolean;
    isPreButtonVisible: boolean;
    isNextButtonVisible: boolean;
    multiJackpotBgClr: string;
    themeArrowLeft: string;
    themeArrowRight: string;
    subCategoryTextColor: string;
    lobbyTileFontFamily: string;
    enableEpcotSubNavigation: boolean;
    multiJackpotAmountClr: string;
    multiJackpotPlayAnyGameText: string;
    multiJackpotAlignment: string;
    multiJackpotLeftArrowStyles: string;
    multiJackpotRightArrowStyles: string;
    multiBackgroundTransparency: boolean;
    jackpotLeftImg: IContentImage;
    jackpotRightImg: IContentImage;
    multiJackpotBottomImage: IContentImage;
    jackpotWidgetBackgroundImage: IContentImage;
    jackpotAmountText: string;
    backgroundImageStyles: string;
    imageType: string;
    multiJackpotPlayAnyGameTextColor: string;
    multiJackpotValueClr: string;
    multiJackpotAmountBtnBgClr: string;
    multiJackpotAmountBgClr: string;
    multiJackpotAmountAnnimation: boolean;
    widgets: any = [];
    widgetsData: any;
    glassEffectColor: string;
    private seeAllText: string;
    private jackpotsCache: any;
    private jackpotWidgetData: any = [];
    private indexCount: number;
    private currentSlideIndex: number;
    totalJpGroupList: any;
    private destroy$ = new Subject<void>();
    JackpotDisplayNameConfig: any;
    isLegacy: boolean = false;
    constructor(
        private jackpotDataService: JackpotDataService,
        private navigation: NavigationService,
        private gameDataManager: GameDataManager,
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
        private fastLoadingGamesService: FastLoadingGamesService,
        private casinoManager: CasinoManager,
        private designSystemEnablerService: DesignSystemEnablerService,
    ) {
        this.currentSlideIndex = 0;
        this.carouselConfig = {
            grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
            slide: 1,
            speed: 400,
            point: {
                visible: true,
            },
            loop: false,
            touch: true,
        };
        this.indexCount = 0;
    }
    ngOnInit(): void {
        const global = this.configProviderService.provideGlobalConfig();
        const subnavConfig: any = this.configProviderService.provideSubNavigationConfig();
        this.multiJackpotBgClr = global?.lobbyConfigurations?.multiJackpotBgClr;
        this.themeArrowLeft = global?.messages?.ThemeArrowLeft;
        this.themeArrowRight = global?.messages?.ThemeArrowRight;
        this.multiJackpotAmountClr = global?.lobbyConfigurations?.multiJackpotAmountClr;
        this.subCategoryTextColor = this.casinoLobbyService.getsubCategoryTextColor(this.category);
        this.lobbyTileFontFamily = this.casinoLobbyService.getLTileFontFamily(this.category);
        const jackpotDataConfig = this.configProviderService.provideJackpotConfig();
        this.enableJpGroupTickingForLobby = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.lobbyType,
            jackpotDataConfig.enableJpGroupTickingForLobbyType,
        );
        this.multiJackpotAlignment = jackpotDataConfig?.jackpotWidgetConfigurations?.MultiJackpotAlignment;
        this.multiJackpotPlayAnyGameText = jackpotDataConfig?.jackpotWidgetConfigurations?.MultiJackpotPlayAnyGameText;
        this.seeAllText = jackpotDataConfig?.jackpotWidgetConfigurations?.SeeAll;
        this.jackpotLeftImg = jackpotDataConfig?.multiJackpotLeftArrowImage;
        this.jackpotRightImg = jackpotDataConfig?.multiJackpotRightArrowImage;
        this.multiJackpotBottomImage = jackpotDataConfig?.multiJackpotBottomImage;
        this.backgroundImageStyles = jackpotDataConfig?.jackpotWidgetConfigurations?.MultiJackpotBackgroundgImageStyles;
        this.multiJackpotLeftArrowStyles = jackpotDataConfig?.jackpotWidgetConfigurations?.MultiJackpotLeftArrowStyles;
        this.multiJackpotRightArrowStyles = jackpotDataConfig?.jackpotWidgetConfigurations?.MultiJackpotRightArrowStyles;
        this.jackpotAmountText = jackpotDataConfig?.jackpotWidgetConfigurations?.MultiJackpotAmountText;
        this.multiJackpotPlayAnyGameTextColor = jackpotDataConfig?.jackpotWidgetConfigurations?.MultiJackpotPlayAnyGameTextColor;
        this.multiJackpotValueClr = jackpotDataConfig?.jackpotWidgetConfigurations?.MultiJackpotValueClr;
        this.multiJackpotAmountBtnBgClr = jackpotDataConfig?.jackpotWidgetConfigurations?.MultiJackpotAmountBtnBgClr;
        this.multiJackpotAmountBgClr = jackpotDataConfig?.jackpotWidgetConfigurations?.MultiJackpotAmountBgClr;
        const glassEffect = jackpotDataConfig?.jackpotWidgetConfigurations?.MultiJackpotGlassEffect;
        const jackpotAnnimation = jackpotDataConfig?.jackpotWidgetConfigurations?.MultiJackpotAmountAnnimation;
        this.isLegacy = !this.designSystemEnablerService.isEnabled();

        if (jackpotDataConfig.enableJackpotSortGames) this.getSortingGames();

        if (glassEffect && glassEffect.toLowerCase() === 'true') {
            this.multiBackgroundTransparency = true;
        }
        this.glassEffectColor = this.multiBackgroundTransparency
            ? jackpotDataConfig?.jackpotWidgetConfigurations?.GlassEffectColor
            : this.multiJackpotBgClr;
        if (jackpotAnnimation && jackpotAnnimation.toLowerCase() === 'true') this.multiJackpotAmountAnnimation = true;

        this.startJackpotFeed();
        this.isPreButtonVisible = true;
        if (this.widgets.length > 1) {
            this.isNextButtonVisible = false;
        } else {
            this.isNextButtonVisible = true;
        }
        this.fastLoadingGamesService.fastLoadingGamesFeedObservable.pipe(takeUntil(this.destroy$)).subscribe((fastLoadingGames: any) => {
            this.fastLoadingGamesService.setFastLoadingGames(this.games, fastLoadingGames);
        });
        this.enableEpcotSubNavigation = subnavConfig.enableEpcotSubNavigation;
        if (this.enableJpGroupTickingForLobby) {
            this.updateTickingValue();
        }
    }
    onmoveFn(data: NguCarouselStore): void {
        this.isPreButtonVisible = data.isFirst;
        this.moveSlideTo = data.slideItems;
        this.isNextButtonVisible = data.isLast;
        this.currentSlideIndex = data.currentSlide;
        this.indexCount = this.currentSlideIndex;
        this.updateWidgetAndSetActiveWidget();
    }

    slideClicked(value: boolean): void {
        if (value === true) {
            if (this.indexCount < this.widgets.length - 1) {
                this.indexCount++;
                this.updateWidgetAndSetActiveWidget();
            } else if (this.indexCount === this.widgets.length - 1) {
                this.indexCount = 0;
                this.updateWidgetAndSetActiveWidget();
            }
        } else {
            if (this.indexCount !== 0) {
                this.indexCount--;
                this.updateWidgetAndSetActiveWidget();
            } else if (this.indexCount === 0) {
                this.indexCount = this.widgets.length - 1;
                this.updateWidgetAndSetActiveWidget();
            }
        }
        this.handleMultiJackpotClick(this.indexCount);
        this.currentSlideIndex = this.indexCount;
    }
    gotoJackpots(ctaUrl: any): void {
        if (ctaUrl) this.navigation.goTo(ctaUrl, { replace: false, forceReload: true });
    }
    private startJackpotFeed(): void {
        this.showJackpotWidget = false;
        this.jackpotDataService.jackpotFeedObservable.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
            if (response) {
                this.refreshJackpotsNew();
                this.isPreButtonVisible = false;
                this.isNextButtonVisible = false;
                if (this.widgets.length > 1) {
                    this.isjackpotFeed.emit({ isJackpotdataForParallax: true });
                }
                if (this.currentSlideIndex == 0) this.isPreButtonVisible = true;
                if (this.currentSlideIndex == this.widgets?.length - 1) this.isNextButtonVisible = true;
            }
        });
    }
    private refreshJackpotsNew(): void {
        if (this.category.sitecoreData.showJackpotWidget) {
            this.JackpotDisplayNameConfig = this.configProviderService.provideGlobalConfig()?.jackpotDisplayNameConfigurations;
            this.jackpotsCache = this.jackpotDataService.getJackpotData();
            const jackpotWidget = this.category.sitecoreData.jackpotWidget.map((v: any) => v.toLowerCase());
            for (const widgetTemp in jackpotWidget) {
                jackpotWidget[widgetTemp] = decodeURIComponent(jackpotWidget[widgetTemp]);
            }
            const jackpotWidgetsItems: any = [];
            this.jackpotWidgetData.length = 0;
            this.widgets.length = 0;
            if (this.jackpotsCache && Object.keys(this.jackpotsCache).length && jackpotWidget && jackpotWidget.length > 1) {
                const jackpotFilterData: any = Object.keys(this.jackpotsCache)
                    .filter((key) => jackpotWidget.includes(key?.toLowerCase()))
                    .reduce((cur, key) => {
                        return Object.assign(cur, { [key]: this.jackpotsCache[key] });
                    }, {});

                for (const key in jackpotFilterData) {
                    const jackpotWidgetItem = this.getJackpotWidgetItem(key, jackpotFilterData);
                    if (jackpotWidgetItem) {
                        jackpotWidgetsItems.push({
                            isActive: false,
                            jackpotValue: jackpotWidgetItem.amount,
                            jackpotDisplayName: jackpotWidgetItem.content,
                            jackpotName: this.JackpotDisplayNameConfig[jackpotWidgetItem.jackpotGroupId]
                                ? this.JackpotDisplayNameConfig[jackpotWidgetItem.jackpotGroupId]
                                : jackpotWidgetItem.jackpotGroupName,
                            jackpotWidgetImage: jackpotWidgetItem.jackpotImage,
                            showBackgroundImage: true,
                            jackpotWidgetBackgroundImage: jackpotWidgetItem.backgroundImage,
                        });
                        this.jackpotWidgetData.push({
                            jackpotName: this.JackpotDisplayNameConfig[jackpotWidgetItem.jackpotGroupId]
                                ? this.JackpotDisplayNameConfig[jackpotWidgetItem.jackpotGroupId]
                                : jackpotWidgetItem.jackpotGroupName,
                            jackpotDisplayName: jackpotWidgetItem.content,
                            jackpotCta: jackpotWidgetItem.ctaLink,
                            jackpotGames: jackpotWidgetItem.gamesList,
                        });
                    }
                }
                if (this.jackpotWidgetData.length > 0) {
                    const defaultWidget = this.jackpotWidgetData[this.currentSlideIndex];
                    this.updateWidget(defaultWidget);
                    this.widgets = jackpotWidgetsItems;
                    this.widgets[this.currentSlideIndex].isActive = true;
                    this.widgetsData = this.jackpotWidgetData;
                    this.showJackpotWidget = true;
                    this.setActiveMultiJackpotWidget(this.currentSlideIndex);
                }
            }
        } else {
            this.showJackpotWidget = false;
        }
    }

    private getJackpotWidgetItem(jackpotWidgetKey: any, jackpots: any) {
        const widgetItem = {};
        for (const jackpot in jackpots) {
            if (jackpot.toLowerCase() === jackpotWidgetKey.toLowerCase()) {
                return jackpots[jackpot];
            } else if (jackpots[jackpot] && jackpots[jackpot].subJackpotDetails) {
                for (const subjackpot of jackpots[jackpot].subJackpotDetails) {
                    if (subjackpot && subjackpot.subJackpotName && subjackpot?.subJackpotName?.toLowerCase() === jackpotWidgetKey?.toLowerCase()) {
                        subjackpot.gamesList = jackpots[jackpot].gamesList;
                        subjackpot.jackpotGroupName = subjackpot.subJackpotName;
                        subjackpot.amount = subjackpot.subJackpotAmount;
                        subjackpot.Value = subjackpot.subJackpotValue;
                        return subjackpot;
                    }
                }
            }
        }
        return widgetItem;
    }

    private handleMultiJackpotClick(index: any): void {
        this.setActiveMultiJackpotWidget(index);
        this.moveSlideTo = index;
    }

    private updateWidget(currentJackpotWidget: any): void {
        let sortGamesList: any;
        if (currentJackpotWidget && currentJackpotWidget.jackpotGames && currentJackpotWidget.jackpotGames.length > 0) {
            if (this.totalJpGroupList && this.totalJpGroupList.length > 0) {
                sortGamesList = this.jackpotDataService.filterJpGroup(this.totalJpGroupList, currentJackpotWidget.jackpotName);
            }
            this.setGames(currentJackpotWidget.jackpotGames, sortGamesList);
        }
        this.ctaUrl = currentJackpotWidget.jackpotCta;
        const ctaText = currentJackpotWidget?.jackpotCta?.text;
        this.cta = (ctaText ? ctaText : this.seeAllText) + ' ' + '(' + this.games?.length + ')';
    }

    private setGames(gameslist: string[], sortGamesList?: any) {
        this.games = [];
        if (gameslist && gameslist.length > 0) {
            let starttileBgMode: boolean = false;
            const newgridbgcolor = this.category?.sitecoreData?.attributes?.newgridbgcolor;
            this.categoryObj = { ...this.category };
            this.categoryObj.rowCount = '-1';
            for (let i = 0; i < gameslist.length; i++) {
                const gameIdString: string = gameslist[i];
                const gameInfo = this.casinoLobbyService.getGameInfo(gameIdString);
                // TODO check for disable games
                if (gameInfo) {
                    let disabledGame: boolean = false;
                    const disabledGames = this.casinoLobbyService.getDisabledGames();
                    disabledGames.forEach((game: string) => {
                        if (game.toLowerCase() === gameInfo.game.toLowerCase()) {
                            disabledGame = true;
                        }
                    });
                    if (!disabledGame) {
                        gameInfo.icon = this.category.isIconSixCategory ? '6' : this.category.isIconSevenCategory ? '7' : '1';
                        gameInfo.lobbyType = this.lobbyType;
                        const gameObj = this.gameDataManager.setGameLobbyModel(gameInfo, this.isTouch);
                        gameObj.gameContainer = this.gameContainer;
                        gameObj.bgTileMode = this.category.isIconSevenCategory ? (starttileBgMode ? starttileBgMode : false) : false;
                        gameObj.gameTileBorderRadius = this.casinoLobbyService.getBorderRadius(this.category);
                        gameObj.gameTileDropShadow = this.casinoLobbyService.getDropShadow(this.category);
                        gameObj.infoIconClass = this.casinoManager.assignGameClass(this.casinoManager.getCurrentWidth(), gameInfo.icon);
                        gameObj.newgridbgcolor = newgridbgcolor;
                        starttileBgMode = !starttileBgMode;
                        this.games.push(gameObj);
                    }
                }
            }
        }
        if (sortGamesList && sortGamesList.length > 0) {
            this.games = this.jackpotDataService.filterGames(sortGamesList, this.games);
        }
    }
    private setActiveMultiJackpotWidget(currentActiveWidgetIndex: number): void {
        try {
            if (this.widgets) {
                this.widgets.forEach((widget: any) => {
                    widget.isActive = false;
                });
                if (this.widgets[currentActiveWidgetIndex]) {
                    this.widgets[currentActiveWidgetIndex].isActive = true;
                }
            }
        } catch (e) {
            //Do nothing
        }
    }

    updateWidgetAndSetActiveWidget(): void {
        this.carousel?.slideClicked(this.indexCount);
        this.updateWidget(this.jackpotWidgetData[this.indexCount]);
        this.setActiveMultiJackpotWidget(this.indexCount);
    }
    getSortingGames(): void {
        this.jackpotDataService.jpSortGamesObservable.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
            if (data) {
                this.totalJpGroupList = data?.jackpotGroupGamesList;
                this.refreshJackpotsNew();
            }
        });
    }
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    updateTickingValue() {
        this.jackpotDataService.jpTickingObservable.subscribe((res: any) => {
            if (res) {
                this.jackpotList = [];
                res?.forEach((jackpot: any) => {
                    this.jackpotList.push(jackpot);
                });
            }
            const ids = this.jackpotList.map((jackpotName: any) => jackpotName);
            const filteredList = this.jackpotList.filter((jackpotName: any, index: any) => !ids.includes(jackpotName, index + 1));
            filteredList?.forEach((item: any) => {
                this.widgets?.forEach((wid: any) => {
                    if (item.jackpotName.toLowerCase() == wid.jackpotName.toLowerCase()) {
                        wid.jackpotValue = item.amount;
                    }
                });
            });
        });
    }
}
