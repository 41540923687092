import { AfterViewInit, Component, OnInit, Type, ViewChild, ViewContainerRef, computed, effect, input, reflectComponentType } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

import {
    CasinocoreErrorHandlerComponent,
    CozybingoQuickinfoDirectComponent,
    MobileSearchComponent,
    QuickInfoDirectComponent,
    ThumbnailSectionComponent,
} from '@casinocore/platform';
import { CasinoLobbyService, CasinoManager, ConfigProviderService } from '@casinocore/platform/core';
import { DeviceService } from '@frontend/vanilla/core';
import { setTimeout as unpatchedSetTimeout } from '@rx-angular/cdk/zone-less/browser';
import { rxEffects } from '@rx-angular/state/effects';
import { isEqual } from 'lodash-es';
import { combineLatest, distinctUntilChanged, filter, map, merge, tap, throttleTime } from 'rxjs';

import { ComponentCompositionsClientConfig } from '../../client-config';
import { PerformanceMarker, PerformanceMarkerIdentifiers } from '../../shared/services/performance-marker.service';
import { ArcadeNavigationSectionComponent } from '../arcade-navigation-section';
import { CasinoGamesSectionComponent } from '../casino-games-section';
import { DynamicPlayerTitleComponent } from '../dynamic-player-title/dynamic-player-title.component';
import { GamesNavSectionComponent } from '../games-nav-section';
import { GamesSubNavSectionComponent } from '../games-sub-nav-section';
import { InstagramSectionComponent } from '../instagram-section';
import { LazyPlaceholderComponent } from '../lazy-placeholder/lazy-placeholder.component';
import { NavSectionComponent } from '../nav-section';
import { SubNavSectionComponent } from '../sub-nav-section';
import { TeaserSectionComponent } from '../teaser-section/teaser-section.component';

@Component({
    selector: 'cs-widgets',
    template: '<ng-template #template />',
    standalone: true,
})
export class WidgetsComponent implements OnInit, AfterViewInit {
    lobbyType = input.required<string>();
    data = input<any>();
    isDefaultComposition = input.required<boolean>();
    categoryRoute = input<string | undefined>();
    currentProduct = input<string | undefined>();

    readonly isMobileWidth = this.deviceService.isMobileWidth;
    enableEpcotSubNavigation: boolean;
    hideNavBarForEpcotTouch: any;
    enablePillsSubNavigation: boolean;

    @ViewChild('template', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

    private componentsDictionary: Record<string, Type<any>> = {
        NavSectionComponent: NavSectionComponent,
        TeaserSectionComponent: TeaserSectionComponent,
        QuickInfoDirectComponent: QuickInfoDirectComponent,
        MobileSearchComponent: MobileSearchComponent,
        CasinoGamesSectionComponent: CasinoGamesSectionComponent,
        GamesNavSectionComponent: GamesNavSectionComponent,
        SubNavSectionComponent: SubNavSectionComponent,
        GamesSubNavSectionComponent: GamesSubNavSectionComponent,
        InstagramSectionComponent: InstagramSectionComponent,
        ThumbnailSectionComponent: ThumbnailSectionComponent,
        CasinocoreErrorHandlerComponent: CasinocoreErrorHandlerComponent,
        CozybingoQuickinfoDirectComponent: CozybingoQuickinfoDirectComponent,
        DynamicPlayerTitleComponent: DynamicPlayerTitleComponent,
        ArcadeNavigationSectionComponent: ArcadeNavigationSectionComponent,
    };

    isLiveCasinoLounge = computed(() => {
        const lobbyType = this.lobbyType();
        const categoryRoute = this.categoryRoute();
        const liveCasinoLoungeConfig = this.configProviderService.provideImmersiveLobbyConfig().enabled
            ? this.configProviderService.provideImmersiveLobbyConfig()
            : this.configProviderService.provideLiveCasinoLoungeConfig();
        return (
            liveCasinoLoungeConfig.enabled &&
            liveCasinoLoungeConfig?.lobbyType === lobbyType &&
            categoryRoute &&
            categoryRoute?.indexOf(liveCasinoLoungeConfig.defaultCategoryId) > -1
        );
    });

    private isLiveCasinoLoungeDefaultCategory = computed(() => {
        const liveCasinoLoungeConfig = this.configProviderService.provideImmersiveLobbyConfig().enabled
            ? this.configProviderService.provideImmersiveLobbyConfig()
            : this.configProviderService.provideLiveCasinoLoungeConfig();
        const defaultCategoryInfo = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(this.lobbyType())?.categoryInfo;
        return defaultCategoryInfo && defaultCategoryInfo.categoryid === liveCasinoLoungeConfig.defaultCategoryId && liveCasinoLoungeConfig.enabled;
    });

    private isImmersiveLobby = computed(() => {
        return this.isLiveCasinoLoungeDefaultCategory() || this.lobbyType()?.toLowerCase() === 'mapgrid';
    });

    private readonly componentComposition = computed(() => {
        const deviceType = this.isMobileWidth() ? 'mobile' : 'desktop';
        const configuration = this.isDefaultComposition() || this.isLiveCasinoLounge() ? 'default' : 'custom';
        return this.compositionConfiguration[deviceType][configuration];
    });

    private readonly componentList = computed<any[]>(
        () => this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType(), this.componentComposition()),
        { equal: isEqual },
    );

    private readonly lobbyType$ = toObservable(this.lobbyType);
    private readonly componentList$ = toObservable(this.componentList);
    private readonly rxEffects = rxEffects();

    private readonly containerRestTrigger$ = merge(
        this.componentList$,
        this.casinoLobbyService.lobbyLoadedObservable.pipe(filter((response) => response?.isPostLogin)),
    );

    private readonly generateComponentTrigger$ = merge(
        this.casinoLobbyService.lobbyLoadedObservable.pipe(filter((response) => response?.isPostLogin)),
        this.casinoManager.reSizeObservable,
        this.lobbyType$,
    ).pipe(
        map(() => ({ isMobile: this.isMobileWidth(), lobbyType: this.lobbyType() })),
        distinctUntilChanged((prev, curr) => prev.lobbyType === curr.lobbyType && prev.isMobile === curr.isMobile),
    );

    private handleComponentGeneration$ = combineLatest([this.containerRestTrigger$, this.generateComponentTrigger$]).pipe(
        throttleTime(500),
        tap(() => this.handleContainerReset()),
        tap(() => this.generateComponents()),
    );

    private handleContainerReset(): void {
        if (this.container) {
            this.container.clear();
        }
    }

    private readonly compositionConfiguration = {
        mobile: {
            default: this.componentCompositionsClientConfig.mobileDefaultHomeComposition,
            custom: this.componentCompositionsClientConfig.mobileNonDefaultHomeComposition,
        },
        desktop: {
            default: this.componentCompositionsClientConfig.desktopDefaultHomeComposition,
            custom: this.componentCompositionsClientConfig.desktopNonDefaultHomeComposition,
        },
    };

    constructor(
        private deviceService: DeviceService,
        private casinoManager: CasinoManager,
        private performanceMarker: PerformanceMarker,
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
        private componentCompositionsClientConfig: ComponentCompositionsClientConfig,
    ) {
        effect(() => {
            if (this.isLiveCasinoLounge()) {
                localStorage.setItem('isLiveCasinoLoungeRoute', '1');
            } else if (localStorage.getItem('isLiveCasinoLoungeRoute')) {
                localStorage.removeItem('isLiveCasinoLoungeRoute');
            }
        });
    }

    ngOnInit() {
        const subNavigationConfig = this.configProviderService.provideSubNavigationConfig();
        this.enableEpcotSubNavigation = subNavigationConfig.enableEpcotSubNavigation;
        this.enablePillsSubNavigation = subNavigationConfig.enablePillsSubNavigation;
        this.hideNavBarForEpcotTouch = subNavigationConfig.hideNavBarForEpcotTouch;
        this.rxEffects.register(this.handleComponentGeneration$);
    }

    private generateComponents() {
        this.checkGameAvailable();
        const components = this.componentList();
        if (!components.length) {
            return;
        }
        const enabledSubNavigation = this.enableEpcotSubNavigation || this.enablePillsSubNavigation;
        const hasHiddenNavigation = this.hasHiddenNavigation(components, enabledSubNavigation);
        const validComponents = components.filter((component) => this.isValidComponent(component, hasHiddenNavigation));

        for (let component of validComponents) {
            this.generateComponentWithLobbyType(component.component, component.data);
        }
    }

    private isValidComponent(component: any, hiddenCondition: boolean): boolean {
        return component?.enabled && !(hiddenCondition && component?.component === this.hideNavBarForEpcotTouch.navigationBarComponent);
    }

    checkGameAvailable() {
        let link = window.location.href;
        link = decodeURIComponent(link);
        const param = 'game-info';
        let gameId = link.substring(link.indexOf(param) + (param?.length || 0));
        if (link.indexOf(param) !== -1) {
            gameId = gameId.indexOf('?') !== -1 ? gameId.substring(0, gameId.indexOf('?')) : gameId;
            gameId = gameId.indexOf('%2F') !== -1 ? gameId.replace('%2F', '/') : gameId;
            gameId = gameId.replace(/\/\/*/g, '');
            const disabledGames = this.casinoLobbyService.getDisabledGames();
            const gameInfoData = this.casinoLobbyService.getGameInfo(gameId);
            if (gameInfoData != null) {
                window.history.pushState({}, '', link);
            } else if (gameInfoData == null || disabledGames.indexOf(gameId) > -1) {
                const url1 = decodeURIComponent(location.href);
                window.history.pushState(url1, '', url1.substring(0, url1.indexOf(param) - 1));
            }
        }
    }

    private hasHiddenNavigation(componentList: any[], enabledSubNavigation: boolean): boolean {
        if (!enabledSubNavigation || !this.deviceService.isTouch) {
            return false;
        }
        const navBarComponent = this.hideNavBarForEpcotTouch.navigationBarComponent;
        const subNavComponent = this.hideNavBarForEpcotTouch.subNavComponent;
        const navigationComponents = componentList.filter(
            (item) => item.component === navBarComponent || (item.component === subNavComponent && item.enabled),
        );
        return navigationComponents.length >= 2;
    }

    generateComponentWithLobbyType(componentName: string, componentDataFromDynacon: any) {
        switch (componentName) {
            case 'SeoBoxSectionComponent':
            case 'RPFavSectionComponent':
            case 'RecentlyplayedGymlSectionComponent':
            case 'ThumbnailHomeComponent':
            case 'NewsFeedSectionV2Component':
            case 'PlayBingoBtnComponent':
            case 'NewsFeedSectionComponent':
            case 'StaticDiscoverFeedWrapperComponent':
            case 'GlobalJackpotTandCComponent':
            case 'LiveGamesSectionComponent':
            case 'GlobalJackpotContainerComponent':
            case 'CozyBingoWidgetComponent':
            case 'SuperButtonSectionComponent':
            case 'PromotionsBannerSectionComponent':
            case 'CasinoGameSelectorSectionComponent':
            case 'GameShowHubSectionComponent':
            case 'NewGameQuickInfoComponent':
            case 'GamingStoriesSectionComponent':
            case 'ChatIntegrationComponent':
            case 'GlobalJackpotWinnersFeedComponent':
            case 'ArcadeTandCComponent':
            case 'ArcadeRPSectionComponent':
                this.createLazyPlaceholder(componentName, componentDataFromDynacon);
                break;
            case 'ImmersiveLobbySectionComponent':
                {
                    if (this.isImmersiveLobby() && this.configProviderService.provideImmersiveLobbyConfig().enabled)
                        this.createLazyPlaceholder(componentName, componentDataFromDynacon);
                    else if (!this.isLiveCasinoLoungeDefaultCategory() && !this.configProviderService.provideLiveCasinoLoungeConfig().enabled)
                        this.createComponent('CasinoGamesSectionComponent', componentDataFromDynacon);
                }
                break;
            case 'LiveCasinoLoungeSectionComponent':
                {
                    if (this.isLiveCasinoLoungeDefaultCategory() && !this.configProviderService.provideImmersiveLobbyConfig().enabled)
                        this.createLazyPlaceholder(componentName, componentDataFromDynacon);
                    else if (!this.configProviderService.provideImmersiveLobbyConfig().enabled)
                        this.createComponent('CasinoGamesSectionComponent', componentDataFromDynacon);
                }
                break;
            default:
                this.createComponent(componentName, componentDataFromDynacon);
        }
    }

    createLazyPlaceholder(componentName: string, componentDataFromDynacon: any) {
        const componentRef = this.container.createComponent(LazyPlaceholderComponent);
        componentRef.setInput('componentName', componentName);
        componentRef.setInput('lobbyType', this.lobbyType());
        componentRef.setInput('currentProduct', this.currentProduct());
        componentRef.setInput('data', this.mergeInputData(this.data(), componentDataFromDynacon));
    }

    createComponent(componentName: string, componentDataFromDynacon: any) {
        const component = this.componentsDictionary[componentName];
        if (component) {
            const componentRef = this.container.createComponent(component);
            const componentMeta = reflectComponentType(componentRef.componentType);
            if (componentMeta?.inputs.find(({ propName }) => propName === 'lobbyType')) {
                componentRef.setInput('lobbyType', this.lobbyType());
            }
            if (componentMeta?.inputs.find(({ propName }) => propName === 'currentProduct')) {
                componentRef.setInput('currentProduct', this.currentProduct());
            }
            if (componentMeta?.inputs.find(({ propName }) => propName === 'data')) {
                componentRef.setInput('data', this.mergeInputData(this.data(), componentDataFromDynacon));
            }
        }
    }

    mergeInputData(src: any, dest: any) {
        const data = dest ? dest : new Object();
        for (const key in src) {
            data[key] = src[key];
        }
        data['isDefaultComposition'] = this.isDefaultComposition();
        return data;
    }

    ngAfterViewInit(): void {
        const casinoConfig = this.configProviderService.provideCasinoConfig();
        if (casinoConfig.enableInitialScrollTop) {
            unpatchedSetTimeout(() => window.scroll(0, 0), casinoConfig.initialScrollTopTimeOut);
        }
        this.performanceMarker.mark(PerformanceMarkerIdentifiers.WidgetsViewInitialized);
    }
}
