import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild, signal } from '@angular/core';
import { MatBottomSheet, MatBottomSheetModule, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { ConfigProviderService, FreespinSummaryService, IContentImage } from '@casinocore/platform/core';
import { DeviceService } from '@frontend/vanilla/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Swiper } from 'swiper/types';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { CasinoCoreLazyPlaceholderModule } from '../casino-core-lazy-placeholder/casino-core-lazy-placeholder.module';
import { LibMockService } from '../services/lib-mock.service';
import { ProminentFreespinsLinkModalInfoComponent } from './prominent-freespins-link-modal-info/prominent-freespins-link-modal-info.component';
import { ProminentFreespinsTimmerComponent } from './prominent-freespins-timmer/prominent-freespins-timer.component';
import { TimeZoneFormatPipe } from './timezone-format.pipe';

@Component({
    selector: 'cc-prominent-freespins-widget',
    templateUrl: 'prominent-freespins-widget.component.html',
    standalone: true,
    imports: [
        MatBottomSheetModule,
        MatDialogModule,
        NgIf,
        NgFor,
        NgClass,
        NgStyle,
        SwiperComponent,
        CasinoCoreLazyPlaceholderModule,
        TimeZoneFormatPipe,
        ProminentFreespinsTimmerComponent,
    ],
})
export class ProminentFreespinsWidgetComponent implements OnInit, OnDestroy, OnChanges {
    @Input() category: any;
    @ViewChild('swiperRef', { static: false }) swiperRef?: SwiperComponent;
    private dialogRef?: MatDialogRef<ProminentFreespinsLinkModalInfoComponent>;
    private bottomSheetRef?: MatBottomSheetRef<ProminentFreespinsLinkModalInfoComponent>;
    readonly isTouch = this.deviceService.isTouch;
    freespinRewardDataObservable: any;
    rewardIds: any[] = [];
    rewardDetails: any[] = [];
    freespinSummaryData: any;
    enableFreeSpinSummary: boolean;
    rewardPercent: number;
    responseFreespinsData: any;
    parallaxInput: any;
    subCategoryInfo: any;
    rewardId: string;
    rewardIdBasedGames: { [key: string]: string[] };
    quickInfoService: any;
    moreinfoUrl: string = '';
    widgetBackgroundImage: IContentImage;
    spinIconImage: IContentImage;
    freeSpinsConfigs: any;
    freespinsWidgetChanged: boolean;
    readonly widgetConfigChanged = signal(true);
    sortInterval: any;
    swiperOptions: SwiperOptions = {
        spaceBetween: 1,
        slidesPerView: 3,
        slidesPerGroup: 1,
        navigation: true,
        pagination: true,
        loop: false,
        rewind: false,
        on: {
            init: (swiper) => this.onSwiperInit(swiper),
        },
    };
    @HostListener('window:orientationchange', ['$event'])
    onOrientationChange(event: any) {
        if (event) {
            this.getDeviceWidth();
        }
    }
    constructor(
        private configProviderService: ConfigProviderService,
        private freespinSummaryService: FreespinSummaryService,
        private bottomSheet: MatBottomSheet,
        private dialog: MatDialog,
        private deviceService: DeviceService,
        private libMockService: LibMockService,
    ) {}
    ngOnChanges(): void {
        this.setFreeSpinsSummaryResponseData();
        this.getDeviceWidth();
    }
    ngOnInit() {
        this.subCategoryInfo = this.category;
        this.assignFeatures();
        this.setFreeSpinsSummaryResponseData();
        //Freespins spins widgets tracking events
        this.freespinSummaryService.addTrackingEvent(
            'casino games',
            'free spins',
            'load',
            'not applicable',
            'free spins overlay',
            'free spins overlay',
        );
        this.getDeviceWidth();
    }

    assignFeatures() {
        // need to refactor this code
        const prominentFreespinsConfig = this.configProviderService.providefreeSpinsClientConfig();
        this.freeSpinsConfigs = prominentFreespinsConfig?.prominentFreeSpinsConfigurations;
        this.quickInfoService = this.libMockService.getQuickInfoService();
        this.enableFreeSpinSummary = prominentFreespinsConfig?.isFreeSpinSummaryEnabled;
        this.widgetBackgroundImage = prominentFreespinsConfig?.prominentFreeSpinsWidgetBackgroundImage;
        this.spinIconImage = prominentFreespinsConfig.ctaSpinBackgroundImage;
    }

    private onSwiperInit(swiper: Swiper): void {
        swiper.params.loop = swiper.slides.length > 1;
        if (!this.isTouch) {
            this.handleSwiperNavigationArrows(swiper);
            swiper.on('slideChange', (swiper) => this.handleSwiperNavigationArrows(swiper));
        }
    }

    replaceText(count: number): string {
        if (this.isTouch) {
            if (count > 1) {
                return `${count} ${this.freeSpinsConfigs?.noOfFreespinsLeft}`;
            } else {
                return `${count} ${this.freeSpinsConfigs?.noOfFreespinLeft}`;
            }
        } else {
            return this.freeSpinsConfigs?.remainingFreeSpinsText.replace('$', count.toString());
        }
    }

    setFreeSpinsSummaryResponseData() {
        if (this.enableFreeSpinSummary) {
            this.freespinRewardDataObservable = this.freespinSummaryService.freeSpinsSummaryDataMapObservable.subscribe((freespinsdata: any) => {
                if (freespinsdata) {
                    this.responseFreespinsData = freespinsdata;
                    if (this.responseFreespinsData) {
                        this.appendFreeSpins();
                    }
                }
            });
        }
    }

    appendFreeSpins() {
        this.widgetConfigChanged.set(false);
        const dateE = Math.floor(Date.now() / 1000);
        if (this.responseFreespinsData?.freeSpinRewardDetails?.rewardDetails) {
            this.rewardDetails = this.responseFreespinsData?.freeSpinRewardDetails?.rewardDetails;
            const uniqObjs: any = [];
            const dupeObjs: any = [];
            this.rewardDetails.forEach((obj: any) =>
                [uniqObjs, dupeObjs][
                    +(this.rewardDetails.map((obj: any) => obj.expiryDate).filter((id: any) => id === obj.expiryDate).length > 1)
                ].push(obj),
            );
            dupeObjs.sort((a: any, b: any) => {
                return b.availableCount - a.availableCount;
            });
            const finalArray = uniqObjs.concat(dupeObjs);
            finalArray.sort((a: any, b: any) => {
                const expiryDateA = parseInt(a.expiryDate, 10);
                const expiryDateB = parseInt(b.expiryDate, 10);
                return expiryDateA - expiryDateB;
            });

            const removeZeroAvailbleCount: any = finalArray.filter((item: any) => item.availableCount != 0);
            // remove expair date widgets
            this.rewardDetails = removeZeroAvailbleCount.filter((item: any) => item.expiryDate - dateE > 0);
            this.freespinSummaryData = this.responseFreespinsData.freeSpinSummaryData;
            this.parallaxInput = {
                sitecoreData: {
                    backgroundImageLayer1: this.subCategoryInfo?.sitecoreData?.prominentFreeSpinsWidgetBackgroundImage1,
                    backgroundImageLayer2: this.subCategoryInfo?.sitecoreData?.prominentFreeSpinsWidgetBackgroundImage2,
                    backgroundImageLayer3: this.subCategoryInfo?.sitecoreData?.prominentFreeSpinsWidgetBackgroundImage3,
                },
            };
            // refresh expaire date is zero
            const nextUpdateTime = (this.rewardDetails[0].expiryDate - dateE) * 1000;
            if (this.sortInterval) {
                clearInterval(this.sortInterval);
            }
            this.sortInterval = setInterval(() => {
                this.setFreeSpinsSummaryResponseData();
            }, nextUpdateTime);
            this.widgetConfigChanged.set(true);
            if (!this.isTouch) {
                if (this.rewardDetails.length == 3) this.swiperOptions.allowSlideNext = false;
            }
        }
        // this.swiperRef?.swiper.update();
    }

    rewardIdBasedOnClick(item: any) {
        this.rewardId = item.userRewardId;
        if (this.rewardId) {
            this.gameListBasedonRewardId(this.rewardId);
        }
    }

    gameListBasedonRewardId(rewardId: any): { [key: string]: string[] } {
        if (rewardId) {
            const gamesdetailsoffreespins = this.freespinSummaryData?.gameDetails;
            const gameList: { [key: string]: string[] } = {};
            Object.keys(gamesdetailsoffreespins).forEach((gamekey) => {
                const rewardIds: number[] = gamesdetailsoffreespins[gamekey]?.rewardIds;
                if (rewardIds && Array.isArray(rewardIds) && rewardIds.includes(rewardId)) {
                    if (!gameList[rewardId]) {
                        gameList[rewardId] = [];
                    }
                    gameList[rewardId].push(gamekey);
                }
            });
            return gameList;
        }
        return {};
    }

    public widgetClick(item: any, index: any) {
        this.rewardIdBasedOnClick(item);
        this.rewardIdBasedGames = this.gameListBasedonRewardId(this.rewardId);
        const combinedData = {
            rewardData: item,
            freespinsRwardIdBasedGamesList: this.rewardIdBasedGames,
        };
        const isMobilePhone = this.deviceService.isMobilePhone;
        if (isMobilePhone) {
            this.bottomSheetRef = this.bottomSheet.open(ProminentFreespinsLinkModalInfoComponent, {
                panelClass: ['casino-freespin-overlay'],
                disableClose: true,
                autoFocus: false,
                data: combinedData,
            });
        } else {
            this.dialogRef = this.dialog.open(ProminentFreespinsLinkModalInfoComponent, {
                panelClass: ['casino-freespin-overlay'],
                disableClose: true,
                autoFocus: false,
                width: this.deviceService.isTablet ? 'auto' : '642px',
                data: combinedData,
            });
        }
        //Freespins spins widgets tracking events
        this.freespinSummaryService.addTrackingEvent(
            'casino games',
            'free spins',
            'click',
            'not applicable',
            'free spins overlay',
            'free spins overlay',
            index,
        );
    }
    ngOnDestroy() {
        this.freespinRewardDataObservable?.unsubscribe();
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = undefined;
        }
        if (this.bottomSheetRef) {
            this.bottomSheetRef.dismiss();
            this.bottomSheetRef = undefined;
        }
        if (this.sortInterval) {
            clearInterval(this.sortInterval);
        }
    }
    handleSwiperNavigationArrows(swiper: Swiper): void {
        if (swiper.realIndex === 0) {
            document.getElementsByClassName('prominent-freespins')[0]?.classList?.add('remove-left-arrow');
        } else {
            document.getElementsByClassName('prominent-freespins')[0]?.classList?.remove('remove-left-arrow');
        }
        swiper.allowSlideNext = swiper.isEnd ? false : true;
    }
    getDeviceWidth() {
        if (!this.isTouch || this.deviceService.isTablet || window.innerWidth >= 768 || document.documentElement.clientWidth >= 768) {
            this.swiperOptions.slidesPerView = 3;
        } else {
            this.swiperOptions.slidesPerView = 1.2;
        }
        // this.appendFreeSpins();
    }
}
