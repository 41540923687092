import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';

import {
    CasinoIFrameOverlayService,
    CasinoLobbyService,
    CasinoManager,
    CasinoWidgetService,
    ConfigProviderService,
    CozyBingoDataService,
    GameDataManager,
    PromotedGamesService,
    RecentGamesService,
    UnfinishedGamesService,
} from '@casinocore/platform/core';
import { DeviceService } from '@frontend/vanilla/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { RxStrategyProvider } from '@rx-angular/cdk/render-strategies';
import { cloneDeep } from 'lodash-es';
import { Swiper, SwiperOptions } from 'swiper/types';

import { CozyBingoGameComponent } from '../cozy-bingo-game/cozy-bingo-game.component';
import { GameLobbyComponent } from '../gamelobby/gamelobby.component';

export class RgWidgetGameModel {
    gameId: string;
    sticker: string;
}
export class RCP_GYML_WidgetGame {
    GameId: string;
    isRecentlyPlayed: boolean;
    isGYML: boolean;
    sticker: string;
    fromCasino: boolean = true;
    name: string;
    ownerId: any;
    isPromoted: boolean;
    fromCozybingo: boolean;
    constructor(gameId: string, isRecentlyPlayed: boolean, isGYML: boolean, fromCasino: boolean, isPromoted: boolean, fromCozybingo: boolean) {
        this.GameId = gameId;
        this.isRecentlyPlayed = isRecentlyPlayed;
        this.isGYML = isGYML;
        this.fromCasino = fromCasino;
        this.isPromoted = isPromoted;
        this.fromCozybingo = fromCozybingo;
    }
}
@Component({
    selector: 'cc-rcp-gyml-widget',
    templateUrl: 'rp-gyml-widget.component.html',
    standalone: true,
    imports: [NgClass, NgIf, NgStyle, NgFor, CozyBingoGameComponent, GameLobbyComponent, SwiperComponent],
})
export class RpGymlWidgetComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() lobbyType: string;
    @Input() isTouch: any;
    @Input() gameContainer: string;
    @ViewChild('recentGYMLCarousel') swiperRef: SwiperComponent;
    gameLaunchedFromLoc: any;
    recentCategory: any;
    gymlCategory: any;
    recentlyandGymlGames: any = [];
    baseGameCount: number;
    recentStartIndex: number = 0;
    maxScrollPosition: number;
    staticGYML: boolean;
    unfinishedGames: any = [];
    Games: any[] = [];
    isCombinedCategories: boolean = false;
    subcategory: any;
    onlyRecentGames: any[] = [];
    onlyRecentGamesStrings: any[] = [];
    onlyGYMLGamesStrings: any[] = [];
    onlyGYMLGames: any[] = [];
    youMightLikeMessage: any;
    gymlGames: any = [];
    recentGames: any = [];
    recentlyPlayedGames: any = [];
    gymlGameStrings: any = [];
    unfinishedRPANDGYMLGameIds: RCP_GYML_WidgetGame[] = [];
    unfinishedRPANDGYMLGames: any = [];
    recentGamesCount: number = 0;
    subcategoryNew: any;
    isCombinedCategoriesNew: boolean = false;
    recentsObs: any;
    unfinishedObv: any;
    categoryLookupObv: any;
    defaultCategoryLookupObv: any;
    lobbyObs: any;
    isUkHeading: boolean;
    alignIconRight: boolean;
    noRecentGamesIcon: string;
    WidgetFrameClr: string;
    WidgetBackgroundClr: string;
    WidgetDefaultMsgTxtColor: string;
    elms: number = 6;
    public isRCPWidget: boolean = false;
    isCategoryRcpWidget: boolean;

    currentWidgetHeight: number = 0;
    newWidgetHeight: number = 0;
    interval: any;
    maxHeightCount: number;
    casinoConfig: any;
    Global: any;
    features: any;
    widgetConfig: any;
    zeroRecentGames: boolean = false;
    promotedGamesSubscriber: any;
    recentGamesSubscriber: any;
    bingoRoomDataSubscriber: any;
    bingoVfSchedulerSubscriber: any;
    cozyBingoRecentGames: any = [];
    cozyBingoSubscriber: any;
    cozyBingoRecentGamesInRpwidget: boolean;
    lastElementIndex: number;
    gymlCategoryRef: any;
    recommendedGamesBackgroundColor: string;

    swiperOptions: SwiperOptions = {
        speed: 400,
        autoHeight: true,
        spaceBetween: 5,
        slidesPerView: 6,
        navigation: true,
        slidesPerGroup: 6,
        on: {
            slideChange: (swiper) => this.onIndexChanged(swiper),
        },
    };

    constructor(
        private gameDataManager: GameDataManager,
        private recentGameService: RecentGamesService,
        private elementRef: ElementRef,
        private unfinishedGameService: UnfinishedGamesService,
        private casinoManager: CasinoManager,
        private configProviderService: ConfigProviderService,
        private casinoWidgetService: CasinoWidgetService,
        private casinoIFrameOverlayService: CasinoIFrameOverlayService,
        private casinoLobbyService: CasinoLobbyService,
        private promotedGamesService: PromotedGamesService,
        private cozyBingoDataService: CozyBingoDataService,
        private deviceService: DeviceService,
        private strategy: RxStrategyProvider,
    ) {
        this.isCategoryRcpWidget = true;
    }

    ngOnInit() {
        const global = this.configProviderService.provideGlobalConfig();
        this.recommendedGamesBackgroundColor = global?.messages['RecommendedGamesBackground'];
        this.youMightLikeMessage = this.Global.messages['LobbyGamesYouMightLike']
            ? this.Global.messages['LobbyGamesYouMightLike']
            : this.Global.messages['GamesYouMightLike'];
        this.gameLaunchedFromLoc = 'RecentlyPlayed_widget_RecentlyPlayed';
        this.noRecentGamesIcon = this.Global.messages['WidgetnoRecentGamesIcon'];
        this.WidgetFrameClr = this.Global.messages['WidgetframeClr'];
        this.WidgetDefaultMsgTxtColor = this.Global.messages['rcpWidgetDefaultMsgTxtColor'];
        this.WidgetBackgroundClr = this.Global.messages['WidgetBackgroundColor'];
        const cozybingoConfig: any = this.configProviderService.provideCozyBingoConfig();
        this.cozyBingoRecentGamesInRpwidget = cozybingoConfig.cozyBingoRecentGamesInRpwidget;
        const gymlCategorydata = cloneDeep(this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML'));
        // let gymlCategorydata = {...this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML')};

        if (gymlCategorydata) this.gymlCategory = gymlCategorydata.categoryInfo;
        // if (this.gymlCategory) {
        //     this.gymlCategory.rowCount = '-1';
        // }
        this.isRCPWidget = this.casinoWidgetService.isCasinoWidget();
        this.maxHeightCount = this.widgetConfig.maxHeightCount;
        const recentCategorydata = cloneDeep(this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'recentgames'));
        // let recentCategorydata = {...this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'recentgames')};

        if (recentCategorydata) this.recentCategory = recentCategorydata.categoryInfo;
        this.recentlyPlayedGames = this.recentGameService.getrecentGamesFromAllLobby();

        this.setGamesOnInit();
        this.promotedGamesSubscriber = this.promotedGamesService.promotedFeedFromAllLobbyObservable.subscribe((promotedGames: any) => {
            if (promotedGames) {
                let gymlCategoryInfo: any;
                const gymlCategorydata = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
                if (gymlCategorydata) {
                    gymlCategoryInfo = gymlCategorydata.categoryInfo;
                }
                this.setUnfinishedRPAndGYML(
                    this.promotedGamesService.getPromotedGamesFromAllLobby(),
                    this.unfinishedGameService.getUnfinishedGamesFromAllLobby(),
                    this.recentGameService.getrecentGamesFromAllLobby(),
                    this.cozyBingoDataService.getcozybingorecentgames(),
                    this.getGymlGameIds(gymlCategoryInfo),
                );
            }
        });
        this.recentGamesSubscriber = this.recentGameService.recentGamesFeedFromAllLobbyObservable.subscribe((e: any) => {
            if (this.recentCategory && e) {
                this.recentlyPlayedGames = e;
                let gymlCategoryInfo: any;
                const gymlCategorydata = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
                if (gymlCategorydata) {
                    gymlCategoryInfo = gymlCategorydata.categoryInfo;
                }
                this.setUnfinishedRPAndGYML(
                    this.promotedGamesService.getPromotedGamesFromAllLobby(),
                    this.unfinishedGameService.getUnfinishedGamesFromAllLobby(),
                    this.recentlyPlayedGames,
                    this.cozyBingoDataService.getcozybingorecentgames(),
                    this.getGymlGameIds(gymlCategoryInfo),
                );
            }
        });

        this.alignIconRight = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, this.features.alignIconRight);
        this.unfinishedObv = this.unfinishedGameService.unfinishedFeedFromAllLobbyObservable.subscribe((response: any) => {
            if (response) {
                this.unfinishedGames = response;
                let gymlCategoryInfo: any;
                const gymlCategorydata = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
                if (gymlCategorydata) {
                    gymlCategoryInfo = gymlCategorydata.categoryInfo;
                }
                this.setUnfinishedRPAndGYML(
                    this.promotedGamesService.getPromotedGamesFromAllLobby(),
                    response,
                    this.recentGameService.getrecentGamesFromAllLobby(),
                    this.cozyBingoDataService.getcozybingorecentgames(),
                    this.getGymlGameIds(gymlCategoryInfo),
                );
            }
        });
        if (this.cozyBingoRecentGamesInRpwidget) {
            this.cozyBingoSubscriber = this.cozyBingoDataService.cozyBingoRoomsDataObservable.subscribe((cozyRecentGamesresponse: any) => {
                if (cozyRecentGamesresponse) {
                    this.cozyBingoRecentGames = this.cozyBingoDataService.getcozybingorecentgames();
                    let gymlCategoryInfo: any;
                    const gymlCategorydata = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
                    if (gymlCategorydata) {
                        gymlCategoryInfo = gymlCategorydata.categoryInfo;
                    }
                    this.setUnfinishedRPAndGYML(
                        this.promotedGamesService.getPromotedGamesFromAllLobby(),
                        this.unfinishedGameService.getUnfinishedGamesFromAllLobby(),
                        this.recentGameService.getrecentGamesFromAllLobby(),
                        this.cozyBingoRecentGames,
                        this.getGymlGameIds(gymlCategoryInfo),
                    );
                }
            });
        }
        if (this.recentCategory) this.recentCategory.categoryIndex = 1;
        if (this.gymlCategory) this.gymlCategory.categoryIndex = 1;
        if (this.subcategory) this.subcategory.categoryIndex = 1;
        this.isUkHeading = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, this.features.isUkHeading);
    }
    ngOnChanges() {
        this.assignConfig();
        this.cozyBingoRecentGames = this.cozyBingoDataService.getcozybingorecentgames();
        let gymlCategoryInfo: any;
        const gymlCategorydata = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
        if (gymlCategorydata) {
            gymlCategoryInfo = gymlCategorydata.categoryInfo;
        }
        this.setUnfinishedRPAndGYML(
            this.promotedGamesService.getPromotedGamesFromAllLobby(),
            this.unfinishedGameService.getUnfinishedGamesFromAllLobby(),
            this.recentGameService.getrecentGamesFromAllLobby(),
            this.cozyBingoRecentGames,
            this.getGymlGameIds(gymlCategoryInfo),
        );
    }
    assignConfig() {
        this.casinoConfig = this.configProviderService.provideCasinoConfig();
        this.Global = this.configProviderService.provideGlobalConfig();
        this.features = this.configProviderService.provideFeaturesConfig();
        this.widgetConfig = this.configProviderService.providewidgetConfig();
    }

    postHeight(): void {
        if (this.casinoWidgetService.isCasinoWidget() && this.unfinishedRPANDGYMLGames.length > 0) {
            const widget = document.getElementById('sticky-container');
            if (widget) {
                this.currentWidgetHeight = widget.scrollHeight;
                const params = {
                    categoryid: 'recentgameswidget',
                    displayName: this.recentGamesCount > 0 ? this.recentCategory.categoryname : this.gymlCategory.categoryname,
                    height: this.currentWidgetHeight,
                };
                this.casinoWidgetService.postMessageToParent('LobbyLoaded', params);
                if (!this.interval) {
                    this.interval = setInterval(() => {
                        this.checkAndUpdateWidgetHeight();
                    }, 500);
                }
            }
        }
    }

    checkAndUpdateWidgetHeight() {
        const stickyContainer = document.getElementById('sticky-container');
        if (stickyContainer) {
            this.newWidgetHeight = stickyContainer.scrollHeight;
            if (this.currentWidgetHeight === this.newWidgetHeight) {
                this.maxHeightCount--;
            }
            if (!this.maxHeightCount) {
                clearInterval(this.interval);
            }
            if (this.newWidgetHeight !== this.currentWidgetHeight) {
                this.currentWidgetHeight = this.newWidgetHeight;
                const params = {
                    categoryid: 'recentgameswidget',
                    displayName: this.recentGamesCount > 0 ? this.recentCategory.categoryname : this.gymlCategory.categoryname,
                    height: stickyContainer.scrollHeight,
                };
                this.casinoWidgetService.postMessageToParent('LobbyLoaded', params);
            }
        }
    }

    setGamesOnInit() {
        const unfinishedGames = this.unfinishedGameService.getUnfinishedGamesFromAllLobby();
        const recentGames = this.recentGameService.getrecentGamesFromAllLobby();
        const cozyBingoRecentGames = this.cozyBingoDataService.getcozybingorecentgames();
        const gymlCategoryData = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
        const promotedGames = this.promotedGamesService.getPromotedGamesFromAllLobby();
        if (gymlCategoryData) this.gymlCategory = gymlCategoryData.categoryInfo;
        let gymlGames: any;
        if (this.gymlCategory) {
            //this.gymlCategory.rowCount = '-1';
            gymlGames = this.getGymlGameIds(this.gymlCategory);
        }

        this.setUnfinishedRPAndGYML(promotedGames, unfinishedGames, recentGames, cozyBingoRecentGames, gymlGames);
    }

    getGymlGameIds(gymlCategory: any): any[] {
        const gymlGames: RgWidgetGameModel[] = [];
        if (gymlCategory && gymlCategory.gamelist && gymlCategory.gamelist.length > 0) {
            for (let i = 0; i < gymlCategory.gamelist.length; i++) {
                const gameModel: RgWidgetGameModel = new RgWidgetGameModel();
                gameModel.gameId = gymlCategory.gamelist[i].game;
                gameModel.sticker = gymlCategory.gamelist[i].sticker;
                gymlGames.push(gameModel);
            }
        }
        return gymlGames;
    }

    setUnfinishedRPAndGYML(
        promotedGames: string[],
        unfinishedGames: string[],
        recentGames: string[],
        cozyBingoRecentGames: string[],
        gymlGames: RgWidgetGameModel[],
    ) {
        if (!unfinishedGames) {
            unfinishedGames = [];
        }
        if (!recentGames) {
            recentGames = [];
        }
        if (!gymlGames) {
            gymlGames = [];
        }
        if (!promotedGames) {
            promotedGames = [];
        }
        if (!cozyBingoRecentGames) {
            cozyBingoRecentGames = [];
        }

        const unfinishedRecentArray: RCP_GYML_WidgetGame[] = this.concatUnfinishedAndRecentGames(
            promotedGames,
            unfinishedGames,
            recentGames,
            cozyBingoRecentGames,
        );

        this.unfinishedRPANDGYMLGameIds = this.concatUnfinishedRecentANDGYMLGames(unfinishedRecentArray, gymlGames);
        this.processRecentGYMLGames();
    }

    concatUnfinishedAndRecentGames(
        promotedGames: string[],
        unfinishedGames: string[],
        recentGames: string[],
        cozyBingoRecentGames: string[],
    ): RCP_GYML_WidgetGame[] {
        let unfinishedRecentArray: RCP_GYML_WidgetGame[] = [];
        for (let i = 0; i < unfinishedGames.length; i++) {
            unfinishedRecentArray.push(new RCP_GYML_WidgetGame(unfinishedGames[i], true, false, true, false, false));
        }
        for (let i = 0; i < recentGames.length; i++) {
            const filteredRecentGame: any = unfinishedRecentArray.filter((unfinishedGame) => {
                return unfinishedGame.GameId === recentGames[i];
            });
            if (!(filteredRecentGame.length > 0)) {
                const recentGame: RCP_GYML_WidgetGame = new RCP_GYML_WidgetGame(recentGames[i], true, false, true, false, false);
                if (unfinishedRecentArray.indexOf(recentGame) === -1) unfinishedRecentArray.push(recentGame);
            }
        }

        for (let i = 0; i < promotedGames.length; i++) {
            const filteredPromotedGame: any = unfinishedRecentArray.filter((promotedGame) => {
                return promotedGame.GameId === promotedGames[i];
            });
            if (!(filteredPromotedGame.length > 0)) {
                const promotedGame: RCP_GYML_WidgetGame = new RCP_GYML_WidgetGame(promotedGames[i], true, false, true, true, false);
                if (unfinishedRecentArray.indexOf(promotedGame) === -1) unfinishedRecentArray.push(promotedGame);
            }
        }

        const filteredRecentArray: any = [];
        for (let i = 0; i < unfinishedRecentArray.length; i++) {
            const gameInfo = this.casinoLobbyService.getGameInfo(unfinishedRecentArray[i].GameId);
            if (gameInfo) {
                filteredRecentArray.push(unfinishedRecentArray[i]);
            }
        }
        unfinishedRecentArray = filteredRecentArray;

        if (this.cozyBingoRecentGamesInRpwidget) {
            for (let i = 0; i < cozyBingoRecentGames.length; i++) {
                const cozyrecentGame: RCP_GYML_WidgetGame = new RCP_GYML_WidgetGame(cozyBingoRecentGames[i], true, false, false, false, true);
                if (unfinishedRecentArray.indexOf(cozyrecentGame) === -1) unfinishedRecentArray.push(cozyrecentGame);
            }
        }
        this.zeroRecentGames = !unfinishedRecentArray || unfinishedRecentArray.length == 0;
        return unfinishedRecentArray;
    }

    assignRecommendedWidgetColors(game: any) {
        if (game.isGYML) {
            if (this.casinoWidgetService.isCasinoWidget()) {
                return 'gyml-outerframe-casinowidget';
            } else if (this.casinoIFrameOverlayService.isOverLayOnAProduct()) {
                return 'gyml-outerframe-bg-overlay';
            } else {
                return 'gyml-outerframe-casino';
            }
        }
        return '';
    }

    concatUnfinishedRecentANDGYMLGames(unfinishedRecentGames: RCP_GYML_WidgetGame[], gymlGames: RgWidgetGameModel[]): RCP_GYML_WidgetGame[] {
        const unfinishedRecentGYMLArray: RCP_GYML_WidgetGame[] = unfinishedRecentGames;
        for (let game of gymlGames) {
            const gymlGameInRecentArray: any = unfinishedRecentGYMLArray.find((a) => a.GameId === game.gameId);
            if (!gymlGameInRecentArray) {
                const gymlItem: RCP_GYML_WidgetGame = new RCP_GYML_WidgetGame(game.gameId, false, true, true, false, false);
                gymlItem.sticker = game.sticker;

                const gameInfo = this.casinoLobbyService.getGameInfo(gymlItem.GameId);
                if (gameInfo) {
                    gymlItem.name = gameInfo.Title;
                    gymlItem.ownerId = gameInfo.ownerId;
                    unfinishedRecentGYMLArray.push(gymlItem);
                }
                // filter from disable games this.casinoLobbyService.getDisabledGames()
            }
        }

        return unfinishedRecentGYMLArray;
    }

    processRecentGYMLGames() {
        this.sliceGYMLLimitAndSetBaseGameCount();
        this.setGames();
        this.setIsCombinedCategories();
        this.updateGYMLCategoryRowCount();
    }

    sliceGYMLLimitAndSetBaseGameCount() {
        const unfinishedOrRecentGames = this.unfinishedRPANDGYMLGameIds.filter((game: any) => game.isRecentlyPlayed);
        if (unfinishedOrRecentGames) {
            this.recentGamesCount = unfinishedOrRecentGames.length;
        }

        let gymlGames = this.unfinishedRPANDGYMLGameIds.filter((game: any) => !game.isRecentlyPlayed);
        gymlGames = gymlGames.slice(0, this.casinoConfig.gymlLimit);
        this.unfinishedRPANDGYMLGameIds = [];
        this.unfinishedRPANDGYMLGameIds = this.unfinishedRPANDGYMLGameIds.concat(unfinishedOrRecentGames);
        this.unfinishedRPANDGYMLGameIds = this.unfinishedRPANDGYMLGameIds.concat(gymlGames);
    }

    setGames() {
        if (this.unfinishedRPANDGYMLGameIds && this.unfinishedRPANDGYMLGameIds.length > 0) {
            const gamesModel: any[] = [];
            let GameModelItem: any;
            for (let i = 0; i < this.unfinishedRPANDGYMLGameIds.length; i++) {
                if (this.unfinishedRPANDGYMLGameIds[i].fromCasino) {
                    const gameInfo = this.casinoLobbyService.getGameInfo(this.unfinishedRPANDGYMLGameIds[i].GameId);
                    if (gameInfo) {
                        GameModelItem = {
                            game: this.unfinishedRPANDGYMLGameIds[i].GameId,
                            icon: '1',
                            isRecentlyPlayed: this.unfinishedRPANDGYMLGameIds[i].isRecentlyPlayed,
                            isGYML: this.unfinishedRPANDGYMLGameIds[i].isGYML,
                            sticker: this.unfinishedRPANDGYMLGameIds[i].sticker,
                            fromCasino: this.unfinishedRPANDGYMLGameIds[i].fromCasino,
                            name: gameInfo.name,
                            provider: gameInfo.provider,
                            lobbyType: this.lobbyType,
                            ownerId: gameInfo.ownerId,
                            isPromoted: this.unfinishedRPANDGYMLGameIds[i].isPromoted,
                            fromCozybingo: this.unfinishedRPANDGYMLGameIds[i].fromCozybingo,
                        };
                    }
                } else if (this.unfinishedRPANDGYMLGameIds[i].fromCozybingo) {
                    GameModelItem = this.cozyBingoDataService.getRoomdatabyId(this.unfinishedRPANDGYMLGameIds[i].GameId);
                    if (GameModelItem) {
                        GameModelItem.fromCozybingo = this.unfinishedRPANDGYMLGameIds[i].fromCozybingo;
                        GameModelItem.fromCasino = false;
                        GameModelItem.isRecentlyPlayed = this.unfinishedRPANDGYMLGameIds[i].isRecentlyPlayed;
                        GameModelItem.gameId = GameModelItem.id;
                        GameModelItem.lobbyType = this.lobbyType;
                    }
                }
                if (GameModelItem) {
                    gamesModel.push(GameModelItem);
                }
            }
            this.setDefaultLMTGameIcons(gamesModel);
        } else if (this.unfinishedRPANDGYMLGameIds && this.unfinishedRPANDGYMLGameIds.length === 0) {
            this.Games = [];
            this.unfinishedRPANDGYMLGames = [];
        }
    }

    setDefaultLMTGameIcons(gamesModellist: any[]) {
        this.Games = [];
        this.unfinishedRPANDGYMLGames = [];
        const disabledGames: any = this.casinoLobbyService.getDisabledGames();
        let otherProductRecentGames = [];
        otherProductRecentGames = this.cozyBingoDataService.getcozybingorecentgames();
        if (
            this.recentGameService.getrecentGamesFromAllLobby() &&
            this.recentGameService.getrecentGamesFromAllLobby().length === 0 &&
            otherProductRecentGames &&
            otherProductRecentGames.length === 0 &&
            this.promotedGamesService.getPromotedGamesFromAllLobby() &&
            this.promotedGamesService.getPromotedGamesFromAllLobby().length === 0
        ) {
            this.elms = this.deviceService.windowWidth() < 321 ? 3 : 5;
        } else {
            this.elms = this.deviceService.windowWidth() < 321 ? 3 : 6;
        }
        this.strategy
            .schedule(() => {
                if (this.swiperRef?.swiper) {
                    this.swiperRef.swiper.update();
                }
            })
            .subscribe();
        if (gamesModellist && gamesModellist.length > 0) {
            for (let i = 0; i < gamesModellist.length; i++) {
                const gameModelItem: any = gamesModellist[i];
                let filteredGame: any;
                if (!gameModelItem.fromCozybingo) {
                    filteredGame = disabledGames.filter((disabledGame: any) => disabledGame === gameModelItem.game);
                }
                let gamelobbyModel: any = {};
                if (!gameModelItem.fromCozybingo) {
                    if (!(filteredGame && filteredGame.length > 0)) {
                        gamelobbyModel = this.gameDataManager.setGameLobbyModel(gameModelItem, this.isTouch);
                        gamelobbyModel.isRecentlyPlayed = gamesModellist[i].isRecentlyPlayed;
                        gamelobbyModel.isUnfinished = this.getUnfinishedStatus(gamelobbyModel.gameId);
                        gamelobbyModel.isGYML = gamesModellist[i].isGYML;
                        gamelobbyModel.fromCasino = gamesModellist[i].fromCasino;
                        gamelobbyModel.gameSection = 'RecentlyPlayed_widget_RecentlyPlayed';
                        gamelobbyModel.gameContainer = this.gameContainer;
                        gamelobbyModel.isPromoted = gamesModellist[i].isPromoted;
                        gamelobbyModel.fromCozybingo = gamesModellist[i].fromCozybingo;
                    }
                } else {
                    gamelobbyModel = gameModelItem;
                    gamelobbyModel.gameSection = 'RecentlyPlayed_widget_RecentlyPlayed';
                    gamelobbyModel.gameContainer = this.gameContainer;
                }
                if (!(filteredGame && filteredGame.length > 0)) {
                    gamelobbyModel.dataIndex = i;
                    gamelobbyModel.data = JSON.parse(JSON.stringify(gamelobbyModel));
                    this.Games.push(gamelobbyModel);
                    this.unfinishedRPANDGYMLGames.push(gamelobbyModel);
                }
            }
            const iconType = '1';
            const currentWidth = this.casinoManager.getCurrentWidth();
            this.unfinishedRPANDGYMLGames.forEach((game: any) => {
                game.infoIconClass = this.casinoManager.assignGameClass(currentWidth, iconType);
            });

            const recentGamesModel: any = gamesModellist.filter((gameModel) => gameModel.isRecentlyPlayed);
            if (this.recentCategory) {
                this.recentCategory.gamesModel = recentGamesModel;
            }
            // if (this.unfinishedRPANDGYMLGames.length > 6 || (this.isNoIconBoxVisible() && this.unfinishedRPANDGYMLGames.length > 5)) {
            //     this.hideNextButton = false;
            // } else {
            //     this.hideNextButton = true;
            // }
            // if (this.unfinishedRPANDGYMLGames.length == 6 && this.isNoIconBoxVisible()) {
            //     this.hideNextButton = true;
            // }
        }
    }
    getUnfinishedStatus(gameId: string): boolean {
        const unfinishedGamesArray: any = this.unfinishedGameService.getUnfinishedGamesFromAllLobby();
        return unfinishedGamesArray.indexOf(gameId) != -1;
    }
    setIsCombinedCategories() {
        this.subcategory = undefined;
        const recentGamesCountLimit = this.isRCPWidget ? this.widgetConfig.recentGamesCountLimit : this.casinoConfig.recentGamesCountLimit;
        if (this.unfinishedRPANDGYMLGames) {
            const recentGames = this.unfinishedRPANDGYMLGames.filter((game: any) => game.isRecentlyPlayed);
            const gymlGames = this.unfinishedRPANDGYMLGames.filter((game: any) => !game.isRecentlyPlayed);
            if (
                !recentGames ||
                !gymlGames ||
                (recentGames && (recentGames.length === 0 || recentGames.length >= recentGamesCountLimit)) ||
                (gymlGames && gymlGames.length > 0 && (!recentGames || (recentGames && recentGames.length === 0)))
            ) {
                this.isCombinedCategories = false;

                if (recentGames.length === 0 && this.gymlCategory && gymlGames.length > 0) {
                    this.subcategory = this.gymlCategory;
                    this.unfinishedRPANDGYMLGames = this.unfinishedRPANDGYMLGames.filter((game: any) => !game.isRecentlyPlayed);
                    this.Games = this.unfinishedRPANDGYMLGames;
                } else if (
                    (recentGames.length >= recentGamesCountLimit && this.recentCategory) ||
                    (recentGames.length > 0 && this.recentCategory && !this.gymlCategory)
                ) {
                    this.subcategory = this.recentCategory;
                    this.unfinishedRPANDGYMLGames = this.unfinishedRPANDGYMLGames.filter((game: any) => game.isRecentlyPlayed);
                    this.Games = this.unfinishedRPANDGYMLGames;
                }
            } else {
                this.isCombinedCategories = true;
            }
        }
        const iconType = '1';
        const currentWidth = this.casinoManager.getCurrentWidth();
        const promotedGames = this.unfinishedRPANDGYMLGames.filter((game: any) => game.isPromoted);
        const nonPromotedGames = this.unfinishedRPANDGYMLGames.filter((game: any) => !game.isPromoted);
        this.unfinishedRPANDGYMLGames = [];
        this.unfinishedRPANDGYMLGames = this.unfinishedRPANDGYMLGames.concat(promotedGames);
        this.unfinishedRPANDGYMLGames = this.unfinishedRPANDGYMLGames.concat(nonPromotedGames);
        this.unfinishedRPANDGYMLGames.forEach((game: any) => {
            game.infoIconClass = this.casinoManager.assignGameClass(currentWidth, iconType);
        });
        const slidesPerOptions = this.zeroRecentGames ? 5 : 6;
        this.swiperOptions.slidesPerView = slidesPerOptions;
        this.swiperOptions.slidesPerGroup = slidesPerOptions;
        if (this.swiperRef) {
            this.swiperRef.swiper.params.slidesPerView = this.swiperOptions.slidesPerView;
            this.swiperRef.swiper.params.slidesPerGroup = this.swiperOptions.slidesPerGroup;
        }
    }
    isNoIconBoxVisible(): boolean {
        const cozyBingoRecentGames = this.cozyBingoDataService.getcozybingorecentgames();
        return this.recentlyPlayedGames && this.recentlyPlayedGames.length == 0 && cozyBingoRecentGames && cozyBingoRecentGames.length == 0;
    }

    ngAfterViewInit() {
        if (document.getElementById('gymlId')) {
            this.maxScrollPosition = document.getElementById('gymlId')!.offsetLeft;
        }
    }

    //previous methods

    setSubCategoryStyle() {
        if (this.isTouch) {
            return 'scrollable-games';
        }
        return 'unscrollable-games';
    }

    scrollGYML() {
        const firstGamesScrollLeft = this.elementRef.nativeElement.getElementsByClassName('scrollable-games')[0].scrollLeft;
        if (firstGamesScrollLeft >= this.maxScrollPosition) {
            this.staticGYML = true;
            setTimeout(() => {
                document.getElementById('sticky-container')?.setAttribute('position', 'relative');
            }, 500);
        } else {
            this.staticGYML = false;
        }
    }

    onIndexChanged(swiper: Swiper): void {
        if (swiper.slides.length) {
            this.lastElementIndex = swiper.activeIndex + this.elms - 1;
        }
    }

    trackGame(gameData: any) {
        if (gameData.icon && gameData.icon.src) {
            return gameData.gameId + gameData.icon.src;
        }
    }

    ngOnDestroy() {
        if (this.unfinishedObv) {
            this.unfinishedObv.unsubscribe();
        }
        if (this.promotedGamesSubscriber) {
            this.promotedGamesSubscriber.unsubscribe();
        }
        if (this.recentGamesSubscriber) {
            this.recentGamesSubscriber.unsubscribe();
        }
        if (this.bingoRoomDataSubscriber) {
            this.bingoRoomDataSubscriber.unsubscribe();
        }
        if (this.bingoVfSchedulerSubscriber) {
            this.bingoVfSchedulerSubscriber.unsubscribe();
        }
    }

    updateGYMLCategoryRowCount() {
        if (this.gymlCategory) {
            this.gymlCategoryRef = cloneDeep(this.gymlCategory);
            // this.gymlCategoryRef = {...this.gymlCategory};
            this.gymlCategoryRef.rowCount = '-1';
        }
    }
}
