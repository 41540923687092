import { NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, DestroyRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
    CasinoManager,
    CasinoWidgetService,
    ConfigProviderService,
    CozyBingoDataService,
    FavouriteService,
    RecentGamesService,
} from '@casinocore/platform/core';
import { DeviceService, NavigationService, UserService } from '@frontend/vanilla/core';

@Component({
    selector: 'cc-rcp-fav-logout-widget',
    templateUrl: 'rcp-fav-logout-widget.component.html',
    standalone: true,
    imports: [NgIf, NgStyle, NgClass],
})
export class RcpFavLogoutWidgetComponent implements OnInit, OnChanges, OnDestroy {
    @Input() lobbyType: string;
    rcpToggle: boolean;
    widgetContactUsBG: string;
    widgetContactUsClr: string;
    widgetHelpBG: string;
    widgetHelpClr: string;
    rcpWidgeticon: string;
    rcpFavWidgeticon: string;
    rcpContactUsicon: string;
    rcpHelpicon: string;
    isTouch: boolean;
    showHelpContactForPrelogin: boolean;
    rcpWidgetBackground: string;
    WidgetHelpButton: any;
    WidgetContactusButton: any;
    isAuthenticated: boolean;
    recentGames: any = [];
    favouriteGames: any = [];
    currentWidgetHeight: number = 0;
    interval: any;
    newWidgetHeight: number = 0;
    maxHeightCount: number;
    global: any;
    widgetConfig: any;
    cozyBingoSubscriber: any;
    enableEpcotSubNavigation: boolean;

    constructor(
        private deviceService: DeviceService,
        private navigation: NavigationService,
        private casinoManager: CasinoManager,
        private user: UserService,
        private recentGameService: RecentGamesService,
        private favouriteService: FavouriteService,
        private casinoWidgetService: CasinoWidgetService,
        private configProviderService: ConfigProviderService,
        private cozyBingoDataService: CozyBingoDataService,
        private destroyRef: DestroyRef,
    ) {}

    ngOnInit() {
        this.isDisplayRpandGyml();
        const subnavConfig: any = this.configProviderService.provideSubNavigationConfig();
        this.global = this.configProviderService.provideGlobalConfig();
        this.widgetConfig = this.configProviderService.providewidgetConfig();
        this.WidgetHelpButton =
            this.widgetConfig.widgetLinks.help && this.widgetConfig.widgetLinks.help.imageLink
                ? this.widgetConfig.widgetLinks.help.imageLink.text
                : '';
        this.WidgetContactusButton =
            this.widgetConfig.widgetLinks.contactus && this.widgetConfig.widgetLinks.contactus.imageLink
                ? this.widgetConfig.widgetLinks.contactus.imageLink.text
                : '';
        this.isTouch = this.deviceService.isTouch;
        this.dataBindings();
        this.postWidgetHeight();
        this.recentGameService.recentGamesFeedObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((e: any) => {
            if (e) {
                this.recentGames = e;
                this.isDisplayRpandGyml();
            }
        });
        this.favouriteService.favouriteGamesFeedObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((favouriteGames: any) => {
            if (favouriteGames) {
                this.favouriteGames = favouriteGames;
                this.isDisplayRpandGyml();
            }
        });
        this.casinoManager.lobbyObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((lobbyModel) => {
            if (lobbyModel) {
                this.isDisplayRpandGyml();
            }
        });
        this.cozyBingoSubscriber = this.cozyBingoDataService.cozyBingoRoomsDataObservable
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((response: any) => {
                if (response) {
                    this.isDisplayRpandGyml();
                }
            });
        this.enableEpcotSubNavigation = subnavConfig.enableEpcotSubNavigation;
    }

    ngOnChanges() {
        this.isDisplayRpandGyml();
    }

    isDisplayRpandGyml() {
        const widgetConfig: any = this.configProviderService.providewidgetConfig();
        if (!this.user.isAuthenticated) {
            this.showHelpContactForPrelogin = this.casinoManager.IsFeatureEnabledforLobbytype(
                this.lobbyType,
                widgetConfig.enableHelpContactForPrelogin,
            );
            if (this.showHelpContactForPrelogin) {
                this.addHelpContactBarClassToHtml();
            }
        }
        if (this.user.isAuthenticated) {
            let otherProductRpGames = [];
            let otherProductfav = [];
            otherProductRpGames = this.cozyBingoDataService.getcozybingorecentgames();
            otherProductfav = this.cozyBingoDataService.getcozybingofavourites();
            if (
                this.recentGames &&
                this.recentGames.length == 0 &&
                this.favouriteGames &&
                this.favouriteGames.length == 0 &&
                otherProductRpGames.length == 0 &&
                otherProductfav.length == 0
            ) {
                this.showHelpContactForPrelogin = this.casinoManager.IsFeatureEnabledforLobbytype(
                    this.lobbyType,
                    widgetConfig.enableHelpContactForPrelogin,
                );
                if (this.showHelpContactForPrelogin) {
                    this.addHelpContactBarClassToHtml();
                }
            } else {
                this.showHelpContactForPrelogin = false;
            }
        }
        if (this.showHelpContactForPrelogin) {
            document.getElementsByTagName('html')[0].classList.add('rpfav-widget-enabled');
        }
    }

    addHelpContactBarClassToHtml() {
        document.getElementsByTagName('html')[0].classList.add('help-contact-bar');
        if (this.casinoWidgetService.isCasinoWidget()) {
            const contactUsParam = {
                categoryid: 'rcp-widget',
                htmlClass: 'help-contact-bar',
            };
            this.casinoWidgetService.postMessageToParent('HtmlClass', contactUsParam);
        }
    }

    dataBindings() {
        this.widgetContactUsBG = this.global.messages['WidgetContactUsBG'];
        this.widgetContactUsClr = this.global.messages['WidgetContactUsClr'];
        this.widgetHelpBG = this.global.messages['WidgetHelpBG'];
        this.widgetHelpClr = this.global.messages['WidgetHelpClr'];
        this.rcpWidgeticon = this.global.messages['rcpWidgeticon'];
        this.rcpFavWidgeticon = this.global.messages['rcpFavWidgeticon'];

        this.rcpContactUsicon =
            this.widgetConfig.widgetLinks.contactus && this.widgetConfig.widgetLinks.contactus.image
                ? this.widgetConfig.widgetLinks.contactus.image.src
                : '';
        this.rcpHelpicon =
            this.widgetConfig.widgetLinks.help && this.widgetConfig.widgetLinks.help.image ? this.widgetConfig.widgetLinks.help.image.src : '';
        this.rcpWidgetBackground = this.global.messages['WidgetBackgroundColor'];
    }

    getContactusUrl() {
        if (
            this.casinoWidgetService.isCasinoWidget() &&
            this.widgetConfig.widgetLinks.contactus &&
            this.widgetConfig.widgetLinks.contactus.imageLink
        ) {
            const contactUsParam = {
                categoryid: 'rcp-widget',
                contactUrl: this.widgetConfig.widgetLinks.contactus.imageLink.url,
            };
            this.casinoWidgetService.postMessageToParent('ContactUs', contactUsParam);
        } else {
            this.navigation.goTo(this.widgetConfig?.widgetLinks?.contactus?.imageLink?.url);
        }
    }

    getHelpUrl() {
        if (this.casinoWidgetService.isCasinoWidget() && this.widgetConfig.widgetLinks.help && this.widgetConfig.widgetLinks.help.imageLink) {
            const helpParam = {
                categoryid: 'rcp-widget',
                helpUrl: this.widgetConfig.widgetLinks.help.imageLink.url,
            };
            this.casinoWidgetService.postMessageToParent('Help', helpParam);
        } else {
            this.navigation.goTo(this.widgetConfig?.widgetLinks?.help?.imageLink?.url);
        }
    }

    postWidgetHeight(): void {
        setTimeout(() => {
            this.postHeight();
            this.widgetHeight();
        }, 500);
    }

    postHeight(): void {
        if (this.casinoWidgetService.isCasinoWidget()) {
            const widget = document.getElementById('loggedoutWidgetToggle');
            if (widget) {
                this.currentWidgetHeight = widget.scrollHeight;
                const params = {
                    isFooterWidget: this.casinoWidgetService.getWidgetPosition() ? true : false,
                    categoryid: 'rcp-widget',
                    height: this.casinoWidgetService.getWidgetPosition() ? this.currentWidgetHeight + 10 : this.currentWidgetHeight,
                };
                this.casinoWidgetService.postMessageToParent('LobbyLoaded', params);
                if (!this.interval) {
                    this.interval = setInterval(() => {
                        this.checkAndUpdateWidgetHeight();
                    }, 500);
                }
            }
        }
    }

    checkAndUpdateWidgetHeight() {
        if (document.getElementById('loggedoutWidgetToggle')) {
            this.newWidgetHeight = document.getElementById('loggedoutWidgetToggle')!.scrollHeight;
        }
        if (this.currentWidgetHeight === this.newWidgetHeight) {
            this.maxHeightCount--;
        }
        if (!this.maxHeightCount) {
            clearInterval(this.interval);
        }
        if (this.newWidgetHeight !== this.currentWidgetHeight) {
            this.currentWidgetHeight = this.newWidgetHeight;
            if (document.getElementById('loggedoutWidgetToggle')) {
                this.newWidgetHeight = document.getElementById('loggedoutWidgetToggle')!.scrollHeight;
            }
            const params = {
                isFooterWidget: this.casinoWidgetService.getWidgetPosition() ? true : false,
                categoryid: 'rcp-widget',
                height: this.newWidgetHeight,
            };
            this.casinoWidgetService.postMessageToParent('LobbyLoaded', params);
        }
    }

    widgetHeight() {
        if (document.getElementsByTagName('vn-bottom-nav') && document.getElementsByTagName('vn-bottom-nav').length > 0) {
            let rpFavWidgetHeight: number = 0;
            if (
                document.getElementsByClassName('bottom-nav') &&
                document.getElementsByClassName('bottom-nav')[0] &&
                document.getElementsByClassName('bottom-nav')[0].clientHeight
            ) {
                rpFavWidgetHeight = document.getElementsByClassName('bottom-nav')[0].clientHeight;
                if (document.getElementsByClassName('rcp-widget') && document.getElementsByClassName('rcp-widget')[0]) {
                    document.getElementsByClassName('rcp-widget')[0].setAttribute('style', 'bottom:' + rpFavWidgetHeight + 'px;');
                }
            } else {
                if (document.getElementsByClassName('rcp-widget') && document.getElementsByClassName('rcp-widget')[0]) {
                    document.getElementsByClassName('rcp-widget')[0].setAttribute('style', 'bottom:' + rpFavWidgetHeight + 'px;');
                }
            }
        }
    }

    ngOnDestroy() {
        if (this.cozyBingoSubscriber) {
            this.cozyBingoSubscriber.unsubscribe();
        }
    }
}
