import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';

@Injectable({
    providedIn: 'root',
})
export class DiscoverFeedService {
    discoverFeedData: any;
    isStickerDisplay: boolean;
    private discoverFeedObject = new BehaviorSubject<any>(null);
    private displayDiscoverSticker = new BehaviorSubject<any>(null);
    discoverFeedObservable: Observable<any> = this.discoverFeedObject.asObservable();
    discoverFeedStickerObservable: Observable<any> = this.displayDiscoverSticker.asObservable();
    constructor(
        private api: PlatformApiService,
        private configProviderService: ConfigProviderService,
    ) {}

    GetDiscoverFeedSitecoreData() {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        this.api.get(apiPathsConfig.disjointFeeds['dsFeedData']).subscribe(
            (response: any) => {
                this.discoverFeedData = response;
                this.expiryDatecal(response.discoverContainer);
                this.discoverFeedObject.next(response);
            },
            () => {},
        );
    }
    getDiscoverFeed() {
        return this.discoverFeedData;
    }
    getStickerExpiryTime(discoverData: any) {
        this.displayDiscoverSticker.next(discoverData);
    }
    expiryDatecal(data: any) {
        if (data.highlightExpiryDate) {
            const expiryDate: any = new Date(data.highlightExpiryDate);
            const currentDate: any = new Date();
            const remainingTime = expiryDate - currentDate;
            const remainingTimeInMints = remainingTime / 1000 / 60;
            this.isStickerDisplay = remainingTimeInMints > 0 ? true : false;
            if (this.isStickerDisplay) {
                this.getStickerExpiryTime(data);
            }
        }
    }
}
