import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import {
    CasinoCoreNpmParamsService,
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    InvokerProductService,
} from '@casinocore/platform/core';
import { NewGameQuickInfoComponent } from '@casinocore/platform/new-game-quick-info';
import { Subscription } from 'rxjs';

import { RcpFavLogoutWidgetComponent } from '../rcp-fav-logout-widget/rcp-fav-logout-widget.component';
import { RpFavWidgetComponent } from '../rp-fav-widget/rp-fav-widget.component';

@Component({
    selector: 'cc-rp-fav-widget-wrapper',
    templateUrl: 'rp-fav-widget-wrapper.component.html',
    standalone: true,
    imports: [NgIf, NewGameQuickInfoComponent, RpFavWidgetComponent, RcpFavLogoutWidgetComponent],
})
export class RpFavWidgetWrapperComponent implements OnInit, OnDestroy {
    invokerProduct: any;
    lobbyType: string;
    isLobbyLoaded: boolean;
    lobbyLoadSubscription: Subscription;
    isNewQuickInfoEnabled: boolean;

    constructor(
        private casinoLobbyService: CasinoLobbyService,
        private configProviderService: ConfigProviderService,
        private invokerProductService: InvokerProductService,
        private casinoCoreNpmParamsService: CasinoCoreNpmParamsService,
        private casinoManager: CasinoManager,
    ) {}
    ngOnInit() {
        this.lobbyLoadSubscription = this.casinoLobbyService.lobbyLoadedObservable.subscribe((response) => {
            if (response) {
                //get dynacon key for other product mapping
                const widgetConfig: any = this.configProviderService.providewidgetConfig();
                this.lobbyType = widgetConfig.lobbyTypeForRpFavOnOtherProducts;
                this.isLobbyLoaded = true;
                // get invoker product from appinfoconfig
                this.invokerProduct = this.invokerProductService.getInvokerProduct();
                const casinoNpmParams = this.casinoCoreNpmParamsService.getcasinoCoreNpmParams();
                const features: any = this.configProviderService.provideFeaturesConfig();
                if (!casinoNpmParams.isDll) {
                    this.isNewQuickInfoEnabled = this.casinoManager.IsFeatureEnabledforLobbytypeWithInvokerProduct(
                        this.lobbyType,
                        features.enableNewQuickInfoLobbyType,
                    );
                }
            }
        });
    }
    ngOnDestroy() {
        if (this.lobbyLoadSubscription) {
            this.lobbyLoadSubscription.unsubscribe();
        }
    }
}
