import { LowerCasePipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { SitecoreImageResizePipe } from '@casinocore/platform/core';

@Component({
    selector: 'cc-opt-teaser-bg-image-npm',
    templateUrl: 'opt-teaser-bg-image-npm.component.html',
    standalone: true,
    imports: [NgIf, NgClass, NgStyle, LowerCasePipe, SitecoreImageResizePipe],
})
export class OptTeaserBgImageNpmComponent implements OnInit {
    @Input() teaser: any;
    @Input() teaserBackgroundImageAlignment: string = '';
    @Input() teaserBackgroundPosition: number;
    @Input() isMobileImage: string;
    tabletImage: any;

    ngOnInit() {
        this.tabletImage = this.teaser?.tabletImage ? this.teaser?.tabletImage?.src : this.teaser?.desktopImage?.src;
    }
}
