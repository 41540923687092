import { Injectable } from '@angular/core';

import { DeviceService, Logger, TrackingService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { CasinoApiService } from './casino-api.service';
import { CategoryManager } from './category-manager.service';
import { ConstService } from './const.service';

@Injectable({
    providedIn: 'root',
})
export class GlobalJackpotService {
    private globalJackpotObject: any;
    private gjWinnerJackpotData: any;
    private globalJackpotsSubject = new BehaviorSubject<any>(null);
    globalJackpotsFeedObservable: Observable<any> = this.globalJackpotsSubject.asObservable();
    private globalJackpotsTemplateSubject = new BehaviorSubject<any>(null);
    globalJackpotsTempslateObservable: Observable<any> = this.globalJackpotsTemplateSubject.asObservable();
    private wonStatusSubject = new BehaviorSubject<any>(null);
    userWinStatusObservable: Observable<any> = this.wonStatusSubject.asObservable();
    private claimStatusSubject = new BehaviorSubject<any>(null);
    claimStatusObservable: Observable<any> = this.claimStatusSubject.asObservable();
    private claimStatusErrorResponce = new BehaviorSubject<any>(null);
    claimStatusErrorResponceObservable: Observable<any> = this.claimStatusErrorResponce.asObservable();
    private historyFeedMarketingContent: any;
    private historyFeedMarketingContentSubject = new BehaviorSubject<any>(null);
    historyFeedTemplateSubjectObservable: Observable<any> = this.historyFeedMarketingContentSubject.asObservable();
    private gameslobbyfeedcategories = new BehaviorSubject<any>(null);
    lobbyfeedcategoriesObservable: Observable<any> = this.gameslobbyfeedcategories.asObservable();
    private winnersFeedSubject = new BehaviorSubject<any>(null);
    winnersFeedContentObservable: Observable<any> = this.winnersFeedSubject.asObservable();
    private devicetype: any;
    private lobbyfeedcategories: any;
    readonly RecentGames: string = 'RECENTGAMES';
    readonly FavouriteGames: string = 'FAVOURITEGAMES';
    lobbytype: string;
    isGlobalJackpot: boolean;
    oldPathSegment: string;
    seoBoxesRes: any = [];
    seoBoxesObservable: Observable<boolean | null>;
    private seoBoxesPublisher = new BehaviorSubject<boolean>(false);

    constructor(
        private api: CasinoApiService,
        private deviceService: DeviceService,
        private categoryManager: CategoryManager,
        private configProviderService: ConfigProviderService,
        private tracking: TrackingService,
        private logger: Logger,
    ) {
        if (this.deviceService.isTablet) this.devicetype = 'Tablet';
        else if (this.deviceService.isMobile) this.devicetype = 'Mobile';
        else this.devicetype = 'Desktop';
        this.seoBoxesObservable = this.seoBoxesPublisher.asObservable();
    }
    // NFR - Services optimization - Remove
    onAppInit() {}
    get seoBoxesContent(): any[] {
        return this.seoBoxesRes;
    }
    setGlobalJackpots(
        activeJackpotGroupId: any,
        activeTemplate: string,
        configurationType: string,
        selectedPhase: string,
        folderId: string,
        isRefreshLobby: boolean,
    ) {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();

        this.api
            .get(
                apiPathsConfig.globalJackpots['gjpLobbyPoolFeed'],
                {
                    jackpotGroupId: activeJackpotGroupId,
                    device: this.devicetype,
                    activeTemplate: activeTemplate,
                    configurationType: configurationType,
                    selectedPhase: selectedPhase,
                    refreshLobby: isRefreshLobby,
                    folderId: folderId,
                },
                { withCredentials: true },
            )
            .subscribe(
                (response: any) => {
                    if (response) {
                        this.globalJackpotObject = response;
                        this.globalJackpotsSubject.next(response);
                    }
                },
                (error) => {
                    this.logger.error('Unable to retrieve GlobalJackpot Data' + error);
                },
            );
    }

    setGlobalJackpotObject(globalJackpotObject: any) {
        this.globalJackpotObject = globalJackpotObject;
        this.globalJackpotsSubject.next(globalJackpotObject);
    }
    // NFR - Services optimization - Remove
    getGlobalJackpots() {
        return this.globalJackpotObject;
    }

    checkIfUserWonGroupJackpot(subJackpotId: any) {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        this.api.get(apiPathsConfig.globalJackpots['gjpUserWonJackpot'], { subJackpotId: subJackpotId }).subscribe(
            (response: any) => {
                if (response) {
                    this.wonStatusSubject.next(response);
                }
            },
            (error) => {
                this.logger.error('Unable to retrieve JackpotWon Data' + error);
            },
        );
    }

    userClaimGlobalJackpotStatus(winRefId: any) {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        this.api.get(apiPathsConfig.globalJackpots['gjpUserClaimStatus'], { winRefId: winRefId }, { responseType: 'text' }).subscribe(
            (response: string) => {
                this.claimStatusSubject.next(response);
            },
            (error) => {
                this.claimStatusErrorResponce.next(error);
                this.logger.error('Unable to retrieve Claim Status' + error);
            },
        );
    }
    clearRevealObservable() {
        this.wonStatusSubject.next('');
    }
    userClaimGlobalJackpotStatusClear() {
        this.claimStatusSubject.next('');
    }

    getAvailableGames() {
        return this.lobbyfeedcategories;
    }
    // NFR - Services optimization - Remove
    getUILobby(lobbyConfiguration: any, lobbyType: string) {
        let categoryTitleLangMap: Map<string, string> = new Map<string, string>();
        let stickerTitleLangMap: Map<string, string> = new Map<string, string>();
        if (lobbyConfiguration?.languageContent.length > 0 && lobbyConfiguration?.languageContent[0]) {
            if (lobbyConfiguration.languageContent[0].categories && lobbyConfiguration.languageContent[0].categories.length > 0) {
                categoryTitleLangMap = this.getLanguageContentMap(lobbyConfiguration.languageContent[0].categories);
            }
            if (lobbyConfiguration.languageContent[0].stickers && lobbyConfiguration.languageContent[0].stickers.length > 0) {
                stickerTitleLangMap = this.getLanguageContentMap(lobbyConfiguration.languageContent[0].stickers);
            }
        }
        return this.bindUILobby(lobbyConfiguration, lobbyType, categoryTitleLangMap, stickerTitleLangMap);
        //TODO:store UILobby in localStorage
    }
    getLobbyCategory(lobbyCategory: any, categoryTitleLangMap: any) {
        if (lobbyCategory) {
            const lmtCategoryId: string = lobbyCategory.categoryid;
            lobbyCategory.lmtCategoryId = lmtCategoryId;
            if (lobbyCategory.route) {
                lobbyCategory.categoryid =
                    lobbyCategory.categoryid === 'LMC_GYML'
                        ? lobbyCategory.categoryid
                        : lobbyCategory?.route?.trim()?.replace(/ /g, '')?.toLowerCase();
                lobbyCategory.route = lobbyCategory.categoryid;
            }
            lobbyCategory.engName = lobbyCategory.categoryname;
            lobbyCategory.categoryname = this.getCategoryNameFromLangMap(lmtCategoryId, categoryTitleLangMap, lobbyCategory.categoryname);
            lobbyCategory.isNavigationCategory = this.getNavigationVisibility(lobbyCategory.isHidden);
            lobbyCategory.sitecoreData = this.getSitecoreInfoForCategoryIdAndLobbyType(lobbyCategory.categoryid);
            if (lobbyCategory.gamelist.length > 0) {
                for (let i = 0; i < lobbyCategory.gamelist.length; i++) {
                    if (typeof lobbyCategory.gamelist[i].icon == 'number') {
                        const t: string = String(lobbyCategory.gamelist[i].icon);
                        lobbyCategory.gamelist[i].icon = t;
                    }
                }
            }
            lobbyCategory.gamesModel = lobbyCategory.gamelist;
            return lobbyCategory;
        }
    }
    getLobbySubCategory(lobbySubCategory: any, categoryTitleLangMap: any) {
        if (lobbySubCategory) {
            const lmtCategoryId: string = lobbySubCategory.subcategoryid;
            lobbySubCategory.lmtCategoryId = lmtCategoryId;
            if (lobbySubCategory.route) {
                lobbySubCategory.categoryid =
                    lobbySubCategory.subcategoryid === 'LMC_GYML'
                        ? lobbySubCategory.subcategoryid
                        : lobbySubCategory?.route?.trim()?.replace(/ /g, '')?.toLowerCase();
                lobbySubCategory.route = lobbySubCategory.categoryid;
            }
            lobbySubCategory.engName = lobbySubCategory.categoryname;
            lobbySubCategory.categoryname = this.getCategoryNameFromLangMap(lmtCategoryId, categoryTitleLangMap, lobbySubCategory.subcategoryname);
            lobbySubCategory.sitecoreData = this.getSitecoreInfoForCategoryIdAndLobbyType(lobbySubCategory.categoryid);
            lobbySubCategory.hideSubCategory = this.hideSubCategory(lobbySubCategory);
            if (lobbySubCategory.gamelist.length > 0) {
                for (let i = 0; i < lobbySubCategory.gamelist.length; i++) {
                    if (typeof lobbySubCategory.gamelist[i].icon == 'number') {
                        const t: string = String(lobbySubCategory.gamelist[i].icon);
                        lobbySubCategory.gamelist[i].icon = t;
                    }
                }
            }
            lobbySubCategory.gamesModel = lobbySubCategory.gamelist;
            return lobbySubCategory;
        }
    }
    getLanguageContentMap(langArray: any): Map<string, string> {
        const langMap: Map<string, string> = new Map<string, string>();
        if (langArray && langArray.length > 0) {
            for (let i = 0; i < langArray.length; i++) {
                const langItem: string = langArray[i];
                if (langItem.indexOf(':') > -1) {
                    const langResolve: any = langItem.split(':');
                    langMap.set(langResolve[0], langResolve[1]);
                }
            }
        }
        return langMap;
    }
    getParentCategoryId(category: any, feedCategories: any) {
        if (category.isHidden && category.isHidden === 'true') {
            //check if it is a aubcategory under any other category
            for (let i = 0; i < feedCategories.length; i++) {
                const item: any = feedCategories[i];
                if (item.subcategories && item.subcategories.length > 0) {
                    for (let j = 0; j < item.subcategories.length; j++) {
                        if (item.subcategories[j]?.toLowerCase() === category?.lmtCategoryId?.toLowerCase()) {
                            const categoryRoute: string =
                                item.categoryid === 'LMC_GYML' ? item.categoryid : item.route.replace(/\s/g, '')?.toLowerCase();
                            return categoryRoute;
                        }
                    }
                }
            }
        }
        return category.categoryid;
    }
    getCategoryNameFromSitecore(categoryid: string, lobbyType: string, fallbackCategoryId: boolean): string {
        const sitecoreData: any = this.getSitecoreInfoForCategoryIdAndLobbyType(categoryid);
        if (sitecoreData) {
            return sitecoreData.title;
        }
        if (fallbackCategoryId) {
            return categoryid;
        }
        return '';
    }
    getSitecoreInfoForCategoryIdAndLobbyType(categoryid: string) {
        const defaultSitecoreObj: any = {
            attributes: {
                icon: 'theme-home',
            },
            categoryId: categoryid,
        };
        return defaultSitecoreObj;
    }
    getCategoryNameFromLangMap(categoryId: string, langMap: any, defaultCategoryName: string): string {
        if (langMap && langMap.size > 0 && langMap.get(categoryId)) {
            return langMap.get(categoryId);
        }
        return defaultCategoryName;
    }
    getNavigationVisibility(hiddenStatus: string) {
        if (hiddenStatus) {
            return hiddenStatus?.toLowerCase().trim() == 'false';
        }
        return true;
    }
    setGlobalJackpotCategories() {
        this.gameslobbyfeedcategories.next(this.categoryManager.getAllCategories());
    }
    setIsGlobalJackpot(isGlobalJackpot: boolean) {
        this.isGlobalJackpot = isGlobalJackpot;
    }
    getIsGlobalJackpot() {
        return this.isGlobalJackpot;
    }
    setHistoryFeedContent(enablePhase2: boolean, folderId: string) {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        this.api
            .get(apiPathsConfig.globalJackpots['gjpHistoryFeed'], {
                enablePhase2: enablePhase2,
                folderId: folderId,
            })
            .subscribe(
                (response: any) => {
                    if (response) {
                        this.historyFeedMarketingContent = response;
                        this.historyFeedMarketingContentSubject.next(response);
                    }
                },
                (error) => {
                    this.logger.error('Unable to retrieve HistoryFeed Data' + error);
                },
            );
    }
    // NFR - Services optimization - Remove
    getHistoryFeedContent() {
        return this.historyFeedMarketingContent;
    }

    setGJPathSegment(pathsegment: string) {
        if (this.oldPathSegment != pathsegment) {
            this.globalJackpotObject = null;
            this.globalJackpotsSubject.next(null);
            this.wonStatusSubject.next(null);
            this.claimStatusSubject.next(null);
            this.claimStatusErrorResponce.next(null);
            this.historyFeedMarketingContent = null;
            this.historyFeedMarketingContentSubject.next(null);
            this.winnersFeedSubject.next(null);
            this.oldPathSegment = pathsegment;
        }
    }
    setGJWinnerJackpotContent(): void {
        // let thisClass = this;
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        this.api.get(apiPathsConfig.globalJackpots['gjpWinnersFeed']).subscribe(
            (response: any) => {
                if (response) {
                    this.gjWinnerJackpotData = response;
                    this.winnersFeedSubject.next(this.gjWinnerJackpotData);
                }
            },
            (error) => {
                this.logger.error('Unable to retrieve Global jackpot Winners Feed' + error);
            },
        );
    }
    getSeoboxes(id: string): void {
        this.api.get('GlobalJackpot/Get', { categoryType: id }).subscribe((response) => {
            if (response) {
                this.seoBoxesRes = response;
                this.seoBoxesPublisher.next(true);
            }
        });
    }
    hideSubCategory(lobbySubCategory: any) {
        let hideCategory: boolean;
        hideCategory =
            lobbySubCategory?.categoryid?.toLowerCase() === ConstService.RECENT_GAMES_CATEGORY_ID ||
            lobbySubCategory.categoryid?.toLowerCase() === ConstService.LMC_GYML_CATEGORY_ID;
        return hideCategory;
    }
    trackEvent(
        trackingType: string,
        categoryEvent: string,
        labelEvent: string,
        actionEvent: string,
        positionEvent: string,
        locationEvent: string,
        eventDetails: string,
        uRLClicked: string,
    ): void {
        this.tracking.triggerEvent(trackingType, {
            'component.CategoryEvent': categoryEvent,
            'component.LabelEvent': labelEvent,
            'component.ActionEvent': actionEvent,
            'component.PositionEvent': positionEvent,
            'component.LocationEvent': locationEvent,
            'component.EventDetails': eventDetails,
            'component.URLClicked': uRLClicked,
        });
    }
    bindUILobby(lobbyConfiguration: any, lobbyType: string, categoryTitleLangMap: any, stickerTitleLangMap: any) {
        const lobbyCategoriesMap: Map<string, any> = new Map<string, any>();
        const lobbySubCategoriesMap: Map<string, any> = new Map<string, any>();

        if (lobbyConfiguration && lobbyConfiguration.categories && lobbyConfiguration.categories.length > 0) {
            for (let category of lobbyConfiguration.categories) {
                const lobbyCategory: any = this.getLobbyCategory(category, categoryTitleLangMap);
                if (lobbyCategory.subcategories && lobbyCategory.subcategories.length > 0) {
                    lobbyCategory.lmtSubCategories = [];
                    for (let subcategory of lobbyCategory.subcategories) {
                        const lobbySubCategory: any = this.getLobbySubCategory(subcategory, categoryTitleLangMap);
                        lobbySubCategory.parentCategoryId = this.getParentCategoryId(lobbyCategory, lobbyConfiguration.categories);
                        lobbySubCategoriesMap.set(lobbySubCategory.route, lobbySubCategory);
                        lobbyCategory.lmtSubCategories.push(lobbySubCategory.categoryid);
                    }
                }
                lobbyCategoriesMap.set(lobbyCategory.route, lobbyCategory);
            }

            const recentPlayedCategory: any = {
                categoryid: this.RecentGames?.toLowerCase(),
                route: this.RecentGames?.toLowerCase(),
                categoryname: this.getCategoryNameFromSitecore(this.RecentGames?.toLowerCase(), lobbyType, true),
                isNavigationCategory: true,
                sitecoreData: this.getSitecoreInfoForCategoryIdAndLobbyType(this.RecentGames?.toLowerCase()),
            };

            lobbyCategoriesMap.set(recentPlayedCategory.route, recentPlayedCategory);

            const favouritesCategory: any = {
                categoryid: this.FavouriteGames?.toLowerCase(),
                route: this.FavouriteGames?.toLowerCase(),
                categoryname: this.getCategoryNameFromSitecore(this.FavouriteGames?.toLowerCase(), lobbyType, true),
                isNavigationCategory: true,
                sitecoreData: this.getSitecoreInfoForCategoryIdAndLobbyType(this.FavouriteGames?.toLowerCase()),
            };
            lobbyCategoriesMap.set(favouritesCategory.route, favouritesCategory);

            if (lobbySubCategoriesMap.size > 0) {
                lobbySubCategoriesMap.forEach((subcategory: any, subcategoryRoute: string) => {
                    lobbyCategoriesMap.set(subcategoryRoute, subcategory);
                });
            }
        }
        const UILobby: any = {
            categoriesMap: lobbyCategoriesMap,
            categoryNameLangMap: categoryTitleLangMap,
            stickerNameMap: stickerTitleLangMap,
            gamemetadata: lobbyConfiguration.gamemetadata,
        };
        return UILobby;
    }
}
