import { NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import {
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    DesignSystemEnablerService,
    FastLoadingGamesService,
    GameDataManager,
    JackpotDataService,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, GameTileCtaComponent } from '@frontend/casino-ui';
import { NavigationService } from '@frontend/vanilla/core';

import { CasinoCoreLazyPlaceholderComponent } from '../casino-core-lazy-placeholder/casino-core-lazy-placeholder.component';
import { FeatureGridComponent } from '../feature-grid/feature-grid.component';

@Component({
    selector: 'cc-single-jackpot-widget',
    templateUrl: 'single-jackpot-widget.component.html',
    standalone: true,
    imports: [NgIf, NgStyle, NgClass, CasinoCoreLazyPlaceholderComponent, FeatureGridComponent, GameTileCtaComponent, DecoratorClassDirective],
})
export class SingleJackpotWidgetComponent implements OnInit {
    @ViewChild('featuredGrid') featuredGrid: FeatureGridComponent;
    @Input() category: any;
    @Input() isTouch: boolean;
    @Input() lobbyType: string;
    @Input() gameContainer: string;
    @Input() changeCarouselView: boolean;
    @Output() isjackpotFeed = new EventEmitter<object>();
    games: any = [];
    className = 'single-jackpot';
    jackpotType = 'single';
    moreInfoMessage: any;
    showJackpotWidget: boolean = false;
    jackpotList: any = [];
    widgets: any = [];
    jackpotsCache: any;
    jackpotWidget: any;
    jackpotGames: any;
    jackpotName: any;
    jackpotValue: any;
    showBackgroundImage: boolean;
    jackpotWidgetBackgroundImage: any;
    jackpotWidgetImage: any;
    jackpotDesc: any;
    cta: any;
    ShowSeeAllJackpots: any;
    isCrossSaleIOS: boolean;
    mode: string;
    singleJackpotBgClr: string;
    singleJackpotmode: string;
    jackpotNameFromSitecore: any = '';
    newFeatureGridArr: any[] = [];
    categoryObj: any;
    carouselData: any;
    themeArrowRight: any;
    themeArrowLeft: any;
    subCategorySubTitleFontSize: any;
    isUkHeading: any;
    subCategoryTextColor: any;
    lobbyTileFontFamily: any;
    totalJpGroupList: any;
    jackpotDataConfig: any;
    enableJpGroupTickingForLobby: boolean;
    JackpotDisplayNameConfig: any;
    isLegacy: boolean = false;
    constructor(
        private jackpotDataService: JackpotDataService,
        private casinoLobbyService: CasinoLobbyService,
        private navigation: NavigationService,
        private gameDataManager: GameDataManager,
        private configProviderService: ConfigProviderService,
        private fastLoadingGamesService: FastLoadingGamesService,
        private casinoManager: CasinoManager,
        private designSystemEnablerService: DesignSystemEnablerService,
    ) {}

    ngOnInit() {
        this.jackpotDataConfig = this.configProviderService.provideJackpotConfig();
        const global = this.configProviderService.provideGlobalConfig();
        const jackpotData = this.configProviderService.provideJackpotConfig();
        this.enableJpGroupTickingForLobby = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.lobbyType,
            jackpotData.enableJpGroupTickingForLobbyType,
        );
        this.themeArrowLeft = global.messages.ThemeArrowLeft;
        this.themeArrowRight = global.messages.ThemeArrowRight;
        const features: any = this.configProviderService.provideFeaturesConfig();
        this.subCategorySubTitleFontSize = this.casinoLobbyService.getsubCategorySubTitleFontSize(this.category);
        this.isUkHeading = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, features.isUkHeading);
        this.subCategoryTextColor = this.casinoLobbyService.getsubCategoryTextColor(this.category);
        this.lobbyTileFontFamily = this.casinoLobbyService.getLTileFontFamily(this.category);
        this.singleJackpotBgClr = global.lobbyConfigurations.singleJackpotBgClr;
        this.moreInfoMessage = 'More info';
        this.isLegacy = !this.designSystemEnablerService.isEnabled();

        if (this.jackpotDataConfig.enableJackpotSortGames) {
            this.getSortingGames();
        }
        this.startJackpotFeed();
        this.fastLoadingGamesService.fastLoadingGamesFeedObservable.subscribe((fastLoadingGames: any) => {
            this.fastLoadingGamesService.setFastLoadingGames(this.games, fastLoadingGames);
        });
        const singleJackpotmode = global.lobbyConfigurations.singleJackpotmode;
        if (singleJackpotmode === 'v2' || singleJackpotmode === 'v3') {
            this.mode = singleJackpotmode;
        } else {
            this.mode = 'v1';
        }
        if (this.enableJpGroupTickingForLobby) {
            this.updateTickingValue();
        }
    }

    startJackpotFeed() {
        this.showJackpotWidget = false;

        //Get jackpot feed data and update component if jackpot feed is available already
        this.refreshJackpotsNew();

        this.jackpotDataService.jackpotFeedObservable.subscribe(() => {
            this.refreshJackpotsNew();
        });
    }

    getCarouselData(data: any) {
        this.carouselData = data;
    }

    refreshJackpotsNew() {
        let sortGameslist: any;
        let isSortGames: boolean = false;
        if (this.category.sitecoreData.showJackpotWidget) {
            this.JackpotDisplayNameConfig = this.configProviderService.provideGlobalConfig()?.jackpotDisplayNameConfigurations;
            this.jackpotsCache = this.jackpotDataService.getJackpotData();
            this.jackpotWidget = decodeURIComponent(this.category.sitecoreData.jackpotWidget[0]);
            this.ShowSeeAllJackpots = this.category.sitecoreData.showSeeAllJackpots;
            if (this.jackpotsCache && this.jackpotWidget && this.jackpotWidget !== 'null') {
                const jackpotWidgetItem = this.GetJackpotWidgetItem(this.jackpotWidget, this.jackpotsCache);

                if (this.jackpotDataConfig.enableJackpotSortGames) {
                    if (this.totalJpGroupList && this.totalJpGroupList.length > 0) {
                        sortGameslist = this.jackpotDataService.filterJpGroup(this.totalJpGroupList, jackpotWidgetItem.jackpotGroupName);
                    }
                    if (jackpotWidgetItem && jackpotWidgetItem?.gamesList?.length > 0) {
                        this.setGames(jackpotWidgetItem?.gamesList, sortGameslist);
                        isSortGames = true;
                    }
                    if (this.games && this.games.length > 0) {
                        this.showJackpotWidget = true;
                    }
                }
                if (this.jackpotDataService && Object.keys(this.jackpotDataService).length) {
                    if (
                        !(
                            this.jackpotName &&
                            this.jackpotName?.toLowerCase() === this.jackpotWidget?.toLowerCase() &&
                            this.jackpotValue === jackpotWidgetItem.amount
                        )
                    ) {
                        this.jackpotValue = jackpotWidgetItem.amount;
                        this.jackpotName = this.JackpotDisplayNameConfig[jackpotWidgetItem.jackpotGroupId]
                            ? this.JackpotDisplayNameConfig[jackpotWidgetItem.jackpotGroupId]
                            : jackpotWidgetItem.jackpotGroupName;
                        this.jackpotGames = jackpotWidgetItem.gamesList;
                        if (!isSortGames && this.jackpotGames && this.jackpotGames.length > 0) {
                            this.setGames(this.jackpotGames, sortGameslist);
                        }
                        this.showBackgroundImage = true;
                        this.jackpotWidgetBackgroundImage = jackpotWidgetItem.backgroundImage;
                        this.jackpotWidgetImage = jackpotWidgetItem.jackpotImage;
                        this.jackpotDesc = jackpotWidgetItem.content;
                        this.cta = jackpotWidgetItem.ctaLink;
                        if (this.jackpotWidgetImage && this.games && this.games.length > 0) {
                            this.showJackpotWidget = true;
                        }
                        if (jackpotWidgetItem.content) {
                            this.jackpotNameFromSitecore = jackpotWidgetItem.content;
                        }
                    }
                }
            }
        } else {
            this.showJackpotWidget = false;
        }
    }

    setGames(gameslist: string[], sortGameslist?: any) {
        this.games = [];
        this.newFeatureGridArr = [];
        if (gameslist && gameslist.length > 0) {
            let starttileBgMode: boolean = false;
            const newgridbgcolor = this.category?.sitecoreData?.attributes?.newgridbgcolor;
            this.categoryObj = { ...this.category };
            this.categoryObj.rowCount = '-1';
            for (let i = 0; i < gameslist.length; i++) {
                const gameIdString: string = gameslist[i];
                const gameInfo: any = this.casinoLobbyService.getGameInfo(gameIdString);
                // TODO check for disable games
                if (gameInfo) {
                    gameInfo.icon = this.category.isIconSixCategory ? '6' : this.category.isIconSevenCategory ? '7' : '1';
                    gameInfo.lobbyType = this.lobbyType;
                    const gameObj = this.gameDataManager.setGameLobbyModel(gameInfo, this.isTouch);
                    gameObj.gameContainer = this.gameContainer;
                    gameObj.bgTileMode = this.category.isIconSevenCategory ? (starttileBgMode ? starttileBgMode : false) : false;
                    gameObj.gameTileBorderRadius = this.casinoLobbyService.getBorderRadius(this.category);
                    gameObj.gameTileDropShadow = this.casinoLobbyService.getDropShadow(this.category);
                    gameObj.infoIconClass = this.casinoManager.assignGameClass(this.casinoManager.getCurrentWidth(), gameInfo.icon);
                    gameObj.newgridbgcolor = newgridbgcolor;
                    starttileBgMode = !starttileBgMode;
                    this.games.push(gameObj);
                    this.newFeatureGridArr.push({
                        games: [gameObj],
                        maxRowSpan: gameObj.rowSpan,
                        maxColSpan: gameObj.colSpan,
                    });
                }
            }
            if (this.games && this.games.length > 0) {
                this.isjackpotFeed.emit({ isJackpotdataForParallax: true });
            }
        }
        if (sortGameslist && sortGameslist.length > 0) {
            this.games = this.jackpotDataService.filterGames(sortGameslist, this.games);
            this.newFeatureGridArr = this.jackpotDataService.newFeaturegridArray(this.games);
        }
    }

    public GotoJackpots(ctaUrl: any) {
        if (ctaUrl) {
            this.navigation.goTo(ctaUrl, { replace: false, forceReload: true });
        }
    }

    GetJackpotWidgetItem(jackpotWidgetKey: any, jackpots: any) {
        const widgetItem = {};
        for (const jackpot in jackpots) {
            if (jackpot?.toLowerCase() === jackpotWidgetKey?.toLowerCase()) {
                return jackpots[jackpot];
            } else if (jackpots[jackpot] && jackpots[jackpot].subJackpotDetails) {
                for (const subjackpot of jackpots[jackpot].subJackpotDetails) {
                    if (subjackpot && subjackpot.subJackpotName?.toLowerCase() === jackpotWidgetKey?.toLowerCase()) {
                        subjackpot.gamesList = jackpots[jackpot].gamesList;
                        subjackpot.jackpotGroupName = subjackpot.subJackpotName;
                        subjackpot.amount = subjackpot.subJackpotAmount;
                        subjackpot.Value = subjackpot.subJackpotValue;
                        return subjackpot;
                    }
                }
            }
        }
        return widgetItem;
    }
    getSortingGames() {
        this.jackpotDataService.jpSortGamesObservable.subscribe((data: any) => {
            this.totalJpGroupList = data?.jackpotGroupGamesList;
            this.refreshJackpotsNew();
        });
    }
    updateTickingValue() {
        this.jackpotDataService.jpTickingObservable.subscribe((res: any) => {
            if (res) {
                this.jackpotList = [];
                res.forEach((jackpot: any) => {
                    this.jackpotList.push(jackpot);
                });
            }
            const ids = this.jackpotList?.map((jackpotName: any) => jackpotName);
            const filteredList = this.jackpotList?.filter((jackpotName: any, index: any) => !ids.includes(jackpotName, index + 1));
            filteredList?.forEach((item: any) => {
                this.widgets?.forEach((wid: any) => {
                    if (item.jackpotName.toLowerCase() == wid.jackpotName.toLowerCase()) {
                        wid.jackpotValue = item.amount;
                    }
                });
            });
        });
    }
}
