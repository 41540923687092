import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ConfigProviderService } from '../../config-provider/config-provider.service';

@Injectable({
    providedIn: 'root',
})
export class GeolocationActivatorService {
    constructor(
        private configProviderService: ConfigProviderService,
        private readonly activatedRoute: ActivatedRoute,
    ) {}
    get isActive(): boolean {
        const geolocationConfiguration = this.configProviderService.provideGeoLocationConfig();
        let isDisabledViaRoute: any = false;
        const disableFeaureQueryParameter = this.activatedRoute.snapshot.queryParamMap.get('_disableFeature');
        isDisabledViaRoute =
            disableFeaureQueryParameter &&
            disableFeaureQueryParameter
                .trim()
                .split(',')
                .some((queryParameterValue) => queryParameterValue?.toLowerCase() === 'geolocation');
        return !isDisabledViaRoute && geolocationConfiguration.isEnabled;
    }
}
