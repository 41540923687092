import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'csMcPage', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: mcPageClientConfigFactory,
})
export class McPageClientConfig {
    cashierTrackerIds: any[];
    cashierWithdrawUrlTemplate: string;
    cashierDepositUrlTemplate: string;
    cashierQDUrlTemplate: string;
    prefix: string;
    myFreeSpinsHistoryItem: any;
    minAgeValidationCasinoPlay: number;
    defaultPage: any;
    defaultHomePage: any;
    casinoHomePage: any;
    portalUrl: any;
    langName: string;
    nativeAppScheme: string;
    extendTopMenu: any;
    labelPrefix: string;
    isAccountPage: boolean;
    isLobbyPage: boolean;
    isFreespinsPage: boolean;
    channelId: any;
    accountUpgrade: string;
    invokerProductHomePage: any;
    depositUrl: any;
    bingoPageUrl: any;
    bingoSeeAllOnCasino: any;
    playBingoButton: any;
    widgetHelpButton: any;
    widgetContactusButton: any;
    gamingHomeButtonUrl: any;
    gamingOverlayLogoUrl: any;
    gameLaunchTrackingParams: any;
}

export function mcPageClientConfigFactory(service: LazyClientConfigService) {
    return service.get(McPageClientConfig);
}
