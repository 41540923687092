import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    CasinoCoreGamelaunchService,
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    FastLoadingGamesService,
    GameDataManager,
    GameLaunch,
    GameLobbyModel,
    GlobalTranslateComponent,
    HighlightSearch,
    SearchSectionService,
} from '@casinocore/platform/core';
import { TrackingService, WindowRef } from '@frontend/vanilla/core';
import { DslPipe } from '@frontend/vanilla/shared/browser';
import { Subscription } from 'rxjs';

import { LibMockService } from '../services/lib-mock.service';

@Component({
    selector: 'cc-overlay-search',
    templateUrl: 'overlay-search.component.html',
    standalone: true,
    imports: [ReactiveFormsModule, FormsModule, NgIf, NgFor, GlobalTranslateComponent, AsyncPipe, DslPipe, HighlightSearch],
})
export class OverlaySearchComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren('searchInput') vc: any;
    @Input() lobbyType: any;
    show: boolean;
    loadNewSet: boolean = true;
    model: any = {
        query: '',
        results: [],
    };
    isMsgVisible: boolean = false;
    innerWidth: any;
    event: KeyboardEvent;
    showGYML: boolean = true;
    isTouch: boolean;
    gymlText: string;
    GYMLGames: any = [];
    gymlCategory: any;
    SearchCloseIcon: boolean;
    SearchProductForGamingOverlay: any;
    casinoMenuActionsBootstrapService: any;
    Global: any;
    features: any;
    casinoConfiguration: any;
    fastLoadingObs: Subscription;
    displaySearchOverlay: boolean;

    constructor(
        private windowRef: WindowRef,
        private searchSection: SearchSectionService,
        private gameDataManager: GameDataManager,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private fastLoadingGamesService: FastLoadingGamesService,
        private casinoManager: CasinoManager,
        private tracking: TrackingService,
        private configProviderService: ConfigProviderService,
        private casinoLobbyService: CasinoLobbyService,
        private libMockService: LibMockService,
    ) {}

    ngOnInit() {
        this.casinoMenuActionsBootstrapService = this.libMockService.getCasinoBootstrapService();
        this.Global = this.configProviderService.provideGlobalConfig();
        this.features = this.configProviderService.provideFeaturesConfig();
        this.casinoConfiguration = this.configProviderService.provideCasinoConfig();
        this.gymlText = this.Global.messages['SearchGamesYouMightLike']
            ? this.Global.messages['SearchGamesYouMightLike']
            : this.Global.messages['GamesYouMightLike'];
        this.searchSection.searchVisibilityStatus.subscribe((isVisible) => {
            this.setSearchLayerVisibility(isVisible);
        });
        this.SearchCloseIcon = false;
        this.showGYML = true;
        this.isTouch = this.casinoManager.isTouch();
        if (this.features.enableEnhancedSearch) {
            this.setGYML();
            if (!this.gymlCategory) {
                this.gymlCategory = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
                this.fastLoadingObs = this.fastLoadingGamesService.fastLoadingGamesFeedObservable.subscribe((fastLoadingGames: any) => {
                    this.fastLoadingGamesService.setFastLoadingGames(this.GYMLGames, fastLoadingGames);
                });
            }
        }
        this.SearchProductForGamingOverlay = this.Global.messages.SearchProductForGamingOverlay;
    }

    ngOnDestroy() {
        if (this.fastLoadingObs) {
            this.fastLoadingObs.unsubscribe();
        }
    }

    trackInput() {
        this.tracking.triggerEvent('GameSearch', {
            'eventAction': 'Search',
            'page.search': this.model.query,
        });
    }
    setGYML() {
        this.gymlCategory = this.casinoLobbyService.getCategoryInfo(this.lobbyType, 'LMC_GYML');
        if (
            this.gymlCategory &&
            this.gymlCategory.categoryInfo &&
            this.gymlCategory.categoryInfo.gamelist &&
            this.gymlCategory.categoryInfo.gamelist.length > 0
        ) {
            this.gymlCategory.categoryInfo.gamesModel = this.gymlCategory.categoryInfo.gamelist.slice(0, this.casinoConfiguration.gymlLimitForSearch);
            const gymlGameIds: string[] = [];
            this.gymlCategory.categoryInfo.gamesModel.forEach((gameModel: any) => {
                gymlGameIds.push(gameModel.game);
            });
            const gamesModel: any = this.gameDataManager.setDefaultGameIcons(gymlGameIds);
            this.getGyMLGames(gamesModel);
        }
    }
    ngAfterViewInit() {
        //using selectRootElement instead of deprecated invokeElementMethod
        this.vc.first.nativeElement.focus();
        const searchTempInterval = setInterval(() => {
            if (document.getElementById('serachTxt')) {
                document.getElementById('serachTxt')!.focus();
                clearInterval(searchTempInterval);
            }
        }, 1000);
    }
    onEvent(event: KeyboardEvent): void {
        this.event = event;
    }

    onResize() {
        this.innerWidth = window.innerWidth;
    }

    setSearchLayerVisibility(isVisible: boolean) {
        this.showGYML = isVisible;
        this.show = isVisible;
        this.innerWidth = window.innerWidth;
        if (this.show && this.innerWidth < 1365) {
            this.windowRef.nativeWindow.document.body.classList.add('casino-hidescroll');
        } else {
            this.windowRef.nativeWindow.document.body.classList.remove('casino-hidescroll');
        }
    }

    launchGame(game: any, index: number) {
        this.setSearchLayerVisibility(false);
        this.searchSection.setSearchVisibility(false);
        game.lobbyType = this.lobbyType;
        game.iconType = 1; // for advance game data model
        const gameModel = new GameLaunch();
        gameModel.game = game;
        gameModel.isTouch = this.isTouch;
        gameModel.gamePosition = index;
        gameModel.categoryType = 'Search';
        gameModel.from = 's';
        gameModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
        gameModel.gameContainer = 'Overlay';
        this.gameLaunchService.launchGame(gameModel);
    }

    closeSearchIcon() {
        this.model.query = '';
        this.displaySearchOverlay = false;
        if (this.model.query.length == 0) {
            this.SearchCloseIcon = false;
        }
    }
    queryChanged() {
        this.loadNewSet = true;
        this.displaySearchOverlay = true;
        if (this.model.query.length === 0) {
            this.showGYML = true;
            this.SearchCloseIcon = false;
        } else {
            this.showGYML = false;
            this.SearchCloseIcon = true;
        }
        setTimeout(() => {
            if (this.model.query.length < 1) {
                this.model.results = [];
                return;
            }
            this.model.results = this.casinoLobbyService.searchGames(this.model.query);
            this.isMsgVisible = this.model.results.length > 0 ? false : true;
        }, 500);
        this.isMsgVisible = false;
    }

    getGyMLGames(gamesModelList: any[]) {
        this.GYMLGames = [];
        for (let i = 0; i < gamesModelList.length; i++) {
            const gameModelItem: any = gamesModelList[i];
            const gamelobbyModel: GameLobbyModel = this.gameDataManager.setGameLobbyModel(gameModelItem, this.isTouch);
            this.GYMLGames.push(gamelobbyModel);
        }
    }
}
