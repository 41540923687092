import { Injectable } from '@angular/core';

import { UserService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';
import { CacheManagerService } from './cache-manager.service';
import { CasinoManager } from './casino-manager.service';
import { FavouriteService } from './favourite.service';

@Injectable({
    providedIn: 'root',
})
export class CategoryManager {
    lobbyCategoryLookup: Map<string, Map<string, any>> = new Map<string, Map<string, any>>();
    parentCategoryLookUpMap: Map<string, Map<string, any>> = new Map<string, Map<string, any>>();

    public currentCategory: any;
    private categoryLookUpFilledPublisher = new BehaviorSubject<Map<string, any>>(new Map<string, any>());
    categoryLookUpObservable: Observable<any> = this.categoryLookUpFilledPublisher.asObservable();

    private defaultCategoryFilledPublisher = new BehaviorSubject<any>(null);
    defaultCategoryLookUpObservable: Observable<any> = this.defaultCategoryFilledPublisher.asObservable();

    gameStickersMap: any = {};
    stickersLookupCompletePublisher = new BehaviorSubject<Boolean>(new Boolean());

    constructor(
        private api: PlatformApiService,
        private cacheManager: CacheManagerService,
        private user: UserService,
        private favouriteService: FavouriteService,
        private casinoManager: CasinoManager,
        private configProviderService: ConfigProviderService,
    ) {}

    getCategoryInfo(category: string) {
        //fetch from lookup or local storage
        const lobbyType: string = this.casinoManager.getLobbyType() || '';
        let lobbyCategory: Map<string, any> = this.lobbyCategoryLookup.get(lobbyType) || new Map<string, any>();
        if (!lobbyCategory || lobbyCategory.size === 0) {
            lobbyCategory = this.cacheManager.getAllCategoriesFromLocalCache();
            if (lobbyCategory && lobbyCategory.size > 0) {
                this.lobbyCategoryLookup.set(lobbyType, lobbyCategory);
                this.parentCategoryLookUpMap.set(lobbyType, this.setParentCategoryLookUpMap(lobbyCategory));
            }
        }
        if (lobbyCategory && lobbyCategory.size > 0) {
            const categoryInfo: any = lobbyCategory.get(category);
            return categoryInfo;
        }
    }
    // NFR - Services optimization - Remove
    getCategoryInfoLobbyType(category: string, lobbyType: string) {
        //fetch from lookup or local storage
        let lobbyCategory: Map<string, any> = this.lobbyCategoryLookup.get(lobbyType) || new Map<string, any>();
        if (!lobbyCategory || lobbyCategory.size === 0) {
            lobbyCategory = this.cacheManager.getAllCategoriesFromLocalCache();
            if (lobbyCategory && lobbyCategory.size > 0) {
                this.lobbyCategoryLookup.set(lobbyType, lobbyCategory);
                this.parentCategoryLookUpMap.set(lobbyType, this.setParentCategoryLookUpMap(lobbyCategory));
            }
        }
        if (lobbyCategory && lobbyCategory.size > 0) {
            const categoryInfo: any = lobbyCategory.get(category);
            return categoryInfo;
        }
    }

    getAllLMTCategories(lobbyType: string = '', invokerproduct: string = ''): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(apiPathsConfig.lmt['lmtCategoriesAsync'], { lobbytype: lobbyType, invokerProduct: invokerproduct });
    }

    defaultCategoryLoadEvent(gymlandRecentfromDefaultCategory: any) {
        this.defaultCategoryFilledPublisher.next(gymlandRecentfromDefaultCategory);
    }
    // NFR - Services optimization - Remove
    setCategoryLookUpMap(allCategoriesResponse: any, storeInLocalStorage: boolean, lobbyType: string) {
        this.mapGameStickers(allCategoriesResponse);
        const categoryLookup: Map<string, any> = new Map<string, any>();
        allCategoriesResponse.forEach((category: any) => {
            categoryLookup.set(category.categoryid, category);
        });

        if (storeInLocalStorage) {
            this.cacheManager.setCategoryLocalStorageCacheMap(categoryLookup, lobbyType);
        }

        this.lobbyCategoryLookup.set(lobbyType, categoryLookup);
        this.parentCategoryLookUpMap.set(lobbyType, this.setParentCategoryLookUpMap(categoryLookup));
        if (this.casinoManager.getLobbyType() == lobbyType) {
            this.categoryLookUpFilledPublisher.next(categoryLookup);
        }
    }
    // NFR - Services optimization - Remove
    clearLobbyCategoryLookups() {
        this.lobbyCategoryLookup.clear();
        this.parentCategoryLookUpMap.clear();
    }

    // NFR - Services optimization - Remove
    getSubCategoryData(categoryIds: string[]) {
        const subCategoryData: any = {
            availableSubCategories: [],
            unAvailableSubCategories: [],
        };
        categoryIds.forEach((categoryId) => {
            const subCategory: any = this.getCategoryInfo(categoryId);
            if (subCategory) {
                subCategoryData.availableSubCategories.push(subCategory);
            } else {
                subCategoryData.unAvailableSubCategories.push(categoryId);
                //TODO: collect categories which are not available
            }
        });
        return subCategoryData;
    }

    getAllCategories(lobbyType: string = '') {
        if (lobbyType === '') {
            lobbyType = this.casinoManager.getLobbyType() || '';
        }
        let categoryLookUp: Map<string, any> = new Map<string, any>();
        categoryLookUp = this.lobbyCategoryLookup.get(lobbyType) || new Map<string, any>();
        if (categoryLookUp && categoryLookUp.size > 0) {
            return categoryLookUp;
        } else {
            const categoryMap: any = this.cacheManager.getAllCategoriesFromLocalCache(lobbyType);
            if (categoryMap) {
                this.lobbyCategoryLookup.set(lobbyType, categoryMap);
                this.parentCategoryLookUpMap.set(lobbyType, this.setParentCategoryLookUpMap(categoryMap));
            }
            return categoryMap;
        }
    }
    // NFR - Services optimization - Remove
    isGameInCategory(gameId: string, categoryType: string) {
        const lobbyType: string = this.casinoManager.getLobbyType() || '';
        let categoryLookUp: Map<string, any> = new Map<string, any>();
        categoryLookUp = this.lobbyCategoryLookup.get(lobbyType) || new Map<string, any>();
        if (categoryLookUp && categoryLookUp.size > 0) {
            const category: any = categoryLookUp.get(categoryType);
            if (category && category.gamesModel)
                category.gamesModel.forEach((gid: any) => {
                    if (gid.game === gameId) return true;
                    return false;
                });
        }
        return false;
    }

    setParentCategoryLookUpMap(categoryLookup: any) {
        const tempParentCategories: Map<string, string> = new Map<string, string>();
        if (categoryLookup && categoryLookup.size > 0) {
            categoryLookup.entries().forEach((entry: any) => {
                if (!entry[1].isNavigationCategory) {
                    categoryLookup.entries().forEach((category: any) => {
                        if (category[1].linkedCategory == entry[0]) {
                            categoryLookup.entries().forEach((category2: any) => {
                                if (category2[1].lmtSubCategories && category2[1].lmtSubCategories.indexOf(category[1].categoryid) > -1) {
                                    entry[1].parentCategoryid = category2[1].categoryid;
                                    tempParentCategories.set(entry[1].categoryid, entry[1].parentCategoryid);
                                }
                            });
                        }
                    });
                }
            });
        }
        return tempParentCategories;
    }

    getParentCategory(childCategoryid: string) {
        const parentCategoryMap: any = this.parentCategoryLookUpMap.get(this.casinoManager.getLobbyType() || '');
        if (parentCategoryMap && parentCategoryMap.size > 0) {
            const categoryLookUp: any = parentCategoryMap.get(childCategoryid);
            return this.getCategoryInfo(categoryLookUp);
        }
    }
    getDefaultCategoryTypeforLobbyType(): any {
        const casinoConfig = this.configProviderService.provideCasinoConfig();
        const lobbyType: string = this.casinoManager.getLobbyType() || '';
        let dynaconDefaultCategory: string = '';
        if (lobbyType) {
            for (let i = 0; i < casinoConfig.defaultyLobbyTypeCategory.length; i++) {
                if (casinoConfig?.defaultyLobbyTypeCategory[i]?.lobbyType?.toLowerCase() === lobbyType?.toLowerCase()) {
                    dynaconDefaultCategory = casinoConfig.defaultyLobbyTypeCategory[i].defaultCategory;
                }
            }
        }
        if (dynaconDefaultCategory) {
            return this.getLMTMap(dynaconDefaultCategory);
        }
        return null;
    }
    // NFR - Services optimization - Remove
    getDefaultCategoryTypeforGivenLobbyType(lobbyType: string): any {
        const casinoConfig = this.configProviderService.provideCasinoConfig();
        let dynaconDefaultCategory: string = '';
        if (lobbyType) {
            for (let i = 0; i < casinoConfig.defaultyLobbyTypeCategory.length; i++) {
                if (casinoConfig?.defaultyLobbyTypeCategory[i]?.lobbyType?.toLowerCase() === lobbyType?.toLowerCase()) {
                    dynaconDefaultCategory = casinoConfig.defaultyLobbyTypeCategory[i].defaultCategory;
                }
            }
        }
        if (dynaconDefaultCategory) {
            return this.getLMTMap(dynaconDefaultCategory);
        }
        return null;
    }
    // NFR - Services optimization - Remove
    getDefaultCategoryIdforGivenLobbyType(lobbyType: string): any {
        const casinoConfig = this.configProviderService.provideCasinoConfig();
        let dynaconDefaultCategory: string = '';
        if (lobbyType) {
            for (let i = 0; i < casinoConfig.defaultyLobbyTypeCategory.length; i++) {
                if (casinoConfig?.defaultyLobbyTypeCategory[i]?.lobbyType?.toLowerCase() === lobbyType?.toLowerCase()) {
                    dynaconDefaultCategory = casinoConfig.defaultyLobbyTypeCategory[i].defaultCategory;
                }
            }
        }
        if (dynaconDefaultCategory) {
            return dynaconDefaultCategory?.toLowerCase();
        }
        return null;
    }

    getDefaultCategoryFromDynacon(): string {
        const casinoConfig = this.configProviderService.provideCasinoConfig();
        const lobbyType: string = this.casinoManager.getLobbyType() || '';
        if (lobbyType) {
            for (let i = 0; i < casinoConfig.defaultyLobbyTypeCategory.length; i++) {
                if (casinoConfig?.defaultyLobbyTypeCategory[i]?.lobbyType?.toLowerCase() === lobbyType?.toLowerCase()) {
                    return casinoConfig.defaultyLobbyTypeCategory[i].defaultCategory;
                }
            }
        }
        return '';
    }

    getParentCategoryId(childCategoryid: string): string {
        const lobbyCategories: any = this.getAllCategories();
        let parentCategoryId: string = '';
        if (lobbyCategories && lobbyCategories.size > 0 && lobbyCategories.get(childCategoryid)) {
            parentCategoryId = lobbyCategories.get(childCategoryid).parentCategoryid;
            if (!parentCategoryId) parentCategoryId = childCategoryid;
        }
        return parentCategoryId;
    }
    // NFR - Services optimization - Remove
    getDefaultLMTCategory() {
        const casinoConfig = this.configProviderService.provideCasinoConfig();
        return this.getLMTMap(casinoConfig.defaultCategory);
    }

    getLMTMap(categoryOnAddressBar: string): any {
        if (!categoryOnAddressBar || categoryOnAddressBar === '') {
            return this.getDefaultCategoryInfo();
        }
        if (categoryOnAddressBar?.toLowerCase() === this.getDefaultCategoryFromDynacon()?.toLowerCase()) {
            return this.getDefaultCategoryInfo();
        }
        return this.fetchCategoryInfoOnsiteCoreDataCategoryId(categoryOnAddressBar);
    }

    fetchCategoryInfoOnsiteCoreDataCategoryId(sitecoreDataCategoryId: string) {
        if (sitecoreDataCategoryId) {
            const allCategoryLookUpMap: any = this.getAllCategories();
            if (allCategoryLookUpMap && allCategoryLookUpMap.size > 0) {
                for (const key of Array.from(allCategoryLookUpMap.keys())) {
                    const category: any = allCategoryLookUpMap.get(key);
                    if (category && category.categoryid && category?.categoryid?.toLowerCase() === sitecoreDataCategoryId?.toLowerCase()) {
                        return allCategoryLookUpMap.get(key);
                    }
                }
                return null;
            }
        }
        return null;
    }

    getDefaultCategoryInfo() {
        const defaultLobbyCategory: string = this.getDefaultCategoryFromDynacon();
        const defaultCategoryInfo: any = this.fetchCategoryInfoOnsiteCoreDataCategoryId(defaultLobbyCategory);
        if (defaultCategoryInfo) {
            return defaultCategoryInfo;
        } else {
            const allCategoryLookUpMap: any = this.getAllCategories();
            if (allCategoryLookUpMap && allCategoryLookUpMap.size > 0) {
                return allCategoryLookUpMap.entries().next().value[1];
            }
        }
        return null;
    }
    // NFR - Services optimization - Remove
    setUserSessionLMTLookUpFromLocalStorage() {
        const lobbyType: any = this.casinoManager.getLobbyType();

        const categoryMap: any = this.cacheManager.getAllCategoriesFromLocalCache();
        this.lobbyCategoryLookup.set(lobbyType, categoryMap);
        this.parentCategoryLookUpMap.set(lobbyType, this.setParentCategoryLookUpMap(categoryMap));
        if (categoryMap && categoryMap.size > 0) {
            this.categoryLookUpFilledPublisher.next(categoryMap);
        }
    }
    mapGameStickers(lookUpResponse: any) {
        const gameStickersMap: any = {};
        lookUpResponse.forEach((category: any) => {
            if (category['gamesModel'])
                category['gamesModel'].forEach((gameModel: any) => {
                    'sticker' in gameModel && (gameStickersMap[gameModel.game] = gameModel.sticker);
                });
        });
        this.gameStickersMap = gameStickersMap;
        this.cacheManager.setGameToStickersMap(this.gameStickersMap);
        this.stickersLookupCompletePublisher.next(true);
    }
}
