import { AsyncPipe, NgClass, NgIf, SlicePipe } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
    computed,
    inject,
    input,
    signal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatGridListModule } from '@angular/material/grid-list';

import { CasinoManager, ConfigProviderService, ConstService } from '@casinocore/platform/core';
import { DeviceService, ResizeObserverService } from '@frontend/vanilla/core';
import {
    NguCarousel,
    NguCarouselConfig,
    NguCarouselDefDirective,
    NguCarouselNextDirective,
    NguCarouselPrevDirective,
    NguCarouselStore,
    NguItemComponent,
} from '@ngu/carousel';
import { RxLet } from '@rx-angular/template/let';
import { isEqual } from 'lodash-es';
import { filter, of, take, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { GameLobbyComponent } from '../gamelobby/gamelobby.component';

@Component({
    selector: 'cc-feature-grid',
    templateUrl: 'feature-grid.component.html',
    standalone: true,
    imports: [
        NgIf,
        MatGridListModule,
        NgClass,
        GameLobbyComponent,
        SlicePipe,
        NguCarouselDefDirective,
        NguCarouselNextDirective,
        NguCarouselPrevDirective,
        NguItemComponent,
        NguCarousel,
        AsyncPipe,
        RxLet,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            :host {
                display: block;
            }

            .game {
                height: 100%;
            }
        `,
    ],
})
export class FeatureGridComponent implements AfterViewInit, OnInit {
    private resizeObserver = inject(ResizeObserverService);
    private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

    _games = input.required<any[]>({ alias: 'games' });
    games = computed(() => {
        const games = this._games();
        this.setInfoClassIconForGames(games);
        return games;
    });
    subcategory = input.required<any>();
    rowCount = computed(() => this.subcategory()?.rowCount || 0);
    isTouch = input<boolean>(false);
    _Type = input.required<string>({ alias: 'Type' });
    Type = computed(() => {
        if (this._Type() == 'scrollable-games' && this.isTouch() && this.subcategory().categoryWithMultipleGameIconTypes) {
            return 'scrollable-games-multiple-icons';
        }
        return this._Type();
    });
    EnableFutureGrid = input(true);
    gameLaunchedFrom = input<string>('');
    lobbyType = input<string>('');
    changeCarouselView = input<boolean>(false);
    _newFeatureGridArr = input.required<any[]>({ alias: 'newFeatureGridArr' }); //Game array
    newFeatureGridArr = computed(() => this._newFeatureGridArr() ?? this.games());
    visibleNewFeatureGridArr = computed(() => this.newFeatureGridArr().slice(0, this.currentIndex()));
    newFeatureGridArr$ = toObservable(this.visibleNewFeatureGridArr);
    breakpoint = computed(() => this.computeBreakpoint());

    @ViewChild('desktopGamesCarousel') desktopGamesCarousel: NguCarousel<any>;
    @ViewChild('mobileGamesCarousel') mobileGamesCarousel: NguCarousel<any>;

    gridContainerWidth = toSignal(this.resizeObserver.observe(this.elementRef).pipe(map((rect) => rect.contentBoxSize[0].inlineSize)), {
        initialValue: this.deviceService.isMobileWidth() ? this.deviceService.windowWidth() - 20 : Math.min(1210, this.deviceService.windowWidth()),
    });

    /* we need to keep this config mutable as NguCarousel mutates it */
    private defaultCarouselConfig = {
        grid: { xs: 3, sm: 6, md: 6, lg: 6, all: 0 },
        slide: 6,
        speed: 400,
        point: {
            visible: false,
        },
        loop: false,
        touch: false,
    };
    carouselConfigNonTouch = computed<NguCarouselConfig>(
        () => {
            return this.computeCarouselGrid(this.defaultCarouselConfig);
        },
        /* we need to keep this config mutable as NguCarousel mutates it */
        { equal: isEqual },
    );
    width = computed(() => {
        if ((this.rowCount() === '1' || this.rowCount() === '2') && this.isTouch()) {
            const width = this.deviceService.windowWidth();
            if (width <= 320) {
                return 2.4 * width;
            } else if (width <= 480) {
                return 2.4 * width;
            } else if (width < 625) {
                return 1.72 * width;
            } else if (width < 768) {
                return 1.33 * width;
            } else if (width < 1000) {
                return 1.33 * width;
            } else if (width < 1360) {
                return 27 + width;
            } else {
                return 37 + width;
            }
        }
        return null;
    });
    featureGridHeightTimeout = computed(() => {
        const modularConfig = this.configProviderService.provideModularSettingsConfig();
        return this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType(), modularConfig.featuredGridSetHeightTimeOut) || 100;
    });
    height = toSignal(
        toObservable(
            computed(() => {
                // just a trigger code
                if (this.deviceService.windowWidth()) {
                    return (this.rowCount() === '1' || this.rowCount() === '2') && this.isTouch();
                } else {
                    return 500;
                }
            }),
        ).pipe(
            switchMap((shouldCalculateOrHeight) => {
                if (shouldCalculateOrHeight === true) {
                    return timer(this.featureGridHeightTimeout()).pipe(
                        map(() => {
                            const element = document.getElementById('heightFix-' + this.subcategory().categoryid + '-' + this.games()[0].gameId);
                            if (element) {
                                if (
                                    this.games()[0].iconType === '1' ||
                                    this.games()[0].iconType === '2' ||
                                    this.games()[0].iconType === '6' ||
                                    this.games()[0].iconType === '7'
                                ) {
                                    return element.clientHeight * 2 + 20;
                                } else {
                                    return element.clientHeight + 20;
                                }
                            }
                            return null;
                        }),
                    );
                } else if (shouldCalculateOrHeight === false) {
                    return of(null);
                } else {
                    return of(shouldCalculateOrHeight);
                }
            }),
        ),
        { initialValue: null },
    );
    currentIndex = computed(() => this.computeCurrentIndex());
    isLastCarousel = signal(false);
    isPreButtonVisible = signal(true);
    isNextButtonVisible = computed(() => {
        if (this.isLastCarousel()) {
            return true;
        }
        if (this.games() && this.games().length) {
            return !(
                (this.rowCount() === '-1' || this.rowCount() === '-2' || this.rowCount() === '-3') &&
                this.games().length > this.carouselConfigNonTouch().grid.lg * Math.abs(this.rowCount())
            );
        } else {
            return true;
        }
    });
    isIconSevenCategory = computed(() => !!this.subcategory()?.isIconSevenCategory);
    gutterSize = computed(() => (this.isIconSevenCategory() ? '3px' : '0px'));
    rowHeight = computed(() => (this.isIconSevenCategory() ? '112' : '1:1'));
    @Output() loadNextGames: EventEmitter<void> = new EventEmitter<void>(true);
    @Output() carouselDataEvent: EventEmitter<null> = new EventEmitter<null>();
    jackpot_justify_center_icon_7_xs = computed<boolean>(
        () =>
            this.games().length === 1 &&
            this.subcategory().isIconSevenCategory &&
            this.gameLaunchedFrom() === `JackpotWidget_${this.subcategory().engCategoryName}`,
    );
    jackpot_justify_center_icon_7_sm = computed<boolean>(
        () =>
            this.games().length === 2 &&
            this.subcategory().isIconSevenCategory &&
            this.gameLaunchedFrom() === `JackpotWidget_${this.subcategory().engCategoryName}`,
    );

    unscrollableHeight = computed(() => {
        if (this.Type() === 'unscrollable-games' && this.height() == null) {
            const cols = this.breakpoint();
            const rowHeight = this.rowHeight();
            const tiles = this.visibleNewFeatureGridArr().reduce<number>((totalTiles, game) => {
                const gameTiles = game.colSpan * game.rowSpan;
                return totalTiles + gameTiles;
            }, 0);
            const rows = Math.ceil(tiles / cols);

            if (rowHeight === '1:1') {
                const windowWidth = this.gridContainerWidth();
                const widthPerCol = windowWidth / cols;
                return rows * widthPerCol;
            } else {
                return rows * parseInt(rowHeight);
            }
        }
        return null;
    });

    scrollableMultipleIconsHeight = computed(() => {
        if (this.Type() === 'scrollable-games-multiple-icons' && this.isTouch()) {
            const windowWidth = this.deviceService.windowWidth();
            // values based on homelobby.scss .scrollable-games-multiple-icons rules
            let ratio = 0.18;
            if (windowWidth < 1024) {
                if (windowWidth >= 640) {
                    ratio = 0.223;
                } else {
                    ratio = 0.4;
                }
            }
            return this.gridContainerWidth() * ratio;
        }
        return null;
    });

    games$ = toObservable(this.games);

    resetDesktopCarousel$ = toObservable(
        computed(() => this.subcategory()?.categoryid?.toLowerCase() === ConstService.FAVOURITE_GAMES_CATEGORY_ID && this.games()?.length > 0),
    ).pipe(take(1), filter(Boolean));

    readonly destroyRef = inject(DestroyRef);

    constructor(
        private configProviderService: ConfigProviderService,
        private casinoManager: CasinoManager,
        private deviceService: DeviceService,
    ) {}

    ngOnInit() {
        this.loadNextGames.emit();
        if (this.changeCarouselView() && !this.isTouch()) {
            this.emitCarouselData();
        }
    }

    ngAfterViewInit() {
        this.resetDesktopCarousel$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.desktopGamesCarousel?.moveTo(0);
        });
    }

    onmoveFn(data: NguCarouselStore) {
        this.isPreButtonVisible.set(data.isFirst);
        this.isLastCarousel.set(data.isLast);
        if (this.changeCarouselView() && !this.isTouch()) {
            this.emitCarouselData();
        }
    }

    prevItem() {
        this.desktopGamesCarousel.moveTo(this.desktopGamesCarousel.activePoint - 1);
    }

    nextItem() {
        this.desktopGamesCarousel.moveTo(this.desktopGamesCarousel.activePoint + 1);
    }

    emitCarouselData() {
        const data: any = {
            showArrows: this.Type() === 'scrollable-games' && !(this.isPreButtonVisible() && this.isNextButtonVisible()),
            disablePrev: this.isPreButtonVisible(),
            disableNext: this.isNextButtonVisible(),
        };
        this.carouselDataEvent.emit(data);
    }

    getClassByicon(game: any) {
        if (game && game.iconType && this.EnableFutureGrid()) {
            return 'icon-' + game.iconType;
        } else if (
            game &&
            game.iconType &&
            (this.gameLaunchedFrom() === 'JackpotWidget_' + this.subcategory().engCategoryName || 'MustGoJackpot_' + this.subcategory().categoryid)
        ) {
            return 'icon-' + game.iconType;
        } else {
            return 'icon-1';
        }
    }

    private setInfoClassIconForGames(gamesArr: any[]) {
        if (gamesArr && gamesArr.length > 0) {
            const isFromJackpot = this.gameLaunchedFrom() && this.gameLaunchedFrom()?.toLowerCase().indexOf('jackpotwidget') > -1;
            for (let i = 0; i < gamesArr.length; i++) {
                const iconType = isFromJackpot ? '1' : gamesArr[i].iconType;
                const currentWidth = this.deviceService.windowWidth();
                gamesArr[i].infoIconClass = this.casinoManager.assignGameClass(currentWidth, iconType);
            }
        }
    }

    private computeBreakpoint(): number {
        const width = this.deviceService.windowWidth();
        let breakpoint = 0;
        if ((this.rowCount() === '1' || this.rowCount() === '2') && this.isTouch()) {
            breakpoint = 6;
        } else {
            if (this.subcategory().isIconSevenCategory) {
                if ((this.rowCount() === '1' || this.rowCount() === '2') && !this.isTouch()) {
                    breakpoint = 8;
                } else if (width < 568) {
                    breakpoint = 2;
                } else if (width < 1024) {
                    breakpoint = 4;
                } else {
                    breakpoint = 8;
                }
            } else if (this.subcategory().isIconSixCategory) {
                if (width < 568) {
                    breakpoint = 3;
                } else if (width < 768) {
                    breakpoint = 4;
                } else {
                    breakpoint = 6;
                }
            } else if (width < 375) {
                breakpoint = 2;
            } else if (width <= 480 && window.matchMedia('(orientation:portrait)').matches) {
                breakpoint = this.casinoManager.IsFeatureEnabledforLobbytype(
                    this.lobbyType(),
                    this.configProviderService.provideFeaturesConfig().matGridColCountForLobbyType,
                );
            } else if (width < 568) {
                breakpoint = 3;
            } else if (width < 768) {
                breakpoint = 4;
            } else {
                breakpoint = 6;
            }
        }
        return breakpoint;
    }

    private computeCurrentIndex() {
        let currentSlideCount = 12;
        let currentIndex = 1;
        if (this.rowCount() === '1' || this.rowCount() === '2') {
            for (let i = 0; i < this.games().length; i++) {
                if (currentSlideCount > 0) {
                    const game = this.games()[i];
                    if (game.iconType == '1') {
                        currentSlideCount = currentSlideCount - 1;
                    } else if (game.iconType == '2') {
                        currentSlideCount = currentSlideCount - 2;
                    } else if (game.iconType == '3') {
                        currentSlideCount = currentSlideCount - 2;
                    } else if (game.iconType == '4') {
                        currentSlideCount = currentSlideCount - 4;
                    } else if (game.iconType == '6') {
                        currentSlideCount = currentSlideCount - 1;
                    } else if (game.iconType == '7') {
                        if (this.isTouch()) {
                            currentSlideCount = currentSlideCount - 2;
                        } else {
                            currentSlideCount = currentSlideCount - 1.5;
                        }
                    } else if (game.iconType == '10') {
                        currentSlideCount = currentSlideCount - 4;
                    } else {
                        if (this.isTouch()) {
                            currentSlideCount = currentSlideCount - 4;
                        } else {
                            currentSlideCount = currentSlideCount - 6;
                        }
                    }
                    currentIndex = i + 1;
                }
            }
        } else {
            currentIndex = this.subcategory()?.gamelist?.length ?? 1;
        }
        return currentIndex;
    }

    private computeCarouselGrid(config: NguCarouselConfig): NguCarouselConfig {
        if (this.subcategory()?.isIconSevenCategory) {
            config.grid = { xs: 1, sm: 2, md: 4, lg: 4, all: 0 };
        }

        if (this.games()?.length > 0 && !this.isTouch() && this.EnableFutureGrid()) {
            switch (this.games()[0].iconType) {
                case '1':
                case '3':
                    config.grid.sm = 3;
                    config.grid.md = 6;
                    config.grid.lg = 6;
                    break;
                case '2':
                case '4':
                    config.grid.sm = 3;
                    config.grid.md = 3;
                    config.grid.lg = 3;
                    break;
                case '5':
                    config.grid.sm = 2;
                    config.grid.md = 2;
                    config.grid.lg = 2;
                    break;
                case '7':
                    config.grid.xs = 1;
                    config.grid.sm = 2;
                    config.grid.md = 4;
                    config.grid.lg = 4;
                    break;
                case '10':
                    config.grid.sm = 3;
                    config.grid.md = 3;
                    config.grid.lg = 3;
                    break;
                default:
                    config.grid.sm = 3;
                    config.grid.md = 6;
                    config.grid.lg = 6;
            }
        } else if (
            this.games()?.length > 0 &&
            this.isTouch() &&
            (this.EnableFutureGrid() ||
                this.gameLaunchedFrom() === 'JackpotWidget_' + this.subcategory().engCategoryName ||
                'MustGoJackpot_' + this.subcategory().categoryid)
        ) {
            switch (this.games()[0].iconType) {
                case '1':
                case '3':
                case '6':
                    config.grid.xs = 2.5;
                    config.grid.sm = 4.5;
                    config.grid.md = 5.5;
                    config.grid.lg = 5.5;
                    if (this.deviceService.windowWidth() > 586 && this.deviceService.windowWidth() < 768) {
                        config.grid.xs = 4.5;
                    }
                    break;
                case '2':
                case '4':
                case '10':
                case '5':
                    config.grid.xs = 1.3;
                    config.grid.sm = 2.3;
                    config.grid.md = 2.7;
                    config.grid.lg = 2.7;
                    if (this.deviceService.windowWidth() > 586 && this.deviceService.windowWidth() < 768) {
                        config.grid.xs = 2.3;
                    }
                    break;
                case '7':
                    config.grid.xs = 1.2;
                    config.grid.sm = 2.2;
                    config.grid.md = 2.3;
                    config.grid.lg = 2.3;
                    if (this.deviceService.windowWidth() > 586 && this.deviceService.windowWidth() < 768) {
                        config.grid.xs = 2.2;
                    }
                    break;
                default:
                    config.grid.xs = 2.5;
                    config.grid.sm = 4.5;
                    config.grid.md = 5.5;
                    config.grid.lg = 5.5;
            }
        }
        return config;
    }
}
