import { Injectable } from '@angular/core';

import { ClientConfigService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ABTestingClientConfig } from '../client-config/abtesting-config';
import { AdvanceGameDataClientConfig } from '../client-config/advance-game-data.config';
import { ApiPathsClientConfig } from '../client-config/api-paths.config';
import { ArcadeLobbyClientConfig } from '../client-config/arcadelobby-config';
import { CasinoCoreConfigClientConfig } from '../client-config/casino-config.config';
import { CasinoAsIFrameOverlayConfig } from '../client-config/casino-overlay.config';
import { ClientSideConfigClientConfig } from '../client-config/clientside-config';
import { CommonClientConfig } from '../client-config/common.config';
import { CozyBingoClientConfig } from '../client-config/cozy-bingo.config';
import { DevSettingsConfig } from '../client-config/dev-settings.config';
import { DiscoverFeedClientConfig } from '../client-config/discover-feed.config';
import { DownloadAppFromDeviceClientConfig } from '../client-config/download-app-from-device.config';
import { FeaturesClientConfig } from '../client-config/features.config';
import { FeedCacheClientConfig } from '../client-config/feed-cache.config';
import { FreeSpinsClientConfig } from '../client-config/freespins.config';
import { GameLaunchTrackingClientConfig } from '../client-config/game-launch-tracking.config';
import { GamePremiereClientConfig } from '../client-config/game-premiere.config';
import { GameSelectorWidgetClientConfig } from '../client-config/game-selector-widget.config';
import { GameShowHubClientConfig } from '../client-config/game-show-hub.config';
import { GameConfigClientConfig } from '../client-config/game.config';
import { GameImpressionsClientConfig } from '../client-config/gameimpressions-config';
import { GamificationWidgetConfig } from '../client-config/gamification-widget-config';
import { GamingStoriesClientConfig } from '../client-config/gaming-stories-config';
import { GamingStoriesPhase2ClientConfig } from '../client-config/gaming-stories-phase2-config';
import { GeolocationClientConfig } from '../client-config/geo-location.config';
import { GlobalJackpotClientConfig } from '../client-config/global-jackpot.config';
import { GlobalSearchClientConfig } from '../client-config/global-search.config';
import { GlobalClientConfig } from '../client-config/global.config';
import { HeaderTeasersClientConfig } from '../client-config/header-teaser.config';
import { ImageLoadOptimizationClientConfig } from '../client-config/image-load-optimization.config';
import { ImmersiveLobbyClientConfig } from '../client-config/immersive-lobby.config';
import { JackpotDataClientConfig } from '../client-config/jackpot-data.config';
import { LiveCasinoLoungeClientConfig } from '../client-config/live-casino-lounge.config';
import { LiveDealerConfigClientConfig } from '../client-config/live-dealer.config';
import { LiveCasinoClientConfig } from '../client-config/livecasino-config';
import { LMTDataClientConfig } from '../client-config/lmt-data.config';
import { McPageClientConfig } from '../client-config/mc-page.config';
import { MinBetGamesClientConfig } from '../client-config/minbetgames-config';
import { ModularBingoClientConfig } from '../client-config/modular-bingo.config';
import { ModularSettingsClientConfig } from '../client-config/modular-settings.config';
import { NativeGameLaunchClientConfig } from '../client-config/native-game-launch.config';
import { NewsFeedConfigClientConfig } from '../client-config/news-feed.config';
import { NonCriticalClientConfig } from '../client-config/noncritical-config';
import { OptimizedTeaserClientConfig } from '../client-config/optimized-teaser.config';
import { PlayerStatsWidgetClientConfig } from '../client-config/player-stats-config';
import { PreLoginClientConfig } from '../client-config/pre-login.config';
import { PromoCoinEconomyClientConfig } from '../client-config/promo-coin-economy-config';
import { PromoOfferWidgetConfig } from '../client-config/promo-offer-widget-config';
import { PromotedGamesClientConfig } from '../client-config/promoted-games.config';
import { PromotionsBannerClientConfig } from '../client-config/promotions-banner.config';
import { PromotionsInfoClientConfig } from '../client-config/promotions-info.config';
import { QuickInfoClientConfig } from '../client-config/quick-info.config';
import { RecentWinnersWidgetClientConfig } from '../client-config/recent-winners-config';
import { RecentlyPlayedClientConfig } from '../client-config/recentlyplayed-games.config';
import { SearchClientConfig } from '../client-config/search.config';
import { SlotRacesClientConfig } from '../client-config/slot-races.config';
import { SmartLobbyClientConfig } from '../client-config/smart-lobby.config';
import { SubNavigationClientConfig } from '../client-config/sub-nav.config';
import { SuperButtonClientConfig } from '../client-config/super-button.config';
import { ThumbnailConfigClientConfig } from '../client-config/thumbnailconfig';
import { VideoPreviewClientConfig } from '../client-config/video-preview.config';
import { WidgetConfigClientConfig } from '../client-config/widget.config';
import { ProductApiService } from '../platform-api/product-api.service';
import { UrlUtilityService } from '../public-api';

@Injectable({
    providedIn: 'root',
})
export class ConfigProviderService {
    casinoConfig: any;
    featuresConfig: any;
    globalSearchConfig: any;
    lmtConfig: any;
    globalConfig: GlobalClientConfig;
    devSettingsClientConfig: any;
    globalJackpotConfig: any;
    gameConfig: any;
    smartLobbyConfig: any;
    mcPageConfig: any;
    liveDealerConfig: any;
    jackpotConfig: any;
    nativegamelaunchConfig: any;
    geolocationConfig: any;
    casinoAsIFrameConfig: any;
    feedCacheConfig: any;
    advanceGameDataConfig: any;
    widgetConfig: any;
    quickInfoConfig: any;
    optimizedTeaserConfig: any;
    promotionsInfoConfig: any;
    discoverFeedConfig: any;
    subNavigationConfig: any;
    modularSettingsConfig: any;
    liveCasinoConfig: any;
    newsFeedCasinoConfig: any;
    slotRacesConfig: SlotRacesClientConfig;
    promotedGamesConfig: any;
    thumbnailConfig: any;
    casinoClientConfigLoadedSubject = new BehaviorSubject<boolean>(false);
    //Subscribe to this observable to get the status of the casino client config of the lobby
    casinoClientConfigObservable: Observable<boolean> = this.casinoClientConfigLoadedSubject.asObservable();
    cozybingoConfig: any;
    recentlyPlayedConfig: any;
    headerTeasersConfig: any;
    freeSpinsClientConfig: any;
    imageLoadOptimizationConfig: ImageLoadOptimizationClientConfig;
    clientSideConfig: any;
    apiPathsConfig: any;
    searchConfig: any;
    nonCriticalConfig: any;
    abTestingConfig: any;
    downloadAppFromDeviceConfig: any;
    gamingStoriesClientConfig: any;
    preLoginConfig: any;
    promoOfferWidgetConfig: any;
    gamificationWidgetConfig: any;
    gameSelectorWidgetConfig: any;
    gameShowHubConfig: any;
    liveCasinoLoungeConfig: any;
    immersiveLobbyConfig: any;
    gamingStoriesPhase2Config: any;
    superButtonConfig: any;
    gameLaunchTrackingConfig: any;
    commonConfig: any;
    promotionsBannerConfig: any;
    videoPreviewConfig: VideoPreviewClientConfig;
    recentWinnersWidgetConfig: any;
    modularBingoClientConfig: any;
    arcadeLobbyConfig: any;
    promoCoinEconomyConfig: any;
    gamePremiereConfig: any;
    minBetGamesConfig: any;
    playerStatsWidgetConfig: any;
    gameImpressionsConfig: any;
    constructor(
        private api: ProductApiService,
        private clientConfigService: ClientConfigService,
        private urlUtilityService: UrlUtilityService,
    ) {}

    fillClientConfig(isDll: boolean) {
        if (isDll) {
            this.casinoConfig = this.clientConfigService.get(CasinoCoreConfigClientConfig);
            this.globalSearchConfig = this.clientConfigService.get(GlobalSearchClientConfig);
            this.featuresConfig = this.clientConfigService.get(FeaturesClientConfig);
            this.globalConfig = this.clientConfigService.get(GlobalClientConfig);
            this.devSettingsClientConfig = this.clientConfigService.get(DevSettingsConfig);
            this.lmtConfig = this.clientConfigService.get(LMTDataClientConfig);
            this.gameConfig = this.clientConfigService.get(GameConfigClientConfig);
            this.videoPreviewConfig = this.clientConfigService.get(VideoPreviewClientConfig);
            this.smartLobbyConfig = this.clientConfigService.get(SmartLobbyClientConfig);
            this.globalJackpotConfig = this.clientConfigService.get(GlobalJackpotClientConfig);
            this.mcPageConfig = this.clientConfigService.get(McPageClientConfig);
            this.liveDealerConfig = this.clientConfigService.get(LiveDealerConfigClientConfig);
            this.jackpotConfig = this.clientConfigService.get(JackpotDataClientConfig);
            this.nativegamelaunchConfig = this.clientConfigService.get(NativeGameLaunchClientConfig);
            this.geolocationConfig = this.clientConfigService.get(GeolocationClientConfig);
            this.casinoAsIFrameConfig = this.clientConfigService.get(CasinoAsIFrameOverlayConfig);
            this.feedCacheConfig = this.clientConfigService.get(FeedCacheClientConfig);
            this.advanceGameDataConfig = this.clientConfigService.get(AdvanceGameDataClientConfig);
            this.widgetConfig = this.clientConfigService.get(WidgetConfigClientConfig);
            this.quickInfoConfig = this.clientConfigService.get(QuickInfoClientConfig);
            this.optimizedTeaserConfig = this.clientConfigService.get(OptimizedTeaserClientConfig);
            this.promotionsInfoConfig = this.clientConfigService.get(PromotionsInfoClientConfig);
            this.discoverFeedConfig = this.clientConfigService.get(DiscoverFeedClientConfig);
            this.subNavigationConfig = this.clientConfigService.get(SubNavigationClientConfig);
            this.liveCasinoConfig = this.clientConfigService.get(LiveCasinoClientConfig);
            this.newsFeedCasinoConfig = this.clientConfigService.get(NewsFeedConfigClientConfig);
            this.modularSettingsConfig = this.clientConfigService.get(ModularSettingsClientConfig);
            this.slotRacesConfig = this.clientConfigService.get(SlotRacesClientConfig);
            this.promotedGamesConfig = this.clientConfigService.get(PromotedGamesClientConfig);
            this.thumbnailConfig = this.clientConfigService.get(ThumbnailConfigClientConfig);
            this.cozybingoConfig = this.clientConfigService.get(CozyBingoClientConfig);
            this.recentlyPlayedConfig = this.clientConfigService.get(RecentlyPlayedClientConfig);
            this.headerTeasersConfig = this.clientConfigService.get(HeaderTeasersClientConfig);
            this.freeSpinsClientConfig = this.clientConfigService.get(FreeSpinsClientConfig);
            this.imageLoadOptimizationConfig = this.clientConfigService.get(ImageLoadOptimizationClientConfig);
            this.clientSideConfig = this.clientConfigService.get(ClientSideConfigClientConfig);
            this.apiPathsConfig = this.clientConfigService.get(ApiPathsClientConfig);
            this.searchConfig = this.clientConfigService.get(SearchClientConfig);
            this.nonCriticalConfig = this.clientConfigService.get(NonCriticalClientConfig);
            this.downloadAppFromDeviceConfig = this.clientConfigService.get(DownloadAppFromDeviceClientConfig);
            this.gamingStoriesClientConfig = this.clientConfigService.get(GamingStoriesClientConfig);
            this.preLoginConfig = this.clientConfigService.get(PreLoginClientConfig);
            this.promoOfferWidgetConfig = this.clientConfigService.get(PromoOfferWidgetConfig);
            this.gamificationWidgetConfig = this.clientConfigService.get(GamificationWidgetConfig);
            this.abTestingConfig = this.clientConfigService.get(ABTestingClientConfig);
            this.gameSelectorWidgetConfig = this.clientConfigService.get(GameSelectorWidgetClientConfig);
            this.gameShowHubConfig = this.clientConfigService.get(GameShowHubClientConfig);
            this.commonConfig = this.clientConfigService.get(CommonClientConfig);
            this.liveCasinoLoungeConfig = this.clientConfigService.get(LiveCasinoLoungeClientConfig);
            this.immersiveLobbyConfig = this.clientConfigService.get(ImmersiveLobbyClientConfig);
            this.gamingStoriesPhase2Config = this.clientConfigService.get(GamingStoriesPhase2ClientConfig);
            this.superButtonConfig = this.clientConfigService.get(SuperButtonClientConfig);
            this.gameLaunchTrackingConfig = this.clientConfigService.get(GameLaunchTrackingClientConfig);
            this.recentWinnersWidgetConfig = this.clientConfigService.get(RecentWinnersWidgetClientConfig);
            this.promotionsBannerConfig = this.clientConfigService.get(PromotionsBannerClientConfig);
            this.modularBingoClientConfig = this.clientConfigService.get(ModularBingoClientConfig);
            (this.arcadeLobbyConfig = this.clientConfigService.get(ArcadeLobbyClientConfig)),
                (this.promoCoinEconomyConfig = this.clientConfigService.get(PromoCoinEconomyClientConfig)),
                (this.minBetGamesConfig = this.clientConfigService.get(MinBetGamesClientConfig));
            this.gamePremiereConfig = this.clientConfigService.get(GamePremiereClientConfig);
            this.gameImpressionsConfig = this.clientConfigService.get(GameImpressionsClientConfig);
            this.playerStatsWidgetConfig = this.clientConfigService.get(PlayerStatsWidgetClientConfig);
            this.setClientConfigLoadedObservable();
        } else {
            const options: any = { headers: {} };
            options.headers['Cache-Control'] = 'no-cache';
            options.headers['Pragma'] = 'no-cache';
            options.headers['Expires'] = '0';
            options.withCredentials = true;
            this.api.get(this.urlUtilityService.getClientConfigUrl(), {}, options).subscribe((response) => {
                if (response) {
                    this.casinoConfig = response.csCasinoConfig;
                    this.featuresConfig = response.csFeatures;
                    this.lmtConfig = response.cslmtData;
                    this.globalConfig = response.csGlobal;
                    this.globalSearchConfig = response.csCasinoGlobalSearch;
                    this.devSettingsClientConfig = response.csDevSettingsConfig;
                    this.globalJackpotConfig = response.csGlobalJackpot;
                    this.gameConfig = response.csGameConfig;
                    this.smartLobbyConfig = response.csSmartLobbyConfig;
                    this.mcPageConfig = response.csMcPage;
                    this.liveDealerConfig = response.csLiveDealerConfig;
                    this.jackpotConfig = response.csJackpotData;
                    this.nativegamelaunchConfig = response.csNativeGameLaunch;
                    this.geolocationConfig = response.csGeolocationConfig;
                    this.casinoAsIFrameConfig = response.csCasinoAsIFrameOverlayConfig;
                    this.feedCacheConfig = response.csFeedCacheConfiguration;
                    this.advanceGameDataConfig = response.csAdvanceGameDataModel;
                    this.quickInfoConfig = response.csQuickInfo;
                    this.widgetConfig = response.csWidgetConfig;
                    this.optimizedTeaserConfig = response.csOptimizedTeaser;
                    this.promotionsInfoConfig = response.csPromotionsInfo;
                    this.discoverFeedConfig = response.csDiscoverFeed;
                    this.subNavigationConfig = response.csSubNavigationConfig;
                    this.modularSettingsConfig = response.csModularSettings;
                    this.liveCasinoConfig = response.csLiveCasinoApi;
                    this.newsFeedCasinoConfig = response.csNewsFeedConfig;
                    this.promotedGamesConfig = response.csPromotedGamesConfig;
                    this.slotRacesConfig = response.csSlotRaces;
                    this.thumbnailConfig = response.csThumbnail;
                    this.cozybingoConfig = response.csCozyBingo;
                    this.recentlyPlayedConfig = response.csRecentlyPlayed;
                    this.headerTeasersConfig = response.csHeaderTeasers;
                    this.freeSpinsClientConfig = response.csFreeSpins;
                    this.clientSideConfig = response.csClientSideConfig;
                    this.imageLoadOptimizationConfig = response.csImageLoadOptimizationConfig;
                    this.apiPathsConfig = response.csApiPathsConfig;
                    this.searchConfig = response.csSearchConfig;
                    this.nonCriticalConfig = response.csNonCriticalFeedConfig;
                    this.abTestingConfig = response.csAbTestingConfig;
                    this.downloadAppFromDeviceConfig = response.csDownloadAppFromDevice;
                    this.promoOfferWidgetConfig = response.csPromoOfferWidget;
                    this.gamificationWidgetConfig = response.csGamificationWidget;
                    this.gamingStoriesClientConfig = response.csGamingStoriesConfig;
                    this.preLoginConfig = response.csPrelogin;
                    this.gameSelectorWidgetConfig = response.csGameSelectorConfig;
                    this.gameShowHubConfig = response.csGameShowHubConfig;
                    this.commonConfig = response.csCommonConfig;
                    this.liveCasinoLoungeConfig = response.csLiveCasinoLoungeConfig;
                    this.immersiveLobbyConfig = response.csImmersiveLobbyConfig;
                    this.videoPreviewConfig = response.csVideoPreviewConfig;
                    this.gameLaunchTrackingConfig = response.csGameLaunchTrackingConfig;
                    this.promotionsBannerConfig = response.csPromotionsBannerConfig;
                    this.gamePremiereConfig = response.csGamePremiere;
                    this.recentWinnersWidgetConfig = response.csRecentWinnersConfig;
                    this.gamingStoriesPhase2Config = response.csGamingStoriesPhase2Config;
                    this.modularBingoClientConfig = response.csModularBingo;
                    this.arcadeLobbyConfig = response.csAracdeLobbyConfig;
                    this.promoCoinEconomyConfig = response.csPromoCoinEconomyConfig;
                    this.minBetGamesConfig = response.csMinBetGamesConfig;
                    this.playerStatsWidgetConfig = response.csPlayerStatsConfig;
                    this.gameImpressionsConfig = response.csGameImpressionsConfig;
                    this.setClientConfigLoadedObservable();
                }
            });
        }
    }

    setClientConfigLoadedObservable() {
        this.casinoClientConfigLoadedSubject.next(true);
    }

    provideCasinoConfig() {
        return this.casinoConfig;
    }

    provideFeaturesConfig() {
        return this.featuresConfig;
    }

    provideLmtConfig() {
        return this.lmtConfig;
    }

    setLmtConfig(customLobbyTypes: any) {
        this.lmtConfig.lobbyTypeMap = customLobbyTypes;
    }

    provideGlobalConfig() {
        return this.globalConfig;
    }

    provideGlobalSearchConfig() {
        return this.globalSearchConfig;
    }

    provideDevSettingsConfig() {
        return this.devSettingsClientConfig;
    }

    provideGlobalJackpotConfig() {
        return this.globalJackpotConfig;
    }

    provideGameConfig() {
        return this.gameConfig;
    }

    provideSmartLobbyConfig() {
        return this.smartLobbyConfig;
    }

    provideThumbnailConfig() {
        return this.thumbnailConfig;
    }

    provideMcPageConfig() {
        return this.mcPageConfig;
    }

    provideLiveDealerConfig() {
        return this.liveDealerConfig;
    }

    provideJackpotConfig() {
        return this.jackpotConfig;
    }

    provideNativeGameLaunchConfig() {
        return this.nativegamelaunchConfig;
    }

    provideGeoLocationConfig() {
        return this.geolocationConfig;
    }

    provideCasinoAsIFrameConfig() {
        return this.casinoAsIFrameConfig;
    }

    provideFeedCacheConfig() {
        return this.feedCacheConfig;
    }

    provideOptimizedTeaserConfig() {
        return this.optimizedTeaserConfig;
    }

    provideAdvanceGameDataConfig() {
        return this.advanceGameDataConfig;
    }

    provideQuickInfoConfig() {
        return this.quickInfoConfig;
    }

    providewidgetConfig(): any {
        return this.widgetConfig;
    }

    providePromotionsInfoConfig() {
        return this.promotionsInfoConfig;
    }

    provideDiscoverFeedConfig() {
        return this.discoverFeedConfig;
    }

    provideSubNavigationConfig() {
        return this.subNavigationConfig;
    }

    provideModularSettingsConfig() {
        return this.modularSettingsConfig;
    }

    provideLiveCasinoConfig() {
        return this.liveCasinoConfig;
    }

    provideNewsFeedCasinoConfig() {
        return this.newsFeedCasinoConfig;
    }

    provideSlotRacesConfig(): SlotRacesClientConfig {
        return this.slotRacesConfig;
    }

    providePromotedGamesConfig() {
        return this.promotedGamesConfig;
    }

    provideCozyBingoConfig() {
        return this.cozybingoConfig;
    }

    providePromoOfferWidgetConfig() {
        return this.promoOfferWidgetConfig;
    }

    provideGamificationWidgetConfig() {
        return this.gamificationWidgetConfig;
    }

    provideRecentlyPlayedGamesConfig() {
        return this.recentlyPlayedConfig;
    }

    provideHeaderTeasersConfig() {
        return this.headerTeasersConfig;
    }

    providefreeSpinsClientConfig() {
        return this.freeSpinsClientConfig;
    }

    provideImageLoadOptimizationClientConfig(): ImageLoadOptimizationClientConfig {
        return this.imageLoadOptimizationConfig;
    }

    provideClientSideConfig() {
        return this.clientSideConfig;
    }

    provideApiPathsConfig() {
        return this.apiPathsConfig;
    }

    provideSearchConfig() {
        return this.searchConfig;
    }

    provideNonCriticalConfig() {
        return this.nonCriticalConfig;
    }

    provideABTestingConfig() {
        return this.abTestingConfig;
    }

    provideDownloadAppFromDeviceConfig() {
        return this.downloadAppFromDeviceConfig;
    }

    provideGamingStoriesConfig() {
        return this.gamingStoriesClientConfig;
    }

    providePreLoginConfig() {
        return this.preLoginConfig;
    }

    provideVideoPreviewConfig() {
        return this.videoPreviewConfig;
    }

    provideGameSelectorWidgetConfig() {
        return this.gameSelectorWidgetConfig;
    }

    provideGameShowHubConfig() {
        return this.gameShowHubConfig;
    }

    provideCommonConfig() {
        return this.commonConfig;
    }

    provideLiveCasinoLoungeConfig() {
        return this.liveCasinoLoungeConfig;
    }

    provideImmersiveLobbyConfig() {
        return this.immersiveLobbyConfig;
    }

    provideGamingStoriesPhase2Config() {
        return this.gamingStoriesPhase2Config;
    }

    provideSuperButtonConfig() {
        return this.superButtonConfig;
    }

    provideGameLaunchTrackingConfig() {
        return this.gameLaunchTrackingConfig;
    }

    providePromotionsBannerConfig() {
        return this.promotionsBannerConfig;
    }

    provideRecentWinnersWidgetConfig(): RecentWinnersWidgetClientConfig {
        return this.recentWinnersWidgetConfig;
    }
    provideModularBingoClientConfig() {
        return this.modularBingoClientConfig;
    }

    provideAracadeLobbyConfig() {
        return this.arcadeLobbyConfig;
    }

    providePromoCoinEconomyConfig() {
        return this.promoCoinEconomyConfig;
    }

    provideMinBetGamesConfig() {
        return this.minBetGamesConfig;
    }

    provideGameImpressionsConfig() {
        return this.gameImpressionsConfig;
    }

    provideGamePremiereConfig() {
        return this.gamePremiereConfig;
    }

    providePlayerStatsWidgetConfig(): PlayerStatsWidgetClientConfig {
        return this.playerStatsWidgetConfig;
    }
}
