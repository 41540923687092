import { Injectable } from '@angular/core';

import { RecentGamesService } from './recent-games.service';

@Injectable({
    providedIn: 'root',
})
export class SignPostingService {
    constructor(private recentGamesService: RecentGamesService) {}

    getSignPostingCount(categoryGames: any, recentGames: string[]): number {
        let signPostingCount: number = 0;
        const latestStickerGames: any = [];
        let stickers: any = [];
        if (categoryGames && categoryGames.length > 0) {
            categoryGames.forEach((element: any) => {
                if (element.sticker) {
                    stickers = element.sticker.indexOf('#') !== -1 ? element.sticker.split('#') : [element.sticker];
                    if (stickers.indexOf('LMS_LATEST') > -1) {
                        latestStickerGames.push(element);
                    }
                }
            });
        }

        if (latestStickerGames && latestStickerGames.length > 0 && recentGames) {
            const signPostedGames: any = latestStickerGames.filter((item: any) => !(recentGames.indexOf(item.game) > -1));
            if (signPostedGames) {
                signPostingCount = signPostedGames.length;
            }
        }
        return signPostingCount;
    }
    // NFR - Services optimization - Remove
    isSignPostedGame(game: any) {
        const recentGames = this.recentGamesService.getrecentGames();
        if (game.sticker && game.sticker.indexOf('LMS_LATEST') > -1) {
            if (recentGames && recentGames.length > 0 && recentGames.indexOf(game.gameId) !== -1) {
                return false;
            }
            return true;
        }
        return false;
    }
}
