import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectorRef, Component, DoCheck, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';

import {
    CasinoCoreGamelaunchService,
    CasinoCoreNpmParamsService,
    CasinoLobbyService,
    CasinoManager,
    CasinoWidgetService,
    ConfigProviderService,
    FastLoadingGamesService,
    FavouriteService,
    FreespinSummaryService,
    GameDataManager,
    GameLaunch,
    GlobalTranslateComponent,
    ImageLoaderComponent,
    JackpotDataService,
    LiveCasinoService,
    OpenLoginDialog,
    QuickInfoModalService,
    Race,
    RecentGamesService,
    SlotRacesService,
    UrlUtilityService,
} from '@casinocore/platform/core';
import { CookieService, DeviceService, Logger, Page, TimerService, TrackingService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { DestroyableDirective, InViewportDirective } from 'ng-in-viewport';
import { Subscription, filter } from 'rxjs';

import { CasinoCoreLazyPlaceholderComponent } from '../casino-core-lazy-placeholder/casino-core-lazy-placeholder.component';
import { FreeSpinsSummaryComponent } from '../freespins-summary/freespins-summary.component';
import { GameQuickInfoComponent } from '../game-quick-info/game-quick-info.component';
import { LibMockService } from '../services/lib-mock.service';
import { QuickInfoService } from '../services/quick-info.service';

@Component({
    selector: 'cc-arcade-game-lobby',
    templateUrl: 'arcade-game-lobby.component.html',
    standalone: true,
    imports: [
        DestroyableDirective,
        InViewportDirective,
        NgClass,
        NgStyle,
        NgIf,
        FreeSpinsSummaryComponent,
        GameQuickInfoComponent,
        CasinoCoreLazyPlaceholderComponent,
        ImageLoaderComponent,
        GlobalTranslateComponent,
        NgFor,
    ],
})
export class ArcadeGameLobbyComponent implements OnInit, DoCheck, OnChanges, OnDestroy {
    @ViewChild('gameref') gameref: ElementRef;
    @Input() category: any;
    @Input() game: any;
    @Input() radius: number;
    @Input() gamePosition: number;
    @Input() gameLaunchedFrom: string;
    @Input() gameModel: any;
    @Input() product: string = '';
    @Input() widgetFrameClr: string = '';
    @Input() gameIndex: any;
    @Input() lobbyType: string;
    @Input() Type: string;
    @ViewChild('videoPlayer') videoPlayer: ElementRef;

    unfinishedStyle: any;
    unfinishedText: any;
    isRecentlyPlayedCategory: boolean;
    isTouch: boolean;
    useFavouriteGames: boolean;
    unfinishedBGOpacity: any;
    unfinishedBG: string;
    gameProvider: string;
    cta: string;
    mouseHoveredOnGame: boolean = false;
    visiblestatus: boolean;
    QuickInfoVisible: string = '';
    showJackpotOnGameTiles: any;
    gameClass: string[] = [];
    jackpotHandle: any;
    showGameTitle: boolean;
    stickerName: any;
    stickers: any = [];
    liveGameInfo: any;
    icon: any = { src: '' };
    quickInfoIcon: any = { src: '' };
    useQuickInfo: boolean;
    onMouseHover: boolean;
    onMouseLeave: boolean;
    onLivecasinoMouseHover: boolean;
    onLivecasinoMouseLeave: boolean;
    favouriteStyle: string = 'theme-favorite-i';
    unFavouriteStyle: string = 'theme-favorite';
    isShowFavouriteHeartEnabled: boolean;
    playNowHoverEffect: boolean = false;
    gameTitle: string;
    quickInfoScrollY: number = 0;
    enableTickingForJackpotOnGameTiles: boolean;
    newJackpotRibbonStyling: boolean;
    isStickersForUk: boolean;
    hotIcon: any;
    epcotHotIcon: any;
    enableHotIcon: boolean;
    isAnchorTagVisible: boolean;
    enableUkMouseOverStyle: boolean;
    tileWidth: any;
    tileWidthInt: any;
    currentWidth: any;
    showLiveCasinoComponents: boolean;
    currentLobbyType: string;
    isLiveGame: boolean;
    liveCasinoApiSubscriber: any;
    enableQuickInfoforLiveCasinoApi: boolean;
    enableFavouriteGamesforLiveCasinoApi: boolean;
    isLiveCasinoEnabled: boolean;
    isTableOnline: boolean;
    widgetRecommendedTxtClr: string;
    filteredbingoGameItem: any;
    filteredbingoRoomItem: any;
    widgetRecommendedBgClr: string;
    widgetRecommended: string;
    isUnfinishedGame: boolean;
    moreInfo: string;
    Favourite: boolean;
    recentObs: any;
    optedIn: any;
    availableCount: any = 0;
    totalCount: any = 0;
    freespinCountGame: any;
    freespinObservable: any;
    showFreeSpinCount: boolean = false;
    enableFreeSpinSummary: boolean;
    demoEnabled: boolean;
    isSignPosting: boolean;
    stickerMap: any;
    promotedGameBackgroundColor: string;
    promotedGameTextColor: string;
    promotedGameText: string;
    gameContainerLocation: string;
    racesSubscription: Subscription;
    coinBalSubscription: Subscription;
    races: Race[] = [];
    isStickerEnabled: boolean;
    slotRacesStickerTimeOut: any;
    slotRacesStickerDataSubScription: Subscription;
    slotRacesConfig: boolean;
    globalConfig: any;
    isGameHover: boolean = false;
    videoUrl: string = '';
    gameVideoFormat: string = '';
    isVideoLoaded: boolean = false;
    isVideoPreviewEnabled: boolean = false;
    positiveVideoIconType = '10';
    negativeVideoIconType = '-10';
    afterLoaded: boolean;
    enableEpcotSubNavigation: boolean;
    isMobile: boolean;
    isTablet: boolean;
    playVideoFromStart: any;
    setVideoPlayTimeoutint: any;
    isNewQuickInfoEnabled: boolean;
    displayRecenetWinners: boolean;
    enableJpGroupTickingForLobby: boolean;
    recommendedGamesBackgroundColor: string;
    recommendedGamesFontColours: string;
    stickerKey: any = [];
    stickerStyles: any;
    enableStickerStyles: boolean;
    arcadeLobbyMessages: any;
    arcadeLobbyType: string;
    gameMinBet: number;
    isLoggedIn: boolean;
    userCoinBalance: number;
    isPlayDisabled: boolean;

    jpRibbonStyles: { jpBgColor: string; jpTextColor: string };
    constructor(
        private jackpotDataService: JackpotDataService,
        private user: UserService,
        private elementRef: ElementRef,
        private openLoginDialog: OpenLoginDialog,
        private favouriteService: FavouriteService,
        private cookie: CookieService,
        private gameDataManager: GameDataManager,
        private casinoManager: CasinoManager,
        private recentGamesService: RecentGamesService,
        private liveCasinoService: LiveCasinoService,
        private tracking: TrackingService,
        private casinoWidgetService: CasinoWidgetService,
        private freespinSummaryService: FreespinSummaryService,
        private configProviderService: ConfigProviderService,
        private fastLoadingGamesService: FastLoadingGamesService,
        private casinoLobbyService: CasinoLobbyService,
        private deviceService: DeviceService,
        private casinoCoreGameLaunchService: CasinoCoreGamelaunchService,
        private slotRacesService: SlotRacesService,
        private timerService: TimerService,
        private casinoNpmParamsService: CasinoCoreNpmParamsService,
        private quickInfoService: QuickInfoService,
        private libMockService: LibMockService,
        private quickInfoModalService: QuickInfoModalService,
        private logger: Logger,
        private changeDetection: ChangeDetectorRef,
        private urlUtilityService: UrlUtilityService,
        private page: Page,
    ) {
        this.libMockService.setQuickInfoService(this.quickInfoService);
        this.user.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            if (this.user.isAuthenticated && (this.isMobile || this.isTablet)) {
                localStorage.removeItem('isVideoEnded');
                localStorage.setItem('isVideoEnded', 'first');
                localStorage.removeItem('isVideoRunning');
            }
            this.isLoggedIn = true;
        });
    }

    ngDoCheck() {
        if (this.afterLoaded) {
            if (
                this.isVideoPreviewEnabled &&
                (this.game.iconType === this.positiveVideoIconType || this.game.iconType === this.negativeVideoIconType) &&
                (this.isMobile || this.isTablet) &&
                (localStorage.getItem('isVideoEnded') === 'first' ||
                    (localStorage.getItem('isVideoEnded') &&
                        JSON.parse(localStorage.getItem('isVideoEnded') || '').indexOf(this.game.gameId + this.category.categoryid) === -1)) &&
                !localStorage.getItem('isVideoRunning') &&
                this.game.isVideoLoaded
            ) {
                const element = document.getElementById('gameIdentifier_' + this.category.categoryid + '_' + this.game.gameId);
                if (this.isInViewport(element)) {
                    localStorage.setItem('isVideoRunning', this.category.categoryid + this.game.gameId);
                    this.logger.info('docheck', this.game.gameId);
                    const video = document
                        .querySelector('#' + 'gameIdentifier_' + this.category.categoryid + '_' + this.game.gameId)
                        ?.querySelector('video');
                    if (video) {
                        video.style.backgroundImage = 'url(' + this.game?.icon?.src + ')';
                    }
                    setTimeout(() => {
                        if (video) {
                            if (this.playVideoFromStart) {
                                video.currentTime = 0;
                                video.play();
                                this.game.isGameHover = true;
                            } else {
                                video.play();
                                this.game.isGameHover = true;
                            }
                        }
                    }, this.setVideoPlayTimeoutint);
                    this.tracking.triggerEvent('Event.Tracking', {
                        'component.CategoryEvent': 'games',
                        'component.LabelEvent': 'Video Preview Game Tile',
                        'component.ActionEvent': 'autoplay',
                        'component.PositionEvent': this.gamePosition,
                        'component.LocationEvent': this.category.categoryname + '-' + this.game.name,
                        'component.EventDetails': 'game video preview play',
                        'component.URLClicked': 'not applicable',
                    });
                }
            } else if (
                (this.game.iconType === this.positiveVideoIconType || this.game.iconType === this.negativeVideoIconType) &&
                (this.isMobile || this.isTablet) &&
                this.isVideoPreviewEnabled &&
                this.game.isGameHover &&
                this.game.isVideoLoaded &&
                localStorage.getItem('isVideoRunning')
            ) {
                const element = document.getElementById('gameIdentifier_' + this.category.categoryid + '_' + this.game.gameId);
                if (!this.isInViewport(element)) {
                    document
                        .querySelector('#' + 'gameIdentifier_' + this.category.categoryid + '_' + this.game.gameId)
                        ?.querySelector('video')
                        ?.pause();
                    this.videoEnded(this.game, this.game.gameId + this.category.categoryid);
                }
            }
        }
    }

    ngOnInit(): void {
        this.isLoggedIn = this.user.isAuthenticated;
        this.displayRecenetWinners = this.category?.sitecoreData?.displayRecentWinnersWidget;
        if (this.isMobile || this.isTablet) {
            localStorage.setItem('isVideoEnded', 'first');
        }
        this.isTouch = this.casinoManager.isTouch();
        this.isMobile = this.deviceService.isMobile;
        this.isTablet = this.deviceService.isTablet;
        this.currentLobbyType = this.game.lobbyType;
        this.assignFeatures();
        const featureConfig = this.configProviderService.provideFeaturesConfig();
        if (featureConfig.gameVideo && Boolean(JSON.parse(featureConfig?.gameVideo?.enabled?.toString()?.toLowerCase()))) {
            // some cases flag returning from dynacon as False
            this.gameVideoFormat = featureConfig.gameVideo.videoFormat;
            this.isVideoPreviewEnabled = true;
        }

        //TODO:liveCasinoApiConfig
        const liveCasinoConfig = this.configProviderService.provideLiveCasinoConfig();
        this.isLiveCasinoEnabled = this.casinoManager.IsFeatureEnabledforLobbytype(this.game.lobbyType, liveCasinoConfig.enableLobbyType);

        this.setStickers();
        this.checkforLatestStickers(this.recentGamesService.getrecentGames());
        if (this.isSignPosting && this.game.sticker && this.game.sticker.indexOf('LMS_LATEST') > -1) {
            this.recentObs = this.recentGamesService.recentGamesFeedObservable.subscribe((response) => {
                if (response) {
                    this.checkforLatestStickers(response);
                }
            });
        }
        this.setGameIconSrc();
        this.setJackPotValue();
        this.subscribtToJackpotFeed();

        //TODO: liveCasinoApiConfig
        this.enableQuickInfoforLiveCasinoApi = liveCasinoConfig.enableQuickInfo;
        this.enableFavouriteGamesforLiveCasinoApi = liveCasinoConfig.enableFavouriteGames;
        this.liveCasinoApiSubscriber = this.liveCasinoService.liveGamesFeedObservable.subscribe((response) => {
            if (response && response.size >= 0) {
                this.setLiveGameInfo();
            }
        });
        this.setLiveGameInfo();
        this.enableFreeSpinSummary = this.configProviderService.providefreeSpinsClientConfig().isFreeSpinSummaryEnabled;
        if (this.enableFreeSpinSummary) {
            this.freespinObservable = this.freespinSummaryService.freeSpinsGameDetailsObser.subscribe((freespinsdata: any) => {
                if (freespinsdata) {
                    this.bindFreeSpinInfo();
                }
            });
        }

        this.fastLoadingGamesService.fastLoadingGamesFeedObservable.subscribe((fastLoadingGames: Map<string, boolean>) => {
            if (fastLoadingGames && fastLoadingGames.size > 0) {
                this.setFastLoading(fastLoadingGames);
            }
        });
        this.setGameTitle();
        this.gameContainerLocation = 'Widget|' + this.product;
        if (this.slotRacesConfig) {
            this.setSlotRaceStickers();
        }
        this.game.gameVideoUrl = this.game?.icon?.src?.substr(0, this.game?.icon?.src?.lastIndexOf('.')) + this.gameVideoFormat;
    }

    setFastLoading(fastLoadingGames: Map<string, boolean>) {
        if (fastLoadingGames && fastLoadingGames.size > 0) {
            for (const val of fastLoadingGames) {
                if (val[0] == this.game.gameId) {
                    this.game.isFastLoadingGame = true;
                    break;
                }
            }
        }
    }
    assignFeatures() {
        this.arcadeLobbyMessages = this.configProviderService.provideGlobalConfig()?.arcadeLobbyConfigurations;
        this.arcadeLobbyType = this.configProviderService.provideGameConfig()?.arcadeLobbyType.toLowerCase();
        const features: any = this.configProviderService.provideFeaturesConfig();
        const subnavConfig: any = this.configProviderService.provideSubNavigationConfig();
        this.enableEpcotSubNavigation = subnavConfig.enableEpcotSubNavigation;
        this.useFavouriteGames = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.favouriteGamesLobbyType);
        this.showGameTitle = features.showGameTitle;
        this.isStickersForUk = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.stickersForUk);
        this.playNowHoverEffect = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.playNowHoverEffect);
        this.useQuickInfo = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.enableQuickInfoLobbyType);
        this.isNewQuickInfoEnabled = this.casinoManager.IsFeatureEnabledforLobbytypeWithInvokerProduct(
            this.currentLobbyType,
            features.enableNewQuickInfoLobbyType,
        );
        this.isAnchorTagVisible = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.isAnchorTagVisibleOnQuickInfo);
        this.newJackpotRibbonStyling = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.newJackpotRibbonStyling);
        this.enableUkMouseOverStyle = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.enableUkMouseOverStyle);
        this.enableHotIcon = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.enableHotIcon);
        this.isSignPosting = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, features.enableSignpostingSticker);
        const gameConfig: any = this.configProviderService.provideGameConfig();
        this.hotIcon = gameConfig.host + features.hotIconPath.hotIcon;
        this.epcotHotIcon = gameConfig.host + features.hotIconPath.epcotHotIcon;
        const jackpotData = this.configProviderService.provideJackpotConfig();
        this.showJackpotOnGameTiles = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.currentLobbyType,
            jackpotData.showJackpotOnGameTilesLobbyType,
        );
        this.enableTickingForJackpotOnGameTiles = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.currentLobbyType,
            jackpotData.enableJpTickingForLobbyType,
        );
        this.globalConfig = this.configProviderService.provideGlobalConfig();
        this.widgetRecommended = this.globalConfig.messages['WidgetRecommended'];
        this.widgetRecommendedTxtClr = this.globalConfig.messages['widgetRecommendedTxtclr'];
        this.widgetRecommendedBgClr = this.globalConfig.messages['widgetRecommendedBGclr'];
        this.promotedGameText = this.globalConfig.lobbyConfigurations['PromotedGamesText'];
        this.promotedGameBackgroundColor = this.globalConfig.lobbyConfigurations['promotedGamesFrameColor'];
        this.promotedGameTextColor = this.globalConfig.lobbyConfigurations['promotedGamesTextColor'];
        this.recommendedGamesBackgroundColor = this.globalConfig?.messages['RecommendedGamesBackground'];
        this.recommendedGamesFontColours = this.globalConfig?.messages['RecommendedGamesFontColour'];
        this.unfinishedBG = this.globalConfig.unfinishedStyle.unfinishedBackground
            ? this.globalConfig.unfinishedStyle.unfinishedBackground
            : 'transparent';
        if (
            this.globalConfig.lobbyConfigurations.FavouriteHeart &&
            this.globalConfig?.lobbyConfigurations?.FavouriteHeart?.toLowerCase() === 'true'
        ) {
            this.favouriteStyle = 'theme-heart-solid';
            this.unFavouriteStyle = 'theme-heart-outline';
        }
        const slotRaces: any = this.configProviderService.provideSlotRacesConfig();
        this.slotRacesConfig = slotRaces.enableSlotRacesStickers;
        const videoPreviewConfig = this.configProviderService.provideVideoPreviewConfig();
        this.playVideoFromStart = videoPreviewConfig.playVideoFromStart;
        this.setVideoPlayTimeoutint = videoPreviewConfig.setVideoPlayTimeoutint;
        this.enableStickerStyles = features.enableStickerStyles;
    }

    bindFreeSpinInfo() {
        this.setfreeSpinsCount(this.freespinSummaryService.getFreespinCountInfoForGame(this.game.gameId));
    }

    /*This is to set freespins count for the respective game*/
    setfreeSpinsCount(freespinsCountResponse: any) {
        this.freespinCountGame = freespinsCountResponse;
        if (this.freespinCountGame != undefined) {
            this.showFreeSpinCount = true;
            this.optedIn = this.freespinCountGame.optedIn;
            this.availableCount = this.freespinCountGame.availableCount;
            this.totalCount = this.freespinCountGame.totalCount;
        } else {
            this.showFreeSpinCount = false;
        }
    }
    ngAfterViewInit() {
        setTimeout(() => {
            this.afterLoaded = true;
        }, 200);
        if (this.isStickersForUk) {
            this.gameStickerStyleForUk();
        } else {
            this.gameStickerStyle();
        }
    }

    ngOnChanges() {
        this.currentLobbyType = this.game.lobbyType;
        this.assignFeatures();
        this.gameMinBet = this.casinoLobbyService.getMinBetBasedOnGameId(this.game?.gameId);
        if (this.enableFreeSpinSummary) {
            this.bindFreeSpinInfo();
        }
        this.setFastLoading(this.fastLoadingGamesService.getFastLoadingGamesLookUp());
    }
    assignRecommendedWidgetColors() {
        if (this.casinoWidgetService.isCasinoWidget()) {
            return 'gyml-frame-casinowidget';
        } else {
            return 'gyml-frame-casino';
        }
    }
    assignPromotedGameThumbail(game: any) {
        if (game.isPromoted) {
            return 'promoted-games-outerframe-casino';
        }
        return '';
    }
    checkforLatestStickers(recentGames: any) {
        this.setStickers();
        if (this.stickers && this.stickers.length > 0) {
            this.stickers.forEach((item: any, i: number) => {
                if (item?.indexOf('LMS_LATEST') > -1) {
                    if (recentGames && recentGames.length > 0 && recentGames.indexOf(this.game.gameId) > -1) {
                        this.stickers.splice(i, 1);
                    } else {
                        this.stickers[i] = this.stickerMap.get(this.stickers[i]);
                    }
                    this.stickers = Array.from(new Set(this.stickers));
                }
            });
        }
    }
    setStickers() {
        this.stickers = [];
        const stickerValues: string[] = [];
        this.stickerMap = this.casinoLobbyService.getStickerMap(this.currentLobbyType);
        if (this.game.sticker && this.game.sticker.indexOf('#') !== -1) {
            const stickerKeys: string[] = this.game.sticker.split('#');
            if (this.isStickersForUk && this.stickerMap && this.stickerMap.size > 0 && stickerKeys && stickerKeys.length > 0) {
                for (let i = 0; i < stickerKeys.length; i++) {
                    const stickerValue: string = stickerKeys[i] === 'LMS_LATEST' ? stickerKeys[i] : this.stickerMap.get(stickerKeys[i]);
                    if (stickerValue && stickerValue != '') {
                        stickerValues.push(stickerValue);
                    }
                }
                this.stickers = stickerValues;
            } else {
                if (this.stickerMap && this.stickerMap.size > 0 && stickerKeys && stickerKeys.length > 0) {
                    const sticker: string = stickerKeys[0] === 'LMS_LATEST' ? stickerKeys[0] : this.stickerMap.get(stickerKeys[0]);
                    if (sticker) {
                        this.stickers.push(sticker);
                    }
                }
            }
        } else {
            if (this.game.sticker && this.stickerMap && this.stickerMap.size > 0) {
                const stickerValue: string = this.game.sticker === 'LMS_LATEST' ? this.game.sticker : this.stickerMap.get(this.game.sticker);
                this.stickers = [stickerValue];
            }
        }
    }
    ngOnDestroy() {
        if (this.jackpotHandle) {
            this.jackpotHandle.unsubscribe();
        }
        if (this.liveCasinoApiSubscriber) {
            this.liveCasinoApiSubscriber.unsubscribe();
        }
        if (this.recentObs) {
            this.recentObs.unsubscribe();
        }
        if (this.freespinObservable) {
            this.freespinObservable.unsubscribe();
        }
        if (this.slotRacesStickerTimeOut) {
            clearTimeout(this.slotRacesStickerTimeOut);
        }
        if (this.slotRacesStickerDataSubScription) {
            this.slotRacesStickerDataSubScription.unsubscribe();
        }
        this.coinBalSubscription?.unsubscribe();
    }

    setGameTitle() {
        if (this.game) {
            this.gameTitle = this.game.name;
        }
    }
    mouseOver(hoveredgame: string) {
        this.mouseHoveredOnGame = true;
        if (!(this.isMobile || this.isTablet) && this.mouseHoveredOnGame && this.currentLobbyType?.toLowerCase() != this.arcadeLobbyType) {
            this.QuickInfoVisible = hoveredgame;
            if (this.isLiveGame) {
                if (this.isTableOnline) {
                    this.onLivecasinoMouseHover = true;
                    this.onLivecasinoMouseLeave = true;
                }
            } else if (this.enableUkMouseOverStyle || this.displayRecenetWinners) {
                this.onMouseHover = true;
                this.onMouseLeave = true;
            }
        }
    }
    gameStickerStyle() {
        const global: any = this.configProviderService.provideGlobalConfig();
        const stickerStyle = global.messages.GameStickerStyle;
        const sticker = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-0');
        for (let i = 0; i < sticker.length; i++) {
            sticker[i].style.cssText = stickerStyle;
        }
    }

    gameStickerStyleForUk() {
        const global: any = this.configProviderService.provideGlobalConfig();
        const stickerStyle = global.messages.UkStickerStyle1;
        const sticker = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-0');
        for (let i = 0; i < sticker.length; i++) {
            sticker[i].style.cssText = stickerStyle;
        }
        const stickerStyle1 = global.messages.UkStickerStyle2;
        const sticker1 = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-1');
        for (let i = 0; i < sticker1.length; i++) {
            sticker1[i].style.cssText = stickerStyle1;
        }
    }

    mouseLeave() {
        if (!(this.isMobile || this.isTablet)) {
            this.QuickInfoVisible = '';
            this.visiblestatus = false;
            this.mouseHoveredOnGame = false;
            if (this.enableUkMouseOverStyle) {
                this.onMouseHover = false;
                this.onMouseLeave = false;
            }
            if (this.displayRecenetWinners) {
                this.onMouseHover = false;
                this.onMouseLeave = false;
            }
            if (this.isLiveGame) {
                this.onLivecasinoMouseHover = false;
                this.onLivecasinoMouseLeave = false;
            }
        }
    }

    hideQuickInfo() {
        window.scrollTo(0, this.quickInfoScrollY);
        this.removeQuickInfoQueryParameterFromUrl();
    }

    setJackPotValue() {
        const jackpotValue = this.jackpotDataService.getGameJackpotValue(this.game.gameId);
        const enableRibbonConfig = this.configProviderService.provideJackpotConfig().enableRibbonConfig;
        if (jackpotValue) {
            this.game.jackpotValue = jackpotValue.amount;
            this.game.isHotJackpotGroup = jackpotValue.isHotJackpotGroup;
            if (enableRibbonConfig) {
                this.jpRibbonStyles = this.jackpotDataService.getJackpotRibbonStyles(jackpotValue);
            }
        }
    }

    subscribtToJackpotFeed() {
        this.jackpotHandle = this.jackpotDataService.jackpotFeedObservable.subscribe(() => {
            this.setJackPotValue();
        });
        if (this.enableTickingForJackpotOnGameTiles) {
            this.jackpotDataService.jpTickingObservable.subscribe(() => {
                this.setJackPotValue();
                this.changeDetection.detectChanges();
            });
        }
    }

    toggleFavourite(game: any, $event: any) {
        $event.stopImmediatePropagation();
        if (this.user.isAuthenticated) {
            if (
                this.gameLaunchedFrom &&
                (this.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed' || this.gameLaunchedFrom === 'RecentlyPlayed_widget_Favourites')
            ) {
                this.trackFavfromRpWidget(game);
            }
            this.favouriteService.toggleGameFavourite(game);
        } else {
            this.cookie.putObject('favouriteGame', game);
            if (this.casinoWidgetService.isCasinoWidget()) {
                this.casinoWidgetService.postMessageToParent('UserAction', { promptLogin: true, loginMessageKey: 'Favourite' });
                return;
            }
            this.openLoginDialog.open({ loginMessageKey: 'Favourite' });
        }
        return;
    }
    trackFavfromRpWidget(game: any) {
        this.currentLobbyType = this.game.lobbyType;
        if (
            this.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed' ||
            this.gameLaunchedFrom === 'rpWidgRecentlyPlayed_widget_FavouritesetFAV'
        ) {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'Favourites',
                'component.ActionEvent': this.isFavouriteGame(game) ? 'UnFavourited' : 'Favourited',
                'component.PositionEvent': game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this.currentLobbyType,
            });
        }
    }
    isFavouriteGame(game: any): boolean {
        return this.favouriteService.checkForFavouriteGame(game);
    }

    action(game: any, from: string, event: any = null) {
        if (event && event.target.classList.contains('tandcs')) {
            return;
        }
        const gameConfig: any = this.configProviderService.provideGameConfig();
        this.demoEnabled = gameConfig.enableDemoMode;
        let gameHomeUrl: string = '';
        if (this.casinoWidgetService.isCasinoWidget()) {
            gameHomeUrl = document.referrer;
        } else {
            if (this.isTouch) {
                if (this.lobbyType?.toLowerCase() == 'dice') {
                    gameHomeUrl = this.urlUtilityService.getDiceSmartUrl();
                }
            } else {
                gameHomeUrl = window.location.href;
            }
        }
        this.casinoManager.setGameHomeUrl(gameHomeUrl);
        const gameModel = new GameLaunch();
        gameModel.game = game;
        gameModel.isTouch = this.isMobile || this.isTablet;
        gameModel.gamePosition = this.gamePosition;
        gameModel.categoryType = this.category.categoryid;
        gameModel.from = from;
        gameModel.replaceRouteInHistory = false;
        gameModel.isDemoLaunch = false;
        gameModel.skipUnfinGame = false;
        gameModel.gameLaunchedFrom = this.gameLaunchedFrom;
        gameModel.gridPosition = this.category.categoryIndex;
        gameModel.specialProduct = this.product;
        gameModel.gameContainer = game.gameContainer;
        gameModel.clickTimeStamp = this.casinoCoreGameLaunchService.currentTimeStamp();
        this.casinoCoreGameLaunchService.launchGame(gameModel);
    }

    setGameIconSrc() {
        const isArcade: boolean = this.currentLobbyType?.toLowerCase() == this.arcadeLobbyType?.toLowerCase() ? true : false;
        if (this.game.isDataNotAvailable) {
            return;
        }
        if (this.game.icon && this.game.icon.src) {
            this.icon.src = this.quickInfoIcon.src = this.game.icon.src;
        } else {
            const gameConfig: any = this.configProviderService.provideGameConfig();
            const gameModel = this.category.gamesModel && this.category.gamesModel.filter((gameModel: any) => gameModel.game === this.game.gameId);
            if (gameModel && gameModel[0]) {
                const src =
                    gameConfig.host +
                    gameConfig.gameIconTemplate
                        .replace('{0}', this.gameDataManager.getGameIcon(gameModel[0], isArcade))
                        .replace('{1}', gameModel[0].game) +
                    '.' +
                    this.gameDataManager.getGameFormat(gameModel[0], isArcade);
                this.icon.src = this.quickInfoIcon.src = src;
            } else {
                const src =
                    gameConfig.host +
                    gameConfig.gameIconTemplate
                        .replace('{0}', this.gameDataManager.getGameIcon(this.game, isArcade))
                        .replace('{1}', this.game.gameId) +
                    '.' +
                    this.gameDataManager.getGameFormat(this.game.gameId, isArcade);
                this.icon.src = this.quickInfoIcon.src = src;
            }
        }
        this.icon.gameTileBorderRadius = this.game.gameTileBorderRadius;
        this.icon.gameTileDropShadow = this.game.gameTileDropShadow;
    }

    displayQuickInfo($event: any) {
        this.quickInfoScrollY = window.pageYOffset;
        this.appendQuickInfoQueryParameterToUrl();
        document.getElementsByTagName('html')[0].classList.add('casino-quickinfo-open');
        if (this.gameLaunchedFrom === 'RecentlyPlayed_widget_RecentlyPlayed') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'RecentlyPlayed_QuickInfo',
                'component.ActionEvent': 'Info_click',
                'component.PositionEvent': this.game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this.game.lobbyType,
            });
        }
        if (this.gameLaunchedFrom === 'RecentlyPlayed_widget_Favourites') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'RecentlyPlayed_Widget',
                'component.LabelEvent': 'Favourites_QuickInfo',
                'component.ActionEvent': 'Info_click',
                'component.PositionEvent': this.game.gameId + '_' + this.gamePosition,
                'component.LocationEvent': this.game.lobbyType,
            });
        }

        if (this.gameLaunchedFrom != 'RecentlyPlayed_widget_RecentlyPlayed' && this.gameLaunchedFrom != 'RecentlyPlayed_widget_Favourites') {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'games',
                'component.LabelEvent': 'game interactions',
                'component.ActionEvent': 'click',
                'component.PositionEvent': this.gamePosition,
                'component.LocationEvent': 'bacon',
                'component.EventDetails': 'more info icon',
                'component.URLClicked': 'not applicable',
            });
        }
        if ($event) {
            $event.stopImmediatePropagation();
        }
        const requiredModalData = {
            game: this.game,
            gamePosition: this.gamePosition,
            gameLaunchedFrom: this.gameLaunchedFrom,
            categoryType: this.category.categoryid,
            icon: this.icon,
            stickers: this.stickers,
            gridPosition: this.category.categoryIndex,
            gameModel: this.gameModel,
            product: this.product,
            widgetFrameClr: this.widgetFrameClr,
            gameContainerLocation: this.gameContainerLocation + 'QuickInfo',
            lobbyType: this.currentLobbyType,
            moreInfoText: 'moreInfoEvent',
            quickInfoScrollY: this.quickInfoScrollY,
        };
        if (this.isNewQuickInfoEnabled) {
            this.quickInfoModalService.setQuickInfoModalData(requiredModalData);
        } else {
            const dialogRef = this.quickInfoService.openGameQuickInfoDialog(requiredModalData);
            dialogRef.afterClosed().subscribe(() => {
                this.hideQuickInfo();
            });
        }
    }

    appendQuickInfoQueryParameterToUrl() {
        const casinoNpmParams = this.casinoNpmParamsService.getcasinoCoreNpmParams();
        if (casinoNpmParams && casinoNpmParams.isDll) {
            if (
                !(this.game.fromCasino != undefined && !this.game.fromCasino) &&
                this.game.gameContainer != 'Overlay' &&
                this.game.gameContainer != 'Widget'
            ) {
                const windowUrl: string = window.location.href;
                let pushStateUrl: string;
                let queryStrings: string = '';
                if (this.urlHasQueryStrings(windowUrl)) {
                    pushStateUrl = windowUrl.substr(0, windowUrl.indexOf('?'));
                    queryStrings = windowUrl.substr(windowUrl.indexOf('?'));
                } else {
                    pushStateUrl = windowUrl;
                }
                if (this.isUrlEndsWithSlash(pushStateUrl)) {
                    pushStateUrl = pushStateUrl + 'game-info/' + this.game.gameId;
                } else {
                    pushStateUrl = pushStateUrl + '/game-info/' + this.game.gameId;
                }
                pushStateUrl = pushStateUrl + queryStrings;
                window.history.pushState(location.href, '', pushStateUrl);
            }
        }
    }

    removeQuickInfoQueryParameterFromUrl() {
        const casinoNpmParams = this.casinoNpmParamsService.getcasinoCoreNpmParams();
        if (casinoNpmParams && casinoNpmParams.isDll) {
            if (
                !(this.game.fromCasino != undefined && !this.game.fromCasino) &&
                this.game.gameContainer != 'Overlay' &&
                this.game.gameContainer != 'Widget'
            ) {
                const param = 'game-info';
                window.history.pushState(location.href, '', location.href.substr(0, location.href.indexOf(param) - 1));
            }
        }
    }

    setLiveGameInfo() {
        if (this.game && this.game.gameId) {
            this.liveGameInfo = this.liveCasinoService.getLiveGameLookUpInfo(this.game?.gameId?.toLowerCase());
        }
        if (this.liveGameInfo) {
            this.isLiveGame = true;
        } else {
            this.isLiveGame = false;
        }
        if (this.game && this.game.gameId) {
            this.isTableOnline = this.liveCasinoService.isTableOnline(this.game?.gameId?.toLowerCase());
        }
    }

    onLiveCasinoTableTimerEnd(value: boolean) {
        if (value) {
            this.isTableOnline = value;
        }
    }

    isUrlEndsWithSlash(url: string): boolean {
        if (url.lastIndexOf('/') + 1 === url.length) {
            return true;
        }
        return false;
    }

    urlHasQueryStrings(url: string): boolean {
        if (url && url.indexOf('?') > -1) {
            return true;
        }
        return false;
    }
    setSlotRaceStickers() {
        this.slotRacesStickerDataSubScription = this.slotRacesService.stickerRacesData.subscribe((races) => {
            if (races.size > 0) {
                const racesDataObj = races.get(this.game.gameId);
                let expiryTime: number;
                if (racesDataObj) {
                    this.isStickerEnabled = true;
                    const stickerValue: string = racesDataObj.data.stickerText;
                    if (stickerValue.length > 0) {
                        this.stickers = [stickerValue];
                    }
                    expiryTime = racesDataObj.data && racesDataObj.data.optinExpiryDate && racesDataObj.data.optinExpiryDate.getTime();
                    if (!this.slotRacesStickerTimeOut && expiryTime > Date.now()) {
                        this.slotRacesStickerTimeOut = this.timerService.setTimeout(() => {
                            this.setStickers();
                            this.slotRacesStickerTimeOut = null;
                        }, expiryTime - Date.now());
                    }
                }
            }
        });
    }

    GameEnter(game: any) {
        if (
            (game.iconType === this.positiveVideoIconType || game.iconType === this.negativeVideoIconType) &&
            !(this.isMobile || this.isTablet) &&
            this.isVideoPreviewEnabled
        ) {
            game.isGameHover = true;
            const video = document.querySelector('#' + 'gameIdentifier_' + this.category.categoryid + '_' + this.game.gameId)?.querySelector('video');
            if (video) {
                video.currentTime = 0;
                video.play();
            }
            this.tracking.triggerEvent('Event.Tracking', {
                'component.CategoryEvent': 'games',
                'component.LabelEvent': 'Video Preview Game Tile',
                'component.ActionEvent': 'hover',
                'component.PositionEvent': this.gamePosition,
                'component.LocationEvent': this.category.categoryname + '-' + this.game.name,
                'component.EventDetails': 'game video preview play',
                'component.URLClicked': 'not applicable',
            });
        }
    }

    onLoadMetaData(game: any) {
        game.isVideoLoaded = true;
    }

    GameLeave(game: any) {
        if (
            (game.iconType === this.positiveVideoIconType || game.iconType === this.negativeVideoIconType) &&
            !(this.isMobile || this.isTablet) &&
            this.isVideoPreviewEnabled
        ) {
            if (game.isGameHover == true) {
                const videoLength: any = document.getElementById(game.gameId);
                game.isGameHover = false;
                game.isVideoLoaded = false;
                if (videoLength) {
                    const videoTime = videoLength.duration - videoLength.currentTime;
                    let videoPercentage = (100 * videoLength.currentTime) / videoLength.duration;
                    if (videoLength.currentTime === 0) {
                        videoPercentage = 0;
                    }
                    const actionEvent = videoLength.currentTime === 0 ? 'started' : videoTime === 0 ? 'ended' : 'inprogress';
                    this.tracking.triggerEvent('Event.Tracking', {
                        'component.CategoryEvent': 'games',
                        'component.LabelEvent': 'Video Preview Game Tile',
                        'component.ActionEvent': actionEvent,
                        'component.PositionEvent': this.gamePosition,
                        'component.LocationEvent': this.category.categoryname + '-' + this.game.name,
                        'component.EventDetails': videoPercentage.toFixed() + '%-{' + videoLength.currentTime + '} secs',
                        'component.URLClicked': 'not applicable',
                    });
                }
            }
        }
    }

    playbackFailed(game: any, id: any) {
        this.videoEnded(game, id);
    }

    videoEnded(game: any, id: string) {
        game.isGameHover = false;
        if (this.isMobile || this.isTablet) {
            const videoLength: any = document.getElementById(game.gameId);
            this.logger.info(videoLength.duration, videoLength.currentTime);
            if (videoLength) {
                const videoTime = videoLength.duration - videoLength.currentTime;
                let videoPercentage = (100 * videoLength.currentTime) / videoLength.duration;
                if (videoLength.currentTime === 0) {
                    videoPercentage = 0;
                }
                const actionEvent = videoLength.currentTime === 0 ? 'started' : videoTime === 0 ? 'ended' : 'inprogress';
                this.tracking.triggerEvent('Event.Tracking', {
                    'component.CategoryEvent': 'games',
                    'component.LabelEvent': 'Video Preview Game Tile',
                    'component.ActionEvent': actionEvent,
                    'component.PositionEvent': this.gamePosition,
                    'component.LocationEvent': this.category.categoryname + '-' + this.game.name,
                    'component.EventDetails': videoPercentage.toFixed() + '%-{' + videoLength.currentTime + '} secs',
                    'component.URLClicked': 'not applicable',
                });
            }
            if (localStorage.getItem('isVideoEnded') && localStorage.getItem('isVideoEnded') != 'first') {
                const games = JSON.parse(localStorage.getItem('isVideoEnded') || '');
                games.push(id);
                localStorage.setItem('isVideoEnded', JSON.stringify(games));
            } else {
                localStorage.setItem('isVideoEnded', JSON.stringify([id]));
            }
            localStorage.removeItem('isVideoRunning');
        }
    }

    videoDesktopEnded(game: any, id: string) {
        game.isGameHover = false;
        game.isVideoLoaded = false;
        const videoLength: any = document.getElementById(game.gameId);
        if (videoLength && id) {
            const videoTime = videoLength.duration - videoLength.currentTime;
            let videoPercentage = (100 * videoLength.currentTime) / videoLength.duration;
            if (videoLength.currentTime === 0) {
                videoPercentage = 0;
            }
            const actionEvent = videoLength.currentTime === 0 ? 'started' : videoTime === 0 ? 'ended' : 'inprogress';
            this.tracking.triggerEvent('Event.Tracking', {
                'component.CategoryEvent': 'games',
                'component.LabelEvent': 'Video Preview Game Tile',
                'component.ActionEvent': actionEvent,
                'component.PositionEvent': this.gamePosition,
                'component.LocationEvent': this.category.categoryname + '-' + this.game.name,
                'component.EventDetails': videoPercentage.toFixed() + '%-{' + videoLength.currentTime + '} secs',
                'component.URLClicked': 'not applicable',
            });
        }
    }

    isElementinViewPort(target: any, game: any): void {
        if (
            this.isVideoPreviewEnabled &&
            (this.isMobile || this.isTablet) &&
            (game.iconType == '10' || game.iconType == '-10') &&
            this.isInViewport(target.target)
        ) {
            game.isInViewport = true;
        }
        localStorage.removeItem('isVideoEnded');
        localStorage.setItem('isVideoEnded', 'first');
    }
    gameMouseDown(event: any) {
        if (document.querySelector('.click-interaction')) {
            event.target.style.transform = 'scale(0.98)';
        }
    }
    gameMouseUp(event: any) {
        if (document.querySelector('.click-interaction')) {
            event.target.style.transform = 'scale(1)';
        }
    }

    @HostListener('window:orientationchange', ['$event'])
    onOrientationChange() {
        if (this.game.isGameHover) {
            this.game.isGameHover = false;
        }
        localStorage.removeItem('isVideoRunning');
        localStorage.removeItem('isVideoEnded');
        localStorage.setItem('isVideoEnded', 'first');
    }

    isInViewport(element: any) {
        const rect = element.getBoundingClientRect();
        return (
            (rect.top >= 0 ||
                rect.left >= 0 ||
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) ||
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)) &&
            (rect.left >= 0 || rect.right >= 0) &&
            (rect.top >= 0 || rect.bottom >= 0) &&
            (rect.left <= (window.innerWidth || document.documentElement.clientWidth) ||
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)) &&
            (rect.top <= (window.innerHeight || document.documentElement.clientHeight) ||
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
        );
    }

    getStickerColor = (index: number) =>
        this.isStickersForUk
            ? index % 2 === 0
                ? this.globalConfig.messages.UkStickerStyle1
                : this.globalConfig.messages.UkStickerStyle2
            : this.globalConfig.messages.GameStickerStyle;
    getStyles(sticker: any) {
        this.stickerKey = Object.keys(this.globalConfig.messages);
        if (this.isStickersForUk) {
            if (this.stickerKey.includes(sticker)) {
                this.stickerStyles = this.globalConfig.messages[sticker];
            } else {
                this.stickerStyles = this.globalConfig.messages.UkStickerStyle1;
            }
        } else {
            this.stickerStyles = this.globalConfig.messages.GameStickerStyle;
        }
        return this.stickerStyles;
    }
}
