<div
    class="casino-mobile-prominent-freespins"
    [ngClass]="{
        'mobile-single-game-line': gamesList?.length <= 3 && isTouch,
        'mobile-double-game-line': ((gamesList?.length >= 4 && gamesList?.length <= 6) || gamesList?.length > 6) && isTouch,
        'desktop-single-game-line': gamesList?.length <= 5 && !isTouch,
        'desktop-double-game-line': ((gamesList?.length >= 6 && gamesList?.length <= 10) || gamesList?.length > 10) && !isTouch
    }">
    <div class="drawer-title">
        <div class="drawer">
            <div class="bouns" [ngStyle]="{ color: freeSpinsConfig?.ModelTitle }">{{ mainTitle }}</div>
            <div class="bouns-expair" [ngStyle]="{ color: freeSpinsConfig?.modelSpinsExpireColor }">
                {{ offerExpiringIn }}
                <cc-prominent-freespins-widget-timmer [timmer]="offerExpiryDate" (isTimmerZero)="getZeroTimmer($event)">
                </cc-prominent-freespins-widget-timmer>
            </div>
        </div>
        <div class="header-btn btn btn-primary" (click)="onCloseModal($event)">{{ freeSpinsConfig?.closeCTA }}</div>
    </div>

    <div class="prograss-bar-container">
        <div class="prograss-bar-left">
            <img src="{{ spinIconImage }}" alt="" />
        </div>
        <div class="prograss-bar-right">
            <div class="prograss-bar-top">
                <div class="winning-title" [ngStyle]="{ color: freeSpinsConfig?.currentWinningTitle }">{{ freeSpinsConfig?.currentWinnings }}</div>
                <div class="winning-currency">
                    <span [ngStyle]="{ color: freeSpinsConfig?.currentWinningTitle }">{{
                        winAmountSoFar == null ? (0 | vnCurrency: currencyAmount) : (winAmountSoFar / 100 | vnCurrency: currencyAmount)
                    }}</span>
                </div>
            </div>
            <div class="prograssbar"><mat-progress-bar mode="determinate" [value]="percentagevalue"></mat-progress-bar></div>
            <div class="prograss-bar-bottom">
                <div class="t-spins" [ngStyle]="{ color: freeSpinsConfig?.currentWinningTitle }">
                    {{ complitedSpins }}/{{ totalSPinCount }} {{ replaceSpinsText(totalSPinCount) }}
                </div>
                <div class="t-spins-left" [ngStyle]="{ color: freeSpinsConfig?.currentWinningTitle }">
                    {{ initialCount }} {{ replaceSpinsCountText(initialCount) }}
                </div>
            </div>
        </div>
    </div>

    <div class="games-sec">
        <div class="game-title" [ngStyle]="{ color: freeSpinsConfig?.eligibleGamesTitle }">{{ replaceGamesText(gamesList?.length) }}</div>
        <div class="games-section">
            <div class="games-list">
                <img src="{{ data.imageUrl }}" alt="" class="gameTile" *ngFor="let data of gamesList; let i = index" (click)="launchGame(data, i)" />
            </div>
        </div>
        <hr />
        <div class="view-more" [ngStyle]="{ color: freeSpinsConfig?.moreInfoTextColor }" (click)="moreInfoClick($event, spins)">
            {{ freeSpinsConfig?.viewDetailsText }}
        </div>
    </div>
</div>
