<div class="casino-skeleton-loading-container" [style.background-color]="loadingConfig.backgroundColor || ''">
    <div *ngIf="loadingConfig.spinnerType === 'brand'" class="brand-logo"></div>
    <div *ngIf="loadingConfig.spinnerType === 'standard'" class="standard-loading-indicator"></div>
    <div *ngIf="loadingConfig.spinnerType === 'lottie' && loadingConfig?.lottieUrl" class="lottie-loading-indicator">
        <cc-lottie-loading [path]="loadingConfig.lottieUrl"></cc-lottie-loading>
    </div>
    <img
        *ngIf="loadingConfig.spinnerType === 'ferris' && loadingConfig.ferrisSpinningWheelUrl"
        class="ferris-loading-indicator"
        [src]="loadingConfig.ferrisSpinningWheelUrl"
        alt="Skeleton" />
    <div
        *ngIf="loadingConfig.loadingText"
        class="loading-text"
        [style.color]="loadingConfig.loadingTextColor || ''"
        [textContent]="loadingConfig.loadingText"></div>
</div>
