import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

import { IContentImage } from '../shared/models/common.model';

@LazyClientConfig({ key: 'csFreeSpins', product: ClientConfigProductName.CASINO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: freeSpinsClientConfigFactory,
})
export class FreeSpinsClientConfig {
    freeSpinsConfigurations: any;
    freeSpinsMoreInfo: any;
    freespinHost: string;
    freeSpinSummaryPathSegment: string;
    rewardDetailsPathSegment: string;
    isFreeSpinSummaryEnabled: boolean;
    isEnableFreeSpinsOnQuickInfo: boolean;
    freespinsVanillaActiveHeader: string;
    prominentFreeSpinsConfigurations: any;
    isEnableProminentFreeSpinsDisplay: boolean;
    prominentFreeSpinsWidgetBackgroundImage: IContentImage;
    currentWinningsImage: IContentImage;
    ctaSpinBackgroundImage: IContentImage;
}

export function freeSpinsClientConfigFactory(service: LazyClientConfigService) {
    return service.get(FreeSpinsClientConfig);
}
