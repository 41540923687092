import { NgModuleFactory, Type } from '@angular/core';

export interface LazyModules {
    [key: string]: { loadChildren: () => Promise<NgModuleFactory<any> | Type<any>> };
}

export const lazyMap: LazyModules = {
    rpfavwidget: {
        loadChildren: () => import('./iframe-rp-fav-widget/iframe-rp-fav-widget.module').then((m) => m.IFrameRpFavWidgetModule),
    },
    rpgyml: {
        loadChildren: () => import('./iframe-rp-gyml/iframe-rp-gyml.module').then((m) => m.IFrameRpGymlModule),
    },
    subcategoryfooter: {
        loadChildren: () => import('./iframe-subcategory-footer/iframe-subcategory-footer.module').then((m) => m.IframeSubCategoryAsFooterModule),
    },
    explorepage: {
        loadChildren: () => import('./explore-page/explore-page.module').then((m) => m.ExplorePageModule),
    },
    nolazypathkey: {
        loadChildren: () => import('./lazy-route-error/lazy-route-error.module').then((m) => m.LazyRouteErrorModule),
    },
    SeoBoxSectionComponent: {
        loadChildren: () => import('./mod-casino/seo-box-section/seo-box-section.module').then((m) => m.SeoBoxSectionModule),
    },
    RPFavSectionComponent: {
        loadChildren: () => import('./mod-casino/rp-fav-section/rp-fav-section.module').then((m) => m.RPFavSectionModule),
    },
    RecentlyplayedGymlSectionComponent: {
        loadChildren: () =>
            import('./mod-casino/recentlyplayed-gyml-section/recentlyplayed-gyml-section.module').then((m) => m.RecentlyPlayedGYMLSectionModule),
    },
    ThumbnailHomeComponent: {
        loadChildren: () => import('./thumbnail-home/thumbnail-home.module').then((m) => m.ThumbnailHomeModule),
    },
    NewsFeedSectionV2Component: {
        loadChildren: () => import('./mod-casino/news-feed-section-v2/news-feed-section-v2.module').then((m) => m.NewsFeedSectionV2Module),
    },
    CozyBingoWidgetComponent: {
        loadChildren: () => import('@casinocore/platform/cozy-bingo-widget').then((m) => m.CozyBingoWidgetModule),
    },
    PlayBingoBtnComponent: {
        loadChildren: () => import('@casinocore/platform/play-bingo-btn').then((m) => m.PlayBingoBtnModule),
    },
    NewsFeedSectionComponent: {
        loadChildren: () => import('@casinocore/platform/news-feed-section').then((m) => m.NewsFeedSectionModule),
    },
    StaticDiscoverFeedWrapperComponent: {
        loadChildren: () => import('@casinocore/platform/discover-feed').then((m) => m.StaticDiscoverFeedWrapperModule),
    },
    GlobalJackpotSeoboxesComponent: {
        loadChildren: () => import('@casinocore/platform/global-jackpot-seoboxes').then((m) => m.GlobalJackpotSeoboxesModule),
    },
    GlobalJackpotTandCComponent: {
        loadChildren: () => import('@casinocore/platform/global-jackpot-tnc').then((m) => m.GlobalJackpotTandCModule),
    },
    GlobalJackpotWinnersFeedComponent: {
        loadChildren: () => import('@casinocore/platform/global-jackpot-winnersfeed').then((m) => m.GlobalJackpotWinnersFeedModule),
    },
    LiveGamesSectionComponent: {
        loadChildren: () => import('@casinocore/platform/live-games-section').then((m) => m.LiveGamesSectionModule),
    },
    GlobalJackpotContainerComponent: {
        loadChildren: () =>
            import('./mod-casino/global-jackpot-container/global-jackpot-container.module').then((m) => m.GlobalJackpotContainerModule),
    },
    TeaserSectionComponent: {
        loadChildren: () => import('./mod-casino/teaser-section/teaser-section.component').then((m) => m.TeaserSectionComponent),
    },
    CasinoGameSelectorSectionComponent: {
        loadChildren: () =>
            import('./mod-casino/casino-game-selector-section/casino-game-selector-section.module').then((m) => m.CasinoGameSelectorSectionModule),
    },
    GameShowHubSectionComponent: {
        loadChildren: () => import('./mod-casino/game-show-hub-section/game-show-hub-section.module').then((m) => m.GameShowHubSectionModule),
    },
    NewGameQuickInfoComponent: {
        loadChildren: () => import('@casinocore/platform/new-game-quick-info').then((m) => m.NewGameQuickInfoModule),
    },
    ChatIntegrationComponent: {
        loadChildren: () => import('./mod-casino/chat-integration/chat-integration.module').then((m) => m.ChatIntegrationModule),
    },
    LiveCasinoLoungeSectionComponent: {
        loadChildren: () =>
            import('./mod-casino/live-casino-lounge-section/live-casino-lounge-section.module').then((m) => m.LiveCasinoLoungeSectionModule),
    },
    ImmersiveLobbySectionComponent: {
        loadChildren: () => import('./mod-casino/immersive-lobby-section/immersive-lobby-section.module').then((m) => m.ImmersiveLobbySectionModule),
    },
    GamingStoriesSectionComponent: {
        loadChildren: () => import('./mod-casino/gaming-stories-section/gaming-stories-section.module').then((m) => m.GamingStoriesSectionModule),
    },
    SlotRacesComponent: {
        loadChildren: () => import('@casinocore/platform/slot-races').then((m) => m.SlotRacesModule),
    },
    SlotRacesPhase2Component: {
        loadChildren: () => import('@casinocore/platform/slot-races-phase2').then((m) => m.SlotRacesPhase2Module),
    },
    BingoTournamentsComponent: {
        loadChildren: () => import('@casinocore/platform/bingo-tournaments').then((m) => m.BingoTournamentsModule),
    },
    SuperButtonSectionComponent: {
        loadChildren: () => import('./mod-casino/super-button-section/super-button-section.module').then((m) => m.SuperButtonSectionModule),
    },
    PromotionsBannerSectionComponent: {
        loadChildren: () =>
            import('./mod-casino/promotions-banner-section/promotions-banner-section.module').then((m) => m.PromotionsBannerSectionModule),
    },
    ArcadeTandCComponent: {
        loadChildren: () => import('./mod-casino/arcade-tnc/arcadeTandC.module').then((m) => m.ArcadeTandCModule),
    },
    ArcadeRPSectionComponent: {
        loadChildren: () =>
            import('./mod-casino/arcade-recently-played-section/arcade-recently-played-section.module').then((m) => m.ArcadeRPSectionModule),
    },
};
