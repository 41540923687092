import { Injectable } from '@angular/core';

import { TrackingService } from '@frontend/vanilla/core';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';
import { ApiPathsHelperService } from './api-paths-helper-service';
import { CacheManagerService } from './cache-manager.service';
import { CasinoIFrameOverlayService } from './casino-iframe-overlay.service';
import { CasinoManager } from './casino-manager.service';
import { CozyBingoDataService } from './cozybingodata.service';
import { InvokerProductService } from './invoker-product.service';

@Injectable({
    providedIn: 'root',
})
export class RecentGamesService {
    private recentGames: string[] = [];
    private recentGamesFromAllLobby: string[] = [];
    private mostRecentPlayedGames: any[] = [];
    private recentGamesSubject = new BehaviorSubject<any>(null);
    recentGamesFeedObservable: Observable<any> = this.recentGamesSubject.asObservable();
    private recentGamesFromAllLobbySubject = new BehaviorSubject<any>(null);
    recentGamesFeedFromAllLobbyObservable: Observable<any> = this.recentGamesFromAllLobbySubject.asObservable();
    private isRpfavWidgetDisplayed = new BehaviorSubject<any>(null);
    isRpfavWidgetDisplayedObservable: Observable<any> = this.isRpfavWidgetDisplayed.asObservable();
    private collectiveRecentGames: any = {};

    constructor(
        private api: PlatformApiService,
        private tracking: TrackingService,
        private invokerProductService: InvokerProductService,
        private configProviderService: ConfigProviderService,
        private casinoManager: CasinoManager,
        private cacheManager: CacheManagerService,
        private apiPathsHelperService: ApiPathsHelperService,
        private casinoIFrameOverlayService: CasinoIFrameOverlayService,
        private cozyBingoDataService: CozyBingoDataService,
    ) {}

    setRecentGames() {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        const isOverlay: boolean = this.casinoIFrameOverlayService.getOverlayStatus();
        const endPoint = this.apiPathsHelperService.transformApi(
            apiPathsConfig.nonCriticalFeeds['rpGamesOfProduct'],
            this.invokerProductService.getInvokerProduct(),
        );

        this.api.get(endPoint, { isOverlay: isOverlay }, { withCredentials: true }).subscribe(
            (response: any) => {
                this.collectiveRecentGames = response;
                this.recentGamesFromAllLobby = response?.casino?.map((game: any) => game.gamevariant) || [];
                if (response.casino) {
                    if (
                        featuresConfig?.rpGamesFilterInLiveCasino &&
                        response.casino.length > 0 &&
                        this.casinoManager?.getCurrentLobby()?.toLowerCase() == 'livecasino'
                    ) {
                        this.collectiveRecentGames = cloneDeep(response);
                        // this.collectiveRecentGames = {...response};
                        response.casino = this.filterLiveCasinoGames(response.casino);
                    }
                    let arcadeLobby = this.configProviderService.provideGameConfig()?.arcadeLobbyType.toLowerCase();
                    if (response.casino.length > 0 && this.casinoManager?.getCurrentLobby()?.toLowerCase() == arcadeLobby) {
                        this.collectiveRecentGames = cloneDeep(response);
                        // this.collectiveRecentGames = {...response};
                        response.casino = this.filterArcadeLobbyGames(response.casino);
                    }

                    this.mostRecentPlayedGames = response.casino;
                    this.recentGames = response.casino.map((game: any) => game.gamevariant);
                }
                if (featuresConfig?.rpWidgetGamesFilterInLiveCasino) this.recentGamesFromAllLobby = this.recentGames;
                this.recentGamesSubject.next(this.recentGames);
                this.recentGamesFromAllLobbySubject.next(this.recentGamesFromAllLobby);
                // Send recently played games to GTM
                this.collectiveRecentGames && this.raiseEventForRecentlyPlayed(this.collectiveRecentGames);
            },
            () => {
                this.recentGamesSubject.next(this.recentGames);
                this.recentGamesFromAllLobbySubject.next(this.recentGamesFromAllLobby);
            },
        );
    }

    filterLiveCasinoGames(casinoGames: any) {
        const categoryCoreLookUp: any = this.cacheManager.getAllCategoriesFromLocalCache('LiveCasino', this.casinoManager.getInvokerProduct());
        const gamesLookUp: any = categoryCoreLookUp?.lsGameMetaDataMap?.map((x: any) => x && x[0]);
        return casinoGames?.filter((x: any) => gamesLookUp?.includes(x.gamevariant)) || [];
    }

    filterArcadeLobbyGames(casinoGames: any) {
        const categoryCoreLookUp: any = this.cacheManager.getAllCategoriesFromLocalCache('coinArcade', this.casinoManager.getInvokerProduct());
        const gamesLookUp: any = categoryCoreLookUp?.lsGameMetaDataMap?.map((x: any) => x && x[0]);
        return casinoGames?.filter((x: any) => gamesLookUp?.includes(x.gamevariant)) || [];
    }

    getrecentGames() {
        if (this.recentGames && this.recentGames.length > 0) {
            return this.recentGames;
        }
        return [];
    }

    getrecentGamesFromAllLobby() {
        return this.recentGamesFromAllLobby?.length > 0 ? this.recentGamesFromAllLobby : [];
    }

    private raiseEventForRecentlyPlayed(recentGamesObject: any): void {
        let recentPlayedGames = {};
        const globalsearchconfig: any = this.configProviderService.provideGlobalSearchConfig();
        if (globalsearchconfig.isEnabled) {
            this.cozyBingoDataService.cozyBingoRecentGamesObservable.subscribe((response: any) => {
                if (response) {
                    const cozybingodata = response;
                    recentPlayedGames = Object.assign(recentGamesObject, cozybingodata);
                } else {
                    recentPlayedGames = recentGamesObject;
                }
            });
            dispatchEvent(new CustomEvent('casino:Recent_Games_Updated', { detail: recentPlayedGames }));
        }
        this.tracking.triggerEvent('Event.RecentlyPlayed', { 'user.recentGames': recentPlayedGames });
    }

    getMostRecentlyPlayedGames() {
        if (this.mostRecentPlayedGames && this.mostRecentPlayedGames.length > 0) {
            return this.mostRecentPlayedGames;
        }
        return [];
    }

    getMostRecentlyPlayedRouteInfo(subCategoryId: string) {
        const recentlyPlayedClientConfig = this.configProviderService.provideRecentlyPlayedGamesConfig();
        if (recentlyPlayedClientConfig.isMostRecentlyPlayedEnabled) {
            const mostRecentlyPlayedRoutes: any = recentlyPlayedClientConfig.recentlyPlayedRoutes;
            for (let i = 0; i < mostRecentlyPlayedRoutes.length; i++) {
                if (mostRecentlyPlayedRoutes[i]?.mostPlayedRoute?.toLowerCase() == subCategoryId?.toLowerCase()) {
                    return mostRecentlyPlayedRoutes[i];
                }
            }
        }
    }

    isRpfavDisplayed(value: boolean) {
        this.isRpfavWidgetDisplayed.next(value);
    }

    getRecentGames(): any {
        return this.collectiveRecentGames;
    }
}
