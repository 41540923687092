import { NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CasinoLobbyService, CasinoManager, ConfigProviderService } from '@casinocore/platform/core';
import { TrackingService } from '@frontend/vanilla/core';

import { SignPostingComponent } from '../sign-posting/sign-posting.component';
import { CategoryiconSubtitleMoreinfoComponent } from './categoryicon-subtitle-moreinfo/categoryicon-subtitle-moreinfo.component';

@Component({
    selector: 'cc-categoryicon-subtitle',
    templateUrl: 'categoryicon-subtitle.component.html',
    standalone: true,
    imports: [NgIf, NgClass, NgStyle, SignPostingComponent],
})
export class CategoryiconSubtitleComponent implements OnInit, OnChanges {
    @Input() lobbyType: any;
    @Input() subCategory: any;
    @Input() recentAndGyml: any;
    @Input() alignIconRight: boolean;
    @Input() displayHeading: boolean = true;
    @Input() title: string;
    @Input() seeAllGamesCount: number;

    subCategoryTitleFontSize: string;
    subCategorySubTitleFontSize: string;
    displayTitle: string;
    isSignPosting: boolean;
    features: any;
    subCategoryData: any;
    subCategoryTextColor: string;
    lobbyTileFontFamily: string;
    jackpotMoreInfoEnable: boolean;
    jackpotDataConfig: any;
    constructor(
        private configProviderService: ConfigProviderService,
        private casinoManager: CasinoManager,
        private casinoLobbyService: CasinoLobbyService,
        private categoryiconSubtitleMoreinfo: MatDialog,
        private tracking: TrackingService,
    ) {}

    ngOnInit() {
        this.assignSubCategory();
    }

    assignSubCategory() {
        if (this.subCategory && this.subCategory.sitecoreData) {
            this.subCategoryData = this.subCategory;
        } else if (this.subCategory && this.subCategory.categoryInfo && this.subCategory.categoryInfo.sitecoreData) {
            this.subCategoryData = this.subCategory.categoryInfo;
        }
        if (this.title && this.subCategoryData && this.subCategoryData.categoryname == undefined) {
            this.subCategoryData.categoryname = this.title;
        }
        this.features = this.configProviderService.provideFeaturesConfig();
        this.jackpotDataConfig = this.configProviderService.provideJackpotConfig();
        this.subCategoryTitleFontSize = this.casinoLobbyService.getsubCategoryTitleFontSize(this.subCategory);
        this.subCategorySubTitleFontSize = this.casinoLobbyService.getsubCategorySubTitleFontSize(this.subCategory);
        this.isSignPosting = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, this.features.enableSignpostingSticker);
        this.subCategoryTextColor = this.casinoLobbyService.getsubCategoryTextColor(this.subCategory);
        this.lobbyTileFontFamily = this.casinoLobbyService.getLTileFontFamily(this.subCategory);
        this.jackpotMoreInfoEnable = this.jackpotDataConfig.jackpotMoreInfoEnable;
    }
    openDialog($event: any): void {
        this.jackpotMoreInfoTracking();
        let requiredModalData: any;
        if ($event) {
            $event.stopImmediatePropagation();
        }
        if (this.subCategoryData?.sitecoreData?.jackpotInfo) {
            requiredModalData = {
                subcategoryinfo: this.subCategoryData?.sitecoreData?.jackpotInfo,
                subcategorytittle: this.subCategoryData?.categoryname,
                seeAllCtaText: this.subCategoryData?.sitecoreData?.seeAllGames,
                jackpotBadgeStatus: this.subCategoryData?.sitecoreData?.jackpotBadgeStatus,
                showSeeAllGamesCta: this.subCategoryData?.sitecoreData?.showSeeAllGamesCta,
                seeallctaurl: this.subCategoryData?.sitecoreData?.seeallctaurl,
                jackpotMoreInfoCloseCtaText: this.subCategoryData?.sitecoreData?.jackpotMoreInfoCloseCtaText,
            };
        }
        this.categoryiconSubtitleMoreinfo.open(CategoryiconSubtitleMoreinfoComponent, {
            data: requiredModalData,
            panelClass: 'custom-dialog-container',
        });
    }

    ngOnChanges() {
        setTimeout(() => {
            this.assignSubCategory();
        }, 600);
    }

    jackpotMoreInfoTracking() {
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'slots',
            'component.LabelEvent': 'jackpot slots',
            'component.ActionEvent': 'click',
            'component.PositionEvent': this.subCategoryData.categoryname,
            'component.LocationEvent': this.subCategoryData.categoryname,
            'component.EventDetails': 'more info',
        });
    }
}
