import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { PlatformApiService } from '../platform-api/platform-api.service';
import { PlatformBingoApiService } from '../platform-api/platform-bingo-api.service';
import { CasinoWidgetService } from './casino-widget.service';

@Injectable({
    providedIn: 'root',
})
export class CasinoApiService {
    constructor(
        private casinoWidgetService: CasinoWidgetService,
        private apiService: PlatformApiService,
        private apiBingoService: PlatformBingoApiService,
        private configProviderService: ConfigProviderService,
    ) {}
    // NFR - Services optimization - Remove
    request(url: string, options?: any): Observable<any> {
        return this.apiService.request(url, options);
    }

    get(url: string, params?: any, options?: any): Observable<any> {
        const requestParams = this.casinoWidgetService.getApiParams(params);
        return this.apiService.get(url, requestParams, options);
    }

    post(url: string, data?: any, options?: any): Observable<any> {
        return this.apiService.post(url, data, options);
    }

    getBingoApi(url: string, params?: any, options?: any): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        const requestParams = this.casinoWidgetService.getApiParams(params);
        // remove newApiStrucute check when new bingo api structure is ready and rolled out.
        if (apiPathsConfig.bingo['newApiStructure'] && apiPathsConfig.bingo['newApiStructure'] == 'enabled')
            return this.apiBingoService.get(url, requestParams, options);
        else return this.apiService.get(url, requestParams, options);
    }

    postBingoApi(url: string, data?: any, options?: any): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        // remove newApiStrucute check when new bingo api structure is ready and rolled out.
        if (apiPathsConfig.bingo['newApiStructure'] && apiPathsConfig.bingo['newApiStructure'] == 'enabled')
            return this.apiBingoService.post(url, data, options);
        else return this.apiService.post(url, data, options);
    }

    // NFR - Services optimization - Remove
    put(url: string, data?: any, options?: any): Observable<any> {
        return this.apiService.put(url, data, options);
    }
    // NFR - Services optimization - Remove
    delete(url: string, params?: any, options?: any): Observable<any> {
        return this.apiService.delete(url, params, options);
    }
    // NFR - Services optimization - Remove
    jsonp(url: string, callbackParam: string): Observable<Object> {
        return this.apiService.jsonp(url, callbackParam);
    }
}
