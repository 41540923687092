import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ConfigProviderService } from '@casinocore/platform/core';

import { CasinoCoreLazyPlaceholderComponent } from '../casino-core-lazy-placeholder/casino-core-lazy-placeholder.component';

@Component({
    selector: 'cc-resizable-teasers',
    templateUrl: 'resizable-teasers.component.html',
    standalone: true,
    imports: [CasinoCoreLazyPlaceholderComponent],
})
export class ResizableTeasersComponent implements OnInit {
    isLandscape: boolean;
    headerTeasers: any;
    TopTeaser: any;
    constructor(
        private route: ActivatedRoute,
        private configProviderService: ConfigProviderService,
    ) {}
    ngOnInit() {
        this.TopTeaser = 'Top';
        if (this.route.snapshot.paramMap.get('isLand')) {
            this.isLandscape = Boolean(this.route.snapshot.paramMap.get('isLand'));
        } else {
            this.isLandscape = false;
        }
        const headerTeaserClientConfig = this.configProviderService.provideHeaderTeasersConfig();

        this.headerTeasers = headerTeaserClientConfig;
    }
}
