import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import {
    CasinoCoreGamelaunchService,
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    GameLaunch,
    GamePremiereService,
    IContentLink,
    IGamePremiere,
    UserRequestModel,
} from '@casinocore/platform/core';
import { AppInfoConfig, DeviceService, Logger, NavigationService, TrackingService, UserService } from '@frontend/vanilla/core';
import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'cc-game-premiere',
    templateUrl: 'game-premiere.component.html',
    standalone: true,
    imports: [NgIf, NgFor, NgStyle, NgClass],
})
export class GamePremiereComponent implements OnInit, OnDestroy {
    @Input() category: any;
    @Input() isTouch: boolean;
    @Input() place: string = '';
    @Input() lobbyType: string;
    @Input() containerPath: string;
    @ViewChild('videoPlayer') videoPlayer: any;
    showPosterImage: boolean = true;
    data: any;
    displayOverlay: boolean = false;
    config: any;
    overlayConfig: any;
    headerText: string;
    closeCTA: IContentLink;
    sticker: string;
    promoVideo: any;
    enableVideo: boolean;
    subTitle1: string;
    subTitle2: string;
    mainTitle: string;
    mobileSubTitle1: string;
    mobileSubTitle2: string;
    stepsTitle: string;
    step1: string;
    step2: string;
    step3: string;
    moreInfoText: string;
    lessInfoText: string;
    viewDetails: IContentLink;
    moreInfoContent: string;
    styles: any;
    enableGamePremiere: boolean;
    gamePremiereObs: Subscription;
    gamePremiere: IGamePremiere;
    gamePremiereOverlay: any;
    additionalInfo: string;
    optinCTA: any;
    playNowCTA: any;
    ctaText: any;
    optinCta: string;
    playnowCta: string;
    playNowText: any;
    overlayAnimationImage: any;
    moreInfoDefaultExpandedView: boolean = false;
    isMobilePhone: boolean;
    isTablet: boolean;
    showContent: boolean = false;
    showToasterMessage: boolean = false;
    viewDetailsLink: any;
    optInDisabled: boolean;
    sourceType: string;
    sourceId: any;
    optInClickedStatus: any;
    private destroy$ = new Subject<void>();
    optInText: string;
    optedInText: string;
    enableStatic: boolean = false;
    desktopLinearGradient: string;
    desktopGameImage: string;
    mobileGameImage: string;
    gamePlayUrl: string;
    gameId: string;
    link: any;
    gameName: string;
    onLoadTrackingEvent: boolean = false;
    animationDuration: number;
    @ViewChild('game_premiere_more_info') game_premiere_more_info: ElementRef;
    constructor(
        private configProviderService: ConfigProviderService,
        private user: UserService,
        private deviceService: DeviceService,
        private gamePremiereService: GamePremiereService,
        private dialog: MatDialogRef<GamePremiereComponent>,
        private tracking: TrackingService,
        private casinoManager: CasinoManager,
        private ref: ChangeDetectorRef,
        private casinoLobbyService: CasinoLobbyService,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private Navigator: NavigationService,
        private appInfoConfig: AppInfoConfig,
        private logger: Logger,
    ) {}

    ngOnInit() {
        this.assignFeatures();
        document.getElementsByTagName('html')[0].classList.add('casino-gamepremiere-open');
        setTimeout(() => {
            this.showContent = true;
        }, this.animationDuration);
    }

    assignFeatures() {
        this.isTouch = this.casinoManager.isTouch();
        this.isMobilePhone = this.deviceService.isMobilePhone;
        // this.isTablet = this.deviceService.isTablet;
        const gamePremiereConfig = this.configProviderService.provideGamePremiereConfig();
        this.enableGamePremiere = gamePremiereConfig.enableGamePremiere;
        this.animationDuration = gamePremiereConfig.overlayAnimationDuration;
        if (this.enableGamePremiere) {
            this.headerText = gamePremiereConfig.gamePremiereOverlayConfiguration.headerText;
            this.closeCTA = gamePremiereConfig.gamePremiereOverlayConfiguration.closeCTA;
            this.sticker = gamePremiereConfig.gamePremiereOverlayConfiguration.sticker;
            this.overlayAnimationImage = gamePremiereConfig.gamePremiereOverlayConfiguration.overlayAnimationImage;
            this.mobileGameImage = gamePremiereConfig.gamePremiereOverlayConfiguration.mobileGameImage?.src
                ? gamePremiereConfig.gamePremiereOverlayConfiguration.mobileGameImage?.src
                : undefined;
            this.desktopGameImage = gamePremiereConfig.gamePremiereOverlayConfiguration.desktopGameImage?.src
                ? gamePremiereConfig.gamePremiereOverlayConfiguration.desktopGameImage?.src
                : undefined;

            this.promoVideo = gamePremiereConfig.gamePremiereOverlayConfiguration.promoVideo;
            this.enableVideo = gamePremiereConfig.gamePremiereOverlayConfiguration.enableVideo;
            this.mainTitle = gamePremiereConfig.gamePremiereOverlayConfiguration.mainTitle;
            this.subTitle1 = gamePremiereConfig.gamePremiereOverlayConfiguration.subTitle1;
            this.subTitle2 = gamePremiereConfig.gamePremiereOverlayConfiguration.subTitle2;
            this.mobileSubTitle1 = gamePremiereConfig.gamePremiereOverlayConfiguration.mobileSubTitle1;
            this.mobileSubTitle2 = gamePremiereConfig.gamePremiereOverlayConfiguration.mobileSubTitle2;
            this.stepsTitle = gamePremiereConfig.gamePremiereOverlayConfiguration?.stepsTitle;
            this.step1 = gamePremiereConfig.gamePremiereOverlayConfiguration?.step1;
            this.step2 = gamePremiereConfig.gamePremiereOverlayConfiguration?.step2;
            this.step3 = gamePremiereConfig.gamePremiereOverlayConfiguration?.step3;
            this.sourceType = gamePremiereConfig.gamePremiereOverlayConfiguration.sourceType;
            if (this.sourceType == 'Static') {
                this.enableStatic = true;
            }
            this.sourceId = gamePremiereConfig.gamePremiereOverlayConfiguration?.sourceId;
            this.additionalInfo = gamePremiereConfig.gamePremiereOverlayConfiguration?.additionalInfo;
            if (gamePremiereConfig.gamePremiereOverlayConfiguration.playNowCTA) {
                this.playNowCTA = gamePremiereConfig.gamePremiereOverlayConfiguration?.playNowCTA?.url;
                this.playNowText = gamePremiereConfig.gamePremiereOverlayConfiguration?.playNowCTA.text;
            }
            this.moreInfoText = gamePremiereConfig.gamePremiereOverlayConfiguration?.moreInfoText;
            this.lessInfoText = gamePremiereConfig.gamePremiereOverlayConfiguration?.lessInfoText;
            this.moreInfoContent = gamePremiereConfig.gamePremiereOverlayConfiguration.moreInfoContent;
            this.viewDetailsLink = gamePremiereConfig.gamePremiereOverlayConfiguration?.viewDetails?.url;
            this.viewDetails = gamePremiereConfig.gamePremiereOverlayConfiguration?.viewDetails?.text;
            const styles = gamePremiereConfig.gamePremiereOverlayConfiguration.styles;
            this.optInText = styles.OptInText;
            this.optedInText = styles.OptedInText;
            this.optInDisabled = false;
            const mcPageConfig = this.configProviderService.provideMcPageConfig();
            this.gamePlayUrl = '/' + mcPageConfig.labelPrefix + '/launchng/';
            this.gameId = this.getGameIdFromLaunchUrl(this.playNowCTA);
            this.gameName = this.casinoLobbyService.getGameDisplayName(this.gameId);
            this.onLoadOverlayTracking();
        }
    }

    get desktopBackgroundImage() {
        let linearGradient: string = 'linear-gradient(90deg, #06382D 42.77%, rgba(5, 55, 44, 0) 90.4%)';
        return `${linearGradient}, url('${this.desktopGameImage}') no-repeat right center /  60% 100%`;
    }

    onLoadOverlayTracking() {
        if (!this.onLoadTrackingEvent) {
            this.tracking.triggerEvent('contentView', {
                'component.CategoryEvent': 'promotions',
                'component.LabelEvent': 'game premiere',
                'component.ActionEvent': 'load',
                'component.PositionEvent': this.gameName,
                'component.LocationEvent': this.appInfoConfig.product,
                'component.EventDetails': this.gameName,
                'component.URLClicked': 'not applicable',
            });
            this.onLoadTrackingEvent = true;
        }
    }

    closeOverlay(isCloseCta: boolean) {
        let postRequestModel: UserRequestModel = new UserRequestModel();
        if (!this.enableStatic) {
            (postRequestModel.type = this.sourceType),
                (postRequestModel.id = this.sourceId),
                (postRequestModel.optIn = true),
                (postRequestModel.source = 'INBOX'),
                (postRequestModel.actionType = 'CLOSE'),
                this.gamePremiereService.getGamepremiereOptIn(postRequestModel);
        } else {
            (postRequestModel.type = this.sourceType),
                (postRequestModel.id = this.sourceId),
                (postRequestModel.optIn = false),
                (postRequestModel.source = 'INBOX'),
                (postRequestModel.actionType = 'CLOSE'),
                this.gamePremiereService.getGamepremiereOptIn(postRequestModel);
        }
        this.gamePremiereService.optInObservable.subscribe((response: any) => {
            if (response?.showOverlay === false) {
                this.dialog.close();
                document.getElementsByTagName('html')[0].classList.remove('casino-gamepremiere-open');
            }
        });
        if (isCloseCta) {
            this.tracking.triggerEvent('Event.Tracking', {
                'component.CategoryEvent': 'promotions',
                'component.LabelEvent': 'game premiere',
                'component.ActionEvent': 'close',
                'component.PositionEvent': this.gameName,
                'component.LocationEvent': this.appInfoConfig.product,
                'component.EventDetails': this.gameName,
                'component.URLClicked': 'not applicable',
            });
        }
    }

    optinClick(event: MouseEvent) {
        event.preventDefault();
        if (this.user?.isAuthenticated) {
            this.optInDisabled = true;
            this.optInText = this.optedInText; // Change the text immediately on click
            let postRequestModel: UserRequestModel = new UserRequestModel();
            postRequestModel.type = this.sourceType;
            postRequestModel.id = this.sourceId;
            postRequestModel.optIn = true;
            postRequestModel.source = 'INBOX';
            postRequestModel.actionType = 'OPTIN';
            this.gamePremiereService.getGamepremiereOptIn(postRequestModel);
            this.gamePremiereService.optInObservable.subscribe(
                (res: any) => {
                    if (res.status === 'OPTED_IN') {
                        this.optInText = this.optedInText;
                        this.optInDisabled = true;
                    } else {
                        // Handle case where the opt-in was not successful
                        this.optInDisabled = false;
                    }
                },
                (error) => {
                    // Handling error case
                    this.optInDisabled = false;
                    this.optInText = 'OPT IN';
                    console.error('Opt-in failed', error);
                },
            );
        }
        this.trackGamePremiereCtaStatus(this.optInText);
    }

    trackGamePremiereCtaStatus(cta: string) {
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'promotions',
            'component.LabelEvent': 'game premiere',
            'component.ActionEvent': 'click',
            'component.PositionEvent': this.gameName,
            'component.LocationEvent': this.appInfoConfig.product, //ex: casino
            'component.EventDetails': cta, //ex: opt-in cta, play now cta
            'component.URLClicked': 'not applicable',
        });
    }

    handlePlaynowClick(event: MouseEvent) {
        const mcPageConfig = this.configProviderService.provideMcPageConfig();
        this.gamePlayUrl = '/' + mcPageConfig.labelPrefix + '/launchng/';
        this.link = this.playNowCTA;
        event.preventDefault();
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'promotions',
            'component.LabelEvent': 'game premiere',
            'component.ActionEvent': 'Click',
            'component.PositionEvent': this.gameName,
            'component.LocationEvent': this.appInfoConfig.product,
            'component.EventDetails': this.playNowText,
            'component.URLClicked': 'not applicable',
        });
        if (this.link) {
            if (this.isGameUrl(this.link)) {
                const gameModel = new GameLaunch();
                const gameId: string = this.getGameIdFromLaunchUrl(this.link);
                gameModel.game = this.casinoLobbyService.getGameInfo(gameId);
                gameModel.game.gameId = gameId;
                gameModel.game.lobbyType = this.lobbyType;
                gameModel.isTouch = this.isTouch;
                gameModel.gamePosition = 1;
                gameModel.categoryType = this.appInfoConfig.product;
                gameModel.gameSection = 'game premiere';
                gameModel.from = 'q';
                gameModel.replaceRouteInHistory = false;
                gameModel.isDemoLaunch = false;
                gameModel.skipUnfinGame = false;
                gameModel.gameLaunchedFrom = 'GamePremiere';
                gameModel.gameLaunchedLocation = 'game premiere';
                gameModel.gridPosition = 1;
                gameModel.gameContainer = 'GamePremiere';
                gameModel.returnUrl = window.location.href;
                gameModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
                this.gameLaunchService.launchGame(gameModel);
            }
        }
        this.closeOverlay(true);
        document.getElementsByTagName('html')[0].classList.remove('casino-gamepremiere-open');
    }

    handleViewDetailsClick() {
        this.viewDetailsLink = this.configProviderService.provideGamePremiereConfig().gamePremiereOverlayConfiguration.viewDetails.url;
        let postRequestModel: UserRequestModel = new UserRequestModel();
        if (this.viewDetailsLink) {
            (postRequestModel.type = this.sourceType),
                (postRequestModel.id = this.sourceId),
                (postRequestModel.optIn = false),
                (postRequestModel.source = 'INBOX'),
                (postRequestModel.actionType = 'CLOSE'),
                this.gamePremiereService.getGamepremiereOptIn(postRequestModel);
            this.gamePremiereService.optInObservable.subscribe((response) => {
                if (response) {
                    this.dialog.close();
                    this.Navigator.goTo(this.viewDetailsLink);
                    document.getElementsByTagName('html')[0].classList.remove('casino-gamepremiere-open');
                }
            });
        }
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'promotions',
            'component.LabelEvent': 'game premiere',
            'component.ActionEvent': 'Click',
            'component.PositionEvent': this.gameName,
            'component.LocationEvent': this.appInfoConfig.product,
            'component.EventDetails': this.viewDetails,
            'component.URLClicked': this.viewDetailsLink,
        });
    }
    getGameIdFromLaunchUrl(link: string) {
        return link.substr(link.indexOf(this.gamePlayUrl) + this.gamePlayUrl.length);
    }

    isGameUrl(link: string) {
        return link.indexOf(this.gamePlayUrl) > 0;
    }

    playDesktopVideo() {
        this.showPosterImage = false;
        this.ref.detectChanges();
        const video = this.videoPlayer.nativeElement;
        video
            .play()
            .then(() => {
                this.tracking.triggerEvent('Event.Tracking', {
                    'component.CategoryEvent': 'promotions',
                    'component.LabelEvent': 'game premiere',
                    'component.ActionEvent': 'play',
                    'component.PositionEvent': this.gameName,
                    'component.LocationEvent': this.appInfoConfig.product,
                    'component.EventDetails': 'video play initiated',
                    'component.URLClicked': 'not applicable',
                });
            })
            .catch((error: any) => {
                this.logger.error('Error playing video:', error);
            });
    }

    pauseDesktopVideo() {
        this.videoPlayer.nativeElement.pause();
        this.showPosterImage = true;
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'promotions',
            'component.LabelEvent': 'game premiere',
            'component.ActionEvent': 'pause',
            'component.PositionEvent': this.gameName,
            'component.LocationEvent': this.appInfoConfig.product,
            'component.EventDetails': 'video pause initiated',
            'component.URLClicked': 'not applicable',
        });
    }

    resetVideo() {
        this.showPosterImage = true;
    }

    toggleMoreInfoTerms() {
        this.moreInfoDefaultExpandedView = !this.moreInfoDefaultExpandedView;
        const termsElement: Element | null = document.querySelector('.terms-sec .terms-show-hide') || null;

        if (this.moreInfoDefaultExpandedView) {
            termsElement!.setAttribute('style', 'display:block;');
            this.game_premiere_more_info.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            termsElement!.setAttribute('style', 'display:none;');
        }

        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'promotions',
            'component.LabelEvent': 'game premiere',
            'component.ActionEvent': this.moreInfoDefaultExpandedView ? 'expand' : 'collapse',
            'component.PositionEvent': this.gameName,
            'component.LocationEvent': this.appInfoConfig.product,
            'component.EventDetails': this.moreInfoDefaultExpandedView ? 'more info expand' : 'more info collapse',
            'component.URLClicked': 'not applicable',
        });
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
