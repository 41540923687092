import { IContentImage } from './common.model';

export interface IGameSelectorWidgetQuestionOption {
    title: string;
    image?: IContentImage;
    condition?: string;
    selected: boolean;
}

export interface IGameSelectorWidgetQuestion {
    basedOn: string;
    question: string;
    condition?: string;
    options: Array<IGameSelectorWidgetQuestionOption>;
    optionsDisplayType: string;
    filterCriteria: string;
    tooltipSubtitle: string;
    tooltipTitle: string;
    ctA1Text: string;
    ctA2Text: string;
}

export interface IGameSelectorWidget {
    path: string;
    questions: Array<IGameSelectorWidgetQuestion>;
    resultsTitle: string;
    subTitle: string;
    title: string;
    widgetBackgroundImage: IContentImage;
    overlayBackgroundImage: IContentImage;
    overlayBackgroundColor: string;
    ctaText: string;
    resultCTA1Text: string;
    resultCTA2Text: string;
    condition?: string;
    versionedList: any;
    gamesDisplayType: string;
}

export interface IGameMetaData {
    displayName: string;
    rtp: number;
    ownerId: number;
    vendorId: string;
    featureTags: string;
    symbolTags: string;
    themeTags: string;
    sitecoreId: string;
    gameType: string;
    gameGroup: string;
    gameVariant: string;
    sticker: string;
}

export interface IGameSelectorConfiguration {
    isEnabled: boolean;
    gameSelectorFeedUrl: string;
    providers: string[];
}

export class RecommendedGamesFilterModel {
    basedOn: string;
    filterCriteria: string;
    answers: Array<string>;

    constructor() {
        this.answers = [];
    }
}

export class RecommendedGameModel {
    gameId: string;
    vendorId: string;
    displayName: string;
    gameType: string;
    gameGroup: string;
    image: string;
    jackpotValue?: number;
    gameVariant: string;
    sticker: string;
}

export interface IGameSelectorWidgetGlobalConfig {
    gameTitleColor: string;
    jackpotValueColor: string;
    preferenceBoxTextColor: string;
    progressBarColor: string;
    questionTextColor: string;
    resultTitleColor: string;
    selectedOptionTextColor: string;
    selectedOptionBorderColor: string;
    selectedOptionBackgroundColor: string;
    widgetTitleColor: string;
    widgetSubTitleColor: string;
    gameResultsColor: string;
    preferenceBoxStaticText: string;
    closebtnText: string;
    jackpotValue: string;
    noGamesFoundText: string;
}

export interface IRecommendedGamesResponse {
    games: Array<RecommendedGameModel>;
    selectedOptions: Array<string>;
    questionsState: Array<IGameSelectorWidgetQuestion>;
}

export enum WizardType {
    Questions,
    Games,
    NoGamesFound,
}

export interface IMatDialogOverlayData {
    isDesktop: boolean;
    widget: IGameSelectorWidget;
    globalConfig: IGameSelectorWidgetGlobalConfig;
    category: any;
    widgetLocation: string;
}
